<template>
    <div id="modal-password-recover">
        <v-dialog v-model="dialog_forgotten_password" persistent max-width="450">
            <v-card>
                <validation-observer ref="observer" v-slot="{ invalid }">
                    <v-form ref="form">
                        <v-card-title class="primary white--text">
                            {{ $t('login.edit_password') }}
                        </v-card-title>

                        <v-card-text class="pt-4">
                            <p class="font-16">
                                {{ $t('login.forgot_password_notice') }}
                            </p>

                            <validation-provider :name="$t('login.email')" rules="required|email" v-slot="{ errors }">
                                <v-text-field v-model="email" :label="$t('login.email')" :error-messages="errors"
                                              class="mt-2" name="email" type="text" prepend-icon="mdi-account"
                                />
                            </validation-provider>

                            <p class="mt-1 font-16">
                                {{ $t('login.forgot_password_advice') }}
                            </p>
                        </v-card-text>

                        <v-card-actions class="justify-end">
                            <v-btn @click="closeDialogResetPassword()">
                                {{ $t('global.cancel') }}
                            </v-btn>
                            <v-btn type="submit" color="primary" :disabled="invalid"
                                   @click.prevent="passwordRecoveryEmailSending()" :loading="loading_reset_password"
                            >
                                {{ $t('global.validation') }}
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </validation-observer>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'ModalPasswordRecover',
    data: function () {
        return {
            dialog_forgotten_password: false,
            loading_reset_password: false,
            email: null,
            invalid: false
        }
    },
    methods: {
        passwordRecoveryEmailSending() {
            this.loading_reset_password = true;

            this.axios.post('api/reset_password/send_mail', {
                email: this.email
            }).then(() => {
                this.showGlobalSnackbar('success', this.$t('login.recovery_email_sent'));
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('login.error_reset_password'));
            }).finally(() => {
                this.closeDialogResetPassword();
                this.loading_reset_password = false;
            });
        },

        closeDialogResetPassword() {
            this.dialog_forgotten_password = false;
            this.$refs.form.reset();
            this.$refs.observer.reset();
        }
    },
}
</script>
