<template>
    <div id="setting-referential">
        <v-row>
            <v-col>
                <v-subheader>
                    <p class="title mb-0 color-primary text-uppercase">
                        {{ $t('roles.title') }}
                    </p>
                </v-subheader>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-card elevation="2" outlined>
                    <v-card-title>
                        <v-spacer/>
                    </v-card-title>

                    <v-data-table
                        :headers="headers"
                        :items="roles"
                        sort-by="id"
                        class="elevation-1"
                        :loading="loading"
                        :items-per-page="10"
                        :search="search">

                        <template v-slot:top>
                            <v-toolbar flat color="white" class="pt-3">
                                <v-row>
                                    <v-col cols="8">
                                        <v-btn color="primary" class="white--text mr-3" @click="openDialogAddRole()">
                                            {{ $t('roles.add_role') }}
                                        </v-btn>
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            v-model="search"
                                            append-icon="mdi-magnify"
                                            :label="$t('global.search')"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-dialog v-model="dialog_edit" max-width="500px">
                                    <v-card>
                                        <validation-observer ref="observer" v-slot="{ invalid }">
                                            <v-form ref="form">
                                                <v-card-title><span class="headline">{{ dialogTitle }}</span>
                                                </v-card-title>
                                                <v-divider></v-divider>

                                                <v-card-text>
                                                    <validation-provider :name="$t('global.label')" rules="required"
                                                                         v-slot="{ errors }">
                                                        <v-text-field
                                                            :label="$t('global.label')"
                                                            v-model="role.name"
                                                            :prepend-icon="'mdi-label-outline'"
                                                            :error-messages="errors" solo
                                                        />
                                                    </validation-provider>
                                                </v-card-text>

                                                <v-card-actions>
                                                    <v-spacer/>
                                                    <v-btn @click="closeDialog()">
                                                        <v-icon left>mdi-close</v-icon>
                                                        {{ $t('global.close') }}
                                                    </v-btn>
                                                    <v-btn type="submit" color="primary"
                                                           @click.prevent="prepareEditRole()"
                                                           :disabled="invalid">
                                                        <v-icon left>mdi-content-save</v-icon>
                                                        {{ $t('global.validation') }}
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-form>
                                        </validation-observer>
                                    </v-card>
                                </v-dialog>

                                <v-dialog v-model="dialog_delete" max-width="500px">
                                    <v-card>
                                        <v-card-title>
                                            <span class="headline">{{ $t('roles.delete_role') }}</span>
                                        </v-card-title>
                                        <v-divider></v-divider>
                                        <br/>

                                        <v-card-actions>
                                            <v-spacer/>
                                            <v-btn @click="dialog_delete = false">
                                                <v-icon left>mdi-close</v-icon>
                                                {{ $t('global.cancel') }}
                                            </v-btn>
                                            <v-btn color="error" @click="confirmDelete()">
                                                <v-icon left>mdi-delete</v-icon>
                                                {{ $t('global.delete') }}
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>

                                <v-snackbar
                                    v-model="snackbar"
                                    :color="snackbar_type">
                                    {{ snackbar_text }}
                                    <template v-slot:action="{ attrs }">
                                        <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                                    </template>
                                </v-snackbar>
                            </v-toolbar>
                        </template>

                        <template v-slot:item.action="{ item }">
                            <v-icon class="pointer" @click="openDialogUpdate(item)">mdi-pencil</v-icon>
                            <v-icon class="pointer" @click="openDialogDelete(item)" v-if="item.total_user === 0">
                                mdi-delete
                            </v-icon>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import {ROLES} from "../../../api";

export default {
    name: "Role",
    data() {
        return {
            roles: [],
            headers: [
                {text: "Id", value: 'id'},
                {text: this.$t('global.label'), value: 'name'},
                {text: this.$t('roles.total_user'), value: 'total_user'},
                {text: this.$t('roles.access_level'), value: 'access_level'},
                {text: this.$t('global.actions'), value: 'action'}
            ],
            loading: true,
            search: '',
            invalid: false,

            dialog_edit: false,
            edited_index: null,
            role: {
                id: null,
                name: '',
            },
            valid: true,

            dialog_delete: false,
            delete_id: null,
            deleted_index: null,
        }
    },
    computed: {
        dialogTitle() {
            return this.edited_index === null ? this.$t('roles.add_role') : this.$t('roles.edit_role');
        }
    },
    created() {
        this.getRoles();
    },
    methods: {
        getRoles() {
            this.axios.get(ROLES).then((success) => {
                this.roles = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'))
            }).finally(() => {
                this.loading = false
            });
        },

        openDialogAddRole() {
            if (this.edited_index !== null) {
                this.$refs.form.reset();
                this.$refs.observer.reset();
                this.edited_index = null;
            }

            this.dialog_edit = true;
        },

        openDialogUpdate(role) {
            this.edited_index = this.roles.indexOf(role);
            this.role = Object.assign({}, role);
            this.dialog_edit = true;
        },

        prepareEditRole() {
            if (this.edited_index !== null) {
                this.updateRole();
            } else {
                this.addRole();
            }
        },

        addRole() {
            this.axios.post(ROLES, this.role
            ).then((success) => {
                this.roles.push(success.data);
                this.showSnackbar('success', this.$t('global.success_to_add'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_add'))
            }).finally(() => {
                this.closeDialog();
            });
        },

        updateRole() {
            this.axios.patch(ROLES + '/' + this.role.id,
                this.role
            ).then((success) => {
                Object.assign(this.roles[this.edited_index], success.data);
                this.edited_index = null;
                this.showSnackbar('success', this.$t('global.success_to_edit'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'))
            }).finally(() => {
                this.closeDialog();
            });
        },

        openDialogDelete(role) {
            this.deleted_index = this.roles.indexOf(role);
            this.delete_id = role.id;
            this.dialog_delete = true;
        },

        confirmDelete() {
            this.axios.delete(
                ROLES + '/' + this.delete_id
            ).then(() => {
                this.roles.splice(this.deleted_index, 1);
                this.showSnackbar('success', this.$t('global.success_to_delete'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_delete'))
            }).finally(() => {
                this.dialog_delete = false;
            });
        },

        closeDialog() {
            this.dialog_edit = false;
            this.$refs.form.reset();
            this.$refs.observer.reset();
        }
    }
}
</script>
