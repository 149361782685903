var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"add-new-client"}},[_c('v-tooltip',{staticClass:"mr-2",attrs:{"bottom":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",staticStyle:{"border":"1px solid #FFFFFF45"},attrs:{"text":"","icon":"","dusk":"logout-button"},on:{"click":function($event){_vm.dialog_add = true}}},on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-plus")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('clients.add-clients')))])]),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog_add),callback:function ($$v) {_vm.dialog_add=$$v},expression:"dialog_add"}},[_c('v-snackbar',{attrs:{"color":_vm.snackbar_type},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-icon',{attrs:{"color":"white"},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("mdi-close")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v("\n            "+_vm._s(_vm.snackbar_text)+"\n            ")]),_vm._v(" "),_c('v-card',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{ref:"form"},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('add_new_client.add_client')))])]),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-card-text',[_c('validation-provider',{attrs:{"name":_vm.$t('global.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"clearable":"","label":_vm.$t('global.name'),"prepend-icon":'mdi-label-outline',"error-messages":errors},model:{value:(_vm.professional.name),callback:function ($$v) {_vm.$set(_vm.professional, "name", $$v)},expression:"professional.name"}})]}}],null,true)}),_vm._v(" "),_c('validation-provider',{attrs:{"name":_vm.$t('sites.address'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vuetify-google-autocomplete',{ref:"clientAddress",attrs:{"id":"map","clearable":"","prepend-icon":"mdi-map-marker","error-messages":errors},on:{"placechanged":_vm.setPlace},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                    "+_vm._s(_vm.$t('sites.address'))+"\n                                ")]},proxy:true}],null,true),model:{value:(_vm.professional.address),callback:function ($$v) {_vm.$set(_vm.professional, "address", $$v)},expression:"professional.address"}})]}}],null,true)}),_vm._v(" "),_c('validation-provider',{attrs:{"name":_vm.$t('add_new_client.email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"clearable":"","label":_vm.$t('add_new_client.email'),"prepend-icon":'mdi-at',"error-messages":errors},model:{value:(_vm.professional.mail),callback:function ($$v) {_vm.$set(_vm.professional, "mail", $$v)},expression:"professional.mail"}})]}}],null,true)}),_vm._v(" "),_c('validation-provider',{attrs:{"name":_vm.$t('add_new_client.phone_number'),"rules":"required|digits:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"clearable":"","label":_vm.$t('add_new_client.phone_number'),"prepend-icon":'mdi-phone',"error-messages":errors},model:{value:(_vm.professional.phone_number),callback:function ($$v) {_vm.$set(_vm.professional, "phone_number", $$v)},expression:"professional.phone_number"}})]}}],null,true)}),_vm._v(" "),_c('validation-provider',{attrs:{"name":_vm.$t('global.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.offers_labels,"label":_vm.$t('users.offer'),"menu-props":{ bottom: true, offsetY: true },"prepend-icon":'mdi-sitemap',"item-text":"label","item-value":"id","chips":"","clearable":""},model:{value:(_vm.professional.offer),callback:function ($$v) {_vm.$set(_vm.professional, "offer", $$v)},expression:"professional.offer"}})]}}],null,true)}),_vm._v(" "),_c('validation-provider',{attrs:{"name":_vm.$t('global.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{attrs:{"items":_vm.tags,"label":_vm.$t('users.tags'),"menu-props":{ bottom: true, offsetY: true },"prepend-icon":'mdi-sitemap',"item-text":"label","item-value":"id","multiple":"","chips":"","clearable":""},model:{value:(_vm.professional.tags),callback:function ($$v) {_vm.$set(_vm.professional, "tags", $$v)},expression:"professional.tags"}})]}}],null,true)}),_vm._v(" "),_c('validation-provider',{attrs:{"name":_vm.$t('global.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"solo":"","label":_vm.$t('users.logo'),"prepend-icon":'mdi-label-outline',"error-messages":errors},model:{value:(_vm.professional.logo),callback:function ($$v) {_vm.$set(_vm.professional, "logo", $$v)},expression:"professional.logo"}})]}}],null,true)})],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{on:{"click":function($event){return _vm.closeDialog();}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close")]),_vm._v("\n                            "+_vm._s(_vm.$t('global.close'))+"\n                        ")],1),_vm._v(" "),_c('v-btn',{attrs:{"type":"submit","color":"primary","disabled":invalid},on:{"click":function($event){$event.preventDefault();return _vm.addProfessional()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v("\n                            "+_vm._s(_vm.$t('global.validation'))+"\n                        ")],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }