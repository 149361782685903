<template>
    <v-col class="col col-12 mt-0 pt-0 pa-0" style="margin-top:0px!important;padding:0px;position:relative; top:-12px;">
        
        <!-- MACHINE MENU PACKAGE -->
        <div class="pl-3 pr-3">
            <v-col class="pl-5 pr-5" style="background-color:#001047;color:#FFFFFF;border-radius: 0px 0px 5px 5px;">
                <!-- TOP TITLE -->
                <v-row class="pl-0 pt-5 mb-5" style="height: 52px; padding:5px;">
                    <!-- MACHINE NAME -->
                    <v-col class="row mb-0 pa-0 mt-1 col-auto" @click="infoToggler()">
                        <!-- COUNTER -->
                        <v-col color="white" class="col-auto pa-0 mr-5" v-if="machine">
                            <v-col class="col pt-0 pb-0 mr-10 rounded col ml-6" style="height: 35px;background-color: rgb(92 101 123);font-weight: bold;text-align: center;font-size: 16px;border: 1px solid #ffffff5c;line-height: 35px;color: #FFFFFF;">
                                {{ machine.label }}
                            </v-col>
                        </v-col>
                    </v-col>
                    
                    <!-- PAGE TITLE -->
                    <v-subheader class="v-subheader col theme--light pl-3 ml-2">
                        <p class="title mb-0 color-white" style="color: white!important;">
                            <div style="color:#FFFFFFb3;margin-right:4px;margin-left:4px;"> Equipements > {{ machine.label }} > Rapport > </div><div @click="infoToggler()" style="color:#FFFFFF;font-weight:bold;"> Edition</div>
                        </p>
                    </v-subheader>

                    <!-- GLOBAL ACTIONS -->
                    <v-col class="row mt-1 col-auto pa-0 pr-5">
                        <v-tooltip bottom dark class="mr-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="getData()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                    <v-icon color="white">mdi-tag-multiple</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('global.1_5_2') }}</span>
                        </v-tooltip>
                        <v-tooltip bottom dark class="mr-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="getData()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                    <v-icon color="white">mdi-refresh</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('machines.refresh') }}</span>
                        </v-tooltip>
                        
                        <v-tooltip v-if="infoToggle == true" bottom dark class="mr-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="infoToggler()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                    <v-icon color="white">mdi-minus-box-multiple</v-icon>
                                </v-btn>
                            </template>
                            <span>Replier les informations machine</span>
                        </v-tooltip>

                        <v-tooltip v-if="infoToggle == false" bottom dark class="mr-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="infoToggler()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                    <v-icon color="white">mdi-plus-box-multiple</v-icon>
                                </v-btn>
                            </template>
                            <span>Déplier les informations machine</span>
                        </v-tooltip>
                    </v-col>
                </v-row>

                <!-- MACHINE INFOS -->
                <v-col class="mb-4" v-if="infoToggle == true" style="min-height: 250px; background-color:#e8edff;border-radius:5px;">
                    <v-row>
                        <v-col class="col-4">
                            <machine-state/>
                        </v-col>
                        <v-col class="col pl-0">
                            <machine-specifications v-on:updateLabelMachine="updateLabelMachine"/>
                            <v-row>
                                <v-col class="col">
                                    <machine-subassemblies/>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>

                <!-- MACHINE LINKS -->
                <v-row style="border-top: 1px solid #ffffff1c;">
                    <v-row style="margin:0px;">
                        <v-row class="ml-5 pt-5 pb-5 rounded" style="">
                            <!-- ALERTS -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + machine.id + '/events'}" dusk="logout-button">
                                        <v-icon color="white">mdi-alert</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.alerts') }}</span>
                            </v-tooltip>

                            <!-- REPORTS -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + machine.id + '/report'}" dusk="logout-button">
                                        <v-icon color="white">mdi-chart-box</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.reports') }}</span>
                            </v-tooltip>

                            <!-- DETAILS -->
                            <v-tooltip v-if="access_level <= 100 || access_level == 200 || access_level === 300" bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + machine.id + '/details'}" dusk="logout-button">
                                        <v-icon color="white">mdi-chart-line</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.courbes') }}</span>
                            </v-tooltip>

                            <!-- SANDBOX -->
                            <v-tooltip bottom dark class="mr-2" v-if="$store.state.access_level <= 100">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" class="mr-5 col-0" :to="{ path: '/machine/' + machine.id + '/sandbox'}" dusk="logout-button">
                                    <v-icon color="red">mdi-vector-curve</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('machines.sandbox') }}</span>
                        </v-tooltip>

                            <!-- Documents -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + machine.id + '/documents'}" dusk="logout-button">
                                        <v-icon color="white">mdi-file-document</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machines.documents') }}</span>
                            </v-tooltip bottom dark class="mr-2">
                            
                            <!-- SETTINGS -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/settings'}" dusk="logout-button">
                                        <v-icon color="white">mdi-cog</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.settings') }}</span>
                            </v-tooltip bottom dark class="mr-2">
                        </v-row>  

                        <!-- Menu déroulant pour choisir les rapports -> Potentiel champ rechercher pour la suite
                        <v-row id="report-linker" class="col-6 justify-end pt-2 pr-8" >
                            
                            <v-select
                                :reports="reports"
                                label="Sélectionner un rapport"
                                v-model="reportSelection"
                                outlined
                            ></v-select>
                            <p>{{ reportSelection }}</p>
                            <v-btn :disabled="isLoading" @click="calculateHeight" class="ml-2">
                                <v-icon color="primary">{{ isLoading ? 'mdi-loading' : 'mdi-printer' }}</v-icon>
                            </v-btn>
                        </v-row>   
                            -->
                    </v-row>
                </v-row>
            </v-col>
        </div>   

        <!-- CONTENT -->
        <v-form ref="form" class="row mt-0" style="background-color: transparent !important;">
            
            <!-- REPORT VIEW -->
            <v-col v-if="access_level <= 99" color="white" class="pa-6  pt-3 col">
                <div v-if="loading_datatable == true"  style="color:grey;text-align: center;">
                    <li class="rounded row notification_card mb-1 pa-10 mt-15 ml-6" style="color: grey;justify-content: center;">
                        <div style="color:grey;text-align: center;"><strong>Chargement en cours...</strong></div>
                    </li>
                </div>            
                
                <div v-if="loading_datatable == false" flat color="white" class="pt-0 mb-3">
                    <!-- campain ADD / EDIT MODAL 
                    <v-row style="color:#ffffffe0;background-color:#1e1e1e;margin:0">
                        <v-col class="col-8">
                            <v-col v-on="on" link :to="'/machine/' + machine.id" style="font-weight: bold;border: 1px solid #bdbdbd45;border-radius:5px">Campagne : {{campain.name}}</v-col>
                            <!--SUBASSEMBLIES
                            <div class="col pl-4 mt-1" style="border: 1px solid #bdbdbd45; font-size:12px;border-radius:5px;display:flex;align-items:center;">  
                                <div style="font-weight: bold;font-size: 13px;">Sous-ensemble(s) :</div> 
                                <v-row class="row col pa-0 pt-2 ml-2">
                                    <div v-for="subassembly in machine.subassemblies" :key="subassembly.id">
                                        <v-chip link :to="'/subassembly/' + subassembly.id" style="font-size: 12px;" class="mr-2 mb-2">{{subassembly.label}}</v-chip>
                                    </div>  
                                </v-row> 
                            </div>
                        </v-col>
                        <v-col class="col pl-5 ma-3 ml-0" style="border: 1px solid #bdbdbd45;border-radius:5px;text-align:center;display:flex;flex-direction:column;justify-content:center">
                            <div v-for="analyst in analysts" :key="analyst">
                                <div v-if="analyst.id == report.validator" >
                                    Validé par : {{ analyst.first_name }} {{  analyst.last_name }}
                                </div>
                            </div>
                            
                            <div v-for="analyst in analysts" :key="analyst">
                                <div v-if="analyst.id == report.analyst" >
                                    Analysé par : {{ analyst.first_name }} {{  analyst.last_name }}
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                    -->
                    
                    <!--CHIPS-->
                    <div v-for="filters in machine_filters" :key="filters" style="max-height:90px;border-radius:5px;overflow:hidden;">
                        <div  v-if="filters.level == report.alert_level && filters.level_name !== 'Tout voir'" :to="{ path: '/campain/' + campain.id}" class="col-0" style="color:white;font-weight:800;display:flex;justify-content:center;text-shadow:2px 2px 4px rgba(0, 0, 0, 0.29); height:100%;min-width: 8px;border-radius:5px;" >
                            <v-row class="pa-0 ma-0">
                                <v-col class="col pa-0" style="max-width:30%;display:flex;flex-direction:column;" :style="{backgroundColor: filters.level_color}">
                                    <v-row style="align-items:center;width:100%;position:relative;top:0px;padding-left: 35px;">
                                        <v-col class="col-5" style="font-size:50px;font-weight:bold;text-align:right;">{{ report.indice }}%</v-col>
                                        <v-col style="font-size:13px;text-align:left;font-weight: 400;align-items:center;">
                                            <div  v-if="report_position == 1 && report_position == report_nb">Rapport initial (seul rapport existant)</div>
                                            <div  v-if="report_position == 1 && report_position != report_nb">Rapport initial (sur {{ report_nb }} existants)</div> 
                                            <div  v-if="report_position > 1 && report_position == report_nb">Rapport n°{{ report_position }} (dernier en date)</div>
                                            <div  v-if="report_position > 1 && report_position != report_nb">Rapport n°{{ report_position }} (sur {{ report_nb }} existants)</div>
                                            <div  v-if="report.released_at">Publié le {{ report.released_at }}</div> 
                                            <div  v-if="!report.released_at">Date de publication non définie</div> 
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col class="col pa-3" style="max-width:70%;background-color:white;height:90px;display:flex;flex-direction:column;justify-content: center;text-shadow:none;">
                                    <div v-for="state in machine_filters" :key="state">
                                        <div v-if="state.percent === report.indice" style="font-size:18px;color:black;padding-left: 20px;"> {{ state.annotation }}</div>
                                        <div v-if="state.percent === report.indice && report_position == 1" style="font-size:14px;color:black;padding-left: 20px;font-weight:400;display:flex;"> <div style="color:#0e4cef;font-weight:bold;margin-right:5px;">* </div> Il s'agit du premier rapport enregistré sur cette application pour ce moyen.</div>
                                        <div v-if="state.percent === report.indice && previous_indice < report.indice && report_position != 1" style="font-size:14px;color:black;padding-left: 20px;font-weight:400;display:flex;"> <div style="color:green;font-weight:bold;margin-right:5px;">↗ </div> Evolution positive depuis le {{previous_date}} ({{previous_indice}}%)</div>
                                        <div v-if="state.percent === report.indice && previous_indice == report.indice && report_position != 1" style="font-size:14px;color:black;padding-left: 20px;font-weight:400;display:flex;"> <div style="color:black;font-weight:bold;margin-right:5px;">= </div> Pas d'évolution depuis le {{previous_date}} ({{previous_indice}}%)</div>
                                        <div v-if="state.percent === report.indice && previous_indice > report.indice && report_position != 1" style="font-size:14px;color:black;padding-left: 20px;font-weight:400;display:flex;"> <div style="color:red;font-weight:bold;margin-right:5px;">↘ </div>Evolution négative depuis le {{previous_date}} ({{previous_indice}}%)</div>
                                    </div>
                                </v-col>
                                <v-col class="col-2 pa-3" style="max-width:70%;background-color:white;height:90px;display:flex;flex-direction:column;justify-content: center;text-shadow:none;align-items:center;">
                                    <v-row style="align-items: center;">
                                        <v-row class="col mr-8" style="justify-content: start;
                                                        display: flex;
                                                        align-items: center;"
                                        >
                                            <div v-if="report.presta == 0">
                                                <div style="font-size: 20px;
                                                            border: 1px solid grey;
                                                            border-radius: 50px;
                                                            padding: 10px;
                                                            color: grey;
                                                            margin-right: 15px;"
                                                >VIB</div>
                                            </div>
                                            <div v-if="report.presta == 1">
                                                <div style="font-size: 20px;
                                                            border: 1px solid grey;
                                                            border-radius: 50px;
                                                            padding: 10px;
                                                            color: grey;
                                                            margin-right: 15px;"
                                                >TH</div>
                                            </div>
                                            <div v-if="report.presta == 2">
                                                <div style="font-size: 20px;
                                                            border: 1px solid grey;
                                                            border-radius: 50px;
                                                            padding: 10px;
                                                            color: grey;
                                                            margin-right: 15px;"
                                                >US</div>
                                            </div>
                                            <div v-if="report.presta == 3">
                                                <div style="font-size: 20px;
                                                            border: 1px solid grey;
                                                            border-radius: 50px;
                                                            padding: 10px;
                                                            color: grey;
                                                            margin-right: 15px;"
                                                >EQ</div>
                                            </div>
                                            <div v-if="report.presta == 4">
                                                <div style="font-size: 20px;
                                                            border: 1px solid grey;
                                                            border-radius: 50px;
                                                            padding: 10px;
                                                            color: grey;
                                                            margin-right: 15px;"
                                                >EXP</div>
                                            </div>
                                            <div v-if="report.presta == 5">
                                                <div style="font-size: 20px;
                                                            border: 1px solid grey;
                                                            border-radius: 50px;
                                                            padding: 10px;
                                                            color: grey;
                                                            margin-right: 15px;"
                                                >OL</div>
                                            </div>

                                            <v-icon v-if="report.type == 0 || report.type == 3" class="pointer mb-2"
                                                    v-bind="attrs" v-on="on"
                                                    style="font-size:60px;color: #c3c3c3;margin-bottom: 0px !important;"
                                            >
                                                mdi-file-pdf
                                            </v-icon>

                                            <v-icon v-if="report.type == 1" class="pointer mb-2"
                                                    v-bind="attrs" v-on="on"
                                                    style="font-size:60px;color: #c3c3c3;margin-bottom: 0px !important;"
                                            >
                                                mdi-file-document
                                            </v-icon>
                                        </v-row>
                                        
                                        <v-tooltip bottom dark class="mr-2">
                                            <template v-slot:activator="{ on }">
                                                <v-btn text icon v-on="on" class="mr-2" dusk="logout-button">
                                                    <v-icon color="#b1b1b1">mdi-eye</v-icon>
                                                </v-btn>
                                            </template>
                                            <span style="opacity:100%;">
                                                <highcharts :options="lineChartOptions"></highcharts>
                                            </span>
                                        </v-tooltip>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </div>
                    </div>  
                    
                    <!--  DIGITAL REPORT CONTENT -->
                    <v-card v-if="report.type === 1" class="mt-3" style="margin-bottom:152px;">
                        <validation-observer ref="observer" v-slot="{ invalid }">
                            <v-card-text class="pa-0">
                                <v-col>
                                    <v-row class="pt-0" style="margin-bottom: 0px;">
                                        <!-- OK REPORT CONTEXT -->
                                        <div class="pt-4 pa-3 pr-0 pt-0" style="width:30%;background-color: #000000ba;color: white;border-radius: 5px 0px 0px 0px;">
                                            <v-row class="ma-0 pa-0" style="justify-content: space-between;">
                                                <h4 class="pa-5 pt-2 pb-0 ml-1">Décrire le contexte de l'intervention</h4>
                                                <div class="mt-0 pb-0" v-if="report.context == '' || report.context == null" style="background-color: #ff801875; border-radius:5px 0px 0px 5px;height:40px; width:40px;display:flex;justify-content:center;">
                                                    <v-icon color="white">mdi-alert-circle-outline</v-icon>
                                                </div>
                                                <div class="mt-0 pb-0" v-if="report.context != '' && report.context != null" style="background-color: #b7b7b754; border-radius:5px 0px 0px 5px;height:40px; width:40px;display:flex;justify-content:center;">
                                                    <v-icon color="white">mdi-check-circle-outline</v-icon>
                                                </div>
                                            </v-row>
                                            <br>
                                            <p class="pa-8 pt-4" style="color:#b9b9b9;font-size:13px;">
                                                Le contexte de l'intervention permet de founir des détails à l'analyste pour établir le rapport.<br><br>
                                                Toute information reste importante et doit être spécifiée comme par exemple les vitesses de rotation ou l'incapacité de mesurer un sous-ensemble.
                                            </p>
                                            <p class="pa-8 pt-4" style="color:red;font-size:13px;">
                                                (*) Ce champ doit être obligatoirement renseigné pour valider ou publier ce rapport.
                                            </p>
                                        </div>
                                        <div class="mt-0 pa-3" style="width:70%;    background-color: #00000005;">
                                            <vue-editor id="editor0" v-model="report.context" ref="editor" class="col col"></vue-editor>
                                        </div>
                                    </v-row>

                                    <v-row class="pt-6 mt-0" style="margin-bottom: 0px;">
                                        <!-- OK REPORT DEFAULTS -->
                                        <div class="mt-4 pa-3 pr-0 pt-0" style="width:30%;border-right:1px solid rgba(0, 0, 0, 0.22)">
                                            <v-row class="ma-0 pa-0" style="justify-content: space-between;">
                                                <h4 class="pa-5 pt-2 pb-0 ml-1">1 - Vérification des défauts potentiels</h4>
                                                <div class="mt-0 pb-0" v-if="report.defaults == '' || report.defaults == null" style="background-color: #ff5b00b0; border-radius:5px 0px 0px 5px;height:40px; width:40px;display:flex;justify-content:center;">
                                                    <v-icon color="white">mdi-alert-circle-outline</v-icon>
                                                </div>
                                                <div class="mt-0 pb-0" v-if="report.defaults != '' && report.defaults != null" style="background-color: #118d0078; border-radius:5px 0px 0px 5px;height:40px; width:40px;display:flex;justify-content:center;">
                                                    <v-icon color="white">mdi-check-circle-outline</v-icon>
                                                </div>
                                            </v-row>
                                            <br>
                                            <p class="pa-8 pt-4" style="color:grey;font-size:13px;">
                                                Cette section indique le cheminement de l'analyse<br><strong>et reste non visible par le client final.</strong><br><br>
                                                Par défaut pour une compréhension de la réflexion, veuillez noter un maximum d'informations et remarque sur ce qui est constaté. Il peut être pertinent de faire un retour global sur l'ensemble des défauts à relever.<br>
                                                Le contenu est pré-renseigné, cela vous permet d'utiliser une base pour développer votre analyse.
                                            </p>
                                        </div>
                                        <div class="mt-0 pa-3" style="width:70%">
                                            <vue-editor id="editor0" v-model="report.defaults" ref="editor" class="col col"></vue-editor>
                                        </div>
                                    </v-row>

                                    <v-row class="pt-6 mt-0" style="margin-bottom: 0px;">
                                        <!-- OK REPORT COMMENTS -->
                                        <div class="mt-4 pa-3 pr-0 pt-0" style="width:30%;border-right:1px solid rgba(0, 0, 0, 0.22)">
                                            <v-row class="ma-0 pa-0" style="justify-content: space-between;">
                                                <h4 class="pa-5 pt-2 pb-0 ml-1">2 - Complément d'informations</h4>
                                                <div class="mt-0 pb-0" v-if="report.comment == '' || report.comment == null" style="background-color: #ff5b00b0; border-radius:5px 0px 0px 5px;height:40px; width:40px;display:flex;justify-content:center;">
                                                    <v-icon color="white">mdi-alert-circle-outline</v-icon>
                                                </div>
                                                <div class="mt-0 pb-0" v-if="report.comment != '' && report.comment != null" style="background-color: #118d0078; border-radius:5px 0px 0px 5px;height:40px; width:40px;display:flex;justify-content:center;">
                                                    <v-icon color="white">mdi-check-circle-outline</v-icon>
                                                </div>
                                            </v-row>
                                            <br>
                                            <p class="pa-8 pt-4" style="color:grey;font-size:13px;">
                                                Cette section vous permet <strong> d'informer le client final sur les détails de votre analyse</strong>.<br><br>
                                                Dans cette encart soyez factuels et adoptez une approche vulgarisatrice pour permettre à tous types de lecteurs de comprendre votre commentaire.<br>
                                                Pour plus de précision et si besoin n'hésitez pas à compléter vos propos par quelques prises de vues.
                                            </p>
                                        </div>
                                        <div class="mt-0 pa-3" style="width:70%">
                                            <vue-editor id="editor0" v-model="report.comment" ref="editor" class="col col"></vue-editor>
                                        </div>
                                    </v-row>

                                    <v-row class="pt-4 mt-4" style="margin-bottom: 0px;background-color: #fbfbfb;">
                                        <!-- OK REPORT COMMENTS -->
                                        <div class="mt-0 pa-3 pr-0 pt-0" style="width:30%;border-right:1px solid rgba(0, 0, 0, 0.22)">
                                            <v-row class="ma-0 pa-0" style="justify-content: space-between;">
                                                <h4 class="pa-5 pt-2 pb-0 ml-1">3 - Prises de vues (AMS ou interface Techview)</h4>
                                                <div class="mt-0 pb-0" v-if="img_available == false" style="background-color: #ff5b00b0; border-radius:5px 0px 0px 5px;height:40px; width:40px;display:flex;justify-content:center;">
                                                    <v-icon color="white">mdi-alert-circle-outline</v-icon>
                                                </div>
                                                <div class="mt-0 pb-0" v-if="img_available == true" style="background-color: #118d0078; border-radius:5px 0px 0px 5px;height:40px; width:40px;display:flex;justify-content:center;">
                                                    <v-icon color="white">mdi-check-circle-outline</v-icon>
                                                </div>
                                            </v-row>
                                            
                                            <!-- uploader -->
                                            <div :min-height="minHeight + 'px'" elevation="2" outlined height="100%" class="col col">
                                                <v-col class="col pt-1">
                                                    <v-row class="mb-4 mr-2 ml-2 mt-2">
                                                        <v-card
                                                            :class="{ 'grey lighten-2': img_dragover }"
                                                            class="w-100" height="240"
                                                            style="background-color: #efefef;border: 2px dashed #e1e1e1;"
                                                            @dragenter.prevent="img_dragover = true"
                                                            @dragleave.prevent="img_dragover = false"
                                                            @dragover.prevent="img_dragover = true"
                                                            @drop.prevent="img_onDrop($event)"
                                                            outlined
                                                        >
                                                            <v-card-text class="mt-8 mb-6 text-center">
                                                                <v-icon large color="primary"> mdi-cloud-download-outline</v-icon></br></br>
                                                                <strong>Faites glisser vos fichiers ici <br>(format JPG ou PNG uniquement)</strong></br>
                                                                Les fichiers déposés ici seront directement enregistrés et disponibles ci aprés.
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-row>

                                                <v-row v-if="external_link_activated" class="ma-2">
                                                        <v-btn @click="openDialogAddExternalLink()" block color="primary">
                                                            {{ $t('document.add_link_extern_document') }}
                                                        </v-btn>
                                                    </v-row>
                                                </v-col>
                                                <v-col class="col pt-5">
                                                    <v-row>
                                                        <v-col class="text-left mb-1 pl-7" style="font-weight:600;">
                                                            Eléments déjà enregistrés :
                                                        </v-col>
                                                    </v-row>

                                                    <v-progress-linear color="primary" indeterminate v-if="img_loading"></v-progress-linear>

                                                    <v-virtual-scroll :items="img_result" height="150" item-height="50" class="mt-1">
                                                        <template v-slot:default="{ item }">
                                                            <v-list-item>
                                                                <!--
                                                                <v-list-item-content v-if="item.link">
                                                                    <v-list-item-title>
                                                                        <a :href="item.link" target="_blank"> {{ item.link }}</a>
                                                                    </v-list-item-title>
                                                                </v-list-item-content>

                                                                <v-list-item-action v-if="item.link">
                                                                    <v-btn @click.stop="removeExternalLink(item.id)" icon>
                                                                        <v-icon> mdi-close-circle</v-icon>
                                                                    </v-btn>
                                                                </v-list-item-action>
                                                                -->
                                                                <v-list-item-content v-if="item.file_name">
                                                                    <v-list-item-title>
                                                                        <a :href="item.path" download> {{ item.file_name }}</a>
                                                                    </v-list-item-title>
                                                                </v-list-item-content>

                                                                <v-list-item-action v-if="item.file_name">
                                                                    <v-btn @click.stop="img_removeDocument(item.file_name)" icon>
                                                                        <v-icon> mdi-close-circle</v-icon>
                                                                    </v-btn>
                                                                </v-list-item-action>
                                                            </v-list-item>

                                                            <v-divider></v-divider>
                                                        </template>
                                                    </v-virtual-scroll>
                                                </v-col>

                                                <template v-if="external_link_activated">
                                                    <v-dialog v-model="dialog_add" max-width="500px">
                                                        <v-card>
                                                            <v-card-title>
                                                                <span class="headline">{{ $t('document.add_link_extern_document') }}</span>
                                                            </v-card-title>
                                                            <v-divider></v-divider>

                                                            <v-card-text><br/>
                                                                <v-form v-model="valid">
                                                                    <v-text-field
                                                                        label="URL" solo
                                                                        :prepend-icon="'mdi-web'"
                                                                        v-model="external_link.link"
                                                                    ></v-text-field>
                                                                </v-form>
                                                            </v-card-text>

                                                            <v-card-actions class="justify-end">
                                                                <v-btn @click="addExternalLink()" color="primary">
                                                                    <v-icon left>mdi-content-save</v-icon>
                                                                    {{ $t('global.validation') }}
                                                                </v-btn>
                                                            </v-card-actions>
                                                        </v-card>
                                                    </v-dialog>

                                                    <v-snackbar
                                                        v-model="snackbar"
                                                        :color="snackbar_type">
                                                        {{ snackbar_text }}
                                                        <template v-slot:action="{ attrs }">
                                                            <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                                                        </template>
                                                    </v-snackbar>
                                                </template>
                                            </div> 
                                        </div>
                                        <div class="mt-0 pa-3" style="width:70%">
                                            <!-- IMAGES CAROUSEL -->
                                            <v-carousel class="col" hide-delimiters>
                                                <v-carousel-item 
                                                    v-for="img in img_urls" :key="img"
                                                    :src=img
                                                    height="400"
                                                    progress="primary"
                                                    contain
                                                ></v-carousel-item>
                                            </v-carousel>
                                        </div>
                                    </v-row>

                                    <v-row class="pt-4 mt-0 mb-4">
                                        <!-- REPORT PRECO -->
                                        <div class="mt-4 pa-3 pr-0 pt-0" style="width:30%;border-right:1px solid rgba(0, 0, 0, 0.22)">
                                            <v-row class="ma-0 pa-0" style="justify-content: space-between;">
                                                <h4 class="pa-5 pt-2 pb-0 ml-1">4 - Rédiger vos préconisations</h4>
                                                <div class="mt-0 pb-0" v-if="report.preco == '' || report.preco == null" style="background-color: #ff5b00b0; border-radius:5px 0px 0px 5px;height:40px; width:40px;display:flex;justify-content:center;">
                                                    <v-icon color="white">mdi-alert-circle-outline</v-icon>
                                                </div>
                                                <div class="mt-0 pb-0" v-if="report.preco != '' && report.preco != null" style="background-color: #118d0078; border-radius:5px 0px 0px 5px;height:40px; width:40px;display:flex;justify-content:center;">
                                                    <v-icon color="white">mdi-check-circle-outline</v-icon>
                                                </div>
                                            </v-row>
                                            <br>
                                            <p class="pa-8 pt-4" style="color:grey;font-size:13px;">
                                                Les préconisations permettent de référencer les défauts et actions de maintenance à réaliser sur la globalité de la machine.<br><br>
                                                Chaque sous ensemble nécéssitant une action doit y être mentionné comprenant la sévérité (code couleur) du défaut associé et l'action préconisée.
                                            </p>
                                            <p class="pa-8 pt-4" style="color:red;font-size:13px;">
                                                (*) Ce champ doit être obligatoirement renseigné pour valider ou publier ce rapport.
                                            </p>
                                        </div>
                                        <div class="mt-0 pa-3" style="width:70%">
                                            <vue-editor id="editor1" v-model="report.preco" class="col col"></vue-editor>
                                        </div>
                                    </v-row>

                                    <v-row class="pt-0 pb-8 mt-4" style="background-color: #fbfbfb;">
                                        <!-- INFORMATIONS -->
                                        <div class="mt-4 pa-3 pr-0 pt-0" style="width:30%;;border-right:1px solid rgba(0, 0, 0, 0.22)">
                                            <v-row class="ma-0 pa-0" style="justify-content: space-between;">
                                                <h4 class="pa-5 pt-2 pb-0 ml-1">Informations générales</h4>
                                                <div class="mt-0 pb-0" style="background-color: #56565678; border-radius:5px 0px 0px 5px;height:40px; width:40px;display:flex;justify-content:center;">
                                                    <v-icon color="white">mdi-information</v-icon>
                                                </div>
                                            </v-row>
                                            <br>
                                            <p class="pa-8 pt-4" style="color:grey;font-size:13px;">
                                                Les informations présentes dans cette section reprennent les données et le contexte de la mesure effectuée. 
                                            </p>
                                        </div>
                                        <div class="mt-0 pa-0" style="width:70%;">
                                            <v-row class="ma-0 pa-0 pt-7 pl-5" style="justify-content:space-between;">
                                                <v-col class="pa-0 ma-0 col-8">
                                                    <v-row class="pa-0 ma-0">
                                                        <!-- CAMPAIN INFOS -->
                                                        <v-col class="col pl-5 pr-5 pt-0" style=" color: black;">
                                                            <div class="mt-0" style="font-size: 12px;margin-left: 8px;font-weight:bold;">Informations complémentaires sur la campagne :</div>
                                                            <v-col class="mb-0 mt-0 pl-1">
                                                                <div v-for="presta in prestas" :key="presta">
                                                                    <div v-for="typex in types" :key="typex">
                                                                        <v-chip v-if="presta.id === campain.presta && typex.id === campain.type" class="ml-0 mb-0" style="font-size:12px;">{{ presta.name }} ({{ typex.name.toLowerCase().trim() }})</v-chip>
                                                                    </div>
                                                                </div>
                                                            </v-col>
                                                            <v-row class="mb-0 pa-0 mt-0"> 
                                                                <div class="mb-0 mt-0" style="height: 18px;font-size: 12px;margin-left: 22px;font-weight: bold;" @click="$router.push({ name: 'campain', params: {id: campain.id} })">Désignation : {{campain.name}}</div>
                                                                <v-icon style="cursor:pointer;"class="ml-1" @click="$router.push({ name: 'campain', params: {id: campain.id} })" color="primary" small>mdi-link-variant</v-icon>
                                                            </v-row>
                                                            <div class="mb-0 mt-0" style="height: 18px;font-size: 12px;margin-left: 10px;">Référence : {{campain.uid}} <v-icon class="ml-1" @click="copyValue(machine.uid)" color="primary" small>mdi-content-copy</v-icon></div>
                                                            <div class="mb-0 mt-0" style="height: 18px;font-size: 12px;margin-left: 10px;">Créée le : {{campain.created_at}}</div>
                                                            <div v-for="tviewer in analysts" :key="tviewer">
                                                                <div class="mb-0 mt-0" v-if="tviewer.id === campain.creator" style="height: 18px;font-size: 12px;margin-left: 10px;">Créée par : {{tviewer.first_name}} {{tviewer.last_name}}</div>
                                                            </div>
                                                            <div class="mb-0 mt-0 mb-0" style="height: 18px;font-size: 12px;margin-left: 10px;">Denière modification : {{campain.updated_at}}</div>
                                                            <div v-if="campain.closed_at != null" class="mb-0 mt-0 mb-2" style="height: 18px;font-size: 12px;margin-left: 10px;">Clôturée le : {{campain.closed_at}}</div>
                                                            <div v-if="campain.closed_at == null" class="mb-0 mt-0 mb-2" style="height: 18px;font-size: 12px;margin-left: 10px;">Campagne ouverte.</div>
                                                            <div v-for="analyst in analysts_datas" :key="analyst">
                                                                <div class="mb-0 mt-0" v-if="analyst.id === campain.manager" style="height: 18px;font-size: 12px;margin-left: 10px;font-weight: bold;">Chargé de campagne : {{analyst.name}}</div>
                                                            </div>
                                                            <div class="mb-0 mt-0" style="height: 18px;font-size: 12px;margin-left: 10px;font-weight: bold;">N° de dossier : {{campain.affaire_number}} <v-icon class="ml-1" @click="copyValue(machine.uid)" color="primary" small>mdi-content-copy</v-icon></div>
                                                            <div v-for="site in sites" :key="site">
                                                                <div class="mb-0 mt-0" v-if="site.id === campain.id_park" style="height: 18px;font-size: 12px;margin-left: 10px;font-weight: bold;">Parc machine associé : {{site.label}}</div>
                                                            </div>
                                                        </v-col>
                                                        
                                                        <!-- REPORT INFOS -->
                                                        <v-col class="col pt-0" style="margin-left:15px;">
                                                            <v-row class="" style="color: black;">
                                                                <v-col class="">
                                                                    <div class="mt-0" style="font-size: 12px;margin-left: 8px;font-weight:bold;">Informations complémentaires sur le rapport :</div>
                                                                    <v-col class="mt-3 pa-0"></v-col>
                                                                    <v-col v-for="analyst in analysts_datas" :key="analyst" class="pa-0 mb-0">
                                                                        <v-row class="mb-0 mt-0" v-if="analyst.id === report.creator" style="height: 18px;font-size: 12px;margin-left: 10px;">Référence : {{report.uid}} <v-icon class="ml-1" @click="copyValue(machine.uid)" color="primary" small>mdi-content-copy</v-icon></v-row>
                                                                        <v-row class="mb-0 mt-0" v-if="analyst.id === report.creator" style="height: 18px;font-size: 12px;margin-left: 10px;">Créé le : {{report.created_at}}</v-row>
                                                                        <v-row class="mb-0 mt-0" v-if="analyst.id === report.creator" style="height: 18px;font-size: 12px;margin-left: 10px;">Créé par : {{analyst.name}}</v-row>
                                                                        <v-row class="mb-0 mt-0" v-if="analyst.id === report.creator" style="height: 18px;font-size: 12px;margin-left: 10px;">Révisions : {{report.rev}}</v-row>
                                                                        <v-row class="mb-0 mt-0" v-if="analyst.id === report.creator" style="height: 18px;font-size: 12px;margin-left: 10px;">Dernière modification : {{report.updated_at}}</v-row>
                                                                        <v-row class="mb-0 mt-0" v-if="analyst.id === report.creator && report.released_at" style="height: 18px;font-size: 12px;margin-left: 10px;font-weight: bold;">Date de la publication : {{report.released_at}}</v-row>
                                                                    </v-col>
                                                                    <v-col class="mt-2 pa-0"></v-col>
                                                                    <v-col v-for="analyst in analysts_datas" :key="analyst" class="pa-0 mb-0 mt-0">
                                                                        <v-row class="mb-0 mt-0" v-if="report.intervention_date && analyst.id === report.intervenant" style="height: 18px;font-size: 12px;margin-left: 10px;font-weight: bold;">Date de l'intervention : {{report.intervention_date}}</v-row>
                                                                        <v-row class="mb-0 mt-0" v-if="analyst.id === report.intervenant" style="height: 18px;font-size: 12px;margin-left: 10px;font-weight: bold;">Intervenant : {{analyst.name}}</v-row>
                                                                    </v-col>
                                                                    <v-col class="mt-2 pa-0"></v-col>
                                                                    <v-col v-for="analyst in analysts_datas" :key="analyst" class="pa-0 mb-0 mt-0">
                                                                        <v-row class="mb-0 mt-0" v-if="analyst.id === report.manager" style="height: 18px;font-size: 12px;margin-left: 10px;font-weight: bold;">Manager : {{analyst.name}}</v-row>
                                                                        <v-row class="mb-0 mt-0" v-if="analyst.id === report.analyst" style="height: 18px;font-size: 12px;margin-left: 10px;font-weight: bold;">Analyste : {{analyst.name}}</v-row>
                                                                        <v-row class="mb-0 mt-0" v-if="analyst.id === report.validator" style="height: 18px;font-size: 12px;margin-left: 10px;font-weight: bold;">Validateur : {{analyst.name}}</v-row>
                                                                    </v-col>
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row class="pa-0 ma-0 mt-4">
                                                        <!-- TAGS INFOS -->
                                                        <v-col class="col pl-5 pr-5 pt-0" style=" color: black;">
                                                            <!-- TAGS -->
                                                            <div class="mt-0 mb-2" style="font-size: 12px;margin-left: 8px;font-weight:bold;">Tags associés :</div>
                                                            <!--
                                                            <validation-provider :name="$t('campain.add_name')" rules="required" v-slot="{ errors }">
                                                                <v-select
                                                                    v-model="report.tags"
                                                                    :items="report_tags"
                                                                    :error-messages="errors"
                                                                    item-value="name"
                                                                    item-text="name"  
                                                                    multiple chips disabled
                                                                    style="font-size: 12px;"
                                                                    class="custom-v-select mt-0 pt-0"
                                                                ></v-select>
                                                            </validation-provider>
                                                            -->
                                                            <v-chip-group>
                                                                <v-chip v-for="tag in report.tags" :key="tag" class="ma-1">
                                                                    {{ tag }}
                                                                </v-chip>
                                                            </v-chip-group>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-col class="pa-0 ma-0 mr-4 mt-8" style="max-width:1px;border-right:1px solid #00000014;position:relative;left:26px;"></v-col>
                                                <v-col class="pa-0 ma-0 col">
                                                    <div class="mt-0 mb-4" style="font-size: 12px;margin-left: 34px;font-weight:bold;">Historique des rapports ({{ report_nb }}) :</div>
                                                    <!-- report list -->
                                                    <div v-for="report in reversed_indices" :key="report">
                                                        <v-row class="col mb-2"> 
                                                            <div class="ma-0 pa-0" v-for="filters in machine_filters" :key="filters">
                                                                <v-row class="ma-0 pa-0" style="font-size: 12px;margin-left: 10px;align-items:center;" v-if="filters.level == report.alert_level && filters.level_name !== 'Tout voir'" :to="{ path: '/report/' + report.id}" >
                                                                    <div style="width:18px;height:12px;border-radius:10px;margin-right:15px;z-index:2;" @click="$router.push({ name: 'reported', params: {id: report.id} })" :style="{backgroundColor: filters.level_color}"></div>
                                                                    <div class="row col" @click="$router.push({ name: 'reported', params: {id: report.id} })">Rapport du {{report.released_at}} (EDS {{report.indice}}%)</div>
                                                                    <v-icon style="cursor:pointer;" class="ml-1" @click="$router.push({ name: 'reported', params: {id: report.id} })" color="primary" small>mdi-link-variant</v-icon>
                                                                </v-row>
                                                                
                                                            </div>
                                                        </v-row>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-row>
                                </v-col>
                            </v-card-text>
                        </validation-observer>
                    </v-card>

                    <!-- AUTOMATIC REPORT CONTENT-->
                    <v-card v-if="report.type === 2" class="mt-6">
                    </v-card>

                    <!-- PDF REPORT VIB OR THERMO CONTENT -->
                    <v-card v-if="report.type === 0 || report.type === 3" class="mt-6" style="margin-bottom:152px;">
                        <v-row class="pa-0 pl-3 pr-3">
                            <!--CHIPS-->
                            <div class="mt-0 pl-3 pr-0" style="width:30%">
                                <!-- uploader V2 -->
                                <v-row class="ma-0 pa-0" style="justify-content: space-between;">
                                    <h4 class="pa-5 pt-5 pb-0 ml-1">1 - Importer vos documents</h4>
                                    <div class="mt-0 pb-0 mt-2" v-if="!pdf_report_available" style="background-color: #ff5b00b0; border-radius:5px 0px 0px 5px;height:40px; width:40px;display:flex;justify-content:center;">
                                        <v-icon color="white">mdi-alert-circle-outline</v-icon>
                                    </div>
                                    <div class="mt-0 pb-0 mt-2" v-if="pdf_report_available" style="background-color: #118d0078; border-radius:5px 0px 0px 5px;height:40px; width:40px;display:flex;justify-content:center;">
                                        <v-icon color="white">mdi-check-circle-outline</v-icon>
                                    </div>
                                </v-row>
                                <v-card width="100%" class="mt-0 pr-3" elevation="0">
                                    <validation-observer v-slot="{ invalid }">
                                        <!-- Drag zone -->
                                        <v-card-text>
                                            <div class="file-input-zone-container">
                                                <div :class="['dropzone', dragging ? 'dropzone-over' : '']" @dragenter="dragging = true"
                                                    @dragleave="dragging = false"
                                                >
                                                    <div class="dropzone-info" @drag="onChange">
                                                        <span class="fa fa-cloud-upload dropzone-title"></span>
                                                        <v-icon class="mt-4" large color="primary"> mdi-cloud-download-outline</v-icon>

                                                        <div class="dropzone-upload-limit-info">
                                                            <div class="mt-4"><strong> {{ $t('documents_import.help') }}</strong></div>
                                                            <span class="mt-2">En cliquant sur ce cadre vous pouvez également</br>sélectionner le fichier que vous souhaitez importer.</span>
                                                            <span class="mt-0">Une fois vos fichiers sélectionnés cliquez sur le</br> bouton importer pour enregistrer vos documents.</span>
                                                        </div>
                                                    </div>

                                                    <input type="file" @change="onChange" accept="application/pdf" multiple>
                                                </div>
                                            </div>

                                            <div v-for="file in files" class="mt-6">
                                                <p class="uploaded-file-name pl-3 pr-3">{{ file.name }}</p>
                                                <v-icon @click="removeFile(file)" class="remove-file">mdi-close-circle-outline</v-icon>
                                            </div>
                                        </v-card-text>

                                        <!-- Validation zone + validation popup -->
                                        <v-card-actions class="pa-4 pt-0" style="width:100%;">
                                            <v-dialog transition="dialog-top-transition" v-model="dialog" width="600">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn v-bind="attrs" v-on="on" :disabled="isValid" color="primary" style="width:100%;">
                                                        Importer les documents sélectionnés
                                                    </v-btn>
                                                </template>

                                                <v-card>
                                                    <v-card-title>
                                                        Importer les documents ?
                                                    </v-card-title>

                                                    <v-divider></v-divider>

                                                    <v-container>
                                                        <v-card-text class="text-center">
                                                            Vous êtes sur le point d'enregistrer les documents sélectionnés sur notre plateforme.</br>
                                                            Etes vous spur de vouloir effectuer cette opération ?
                                                        </v-card-text>

                                                        <v-card-actions class="justify-center">
                                                            <v-btn text link class="cancel-btn" @click="dialog=false">
                                                                {{ $t('global.cancel') }}
                                                            </v-btn>
                                                            <v-btn color="primary" @click="sendFiles()">
                                                                {{ $t('global.validation') }}
                                                            </v-btn>
                                                        </v-card-actions>
                                                    </v-container>
                                                </v-card>
                                            </v-dialog>
                                        </v-card-actions>
                                    </validation-observer>
                                </v-card>

                                <!-- uploader -->
                                <div :min-height="minHeight + 'px'" elevation="2" outlined height="100%" class="mt-5 pr-3">
                                    <v-card-text class="row pr-4" >
                                        <v-row v-if="!pdf_report_available && external_link_activated" class="mb-4 mr-2 ml-2 mt-2">
                                            <v-card
                                                :class="{ 'grey lighten-2': dragover }"
                                                class="w-100" height="120"
                                                @dragenter.prevent="dragover = true"
                                                @dragleave.prevent="dragover = false"
                                                @dragover.prevent="dragover = true"
                                                @drop.prevent="onDrop($event)"
                                                elevation="2" outlined
                                            >
                                                <v-card-text class="mt-12 text-center">
                                                    {{ $t('document.put_file_to_download') }}
                                                </v-card-text>
                                            </v-card>
                                        </v-row>

                                        <v-row v-if="!pdf_report_available && external_link_activated" class="ma-2">
                                            <v-btn @click="openDialogAddExternalLink()" block color="primary">
                                                {{ $t('document.add_link_extern_document') }}
                                            </v-btn>
                                        </v-row>

                                        <v-col v-if="pdf_report_available" class="col pa-0 ml-3">
                                            <v-row>
                                                <v-col class="mb-1" style="font-weight:bold;border-bottom:1px solid #00000038;margin-left:14px;padding-bottom:1px;">
                                                    Documents enregistrés :
                                                </v-col>
                                            </v-row>

                                            <v-progress-linear color="primary" indeterminate v-if="loading"></v-progress-linear>

                                            <v-virtual-scroll :items="uploaded_files"  item-height="50" class="mt-1">
                                                <template v-slot:default="{ item }">
                                                    <v-list-item class="pr-0">
                                                        <!--
                                                        <v-list-item-content v-if="item.link">
                                                            <v-list-item-title>
                                                                <a :href="item.link" target="_blank"> {{ item.link }}</a>
                                                            </v-list-item-title>
                                                        </v-list-item-content>

                                                        <v-list-item-action v-if="item.link">
                                                            <v-btn @click.stop="removeExternalLink(item.id)" icon>
                                                                <v-icon> mdi-close-circle</v-icon>
                                                            </v-btn>
                                                        </v-list-item-action>
                                                        -->

                                                        <v-list-item-content v-if="item.file_name">
                                                            <v-list-item-title>
                                                                <a @click.stop="readDocument(item.file_name)"> {{ item.file_name }}</a>
                                                            </v-list-item-title>
                                                        </v-list-item-content>

                                                        <v-list-item-action v-if="item.file_name">
                                                            <v-btn :href="item.path" download icon>
                                                                <v-icon> mdi-arrow-down-bold-circle</v-icon>
                                                            </v-btn>
                                                        </v-list-item-action>

                                                        <v-list-item-action v-if="item.file_name" style="margin-left:0px;">
                                                            <v-btn @click.stop="removeDocument(item.file_name)" icon>
                                                                <v-icon> mdi-close-circle</v-icon>
                                                            </v-btn>
                                                        </v-list-item-action>
                                                    </v-list-item>

                                                    <v-divider></v-divider>
                                                </template>
                                            </v-virtual-scroll>
                                        </v-col>
                                    </v-card-text>

                                    <template v-if="external_link_activated">
                                        <v-dialog v-model="dialog_add" max-width="500px">
                                            <v-card>
                                                <v-card-title>
                                                    <span class="headline">{{ $t('document.add_link_extern_document') }}</span>
                                                </v-card-title>
                                                <v-divider></v-divider>

                                                <v-card-text><br/>
                                                    <v-form v-model="valid">
                                                        <v-text-field
                                                            label="URL" solo
                                                            :prepend-icon="'mdi-web'"
                                                            v-model="external_link.link"
                                                        ></v-text-field>
                                                    </v-form>
                                                </v-card-text>

                                                <v-card-actions class="justify-end">
                                                    <v-btn @click="addExternalLink()" color="primary">
                                                        <v-icon left>mdi-content-save</v-icon>
                                                        {{ $t('global.validation') }}
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>

                                        <v-snackbar
                                            v-model="snackbar"
                                            :color="snackbar_type">
                                            {{ snackbar_text }}
                                            <template v-slot:action="{ attrs }">
                                                <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                                            </template>
                                        </v-snackbar>
                                    </template>
                                </div>  
                            </div>  

                            <PDFViewer
                                id="pdf"
                                :source="report_url"
                                style="height: 79vh;max-width:70%;background-color:white;border-radius: 0px 5px 0px 5px;"
                                v-if="pdf_report_available"
                            />
                            <div v-if="!pdf_report_available" style="height: 79vh;max-width:70%;background-color:white;border-radius: 0px 5px 0px 5px;text-align: center;width: 100%;background-color: #50505030;justify-content: center;display: flex;color: #666666;padding-top: 100px;}"> Aucun document à visionner.</div>
                        </v-row>

                        <v-row class="pt-8 mb-4 ma-0">
                            <!-- REPORT PRECO -->
                            <div class="mt-4 pa-3 pr-0 pt-0" style="width:30%;border-right:1px solid rgba(0, 0, 0, 0.22)">
                                <v-row class="ma-0 pa-0" style="justify-content: space-between;">
                                    <h4 class="pa-5 pt-2 pb-0 ml-1">2 - Rédiger vos préconisations</h4>
                                    <div class="mt-0 pb-0" v-if="report.preco == '' || report.preco == null" style="background-color: #ff5b00b0; border-radius:5px 0px 0px 5px;height:40px; width:40px;display:flex;justify-content:center;">
                                        <v-icon color="white">mdi-alert-circle-outline</v-icon>
                                    </div>
                                    <div class="mt-0 pb-0" v-if="report.preco != '' && report.preco != null" style="background-color: #118d0078; border-radius:5px 0px 0px 5px;height:40px; width:40px;display:flex;justify-content:center;">
                                        <v-icon color="white">mdi-check-circle-outline</v-icon>
                                    </div>
                                </v-row>
                                <br>
                                <p class="pa-8 pt-4" style="color:grey;font-size:13px;">
                                    Les préconisations permettent de référencer les défauts et actions de maintenance à réaliser sur la globalité de la machine.<br><br>
                                    Chaque sous ensemble nécéssitant une action doit y être mentionné comprenant la sévérité (code couleur) du défaut associé et l'action préconisée.
                                </p>
                            </div>
                            <div class="mt-0 pa-3" style="width:70%">
                                <vue-editor id="editor1" v-model="report.preco" class="col col"></vue-editor>
                            </div>
                        </v-row>

                        <v-row class="pt-0 pb-8 mt-4 ma-0" style="background-color: #fbfbfb;margin-bottom: 120px;">
                            <!-- INFORMATIONS -->
                            <div class="mt-4 pa-3 pr-0 pt-0" style="width:30%;;border-right:1px solid rgba(0, 0, 0, 0.22)">
                                <v-row class="ma-0 pa-0" style="justify-content: space-between;">
                                    <h4 class="pa-5 pt-2 pb-0 ml-1">Informations générales</h4>
                                    <div class="mt-0 pb-0" style="background-color: #56565678; border-radius:5px 0px 0px 5px;height:40px; width:40px;display:flex;justify-content:center;">
                                        <v-icon color="white">mdi-information</v-icon>
                                    </div>
                                </v-row>
                                <br>
                                <p class="pa-8 pt-4" style="color:grey;font-size:13px;">
                                    Les informations présentes dans cette section reprennent les données et le contexte de la mesure effectuée. 
                                </p>
                            </div>
                            <div class="mt-0 pa-0" style="width:70%;">
                                <v-row class="ma-0 pa-0 pt-7 pl-5" style="justify-content:space-between;">
                                    <v-col class="pa-0 ma-0 col-8">
                                        <v-row class="pa-0 ma-0">
                                            <!-- CAMPAIN INFOS -->
                                            <v-col class="col pl-5 pr-5 pt-0" style=" color: black;">
                                                <div class="mt-0" style="font-size: 12px;margin-left: 8px;font-weight:bold;">Informations complémentaires sur la campagne :</div>
                                                <v-col class="mb-0 mt-0 pl-1">
                                                    <div v-for="presta in prestas" :key="presta">
                                                        <div v-for="typex in types" :key="typex">
                                                            <v-chip v-if="presta.id === campain.presta && typex.id === campain.type" class="ml-0 mb-0" style="font-size:12px;">{{ presta.name }} ({{ typex.name.toLowerCase().trim() }})</v-chip>
                                                        </div>
                                                    </div>
                                                </v-col>
                                                <v-row class="mb-0 pa-0 mt-0"> 
                                                    <div class="mb-0 mt-0" style="font-size: 12px;margin-left: 22px;font-weight: bold;" @click="$router.push({ name: 'campain', params: {id: campain.id} })">Désignation : {{campain.name}}</div>
                                                    <v-icon style="cursor:pointer;"class="ml-1" @click="$router.push({ name: 'campain', params: {id: campain.id} })" color="primary" small>mdi-link-variant</v-icon>
                                                </v-row>
                                                <div class="mb-0 mt-0" style="font-size: 12px;margin-left: 10px;">Référence : {{campain.uid}} <v-icon class="ml-1" @click="copyValue(machine.uid)" color="primary" small>mdi-content-copy</v-icon></div>
                                                <div class="mb-0 mt-0" style="font-size: 12px;margin-left: 10px;">Créée le : {{campain.created_at}}</div>
                                                <div v-for="tviewer in analysts" :key="tviewer">
                                                    <div class="mb-0 mt-0" v-if="tviewer.id === campain.creator" style="font-size: 12px;margin-left: 10px;">Créée par : {{tviewer.first_name}} {{tviewer.last_name}}</div>
                                                </div>
                                                <div class="mb-0 mt-0 mb-0" style="font-size: 12px;margin-left: 10px;">Denière modification : {{campain.updated_at}}</div>
                                                <div v-if="campain.closed_at != null" class="mb-0 mt-0 mb-2" style="font-size: 12px;margin-left: 10px;">Clôturée le : {{campain.closed_at}}</div>
                                                <div v-if="campain.closed_at == null" class="mb-0 mt-0 mb-2" style="font-size: 12px;margin-left: 10px;">Campagne ouverte.</div>
                                                <div v-for="analyst in analysts_datas" :key="analyst">
                                                    <div class="mb-0 mt-0" v-if="analyst.id === campain.manager" style="font-size: 12px;margin-left: 10px;font-weight: bold;">Chargé de campagne : {{analyst.name}}</div>
                                                </div>
                                                <div class="mb-0 mt-0" style="font-size: 12px;margin-left: 10px;font-weight: bold;">N° de dossier : {{campain.affaire_number}} <v-icon class="ml-1" @click="copyValue(machine.uid)" color="primary" small>mdi-content-copy</v-icon></div>
                                                <div v-for="site in sites" :key="site">
                                                    <div class="mb-0 mt-0" v-if="site.id === campain.id_park" style="font-size: 12px;margin-left: 10px;font-weight: bold;">Parc machine associé : {{site.label}}</div>
                                                </div>
                                            </v-col>
                                            
                                            <!-- REPORT INFOS -->
                                            <v-col class="col pt-0" style="margin-left:15px;">
                                                <v-row class="" style="color: black;">
                                                    <v-col class="">
                                                        <div class="mt-0" style="font-size: 12px;margin-left: 8px;font-weight:bold;">Informations complémentaires sur le rapport :</div>
                                                        <v-col class="mt-3 pa-0"></v-col>
                                                        <v-col v-for="analyst in analysts_datas" :key="analyst" class="pa-0 mb-0">
                                                            <v-row class="mb-0 mt-0" v-if="analyst.id === report.creator" style="font-size: 12px;margin-left: 10px;">Référence : {{report.uid}} <v-icon class="ml-1" @click="copyValue(machine.uid)" color="primary" small>mdi-content-copy</v-icon></v-row>
                                                            <v-row class="mb-0 mt-0" v-if="analyst.id === report.creator" style="font-size: 12px;margin-left: 10px;">Créé le : {{report.created_at}}</v-row>
                                                            <v-row class="mb-0 mt-0" v-if="analyst.id === report.creator" style="font-size: 12px;margin-left: 10px;">Créé par : {{analyst.name}}</v-row>
                                                            <v-row class="mb-0 mt-0" v-if="analyst.id === report.creator" style="font-size: 12px;margin-left: 10px;">Révisions : {{report.rev}}</v-row>
                                                            <v-row class="mb-0 mt-0" v-if="analyst.id === report.creator" style="font-size: 12px;margin-left: 10px;">Dernière modification : {{report.updated_at}}</v-row>
                                                            <v-row class="mb-0 mt-0" v-if="analyst.id === report.creator && report.released_at" style="font-size: 12px;margin-left: 10px;font-weight: bold;">Date de la publication : {{report.released_at}}</v-row>
                                                        </v-col>
                                                        <v-col class="mt-2 pa-0"></v-col>
                                                        <v-col v-for="analyst in analysts_datas" :key="analyst" class="pa-0 mb-0 mt-0">
                                                            <v-row class="mb-0 mt-0" v-if="report.intervention_date && analyst.id === report.intervenant" style="font-size: 12px;margin-left: 10px;font-weight: bold;">Date de l'intervention : {{report.intervention_date}}</v-row>
                                                            <v-row class="mb-0 mt-0" v-if="analyst.id === report.intervenant" style="font-size: 12px;margin-left: 10px;font-weight: bold;">Intervenant : {{analyst.name}}</v-row>
                                                        </v-col>
                                                        <v-col class="mt-2 pa-0"></v-col>
                                                        <v-col v-for="analyst in analysts_datas" :key="analyst" class="pa-0 mb-0 mt-0">
                                                            <v-row class="mb-0 mt-0" v-if="analyst.id === report.manager" style="font-size: 12px;margin-left: 10px;font-weight: bold;">Manager : {{analyst.name}}</v-row>
                                                            <v-row class="mb-0 mt-0" v-if="analyst.id === report.analyst" style="font-size: 12px;margin-left: 10px;font-weight: bold;">Analyste : {{analyst.name}}</v-row>
                                                            <v-row class="mb-0 mt-0" v-if="analyst.id === report.validator" style="font-size: 12px;margin-left: 10px;font-weight: bold;">Validateur : {{analyst.name}}</v-row>
                                                        </v-col>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                        <v-row class="pa-0 ma-0 mt-4">
                                            <!-- TAGS INFOS -->
                                            <v-col class="col pl-5 pr-5 pt-0" style=" color: black;">
                                                <!-- TAGS -->
                                                <div class="mt-0 mb-2" style="font-size: 12px;margin-left: 8px;font-weight:bold;">Tags associés :</div>
                                                <!--
                                                <validation-provider :name="$t('campain.add_name')" rules="required" v-slot="{ errors }">
                                                    <v-select
                                                        v-model="report.tags"
                                                        :items="report_tags"
                                                        :error-messages="errors"
                                                        item-value="name"
                                                        item-text="name"  
                                                        multiple chips disabled
                                                        style="font-size: 12px;"
                                                        class="custom-v-select mt-0 pt-0"
                                                    ></v-select>
                                                </validation-provider>
                                                -->
                                                <v-chip-group>
                                                    <v-chip v-for="tag in report.tags" :key="tag" class="ma-1">
                                                        {{ tag }}
                                                    </v-chip>
                                                </v-chip-group>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col class="pa-0 ma-0 mr-4 mt-8" style="max-width:1px;border-right:1px solid #00000014;position:relative;left:26px;"></v-col>
                                    <v-col class="pa-0 ma-0 col">
                                        <div class="mt-0 mb-4" style="font-size: 12px;margin-left: 34px;font-weight:bold;">Historique des rapports ({{ report_nb }}) :</div>
                                        <!-- report list -->
                                        <div v-for="report in reversed_indices" :key="report">
                                            <v-row class="col mb-2"> 
                                                <div class="ma-0 pa-0" v-for="filters in machine_filters" :key="filters">
                                                    <v-row class="ma-0 pa-0" style="font-size: 12px;margin-left: 10px;align-items:center;" v-if="filters.level == report.alert_level && filters.level_name !== 'Tout voir'" :to="{ path: '/report/' + report.id}" >
                                                        <div style="width:18px;height:12px;border-radius:10px;margin-right:15px;z-index:2;" @click="$router.push({ name: 'reported', params: {id: report.id} })" :style="{backgroundColor: filters.level_color}"></div>
                                                        <div class="row col" @click="$router.push({ name: 'reported', params: {id: report.id} })">Rapport du {{report.released_at}} (EDS {{report.indice}}%)</div>
                                                        <v-icon style="cursor:pointer;" class="ml-1" @click="$router.push({ name: 'reported', params: {id: report.id} })" color="primary" small>mdi-link-variant</v-icon>
                                                    </v-row>
                                                    
                                                </div>
                                            </v-row>
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-row>
                    </v-card>

                    <!-- SNACKBAR INFO DISPLAY -->
                    <v-snackbar
                        v-model="snackbar"
                        :color="snackbar_type">
                        {{ snackbar_text }}
                        <template v-slot:action="{ attrs }">
                            <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                        </template>
                    </v-snackbar>
                </div>
            </v-col>

            <!-- EDIT BOTTOM-->
            <div style="position: fixed; bottom: 0; z-index: 5; width: 100%; left: 0;bottom: 130px;">
                <v-col class="pr-5 col-12" style="padding-left:390px!important;">
                    <v-row class="ma-0 pa-0 col" style="align-items: center;justify-content: center;font-weight: bold;color: #0000007a;width:50px;">
                        <v-tooltip bottom dark class="mr-4">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" class="col col" type="submit" color="white" dusk="logout-button"
                                    style=" text-transform: unset !important;
                                            background-color:#00104785;
                                            border-radius: 5px;
                                            color: #FFFFFF!important;
                                            border-radius: 5px;
                                            font-size:11px;"  
                                    @click="$router.push({ name: 'reported', params: {id: report.id} })"
                                >                                        
                                   <v-icon color="white" class="ml-2" left>mdi-eye</v-icon>
                                </v-btn>
                            </template>
                            <span>Voir le rapport du point de vu client</span>
                        </v-tooltip>
                    </v-row>
                </v-col>
            </div>  

            <!-- MENU BOTTOM-->
            <div style="position: fixed; bottom: 0; z-index: 5; width: 100%; left: 0;">
                <v-col class="pl-5 pr-5 col-12" style="background-color:rgb(255 255 255); border-radius: 0px 0px 5px 5px; color: white; border-top: 1px solid #e0e0e0; padding-left: 400px!important;">
                    <!-- VALIDATION BAR document upload -->
                    <v-expand-transition>
                        <v-alert
                            v-show="alert_file_send"
                            type="success"
                            transition="fade-transition"
                            close-icon="mdi-close"
                            text dismissible
                        >
                            {{ $t('documents_import.alert_success_import') }}
                        </v-alert>
                    </v-expand-transition>

                    <v-expand-transition>
                        <v-alert
                            v-show="alert_file_error"
                            text
                            type="warning"
                            transition="fade-transition"
                            close-icon="mdi-close"
                            dismissible
                        >
                            {{ alert_message }}
                        </v-alert>
                    </v-expand-transition>
                    
                    <!-- TOP OPTIONS -->
                    <v-row class="pl-0 mb-5" style="height: 100px;align-items:center;justify-content:flex-end;padding-right:15px;">
                        <v-row class="col-5 pt-0 pb-0" style="justify-content:space-between;border-right:1px solid #e0e0e0;">
                            <!-- PLUS BUTTON -->
                            <v-col v-if="infoToggle2 == false" class="col-0" style="display:flex;align-items:flex-end; justify-content: flex-end; margin-bottom: 6px;max-width: 35px;align-items: center;">
                                <v-tooltip bottom dark class="mr-2">
                                    <template v-slot:activator="{ on }">
                                        <v-btn text icon v-on="on" @click="infoToggler2()" class="mr-2" dusk="logout-button" style="border: 1px solid black;">
                                            <v-icon color="black">mdi-chevron-up</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Déplier</span>
                                </v-tooltip>
                            </v-col>

                            <!-- MOINS BUTTON -->
                            <v-col v-if="infoToggle2 == true" class="col-0" style="display:flex;align-items:flex-end; justify-content: flex-end; margin-bottom: 6px;max-width: 35px;align-items: center;">
                                <v-tooltip bottom dark class="mr-2">
                                    <template v-slot:activator="{ on }">
                                        <v-btn text icon v-on="on" @click="infoToggler2()" class="mr-2" dusk="logout-button" style="border: 1px solid black;">
                                            <v-icon color="black">mdi-chevron-down</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Replier</span>
                                </v-tooltip>
                            </v-col>

                            <!-- REPORT TOP INFOS -->
                            <v-col class="col" style="justify-content:flex-start">
                                <v-row class="pt-5 pb-3 pr-1 pl-2" style="color: black;">
                                    <v-col class="pt-5 pb-5 pl-0">
                                        <v-row v-for="step in report_steps" :key="step" class="row pa-0">
                                            <v-row class="mb-1 mt-1 ml-2 mr-0" v-if="step.id === report.step" style="min-width: 10px;max-width: 10px;min-height: 10px;max-height: 10px;position:relative;top:5px;border-radius:25px;" :style="{backgroundColor: step.level_color}"></v-row>
                                            <v-row class="mb-1 mt-1 ml-2" v-if="step.id === report.step" style="font-size:12px;font-weight: bold;font-size:14px;">{{step.name}}</v-row>
                                        </v-row>   
                                        
                                        <v-col v-for="analyst in analysts" :key="analyst" class="pa-0">
                                            <v-row class="mb-0 mt-2 ml-2" v-if="analyst.id === report.analyst" style="font-size:14px;margin-left:15px!important;margin-top:3px;">Par {{analyst.first_name}} {{analyst.last_name}}</v-row>
                                            <v-row class="mb-1 mt-0 ml-2" v-if="analyst.id === report.analyst" style="font-size:12px;margin-left:15px!important;">{{report.updated_at}}</v-row>
                                        </v-col>
                                    </v-col>
                                </v-row>
                            </v-col>

                            <!-- CAMPAIN TOP INFOS -->
                            <v-col class="col-5 pl-0" style="justify-content:flex-start">
                                <v-row class="pt-5 pb-3 pr-1 pl-0" style="color: black;">
                                    <v-col class="pt-5 pb-5">
                                        <v-row :key="step" class="row pa-0">
                                            <v-row class="mb-1 mt-1 ml-2" style="font-size:12px;font-weight: bold;font-size:14px;">Campagne associée</v-row>
                                        </v-row>   
                                        
                                        <v-col class="pa-0">
                                            <div class="row"><v-row class="mb-0 mt-1 ml-2" :to="{ path: '/campain/' + campain.id}" color="primary" style="font-size:14px;margin-left:5px;margin-top:3px;">{{campain.name}}</v-row> <v-icon class="ml-1" @click="$router.push({ name: 'campain', params: {id: campain.id} })" color="primary" small>mdi-link-variant</v-icon></div>
                                        </v-col>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                        <v-row class="col" style="justify-content:flex-end;">
                            <!-- REPORT TYPE -->
                            <v-col class="row mb-0 pa-0 mt-0 col-5 ml-7" style="display: flex; align-items: flex-start;">
                                <validation-provider :name="$t('campain.add_name')" rules="required" v-slot="{ errors }" style="width:100%">
                                    <v-select
                                        label="Type et format du rapport"
                                        v-model="report.type"
                                        :items="report_types"
                                        :prepend-icon="'mdi-chart-box'"
                                        :error-messages="errors"
                                        item-value="id"
                                        item-text="name"
                                        underlined
                                        style="font-size: 14px; font-weight: 800;"
                                        class="custom-v-select full-width-text"
                                    ></v-select>
                                </validation-provider>
                                <!-- FOLLOWER -->
                            </v-col>
                            
                            <!-- HEALTH -->
                            <v-col class="row mb-0 pa-0 mt-0 col" style="display: flex; align-items: flex-start;margin-left:48px;">
                                <validation-provider :name="$t('campain.add_name')" rules="required" v-slot="{ errors }" style="width:100%">
                                    <v-select
                                        label="Etat de santé"
                                        v-model="report.alert_level"
                                        :items="machine_filters"
                                        :prepend-icon="'mdi-speedometer'"
                                        :error-messages="errors"
                                        item-value="level"
                                        item-text="level_name" 
                                        underlined 
                                        style="font-size: 14px;"
                                        class="custom-v-select full-width-text"
                                    >
                                        <template slot="selection" slot-scope="data">
                                            <!-- HTML that describe how select should render selected items -->
                                            <v-col>
                                                <div>{{ data.item.annotation }}</div>
                                                <div style="font-size:12px;">{{ data.item.level_name }}</div>
                                            </v-col>
                                        </template>
                                        <template slot="item" slot-scope="data">
                                            <!-- HTML that describe how select should render items when the select is open -->
                                            <v-col>
                                                <div><strong>{{ data.item.annotation }}</strong></div>
                                                <div style="font-size:12px;">{{ data.item.level_name }}</div>
                                            </v-col>
                                        </template>
                                    </v-select>
                                </validation-provider>
                            </v-col>

                            <!-- BUTTONS -->
                            <v-col class="col-0 mt-1 mb-5" style="max-width: 70px;margin-left:40px;">
                                <!-- Bouton sauvegarder -->
                                <v-card-actions v-if="report.step <= 1 || report.step == 4 || (access_level <= 95 && report.step == 6)" class="pa-1 col col" style="display:flex;justify-content:center">
                                    <v-tooltip bottom dark class="mr-4">
                                        <template v-slot:activator="{ on }">
                                            <v-btn text icon v-on="on" class="col col" type="submit" color="primary" @click.prevent="updateReport()">
                                                <v-icon left>mdi-content-save</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Enregistrer</span>
                                    </v-tooltip>
                                </v-card-actions>

                                <!-- Bouton sauvegarder disabled -->
                                <v-card-actions v-else class="pa-1 col col" style="display:flex;justify-content:center">
                                    <v-tooltip bottom dark class="mr-4">
                                        <template v-slot:activator="{ on }">
                                            <v-btn text icon v-on="on" class="col col" type="submit" color="grey" disabled>
                                                <v-icon left>mdi-content-save</v-icon>
                                            </v-btn>

                                            <v-icon v-on="on" class="col col" color="grey" left>mdi-content-save</v-icon>
                                        </template>
                                        <span>Pour enregistrer penser à mettre une date d'intervention</span>
                                    </v-tooltip>
                                </v-card-actions>
                            </v-col>
                        </v-row>
                    </v-row>

                    <!-- TOGGLE -->
                    <v-row v-if="infoToggle2 == true" style="min-height: 250px; border-radius:5px 12px;padding-right:15px;">
                        
                        <v-row class="col-5 mt-0 pt-0 pr-0">
                            <!-- BUTTONS -->
                            <v-row class="col-1 mt-0 mb-0 pt-0 ml-0" style="min-width: 100%;margin-left:0px;border-right:1px solid #e0e0e0;position:relative;top:-8px;padding-left:55px;">
                                <!-- Bouton afficher la visualisation du rapport -->
                                <v-card-actions v-if="access_level <= 100" class="pa-1 col col" style="display:flex;justify-content:center;max-width:45px; margin-right: 20px;">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn text icon v-on="on" class="col col" type="submit" :disabled="invalid" style="color: #FFFFFF;background-color: #00104785;border:1px solid #001047!important"
                                            @click="$router.push({ name: 'reported', params: {id: report.id} })">
                                                <v-icon center style="font-size:24px; padding-top:2px;">mdi-eye</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Voir le rapport du point de vu client</span>
                                    </v-tooltip>
                                </v-card-actions>
                                
                                <!-- Bouton demander la validation -->
                                <v-card-actions v-if="access_level <= 100 && (report.step == 1 || report.step == 4)" class="pa-1 col col" style="display:flex;justify-content:center;max-width:45px;">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn text icon v-on="on" class="col col" type="submit" :disabled="invalid" style="color: #017f00;background-color: #a3f182b3;border:1px solid #017f009e!important"
                                            @click.prevent="setReportState(report, 1)">
                                                <v-icon center style="font-size:24px;">mdi-book-clock-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Demander une validation</span>
                                    </v-tooltip>
                                </v-card-actions>

                                <!-- Bouton relancer l'édition -->
                                <v-card-actions v-if="access_level <= 100 && access_level >= 96 && report.step == 2" class="pa-1 col col" style="display:flex;justify-content:center;max-width:45px;">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn text icon v-on="on" class="col col" type="submit" :disabled="invalid" style="color: #930000;background-color: #d7000063;border:1px solid #d700005e!important"
                                            @click.prevent="setReportState(report, 2)">
                                                <v-icon center style="font-size:28px;">mdi-book-clock-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Relancer l'édition</span>
                                    </v-tooltip>
                                </v-card-actions>

                                <!-- Bouton refuser la validation -->
                                <v-card-actions v-if="access_level <= 97 && report.step == 2" class="pa-1 col col" style="display:flex;justify-content:center;max-width:45px;">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn text icon v-on="on" class="col col" type="submit" :disabled="invalid" style="color: #9f4300;background-color: #e5b673;border:1px solid #a77d62 !important"
                                            @click.prevent="setReportState(report, 4)">
                                                <v-icon center style="font-size:24px;">mdi-book-cancel-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Refuser la validation</span>
                                    </v-tooltip>
                                </v-card-actions>

                                <!-- Bouton valider -->
                                <v-card-actions v-if="access_level <= 97 && (report.step == 1 || report.step == 2 || report.step == 4)" class="pa-1 col col" style="display:flex;justify-content:center;max-width:45px;">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn text icon v-on="on" class="col col" type="submit" :disabled="invalid" style="color: #017f00;background-color: #a3f182b3;border:1px solid #017f009e !important"
                                            @click.prevent="setReportState(report, 3)">
                                                <v-icon center style="font-size:24px;">mdi-book-check-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Valider le rapport</span>
                                    </v-tooltip>
                                </v-card-actions>

                                <!-- Bouton annuler la validation -->
                                <v-card-actions v-if="access_level <= 97 && report.step == 3" class="pa-1 col col" style="display:flex;justify-content:center;max-width:45px;">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn text icon v-on="on" class="col col" type="submit" :disabled="invalid" style="color: #930000;background-color: #d7000063;border:1px solid #d700005e !important"
                                            @click.prevent="setReportState(report, 5)">
                                                <v-icon center style="font-size:24px;">mdi-book-check-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Annuler la validation</span>
                                    </v-tooltip>
                                </v-card-actions>

                                <!-- Bouton publier -->
                                <v-card-actions v-if="(access_level <= 100 && report.step == 3) || (access_level <= 95 && report.step == 6)" class="pa-1 col col" style="display:flex;justify-content:center;max-width:45px;">
                                    <v-tooltip v-if="selectedDate" bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn text icon v-on="on" class="col col" type="submit" :disabled="!selectedDate" style="color: white;background-color: #0e4cef;border: 1px solid #0e4cef !important;"
                                            @click.prevent="setReportState(report, 6)">
                                                <v-icon center style="font-size:24px;">mdi-book-play-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Publier le rapport</span>
                                    </v-tooltip>
                                </v-card-actions>

                                <!-- Bouton retravailler -->
                                <v-card-actions v-if="access_level <= 100 && report.step == 5" class="pa-1 col col" style="display:flex;justify-content:center;max-width:45px;">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn text icon v-on="on" class="col col" type="submit" :disabled="invalid" style="color: #930000;background-color: #d7000063;border:1px solid #d700005e !important"
                                            @click.prevent="setReportState(report, 7)">
                                                <v-icon center style="font-size:24px;">mdi-book-cog-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Retravailler le rapport</span>
                                    </v-tooltip>
                                </v-card-actions>
                            </v-row>
                            
                            <v-row class="ma-0 pa-0" style="justify-content:space-between;border-right:1px solid #e0e0e0;height:100%;">
                                <!-- REPORT INFOS -->
                                <v-col class="col" style="margin-left:33px;">
                                    <v-row class="pt-0 pb-0 pr-1 pl-1" style="color: black;">
                                        <v-col class="pt-0 pb-0 mb-6">
                                            <div class="mt-0" style="font-size: 12px;margin-left: 8px;font-weight:bold;">Informations complémentaires :</div>
                                            <v-col v-for="analyst in analysts_datas" :key="analyst" class="pa-0 mb-0">
                                                <v-row class="mb-0 mt-5 ml-2" v-if="analyst.id === report.creator" style="font-size: 12px;margin-left: 10px;">Ref - {{report.uid}}</v-row>
                                                <v-row class="mb-1 mt-0 ml-2" v-if="analyst.id === report.creator" style="font-size: 12px;margin-left: 10px;">Révisions : {{report.rev}}</v-row>
                                                <v-row class="mb-1 mt-0 ml-2" v-if="analyst.id === report.creator" style="font-size: 12px;margin-left: 10px;">Date de création : {{report.created_at}}</v-row>
                                                <v-row class="mb-1 mt-0 ml-2" v-if="report.intervention_date && analyst.id === report.creator" style="font-size: 12px;margin-left: 10px;font-weight: bold;">Date d'intervention : {{report.intervention_date}}</v-row>
                                                <v-row class="mb-1 mt-0 ml-2" v-if="report.released_at && analyst.id === report.creator" style="font-size: 12px;margin-left: 10px;font-weight: bold;">Date de publication : {{report.released_at}}</v-row>
                                                <v-row class="mb-1 mt-0 ml-2" v-if="analyst.id === report.creator" style="font-size: 12px;margin-left: 10px;">Créateur : {{analyst.name}}</v-row>
                                            </v-col>
                                            <v-col v-for="analyst in analysts_datas" :key="analyst" class="pa-0 mb-0">
                                                <v-row class="mb-1 mt-0 ml-2" v-if="analyst.id === report.manager" style="font-size: 12px;margin-left: 10px;font-weight: bold;">Manager : {{analyst.name}}</v-row>
                                                <v-row class="mb-1 mt-0 ml-2" v-if="analyst.id === report.intervenant" style="font-size: 12px;margin-left: 10px;font-weight: bold;">Intervenant : {{analyst.name}}</v-row>
                                                <v-row class="mb-1 mt-0 ml-2" v-if="analyst.id === report.analyst" style="font-size: 12px;margin-left: 10px;font-weight: bold;">Analyste : {{analyst.name}}</v-row>
                                                <v-row class="mb-1 mt-0 ml-2" v-if="analyst.id === report.validator" style="font-size: 12px;margin-left: 10px;font-weight: bold;">Validateur : {{analyst.name}}</v-row>
                                            </v-col>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                
                                <!-- CAMPAIN INFOS -->
                                <v-col class="col pl-5 pr-5 pt-0" style=" color: black;">
                                    <v-col class="col col pa-0 pb-1 ma-0 mt-0 mb-0" style="position:relative;top:-6px;">
                                        <div v-for="presta in prestas" :key="presta">
                                            <div v-for="typex in types" :key="typex">
                                                <v-chip v-if="presta.id === campain.presta && typex.id === campain.type" class="ml-0 mb-0" style="font-size:12px;">{{ presta.name }} ({{ typex.name.toLowerCase().trim() }})</v-chip>
                                            </div>
                                        </div>
                                    </v-col>
                                    <div style="font-size: 12px;margin-left: 10px;">Ref - {{campain.uid}}</div>
                                    <div style="font-size: 12px;margin-left: 10px;">Créée le : {{campain.created_at}}</div>
                                    <div v-for="tviewer in analysts" :key="tviewer">
                                        <div v-if="tviewer.id === campain.creator" style="font-size: 12px;margin-left: 10px;">Créée par : {{tviewer.first_name}} {{tviewer.last_name}}</div>
                                    </div>
                                    <div v-for="analyst in analysts_datas" :key="analyst">
                                        <div v-if="analyst.id === campain.manager" style="font-size: 12px;margin-left: 10px;margin-top:15px;font-weight: bold;">Chargé de campagne : {{analyst.name}}</div>
                                    </div>
                                    <div style="font-size: 12px;margin-left: 10px;font-weight: bold;">N° de dossier : {{campain.affaire_number}}</div>
                                    <div v-for="site in sites" :key="site">
                                        <div v-if="site.id === campain.id_park" style="font-size: 12px;margin-left: 10px;font-weight: bold;">Parc machine associé : {{site.label}}</div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-row>

                        <v-row class="col mt-0 pt-0" style="justify-content:flex-end">
                            <!-- MORE INFOS -->
                            <v-col class="col pt-0">
                                <v-row class="mt-2">
                                    <v-col class="col ml-4 mr-0 pr-5">
                                        <!-- Presta -->
                                        <validation-provider :name="$t('campain.add_name')" rules="required" v-slot="{ errors }">
                                            <v-select
                                                label="(*) Type d'intervention"
                                                v-model="report.presta"
                                                :items="prestas"
                                                :prepend-icon="'mdi-chart-box'"
                                                variant="outlined"
                                                :error-messages="errors"
                                                item-value="id"
                                                item-text="name" underline
                                                style="font-size: 12px; "
                                                class="custom-v-select"
                                            ></v-select>
                                        </validation-provider>
                                        <!-- Interlocuteur -->
                                        <validation-provider :name="$t('campain.add_name')" rules="required" v-slot="{ errors }">
                                            <v-text-field
                                                label="(*) Interlocuteur chez le client"
                                                v-model="report.interlocutor"
                                                :prepend-icon="'mdi-account-tie'"
                                                variant="outlined"
                                                :error-messages="errors"
                                                style="font-size: 12px;"
                                            ></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col class="col">
                                        <!-- Intervenant-->
                                        <validation-provider :name="$t('campain.add_name')" rules="required" v-slot="{ errors }">
                                            <v-select
                                                label="(*) Intervenant sur site"
                                                v-model="report.intervenant"
                                                :items="analysts_datas"
                                                :prepend-icon="'mdi-account-hard-hat'"
                                                variant="outlined"
                                                :error-messages="errors"
                                                item-value="id"
                                                item-text="name" underlined
                                                style="font-size: 12px;"
                                                class="custom-v-select"
                                            ></v-select>
                                        </validation-provider>
                                        <!-- Date de mesures -->
                                        <v-menu
                                            ref="menu"
                                            v-model="menu"
                                            :close-on-content-click="true"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    v-model="dateRangeText"
                                                    label="(*) Date de l'intervention"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="selectedDate"
                                                @input="saveDate"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <!-- BUTTONS -->
                                    <v-col class="col-0 mt-1 mb-5" style="max-width: 30px;margin-left:55px;">
                                        <!-- Bouton sauvegarder -->
                                        <v-card-actions v-if="(access_level <= 99 && (report.step <= 1 || report.step == 4)) || (access_level <= 95 && report.step == 6)" class="pa-1 col col" style="display:flex;justify-content:center">
                                            <v-tooltip bottom dark class="mr-4">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn text icon v-on="on" class="col col" type="submit" color="white">
                                                        <v-icon left color="white">mdi-content-save</v-icon>
                                                    </v-btn>
                                                </template>
                                            </v-tooltip>
                                        </v-card-actions>
                                    </v-col>
                                </v-row> 
                                <v-row class="mt-0">
                                    <v-col class="col pt-8">
                                        <!-- TAGS -->
                                        <validation-provider :name="$t('campain.add_name')" rules="required" v-slot="{ errors }">
                                                <v-select
                                                    label="Tags"
                                                    v-model="report.tags"
                                                    :items="report_tags"
                                                    :prepend-icon="'mdi-tag'"
                                                    :error-messages="errors"
                                                    item-value="name"
                                                    item-text="name" 
                                                    underlined 
                                                    multiple chips clearable
                                                    style="font-size: 12px;"
                                                    class="custom-v-select ml-4 pr-16 mr-6 mt-0 pt-0"
                                                ></v-select>
                                        </validation-provider>
                                    </v-col>
                                </v-row> 
                            </v-col>
                        </v-row>
                    </v-row>
                </v-col>
            </div>  
        </v-form>

        
    </v-col>
</template>

<script>
import {CAMPAIN,MACHINES,REPORT,USERS,SITES,DOCUMENTS, EXTERNAL_LINK} from "../../../api";
import moment from "moment";
import Line from "./components/Line.vue";
import {api as fullscreen} from 'vue-fullscreen';
import PDFViewer from 'pdf-viewer-vue/dist/vue2-pdf-viewer'
import VirtualDeviceLocation from '../../components/VirtualDevice/VirtualDeviceLocation.vue';
import { VueEditor, Quill } from "vue2-editor";

// Machine menu package //
import MachineSpecifications from "../../components/Machine/MachineSpecifications152";
import MachineState from "../../components/Machine/MachineState152";
import MachineSubassemblies from "../../components/Machine/MachineSubassemblies152";

const authorizedTypes = [
    {name: 'png', value: 'image/png'},
    {name: 'jpg', value: 'image/jpeg'},
    {name: 'pdf', value: 'application/pdf'}
];

export default {
    name: "CampainVision",
    components:{
        Line,
        PDFViewer,
        VirtualDeviceLocation,
        VueEditor,
        MachineSpecifications,      // Machine menu package //
        MachineState,               // Machine menu package //
        MachineSubassemblies,       // Machine menu package //
    },
    props: {
        minHeight: {
            type: Number,
            required: false,
            default: 350
        },
    },
    data() {
        this.authorizedTypes = authorizedTypes;
        return {
            selectedDate: null,
            infoToggle2: false, // bottom menu //
            infoToggle: false, // Machine menu package //
            label: '', // Machine menu package //
            // CAMPAINS ----------------
            campain_id:'',
            raw_campain: [],
            campain: [],
            // MACHINE ------------------
            machine_id:'',
            raw_machine: [],
            machine: [],
            parentType:"report",
            parentId:this.$route.params.id,
            // Other infos ------------------
            analysts: [],
            sites:[],
            // REPORT ----------------------
            raw_report:[],
            report_url:"",
            report_nb:0,
            reversed_indices:0,
            previous_indice:-1,
            previous_date:"",
            report_position:0,
            report: {
                id: null,
                uid:'',
                id_campain:'',
                id_machine:'',
                creator:'',
                manager:'',
                validator:'',
                type:'',
                step:'',
                alert_level:'',
                indice:'',
                tags:[], 
                trash:'',
                context: '',
                comment: '',
                preco:'',
                defaults: [],
                images:[],
                created_at:'',
                updated_at:'',
                released_at:'',
                intervenant:'',  
                intervention_date:'',  
                interlocutor:'',
                presta:'',               
            },
            machine_filters: [
                { level: -1, level_name: "Rapport en attente", level_color: "grey", count:0, annotation: "Etat à définir"},
                { level: 1, level_name: "Pour information : EDS 100% (vert)", level_color: "#4CAF50", count:0, percent: 100, annotation: "Etat neuf" },
                { level: 2, level_name: "Pour information : EDS 90% (vert)", level_color: "#4CAF50", count:0, percent: 90, annotation: "Comportement vibratoire correct" },
                { level: 3, level_name: "Pour information : EDS 80% (vert)", level_color: "#4CAF50", count:0, percent: 80, annotation: "Pour information" },
                { level: 11, level_name: "A suivre : EDS 70% (jaune)", level_color: "#FFCB05", count:0, percent: 70, annotation: "A suivre lors de la prochaine campagne" },
                { level: 12, level_name: "A suivre : EDS 60% (jaune)", level_color: "#FFCB05", count:0, percent: 60, annotation: "A suivre de manière rapprochée" },
                { level: 13, level_name: "A suivre : EDS 50% (jaune)", level_color: "#FFCB05", count:0, percent: 50, annotation: "Expertise complémentaire préconisée" },
                { level: 21, level_name: "Importance élevée : EDS 40% (rouge)", level_color: "#F44336", count:0, percent: 40, annotation: "Action à prévoir au prochain arrêt technique" },
                { level: 22, level_name: "Importance élevée : EDS 30% (rouge)", level_color: "#F44336", count:0, percent: 30, annotation: "Action à prévoir au prochain arrêt technique" },
                { level: 23, level_name: "Importance élevée : EDS 20% (rouge)", level_color: "#F44336", count:0, percent: 20, annotation: "Action à prévoir au prochain arrêt technique" },
                { level: 31, level_name: "Urgence : EDS 10% (Noir)", level_color: "black", count:0, percent: 10, annotation: "Action à prévoir dans les plus brefs délais" },
                { level: 32, level_name:"Urgence : EDS 0% (Noir)", level_color: "black", count:0, percent: 0, annotation: "Arrêt de la machine recommandé" },
            ],
            prestas:[
                {id:0, name:"Mesures vibratoires"},
                {id:1, name:"Thermographie"},
                {id:2, name:"Mesures ultrasons"},
                {id:3, name:"Equilibrages"},
                {id:4, name:"Expertise avancée"},
                {id:5, name:"Surveillance Online"},
            ],
            types:[
                {id:0, name:"Ponctuel"},
                {id:1, name:"Annuel"},
                {id:2, name:"Semestriel"},
                {id:3, name:"Quadrmestriel"}, 
                {id:4, name:"Trimestriel"},
                {id:5, name:"Continu"},
            ],
            report_types:[
                {id:0, name:"Rapport au format PDF"},
                {id:1, name:"Rapport au format digital"},
                {id:2, name:"Rapport généré par les algorithmes"},
            ],
            report_steps:[
                {id:0, name:"En attente", level_color: "grey"},
                {id:1, name:"En cours d'édition",level_color: "#ef780e"},
                {id:2, name:"En attente de validation",level_color: "#d40eef"},
                {id:3, name:"Validé",level_color: "#089d2d"},
                {id:4, name:"En cours de rectification",level_color: "#d40eef"},
                {id:5, name:"Livré",level_color: "#0e4cef"},
                {id:6, name:"En cours de rectification (post-livraison)",level_color: "#d40eef"},
            ],
            report_tags:[
                {id:1, name:"Rapport préliminaire"},
                {id:2, name:"Expertise avancée"},
                {id:3, name:"Expertise ODS"},
                {id:4, name:"Expertise Ultrasons"},
                {id:5, name:"Installation électrique"},
                {id:6, name:"Moyen particulier"},
                {id:7, name:"Contre visite"},
                {id:8, name:"Défauts de roulement"},
                {id:9, name:"Défauts BF"},
                {id:10, name:"Défauts HF"},
                {id:11, name:"Défauts électriques"},
            ],
            analysts_datas:[
                {id:40,  name:"Eric Quenneville", tel:"+33 6 38 38 11 18", email:"eric.quenneville@techview.fr", logo:"https://techview.fr/wp-content/uploads/2024/10/ERIC-QUENNEVILLE-CAT-III-e1725528314226-removebg-preview-2.png"},
                {id:121, name:"Pascal Ardoin",    tel:"+33 6 74 35 31 13", email:"pascal.ardoin@techview.fr", logo:"https://techview.fr/wp-content/uploads/2024/09/PASCAL-ARDOIN-CAT-II-e1725542858336.png"},
                {id:91,  name:"Amandine Prieur",  tel:"+33 7 87 58 28 98", email:"amandine.prieur@techview.fr", logo:"https://techview.fr/wp-content/uploads/2024/12/572e2dac-42b9-46f8-a7d5-995f87877850.png"},
                {id:278, name:"Louis Blaise",  tel:"+33 6 77 33 08 68", email:"louis.blaise@techview.fr"},
                {id:216, name:"Guillaume Maury",  tel:"+33 6 07 46 48 40", email:"guillaume.maury@techview.fr"},
                {id:79,  name:"Sébastien Poudenx",  tel:"+33 6 68 42 45 15", email:"sebastien.poudenx@techview.fr"},
            ],
            lineChartOptions: {
                title: {text: '(Indice) - Evolution de l\'état de santé machine'},
                time: {timezone: 'Europe/Paris',},
                lang: {noData: this.$t('three_dimensional_curve.data_loading_error')},
                height:140,
                noData: {
                    style: {
                        fontWeight: 'bold',
                        fontSize: '15px',
                        color: '#303030'
                    }
                },
                loading: {hideDuration: 1000,showDuration: 1000},
                scrollbar: {
                    liveRedraw: false,
                    height: 17,
                    barBackgroundColor: '#cfcfcf',
                    barBorderRadius: 9,
                    buttonBackgroundColor: '#c9c9c9',
                    buttonArrowColor: 'white',
                    buttonBorderWidth: 3,
                    buttonBorderRadius: 11,
                    rifleColor: 'white',
                    trackBorderRadius: 9
                },
                rangeSelector: {enabled: false,},
                updateArgs: [true, true, true],
                yAxis: {
                    title: {
                        text: 'Niveau de santé (%)',
                    },
                    plotBands: [],
                },
                plotOptions: {
                    line: {
                        connectNulls: true,
                    },
                    series: {
                        marker: {
                            enabled: true,
                            radius: 4
                        }
                    }
                },
                series: [{
                    name: 'Etat de santé',
                    data: []
                }],
                credits: {
                    enabled: false
                },
            },

            // GLOBAL --------------------
            access_level: this.$store.state.access_level,
            id: this.$route.params.id,
            loading_datatable:true,

            // Uploads V2
            alert_file_send: false,
            alert_file_error: false,
            alert_message: '',
            professionals: [],
            selected_professional: '',
            professionals_load: false,
            contracts: [],
            selected_contract: '',
            contracts_load: false,
            files: [],
            dragging: false,
            dialog: false,

            // Uploads
            dragover: false,
            files_to_upload: [],
            uploaded_files: [],
            loading: true,
            external_link: {
                id_relatable: this.$route.params.id,
                type_relatable: 'report',
                link: ''
            },
            external_links: [],
            dialog_add: false,
            valid: true,
            result: [],
            pdf_report_available:false,
            external_link_activated:false,

            // Uploads (img)
            img_dragover: false,
            img_files_to_upload: [],
            img_uploaded_files: [],
            img_loading: true,
            img_external_link: {
                id_relatable: this.$route.params.id,
                type_relatable: 'report_images',
                link: ''
            },
            img_external_links: [],
            img_result: [],
            img_available:false,
            img_urls:[],
        }
    },
    computed: {
        dialogTitle() {
            return this.edited_index === null ? this.$t('campain.add') : this.$t('campain.edit');
        },
        isValid() {
            let isValid = false
            if (this.files.length > 0 ) {
                isValid = true
            }
            return !isValid
        },
        dateRangeText() {
            return this.selectedDate ? this.selectedDate : "Sélectionner une date";
        },
    },
    created() {
        moment.locale('fr');
        this.initialize();
        this.getExternalLinks();
        this.getDocuments();
        this.img_getDocuments();
        this.setMetricThresholds();
    },
    methods: {
        // STARTING PROCESS
        initialize() {
            this.loading_datatable = true;
            this.getData();
        },
        indiceReturned() {
            let new_indice =  this.machine_filters.find(filter => filter.annotation == this.health_state);
            return new_indice.percent;
        },

        // COLLECTING ALL NEEDED DATAS
        getData() {
            // GETTING CAMPAINS
            this.axios.get(REPORT + "/specific/" + this.id)
            
            .then((success) => {
                this.raw_report = success.data;
                this.report = success.data;

                if (this.report.id != 0 && this.report.id != null && this.report.id != ""){
                    this.report_available = true;

                    this.report.tags = JSON.parse(this.report.tags);
                    this.report.created_at = moment(this.report.created_at).format("DD/MM/YYYY");
                    this.report.updated_at = moment(this.report.updated_at).fromNow();
                    this.report.released_at = moment(this.report.released_at).format("DD/MM/YYYY");
                    this.campain_id = this.report.id_campain;
                    this.machine_id = this.report.id_machine;

                }else{
                    this.report_available = false; 
                }

            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");

            }).finally(() => {
                this.getSideDatas();
                this.getIndices();
                this.loading_datatable = false;
            })
        },
        getSideDatas(){
            if (this.report){
                this.getCampain();
                this.getMachine();
                this.getAnalysts();
                this.getSite();
            }
        },
        getCampain(){
            this.axios.get(CAMPAIN + '/specific/' + this.campain_id )
            
            .then((success) => {
                success.data.forEach(item => {
                    item.created_at = moment(item.created_at).format("DD/MM/YYYY");
                    item.closed_at = moment(item.closed_at).format("DD/MM/YYYY");
                    item.updated_at = moment(item.updated_at).fromNow();
                })
                
                this.campain = [];
                this.campain = success.data[0];

            }).catch(() => {
                this.showSnackbar('error', "Side datas error.");
            })
        },
        getMachine() {
            this.axios.get(MACHINES + '/specific/' + this.machine_id)
            
            .then((success) => {
                this.machine = [];
                this.machine = success.data[0];

            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");
            })
        },
        getAnalysts(){
            this.axios.get(USERS + '/team')
            
            .then((success) => {
                this.analysts = [];
                this.analysts = success.data;

            }).catch(() => {
                this.showSnackbar('error', "Analysts datas error.");
            })
        },
        getSite() {
            this.axios.get(SITES + '/resume')
            
            .then((success) => {
                this.sites = [];
                this.sites = success.data;

            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");
            })
        },
        getIndices() {
            // GETTING CAMPAINS
            this.axios.get(REPORT + "/machines_indices/" + this.report.id_machine)
            
            .then((success) => {
                this.raw_report_indices = [];
                this.report_indices = [];
                this.reversed_indices = [];
                this.report_series = [];
                this.report_nb = 0;
                this.previous_indice = -1;
                this.previous_date = "";
                this.report_position = 0;

                if (typeof success.data[0] !== 'undefined'){
                    this.report_indices_available = true;
                    this.raw_report_indices = success.data;

                    success.data.forEach(report => {
                        this.report_series.push(report.indice);
                    })
                    
                    // Generate previous health indice
                    this.report_nb = this.raw_report_indices.length;
                    this.reversed_indices = this.raw_report_indices;
                    this.reversed_indices.reverse();
                    let actual_flag = false;
                    let pos = this.report_nb;

                    this.reversed_indices.forEach(item => {                       
                        if(actual_flag == true){
                            this.previous_indice = item.indice;
                            this.previous_date = moment(item.released_at).format("DD/MM/YYYY");
                            actual_flag = false;
                        }
                        
                        if (item.id == this.report.id){
                            actual_flag = true;
                            this.report_position = pos;
                        }
                        pos = pos -1;
                    })
                    
                    this.lineChartOptions.series[0].data = this.report_series;

                }else{
                    this.report_indices_available = false;
                }
            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");

            })
        },
        setMetricThresholds() {
            this.lineChartOptions.yAxis.plotBands = [];
            this.lineChartOptions.yAxis.plotBands.push(
                {
                    from: 100,
                    to: 75,
                    color: 'rgba(76, 175, 80, 0.25)',
                    label: {
                        text: 'Etat de santé convenable'
                    }
                },
                {
                    from: 75,
                    to: 45,
                    color: 'rgba(255, 203, 5, 0.25)',
                    label: {
                        text: 'Etat de santé affecté (à suivre)'
                    }
                },
                {
                    from: 45,
                    to: 15,
                    color: 'rgba(244, 67, 54, 0.25)',
                    label: {
                        text: 'Etat de santé dégradé (importance élevée)'
                    }
                },
                {
                    from: 15,
                    to: 0,
                    color: 'rgba(0, 0, 0, 0.25)',
                    label: {
                        text: 'Etat de santé critique (urgence)'
                    }
                }
            );
        },

        // DISPLAY DATA MANAGEMENT
        checkDataAvailable(datas){
            if(typeof datas[0] === 'undefined') {                    
                return false;
            }else {
                return true;
            }
        },
        setMetricThresholds() {
            this.lineChartOptions.yAxis.plotBands = [];
            this.lineChartOptions.yAxis.plotBands.push(
                {
                    from: 100,
                    to: 75,
                    color: 'rgba(76, 175, 80, 0.25)',
                    label: {
                        text: 'Etat de santé convenable'
                    }
                },
                {
                    from: 75,
                    to: 45,
                    color: 'rgba(255, 203, 5, 0.25)',
                    label: {
                        text: 'Etat de santé affecté (à suivre)'
                    }
                },
                {
                    from: 45,
                    to: 15,
                    color: 'rgba(244, 67, 54, 0.25)',
                    label: {
                        text: 'Etat de santé dégradé (importance élevée)'
                    }
                },
                {
                    from: 15,
                    to: 0,
                    color: 'rgba(0, 0, 0, 0.25)',
                    label: {
                        text: 'Etat de santé critique (urgence)'
                    }
                }
            );
        }, 

        //REQUESTS
        updateReport() {
            this.machine_filters.forEach(filter => {
                if (filter.level ==  this.report.alert_level){
                    this.report.indice = filter.percent;
                }
            })
            
            this.axios.patch(REPORT + "/" + this.report.id,  this.report)
            .then((success) => {
                Object.assign(this.report, success.data[0]);
                this.getData();
                this.loading_datatable = true;
                this.showSnackbar('success', this.$t('global.success_to_edit'));
            
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            
            }).finally(() => {
                this.loading_datatable = true;
                this.initialize();
            })
        },
        saveDate(date) {
            this.selectedDate = date;
            if (this.report) {
                this.report.intervention_date = date;
            }

            //this.updateReport();
        },
        initializeDate() {
            if (this.report && this.report.intervention_date) {
                this.selectedDate = new Date(this.report.intervention_date).toISOString().split('T')[0];
            }
        },
        checkReportConditions(){
            var flagNext = false;
            if (this.report.type == 1){
                if((this.report.intervention_date != null || this.report.intervention_date != "") 
                    && (this.report.intervenant != null || this.report.intervenant != "")
                    && (this.report.preco != null || this.report.preco != "")
                    && (this.report.presta != null || this.report.presta != "")
                    && (this.report.type != null || this.report.type != "")
                    && (this.report.context != null || this.report.context != "")
                    && (this.report.interlocutor != null || this.report.interlocutor != "")){
                        flagNext = true;
                }   
            }else{
                if((this.report.intervention_date != null || this.report.intervention_date != "") 
                    && (this.report.intervenant != null || this.report.intervenant != "")
                    && (this.report.preco != null || this.report.preco != "")
                    && (this.report.presta != null || this.report.presta != "")
                    && (this.report.type != null || this.report.type != "")
                    && (this.report.interlocutor != null || this.report.interlocutor != "")){
                        flagNext = true;
                }  
            }


            return flagNext;
        },


        setReportState(report, state) {
            //  transition ASK VALIDATION STEP [2]
            if (state === 1) {
                this.axios.patch( REPORT + '/ask_validate/' + report.id)
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.initialize();
                })
            }
            //  transition UNASK VALIDATION STEP [1]
            if (state === 2) {
                this.axios.patch( REPORT + '/unask_validate/' + report.id)
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.initialize();
                })
            }
            //  transition VALIDATION STEP [3]
            if (state === 3) {
                if(this.checkReportConditions){
                    this.axios.patch( REPORT + '/certify/' + report.id )
            
                    .then(() => {
                        this.showSnackbar('success', this.$t('global.success_to_edit'));
                    
                    }).catch(() => {
                        this.showSnackbar('error', this.$t('global.failure_to_update'));
                    
                    }).finally(() => {
                        this.loading_datatable = true;
                        this.initialize();
                    })
                }else{
                    this.showSnackbar('error', "Tous les champs obligatoires ne sont pas renseignés pour valider le rapport");
                }

            }
            //  transition VALIDATION STEP [4]
            if (state === 4) {
                this.axios.patch( REPORT + '/failed_certify/' + report.id )
            
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.initialize();
                })
            }
            //  transition UNVALIDATION STEP [4]
            if (state === 5) {
                this.axios.patch( REPORT + '/uncertify/' + report.id )
            
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.initialize();
                })
            }
            //  transition RELEASE STEP [5]
            if (state === 6) {
                if(this.checkReportConditions){
                    this.axios.patch( REPORT + '/release/' + report.id )
                
                    .then(() => {
                        this.showSnackbar('success', this.$t('global.success_to_edit'));
                    
                    }).catch(() => {
                        this.showSnackbar('error', this.$t('global.failure_to_update'));
                    
                    }).finally(() => {
                        this.loading_datatable = true;
                        this.initialize();
                    })
                }else{
                        this.showSnackbar('error', "Tous les champs obligatoires ne sont pas renseignés pour publier le rapport.");
                }
            }

            //  transition UNRELEASE STEP [6]
            if (state === 7) {
                this.axios.patch( REPORT + '/unrelease/' + report.id )
            
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.initialize();
                })
            }
        },

        // UPLOADS (report)
        onDrop(e) {
            this.dragover = false;
            // If there are already uploaded files remove them
            if (this.files_to_upload.length > 0) this.files_to_upload = [];
            // If user has uploaded multiple files but the component is not multiple throw error
            let file;
            for (let i = 0; i < e.dataTransfer.files.length; i++) {
                file = e.dataTransfer.files.item(i);
                let docCheck = this.uploadFilesCheck(file,['image/png','image/jpg','image/jpeg','application/pdf','text/csv'],10);
                if(!docCheck.authorized ) {
                    this.showSnackbar('error',docCheck.output.toString());
                }
                else {
                    this.files_to_upload.push(file);
                }
            }
            if (this.files_to_upload.length > 0) this.uploadDocuments();
        },
        uploadDocuments() {
            this.loading = true;
            this.files_to_upload.forEach((file) => {
                let formData = new FormData();
                formData.append('files[]', file);
                formData.append('entity_id', this.id);
                formData.append('type', "report");
                formData.append('professional_id', this.$store.state.professional_id);

                axios.post(DOCUMENTS + '/upload', formData, this.configurationFile)
                    .then(() => {
                        this.getDocuments();
                    })
                    .catch((error) => {
                        if (error.response.status === 413) {
                            this.showSnackbar('warning', this.$t('document.too_large'));
                        } else {
                            this.showSnackbar('error', this.$t('global.failure_to_add'));
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            });
        },
        getDocuments() {
            this.axios.get(DOCUMENTS + '/getFiles', {
                    params: {
                        entity_id: this.$route.params.id,
                        type: 'report',
                        professional_id: this.$store.state.professional_id
                    }
                }

            ).then((success) => {
                this.uploaded_files = [];
                
                if (this.checkDataAvailable(success.data)){
                    success.data.forEach((path) => {
                        let fileName = path.replace(/^.*[\\\/]/, '');
                        this.uploaded_files.push({
                            file_name: fileName,
                            path: path
                        });
                    });

                    this.uploaded_files.reverse();
                    this.report_url = this.uploaded_files[0].path;
                    this.pdf_report_available = true;

                }else{
                    this.pdf_report_available = false;
                }
            }).catch(() => {
                this.showSnackbar('error', 'GetDocuments error');

            }).finally(() => {
                this.mergeExternalLinkAndDocuments();
                this.loading = false;
            });
        },
        launchUrl(url){
            this.report_url = url;
        },
        removeDocument(fileName) {
            this.loading = true;
            this.axios.delete(DOCUMENTS + '/delete', {
                    params: {
                        file_name: fileName,
                        entity_id: this.$route.params.id,
                        type: 'report'
                    }
                }
            ).then(() => {
                this.getDocuments();
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_delete'))
            }).finally(() => {
                this.loading = false;
            });
        },
        readDocument(fileName) {
            this.axios.get(DOCUMENTS + '/getFiles', {
                    params: {
                        entity_id: this.$route.params.id,
                        type: 'report',
                        professional_id: this.$store.state.professional_id
                    }
                }

            ).then((success) => {
                this.uploaded_files = [];
                
                if (this.checkDataAvailable(success.data)){
                    success.data.forEach((path) => {
                        let fileName = path.replace(/^.*[\\\/]/, '');
                        this.uploaded_files.push({
                            file_name: fileName,
                            path: path
                        });
                    });

                    this.uploaded_files.reverse();
                    this.uploaded_files.forEach(item => {
                        if (fileName == item.file_name){
                            this.report_url = item.path;
                        }
                        
                        item.created_at = moment(item.created_at).format("DD/MM/YYYY");
                    })
                    this.pdf_report_available = true;
                }else{
                    this.pdf_report_available = false;
                }
            }).catch(() => {
                this.showSnackbar('error', 'GetDocuments error');

            }).finally(() => {
                this.mergeExternalLinkAndDocuments();
                this.loading = false;
            });
        },

        // UPLOADS (imgs)
        img_onDrop(e) {
            this.img_dragover = false;
            // If there are already uploaded files remove them
            if (this.img_files_to_upload.length > 0) this.img_files_to_upload = [];
            // If user has uploaded multiple files but the component is not multiple throw error
            let file;
            for (let i = 0; i < e.dataTransfer.files.length; i++) {
                file = e.dataTransfer.files.item(i);
                let docCheck = this.uploadFilesCheck(file,['image/png','image/jpg','image/jpeg'],10);
                if(!docCheck.authorized ) {
                    this.showSnackbar('error',docCheck.output.toString());
                }
                else {
                    this.img_files_to_upload.push(file);
                }
            }
            if (this.img_files_to_upload.length > 0) this.img_uploadDocuments();
        },
        img_uploadDocuments() {
            this.img_loading = true;

            this.img_files_to_upload.forEach((file) => {
                let formData = new FormData();
                formData.append('files[]', file);
                formData.append('entity_id', this.id);
                formData.append('type', "report_images");
                formData.append('professional_id', this.$store.state.professional_id);

                axios.post(DOCUMENTS + '/upload', formData, this.configurationFile)
                
                .then(() => {
                    this.img_getDocuments();
                })
                
                .catch((error) => {
                    if (error.response.status === 413) {
                        this.showSnackbar('warning', this.$t('document.too_large'));
                    } else {
                        this.showSnackbar('error', this.$t('global.failure_to_add'));
                    }
                })
                
                .finally(() => {
                    this.img_loading = false;
                });
            });
        },
        img_getDocuments() {
            this.axios.get(DOCUMENTS + '/getFiles', {
                    params: {
                        entity_id: this.$route.params.id,
                        type: 'report_images',
                        professional_id: this.$store.state.professional_id
                    }
                }

            ).then((success) => {
                this.img_uploaded_files = [];
                this.img_urls = [];
                
                if (this.checkDataAvailable(success.data)){
                    success.data.forEach((path) => {
                        let fileName = path.replace(/^.*[\\\/]/, '');
                        this.img_uploaded_files.push({
                            file_name: fileName,
                            path: path
                        });
                    });

                    this.img_uploaded_files.reverse();
                    this.img_uploaded_files.forEach(img_url => {
                        this.img_urls.push(img_url.path);
                        this.img_available = true;
                    })

                }else{
                    this.img_available = false;
                }
            }).catch(() => {
                this.showSnackbar('error', 'img_GetDocuments error');

            }).finally(() => {
                this.img_result = this.img_uploaded_files;
                this.img_loading = false;
            });
        },
        img_launchUrl(url){
            this.report_url = url;
        },
        img_removeDocument(fileName) {
            this.img_loading = true;
            this.axios.delete(DOCUMENTS + '/delete', {
                    params: {
                        file_name: fileName,
                        entity_id: this.$route.params.id,
                        type: 'report_images'
                    }
                }
            ).then(() => {
                this.img_getDocuments();
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_delete'))
            }).finally(() => {
                this.loading = false;
            });
        },

        // EXTERNAL LINKS MANAGEMENT (not used on 1.5.1)
        getExternalLinks() {
            this.axios.get(EXTERNAL_LINK, {
                    params: {
                        ['id_report']: this.$route.params.id,
                        type: 'report'
                    }
                }
            ).then((success) => {
                this.external_links = success.data;
                this.mergeExternalLinkAndDocuments();
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            }).finally(() => {
                this.loading = false;
            });
        },
        removeExternalLink(id) {
            this.loading = true;
            this.axios.delete(EXTERNAL_LINK + '/' + id, {
                    params: {
                        id: id
                    }
                }
            ).then(() => {
                this.getExternalLinks();
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_delete'))
            }).finally(() => {
                this.loading = false;
            });
        },
        openDialogAddExternalLink() {
            this.resetExternalLinkObject();
            this.dialog_add = true;

        },
        resetExternalLinkObject() {
            this.external_link.link = '';
        },
        closeDialog() {
            this.dialog_add = false;
        },
        addExternalLink() {
            if (!this.validURL()) {
                this.showSnackbar('error', 'Veuillez saisir une url valide');
            } else {
                this.loading = true;

                this.axios.post(EXTERNAL_LINK, this.external_link
                ).then((success) => {
                    this.result.push(success.data);
                    this.external_links.push(success.data);
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_add'))
                }).finally(() => {
                    this.closeDialog();
                    this.loading = false;
                });
            }
        },
        validURL() {
            let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
            this.valid = !!pattern.test(this.external_link.link);
            return this.valid;
        },
        mergeExternalLinkAndDocuments() {
            this.result = this.uploaded_files.concat(this.external_links);
        },

        // Machine menu package //
        updateLabelMachine(item) {
            this.label = item.label;
        },
        infoToggler(){
            this.infoToggle = !this.infoToggle;
        },
        infoToggler2(){
            this.infoToggle2 = !this.infoToggle2;
        },

        // Uploads V2 methods
        onChange(e) {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                this.dragging = false;
                return;
            }
            this.createFile(files[0]);
        },
        createFile(file) {
            this.files.push(file);
            this.dragging = false;
        },
        removeFile(file) {
            this.files.splice(this.files.indexOf(file), 1);
        },
        sendFiles() {
            this.loading = true;
            this.dialog = false;
            
            this.files.forEach((file) => {
                let formData = new FormData();
                let docCheck = this.uploadFilesCheck(file,['application/pdf'],10);
                
                // if form factor NOK + file unavailable -> do not upload
                if(!docCheck.authorized || this.files.length < 1) {
                    this.showSnackbar('error',docCheck.output.toString());
                }

                // if form factor OK + file available -> upload
                if (docCheck.authorized && this.files.length > 0) {
                    formData.append('files[]', file);
                    formData.append('entity_id', this.id);
                    formData.append('type', "report");
                    formData.append('professional_id', this.$store.state.professional_id);

                    axios.post(DOCUMENTS + '/upload', formData, this.configurationFile)
                        .then(() => {
                            this.getDocuments();
                            this.dialog = false;
                            this.alert_file_send = true;
                            setTimeout(() => {
                                this.alert_file_send = false;
                            }, 2500);
                        })
                        .catch((error) => {
                            if (error.response.status === 413) {
                                this.showSnackbar('warning', this.$t('document.too_large'));
                            } else {
                                this.showSnackbar('error', this.$t('global.failure_to_add'));
                            }
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            });
        },
    },
    watch: {
        report: {
            handler(newReport) {
                if (newReport && newReport.intervention_date) {
                    const date = new Date(newReport.intervention_date);
                    this.selectedDate = date.toISOString().split('T')[0];
                }
            },
            deep: true,
            immediate: true
        }
    },
}
</script>

<style>
.full-width-text .v-select__selections {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.pdf-viewer__body .loading-mask {
    z-index: 4!important;
}

.full-width-text .v-select__selection {
  flex: 1 1 auto;
  text-align: left;
}

.v-responsive__content{
    width: auto !important;
}

.custom-v-select .v-select__selections {
  width: 100%; /* S'assurer que l'élément prend toute la largeur */
}

.custom-v-select .v-select__selection {
  text-overflow: ellipsis; /* Ajuste l'affichage si le texte dépasse */
  white-space: nowrap; /* Évite les retours à la ligne */
  overflow: hidden; /* Masque le contenu qui déborde */
}

.custom-v-select input {
  display: none; /* Supprime visuellement l'élément <input> */
}
</style>

<style scoped>
.file-input-zone-container {
    text-align: center;
}

.dropzone {
    width: 100%;
    height: 200px;
    position: relative;
    border: 2px dashed #e1e1e1;
}

.dropzone:hover {
    border: 1px dashed #004181;
}

.dropzone-info {
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
}

.dropzone-title {
    color: #787878;
}

.dropzone input {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.dropzone-upload-limit-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.dropzone-btn {
    margin-left: auto;
    margin-right: auto;
}

.uploaded-file-name {
    display: inline;
}

.remove-file {
    display: inline;
    float: right;
}

.width-layout {
    max-width: 350px;
}

.cancel-btn {
    text-decoration: black underline;
}
</style>