<template>
    <div id="password-recovery-view" class="page-not-connected">
        <v-row align="center" justify="center" class="h-100">
            <v-col lg="4" md="5" sm="8" cols="11">
                <v-card class="elevation-12" align-self="center">
                    <validation-observer ref="observer" v-slot="{ invalid }">
                        <v-form ref="form">
                            <v-card-title class="primary white--text">
                                {{ $t('password_recovery.new_password') }}
                            </v-card-title>

                            <v-card-text class="pt-4">
                                <p class="text--black font-16 mt-2 mb-2">{{ $t('password_recovery.set_new_password') }}</p>

                                <validation-provider :name="$t('password_recovery.password')" rules="required|min:7"
                                                     vid="new_pass" v-slot="{ errors }"
                                >
                                    <v-text-field
                                        prepend-icon="mdi-lock"
                                        :label="$t('password_recovery.password')"
                                        :append-icon="show_new_password? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="show_new_password ? 'text' : 'password'"
                                        @click:append="show_new_password = !show_new_password"
                                        v-model="new_password"
                                        :error-messages="errors"
                                    />
                                </validation-provider>

                                <validation-provider :name="$t('password_recovery.new_password_confirmation')"
                                                     rules="required|min:7|confirmed:new_pass" v-slot="{ errors }"
                                >
                                    <v-text-field
                                        prepend-icon="mdi-lock"
                                        :label="$t('password_recovery.new_password_confirmation')"
                                        :append-icon="show_new_password_confirmation ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="show_new_password_confirmation ? 'text' : 'password'"
                                        @click:append="show_new_password_confirmation = !show_new_password_confirmation"
                                        v-model="new_password_confirmation"
                                        :error-messages="errors"
                                    />
                                </validation-provider>
                            </v-card-text>

                            <v-card-actions class="justify-end">
                                <v-btn @click="$router.push({name: 'login'})">
                                    {{ $t('global.cancel') }}
                                </v-btn>
                                <v-btn color="primary" @click="resetPassword()" :disabled="invalid">
                                    {{ $t('global.validation') }}
                                </v-btn>
                            </v-card-actions>
                        </v-form>
                    </validation-observer>
                </v-card>
            </v-col>

            <global-snackbar/>
        </v-row>
    </div>
</template>

<script>
import GlobalSnackbar from "../components/GlobalSnackbar";
import {USERS} from "../../api";

export default {
    name: 'PasswordRecoveryView',
    components: {GlobalSnackbar},
    data() {
        return {
            invalid: false,
            recovery_email: '',
            new_password: '',
            show_new_password: false,
            new_password_confirmation: '',
            show_new_password_confirmation: false
        }
    },
    created() {
        this.getPasswordResetToken();
    },
    methods: {
        getPasswordResetToken() {
            this.axios.get(
                'api/reset_password/' + this.$route.query.token
            ).then((success) => {
                this.recovery_email = success.data.email;
            }).catch((error) => {
                let errorMessage = this.$t('password_recovery.error_get_password_reset');
                if (error.response.status === 404) {
                    errorMessage = this.$t('password_recovery.password_reset_not_found');
                    this.$router.push({name: 'login'});
                }
                this.manageError(error.response.status, errorMessage);
            });
        },

        resetPassword() {
            this.axios.put(USERS + '/reset_password', {
                token: this.$route.query.token,
                new_password: this.new_password,
                new_password_confirmation: this.new_password_confirmation
            }).then(() => {
                this.showGlobalSnackbar('success', this.$t('password_recovery.password_reset'));
                this.$router.push({name: 'login'});
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('password_recovery.error_during_reset_password'));
            })
        }
    }
}
</script>
