<template>
    <div id="resend-invitation">
        <v-dialog v-model="dialog_send_mail" max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="headline">
                        {{ $t('account_management.resend_mail_invitation') }}
                    </span>
                </v-card-title>

                <v-divider/>

                <v-card-text class="text-center font-weight-bold mt-4">
                    {{ $t('account_management.resend_mail_to', {email: email_send_to}) }}
                </v-card-text>

                <v-card-actions class="justify-end">
                    <v-btn text @click="closeModalSendMail()" class="btn-cancel">
                        {{ $t('global.cancel') }}
                    </v-btn>
                    <v-btn color="primary" @click="resendMailInvitation()">
                        {{ $t('global.validation') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <global-snackbar/>
    </div>
</template>

<script>
import {ADMIN_USERS} from "../../../../api";
import GlobalSnackbar from "../../../../global/components/GlobalSnackbar";

export default {
    name: 'ResendInvitation',
    components: {GlobalSnackbar},
    data() {
        return {
            dialog_send_mail: false,
            email_send_to: '',
        }
    },
    methods: {
        openModalSendMail(item) {
            this.dialog_send_mail = true;
            this.email_send_to = item.email;
        },

        async resendMailInvitation() {
            await this.axios.post(ADMIN_USERS + '/resend_invitation', {
                email: this.email_send_to,
                sender_name: this.$auth.user().last_name + ' ' + this.$auth.user().first_name,
            }).then(() => {
                this.showGlobalSnackbar('success', this.$t('account_management.invitation_resend'));
            }).catch((error) => {
                this.manageError(error.response.status,this.$t('global.failure_to_download'))
            }).finally(() => {
                this.closeModalSendMail();
            });
        },

        closeModalSendMail() {
            this.dialog_send_mail = false;
            this.email_send_to = '';
        },
    }
}
</script>
