<template>
    <div id="virtual-device-specifications">
        <v-card elevation="2" outlined min-height="480px">
            <v-card-title class="justify-space-between">
                <v-icon>mdi-view-list-outline</v-icon>
                {{ $t('virtual_device_specification.title') }}
                <v-btn v-if="$store.state.access_level !== 300 && $store.state.access_level !== 400"
                       elevation="3" color="amber darken-3" small @click="openDialogUpdateVirtualDevice()"
                >
                    <v-icon color="white" small>mdi-wrench</v-icon>
                </v-btn>

                <v-icon v-if="$store.state.access_level === 300 || $store.state.access_level === 400"/>
            </v-card-title>

            <v-divider/>

            <v-card-text>
                <v-text-field :prepend-inner-icon="'mdi-label-outline'"
                              :label="$t('virtual_device_specification.label')"
                              v-model="virtual_device.label"
                              outlined readonly background-color="#EEEEEE"
                />

                <v-text-field :prepend-inner-icon="'mdi-tape-measure'"
                              :label="$t('virtual_device_specification.type')"
                              v-model="virtual_device.type"
                              outlined readonly background-color="#EEEEEE"
                />
                <v-textarea
                    background-color="#EEEEEE"
                    v-model="virtual_device.note"
                    rows="2" outlined readonly no-resize
                    :label="$t('global.notes')"
                    :prepend-inner-icon="'mdi-comment-outline'"
                />

                <v-row>
                    <v-col cols="7">
                        <v-text-field outlined readonly background-color="#EEEEEE"
                                      :value="is_associate_contract_loading ? '' : (is_associate_contract ? $t('virtual_device_specification.subscription_expire_at') : $t('virtual_device_specification.no_contract_associated'))"
                                      :loading="is_associate_contract_loading"
                        >
                            <template v-slot:prepend-inner class="w-100">
                                <v-icon class="mr-1">mdi-ticket-confirmation</v-icon>
                            </template>

                            <template v-slot:append class="w-100">
                                <span v-if="virtual_device.subscription_expire_at !== null">
                                    <v-chip :color="subscription_expire_at_color" text-color="white">
                                        {{ subscription_expire_at_text }}
                                    </v-chip>
                                </span>
                            </template>
                        </v-text-field>
                    </v-col>

                    <v-col cols="5" v-if="this.$store.state.access_level === 100">
                        <v-menu bottom :close-on-content-click="false" :offset-y="true">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-if="files.length !== 0 && !files_loading"
                                       color="primary" large block
                                       v-on="on" v-bind="attrs"
                                       :disabled="!is_associate_contract"
                                >
                                    <v-icon left>mdi-arrow-collapse-down</v-icon>
                                    {{ $t('contracts_datatable.contract_download') }}
                                </v-btn>

                                <v-btn v-else-if="files.length === 0 && !files_loading" large block disabled>
                                    {{ $t('contracts_datatable.none_contract_found') }}
                                </v-btn>

                                <v-btn v-else block large disabled loading/>
                            </template>

                            <v-list>
                                <v-list-item v-for="(item, index) in files" :key="index" :href="item.path" download>
                                    <v-list-item-title>{{ item.file_name }}</v-list-item-title>
                                    <v-list-item-icon>
                                        <v-icon> mdi-arrow-down-bold</v-icon>
                                    </v-list-item-icon>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-dialog v-model="dialog_edit" max-width="800px">
            <v-card>
                <validation-observer ref="observer" v-slot="{ invalid }">
                    <v-form ref="form">
                        <v-card-title>
                            <span class="headline">{{ $t('virtual_device_specification.edit_virtual_device') }}</span>
                        </v-card-title>

                        <v-divider/>

                        <v-container>
                            <v-card-title>
                                {{ $t('virtual_device_specification.title') }}
                            </v-card-title>

                            <v-card-text>
                                <validation-provider :name="$t('virtual_device_specification.label')"
                                                     rules="required" v-slot="{ errors }"
                                >
                                    <v-text-field :prepend-inner-icon="'mdi-label-outline'"
                                                  :label="$t('virtual_device_specification.label')"
                                                  v-model="edited_virtual_device.label"
                                                  outlined background-color="#EEEEEE"
                                                  :error-messages="errors"
                                    />
                                </validation-provider>

                                <validation-provider :name="$t('virtual_device_specification.type')" rules="required"
                                                     v-slot="{ errors }"
                                >
                                    <v-text-field :prepend-inner-icon="'mdi-tape-measure'"
                                                  :label="$t('virtual_device_specification.type')"
                                                  v-model="edited_virtual_device.type"
                                                  outlined background-color="#EEEEEE"
                                                  :error-messages="errors"
                                    />
                                </validation-provider>

                                <v-textarea
                                    background-color="#EEEEEE"
                                    v-model="edited_virtual_device.note"
                                    rows="2" outlined no-resize
                                    :label="$t('global.notes')"
                                    :prepend-inner-icon="'mdi-comment-outline'"
                                />
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer/>
                                <v-btn @click="dialog_edit = false">
                                    <v-icon left>mdi-close</v-icon>
                                    {{ $t('global.close') }}
                                </v-btn>
                                <v-btn type="submit" color="primary" :disabled="invalid"
                                       @click.prevent="updateVirtualDeviceDetails()">
                                    <v-icon left>mdi-content-save</v-icon>
                                    {{ $t('global.validation') }}
                                </v-btn>
                            </v-card-actions>
                        </v-container>
                    </v-form>
                </validation-observer>

                <v-divider></v-divider>

                <v-card-title>
                    {{ $t('virtual_device_specification.dialog_manage_contract_title') }}
                </v-card-title>

                <v-container>
                    <v-card-actions class="justify-center">

                        <v-btn v-if="!is_associate_contract && !is_associate_contract_loading"
                               color="primary" large @click="associateDialogOpen()"
                        >
                            {{ $t('virtual_device_specification.dialog_manage_contract_associate') }}
                        </v-btn>

                        <v-btn v-else-if="is_associate_contract && !is_associate_contract_loading"
                               color="error" large @click="dialog_dissociate = true"
                        >
                            {{ $t('virtual_device_specification.dialog_manage_contract_dissociate') }}
                        </v-btn>

                        <v-btn v-else loading large></v-btn>
                    </v-card-actions>
                </v-container>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_associate" max-width="600px">
            <v-card>
                <validation-observer ref="associateObserver" v-slot="{ invalid }">
                    <v-form ref="associateForm">
                        <v-card-title>
                            {{ $t('virtual_device_specification.dialog_manage_contract_associate_title') }}
                        </v-card-title>

                        <v-divider/>

                        <v-container class="justify-center">
                            <v-card-text class="text-center">
                                {{
                                    $t('virtual_device_specification.dialog_manage_contract_associate_text', {virtual_device: virtual_device.label})
                                }}
                            </v-card-text>

                            <v-card-text class="text-center">
                                <validation-provider rules="required" v-slot="{ errors }"
                                                     :name="$t('virtual_device_specification.dialog_manage_contract_associate_number_contract')"
                                >
                                    <v-autocomplete
                                        v-if="contracts"
                                        v-model="selected_contract"
                                        class="mt-5 ml-3 width-layout"
                                        :items="contracts"
                                        :label="$t('virtual_device_specification.dialog_manage_contract_associate_number_contract')"
                                        :item-text="getItemText" item-value="id"
                                        :loading="contracts_load"
                                        :disabled="contracts.length === 0"
                                        :error-messages="errors"
                                        @change="getTypeContracts"
                                        return-object filled
                                    >
                                    </v-autocomplete>
                                </validation-provider>

                                <validation-provider rules="required" v-slot="{ errors }"
                                                     :name="$t('virtual_device_specification.dialog_manage_contract_associate_type_contract')"
                                >
                                    <v-autocomplete
                                        v-model="selected_contract_type"
                                        class="mt-5 ml-3 width-layout"
                                        :items="contract_types"
                                        :loading="contract_types_load"
                                        :disabled="contract_types.length === 0"
                                        :label="$t('virtual_device_specification.dialog_manage_contract_associate_type_contract')"
                                        item-text="label" item-value="contract_type_id" item-disabled="disabled"
                                        :error-messages="errors"
                                        return-object filled
                                    >
                                    </v-autocomplete>
                                </validation-provider>
                            </v-card-text>

                            <v-card-actions class="justify-center">
                                <v-btn text @click="resetAssociateContractDialogs()" class="btn-cancel">
                                    {{ $t('global.cancel') }}
                                </v-btn>
                                <v-btn color="primary" @click="associateContract()" :disabled="invalid">
                                    {{ $t('global.validation') }}
                                </v-btn>
                            </v-card-actions>
                        </v-container>
                    </v-form>
                </validation-observer>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_dissociate" max-width="600px">
            <v-card>
                <v-card-title>
                    {{ $t('virtual_device_specification.dialog_manage_contract_dissociate_title') }}
                </v-card-title>

                <v-divider/>

                <v-container>
                    <v-card-text class="text-center"
                                 v-html="$t('virtual_device_specification.dialog_manage_contract_dissociate_text',{virtual_device : virtual_device.label}) "/>
                    <v-card-actions class="justify-center">
                        <v-btn text @click="dialog_dissociate = false" class="btn-cancel">
                            {{ $t('global.cancel') }}
                        </v-btn>
                        <v-btn color="primary" @click="dissociateContract()">
                            {{ $t('global.validation') }}
                        </v-btn>
                    </v-card-actions>
                </v-container>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="snackbar" :color="snackbar_type">
            {{ snackbar_text }}
            <template v-slot:action="{ attrs }">
                <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import {
    CONTRACT_TYPES,
    CONTRACTS,
    DOCUMENTS,
    VIRTUAL_DEVICES
} from "../../../api";

export default {
    name: 'VirtualDeviceSpecifications',
    data() {
        return {
            virtual_device_id: null,
            virtual_device: {
                label: '',
                type: '',
                note: '',
                subscription_expire_at: null,
            },
            edited_virtual_device: null,
            dialog_edit: false,
            invalid: false,

            is_associate_contract: false,
            is_associate_contract_loading: true,

            contracts: [],
            contracts_load: false,
            selected_contract: null,

            contract_types: [],
            contract_types_load: false,
            selected_contract_type: null,

            files: [],
            files_loading: true,

            dialog_associate: false,
            dialog_dissociate: false,

        }
    },
    computed: {
        subscription_expire_at_color() {
            let color = 'green';
            if (this.virtual_device.subscription_expire_at >= 1 && this.virtual_device.subscription_expire_at <= 3) {
                color = 'amber';
            } else if (this.virtual_device.subscription_expire_at < 1) {
                color = 'red';
            }
            return color;
        },

        subscription_expire_at_text() {
            let text = Math.round(this.virtual_device.subscription_expire_at) + ' ' + this.$t('global.months');
            if (this.virtual_device.subscription_expire_at >= 1 && this.virtual_device.subscription_expire_at <= 3) {
                text = Math.round(this.virtual_device.subscription_expire_at) + ' ' + this.$t('global.months');
            } else if (this.virtual_device.subscription_expire_at < 1 && this.virtual_device.subscription_expire_at > 0) {
                text = ' < 1 ' + this.$t('global.months');
            } else if (this.virtual_device.subscription_expire_at <= 0) {
                text = this.$t('virtual_device_specification.expired');
            }

            return text;
        }
    },
    created() {
        this.virtual_device_id = this.$route.params.id;
        this.getVirtualDeviceDetails();
        this.getContracts();
        this.getContractFiles();
    },
    methods: {
        refresh() {
            this.contract_types = [];
            this.selected_contract = '';
            this.selected_contract_type = '';
            this.getContracts();
            this.getVirtualDeviceDetails();
            this.getContractFiles();
        },

        getVirtualDeviceDetails() {
            this.is_associate_contract_loading = true;

            this.axios.get(
                VIRTUAL_DEVICES + '/' + this.virtual_device_id + '/specification'
            ).then((success) => {
                this.virtual_device = success.data;
                if (this.virtual_device.subscription_expire_at != null) {
                    this.is_associate_contract = true;
                }
                this.is_associate_contract_loading = false;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            });
        },

        getContracts() {
            if (this.contracts.length >= 1) {
                this.contracts_load = true;
            }

            this.contracts = [];
            this.selected_contract = null;

            this.axios.get(
                CONTRACTS + '/professional'
            ).then((success) => {
                this.contracts = success.data;
                this.selected_contract = this.contracts[0];
                this.getTypeContracts();
                this.contracts_load = false;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            });
        },

        getTypeContracts() {
            if (this.contract_types.length >= 1) {
                this.contract_types_load = true;
            }
            this.contract_types = [];
            this.selected_contract_type = null;

            this.axios.get(CONTRACT_TYPES + '/' + this.selected_contract.id + '/contract', {
                params: {'virtual_device_id': this.virtual_device_id}
            }).then((success) => {
                this.contract_types = success.data;
                if (this.contract_types.length === 1) {
                    if (!this.contract_types[0].disabled) {
                        this.selected_contract_type = this.contract_types[0];
                    }
                }
                this.contract_types_load = false;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            })
        },

        getContractFiles() {
            this.files_loading = true;

            this.axios.get(DOCUMENTS + '/contract_files', {
                params: {'virtual_device_id': this.virtual_device_id,}
            }).then((success) => {
                success.data.forEach((path) => {
                    let fileName = path.replace(/^.*[\\\/]/, '');
                    this.files.push({
                        file_name: fileName,
                        path: path
                    });
                });
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            }).finally(() => {
                this.files_loading = false;
            });
        },

        openDialogUpdateVirtualDevice() {
            this.edited_virtual_device = Object.assign({}, this.virtual_device);
            this.dialog_edit = true;
        },

        resetAssociateContractDialogs() {
            this.dialog_associate = false;
            this.dialog_dissociate = false;
            this.$refs.associateForm.reset();
            this.$refs.associateObserver.reset();
        },

        associateContract() {
            axios.put(VIRTUAL_DEVICES + '/' + this.virtual_device_id + '/associate_contract', {
                'contract_type_id': this.selected_contract_type.contract_type_id
            }).then(() => {
                this.is_associate_contract = true;
                this.refresh();
                this.showSnackbar('success', this.$t('global.success_to_edit'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            }).finally(() => {
                this.resetAssociateContractDialogs();
            });
        },

        dissociateContract() {
            axios.put(
                VIRTUAL_DEVICES + '/' + this.virtual_device_id + '/dissociate_contract'
            ).then(() => {
                this.is_associate_contract = false;
                this.refresh();
                this.showSnackbar('success', this.$t('global.success_to_edit'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'))
            }).finally(() => {
                this.resetAssociateContractDialogs();
            });
        },

        updateVirtualDeviceDetails() {
            this.axios.patch(
                VIRTUAL_DEVICES + '/' + this.virtual_device_id, this.edited_virtual_device
            ).then(() => {
                this.virtual_device = Object.assign({}, this.edited_virtual_device);
                this.showSnackbar('success', this.$t('global.success_to_edit'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            }).finally(() => {
                this.dialog_edit = false;
                this.resetForm();
            });
        },

        resetForm() {
            this.$refs.form.reset();
            this.$refs.observer.reset();
        },

        getItemText(item) {
            return `${item.label} - ${item.contract_number}`;
        },
        associateDialogOpen() {
            this.dialog_associate= true;
            if(this.contracts.length > 0){
                this.selected_contract= this.contracts[0];
                if(this.contract_types.filter(contract_type => !contract_type.disabled).length > 0){
                    this.selected_contract_type= this.contract_types.filter(contract_type => !contract_type.disabled)[0];
                }
            }
        }
    },
}
</script>

<style>
.btn-cancel {
    text-decoration: black underline;
}
</style>
