<template>
    <div id="setting-log">
        <cards-notifications class="mt-6" v-if="cards"/>
        <datatable-notifications class="mt-6" v-if="!cards"/>
    </div>
</template>

<script>
import DatatableNotifications from "../components/DatatableNotifications";
import CardsNotifications from "../components/CardsNotifications";
/*
 
Notifiction system :
    - Be awared if somthing happened
    -> Things to be awared (TAGS)
        -> Alerts (new / update / closed)
            > Analyst -> by any measure points and any reports
            > Customer -> for any reports
        
        -> Campain (report or launch / updated / finished)
        -> Reports (new / update)
        -> Update (software)
        -> Messages (mainly published - by any customer)

    -> Criticity (TAGS_LEVEL)
        -> 0:blank
        -> 1:green
        -> 2:yellow
        -> 3:red
        -> 4:purple

    -> 

*/

export default {
    name: "Notification",
    components: {DatatableNotifications, CardsNotifications},
    data() {
        return {
            cards: true,
            ccess_level: this.$store.state.access_level,
        }
    },
    created() {
        this.setCards();
    },
    methods: {
        switchView() {
            this.cards = !this.cards;
        },

        setCards() {
            this.cards = true;
        },

        setDatatable() {
            this.cards = false;
        },
    }
}


</script>
