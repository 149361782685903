<template>
    <v-col class="col mt-12" style="margin-top:0px!important;padding:0px;position:relative; top:-12px;">
        <!-- MACHINE MENU PACKAGE -->
        <div style="padding:0px 12px;" id="MachineMenuPackage">
            <v-col class="pl-5 pr-5" style="background-color:#001047;color:#FFFFFF;border-radius: 0px 0px 5px 5px;">
                <!-- TOP TITLE -->
                <v-row class="pl-0 pt-5 mb-5" style="height: 52px; padding:5px;">
                    <!-- MACHINE NAME -->
                    <v-col class="row mb-0 pa-0 mt-1 col-auto" @click="infoToggler()">
                        <!-- COUNTER -->
                        <v-col color="white" class="col-auto pa-0 mr-5" v-if="machine">
                            <v-col class="col pt-0 pb-0 mr-10 rounded col ml-6" style="height: 35px;background-color: rgb(92 101 123);font-weight: bold;text-align: center;font-size: 16px;border: 1px solid #ffffff5c;line-height: 35px;color: #FFFFFF;">
                                {{ machine.label }}
                            </v-col>
                        </v-col>
                    </v-col>
                    
                    <!-- PAGE TITLE -->
                    <v-subheader class="v-subheader col theme--light pl-3 ml-2">
                        <!------------------- Info bulle ---------------------->
                        <v-tooltip bottom dark class="mr-2 ml-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 mr-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                                    <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                                </v-btn>
                            </template>
                            <template v-slot:default>
                            <div style="min-width: 200px;position:relative;z-index: 10;">
                                <strong>{{ $t('report.tooltip_page_title') }}</strong>
                                <p>{{ $t('report.tooltip_page_title_content1') }}</br>
                                    {{ $t('report.tooltip_page_title_content2') }}
                                </p>
                            </div>
                            </template>
                        </v-tooltip>
                        <p class="title mb-0 color-white" style="color: white!important;">
                            <div style="color:#FFFFFFb3;margin-right:4px;">Equipements > {{ machine.label }} > </div><div @click="infoToggler()" style="color:#FFFFFF;font-weight:bold;"> {{ $t('machines.reports') }}</div>
                        </p>
                        <!------------------- Info bulle ---------------------->
                        <v-tooltip bottom dark class="ml-0">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="getNotification()" class="ml-0 v-size--default" dusk="logout-button">
                                    <v-icon color="orange" style="font-size:16px">mdi-message-alert</v-icon>
                                </v-btn>
                            </template>
                            <template v-slot:default>
                            <div style="min-width: 200px;">
                                <p>{{ $t('report.tooltip_pdf1') }}</br>
                                    {{ $t('report.tooltip_pdf2') }}
                                </p>
                            </div>
                            </template>
                        </v-tooltip>
                    </v-subheader>

                    <!-- GLOBAL ACTIONS -->
                    <v-col class="row mt-1 col-auto pa-0 pr-5">
                        <v-tooltip bottom dark class="mr-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="getData()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                    <v-icon color="white">mdi-tag-multiple</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('global.1_5_2') }}</span>
                        </v-tooltip>
                        <v-tooltip bottom dark class="mr-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="getData()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                    <v-icon color="white">mdi-refresh</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('machines.refresh') }}</span>
                        </v-tooltip>
                        
                        <v-tooltip v-if="infoToggle == true" bottom dark class="mr-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="infoToggler()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                    <v-icon color="white">mdi-minus-box-multiple</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('machines.box') }}</span>
                        </v-tooltip>

                        <v-tooltip v-if="infoToggle == false" bottom dark class="mr-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="infoToggler()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                    <v-icon color="white">mdi-plus-box-multiple</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('machines.box') }}</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
                <!-- MACHINE INFOS -->
                <v-col class="mb-4" v-if="infoToggle == true" style="min-height: 250px; background-color:#e8edff;border-radius:5px;">
                    <v-row>
                        <v-col class="col-4">
                            <machine-state/>
                        </v-col>
                        <v-col class="col pl-0">
                            <machine-specifications v-on:updateLabelMachine="updateLabelMachine"/>
                            <v-row>
                                <v-col class="col">
                                    <machine-subassemblies/>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
                <!-- MACHINE LINKS -->
                <v-row v-if="infoToggle == false" style="border-top: 1px solid #ffffff1c;">
                    <v-row style="margin:0px;">
                        <v-row class="ml-5 pt-5 pb-5 rounded" style="">
                            <!-- ALERTS -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/events'}" dusk="logout-button">
                                        <v-icon color="white">mdi-alert</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.alerts') }}</span>
                            </v-tooltip>

                            <!-- REPORTS -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/report'}" dusk="logout-button">
                                        <v-icon color="white">mdi-chart-box</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.reports') }}</span>
                            </v-tooltip>

                            <!-- DETAILS -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/details'}" dusk="logout-button">
                                        <v-icon color="white">mdi-chart-line</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.courbes') }}</span>
                            </v-tooltip>

                            <!-- SANDBOX -->
                            <v-tooltip bottom dark class="mr-2" v-if="$store.state.access_level <= 100">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" class="mr-5 col-0" :to="{ path: '/machine/' + id + '/sandbox'}" dusk="logout-button">
                                    <v-icon color="red">mdi-vector-curve</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('machines.sandbox') }}</span>
                        </v-tooltip>

                            <!-- Documents -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/documents'}" dusk="logout-button">
                                        <v-icon color="white">mdi-file-document</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machines.documents') }}</span>
                            </v-tooltip bottom dark class="mr-2">
                            
                            <!-- SETTINGS -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/settings'}" dusk="logout-button">
                                        <v-icon color="white">mdi-cog</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.settings') }}</span>
                            </v-tooltip bottom dark class="mr-2">
                        </v-row>  

                        <!-- Menu déroulant pour choisir les rapports -> Potentiel champ rechercher pour la suite
                        <v-row id="report-linker" class="col-6 justify-end pt-2 pr-8" >
                            
                            <v-select
                                :reports="reports"
                                label="Sélectionner un rapport"
                                v-model="reportSelection"
                                outlined
                            ></v-select>
                            <p>{{ reportSelection }}</p>
                            <v-btn :disabled="isLoading" @click="calculateHeight" class="ml-2">
                                <v-icon color="primary">{{ isLoading ? 'mdi-loading' : 'mdi-printer' }}</v-icon>
                            </v-btn>
                        </v-row>   
                         -->
                    </v-row>
                </v-row>

                <v-row v-if="infoToggle == true">
                    <v-row style="margin:0px;">
                        <v-row class="ml-5 pt-5 pb-5 rounded" style="">
                            <!-- ALERTS -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/events'}" dusk="logout-button">
                                        <v-icon color="white">mdi-alert</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.alerts') }}</span>
                            </v-tooltip>

                            <!-- REPORTS -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/report'}" dusk="logout-button">
                                        <v-icon color="white">mdi-chart-box</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.reports') }}</span>
                            </v-tooltip>

                            <!-- DETAILS -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/details'}" dusk="logout-button">
                                        <v-icon color="white">mdi-chart-line</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.courbes') }}</span>
                            </v-tooltip>

                            <!-- SANDBOX -->
                            <v-tooltip bottom dark class="mr-2" v-if="$store.state.access_level <= 100">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" class="mr-5 col-0" :to="{ path: '/machine/' + id + '/sandbox'}" dusk="logout-button">
                                    <v-icon color="red">mdi-vector-curve</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('machines.sandbox') }}</span>
                        </v-tooltip>

                            <!-- Documents -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/documents'}" dusk="logout-button">
                                        <v-icon color="white">mdi-file-document</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machines.documents') }}</span>
                            </v-tooltip bottom dark class="mr-2">
                            
                            <!-- SETTINGS -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/settings'}" dusk="logout-button">
                                        <v-icon color="white">mdi-cog</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.settings') }}</span>
                            </v-tooltip bottom dark class="mr-2">
                        </v-row>     
                    </v-row>
                </v-row>
            </v-col>
        </div>       

        <!--CHIPS-->
        <div class="ma-0 pa-0" v-if="reportLast_found" id="ChipsAlert">
            <div v-for="filters in machine_filters" :key="filters" style="max-height:90px;border-radius:5px;" class="ma-0 mb-0 pa-0">
                <div v-if="filters.level == report.alert_level && filters.level_name !== 'Tout voir'" class="col-0" style="color:white;font-weight:800;display:flex;justify-content:center;text-shadow:2px 2px 4px rgba(0, 0, 0, 0.29); height:100%;min-width: 8px;border-radius:5px;">
                    <v-row class="pa-0 ma-3">
                        <v-col class="col pa-0" style="max-width:30%;display:flex;flex-direction:column;border-radius: 5px 0px 0px 5px;" :style="{backgroundColor: filters.level_color}">
                            <v-row style="align-items:center;width:100%;position:relative;top:0px;padding-left: 35px;">
                                <v-col class="col-5" style="font-size:50px;font-weight:bold;text-align:right;">{{ report.indice }}%</v-col>
                                <v-col style="font-size:13px;text-align:left;font-weight: 400;align-items:center;">
                                    <div v-if="report.id">Dernier rapport publié</div>
                                    <div v-if="!report.released_at">Date de publication non définie</div>
                                    <div v-if="report.released_at != null">Le {{ report.released_at }}</div>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col class="col pa-3" style="max-width:70%;background-color:white;height:90px;display:flex;flex-direction:column;justify-content: center;text-shadow:none;">
                            <div v-for="state in machine_filters" :key="state">
                                <div v-if="state.percent === report.indice" style="font-size:18px;color:black;padding-left: 20px;"> {{ state.annotation }}</div>
                                <div v-if="state.percent === report.indice && report_position == 1" style="font-size:14px;color:black;padding-left: 20px;font-weight:400;display:flex;"> <div style="color:#0e4cef;font-weight:bold;margin-right:5px;">* </div> Il s'agit du premier rapport publié sur cette application pour ce moyen.</div>
                                <div v-if="state.percent === report.indice && report_position > 1 && previous_indice < report.indice" style="font-size:14px;color:black;padding-left: 20px;font-weight:400;display:flex;"> <div style="color:green;font-weight:bold;margin-right:5px;">↗ </div> Evolution positive depuis le {{previous_date}} (EDS {{previous_indice}}%)</div>
                                <div v-if="state.percent === report.indice && report_position > 1 && previous_indice == report.indice" style="font-size:14px;color:black;padding-left: 20px;font-weight:400;display:flex;"> <div style="color:black;font-weight:bold;margin-right:5px;">= </div> Pas d'évolution depuis le {{previous_date}} (EDS {{previous_indice}}%)</div>
                                <div v-if="state.percent === report.indice && report_position > 1 && previous_indice > report.indice" style="font-size:14px;color:black;padding-left: 20px;font-weight:400;display:flex;"> <div style="color:red;font-weight:bold;margin-right:5px;">↘ </div>Evolution négative depuis le {{previous_date}} (EDS {{previous_indice}}%)</div>
                            </div>
                        </v-col>
                        <v-col class="col-1 pa-3" style="border-radius: 0px 5px 5px 0px;max-width:70%;background-color:white;height:90px;display:flex;flex-direction:column;justify-content: center;text-shadow:none;align-items:center;">
                            <v-row style="align-items: center;">                            
                                <v-tooltip bottom dark class="mr-2">
                                    <template v-slot:activator="{ on }">
                                        <v-btn text icon v-on="on" class="mr-2" dusk="logout-button">
                                            <v-icon color="#b1b1b1">mdi-eye</v-icon>
                                        </v-btn>
                                    </template>
                                    <span style="opacity:100%;">
                                        <highcharts :options="lineChartOptions3"></highcharts>
                                    </span>
                                </v-tooltip>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </div>

        <div class="ma-0 pa-0" v-if="!reportLast_found" id="ChipsAlert">
            <div v-for="filters in machine_filters" :key="filters" style="max-height:90px;border-radius:5px;" class="ma-0 mb-0 pa-0">
                <div v-if="filters.level == report.alert_level && filters.level_name !== 'Tout voir'" class="col-0" style="color:white;font-weight:800;display:flex;justify-content:center;text-shadow:2px 2px 4px rgba(0, 0, 0, 0.29); height:100%;min-width: 8px;border-radius:5px;">
                    <v-row class="pa-0 ma-3">
                        <v-col class="col pa-0" style="max-width:30%;display:flex;flex-direction:column;border-radius: 5px 0px 0px 5px;background-color:#0010479e;">
                            <v-row style="align-items:center;width:100%;position:relative;top:0px;padding-left: 35px;">
                                <v-col class="col-5" style="font-size:50px;font-weight:bold;text-align:right;">--%</v-col>
                                <v-col style="font-size:13px;text-align:left;font-weight: 400;align-items:center;">
                                    <div >Aucun rapport publié</div>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col class="col pa-3" style="max-width:70%;background-color:white;height:90px;display:flex;flex-direction:column;justify-content: center;text-shadow:none;">
                            <div>
                                <div style="font-size:18px;color:black;padding-left: 20px;"> Nous n'avons pas d'historique dans cette section</div>
                                <div style="font-size:14px;color:black;padding-left: 20px;font-weight:400;display:flex;"> <div style="color:#0e4cef;font-weight:bold;margin-right:5px;">* </div> Aucun rapport n'a été produit ou enregistré ici, en cas de besoin veuillez nous contacter.</div>
                            </div>
                        </v-col>
                        <v-col class="col-1 pa-3" style="border-radius: 0px 5px 5px 0px;max-width:70%;background-color:white;height:90px;display:flex;flex-direction:column;justify-content: center;text-shadow:none;align-items:center;">
                            <v-row style="align-items: center;">                            
                                <v-tooltip bottom dark class="mr-2">
                                    <template v-slot:activator="{ on }">
                                        <v-btn text icon v-on="on" class="mr-2" dusk="logout-button">
                                            <v-icon color="#b1b1b1">mdi-eye</v-icon>
                                        </v-btn>
                                    </template>
                                    <span style="opacity:100%;">
                                        <highcharts :options="lineChartOptions3"></highcharts>
                                    </span>
                                </v-tooltip>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </div> 

        <!-- content --> 
        <v-col color="white" class="ml-0 mr-4 mt-1 pl-3 col" id="ContentReport">
            <div class="pt-0 mt-2" style="border-radius: 5px 5px 0 0;">
                <!-- Différents onglets -->
                <v-col class="col pa-0 ma-0" style="max-width: 100%;">
                    <!-- Vue -->
                    <v-row class="pt-0" style="margin:0px;height:40px;">
                        <v-col class="row ml-0 mt-0 pb-0 pt-0 pl-0" style="position: relative;left:-1px;">
                            <div v-for="vue in vues" :key="vue.id" class="mr-1">
                                <div 
                                    v-if="vue.id == active_special_vue" 
                                    class="ma-auto pl-5 pr-5 pa-2"
                                    style="color: black; font-weight: 400; display: flex; justify-content: left; text-shadow: rgba(0, 0, 0, 0.29) 2px 2px 4px; background-color: rgb(255 255 255); border-radius: 5px 5px 0px 0px; border-left: 1px solid rgba(211, 211, 211, 0.43); border-bottom: none; font-size: 14px; height: 40px; align-items: center; font-weight:bold;"
                                >
                                    <div v-if="active_special_vue == 0 " style="margin-right: 8px;">
                                        <span v-if="rapports_vibratoire_nb > 0" style="font-weight: 800;color: white;background-color: #001047;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ rapports_vibratoire_nb }}</span>
                                        <span v-if="rapports_vibratoire_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ rapports_vibratoire_nb }}</span>
                                    </div>
                                    <div v-if="active_special_vue == 5 " style="margin-right: 8px;">
                                        <span v-if="rapports_online_nb > 0" style="font-weight: 800;color: white;background-color: #001047;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ rapports_online_nb }}</span>
                                        <span v-if="rapports_online_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ rapports_online_nb }}</span>
                                    </div>
                                    <div v-if="active_special_vue == 1 " style="margin-right: 8px;">
                                        <span v-if="rapports_thermographie_nb > 0" style="font-weight: 800;color: white;background-color: #001047;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ rapports_thermographie_nb }}</span>
                                        <span v-if="rapports_thermographie_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ rapports_thermographie_nb }}</span>
                                    </div>
                                    <div v-if="active_special_vue == 2 " style="margin-right: 8px;">
                                        <span v-if="rapports_ultrasons_nb > 0" style="font-weight: 800;color: white;background-color: #001047;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ rapports_ultrasons_nb }}</span>
                                        <span v-if="rapports_ultrasons_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ rapports_ultrasons_nb }}</span>
                                    </div>
                                    <div v-if="active_special_vue == 3 " style="margin-right: 8px;">
                                        <span v-if="rapports_equilibrage_nb > 0" style="font-weight: 800;color: white;background-color: #001047;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ rapports_equilibrage_nb }}</span>
                                        <span v-if="rapports_equilibrage_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ rapports_equilibrage_nb }}</span>
                                    </div>
                                    <div v-if="active_special_vue == 4 " style="margin-right: 8px;">
                                        <span v-if="rapports_expertises_nb > 0" style="font-weight: 800;color: white;background-color: #001047;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ rapports_expertises_nb }}</span>
                                        <span v-if="rapports_expertises_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ rapports_expertises_nb }}</span>
                                    </div>
                                    
                                    {{ vue.label }}
                                </div>
                                <div 
                                    v-else 
                                    class="ma-auto pl-5 pr-5 pa-2"
                                    style="color: black; font-weight: 400; display: flex; justify-content: left; text-shadow: rgba(0, 0, 0, 0.29) 2px 2px 4px; background-color: rgb(243 243 243 / 90%); border-radius: 10px 10px 0px 0px; border: 1px solid #dbdce1; border-bottom: none; font-size: 14px; height: 40px; cursor: pointer;"
                                    @click="loadVue(vue.id)"
                                >
                                    <div v-if="vue.id == 0" style="margin-right: 8px;">
                                        <span v-if="rapports_vibratoire_nb > 0" style="font-weight: 800;color: white;background-color: #939393;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ rapports_vibratoire_nb }}</span>
                                        <span v-if="rapports_vibratoire_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ rapports_vibratoire_nb }}</span>
                                    </div>
                                    <div v-if="vue.id == 5" style="margin-right: 8px;">
                                        <span v-if="rapports_online_nb > 0" style="font-weight: 800;color: white;background-color: #939393;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ rapports_online_nb }}</span>
                                        <span v-if="rapports_online_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ rapports_online_nb }}</span>
                                    </div>
                                    <div v-if="vue.id == 1" style="margin-right: 8px;">
                                        <span v-if="rapports_thermographie_nb > 0" style="font-weight: 800;color: white;background-color: #939393;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ rapports_thermographie_nb }}</span>
                                        <span v-if="rapports_thermographie_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ rapports_thermographie_nb }}</span>
                                    </div>
                                    <div v-if="vue.id == 2" style="margin-right: 8px;">
                                        <span v-if="rapports_ultrasons_nb > 0" style="font-weight: 800;color: white;background-color: #939393;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ rapports_ultrasons_nb }}</span>
                                        <span v-if="rapports_ultrasons_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ rapports_ultrasons_nb }}</span>
                                    </div>
                                    <div v-if="vue.id == 3" style="margin-right: 8px;">
                                        <span v-if="rapports_equilibrage_nb > 0" style="font-weight: 800;color: white;background-color: #939393;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ rapports_equilibrage_nb }}</span>
                                        <span v-if="rapports_equilibrage_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ rapports_equilibrage_nb }}</span>
                                    </div>
                                    <div v-if="vue.id == 4" style="margin-right: 8px;">
                                        <span v-if="rapports_expertises_nb > 0" style="font-weight: 800;color: white;background-color: #939393;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ rapports_expertises_nb }}</span>
                                        <span v-if="rapports_expertises_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ rapports_expertises_nb }}</span>
                                    </div>                                
                                    {{ vue.label }}
                                </div>
                            </div>
                        </v-col>
                    </v-row>

                    <v-data-table
                        :headers="headers"
                        :items="reports_displayed"
                        class="elevation-1 datatable-operation"
                        :items-per-page="5"
                        :loading="loading_datatable"
                        :search="search"
                        :sort-by="['id']"
                        :sort-desc="[true]"
                        v-if="reports_found"
                    >
                        <!-- TOP -->
                        <template v-slot:top>
                            <v-toolbar flat color="white" class="pt-3">
                                <div> 
                                    <v-tooltip v-if="view_details" bottom dark>
                                        <template v-slot:activator="{ on }">
                                            <v-btn class="ma-0 mr-5 col-auto elevation-3" v-on="on" text icon @click="viewDetails()" dusk="logout-button" color="primary" style="max-width: 50px !important;min-width: 45px !important;border-radius: 25px;background-color: #001047;">
                                                <v-icon color="white">mdi-eye</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Masquer le détail des campagnes</span>
                                    </v-tooltip>
                                    <v-tooltip v-if="!view_details" bottom dark>
                                        <template v-slot:activator="{ on }">
                                            <v-btn class="ma-0 mr-5 col-auto elevation-3" v-on="on" text icon @click="viewDetails()" dusk="logout-button" color="red" style="max-width: 50px !important;min-width: 45px !important;border-radius: 25px;background-color: #f15959;">
                                                <v-icon color="white">mdi-eye-off</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Afficher le détail des campagnes</span>
                                    </v-tooltip>
                                </div>
                                <v-col class="col-8">
                                    <div class="elevation-0 pl-5 pt-2 pb-2" >
                                        <h4>Liste des rapports de la machine</h4>
                                    </div>
                                </v-col>
                                <!-- SEARCH -->
                                <v-col style="display: flex; justify-content: end;">
                                    <v-layout class="justify-end mt-7 max-width-300">
                                        <v-text-field
                                            v-model="search"
                                            append-icon="mdi-magnify"
                                            :label="$t('global.search')"
                                        ></v-text-field>
                                    </v-layout>
                                </v-col>
                            </v-toolbar>
                        </template>

                        <!-- PRECO -->
                        <template v-slot:item.preco="{ item }">
                            <v-row style="border-left: 1px solid #dfdfdf;"> 
                                <v-col class="col">
                                    <v-col class="pt-8 mb-0 pl-2 mt-0 mb-0 pb-4" v-if="access_level <= 99 && item.preco !== null" v-html="item.preco" @click="$router.push({ name: 'reported', params: {id: item.id} })" style="font-style: italic;"></v-col>
                                    <v-col class="pt-8 mb-0 pl-2 mt-0 mb-0 pb-4" v-if="access_level <= 99 && item.preco == null" @click="$router.push({ name: 'reported', params: {id: item.id} })" style="font-style: italic;">Aucune préconisation associé.</v-col>
                                    <v-col class="pt-8 mb-0 pl-2 mt-0 mb-0 pb-4" v-if="access_level > 99 && item.step == 5 && item.preco !== null" v-html="item.preco" @click="$router.push({ name: 'reported', params: {id: item.id} })" style="font-style: italic;"></v-col>
                                    <v-col class="pt-8 mb-0 pl-2 mt-0 mb-0 pb-4" v-if="access_level > 99 && item.step == 5 && item.preco == null" @click="$router.push({ name: 'reported', params: {id: item.id} })" style="font-style: italic;">Aucune préconisation associé.</v-col>
                                    <v-col class="pt-8 mb-0 pl-2 mt-0 mb-0 pb-4" v-if="access_level > 99 && item.step != 5" style="font-style: italic;">Le rapport est en cours d'édition. </br>Les préconisations seront disponibles dés que celui ci sera publié.</v-col>
                                    <v-row class="pl-6 mt-0 mb-3">
                                        <v-tooltip v-if="item.step != 5 && access_level <= 99" top class="mr-2">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer mr-2"
                                                        v-bind="attrs" v-on="on"
                                                        color="orange"
                                                        @click="$router.push({ name: 'redit', params: {id: item.id} })"
                                                >
                                                    mdi-file-edit
                                                </v-icon>
                                            </template>
                                            <span>Editer le rapport</span>
                                        </v-tooltip>

                                        <v-tooltip v-if="item.step == 5 && access_level <= 99" top class="mr-2">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer mr-2"
                                                        v-bind="attrs" v-on="on"
                                                        @click="$router.push({ name: 'redit', params: {id: item.id} })"
                                                >
                                                    mdi-file-lock
                                                </v-icon>
                                            </template>
                                            <span>Ce rapport est publié et par conséquent son édition est bloquée.<br>
                                                Cliquez ici pour voir le format d'édition de ce rapport.</span>
                                        </v-tooltip>

                                        <v-tooltip  top class="mr-2" v-if="item.released_at != null">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer mr-2"
                                                        v-bind="attrs" v-on="on"
                                                        @click="$router.push({ name: 'reported', params: {id: item.id} })"
                                                >
                                                    mdi-eye
                                                </v-icon>
                                            </template>
                                            <span>Consulter le rapport complet</span>
                                        </v-tooltip>

                                        <v-tooltip  top class="mr-2" v-if="item.released_at == null && access_level < 100">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer mr-2"
                                                        v-bind="attrs" v-on="on"
                                                        @click="$router.push({ name: 'reported', params: {id: item.id} })"
                                                >
                                                    mdi-eye
                                                </v-icon>
                                            </template>
                                            <span>Consulter le rapport complet</span>
                                        </v-tooltip>
                                        <!--
                                        <v-tooltip  top class="mr-2" v-if="item.type === 1 || item.type === 2">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer mr-2"
                                                        v-bind="attrs" v-on="on"
                                                        @click="chooseToPrintPDF(item)"
                                                >
                                                    mdi-printer
                                                </v-icon>
                                            </template>
                                            <span>
                                                <div style="min-width: 200px;position:relative;z-index: 10;">
                                                <p>Pour que l'impression soit optimale pensez à appuyer sur le bouton ci dessous sur Chrome</p>
                                                <img src="https://techview.fr/wp-content/uploads/2024/10/Capture-decran-2024-10-14-120731.png">
                                                <p>Et ci dessous sur Firefox</p>
                                                <img src="https://techview.fr/wp-content/uploads/2024/10/Capture-decran-2024-10-14-120130.png">
                                            </div>
                                            </span>
                                        </v-tooltip>
                                        <v-tooltip  top class="mr-2" v-if="item.type === 1 || item.type === 2 && isLoaded">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer mr-2"
                                                        v-bind="attrs" v-on="on"
                                                        @click="printContent(item)"
                                                >
                                                    mdi-printer
                                                </v-icon>
                                            </template>
                                            <span>
                                                <div style="min-width: 200px;position:relative;z-index: 10;">
                                                    <p>Pour que l'impression soit optimale pensez à appuyer sur le bouton ci dessous sur Chrome</p>
                                                    <img src="https://techview.fr/wp-content/uploads/2024/10/Capture-decran-2024-10-14-120731.png">
                                                    <p>Et ci dessous sur Firefox</p>
                                                    <img src="https://techview.fr/wp-content/uploads/2024/10/Capture-decran-2024-10-14-120130.png">
                                                </div>
                                            </span>
                                        </v-tooltip>
                                        -->

                                    </v-row>                      
                                </v-col> 

                                <v-col v-if="view_details" class="col-1" style="justify-content: start;
                                                            display: flex;
                                                            align-items: center;
                                                            padding-top: 50px;
                                                            flex-direction:column;"
                                >
                                    <div v-if="item.presta == 0">
                                        <div style="font-size: 20px;
                                                    border: 1px solid grey;
                                                    border-radius: 50px;
                                                    padding: 10px;
                                                    color: grey;
                                                    margin-bottom: 15px;"
                                        >VIB</div>
                                    </div>
                                    <div v-if="item.presta == 1">
                                        <div style="font-size: 20px;
                                                    border: 1px solid grey;
                                                    border-radius: 50px;
                                                    padding: 10px;
                                                    color: grey;
                                                    margin-bottom: 15px;"
                                        >TH</div>
                                    </div>
                                    <div v-if="item.presta == 2">
                                        <div style="font-size: 20px;
                                                    border: 1px solid grey;
                                                    border-radius: 50px;
                                                    padding: 10px;
                                                    color: grey;
                                                    margin-bottom: 15px;"
                                        >US</div>
                                    </div>
                                    <div v-if="item.presta == 3">
                                        <div style="font-size: 20px;
                                                    border: 1px solid grey;
                                                    border-radius: 50px;
                                                    padding: 10px;
                                                    color: grey;
                                                    margin-bottom: 15px;"
                                        >EQ</div>
                                    </div>
                                    <div v-if="item.presta == 4">
                                        <div style="font-size: 20px;
                                                    border: 1px solid grey;
                                                    border-radius: 50px;
                                                    padding: 10px;
                                                    color: grey;
                                                    margin-bottom: 15px;"
                                        >EXP</div>
                                    </div>
                                    <div v-if="item.presta == 5">
                                        <div style="font-size: 20px;
                                                    border: 1px solid grey;
                                                    border-radius: 50px;
                                                    padding: 10px;
                                                    color: grey;
                                                    margin-bottom: 15px;"
                                        >OL</div>
                                    </div>

                                    <v-icon v-if="item.type == 0 || item.type == 3" class="pointer mb-2"
                                            v-bind="attrs" v-on="on"
                                            style="font-size:60px;color: #c3c3c3;"
                                            @click="$router.push({ name: 'reported', params: {id: item.id} })"
                                    >
                                        mdi-file-pdf
                                    </v-icon>

                                    <v-icon v-if="item.type == 1" class="pointer mb-2"
                                            v-bind="attrs" v-on="on"
                                            style="font-size:60px;color: #c3c3c3;"
                                            @click="$router.push({ name: 'reported', params: {id: item.id} })"
                                    >
                                        mdi-file-document
                                    </v-icon>
                                </v-col>

                                <v-col v-if="!view_details" class="col-1" style="justify-content: start;
                                                            display: flex;
                                                            align-items: center;
                                                            padding-top: 50px;
                                                            flex-direction:column;"
                                >
                                    <div v-if="item.presta == 0">
                                        <div style="font-size: 14px;color: grey;">VIB</div>
                                    </div>
                                    <div v-if="item.presta == 1">
                                        <div style="font-size: 14px;color: grey;">TH</div>
                                    </div>
                                    <div v-if="item.presta == 2">
                                        <div style="font-size: 14px;color: grey;">US</div>
                                    </div>
                                    <div v-if="item.presta == 3">
                                        <div style="font-size: 14px;color: grey;">EQ</div>
                                    </div>
                                    <div v-if="item.presta == 4">
                                        <div style="font-size: 20px;color: grey;">EXP</div>
                                    </div>
                                    <div v-if="item.presta == 5">
                                        <div style="font-size: 20px;color: grey;">OL</div>
                                    </div>

                                    <v-icon v-if="item.type == 0 || item.type == 3" class="pointer mb-2"
                                            v-bind="attrs" v-on="on"
                                            style="font-size:35px;color: #c3c3c3;"
                                            @click="$router.push({ name: 'reported', params: {id: item.id} })"
                                    >
                                        mdi-file-pdf
                                    </v-icon>

                                    <v-icon v-if="item.type == 1" class="pointer mb-2"
                                            v-bind="attrs" v-on="on"
                                            style="font-size:35px;color: #c3c3c3;"
                                            @click="$router.push({ name: 'reported', params: {id: item.id} })"
                                    >
                                        mdi-file-document
                                    </v-icon>
                                </v-col>    
                                
                                <v-row class="pb-0 ma-0 mb-3 col-5" v-if="view_details">
                                    <!-- REPORT INFOS -->
                                    <v-col class="col pt-0" style="background-color: #00000005;position: relative;left: 15px;">
                                        <v-row class="ma-0" style="color: black;">
                                            <v-col class="pa-8 pl-0 pt-4 pb-2 pt-0">
                                                <div class="mt-0" style="font-size: 12px;margin-left: 8px;font-weight:bold;">Informations complémentaires sur le rapport :</div>
                                                <v-col class="mt-3 pa-0"></v-col>
                                                <v-col v-for="analyst in analysts_datas" :key="analyst" class="pa-0 mb-0">
                                                    <v-row class="mb-0 mt-0" v-if="analyst.id === item.creator" style="font-size: 12px;margin-left: 10px;">Référence : {{item.uid}} <v-icon class="ml-1" @click="copyValue(item.uid)" color="primary" small>mdi-content-copy</v-icon></v-row>
                                                    <v-row class="mb-0 mt-0" v-if="analyst.id === item.creator" style="font-size: 12px;margin-left: 10px;">Créé le : {{item.created_at}}</v-row>
                                                    <v-row class="mb-0 mt-0" v-if="analyst.id === item.creator" style="font-size: 12px;margin-left: 10px;">Créé par : {{analyst.name}}</v-row>
                                                    <v-row class="mb-0 mt-0" v-if="analyst.id === item.creator" style="font-size: 12px;margin-left: 10px;">Révisions : {{item.rev}}</v-row>
                                                    <v-row class="mb-0 mt-0" v-if="analyst.id === item.creator" style="font-size: 12px;margin-left: 10px;">Dernière modification : {{item.updated_at}}</v-row>
                                                    <v-row class="mb-0 mt-0" v-if="analyst.id === item.creator && item.released_at" style="font-size: 12px;margin-left: 10px;font-weight: bold;">Date de la publication : {{item.released_at}}</v-row>
                                                </v-col>
                                                <v-col class="mt-2 pa-0"></v-col>
                                                <v-col v-for="analyst in analysts_datas" :key="analyst" class="pa-0 mb-0 mt-0">
                                                    <v-row class="mb-0 mt-0" v-if="item.intervention_date && analyst.id === item.intervenant" style="font-size: 12px;margin-left: 10px;font-weight: bold;">Date de l'intervention : {{item.intervention_date}}</v-row>
                                                    <v-row class="mb-0 mt-0" v-if="analyst.id === item.intervenant" style="font-size: 12px;margin-left: 10px;font-weight: bold;">Intervenant : {{analyst.name}}</v-row>
                                                </v-col>
                                                <v-col class="mt-2 pa-0"></v-col>
                                                <v-col v-for="analyst in analysts_datas" :key="analyst" class="pa-0 mb-0 mt-0">
                                                    <v-row class="mb-0 mt-0" v-if="analyst.id === item.manager" style="font-size: 12px;margin-left: 10px;font-weight: bold;">Manager : {{analyst.name}}</v-row>
                                                    <v-row class="mb-0 mt-0" v-if="analyst.id === item.analyst" style="font-size: 12px;margin-left: 10px;font-weight: bold;">Analyste : {{analyst.name}}</v-row>
                                                    <v-row class="mb-0 mt-0" v-if="analyst.id === item.validator" style="font-size: 12px;margin-left: 10px;font-weight: bold;">Validateur : {{analyst.name}}</v-row>
                                                </v-col>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row> 
                            </v-row>
                        </template>

                        <!-- ID --> 
                        <template v-slot:item.id="{ item }" >
                            <v-col v-if="item.step == 5 && item.released_at != null && (item.alert_level !== 0 && item.alert_level > 10 && item.alert_level < 20)" class="col justify-start h-100 pt-7" style="max-width: 60px;background-color: #FFCB05;height:90%;border-radius:5px;">
                                <v-row style="color:white;" class="row mb-3 justify-center"> #{{ item.id }} </v-row>
                                <v-icon color="white" large>
                                    mdi-alert
                                </v-icon>
                            </v-col>

                            <v-col v-if="item.step == 5 && item.released_at != null && (item.alert_level !== 0 && item.alert_level > 20 && item.alert_level < 30)" class="col justify-start h-100 pt-7" style="max-width: 60px;background-color: #f44336;height:90%;border-radius:5px;">
                                <v-row style="color:white;" class="row mb-3 justify-center"> #{{ item.id }} </v-row>
                                <v-icon color="white" large>
                                    mdi-alert
                                </v-icon>
                            </v-col>

                            <v-col v-if="item.step == 5 && item.released_at != null && (item.alert_level !== 0 && item.alert_level > 30)" class="col justify-start h-100 pt-7" style="max-width: 60px;background-color: #000000;height:90%;border-radius:5px;">
                                <v-row style="color:white;" class="row mb-3 justify-center"> #{{ item.id }} </v-row>
                                <v-icon color="white" large>
                                    mdi-alert
                                </v-icon>
                            </v-col>

                            <v-col v-if="item.step == 5 && item.released_at != null && (item.alert_level === -1 || item.alert_level === 0)" class="col justify-start h-100 pt-7" style="max-width: 60px;background-color: #939393bf;height:90%;border-radius:5px;">
                                <v-row style="color:white;" class="row mb-3 justify-center"> #{{ item.id }} </v-row>
                                <v-icon color="white" large>
                                    mdi-help-rhombus
                                </v-icon>
                            </v-col>

                            <v-col v-if="item.step == 5 && item.released_at != null && (item.alert_level === 1 || item.alert_level === 2 || item.alert_level === 3 || item.alert_level === 9)" class="col justify-start h-100 pt-7" style="max-width: 60px;background-color: #4CAF50;height:90%;border-radius:5px;">
                                <v-row style="color:white;" class="row mb-3 justify-center"> #{{ item.id }} </v-row>
                                <v-icon color="white" large>
                                    mdi-check-circle
                                </v-icon>
                            </v-col>



                            <v-col v-if="item.step != 5 && access_level > 99" class="col justify-start h-100 pt-7" style="max-width: 60px;background-color: #939393bf;height:90%;border-radius:5px;">
                                <v-row style="color:white;" class="row mb-3 justify-center"> #{{ item.id }} </v-row>
                                <v-icon color="white" large>
                                    mdi-help-rhombus
                                </v-icon>
                            </v-col>



                            <v-col v-if="access_level <= 99 && item.step != 5 && item.released_at != null && (item.alert_level !== 0 && item.alert_level > 10 && item.alert_level < 20)" class="col h-100 pt-7 pl-0 pr-0 pb-0" style="max-width: 60px;background-color: #FFCB05;height:90%;border-radius:5px;align-items: center;justify-content: start;display: flex;flex-direction: column;">
                                <v-row style="color:white;max-height: 20px;" class="row mb-3 justify-center"> #{{ item.id }} </v-row>
                                <v-icon color="white" large>
                                    mdi-alert
                                </v-icon>
                                <div style="
                                    background-color: rgb(255 255 255 / 59%);
                                    text-align: center;
                                    margin-top: 20px;
                                    font-weight: 600;
                                    width: 100%;
                                    height: 100%;
                                    align-items: center;
                                    justify-content: center;
                                    display: flex;
                                ">
                                    <v-icon color="black" style="font-size:30px;">
                                        mdi-update
                                    </v-icon>
                                </div>
                            </v-col>

                            <v-col v-if="access_level <= 99 && item.step != 5 && item.released_at != null && (item.alert_level !== 0 && item.alert_level > 20 && item.alert_level < 30)" class="col h-100 pt-7 pl-0 pr-0 pb-0" style="max-width: 60px;background-color: #f44336;height:90%;border-radius:5px;align-items: center;justify-content: start;display: flex;flex-direction: column;">
                                <v-row style="color:white;max-height: 20px;" class="row mb-3 justify-center"> #{{ item.id }} </v-row>
                                <v-icon color="white" large>
                                    mdi-alert
                                </v-icon>
                                <div style="
                                    background-color: rgb(255 255 255 / 59%);
                                    text-align: center;
                                    margin-top: 20px;
                                    font-weight: 600;
                                    width: 100%;
                                    height: 100%;
                                    align-items: center;
                                    justify-content: center;
                                    display: flex;
                                ">
                                    <v-icon color="black" style="font-size:30px;">
                                        mdi-update
                                    </v-icon>
                                </div>
                            </v-col>

                            <v-col v-if="access_level <= 99 && item.step != 5 && item.released_at != null && (item.alert_level !== 0 && item.alert_level > 30)" class="col h-100 pt-7 pl-0 pr-0 pb-0" style="max-width: 60px;background-color: #000000;height:90%;border-radius:5px;align-items: center;justify-content: start;display: flex;flex-direction: column;">
                                <v-row style="color:white;max-height: 20px;" class="row mb-3 justify-center"> #{{ item.id }} </v-row>
                                <v-icon color="white" large>
                                    mdi-alert
                                </v-icon>
                                <div style="
                                    background-color: rgb(255 255 255 / 59%);
                                    text-align: center;
                                    margin-top: 20px;
                                    font-weight: 600;
                                    width: 100%;
                                    height: 100%;
                                    align-items: center;
                                    justify-content: center;
                                    display: flex;
                                ">
                                    <v-icon color="black" style="font-size:30px;">
                                        mdi-update
                                    </v-icon>
                                </div>
                            </v-col>

                            <v-col v-if="access_level <= 99 && item.step != 5 && item.released_at != null && (item.alert_level === -1 || item.alert_level === 0)" class="col h-100 pt-7 pl-0 pr-0 pb-0" style="max-width: 60px;background-color: #939393bf;height:90%;border-radius:5px;align-items: center;justify-content: start;display: flex;flex-direction: column;">
                                <v-row style="color:white;max-height: 20px;" class="row mb-3 justify-center"> #{{ item.id }} </v-row>
                                <v-icon color="white" large>
                                    mdi-help-rhombus
                                </v-icon>
                                <div style="
                                    background-color: rgb(255 255 255 / 59%);
                                    text-align: center;
                                    margin-top: 20px;
                                    font-weight: 600;
                                    width: 100%;
                                    height: 100%;
                                    align-items: center;
                                    justify-content: center;
                                    display: flex;
                                ">
                                    <v-icon color="black" style="font-size:30px;">
                                        mdi-update
                                    </v-icon>
                                </div>
                            </v-col>

                            <v-col v-if="access_level <= 99 && item.step != 5 && item.released_at != null && (item.alert_level === 1 || item.alert_level === 2 || item.alert_level === 3 || item.alert_level === 9)" class="col h-100 pt-7 pl-0 pr-0 pb-0" style="max-width: 60px;background-color: #4CAF50;height:90%;border-radius:5px;align-items: center;justify-content: start;display: flex;flex-direction: column;">
                                <v-row style="color:white;max-height: 20px;" class="row mb-3 justify-center"> #{{ item.id }} </v-row>
                                <v-icon color="white" large>
                                    mdi-check-circle
                                </v-icon>
                                <div style="
                                    background-color: rgb(255 255 255 / 59%);
                                    text-align: center;
                                    margin-top: 20px;
                                    font-weight: 600;
                                    width: 100%;
                                    height: 100%;
                                    align-items: center;
                                    justify-content: center;
                                    display: flex;
                                ">
                                    <v-icon color="black" style="font-size:30px;">
                                        mdi-update
                                    </v-icon>
                                </div>
                            </v-col>
                        </template>

                        <!-- ALERT LEVEL --> 
                        <template v-slot:item.indice="{ item }" >
                            <v-col class="col justify-start h-100 pt-12" style="max-width: 60px;">
                                <v-row v-if="item.step == 5" class="row mb-3 justify-center" style="font-size:30px; font-weight: bold;"> {{ item.indice }}% </v-row>
                                <v-row v-if="item.step != 5 && access_level <= 99" class="row mb-3 justify-center" style="font-size:30px; font-weight: bold;"> *{{ item.indice }}% </v-row>
                                <v-row v-if="item.step != 5 && access_level > 99" class="row mb-3 justify-center" style="font-size:30px; font-weight: bold;">
                                    <v-icon style="color:rgb(0 16 71);" large>
                                        mdi-file-document-edit-outline
                                    </v-icon> </v-row>   
                            </v-col>
                        </template>
                    </v-data-table>

                    <div v-if="!reports_found">
                        <!--CHIPS NO ALERT-->
                        <div class="ma-0 pa-0" id="ChipsNoAlert"> 
                            <div style="height:150px;border-radius:0px 0px 5px 5px;background-color:white;" class="ma-0 pa-0">
                                <div class="col-0" style="color:black;font-weight:800;display:flex;justify-content:center; height:100%;min-width: 8px;border-radius:5px;" >
                                    <v-col class="pa-0 pt-8 pb-4" style="display:flex;justify-content:center;align-items:center;">
                                        <div style="text-align:center;opacity:0.5;">  
                                            <v-icon color="primary" style="height:36px;font-size:36px;">
                                                mdi-file-chart
                                            </v-icon>
                                            <br>
                                            <div class="pa-3 pb-0">
                                                Une campagne prévue ?
                                            </div>
                                            <p class="pb-0" style="font-weight:400">
                                                Nous n'avons encore jamais réalisé de rapport pour votre machine sur ce type de prestation.
                                            </p>
                                        </div>
                                    </v-col>
                                </div>
                            </div>
                        </div> 
                    </div>
                </v-col>
            </div>          

            <!-- SNACKBAR INFO DISPLAY -->
            <v-snackbar
                v-model="snackbar"
                :color="snackbar_type">
                {{ snackbar_text }}
                <template v-slot:action="{ attrs }">
                    <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                </template>
            </v-snackbar>

            <!-- Modal next update -->
            <v-dialog v-model="dialog_next_update" max-width="500px">
                <v-card>
                    <v-card-title>
                        <span class="headline">
                            Fonctionnalité bientôt disponible
                        </span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <br/>
                    <p class="pl-4 pr-4">
                        Cette fonctionnalité est en cours de développement et sera bientôt disponible.
                        Elle vous permettra entre autre de filter et de rechercher précisément via des mots clés votre cible.
                        Un rapport, une machine, une référence...
                        Le but, vous faire gagner du temps ! 
                    </p>
                    <!-- Bouton pour fermer -->
                    <v-card-actions class="mb-3" style="position:absolute;top:20px;right:2px">
                        <v-btn @click="dialog_show = false;" style="color:red;max-width: 30px;padding: 0 0 0 5px;min-width: 10px!important;height: 30px!important;">
                            <v-icon left>mdi-close</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-col>

        <!-- --------------------------------- -->
        <!-- PRINT -->
        <div ref="contentToPrint" class="pt-0 mb-3 mt-1 printable-content" id="printDivision" style="position: fixed; left: 0; top: -20px; width: 100%; z-index: -100;">
            <!-- Page de garde -->
            <div id="presentation-page-print" style="height:1020px; display: block;flex-direction: column; justify-content: space-between; position: relative; page-break-after: always;">
                <div>  
                    <div>
                        <div style="height:150px; background-image: url('https://techview.fr/wp-content/uploads/2024/09/OnLine2-report.png'); background-repeat: no-repeat; background-position: center; background-size: cover;">
                            <img style="width:100px; margin:25px;" src="https://techview.fr/wp-content/uploads/2023/08/40b88e9c74d694e5fe469dbaa779b52c-2.png">
                        </div>
                        <div style="background-color:#014181; color: white; text-align: center;">
                            <h2 style="padding: 5px; margin: 0;font-size: 24px;">EXPERTS INSIDE</h2>
                        </div>
                    </div>

                    <div>
                        <div v-if="campain.id === print.id_campain" style="margin-top: 10px; text-align: center;font-size: 24px;font-weight: 600;text-transform: uppercase;color: #23b5d3;">
                            <p>{{ campain.name }}
                                <br>du {{ print.intervention_date }}
                            </p>
                            <div>
                                <div v-if="print.id_professional === professional.id" style="display: flex;flex-direction: column;align-items: center; color: black;">
                                    <img style="max-height :150px;" :src="professional.logo">
                                    <p>{{ professional.name }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>

                <div style="margin-top: 50px; position: absolute; bottom: 0; width: 100%;">
                    <div >
                        <v-col class="col-12">
                            <div style="background-color: #014181;color: white;display: flex;flex-direction: column;padding: 20px; font-size: 14px; width: 60%;box-shadow: -15px -15px 0px 0px #23b5d3;margin-left: 15px;">
                                <div style="line-height: 1.2;">
                                    <p style="margin:0;">Date de mise à disposition du rapport {{ print.released_at }}</p>
                                    <p style="margin:0;">Interlocuteurs : {{ print.interlocutor }}</p>
                                    <p style="margin:0;">Affaire TECHVIEW : {{ campain.affaire_number}}</p>
                                </div>

                                <div v-for="analyst in analysts_datas" :key="analyst">
                                    <v-row style="display: flex;align-items: flex-end;line-height: 1.2;" v-if="print.intervenant == analyst.id">
                                        <v-col style="width:65%;">
                                            <p style="margin-top:20px;margin-bottom:5px;font-weight: 600;">Mesures sur site {{ print.intervention_date }}</p>
                                            <p style="margin:0!important;font-style: italic;">{{ analyst.name }}</p>
                                            <p style="margin:0!important;"><v-icon color="white" size="14">mdi-phone-outline</v-icon> {{ analyst.tel }}</p>
                                            <p style="margin:0!important;"><v-icon color="white" size="14">mdi-email-outline</v-icon> <span style="text-decoration: underline;">{{ analyst.email }}</span></p>
                                        </v-col>
                                            <v-col style="width:35%;" v-if="analyst.logo != null">
                                            <img :src="analyst.logo" style="width: 100px;">
                                        </v-col>
                                    </v-row>
                                </div>
                            
                                <div v-for="analyst in analysts_datas" :key="analyst">
                                    <v-row style="display: flex;align-items: flex-end;line-height: 1.2;" v-if="print.analyst === analyst.id">
                                        <v-col style="width:65%;">
                                            <p style="margin-top:20px;margin-bottom:5px;font-weight: 600;">Analyse des mesures</p>
                                            <p style="margin:0!important;font-style: italic;">{{ analyst.name }}</p>
                                            <p style="margin:0!important;"><v-icon color="white" size="14">mdi-phone-outline</v-icon> {{ analyst.tel }}</p>
                                            <p style="margin:0!important;"><v-icon color="white" size="14">mdi-email-outline</v-icon> <span style="text-decoration: underline;">{{ analyst.email }}</span></p>
                                        </v-col>
                                        <v-col style="width:35%;" v-if="analyst.logo != null">
                                            <img :src="analyst.logo" style="width: 100px;">
                                        </v-col>
                                    </v-row>
                                </div>

                                <div v-for="analyst in analysts_datas" :key="analyst">
                                    <v-row style="display: flex;align-items: flex-end;line-height: 1.2;" v-if="print.validator === analyst.id">
                                        <v-col style="width:65%;">
                                            <p style="margin-top:20px;margin-bottom:5px;font-weight: 600;">Validation du rapport</p>
                                            <p style="margin:0;font-style: italic;">{{ analyst.name }}</p>
                                            <p style="margin:0;"><v-icon color="white" size="14">mdi-phone-outline</v-icon> {{ analyst.tel }}</p>
                                            <p style="margin:0;"><v-icon color="white" size="14">mdi-email-outline</v-icon> <span style="text-decoration: underline;">{{ analyst.email }}</span></p>
                                        </v-col>
                                        <v-col style="width:35%;" v-if="analyst.logo != null">
                                            <img :src="analyst.logo" style="width: 100px;">
                                        </v-col>
                                    </v-row>
                                </div>
                                
                            </div>
                            <!-- Pied de page -->
                            <div style="margin-top: 25px; background-color: #014181; color: white; text-align: center; font-size: 10px; padding: 5px; display: flex; flex-direction: column; justify-content: flex-end;">
                                <p style="margin:0!important;">Techview - https://techview.fr - 05.56.95.51.92</p>
                                <p style="margin:0!important;">S.A.S au capital de 100 000 Euros - SIRET 793 684 960 00024 - Code A.P.E 8559a - TVA FR63 793 684 960</p>
                                <p style="margin:0!important;">11 rue Pierre et Marie Curie - 33290 Blanquefort</p>
                            </div>
                        </v-col>
                    </div>
                </div>  
            </div>

            <!-- Page rapport -->
            <v-col class="pa-0 pt-1 col rounded">
                <div v-if="loading_datatable == false" flat style="height: 1020px; max-width: 100%; width: 100%; box-sizing: border-box;" class="pt-0 mb-3">
                    <!-- REPORT HEADER -->
                    <v-row style="background-color:white;margin:0; position: relative; z-index: 10;">
                        <v-col class="col pa-0 pt-1 pb-2">
                            <div style="display: flex;">
                                <v-col class="col-2 pl-5">
                                    <img style="width:100px" src="https://techview.fr/wp-content/uploads/2023/08/40b88e9c74d694e5fe469dbaa779b52c-2.png">
                                </v-col>
                                <v-col v-if="print.id_machine === machine.id" class="col" style="display: flex;align-items: center; color: black; justify-content: center; text-align: center; width: 100%;">
                                    <p style="font-size:16px">Rapport de l'équipement "<span style="font-weight: 800;">{{ machine.label}}</span>"
                                        <br>dans la campagne "<span style="font-weight: 800;">{{ campain.name}}</span>"
                                    </p> 
                                </v-col>
                            </div>

                            <div style="margin-top: 0!important; padding: 0!important; display: flex; flex-direction: column;">
                                <div v-for="filters in machine_filters" :key="filters" style="max-height:50px;border-radius:5px;">
                                    <div  v-if="filters.level == print.alert_level && filters.level_name !== 'Tout voir'" :to="{ path: '/campain/' + campain.id}" class="col-0" style="color:white;font-weight:800;display:flex;justify-content:center;text-shadow:2px 2px 4px rgba(0, 0, 0, 0.29); height:100%;min-width: 8px;border-radius:5px;" >
                                        <div class="pa-0 ma-0" style="border-radius: 5px; overflow: hidden; display: flex; width:100%;">
                                            <v-col class="col pa-0" style="width:35%;display:flex;flex-direction:column; justify-content: center; height: 50px;" :style="{backgroundColor: filters.level_color}">
                                                <div style="align-items:center;width:100%; display: flex">
                                                    <v-col class="col-2" style="margin-left:12px; font-size:18px;font-weight:bold;text-align:right;">{{ print.indice }}%</v-col>
                                                    <v-col style="padding-left:40px; font-size:10px;text-align:left;font-weight: 400;align-items:center;">
                                                        <div  v-if="report_position == 1 && report_position == report_nb">Rapport initial (seul rapport existant)</div>
                                                        <div  v-if="report_position == 1 && report_position != report_nb">Rapport initial (sur {{ report_nb }} existants)</div> 
                                                        <div  v-if="report_position > 1 && report_position == report_nb">Rapport n°{{ report_position }} (dernier en date)</div>
                                                        <div  v-if="report_position > 1 && report_position != report_nb">Rapport n°{{ report_position }} (sur {{ report_nb }} existants)</div>
                                                        <div  v-if="report.released_at">Publié le {{ print.released_at }}</div> 
                                                        <div  v-if="!report.released_at">Date de publication non définie</div> 
                                                    </v-col>
                                                </div>
                                            </v-col>
                                            <v-col class="col pa-3" style="width:65%;background-color:#dddddd;height:50px;display:flex;flex-direction:column;justify-content: center;text-shadow:none;">
                                                <div v-for="state in machine_filters" :key="state">
                                                    <div v-if="state.percent === print.indice" style="font-size:14px;color:black;padding-left: 20px;"> {{ state.annotation }}</div>
                                                    <div v-if="state.percent === print.indice && report_position == 1" style="font-size:10px;color:black;padding-left: 20px;font-weight:400;display:flex;"> <div style="color:#0e4cef;font-weight:bold;margin-right:5px;">* </div> Il s'agit du premier rapport publié sur cette application pour ce moyen.</div>
                                                    <div v-if="state.percent === print.indice && previous_indice < print.indice && report_position != 1" style="font-size:10px;color:black;padding-left: 20px;font-weight:400;display:flex;"> <div style="color:green;font-weight:bold;margin-right:5px;">↗ </div> Evolution positive depuis le {{previous_date}} (EDS {{previous_indice}}%)</div>
                                                    <div v-if="state.percent === print.indice && previous_indice == print.indice && report_position != 1" style="font-size:10px;color:black;padding-left: 20px;font-weight:400;display:flex;"> <div style="color:black;font-weight:bold;margin-right:5px;">= </div> Pas d'évolution depuis le {{previous_date}} (EDS {{previous_indice}}%)</div>
                                                    <div v-if="state.percent === print.indice && previous_indice > print.indice && report_position != 1" style="font-size:10px;color:black;padding-left: 20px;font-weight:400;display:flex;"> <div style="color:red;font-weight:bold;margin-right:5px;">↘ </div>Evolution négative depuis le {{previous_date}} (EDS {{previous_indice}}%)</div>
                                                </div>
                                            </v-col>
                                        </div>
                                    </div>
                                </div>
                                <!-- Sous-ensemble -->
                                <v-row v-for="machine in machines" :key="machine" v-if="machine.id === report.id_machine" style="display: flex; align-items: center; margin: 10px 0 0 0;">
                                    <v-chip style="padding: 0 10px; margin-bottom: 0px!important;">{{ $t('report.subassembly_equipment') }} : 
                                        <template v-for="subassembly in machine.subassemblies" :key="subassembly">
                                            {{ subassembly.label }} |
                                        </template>
                                    </v-chip>
                                </v-row>
                            </div>                            
                        </v-col> 
                    </v-row>

                    <div v-if="img_urls.length > 1">
                        <v-row >
                            <!-- REPORT CONTENT -->
                            <v-col class="col-7">
                                <v-card>
                                    <validation-observer ref="observer" v-slot="{ invalid }">
                                        <v-card-text class="pb-0" style="padding:0!important">
                                            <v-col class="pa-0">
                                                <v-col v-if="print.context || print.comment || print.preco" class="pa-0 pl-0 col" ref="contextHeightRef">
                                                    <!-- REPORT CONTEXT -->
                                                    <v-row class="pt-0 pl-3 pr-3">
                                                        <v-col class="pa-0 pl-0 col">
                                                            <template v-if="print.context">
                                                                <p  class="d-inline-block" style="position: relative; top: 17px; background-color: white; z-index: 10; margin-left: 15px; padding: 0px 5px; font-weight: bold; width: 259px;font-size: 12px;">
                                                                    {{ $t('report.context') }}
                                                                </p>
                                                                <div style="border: 1px solid #bdbdbd; padding: 10px 20px; border-radius: 5px;margin: 0px 0px;font-size: 13px;line-height: 1.2;">
                                                                    <div v-html="print.context"></div>
                                                                </div>
                                                            </template>
                                                        </v-col>

                                                    </v-row>

                                                    <!-- REPORT COMMENT -->
                                                    <template v-if="print.comment">
                                                        <v-col class="col" style="padding-left: 0; padding-right: 0;">
                                                            <p style="position: relative; top: 17px; background-color: white; z-index: 10; margin-left: 15px; padding: 0px 5px;font-weight: bold; display:inline-block;font-size: 12px;">
                                                                {{ $t('report.comment') }}
                                                            </p>
                                                            <div style="border: 1px solid #bdbdbd; padding: 10px 20px; border-radius: 5px; margin: 0px 0px;font-size: 13px;line-height: 1.2;">
                                                                <div v-html="print.comment"></div>
                                                            </div>
                                                        </v-col>
                                                    </template>
                                                
                                                    <!-- REPORT PRECO -->
                                                    <template v-if="print.preco">
                                                        <p class="d-inline-block" style="position: relative; top: 17px; background-color: white; z-index: 10; margin-left: 15px; padding: 0px 5px; font-weight: bold; width: 212px;font-size: 12px;">
                                                            {{ $t('report.preco') }}
                                                        </p>
                                                        <div style="border: 1px solid #bdbdbd; padding: 10px 20px; border-radius: 5px; margin: 0px 0px;font-size: 13px;line-height: 1.2;">
                                                            <div v-html="print.preco"></div>
                                                        </div>
                                                    </template>
                                                </v-col>                                        
                                            </v-col>
                                        </v-card-text>
                                    </validation-observer>
                                </v-card>
                            </v-col>

                            <!-- REPORT IMAGES SECTION -->
                            <v-col>
                                <v-col class="col" v-for="(url, index) in img_urls" :key="index" style="max-height:250mm;">
                                    <img :src="url" alt="Image du rapport" style="object-fit: fill; width: 100%;">
                                </v-col>
                            </v-col>
                        </v-row>
                    </div>
                    <div v-else>
                        <v-card>
                            <validation-observer ref="observer" v-slot="{ invalid }">
                                <v-card-text class="pb-0" style="padding:0!important">
                                    <v-col class="pa-0">
                                        <v-col v-if="print.context || print.comment || print.preco" class="pa-0 pl-0 col" ref="contextHeightRef">
                                            <!-- REPORT CONTEXT -->
                                            <v-row class="pt-0 pl-3 pr-3">
                                                <v-col class="pa-0 pl-0 col">
                                                    <template v-if="print.context">
                                                        <p  class="d-inline-block" style="position: relative; top: 17px; background-color: white; z-index: 10; margin-left: 15px; padding: 0px 5px; font-weight: bold; width: 259px;font-size: 12px;">
                                                            {{ $t('report.context') }}
                                                        </p>
                                                        <div style="border: 1px solid #bdbdbd; padding: 10px 20px; border-radius: 5px;margin: 0px 0px;font-size: 13px;line-height: 1.2;">
                                                            <div v-html="print.context"></div>
                                                        </div>
                                                    </template>
                                                </v-col>

                                            </v-row>

                                            <!-- REPORT COMMENT -->
                                            <v-row class="pt-0 pl-3 pr-3">
                                                <v-col class="pa-0 pl-0 col">
                                                    <template v-if="print.comment">
                                                        <p style="position: relative; top: 17px; background-color: white; z-index: 10; margin-left: 15px; padding: 0px 5px;font-weight: bold; display:inline-block;font-size: 12px;">
                                                            {{ $t('report.comment') }}
                                                        </p>
                                                        <div style="border: 1px solid #bdbdbd; padding: 10px 20px; border-radius: 5px; margin: 0px 0px;font-size: 13px;line-height: 1.2;">
                                                            <div v-html="print.comment"></div>
                                                        </div>
                                                    </template>
                                                </v-col>
                                            </v-row>
                                        
                                            <!-- REPORT PRECO -->
                                            <v-row class="pt-0 pl-3 pr-3">
                                                <v-col class="pa-0 pl-0 col">
                                                    <template v-if="print.preco">
                                                        <p class="d-inline-block" style="position: relative; top: 17px; background-color: white; z-index: 10; margin-left: 15px; padding: 0px 5px; font-weight: bold; width: 212px;font-size: 12px;">
                                                            {{ $t('report.preco') }}
                                                        </p>
                                                        <div style="border: 1px solid #bdbdbd; padding: 10px 20px; border-radius: 5px; margin: 0px 0px;font-size: 13px;line-height: 1.2;">
                                                            <div v-html="print.preco"></div>
                                                        </div>                  
                                                    </template>
                                                </v-col>
                                            </v-row>

                                            <!-- REPORT IMAGES SECTION -->
                                            <v-col class="col" v-for="(url, index) in img_urls" :key="index" style="max-height:400px; display:flex; justify-content: center;">
                                                <img :src="url" alt="Image du rapport" style="object-fit: fill; width: auto; max-height: 400px;">
                                            </v-col>
                                        </v-col>                                        
                                    </v-col>
                                </v-card-text>
                            </validation-observer>
                        </v-card>
                    </div>
                    
                </div>
            </v-col>
        </div>
        <!-- --------------------------------- -->
    </v-col>
</template>

<script>
import {CAMPAIN,MACHINES,REPORT,USERS,SITES,DOCUMENTS, PROFESSIONALS} from "../../../api";
import Line from "./components/Line.vue";
import moment from "moment";
import {api as fullscreen} from 'vue-fullscreen';
import PDFViewer from 'pdf-viewer-vue/dist/vue2-pdf-viewer';
import { chart } from "highcharts";

// Machine menu package //
import MachineSpecifications from "../../components/Machine/MachineSpecifications152";
import MachineState from "../../components/Machine/MachineState152";
import MachineSubassemblies from "../../components/Machine/MachineSubassemblies152";

export default {
    name: "CampainVision",
    components:{
        Line, 
        PDFViewer,
        MachineSpecifications,      // Machine menu package //
        MachineState,               // Machine menu package //
        MachineSubassemblies,       // Machine menu package //
    },
    data() {
        return {        
            active_special_vue: 0,
            vues:[
                {id:0, label: "Rapports vibratoires (Offline)"},     // 0 et 5 et null
                {id:5, label: "Rapports vibratoires (Online)"},      // 1
                {id:1, label: "Rapports thermographies"},            // 1
                {id:2, label: "Rapports ultrasons"},                 // 2
                {id:3, label: "Rapports équilibrages"},              // 3
                {id:4, label: "Rapports expertises avancées"},       // 4
            ],
             // Variables pour stocker les rapports triés par prestation
            rapports_vibratoire: [],
            rapports_online: [],
            rapports_thermographie: [],
            rapports_ultrasons: [],
            rapports_equilibrage: [],
            rapports_expertises: [],

            rapports_vibratoire_nb: 0,
            rapports_online_nb: 0,
            rapports_thermographie_nb: 0,
            rapports_ultrasons_nb: 0,
            rapports_equilibrage_nb: 0,
            rapports_expertises_nb: 0,
            reports_found: false,
            reportLast_found: false,

            headers: [
                {text: "ID", value: 'id', width: '92px'},
                {text: "Etat de santé", value: 'indice', width: '92px'},
                {text: "Informations & préconisations", value: 'preco', width:'100%'},
            ],
            search: '',
            infoToggle: false, // Machine menu package //
            label: '', // Machine menu package //
            // CAMPAINS ----------------
            campain_id:null,
            raw_campain: [],
            campain: [],
            campain_available: false,
            // PROFESSIONAL ---------------
            professionals: [],
            raw_professional: [],
            professional: [],
            professional_name: '',
            professional_logo: '',
            // MACHINE ------------------
            machine_id:null,
            raw_machine: [],
            machine: [],
            // Other infos ------------------
            analysts: [],
            sites:[],

            defaultsHeight: 0,
            contextHeight: 0,
            reportHeight: 0,
            // REPORT ----------------------
            raw_report:[],
            report_url:"",
            report_nb:0,
            reports_displayed: [],
            reversed_indices:0,
            previous_indice:-1,
            previous_date:"",
            previous_id:"",
            next_date:"",
            next_indice:"",
            next_id:"",
            report_position:0,
            report_switched:false,
            new_report_id:0,
            switch_loader:false,
            reversed_series: [],

            team:[],
            report_available:false,
            report: {},
            print: {},
            active_report: null, // ID du rapport actif
            reports: [], // Liste de rapports
            reports_available: true, // Indique si les rapports sont disponibles
            dialogAddReport: false, // État du dialog pour ajouter un rapport
            selectedReport: null, // Rapport sélectionné via le dialogue
            availableReports: [],
            machine_filters: [
                { level: -1, level_name: "Rapport en attente", level_color: "grey", count:0, annotation: "Etat à définir"},
                
                { level: 1,  level_name: "Pour information : EDS 100% (vert)",  level_color: "#4CAF50", count:0, percent: 100, annotation: "Etat neuf" },
                { level: 2,  level_name: "Pour information : EDS 90% (vert)",   level_color: "#4CAF50", count:0, percent: 90,  annotation: "Comportement vibratoire correct" },
                { level: 3,  level_name: "Pour information : EDS 80% (vert)",   level_color: "#4CAF50", count:0, percent: 80,  annotation: "Pour information" },
                
                { level: 11, level_name: "A suivre : EDS 70% (jaune)",          level_color: "#FFCB05", count:0, percent: 70,  annotation: "A suivre lors de la prochaine campagne" },
                { level: 12, level_name: "A suivre : EDS 60% (jaune)",          level_color: "#FFCB05", count:0, percent: 60,  annotation: "A suivre de manière rapprochée" },
                { level: 13, level_name: "A suivre : EDS 50% (jaune)",          level_color: "#FFCB05", count:0, percent: 50,  annotation: "Expertise complémentaire préconisée" },
                
                { level: 21, level_name: "Importance élevée : EDS 40% (rouge)", level_color: "#F44336", count:0, percent: 40,  annotation: "Action à prévoir au prochaine arrêt technique" },
                { level: 22, level_name: "Importance élevée : EDS 30% (rouge)", level_color: "#F44336", count:0, percent: 30,  annotation: "Action à prévoir au prochaine arrêt technique" },
                { level: 23, level_name: "Importance élevée : EDS 20% (rouge)", level_color: "#F44336", count:0, percent: 20,  annotation: "Action à prévoir au prochaine arrêt technique" },
                
                { level: 31, level_name: "Urgence : EDS 10% (Noir)",            level_color: "black",   count:0, percent: 10,  annotation: "Action à prévoir dans les plus brefs délais" },
                { level: 32, level_name: "Urgence : EDS 0% (Noir)",             level_color: "black",   count:0, percent: 0,   annotation: "Arrêt de la machine recommandé" },      
            ],
            prestas:[
                {id:0, name:"Mesures vibratoires"},
                {id:1, name:"Thermographie"},
                {id:2, name:"Mesures ultrasons"},
                {id:3, name:"Equilibrages"},
                {id:4, name:"Expertise avancée"},
                {id:5, name:"Surveillance Online"},
            ],
            types:[
                {id:0, name:"Ponctuel"},
                {id:1, name:"Annuel"},
                {id:2, name:"Semestriel"},
                {id:3, name:"Quadrmestriel"}, 
                {id:4, name:"Trimestriel"},
                {id:5, name:"Continu"},
            ],
            report_types:[
                {id:0, name:"(VIB) PDF"},
                {id:1, name:"(VIB) Digital"},
                {id:2, name:"(VIB) Automatique"},
                {id:3, name:"(THERMO) PDF"},
            ],
            report_steps:[
                {id:0, name:"En attente",          level_color: "grey"},
                {id:1, name:"En cours d'édition",  level_color: "#ef780e"},
                {id:2, name:"Prêt pour validation",level_color: "#d40eef"},
                {id:3, name:"Validé",              level_color: "#089d2d"},
                {id:4, name:"Envoyé",              level_color: "#089d2d"},
                {id:5, name:"Archivé",             level_color: "grey"},
            ],
            report_tags:[
                {id:0, name:"Défauts BF"},
                {id:1, name:"Défauts HF"},
                {id:2, name:"Défaut électrique"},
                {id:3, name:"Moyen particulier"},
                {id:4, name:"Contre visite"},
                {id:5, name:"Rapport préliminaire"},
            ],
            analysts_datas:[
                {id:40,   name:"Eric Quenneville",   tel:"+33 6 38 38 11 18", email:"eric.quenneville@techview.fr", logo:"https://techview.fr/wp-content/uploads/2024/10/ERIC-QUENNEVILLE-CAT-III-e1725528314226-removebg-preview-2.png"},
                {id:121,  name:"Pascal Ardoin",      tel:"+33 6 74 35 31 13", email:"pascal.ardoin@techview.fr",    logo:"https://techview.fr/wp-content/uploads/2024/09/PASCAL-ARDOIN-CAT-II-e1725542858336.png"},
                {id:91,   name:"Amandine Prieur",    tel:"+33 7 87 58 28 98", email:"amandine.prieur@techview.fr",  logo:"https://techview.fr/wp-content/uploads/2024/12/572e2dac-42b9-46f8-a7d5-995f87877850.png"},
                {id:278,  name:"Louis Blaise",       tel:"+33 6 77 33 08 68", email:"louis.blaise@techview.fr"},
                {id:216,  name:"Guillaume Maury",    tel:"+33 6 07 46 48 40", email:"guillaume.maury@techview.fr"},
                {id:79,   name:"Sebastien Poudenx",  tel:"+33 6 68 42 45 15", email:"sebastien.poudenx@techview.fr"},
            ],

            // GLOBAL --------------------
            access_level: this.$store.state.access_level,
            id: this.$route.params.id,
            loading_datatable:true,
            lineChartOptions: {
                title: {text: 'Evolution de l\'indice de santé machine'},
                time: {timezone: 'Europe/Paris',},
                lang: {noData: this.$t('three_dimensional_curve.data_loading_error')},
                noData: {
                    style: {
                        fontWeight: 'bold',
                        fontSize: '15px',
                        color: '#303030'
                    }
                },
                loading: {hideDuration: 1000,showDuration: 1000},
                scrollbar: {
                    liveRedraw: false,
                    height: 17,
                    barBackgroundColor: '#cfcfcf',
                    barBorderRadius: 9,
                    buttonBackgroundColor: '#c9c9c9',
                    buttonArrowColor: 'white',
                    buttonBorderWidth: 3,
                    buttonBorderRadius: 11,
                    rifleColor: 'white',
                    trackBorderRadius: 9
                },
                rangeSelector: {enabled: false,},
                updateArgs: [true, true, true],
                yAxis: {
                    title: {
                        text: '',
                    },
                    plotBands: [],
                },
                plotOptions: {
                    line: {
                        connectNulls: true,
                    },
                    series: {
                        marker: {
                            enabled: true,
                            radius: 4
                        }
                    }
                },
                series: [{
                    name: 'Data',
                    data: []
                }],
                credits: {
                    enabled: false
                },
            },
            lineChartOptions2: {
                chart: { width: 300, height: 200 },
                title: {
                    text: 'Evolution de l\'indice de santé machine',
                    style: {
                        fontSize: '12px'
                    }
                },
                time: {timezone: 'Europe/Paris',},
                lang: {noData: this.$t('three_dimensional_curve.data_loading_error')},
                noData: {
                    style: {
                        fontWeight: 'bold',
                        fontSize: '15px',
                        color: '#303030'
                    }
                },
                loading: {hideDuration: 1000,showDuration: 1000},
                scrollbar: {
                    liveRedraw: false,
                    height: 17,
                    barBackgroundColor: '#cfcfcf',
                    barBorderRadius: 9,
                    buttonBackgroundColor: '#c9c9c9',
                    buttonArrowColor: 'white',
                    buttonBorderWidth: 3,
                    buttonBorderRadius: 11,
                    rifleColor: 'white',
                    trackBorderRadius: 9
                },
                rangeSelector: {enabled: false,},
                updateArgs: [true, true, true],
                yAxis: {
                    title: {
                        text: '',
                    },
                    plotBands: [],
                },
                plotOptions: {
                    line: {
                        connectNulls: true,
                    },
                    series: {
                        marker: {
                            enabled: true,
                            radius: 4
                        },
                    }
                },
                series: [{
                    name: 'Data',
                    data: []
                }],
                credits: {
                    enabled: false
                },
            },
            lineChartOptions3: {
                title: {text: '(Indice) - Evolution de l\'état de santé machine'},
                time: {timezone: 'Europe/Paris',},
                lang: {noData: this.$t('three_dimensional_curve.data_loading_error')},
                height:140,
                noData: {
                    style: {
                        fontWeight: 'bold',
                        fontSize: '15px',
                        color: '#303030'
                    }
                },
                loading: {hideDuration: 1000,showDuration: 1000},
                scrollbar: {
                    liveRedraw: false,
                    height: 17,
                    barBackgroundColor: '#cfcfcf',
                    barBorderRadius: 9,
                    buttonBackgroundColor: '#c9c9c9',
                    buttonArrowColor: 'white',
                    buttonBorderWidth: 3,
                    buttonBorderRadius: 11,
                    rifleColor: 'white',
                    trackBorderRadius: 9
                },
                rangeSelector: {enabled: false,},
                updateArgs: [true, true, true],
                yAxis: {
                    title: {
                        text: 'Niveau de santé (%)',
                    },
                    min: 0,
                    max: 100,
                    plotBands: [],
                },
                plotOptions: {
                    line: {
                        connectNulls: true,
                    },
                    series: {
                        marker: {
                            enabled: true,
                            radius: 4
                        }
                    }
                },
                series: [{
                    name: 'Etat de santé',
                    data: []
                }],
                credits: {
                    enabled: false
                },
            },
            chart: {
                type: 'line',
                preAlert: {
                    yMin: 0,
                    yMax: 0
                },
                alert: {
                    yMin: 0,
                    yMax: 0
                },
                selected: {
                    label: null,
                    date: null,
                    value: null
                },
            },
            raw_report_indices: [],
            report_indices: [],
            report_series: [],
            report_indices_available:false,
            checkInterval:'',

            // Uploads
            dragover: false,
            files_to_upload: [],
            uploaded_files: [],
            loading: true,
            external_link: {
                id_relatable: 0,
                type_relatable: 'report',
                link: ''
            },
            external_links: [],
            dialog_add: false,
            valid: true,
            result: [],

            // Uploads (img)
            img_dragover: false,
            img_files_to_upload: [],
            img_uploaded_files: [],
            img_loading: true,
            img_external_link: {
                id_relatable: this.$route.params.id,
                type_relatable: 'report_images',
                link: ''
            },
            img_external_links: [],
            img_result: [],
            img_available:false,
            img_urls:[],

            view_details: false,


            isLoaded: false,
        }
    },
    computed: {
        dialogTitle() {
            return this.edited_index === null ? this.$t('campain.add') : this.$t('campain.edit');
        },

        vuesWithReports() {
            return this.vues.filter(vue => {
                return this.reports.some(report => 
                    (report.presta === vue.id) || (report.presta === null && vue.id === 0)
                );
            });
        },
    },
    watch:{
    },
    created() {
        moment.locale('fr');
        this.initialize();
        this.setMetricThresholds();
        this.resizeIframe();
    },
    mounted() {
        setTimeout(() => {
            this.isLoaded = true;
        }, 2000);
    },
    methods: {
        // STARTING PROCESS
        initialize() {
            this.loading_datatable = true;
            this.view_details = true;
            this.getData();
        },

        getData() {
            // GETTING CAMPAINS
            // REQUEST : récupère et gère les données de la table
            this.axios.get(REPORT + "/machines_indices/" + this.id)
            
            .then((success) => {
                this.raw_report = success.data;

                // récupération de la liste des rapports
                this.reports = success.data.reverse();
                this.reports.forEach(itemReport => {

                    if (itemReport.id != 0 && itemReport.id != null && itemReport.id != "") {
                        this.report_available = true;

                        itemReport.tags = JSON.parse(itemReport.tags);
                        itemReport.created_at = moment(itemReport.created_at).format("DD/MM/YYYY");
                        itemReport.updated_at = moment(itemReport.updated_at).format("DD/MM/YYYY");

                        if (itemReport.released_at) {  // Vérifie que released_at existe
                            itemReport.released_at = moment(itemReport.released_at).format("DD/MM/YYYY")
                        } else {
                            itemReport.released_at = "rapport en attente de publication.";
                        }

                        this.professional_id = itemReport.id_professional;
                        this.machine_id = itemReport.id_machine;
                        this.report_available = true;
                    } else {
                        this.report_available = false;
                    }
                });

            }).catch(() => {
                this.showSnackbar('error', "Reports data error.");

            }).finally(() => {
                this.getSideDatas();
            })
        },
        getSideDatas(){
            this.getMachine();
            
            if (this.report_available){
                this.loadVue(0);
                this.getCampain();
                this.getAnalysts();
                this.getTeam();
                this.getProfessional();
            }
        },
        getCampain(){
            this.axios.get(CAMPAIN + '/specific/' + this.reports[0].id_campain )
            
            .then((success) => {
                success.data.forEach(item => {
                    item.created_at = moment(item.created_at).format("DD/MM/YYYY");
                })
                
                this.campain = [];
                this.campain = success.data[0];
                this.campain_available = true;

            }).catch(() => {
                this.campain_available = false;
                //this.showSnackbar('error', "Side datas error.");
            })
        },
        getMachine() {
            this.axios.get(MACHINES + '/specific/' + this.id)
            
            .then((success) => {
                this.machine = [];
                this.machine = success.data[0];

            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");
            })
        },
        getAnalysts(){
            this.axios.get(USERS + '/team')
            
            .then((success) => {
                this.analysts = [];
                this.analysts = success.data;

            }).catch(() => {
                this.showSnackbar('error', "Analysts datas error.");
            })
        },
        getTeam(){
            this.axios.get(USERS + '/team')
            
            .then((success) => {
                this.team = [];
                this.team = success.data;

            }).catch(() => {
                this.showSnackbar('error', "Team datas error.");
            })
        },
        getIndices(id) {
            // GETTING CAMPAINS
            // REQUEST : récupère et gère les données du chips et de la courbe d'indices
            this.axios.get(REPORT + "/machines_indices/" + this.id)
            
            .then((success) => {
                this.raw_report_indices = [];
                this.reversed_indices = [];

                this.previous_indice = -1;
                this.previous_date = "";
                this.previous_id = 0;
                
                this.next_indice = -1;
                this.next_date = "";
                this.next_id = 0;
                
                this.report_position = 0;
                this.report_nb = 0;
                this.report_indices = [];
                this.report_series = [];
                this.reversed_series = [];

                this.rapports_vibratoire_nb = 0;
                this.rapports_online_nb = 0;
                this.rapports_thermographie_nb = 0;
                this.rapports_ultrasons_nb = 0;
                this.rapports_equilibrage_nb = 0;
                this.rapports_expertises_nb = 0;

                if (typeof success.data[0] !== 'undefined'){
                    this.report_indices_available = true;
                    this.raw_report_indices = success.data;

                    // Modifications on data + creating report_series
                    success.data.forEach(report => {
                        if(report.step == 5 && report.released_at != null){
                            report.released_at = moment(report.released_at).format("DD/MM/YYYY");
                            this.report_series.push(report.indice);
                        }
                        /*
                        if(report.intervention_date != null){
                            report.intervention_date = moment(report.intervention_date).format("DD/MM/YYYY");
                            report.
                            this.report_series.push(report.indice);
                        }
                        */

                        if (report.presta == 0 || report.presta == null) {this.rapports_vibratoire_nb = this.rapports_vibratoire_nb +1};
                        if (report.presta == 5) {this.rapports_online_nb = this.rapports_online_nb +1};
                        if (report.presta == 1) {this.rapports_thermographie_nb = this.rapports_thermographie_nb +1};
                        if (report.presta == 2) {this.rapports_ultrasons_nb = this.rapports_ultrasons_nb +1};
                        if (report.presta == 3) {this.rapports_equilibrage_nb = this.rapports_equilibrage_nb +1};
                        if (report.presta == 4) {this.rapports_expertises_nb = this.rapports_expertises_nb +1};
                    })

                    // Generate previous health indice
                    // this.reversed_indices = this.raw_report_indices.reverse();
                    this.reversed_indices = this.raw_report_indices;

                    // Récupération du denier rapport publié
                    this.reportLast_found = false;
                    this.reversed_indices.forEach(reportLast => {
                        if((reportLast.released_at != null && reportLast.presta == id) || (reportLast.released_at != null && id == 0 && reportLast.presta == null) && !this.reportLast_found){
                            this.reportLast_found = true;
                            this.report = reportLast;
                        }
                        if((reportLast.released_at != null && reportLast.presta == id) || (reportLast.released_at != null && id == 0 && reportLast.presta == null)){
                            this.reversed_series.push(reportLast);
                        }
                    })
                    
                    this.reversed_series.reverse();
                    this.report_nb = this.reversed_series.length;
                    let pos = this.report_nb;
                    let actual_flag = false;
                    let actual_flag_ended = false;

                    this.reversed_series.forEach(item => {                       
                        // Si le rapport est celui avant (chronologiquement) le  dernier rapport publié
                        // Association du rapport précédent au dernier rapport publié
                        if(actual_flag == true){
                            this.previous_id = item.id;
                            this.previous_indice = item.indice;
                            this.previous_date = item.released_at;
                            
                            actual_flag = false;
                            actual_flag_ended = true;
                        }
                        
                        // Si le rapport est le dernier rapport publié
                        if (item.id == this.report.id){
                            actual_flag = true;
                            this.report_position = pos;
                        }

                        // Tant que je ne suis pas sur le dernier rapport publié ou celui d'avant
                        // Alors je déclare le rapport en cours est le suivant
                        if(actual_flag_ended == false && actual_flag == false){
                            this.next_id = item.id;
                            this.next_indice = item.indice;
                            this.next_date = item.released_at;
                        }

                        pos = pos -1;
                    })
                    
                    this.print = this.reversed_indices[0];
                    this.lineChartOptions3.series[0].data = this.report_series;

                }else{
                    this.report_indices_available = false;
                }

            }).catch(() => {
                this.showSnackbar('error', "Indices data error.");

            })
        },
        getProfessional() {
            this.axios.get(PROFESSIONALS + "/active")
            
            .then((success) => {
                    this.professional = success.data;
            }).catch(() => {
                this.showSnackbar('error', "Untrashed me data error.");
            })
        },

        // DISPLAY DATA MANAGEMENT
        checkDataAvailable(datas){
            if(typeof datas[0] === 'undefined') {                    
                return false;
            }else {
                return true;
            }
        },
        //REQUESTS
        updateReport() {
            this.axios.patch(REPORT + "/" + this.reports.id,  this.report)
            .then((success) => {
                Object.assign(this.report, success.data[0]);
                this.getData();
                this.loading_datatable = true;
                this.showSnackbar('success', this.$t('global.success_to_edit'));
            
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            
            })
        },
        // Fonction pour modifier le style de l'élément avec la classe "viewer-item"
        modifyStyle(iframe) {
            try {
                // Obtenez le document de l'iframe
                let iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

                // Créez un observeur de mutation pour surveiller les modifications du DOM
                let observer = new MutationObserver(function(mutations) {
                    mutations.forEach(function(mutation) {
                        // Vérifiez si l'élément avec la classe "viewer-item" a été modifié
                        let viewerItem = iframeDocument.querySelector('.viewer-item');
                        if (viewerItem && mutation.target === viewerItem) {
                            // Modifiez la largeur de l'élément
                            viewerItem.style.width = '800px';
                        }
                    });
                });

                // Configuration de l'observeur :
                let config = { attributes: true, childList: true, characterData: true, subtree: true };

                // Passez l'élément cible (document de l'iframe) et la configuration à l'observeur
                observer.observe(iframeDocument, config);
            } catch (error) {
                console.error('An error occurred: ', error);
            }
        },
        checkIframe() {
            let iframe = document.querySelector('iframe');
            if (iframe) {
                // Si l'iframe existe, exécutez votre code
                iframe.addEventListener('load', function() {
                    this.modifyStyle(iframe);
                });

                // Arrêtez de vérifier
                clearInterval(this.checkInterval);
            }
        },
        resizeIframe(){
            this.checkInterval = setInterval(this.checkIframe, 500);
        },
        setMetricThresholds() {
            this.lineChartOptions.yAxis.plotBands = [];
            this.lineChartOptions.yAxis.plotBands.push(
                {
                    from: 100,
                    to: 75,
                    color: 'rgba(76, 175, 80, 0.25)',
                    label: {
                        text: 'Etat de santé convenable'
                    }
                },
                {
                    from: 75,
                    to: 45,
                    color: 'rgba(255, 203, 5, 0.25)',
                    label: {
                        text: 'Etat de santé affecté (à suivre)'
                    }
                },
                {
                    from: 45,
                    to: 15,
                    color: 'rgba(244, 67, 54, 0.25)',
                    label: {
                        text: 'Etat de santé dégradé (importance élevée)'
                    }
                },
                {
                    from: 15,
                    to: 0,
                    color: 'rgba(0, 0, 0, 0.25)',
                    label: {
                        text: 'Etat de santé critique (urgence)'
                    }
                }
            );
            this.lineChartOptions2.yAxis.plotBands = [];
            this.lineChartOptions2.yAxis.plotBands.push(
                {
                    from: 100,
                    to: 75,
                    color: 'rgba(76, 175, 80, 0.25)',
                },
                {
                    from: 75,
                    to: 45,
                    color: 'rgba(255, 203, 5, 0.25)',
                },
                {
                    from: 45,
                    to: 15,
                    color: 'rgba(244, 67, 54, 0.25)',
                },
                {
                    from: 15,
                    to: 0,
                    color: 'rgba(0, 0, 0, 0.25)',
                }
            );
            this.lineChartOptions3.yAxis.plotBands = [];
            this.lineChartOptions3.yAxis.plotBands.push(
                {
                    from: 100,
                    to: 75,
                    color: 'rgba(76, 175, 80, 0.25)',
                },
                {
                    from: 75,
                    to: 45,
                    color: 'rgba(255, 203, 5, 0.25)',
                },
                {
                    from: 45,
                    to: 15,
                    color: 'rgba(244, 67, 54, 0.25)',
                },
                {
                    from: 15,
                    to: 0,
                    color: 'rgba(0, 0, 0, 0.25)',
                }
            );
        },
        getDocuments() {
            this.axios.get(DOCUMENTS + '/getFiles', {
                params: {
                    entity_id: this.reports.id,
                    type: 'report',
                    professional_id: this.$store.state.professional_id
                }
            }

            ).then((success) => {
                this.uploaded_files = [];
                
                if (this.checkDataAvailable(success.data)){
                    success.data.forEach((path) => {
                        let fileName = path.replace(/^.*[\\\/]/, '');
                        this.uploaded_files.push({
                            file_name: fileName,
                            path: path
                        });
                    });

                    this.uploaded_files.reverse();
                    this.report_url = this.uploaded_files[0].path;
                    this.pdf_report_available = true;

                }else{
                    this.pdf_report_available = false;
                }

            }).catch(() => {
                this.showSnackbar('error', 'GetDocuments error');

            }).finally(() => {
                //this.mergeExternalLinkAndDocuments();
                this.loading = false;
            });
        },
        printReports() {
            this.img_getDocuments();
            setTimeout(() => {
                window.print();
            }, 500);
        },
        img_getDocuments() {
            this.axios.get(DOCUMENTS + '/getFiles', {
                    params: {
                        entity_id: this.id,
                        type: 'report_images',
                        professional_id: this.$store.state.professional_id,
                    }
                }

            ).then((success) => {
                this.img_uploaded_files = [];
                this.img_urls = [];
                
                if (this.checkDataAvailable(success.data)){
                    success.data.forEach((path) => {
                        let fileName = path.replace(/^.*[\\\/]/, '');
                        this.img_uploaded_files.push({
                            file_name: fileName,
                            path: path
                        });
                    });

                    this.img_uploaded_files.reverse();
                    this.img_uploaded_files.forEach(img_url => {
                        this.img_urls.push(img_url.path);
                        this.img_available = true;
                    })

                }else{
                    this.img_available = false;
                }
            }).catch(() => {
                this.showSnackbar('error', 'img_GetDocuments error');

            }).finally(() => {
                this.img_result = this.img_uploaded_files;
                this.img_loading = false;
            });
        },
        loadReport(reportId) {
        this.active_report = reportId;
        },
        openDialogAddReport() {
        this.dialogAddReport = true;
        },
        addSelectedReport() {
        if (this.selectedReport) {
            this.loadReport(this.selectedReport.id);
        }
        this.dialogAddReport = false;
        },

        // Machine menu package //
        updateLabelMachine(item) {
            this.label = item.label;
        },
        infoToggler(){
            this.infoToggle = !this.infoToggle;
        },
        chooseToPrintPDF(item) {
            this.print = {};
            this.print = item;
            this.printReports();
        },
        viewDetails(){
            this.view_details = !this.view_details;
        },
        goToReport(item) {
            if (item && item.id) {
                this.$router.push(`/reported/${item.id}`);
            }
        },
        loadVue(id) {
            this.active_special_vue = id;
            this.getIndices(id);
            this.displayReports(id);
        },
        displayReports(id) {
            this.loading_datatable = true;
            this.reports_found = false;
            this.reports_displayed = [];

            this.reports.forEach(report => {
                if (report.presta === id && id != 0) this.reports_displayed.push(report);
                if ((report.presta === id && id === 0) || (report.presta === null && id === 0)) this.reports_displayed.push(report);
            });

            if (this.reports_displayed[0]) {
                this.reports_found = true;
            }

            this.loading_datatable = false;
        },
        printContent() {
            const content = this.$refs.contentToPrint.innerHTML;

            const printWindow = window.open("", "_blank");
            printWindow.document.open();
            printWindow.document.write(`
                <html>
                <head>
                    <title>Impression</title>
                </head>
                <body>
                    ${content}
                </body>
                </html>
            `);
            printWindow.document.close();

            const waitForPageLoad = new Promise((resolve) => {
                printWindow.onload = resolve;
            });

            const waitForImagesLoad = () => {
                const images = printWindow.document.images;
                const imageLoadPromises = Array.from(images).map((img) => {
                return new Promise((resolve) => {
                    if (img.complete) {
                    resolve();
                    } else {
                    img.onload = resolve;
                    img.onerror = resolve;
                    }
                });
                });
                return Promise.all(imageLoadPromises);
            };

            Promise.all([waitForPageLoad, waitForImagesLoad()]).then(() => {
                printWindow.print();

                /*setTimeout(() => {
                printWindow.close();
                }, 500);*/
            });
        },
    },
}
</script>

<style>
td.text-start {
    padding-right:0px;
}

.pdf-viewer__body iframe html body .viewer-container .scroller.viewer .viewer-content .viewer-item>div {
    width: 75% !important;
}

.header__preview .start .title {
    font-size: 13px !important;
}

.pdf-viewer__header {
    box-shadow: 0px 3px 10px 2px #00000021!important;
    z-index: 1!important;
}

/* Styles spécifiques pour l'impression */
@page {
    size: A4;
    margin: 10mm;
}

@media print {
    html * {
        visibility: hidden;
    }

    #printDivision, #printDivision * {
        visibility: visible!important;
    }

    #MachineMenuPackage, #ContentReport, #ChipsNoAlert, #ChipsAlert {
        display: none!important;
    }

    #printDivision {
        display: block!important;
    }
    html, body {
        width: 100%;
        height: auto;
        transform: scale(1);
        transform-origin: top left;
    }
}
</style>