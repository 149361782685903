<template>
    <v-card elevation="0" :loading="loading" min-height="625px">
        <v-card-text>
            <v-row style="align-items: center;justify-content:space-between;">
               <!-- Fields -->
                <v-col class="col-3">
                    <v-menu
                        v-model="menu_date"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                :value="formattedDate"
                                :label="$t('global.date')"
                                prepend-inner-icon="mdi-calendar"
                                readonly v-bind="attrs" v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="chosen_date"
                            @input="menu_date = false"
                            @change="loadChartData()"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col class="col-3">
                    <v-select
                        :items="timestamps"
                        v-model="chosen_timestamp"
                        :loading="loading_timestamp"
                        item-text="date"
                        :hint="hint_select_timestamp"
                        persistent-hint
                        prepend-inner-icon="mdi-map"
                        :label="$t('global.schedule')"
                        @change="updateChart()" block
                    ></v-select>
                </v-col>
                <v-col cols="4" lg="4" md="4">
                    <v-btn class="w-100" @click="switchScale()" :disabled="no_data_popup_show">
                        <span>{{ $t('favorite_curve.linear_scale') }}</span>
                        <v-switch
                            v-model="is_log"
                            class="ml-3"
                            color="primary" disabled
                        >
                        </v-switch>
                        <span>{{ $t('favorite_curve.log_scale') }}</span>
                    </v-btn>
                </v-col>

                <!-- BTN -->
                <div class="mr-3">
                    <v-btn class="ml-0" elevation="3" color="red darken-3" small @click="dialog_delete_favorite_curve = true">
                        <v-icon color="white" small>mdi-trash-can-outline</v-icon>
                    </v-btn>
                    <v-btn class="ml-0" elevation="3" small color="primary" @click="spectrumCurveCsvExport()" :loading="csv_export_loading">
                        <v-icon color="white" small>mdi-download</v-icon>
                    </v-btn>
                    <v-btn class="ml-0" elevation="3" small @click="toggleFullscreen()">
                        <v-icon color="black">mdi-fullscreen</v-icon>
                    </v-btn>
                </div>
            </v-row>

            <v-row class="mt-0">
                <v-col cols="2" lg="2" md="2">
                    <v-btn class="w-100" @click="switchFrequency()" :disabled="no_data_popup_show">
                        BF
                        <v-switch
                            v-model="is_high_frequency"
                            class="ml-3"
                            color="primary" disabled
                        ></v-switch>
                        HF
                    </v-btn>
                </v-col>

                <v-col cols="3" lg="3" md="3">
                    <v-btn class="w-100" @click="switchOrder()" :disabled="no_data_popup_show">
                        {{ $t('favorite_curve.hertz') }}
                        <v-switch
                            v-model="is_order"
                            class="ml-3"
                            color="primary" disabled
                        ></v-switch>
                        {{ $t('favorite_curve.order') }}
                    </v-btn>
                </v-col>

                <v-col class="col col"></v-col>
            </v-row>

            <div v-if="is_order" class="row3 mt-2">
                <v-text-field
                    class="rotation-speed-input mt-3"
                    :label="$t('subassemblies.rotation_speed')"
                    :prepend-inner-icon="'mdi-fan-chevron-up '"
                    v-model="favorite_curve.rotation_speed"
                    dense
                    filled
                    outlined
                >
                </v-text-field>
                <v-btn class="mt-3 primary" @click="updateSubassemblySpeedRotation()" :loading="speed_rotation_update_loading">
                    {{ $t('global.validation') }}
                </v-btn>
            </div>

            <div id="spectrum-fullscreen-wrapper" class="fullscreen-wrapper">
                <canvas :id="favorite_curve.type + '-' + favorite_curve.id"></canvas>
            </div>
        </v-card-text>

        <v-dialog v-model="dialog_delete_favorite_curve" max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="headline">{{ $t('favorite_curve.delete_favorite_curve') }}</span>
                </v-card-title>
                <v-divider/>

                <v-card-text class="text-center pb-0">
                    <p class="mb-5 mt-3"> {{ $t('word_curve.delete_message')}} <strong>{{ favorite_curve.label }} ?</strong></p>
                </v-card-text>

                <v-card-actions>
                    <v-spacer/>
                    <v-btn @click="dialog_delete_favorite_curve = false">
                        <v-icon left>mdi-close</v-icon>
                        {{ $t('global.close') }}
                    </v-btn>
                    <v-btn type="submit" color="error"
                           @click="deleteFavoriteCurve()">
                        <v-icon left>mdi-content-save</v-icon>
                        {{ $t('global.delete') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar
            v-model="snackbar"
            :color="snackbar_type">
            {{ snackbar_text }}
            <template v-slot:action="{ attrs }">
                <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
            </template>
        </v-snackbar>
        <global-snackbar/>
    </v-card>
</template>

<script>
import Chart from 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';
import moment from "moment";
import {api as fullscreen} from 'vue-fullscreen'
import annotationPlugin from 'chartjs-plugin-annotation';
import {DOWNLOAD_CSV_FFT_CURVE, DOWNLOAD_CSV_TEXT_CURVE, FAVORITE_CURVES, SUBASSEMBLIES} from "../../../../api";
import GlobalSnackbar from "../../../../global/components/GlobalSnackbar";

Chart.register(annotationPlugin);
Chart.register(zoomPlugin);
Chart.register({
    id:'no_data_plugin',
    afterRender: function(x) {
        if (x.data.datasets[0].data.length === 0) {
            let ctx = x.ctx;
            let width = x.width;
            let height = x.height
            x.clear();

            ctx.save();
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.font = "16px normal 'Helvetica Nueue'";
            ctx.fillText('No data available', width / 2, height / 2);
            ctx.restore();
        }
    }
})

export default {
    name: "SpectrumCurve",
    components: {GlobalSnackbar},
    props: ['item'],
    data() {
        return {
            favorite_curve: this.item,
            loading: false,
            fullscreen: false,
            teleport: true,
            dialog_delete_favorite_curve: false,
            chosen_date: '',
            menu_date: false,
            loading_timestamp: true,
            chosen_timestamp: '',
            chose_timestamp_data: [],
            timestamps: [],
            metrics: [],
            hint_select_timestamp: '',
            chart_data: [],

            speed_rotation: null,
            abscissa_x_min: null,
            abscissa_x_max: null,
            is_high_frequency: false,
            is_order: false,

            title_x_axis: 'Hertz',
            title_y_axis: 'mm/s RMS',

            subassembly_configs: null,
            subassembly: null,
            subassemblySpeedRotation: null,
            speed_rotation_update_loading: false,
            is_log:false,
            scale:'linear',
            no_data_popup_show: false,
            csv_export_loading: false,
            metric_details: []
        }
    },
    computed: {
        formattedDate() {
            return this.chosen_date ? moment(this.chosen_date).format('DD/MM/yyyy') : '';
        },
    },
    mounted() {
        this.chosen_date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
        this.initialize();
    },
    methods: {
        async initialize() {
            this.loadChartData();
            this.loadSubassembly();
        },

        loadChartData() {
            this.loading = true;
            this.loading_timestamp = true;
            this.timestamps = [];
            this.hint_select_timestamp = '';

            this.axios.get('/api/' + this.favorite_curve.type_relatable + '/' + this.favorite_curve.id_relatable + '/metric/' +
                this.favorite_curve.metric_uuid + '/fft?date=' + this.chosen_date +'&rotation_speed='+ this.favorite_curve.rotation_speed)
                
            .then((success) => {
                let totalTimestamp = success.data.metrics.length;
                if (totalTimestamp !== 0) {
                    this.speed_rotation = success.data.speed_rotation;
                    this.metrics = success.data.metrics;

                    success.data.metrics.forEach((data) => {
                        this.timestamps.push(moment.parseZone(moment.utc(data.timestamp,'HH:mm:ss')).local().format('HH:mm:ss'));
                    });
                    this.chosen_timestamp = this.timestamps[totalTimestamp - 1];
                    this.chose_timestamp_data = success.data.metrics[totalTimestamp - 1].data;
                    this.no_data_popup_show= false;
                    this.prepareChartData();
                } else {
                    this.hint_select_timestamp = this.$t('favorite_curve.no_data_for_this_date');
                    this.chart_data.datasets[0].data=[];
                    this.drawChart();
                }
            }).catch(() => {
                //this.showSnackbar('error', this.$t('global.error'));
                this.no_data_popup_show= true;
            }).finally(() => {
                this.loading = false;
                this.loading_timestamp = false;
            });
        },

        loadSubassembly() {
            this.axios.get(
                SUBASSEMBLIES + "/" + this.favorite_curve.id_relatable
            ).then((success) => {
                this.subassembly_configs = JSON.parse(success.data.config);
                this.subassembly = success.data;
                //this.subassemblySpeedRotation = this.favorite_curve.rotation_speed;
                /*this.subassembly_configs.forEach(config => {
                    if (config.index === 'speed_0' && config.type === 'integer') {
                        this.remotesubassemblySpeedRotation= config.value;
                    }
                });*/
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            });
        },

        prepareChartData() {
            let label = "mm/s RMS";
            if (this.is_high_frequency) {
                label = "G Pic";
            }

            let datasets_data;
            if (this.is_high_frequency) {
                datasets_data = this.chose_timestamp_data.data_high_frequency;
                this.abscissa_x_min = 1000;
                this.abscissa_x_max = 10000;

                if (this.is_order) {
                    this.abscissa_x_min = this.abscissa_x_min / (this.speed_rotation / 60);
                    this.abscissa_x_max = this.abscissa_x_max / (this.speed_rotation / 60);
                    datasets_data = this.chose_timestamp_data.data_high_frequency_with_order;
                }
            } else {
                datasets_data = this.chose_timestamp_data.data_low_frequency;
                this.abscissa_x_min = 0;
                this.abscissa_x_max = 1000;

                if (this.is_order) {
                    this.abscissa_x_max = this.abscissa_x_max / (this.speed_rotation / 60);
                    datasets_data = this.chose_timestamp_data.data_low_frequency_with_order;
                }
            }

            this.chart_data = {
                labels: [],
                datasets: [{
                    label: label,
                    data: datasets_data,
                    borderColor: 'rgb(75, 192, 192)',
                    borderWidth: 1,
                    barThickness: 3
                }]
            }

            this.chart_data.datasets[0].data.forEach((metric) => {
                this.chart_data.labels.push(metric.x);
            });

            this.drawChart();
        },

        drawChart() {
            const ctx = document.getElementById(this.favorite_curve.type + '-' + this.favorite_curve.id);

            let chart = Chart.getChart(this.favorite_curve.type + '-' + this.favorite_curve.id);
            if (chart) {
                chart.destroy();
            }
            this.chart_data.datasets[0].data.length === 0 ? this.no_data_popup_show =true : this.no_data_popup_show=false;
            chart = new Chart(ctx, {
                    title: this.favorite_curve.metric_unit +' ('+ this.favorite_curve.metric_uuid+')',
                    type: 'bar',
                    data: {
                        labels: this.chart_data.labels,
                        datasets: this.chart_data.datasets
                    },
                    options: {
                        scales: {
                            x: {
                                type: 'linear',
                                min: this.abscissa_x_min,
                                max: this.abscissa_x_max,
                                title: {
                                    display: true,
                                    text: this.title_x_axis,
                                },
                            },
                            y: {
                                type: this.scale,
                                title: {
                                    display: true,
                                    text: this.title_y_axis,
                                },
                                ticks: {
                                    // Include a dollar sign in the ticks
                                    callback: (value, index, ticks) => {
                                        if(value - Math.floor(value) === 0) {
                                            return Math.floor(value)
                                        }
                                        else return value
                                    }
                                }
                            },
                        },
                        plugins:{
                            title: {
                                text: this.favorite_curve.metric_unit +' ('+ this.favorite_curve.metric_uuid+')',
                                display: true,
                                color: 'black',
                                font: {
                                    size: 16,
                                    weight: 'normal'
                                }
                            },
                            legend: {
                                display: false,
                            },
                            zoom: {
                                pan: {
                                    enabled: true,
                                    modifierKey: 'ctrl',
                                    overScaleMode: 'y',
                                },
                                zoom: {
                                    wheel: {
                                        enabled: true,
                                        modifierKey: 'ctrl'
                                    },
                                    pinch: {
                                        enabled: true,
                                    },
                                    mode: 'x',
                                }
                            },
                        }

                    }
                });
        },

        switchFrequency() {
            this.is_high_frequency = !this.is_high_frequency;
            if (this.title_y_axis === 'mm/s RMS')
                this.title_y_axis = 'G Pic';
            else
                this.title_y_axis = 'mm/s RMS';
            if(!this.no_data_popup_show){
                this.prepareChartData();
            }
        },

        switchOrder() {
            this.is_order = !this.is_order;
            if (this.title_x_axis === 'Hertz')
                this.title_x_axis = 'Ordre';
            else
                this.title_x_axis = 'Hertz';
            if(!this.no_data_popup_show){
                this.prepareChartData();
            }
        },

        switchScale() {
            this.is_log =! this.is_log
            this.is_log ? this.scale= 'logarithmic' : this.scale='linear';
            if(!this.no_data_popup_show){
                this.prepareChartData();
            }
        },

        updateChart() {
            let chosen_timestamp = this.chosen_timestamp;
            this.chose_timestamp_data = this.metrics.filter(function (metric) {
                if (metric.timestamp === moment(chosen_timestamp,'HH:mm:ss').utc().format('HH:mm:ss')) return metric;
            })[0].data;

            this.prepareChartData();
        },

        deleteFavoriteCurve() {
            this.axios.delete(
                FAVORITE_CURVES + '/' + this.favorite_curve.id
            ).then(() => {
                this.$emit('deleteFavoriteCurve', this.favorite_curve.id);
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_delete'))
            }).finally(() => {
                this.dialog_delete_favorite_curve = false;
            });
        },

        updateSubassemblySpeedRotation() {
            this.speed_rotation_update_loading = true;
            if( !(this.favorite_curve.rotation_speed > 0) || this.favorite_curve.rotation_speed > 999999){
                this.showGlobalSnackbar('error',this.$t('subassemblies.invalid_rotation_speed'));
            }
            else {
                this.axios.put(
                    FAVORITE_CURVES + '/' + this.favorite_curve.id,{
                        rotation_speed : this.favorite_curve.rotation_speed
                    }
                ).then((success) => {
                    this.showGlobalSnackbar('success',this.$t('global.success_to_edit'));
                    this.loadChartData();
                }).catch((error) => {
                    this.manageError(error.response.status,this.$t('global.error'));
                })
            }
            this.speed_rotation_update_loading = false;
        },

        toggleFullscreen() {
            let wrapper = this.$el.querySelector('#spectrum-fullscreen-wrapper');
            wrapper.classList.add('enabled');

            fullscreen.toggle(wrapper, {
                teleport: this.teleport,
                callback: (isFullscreen) => {
                    this.fullscreen = isFullscreen;
                    if (isFullscreen === false) {
                        this.$el.querySelector('#spectrum-fullscreen-wrapper').classList.remove('enabled');
                    }
                },
            });
        },

        spectrumCurveCsvExport() {
            this.csv_export_loading= true;
            this.axios({
                url: DOWNLOAD_CSV_FFT_CURVE,
                method: 'POST',
                data: {
                    'data': this.chart_data.datasets[0].data,
                },
                responseType: 'blob',
            }).then((success) => {
                this.downloadCsvFile(success.data, "_FFTCurve :" + this.favorite_curve.label + "_data.csv");
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('global.failure_to_download'));
            }).finally(()=>{
                this.csv_export_loading= false;
            });
        },
    }
}
</script>

<style scoped lang="scss">
.fullscreen-wrapper {
    background: white;
}

.fullscreen-wrapper.enabled {
    z-index: 10;
    padding: 50px;
}

.row3 {
    display: flex;
    justify-content: space-between;
}

.rotation-speed-input {
    max-width: 75%;
}
</style>
