import Vue from 'vue';
import {ValidationProvider, ValidationObserver, extend, configure} from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import i18n from "./i18n";

configure({
    defaultMessage: (field, values) => {
        return i18n.t(`vee-validation.${values._rule_}`, values);
    }
});

for (let rule in rules) {
    extend(rule, rules[rule]);
}

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
