<template>
    <div id="professional-tree-structure">
        <v-card v-if="$store.state.access_level > 200" :loading="loading_items_tree_view_user"
                class="mt-4" elevation="0"
        >
            <v-card-title class="justify-space-between">
                <v-icon>mdi-alert-circle</v-icon>
                Abonnement alertes
                <v-icon color="primary" @click="dialog_edit_subscribe_subscription = true">mdi-pencil</v-icon>
            </v-card-title>

            <v-divider/>

            <v-card-text>
                <v-row class="mt-2" v-if="!loading_items_tree_view_user">
                    <v-treeview v-if="items_tree_view_user.length > 0" :items="items_tree_view_user"
                                v-model="items_tree_view_user" return-object
                                selectable selected-color="primary" item-disabled="locked" item-key="index"
                    />

                    <h3 class="ma-4 mt-0" v-else>Vous êtes abonné à toutes les alertes.</h3>
                </v-row>
            </v-card-text>
        </v-card>

        <v-dialog v-model="dialog_edit_subscribe_subscription" max-width="600px" eager>
            <v-card :loading="loading_items_tree_view_global">
                <v-card-title>Gérer mes alertes</v-card-title>

                <v-divider/>

                <v-card-text class="mt-4">
                    <p class="pb-2 font-16">
                        Sélectionnez les <u>sous-ensembles</u> pour lesquelles vous souhaitez recevoir une alerte.
                    </p>

                    <v-treeview selectable :items="items_tree_view_global" v-model="selected_items_tree_view_global"
                                return-object item-key="index" selected-color="primary"
                    />
                </v-card-text>

                <v-card-actions>
                    <v-spacer/>
                    <v-btn @click="dialog_edit_subscribe_subscription = false">
                        <v-icon left>mdi-close</v-icon>
                        {{ $t('global.close') }}
                    </v-btn>
                    <v-btn color="primary" @click="updateSubscriptionSubassembly()">
                        <v-icon left>mdi-content-save</v-icon>
                        {{ $t('global.validation') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {PROFESSIONALS, USERS} from "../../api";

export default {
    name: "ProfessionalTreeStructure",
    data() {
        return {
            loading_items_tree_view_user: false,
            items_tree_view_user: [],
            open_all: false,

            dialog_edit_subscribe_subscription: false,
            loading_items_tree_view_global: false,
            items_tree_view_global: [],
            selected_items_tree_view_global: [],
        }
    },
    created() {
        if (this.$store.state.access_level > 200) {
            this.getProfessionalTreeStructure();
        }
        this.getSubassembliesAssociateToUser();
    },
    methods: {
        getSubassembliesAssociateToUser() {
            this.loading_items_tree_view_user = true;

            this.axios.get(
                USERS + '/site/subscription/tree_structure'
            ).then((success) => {
                this.items_tree_view_user = success.data;
            }).catch((error) => {
                this.showGlobalSnackbar(error, this.$t('global.error'))
            }).finally(() => {
                this.open_all = true;
                this.loading_items_tree_view_user = false;
            });
        },

        getProfessionalTreeStructure() {
            this.loading_items_tree_view_global = true;

            this.axios.get(
                PROFESSIONALS + '/tree_structure'
            ).then((success) => {
                this.items_tree_view_global = success.data.tree_structure;
                this.selected_items_tree_view_global = success.data.selected_items;
            }).catch((error) => {
                this.showGlobalSnackbar(error, this.$t('global.error'))
            }).finally(() => {
                this.loading_items_tree_view_global = false;
            });
        },

        updateSubscriptionSubassembly() {
            this.axios.put(USERS + '/subassembly_subscription', {
                subassemblies: this.selected_items_tree_view_global
            }).then(() => {
                this.getSubassembliesAssociateToUser();
                this.showGlobalSnackbar('success', this.$t('global.success_to_edit'));
            }).catch(() => {
                this.showGlobalSnackbar('error', this.$t('global.failure_to_update'));
            }).finally(() => {
                this.dialog_edit_subscribe_subscription = false;
            });
        }
    },
}
</script>
