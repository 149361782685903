<template>
    <v-card class="rounded-lg" :min-height="minHeight + 'px'" elevation="2" outlined height="100%">
        <v-card-title class="justify-center">
            {{ $t('document.title') }}
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
            <v-row class="mb-4 mr-2 ml-2 mt-2">
                <v-card
                    :class="{ 'grey lighten-2': dragover }"
                    class="w-100" height="150"
                    @dragenter.prevent="dragover = true"
                    @dragleave.prevent="dragover = false"
                    @dragover.prevent="dragover = true"
                    @drop.prevent="onDrop($event)"
                    elevation="2" outlined
                >
                    <v-card-text class="mt-12 text-center">
                        {{ $t('document.put_file_to_download') }}
                    </v-card-text>
                </v-card>
            </v-row>

            <v-row class="ma-2">
                <v-btn @click="openDialogAddExternalLink()" block color="primary">
                    {{ $t('document.add_link_extern_document') }}
                </v-btn>
            </v-row>

            <v-row>
                <v-col class="text-center mb-1">
                    {{ $t('document.element_already_download') }}
                </v-col>
            </v-row>

            <v-progress-linear color="primary" indeterminate v-if="loading"></v-progress-linear>

            <v-virtual-scroll :items="result" height="150" item-height="50" class="mt-1">
                <template v-slot:default="{ item }">
                    <v-list-item>
                        <v-list-item-content v-if="item.link">
                            <v-list-item-title>
                                <a :href="item.link" target="_blank"> {{ item.link }}</a>
                            </v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action v-if="item.link">
                            <v-btn @click.stop="removeExternalLink(item.id)" icon>
                                <v-icon> mdi-close-circle</v-icon>
                            </v-btn>
                        </v-list-item-action>

                        <v-list-item-content v-if="item.file_name">
                            <v-list-item-title>
                                <a :href="item.path" download> {{ item.file_name }}</a>
                            </v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action v-if="item.file_name">
                            <v-btn @click.stop="removeDocument(item.file_name)" icon>
                                <v-icon> mdi-close-circle</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>

                    <v-divider></v-divider>
                </template>
            </v-virtual-scroll>
        </v-card-text>

        <template>
            <v-dialog v-model="dialog_add" max-width="500px">
                <v-card>
                    <v-card-title>
                        <span class="headline">{{ $t('document.add_link_extern_document') }}</span>
                    </v-card-title>
                    <v-divider></v-divider>

                    <v-card-text><br/>
                        <v-form v-model="valid">
                            <v-text-field
                                label="URL" solo
                                :prepend-icon="'mdi-web'"
                                v-model="external_link.link"
                            ></v-text-field>
                        </v-form>
                    </v-card-text>

                    <v-card-actions class="justify-end">
                        <v-btn @click="addExternalLink()" color="primary">
                            <v-icon left>mdi-content-save</v-icon>
                            {{ $t('global.validation') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-snackbar
                v-model="snackbar"
                :color="snackbar_type">
                {{ snackbar_text }}
                <template v-slot:action="{ attrs }">
                    <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                </template>
            </v-snackbar>
        </template>
    </v-card>
</template>

<script>
import {DOCUMENTS, EXTERNAL_LINK} from "../../api";

const authorizedTypes = [
    {name: 'png', value: 'image/png'},
    {name: 'jpg', value: 'image/jpeg'},
    {name: 'pdf', value: 'application/pdf'}
];

export default {
    name: "DocumentsUpload",
    props: {
        parentType: {
            required: true,
        },
        parentId: {
            required: true,
        },
        minHeight: {
            type: Number,
            required: false,
            default: 450
        },
    },
    data() {
        this.authorizedTypes = authorizedTypes;
        return {
            dragover: false,
            files_to_upload: [],
            uploaded_files: [],
            loading: true,
            external_link: {
                id_relatable: this.parentId,
                type_relatable: this.parentType,
                link: ''
            },
            external_links: [],
            dialog_add: false,
            valid: true,
            result: []
        };
    },
    created() {
        this.getExternalLinks();
        this.getDocuments();
    },
    methods: {
        getDocuments() {
            this.axios.get(DOCUMENTS + '/getFiles', {
                    params: {
                        entity_id: this.parentId,
                        type: this.parentType,
                        professional_id: this.$store.state.professional_id
                    }
                }
            ).then((success) => {
                this.uploaded_files = [];
                success.data.forEach((path) => {
                    let fileName = path.replace(/^.*[\\\/]/, '');
                    this.uploaded_files.push({
                        file_name: fileName,
                        path: path
                    });
                });
                this.uploaded_files.reverse();
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            }).finally(() => {
                this.mergeExternalLinkAndDocuments();
                this.loading = false;
            });
        },

        getExternalLinks() {
            this.axios.get(EXTERNAL_LINK, {
                    params: {
                        ['id_' + this.parentType]: this.parentId,
                        type: this.parentType
                    }
                }
            ).then((success) => {
                this.external_links = success.data;
                this.mergeExternalLinkAndDocuments();
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            }).finally(() => {
                this.loading = false;
            });
        },

        removeDocument(fileName) {
            this.loading = true;
            this.axios.delete(DOCUMENTS + '/delete', {
                    params: {
                        file_name: fileName,
                        entity_id: this.parentId,
                        type: this.parentType
                    }
                }
            ).then(() => {
                this.getDocuments();
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_delete'))
            }).finally(() => {
                this.loading = false;
            });
        },

        removeExternalLink(id) {
            this.loading = true;
            this.axios.delete(EXTERNAL_LINK + '/' + id, {
                    params: {
                        id: id
                    }
                }
            ).then(() => {
                this.getExternalLinks();
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_delete'))
            }).finally(() => {
                this.loading = false;
            });
        },

        onDrop(e) {
            this.dragover = false;
            // If there are already uploaded files remove them
            if (this.files_to_upload.length > 0) this.files_to_upload = [];
            // If user has uploaded multiple files but the component is not multiple throw error
            let file;
            for (let i = 0; i < e.dataTransfer.files.length; i++) {
                file = e.dataTransfer.files.item(i);
                let docCheck = this.uploadFilesCheck(file,['image/png','image/jpg','image/jpeg','application/pdf','text/csv'],10);
                if(!docCheck.authorized ) {
                    this.showSnackbar('error',docCheck.output.toString());
                }
                else {
                    this.files_to_upload.push(file);
                }
            }
            if (this.files_to_upload.length > 0) this.uploadDocuments();
        },

        uploadDocuments() {
            this.loading = true;
            this.files_to_upload.forEach((file) => {
                let formData = new FormData();
                formData.append('files[]', file);
                formData.append('entity_id', this.parentId);
                formData.append('type', this.parentType);
                formData.append('professional_id', this.$store.state.professional_id);

                axios.post(DOCUMENTS + '/upload', formData, this.configurationFile)
                    .then(() => {
                        this.getDocuments();
                    })
                    .catch((error) => {
                        if (error.response.status === 413) {
                            this.showSnackbar('warning', this.$t('document.too_large'));
                        } else {
                            this.showSnackbar('error', this.$t('global.failure_to_add'));
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            });
        },

        openDialogAddExternalLink() {
            this.resetExternalLinkObject();
            this.dialog_add = true;

        },

        resetExternalLinkObject() {
            this.external_link.link = '';
        },

        closeDialog() {
            this.dialog_add = false;
        },

        addExternalLink() {
            if (!this.validURL()) {
                this.showSnackbar('error', 'Veuillez saisir une url valide');
            } else {
                this.loading = true;

                this.axios.post(EXTERNAL_LINK, this.external_link
                ).then((success) => {
                    this.result.push(success.data);
                    this.external_links.push(success.data);
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_add'))
                }).finally(() => {
                    this.closeDialog();
                    this.loading = false;
                });
            }
        },

        validURL() {
            let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
            this.valid = !!pattern.test(this.external_link.link);
            return this.valid;
        },

        mergeExternalLinkAndDocuments() {
            this.result = this.uploaded_files.concat(this.external_links);
        }
    },
}
</script>

