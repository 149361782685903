<template>
    <v-row class="row mt-0">
        <!-- MACHINE MENU -->
        <v-col class="col-1 pt-5 pb-5 rounded" style="height: 110%;background-color: #014181;max-width: 70px;position: fixed;top: -20px;z-index: 1;">
            <!-- REPORTS -->
            <v-tooltip bottom dark class="mr-2">
                <template v-slot:activator="{ on }">
                    <v-btn class="ma-2 mt-10 mb-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/report'}" dusk="logout-button">
                        <v-icon color="white">mdi-chart-box</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('machine.reports') }}</span>
            </v-tooltip>
            <!-- ALERTS -->
            <v-tooltip bottom dark class="mr-2">
                <template v-slot:activator="{ on }">
                    <v-btn class="ma-2 mt-5 mb-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/events'}" dusk="logout-button">
                        <v-icon color="white">mdi-alert</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('machine.alerts') }}</span>
            </v-tooltip>
            <!-- SETTINGS -->
            <v-tooltip bottom dark class="mr-2">
                <template v-slot:activator="{ on }">
                    <v-btn class="ma-2 mt-5 mb-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/settings'}" dusk="logout-button">
                        <v-icon color="white">mdi-cog</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('machine.settings') }}</span>
            </v-tooltip>
            <!-- DETAILS -->
            <v-tooltip v-if="access_level <= 100 || access_level === 200 || access_level === 300 " bottom dark class="mr-2">
                <template v-slot:activator="{ on }">
                    <v-btn class="ma-2 mt-5 mb-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/details'}" dusk="logout-button">
                        <v-icon color="white">mdi-chart-line</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('machine.courbes') }}</span>
            </v-tooltip>
        </v-col>        
        
        <!-- CONTENT -->
        <v-col class="col pa-0 pl-5 pr-5" style="margin-left: 70px;">
            <!-- TOP TITLE -->
            <v-row class="row mt-0">
                <!-- PAGE TITLE -->
                <v-subheader class="v-subheader col theme--light pl-5 ml-2 mt-3">
                    <p class="title mb-0 color-primary text-uppercase">
                        {{ $t('machines.mesures') }}
                    </p>
                    <!------------------- Info bulle ---------------------->
                    <v-tooltip bottom dark class="mr-2">
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                                <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:default>
                        <div style="min-width: 200px;position:relative;z-index: 10;">
                            <strong>Page [Mesures]</strong>
                            <p>Nos instruments de mesures sont interconnectés à nos services en ligne.</br>
                                Ceux-ci peuvent donc renvoyer leurs données sur cet onglet de manière indépendante pour chaque machine.</br></br>
                                **Attention, la visualisation des courbes sur cet écran n'est pas individuel dans cette version.</br>
                                Nous modifierons ces paramètres dans notre prochaine version [1.5.2] pour vous permettre d'avoir une visualisation liée à votre compte uniquement.
                            </p>
                        </div>
                        </template>
                    </v-tooltip>
                </v-subheader>

                <!-- FORMAT SELECTOR -->
                <v-row cols="2" class="v-subheader col-1 theme--light mr-3">
                    <!-- SPACER -->
                    <v-col class="col"></v-col>
                </v-row>
            </v-row>

            <!-- STATE DATAS -->
            <v-col class="row col-12 mb-0 pa-0">
                <!-- COUNTER -->
                <v-col color="white" class="col-auto pa-0 mt-5" v-if="machine">
                    <v-col class="col pt-0 pb-0 mr-10 rounded col ml-6" style="height: 35px;background-color: rgb(224 232 240);font-weight: bold;text-align: center;font-size: 16px;border: 1px solid #014181;line-height: 35px;color: #014181;">
                        {{ machine.label }}
                    </v-col>
                </v-col>

                <!-- GLOBAL ACTIONS -->
                <v-col class="row col ml-6 pt-8">
                    <v-tooltip bottom dark class="mr-2">
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" @click="getData()" class="mr-2" dusk="logout-button" style="border: 1px solid #01418145;">
                                <v-icon color="primary">mdi-tag-multiple</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('global.1_5_2') }}</span>
                    </v-tooltip>
                    <v-tooltip bottom dark class="mr-2">
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" @click="getData()" class="mr-2" dusk="logout-button" style="border: 1px solid #01418145;">
                                <v-icon color="primary">mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('machines.refresh') }}</span>
                    </v-tooltip>
                </v-col>

                <!-- SPACER -->
                <v-col class="row col"></v-col> 

                <v-col cols="3">
                    <favorite-curve v-bind:parentType="type2" justify-end
                                    v-on:getSubassemblyFavoriteCurves="getSubassemblyFavoriteCurves"
                                    v-on:getSubassemblyWordCurves="getSubassemblyWordCurves">
                    </favorite-curve>
                </v-col>

                <v-col cols="3">
                    <modal-comparison-curves v-bind:parentType="type" justify-end
                                            v-on:getMachineComparisonCurves="getMachineComparisonCurves"
                                            v-on:getMachineThreeDimensionalCurves="getMachineThreeDimensionalCurves">
                    </modal-comparison-curves>
                </v-col>
            </v-col>

            <!-- MACHINE CURVES CONTENT -->
            <v-col class="pt-0">
                <!-- OPERATIONS -->
                <div id="machine-detail">
                    <!-- CURVES -> TO ADD TO DETAILS PAGE -->
                    <v-row class="mt-0">
                        <template v-for="(favorite_curve) in favorite_curves" >
                            <v-col cols="6" v-if="favorite_curve.chart_type === 1" :key="favorite_curve.label">
                                <time-curve v-bind:favoriteCurve="favorite_curve"
                                            v-on:deleteFavoriteCurve="deleteFavoriteCurve"
                                            v-on:updateOperationDatatable="updateOperationDatatable"
                                ></time-curve>
                            </v-col>
                            
                            <v-col cols="6" v-else-if="$store.state.access_level <= 100" :key="favorite_curve">
                                <spectrum-curve v-bind:favoriteCurve="favorite_curve"
                                                v-on:deleteFavoriteCurve="deleteFavoriteCurve"
                                ></spectrum-curve>
                            </v-col>
                        </template>

                        <template v-for="word_curve in word_curves">
                            <v-col cols="6" :key="word_curve">
                                <word-curves v-bind:word-curve="word_curve" v-on:deleteWordCurve="deleteWordCurve" class="mt-6"/>
                            </v-col>
                        </template>

                        <template v-for="(comparison_curve) in comparison_curves">
                            <v-col cols="6" :key="comparison_curve">
                                <comparison-curves v-bind:comparison-curve="comparison_curve"
                                                v-on:deleteComparisonCurves="deleteComparisonCurves"
                                                :key="comparison_curve.id"
                                />
                            </v-col>
                        </template>

                        <template v-for="three_dimensional_curve in three_dimensional_curves">
                            <v-col cols="6">
                                <three-dimensional-curves v-bind:three-dimensional-curve="three_dimensional_curve"
                                                        v-on:deleteThreeDimensionalCurve="deleteThreeDimensionalCurve"
                                                        :key="three_dimensional_curve.id"
                                />
                            </v-col>
                        </template>
                    </v-row>
                </div>

                <!-- SNACKBAR -->
                <v-snackbar
                    v-model="snackbar"
                    :color="snackbar_type">
                    {{ snackbar_text }}
                    <template v-slot:action="{ attrs }">
                        <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                    </template>
                </v-snackbar>
            </v-col>
        </v-col>
    </v-row>

</template>
<script>

import DocumentsUpload from "../../components/DocumentsUpload";
import MachineSpecifications from "../../components/Machine/MachineSpecifications";
import MachineState from "../../components/Machine/MachineState";
import DatatableMachineSubassemblies from "../../components/Machine/DatatableMachineSubassemblies";
import DatatableOperations from "../../components/DatatableOperations";
import ModalComparisonCurves from "../../components/Machine/ComparisonCurves/ModalComparisonCurves";
import ComparisonCurves from "../../components/Machine/ComparisonCurves/ComparisonCurves";
import ThreeDimensionalCurves from "../../components/Machine/ThreeDimensionalCurves/ThreeDimensionalCurves";
// FROM SUBASSEMBLY DETAILS
import DatatableSubassemblyOperations from "../../components/Subassembly/Operation/DatatableSubassemblyOperations";
import SubassemblyState from "../../components/Subassembly/SubassemblyState";
import FavoriteCurve from "../../components/Subassembly/Curve/FavoriteCurve";
import SubassemblySpecifications from "../../components/Subassembly/Specifications/SubassemblySpecifications";
import TimeCurve from '../../components/Subassembly/Curve/TimeCurve';
import SpectrumCurve from '../../components/Subassembly/Curve/SpectrumCurve';
import WordCurves from "../../components/Subassembly/WordCurves/WordCurves";

import {COMPARISON_CURVES, THREE_DIMENSION_CURVES, FAVORITE_CURVES, WORD_CURVE, MACHINES} from "../../../api";
import moment from "moment";

export default {
    name: "DetailMachine",
    components: {
        ThreeDimensionalCurves,
        MachineSpecifications,
        MachineState,
        DatatableMachineSubassemblies,
        DatatableOperations,
        ModalComparisonCurves,
        ComparisonCurves,
        WordCurves,
        DocumentsUpload,
        DatatableSubassemblyOperations,
        SubassemblyState,
        FavoriteCurve,
        SubassemblySpecifications,
        SpectrumCurve,
        TimeCurve,
    },
    data() {
        return {
            id: this.$route.params.id,
            access_level: this.$store.state.access_level,
            type: "machine",
            machine:[],
            label: '',
            comparison_curves: [],
            three_dimensional_curves: [],
            machineTitle:"",

            type2: "subassembly",
            favorite_curves: [],
            word_curves: [],
            label: "",
            machine_filters: [
                {
                    level: 9,
                    level_name: "Tout voir",
                    level_color: "#d7d7d752",
                    count:this.global_notifications_count
                },
                {
                    level: 3,
                    level_name: "Urgence",
                    level_color: "black",
                    count:0
                },
                {
                    level: 2,
                    level_name: "Importance élevée",
                    level_color: "#F44336",
                    count:0
                },
                {
                    level: 1,
                    level_name: "A suivre",
                    level_color: "#FFCB05",
                    count:0
                },
                {
                    level: 0,
                    level_name: "Pour information",
                    level_color: "#4CAF50",
                    count:0
                },
                {
                    level: -1,
                    level_name: "En attente",
                    level_color: "grey",
                    count:0
                },                
            ],
        }
    },
    created() {
        this.getMachine();
        this.getMachineComparisonCurves();
        this.getMachineThreeDimensionalCurves();
        this.getSubassemblyFavoriteCurves();
        this.getSubassemblyWordCurves();
    },
    methods: {
        getMachine() {
            this.axios.get(MACHINES + '/specific/' + this.id)
            
            .then((success) => {
                this.machine = [];
                this.machine = success.data[0];

            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");
            })
        },
        checkDataAvailable(datas){
            if(typeof datas[0] === 'undefined') {                    
                return false;
            }else {
                return true;
            }
        },
        getMachineComparisonCurves() {
            this.axios.get(
                MACHINES + "/comparison-curves/" + this.id
            ).then((success) => {
                this.comparison_curves = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            });
        },

        getMachineThreeDimensionalCurves() {
            this.axios.get(
                MACHINES + "/td-curves/" + this.id
            ).then((success) => {
                    this.three_dimensional_curves = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            });
        },

        deleteComparisonCurves(item) {
            let removed_item= this.comparison_curves.splice(this.comparison_curves.findIndex(
                comparison_curve => comparison_curve.id === item
            ), 1);
        },

        deleteThreeDimensionalCurve(item){
            this.three_dimensional_curves.splice(this.three_dimensional_curves.findIndex(
                three_dimensional_curve => three_dimensional_curve.id === item
            ),1);
            this.showSnackbar('success', this.$t('global.success_to_delete'));
        },

        updateLabelMachine(item) {
            this.label = item.label;
        },
        getSubassemblyFavoriteCurves() {
            this.axios.get(
                MACHINES + "/favorite-curves/" + this.id
            ).then((success) => {
                this.favorite_curves = success.data;
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('global.error'));
            });
        },

        getSubassemblyWordCurves() {
            this.axios.get(
                MACHINES + "/word-curves/" + this.id
            ).then((success) => {
                this.word_curves = success.data;
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('global.error'));
            });
        },

        deleteFavoriteCurve(item) {
            this.favorite_curves.splice(this.favorite_curves.findIndex(
                favorite_curve => favorite_curve.id === item
            ), 1);
        },

        deleteWordCurve(item) {
            this.word_curves.splice(this.word_curves.findIndex(word_curve => word_curve.id === item), 1);
            this.showGlobalSnackbar('success', this.$t('global.success_to_delete'));
        },

        updateSubassemblyLabel(item) {
            this.label = item.label;
        },

        updateOperationDatatable() {
            this.$refs.dt_operations.getOperations();
        },

        updateSubassemblyDevices() {
            this.$refs.subassembly_state.getSubassembly();
        },
    }
}
</script>
<style scoped lang="scss">
#fixed-col {
    min-width: 638px;
    max-width: 638px !important;
}
@media only screen and (max-width: 480px) {
    #fixed-col {
        min-width: 100%;
        max-width: 100% !important;
    }
}
</style>
