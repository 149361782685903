<template>
    <div id="site-detail">
        <title-toolbar v-bind:title="$t('sites.detail') + ' : ' + site.label"/>

        <v-row class="justify-center">
            <v-col id="fixed-col" class="pb-0">
                <site-state/>
            </v-col>
            <v-col class="pb-0" :class="is_mobile ? '' : 'pl-0'">
                <autocomplete-map-card :site="site"/>
            </v-col>
        </v-row>

        <<datatable-operations :entity-name="'site'"/>

        <datatable-site-machines class="mt-3"/>
    </div>
</template>

<script>
import {find} from 'lodash';
import DatatableSiteMachines from '../../components/Site/DatatableSiteMachines';
import SiteState from '../../components/Site/SiteState';
import AutocompleteMapCard from '../../components/Site/AutocompleteMapCard';
import DatatableOperations from '../../components/DatatableOperations';
import TitleToolbar from '../../components/TitleToolbar';

export default {
    name: 'DetailSite',
    components: {
        TitleToolbar,
        DatatableSiteMachines,
        SiteState,
        AutocompleteMapCard,
        DatatableOperations,
    },
    async created() {
        await this.$store.dispatch('sites/load');
    },
    computed: {
        sites() {
            return this.$store.state.sites;
        },
        site() {
            return find(this.sites, {id: parseInt(this.$route.params.id, 10)});
        },
    },
}
</script>

<style scoped lang='scss'>
#fixed-col {
    min-width: 638px;
    max-width: 638px !important;
}

@media only screen and (max-width: 480px) {
    #fixed-col {
        min-width: 100%;
        max-width: 100% !important;
    }
}
</style>
