<template>
    <div id="favorite-curve" v-if="access_level <= 100 || access_level === 200 || access_level === 300">
        <v-btn style="background-color: #ffffff; color:#001047;" @click="dialog_edit_favorite_curves = true" block>
            <v-icon left>mdi-tooltip-plus</v-icon>
            {{ $t('global.add_graphic') }}
        </v-btn>

        <v-dialog v-model="dialog_edit_favorite_curves" scrollable max-width="500px">
            <v-card>
                <validation-observer ref="observer" v-slot="{ invalid }">
                    <v-form ref="form">
                        <v-card-title>
                            <span class="headline">{{ $t('global.add_graphic') }}</span>
                        </v-card-title>
                        <v-divider/>

                        <v-card-text>
                            <validation-provider :name="$t('global.label')" rules="required"
                                                 v-slot="{ errors }">
                                <v-text-field
                                    :label="$t('global.name')" solo
                                    :error-messages="errors"
                                    v-model="edited_favorite_curve.label"
                                    :prepend-icon="'mdi-label-outline'"
                                />
                            </validation-provider>

                            <validation-provider :name="$t('global.devices')" rules="required"
                                                 v-slot="{ errors }">
                                <v-select
                                    :items="virtual_devices"
                                    v-model="edited_favorite_curve.virtual_device_id"
                                    :label="$t('global.devices')"
                                    :error-messages="errors"
                                    :prepend-icon="'mdi-chemical-weapon'"
                                    item-text="label"
                                    item-value="id"
                                    @change="getMetricsOfVirtualDevices()" solo
                                >
                                    <template slot="selection" slot-scope="data">
                                        {{ data.item.label }}
                                    </template>
                                    <template slot="item" slot-scope="data">
                                        {{ data.item.label }}
                                    </template>
                                </v-select>
                            </validation-provider>

                            <validation-provider :name="$t('favorite_curve.physical_value')" rules="required"
                                                 v-slot="{ errors }">
                                <v-select
                                    :items="virtual_device_metric_types"
                                    v-model="metric_name"
                                    :label="$t('favorite_curve.physical_value')"
                                    :error-messages="errors"
                                    :prepend-icon="'mdi-tape-measure'"
                                    item-text="metric_name"
                                    item-value="metric_uuid"
                                    :disabled="disabled_virtual_device_metric_types"
                                    :loading="loading_virtual_device_metric_types" solo
                                ></v-select>
                            </validation-provider>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer/>
                            <v-btn @click="closeDialogEditFavoriteCurve()">
                                <v-icon left>mdi-close</v-icon>
                                {{ $t('global.close') }}
                            </v-btn>
                            <v-btn type="submit" color="primary"
                                   @click.prevent="addCurve()"
                                   :disabled="invalid">
                                <v-icon left>mdi-content-save</v-icon>
                                {{ $t('global.validation') }}
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </validation-observer>
            </v-card>
        </v-dialog>

        <v-snackbar
            v-model="snackbar"
            :color="snackbar_type">
            {{ snackbar_text }}
            <template v-slot:action="{ attrs }">
                <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
            </template>
        </v-snackbar>
    </div>
</template>

<script>

import {FAVORITE_CURVES, SUBASSEMBLIES, VIRTUAL_DEVICES, WORD_CURVE,MACHINES} from "../../../../api";

export default {
    name: "FavoriteCurve",
    props: ["parentType"],
    data() {
        return {
            dialog_edit_favorite_curves: false,
            virtual_devices: [],
            virtual_device_metric_types: [],
            invalid: false,
            disabled_virtual_device_metric_types: true,
            loading_virtual_device_metric_types: false,
            chosen_curve_type:'',
            access_level: this.$store.state.access_level,
            metric_name: '',
            metric_type: '',
            edited_favorite_curve: {
                id: null,
                id_relatable: '',
                type_relatable: this.parentType,
                label: '',
                chart_type: '',
                metric_uuid: '',
                metric_unit: '',
                virtual_device_id: null,
                configuration: {
                    'color1' : "#25498A",
                    'color2' : "#E7186D"
                }
            }
        }
    },
    watch: {
        metric_name: function (val) {
            if (val !== null) {
                this.edited_favorite_curve.metric_uuid = val;

                let metric = this.virtual_device_metric_types.filter(function (metric) {
                    if (metric.metric_uuid === val) return metric;
                })[0];

                this.metric_type = metric.metric_type.toUpperCase();
                this.edited_favorite_curve.metric_unit = metric.metric_unit;
            }
        }
    },
    created() {
        this.getSubassemblyVirtualDevices();
    },
    methods: {
        // TODO : DEBUG THIS PHASE TO PASS FROM MACHINE TO VIRTUAL DEVICES
        // MACHINE -> Virts -> Curves
        getSubassemblyVirtualDevices() {
            this.axios.get(
                MACHINES + "/" + this.$route.params.id + "/virtual_devices"
            ).then((success) => {
                this.virtual_devices = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'))
            });
        },
        getMetricsOfVirtualDevices() {
            this.loading_virtual_device_metric_types = true;

            this.virtual_devices.forEach( virtual_device =>{
                if (virtual_device.id == this.edited_favorite_curve.virtual_device_id)
                this.edited_favorite_curve.id_relatable = virtual_device.subassembly_id;
            })

            this.axios.get(
                VIRTUAL_DEVICES + "/" + this.edited_favorite_curve.virtual_device_id + "/metric_types"
            
            ).then((success) => {
                if (this.$store.state.access_level === 200 || this.$store.state.access_level === 300) {
                    success.data.forEach((metric) => {
                        if (metric.metric_type !== "FFT" && metric.metric_unit !== "fft") {
                            this.virtual_device_metric_types.push(metric);
                        }
                    });
                } else {
                    this.virtual_device_metric_types = success.data;
                }

                this.disabled_virtual_device_metric_types = false;

            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'))
            }).finally(() => {
                this.loading_virtual_device_metric_types = false;
            });
        },
        addCurve(){
            this.metric_type === "TEXT" ? this.addWordCurve():this.addFavoriteCurve();
        },
        addFavoriteCurve() {
            this.metric_type !== 'FFT' ? this.edited_favorite_curve.chart_type = 1 : this.edited_favorite_curve.chart_type = 2;
            //this.edited_favorite_curve.id_relatable = this.virtual_devices.subassembly_id;

            this.axios.post(
                FAVORITE_CURVES, this.edited_favorite_curve
            ).then(() => {
                this.$emit('getSubassemblyFavoriteCurves');
                this.showSnackbar('success', this.$t('global.success_to_add'));
                this.closeDialogEditFavoriteCurve();
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_add'));
            });
        },
        addWordCurve() {
            this.axios.post(
                WORD_CURVE,this.edited_favorite_curve
            ).then(()=>{
                this.$emit('getSubassemblyWordCurves');
                this.showSnackbar('success', this.$t('global.success_to_add'));
                this.closeDialogEditFavoriteCurve();
            }).catch(()=>{
                this.showSnackbar('error', this.$t('global.failure_to_add'));
            })
        },
        closeDialogEditFavoriteCurve() {
            this.dialog_edit_favorite_curves = false;
            this.$refs.form.reset();
            this.$refs.observer.reset();
        }
    }
}
</script>
