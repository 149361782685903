<template>
    <div id="machine-vision">
        <v-row class="row mt-5">
            <!-- PAGE TITLE -->
            <v-subheader class="v-subheader col theme--light pl-5 ml-2">
                <p class="title mb-0 color-primary text-uppercase">
                    {{ $t('machines.title') }}
                </p>
                <!------------------- Info bulle ---------------------->
                <v-tooltip bottom dark class="mr-2">
                    <template v-slot:activator="{ on }">
                        <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                            <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:default>
                    <div style="min-width: 200px;">
                        <strong>Page [Equipement]</strong>
                        <p>Toutes les machines que nous sommes susceptibles de surveiller se trouvent ici.</br>
                            Toutes les caractéristiques techniques associées au suivi en ligne sont configurés sur cette page.</br>
                            Ceci est valable par exemple pour les sous-ensembles. </br>
                            Cliquer sur l'un d'entre eux pour le visualiser en détail.
                        </p>
                    </div>
                    </template>
                </v-tooltip>
            </v-subheader>

            <!-- FORMAT SELECTOR -->
            <v-row cols="2" class="v-subheader col-1 theme--light mr-3">
                <!-- SPACER -->
                <v-col class="col"></v-col>

                <!-- CARD VIEW -->
                <v-tooltip bottom dark>
                    <template v-slot:activator="{ on }">
                        <v-btn text icon v-on="on" @click="setCards()" dusk="logout-button">
                            <v-icon color="primary">mdi-card-text-outline</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('notification.list-card') }}</span>
                </v-tooltip>

                <!-- LIST VIEW -->
                <v-tooltip v-if="access_level <= 99" bottom dark>
                    <template v-slot:activator="{ on }">
                        <v-btn text icon v-on="on" @click="setDatatable()" dusk="logout-button">
                            <v-icon color="primary">mdi-format-list-bulleted</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('notification.list-view') }}</span>
                </v-tooltip>
            </v-row>
        </v-row>

        <!-- IMPORT CONTENT -->
        <cards-notifications class="mt-6" v-if="cards"/>
        <datatable-notifications class="mt-6" v-if="!cards"/>
    </div>
</template>

<script>
import DatatableNotifications from "../../components/Machine/VisionDatatable";
import CardsNotifications from "../../components/Machine/VisionCards";
import { MACHINES } from "../../../api";

export default {
    name: "Vision",
    components: {DatatableNotifications, CardsNotifications},
    data() {
        return {
            cards: true,
            id: this.$route.params.id,
            access_level: this.$store.state.access_level,
        }
    },
    created() {
        this.setCards();
    },
    methods: {
        getMachines() {
            this.axios.get(REPORT + "/specific_machine/" + this.id)
            
            .then((success) => {
                if (this.checkDataAvailable(success.data)){
                    machineTitle = success.data.label
                }
            })
        },
        checkDataAvailable(datas){
            if(typeof datas[0] === 'undefined') {                    
                return false;
            }else {
                return true;
            }
        },
        switchView() {
            this.cards = !this.cards;
        },

        setCards() {
            this.cards = true;
        },

        setDatatable() {
            this.cards = false;
        },
    }
}


</script>
