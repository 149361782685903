<template>
    <div id="comparison-curve">
        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }" v-if="access_level <= 100 || access_level === 200 || access_level === 300">
                <v-btn style="background-color: #ffffff; color:#001047;" block v-bind="attrs" v-on="on">
                    <v-icon left>mdi-tooltip-plus</v-icon>
                    {{ $t('three_dimensional_curve.add_curve') }}
                </v-btn>
            </template>
            <v-list class="mt-1">
                <v-list-item link @click="openAddComparisonCurveModal()">
                    <v-list-item-title>{{ $t('modal_comparison_curves.add_comparison_curves') }}</v-list-item-title>
                </v-list-item>
                <v-list-item link @click="displayAddThreeDimensionalCurveModal()">
                    <v-list-item-title>{{ $t('three_dimensional_curve.title')}}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

        <v-dialog v-if="add_comparison_curve_modal_visible" v-model="dialog_add_comparison_curve" scrollable max-width="500px" content-class="v-dialog-height">
            <v-card>
                <v-card-title>
                    <span class="headline">{{ $t('modal_comparison_curves.add_comparison_curves') }}</span>
                </v-card-title>
                <v-divider/>
                <v-form ref="form" v-model="valid">
                    <v-card-text class="mt-6">
                        <v-text-field
                            :label="$t('global.name')"
                            :prepend-icon="'mdi-label-outline'"
                            v-model="comparison_curve.label"
                            :rules="[v => !!v || $t('vee-validation.required_field')]"
                            solo
                        />
                        <v-expansion-panels ref="panels">
                            <v-expansion-panel v-for="i in comparison_curve_number"
                                               :key="i" ref="expansion_panel" v-if="comparison_curve_number > 0">
                                <v-expansion-panel-header ripple>
                                    <span><v-icon size="18" class="mr-3">mdi-chart-bell-curve-cumulative</v-icon>{{ $t('global.curve')}} : {{ i }}</span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <modal-add-comparison-curves
                                        ref="inputs"
                                        v-bind:parentType="parentType"
                                        v-bind:traceIndex="i-1"
                                        v-on:deleteCurveForm="deleteCurveForm"
                                        v-on:setTraceDetail="getTraceDetails"
                                    >
                                    </modal-add-comparison-curves>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <v-row class="justify-center mt-5">
                            <!--<v-fab-transition>
                                <v-btn @click='incrementCurveNumber()'
                                       color="primary"
                                       elevation="12"
                                       absolute
                                       small
                                >
                                    <v-icon size="17">mdi-plus</v-icon>
                                </v-btn>
                            </v-fab-transition>-->
                            <v-btn @click='incrementCurveNumber()'
                                   color="primary"
                                   elevation="2"
                                   style="z-index: 999999"
                            >
                                <v-icon size="17" class="mr-1">mdi-plus</v-icon>
                                {{ $t('favorite_curve.add_favorite_curve')}}
                            </v-btn>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="justify-end mt-4">
                        <v-btn @click="closeDialogAddComparisonCurves()">
                            <v-icon left>mdi-close</v-icon>
                            {{ $t('global.close') }}
                        </v-btn>
                        <v-btn
                                color="primary"
                               :disabled="!valid"
                               @click="saveComparisonCurve()"
                        >
                            <v-icon left>mdi-content-save</v-icon>
                            {{ $t('global.validation') }}
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
        <three-dimensional-curve-settings-modal ref="settings" v-on:getMachineThreeDimensionalCurves="$emit('getMachineThreeDimensionalCurves')"/>
        <global-snackbar/>
    </div>
</template>

<script>
import {COMPARISON_CURVES} from "../../../../api";
import ModalAddComparisonCurves from "./ModalAddComparisonCurve";
import GlobalSnackbar from "../../../../global/components/GlobalSnackbar";
import ThreeDimensionalCurveSettingsModal from "../ThreeDimensionalCurves/ThreeDimensionalCurveSettingsModal";

export default {
    name: "ModalComparisonCurves",
    components: {GlobalSnackbar, ModalAddComparisonCurves,ThreeDimensionalCurveSettingsModal},
    props: ["parentType"],
    data() {
        return {
            comparison_curve_number:2,
            dialog_add_comparison_curve:false,
            add_comparison_curve_modal_visible:false,
            access_level: this.$store.state.access_level,
            comparison_curve:{
                id_relatable: this.$route.params.id,
                type_relatable: this.parentType,
                label:null,
                traces:[],
                toggle_panel:'',
            },
            valid:true
        }
    },
    methods: {
        openAddComparisonCurveModal(){
            this.add_comparison_curve_modal_visible=true;
            this.$nextTick(() => {
                this.dialog_add_comparison_curve=true;
            });
        },

        incrementCurveNumber(){
            this.comparison_curve_number++;
        },

        closeDialogAddComparisonCurves() {
            if(this.$refs.inputs){
                this.$refs.inputs.forEach((traceInfo) => {
                    traceInfo.$refs.observer.reset();
                    traceInfo.$refs.form.reset();
                });
            }
            this.comparison_curve.label=null;
            this.comparison_curve.traces=[];
            this.dialog_add_comparison_curve = false;
            this.comparison_curve_number= 2;
            this.add_comparison_curve_modal_visible= false;
        },

        getTraceDetails(trace,index){
            this.comparison_curve.traces[index]=trace;
        },

        deleteCurveForm(index){
            this.$refs.expansion_panel[index].$destroy();
            this.$refs.expansion_panel[index].$el.parentNode.removeChild(this.$refs.expansion_panel[index].$el);
            //this.comparison_curve.traces= this.comparison_curve.traces.splice(index,1);
            this.comparison_curve.traces= this.comparison_curve.traces.filter((value,key) => {
                return key !== index;
            });
            this.comparison_curve_number = this.$refs.expansion_panel.length;
        },

        addFormValidation(){
            let valid = true;
            this.comparison_curve.traces= this.comparison_curve.traces.filter((value)=>{
                return value !== undefined;
            });
            if(this.comparison_curve.traces.length < 2) {
                valid = false;
            }
            return valid;
        },

        saveComparisonCurve(){
            if(!this.addFormValidation()){
                this.showGlobalSnackbar('error',this.$t('modal_comparison_curves.add_comparison_curves_check_notice'));
            }
            else {
                this.axios.post(
                    COMPARISON_CURVES,this.comparison_curve
                ).then(()=> {
                    this.$emit('getMachineComparisonCurves');
                    this.showGlobalSnackbar('success', this.$t('global.success_to_add'))
                }).catch((error) => {
                    this.manageError(error.response.status,this.$t('global.failure_to_add'))
                }).finally(()=>{
                    this.closeDialogAddComparisonCurves();
                });
            }
        },

        displayAddThreeDimensionalCurveModal(){
            this.$refs.settings.three_dimensional_curve_settings_open_dialog = true;
            this.$refs.settings.title = 'add';
        },
    }
}
</script>
