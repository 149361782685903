<template>
    <div id="subassembly-vision">
        <v-row>
            <v-col style="background-color: white">
                <v-subheader>
                    <p class="title mb-0">
                        {{ $t('sites.title') }}
                    </p>
                </v-subheader>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-card>
                    <v-data-table
                        :headers="headers"
                        :items="sites"
                        sort-by="id"
                        class="elevation-1"
                        :loading="loading"
                        :search="search">
                        
                        <template v-slot:top>
                            <v-toolbar flat color="white">
                                <!-- Active buttons -->
                                <v-layout class="justify-start">
                                    <v-btn color="primary" class="white--text mr-3" @click="openDialogAddSite()">
                                        {{ $t('sites.add_site') }}
                                    </v-btn>
                                    <v-btn color="primary" class="white--text mr-3" @click="openDialogAddBuilding()">
                                        {{ $t('buildings.add_building') }}
                                    </v-btn>
                                </v-layout>

                                <!-- Search field -->
                                <v-layout class="justify-end mt-6 max-width-300">
                                    <v-text-field
                                        v-model="search"
                                        append-icon="mdi-magnify"
                                        :label="$t('global.search')"
                                    />
                                </v-layout>
                                
                                <!-- Modal add site -->
                                <v-dialog v-model="dialog_edit" max-width="500px" eager>
                                    <v-card>
                                        <validation-observer ref="observer" v-slot="{ invalid }">
                                            <v-form ref="form">
                                                <v-card-title>
                                                    <span class="headline">{{ dialogTitle }}</span>
                                                </v-card-title>

                                                <v-divider/>

                                                <v-card-text>
                                                    <validation-provider :name="$t('global.name')" rules="required"
                                                                         v-slot="{ errors }">
                                                        <v-text-field solo
                                                                      :label="$t('global.name')"
                                                                      v-model="edited_site.label"
                                                                      prepend-icon="mdi-label-outline"
                                                                      :error-messages="errors"
                                                        />
                                                    </validation-provider>

                                                    <validation-provider :name="$t('sites.address')" rules="required"
                                                                         v-slot="{ errors }">
                                                        <vuetify-google-autocomplete
                                                            id="autocomplete-site" solo
                                                            ref="siteAddress"
                                                            prepend-icon="mdi-map-marker"
                                                            v-model="edited_site.address"
                                                            @placechanged="setPlace"
                                                            :error-messages="errors"
                                                        >
                                                            <template #label>
                                                                {{$t('sites.address')}}
                                                            </template>
                                                        </vuetify-google-autocomplete>

                                                    </validation-provider>

                                                    <v-file-input
                                                        v-if="edited_index === null"
                                                        v-model="edited_site.path_plan"
                                                        :label="$t('sites.site_plan')"
                                                        solo
                                                        @change="onImageChanged"
                                                    />

                                                    <v-textarea rows="2" solo no-resize
                                                                v-model="edited_site.note === 'null' ? '' : edited_site.note"
                                                                :label="$t('global.notes')"
                                                                :prepend-icon="'mdi-comment-outline'"
                                                    />
                                                </v-card-text>

                                                <v-card-actions class="justify-end">
                                                    <v-btn @click="closeDialog()">
                                                        <v-icon left>mdi-close</v-icon>
                                                        {{ $t('global.close') }}
                                                    </v-btn>
                                                    <v-btn type="submit" color="primary" :disabled="invalid"
                                                           @click.prevent="prepareEditSite()">
                                                        <v-icon left>mdi-content-save</v-icon>
                                                        {{ $t('global.validation') }}
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-form>
                                        </validation-observer>
                                    </v-card>
                                </v-dialog>

                                <!-- Delete button (if 0 buildings associated) -->
                                <v-dialog v-model="dialog_delete" max-width="500px">
                                    <v-card>
                                        <v-card-title>
                                            <span class="headline">{{ $t('sites.delete_site') }}</span>
                                        </v-card-title>

                                        <v-divider/>

                                        <v-card-actions class="justify-end mt-4">
                                            <v-btn @click="dialog_delete = false">
                                                <v-icon left>mdi-close</v-icon>
                                                {{ $t('global.cancel') }}
                                            </v-btn>
                                            <v-btn color="error" @click="confirmDelete()">
                                                <v-icon left>mdi-delete</v-icon>
                                                {{ $t('global.delete') }}
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-toolbar>
                        </template>

                        <template v-slot:item.label="{ item }">
                            <router-link :to="{ path: '/site/' + item.id}">
                                {{ item.label }}
                            </router-link>
                        </template>

                        <template v-slot:item.path_plan="{ item }">
                            <v-img aspect-ratio="1" v-if="item.path_plan" class="mb-2 mt-2" :src="item.path_plan"
                                   height="200" contain>
                                <template v-slot:placeholder>
                                    <v-row class="fill-height ma-0 background-loader-image"
                                           align="center" justify="center"
                                    >
                                        <v-progress-circular indeterminate color="black lighten-5"/>
                                    </v-row>
                                </template>
                            </v-img>
                        </template>

                        <template v-slot:item.buildings="{ item }">
                            <span v-if="item.buildings.length === 0">{{ $t('sites.no_buildings') }}</span>
                            <v-chip-group v-else>
                                <v-chip v-for="building in item.buildings" :key="building.id"
                                        :color="assetColor(building)" text-color="white"
                                        @click="$router.push({ name: 'building', params: {id: building.id} })"
                                >
                                    {{ building.label }}
                                </v-chip>
                            </v-chip-group>
                        </template>

                        <template v-slot:item.action="{ item }">
                            <v-icon class="pointer" @click="openDialogUpdateSite(item)">mdi-pencil</v-icon>
                            <v-icon class="pointer" @click="openDialogDeleteSite(item)"
                                    v-if="item.buildings.length === 0"
                            >
                                mdi-delete
                            </v-icon>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
        
        <v-snackbar
            v-model="snackbar"
            :color="snackbar_type">
            {{ snackbar_text }}
            <template v-slot:action="{ attrs }">
                <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import {SITES} from "../../../api";
import TitleToolbar from "../../components/TitleToolbar";
import GlobalSnackbar from "../../../global/components/GlobalSnackbar";

export default {
    name: 'SiteVision',
    components: {TitleToolbar, GlobalSnackbar},
    data() {
        return {
            loading: true,
            search: '',
            sites: [],
            headers: [
                {text: this.$t('global.name'), value: 'label'},
                {text: this.$t('sites.address'), value: 'address'},
                {text: this.$t('sites.site_plan'), value: 'path_plan'},
                {text: this.$t('sites.buildings'), value: 'buildings'},
                {text: this.$t('sites.total_machines'), value: 'machines_count'},
                {text: this.$t('global.actions'), value: 'action'}
            ],

            dialog_edit: false,
            edited_index: null,
            edited_site: {
                id: null,
                label: '',
                address: '',
                note: '',
                latitude: '',
                longitude: '',
                path_plan: null
            },
            invalid: false,

            dialog_delete: false,
            delete_id: null,
            deleted_index: null,
            valid_image: true,
        }
    },
    computed: {
        dialogTitle() {
            return this.edited_index === null ? this.$t('sites.add_site') : this.$t('sites.edit_site');
        }
    },
    created() {
        this.getSites();
    },
    methods: {
        getSites() {
            this.axios.get(
                SITES
            ).then((success) => {
                this.sites = success.data;
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('error_display.error_get_sites'));
            }).finally(() => {
                this.loading = false;
            });
        },

        openDialogAddSite() {
            if (this.edited_index !== null) {
                this.$refs.form.reset();
                this.$refs.observer.reset();
                this.edited_index = null;
            }

            this.dialog_edit = true;
        },

        openDialogUpdateSite(site) {
            this.edited_index = this.sites.indexOf(site);
            this.edited_site = Object.assign({}, site);
            this.dialog_edit = true;
        },

        prepareEditSite() {
            if (this.edited_index !== null) {
                this.updateSite();
            } else {
                this.addSite();
            }
        },

        setPlace(addressData) {
            this.edited_site.latitude = addressData.latitude;
            this.edited_site.longitude = addressData.longitude;
        },

        updateSite() {
            this.axios.patch(SITES + '/' + this.edited_site.id,
                this.edited_site
            ).then((success) => {
                Object.assign(this.sites[this.edited_index], success.data);
                this.edited_index = null;
                this.showGlobalSnackbar('success', this.$t('global.success_to_edit'));
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('global.failure_to_update'));
            }).finally(() => {
                this.closeDialog();
            });
        },

        addSite() {
            if(!this.edited_site.latitude || !this.edited_site.longitude){
                this.showSnackbar('error', this.$t('global.unvalid_address'));
            }
            else{
                let formData = new FormData();
                formData.append('label', this.edited_site.label);
                formData.append('address', this.edited_site.address);
                formData.append('note', this.edited_site.note);
                formData.append('latitude', this.edited_site.latitude);
                formData.append('longitude', this.edited_site.longitude);
                formData.append('plan', this.edited_site.path_plan);
                this.axios.post(
                    SITES, formData, this.configurationFile
                ).then((success) => {
                    this.sites.push(success.data);
                    this.showSnackbar('success', this.$t('global.success_to_add'));
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_add'));
                }).finally(() => {
                    this.edited_site.longitude='';
                    this.edited_site.latitude='';
                    this.closeDialog();
                });
            }
        },

        openDialogDeleteSite(site) {
            this.deleted_index = this.sites.indexOf(site);
            this.delete_id = site.id;
            this.dialog_delete = true;
        },

        confirmDelete() {
            this.axios.delete(
                SITES + '/' + this.delete_id
            ).then(() => {
                this.sites.splice(this.deleted_index, 1);
                this.showGlobalSnackbar('success', this.$t('global.success_to_delete'));
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('global.failure_to_delete'));
            }).finally(() => {
                this.dialog_delete = false;
            });
        },

        closeDialog() {
            this.$refs.siteAddress.clear();
            this.$refs.form.reset();
            this.$refs.observer.reset();
            this.dialog_edit = false;
        },

        onImageChanged() {
            if(this.edited_site.path_plan !== null) {
                let imageCheck = this.uploadFilesCheck(this.edited_site.path_plan,['image/png','image/jpg','image/jpeg'],2);
                if(!imageCheck.authorized) {
                    this.valid_image = false;
                    this.showSnackbar('error',imageCheck.output.toString());
                }
                else {
                    this.valid_image = true;
                }
            }
            if(this.edited_site.path_plan === null){
                this.valid_image = true;
            }
        },
    }
}
</script>

<style scoped lang="scss">
.myInput {
    padding: 5px 10px;
    border: 1px solid #555;
    border-radius: 5px;
    display: block;
    width: 100%;
}
</style>
