<template>
    <div id="three-dimensional-curve-settings-modal">
        <v-dialog v-model="three_dimensional_curve_settings_open_dialog" width="500">
            <v-card>
                <validation-observer ref="observer" v-slot="{ invalid }">
                    <v-form ref="form">
                        <v-card-title class="headline">
                            {{ title === 'add' ? $t('three_dimensional_curve.add_three_d_curve') : $t('three_dimensional_curve.curve_settings')}}
                        </v-card-title>
                        <v-divider/>

                        <v-card-text>
                            <validation-provider :name="$t('global.name')" rules="required" v-slot="{ errors }">
                                <v-text-field
                                    class="mb-0"
                                    v-model="label"
                                    :label="$t('global.name')"
                                    prepend-icon="mdi-label-outline"
                                    :error-messages="errors"
                                    solo
                                />
                            </validation-provider>

                            <v-divider/>
                            <h3 class="mt-3 mb-2">{{ $t('global.x_axis')}}</h3>
                            <validation-provider :name="$t('global.devices')" rules="required" v-slot="{ errors }">
                                <v-autocomplete
                                    :items="virtual_devices"
                                    v-model="three_dimensional_curve_detail.virtual_device_1"
                                    item-text="label"
                                    prepend-icon="mdi-chemical-weapon"
                                    :label="$t('global.devices')"
                                    :error-messages="errors"
                                    @change="getMetricsOfVirtualDevice1()"
                                    return-object
                                    solo/>
                            </validation-provider>

                            <validation-provider :name="$t('global.devices')" rules="required" v-slot="{ errors }">
                                <v-select
                                    :items="virtual_device_metric_1_types"
                                    v-model="metric_name_1"
                                    item-text="metric_name"
                                    item-value="metric_uuid"
                                    prepend-icon="mdi-tape-measure"
                                    :label="$t('modal_comparison_curves.physical_value')"
                                    :error-messages="errors"
                                    :disabled="disabled_virtual_device_metric_1_types"
                                    :loading="loading_virtual_device_metric_1_types"
                                    solo/>
                            </validation-provider>

                            <v-divider/>
                            <h3 class="mt-3 mb-2">{{ $t('global.y_axis')}}</h3>
                            <validation-provider :name="$t('global.devices')" rules="required" v-slot="{ errors }">
                                <v-select
                                    :items="virtual_devices"
                                    v-model="three_dimensional_curve_detail.virtual_device_2"
                                    item-text="label"
                                    prepend-icon="mdi-chemical-weapon"
                                    :label="$t('global.devices')"
                                    :error-messages="errors"
                                    @change="getMetricsOfVirtualDevice2()"
                                    return-object
                                    solo/>
                            </validation-provider>

                            <validation-provider :name="$t('global.devices')" rules="required" v-slot="{ errors }">
                                <v-select
                                    :items="virtual_device_metric_2_types"
                                    v-model="metric_name_2"
                                    item-text="metric_name"
                                    item-value="metric_uuid"
                                    prepend-icon="mdi-tape-measure"
                                    :label="$t('modal_comparison_curves.physical_value')"
                                    :error-messages="errors"
                                    :disabled="disabled_virtual_device_metric_2_types"
                                    :loading="loading_virtual_device_metric_2_types"
                                    solo/>
                            </validation-provider>
                            <v-divider/>

                            <h3 class="mt-3 mb-2">{{ $t('global.z_axis')}}</h3>
                            <validation-provider :name="$t('global.devices')" rules="required" v-slot="{ errors }">
                                <v-select
                                    :items="virtual_devices"
                                    v-model="three_dimensional_curve_detail.virtual_device_3"
                                    item-text="label"
                                    prepend-icon="mdi-chemical-weapon"
                                    :label="$t('global.devices')"
                                    :error-messages="errors"
                                    @change="getMetricsOfVirtualDevice3()"
                                    return-object
                                    solo/>
                            </validation-provider>

                            <validation-provider :name="$t('global.devices')" rules="required" v-slot="{ errors }">
                                <v-select
                                    :items="virtual_device_metric_3_types"
                                    v-model="metric_name_3"
                                    item-text="metric_name"
                                    item-value="metric_uuid"
                                    prepend-icon="mdi-tape-measure"
                                    :label="$t('modal_comparison_curves.physical_value')"
                                    :error-messages="errors"
                                    :disabled="disabled_virtual_device_metric_3_types"
                                    :loading="loading_virtual_device_metric_3_types"
                                    solo/>
                            </validation-provider>
                            <v-divider/>
                            <h3 class="mt-3 mb-2">{{ $t('three_dimensional_curve.influence_factor') }}</h3>
                            <p>{{ $t('three_dimensional_curve.influence_factor_hint') }}</p>

                            <validation-provider :name="$t('global.devices')" rules="required" v-slot="{ errors }">
                                <v-select
                                    :items="virtual_devices"
                                    v-model="virtual_device_metric_id"
                                    item-text="label"
                                    prepend-icon="mdi-chemical-weapon"
                                    :label="$t('global.devices')"
                                    :error-messages="errors"
                                    @change="getMetricsOfVirtualDevice()"
                                    return-object
                                    solo/>
                            </validation-provider>

                            <validation-provider :name="$t('global.devices')" rules="required" v-slot="{ errors }">
                                <v-select
                                    :items="virtual_device_metric_types"
                                    v-model="metric_name"
                                    item-text="metric_name"
                                    item-value="metric_uuid"
                                    prepend-icon="mdi-tape-measure"
                                    :label="$t('modal_comparison_curves.physical_value')"
                                    :error-messages="errors"
                                    :disabled="disabled_virtual_device_metric_types"
                                    :loading="loading_virtual_device_metric_types"
                                    solo/>
                            </validation-provider>

                        </v-card-text>
                        <v-card-actions class="justify-end">
                            <v-btn @click="closeEditThreeDimensionalCurveModal()">
                                <v-icon left>mdi-close</v-icon>
                                {{ $t('global.close') }}
                            </v-btn>
                            <v-btn
                                color="primary"
                                :disabled="invalid"
                                @click.prevent="addOrUpdate3dCurve()"
                            >
                                <v-icon left>mdi-content-save</v-icon>
                                {{ $t('global.validation') }}
                            </v-btn>

                        </v-card-actions>
                    </v-form>
                </validation-observer>
            </v-card>
        </v-dialog>
        <GlobalSnackbar/>
    </div>
</template>

<script>
import {MACHINES, THREE_DIMENSION_CURVES, VIRTUAL_DEVICES} from "../../../../api";
import GlobalSnackbar from "../../../../global/components/GlobalSnackbar";

export default {
    name: "ThreeDimensionalCurveSettingsModal",
    components: {GlobalSnackbar},
    data(){
        return {
            invalid: false,
            three_dimensional_curve_settings_open_dialog:false,
            title:'',
            virtual_devices: [],
            virtual_device_metric_id: '',
            loading_virtual_device_metric_1_types:false,
            loading_virtual_device_metric_2_types:false,
            loading_virtual_device_metric_3_types:false,
            loading_virtual_device_metric_types:false,
            disabled_virtual_device_metric_1_types: true,
            disabled_virtual_device_metric_2_types: true,
            disabled_virtual_device_metric_3_types: true,
            disabled_virtual_device_metric_types: true,
            virtual_device_metric_1_types: [],
            virtual_device_metric_2_types: [],
            virtual_device_metric_3_types: [],
            virtual_device_metric_types: [],
            metric_name_1: null,
            metric_name_2: null,
            metric_name_3: null,
            metric_name: null,
            label:'',
            three_dimensional_curve_detail: {
                id_relatable: this.$route.params.id,
                type_relatable: 'machine',
                label: '',
                metric_1_uuid: '',
                metric_2_uuid: '',
                metric_3_uuid: '',
                metric_1_unit: '',
                metric_2_unit: '',
                metric_3_unit: '',
                virtual_device_1: null,
                virtual_device_2: null,
                virtual_device_3: null,
                configuration: {
                    metric_uuid : this.metric_uuid,
                    min_color_code : "#008000",
                    max_color_code :"#FF0000",
                    min_color_percentage : 50,
                    max_color_percentage : 50,
                    line_width :2,
                }
            },
        }
    },
    watch: {
        metric_name_1: function (val) {
            if (val !== null) {
                this.three_dimensional_curve_detail.metric_1_uuid = val;
                let metric = this.virtual_device_metric_1_types.filter(function (metric) {
                    if (metric.metric_uuid === val) return metric;
                })[0];
                this.three_dimensional_curve_detail.metric_1_unit = metric.metric_unit;
            }
        },

        metric_name_2: function (val) {
            if (val !== null) {
                this.three_dimensional_curve_detail.metric_2_uuid = val;

                let metric = this.virtual_device_metric_2_types.filter(function (metric) {
                    if (metric.metric_uuid === val) return metric;
                })[0];

                this.three_dimensional_curve_detail.metric_2_unit = metric.metric_unit;
            }
        },

        metric_name_3: function (val) {
            if (val !== null) {
                this.three_dimensional_curve_detail.metric_3_uuid = val;

                let metric = this.virtual_device_metric_3_types.filter(function (metric) {
                    if (metric.metric_uuid === val) return metric;
                })[0];

                this.three_dimensional_curve_detail.metric_3_unit = metric.metric_unit;
            }
        },

        metric_name: function (val) {
            if (val !== null) {
                this.three_dimensional_curve_detail.configuration.metric_uuid = val;
            }
        },

    },
    created() {
        this.getMachineVirtualDevices();
    },
    updated() {
        this.label =this.three_dimensional_curve_detail.label;
    },
    methods: {

        async initializeChartUpdate() {
            this.three_dimensional_curve_settings_open_dialog= true;
            this.getMetricsOfVirtualDevice1();
            this.getMetricsOfVirtualDevice2();
            this.getMetricsOfVirtualDevice3();
            await this.getUsedMetricOfColorVariation();
            this.getMetricsOfVirtualDevice();
        },

        getUsedMetricOfVirtualDevice1() {
            this.virtual_device_metric_1_types.forEach(metric => {
                if(this.three_dimensional_curve_detail.metric_1_uuid === metric.metric_uuid) {
                    this.metric_name_1 = metric.metric_uuid;
                }
            });
        },

        getUsedMetricOfVirtualDevice2() {
            this.virtual_device_metric_2_types.forEach(metric => {
                if(this.three_dimensional_curve_detail.metric_2_uuid === metric.metric_uuid) {
                    this.metric_name_2 = metric.metric_uuid;
                }
            });
        },

        getUsedMetricOfVirtualDevice3() {
            this.virtual_device_metric_3_types.forEach(metric => {
                if(this.three_dimensional_curve_detail.metric_3_uuid === metric.metric_uuid) {
                    this.metric_name_3 = metric.metric_uuid;
                }
            });
        },

        async getUsedMetricOfColorVariation() {
            await this.axios.get(
                VIRTUAL_DEVICES +'/'+this.three_dimensional_curve_detail.configuration.metric_uuid+'/metric'
            ).then((success) => {
                this.disabled_virtual_device_metric_types =false;
                this.virtual_device_metric_id=success.data.virtual_device;
                this.metric_name= success.data.metric.metric_uuid;
            });
        },

        closeEditThreeDimensionalCurveModal() {
            this.$emit('closedDialog');
            this.three_dimensional_curve_settings_open_dialog = false;
        },

        addOrUpdate3dCurve(){
            this.title === 'add' ? this.save3dCurve() : this.update3dCurve();
        },

        save3dCurve(){
            this.$refs.form.validate();
            this.three_dimensional_curve_detail.label= this.label;
            this.axios.post(
                THREE_DIMENSION_CURVES, this.three_dimensional_curve_detail
            ).then(() => {
                this.showGlobalSnackbar('success',this.$t('global.success_to_add'));
                this.three_dimensional_curve_settings_open_dialog= false;
                this.$emit('getMachineThreeDimensionalCurves');
            }).catch(() => {
                this.showGlobalSnackbar('error',this.$t('global.failure_to_add'));
            }).finally(() => {
                this.$refs.observer.reset();
                this.$refs.form.reset();
                this.label='';
            });
        },

        update3dCurve(){
            this.three_dimensional_curve_detail.label= this.label;
            this.axios.patch(
                THREE_DIMENSION_CURVES + '/' + this.three_dimensional_curve_detail.id, {
                    data: this.three_dimensional_curve_detail
                }
            ).then((success)=> {
                this.$emit('curveConfigurationUpdated', success.data);
                this.showGlobalSnackbar('success',this.$t('global.success_to_edit'));
                this.three_dimensional_curve_settings_open_dialog=false;
            }).catch(()=>{
                this.showGlobalSnackbar('error',this.$t('global.failure_to_update'));
            }).finally(()=> {
                this.label='';
            });
        },

        getMachineVirtualDevices() {
            this.axios.get(
                MACHINES + "/" + this.$route.params.id + "/virtual_devices"
            ).then((success) => {
                this.virtual_devices = success.data;
            }).catch(() => {
                this.showGlobalSnackbar('error', this.$t('error_display.error_get_virtual_devices'));
            });
        },

        getMetricsOfVirtualDevice1() {
            this.loading_virtual_device_metric_1_types = true;
            this.axios.get(
                VIRTUAL_DEVICES + "/" + this.three_dimensional_curve_detail.virtual_device_1.id + "/metric_types"
            ).then((success) => {
                this.virtual_device_metric_1_types=[];
                success.data.forEach((metric) => {
                    if (metric.metric_type !== "FFT" && metric.metric_unit !== "fft") {
                        this.virtual_device_metric_1_types.push(metric);
                    }
                });
                this.disabled_virtual_device_metric_1_types = false;
                if(this.title !== 'add'){
                    this.getUsedMetricOfVirtualDevice1()
                }
            }).catch(() => {
                this.showGlobalSnackbar('error',  this.$t('word_curve.error_extern_api_connection'));
            }).finally(() => {
                this.loading_virtual_device_metric_1_types = false;
            });
        },

        getMetricsOfVirtualDevice2() {
            this.loading_virtual_device_metric_2_types= true;
            this.axios.get(
                VIRTUAL_DEVICES + "/" + this.three_dimensional_curve_detail.virtual_device_2.id + "/metric_types"
            ).then((success) => {
                this.virtual_device_metric_2_types=[];
                success.data.forEach((metric) => {
                    if (metric.metric_type !== "FFT" && metric.metric_unit !== "fft") {
                        this.virtual_device_metric_2_types.push(metric);
                    }
                });
                this.disabled_virtual_device_metric_2_types = false;
                if(this.title !== 'add'){
                    this.getUsedMetricOfVirtualDevice2()
                }
            }).catch(() => {
                this.showGlobalSnackbar('error',  this.$t('word_curve.error_extern_api_connection'))
            }).finally(() => {
                this.loading_virtual_device_metric_2_types = false;
            });
        },

        getMetricsOfVirtualDevice3() {
            this.loading_virtual_device_metric_3_types= true;
            this.axios.get(
                VIRTUAL_DEVICES + "/" + this.three_dimensional_curve_detail.virtual_device_3.id + "/metric_types"
            ).then((success) => {
                this.virtual_device_metric_3_types=[];
                success.data.forEach((metric) => {
                    if (metric.metric_type !== "FFT" && metric.metric_unit !== "fft") {
                        this.virtual_device_metric_3_types.push(metric);
                    }
                });
                this.disabled_virtual_device_metric_3_types = false;
                if(this.title !== 'add'){
                    this.getUsedMetricOfVirtualDevice3();
                }
            }).catch(() => {
                this.showGlobalSnackbar('error', this.$t('word_curve.error_extern_api_connection'));
            }).finally(() => {
                this.loading_virtual_device_metric_3_types = false;
            });
        },

        getMetricsOfVirtualDevice() {
            this.loading_virtual_device_metric_types= true;
            this.axios.get(
                VIRTUAL_DEVICES + "/" + this.virtual_device_metric_id.id + "/metric_types"
            ).then((success) => {
                this.virtual_device_metric_types=[];
                success.data.forEach((metric) => {
                    if (metric.metric_type !== "FFT" && metric.metric_unit !== "fft") {
                        this.virtual_device_metric_types.push(metric);
                    }
                });
                this.disabled_virtual_device_metric_types = false;
            }).catch(() => {
                this.showGlobalSnackbar('error',  this.$t('word_curve.error_extern_api_connection'));
            }).finally(() => {
                this.loading_virtual_device_metric_types = false;
            });
        }
    }
}
</script>

<style scoped>
h3{
    color: #212121;
}
</style>
