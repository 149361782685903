<template>
    <div id="machine-vision">
        <v-row class="row mt-5">
            <!-- PAGE TITLE -->
            <v-subheader class="v-subheader col theme--light pl-5 ml-1">
                <p class="title mb-0 color-primary text-uppercase">
                    {{ $t('campain.title') }}
                </p>
                <!------------------- Info bulle ---------------------->
                <v-tooltip bottom dark class="mr-2">
                    <template v-slot:activator="{ on }">
                        <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                            <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:default>
                        <div style="min-width: 200px;">
                            <strong>Page [Campagnes]</strong>
                            <p>Cette page à été spécialement développé suivant les process d'interventions de Techview.</br>
                                Dans le cadre de nos campagnes OFFLINE (sur le terrain) et ONLINE (à distance) nous souhaitons que tous nos clients soient informés en temps réel de l'avancement de leurs projets.</br></br>
                                En ce sens toutes les interventions qui seront pévues et réalisées seront accessible depuis cette page.</br>
                                Cliquer sur le titre d'une campagne vous permettra de suivre en détail l'avancement des rapports et de les consulter.
                            </p>
                        </div>
                    </template>
                </v-tooltip>
            </v-subheader>

            <!-- FORMAT SELECTOR -->
            <v-row cols="2" class="v-subheader col-1 theme--light mr-3">
                <!-- SPACER -->
                <v-col class="col"></v-col>

                <!-- CARD VIEW -->
                <v-tooltip bottom dark>
                    <template v-slot:activator="{ on }">
                        <v-btn text icon v-on="on" @click="setCards()" dusk="logout-button">
                            <v-icon color="primary">mdi-card-text-outline</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('campain.list_card') }}</span>
                </v-tooltip>

                <!-- LIST VIEWè
                <v-tooltip bottom dark>
                    <template v-slot:activator="{ on }">
                        <v-btn text icon v-on="on" @click="setDatatable()" dusk="logout-button">
                            <v-icon color="primary">mdi-format-list-bulleted</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('notification.list-view') }}</span>
                </v-tooltip>
                -->
            </v-row>
        </v-row>

        <!-- IMPORT CONTENT -->
        <cards-campain class="mt-6" v-if="cards"/>
        <datatable-notifications class="mt-6" v-if="!cards"/>
    </div>
</template>

<script>
//import DatatableNotifications from "../../components/Campain/VisionDatatable";
import CardsCampain from "../../components/Campain/VisionCards";

export default {
    name: "Vision",
    components: {CardsCampain},
    data() {
        return {
            cards: true,
        }
    },
    created() {
        this.setCards();
    },
    methods: {
        switchView() {
            this.cards = !this.cards;
        },

        setCards() {
            this.cards = true;
        },

        setDatatable() {
            this.cards = false;
        },
    }
}


</script>
