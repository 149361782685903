<template>
    <v-row class="row mt-5">
        
        <!-- content -->
        <v-col color="white" class="ml-0 mr-4 pl-2 col">
            <v-col class="row col-12 mb-0 pa-0">
                <!-- Filter bar  GUILLAUME -->
                <v-col color="white" class="col-2 pa-0 mt-5">
                    <v-col v-if="global_notifications_count > 1" class="col pt-0 pb-0 mr-10 rounded col ml-6" style="height: 35px;background-color: rgb(224 232 240);font-weight: bold;text-align: center;font-size: 16px;border: 1px solid #014181;line-height: 35px;color: #014181;">
                        {{ global_notifications_count }} notifications non lues.
                    </v-col>

                    <v-col v-if="global_notifications_count == 1" class="col pt-0 pb-0 mr-10 rounded col ml-6" style="height: 35px;background-color: rgb(224 232 240);font-weight: bold;text-align: center;font-size: 16px;border: 1px solid #014181;line-height: 35px;color: #014181;">
                        {{ global_notifications_count }} notification non lue.
                    </v-col>

                    <v-col v-if="global_notifications_count == 0" class="col pt-0 pb-0 mr-10 rounded col ml-6" style="height: 35px;background-color: rgb(224 232 240);font-weight: bold;text-align: center;font-size: 16px;border: 1px solid #014181;line-height: 35px;color: #014181;">
                        Aucune notification non lue.
                    </v-col>
                </v-col>

                <v-col class="row col ml-6 pt-8">
                    <v-btn text icon v-on="on" @click="setNotificationState(null, 9)" class="mr-2" dusk="logout-button" color="red" style="border: 1px solid #ff00003b;width: auto;border-radius: 4px;padding: 0px 10px;">
                        <v-icon color="red" class="mr-2">mdi-eye-check</v-icon>
                        <div>Tout passer en vue</div>
                    </v-btn>
                    <v-tooltip bottom dark class="mr-2">
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" @click="getNotification()" class="mr-2" dusk="logout-button" style="border: 1px solid #01418145;">
                                <v-icon color="primary">mdi-tag-multiple</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('global.1_5_2') }}</span>
                    </v-tooltip>
                    <v-tooltip bottom dark class="mr-2">
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" @click="refresh()" class="mr-2" dusk="logout-button" style="border: 1px solid #01418145;">
                                <v-icon color="primary">mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('notification.refresh') }}</span>
                    </v-tooltip>
                </v-col>

                <v-col class="row col"></v-col>   
                    
                <!-- SEARCH -->       
                <v-col @click="dialogNextUpdate()" class="col-4 pa-0">
                    <v-text-field 
                        v-model="search"
                        append-icon="mdi-magnify"
                        :label="$t('global.search')"
                        class="mt-1"
                        disabled
                    ></v-text-field>
                </v-col>
            </v-col>

            <v-col class="row col mb-5 mt-0">
                <div
                    v-for="n_levels in notification_levels"
                    :key="n_levels.level"
                    class="mr-0"
                >
                    <div
                    class="ma-auto col-10 pl-3 pr-15 v-chip v-chip--clickable v-chip--link v-chip--no-color theme--light v-size--default"
                    style="
                        color: black;
                        font-weight: 400;
                        display: flex;
                        justify-content: left;
                        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.29);
                        background-color: #d7d7d752;
                        font-size: 14px;
                        height: 40px;
                    "
                    @click="setNotificationFiltered(n_levels.level)"
                    >
                    <div
                        v-if="n_levels.level_name !== 'Tout voir'"
                        class="mr-3"
                        style="color: white;min-width: 30px; min-height: 20px; border-radius:20px;font-weight: bold;text-align: center;"
                        :style="{backgroundColor: n_levels.level_color}"
                    >{{n_levels.count}}</div>
                    {{n_levels.level_name}}
                    </div>
                </div>
            </v-col> 

            <div v-if="loading_datatable == true" style="color:grey;text-align: center;">
                <li class="rounded row notification_card mb-1 pa-10 mt-15 ml-6" style="color: grey;justify-content: center;">
                    <div style="color:grey;text-align: center;"><strong>Chargement en cours...</strong></div>
                </li>
            </div>  
            
            <!-- ALL DATATABLE -->
            <div v-if="loading_datatable == false" flat color="white" class="pt-0 mb-3">
                <!-- NOTIFICATIONS LIST SEB -->
                <template>
                    
                    <ul id="notifications-cards">
                        <li class="rounded pointer row notification_card mb-1 elevation-1" v-for="notification in notifications" :key="notification.id" style="border: solid 1px #80808021;background-color: #ffffff;">
                            <div class="row pa-3">
                                <div @click="openDialogShow(notification)" class="col-0 mr-5" style="color:white;font-weight:800;display:flex;justify-content:center;text-shadow:2px 2px 4px rgba(0, 0, 0, 0.29); min-width: 8px;border-radius:2px 0px 0px 2px;" :style="{backgroundColor: notification.level_color}"></div>
                                <div @click="openDialogShow(notification)" class="col col-3 ma-auto">
                                    <div style="font-weight: bold;">{{notification.subject}}</div>
                                    <div style="font-size: 12px;">{{notification.created_at}}</div>
                                </div>
                                <div @click="openDialogShow(notification)" class="col col pl-10" style="border-left: 1px solid #d3d3d36e;display:flex;flex-direction:column;justify-content:center">
                                    <div>{{ notification.message.substring(0, 196) + '...' }}</div>
                                    <div style="font-size: 12px;">{{notification.from}}</div>                                    
                                </div>
                                <v-col class="col-1 ml-5" style="max-width: 100px;">
                                    <v-tooltip v-if="!notification.seen" bottom dark>
                                        <template v-slot:activator="{ on }">
                                            <v-btn class="ma-auto mr-5 ml-5 col-0" text icon v-on="on" link :to="notification.link" @click="setNotificationState(notification,1)" dusk="logout-button">
                                                <v-icon color="primary">mdi-arrow-right-circle</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('notification.redirect') }}</span>
                                    </v-tooltip>
                                    <v-tooltip v-if="!notification.seen" bottom dark>
                                        <template v-slot:activator="{ on }">
                                            <v-btn class="ma-auto mr-5 ml-5 col-0" text icon v-on="on" @click="setNotificationState(notification,1)" dusk="logout-button">
                                                <v-icon color="primary">mdi-eye-check-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('notification.set_view_false') }}</span>
                                    </v-tooltip>
                                </v-col>
                            </div>
                        </li>

                        <li v-if="!notifications_available" class="rounded row notification_card mb-1 pa-10" style="border: 2px dotted #80808059;color: grey;justify-content: center;">
                            <div style="color:grey;text-align: center;">{{ $t('notification.ras') }}. <br> {{ $t('notification.consult') }}.</div>
                        </li>

                        <li v-if="notifications_available" class="row col-12 pb-5 mb-5 mt-10" style="text-align:center;display: flex;justify-content: center;border-bottom: 1px solid #8080801c;">
                            {{ $t('notification.historic') }}
                        </li>

                        <li class="rounded pointer row notification_card mb-1" v-for="notification in notifications_hisroty" :key="notification.id" style="border: solid 1px #80808010;background-color: #d9d9d9cc;color: grey;">
                            <div v-if="history_available" class="row pa-3">
                                <div @click="openDialogShow(notification)" class="col-0 mr-5" style="color:white;font-weight:800;display:flex;justify-content:center;text-shadow:2px 2px 4px rgba(0, 0, 0, 0.29);min-width: 8px;border-radius:2px 0px 0px 2px;" :style="{backgroundColor: notification.level_color}"></div>
                                <div @click="openDialogShow(notification)" class="col col-3 ma-auto">
                                    <div style="font-weight: bold;">{{notification.subject}}</div>
                                    <div style="font-size: 12px;">{{notification.created_at}}</div>
                                </div>
                                <div @click="openDialogShow(notification)" class="col col pl-10" style="border-left: 1px solid #d3d3d3;">
                                    <div>{{ notification.message.substring(0, 128) + '...' }}</div>
                                    <div style="font-size: 12px;">{{notification.from}}</div>                                    
                                </div>
                                <v-col class="col-1 ml-5" style="max-width: 100px;">
                                    <v-tooltip v-if="notification.seen" bottom dark>
                                        <template v-slot:activator="{ on }">
                                            <v-btn class="ma-auto mr-5 ml-5 col-0" text icon v-on="on" link :to="notification.link" dusk="logout-button">
                                                <v-icon color="primary">mdi-arrow-right-circle-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('notification.redirect') }}</span>
                                    </v-tooltip>
                                    <v-tooltip v-if="notification.seen" bottom dark>
                                        <template v-slot:activator="{ on }">
                                            <v-btn class="ma-auto mr-5 ml-5 col-0" text icon v-on="on" @click="setNotificationState(notification,0)" dusk="logout-button">
                                                <v-icon color="primary">mdi-eye-remove</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('notification.set_view_false') }}</span>
                                    </v-tooltip>
                                </v-col>
                            </div>

                            <div v-if="!history_available" class="row pa-3">
                                <div class="ma-4 col-1 pl-3 pr-3 v-chip v-chip--clickable v-chip--link v-chip--no-color theme--light v-size--default" style="color:transparent;font-weight:800;display:flex;justify-content:center;text-shadow:2px 2px 4px rgba(0, 0, 0, 0.29)" >Aucune notification dans l'historique.</div>
                            </div>

                        </li>

                        <li class="row notification_card mb-1" style="display: flex;flex-direction: row;justify-content: center;align-items: center;font-size: 16px;">
                            <v-tooltip bottom dark>
                                <template v-slot:activator="{ on }">
                                    <v-btn text icon v-on="on" @click="viewMore()" dusk="logout-button">
                                        <v-icon color="primary">mdi-arrow-down-thin-circle-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('notification.show-more') }}</span>
                            </v-tooltip>
                        </li>

                    </ul>
                </template>

                <!-- INFO DISPLAY -->
                <v-snackbar
                    v-model="snackbar"
                    :color="snackbar_type">
                    {{ snackbar_text }}
                    <template v-slot:action="{ attrs }">
                        <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                    </template>
                </v-snackbar>
                
                <!-- VIEW NOTIF GUILLAUME -->
                <v-dialog v-model="dialog_show" max-width="750px" eager>
                    <v-card style="overflow:hidden">
                        <v-card-title class="row  ml-1 mt-2" style="display:flex;justify-content:space-between;">
                            <h3 style="font-size:1.1rem" >{{ notification.subject }}</h3>
                            <p style="font-size: 12px;margin-right:50px;font-weight: 400;font-style: italic;">{{ notification.created_at }}</p>
                        </v-card-title>
                        <v-card-text>
                            <div style="border-radius:2px;background-color:#ebf4ff;padding:10px;margin:20px 0px">
                                <p style="color:black!important">{{ notification.message }}</p>
                            </div>

                            <v-row>
                                <v-col style="display:flex;flex-direction:column;align-items:center">
                                    <h4>{{ $t('notification.tags') }}</h4>

                                    <template>
                                        <v-chip-group>
                                            <!--<v-chip v-if="jsonParseTags === null" color="grey">{{ $t('global.no_tags_associated') }}</v-chip>-->
                                            <v-chip v-for="tag in jsonParseTags" class="pl-3 pr-3" link :key="tag">
                                                {{ tag }}
                                            </v-chip>
                                        </v-chip-group>
                                    </template>
                                </v-col>
                                <v-col>
                                    <div style="display:flex;justify-content:center">
                                        <h4 style="text-align:center">{{ $t('notification.level') }}</h4>
                                        <!------------------- Info bulle ---------------------->
                                        <v-tooltip bottom dark class="mr-2" style="">
                                            <template v-slot:activator="{ on }">
                                                <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                                                    <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                                                </v-btn>
                                            </template>
                                            <template v-slot:default>
                                            <div style="min-width: 200px;">
                                                <strong>{{ $t('tooltip.color-title') }}</strong>
                                                <div style="display:flex;align-items:center">
                                                    <div style="width: 10px;height: 10px;background-color:grey;border-radius: 50%;margin-bottom:5px"></div>
                                                    <p class="pl-5">{{ $t('tooltip.grey-content') }}</p>
                                                </div>
                                                <div style="display:flex;align-items:center">
                                                    <div style="width: 10px;height: 10px;background-color:#4CAF50;border-radius: 50%;margin-bottom:5px"></div>
                                                    <p class="pl-5">{{ $t('tooltip.green-content') }}</p>
                                                </div>
                                                <div style="display:flex;align-items:center">
                                                    <div style="width: 10px;height: 10px;background-color:#FFCB05;border-radius: 50%;margin-bottom:5px"></div>
                                                    <p class="pl-5">{{ $t('tooltip.yellow-content') }}</p>
                                                </div>
                                                <div style="display:flex;align-items:center">
                                                    <div style="width: 10px;height: 10px;background-color:#F44336;border-radius: 50%;margin-bottom:5px"></div>
                                                    <p class="pl-5">{{ $t('tooltip.red-content') }}</p>
                                                </div>
                                                <div style="display:flex;align-items:center">
                                                    <div style="width: 10px;height: 10px;background-color: black;border-radius: 50%;margin-bottom:5px"></div>
                                                    <p class="pl-5">{{ $t('tooltip.black-content') }}</p>
                                                </div>
                                            </div>
                                            </template>
                                        </v-tooltip>
                                    </div>
                                    <p class="ma-2 pl-3 pr-3 v-chip v-chip--clickable v-chip--link v-chip--no-color theme--light v-size--default" style="color:white;font-weight:800;display:flex;justify-content:center;text-shadow:2px 2px 4px rgba(0, 0, 0, 0.8)" :style="{backgroundColor: notification.level_color}">{{ notification.level_name }}</p>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <!-- Bouton pour fermer -->
                        <v-card-actions class="mb-3" style="position:absolute;top:20px;right:2px">
                            <v-btn @click="dialog_show = false;" style="color:red;max-width: 30px;padding: 0 0 0 5px;min-width: 10px!important;height: 30px!important;">
                                <v-icon left>mdi-close</v-icon>
                            </v-btn>
                        </v-card-actions>
                        <v-card-actions class="mb-3" style="display:flex;justify-content:center">
                            <v-btn @click="setNotificationState(notification, 1)" color="primary" v-if="!notification.seen">
                                <v-icon left>mdi-eye-check</v-icon>
                                {{ $t('notification.set_view_true') }}
                            </v-btn>

                            <v-btn @click="setNotificationState(notification, 0)" color="primary" v-else>
                                <v-icon left>mdi-eye-remove</v-icon>
                                {{ $t('notification.set_view_false') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>

            <!--FILTER MAX SIZE MESSAGE ITEM-->
            <template v-slot:item.message="{ item }">
                {{ item.message.substring(0, 100) + '...' }}
            </template>

            <!--ACTION BUTTONS -->
            <template v-slot:item.action="{ item }">
                <v-icon class="pointer" @click="setNotificationState(item, 1)" v-if="!notification_seen">
                    mdi-eye-check
                </v-icon>
                <v-icon class="pointer" @click="setNotificationState(item, 0)" v-if="notification_seen">
                    mdi-eye-remove
                </v-icon>
                <v-icon class="pointer" @click="openDialogShow(item)">
                    mdi-arrow-expand-all
                </v-icon>
            </template>
        </v-col>
        <!-- Modal next update -->
        <v-dialog v-model="dialog_next_update" max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="headline">
                        Fonctionnalité bientôt disponible
                    </span>
                </v-card-title>
                <v-divider></v-divider>
                <br/>
                <p class="pl-4 pr-4 pb-4">
                    Cette fonctionnalité est en cours de développement et sera bientôt disponible.
                    Elle vous permettra entre autre de filter et de rechercher précisément via des mots clés votre cible.
                    Un rapport, une machine, une référence...
                    Le but, vous faire gagner du temps ! 
                </p>

                <!-- Bouton pour fermer -->
                <v-card-actions class="mb-3" style="position:absolute;top:5px;right:10px">
                    <v-btn @click="dialog_new_update = false" style="color:red;max-width: 30px;padding: 0 0 0 5px;min-width: 10px!important;height: 30px!important;">
                        <v-icon left>mdi-close</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import {NOTIFICATIONS, USERS} from "../../api";
import moment from "moment";

export default {
    name: "CardsNotifications",
    data() {
        return {
            whos:[],
            active_professional: this.$store.state.professional_id,
            dialog_next_update: false,
            serialized_who: "",
            raw_notifications:[],
            raw_stacked:false,
            notifications: [],
            notifications_hisroty: [],
            history_length:5,
            history_flag:0,
            notifications_available: false,
            history_available:false,
            global_notifications_count:0,
            level_mode:"",
            notification: {
                id: null,
                message: '',
                level: null,
                level_name: '',
                link: '',
                tags: '',
                seen: 0,
                created_at: '',
                subject: '',
                updated_at: '',
                who: '',
                from: '',
            },
            headers: [
                {text: this.$t("notification.created_at"), value: 'created_at'},
                {text: this.$t("notification.subject"), value: 'subject'},
                {text: this.$t("notification.message"), value: 'message'},
                {text: this.$t("notification.level_name"), value: 'level'},
                {text: this.$t("notification.tags"), value: 'tags'},
                {text: this.$t("notification.from"), value: 'from'},
                {text: this.$t("notification.actions"), value: 'action'},
            ],
            loading_datatable: true,
            search: '',
            notification_seen: false,
            dialog_show: false,
            dialog_delete: false,
            notification_level_selected: [],
            notification_levels: [
                {
                    level: "",
                    level_name: "Tout voir",
                    level_color: "#d7d7d752",
                    count:this.global_notifications_count
                },
                {
                    level: 3,
                    level_name: "Urgence",
                    level_color: "black",
                    count:0
                },
                {
                    level: 2,
                    level_name: "Importance élevée",
                    level_color: "#F44336",
                    count:0
                },
                {
                    level: 1,
                    level_name: "A suivre",
                    level_color: "#FFCB05",
                    count:0
                },
                {
                    level: 0,
                    level_name: "Pour information",
                    level_color: "#4CAF50",
                    count:0
                },
                {
                    level: -1,
                    level_name: "Autres",
                    level_color: "grey",
                    count:0
                },
            ]
        }
    },
    created() {
        moment.locale('fr');
        this.notifications = [];
        this.notifications_hisroty = [];
        this.setWhosGood();
    },
    computed: {
        jsonParseTags() {
            if (this.notification.tags != "") {
                return JSON.parse(this.notification.tags);
            }
        },
    },
    methods: {
        // REQUESTS
        setWhosGood() {
            this.axios.get(USERS + '/professionals')
            
            .then((success) => {
                success.data.forEach(professional => {
                    this.whos.push(professional.id+9000);
                })
                
                this.whos.push(this.$auth.user().id+1000);
                this.whos.push(this.$store.state.access_level);

                this.whos.push("all");
                if(this.$store.state.access_level <= 100){
                    this.whos.push("team");
                }

                this.serialized_who = this.whos.join('-');
                //----------------------------------------------------------------------------------------
                //DEBUG :
                //console.log("whos "+ this.whos);
                //console.log("serialized_who "+ this.serialized_who);
            
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            
            }).finally(() => {
                this.getNotification();
            })         
        },
        refresh(){
            this.raw_stacked = false;
            this.setWhosGood();
        },
        getNotification(level = "") {
            this.loading_datatable = true;
            // GET ALL SEEN NOTIFICATIONS
            this.axios.get(NOTIFICATIONS + '?&who=' + this.serialized_who + '&level=' + this.level_mode)
            .then((success) => {
                this.notifications = [];
                this.notifications_hisroty = [];

                if (!this.raw_stacked){
                    this.raw_notifications = [];
                    this.raw_notifications = success.data;
                    this.raw_stacked = true;
                }

                success.data.forEach(notification => {
                    notification.created_at = moment(notification.created_at).fromNow();
                    if(notification.seen == 0){
                        this.notifications.push(notification);
                    }else{
                        this.notifications_hisroty.push(notification);
                    }
                })

                if(typeof this.notifications[0] === 'undefined') {
                    this.notifications_available = false;
                }else {
                    this.notifications_available = true;
                }

                if(typeof this.notifications_hisroty[0] === 'undefined') {
                    this.history_available = false;
                }else {
                    this.history_available = true;
                }

                this.notifications_hisroty = this.notifications_hisroty.slice(0, this.history_length);
                
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            
            }).finally(() => {
                this.getNotificationCount();
                this.loading_datatable = false
            })
        },
        // DATA TREATMENT
        getNotificationCount(level = "") {
            this.notification_levels.forEach(level => {level.count = 0});
            
            // Get all last counts
            this.raw_notifications.forEach(notification => {
                this.notification_levels.forEach(level => {
                    if(notification.level === level.level && notification.seen === 0){
                        level.count = level.count +1;
                    }
                });
            });

            this.global_notifications_count = this.notifications.length;
        },
        setNotificationState(notification, state) {
            // Set notification seen
            if (state === 1) {
                this.axios.patch( NOTIFICATIONS + '/seen/' + notification.id)
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.getNotification();
                    this.dialog_show = false;
                })
            
            // Set notification unseen
            } if (state === 0) {
                this.axios.patch( NOTIFICATIONS + '/unseen/' + notification.id )
            
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.getNotification();
                    this.dialog_show = false;
                })
            } if (state === 9) {
                this.axios.patch(NOTIFICATIONS + '/seen_all')
            
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.getNotification();
                })
            }
        },
        // DIALOGS
        openDialogShow(notification) {
            this.notification = notification;
            this.dialog_show = true;

            //----------------------------------------------------------------------------------------
            //DEBUG :
            //console.log("data this.notification "+ this.notification.subject);
            //console.log("data this.dialog_show "+ this.dialog_show);
        },
        confirmDelete() {
            this.axios.delete(NOTIFICATIONS + '/delete_duplicates')
            
            .then(() => {
                this.Dialog_delete = false;
                this.showSnackbar('success', this.$t('global.success_to_delete'));
            
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_delete'));
            
            }).finally(() => {
                this.getNotification()
            })
        },
        // MORE OPTIONS
        viewMore() {
            this.history_length = this.history_length + 10;
            this.getNotification();
        },
        customSearch (value, search, item) {
          if (Array.isArray(value)) {
              return value.some(item=>Object.values(item).some(v=>v&&v.toString().toLowerCase().includes(search)))
          }
          return Object.values(item).some(v=>v&&v.toString().toLowerCase().includes(search))
        },
        setNotificationFiltered (level) {
            this.level_mode = level;
            this.history_length=5;
            this.getNotification();
        },
        dialogNextUpdate() {
            if (this.dialog_next_update){
                this.dialog_next_update = false;
            }else{
                this.dialog_next_update = true;
            }
        },
        closeNewUpdateDialog(){
            this.setCookieVolet();
            this.dialog_new_update = false;
        }
    }
}
</script>