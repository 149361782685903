var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4",attrs:{"id":"document-import"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('p',{staticClass:"title mb-0 color-primary text-uppercase"},[_vm._v("\n                "+_vm._s(_vm.$t('documents_import.title'))+"\n            ")])])],1),_vm._v(" "),_c('v-expand-transition',[_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.alert_file_send),expression:"alert_file_send"}],attrs:{"type":"success","transition":"fade-transition","close-icon":"mdi-close","text":"","dismissible":""}},[_vm._v("\n            "+_vm._s(_vm.$t('documents_import.alert_success_import'))+"\n        ")])],1),_vm._v(" "),_c('v-expand-transition',[_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.alert_file_error),expression:"alert_file_error"}],attrs:{"text":"","type":"warning","transition":"fade-transition","close-icon":"mdi-close","dismissible":""}},[_vm._v("\n            "+_vm._s(_vm.alert_message)+"\n        ")])],1),_vm._v(" "),_c('v-card',{staticClass:"mt-4",attrs:{"width":"100%","elevation":"0"}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card-text',[_c('v-row',[_c('v-autocomplete',{staticClass:"mt-5 ml-3 width-layout",attrs:{"disabled":_vm.professionals.length === 0,"items":_vm.professionals,"loading":_vm.professionals_load,"label":_vm.$t('documents_import.client'),"item-text":"name","item-value":"id","return-object":"","filled":""},on:{"change":function($event){return _vm.getContractsByProfessional()}},model:{value:(_vm.selected_professional),callback:function ($$v) {_vm.selected_professional=$$v},expression:"selected_professional"}}),_vm._v(" "),_c('v-autocomplete',{staticClass:"mt-5 ml-3 width-layout",attrs:{"disabled":_vm.contracts.length === 0,"items":_vm.contracts,"loading":_vm.contracts_load,"label":_vm.$t('documents_import.contract_number'),"item-text":"label","item-value":"id","return-object":"","filled":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                            "+_vm._s(item.label + ' - ' + item.contract_number)+"\n                        ")]}}],null,true),model:{value:(_vm.selected_contract),callback:function ($$v) {_vm.selected_contract=$$v},expression:"selected_contract"}})],1),_vm._v(" "),_c('div',{staticClass:"file-input-zone-container"},[_c('div',{class:['dropzone', _vm.dragging ? 'dropzone-over' : ''],on:{"dragenter":function($event){_vm.dragging = true},"dragleave":function($event){_vm.dragging = false}}},[_c('div',{staticClass:"dropzone-info",on:{"drag":_vm.onChange}},[_c('span',{staticClass:"fa fa-cloud-upload dropzone-title"}),_vm._v(" "),_c('v-icon',{attrs:{"large":"","color":"primary"}},[_vm._v(" mdi-cloud-download-outline")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('div',{staticClass:"dropzone-upload-limit-info"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('documents_import.help')))]),_vm._v(" "),_c('span',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.$t('documents_import.notice')))]),_vm._v(" "),_c('v-btn',{staticClass:"dropzone-btn mt-2",attrs:{"outlined":"","width":"400","color":"primary"}},[_vm._v("\n                                    "+_vm._s(_vm.$t('documents_import.selection_button'))+"\n                                ")])],1)],1),_vm._v(" "),_c('input',{attrs:{"type":"file","accept":"application/pdf","multiple":""},on:{"change":_vm.onChange}})])]),_vm._v(" "),_vm._l((_vm.files),function(file){return _c('div',{staticClass:"mt-6"},[_c('p',{staticClass:"uploaded-file-name"},[_vm._v(_vm._s(file.name))]),_vm._v(" "),_c('v-icon',{staticClass:"remove-file",on:{"click":function($event){return _vm.removeFile(file)}}},[_vm._v("mdi-close-circle-outline")]),_vm._v(" "),_c('v-divider',{staticClass:"mt-2"})],1)})],2),_vm._v(" "),_c('v-card-actions',{staticClass:"pr-4 pb-4"},[_c('v-spacer'),_vm._v(" "),_c('v-dialog',{attrs:{"transition":"dialog-top-transition","width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.isValid,"color":"primary"}},'v-btn',attrs,false),on),[_vm._v("\n                            "+_vm._s(_vm.$t('global.validation'))+"\n                        ")])]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_vm._v(" "),_c('v-card',[_c('v-card-title',[_vm._v("\n                            "+_vm._s(_vm.$t('documents_import.dialog_send_title'))+"\n                        ")]),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-container',[_c('v-card-text',{staticClass:"text-center"},[_vm._v("\n                                "+_vm._s(_vm.$t('documents_import.dialog_send_text', {client_name: _vm.selected_professional.name}))+"\n                            ")]),_vm._v(" "),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{staticClass:"cancel-btn",attrs:{"text":"","link":""},on:{"click":function($event){_vm.dialog=false}}},[_vm._v("\n                                    "+_vm._s(_vm.$t('global.cancel'))+"\n                                ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.sendFiles()}}},[_vm._v("\n                                    "+_vm._s(_vm.$t('global.validation'))+"\n                                ")])],1)],1)],1)],1)],1)]}}])})],1),_vm._v(" "),_c('global-snackbar')],1)}
var staticRenderFns = []

export { render, staticRenderFns }