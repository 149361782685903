<template>
    <div id="machine-specifications" style="height: 100%">
        <v-card elevation="2" outlined min-height="525px" height="100%">
            <v-card-title class="justify-space-between">
                <v-icon>mdi-view-list-outline</v-icon>
                {{ $t('machine_specifications.title') }}
                <v-btn elevation="3" color="amber darken-3" small @click="openDialogUpdateMachine()">
                    <v-icon color="white" small>mdi-wrench</v-icon>
                </v-btn>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text>
                <v-row class="mt-2">
                    <v-text-field :prepend-inner-icon="'mdi-sitemap'" class="ml-2 mr-2 text-field-cursor-pointer"
                                  :label="$t('subassembly_specifications.site')"
                                  v-model="machine.building.site.label"
                                  @click="$router.push({ name: 'site', params: {id: machine.building.site.id} })"
                                  outlined readonly background-color="#EEEEEE"
                    />
                    <v-text-field :prepend-inner-icon="'mdi-office-building-outline'"
                                  class="ml-2 mr-2 text-field-cursor-pointer"
                                  :label="$t('subassembly_specifications.building')"
                                  v-model="machine.building.label"
                                  @click="$router.push({ name: 'building', params: {id: machine.building.id} })"
                                  outlined readonly background-color="#EEEEEE"
                    />
                    <v-text-field :prepend-inner-icon="'mdi-label-outline'" class="ml-2 mr-2"
                                  :label="$t('global.name')"
                                  v-model="machine.label"
                                  outlined readonly background-color="#EEEEEE"
                    />

                    <v-text-field :prepend-inner-icon="'mdi-function'" class="ml-2 mr-2"
                                  :label="$t('machines.utilisation')"
                                  v-model="machine.functionality"
                                  outlined readonly background-color="#EEEEEE"
                    />

                    <v-textarea
                        v-model="machine.note"
                        rows="2" outlined readonly no-resize background-color="#EEEEEE"
                        :label="$t('global.notes')"
                        :prepend-inner-icon="'mdi-comment-outline'" class="ml-2 mr-2"
                    />
                </v-row>

                <v-img aspect-ratio="1" :src="machine.building.path_plan" height="150" contain>
                    <template v-slot:placeholder>
                        <v-row
                            class="fill-height ma-0 background-loader-image"
                            align="center" justify="center"
                        >
                            <v-progress-circular indeterminate color="black lighten-5" v-if="no_path_image === false"/>

                            <p class="ma-0" v-else>
                                {{ $t('global.no_image') }}
                            </p>
                        </v-row>
                    </template>
                </v-img>
            </v-card-text>
        </v-card>

        <v-dialog v-model="dialog_edit" max-width="500px">
            <v-card>
                <validation-observer ref="observer" v-slot="{ invalid }">
                    <v-form ref="form">
                        <v-card-title>
                            <span class="headline">{{ $t('machines.edit_machine') }}</span>
                        </v-card-title>
                        <v-divider></v-divider>

                        <v-card-text>
                            <validation-provider :name="$t('machines.building')" rules="required"
                                                 v-slot="{ errors }">
                                <v-select
                                    :items="buildings"
                                    v-model="edited_machine.building_id"
                                    :label="$t('machines.building')"
                                    :prepend-icon="'mdi-office-building-outline'"
                                    :error-messages="errors"
                                    item-value="id"
                                    item-text="label" solo
                                >
                                </v-select>
                            </validation-provider>

                            <validation-provider :name="$t('global.name')"
                                                 rules="required"
                                                 v-slot="{ errors }">
                                <v-text-field solo
                                              :label="$t('global.name')"
                                              v-model="edited_machine.label"
                                              :prepend-icon="'mdi-bookmark-check-outline'"
                                              :error-messages="errors"
                                />
                            </validation-provider>

                            <validation-provider :name="$t('machines.utilisation')"
                                                 rules="required"
                                                 v-slot="{ errors }">
                                <v-text-field solo
                                              :label="$t('machines.utilisation')"
                                              v-model="edited_machine.functionality"
                                              :prepend-icon="'mdi-function'"
                                              :error-messages="errors"
                                />
                            </validation-provider>

                            <v-textarea
                                v-model="edited_machine.note"
                                rows="2" solo no-resize
                                :label="$t('global.notes')"
                                :prepend-icon="'mdi-comment-outline'"
                            />
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer/>
                            <v-btn @click="dialog_edit = false">
                                <v-icon left>mdi-close</v-icon>
                                {{ $t('global.close') }}
                            </v-btn>
                            <v-btn type="submit" color="primary" :disabled="invalid"
                                   @click.prevent="updateMachineDetails()">
                                <v-icon left>mdi-content-save</v-icon>
                                {{ $t('global.validation') }}
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </validation-observer>
            </v-card>
        </v-dialog>

        <v-snackbar
            v-model="snackbar"
            :color="snackbar_type">
            {{ snackbar_text }}
            <template v-slot:action="{ attrs }">
                <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
            </template>
        </v-snackbar>
    </div>
</template>

<script>

import {MACHINES, BUILDINGS} from "../../../api";

export default {
    name: "MachineSpecifications",
    data() {
        return {
            buildings: [],
            machine_id: null,
            machine: {
                id: null,
                label: '',
                functionality: '',
                note: '',
                building: {
                    id: null,
                    label: '',
                    site: {
                        id: null,
                        label: ''
                    }
                }
            },
            edited_machine: null,
            dialog_edit: false,
            invalid: false,

            no_path_image: false,
        }
    },
    created() {
        this.machine_id = this.$route.params.id;
        this.getMachineDetails();
        this.getBuildings();
    },
    methods: {
        getMachineDetails() {
            this.axios.get(
                MACHINES + '/' + this.machine_id
            ).then((success) => {
                this.machine = success.data;
                this.$emit('updateLabelMachine', {label: this.machine.label});
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            });
        },

        getBuildings() {
            this.axios.get(
                BUILDINGS
            ).then((success) => {
                this.buildings = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            });
        },

        openDialogUpdateMachine() {
            this.edited_machine = Object.assign({}, this.machine);
            this.dialog_edit = true;
        },

        updateMachineDetails() {
            this.axios.patch(
                MACHINES + '/' + this.machine_id, this.edited_machine
            ).then((success) => {
                this.machine = success.data;
                this.$emit('updateLabelMachine', {label: this.machine.label});
                this.showSnackbar('success', this.$t('global.success_to_edit'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            }).finally(() => {
                this.dialog_edit = false
                this.resetForm();
            });
        },

        resetForm() {
            this.$refs.form.reset();
            this.$refs.observer.reset();
        }
    },
    mounted() {
        this.$nextTick(function () {
            setTimeout(() => {
                this.no_path_image = true;
            }, 5000);
        })
    }
}
</script>
