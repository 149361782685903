import { render, staticRenderFns } from "./ThreeDimensionalCurveSettingsModal.vue?vue&type=template&id=5a90403e&scoped=true&"
import script from "./ThreeDimensionalCurveSettingsModal.vue?vue&type=script&lang=js&"
export * from "./ThreeDimensionalCurveSettingsModal.vue?vue&type=script&lang=js&"
import style0 from "./ThreeDimensionalCurveSettingsModal.vue?vue&type=style&index=0&id=5a90403e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a90403e",
  null
  
)

export default component.exports