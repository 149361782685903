<template>
    <v-card class="rounded-lg" elevation="2" outlined :loading="loading" max-height="250px">


        <v-card-text>
            <div id="chart-time">
               
                <highcharts :options="lineChartOptions"></highcharts>
                
                <!--
                <highcharts :constructorType="'stockChart'" :options="chartOptions" ref="highcharts"></highcharts>
                <highcharts :options="donutChartOptions"></highcharts>
                -->
            </div>
        </v-card-text>

        <v-snackbar
            v-model="snackbar"
            :color="snackbar_type">
            {{ snackbar_text }}
            <template v-slot:action="{ attrs }">
                <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
            </template>
        </v-snackbar>
    </v-card>
</template>

<script>
import moment from 'moment';
import {DOWNLOAD_CSV_TEXT_CURVE, FAVORITE_CURVES, METRICS, REPORT} from "../../../../api";
import {api as fullscreen} from 'vue-fullscreen';

export default {
    name: "Line",
    props: ['favoriteCurve'],
    components: {},
    data() {
        return {
            favorite_curve: this.favoriteCurve,
            date_operation_click: null,
            api_data: [],
            range: [
                moment().clone().startOf('day').subtract(365, "days").format('YYYY-MM-DD'),
                moment().clone().endOf('day').format('YYYY-MM-DD')
            ],
            chartLoaded: false,
            metric_details: '',
            chartOptions: {
                title: {
                    text: ''
                },
                time: {
                    timezone: 'Europe/Paris',
                },
                navigator: {
                    //adaptToUpdatedData: false,
                },
                lang: {
                    noData: this.$t('three_dimensional_curve.data_loading_error')
                },
                noData: {
                    style: {
                        fontWeight: 'bold',
                        fontSize: '15px',
                        color: '#303030'
                    }
                },
                loading: {
                    hideDuration: 1000,
                    showDuration: 1000
                },
                scrollbar: {
                    liveRedraw: false,
                    height: 17,
                    barBackgroundColor: '#cfcfcf',
                    barBorderRadius: 9,
                    buttonBackgroundColor: '#c9c9c9',
                    buttonArrowColor: 'white',
                    buttonBorderWidth: 3,
                    buttonBorderRadius: 11,
                    rifleColor: 'white',
                    trackBorderRadius: 9
                },
                rangeSelector: {
                    enabled: false,
                },
                updateArgs: [true, true, true],
                xAxis: {
                    events: {
                        setExtremes: (e) => {
                            this.zoomInOut(e);
                        }
                    }
                },
                yAxis: {
                    title: {
                        text: '',
                    },
                    plotBands: [],
                },
                plotOptions: {
                    line: {
                        connectNulls: true,
                    },
                    series: {
                        marker: {
                            enabled: true,
                            radius: 4
                        }
                    }
                },
                series: [],
                credits: {
                    enabled: false
                },
            },
            chart: {
                preAlert: {
                    yMin: 0,
                    yMax: 0
                },
                alert: {
                    yMin: 0,
                    yMax: 0
                },
                selected: {
                    label: null,
                    date: null,
                    value: null
                },
            },
            loading: false,
            menu: false,
            menu2: false,
            dialog_delete_favorite_curve: false,
            display_flags: true,
            chartSeries: {
                series: null,
                flags: null,
            },
            dialog_update_thresholds: false,
            thresholds: {
                alert_high: null,
                pre_alert_high: null,
                alert_low: null,
                pre_alert_low: null
            },
            navigator_series: [],
            merger: 'MEAN',
            number_of_point: 200,
            csv_export_loading: false,
            lineChartOptions: {
                chart: {
                type: 'line'
                },
                title: {
                text: 'Evolution de l\'indice de santé machine'
                },
                series: [{
                    name: 'Data',
                    data: []
                }]
            },
            raw_report: [],
            report: [],
            report_series: [],
        }
    },
    created() {
        window.moment = moment;
    },
    computed: {
        dateRangeText() {
            const from = moment(this.range[0]).format('DD-MM-YYYY');
            const to = moment(this.range[1]).format('DD-MM-YYYY');
            return `Du ${from} au ${to}`;
        },
    },
    mounted() {
        this.initialize();
    },
    methods: {
        initialize() {
            this.getData();

        },

        getData() {
            // GETTING CAMPAINS
            this.axios.get(REPORT + "/machines_indices/" + this.$route.params.id,)
            
            .then((success) => {
                this.raw_report = [];
                this.report = [];
                this.report_series = [];

                if (typeof success.data[0] !== 'undefined'){
                    this.report_available = true;
                    this.raw_report = success.data;

                    success.data.forEach(report => {
                        this.report_series.push(report.indice);
                    })
                    
                    this.lineChartOptions.series.data = this.report_series;

                }else{
                    this.report_available = false;
                }

            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");

            }).finally(() => {
                this.getSideDatas();
                this.loading_datatable = false;
            })
        },


        ///////////////////////////////////////////////////////////////////////////////////////////////////////////

        /*hideFlags() {
            if (this.display_flags) {
                this.chartOptions.series = [this.chartSeries.series, this.chartSeries.flags];
            } else {
                this.chartOptions.series = [this.chartSeries.series];
            }
        },*/

        async loadChart() { 
            this.loading = true;
            this.$refs.highcharts.chart.hideNoData();
            this.$refs.highcharts.chart.showLoading();

            let from = moment(this.range[0]).startOf('day').toISOString()
            let to = moment(this.range[1]).endOf('day').toISOString();

            if (moment(from).isAfter(to)) {
                [from, to] = [to, from];
            }
            let intervalDate = moment(from).valueOf() - moment(to).valueOf()
            if (intervalDate < 0) {
                intervalDate *= -1
            }

            let interval = this.calculInterval(intervalDate,this.number_of_point);

            const uri = `/api/${this.favorite_curve.type_relatable}/${this.favorite_curve.id_relatable}/metric/${this.favorite_curve.metric_uuid}/` +
                `timestamp?start=${from}&end=${to}&interval=${interval}&merger=${this.merger}`;

            await this.axios.get(uri).then((success) => {
                const data = success.data.data_metric;
                this.api_data = data;

                if (success.data.metric_thresholds) {
                    this.setThresholdsDialog(success.data.metric_thresholds);
                    this.setMetricThresholds();
                }
                this.buildSeries(data, this.favorite_curve, true);
                this.chartOptions.yAxis.title.text = this.favorite_curve.metric_unit;
            }).catch(() => {
                this.loading = false;
                this.$refs.highcharts.chart.hideLoading();
                this.$refs.highcharts.chart.showNoData(this.$t('three_dimensional_curve.data_loading_error'));
            })
        },
        async refresh() {
            this.loading = true;
            this.$refs.highcharts.chart.hideNoData();
            this.$refs.highcharts.chart.showLoading();
            let from = moment(this.range[0]).startOf('day').toISOString()
            let to = moment(this.range[1]).endOf('day').toISOString();

            if (moment(from).isAfter(to)) {
                [from, to] = [to, from];
            }

            let intervalDate = moment(from).valueOf() - moment(to).valueOf()
            if (intervalDate < 0) {
                intervalDate *= -1
            }

            let interval = this.calculInterval(intervalDate,this.number_of_point);

            const uri = `/api/${this.favorite_curve.type_relatable}/${this.favorite_curve.id_relatable}/metric/` +
                `${this.favorite_curve.metric_uuid}/timestamp?start=${from}&end=${to}&interval=${interval}&merger=${this.merger}`;

            await this.axios.get(uri).then((success) => {
                const data = success.data.data_metric;
                this.api_data = data;

                if (success.data.metric_thresholds) {
                    this.setMetricThresholds();
                }
                this.buildSeries(data, this.favorite_curve, true);
            }).catch(() => {
                this.loading= false;
                this.$refs.highcharts.chart.hideLoading();
                this.$refs.highcharts.chart.showNoData(this.$t('three_dimensional_curve.data_loading_error'));
            });
        },
        setThresholdsDialog(metricThresholds) {
            this.thresholds.pre_alert_low = metricThresholds['prealert-low'];
            this.thresholds.alert_low = metricThresholds['alert-low'];
            this.thresholds.pre_alert_high = metricThresholds['prealert-high'];
            this.thresholds.alert_high = metricThresholds['alert-high'];
        },
        setMetricThresholds() {
            this.chartOptions.yAxis.plotBands = [];
            if (this.thresholds.alert_high !== this.thresholds.pre_alert_high) {

                this.chartOptions.yAxis.plotBands.push(
                    {
                        from: this.thresholds.pre_alert_high,
                        to: this.thresholds.alert_high,
                        color: 'rgba(188, 151, 12, 0.2)',
                        label: {
                            text: 'Seuil de pré-alerte haute'
                        }
                    },
                    {
                        from: this.thresholds.alert_high,
                        to: 50000,
                        color: 'rgba(188, 12, 12, 0.2)',
                        label: {
                            text: 'Seuil d\'alerte haute'
                        }
                    }
                );
            }

            if (this.thresholds.pre_alert_low !== this.thresholds.alert_low) {

                this.chartOptions.yAxis.plotBands.push(
                    {
                        from: this.thresholds.pre_alert_low,
                        to: this.thresholds.alert_low,
                        color: 'rgba(188, 151, 12, 0.2)',
                        label: {
                            text: 'Seuil de pré-alerte basse'
                        }
                    },
                    {
                        from: this.thresholds.alert_low,
                        to: 0,
                        color: 'rgba(188, 12, 12, 0.2)',
                        label: {
                            text: 'Seuil d\'alerte basse'
                        }
                    }
                );
            }
        },
        buildSeries(data, favorite_curve, boolUpdateNavigator) {
            const metrics = data.data;
            const operations = data.operations;
            const modalOps = this.$refs.modalOperation;
            let dataComponents = this;
            let series = [];
            let flags = [];
            metrics.forEach(metric => {
                let utcDate = (new Date(metric.date)).getTime();
                series.push([utcDate, parseFloat((parseFloat(metric.value) * 1e2 / 1e2).toFixed(4))]);

                /*if (metric.operation) {
                    let utcOperationDate = (new Date(metric.operation.date)).getTime();
                    let flag = {
                        x: utcOperationDate,
                        title: ' ',
                        text: metric.operation.label
                    };
                    flags.push(flag);
                }*/
            });

            operations.forEach(operation => {
                let utcOperationDate = (new Date(operation.date_operation)).getTime();
                let flag = {
                    x: utcOperationDate,
                    title: ' ',
                    text: operation.label
                };
                flags.push(flag);
            });

            let dataSeries = {
                name: this.favorite_curve.metric_unit,
                id: 'dataseries',
                data: series,
                events: {
                    click: function (e) {
                        const value = {
                            favorite_curve: favorite_curve,
                            date: moment(e.point.category).toDate(),
                        };
                        dataComponents.date_operation_click = value;
                        modalOps.openDialogAddOperation(value);
                    },
                },
                navigatorOptions: {
                    visible: false
                },
            };

            let dataSeriesNavigator = {
                name: 'DataSeriesNavigator',
                data: series,
            };

            let dataFlags = {
                type: 'flags',
                data: flags,
                shape: 'url(/images/wrench.png)',
                width: 22
            };

            if (boolUpdateNavigator) {
                this.navigator_series = [dataSeriesNavigator];
            }
            this.chartOptions.navigator.series = this.navigator_series;
            this.chartSeries.series = dataSeries;
            this.chartSeries.flags = dataFlags;
            if (this.display_flags) {
                this.chartOptions.series = [dataSeries, dataFlags];
            } else {
                this.chartOptions.series = [dataSeries];
            }
            this.chartOptions.title.text = this.favoriteCurve.virtual_device.label + ' - ' + this.metric_details[0].metric_name;
            this.$refs.highcharts.chart.hideLoading();
            if(!this.$refs.highcharts.chart.hasData()) {
                this.$refs.highcharts.chart.hideNoData();
                this.$refs.highcharts.chart.showNoData(this.$t('global.curve_no_data'));
            }
            this.loading= false;
        },
        updateThresholds() {
            this.axios.put(
                METRICS + '/' + this.favorite_curve.metric_uuid, this.thresholds
            ).then(() => {
                this.showSnackbar('success', this.$t('global.success_to_edit'));
                this.loadChart();
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            }).finally(() => {
                this.dialog_update_thresholds = false;
            });
        },
        toggleFullscreen() {
            let wrapper = this.$el.querySelector('#chart-time');
            wrapper.classList.add('enabled');

            fullscreen.toggle(wrapper, {
                teleport: this.teleport,
                callback: (isFullscreen) => {
                    this.fullscreen = isFullscreen;
                    if (isFullscreen === false) {
                        this.$el.querySelector('#chart-time').classList.remove('enabled');
                    }
                },
            });
        },
        deleteFavoriteCurve() {
            this.axios.delete(
                FAVORITE_CURVES + '/' + this.favorite_curve.id
            ).then(() => {
                this.$emit('deleteFavoriteCurve', this.favorite_curve.id);
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_delete'))
            }).finally(() => {
                this.dialog_delete_favorite_curve = false;
            });
        },
        showSnackbarForModalOperation(item) {
            this.showSnackbar(item.type, item.text);
            this.refresh()
            this.$emit('updateOperationDatatable');
        },
        async zoomInOut(event) {
            this.loading = true;
            this.$refs.highcharts.chart.showLoading();
            let numberOfPoint = 200;

            // The min and max extreme in milliseconds
            const min = event.min;
            const max = event.max;

            // The interval in milliseconds
            const scrollbarInterval = max - min;

            let interval = this.calculInterval(scrollbarInterval,this.number_of_point);

            let from = moment(min).toISOString();
            let to = moment(max).toISOString();

            const uri = `/api/${this.favorite_curve.type_relatable}/${this.favorite_curve.id_relatable}/metric/` +
                `${this.favorite_curve.metric_uuid}/timestamp?start=${from}&end=${to}&interval=${interval}&merger=${this.merger}`;

            await this.axios.get(
                uri
            ).then((success) => {
                let data = success.data.data_metric;
                this.buildSeries(data, this.favorite_curve, false);
            }).catch(() => {
                this.loading= false;
                this.$refs.highcharts.chart.hideLoading();
                this.$refs.highcharts.chart.showNoData(this.$t('three_dimensional_curve.data_loading_error'));
            })
        },
        checkPrivilege() {
            return this.$store.state.access_level === 200 || this.$store.state.access_level === 300
        },
        TimeCurveCsvExport() {
            this.csv_export_loading= true;
            this.axios({
                url: DOWNLOAD_CSV_TEXT_CURVE,
                method: 'POST',
                data: {
                    'data': this.api_data.data,
                },
                responseType: 'blob',
            }).then((success) => {
                this.downloadCsvFile(success.data, "_TextCurve :" + this.favorite_curve.label + "_data.csv");
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('global.failure_to_download'));
            }).finally(()=>{
                this.csv_export_loading= false;
            });
        },
    }
}
</script>

<style scoped lang="scss">
.fullscreen-wrapper {
    background: white;
}

.fullscreen-wrapper.enabled {
    z-index: 10;
    padding: 50px;
}

.highcharts-flags-series > .highcharts-label > text {
    display: none !important;
}
</style>
