<template>
    <div id="=Installer-switch-Interface">
        <v-dialog v-if="this.$store.state.access_level === 200"
                  transition="dialog-top-transition"
                  v-model="installer_interface_switch"
                  width="600"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn-toggle dense>
                    <v-btn class="admin-btn">
                        {{ $t('installer_redirection_notice.admin_space') }}
                    </v-btn>

                    <v-btn color="#E0E0E0" v-bind="attrs" v-on="on">
                        {{ $t('installer_redirection_notice.to_client_space') }}
                    </v-btn>
                </v-btn-toggle>
            </template>

            <v-card>
                <v-container>
                    <v-card-text class="text-center">
                        <p class="mb-5">{{ $t('installer_redirection_notice.redirection_client') }}</p>
                        <p class="mb-5">{{ $t('installer_redirection_notice.redirection_ask_client') }}</p>
                        <p class="color-warning mb-5">
                            {{ $t('installer_redirection_notice.warning_redirection_client') }}
                        </p>
                    </v-card-text>

                    <v-card-actions class="justify-center">
                        <v-btn text link @click="installer_interface_switch = false">
                            {{ $t('installer_redirection_notice.cancel_redirection_client') }}
                        </v-btn>

                        <v-btn color="primary" @click="installerInterfaceSwitch()">
                            {{ $t('global.validation') }}
                        </v-btn>
                    </v-card-actions>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: "InstallerSwitchInterface"
}
</script>

<style scoped>
.admin-btn {
    pointer-events: none;
    background-color: #004181 !important;
    color: white !important;
}
</style>
