<template>
    <v-col class="col mt-12" style="margin-top:0px!important;padding:0px;position:relative; top:-12px;">
        <!-- MACHINE MENU PACKAGE -->
        <div class="mb-3" style="padding:0px 12px;">
            <v-col class="pl-5 pr-5" style="background-color:#001047;color:#FFFFFF;border-radius: 0px 0px 5px 5px;">
                <!-- TOP TITLE -->
                <v-row class="pl-0 pt-5 mb-5" style="height: 52px; padding:5px;">
                    <!-- PAGE TITLE -->
                    <v-subheader class="v-subheader col theme--light">
                        <!-- REPORTS -->
                        <v-tooltip bottom dark class="mr-0">
                            <template v-slot:activator="{ on }">
                                <v-btn class="mr-0 col-0" text icon v-on="on" style="background-color:#e8edff;border-radius:20px;font-weight:bold;color:#001047;">
                                    {{ apps.length }}
                                </v-btn>
                            </template>
                            <span>Applications disponibles</span>
                        </v-tooltip>
                        
                        <!------------------- Info bulle ---------------------->
                        <v-tooltip bottom dark class="mr-2 ml-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 mr-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                                    <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                                </v-btn>
                            </template>
                            <template v-slot:default>
                            <div style="min-width: 200px;position:relative;z-index: 10;">
                                <strong>Page [Centre d'applications]</strong>
                                <p>Retrouvez toutes les applications internes répertoriées chez Techview sur cette page.</p>
                            </div>
                            </template>
                        </v-tooltip>
                        <p class="title mb-0 color-white" style="color: white!important;">
                            <div style="color:#FFFFFFb3;margin-right:4px;">Centre d'informations > </div><div @click="infoToggler()" style="color:#FFFFFF;font-weight:bold;">Applications </div>
                        </p>
                    </v-subheader>
                </v-row>

                <!-- MACHINE INFOS (UNUSED) -->
                <v-col class="mb-5" v-if="infoToggle == true" style="min-height: 250px; background-color:#e8edff;border-radius:5px;">
                    <v-row>

                        <!-- insert HEADER sub content HERE -->

                    </v-row>
                </v-col>
                
                <!-- MACHINE LINKS -->
                <v-row v-if="infoToggle == false" style="border-top: 1px solid #ffffff1c;">
                    <v-row class="pb-2" style="margin:0px;">
                        <v-row class="ml-3 pt-4 pb-0 rounded" style="height:68px;">
                            <!-- BUTTON EXAMPLE 
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/events'}" dusk="logout-button">
                                        <v-icon color="white">mdi-alert</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.alerts') }}</span>
                            </v-tooltip>
                            -->

                            <div
                                v-for="filters in notification_levels"
                                :key="filters.level"
                                class="mr-0"
                                style="height:40px; padding: 4px 0px;"
                            >
                                <div
                                class="ma-0 v-chip v-chip--clickable v-chip--link v-chip--no-color theme--light v-size--default"
                                style="
                                    color: white;
                                    font-weight: 400;
                                    display: flex;
                                    justify-content: left;
                                    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.29);
                                    background-color: #ffffff8c;
                                    font-size: 14px;
                                    height: 32px;
                                    margin-right: 12px !important;
                                "
                                @click="setDataFiltered(filters.level)"
                                >
                                <div
                                    v-if="filters.level_name !== 'Tout voir'"
                                    class="mr-2"
                                    style="color: white;min-width: 30px; min-height: 20px; border-radius:20px;font-weight: bold;text-align: center;"
                                    :style="{backgroundColor: filters.level_color}"
                                >{{filters.count}}</div>
                                {{filters.level_name}}
                                </div>
                            </div>
                        </v-row>

                        <v-row class="justify-end mt-2 mr-3">

                        </v-row>  
                    </v-row>
                </v-row>

                <v-row v-if="infoToggle == true">
                    <v-row class="pb-2" style="margin:0px;">
                        <v-row class="ml-3 pt-4 pb-0 rounded" style="">
                            <!-- BUTTON EXAMPLE 
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/events'}" dusk="logout-button">
                                        <v-icon color="white">mdi-alert</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.alerts') }}</span>
                            </v-tooltip>
                            -->

                            <div
                                v-for="filters in notification_levels"
                                :key="filters.level"
                                class="mr-0"
                                style="height:40px; padding: 4px 0px;"
                            >
                                <div
                                class="ma-0 v-chip v-chip--clickable v-chip--link v-chip--no-color theme--light v-size--default"
                                style="
                                    color: white;
                                    font-weight: 400;
                                    display: flex;
                                    justify-content: left;
                                    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.29);
                                    background-color: #ffffff8c;
                                    font-size: 14px;
                                    height: 32px;
                                    margin-right: 12px !important;
                                "
                                @click="setDataFiltered(filters.level)"
                                >
                                <div
                                    v-if="filters.level_name !== 'Tout voir'"
                                    class="mr-3"
                                    style="color: white;min-width: 30px; min-height: 20px; border-radius:20px;font-weight: bold;text-align: center;"
                                    :style="{backgroundColor: filters.level_color}"
                                >{{filters.count}}</div>
                                {{filters.level_name}}
                                </div>
                            </div>
                        </v-row>     
                    </v-row>
                </v-row>
            </v-col>
        </div>

        <v-col  class="mb-4" no-gutters v-for="level in apps_level" :key="level">
            <h4 class="ml-3">{{ level.name }}</h4>
            <v-row class="pa-0 ma-0">
                <div class="pa-0 ma-0" v-for="app in apps" :key="app">
                    <v-col class="pa-1" v-if="level.level == app.level">
                        <a :href="app.address" style="text-decoration:none">
                            <v-card class="ma-1" elevation="0" style="min-height: 80px;display:flex;align-items: center;">
                                <v-card-text class="mr-6">
                                    <div class="d-flex flex-row">
                                        <v-img :src="app.logo" contain max-height="50px" min-width="100px"/>

                                        <div class="d-block ml-5">
                                            <span class="font-weight-bold;" style="font-weight: bold;color: #3a3a3a;">{{ app.name }}</span>
                                            <p class="color-grey ma-0" style="font-size:12px;">{{ app.description }}</p>
                                        </div>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </a>
                    </v-col>
                </div>
            </v-row>
        </v-col>

        <v-expansion-panels style="display: none;">
            <v-expansion-panel
                v-for="(appsByLevel, level) in groupedApps"
                :key="level"
            >
                <v-expansion-panel-header>
                    <span>{{ getLevelName(level) }}</span>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                    <!-- DATA TABLE -->
                    <v-data-table
                        :headers="headers"
                        :items="appsByLevel"
                        item-value="id"
                        hide-default-footer
                        :items-per-page="-1"
                        class="text-center"
                        @click:row="goToAddress"
                    >
                        <template v-slot:item.logo="{ item }">
                            <div style="display: flex; justify-content: center; align-items: center;">
                                <img v-if="item.logo" :src="item.logo" alt="Logo" style="max-height: 50px; max-width: 250px;" />
                                <span v-else>Aucun logo</span>
                            </div>
                        </template>
                    </v-data-table>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-col>
</template>

<script>

export default {
    name: "Apps",
    components: {},
    data() {
        return {
            headers: [
                {text: "Logo", value: 'logo', width: '100px', sortable: false,},
                {text: "Nom", value: 'name', width: '30%'},
                {text: "Description", value: 'description', width:'70%'},
            ],
            apps:[
                // Services Groupe - Communs
                {id:1,   level:"1", name:"(Groupe) ANDQO - ERP via Coaxis",    description:"Bureau distant permettant la connexion aux services internes : GX, CXM, IVISION.", address:"", logo:"https://techview.fr/wp-content/uploads/2025/01/avufo7wkcjjxq1waevjx-e1737366053243.webp"},
                {id:2,   level:"1", name:"(Groupe) ANDQO - Outlook web",       description:"Lien interne web mail.", address:"https://aqmoowa.coaxis.com/owa", logo:"https://techview.fr/wp-content/uploads/2025/01/Microsoft-Outlook-logo1-1.png"},
                {id:3,   level:"1", name:"(Groupe) ANDQO - PremiumRH",         description:"Site de gestion des congés", address:"https://premium-rh.pro/aqmo/", logo:"https://mypremium.premium-rh.pro/assets/images/premium_rh_by_asys.png"},
                {id:4,   level:"1", name:"(Groupe) ANDQO - Ivision",           description:"Logiciel de pointage", address:"https://ivisionaqmo.coaxis.com/ivision/saas/1/index", logo:"https://everwin.fr/wp-content/uploads/2022/05/Logo-Everwin-1024x250.png"},
                {id:5,   level:"1", name:"(Groupe) ANDQO - Swile",             description:"Site de gestion de tickets restaurants", address:"https://www.swile.co/fr-fr", logo:"https://s3-eu-west-1.amazonaws.com/tpd/logos/5f86f0223251450001dafec9/0x0.png"},
                {id:6,   level:"1", name:"(Groupe) ANDQO - Hubspot",           description:"Site de suivi des clients", address:"https://app.hubspot.com/login", logo:"https://techview.fr/wp-content/uploads/2025/01/HubSpot-Logo.png"},
                {id:7,   level:"1", name:"(Groupe) ANDQO - Synology",          description:"Drive du groupe", address:"Site de drive", logo:"https://techview.fr/wp-content/uploads/2025/01/Synology_Logo.svg_.png"},
                // Services Groupe - Sites web
                {id:10,   level:"-1", name:"(Groupe) ANDQO - Website",          description:"Site du groupe ANDQO",         address:"https://www.groupeandqo.fr/", logo:"https://www.groupeandqo.fr/wp-content/uploads/2024/05/logo.png"},
                {id:11,   level:"-1", name:"(Groupe) ANDQO - Newsletter",       description:"Blog du groupe ANDQO",         address:"https://flax-hyssop-faf.notion.site/debc9aeae7dc4c66816ab00e6c173874?v=d4f1f63cc2b24a0fa6dc2e677856540c", logo:"https://www.groupeandqo.fr/wp-content/uploads/2024/05/logo.png"},
                {id:12,  level:"-1", name:"(Groupe) AQMO - Website",            description:"Site de AQMO",                 address:"https://www.aqmo.fr/", logo:"https://www.groupeandqo.fr/wp-content/uploads/2024/05/logo-AQMO-2022-2.png"},
                {id:13,  level:"-1", name:"(Groupe) SEMSO - Website",           description:"Site de SEMSO",                address:"https://www.semso.com/", logo:"http://serem-socorem.com/wp-content/uploads/2024/08/logo_semso_2022_petit.jpg"},
                {id:14,  level:"-1", name:"(Groupe) SEREM-SOCOREM - Website",   description:"Site de SERME-SOCOREM",        address:"https://serem-socorem.com/", logo:"http://serem-socorem.com/wp-content/uploads/2024/08/logo-SEREM.png"},
                {id:15,  level:"-1", name:"(Groupe) PRAQTIS - Website",         description:"Site de PRAQTIS",              address:"https://praqtis.fr/", logo:"http://serem-socorem.com/wp-content/uploads/2024/08/logo-praqtis.jpg"},
                {id:16,  level:"-1", name:"(Groupe) TECHVIEW - website",        description:"Site internet de la société.", address:"https://techview.fr/", logo:"https://www.techview.app/images/techview_logo_blue.png"},
                
                // Techview -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                // Services Techview - Communs
                {id:20,  level:"10", name:"Centre d'aides",         description:"Application de ticketing interne et externe.",                 address:"",                                     logo:"https://www.techview.app/images/techview_logo_blue.png"},
                {id:21,  level:"10", name:"Support",                description:"Centre de documentation et base de connaissances Techview",    address:"",                                     logo:"https://www.techview.app/images/techview_logo_blue.png"},
                {id:22,  level:"10", name:"SoPlanning",             description:"Logiciel de gestion de planning",                              address:"https://planning.techview.app",        logo:"https://www.soplanning.org/wp-content/uploads/2017/05/logo-soplanning.png"},
                {id:23,  level:"10", name:"VaultWarden",            description:"Gestionnaire de mots de passe hébergé chez Techview",          address:"https://vault.techview.app/#/login",   logo:"https://upload.wikimedia.org/wikipedia/commons/3/33/Vaultwarden_logo.svg"},
                {id:24,  level:"10", name:"Share",                  description:"Centre de partage de fichier sécurisé et hébergé chez Techview", address:"https://share.techview.app", logo:"https://www.techview.app/images/techview_logo_blue.png"},
                
                // Services Techview - Gestion
                {id:30,  level:"11", name:"SoPlanning",                         description:"Logiciel de gestion de planning",    address:"https://planning.techview.app", logo:"https://www.soplanning.org/wp-content/uploads/2017/05/logo-soplanning.png"},
                {id:31,  level:"11", name:"OpenProject",                        description:"Site de gestion de projets",         address:"https://projet.techview.app/", logo:"https://techview.fr/wp-content/uploads/2025/01/OpenProjectLogo.png"},
                {id:32,  level:"11", name:"(Google sheet) Gestion Techview",    description:"Fichier de gestion d'alertes (anterieur à la v1.5.2)", address:"https://docs.google.com/spreadsheets/d/1WkYAGd9Ms2Lb70oKnbJG_bfnNj8mLELnZq_8-dEhisk/edit#gid=1689263078", logo:"https://techview.fr/wp-content/uploads/2025/01/google-Sheets-for-business.png"},
                {id:33,  level:"11", name:"(Syno sheet) DOQ_0012_PARK_SOL",     description:"Fichier de gestion du parc machines clients (anterieur à la v1.5.2)", address:"https://aqmo-blanquefort.fr1.quickconnect.to/oo/r/uwfg6vQbESnzA4IWoqFJ1XCERpKwhZgo", logo:"https://techview.fr/wp-content/uploads/2025/01/google-Sheets-for-business.png"},
                {id:34,  level:"11", name:"(Syno sheet) DOQ_0011_TW_PLAN_2024", description:"Fichier de gestion de planning 2024", address:"https://aqmo-blanquefort.fr1.quickconnect.to/oo/r/uwbrPSHUP2Mg6KeKdJCbUNHqnLjek2Hx#tid=3", logo:"https://techview.fr/wp-content/uploads/2025/01/google-Sheets-for-business.png"},
                
                // Services Techview - OPS
                {id:40,  level:"12", name:"OVH",                      description:"Gestionnaire datacenter : hébergement dédié, VPS et noms de domaines", address:"", logo:"https://techview.fr/wp-content/uploads/2025/01/Logo_OVH.svg_.png"},
                {id:41,  level:"12", name:"Proxmox - Production",     description:"Orchestrateur cluster et virtualisation (OVH Prod principal)", address:"", logo:"https://techview.fr/wp-content/uploads/2025/01/proxmox-server-solutions-gmbh-logo-vector-e1737366126956.png"},
                {id:42,  level:"12", name:"Proxmox - Secondaire",     description:"Orchestrateur cluster et virtualisation (OVH Prod secondaire)", address:"", logo:"https://techview.fr/wp-content/uploads/2025/01/proxmox-server-solutions-gmbh-logo-vector-e1737366126956.png"},
                {id:43,  level:"12", name:"Proxmox - ANDQO",          description:"Orchestrateur cluster et virtualisation (AQMO Bx principal)", address:"", logo:"https://techview.fr/wp-content/uploads/2025/01/proxmox-server-solutions-gmbh-logo-vector-e1737366126956.png"},
                {id:44,  level:"12", name:"Proxmox - Backup Server",  description:"Gestionnaire de sauvegardes", address:"", logo:"https://techview.fr/wp-content/uploads/2025/01/proxmox-server-solutions-gmbh-logo-vector-e1737366126956.png"},
                {id:45,  level:"12", name:"CloudFlare",               description:"Gestionnaire réseaux tunneling & sécurité web", address:"", logo:"https://techview.fr/wp-content/uploads/2025/01/Cloudflare-Logo-e1737366161540.png"},
                {id:46,  level:"12", name:"ZT-Net",                   description:"Gestionnaire VPN", address:"", logo:"https://openrb.com/wp-content/uploads/2020/10/zerotier_logo-300x130.png"},
                {id:47,  level:"12", name:"Kuma",                     description:"Superviseurs de services en ligne", address:"https://status.techview.app/dashboard/1", logo:"https://techview.fr/wp-content/uploads/2025/01/images-1.png"},
                {id:48,  level:"12", name:"Rancher",                  description:"Gestionnaire, orchestrateur kubernetes (CD) production ", address:"https://rancher.techview.app/", logo:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbsJqlU363OP5RRMTNRKPOUuqPhCU_fwX2_w&s"},
                {id:49,  level:"12", name:"Balena",                   description:"Gestionnaire de flotte (Edge)", address:"", logo:"https://techview.fr/wp-content/uploads/2025/01/balena-io-logo-vector-e1737366206691.png"},

                // Services Techview - DEV
                {id:50,  level:"13", name:"VS Code",              description:"IDE coding multi langages", address:"https://code.visualstudio.com/download", logo:"https://techview.fr/wp-content/uploads/2025/01/images-2.png"},
                {id:51,  level:"13", name:"Git",                  description:"Logiciel de versionning et historisation de code", address:"https://git.techview.app/", logo:"https://techview.fr/wp-content/uploads/2025/01/images.png"},
                {id:52,  level:"13", name:"DockerHub",            description:"Logiciel de versionning d'images compilées sous docker (conteneurs)", address:"", logo:"https://techview.fr/wp-content/uploads/2025/01/Docker-Logo-2015-2017-e1737366250286.png"},
                {id:53,  level:"13", name:"SonarQube",            description:"Logiciel de revue de code", address:"https://sonarqube.techview.app", logo:"https://techview.fr/wp-content/uploads/2025/01/sonarqube-icon-2048x499-b6mw67zs.png"},
                {id:54,  level:"13", name:"Material Design",      description:"Bibliothèque d'icones compatibles avec VueJS-2", address:"https://pictogrammers.com/library/mdi/", logo:"https://pictogrammers.com/nextImageExportOptimizer/pictogrammers-full.a341b3e1-opt-828.WEBP"},
                {id:55,  level:"13", name:"Figma - Site web",     description:"Wireframe du site internet Techview", address:"https://www.figma.com/file/WqurIFMVQS4LLDRgc8mUbD/SIte-TECHVIEW?type=design&node-id=0%3A1&mode=design&t=cnXbHFSMTam2XWXm-1", logo:"https://techview.fr/wp-content/uploads/2025/01/figma-logo-post-e1737368037532.png"},
                {id:56,  level:"13", name:"Figma - Interface",    description:"Wireframe de l'application Techview", address:"https://www.figma.com/design/SQai84D3IhzrHEOY8JQPTu/WireFrame-MCO-balises-(Copy)", logo:"https://techview.fr/wp-content/uploads/2025/01/figma-logo-post-e1737368037532.png"},
                
                // Services Techview - VIB
                {id:70,  level:"14", name:"AMS",      description:"Bureau distant permettant la connexion aux services internes : AMS Machinery Manager.", address:"https://ams.techview.app", logo:"https://techview.fr/wp-content/uploads/2025/01/EmersonElectricLogo.png"},
                {id:71,  level:"14", name:"IFM VES",  description:"Logiciel d'administration des boitiers VES (IFM, VIB filaire)", address:"", logo:"https://techview.fr/wp-content/uploads/2025/01/ifm-efector-1.png"},

            ],
            apps_level:[
                {level: 1, name:"Services Groupe - Communs"},
                {level: 10, name:"Services Techview - Communs"},
                {level: 11, name:"Services Techview - Gestion"},
                {level: 12, name:"Services Techview - OPS"},
                {level: 13, name:"Services Techview - DEV"},
                {level: 14, name:"Services Techview - VIB"},
                {level: -1, name:"Services Groupe - Sites web"},
            ],
            notification_levels: [
                {
                    level: 10,
                    level_name: "Gestion",
                    level_color: "#00000069",
                    count:0
                },
                {
                    level: 11,
                    level_name: "Gestion",
                    level_color: "#00000069",
                    count:0
                },
                {
                    level: 12,
                    level_name: "Gestion",
                    level_color: "#00000069",
                    count:0
                },
                {
                    level: 13,
                    level_name: "Gestion",
                    level_color: "#00000069",
                    count:0
                },
                {
                    level: 14,
                    level_name: "Gestion",
                    level_color: "#00000069",
                    count:0
                },
                {
                    level: 1,
                    level_name: "Services Groupe - Communs",
                    level_color: "#00000069",
                    count:0
                },
                {
                    level: -1,
                    level_name: "Services Groupe - Sites web",
                    level_color: "grey",
                    count:0
                },
            ],
        }
    },
    computed: {
        groupedApps() {
            return this.apps.reduce((groups, app) => {
                if (!groups[app.level]) {
                    groups[app.level] = [];
                }
                groups[app.level].push(app);
                return groups;
            }, {});
        },
    },
    created() {},
    methods: {
        switchView() {
            this.cards = !this.cards;
        },
        getLevelName(level) {
            const levelObj = this.apps_level.find((l) => l.level === parseInt(level, 10));
            return levelObj ? levelObj.name : `Niveau ${level}`;
        },
        goToAddress(item) {
            if (item.address) {
                window.open(item.address, "_blank");
            } else {
                alert("Aucune adresse disponible pour cet élément.");
            }
        },
    }
}


</script>
