<template>

    <div class="layer" ref="layer">

        <v-img aspect-ratio="1" :src="assetsData.path_image" contain :height="layerHeight" :width="layerWidth">
            <template v-slot:placeholder>
                <v-row
                    class="fill-height ma-0 background-loader-image"
                    align="center" justify="center"
                >
                    <v-progress-circular indeterminate color="black lighten-5" v-if="no_path_image === false"/>

                    <p class="ma-0" v-else>
                        {{ $t('global.no_image') }}
                    </p>
                </v-row>
            </template>
        </v-img>

        <drag-zone v-for="(a, index) in assetsData.assets" :ref="`asset${a.id}`"
                   :key="index"
                   class="layer--device"
                   :data-index="index"
                   :parent='true'
                   :resizable='false'
                   :rotatable='false'
                   :draggable='false'
                   :active='true'
                   :value="{ x: convertPosition(a.position_x, 'x', false), y: convertPosition(a.position_y, 'y', false), width:20, height: 20, rotation: 0 }"
                   v-if="!a.is_hide"
        >
            <v-tooltip bottom :color="assetColor(a)">
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        :color="assetColor(a)"
                        v-bind="attrs"
                        v-on="on"
                        @click="assetsDetailRedirection(assetType,a)"
                    >
                        mdi-brightness-1
                    </v-icon>
                </template>
                <v-chip
                    :color="assetColor(a)"
                    text-color="white"
                    justify="center"
                >
                    {{ a.label }}
                </v-chip>
            </v-tooltip>
        </drag-zone>
    </div>

</template>

<script>
import DragZone from 'yoyoo-ddr';
import 'yoyoo-ddr/dist/yoyoo-ddr.css'

export default {
    name: "LayerAssets",
    components: {
        DragZone
    },
    props: {
        assetsData: {
            type: Object,
            required: true,
            default: {},
        },
        assetType: {
            type: String,
            required: false,
            default: () => '',
        },
    },
    data() {
        return {
            no_path_image: false,
            layerWidth: 350,
            layerHeight: 350,
        };
    },
    methods: {
        convertPosition(value, type, reverse) {
            let ref, pos;
            if (type === 'x') {
                ref = this.layerWidth;
            } else if (type === 'y') {
                ref = this.layerHeight;
            } else {
                ref = type;
            }
            if (reverse) {
                pos = (value / ref) * 100;
            } else {
                pos = (value * ref) / 100;
            }
            return pos;
        },

        update() {
            this.$forceUpdate();
        },

        assetsDetailRedirection(assetType,a) {
            if(this.$store.state.access_level !== 300 && this.$store.state.access_level !== 400){
                this.$router.push({ name: assetType, params: {id: a.id}})
            }
        }
    },
    mounted() {
        this.$nextTick(function () {
            setTimeout(() => {
                this.no_path_image = true;
            }, 5000);
        });
        if (window.screen.width > 1000) {
            this.layerWidth = 600;
            this.layerHeight = 400;
        } else {
            this.layerWidth = 320;
            this.layerHeight = 320;
        }
    },
}
</script>

<style scoped lang="scss">
.layer {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    display: flex;

    &--device {
        position: absolute;
        top: 50%;
        left: 50%;
    }
}

.yoyoo-ddr {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transition: all .2s ease-out;

    i {
        width: 20px;
        height: 20px;
        font-size: 20px;
        transition: all .2s ease-out;
        border-radius: 50%;
        cursor: pointer;
    }

    &.active {
        border: none !important;
    }

    &:hover {
        i {
            font-size: 24px;
            animation: bloom infinite 1.5s;
        }
    }
}

@keyframes bloom {
    50% {
        box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.3);
    }
}
</style>
