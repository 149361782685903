import moment from 'moment';
import {METRICS} from "../api";

export default {
    data: () => ({
        snackbar: false,
        snackbar_text: '',
        snackbar_type: null,
        installer_interface_switch: false,
        invoicing_periods: [12, 24],
        configurationFile: {
            headers: {
                'content-type': 'multipart/form-data',
            }
        },
        loading_csv_file: false,
        global_regex: /^(?<before>.*\n)?DEV_EUI:(?<devEUI>[a-zA-Z0-9]{16})(\n.*)?\n(?<between>.*\n)?APP_KEY:(?<appKey>[a-zA-Z0-9]{32})(\n.*)?$/gm,
        gateway_regex: /[0-9a-fA-F]{1}[0-9a-fA-F]+/gm,
    }),
    computed: {
        is_mobile() {
            let isMobile = false;
            if (this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs) {
                isMobile = true;
            }

            return isMobile;
        },
    },
    methods: {
        moment(date, format) {
            return moment(date, format);
        },

        showSnackbar(type, text) {
            //Will be remove after, old version of displaying snackbar
            this.snackbar_type = type;
            this.snackbar_text = text;
            this.snackbar = true;
        },

        showGlobalSnackbar(type, text) {
            this.$store.dispatch('snackbar/showSnackbar', {type_snackbar: type, text_snackbar: text});
        },

        manageError(errorCodeHttp, errorGlobal, errorNotFound) {
            let snackbarType = 'error';
            let snackbarText = errorGlobal;

            if(errorCodeHttp === 403) {
                snackbarText = this.$t('error_display.authorization_required');
                this.logout();
            }

            if(snackbarText === undefined) {
                switch (errorCodeHttp) {
                    case 0:
                        snackbarType = 'warning';
                        break;
                    case 400:
                        snackbarText = this.$t('error_display.error_bad_request');
                        break;
                    case 401:
                        snackbarType = 'warning';
                        snackbarText = this.$t('login.login_failed');
                        break;
                    case 404:
                        snackbarType = 'warning';
                        snackbarText = (errorNotFound ? errorNotFound : this.$t('error_display.entity_not_found'));
                        break;
                    case 408:
                        snackbarText = this.$t('error_display.error_time_out');
                        break;
                    case 500:
                        snackbarText = this.$t('error_display.error_internal_server');
                        break;
                    case 502:
                        snackbarText = this.$t('error_display.error_bad_gateway_error');
                        break;
                    case 503:
                        snackbarText = this.$t('error_display.error_extern_api_connection');
                        break;
                }
            }


            this.$store.dispatch('snackbar/showSnackbar', {type_snackbar: snackbarType, text_snackbar: snackbarText});
        },

        getStateOperationLabel(id_state) {
            let state = "";

            switch (id_state) {
                case 1:
                    state = this.$t('operation.to_realized');
                    break;
                case 2:
                    state = this.$t('operation.in_progress');
                    break;
                case 3:
                    state = this.$t('operation.block');
                    break;
                case 4:
                    state = this.$t('operation.close');
                    break;
                default:
                    state = this.$t('operation.undefined_state');
            }
            return state;
        },

        async prepareDownloadCsvFile(url, fileName) {
            await this.axios({
                url: url,
                method: 'GET',
                responseType: 'blob',
            }).then((success) => {
                this.downloadCsvFile(success.data, fileName);
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('global.failure_to_download'));
            });
        },

        downloadCsvFile(data, fileName) {
            let blob = new Blob([data]);
            let link = document.createElement('a');

            link.href = window.URL.createObjectURL(blob);
            link.download = moment().format("YYYYMMDD") + fileName;

            link.click();
        },

        assetColor(asset) {
            let color = 'green';
            if (asset.alert_level === '2') {
                color = 'red';
            } else if (asset.alert_level === '1') {
                color = '#FFCB05';
            } else if (asset.alert_level === '3') {
                color = '#e90eef';
            }

            return color;
        },

        countActiveContracts(contract_types) {
            let cpt = 0;
            contract_types.forEach((contract) => {
                if (contract.is_active > 0) {
                    cpt += 1;
                }
            });
            return cpt;
        },

        checkGatewayAndPhysicalDevicesStates(gadget) {
            let state = '';
            switch (gadget) {
                case 1:
                    state = this.$t('gateways_datatable.gateway_in_storage_state');
                    break;
                case 3:
                    state = this.$t('gateways_datatable.gateway_on_operation_state');
                    break;
                case -3:
                    state = this.$t('gateways_datatable.gateway_scrapped_state');
                    break;
                case -1:
                    state = this.$t('gateways_datatable.gateway_in_maintenance_state');
                    break;
                case 2:
                    state = this.$t('gateways_datatable.gateway_at_the_installer_state');
                    break;
                case 4:
                    state = this.$t('gateways_datatable.gateway_to_test_state');
                    break;
                default:
                    state = this.$t('gateways_datatable.gateway_lost_state');
            }
            return state;
        },

        checkGatewayAndPhysicalDevicesOwnership(user, state_number) {
            let owner = '';
            let state = this.checkGatewayAndPhysicalDevicesStates(state_number);

            if (user != null) {
                owner = user.first_name + ' ' + user.last_name;
            } else {
                if (state === this.$t('gateways_datatable.gateway_lost_state')) {
                    owner = this.$t('global.not_found');
                } else
                    owner = 'TechView';
            }
            return owner;
        },

        installerInterfaceSwitch() {
            let switchState = this.$store.state.switch_state;
            let redirection = switchState === false ? 'dashboard' : 'dashboard_admin';
            this.installer_interface_switch = false;
            this.$store.commit('setSwitchState', !switchState)
            this.$router.push({name: redirection});
        },

        dataExtraction(input) {
            let result = [];
            if (input !== null) {
                input.forEach((item) => {
                    let match = (/^(?<before>.*\n)?DEV_EUI:(?<devEUI>[a-zA-Z0-9]{16})(\n.*)?\n(?<between>.*\n)?APP_KEY:(?<appKey>[a-zA-Z0-9]{32})(\n.*)?$/gm).exec(item);

                    if (match) {
                        result.push({
                            'DEV_EUI': match.groups.devEUI,
                            'APP_KEY': match.groups.appKey
                        });
                    }
                });
            }

            return result;
        },

        logout() {
            this.$store.commit('setSwitchState', false);
            this.$auth.logout();
        },

        lastEmissionAttributeTranslation(last_seen_date) {
            if (last_seen_date !== null) {
                const now = new Date();
                let diff = Math.floor(((now.getTime() + (now.getTimezoneOffset() * 60 * 1000)) - new Date(last_seen_date).getTime()) / 1000);
                let output = {
                    color: '',
                    time: '',
                }

                if (diff <= 86400 && diff >= 0) {
                    output.color = 'green lighten-1';
                    if (diff <= 60) {
                        output.time = this.$t('global.elapsed_time') + diff + ' ' + (diff === 1 ? this.$t('global.second') : this.$t('global.seconds'));
                    }
                    // if the number of seconds is lesser than 1 hour, the output is per minutes
                    else if (diff <= 3600 && diff > 60) {
                        output.time = this.$t('global.elapsed_time') + Math.round((diff / 60)) + ' ' + (Math.round((diff / 60)) === 1 ? this.$t('global.minute') : this.$t('global.minutes'));
                    }
                    // if the number od seconds is lesser than 1 day ,the output is per hours
                    else if (diff > 3600) {
                        output.time = this.$t('global.elapsed_time') + Math.round((diff / 3600)) + ' ' + (Math.round((diff / 36000)) === 1 ? this.$t('global.hour') : this.$t('global.hours'));
                    }
                } else if (diff > 86400 && diff <= 259200) { // between 1 day and 3 days
                    output.color = 'orange';
                    output.time = this.$t('global.elapsed_time') + Math.round((diff / 86400)) + ' ' + (Math.round((diff / 86400)) === 1 ? this.$t('global.day') : this.$t('global.days'));
                } else if (diff > 259200) {
                    output.color = 'red lighten-1';
                    if (diff <= 2678400) {
                        output.time = this.$t('global.elapsed_time') + Math.round((diff / 86400)) + ' ' + (Math.round((diff / 86400)) === 1 ? this.$t('global.day') : this.$t('global.days'));
                    } else if (diff <= 32140800) {
                        output.time = this.$t('global.elapsed_time') + Math.round((diff / 2678400)) + ' ' + this.$t('global.months');
                    } else {
                        output.time = this.$t('global.elapsed_time') + "+12" + this.$t('global.months');
                    }
                }

                return output;
            }
        },

        associatedPhysicalDeviceCount(contract_id) {
            this.axios.get(VIRTUAL_DEVICES + '/' + contract_id + '/associated').then((success) => {
                return success.data
            })
                .catch(() => {
                    this.showSnackbar('error', this.$t('global.error'))
                })
        },

        fromUtcDateConversion(array) {
            array = array.map((sample) => {
                return {
                    date: moment.parseZone(sample.date).local().format('YYYY-MM-DDTHH:mm:ss'),
                    value: sample.value
                }
            });
            return array;
        },

        calculInterval(intervalDate, points_number) {
            const IntervalBetweenPoint = Math.round(intervalDate / points_number);
            let interval;

            if ((IntervalBetweenPoint / 86400000) >= 1) {
                interval = Math.round(IntervalBetweenPoint / 86400000) + 'd';
            } else if ((IntervalBetweenPoint / 3600000) >= 1) {
                interval = Math.round(IntervalBetweenPoint / 3600000) + 'h';
            } else if ((IntervalBetweenPoint / 60000) >= 1) {
                interval = Math.round(IntervalBetweenPoint / 60000) + 'm';
            } else if ((IntervalBetweenPoint / 1000) >= 1) {
                interval = Math.round(IntervalBetweenPoint / 1000) + 's';
            } else {
                interval = Math.round(IntervalBetweenPoint) + 'ms';
            }
            return interval
        },

        uploadFilesCheck(doc, authorizedTypes, maxSize) {
            let errorMessage = '';
            let authorizedUpload = true;
            if (!authorizedTypes.includes(doc.type)) {
                errorMessage = this.$t('global.authorized_file_extension') + ' ' + authorizedTypes.map(type => {
                    return type.match(/\b\/.*/img).toString().substring(1).toUpperCase()
                }).toString();
                authorizedUpload = false;
            }
            if ((doc.size / (1024 * 1024)) > maxSize) {
                errorMessage = this.$t('global.max_length') + ' ' + maxSize.toString() + ' MO';
                authorizedUpload = false;
            }
            return {
                output: errorMessage,
                authorized: authorizedUpload
            }
        },

        async getMetricDetails(metric_uuid) {
            let metric = null;
            await this.axios.get(METRICS + '/' + metric_uuid + '/details').then((success) => {
                metric = success.data
            }).catch(() => {
                return metric
            });
            return metric
        }
    }
};
