<template>
    <v-col class="col col-12 mt-0 pt-0 pa-0" style="margin-top:0px!important;padding:0px;position:relative; top:-12px;">
        <!-- MACHINE MENU PACKAGE -->
        <div class="pl-3 pr-3" id="menuPackage">
            <v-col class="pl-5 pr-5" style="background-color:#001047;color:#FFFFFF;border-radius: 0px 0px 5px 5px;">
                <!-- TOP TITLE -->
                <v-row class="pl-0 pt-5 mb-5" style="height: 52px; padding:5px;">
                    <!-- MACHINE NAME -->
                    <v-col class="row mb-0 pa-0 mt-1 col-auto" @click="infoToggler()">
                        <!-- COUNTER -->
                        <v-col color="white" class="col-auto pa-0 mr-5" v-if="machine">
                            <v-col class="col pt-0 pb-0 mr-10 rounded col ml-6" style="height: 35px;background-color: rgb(92 101 123);font-weight: bold;text-align: center;font-size: 16px;border: 1px solid #ffffff5c;line-height: 35px;color: #FFFFFF;">
                                {{ machine.label }}
                            </v-col>
                        </v-col>
                    </v-col>
                    
                    <!-- PAGE TITLE -->
                    <v-subheader class="v-subheader col theme--light pl-3 ml-2">
                        <p class="title mb-0 color-white" style="color: white!important;">
                            <div style="color:#FFFFFFb3;margin-right:4px;margin-left:4px;"> Equipements > {{ machine.label }} > </div><div @click="infoToggler()" style="color:#FFFFFF;font-weight:bold;"> Rapport  n° {{ report_position }} ({{ report.released_at }})</div>
                        </p>
                    </v-subheader>

                    <!-- GLOBAL ACTIONS -->
                    <v-col class="row mt-1 col-auto pa-0 pr-5">
                        <v-tooltip bottom dark class="mr-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="getData()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                    <v-icon color="white">mdi-tag-multiple</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('global.1_5_2') }}</span>
                        </v-tooltip>
                        <v-tooltip bottom dark class="mr-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="getData()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                    <v-icon color="white">mdi-refresh</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('machines.refresh') }}</span>
                        </v-tooltip>
                        
                        <v-tooltip v-if="infoToggle == true" bottom dark class="mr-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="infoToggler()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                    <v-icon color="white">mdi-minus-box-multiple</v-icon>
                                </v-btn>
                            </template>
                            <span>Replier les informations machine</span>
                        </v-tooltip>

                        <v-tooltip v-if="infoToggle == false" bottom dark class="mr-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="infoToggler()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                    <v-icon color="white">mdi-plus-box-multiple</v-icon>
                                </v-btn>
                            </template>
                            <span>Déplier les informations machine</span>
                        </v-tooltip>
                    </v-col>
                </v-row>

                <!-- MACHINE INFOS -->
                <v-col class="mb-4" v-if="infoToggle == true" style="min-height: 250px; background-color:#e8edff;border-radius:5px;">
                    <v-row>
                        <v-col class="col-4">
                            <machine-state/>
                        </v-col>
                        <v-col class="col pl-0">
                            <machine-specifications v-on:updateLabelMachine="updateLabelMachine"/>
                            <v-row>
                                <v-col class="col">
                                    <machine-subassemblies/>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>

                <!-- MACHINE LINKS -->
                <v-row style="border-top: 1px solid #ffffff1c;">
                    <v-row style="margin:0px;">
                        <v-row class="ml-5 pt-5 pb-5 rounded" style="">
                            <!-- ALERTS -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + machine.id + '/events'}" dusk="logout-button">
                                        <v-icon color="white">mdi-alert</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.alerts') }}</span>
                            </v-tooltip>

                            <!-- REPORTS -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + machine.id + '/report'}" dusk="logout-button">
                                        <v-icon color="white">mdi-chart-box</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.reports') }}</span>
                            </v-tooltip>

                            <!-- DETAILS -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + machine.id + '/details'}" dusk="logout-button">
                                        <v-icon color="white">mdi-chart-line</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.courbes') }}</span>
                            </v-tooltip>

                            <!-- SANDBOX -->
                            <v-tooltip bottom dark class="mr-2" v-if="$store.state.access_level <= 100">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" class="mr-5 col-0" :to="{ path: '/machine/' + machine.id + '/sandbox'}" dusk="logout-button">
                                    <v-icon color="red">mdi-vector-curve</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('machines.sandbox') }}</span>
                        </v-tooltip>

                            <!-- Documents -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + machine.id + '/documents'}" dusk="logout-button">
                                        <v-icon color="white">mdi-file-document</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machines.documents') }}</span>
                            </v-tooltip bottom dark class="mr-2">
                            
                            <!-- SETTINGS -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + machine.id + '/settings'}" dusk="logout-button">
                                        <v-icon color="white">mdi-cog</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.settings') }}</span>
                            </v-tooltip bottom dark class="mr-2">
                        </v-row>  

                        <!-- Menu déroulant pour choisir les rapports -> Potentiel champ rechercher pour la suite
                        <v-row id="report-linker" class="col-6 justify-end pt-2 pr-8" >
                            
                            <v-select
                                :reports="reports"
                                label="Sélectionner un rapport"
                                v-model="reportSelection"
                                outlined
                            ></v-select>
                            <p>{{ reportSelection }}</p>
                            <v-btn :disabled="isLoading" @click="calculateHeight" class="ml-2">
                                <v-icon color="primary">{{ isLoading ? 'mdi-loading' : 'mdi-printer' }}</v-icon>
                            </v-btn>
                        </v-row>   
                            -->
                    </v-row>
                </v-row>
            </v-col>
        </div>   

        <!-- CONTENT -->
        <div class="row mt-0" style="background-color: transparent !important;" id="content">
            <!-- REPORT VIEW -->
            <v-col color="white" class="pa-6 pb-0 pt-3 col">
                <div v-if="loading_datatable == true"  style="color:grey;text-align: center;">
                    <li class="rounded row notification_card mb-1 pa-10 mt-15 ml-6" style="color: grey;justify-content: center;">
                        <div style="color:grey;text-align: center;"><strong>Chargement en cours...</strong></div>
                    </li>
                </div>            
                
                <div v-if="loading_datatable == false" flat color="white" class="pt-0 mb-3">
                    <!--switches-->
                    <v-row v-if="report_nb > 1"class="ma-0 pa-0 mb-3" style="max-height:45px;overflow:hidden;">
                        <v-row v-if="report_position == 1" class="ma-0 pa-0 col" style="max-width:175px;align-items:center;">
                            <v-tooltip bottom dark class="mr-4">
                                <template v-slot:activator="{ on }">
                                    <v-btn text icon v-on="on" class="col col" type="submit" color="white" dusk="logout-button" 
                                        style=" text-transform: unset !important;
                                                background-color: #00000014;
                                                border-radius: 5px;
                                                color: #FFFFFF!important;
                                                border-radius: 5px;
                                                font-size:12px;
                                                letter-spacing: 0;
                                                font-weight:400;"   
                                    >
                                        <v-icon color="white" class="mr-1" left>mdi-chevron-left</v-icon> Rapport précédent
                                    </v-btn>
                                </template>
                                
                                <span>Aucun rapport précédent disponible</span>
                            </v-tooltip>
                        </v-row>

                        <v-row v-if="report_position != 1" class="ma-0 pa-0 col" style="max-width:175px;align-items:center;">
                            <v-tooltip bottom dark class="mr-4">
                                <template v-slot:activator="{ on }">
                                    <v-btn text icon v-on="on" class="col col" type="submit" color="white" dusk="logout-button"
                                        style=" text-transform: unset !important;
                                                background-color: #0000004d;
                                                border-radius: 5px;
                                                color: #FFFFFF!important;
                                                border-radius: 5px;
                                                font-size:12px;
                                                letter-spacing: 0;
                                                font-weight:400;"  
                                        @click="switchReport(previous_id)"
                                    >
                                        <v-icon color="white" class="mr-1" left>mdi-chevron-left</v-icon> Rapport précédent
                                    </v-btn>
                                </template>
                                <span v-if="previous_date != null">Voir le rapport précédent datant du {{previous_date}} (EDS {{previous_indice}}%)</span>
                                <span v-else>Voir le rapport précédent (actuellement en cours d'édition)</span>
                            </v-tooltip>
                        </v-row>

                        <v-row class="ma-0 pa-0 col" style="align-items: center;justify-content: center;font-weight: bold;color: #0000007a;">
                        </v-row>

                        <v-row v-if="report_position == report_nb" class="ma-0 pa-0 col" style="max-width:175px;align-items:center;">
                            <v-tooltip bottom dark class="mr-4">
                                <template v-slot:activator="{ on }">
                                    <v-btn text icon v-on="on" class="col col" type="submit" color="white" dusk="logout-button"
                                        style=" text-transform: unset !important;
                                                background-color: #0000004d;
                                                border-radius: 5px;
                                                color: #FFFFFF!important;
                                                border-radius: 5px;
                                                font-size:12px;
                                                letter-spacing: 0;
                                                font-weight:400;" 
                                        @click="switchReport(next_id)"
                                    >                                        
                                        Rapport suivant <v-icon color="white" class="ml-1" left>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </template>
                                <span>Aucun rapport précédent disponible</span>
                            </v-tooltip>
                        </v-row>

                        <v-row v-if="report_position < report_nb" class="ma-0 pa-0 col" style="max-width:175px;align-items:center;">
                            <v-tooltip bottom dark class="mr-4">
                                <template v-slot:activator="{ on }">
                                    <v-btn text icon v-on="on" class="col col" type="submit" color="white" dusk="logout-button"
                                        style=" text-transform: unset !important;
                                                background-color: #0000004d;
                                                border-radius: 5px;
                                                color: #FFFFFF!important;
                                                border-radius: 5px;
                                                font-size:12px;
                                                letter-spacing: 0;
                                                font-weight:400;"  
                                        @click="switchReport(next_id)"
                                    >                                        
                                        Rapport suivant <v-icon color="white" class="ml-1" left>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </template>
                                <span v-if="next_date != null">Voir le rapport suivant datant du {{next_date}} (EDS {{next_indice}}%)</span>
                                <span v-else>Voir le rapport suivant (actuellement en cours d'édition)</span>
                            </v-tooltip>
                        </v-row>
                    </v-row> 

                    <div v-if="report.released_at == null && access_level > 99">
                        <!--CHIPS NO ALERT-->
                        <div class="ma-0 pa-0" id="ChipsNoAlert"> 
                            <div style="height:150px;border-radius:5px;background-color:white;" class="mt-3 pa-0">
                                <div class="col-0" style="color:black;font-weight:800;display:flex;justify-content:center; height:100%;min-width: 8px;border-radius:5px;" >
                                    <v-col class="pa-0" style="display:flex;justify-content:center;align-items:center;">
                                        <div style="text-align:center;">  
                                            <v-icon color="primary" style="height:36px;font-size:36px;">
                                                mdi-file-chart
                                            </v-icon>
                                            <br>
                                            <div class="pa-3 pb-0">
                                                Ce rapport est en cours d'édition !
                                            </div>
                                            <p class="pb-0" style="font-weight:400">
                                                Vous recevrez une notification dès qu'il sera publié.
                                            </p>
                                        </div>
                                    </v-col>
                                </div>
                            </div>
                        </div> 
                    </div>

                    <div v-else>
                        <!--CHIPS-->
                        <div v-for="filters in machine_filters" :key="filters" style="max-height:90px;border-radius:5px;overflow:hidden;">
                            <div  v-if="filters.level == report.alert_level && filters.level_name !== 'Tout voir'" :to="{ path: '/campain/' + campain.id}" class="col-0" style="color:white;font-weight:800;display:flex;justify-content:center;text-shadow:2px 2px 4px rgba(0, 0, 0, 0.29); height:100%;min-width: 8px;border-radius:5px;" >
                                <v-row class="pa-0 ma-0">
                                    <v-col class="col pa-0" style="max-width:30%;display:flex;flex-direction:column;" :style="{backgroundColor: filters.level_color}">
                                        <v-row style="align-items:center;width:100%;position:relative;top:0px;padding-left: 35px;">
                                            <v-col class="col-5" style="font-size:50px;font-weight:bold;text-align:right;">{{ report.indice }}%</v-col>
                                            <v-col style="font-size:13px;text-align:left;font-weight: 400;align-items:center;">
                                                <div  v-if="report_position == 1 && report_position == report_nb">Rapport initial (seul rapport existant)</div>
                                                <div  v-if="report_position == 1 && report_position != report_nb">Rapport initial (sur {{ report_nb }} existants)</div> 
                                                <div  v-if="report_position > 1 && report_position == report_nb">Rapport n°{{ report_position }} (dernier en date)</div>
                                                <div  v-if="report_position > 1 && report_position != report_nb">Rapport n°{{ report_position }} (sur {{ report_nb }} existants)</div>
                                                <div  v-if="report.released_at != null">Publié le {{ report.released_at }}</div> 
                                                <div  v-if="report.released_at == null">Ce rapport n'a pas encore été publié.</div> 
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col class="col pa-3" style="max-width:70%;background-color:white;height:90px;display:flex;flex-direction:column;justify-content: center;text-shadow:none;">
                                        <div v-for="state in machine_filters" :key="state">
                                            <div v-if="state.percent === report.indice" style="font-size:18px;color:black;padding-left: 20px;"> {{ state.annotation }}</div>
                                            <div v-if="state.percent === report.indice && report_position == 1" style="font-size:14px;color:black;padding-left: 20px;font-weight:400;display:flex;"> <div style="color:#0e4cef;font-weight:bold;margin-right:5px;">* </div> Il s'agit du premier rapport enregistré sur cette application pour ce moyen.</div>
                                            <div v-if="state.percent === report.indice && previous_indice < report.indice && report_position != 1" style="font-size:14px;color:black;padding-left: 20px;font-weight:400;display:flex;"> <div style="color:green;font-weight:bold;margin-right:5px;">↗ </div> Evolution positive depuis le {{previous_date}} (EDS {{previous_indice}}%)</div>
                                            <div v-if="state.percent === report.indice && previous_indice == report.indice && report_position != 1" style="font-size:14px;color:black;padding-left: 20px;font-weight:400;display:flex;"> <div style="color:black;font-weight:bold;margin-right:5px;">= </div> Pas d'évolution depuis le {{previous_date}} (EDS {{previous_indice}}%)</div>
                                            <div v-if="state.percent === report.indice && previous_indice > report.indice && report_position != 1" style="font-size:14px;color:black;padding-left: 20px;font-weight:400;display:flex;"> <div style="color:red;font-weight:bold;margin-right:5px;">↘ </div>Evolution négative depuis le {{previous_date}} (EDS {{previous_indice}}%)</div>
                                        </div>
                                    </v-col>
                                    <v-col class="col-2 pa-3" style="max-width:70%;background-color:white;height:90px;display:flex;flex-direction:column;justify-content: center;text-shadow:none;align-items:center;">
                                        <v-row style="align-items: center;">
                                            <v-row class="col mr-8" style="justify-content: start;
                                                    display: flex;
                                                    align-items: center;"
                                            >
                                                <div v-if="report.presta == 0">
                                                    <div style="font-size: 20px;
                                                                border: 1px solid grey;
                                                                border-radius: 50px;
                                                                padding: 10px;
                                                                color: grey;
                                                                margin-right: 15px;"
                                                    >VIB</div>
                                                </div>
                                                <div v-if="report.presta == 1">
                                                    <div style="font-size: 20px;
                                                                border: 1px solid grey;
                                                                border-radius: 50px;
                                                                padding: 10px;
                                                                color: grey;
                                                                margin-right: 15px;"
                                                    >TH</div>
                                                </div>
                                                <div v-if="report.presta == 2">
                                                    <div style="font-size: 20px;
                                                                border: 1px solid grey;
                                                                border-radius: 50px;
                                                                padding: 10px;
                                                                color: grey;
                                                                margin-right: 15px;"
                                                    >US</div>
                                                </div>
                                                <div v-if="report.presta == 3">
                                                    <div style="font-size: 20px;
                                                                border: 1px solid grey;
                                                                border-radius: 50px;
                                                                padding: 10px;
                                                                color: grey;
                                                                margin-right: 15px;"
                                                    >EQ</div>
                                                </div>
                                                <div v-if="report.presta == 4">
                                                    <div style="font-size: 20px;
                                                                border: 1px solid grey;
                                                                border-radius: 50px;
                                                                padding: 10px;
                                                                color: grey;
                                                                margin-right: 15px;"
                                                    >EXP</div>
                                                </div>
                                                <div v-if="report.presta == 5">
                                                    <div style="font-size: 20px;
                                                                border: 1px solid grey;
                                                                border-radius: 50px;
                                                                padding: 10px;
                                                                color: grey;
                                                                margin-right: 15px;"
                                                    >OL</div>
                                                </div>

                                                <v-icon v-if="report.type == 0 || report.type == 3" class="pointer mb-2"
                                                        v-bind="attrs" v-on="on"
                                                        style="font-size:60px;color: #c3c3c3;margin-bottom: 0px !important;"
                                                >
                                                    mdi-file-pdf
                                                </v-icon>

                                                <v-icon v-if="report.type == 1" class="pointer mb-2"
                                                        v-bind="attrs" v-on="on"
                                                        style="font-size:60px;color: #c3c3c3;margin-bottom: 0px !important;"
                                                >
                                                    mdi-file-document
                                                </v-icon>
                                            </v-row>

                                            <v-tooltip bottom dark class="mr-2">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn text icon v-on="on" class="mr-2" dusk="logout-button">
                                                        <v-icon color="#b1b1b1">mdi-eye</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span style="opacity:100%;">
                                                    <highcharts :options="lineChartOptions"></highcharts>
                                                </span>
                                            </v-tooltip>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>  
                        
                        <!--  DIGITAL REPORT CONTENT -->
                        <v-card v-if="report.type === 1" class="mt-3" style="margin-bottom:50px;">
                            <validation-observer ref="observer" v-slot="{ invalid }">
                                <v-card-text class="pa-0">
                                    <v-col>

                                        <v-row class="pt-0" style="margin-bottom: 0px;">
                                            <!-- OK REPORT CONTEXT -->
                                            <div class="pt-4 pa-3 pr-0 pt-0" style="width:30%;background-color: #000000ba;color: white;border-radius: 5px 0px 0px 0px;">
                                                <v-row class="ma-0 pa-0" style="justify-content: space-between;">
                                                    <h4 class="pa-5 pt-2 pb-0 ml-1">Contexte de l'intervention</h4>
                                                    <div class="mt-0 pb-0" v-if="report.context == '' || report.context == null" style="background-color: #83838387; border-radius:5px 0px 0px 5px;height:40px; width:40px;display:flex;justify-content:center;">
                                                        <v-icon color="white">mdi-text-box</v-icon>
                                                    </div>
                                                    <div class="mt-0 pb-0" v-if="report.context != '' && report.context != null" style="background-color: #83838387; border-radius:5px 0px 0px 5px;height:40px; width:40px;display:flex;justify-content:center;">
                                                        <v-icon color="white">mdi-text-box</v-icon>
                                                    </div>
                                                </v-row>
                                                <br>
                                                <p class="pa-8 pt-4" style="color:#b9b9b9;font-size:13px;">
                                                    Le contexte de l'intervention permet de founir des détails à l'analyste pour établir le rapport.<br><br>
                                                    Toute information reste importante et doit être spécifiée comme par exemple les vitesses de rotation ou l'incapacité de mesurer un sous-ensemble.
                                                </p>
                                            </div>
                                            <div class="mt-0 pa-8" style="width:70%;background-color: #00000005;">
                                                <div id="editor0"v-html="report.context" ref="editor" class="col col"></div>
                                            </div>
                                        </v-row>

                                        <v-row class="pt-4 mb-4 mt-0">
                                            <!-- REPORT PRECO -->
                                            <div class="mt-4 pa-3 pr-0 pt-0" style="width:30%;border-right:1px solid rgba(0, 0, 0, 0.22)">
                                                <v-row class="ma-0 pa-0" style="justify-content: space-between;">
                                                    <h4 class="pa-5 pt-2 pb-0 ml-1">Préconisations de maintenance</h4>
                                                    <div class="mt-0 pb-0" v-if="report.preco == '' || report.preco == null" style="background-color: #56565678; border-radius:5px 0px 0px 5px;height:40px; width:40px;display:flex;justify-content:center;">
                                                        <v-icon color="white">mdi-text-box</v-icon>
                                                    </div>
                                                    <div class="mt-0 pb-0" v-if="report.preco != '' && report.preco != null" style="background-color: #56565678; border-radius:5px 0px 0px 5px;height:40px; width:40px;display:flex;justify-content:center;">
                                                        <v-icon color="white">mdi-text-box</v-icon>
                                                    </div>
                                                </v-row>
                                                <br>
                                                <p class="pa-8 pt-4" style="color:grey;font-size:13px;">
                                                    Les préconisations permettent de référencer les défauts et actions de maintenance à réaliser sur la globalité de la machine.<br><br>
                                                    Chaque sous ensemble nécéssitant une action doit y être mentionné comprenant la sévérité (code couleur) du défaut associé et l'action préconisée.
                                                </p>
                                            </div>
                                            <div class="mt-0 pa-8" style="width:70%">
                                                <div id="editor1" v-html="report.preco" class="col col"></div>
                                            </div>
                                        </v-row>

                                        <v-row v-if="access_level <= 99" class="pt-0 mt-0" style="margin-bottom: 0px;background-color: #fbfbfb;">
                                            <!-- OK REPORT DEFAULTS -->
                                            <div class="mt-4 pa-3 pr-0 pt-0" style="width:30%;border-right:1px solid rgba(0, 0, 0, 0.22)">
                                                <v-row class="ma-0 pa-0" style="justify-content: space-between;">
                                                    <h4 class="pa-5 pt-2 pb-0 ml-1">Défauts mécaniques relevés</h4>
                                                    <div class="mt-0 pb-0" v-if="report.defaults == '' || report.defaults == null" style="background-color: #56565678; border-radius:5px 0px 0px 5px;height:40px; width:40px;display:flex;justify-content:center;">
                                                        <v-icon color="white">mdi-text-box</v-icon>
                                                    </div>
                                                    <div class="mt-0 pb-0" v-if="report.defaults != '' && report.defaults != null" style="background-color: #56565678; border-radius:5px 0px 0px 5px;height:40px; width:40px;display:flex;justify-content:center;">
                                                        <v-icon color="white">mdi-text-box</v-icon>
                                                    </div>
                                                </v-row>
                                                <br>
                                                <p class="pa-8 pt-4" style="color:grey;font-size:13px;">
                                                    Cette section indique le cheminement de l'analyse<br><strong>et reste non visible par le client final.</strong><br><br>
                                                    Par défaut pour une compréhension de la réflexion, veuillez noter un maximum d'informations et remarque sur ce qui est constaté. Il peut être pertinent de faire un retour global sur l'ensemble des défauts à relever.<br>
                                                    Le contenu est pré-renseigné, cela vous permet d'utiliser une base pour développer votre analyse.
                                                </p>
                                            </div>
                                            <div class="mt-0 pa-8" style="width:70%">
                                                <div id="editor0" v-html="report.defaults" ref="editor" class="col col"></div>
                                            </div>
                                        </v-row>

                                        <v-row class="pt-4 mt-0 mb-4" style="margin-bottom: 0px;">
                                            <!-- OK REPORT COMMENTS -->
                                            <div class="mt-4 pa-3 pr-0 pt-0" style="width:30%;border-right:1px solid rgba(0, 0, 0, 0.22)">
                                                <v-row class="ma-0 pa-0" style="justify-content: space-between;">
                                                    <h4 class="pa-5 pt-2 pb-0 ml-1">Complément d'informations</h4>
                                                    <div class="mt-0 pb-0" v-if="report.comment == '' || report.comment == null" style="background-color: #56565678; border-radius:5px 0px 0px 5px;height:40px; width:40px;display:flex;justify-content:center;">
                                                        <v-icon color="white">mdi-text-box</v-icon>
                                                    </div>
                                                    <div class="mt-0 pb-0" v-if="report.comment != '' && report.comment != null" style="background-color: #56565678; border-radius:5px 0px 0px 5px;height:40px; width:40px;display:flex;justify-content:center;">
                                                        <v-icon color="white">mdi-text-box</v-icon>
                                                    </div>
                                                </v-row>
                                                <br>
                                                <p class="pa-8 pt-4" style="color:grey;font-size:13px;">
                                                    Cette section vous permet <strong> d'informer le client final sur les détails de votre analyse</strong>.<br><br>
                                                    Dans cette encart soyez factuels et adoptez une approche vulgarisatrice pour permettre à tous types de lecteurs de comprendre votre commentaire.<br>
                                                    Pour plus de précision et si besoin n'hésitez pas à compléter vos propos par quelques prises de vues.
                                                </p>
                                            </div>
                                            <div class="mt-0 pa-8" style="width:70%">
                                                <div id="editor0" v-html="report.comment" ref="editor" class="col col"></div>
                                            </div>
                                        </v-row>

                                        <v-row class="pt-4 mt-4 mb-4" style="margin-bottom: 0px;">
                                            <!-- OK REPORT img -->
                                            <div class="mt-0 pa-3 pr-0 pt-0" style="width:30%;border-right:1px solid rgba(0, 0, 0, 0.22)">
                                                <v-row class="ma-0 pa-0" style="justify-content: space-between;">
                                                    <h4 class="pa-5 pt-2 pb-0 ml-1">Illustrations</h4>
                                                    <div class="mt-0 pb-0" v-if="img_available == false" style="background-color: #56565678; border-radius:5px 0px 0px 5px;height:40px; width:40px;display:flex;justify-content:center;">
                                                        <v-icon color="white">mdi-image</v-icon>
                                                    </div>
                                                    <div class="mt-0 pb-0" v-if="img_available == true" style="background-color: #56565678; border-radius:5px 0px 0px 5px;height:40px; width:40px;display:flex;justify-content:center;">
                                                        <v-icon color="white">mdi-image</v-icon>
                                                    </div>
                                                </v-row>
                                                
                                            </div>
                                            <div class="mt-0 pa-3" style="width:70%">
                                                <!-- IMAGES CAROUSEL -->
                                                <v-carousel class="col" hide-delimiters>
                                                    <v-carousel-item 
                                                        v-for="img in img_urls" :key="img"
                                                        :src=img
                                                        height="400"
                                                        progress="primary"
                                                        contain
                                                    ></v-carousel-item>
                                                </v-carousel>
                                            </div>
                                        </v-row>

                                        <v-row class="pt-0 pb-8 mt-4" style="background-color: #fbfbfb;">
                                            <!-- INFORMATIONS -->
                                            <div class="mt-4 pa-3 pr-0 pt-0" style="width:30%;border-right:1px solid rgba(0, 0, 0, 0.22)">
                                                <v-row class="ma-0 pa-0" style="justify-content: space-between;">
                                                    <h4 class="pa-5 pt-2 pb-0 ml-1">Informations générales</h4>
                                                    <div class="mt-0 pb-0" style="background-color: #56565678; border-radius:5px 0px 0px 5px;height:40px; width:40px;display:flex;justify-content:center;">
                                                        <v-icon color="white">mdi-information</v-icon>
                                                    </div>
                                                </v-row>
                                                <br>
                                                <p class="pa-8 pt-4" style="color:grey;font-size:13px;">
                                                    Les informations présentes dans cette section reprennent les données et le contexte de la mesure effectuée. 
                                                </p>
                                            </div>
                                            <div class="mt-0 pa-0" style="width:70%;">
                                                <v-row class="ma-0 pa-0 pt-7 pl-5" style="justify-content:space-between;">
                                                    <v-col class="pa-0 ma-0 col-8">
                                                        <v-row class="pa-0 ma-0">
                                                            <!-- CAMPAIN INFOS -->
                                                            <v-col class="col pl-5 pr-5 pt-0" style=" color: black;">
                                                                <div class="mt-0" style="font-size: 12px;margin-left: 8px;font-weight:bold;">Informations complémentaires sur la campagne :</div>
                                                                <v-col class="mb-0 mt-0 pl-1">
                                                                    <div v-for="presta in prestas" :key="presta">
                                                                        <div v-for="typex in types" :key="typex">
                                                                            <v-chip v-if="presta.id === campain.presta && typex.id === campain.type" class="ml-0 mb-0" style="font-size:12px;">{{ presta.name }} ({{ typex.name.toLowerCase().trim() }})</v-chip>
                                                                        </div>
                                                                    </div>
                                                                </v-col>
                                                                <v-row class="mb-0 pa-0 mt-0"> 
                                                                    <div class="mb-0 mt-0" style="height: 18px;font-size: 12px;margin-left: 22px;font-weight: bold;" @click="$router.push({ name: 'campain', params: {id: campain.id} })">Désignation : {{campain.name}}</div>
                                                                    <v-icon style="cursor:pointer;"class="ml-1" @click="$router.push({ name: 'campain', params: {id: campain.id} })" color="primary" small>mdi-link-variant</v-icon>
                                                                </v-row>
                                                                <div class="mb-0 mt-0" style="height: 18px;font-size: 12px;margin-left: 10px;">Référence : {{campain.uid}} <v-icon class="ml-1" @click="copyValue(machine.uid)" color="primary" small>mdi-content-copy</v-icon></div>
                                                                <div class="mb-0 mt-0" style="height: 18px;font-size: 12px;margin-left: 10px;">Créée le : {{campain.created_at}}</div>
                                                                <div v-for="tviewer in analysts" :key="tviewer">
                                                                    <div class="mb-0 mt-0" v-if="tviewer.id === campain.creator" style="height: 18px;font-size: 12px;margin-left: 10px;">Créée par : {{tviewer.first_name}} {{tviewer.last_name}}</div>
                                                                </div>
                                                                <div class="mb-0 mt-0 mb-0" style="height: 18px;font-size: 12px;margin-left: 10px;">Denière modification : {{campain.updated_at}}</div>
                                                                <div v-if="campain.closed_at != null" class="mb-0 mt-0 mb-2" style="height: 18px;font-size: 12px;margin-left: 10px;">Clôturée le : {{campain.closed_at}}</div>
                                                                <div v-if="campain.closed_at == null" class="mb-0 mt-0 mb-2" style="height: 18px;font-size: 12px;margin-left: 10px;">Campagne ouverte.</div>
                                                                <div v-for="analyst in analysts_datas" :key="analyst">
                                                                    <div class="mb-0 mt-0" v-if="analyst.id === campain.manager" style="height: 18px;font-size: 12px;margin-left: 10px;font-weight: bold;">Chargé de campagne : {{analyst.name}}</div>
                                                                </div>
                                                                <div class="mb-0 mt-0" style="height: 18px;font-size: 12px;margin-left: 10px;font-weight: bold;">N° de dossier : {{campain.affaire_number}} <v-icon class="ml-1" @click="copyValue(machine.uid)" color="primary" small>mdi-content-copy</v-icon></div>
                                                                <div v-for="site in sites" :key="site">
                                                                    <div class="mb-0 mt-0" v-if="site.id === campain.id_park" style="height: 18px;font-size: 12px;margin-left: 10px;font-weight: bold;">Parc machine associé : {{site.label}}</div>
                                                                </div>
                                                            </v-col>
                                                            
                                                            <!-- REPORT INFOS -->
                                                            <v-col class="col pt-0" style="margin-left:15px;">
                                                                <v-row class="" style="color: black;">
                                                                    <v-col class="">
                                                                        <div class="mt-0" style="font-size: 12px;margin-left: 8px;font-weight:bold;">Informations complémentaires sur le rapport :</div>
                                                                        <v-col class="mt-3 pa-0"></v-col>
                                                                        <v-col v-for="analyst in analysts_datas" :key="analyst" class="pa-0 mb-0">
                                                                            <v-row class="mb-0 mt-0" v-if="analyst.id === report.creator" style="height: 18px;font-size: 12px;margin-left: 10px;">Référence : {{report.uid}} <v-icon class="ml-1" @click="copyValue(machine.uid)" color="primary" small>mdi-content-copy</v-icon></v-row>
                                                                            <v-row class="mb-0 mt-0" v-if="analyst.id === report.creator" style="height: 18px;font-size: 12px;margin-left: 10px;">Créé le : {{report.created_at}}</v-row>
                                                                            <v-row class="mb-0 mt-0" v-if="analyst.id === report.creator" style="height: 18px;font-size: 12px;margin-left: 10px;">Créé par : {{analyst.name}}</v-row>
                                                                            <v-row class="mb-0 mt-0" v-if="analyst.id === report.creator" style="height: 18px;font-size: 12px;margin-left: 10px;">Révisions : {{report.rev}}</v-row>
                                                                            <v-row class="mb-0 mt-0" v-if="analyst.id === report.creator" style="height: 18px;font-size: 12px;margin-left: 10px;">Dernière modification : {{report.updated_at}}</v-row>
                                                                            <v-row class="mb-0 mt-0" v-if="analyst.id === report.creator && report.released_at" style="height: 18px;font-size: 12px;margin-left: 10px;font-weight: bold;">Date de la publication : {{report.released_at}}</v-row>
                                                                        </v-col>
                                                                        <v-col class="mt-2 pa-0"></v-col>
                                                                        <v-col v-for="analyst in analysts_datas" :key="analyst" class="pa-0 mb-0 mt-0">
                                                                            <v-row class="mb-0 mt-0" v-if="report.intervention_date && analyst.id === report.intervenant" style="height: 18px;font-size: 12px;margin-left: 10px;font-weight: bold;">Date de l'intervention : {{report.intervention_date}}</v-row>
                                                                            <v-row class="mb-0 mt-0" v-if="analyst.id === report.intervenant" style="height: 18px;font-size: 12px;margin-left: 10px;font-weight: bold;">Intervenant : {{analyst.name}}</v-row>
                                                                        </v-col>
                                                                        <v-col class="mt-2 pa-0"></v-col>
                                                                        <v-col v-for="analyst in analysts_datas" :key="analyst" class="pa-0 mb-0 mt-0">
                                                                            <v-row class="mb-0 mt-0" v-if="analyst.id === report.manager" style="height: 18px;font-size: 12px;margin-left: 10px;font-weight: bold;">Manager : {{analyst.name}}</v-row>
                                                                            <v-row class="mb-0 mt-0" v-if="analyst.id === report.analyst" style="height: 18px;font-size: 12px;margin-left: 10px;font-weight: bold;">Analyste : {{analyst.name}}</v-row>
                                                                            <v-row class="mb-0 mt-0" v-if="analyst.id === report.validator" style="height: 18px;font-size: 12px;margin-left: 10px;font-weight: bold;">Validateur : {{analyst.name}}</v-row>
                                                                        </v-col>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row class="pa-0 ma-0 mt-4">
                                                            <!-- TAGS INFOS -->
                                                            <v-col class="col pl-5 pr-5 pt-0" style=" color: black;">
                                                                <!-- TAGS -->
                                                                <div class="mt-0 mb-2" style="font-size: 12px;margin-left: 8px;font-weight:bold;">Tags associés :</div>
                                                                <validation-provider :name="$t('campain.add_name')" rules="required" v-slot="{ errors }">
                                                                    <v-select
                                                                        v-model="report.tags"
                                                                        :items="report_tags"
                                                                        :error-messages="errors"
                                                                        item-value="name"
                                                                        item-text="name"  
                                                                        multiple chips disabled
                                                                        style="font-size: 12px;"
                                                                        class="custom-v-select mt-0 pt-0"
                                                                    ></v-select>
                                                                </validation-provider>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                    <v-col class="pa-0 ma-0 mr-4 mt-8" style="max-width:1px;border-right:1px solid #00000014;position:relative;left:26px;"></v-col>
                                                    <v-col class="pa-0 ma-0 col">
                                                        <div class="mt-0 mb-4" style="font-size: 12px;margin-left: 34px;font-weight:bold;">Historique des rapports ({{ report_nb }}) :</div>
                                                        <!-- report list -->
                                                        <div v-for="report in reversed_indices" :key="report">
                                                            <v-row class="col mb-2"> 
                                                                <div class="ma-0 pa-0" v-for="filters in machine_filters" :key="filters">
                                                                    <v-row class="ma-0 pa-0" style="font-size: 12px;margin-left: 10px;align-items:center;" v-if="filters.level == report.alert_level && filters.level_name !== 'Tout voir'" :to="{ path: '/report/' + report.id}" >
                                                                        <div v-if="report.step == 5 || (report.step != 5 && access_level <= 99)" style="width:18px;height:12px;border-radius:10px;margin-right:15px;z-index:2;" @click="switchReport(report.id)" :style="{backgroundColor: filters.level_color}"></div>
                                                                        <div v-if="report.step != 5 && access_level > 99" style="width:18px;height:12px;border-radius:10px;margin-right:15px;z-index:2;background-color: #adadad"></div>
                                                                        
                                                                        <div v-if="report.step == 5" class="row col" @click="switchReport(report.id)">Rapport du {{report.released_at}} (EDS {{report.indice}}%)</div>
                                                                        <div v-if="report.step != 5 && access_level <= 99" class="row col" @click="switchReport(report.id)">Rapport en cours d'édition (EDS {{report.indice}}%)</div>
                                                                        <div v-if="report.step != 5 && access_level > 99" class="row col">Rapport en cours d'édition</div>
                                                                        <v-icon v-if="report.step == 5 || (report.step != 5 && access_level <= 99)" style="cursor:pointer;" class="ml-1" @click="switchReport(report.id)" color="primary" small>mdi-link-variant</v-icon>
                                                                    </v-row>
                                                                </div>
                                                            </v-row>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </v-row>
                                    </v-col>
                                </v-card-text>
                            </validation-observer>
                        </v-card>

                        <!-- AUTOMATIC REPORT CONTENT-->
                        <v-card v-if="report.type === 2" class="mt-6">
                        </v-card>

                        <!-- PDF REPORT VIB OR THERMO CONTENT -->
                        <v-card v-if="report.type === 0 || report.type === 3" class="mt-6" style="margin-bottom:50px;">
                            
                            <v-row class="pa-0 pl-3 pr-3">
                                <!--CHIPS-->
                                <div class="mt-0 pl-3 pr-0 pt-3" style="width:30%">
                                    <!-- uploader V2 -->
                                    <v-row class="ma-0 pa-0" style="justify-content: space-between;">
                                        <h4 class="pa-5 pt-2 pb-0 ml-1">Rapport et documents associés</h4>
                                        <div class="mt-0 pb-0" style="background-color: #56565678; border-radius:5px 0px 0px 5px;height:40px; width:40px;display:flex;justify-content:center;">
                                            <v-icon color="white">mdi-file-pdf-box</v-icon>
                                        </div>
                                    </v-row>

                                    <!-- uploader -->
                                    <div :min-height="minHeight + 'px'" elevation="2" outlined height="100%" class="mt-5 pr-3" style="height:100%">
                                        <v-card-text class="row pr-4" >
                                            <v-row v-if="!pdf_report_available && external_link_activated" class="mb-4 mr-2 ml-2 mt-2">
                                                <v-card
                                                    :class="{ 'grey lighten-2': dragover }"
                                                    class="w-100" height="120"
                                                    @dragenter.prevent="dragover = true"
                                                    @dragleave.prevent="dragover = false"
                                                    @dragover.prevent="dragover = true"
                                                    @drop.prevent="onDrop($event)"
                                                    elevation="2" outlined
                                                >
                                                    <v-card-text class="mt-12 text-center">
                                                        {{ $t('document.put_file_to_download') }}
                                                    </v-card-text>
                                                </v-card>
                                            </v-row>

                                            <v-row v-if="!pdf_report_available && external_link_activated" class="ma-2">
                                                <v-btn @click="openDialogAddExternalLink()" block color="primary">
                                                    {{ $t('document.add_link_extern_document') }}
                                                </v-btn>
                                            </v-row>

                                            <v-col v-if="pdf_report_available" class="col pa-0 ml-3">

                                                <v-progress-linear color="primary" indeterminate v-if="loading"></v-progress-linear>

                                                <v-virtual-scroll :items="uploaded_files"  item-height="50" class="mt-1" style="overflow:hidden;height:100%;">
                                                    <template v-slot:default="{ item }">
                                                        <v-list-item class="pr-0">
                                                            <!--
                                                            <v-list-item-content v-if="item.link">
                                                                <v-list-item-title>
                                                                    <a :href="item.link" target="_blank"> {{ item.link }}</a>
                                                                </v-list-item-title>
                                                            </v-list-item-content>

                                                            <v-list-item-action v-if="item.link">
                                                                <v-btn @click.stop="removeExternalLink(item.id)" icon>
                                                                    <v-icon> mdi-close-circle</v-icon>
                                                                </v-btn>
                                                            </v-list-item-action>
                                                            -->

                                                            <v-list-item-content v-if="item.file_name">
                                                                <v-list-item-title>
                                                                    <a @click.stop="readDocument(item.file_name)"> {{ item.file_name }}</a>
                                                                </v-list-item-title>
                                                            </v-list-item-content>

                                                            <v-list-item-action v-if="item.file_name">
                                                                <v-btn :href="item.path" download icon>
                                                                    <v-icon> mdi-arrow-down-bold-circle</v-icon>
                                                                </v-btn>
                                                            </v-list-item-action>
                                                        </v-list-item>

                                                        <v-divider></v-divider>
                                                    </template>
                                                </v-virtual-scroll>
                                            </v-col>
                                        </v-card-text>

                                        <template v-if="external_link_activated">
                                            <v-dialog v-model="dialog_add" max-width="500px">
                                                <v-card>
                                                    <v-card-title>
                                                        <span class="headline">{{ $t('document.add_link_extern_document') }}</span>
                                                    </v-card-title>
                                                    <v-divider></v-divider>

                                                    <v-card-text><br/>
                                                        <v-form v-model="valid">
                                                            <v-text-field
                                                                label="URL" solo
                                                                :prepend-icon="'mdi-web'"
                                                                v-model="external_link.link"
                                                            ></v-text-field>
                                                        </v-form>
                                                    </v-card-text>

                                                    <v-card-actions class="justify-end">
                                                        <v-btn @click="addExternalLink()" color="primary">
                                                            <v-icon left>mdi-content-save</v-icon>
                                                            {{ $t('global.validation') }}
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-dialog>

                                            <v-snackbar
                                                v-model="snackbar"
                                                :color="snackbar_type">
                                                {{ snackbar_text }}
                                                <template v-slot:action="{ attrs }">
                                                    <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                                                </template>
                                            </v-snackbar>
                                        </template>
                                    </div>  
                                </div>  

                                <PDFViewer
                                    id="pdf"
                                    :source="report_url"
                                    style="height: 79vh;max-width:70%;background-color:white;border-radius: 0px 5px 0px 5px;"
                                    v-if="pdf_report_available"
                                />
                                <div v-if="!pdf_report_available" style="height: 79vh;max-width:70%;background-color:white;border-radius: 0px 5px 0px 5px;text-align: center;width: 100%;background-color: #50505030;justify-content: center;display: flex;color: #666666;padding-top: 100px;}"> Aucun document à visionner.</div>
                            </v-row>

                            <v-row class="pt-8 ma-0">
                                <!-- REPORT PRECO -->
                                <div class="mt-4 pa-3 pr-0 pt-0" style="width:30%;border-right:1px solid rgba(0, 0, 0, 0.22)">
                                    <v-row class="ma-0 pa-0" style="justify-content: space-between;">
                                        <h4 class="pa-5 pt-2 pb-0 ml-1">Préconisations de maintenance</h4>
                                        <div class="mt-0 pb-0" style="background-color: #56565678; border-radius:5px 0px 0px 5px;height:40px; width:40px;display:flex;justify-content:center;">
                                            <v-icon color="white">mdi-text-box</v-icon>
                                        </div>
                                    </v-row>
                                    <br>
                                    <p class="pa-8 pt-4" style="color:grey;font-size:13px;">
                                        Les préconisations de maintenance regroupent les actions de maintenance à réaliser sur vos moyen pour recouvrer et entretenir l'état de santé de celui-ci.<br><br>
                                        <strong>En cas d'incompréhension de celles-ci ou de contraintes techniques n'hésitez pas à contacter directement un de nos techniciens.</strong>
                                    </p>
                                </div>
                                <div class="mt-0 pa-0" style="width:70%">
                                    <div v-if="report.preco !== null" v-html="report.preco" class="col col pa-8 pl-12"></div>
                                </div>
                            </v-row>

                            <v-row class="pt-0 pb-8 mt-4 ma-0" style="background-color: #fbfbfb;">
                                <!-- INFORMATIONS -->
                                <div class="mt-4 pa-3 pr-0 pt-0" style="width:30%;;border-right:1px solid rgba(0, 0, 0, 0.22)">
                                    <v-row class="ma-0 pa-0" style="justify-content: space-between;">
                                        <h4 class="pa-5 pt-2 pb-0 ml-1">Informations générales</h4>
                                        <div class="mt-0 pb-0" style="background-color: #56565678; border-radius:5px 0px 0px 5px;height:40px; width:40px;display:flex;justify-content:center;">
                                            <v-icon color="white">mdi-information</v-icon>
                                        </div>
                                    </v-row>
                                    <br>
                                    <p class="pa-8 pt-4" style="color:grey;font-size:13px;">
                                        Les informations présentes dans cette section reprennent les données et le contexte de la mesure effectuée. 
                                    </p>
                                </div>
                                <div class="mt-0 pa-0" style="width:70%;">
                                    <v-row class="ma-0 pa-0 pt-7 pl-5" style="justify-content:space-between;">
                                        <v-col class="pa-0 ma-0 col-8">
                                            <v-row class="pa-0 ma-0">
                                                <!-- CAMPAIN INFOS -->
                                                <v-col class="col pl-5 pr-5 pt-0" style=" color: black;">
                                                    <div class="mt-0" style="font-size: 12px;margin-left: 8px;font-weight:bold;">Informations complémentaires sur la campagne :</div>
                                                    <v-col class="mb-0 mt-0 pl-1">
                                                        <div v-for="presta in prestas" :key="presta">
                                                            <div v-for="typex in types" :key="typex">
                                                                <v-chip v-if="presta.id === campain.presta && typex.id === campain.type" class="ml-0 mb-0" style="font-size:12px;">{{ presta.name }} ({{ typex.name.toLowerCase().trim() }})</v-chip>
                                                            </div>
                                                        </div>
                                                    </v-col>
                                                    <v-row class="mb-0 pa-0 mt-0"> 
                                                        <div class="mb-0 mt-0" style="font-size: 12px;margin-left: 22px;font-weight: bold;" @click="$router.push({ name: 'campain', params: {id: campain.id} })">Désignation : {{campain.name}}</div>
                                                        <v-icon style="cursor:pointer;"class="ml-1" @click="$router.push({ name: 'campain', params: {id: campain.id} })" color="primary" small>mdi-link-variant</v-icon>
                                                    </v-row>
                                                    <div class="mb-0 mt-0" style="font-size: 12px;margin-left: 10px;">Référence : {{campain.uid}} <v-icon class="ml-1" @click="copyValue(machine.uid)" color="primary" small>mdi-content-copy</v-icon></div>
                                                    <div class="mb-0 mt-0" style="font-size: 12px;margin-left: 10px;">Créée le : {{campain.created_at}}</div>
                                                    <div v-for="tviewer in analysts" :key="tviewer">
                                                        <div class="mb-0 mt-0" v-if="tviewer.id === campain.creator" style="font-size: 12px;margin-left: 10px;">Créée par : {{tviewer.first_name}} {{tviewer.last_name}}</div>
                                                    </div>
                                                    <div class="mb-0 mt-0 mb-0" style="font-size: 12px;margin-left: 10px;">Denière modification : {{campain.updated_at}}</div>
                                                    <div v-if="campain.closed_at != null" class="mb-0 mt-0 mb-2" style="font-size: 12px;margin-left: 10px;">Clôturée le : {{campain.closed_at}}</div>
                                                    <div v-if="campain.closed_at == null" class="mb-0 mt-0 mb-2" style="font-size: 12px;margin-left: 10px;">Campagne ouverte.</div>
                                                    <div v-for="analyst in analysts_datas" :key="analyst">
                                                        <div class="mb-0 mt-0" v-if="analyst.id === campain.manager" style="font-size: 12px;margin-left: 10px;font-weight: bold;">Chargé de campagne : {{analyst.name}}</div>
                                                    </div>
                                                    <div class="mb-0 mt-0" style="font-size: 12px;margin-left: 10px;font-weight: bold;">N° de dossier : {{campain.affaire_number}} <v-icon class="ml-1" @click="copyValue(machine.uid)" color="primary" small>mdi-content-copy</v-icon></div>
                                                    <div v-for="site in sites" :key="site">
                                                        <div class="mb-0 mt-0" v-if="site.id === campain.id_park" style="font-size: 12px;margin-left: 10px;font-weight: bold;">Parc machine associé : {{site.label}}</div>
                                                    </div>
                                                </v-col>
                                                
                                                <!-- REPORT INFOS -->
                                                <v-col class="col pt-0" style="margin-left:15px;">
                                                    <v-row class="" style="color: black;">
                                                        <v-col class="">
                                                            <div class="mt-0" style="font-size: 12px;margin-left: 8px;font-weight:bold;">Informations complémentaires sur le rapport :</div>
                                                            <v-col class="mt-3 pa-0"></v-col>
                                                            <v-col v-for="analyst in analysts_datas" :key="analyst" class="pa-0 mb-0">
                                                                <v-row class="mb-0 mt-0" v-if="analyst.id === report.creator" style="font-size: 12px;margin-left: 10px;">Référence : {{report.uid}} <v-icon class="ml-1" @click="copyValue(machine.uid)" color="primary" small>mdi-content-copy</v-icon></v-row>
                                                                <v-row class="mb-0 mt-0" v-if="analyst.id === report.creator" style="font-size: 12px;margin-left: 10px;">Créé le : {{report.created_at}}</v-row>
                                                                <v-row class="mb-0 mt-0" v-if="analyst.id === report.creator" style="font-size: 12px;margin-left: 10px;">Créé par : {{analyst.name}}</v-row>
                                                                <v-row class="mb-0 mt-0" v-if="analyst.id === report.creator" style="font-size: 12px;margin-left: 10px;">Révisions : {{report.rev}}</v-row>
                                                                <v-row class="mb-0 mt-0" v-if="analyst.id === report.creator" style="font-size: 12px;margin-left: 10px;">Dernière modification : {{report.updated_at}}</v-row>
                                                                <v-row class="mb-0 mt-0" v-if="analyst.id === report.creator && report.released_at" style="font-size: 12px;margin-left: 10px;font-weight: bold;">Date de la publication : {{report.released_at}}</v-row>
                                                            </v-col>
                                                            <v-col class="mt-2 pa-0"></v-col>
                                                            <v-col v-for="analyst in analysts_datas" :key="analyst" class="pa-0 mb-0 mt-0">
                                                                <v-row class="mb-0 mt-0" v-if="report.intervention_date && analyst.id === report.intervenant" style="font-size: 12px;margin-left: 10px;font-weight: bold;">Date de l'intervention : {{report.intervention_date}}</v-row>
                                                                <v-row class="mb-0 mt-0" v-if="analyst.id === report.intervenant" style="font-size: 12px;margin-left: 10px;font-weight: bold;">Intervenant : {{analyst.name}}</v-row>
                                                            </v-col>
                                                            <v-col class="mt-2 pa-0"></v-col>
                                                            <v-col v-for="analyst in analysts_datas" :key="analyst" class="pa-0 mb-0 mt-0">
                                                                <v-row class="mb-0 mt-0" v-if="analyst.id === report.manager" style="font-size: 12px;margin-left: 10px;font-weight: bold;">Manager : {{analyst.name}}</v-row>
                                                                <v-row class="mb-0 mt-0" v-if="analyst.id === report.analyst" style="font-size: 12px;margin-left: 10px;font-weight: bold;">Analyste : {{analyst.name}}</v-row>
                                                                <v-row class="mb-0 mt-0" v-if="analyst.id === report.validator" style="font-size: 12px;margin-left: 10px;font-weight: bold;">Validateur : {{analyst.name}}</v-row>
                                                            </v-col>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                            <v-row class="pa-0 ma-0 mt-4">
                                                <!-- TAGS INFOS -->
                                                <v-col class="col pl-5 pr-5 pt-0" style=" color: black;">
                                                    <!-- TAGS -->
                                                    <div class="mt-0 mb-2" style="font-size: 12px;margin-left: 8px;font-weight:bold;">Tags associés :</div>
                                                    <validation-provider :name="$t('campain.add_name')" rules="required" v-slot="{ errors }">
                                                        <v-select
                                                            v-model="report.tags"
                                                            :items="report_tags"
                                                            :error-messages="errors"
                                                            item-value="name"
                                                            item-text="name"  
                                                            multiple chips disabled
                                                            style="font-size: 12px;"
                                                            class="custom-v-select mt-0 pt-0"
                                                        ></v-select>
                                                    </validation-provider>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col class="pa-0 ma-0 mr-4 mt-8" style="max-width:1px;border-right:1px solid #00000014;position:relative;left:26px;"></v-col>
                                        <v-col class="pa-0 ma-0 col">
                                            <div class="mt-0 mb-4" style="font-size: 12px;margin-left: 34px;font-weight:bold;">Historique des rapports ({{ report_nb }}) :</div>
                                            <!-- report list -->
                                            <div v-for="report in reversed_indices" :key="report">
                                                <v-row class="col mb-2"> 
                                                    <div class="ma-0 pa-0" v-for="filters in machine_filters" :key="filters">
                                                        <v-row class="ma-0 pa-0" style="font-size: 12px;margin-left: 10px;align-items:center;" v-if="filters.level == report.alert_level && filters.level_name !== 'Tout voir'" :to="{ path: '/report/' + report.id}" >
                                                            <div v-if="report.step == 5 || (report.step != 5 && access_level <= 99)" style="width:18px;height:12px;border-radius:10px;margin-right:15px;z-index:2;" @click="switchReport(report.id)" :style="{backgroundColor: filters.level_color}"></div>
                                                            <div v-if="report.step != 5 && access_level > 99" style="width:18px;height:12px;border-radius:10px;margin-right:15px;z-index:2;background-color: #adadad"></div>
                                                            
                                                            <div v-if="report.step == 5" class="row col" @click="switchReport(report.id)">Rapport du {{report.released_at}} (EDS {{report.indice}}%)</div>
                                                            <div v-if="report.step != 5 && access_level <= 99" class="row col" @click="switchReport(report.id)">Rapport en cours d'édition (EDS {{report.indice}}%)</div>
                                                            <div v-if="report.step != 5 && access_level > 99" class="row col">Rapport en cours d'édition</div>
                                                            <v-icon v-if="report.step == 5 || (report.step != 5 && access_level <= 99)" style="cursor:pointer;" class="ml-1" @click="switchReport(report.id)" color="primary" small>mdi-link-variant</v-icon>
                                                        </v-row>
                                                    </div>
                                                </v-row>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-row>
                        </v-card>               
                    </div>

                    <!-- SNACKBAR INFO DISPLAY -->
                    <v-snackbar
                        v-model="snackbar"
                        :color="snackbar_type">
                        {{ snackbar_text }}
                        <template v-slot:action="{ attrs }">
                            <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                        </template>
                    </v-snackbar>
                </div>
            </v-col>
        </div>

        <!-- EDIT BOTTOM-->
        <div style="position: fixed; bottom: 0; z-index: 5; width: 100%; left: 0;" id="bottom">
            <v-col class="pr-5 col-12" style="padding-left:390px!important;">
                <v-row class="ma-0 pa-0 col" style="align-items: center;font-weight: bold;color: #0000007a;width:100%;">
                    <v-tooltip v-if="access_level <= 99 && report.step != 5" bottom dark class="mr-4">
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" class="col col mr-3" type="submit" color="white" dusk="logout-button"
                                style=" text-transform: unset !important;
                                        background-color:#e7821aeb;
                                        border-radius: 5px;
                                        color: #FFFFFF!important;
                                        border-radius: 5px;
                                        font-size:11px;
                                        max-width:50px;"  
                                @click="$router.push({ name: 'redit', params: {id: report.id} })"
                            >                                        
                                <v-icon color="white" class="ml-2" left>mdi-file-edit</v-icon>
                            </v-btn>
                        </template>
                        <span>Editer le rapport</span>
                    </v-tooltip>

                    <v-tooltip v-if="access_level <= 99 && report.step == 5" bottom dark class="mr-4">
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" class="col col mr-3" type="submit" color="white" dusk="logout-button"
                                style=" text-transform: unset !important;
                                        background-color:#3e3e3eb8;
                                        border-radius: 5px;
                                        color: #FFFFFF!important;
                                        border-radius: 5px;
                                        font-size:11px;
                                        max-width:50px;"  
                                @click="$router.push({ name: 'redit', params: {id: report.id} })"
                            >                                        
                                <v-icon color="white" class="ml-2" left>mdi-file-lock</v-icon>
                            </v-btn>
                        </template>
                        <span>Ce rapport est publié et par conséquent son édition est bloquée.<br>
                        Cliquez ici pour voir le format d'édition de ce rapport.</span>
                    </v-tooltip>
                
                    <div v-if="access_level > 99" bottom dark style="min-width:1px;">
                    </div>

                    <v-tooltip v-if="report.step == 5 && (report.type == 1 || report.type == 2)" bottom dark class="mr-2 ml-2">
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" @click="showModal" class="ml-0 mr-3 v-size--default" dusk="logout-button" style="font-size:11px;background-color:#0009a5c9;width:50px;border-radius: 5px;">
                                <v-icon color="white" style="font-size:16px">mdi-printer</v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:default>
                            <div style="position:relative;z-index: 10;">
                                Imprimer
                            </div>
                        </template>
                    </v-tooltip>
                </v-row>
            </v-col>
        </div>

        <!-- Modal next update -->
        <div v-if="showModalState">
            <div style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.5); z-index: 10; display: flex; justify-content: center; align-items: center;" @click="closeModal"></div>
            <div style="position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); background-color: white; padding: 20px; border-radius: 8px; width: 80%; max-width: 500px; z-index: 20; box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); display: flex; flex-direction: column;">
                <h3 class="headline" style="text-align:center; margin-bottom:10px;">Impression</h3>
                
                <v-divider></v-divider>

                <div class="pl-4 pr-4" style="display:flex; flex-direction: column;">
                    <p style="margin-top: 10px;">Pour que l'impression soit optimale pensez à appuyer sur le bouton ci dessous sur Chrome</p>
                    <img style="margin: 10px 0; border: 1px solid black; border-radius: 5px;" src="https://techview.fr/wp-content/uploads/2024/10/Capture-decran-2024-10-14-120731.png">
                    <p>Et ci dessous sur Firefox</p>
                    <img style="margin: 10px 0; border: 1px solid black; border-radius: 5px;" src="https://techview.fr/wp-content/uploads/2024/10/Capture-decran-2024-10-14-120130.png">
                </div>

                <!-- Bouton pour imprimer -->
                <div style="display:flex;justify-content:center;">
                    <v-btn text icon v-on="on" @click="printAndClose" class="ml-0 mr-3 v-size--default" dusk="logout-button" style="font-size:14px; background-color:#0009a5c9; border-radius: 5px; color: white; padding: 0 20px; width: auto;">
                        <v-icon left>mdi-printer</v-icon>
                        Imprimer
                    </v-btn>
                </div>
            </div>
        </div>

        <!-- PRINT -->
        <div ref="contentToPrint" class="pt-0 mb-3 mt-1 printable-content" id="printSection" style="position: fixed; left: 0; top: -20px; width: 100%; z-index: -100;">
            <!-- Page de garde -->
            <div id="presentation-page-print" style="height:1020px; display: block;flex-direction: column; justify-content: space-between; position: relative; page-break-after: always; margin: 20px;">
                <div>  
                    <div>
                        <div style="height:150px; background-image: url('https://techview.fr/wp-content/uploads/2024/09/OnLine2-report.png'); background-repeat: no-repeat; background-position: center; background-size: cover;">
                            <img style="width:100px; margin:25px;" src="https://techview.fr/wp-content/uploads/2023/08/40b88e9c74d694e5fe469dbaa779b52c-2.png">
                        </div>
                        <div style="background-color:#014181; color: white; text-align: center;">
                            <h2 style="padding: 5px; margin: 0;font-size: 24px;">EXPERTS INSIDE</h2>
                        </div>
                    </div>

                    <div>
                        <div v-if="campain.id === report.id_campain" style="margin-top: 10px; text-align: center;font-size: 24px;font-weight: 600;text-transform: uppercase;color: #23b5d3;">
                            <p>{{ campain.name }}
                                <br>du {{ report.intervention_date }}
                            </p>
                            <div>
                                <div v-if="report.id_professional === professional.id" style="display: flex;flex-direction: column;align-items: center; color: black;">
                                    <img style="max-height :150px;" :src="professional.logo">
                                    <p>{{ professional.name }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>

                <div style="margin-top: 50px; position: absolute; bottom: 0; width: 100%;">
                    <div >
                        <v-col class="col-12">
                            <div style="background-color: #014181;color: white;display: flex;flex-direction: column;padding: 20px; font-size: 14px; width: 60%;box-shadow: -15px -15px 0px 0px #23b5d3;margin-left: 15px;">
                                <div style="line-height: 1.2;">
                                    <p style="margin:0;">Date de mise à disposition du rapport {{ report.released_at }}</p>
                                    <p style="margin:0;">Interlocuteurs : {{ report.interlocutor }}</p>
                                    <p style="margin:0;">Affaire TECHVIEW : {{ campain.affaire_number}}</p>
                                </div>

                                <div v-for="analyst in analysts_datas" :key="analyst">
                                    <v-row style="display: flex;align-items: flex-end;line-height: 1.2;" v-if="report.intervenant == analyst.id">
                                        <v-col style="width:65%;">
                                            <p style="margin-top:20px;margin-bottom:5px;font-weight: 600;">Mesures sur site {{ report.intervention_date }}</p>
                                            <p style="margin:0!important;font-style: italic;">{{ analyst.name }}</p>
                                            <p style="margin:0!important;"><v-icon color="white" size="14">mdi-phone-outline</v-icon> {{ analyst.tel }}</p>
                                            <p style="margin:0!important;"><v-icon color="white" size="14">mdi-email-outline</v-icon> <span style="text-decoration: underline;">{{ analyst.email }}</span></p>
                                        </v-col>
                                            <v-col style="width:35%;" v-if="analyst.logo != null">
                                            <img :src="analyst.logo" style="width: 100px;">
                                        </v-col>
                                    </v-row>
                                </div>
                            
                                <div v-for="analyst in analysts_datas" :key="analyst">
                                    <v-row style="display: flex;align-items: flex-end;line-height: 1.2;" v-if="report.analyst === analyst.id">
                                        <div style="width:65%;">
                                            <p style="margin-top:20px;margin-bottom:5px;font-weight: 600;">Analyse des mesures</p>
                                            <p style="margin:0!important;font-style: italic;">{{ analyst.name }}</p>
                                            <p style="margin:0!important;"><v-icon color="white" size="14">mdi-phone-outline</v-icon> {{ analyst.tel }}</p>
                                            <p style="margin:0!important;"><v-icon color="white" size="14">mdi-email-outline</v-icon> <span style="text-decoration: underline;">{{ analyst.email }}</span></p>
                                        </div>
                                        <div style="width:35%;" v-if="analyst.logo != null">
                                            <img :src="analyst.logo" style="width: 100px;">
                                        </div>
                                    </v-row>
                                </div>

                                <div v-for="analyst in analysts_datas" :key="analyst">
                                    <v-row style="display: flex;align-items: flex-end;line-height: 1.2;" v-if="report.validator === analyst.id">
                                        <v-col style="width:65%;">
                                            <p style="margin-top:20px;margin-bottom:5px;font-weight: 600;">Validation du rapport</p>
                                            <p style="margin:0;font-style: italic;">{{ analyst.name }}</p>
                                            <p style="margin:0;"><v-icon color="white" size="14">mdi-phone-outline</v-icon> {{ analyst.tel }}</p>
                                            <p style="margin:0;"><v-icon color="white" size="14">mdi-email-outline</v-icon> <span style="text-decoration: underline;">{{ analyst.email }}</span></p>
                                        </v-col>
                                        <v-col style="width:35%;" v-if="analyst.logo != null">
                                            <img :src="analyst.logo" style="width: 100px;">
                                        </v-col>
                                    </v-row>
                                </div>
                                
                            </div>
                            <!-- Pied de page -->
                            <div style="margin-top: 25px; background-color: #014181; color: white; text-align: center; font-size: 10px; padding: 5px; display: flex; flex-direction: column; justify-content: flex-end;">
                                <p style="margin:0!important;">Techview - https://techview.fr - 05.56.95.51.92</p>
                                <p style="margin:0!important;">S.A.S au capital de 100 000 Euros - SIRET 793 684 960 00024 - Code A.P.E 8559a - TVA FR63 793 684 960</p>
                                <p style="margin:0!important;">11 rue Pierre et Marie Curie - 33290 Blanquefort</p>
                            </div>
                        </v-col>
                    </div>
                </div>  
            </div>

            <!-- Page rapport -->
            <v-col class="pa-0 pt-1 col rounded" style="margin: 20px;">
                <div v-if="loading_datatable == false" flat style="height: 1020px; max-width: 100%; width: 100%; box-sizing: border-box;" class="pt-0 mb-3">
                    <!-- REPORT HEADER -->
                    <v-row id="headerReport" style="background-color:white;margin:0; position: relative; z-index: 10;">
                        <v-col class="col pa-0 pt-1 pb-2">
                            <div style="display:flex;">
                                <v-col class="col-2 pl-5">
                                    <img style="width:100px" src="https://techview.fr/wp-content/uploads/2023/08/40b88e9c74d694e5fe469dbaa779b52c-2.png">
                                </v-col>
                                <v-col v-if="report.id_machine === machine.id" class="col" style="display: flex;align-items: center; color: black; justify-content: center; text-align: center; width: 100%;">
                                    <p style="font-size:16px">Rapport de l'équipement "<span style="font-weight: 800;">{{ machine.label}}</span>"
                                        <br>dans la campagne "<span style="font-weight: 800;">{{ campain.name}}</span>"
                                    </p> 
                                </v-col>
                            </div>

                            <div style="margin-top: 0!important; padding: 0!important; display: flex; flex-direction: column;">
                                <div v-for="filters in machine_filters" :key="filters" style="max-height:50px;border-radius:5px;">
                                    <div  v-if="filters.level == report.alert_level && filters.level_name !== 'Tout voir'" :to="{ path: '/campain/' + campain.id}" class="col-0" style="color:white;font-weight:800;display:flex;justify-content:center;text-shadow:2px 2px 4px rgba(0, 0, 0, 0.29); height:100%;min-width: 8px;border-radius:5px;" >
                                        <div class="pa-0 ma-0" style="border-radius: 5px; overflow: hidden; display:flex; width:100%;">
                                            <v-col class="col pa-0" style="width:35%;display:flex;flex-direction:column; justify-content: center;" :style="{backgroundColor: filters.level_color}">
                                                <div style="align-items:center;width:100%; display: flex;">
                                                    <v-col class="col-2" style="margin-left: 12px;font-size:18px;font-weight:bold;text-align:right;">{{ report.indice }}%</v-col>
                                                    <v-col style="padding-left: 40px;font-size:10px;text-align:left;font-weight: 400;align-items:center;">
                                                        <div  v-if="report_position == 1 && report_position == report_nb">Rapport initial (seul rapport existant)</div>
                                                        <div  v-if="report_position == 1 && report_position != report_nb">Rapport initial (sur {{ report_nb }} existants)</div> 
                                                        <div  v-if="report_position > 1 && report_position == report_nb">Rapport n°{{ report_position }} (dernier en date)</div>
                                                        <div  v-if="report_position > 1 && report_position != report_nb">Rapport n°{{ report_position }} (sur {{ report_nb }} existants)</div>
                                                        <div  v-if="report.released_at">Publié le {{ report.released_at }}</div> 
                                                        <div  v-if="!report.released_at">Date de publication non définie</div> 
                                                    </v-col>
                                                </div>
                                            </v-col>
                                            <v-col class="col pa-3" style="width:65%;background-color:#dddddd;height:50px;display:flex;flex-direction:column;justify-content: center;text-shadow:none;">
                                                <div v-for="state in machine_filters" :key="state">
                                                    <div v-if="state.percent === report.indice" style="font-size:14px;color:black;padding-left: 20px;"> {{ state.annotation }}</div>
                                                    <div v-if="state.percent === report.indice && report_position == 1" style="font-size:10px;color:black;padding-left: 20px;font-weight:400;display:flex;"> <div style="color:#0e4cef;font-weight:bold;margin-right:5px;">* </div> Il s'agit du premier rapport enregistré sur cette application pour ce moyen.</div>
                                                    <div v-if="state.percent === report.indice && previous_indice < report.indice && report_position != 1" style="font-size:10px;color:black;padding-left: 20px;font-weight:400;display:flex;"> <div style="color:green;font-weight:bold;margin-right:5px;">↗ </div> Evolution positive depuis le {{previous_date}} (EDS {{previous_indice}}%)</div>
                                                    <div v-if="state.percent === report.indice && previous_indice == report.indice && report_position != 1" style="font-size:10px;color:black;padding-left: 20px;font-weight:400;display:flex;"> <div style="color:black;font-weight:bold;margin-right:5px;">= </div> Pas d'évolution depuis le {{previous_date}} (EDS {{previous_indice}}%)</div>
                                                    <div v-if="state.percent === report.indice && previous_indice > report.indice && report_position != 1" style="font-size:10px;color:black;padding-left: 20px;font-weight:400;display:flex;"> <div style="color:red;font-weight:bold;margin-right:5px;">↘ </div>Evolution négative depuis le {{previous_date}} (EDS {{previous_indice}}%)</div>
                                                </div>
                                            </v-col>
                                        </div>
                                    </div>
                                </div>
                                <!-- Sous-ensemble -->
                                <v-row style="display: flex; align-items: center; margin: 10px 0 0 0;">
                                    <div style="padding: 5px 10px; margin-bottom: 0px!important; border: 1px solid #e0e0e0; border-radius:25px; background-color: #e0e0e0;">{{ $t('report.subassembly_equipment') }} : 
                                        <template v-for="subassembly in machine.subassemblies" :key="subassembly">
                                            {{ subassembly.label }} |
                                        </template>
                                    </div>
                                </v-row>
                            </div>                            
                        </v-col> 
                    </v-row>

                    <div>
                        <div>
                            <!-- REPORT CONTENT -->
                            <v-col id="contentReport">
                                <v-card>
                                    <validation-observer ref="observer" v-slot="{ invalid }">
                                        <v-card-text class="pb-0" style="padding:0!important">
                                            <v-col class="pa-0">
                                                <v-col v-if="report.context || report.comment || report.preco" class="pa-0 pl-0 col" ref="contextHeightRef">
                                                    <!-- REPORT CONTEXT -->
                                                    <v-row class="pt-0 pl-3 pr-3">
                                                        <v-col class="pa-0 pl-0 col">
                                                            <template v-if="report.context">
                                                                <p class="d-inline-block" style="position: relative; top: 17px; background-color: white; z-index: 10; margin-left: 15px; padding: 0px 5px; font-weight: bold; width: 200px;font-size: 12px; margin-top: 0;">
                                                                    {{ $t('report.context') }}
                                                                </p>
                                                                <div style="border: 1px solid #bdbdbd; padding: 0px 10px; border-radius: 5px;margin: 0px 0px;font-size: 12px;line-height: 1.2;">
                                                                    <div v-html="report.context"></div>
                                                                </div>
                                                            </template>
                                                        </v-col>

                                                    </v-row>

                                                    <!-- REPORT COMMENT -->
                                                    <v-row class="pt-0 pl-3 pr-3">
                                                        <v-col class="pa-0 pl-0 col">
                                                            <template v-if="report.comment">
                                                                <p style="position: relative; top: 17px; background-color: white; z-index: 10; margin-left: 15px; padding: 0px 5px;font-weight: bold; display:inline-block;font-size: 12px; margin-top: 0;">
                                                                    {{ $t('report.comment') }}
                                                                </p>
                                                                <div style="border: 1px solid #bdbdbd; padding: 0px 10px; border-radius: 5px; margin: 0px 0px;font-size: 12px;line-height: 1.2;">
                                                                    <div v-html="report.comment"></div>
                                                                </div>
                                                            </template>
                                                        </v-col>
                                                    </v-row>
                                                
                                                    <!-- REPORT PRECO -->
                                                    <v-row class="pt-0 pl-3 pr-3">
                                                        <v-col class="pa-0 pl-0 col">
                                                            <template v-if="report.preco">
                                                                <p class="d-inline-block" style="position: relative; top: 17px; background-color: white; z-index: 10; margin-left: 15px; padding: 0px 5px; font-weight: bold; width: 160px;font-size: 12px; margin-top: 0;">
                                                                    {{ $t('report.preco') }}
                                                                </p>
                                                                <div style="border: 1px solid #bdbdbd; padding: 0px 10px; border-radius: 5px; margin: 0px 0px;font-size: 12px;line-height: 1.2;">
                                                                    <div v-html="report.preco"></div>
                                                                </div>
                                                            </template>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>                                        
                                            </v-col>
                                        </v-card-text>
                                    </validation-observer>
                                </v-card>
                            </v-col>
                            

                            <!-- REPORT IMAGES SECTION -->
                            <div id="imgReport" style=" margin-top: 10px; margin-left: 10px; display: flex; flex-direction: column; align-items: center;">
                                <v-col v-for="(url, index) in img_urls" :key="index" style="max-height:400px; margin-top: 20px;">
                                    <img :src="url" alt="Image du rapport" style="object-fit: fill; max-height:400px; max-width: 700px;">
                                </v-col>
                            </div>
                        </div>
                    </div>
                </div>
            </v-col>
        </div>
    </v-col>
    

</template>

<script>
import {CAMPAIN,MACHINES,REPORT,USERS,SITES,DOCUMENTS, EXTERNAL_LINK, PROFESSIONALS} from "../../../api";
import moment from "moment";
import Line from "./components/Line.vue";
import {api as fullscreen} from 'vue-fullscreen';
import PDFViewer from 'pdf-viewer-vue/dist/vue2-pdf-viewer'
import VirtualDeviceLocation from '../../components/VirtualDevice/VirtualDeviceLocation.vue';
import { VueEditor, Quill } from "vue2-editor";

// Machine menu package //
import MachineSpecifications from "../../components/Machine/MachineSpecifications152";
import MachineState from "../../components/Machine/MachineState152";
import MachineSubassemblies from "../../components/Machine/MachineSubassemblies152";

const authorizedTypes = [
    {name: 'png', value: 'image/png'},
    {name: 'jpg', value: 'image/jpeg'},
    {name: 'pdf', value: 'application/pdf'}
];

export default {
    name: "CampainVision",
    components:{
        Line,
        PDFViewer,
        VirtualDeviceLocation,
        VueEditor,
        MachineSpecifications,      // Machine menu package //
        MachineState,               // Machine menu package //
        MachineSubassemblies,       // Machine menu package //
    },
    props: {
        minHeight: {
            type: Number,
            required: false,
            default: 350
        },
    },
    data() {
        this.authorizedTypes = authorizedTypes;
        return {
            isDomLoaded: false,
            isLoading: true,

            infoToggle2: false, // bottom menu //
            infoToggle: false, // Machine menu package //
            label: '', // Machine menu package //
            // CAMPAINS ----------------
            campain_id:'',
            raw_campain: [],
            campain: [],
            // MACHINE ------------------
            machine_id:'',
            raw_machine: [],
            machine: [],
            parentType:"report",
            parentId:this.$route.params.id,
            // Other infos ------------------
            analysts: [],
            sites:[],
            // REPORT ----------------------
            raw_report:[],
            report_url:"",
            report_nb:0,
            reversed_indices:0,
            previous_indice:-1,
            previous_date:"",
            previous_id:"",
            next_date:"",
            next_indice:"",
            next_id:"",
            report_position:0,
            report_switched:false,
            new_report_id:0,
            switch_loader:false,
            report: {
                id: null,
                uid:'',
                id_campain:'',
                id_machine:'',
                creator:'',
                manager:'',
                validator:'',
                type:'',
                step:'',
                alert_level:'',
                indice:'',
                tags:[], 
                trash:'',
                context: '',
                comment: '',
                preco:'',
                defaults: [],
                images:[],
                created_at:'',
                updated_at:'',
                released_at:'',
                intervenant:'',  
                intervention_date:'',  
                interlocutor:'',
                presta:'',               
            },
            machine_filters: [
                { level: -1, level_name: "Rapport en attente", level_color: "grey", count:0, annotation: "Etat à définir"},
                { level: 1, level_name: "Pour information : EDS 100% (vert)", level_color: "#4CAF50", count:0, percent: 100, annotation: "Etat neuf" },
                { level: 2, level_name: "Pour information : EDS 90% (vert)", level_color: "#4CAF50", count:0, percent: 90, annotation: "Comportement vibratoire correct" },
                { level: 3, level_name: "Pour information : EDS 80% (vert)", level_color: "#4CAF50", count:0, percent: 80, annotation: "Pour information" },
                { level: 11, level_name: "A suivre : EDS 70% (jaune)", level_color: "#FFCB05", count:0, percent: 70, annotation: "A suivre lors de la prochaine campagne" },
                { level: 12, level_name: "A suivre : EDS 60% (jaune)", level_color: "#FFCB05", count:0, percent: 60, annotation: "A suivre de manière rapprochée" },
                { level: 13, level_name: "A suivre : EDS 50% (jaune)", level_color: "#FFCB05", count:0, percent: 50, annotation: "Expertise complémentaire préconisée" },
                { level: 21, level_name: "Importance élevée : EDS 40% (rouge)", level_color: "#F44336", count:0, percent: 40, annotation: "Action à prévoir au prochain arrêt technique" },
                { level: 22, level_name: "Importance élevée : EDS 30% (rouge)", level_color: "#F44336", count:0, percent: 30, annotation: "Action à prévoir au prochain arrêt technique" },
                { level: 23, level_name: "Importance élevée : EDS 20% (rouge)", level_color: "#F44336", count:0, percent: 20, annotation: "Action à prévoir au prochain arrêt technique" },
                { level: 31, level_name: "Urgence : EDS 10% (Noir)", level_color: "black", count:0, percent: 10, annotation: "Action à prévoir dans les plus brefs délais" },
                { level: 32, level_name:"Urgence : EDS 0% (Noir)", level_color: "black", count:0, percent: 0, annotation: "Arrêt de la machine recommandé" },              
            ],
            prestas:[
                {id:0, name:"Mesures vibratoires"},
                {id:1, name:"Thermographie"},
                {id:2, name:"Mesures ultrasons"},
                {id:3, name:"Equilibrages"},
                {id:4, name:"Expertise avancée"},
                {id:5, name:"Surveillance Online"},
            ],
            types:[
                {id:0, name:"Ponctuel"},
                {id:1, name:"Annuel"},
                {id:2, name:"Semestriel"},
                {id:3, name:"Quadrmestriel"}, 
                {id:4, name:"Trimestriel"},
                {id:5, name:"Continu"},
            ],
            report_types:[
                {id:0, name:"(VIB) Rapport au format PDF"},
                {id:1, name:"(VIB) Rapport au format digital"},
                {id:2, name:"(VIB) Rapport généré par les algorithmes"},
                {id:3, name:"(THERMO) Rapport au format PDF"},
            ],
            report_steps:[
                {id:0, name:"En attente", level_color: "grey"},
                {id:1, name:"En cours d'édition",level_color: "#ef780e"},
                {id:2, name:"En attente de validation",level_color: "#d40eef"},
                {id:3, name:"Validé",level_color: "#089d2d"},
                {id:4, name:"En cours de rectification",level_color: "#d40eef"},
                {id:5, name:"Livré",level_color: "#0e4cef"},
                {id:6, name:"En cours de rectification (post-livraison)",level_color: "#d40eef"},
            ],
            report_tags:[
                {id:1, name:"Rapport préliminaire"},
                {id:2, name:"Expertise avancée"},
                {id:3, name:"Expertise ODS"},
                {id:4, name:"Epertise Ultrasons"},
                {id:5, name:"Installation électrique"},
                {id:6, name:"Moyen particulier"},
                {id:7, name:"Contre visite"},
                {id:8, name:"Défauts de roulement"},
                {id:9, name:"Défauts BF"},
                {id:10, name:"Défauts HF"},
                {id:11, name:"Défauts électriques"},
            ],
            analysts_datas:[
                {id:40,  name:"Eric Quenneville", tel:"+33 6 38 38 11 18", email:"eric.quenneville@techview.fr", logo:"https://techview.fr/wp-content/uploads/2024/10/ERIC-QUENNEVILLE-CAT-III-e1725528314226-removebg-preview-2.png"},
                {id:121, name:"Pascal Ardoin",    tel:"+33 6 74 35 31 13", email:"pascal.ardoin@techview.fr", logo:"https://techview.fr/wp-content/uploads/2024/09/PASCAL-ARDOIN-CAT-II-e1725542858336.png"},
                {id:91,  name:"Amandine Prieur",  tel:"+33 7 87 58 28 98", email:"amandine.prieur@techview.fr", logo:"https://techview.fr/wp-content/uploads/2024/12/572e2dac-42b9-46f8-a7d5-995f87877850.png"},
                {id:278, name:"Louis Blaise",  tel:"+33 6 77 33 08 68", email:"louis.blaise@techview.fr"},
                {id:216, name:"Guillaume Maury",  tel:"+33 6 07 46 48 40", email:"guillaume.maury@techview.fr"},
                {id:79,  name:"Sébastien Poudenx",  tel:"+33 6 68 42 45 15", email:"sebastien.poudenx@techview.fr"},
            ],
            lineChartOptions: {
                title: {text: '(Indice) - Evolution de l\'état de santé machine'},
                time: {timezone: 'Europe/Paris',},
                lang: {noData: this.$t('three_dimensional_curve.data_loading_error')},
                height:140,
                noData: {
                    style: {
                        fontWeight: 'bold',
                        fontSize: '15px',
                        color: '#303030'
                    }
                },
                loading: {hideDuration: 1000,showDuration: 1000},
                scrollbar: {
                    liveRedraw: false,
                    height: 17,
                    barBackgroundColor: '#cfcfcf',
                    barBorderRadius: 9,
                    buttonBackgroundColor: '#c9c9c9',
                    buttonArrowColor: 'white',
                    buttonBorderWidth: 3,
                    buttonBorderRadius: 11,
                    rifleColor: 'white',
                    trackBorderRadius: 9
                },
                rangeSelector: {enabled: false,},
                updateArgs: [true, true, true],
                yAxis: {
                    title: {
                        text: 'Niveau de santé (%)',
                    },
                    min: 0,
                    max: 100,
                    plotBands: [],
                },
                plotOptions: {
                    line: {
                        connectNulls: true,
                    },
                    series: {
                        marker: {
                            enabled: true,
                            radius: 4
                        }
                    }
                },
                series: [{
                    name: 'Etat de santé',
                    data: []
                }],
                credits: {
                    enabled: false
                },
            },

            // GLOBAL --------------------
            access_level: this.$store.state.access_level,
            id: this.$route.params.id,
            loading_datatable:true,

            // Uploads V2
            alert_file_send: false,
            alert_file_error: false,
            alert_message: '',
            professional: [],
            professionals: [],
            selected_professional: '',
            professionals_load: false,
            contracts: [],
            selected_contract: '',
            contracts_load: false,
            files: [],
            dragging: false,
            dialog: false,

            // Uploads
            dragover: false,
            files_to_upload: [],
            uploaded_files: [],
            loading: true,
            external_link: {
                id_relatable: this.$route.params.id,
                type_relatable: 'report',
                link: ''
            },
            external_links: [],
            dialog_add: false,
            valid: true,
            result: [],
            pdf_report_available:false,
            external_link_activated:false,

            // Uploads (img)
            img_dragover: false,
            img_files_to_upload: [],
            img_uploaded_files: [],
            img_loading: true,
            img_external_link: {
                id_relatable: this.$route.params.id,
                type_relatable: 'report_images',
                link: ''
            },
            img_external_links: [],
            img_result: [],
            img_available:false,
            img_urls:[],


            showModalState: false,

            isDisabled: true,
        }
    },
    computed: {
        dialogTitle() {
            return this.edited_index === null ? this.$t('campain.add') : this.$t('campain.edit');
        },
        isValid() {
            let isValid = false
                if (this.files.length > 0 ) {
                    isValid = true
                }
            return !isValid
        }
    },
    created() {
        moment.locale('fr');
        this.initialize();
        this.getExternalLinks();
        this.getDocuments();
        this.img_getDocuments();
        this.setMetricThresholds();
        this.loadData();
    },
    methods: {
        // STARTING PROCESS
        initialize() {
            this.loading_datatable = true;
            this.getData();
        },
        switchReport(id) {
            if(id != 0){
                this.loading_datatable = true;
                this.id = id;
                this.getData();
                this.getExternalLinks();
                this.getDocuments();
                this.img_getDocuments();
                this.setMetricThresholds();
                this.loadData();
                this.id = this.$route.params.id;
            }
        },
        indiceReturned() {
            let new_indice =  this.machine_filters.find(filter => filter.annotation == this.health_state);
            return new_indice.percent;
        },

        // COLLECTING ALL NEEDED DATAS
        getData() {
            // GETTING CAMPAINS
            this.axios.get(REPORT + "/specific/" + this.id)
            
            .then((success) => {
                this.raw_report = success.data;
                this.report = success.data;

                if (this.report.id != 0 && this.report.id != null && this.report.id != ""){
                    this.report_available = true;

                    this.report.tags = JSON.parse(this.report.tags);
                    this.report.created_at = moment(this.report.created_at).format("DD/MM/YYYY");
                    this.report.updated_at = moment(this.report.updated_at).fromNow();
                    if(this.report.released_at != null){
                        this.report.released_at = moment(this.report.released_at).format("DD/MM/YYYY");
                    }
                    this.campain_id = this.report.id_campain;
                    this.machine_id = this.report.id_machine;

                }else{
                    this.report_available = false; 
                }

            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");

            }).finally(() => {
                this.getSideDatas();
                this.getIndices();
                this.loading_datatable = false;
            })
        },
        getSideDatas(){
            if (this.report){
                this.getCampain();
                this.getMachine();
                this.getAnalysts();
                this.getSite();
                this.getProfessional();
            }
        },
        getCampain(){
            this.axios.get(CAMPAIN + '/specific/' + this.campain_id )
            
            .then((success) => {
                success.data.forEach(item => {
                    item.created_at = moment(item.created_at).format("DD/MM/YYYY");
                    item.closed_at = moment(item.closed_at).format("DD/MM/YYYY");
                    item.updated_at = moment(item.updated_at).fromNow();
                })
                
                this.campain = [];
                this.campain = success.data[0];

            }).catch(() => {
                this.showSnackbar('error', "Side datas error.");
            })
        },
        getMachine() {
            this.axios.get(MACHINES + '/specific/' + this.machine_id)
            
            .then((success) => {
                this.machine = [];
                this.machine = success.data[0];

            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");
            })
        },
        getAnalysts(){
            this.axios.get(USERS + '/team')
            
            .then((success) => {
                this.analysts = [];
                this.analysts = success.data;

            }).catch(() => {
                this.showSnackbar('error', "Analysts datas error.");
            })
        },
        getSite() {
            this.axios.get(SITES + '/resume')
            
            .then((success) => {
                this.sites = [];
                this.sites = success.data;

            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");
            })
        },
        getIndices() {
            // GETTING CAMPAINS
            this.axios.get(REPORT + "/machines_indices/" + this.report.id_machine)
            
            .then((success) => {
                this.raw_report_indices = [];
                this.report_indices = [];
                this.reversed_indices = [];
                this.report_series = [];
                this.report_nb = 0;
                this.previous_indice = -1;
                this.previous_date = "";
                this.previous_id = 0;
                this.next_indice = -1;
                this.next_date = "";
                this.next_id = 0;
                this.report_position = 0;

                if (typeof success.data[0] !== 'undefined'){
                    this.report_indices_available = true;
                    this.raw_report_indices = success.data;

                    success.data.forEach(report => {
                        this.report_series.push(report.indice);
                    })
                    
                    // Generate previous health indice
                    this.report_nb = this.raw_report_indices.length;
                    this.reversed_indices = this.raw_report_indices;
                    this.reversed_indices.reverse();
                    let actual_flag = false;
                    let actual_flag_ended = false;
                    let pos = this.report_nb;

                    this.reversed_indices.forEach(item => {                       
                        
                        if(item.released_at != null){
                            item.released_at = moment(item.released_at).format("DD/MM/YYYY");
                        }

                        if(actual_flag == true){
                            this.previous_indice = item.indice;
                            this.previous_id = item.id;
                            this.previous_date = item.released_at;
                            actual_flag = false;
                            actual_flag_ended = true;
                        }
                        
                        if (item.id == this.report.id){
                            actual_flag = true;
                            this.report_position = pos;
                        }

                        if(actual_flag_ended == false && actual_flag == false){
                            this.next_indice = item.indice;
                            this.next_id = item.id;
                            this.next_date = item.released_at;
                        }

                        pos = pos -1;
                    })
                    
                    this.lineChartOptions.series[0].data = this.report_series;

                }else{
                    this.report_indices_available = false;
                }
            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");

            })
        },
        getProfessional() {
            this.axios.get(PROFESSIONALS + "/active")
            
            .then((success) => {
                    this.professional = success.data;
            }).catch(() => {
                this.showSnackbar('error', "Untrashed me data error.");
            })
        },
        setMetricThresholds() {
            this.lineChartOptions.yAxis.plotBands = [];
            this.lineChartOptions.yAxis.plotBands.push(
                {
                    from: 100,
                    to: 75,
                    color: 'rgba(76, 175, 80, 0.25)',
                    label: {
                        text: 'Etat de santé convenable'
                    }
                },
                {
                    from: 75,
                    to: 45,
                    color: 'rgba(255, 203, 5, 0.25)',
                    label: {
                        text: 'Etat de santé affecté (à suivre)'
                    }
                },
                {
                    from: 45,
                    to: 15,
                    color: 'rgba(244, 67, 54, 0.25)',
                    label: {
                        text: 'Etat de santé dégradé (importance élevée)'
                    }
                },
                {
                    from: 15,
                    to: 0,
                    color: 'rgba(0, 0, 0, 0.25)',
                    label: {
                        text: 'Etat de santé critique (urgence)'
                    }
                }
            );
        },

        // DISPLAY DATA MANAGEMENT
        checkDataAvailable(datas){
            if(typeof datas[0] === 'undefined') {                    
                return false;
            }else {
                return true;
            }
        },
        setMetricThresholds() {
            this.lineChartOptions.yAxis.plotBands = [];
            this.lineChartOptions.yAxis.plotBands.push(
                {
                    from: 100,
                    to: 75,
                    color: 'rgba(76, 175, 80, 0.25)',
                    label: {
                        text: 'Etat de santé convenable'
                    }
                },
                {
                    from: 75,
                    to: 45,
                    color: 'rgba(255, 203, 5, 0.25)',
                    label: {
                        text: 'Etat de santé affecté (à suivre)'
                    }
                },
                {
                    from: 45,
                    to: 15,
                    color: 'rgba(244, 67, 54, 0.25)',
                    label: {
                        text: 'Etat de santé dégradé (importance élevée)'
                    }
                },
                {
                    from: 15,
                    to: 0,
                    color: 'rgba(0, 0, 0, 0.25)',
                    label: {
                        text: 'Etat de santé critique (urgence)'
                    }
                }
            );
        },

        // UPLOADS (report)
        onDrop(e) {
            this.dragover = false;
            // If there are already uploaded files remove them
            if (this.files_to_upload.length > 0) this.files_to_upload = [];
            // If user has uploaded multiple files but the component is not multiple throw error
            let file;
            for (let i = 0; i < e.dataTransfer.files.length; i++) {
                file = e.dataTransfer.files.item(i);
                let docCheck = this.uploadFilesCheck(file,['image/png','image/jpg','image/jpeg','application/pdf','text/csv'],10);
                if(!docCheck.authorized ) {
                    this.showSnackbar('error',docCheck.output.toString());
                }
                else {
                    this.files_to_upload.push(file);
                }
            }
            if (this.files_to_upload.length > 0) this.uploadDocuments();
        },
        getDocuments() {
            this.axios.get(DOCUMENTS + '/getFiles', {
                    params: {
                        entity_id: this.id,
                        type: 'report',
                        professional_id: this.$store.state.professional_id
                    }
                }

            ).then((success) => {
                this.uploaded_files = [];
                
                if (this.checkDataAvailable(success.data)){
                    success.data.forEach((path) => {
                        let fileName = path.replace(/^.*[\\\/]/, '');
                        this.uploaded_files.push({
                            file_name: fileName,
                            path: path
                        });
                    });

                    this.uploaded_files.reverse();
                    this.report_url = this.uploaded_files[0].path;
                    this.pdf_report_available = true;

                }else{
                    this.pdf_report_available = false;
                }
            }).catch(() => {
                this.showSnackbar('error', 'GetDocuments error');

            }).finally(() => {
                this.mergeExternalLinkAndDocuments();
                this.loading = false;
            });
        },
        launchUrl(url){
            this.report_url = url;
        },
        readDocument(fileName) {
            this.axios.get(DOCUMENTS + '/getFiles', {
                    params: {
                        entity_id: this.id,
                        type: 'report',
                        professional_id: this.$store.state.professional_id
                    }
                }

            ).then((success) => {
                this.uploaded_files = [];
                
                if (this.checkDataAvailable(success.data)){
                    success.data.forEach((path) => {
                        let fileName = path.replace(/^.*[\\\/]/, '');
                        this.uploaded_files.push({
                            file_name: fileName,
                            path: path
                        });
                    });

                    this.uploaded_files.reverse();
                    this.uploaded_files.forEach(item => {
                        if (fileName == item.file_name){
                            this.report_url = item.path;
                        }
                        
                        item.created_at = moment(item.created_at).format("DD/MM/YYYY");
                    })
                    this.pdf_report_available = true;
                }else{
                    this.pdf_report_available = false;
                }
            }).catch(() => {
                this.showSnackbar('error', 'GetDocuments error');

            }).finally(() => {
                this.mergeExternalLinkAndDocuments();
                this.loading = false;
            });
        },

        // UPLOADS (imgs)
        img_onDrop(e) {
            this.img_dragover = false;
            // If there are already uploaded files remove them
            if (this.img_files_to_upload.length > 0) this.img_files_to_upload = [];
            // If user has uploaded multiple files but the component is not multiple throw error
            let file;
            for (let i = 0; i < e.dataTransfer.files.length; i++) {
                file = e.dataTransfer.files.item(i);
                let docCheck = this.uploadFilesCheck(file,['image/png','image/jpg','image/jpeg'],10);
                if(!docCheck.authorized ) {
                    this.showSnackbar('error',docCheck.output.toString());
                }
                else {
                    this.img_files_to_upload.push(file);
                }
            }
            if (this.img_files_to_upload.length > 0) this.img_uploadDocuments();
        },
        img_getDocuments() {
            this.axios.get(DOCUMENTS + '/getFiles', {
                    params: {
                        entity_id: this.id,
                        type: 'report_images',
                        professional_id: this.$store.state.professional_id
                    }
                }

            ).then((success) => {
                this.img_uploaded_files = [];
                this.img_urls = [];
                
                if (this.checkDataAvailable(success.data)){
                    success.data.forEach((path) => {
                        let fileName = path.replace(/^.*[\\\/]/, '');
                        this.img_uploaded_files.push({
                            file_name: fileName,
                            path: path
                        });
                    });

                    this.img_uploaded_files.reverse();
                    this.img_uploaded_files.forEach(img_url => {
                        this.img_urls.push(img_url.path);
                        this.img_available = true;
                    })

                }else{
                    this.img_available = false;
                }
            }).catch(() => {
                this.showSnackbar('error', 'img_GetDocuments error');

            }).finally(() => {
                this.img_result = this.img_uploaded_files;
                this.img_loading = false;
            });
        },
        img_launchUrl(url){
            this.report_url = url;
        },

        // EXTERNAL LINKS MANAGEMENT (not used on 1.5.1)
        getExternalLinks() {
            this.axios.get(EXTERNAL_LINK, {
                    params: {
                        ['id_report']: this.id,
                        type: 'report'
                    }
                }
            ).then((success) => {
                this.external_links = success.data;
                this.mergeExternalLinkAndDocuments();
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            }).finally(() => {
                this.loading = false;
            });
        },
        openDialogAddExternalLink() {
            this.resetExternalLinkObject();
            this.dialog_add = true;

        },
        resetExternalLinkObject() {
            this.external_link.link = '';
        },
        closeDialog() {
            this.dialog_add = false;
        },
        validURL() {
            let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
            this.valid = !!pattern.test(this.external_link.link);
            return this.valid;
        },
        mergeExternalLinkAndDocuments() {
            this.result = this.uploaded_files.concat(this.external_links);
        },

        // Machine menu package //
        updateLabelMachine(item) {
            this.label = item.label;
        },
        infoToggler(){
            this.infoToggle = !this.infoToggle;
        },
        infoToggler2(){
            this.infoToggle2 = !this.infoToggle2;
        },

        showModal() {
            this.showModalState = true
        },
        closeModal() {
            this.showModalState = false
        },

        // PRINT //
        printReportSpecific() {
            const waitForLoad = new Promise((resolve) => {
                if (document.readyState === "complete") {
                    resolve();
                } else {
                    window.addEventListener("load", resolve);
                }
            });

            waitForLoad.then(() => {
                window.print();
            });
        },
        loadData() {
            this.isLoading = true;

            setTimeout(() => {
                this.isLoading = false;
            }, 5000);
        },
        printContent() {
            const content = this.$refs.contentToPrint.innerHTML;

            const printWindow = window.open("", "_blank");
            printWindow.document.open();
            printWindow.document.write(`
                <html>
                <head>
                    <title>Impression</title>
                </head>
                <body>
                    ${content}
                </body>
                </html>
            `);
            printWindow.document.close();

            const images = printWindow.document.images;
            const imageLoadPromises = Array.from(images).map((img) => {
                return new Promise((resolve) => {
                if (img.complete) {
                    resolve();
                } else {
                    img.onload = resolve;
                    img.onerror = resolve;
                }
                });
            });

            Promise.all(imageLoadPromises).then(() => {
                printWindow.print();

                printWindow.onafterprint = () => printWindow.close();
                setTimeout(() => {
                    printWindow.close();
                    
                },);
            });
        },
        printAndClose() {
            this.closeModal();
            this.printContent();
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.isDisabled = false;
        });
    },
}
</script>

<style>
.full-width-text .v-select__selections {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.pdf-viewer__body .loading-mask {
    z-index: 4!important;
}

.full-width-text .v-select__selection {
  flex: 1 1 auto;
  text-align: left;
}

.v-responsive__content{
    width: auto !important;
}

.custom-v-select .v-select__selections {
  width: 100%; /* S'assurer que l'élément prend toute la largeur */
}

.custom-v-select .v-select__selection {
  text-overflow: ellipsis; /* Ajuste l'affichage si le texte dépasse */
  white-space: nowrap; /* Évite les retours à la ligne */
  overflow: hidden; /* Masque le contenu qui déborde */
}

.custom-v-select input {
  display: none; /* Supprime visuellement l'élément <input> */
}
</style>

<style scoped>
.file-input-zone-container {
    text-align: center;
}

.dropzone {
    width: 100%;
    height: 200px;
    position: relative;
    border: 2px dashed #e1e1e1;
}

.dropzone:hover {
    border: 1px dashed #004181;
}

.dropzone-info {
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
}

.dropzone-title {
    color: #787878;
}

.dropzone input {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.dropzone-upload-limit-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.dropzone-btn {
    margin-left: auto;
    margin-right: auto;
}

.uploaded-file-name {
    display: inline;
}

.remove-file {
    display: inline;
    float: right;
}

.width-layout {
    max-width: 350px;
}

.cancel-btn {
    text-decoration: black underline;
}

.printable-content {
  border: 1px solid #ccc;
  padding: 20px;
  margin-bottom: 20px;
}

@page {
    size: A4;
    margin: 10mm;
}
@media print {
    html * {
        visibility: hidden;
    }

    #menuPackage, #content, #bottom {
        display: none!important;
    }

    #printSection, #printSection * {
        visibility: visible!important;
    }

    #printSection {
        display: block!important;
        width: 100%!important;
    }

    #printDivision, #printDivision * {
        visibility: visible!important;
    }

    #printDivision {
        display: block!important;
        width: 100%!important;
    }

    #headerReport, #contentReport, #imgReport, #contentImgReport {
        display: block!important;
    }

    html, body {
        width: 100%;
        height: auto;
        transform: scale(1);
        transform-origin: top left;
        margin: 0;
        padding: 0;
    }
}
</style>