var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4",attrs:{"id":"gateways-list"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('p',{staticClass:"title mb-0 color-primary text-uppercase"},[_vm._v(_vm._s(_vm.$t('gateways_datatable.title')))])])],1),_vm._v(" "),_c('v-card',{staticClass:"mt-4",attrs:{"width":"100%","elevation":"0"}},[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.gateways,"loading":_vm.gateway_data_table_loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-layout',{staticClass:"justify-start mt-7"},[_c('p',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.$t('global.filter_by')))]),_vm._v(" "),_c('v-select',{staticClass:"ml-4",staticStyle:{"max-width":"500px"},attrs:{"items":_vm.gateway_states,"placeholder":_vm.$t('gateways_datatable.filter'),"item-text":"name","item-value":"id","filled":"","dense":"","chips":"","multiple":"","deletable-chips":""},model:{value:(_vm.selected_gateway_states),callback:function ($$v) {_vm.selected_gateway_states=$$v},expression:"selected_gateway_states"}})],1),_vm._v(" "),_c('v-layout',{staticClass:"justify-end mt-7 max-width-300"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('global.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_vm._v(" "),_c('v-layout',{staticClass:"justify-end mt-2 mb-2"},[_c('v-btn',{staticClass:"mr-3",attrs:{"color":"primary"},on:{"click":_vm.downloadCsvGateway}},[(_vm.loading_csv_file)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"white","size":"20","width":"2"}}):_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download-box-outline")]),_vm._v("\n                            "+_vm._s(_vm.$t('global.download'))+"\n                        ")],1)],1)]},proxy:true},{key:"item.date_last_seen",fn:function(ref){
var item = ref.item;
return [(item.date_last_seen !==null)?[_c('v-chip',{attrs:{"small":"","color":_vm.lastEmissionAttributeTranslation(item.date_last_seen).color}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"blue-grey darken-3"}},[_vm._v("\n                                mdi-sort-clock-descending-outline\n                            ")]),_vm._v("\n                            "+_vm._s(_vm.lastEmissionAttributeTranslation(item.date_last_seen).time)+"\n                        ")],1)]:[_c('v-icon',[_vm._v("mdi-cancel")])]]}},{key:"item.gx_order_number",fn:function(ref){
var item = ref.item;
return [(item.gx_order_number)?[_c('span',[_vm._v(_vm._s(item.gx_order_number))])]:[_c('v-icon',[_vm._v("mdi-cancel")])]]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.checkGatewayAndPhysicalDevicesStates(item.state)))])]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.checkGatewayAndPhysicalDevicesOwnership(item.user, item.state)))])]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"address-button",on:{"click":function($event){return _vm.$refs.location.gatewayLocationDialogOpen(item.address, item.latitude, item.longitude)}}},[_vm._v("\n                        "+_vm._s(item.address)+"\n                    ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.state === 4 || item.state === -1)?_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.confirm.openConfirmationAlert(item.uid,-3)}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-delete-empty")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('gateways_datatable.gateway_scrapping_notice')))])]):_vm._e(),_vm._v(" "),(item.state === 2 ||
                                       item.state === 4)?_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                       var on = ref.on;
                                       var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.confirm.openConfirmationAlert(item.uid,1)}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-database")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('gateways_datatable.gateway_storing_notice')))])]):_vm._e(),_vm._v(" "),(item.state === 2)?_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                       var on = ref.on;
                                       var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.confirm.openConfirmationAlert(item.uid,3)}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-video-input-antenna")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('gateways_datatable.gateway_on_operation_state')))])]):_vm._e(),_vm._v(" "),(item.state === 3)?_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                       var on = ref.on;
                                       var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.confirm.openConfirmationAlert(item.uid,-1)}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-cog-refresh")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('gateways_datatable.gateway_in_maintenance_state')))])]):_vm._e(),_vm._v(" "),(item.state === 3)?_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                       var on = ref.on;
                                       var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.confirm.openConfirmationAlert(item.uid,-2)}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-progress-close")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('gateways_datatable.gateway_lost_state')))])]):_vm._e(),_vm._v(" "),(item.state === -1)?_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                       var on = ref.on;
                                       var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.confirm.openConfirmationAlert(item.uid,4)}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-clipboard-search")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('gateways_datatable.gateway_to_test_state')))])]):_vm._e()]}}])})],1)],1),_vm._v(" "),_c('gateway-location',{ref:"location"}),_vm._v(" "),_c('device-update-confirmation',{ref:"confirm",on:{"Performed-action":function($event){return _vm.refreshGatewayDataTable()}}}),_vm._v(" "),_c('global-snackbar')],1)}
var staticRenderFns = []

export { render, staticRenderFns }