var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.access_level <= 100 || _vm.access_level === 200 || _vm.access_level === 300)?_c('div',{attrs:{"id":"favorite-curve"}},[_c('v-btn',{staticStyle:{"background-color":"#ffffff","color":"#001047"},attrs:{"block":""},on:{"click":function($event){_vm.dialog_edit_favorite_curves = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-tooltip-plus")]),_vm._v("\n        "+_vm._s(_vm.$t('global.add_graphic'))+"\n    ")],1),_vm._v(" "),_c('v-dialog',{attrs:{"scrollable":"","max-width":"500px"},model:{value:(_vm.dialog_edit_favorite_curves),callback:function ($$v) {_vm.dialog_edit_favorite_curves=$$v},expression:"dialog_edit_favorite_curves"}},[_c('v-card',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{ref:"form"},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('global.add_graphic')))])]),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-card-text',[_c('validation-provider',{attrs:{"name":_vm.$t('global.label'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('global.name'),"solo":"","error-messages":errors,"prepend-icon":'mdi-label-outline'},model:{value:(_vm.edited_favorite_curve.label),callback:function ($$v) {_vm.$set(_vm.edited_favorite_curve, "label", $$v)},expression:"edited_favorite_curve.label"}})]}}],null,true)}),_vm._v(" "),_c('validation-provider',{attrs:{"name":_vm.$t('global.devices'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.virtual_devices,"label":_vm.$t('global.devices'),"error-messages":errors,"prepend-icon":'mdi-chemical-weapon',"item-text":"label","item-value":"id","solo":""},on:{"change":function($event){return _vm.getMetricsOfVirtualDevices()}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v("\n                                    "+_vm._s(data.item.label)+"\n                                ")]}},{key:"item",fn:function(data){return [_vm._v("\n                                    "+_vm._s(data.item.label)+"\n                                ")]}}],null,true),model:{value:(_vm.edited_favorite_curve.virtual_device_id),callback:function ($$v) {_vm.$set(_vm.edited_favorite_curve, "virtual_device_id", $$v)},expression:"edited_favorite_curve.virtual_device_id"}})]}}],null,true)}),_vm._v(" "),_c('validation-provider',{attrs:{"name":_vm.$t('favorite_curve.physical_value'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.virtual_device_metric_types,"label":_vm.$t('favorite_curve.physical_value'),"error-messages":errors,"prepend-icon":'mdi-tape-measure',"item-text":"metric_name","item-value":"metric_uuid","disabled":_vm.disabled_virtual_device_metric_types,"loading":_vm.loading_virtual_device_metric_types,"solo":""},model:{value:(_vm.metric_name),callback:function ($$v) {_vm.metric_name=$$v},expression:"metric_name"}})]}}],null,true)})],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{on:{"click":function($event){return _vm.closeDialogEditFavoriteCurve()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close")]),_vm._v("\n                            "+_vm._s(_vm.$t('global.close'))+"\n                        ")],1),_vm._v(" "),_c('v-btn',{attrs:{"type":"submit","color":"primary","disabled":invalid},on:{"click":function($event){$event.preventDefault();return _vm.addCurve()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v("\n                            "+_vm._s(_vm.$t('global.validation'))+"\n                        ")],1)],1)],1)]}}],null,false,1361454459)})],1)],1),_vm._v(" "),_c('v-snackbar',{attrs:{"color":_vm.snackbar_type},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-icon',{attrs:{"color":"white"},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("mdi-close")])]}}],null,false,672411630),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v("\n        "+_vm._s(_vm.snackbar_text)+"\n        ")])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }