<template>
    <div id="document-import" class="pa-4">
        <v-row no-gutters>
            <v-col>
                <p class="title mb-0 color-primary text-uppercase">
                    {{ $t('documents_import.title') }}
                </p>
            </v-col>
        </v-row>

        <v-expand-transition>
            <v-alert
                v-show="alert_file_send"
                type="success"
                transition="fade-transition"
                close-icon="mdi-close"
                text dismissible
            >
                {{ $t('documents_import.alert_success_import') }}
            </v-alert>
        </v-expand-transition>

        <v-expand-transition>
            <v-alert
                v-show="alert_file_error"
                text
                type="warning"
                transition="fade-transition"
                close-icon="mdi-close"
                dismissible
            >
                {{ alert_message }}
            </v-alert>
        </v-expand-transition>

        <v-card width="100%" class="mt-4" elevation="0">
            <validation-observer v-slot="{ invalid }">
                <v-card-text>
                    <v-row>
                        <v-autocomplete
                            v-model="selected_professional"
                            class="mt-5 ml-3 width-layout"
                            :disabled="professionals.length === 0"
                            :items="professionals"
                            :loading="professionals_load"
                            :label="$t('documents_import.client')"
                            item-text="name" item-value="id"
                            @change="getContractsByProfessional()"
                            return-object filled
                        >
                        </v-autocomplete>

                        <v-autocomplete
                            v-model="selected_contract"
                            class="mt-5 ml-3 width-layout"
                            :disabled="contracts.length === 0"
                            :items="contracts"
                            :loading="contracts_load"
                            :label="$t('documents_import.contract_number')"
                            item-text="label" item-value="id"
                            return-object filled
                        >
                            <template v-slot:item="{item}">
                                {{ item.label + ' - ' + item.contract_number }}
                            </template>
                        </v-autocomplete>
                    </v-row>

                    <div class="file-input-zone-container">
                        <div :class="['dropzone', dragging ? 'dropzone-over' : '']" @dragenter="dragging = true"
                             @dragleave="dragging = false"
                        >
                            <div class="dropzone-info" @drag="onChange">
                                <span class="fa fa-cloud-upload dropzone-title"></span>
                                <v-icon large color="primary"> mdi-cloud-download-outline</v-icon>
                                <br>

                                <div class="dropzone-upload-limit-info">
                                    <div> {{ $t('documents_import.help') }}</div>
                                    <span class="mt-2">{{ $t('documents_import.notice') }}</span>

                                    <v-btn outlined width="400" class="dropzone-btn mt-2" color="primary">
                                        {{ $t('documents_import.selection_button') }}
                                    </v-btn>
                                </div>
                            </div>

                            <input type="file" @change="onChange" accept="application/pdf" multiple>
                        </div>
                    </div>

                    <div v-for="file in files" class="mt-6">
                        <p class="uploaded-file-name">{{ file.name }}</p>
                        <v-icon @click="removeFile(file)" class="remove-file">mdi-close-circle-outline</v-icon>
                        <v-divider class="mt-2"></v-divider>
                    </div>
                </v-card-text>

                <v-card-actions class="pr-4 pb-4">
                    <v-spacer/>
                    <v-dialog transition="dialog-top-transition" v-model="dialog" width="600">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" :disabled="isValid" color="primary">
                                {{ $t('global.validation') }}
                            </v-btn>
                        </template>

                        <v-card>
                            <v-card-title>
                                {{ $t('documents_import.dialog_send_title') }}
                            </v-card-title>

                            <v-divider></v-divider>

                            <v-container>
                                <v-card-text class="text-center">
                                    {{
                                        $t('documents_import.dialog_send_text', {client_name: selected_professional.name})
                                    }}
                                </v-card-text>

                                <v-card-actions class="justify-center">
                                    <v-btn text link class="cancel-btn" @click="dialog=false">
                                        {{ $t('global.cancel') }}
                                    </v-btn>
                                    <v-btn color="primary" @click="sendFiles()">
                                        {{ $t('global.validation') }}
                                    </v-btn>
                                </v-card-actions>
                            </v-container>
                        </v-card>
                    </v-dialog>
                </v-card-actions>
            </validation-observer>
        </v-card>
        <global-snackbar/>
    </div>
</template>

<script>
import {CONTRACTS, DOCUMENTS} from "../../../api";
import GlobalSnackbar from "../../../global/components/GlobalSnackbar";

export default {
    name: "DocumentsImport",
    components: {GlobalSnackbar},
    data() {
        return {
            alert_file_send: false,
            alert_file_error: false,
            alert_message: '',

            professionals: [],
            selected_professional: '',
            professionals_load: false,

            contracts: [],
            selected_contract: '',
            contracts_load: false,

            files: [],
            dragging: false,
            dialog: false,
        }
    },
    created() {
        this.getProfessionalsWithContract();
    },
    methods: {
        getProfessionalsWithContract() {
            this.professionals_load = true;
            this.axios.get(
                '/api/professional/with_contract'
            ).then((success) => {
                this.professionals = success.data;
                this.professionals_load = false;
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('global.error'));
            })
        },

        getContractsByProfessional() {
            this.contracts_load = true;
            this.contracts = [];

            this.axios.get(
                CONTRACTS + "/" + this.selected_professional.id + "/import"
            ).then((success) => {
                this.contracts = success.data;
                if (this.contracts.length === 1) {
                    this.selected_contract = this.contracts[0]
                }
                this.contracts_load = false;
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('global.error'));
            });
        },

        onChange(e) {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                this.dragging = false;
                return;
            }
            this.createFile(files[0]);
        },

        createFile(file) {
            this.files.push(file);
            this.dragging = false;
        },

        removeFile(file) {
            this.files.splice(this.files.indexOf(file), 1);
        },

        sendFiles() {
            let formData = new FormData()
            this.files.forEach((file) => {
                formData.append('files[]', file);
            });
            formData.append('entity_id', this.selected_contract.id);
            formData.append('type', 'contract');
            formData.append('professional_id', this.selected_professional.id);

            axios.post(DOCUMENTS + '/upload', formData, this.configurationFile).then(() => {
                this.dialog = false;
                this.alert_file_send = true;
                setTimeout(() => {
                    this.alert_file_send = false;
                    this.$router.push({name: 'contracts'});
                }, 2500);
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('global.error'));
            });
        },

    },
    computed: {
        isValid() {
            let isValid = false
            if (this.files.length > 0 && this.selected_contract !== '' && this.selected_professional !== '') {
                isValid = true
            }
            return !isValid
        }
    }
}
</script>

<style scoped>
.file-input-zone-container {
    text-align: center;
}

.dropzone {
    width: 100%;
    height: 200px;
    position: relative;
    border: 2px dashed #eee;
}

.dropzone:hover {
    border: 1px dashed #004181;
}

.dropzone-info {
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
}

.dropzone-title {
    color: #787878;
}

.dropzone input {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.dropzone-upload-limit-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.dropzone-btn {
    margin-left: auto;
    margin-right: auto;
}

.uploaded-file-name {
    display: inline;
}

.remove-file {
    display: inline;
    float: right;
}

.width-layout {
    max-width: 350px;
}

.cancel-btn {
    text-decoration: black underline;
}
</style>
