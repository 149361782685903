const state = () => ({
    show_snackbar: false,
    text_snackbar: '',
    type_snackbar: '',
});

const mutations = {
    showSnackbar(state, item) {
        this.state.text_snackbar = item.text;
        this.state.type_snackbar = item.type;
        this.state.show_snackbar = true;
    },
    hideSnackbar(state) {
        this.state.show_snackbar = false;
    },
};

const actions = {
    showSnackbar({ commit }, payload) {
        commit('showSnackbar', {text: payload.text_snackbar, type: payload.type_snackbar});
    },
    hideSnackbar({ commit }) {
        commit('hideSnackbar');
    },
};

const getters = {
    show_snackbar: (state) => state.show_snackbar,
    text_snackbar: (state) => state.text_snackbar,
    type_snackbar: (state) => state.type_snackbar,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
