<template>
    <v-card elevation="0" :loading="loading" min-height="490px">
        <!-- Content -->
        <v-card-text>
            <modal-operation ref="modalOperation" v-on:showSnackbarForModalOperation="showSnackbarForModalOperation"/>

            <v-row style="align-items: baseline;justify-content:space-between;padding-right:10px;">
                <!-- Date selector field -->
                <v-col class="col-4">
                    <v-menu ref="menu" 
                        v-model="menu" 
                        :close-on-content-click="false"
                        transition="scale-transition"  
                        min-width="auto"
                        offset-y
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field 
                                v-model="dateRangeText"
                                label="Période sélectionée" 
                                prepend-icon="mdi-calendar"
                                v-bind="attrs" 
                                v-on="on" 
                                hide-details
                                readonly
                            />
                        </template>

                        <v-date-picker v-model="range" @change="refresh()" range/>
                    </v-menu>
                </v-col>

                <!-- Actions -->
                <div class="mr-3">
                    <v-btn elevation="3" v-if="display_flags" @click="dialog_update_thresholds = false" color="green" small>
                        <v-icon color="white">mdi-flag</v-icon>
                    </v-btn>
                    <v-btn elevation="3" v-if="!display_flags" @click="dialog_update_thresholds = true" small>
                        <v-icon color="white">mdi-flag</v-icon>
                    </v-btn>
                    
                    <v-btn elevation="3" color="amber darken-3" small @click="dialog_update_thresholds = true"
                        v-if="this.$store.state.access_level <= 100 || this.$store.state.access_level === 300 || this.$store.state.access_level === 200"
                    >
                        <v-icon color="white" small>mdi-wrench</v-icon>
                    </v-btn>

                    <v-btn elevation="3" small color="primary" @click="TimeCurveCsvExport()" :loading="csv_export_loading">
                        <v-icon color="white" small>mdi-download</v-icon>
                    </v-btn>

                    <v-btn elevation="3" small @click="toggleFullscreen()">
                        <v-icon color="black">mdi-fullscreen</v-icon>
                    </v-btn>
                </div>

                <!-- 
                <v-col xl="6" cols="12" class="py-0">
                    <v-radio-group v-model="merger" @change="refresh()" hide-details row>
                        <v-radio label="Moyenne" value="MEAN"></v-radio>
                        <v-radio label="Minimum" value="MIN"></v-radio>
                        <v-radio label="Maximum" value="MAX"></v-radio>
                    </v-radio-group>
                </v-col>
                -->
            </v-row>
        </v-card-text>

        <!-- Displaying highcharts graphs -->
        <v-card-text>
            <div id="chart-time">
                <highcharts :constructorType="'stockChart'" :options="chartOptions" ref="highcharts"></highcharts>
            </div>
        </v-card-text>

        <!-- Dialog window for treshold managment -->
        <v-dialog v-model="dialog_update_thresholds" max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="headline">{{ $t('favorite_curve.update_thresholds') }}</span>
                </v-card-title>
                <v-divider></v-divider>
                <br/>

                <v-card-text>
                    <v-text-field
                        :label="$t('favorite_curve.pre_alert_low')"
                        v-model="thresholds.pre_alert_low"
                        :prepend-icon="'mdi-label-outline'"
                        :disabled="checkPrivilege()"
                        outlined type="number"
                    />
                    <v-text-field
                        :label="$t('favorite_curve.alert_low')"
                        v-model="thresholds.alert_low"
                        :prepend-icon="'mdi-label-outline'"
                        :disabled="checkPrivilege()"
                        outlined type="number"
                    />
                    <v-text-field
                        :label="$t('favorite_curve.pre_alert_high')"
                        v-model="thresholds.pre_alert_high"
                        :prepend-icon="'mdi-label-outline'"
                        :disabled="checkPrivilege()"
                        outlined type="number"
                    />
                    <v-text-field
                        :label="$t('favorite_curve.alert_high')"
                        v-model="thresholds.alert_high"
                        :prepend-icon="'mdi-label-outline'"
                        :disabled="checkPrivilege()"
                        outlined type="number"
                    />
                </v-card-text>

                <template v-if="!checkPrivilege()">
                    <v-divider/>

                    <v-card-actions>
                        <v-spacer/>
                        <v-btn @click="dialog_update_thresholds = false">
                            <v-icon left>mdi-close</v-icon>
                            {{ $t('global.close') }}
                        </v-btn>
                        <v-btn type="submit" color="primary"
                               @click.prevent="updateThresholds()">
                            <v-icon left>mdi-content-save</v-icon>
                            {{ $t('global.validation') }}
                        </v-btn>
                    </v-card-actions>
                </template>
            </v-card>
        </v-dialog>

        <!-- Dialog window for confirmation informations -->
        <v-snackbar
            v-model="snackbar"
            :color="snackbar_type">
            {{ snackbar_text }}
            <template v-slot:action="{ attrs }">
                <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
            </template>
        </v-snackbar>

    </v-card>
</template>

<script>
import moment from 'moment';
import {DOWNLOAD_CSV_TEXT_CURVE, FAVORITE_CURVES, METRICS} from "../../../../api";
import {api as fullscreen} from 'vue-fullscreen';
import ModalOperation from "../ModalOperationChart";

export default {
    name: "TimeCurveFree",
    props: ['item','id','ticker'],
    components: {
        ModalOperation,
    },
    data() {
        return {
            favorite_curve: this.item,
            id_machine: this.id,
            ticker:this.ticker,
            item_legacy: this.item,
            date_operation_click: null,
            api_data: [],
            range: [
                moment().clone().startOf('day').subtract(7, "days").format('YYYY-MM-DD'),
                moment().clone().endOf('day').format('YYYY-MM-DD')
            ],
            chartLoaded: false,
            metric_details: '',
            chartOptions: {
                title: {
                    text: ''
                },
                time: {
                    timezone: 'Europe/Paris',
                },
                navigator: {
                    //adaptToUpdatedData: false,
                },
                lang: {
                    noData: this.$t('three_dimensional_curve.data_loading_error')
                },
                noData: {
                    style: {
                        fontWeight: 'bold',
                        fontSize: '15px',
                        color: '#303030'
                    }
                },
                loading: {
                    hideDuration: 1000,
                    showDuration: 1000
                },
                scrollbar: {
                    liveRedraw: false,
                    height: 17,
                    barBackgroundColor: '#cfcfcf',
                    barBorderRadius: 9,
                    buttonBackgroundColor: '#c9c9c9',
                    buttonArrowColor: 'white',
                    buttonBorderWidth: 3,
                    buttonBorderRadius: 11,
                    rifleColor: 'white',
                    trackBorderRadius: 9
                },
                rangeSelector: {
                    enabled: false,
                },
                updateArgs: [true, true, true],
                xAxis: {
                    events: {
                        setExtremes: (e) => {
                            this.zoomInOut(e);
                        }
                    }
                },
                yAxis: {
                    title: {
                        text: '',
                    },
                    plotBands: [],
                },
                plotOptions: {
                    line: {
                        connectNulls: true,
                    },
                    series: {
                        marker: {
                            enabled: true,
                            radius: 4
                        }
                    }
                },
                series: [],
                credits: {
                    enabled: false
                },
            },
            chart: {
                preAlert: {
                    yMin: 0,
                    yMax: 0
                },
                alert: {
                    yMin: 0,
                    yMax: 0
                },
                selected: {
                    label: null,
                    date: null,
                    value: null
                },
            },
            loading: false,
            menu: false,
            menu2: false,
            dialog_delete_favorite_curve: false,
            display_flags: true,
            chartSeries: {
                series: null,
                flags: null,
            },
            dialog_update_thresholds: false,
            thresholds: {
                alert_high: null,
                pre_alert_high: null,
                alert_low: null,
                pre_alert_low: null
            },
            navigator_series: [],
            merger: 'MAX',
            number_of_point: 300,
            csv_export_loading: false,
        }
    },
    created() {
        window.moment = moment;
    },
    computed: {
        dateRangeText() {
            const from = moment(this.range[0]).format('DD-MM-YYYY');
            const to = moment(this.range[1]).format('DD-MM-YYYY');
            return `Du ${from} au ${to}`;
        },
    },
    watch:{
        ticker: function (i) {
            this.favorite_curve = this.item;
            this.loadChart();
        },
    },
    mounted() {
        this.initialize();
    },
    methods: {
        initialize() {
            this.loadChart();
        },

        // Chart managment
        async loadChart() {
            this.loading = true;
            this.$refs.highcharts.chart.hideNoData();
            this.$refs.highcharts.chart.showLoading();
            this.metric_details = await this.getMetricDetails(this.item_legacy.metric_uuid);
            let from = moment(this.range[0]).startOf('day').toISOString()
            let to = moment(this.range[1]).endOf('day').toISOString();

            if (moment(from).isAfter(to)) {
                [from, to] = [to, from];
            }
            let intervalDate = moment(from).valueOf() - moment(to).valueOf()
            if (intervalDate < 0) {
                intervalDate *= -1
            }

            let interval = this.calculInterval(intervalDate,this.number_of_point);

            const uri = `/api/machine/${this.id_machine}/metric/${this.item_legacy.metric_uuid}/` +
                `timestamp?start=${from}&end=${to}&interval=${interval}&merger=${this.merger}`;

            await this.axios.get(uri)
            
            .then((success) => {
                const data = success.data.data_metric;
                this.api_data = data;

                if (success.data.metric_thresholds) {
                    this.setThresholdsDialog(success.data.metric_thresholds);
                    this.setMetricThresholds();
                }
                this.buildSeries(data, this.item_legacy, true);
                this.chartOptions.yAxis.title.text = this.item_legacy.metric_unit;
            
            }).catch(() => {
                this.loading = false;
                this.$refs.highcharts.chart.hideLoading();
                this.$refs.highcharts.chart.showNoData(this.$t('three_dimensional_curve.data_loading_error'));
            })
        },
        async refresh() {
            this.loading = true;
            this.$refs.highcharts.chart.hideNoData();
            this.$refs.highcharts.chart.showLoading();
            let from = moment(this.range[0]).startOf('day').toISOString()
            let to = moment(this.range[1]).endOf('day').toISOString();

            if (moment(from).isAfter(to)) {
                [from, to] = [to, from];
            }

            let intervalDate = moment(from).valueOf() - moment(to).valueOf()
            if (intervalDate < 0) {
                intervalDate *= -1
            }

            let interval = this.calculInterval(intervalDate,this.number_of_point);

            const uri = `/api/machine/${this.id_machine}/metric/${this.item_legacy.metric_uuid}/` +
                `timestamp?start=${from}&end=${to}&interval=${interval}&merger=${this.merger}`;

            await this.axios.get(uri).then((success) => {
                const data = success.data.data_metric;
                this.api_data = data;

                if (success.data.metric_thresholds) {
                    this.setMetricThresholds();
                }
                this.buildSeries(data, this.item_legacy, true);
            }).catch(() => {
                this.loading= false;
                this.$refs.highcharts.chart.hideLoading();
                this.$refs.highcharts.chart.showNoData(this.$t('three_dimensional_curve.data_loading_error'));
            });
        },
        async zoomInOut(event) {
            this.loading = true;
            this.$refs.highcharts.chart.showLoading();
            let numberOfPoint = 200;

            // The min and max extreme in milliseconds
            const min = event.min;
            const max = event.max;

            // The interval in milliseconds
            const scrollbarInterval = max - min;

            let interval = this.calculInterval(scrollbarInterval,this.number_of_point);

            let from = moment(min).toISOString();
            let to = moment(max).toISOString();

            const uri = `/api/machine/${this.id_machine}/metric/${this.item_legacy.metric_uuid}/` +
                `timestamp?start=${from}&end=${to}&interval=${interval}&merger=${this.merger}`;

            await this.axios.get(
                uri
            ).then((success) => {
                let data = success.data.data_metric;
                this.buildSeries(data, this.item_legacy, false);
            }).catch(() => {
                this.loading= false;
                this.$refs.highcharts.chart.hideLoading();
                this.$refs.highcharts.chart.showNoData(this.$t('three_dimensional_curve.data_loading_error'));
            })
        },
        buildSeries(data, favorite_curve, boolUpdateNavigator) {
            const metrics = data.data;
            const operations = data.operations;
            const modalOps = this.$refs.modalOperation;
            let dataComponents = this;
            let series = [];
            let flags = [];
            metrics.forEach(metric => {
                let utcDate = (new Date(metric.date)).getTime();
                series.push([utcDate, parseFloat((parseFloat(metric.value) * 1e2 / 1e2).toFixed(4))]);

                /*if (metric.operation) {
                    let utcOperationDate = (new Date(metric.operation.date)).getTime();
                    let flag = {
                        x: utcOperationDate,
                        title: ' ',
                        text: metric.operation.label
                    };
                    flags.push(flag);
                }*/
            });

            operations.forEach(operation => {
                let utcOperationDate = (new Date(operation.date_operation)).getTime();
                let flag = {
                    x: utcOperationDate,
                    title: ' ',
                    text: operation.label
                };
                flags.push(flag);
            });

            let dataSeries = {
                name: this.item_legacy.metric_unit,
                id: 'dataseries',
                data: series,
                events: {
                    click: function (e) {
                        const value = {
                            favorite_curve: favorite_curve,
                            date: moment(e.point.category).toDate(),
                        };
                        dataComponents.date_operation_click = value;
                        modalOps.openDialogAddOperation(value);
                    },
                },
                navigatorOptions: {
                    visible: false
                },
            };

            let dataSeriesNavigator = {
                name: 'DataSeriesNavigator',
                data: series,
            };

            let dataFlags = {
                type: 'flags',
                data: flags,
                shape: 'url(/images/wrench.png)',
                width: 22
            };

            if (boolUpdateNavigator) {
                this.navigator_series = [dataSeriesNavigator];
            }
            this.chartOptions.navigator.series = this.navigator_series;
            this.chartSeries.series = dataSeries;
            this.chartSeries.flags = dataFlags;
            if (this.display_flags) {
                this.chartOptions.series = [dataSeries, dataFlags];
            } else {
                this.chartOptions.series = [dataSeries];
            }
            this.chartOptions.title.text = this.metric_details[0].device.device_name + ' ('+ this.metric_details[0].metric_uuid +')';
            this.$refs.highcharts.chart.hideLoading();
            
            if(!this.$refs.highcharts.chart.hasData()) {
                this.$refs.highcharts.chart.hideNoData();
                this.$refs.highcharts.chart.showNoData(this.$t('global.curve_no_data'));
            }
            this.loading= false;
        },
        TimeCurveCsvExport() {
            this.csv_export_loading= true;
            this.axios({
                url: DOWNLOAD_CSV_TEXT_CURVE,
                method: 'POST',
                data: {
                    'data': this.api_data.data,
                },
                responseType: 'blob',
            }).then((success) => {
                this.downloadCsvFile(success.data, "_TextCurve :" + this.item_legacy.metric_name + "_data.csv");
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('global.failure_to_download'));
            }).finally(()=>{
                this.csv_export_loading= false;
            });
        },
        toggleFullscreen() {
            this.axios.patch(  FAVORITE_CURVES + '/fullscreen/' + this.favoriteCurve.id
            ).then((success) => {
                
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));

            }).finally(() => {
                this.showSnackbar('error', this.$t('global.failure_to_add'));
            })
        },
        // 4Data function not valdated
        hideFlags() {
            if (this.display_flags) {
                this.chartOptions.series = [this.chartSeries.series, this.chartSeries.flags];
            } else {
                this.chartOptions.series = [this.chartSeries.series];
            }
        },

        // Tresholds management
        setThresholdsDialog(metricThresholds) {
            this.thresholds.pre_alert_low = metricThresholds['prealert-low'];
            this.thresholds.alert_low = metricThresholds['alert-low'];
            this.thresholds.pre_alert_high = metricThresholds['prealert-high'];
            this.thresholds.alert_high = metricThresholds['alert-high'];
        },
        setMetricThresholds() {
            this.chartOptions.yAxis.plotBands = [];
            if (this.thresholds.alert_high !== this.thresholds.pre_alert_high) {

                this.chartOptions.yAxis.plotBands.push(
                    {
                        from: this.thresholds.pre_alert_high,
                        to: this.thresholds.alert_high,
                        color: 'rgba(188, 151, 12, 0.2)',
                        label: {
                            text: 'Seuil de pré-alerte haute'
                        }
                    },
                    {
                        from: this.thresholds.alert_high,
                        to: 50000,
                        color: 'rgba(188, 12, 12, 0.2)',
                        label: {
                            text: 'Seuil d\'alerte haute'
                        }
                    }
                );
            }

            if (this.thresholds.pre_alert_low !== this.thresholds.alert_low) {

                this.chartOptions.yAxis.plotBands.push(
                    {
                        from: this.thresholds.pre_alert_low,
                        to: this.thresholds.alert_low,
                        color: 'rgba(188, 151, 12, 0.2)',
                        label: {
                            text: 'Seuil de pré-alerte basse'
                        }
                    },
                    {
                        from: this.thresholds.alert_low,
                        to: 0,
                        color: 'rgba(188, 12, 12, 0.2)',
                        label: {
                            text: 'Seuil d\'alerte basse'
                        }
                    }
                );
            }
        },
        updateThresholds() {
            this.axios.put(
                METRICS + '/' + this.item_legacy.metric_uuid, this.thresholds
            ).then(() => {
                this.showSnackbar('success', this.$t('global.success_to_edit'));
                this.loadChart();
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            }).finally(() => {
                this.dialog_update_thresholds = false;
            });
        },

        // 
        deleteFavoriteCurve() {
            this.axios.delete(
                FAVORITE_CURVES + '/' + this.item_legacy.id
            ).then(() => {
                this.$emit('deleteFavoriteCurve', this.item_legacy.id);
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_delete'))
            }).finally(() => {
                this.dialog_delete_favorite_curve = false;
            });
        },
        checkPrivilege() {
            return this.$store.state.access_level === 200 || this.$store.state.access_level === 300
        },
        showSnackbarForModalOperation(item) {
            this.showSnackbar(item.type, item.text);
            this.refresh()
            this.$emit('updateOperationDatatable');
        },
    }
}
</script>

<style scoped lang="scss">
.fullscreen-wrapper {
    background: white;
}

.fullscreen-wrapper.enabled {
    z-index: 10;
    padding: 50px;
}

.highcharts-flags-series > .highcharts-label > text {
    display: none !important;
}
</style>
