<template>
    <v-card elevation="2" outlined>
        <v-card-title class="justify-center">
            {{ $t('referential.physical_device_types') }}
        </v-card-title>

        <v-divider></v-divider>

        <v-data-table
            :headers="headers"
            :items="physical_device_types"
            sort-by="id"
            class="elevation-1 datatable-setting-min-height"
            :loading="loading"
            :items-per-page="5"
            :search="search">

            <template v-slot:top>
                <v-alert
                    border="left" colored-border type="warning"
                    elevation="2" class="mr-4 ml-4 mt-4" dense block
                >
                    {{ $t('referential.info_datatable_physical_device_type') }}
                </v-alert>

                <v-toolbar flat color="white" class="pt-3">
                    <v-row>
                        <v-col cols="8">
                            <v-btn color="primary" class="white--text mr-3" @click="openDialogAddPhysicalDeviceType()">
                                {{ $t('referential.add_physical_device_type') }}
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                :label="$t('global.search')"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-dialog v-model="dialog_edit" max-width="500px">
                        <v-card>
                            <validation-observer ref="observer" v-slot="{ invalid }">
                                <v-form ref="form">
                                    <v-card-title><span class="headline">{{ dialogTitle }}</span></v-card-title>

                                    <v-divider></v-divider>

                                    <v-card-text>
                                        <validation-provider :name="$t('virtual_device_specification.label')"
                                                             rules="required|max:30"
                                                             v-slot="{ errors }">
                                            <v-text-field
                                                :label="$t('virtual_device_specification.label')"
                                                v-model="edited_physical_device_type.label"
                                                :prepend-icon="'mdi-label-outline'"
                                                :error-messages="errors" solo
                                            />
                                        </validation-provider>
                                        <validation-provider :name="$t('global.chirp_stack_profile_number')"
                                                             rules="required|max:36"
                                                             v-slot="{ errors }">
                                            <v-text-field
                                                :label="$t('global.chirp_stack_profile_number')"
                                                v-model="edited_physical_device_type.device_profil_chirpstack_id"
                                                :prepend-icon="'mdi-card-account-details-outline'"
                                                :error-messages="errors" solo
                                            />
                                        </validation-provider>

                                        <validation-provider :name="$t('global.image')" v-slot="{ errors }"
                                                             :rules="edited_index === null ? 'required' : ''">
                                            <v-file-input
                                                :label="$t('global.image')"
                                                v-model="edited_physical_device_type.image"
                                                :prepend-icon="'mdi-camera'"
                                                accept="image/png"
                                                :error-messages="errors" solo
                                            ></v-file-input>
                                        </validation-provider>
                                    </v-card-text>

                                    <v-divider></v-divider>

                                    <v-card-actions>
                                        <v-spacer/>
                                        <v-btn @click="closeDialog()">
                                            <v-icon left>mdi-close</v-icon>
                                            {{ $t('global.close') }}
                                        </v-btn>
                                        <v-btn type="submit" color="primary" :disabled="invalid"
                                               @click.prevent="prepareEditPhysicalDeviceType()">
                                            <v-icon left>mdi-content-save</v-icon>
                                            {{ $t('global.validation') }}
                                        </v-btn>
                                    </v-card-actions>
                                </v-form>
                            </validation-observer>
                        </v-card>
                    </v-dialog>

                    <v-dialog v-model="dialog_delete" max-width="500px">
                        <v-card>
                            <v-card-title>
                                <span class="headline">{{ $t('referential.delete_physical_device_type') }}</span>
                            </v-card-title>

                            <v-divider></v-divider>
                            <br/>

                            <v-card-actions>
                                <v-spacer/>
                                <v-btn @click="dialog_delete = false">
                                    <v-icon left>mdi-close</v-icon>
                                    {{ $t('global.cancel') }}
                                </v-btn>
                                <v-btn color="error" @click="confirmDelete()">
                                    <v-icon left>mdi-delete</v-icon>
                                    {{ $t('global.delete') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <v-snackbar v-model="snackbar" :color="snackbar_type">
                        {{ snackbar_text }}
                        <template v-slot:action="{ attrs }">
                            <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                        </template>
                    </v-snackbar>
                </v-toolbar>
            </template>

            <template v-slot:item.image="{ item }">
                <v-img aspect-ratio="1" v-if="item.image" class="mb-2 mt-2" :src="item.image"
                       height="200" contain>
                </v-img>
            </template>

            <template v-slot:item.action="{ item }">
                <v-icon class="pointer" @click="openDialogUpdatePhysicalDeviceType(item)">mdi-pencil</v-icon>
                <v-icon class="pointer" @click="openDialogDeletePhysicalDeviceType(item)">mdi-delete</v-icon>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
import {REFERENTIAL_PHYSICAL_DEVICE_TYPES} from "../../../../api";

export default {
    name: "DatatablePhysicalDeviceType",
    data() {
        return {
            physical_device_types: [],
            headers: [
                {text: this.$t('global.intern_number'), value: 'uid'},
                {text: this.$t('global.label'), value: 'label'},
                {text: this.$t('global.chirp_stack_profile_number'), value: 'device_profil_chirpstack_id'},
                {text: this.$t('global.image'), value: 'image'},
                {text: this.$t('global.actions'), value: 'action'}
            ],
            loading: true,
            search: '',
            invalid: false,

            dialog_edit: false,
            edited_index: null,
            edited_physical_device_type: {
                id: null,
                device_profil_chirpstack_id: '',
                label: '',
                image: null,
            },
            refresh_cache: true,
            valid: true,

            dialog_delete: false,
            delete_id: null,
            deleted_index: null,
        }
    },
    computed: {
        dialogTitle() {
            return this.edited_index === null ? this.$t('referential.add_physical_device_type') : this.$t('referential.edit_physical_device_type');
        }
    },
    created() {
        this.getPhysicalDeviceTypes();
    },
    methods: {
        getPhysicalDeviceTypes() {
            this.axios.get(
                REFERENTIAL_PHYSICAL_DEVICE_TYPES
            ).then((success) => {
                this.physical_device_types = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            }).finally(() => {
                this.loading = false
            });
        },

        openDialogAddPhysicalDeviceType() {
            if (this.edited_index !== null) {
                this.$refs.form.reset();
                this.$refs.observer.reset();
                this.edited_index = null;
            }

            this.dialog_edit = true;
        },

        openDialogUpdatePhysicalDeviceType(physical_device) {
            this.edited_index = this.physical_device_types.indexOf(physical_device);
            this.edited_physical_device_type = Object.assign({}, physical_device);
            this.edited_physical_device_type.image = null;
            this.dialog_edit = true;
        },

        prepareEditPhysicalDeviceType() {
            this.edited_index !== null ? this.updatePhysicalDeviceType() : this.addPhysicalDeviceType();
        },

        updatePhysicalDeviceType() {
            let formData = new FormData();
            formData.append('label', this.edited_physical_device_type.label);
            formData.append('chirp_stack_profil_id', this.edited_physical_device_type.device_profil_chirpstack_id);
            formData.append('image', this.edited_physical_device_type.image);

            this.axios.post(
                REFERENTIAL_PHYSICAL_DEVICE_TYPES + '/' + this.edited_physical_device_type.id,
                formData, this.configurationFile
            ).then((success) => {
                this.refresh_cache = !this.refresh_cache;
                success.data.image += '?refresh_cache=' + this.refresh_cache;
                Object.assign(this.physical_device_types[this.edited_index], success.data);

                this.edited_index = null;
                this.showSnackbar('success', this.$t('global.success_to_edit'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            }).finally(() => {
                this.closeDialog();
            });
        },

        addPhysicalDeviceType() {
            let formData = new FormData();
            formData.append('label', this.edited_physical_device_type.label);
            formData.append('chirp_stack_profil_id', this.edited_physical_device_type.device_profil_chirpstack_id);
            formData.append('image', this.edited_physical_device_type.image);

            this.axios.post(
                REFERENTIAL_PHYSICAL_DEVICE_TYPES, formData, this.configurationFile
            ).then((success) => {
                this.physical_device_types.push(success.data);
                this.showSnackbar('success', this.$t('global.success_to_add'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_add'));
            }).finally(() => {
                this.closeDialog();
            });
        },

        openDialogDeletePhysicalDeviceType(physical_device) {
            this.deleted_index = this.physical_device_types.indexOf(physical_device);
            this.delete_id = physical_device.id;
            this.dialog_delete = true;
        },

        confirmDelete() {
            this.axios.delete(
                REFERENTIAL_PHYSICAL_DEVICE_TYPES + '/' + this.delete_id
            ).then(() => {
                this.physical_device_types.splice(this.deleted_index, 1);
                this.showSnackbar('success', this.$t('global.success_to_delete'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_delete'));
            }).finally(() => {
                this.dialog_delete = false;
            });
        },

        closeDialog() {
            this.dialog_edit = false;
            this.$refs.form.reset();
            this.$refs.observer.reset();
        }
    }
}
</script>
