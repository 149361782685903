<template>
    <v-card class="rounded-lg" elevation="2" outlined>
        <v-card-subtitle>
            <v-btn @click="setNotificationSeenAll()" color="primary" class="mt-3" :disabled="notification_seen">
                {{ $t('notification.view_all') }}
            </v-btn>
            <!-- DELETE DUPLCITATED
                <v-btn @click="openDialogDeleteDuplicates" color="error" class="mt-3">
                    {{ $t('notification.del_duplicates') }}
                </v-btn>
            -->
            <v-spacer/>
        </v-card-subtitle>

        <!-- ALL DATATABLE -->
        <v-data-table
            :headers="headers"
            :items="notifications"
            class="elevation-1"
            :item-per-page=-1
            :search="search"
            sort-by="created_at"
            :sort-desc=true
        >
            <template v-slot:top>
                <v-toolbar flat color="white" class="pt-3 mb-3">
                    <v-row>
                        <v-col cols="2">
                            <v-btn @click="filterNotification()" class="mt-3">
                                {{ $t('notification.view_false') }}
                                <v-switch
                                    v-model="notification_seen"
                                    color="primary" disabled
                                    class="mt-5 pr-2 pl-2"
                                ></v-switch>
                                {{ $t('notification.view_true') }}
                            </v-btn>
                        </v-col>
                        <v-col cols="4">
                            <v-select
                                class="mt-1"
                                :items="notification_levels"
                                :label="$t('notification.select_notification')"
                                v-model="notification_level_selected"
                                item-text="level_name"
                                item-value="level"
                                @change="getNotification()"
                                solo chips multiple deletable-chips
                            >
                            </v-select>
                        </v-col>
                        <v-col cols="2"/>
                        <v-col>
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                :label="$t('global.search')"
                                class="mt-3"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-snackbar
                        v-model="snackbar"
                        :color="snackbar_type">
                        {{ snackbar_text }}
                        <template v-slot:action="{ attrs }">
                            <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                        </template>
                    </v-snackbar>
                    <!-- VIEW NOTIF -->
                    <!--<v-dialog v-model="dialog_show" max-width="500px" eager>
                        <v-card>
                            <v-card-title>
                                <h2>{{ $t('notification.modal_title') }}{{ notification.id }}</h2>
                            </v-card-title>
                            <v-card-text>

                                <v-row>
                                    <v-col>
                                        <h3>{{ $t('notification.subject') }}</h3>
                                        <p>{{ notification.subject }}</p>
                                    </v-col>
                                </v-row>

                                <h3>{{ $t('notification.message') }}</h3>
                                <p>{{ notification.message }}</p>

                                <v-row>
                                    <v-col>
                                        <h3>{{ $t('notification.tags') }}</h3>
                                        <pre>{{ jsonParseTags }}</pre>
                                    </v-col>
                                    <v-col>
                                        <h3>{{ $t('notification.level') }}</h3>
                                        <p>{{ notification.level_name }}</p>
                                    </v-col>
                                    <v-col>
                                        <h3>{{ $t('notification.created_at') }}</h3>
                                        <p>{{ notification.created_at }}</p>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn @click="dialog_show = false">
                                    <v-icon left>mdi-close</v-icon>
                                    {{ $t('global.close') }}
                                </v-btn>

                                <v-btn @click="setNotificationSeen(notification)" color="primary" v-if="!notification.seen">
                                    <v-icon left>mdi-eye-check</v-icon>
                                    {{ $t('notification.set_view_true') }}
                                </v-btn>

                                <v-btn @click="setNotificationSeen(notification)" color="primary" v-else>
                                    <v-icon left>mdi-eye-remove</v-icon>
                                    {{ $t('notification.set_view_false') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>-->
                    <v-dialog v-model="dialog_show" max-width="500px" eager>
                    <v-card style="overflow:hidden">
                        <v-card-title class="row  ml-1 mt-2" style="display:flex;justify-content:space-between;">
                            <h3 style="font-size:1.1rem" >{{ notification.subject }}</h3>
                            <p style="font-size: 12px;margin-right:30px;font-weight: 400;">{{ notification.created_at }}</p>
                        </v-card-title>
                        <v-card-text>
                            <div style="border-radius:2px;background-color:#ebf4ff;padding:10px;margin:20px 0px">
                                <p>{{ notification.message }}</p>
                            </div>

                            <v-row>
                                <v-col>
                                    <h4>{{ $t('notification.tags') }}</h4>

                                    <template>
                                        <v-chip-group>
                                            <!--<v-chip v-if="jsonParseTags === null" color="grey">{{ $t('global.no_tags_associated') }}</v-chip>-->
                                            <v-chip v-for="tag in jsonParseTags" class="pl-3 pr-3" link :key="tag">
                                                {{ tag }}
                                            </v-chip>
                                        </v-chip-group>
                                    </template>
                                </v-col>
                                <v-col>
                                    <h4 style="text-align:center">{{ $t('notification.level') }}</h4>
                                    <p class="ma-4 pl-3 pr-3 v-chip v-chip--clickable v-chip--link v-chip--no-color theme--light v-size--default" style="color:white;font-weight:800;display:flex;justify-content:center;text-shadow:2px 2px 4px rgba(0, 0, 0, 0.8)" :style="{backgroundColor: notification.level_color}">{{ notification.level_name }}</p>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions class="mb-3">
                            <v-spacer/>
                            <v-btn @click="dialog_show = false;">
                                <v-icon left>mdi-close</v-icon>
                                {{ $t('global.close') }}
                            </v-btn>

                            <v-btn @click="setNotificationState(notification, 1)" color="primary" v-if="!notification.seen">
                                <v-icon left>mdi-eye-check</v-icon>
                                {{ $t('notification.set_view_true') }}
                            </v-btn>

                            <v-btn @click="setNotificationState(notification, 0)" color="primary" v-else>
                                <v-icon left>mdi-eye-remove</v-icon>
                                {{ $t('notification.set_view_false') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                    <!-- DELETE MODAL 
                        <v-dialog v-model="dialog_delete" max-width="500px">
                            <v-card>
                                <v-card-title>
                                    <span class="headline">{{ $t('notification.del_duplicates') }}</span>
                                </v-card-title>
                                <v-divider/>
                                <br/>
                                <v-card-actions>
                                    <v-spacer/>
                                    <v-btn @click="dialog_delete = false">
                                        <v-icon left>mdi-close</v-icon>
                                        {{ $t('global.cancel') }}
                                    </v-btn>
                                    <v-btn color="error" @click="confirmDelete">
                                        <v-icon left>mdi-delete</v-icon>
                                        {{ $t('notification.del_duplicates') }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    -->

                </v-toolbar>
            </template>

            <!--FILTER MAX SIZE MESSAGE ITEM-->
            <template v-slot:item.message="{ item }">
                {{ item.message.substring(0, 100) + '...' }}
            </template>
            <!--ACTION BUTTONS -->
            <template v-slot:item.action="{ item }">
                <v-icon class="pointer" @click="setNotificationSeen(item)" v-if="!notification_seen">
                    mdi-eye-check
                </v-icon>
                <v-icon class="pointer" @click="setNotificationUnseen(item)" v-if="notification_seen">
                    mdi-eye-remove
                </v-icon>
                <v-icon class="pointer" @click="openDialogShow(item)">
                    mdi-arrow-expand-all
                </v-icon>
            </template>

        </v-data-table>
    </v-card>
</template>

<script>
import {NOTIFICATIONS, USERS} from "../../api";
import moment from "moment";

export default {
    name: "DatatableNotifications",
    data() {
        return {
            whos:[],
            serialized_who: "",
            notifications: [],
            notification: {
                id: null,
                message: '',
                level: null,
                level_name: '',
                link: '',
                tags: '',
                seen: 0,
                created_at: '',
                subject: '',
                updated_at: '',
                who: '',
                from: '',
            },
            headers: [
                {text: this.$t("notification.level_name"), value: 'level.name'},    
                {text: this.$t("notification.subject"), value: 'subject'},
                {text: this.$t("notification.message"), value: 'message'},
                {text: this.$t("notification.tags"), value: 'tags'},
                {text: this.$t("notification.from"), value: 'from'},
                {text: this.$t("notification.created_at"), value: 'created_at'},
                {text: this.$t("notification.actions"), value: 'action'},
            ],
            loading_datatable: true,
            search: '',
            notification_seen: false,
            dialog_show: false,
            dialog_delete: false,
            notification_level_selected: [],
            notification_levels: [
                {
                    level: 0,
                    level_name: "blank"
                },
                {
                    level: 1,
                    level_name: "green"
                },
                {
                    level: 2,
                    level_name: "yellow"
                },
                {
                    level: 3,
                    level_name: "red"
                },
                {
                    level: 4,
                    level_name: "purple"
                },
            ]
        }
    },
    created() {
        this.setWhosGood();
    },
    computed: {
        jsonParseTags() {
            if (this.notification.tags !== "") {
                return JSON.parse(this.notification.tags);
            }
        },
    },
    methods: {
        setWhosGood() {
            this.axios.get(USERS + '/professionals')
            
            .then((success) => {
                success.data.forEach(professional => {this.whos.push(professional.uid)});
                this.whos.push(this.$auth.user().uid);
                this.whos.push(this.$store.state.access_level);
                this.serialized_who = this.whos.join('-');
                                
                //----------------------------------------------------------------------------------------
                //DEBUG :
                //console.log("whos "+ this.whos);
                //console.log("serialized_who "+ this.serialized_who);
            
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            
            }).finally(() => {
                this.getNotification();
            })          
        },

        getNotification() {
            this.loading_datatable = true;
            this.axios.get(NOTIFICATIONS + '?seen=' + this.notification_seen + '&who=' + this.serialized_who + '&level=')
            
            .then((success) => {
                success.data.forEach(notification => {
                    notification.created_at = moment(notification.created_at).format('DD/MM/YYYY HH:mm');
                })
                
                //----------------------------------------------------------------------------------------
                //DEBUG :
                //console.log("data returned "+ success.data);

                this.notifications = success.data;

                //----------------------------------------------------------------------------------------
                //DEBUG :
                //console.log("data parsed "+ this.notifications);
                //console.log("data collapsed "+ this.notifications[0].subject);
            
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            
            }).finally(() => {
                this.loading_datatable = false
            })
        },

        filterNotification() {
            this.notification_seen = !this.notification_seen;
            this.getNotification();
        },

        setNotificationSeenAll() {
            this.axios.patch(NOTIFICATIONS + '/seen_all')
            
            .then(() => {
                this.showSnackbar('success', this.$t('global.success_to_edit'));
            
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            
            }).finally(() => {
                this.getNotification()
            })
        },

        setNotificationSeen(notification) {
            this.axios.patch( NOTIFICATIONS + '/seen/' + notification.id)
            
            .then(() => {
                this.notifications.splice(this.notifications.indexOf(notification), 1)
                this.dialog_show = false;
                this.showSnackbar('success', this.$t('global.success_to_edit'));
            
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            
            }).finally(() => {
                this.getNotification()
            })
        },

        setNotificationUnseen(notification) {
            this.axios.patch( NOTIFICATIONS + '/unseen/' + notification.id)
            
            .then(() => {
                this.notifications.splice(this.notifications.indexOf(notification), 1)
                this.dialog_show = false;
                this.showSnackbar('success', this.$t('global.success_to_edit'));
            
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            
            }).finally(() => {
                this.getNotification()
            })
        },

        openDialogShow(notification) {
            this.notification = this.notifications[this.notifications.indexOf(notification)];
            this.dialog_show = true;
        },

        openDialogDeleteDuplicates() {
            this.Dialog_delete = true;
        },

        confirmDelete() {
            this.axios.delete(NOTIFICATIONS + '/delete_duplicates')
            
            .then(() => {
                this.Dialog_delete = false;
                this.showSnackbar('success', this.$t('global.success_to_delete'));
            
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_delete'));
            
            }).finally(() => {
                this.getNotification()
            })
        },
    }
}
</script>
