<template>
    <div id="contracts-datatable" class="pa-4">
        <v-row no-gutters>
            <v-col>
                <p class="title mb-0 color-primary text-uppercase"> {{ $t('contracts_datatable.title') }}</p>
            </v-col>
        </v-row>

        <v-card width="100%" class="mt-4" elevation="0">
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :search="search"
                    :items="contracts"
                    :loading="contract_data_table_loading"
                >
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-layout class="justify-start mt-7">
                                <p class="mt-3">{{ $t('global.filter_by') }}</p>
                                <v-select
                                    :items="professionals"
                                    v-model="professionals_id"
                                    :placeholder="$t('contracts_datatable.filter')"
                                    item-text="name" item-value="id"
                                    filled dense class="ml-4 filter-width"
                                    chips multiple deletable-chips
                                ></v-select>
                            </v-layout>

                            <v-layout class="justify-end mt-7 max-width-300">
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    :label="$t('global.search')"
                                ></v-text-field>
                            </v-layout>
                        </v-toolbar>

                        <v-layout class="justify-end mt-2 mb-2">
                            <v-btn color="primary" class="mr-3" @click="downloadCsvContract()">
                                <v-progress-circular
                                    v-if="loading_csv_file"
                                    indeterminate color="white" size="20" width="2"
                                />
                                <v-icon v-else left>mdi-download-box-outline</v-icon>
                                {{ $t('global.download') }}
                            </v-btn>
                        </v-layout>
                    </template>

                    <template v-for="referential_contract_type in referential_contract_types"
                              v-slot:[`item.${referential_contract_type.id}`]="{ item }"
                    >
                            <span :key="referential_contract_type.id">
                                <template v-for="contract_type in item.contract_types">
                                    <template
                                        v-if=" contract_type.referential_contract_type_id===referential_contract_type.id"
                                    >
                                        <template v-if="contract_type.is_active > 0">
                                            <v-row>
                                                <v-tooltip top color="primary">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <span v-bind="attrs" v-on="on">
                                                            {{ contract_type.associated_devices_count }} /
                                                        </span>
                                                    </template>
                                                     <span>
                                                         {{ $t('contracts_datatable.current_contract_type_value') }}
                                                     </span>
                                                </v-tooltip>

                                                <v-tooltip top color="primary">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <span v-bind="attrs" v-on="on">
                                                            {{ contract_type.value_max }}
                                                        </span>
                                                    </template>
                                                    <span>{{ $t('contracts_datatable.max_contract_type_value') }}</span>
                                                </v-tooltip>
                                            </v-row>
                                        </template>

                                        <template v-else>
                                            <v-icon>mdi-cancel</v-icon>
                                        </template>
                                    </template>
                                </template>
                            </span>
                    </template>

                    <template v-slot:item.date_renew="{ item }">
                        <span> {{ formatDate(item.date_renew) }}</span>
                    </template>

                    <template v-slot:item.invoicing_period="{ item }">
                        <span> {{ item.invoicing_period }} {{ $t('global.months') }}</span>
                    </template>

                    <!--<template v-slot:item.contract_types.length="{ item }">
                        <span>{{ countActiveContracts(item.contract_types) }}</span>
                    </template>-->

                    <template v-slot:item.action="{ item }">
                        <v-tooltip top color="primary">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" @click="$refs.ticket.addTicketDialogOpen(item.id)">
                                    mdi-file-plus-outline
                                </v-icon>
                            </template>
                            <span>{{ $t('contracts_datatable.add_ticket_notification') }}</span>
                        </v-tooltip>

                        <v-tooltip top color="primary">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" @click="$refs.edit.editDialogOpen(item)">mdi-pencil
                                </v-icon>
                            </template>
                            <span>{{ $t('global.edit') }}</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <add-expert-ticket ref="ticket" @contract-updated="refreshContractDataTable"></add-expert-ticket>

        <edit-contract ref="edit" @contract-updated="refreshContractDataTable"></edit-contract>
        <global-snackbar/>
    </div>
</template>

<script>
import moment from 'moment';
import AddExpertTicket from "../../components/Contract/ModalAddExpertTicket";
import {
    CONTRACTS,
    DOWNLOAD_CSV_CONTRACT,
    PROFESSIONALS,
    REFERENTIAL_CONTRACT_TYPES
} from "../../../api";
import EditContract from "../../components/Contract/EditContract";
import GlobalSnackbar from "../../../global/components/GlobalSnackbar";

export default {
    name: "ContractsDatatable",
    components: {GlobalSnackbar, EditContract, AddExpertTicket},
    data() {
        return {
            professionals: [],
            contracts_type: [],
            professionals_id: [],
            filter_professionals_id: '',
            search: '',
            loading_csv_file: false,
            contract_data_table_loading: true,
            headers: [],
            contracts: [],
            referential_contract_types: [],
            contract_type_value: '',
        }
    },
    created() {
        this.getReferentialContractTypes();
        this.getProfessionals();
        this.getContracts();
    },
    watch: {
        professionals_id() {
            this.contract_data_table_loading = true;
            this.filter_professionals_id = '';
            if (this.professionals_id.length !== 0) {
                this.professionals_id.forEach((professional_id) => {
                    this.filter_professionals_id += (this.filter_professionals_id === '' ? professional_id : ',' + professional_id);
                });
            }
            this.getContracts();
        }
    },
    methods: {
        getReferentialContractTypes() {
            this.axios.get(
                REFERENTIAL_CONTRACT_TYPES
            ).then((success) => {
                this.referential_contract_types = success.data;
                this.headers.push(
                    {text: this.$t('contracts_datatable.contract_number'), value: 'contract_number'},
                    {text: this.$t('contracts_datatable.label'), value: 'label'},
                    {text: this.$t('contracts_datatable.filter'), value: 'professional.name'},
                );
                success.data.forEach((referential_contract_type) => {
                    this.headers.push({
                        text: referential_contract_type.label, value: referential_contract_type.id
                    });
                });
                this.headers.push(
                    {text: this.$t('contracts_datatable.expert'), value: 'expert_ticket'},
                    {text: this.$t('contracts_datatable.billing_period'), value: 'invoicing_period'},
                    {text: this.$t('contracts_datatable.next_debit_date'), value: 'date_renew'},
                    {text: this.$t('contracts_datatable.contract_document'), value: 'document_number'},
                    {text: this.$t('global.actions'), value: 'action'}
                );
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('global.error'));
            }).finally(() => {
                this.contract_data_table_loading = false;
            });
        },

        getContracts() {
            this.contract_data_table_loading = true;
            this.axios.get(
                CONTRACTS + '?professionals_id=' + this.filter_professionals_id
            ).then((success) => {
                this.contracts = success.data
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('global.error'));
            }).finally(() => {
                this.contract_data_table_loading = false;
            });
        },

        formatDate(value) {
            return moment(String(value)).format('DD/MM/YYYY');
        },

        getProfessionals() {
            this.axios.get(PROFESSIONALS).then((success) => {
                this.professionals = success.data;
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('global.error'));
            });
        },

        refreshContractDataTable() {
            this.getContracts();
            this.contract_data_table_loading = false;
            this.showGlobalSnackbar('success', this.$t('global.success_to_edit'));
        },

        downloadCsvContract() {
            this.loading_csv_file = true;
            this.axios({
                url: DOWNLOAD_CSV_CONTRACT + '?professionals_id=' + this.filter_professionals_id,
                method: 'GET',
                responseType: 'blob',
            }).then((success) => {
                this.downloadCsvFile(success.data, "_contracts.csv");
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('global.error'));
            }).finally(() => {
                this.loading_csv_file = false;
            });
        }
    }
}
</script>

<style scoped>
.filter-width {
    max-width: 400px;
}
</style>
