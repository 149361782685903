<template>
    <div id="admin-dashboard" class="pa-4">
        <!-- Page title -->
        <v-row no-gutters>
            <v-col>
                <p class="title mb-0 color-primary text-uppercase">{{ $t('dashboard_admin.title') }}</p>
            </v-col>
        </v-row>

        <!-- KPI stats -->
        <template v-if="$auth.user() && $store.state.access_level < 300">
            <v-row no-gutters class="pt-4">
                <v-col class="pr-2">
                    <kpi-physical-devices></kpi-physical-devices>
                </v-col>
                <v-col class="pl-2">
                    <kpi-gateways></kpi-gateways>
                </v-col>
            </v-row>
        </template>
        
        <!--Map side -->
        <div class="pt-4">
            <gmap-map :center="center" :zoom="7" class="gmap-map" ref="gmap">
                <google-map-cluster :styles="clusterStyles" :zoomOnClick="true">
                    <gmap-marker
                        v-for="(gateway, index) in gateways"
                        :icon="{ url: '/images/cluster/marker.png' }"
                        :position="{ lat: parseFloat(gateway.latitude) , lng: parseFloat(gateway.longitude) }"
                        :clickable="true" :draggable="false"
                        :key="index"
                        @mouseover="showByIndex = index"
                        @mouseout="showByIndex = null"
                    >
                        <gmap-info-window :opened="showByIndex === index">
                            <h3 class="text-center">{{ $t('navbar.gateways') }}</h3>
                            <v-divider/>
                            <p>{{ $t('dashboard_admin.gateway_number') }} : {{ gateway.uid }}</p>
                            <p>{{ $t('dashboard_admin.gateway_address') }} : {{ gateway.address }}</p>
                            <p>
                                {{ $t('dashboard_admin.gateway_state') }} :
                                {{ checkGatewayAndPhysicalDevicesStates(gateway.state) }}
                            </p>
                        </gmap-info-window>
                    </gmap-marker>
                </google-map-cluster>
            </gmap-map>
        </div>

        <v-snackbar v-model="snackbar" top :color="snackbar_type">
            {{ snackbar_text }}
                                <template v-slot:action="{ attrs }">
                        <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                    </template>
        </v-snackbar>
    </div>
</template>

<script>
import KpiPhysicalDevices from '../components/Dashboard/kpi-physical-devices';
import KpiGateways from '../components/Dashboard/kpi-gateways';
import GoogleMapCluster from 'vue2-google-maps/dist/components/cluster';
import {GATEWAYS, VIRTUAL_DEVICES} from "../../api";
import InstallerSwitchInterface from "../components/Installer/InstallerSwitchInterface";

export default {
    name: "Dashboard",
    components: {
        InstallerSwitchInterface,
        KpiPhysicalDevices,
        KpiGateways,
        GoogleMapCluster,
    },
    data() {
        return {
            gateways: [],
            center: {lat: 45.12505363614733, lng: 2.817760749593558},
            virtual_devices: [],
            showByIndex: null,
            clusterStyles: [
                {
                    url: "/images/cluster/m3.png",
                    height: 66,
                    width: 65,
                    textSize: 20
                },
            ],
        }
    },
    created() {
        this.check_Access();
        this.getGateways();
        this.getVirtualDevices();
    },
    methods: {
        getGateways() {
                this.axios.get(
                    this.$store.state.access_level === 200 ? GATEWAYS + '?installer_proprietary=true' : GATEWAYS
                ).then((success) => {
                    this.gateways = success.data;
                    this.$refs.gmap.$mapPromise.then((map) => {
                        let bounds = new google.maps.LatLngBounds();
                        this.gateways.forEach((marker) => {
                            bounds.extend(new google.maps.LatLng(marker.latitude, marker.longitude));
                        });
                        map.fitBounds(bounds);
                        if (this.gateways.length === 1) {
                            map.setZoom(13);
                        }
                    });
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                });
        },

        getVirtualDevices() {
            this.axios.get(
                 VIRTUAL_DEVICES
            ).then((success) => {
                this.virtual_devices = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            });
        },

        check_Access(){
            if(this.$store.state.access_level == 200){
                this.$router.push({name: 'dashboard_loading'});
            }
        }
    },
}
</script>

<style scoped>
.gmap-map {
    width: 100%;
    height: 600px;
}
</style>


