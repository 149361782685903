<template>
    <v-card elevation="2" outlined>
        <v-card-title class="justify-center">
            {{ $t('datatable_machine_subassemblies.title') }}
        </v-card-title>
        <v-divider></v-divider>

        <v-data-table
            :headers="headers"
            :items="machine_subassemblies"
            sort-by="id"
            class="elevation-1 datatable-setting-min-height"
            :loading="loading"
            :items-per-page="5"
            :search="search">

            <template v-slot:top>
                <v-toolbar flat color="white" class="pt-3">
                    <v-row>
                        <v-col cols="8">
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                :label="$t('global.search')"
                            ></v-text-field>
                        </v-col>
                    </v-row>


                    <v-dialog v-model="dialog_bind_subassembly" max-width="500px">
                        <v-card>
                            <validation-observer ref="observer" v-slot="{ invalid }">
                                <v-form ref="form">
                                    <v-card-title class="justify-center">
                                <span class="headline">
                                    {{ $t('datatable_machine_subassemblies.associate_to_another_machine') }}
                                </span>
                                    </v-card-title>
                                    <v-divider></v-divider>
                                    <br/>

                                    <v-card-text>
                                        <validation-provider :name="$t('global.label')" rules="required"
                                                             v-slot="{ errors }">
                                            <v-select
                                                :items="machines"
                                                v-model="machine_id_to_bind"
                                                :label="$t('navbar.machines')"
                                                :prepend-inner-icon="'mdi-state-machine'"
                                                item-text="label"
                                                item-value="id" solo
                                                :error-messages="errors"
                                            ></v-select>
                                        </validation-provider>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-spacer/>
                                        <v-btn @click="closeDialog()">
                                            <v-icon left>mdi-close</v-icon>
                                            {{ $t('global.cancel') }}
                                        </v-btn>
                                        <v-btn color="primary" @click="bindSubassembly()"
                                               :disabled="invalid"
                                        >
                                            <v-icon left>mdi-link-variant-off</v-icon>
                                            {{ $t('global.dissociate') }}
                                        </v-btn>
                                    </v-card-actions>
                                </v-form>
                            </validation-observer>
                        </v-card>
                    </v-dialog>

                    <v-snackbar
                        v-model="snackbar"
                        :color="snackbar_type">
                        {{ snackbar_text }}
                        <template v-slot:action="{ attrs }">
                            <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                        </template>
                    </v-snackbar>
                </v-toolbar>
            </template>

            <template v-slot:item.label="{ item }">
                <router-link :to="{ path: '/subassembly/' + item.id}">
                    {{ item.label }}
                </router-link>
            </template>

            <template v-slot:item.machine.path_plan="{ item }">
                <v-img aspect-ratio="1" v-if="item.machine.path_plan" class="mb-2 mt-2" :src="item.machine.path_plan"
                       height="200" contain>
                    <template v-slot:placeholder>
                        <v-row
                            class="fill-height ma-0 background-loader-image"
                            align="center" justify="center"
                        >
                            <v-progress-circular indeterminate color="black lighten-5"></v-progress-circular>
                        </v-row>
                    </template>
                </v-img>
            </template>

            <template v-slot:item.path_image="{ item }">
                <v-img aspect-ratio="1" v-if="item.path_image" class="mb-2 mt-2" :src="item.path_image" height="200"
                       contain>
                    <template v-slot:placeholder>
                        <v-row
                            class="fill-height ma-0 background-loader-image"
                            align="center" justify="center"
                        >
                            <v-progress-circular indeterminate color="black lighten-5"></v-progress-circular>
                        </v-row>
                    </template>
                </v-img>
            </template>

            <template v-slot:item.virtual_devices="{ item }">
                <span v-if="item.virtual_devices.length === 0">{{
                        $t('datatable_machine_subassemblies.no_virtual_devices')
                    }}</span>
                <template v-else v-for="virtual_device in item.virtual_devices">
                    <v-chip :key="virtual_device.id" class="mt-1 mb-1" :color="assetColor(virtual_device)"
                            @click="$store.state.access_level !== 300 && $store.state.access_level !== 400 ? $router.push({ name: 'device', params: {id: virtual_device.id} }) : ''"
                            text-color="white"
                    >
                        {{ virtual_device.label }}
                    </v-chip>
                    <br/>
                </template>
            </template>

            <template v-slot:item.action="{ item }">
                <v-icon class="pointer" @click="openDialogBindSubassembly(item)">mdi-link-variant-off</v-icon>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
import {SUBASSEMBLIES, MACHINES} from "../../../api";

export default {
    name: "DatatableMachineSubassemblies",
    data() {
        return {
            machine_id: null,
            machines: [],
            machine_subassemblies: [],
            subassemblies: [],
            headers: [
                {text: this.$t('global.name'), value: 'label'},
                {text: this.$t('datatable_machine_subassemblies.location_subassembly'), value: 'machine.path_plan'},
                {text: this.$t('global.visualization'), value: 'path_image'},
                {text: this.$t('datatable_machine_subassemblies.virtual_devices'), value: 'virtual_devices'},
                {text: this.$t('global.actions'), value: 'action'}
            ],
            loading: true,
            search: '',
            invalid: false,

            dialog_bind_subassembly: false,
            loading_bind_subassembly: false,
            subassembly_id: null,
            machine_id_to_bind: null
        }
    },
    created() {
        this.machine_id = this.$route.params.id;
        this.getMachineSubassemblies();
        this.getMachines();
    },
    methods: {
        getMachineSubassemblies() {
            this.loading = true;

            this.axios.get(
                MACHINES + "/" + this.machine_id + "/subassemblies"
            ).then((success) => {
                this.machine_subassemblies = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'))
            }).finally(() => {
                this.loading = false;
            });
        },

        getMachines() {
            this.axios.get(
                MACHINES
            ).then((success) => {
                this.machines = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            });
        },

        openDialogBindSubassembly(machine) {
            this.subassembly_id = machine.id;
            this.dialog_bind_subassembly = true;
        },

        bindSubassembly() {
            this.axios.put(
                SUBASSEMBLIES + '/' + this.subassembly_id + '/bind/machine/' + this.machine_id_to_bind,
            ).then(() => {
                this.getMachineSubassemblies();
                this.showSnackbar('success', this.$t('global.success_to_edit'));
                this.$refs.form.reset();
                this.$refs.observer.reset();
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            }).finally(() => {
                this.closeDialog();
            });
        },

        closeDialog() {
            this.machine_id_to_bind = null;
            this.dialog_bind_subassembly = false;
        }
    }
}
</script>
