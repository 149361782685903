<template>
    <div id="professional-detail">
        <v-row>
            <v-col style="background-color: white">
                <v-subheader>
                    <p class="title mb-0">
                        {{ $t('professionals.title') }}
                    </p>
                </v-subheader>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: "ProfessionalDetail"
}
</script>