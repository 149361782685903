<template>
    <div id="profile-view">
        <v-row>
            <v-col class="ml-6 mr-6 pt-5 pl-1" style="background-color: #001047;border-radius: 0px 0px 5px 5px;">
                <v-subheader class="v-subheader col theme--light">
                    <!------------------- Info bulle ---------------------->
                    <v-tooltip bottom dark class="mr-2 ml-2">
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 mr-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                                <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:default>
                        <div style="min-width: 200px;position:relative;z-index: 10;">
                            <strong>Page [Gestion de votre compte]</strong>
                            <p>Visualisez et modifiez ici vos informations personnelles et paramètres de préférencces.
                            </p>
                        </div>
                        </template>
                    </v-tooltip>
                    <p class="title mb-0 color-white" style="color: white!important;">
                        <div style="color:#FFFFFFb3;margin-right:4px;">Configuration > </div><div @click="infoToggler()" style="color:#FFFFFF;font-weight:bold;"> {{ $t('profile.profile') }}</div>
                    </p>
                </v-subheader>
            </v-col>
        </v-row>

        <v-card class="mt-8 ml-3 mr-3" elevation="0">
            <validation-observer ref="observer" v-slot="{ invalid }">
                <v-form ref="form">
                    <v-card-text>
                        <validation-provider :name="$t('profile.last_name')" rules="required|alpha|max:40"
                                             v-slot="{ errors }"
                        >
                            <v-text-field :label="$t('profile.last_name')"
                                          v-model="edit_user.last_name"
                                          :error-messages="errors"
                                          filled clearable
                            />
                        </validation-provider>

                        <validation-provider :name="$t('profile.first_name')" rules="required|alpha|max:40"
                                             v-slot="{ errors }"
                        >
                            <v-text-field v-model="edit_user.first_name"
                                          :label="$t('profile.first_name')"
                                          :error-messages="errors"
                                          filled clearable
                            />
                        </validation-provider>

                        <validation-provider :name="$t('profile.email')" rules="required|email" v-slot="{ errors }">
                            <v-text-field v-model="edit_user.email"
                                          :label="$t('profile.email')"
                                          :error-messages="errors"
                                          filled clearable
                            />
                        </validation-provider>

                        <validation-provider :name="$t('profile.phone')" rules="required|digits:10" v-slot="{ errors }">
                            <v-text-field v-model="edit_user.phone"
                                          :label="$t('profile.phone')"
                                          :error-messages="errors"
                                          filled clearable
                            />
                        </validation-provider>

                        <v-btn @click="$refs.passwordEdit.dialog_edit_password = true"
                               :block="is_mobile" color="primary"
                        >
                            {{ $t('profile.modify_own_password') }}
                        </v-btn>
                    </v-card-text>

                    <v-card-actions class="px-4 pb-4 justify-end">
                        <v-btn @click="updateUserProfile()" :disabled="invalid" :block="is_mobile"
                               :loading="loading_user_profile" color="primary">
                            {{ $t('profile.update_profile') }}
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </validation-observer>
        </v-card>

        <professional-tree-structure/>

        <password-edit ref="passwordEdit"/>
    </div>
</template>

<script>
import PasswordEdit from "../components/PasswordEdit";
import ProfessionalTreeStructure from "../components/ProfessionalTreeStructure";
import {ADMIN_USERS, USERS} from "../../api";

export default {
    name: "ProfileView",
    components: {PasswordEdit, ProfessionalTreeStructure},
    data() {
        return {
            invalid: false,
            loading_user_profile: false,
            edit_user: {
                id: '',
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
            },
        }
    },
    created() {
        this.edit_user.id = this.$auth.user().id;
        this.edit_user.first_name = this.$auth.user().first_name;
        this.edit_user.last_name = this.$auth.user().last_name;
        this.edit_user.email = this.$auth.user().email;
        this.edit_user.phone = this.$auth.user().phone;
    },
    methods: {
        updateUserProfile() {
            if(this.$refs.form.validate()) {
                let route = (this.$store.state.access_level === 0 ? ADMIN_USERS : USERS);
                this.loading_user_profile = true;

                this.axios.put(
                    route + '/' + this.edit_user.id, this.edit_user
                ).then(() => {
                    this.updateAuthUser();
                    this.showGlobalSnackbar('success', this.$t('profile.profile_updated'));
                }).catch(() => {
                    this.manageError(error, this.$t('profile.error_update_profile'))
                }).finally(() => {
                    this.loading_user_profile = false;
                });
            }
        },

        updateAuthUser() {
            this.$auth.user().first_name = this.edit_user.first_name;
            this.$auth.user().last_name = this.edit_user.last_name;
            this.$auth.user().email = this.edit_user.email;
            this.$auth.user().phone = this.edit_user.phone;
        }
    },
}
</script>

