<template>
    <div id="machine-detail">
        <v-row>
            <v-col style="background-color: white">
                <v-subheader>
                    <v-col>
                        <p class="title mb-0">
                            {{ $t('machines.detail') }} : {{ label }}
                        </p>
                    </v-col>

                    <v-col cols="3">
                        <modal-comparison-curves v-bind:parentType="type" justify-end
                                                 v-on:getMachineComparisonCurves="getMachineComparisonCurves"
                                                 v-on:getMachineThreeDimensionalCurves="getMachineThreeDimensionalCurves">
                        </modal-comparison-curves>
                    </v-col>
                </v-subheader>
            </v-col>
        </v-row>

        <v-row>
            <v-col id="fixed-col">
                <machine-state/>
            </v-col>
            <v-col>
                <machine-specifications v-on:updateLabelMachine="updateLabelMachine"/>
            </v-col>
            <v-col>
                <documents-upload v-bind:parentType="type" v-bind:parentId="id" v-bind:min-height="525"/>
            </v-col>
        </v-row>

        <datatable-operations :entity-name="'machine'" class="mt-6"/>

        <v-row class="mt-6">
            <template v-for="(comparison_curve) in comparison_curves">
                <v-col cols="6">
                    <comparison-curves v-bind:comparison-curve="comparison_curve"
                                       v-on:deleteComparisonCurves="deleteComparisonCurves"
                                       :key="comparison_curve.id"
                    />
                </v-col>
            </template>


            <template v-for="three_dimensional_curve in three_dimensional_curves">
                <v-col cols="6">
                    <three-dimensional-curves v-bind:three-dimensional-curve="three_dimensional_curve"
                                              v-on:deleteThreeDimensionalCurve="deleteThreeDimensionalCurve"
                                              :key="three_dimensional_curve.id"
                    />
                </v-col>
            </template>
        </v-row>


        <datatable-machine-subassemblies class="mt-6"/>

        <v-snackbar
            v-model="snackbar"
            :color="snackbar_type">
            {{ snackbar_text }}
            <template v-slot:action="{ attrs }">
                <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
            </template>
        </v-snackbar>
    </div>
</template>
<script>

import DocumentsUpload from "../../components/DocumentsUpload";
import MachineSpecifications from "../../components/Machine/MachineSpecifications";
import MachineState from "../../components/Machine/MachineState";
import DatatableMachineSubassemblies from "../../components/Machine/DatatableMachineSubassemblies";
import DatatableOperations from "../../components/DatatableOperations";
import ModalComparisonCurves from "../../components/Machine/ComparisonCurves/ModalComparisonCurves";
import ComparisonCurves from "../../components/Machine/ComparisonCurves/ComparisonCurves";
import ThreeDimensionalCurves from "../../components/Machine/ThreeDimensionalCurves/ThreeDimensionalCurves";

import {COMPARISON_CURVES, THREE_DIMENSION_CURVES} from "../../../api";

export default {
    name: "DetailMachine",
    components: {
        ThreeDimensionalCurves,
        DocumentsUpload,
        MachineSpecifications,
        MachineState,
        DatatableMachineSubassemblies,
        DatatableOperations,
        ModalComparisonCurves,
        ComparisonCurves
    },
    data() {
        return {
            id: this.$route.params.id,
            type: "machine",
            label: '',
            comparison_curves: [],
            three_dimensional_curves: [],
        }
    },
    created() {
        this.getMachineComparisonCurves();
        this.getMachineThreeDimensionalCurves();
    },
    methods: {
        getMachineComparisonCurves() {
            this.axios.get(
                COMPARISON_CURVES + "?id_entity=" + this.id + "&type=machine"
            ).then((success) => {
                this.comparison_curves = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            });
        },

        getMachineThreeDimensionalCurves() {
            this.axios.get(
                THREE_DIMENSION_CURVES + "?id_entity=" + this.id + '&type=machine'
            ).then((success) => {
                    this.three_dimensional_curves = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            });
        },

        deleteComparisonCurves(item) {
            let removed_item= this.comparison_curves.splice(this.comparison_curves.findIndex(
                comparison_curve => comparison_curve.id === item
            ), 1);
        },

        deleteThreeDimensionalCurve(item){
            this.three_dimensional_curves.splice(this.three_dimensional_curves.findIndex(
                three_dimensional_curve => three_dimensional_curve.id === item
            ),1);
            this.showSnackbar('success', this.$t('global.success_to_delete'));
        },

        updateLabelMachine(item) {
            this.label = item.label;
        }
    }
}
</script>
<style scoped lang="scss">
#fixed-col {
    min-width: 638px;
    max-width: 638px !important;
}
@media only screen and (max-width: 480px) {
    #fixed-col {
        min-width: 100%;
        max-width: 100% !important;
    }
}
</style>
