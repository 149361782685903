<template>
    <v-col class="col mt-0"> 
        <v-data-table
            v-if="!view_details"
            :headers="headers"
            :items="machines"
            item-key="label"
            sort-by="label"
            :group-by="['building.label']"
            :group-header="customGroupHeader"
            hide-default-header
            class="elevation-1 datatable-operation"
            :items-per-page="-1"
            :loading="loading_datatable"
            :search="search"
            style="border-radius: 5px; overflow:hidden;"
            no-data-text="Vous n'avez aucun équipement. Créez votre premier équipement via le bouton (+) ci dessus."
        >
            <!-- TOP -->
            <template v-slot:top>
                <!-- SEARCH -->
                <v-toolbar flat>
                    <div> 
                        <v-tooltip bottom dark>
                            <template v-slot:activator="{ on }">
                                <v-btn class="ma-0 mr-5 col-auto elevation-3" v-on="on" text icon @click="viewDetails()" dusk="logout-button" color="primary" style="max-width: 50px !important;min-width: 45px !important;border-radius: 25px;background-color: #001047;">
                                    <v-icon color="white">mdi-eye</v-icon>
                                </v-btn>
                            </template> 
                            <span>Afficher le détail des campagnes</span>
                        </v-tooltip>
                    </div>

                    <v-col class="col-8">
                        <div class="elevation-0 pl-5 pt-2 pb-2" >
                            <h4>Liste des équipements réunit par regroupement</h4>
                        </div>
                    </v-col>
                    <!-- SEARCH -->
                    <v-col style="display: flex; justify-content: end;">
                        <v-layout class="justify-end mt-7 max-width-300">
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                :label="$t('global.search')"
                            ></v-text-field>
                        </v-layout>
                    </v-col>

                    <v-snackbar
                        v-model="snackbar"
                        :color="snackbar_type">
                        {{ snackbar_text }}
                        <template v-slot:action="{ attrs }">
                            <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                        </template>
                    </v-snackbar>
                </v-toolbar>
            </template>

            <!-- Slot personnalisé pour les groupes -->
            <template v-slot:group="{ group, items }">
                <div
                    class="group-header pb-3 pt-3"
                    @click="toggleGroup(group)"
                    style="cursor: pointer;"
                >
                    <v-icon class="mr-2 pl-5" style="font-size: 24px;">
                        {{ isGroupOpen(group) ? 'mdi-chevron-down' : 'mdi-chevron-right' }}
                    </v-icon>
                    <strong>{{ group }}</strong> ({{ items.length }})
                </div>

                <div v-if="isGroupOpen(group)">
                    <v-data-table
                        :headers="headers2"
                        :items="items"
                        item-key="name"
                        hide-default-footer
                        class="sub-table"
                    >
                        <!-- ALERT LEVEL -->
                        <template v-slot:item.alert_level="{ item }" >
                            <v-col v-if="(Number(item.alert_level) !== 0 && Number(item.alert_level) > 10 && Number(item.alert_level) < 20)" class="col justify-start h-100 pt-7" style="max-width: 60px;background-color: #d3ad00bf;height:90%;border-radius:5px;">
                                <v-row v-if="$store.state.access_level === 0" style="color:white;" class="row mb-3 justify-center"> #{{ item.id }} </v-row>
                                <v-icon color="white" large>
                                    mdi-alert
                                </v-icon>
                            </v-col>

                            <v-col v-if="(Number(item.alert_level) !== 0 && Number(item.alert_level) > 20 && Number(item.alert_level) < 30)" class="col justify-start h-100 pt-7" style="max-width: 60px;background-color: #a90000bf;height:90%;border-radius:5px;">
                                <v-row v-if="$store.state.access_level === 0" style="color:white;" class="row mb-3 justify-center"> #{{ item.id }} </v-row>
                                <v-icon color="white" large>
                                    mdi-alert
                                </v-icon>
                            </v-col>

                            <v-col v-if="(Number(item.alert_level) !== 0 && Number(item.alert_level) > 30)" class="col justify-start h-100 pt-7" style="max-width: 60px;background-color: #000000bf;height:90%;border-radius:5px;">
                                <v-row v-if="$store.state.access_level === 0" style="color:white;" class="row mb-3 justify-center"> #{{ item.id }} </v-row>
                                <v-icon color="white" large>
                                    mdi-alert
                                </v-icon>
                            </v-col>


                            <v-col v-if="(Number(item.alert_level) === -1 || Number(item.alert_level) === 0)" class="col justify-start h-100 pt-7" style="max-width: 60px;background-color: #939393bf;height:90%;border-radius:5px;">
                                <v-row v-if="$store.state.access_level === 0" style="color:white;" class="row mb-3 justify-center"> #{{ item.id }} </v-row>
                                <v-icon color="white" large>
                                    mdi-help-rhombus
                                </v-icon>
                            </v-col>
                            
                            <v-col v-if="(Number(item.alert_level) === 1 || Number(item.alert_level) === 2 || Number(item.alert_level) === 3 || Number(item.alert_level) === 9)" class="col justify-start h-100 pt-7" style="max-width: 60px;background-color: #006600bf;height:90%;border-radius:5px;">
                                <v-row v-if="$store.state.access_level === 0" style="color:white;" class="row mb-3 justify-center"> #{{ item.id }} </v-row>
                                <v-icon color="white" large>
                                    mdi-check-circle
                                </v-icon>
                            </v-col>
                        </template>

                        <!-- IMAGES -->
                        <template v-slot:item.img="{ item }">
                            <v-col class="pt-5 pl-0" style="max-width:250px;max-height:250px; position: relative; top: -50px;">
                                <machine-state :machine-on="item.id"/>
                            </v-col>
                        </template>

                        <!-- INFOS -->
                        <template v-slot:item.infos="{ item }">
                            <!-- INFOS -->
                            <v-col class="col pl-5">
                                <div class="row" style="align-items: center;">
                                    <v-chip class="mb-5 mt-5" v-if="item.label !== null" v-on="on" link :to="'/machine/' + item.id + '/report'" style="font-weight: bold; background-color: #f3f3f3; border: 1px solid #e5e5e5;">{{item.label}}</v-chip>
                                    <!------------------- Info bulle ---------------------->
                                    <v-tooltip bottom dark class="mr-2" style="">
                                        <template v-slot:activator="{ on }">
                                            <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                                                <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                                            </v-btn>
                                        </template>
                                        <template v-slot:default>
                                        <div style="min-width: 200px;">
                                            <p>**Cliquer sur une des machines ci dessous vous redirigera vers sa page.</br>
                                                Vous y trouverez directement les derniers rapports, évènements ou encore les caractéristiques mécanique du moyen.
                                            </p>
                                        </div>
                                        </template>
                                    </v-tooltip>
                                </div>
                                <div v-if="item.uid !== null" style="font-size: 12px;margin-left: 0px;">{{ $t('machines.ref') }}{{item.uid}}</div>
                                <div v-if="item.created_at !== null" style="font-size: 12px;margin-left: 0px;">{{ $t('machines.created_at') }}{{item.created_at}}</div>
                                <div v-if="item.building.label !== null" style="font-size: 12px;margin-left: 0px;">{{ $t('machines.building') }} {{item.building.label}}</div>

                                <!--SUBASSEMBLIES--> 
                                <div class="col pa-0" style="font-size:12px;border-top: 1px solid rgba(211, 211, 211, 0.43);">  
                                    <v-row style="align-items: center;">
                                        <div class="pl-3" style="font-weight: bold;font-size: 13px;">{{ $t('machines.subassemblies') }} :</div>
                                        <!------------------- Info bulle ----------------------> 
                                        <v-tooltip bottom dark class="mr-2" style="">
                                            <template v-slot:activator="{ on }">
                                                <v-btn text icon v-on="on" @click="getNotification()" class="mb-5 mt-5 ml-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                                                    <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                                                </v-btn>
                                            </template>
                                            <template v-slot:default>
                                            <div style="min-width: 200px;">
                                                <p>Retrouvez ci dessous la liste des sous-ensemble que comporte cette machine.</p>
                                                <p>**Cliquer sur ce sous ensemble pour ouvrir son résumé.</p>
                                            </div>
                                            </template>
                                        </v-tooltip>
                                    </v-row> 
                                    <v-row v-if="!item.subassemblies[0]" class="row ma-0 pa-0 rounded" style="color: grey;justify-content: center;">
                                        <div @click="openDialogAddSubassembly()" style="color:grey;text-align: center;">{{ $t('machines.no_subassemblies') }}. <br> {{ $t('machines.click') }}.</div>
                                    </v-row>
                                    <!-- CHIPS -->
                                    <v-row v-if="item.subassemblies[0]" class="row col pa-0 ma-0">
                                        <div v-for="subassembly in item.subassemblies" :key="subassembly.id">
                                            <div v-for="filters in machine_filters" :key="filters">
                                                <v-chip v-if="$store.state.access_level <= 100 && filters.level == subassembly.alert_level && filters.level_name !== 'Tout voir'" @click="openDialogEditSubassembly(subassembly)" style="font-size: 12px;font-weight:bold;text-shadow: rgba(0, 0, 0, 0.29) 2px 2px 4px; color: white;" class="mr-2 mb-2 pointer" :style="{backgroundColor: filters.level_color}" ><span v-if="subassembly.virtual_devices.length > 0">({{ subassembly.virtual_devices.length }})</span> {{subassembly.label}}</v-chip>
                                                <v-chip v-if="$store.state.access_level > 100 && filters.level == subassembly.alert_level && filters.level_name !== 'Tout voir'" :to="{ path: '/subassembly/' + subassembly.id}" style="font-size: 12px;font-weight:bold;text-shadow: rgba(0, 0, 0, 0.29) 2px 2px 4px; color: white;" class="mr-2 mb-2 pointer" :style="{backgroundColor: filters.level_color}" ><span v-if="subassembly.virtual_devices.length > 0">({{ subassembly.virtual_devices.length }})</span> {{subassembly.label}}</v-chip>
                                            </div> 
                                        </div>
                                        <v-chip v-if="$store.state.access_level <= 100" @click="openDialogAddSubassembly()" style="font-size: 12px;" class="mr-2 mb-2 pointer" > + Ajouter un sous-ensemble</v-chip>  
                                    </v-row> 
                                </div>
                            </v-col>
                        </template>
                            
                        <!--COMMENT-->
                        <template v-slot:item.complementaires="{ item }">
                            <v-col :to="{ path: '/machine/' + item.id + '/report'}" class="pl-5 mt-3" style="border-left: 1px solid #d3d3d36e; font-size:12px; min-height: 100%; margin-top: 0px!important; padding-top: 20px;">
                                <div style="font-weight: bold;font-size: 13px;">{{ $t('machines.fonction') }} :</div> 
                                <v-row class="row col pa-0 pt-0 pb-1 ma-0 ml-0">
                                    <p class="ml-0 mb-0">{{ item.functionality }}</p>
                                </v-row>
                                <v-col v-if="item.note != null">
                                    <div style="font-weight: bold;font-size: 13px;" class="mt-2">{{ $t('machines.more_info') }} :</div> 
                                    <v-row class="row col pa-0 pt-0 pb-1 ma-0 ml-0">
                                        <p class="ml-0 mb-0">{{ item.note }}</p>
                                    </v-row>
                                </v-col>
                                    
                            </v-col>
                        </template>
                        
                        <!-- ACTIONS -->
                        <template v-slot:item.actions="{ item }" style="display: flex; align-items: flex-start;">
                            <v-col class="col-1 pa-2 mt-3" style="max-width: 80px;">
                                <v-tooltip v-if="!item.trash" bottom dark>
                                    <template v-slot:activator="{ on }">
                                        <v-btn class="ma-auto mr-5 ml-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + item.id}" dusk="logout-button">
                                            <v-icon color="primary">mdi-dots-horizontal-circle</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Voir la machine en détail</span>
                                </v-tooltip>
                                <v-tooltip v-if="!item.trash" bottom dark>
                                    <template v-slot:activator="{ on }">
                                        <v-btn class="ma-auto mr-5 ml-5 col-0" text icon v-on="on" @click="openDialogUpdateMachine(item)" dusk="logout-button">
                                            <v-icon color="primary">mdi-circle-edit-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('machines.edit_machine') }}</span>
                                </v-tooltip>
                                <v-tooltip v-if="!item.trash && $store.state.access_level <= 99" bottom dark>
                                    <template v-slot:activator="{ on }">
                                        <v-btn class="ma-auto mr-5 ml-5 col-0 mt-2" text icon v-on="on" @click="setDataTrash(item,1)" dusk="logout-button">
                                            <v-icon color="red">mdi-archive</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('machines.desactivate_machine') }}</span>
                                </v-tooltip>
                            </v-col>
                        </template>
                    </v-data-table>
                </div>
            </template>
        </v-data-table>

        <v-data-table
            v-if="view_details"
            :headers="headers"
            :items="machines"
            item-key="label"
            sort-by="label"
            :group-by="['building.label']"
            :group-header="customGroupHeader"
            hide-default-header
            class="elevation-1 datatable-operation"
            :items-per-page="-1"
            :loading="loading_datatable"
            :search="search"
            style="border-radius: 5px; overflow:hidden;"
            no-data-text="Vous n'avez aucun équipement. Créez votre premier équipement via le bouton (+) ci dessus."
        >
            <!-- TOP -->
            <template v-slot:top>
                <!-- SEARCH -->
                <v-toolbar flat>
                    <div> 
                        <v-tooltip bottom dark>
                            <template v-slot:activator="{ on }">
                                <v-btn class="ma-0 mr-5 col-auto elevation-3" v-on="on" text icon @click="viewDetails()" dusk="logout-button" color="red" style="max-width: 50px !important;min-width: 45px !important;border-radius: 25px;background-color: #f15959;">
                                    <v-icon color="white">mdi-eye-off</v-icon>
                                </v-btn>
                            </template>
                            <span>Masquer le détail des campagnes</span>
                        </v-tooltip>
                    </div>

                    <v-col class="col-8">
                        <div class="elevation-0 pl-5 pt-2 pb-2" >
                            <h4>Liste des équipements réunit par regroupement</h4>
                        </div>
                    </v-col>
                    <!-- SEARCH -->
                    <v-col style="display: flex; justify-content: end;">
                        <v-layout class="justify-end mt-7 max-width-300">
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                :label="$t('global.search')"
                            ></v-text-field>
                        </v-layout>
                    </v-col>

                    <v-snackbar
                        v-model="snackbar"
                        :color="snackbar_type">
                        {{ snackbar_text }}
                        <template v-slot:action="{ attrs }">
                            <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                        </template>
                    </v-snackbar>
                </v-toolbar>
            </template>
            <!-- Slot personnalisé pour les groupes -->
            <template v-slot:group="{ group, items }">
                <div
                    class="group-header pb-3 pt-3"
                    @click="toggleGroup(group)"
                    style="cursor: pointer;"
                >
                    <v-icon class="mr-2 pl-5" style="font-size: 24px;">
                        {{ isGroupOpen(group) ? 'mdi-chevron-down' : 'mdi-chevron-right' }}
                    </v-icon>
                    <strong>{{ group }}</strong> ({{ items.length }})
                </div>

                <div v-if="isGroupOpen(group)">
                    <v-data-table
                        :headers="headers3"
                        :items="items"
                        item-key="name"
                        hide-default-footer
                        class="sub-table"
                    >
                        <!-- ALERT LEVEL -->
                        <template v-slot:item.alert_level="{ item }" >
                            <v-col v-if="(Number(item.alert_level) !== 0 && Number(item.alert_level) > 10 && Number(item.alert_level) < 20)" class="col justify-start h-100 pt-4" style="max-width: 60px;background-color: #d3ad00bf;height:90%;border-radius:5px;">
                                <v-row v-if="$store.state.access_level === 0" style="color:white;" class="row mb-0 justify-center"> #{{ item.id }} </v-row>
                                <v-icon color="white" large>
                                    mdi-alert
                                </v-icon>
                            </v-col>

                            <v-col v-if="(Number(item.alert_level) !== 0 && Number(item.alert_level) > 20 && Number(item.alert_level) < 30)" class="col justify-start h-100 pt-4" style="max-width: 60px;background-color: #a90000bf;height:90%;border-radius:5px;">
                                <v-row v-if="$store.state.access_level === 0" style="color:white;" class="row mb-0 justify-center"> #{{ item.id }} </v-row>
                                <v-icon color="white" large>
                                    mdi-alert
                                </v-icon>
                            </v-col>

                            <v-col v-if="(Number(item.alert_level) !== 0 && Number(item.alert_level) > 30)" class="col justify-start h-100 pt-4" style="max-width: 60px;background-color: #000000bf;height:90%;border-radius:5px;">
                                <v-row v-if="$store.state.access_level === 0" style="color:white;" class="row mb-0 justify-center"> #{{ item.id }} </v-row>
                                <v-icon color="white" large>
                                    mdi-alert
                                </v-icon>
                            </v-col>


                            <v-col v-if="(Number(item.alert_level) === -1 || Number(item.alert_level) === 0)" class="col justify-start h-100 pt-4" style="max-width: 60px;background-color: #939393bf;height:90%;border-radius:5px;">
                                <v-row v-if="$store.state.access_level === 0" style="color:white;" class="row mb-0 justify-center"> #{{ item.id }} </v-row>
                                <v-icon color="white" large>
                                    mdi-help-rhombus
                                </v-icon>
                            </v-col>
                            
                            <v-col v-if="(Number(item.alert_level) === 1 || Number(item.alert_level) === 2 || Number(item.alert_level) === 3 || Number(item.alert_level) === 9)" class="col justify-start h-100 pt-4" style="max-width: 60px;background-color: #006600bf;height:90%;border-radius:5px;">
                                <v-row v-if="$store.state.access_level === 0" style="color:white;" class="row mb-0 justify-center"> #{{ item.id }} </v-row>
                                <v-icon color="white" large>
                                    mdi-check-circle
                                </v-icon>
                            </v-col>
                        </template>

                        <!-- INFOS -->
                        <template v-slot:item.infos="{ item }">
                            <!-- INFOS -->
                            <v-row class="col pl-5">
                                <div class="row" style="align-items: center;">
                                    <v-chip class="mb-5 mt-5" v-if="item.label !== null" v-on="on" link :to="'/machine/' + item.id + '/report'" style="font-weight: bold; background-color: #f3f3f3; border: 1px solid #e5e5e5;">{{item.label}}</v-chip>
                                    <!------------------- Info bulle ---------------------->
                                    <v-tooltip bottom dark class="mr-2" style="">
                                        <template v-slot:activator="{ on }">
                                            <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                                                <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                                            </v-btn>
                                        </template>
                                        <template v-slot:default>
                                        <div style="min-width: 200px;">
                                            <p>**Cliquer sur une des machines ci dessous vous redirigera vers sa page.</br>
                                                Vous y trouverez directement les derniers rapports, évènements ou encore les caractéristiques mécanique du moyen.
                                            </p>
                                        </div>
                                        </template>
                                    </v-tooltip>
                                </div>
                                <!-- ACTIONS -->
                                <div class="pa-0" style="max-width: 80px; display: flex;">
                                    <v-tooltip v-if="!item.trash" bottom dark>
                                        <template v-slot:activator="{ on }">
                                            <v-btn class="ma-auto col-0" text icon v-on="on" :to="{ path: '/machine/' + item.id}" dusk="logout-button">
                                                <v-icon color="primary">mdi-dots-horizontal-circle</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Voir la machine en détail</span>
                                    </v-tooltip>
                                    <v-tooltip v-if="!item.trash" bottom dark>
                                        <template v-slot:activator="{ on }">
                                            <v-btn class="ma-auto col-0" text icon v-on="on" @click="openDialogUpdateMachine(item)" dusk="logout-button">
                                                <v-icon color="primary">mdi-circle-edit-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('machines.edit_machine') }}</span>
                                    </v-tooltip>
                                    <v-tooltip v-if="!item.trash && $store.state.access_level <= 99" bottom dark>
                                        <template v-slot:activator="{ on }">
                                            <v-btn class="ma-auto col-0" text icon v-on="on" @click="setDataTrash(item,1)" dusk="logout-button">
                                                <v-icon color="red">mdi-archive</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('machines.desactivate_machine') }}</span>
                                    </v-tooltip>
                                </div>
                            </v-row>
                        </template>
                            
                        <!--SUBASSEMBLIES--> 
                        <template v-slot:item.subassemblies="{ item }">
                            <div class="col pa-0" style="font-size:12px;">  
                                    <v-row v-if="!item.subassemblies[0]" class="row ma-0 pa-0 rounded" style="color: grey;justify-content: center;">
                                        <div @click="openDialogAddSubassembly()" style="color:grey;text-align: center;">{{ $t('machines.no_subassemblies') }}. <br> {{ $t('machines.click') }}.</div>
                                    </v-row>
                                    <!-- CHIPS -->
                                    <v-row v-if="item.subassemblies[0]" class="row col pa-0 pt-1 pb-1 ma-0 ml-0">
                                        <div v-for="subassembly in item.subassemblies" :key="subassembly.id">
                                            <div v-for="filters in machine_filters" :key="filters">
                                                <v-chip v-if="$store.state.access_level <= 100 && filters.level == subassembly.alert_level && filters.level_name !== 'Tout voir'" @click="openDialogEditSubassembly(subassembly)" style="font-size: 12px;font-weight:bold;text-shadow: rgba(0, 0, 0, 0.29) 2px 2px 4px; color: white;" class="mr-2 mb-0 pointer" :style="{backgroundColor: filters.level_color}" ><span v-if="subassembly.virtual_devices.length > 0">({{ subassembly.virtual_devices.length }})</span> {{subassembly.label}}</v-chip>
                                                <v-chip v-if="$store.state.access_level > 100 && filters.level == subassembly.alert_level && filters.level_name !== 'Tout voir'" :to="{ path: '/subassembly/' + subassembly.id}" style="font-size: 12px;font-weight:bold;text-shadow: rgba(0, 0, 0, 0.29) 2px 2px 4px; color: white;" class="mr-2 mb-0 pointer" :style="{backgroundColor: filters.level_color}" ><span v-if="subassembly.virtual_devices.length > 0">({{ subassembly.virtual_devices.length }})</span> {{subassembly.label}}</v-chip>
                                            </div> 
                                        </div>
                                        <v-chip v-if="$store.state.access_level <= 100" @click="openDialogAddSubassembly()" style="font-size: 12px;" class="mr-2 mb-2 pointer" > + Ajouter un sous-ensemble</v-chip>  
                                    </v-row> 
                                </div>
                        </template>
                    </v-data-table>
                </div>
            </template>
        </v-data-table>
        
        <!----------- TO BE DELAYED ---------->
        <ul v-if="$store.state.access_level == 0">
            <!-- ACTIVE DATA VOID -->
            <li v-if="!machines_available && deprecated_available" class="rounded row notification_card mb-1 pa-10" style="border: 2px dotted #80808059;color: grey;justify-content: center;">
                <div style="color:grey;text-align: center;">{{ $t('machines.no_machine') }}. <br> {{ $t('machines.create_machine') }}.</div>
            </li>

            <!-- TRASHED DATA TITLE IF PRESENT-->
            <li v-if="deprecated_available && machines_available" class="row col-12 pb-5 mb-5 mt-10" style="text-align:center;display: flex;justify-content: center;border-bottom: 1px solid #8080801c;">
                {{ $t('machines.desactivate') }}
            </li>

            <!-- TRASHED DATA VOID-->
            <li v-if="!deprecated_available && machines_available" class="rounded row notification_card mb-1 pa-10" style="color: grey;justify-content: center;">
                <div style="color:grey;text-align: center;">{{ $t('machines.all_activated') }}</div>
            </li>

            <!-- ALL DATA VOID -->
            <li v-if="!machines_available && !deprecated_available && filter_mode != 9" class="rounded row notification_card mb-1 pa-10" style="color: grey;justify-content: center;">
                <div style="color:grey;text-align: center;">{{ $t('machines.no_equipment_category') }}.</div>
            </li>

            <!-- TRASHED DATA VIEW MORE-->
            <li v-if="deprecated_available && inactive_machines_count > 5" class="row notification_card mb-1" style="display: flex;flex-direction: row;justify-content: center;align-items: center;font-size: 16px;">
                <v-tooltip bottom dark>
                    <template v-slot:activator="{ on }">
                        <v-btn text icon v-on="on" @click="viewMore()" dusk="logout-button">
                            <v-icon color="primary">mdi-arrow-down-thin-circle-outline</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('machines.show-more') }}</span>
                </v-tooltip>
            </li>

            <!-- TRASHED DATA -->
            <li class="rounded pointer row notification_card mb-5" v-for="machine in machines_deactivated" :key="machine" style="border: solid 1px #80808010;background-color: #d9d9d9cc;color: grey; width:100%;">
                <div v-if="deprecated_available" class="row pa-3">
                    <!--CHIPS-->
                    <div v-for="filters in data_filters" :key="filters">
                        <div  v-if="filters.level == machine.level && filters.level_name !== 'Tout voir'" @click="openDialogShow(machine)" class="col-0 mr-5" style="color:white;font-weight:800;display:flex;justify-content:center;text-shadow:2px 2px 4px rgba(0, 0, 0, 0.29); height:100%;min-width: 8px;border-radius:2px 0px 0px 2px;" :style="{backgroundColor: filters.level_color}"></div>
                    </div>
                    <!--INFOS-->
                    <div @click="openDialogShow(machine)" class="col-3 ma-auto">
                        <div style="font-weight: bold;">{{machine.label}}</div>
                        <div style="font-size: 12px;">{{ $t('machines.ref') }} {{machine.uid}}</div>
                        <div style="font-size: 12px;">{{ $t('machines.created_at') }} :{{machine.created_at}}</div>
                        <div style="font-size: 12px;">{{ $t('machines.batiment') }} : {{machine.building.label}}</div>
                    </div>


                    <!--SUBASSEMBLIES-->
                    <div class="col pl-10" style="border-left: 1px solid #d3d3d36e; font-size:12px;">  
                        <div style="font-weight: bold;font-size: 13px;">{{ $t('machines.subassemblies') }} :</div> 
                        <v-row class="row col pa-0 pt-1 pb-1 ma-0 ml-2">
                            <div v-for="subassembly in machine.subassemblies" :key="subassembly.id">
                                <v-chip style="font-size: 12px;background-color: grey;" class="mr-2 mb-2">{{subassembly.label}}</v-chip>
                            </div>  
                        </v-row> 
                    </div>

                    <!--COMMENT-->
                    <div :to="{ path: '/machine/' + machine.id + '/report'}" class="col pl-10" style="border-left: 1px solid #d3d3d36e; font-size:12px;">
                        <div style="font-weight: bold;font-size: 13px;">{{ $t('machines.fonction') }} :</div> 
                        <v-row class="row col pa-0 pt-1 pb-1 ma-0">
                            <p class="mb-0">{{ machine.functionality }}</p>
                        </v-row> 
                        <div style="font-weight: bold;font-size: 13px;" class="mt-2">{{ $t('machines.more_info') }} :</div> 
                        <v-row class="row col pa-0 pt-1 pb-1 ma-0">
                            <p class="mb-0">{{ machine.note }}</p>
                        </v-row>                                
                    </div>

                    <!--ACTIONS -->
                    <v-col class="col-1 pa-2" style="max-width: 80px;">
                        <v-tooltip v-if="machine.trash && $store.state.access_level <= 99" bottom dark>
                            <template v-slot:activator="{ on }">
                                <v-btn class="ma-auto mr-5 ml-5 col-0" text icon v-on="on" @click="setDataTrash(machine,0)" dusk="logout-button">
                                    <v-icon color="primary">mdi-archive-arrow-up</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('machines.restore_machine') }}</span>
                        </v-tooltip>
                        <v-tooltip v-if="machine.trash && $store.state.access_level <= 100" bottom dark>
                            <template v-slot:activator="{ on }">
                                <v-btn class="ma-auto mr-5 ml-5 col-0 mt-2" text icon v-on="on" @click="openDialogDeleteMachine(machine,0)" dusk="logout-button">
                                    <v-icon color="red">mdi-delete-forever</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('machines.delete_machine') }}</span>
                        </v-tooltip>
                    </v-col>
                </div>
            </li>            
        </ul>

        <!----------- MODAL ---------->
        <!---------------------------->

        <!-- MACHINE ADD / EDIT MODAL -->
        <v-dialog v-model="dialog_edit" max-width="500px">
            <v-card>
                <validation-observer ref="observer" v-slot="{ invalid }">
                    <v-form ref="form">
                        <v-card-title>
                            <span class="headline">{{ dialogTitle }}</span>
                        </v-card-title>
                        <v-divider></v-divider>

                        <v-card-text>
                            <validation-provider :name="$t('global.name')" rules="required"
                                                    v-slot="{ errors }">
                                <v-text-field solo
                                                :label="$t('global.name')"
                                                v-model="edited_machine.label"
                                                :prepend-icon="'mdi-label-outline'"
                                                :error-messages="errors"
                                />
                            </validation-provider>

                            <validation-provider :name="$t('machines.building')"
                                                    rules="required"
                                                    v-slot="{ errors }">
                                <v-select
                                    :items="buildings"
                                    v-model="edited_machine.building_id"
                                    :label="$t('machines.building')"
                                    :prepend-icon="'mdi-office-building-outline'"
                                    :error-messages="errors"
                                    item-value="id"
                                    item-text="label" solo
                                >
                                </v-select>
                            </validation-provider>

                            <v-file-input
                                v-if="edited_index === null"
                                v-model="edited_machine.path_plan"
                                :label="$t('machines.machine_photo')"
                                @change="onImageChanged"
                                solo
                            ></v-file-input>


                            <validation-provider :name="$t('machines.utilisation')"
                                                    rules="required"
                                                    v-slot="{ errors }">
                                <v-text-field solo
                                                :label="$t('machines.utilisation')"
                                                v-model="edited_machine.functionality"
                                                :prepend-icon="'mdi-function'"
                                                :error-messages="errors"
                                />
                            </validation-provider>

                            <v-textarea rows="2" solo no-resize
                                        v-model="edited_machine.note"
                                        :label="$t('global.notes')"
                                        :prepend-icon="'mdi-comment-outline'"
                            />
                        </v-card-text>
                        <v-card-actions class="mb-3" style="position:absolute;top:10px;right:10px">
                            <v-btn @click="closeDialog()" style="color:red;max-width: 30px;padding: 0 0 0 5px;min-width: 10px!important;height: 30px!important;">
                                <v-icon left>mdi-close</v-icon>
                            </v-btn>
                        </v-card-actions>
                        <v-card-actions class="mb-3" style="display:flex;justify-content:center">
                            <v-btn type="submit" color="primary" :disabled="invalid"
                                    @click.prevent="prepareEditMachine()">
                                <v-icon left>mdi-content-save</v-icon>
                                {{ $t('global.validation') }}
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </validation-observer>
            </v-card>
        </v-dialog>

        <!-- MACHINE DELETE MODAL -->
        <v-dialog v-model="dialog_delete" max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="headline">
                        {{ $t('machines.delete_machine') }}
                    </span>
                </v-card-title>
                <v-divider></v-divider>
                <br/>

                <v-card-actions>
                    <v-spacer/>
                    <v-btn @click="dialog_delete = false">
                        <v-icon left>mdi-close</v-icon>
                        {{ $t('global.cancel') }}
                    </v-btn>
                    <v-btn color="error" @click="confirmMachineDelete()">
                        <v-icon left>mdi-delete</v-icon>
                        {{ $t('global.delete') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- SUB ADD MODAL -->
        <v-dialog v-model="sub_dialog_edit" max-width="700px">
            <v-card>
                <validation-observer ref="observer" v-slot="{ invalid }">
                    <!-- GLOBALS -->
                    <v-form v-if="sub_edit_location === 'add'" ref="formBindVirtualDevice">
                        <v-card-title>
                            <span class="headline row col">
                                {{ modal_sub_title }}
                            </span>
                            <div class="row col-3 mr-5">
                                <v-icon v-if="sub_edit_location === 'edit'" class="pointer" @click="openDialogDeleteSubassembly(edited_subassembly)">mdi-delete</v-icon>
                            </div>
                            <div class="row col-3 mr-5">
                                <v-btn @click="closeSubDialog()">
                                    <v-icon left>mdi-close</v-icon>
                                    {{ $t('global.close') }}
                                </v-btn>
                            </div>
                        </v-card-title>
                        <v-divider></v-divider>

                        <v-card-text>
                            <validation-provider
                                :name="$t('subassemblies.choose_subassembly_configuration')"
                                rules="required"
                                v-slot="{ errors }"
                            >
                                <!-- TODO : IF marker for add is on get defaul deplay -->
                                <!-- TODO : IF not get subassembly_type text on it -->
                                <v-select :items="subassembly_configurations"
                                            v-model="subassembly_configuration"
                                            :label="$t('subassemblies.choose_subassembly_configuration')"
                                            :prepend-icon="'mdi-code-json'"
                                            :error-messages="errors"
                                            return-object
                                            @change="parseSubassemblyConfigurationChoose()"
                                            item-text="label"
                                            item-value="configuration" solo
                                >
                                </v-select>
                            </validation-provider>

                            <validation-provider :name="$t('global.label')" rules="required"
                                                    v-slot="{ errors }">
                                <v-text-field solo
                                                :label="$t('global.name')"
                                                v-model="edited_subassembly.label"
                                                :prepend-icon="'mdi-label-outline'"
                                                :error-messages="errors"
                                />
                            </validation-provider>
                            
                            <!-- TODO : IF marker for add is on get defaul deplay -->
                            <!-- TODO : IF not get path text image name on it -->
                            <v-file-input
                                v-if="edited_index === null"
                                v-model="edited_subassembly.path_image"
                                :label="$t('global.image')"
                                solo
                                @change="onImageChanged"
                            ></v-file-input>

                            <validation-provider
                                :name="$t('subassembly_specifications.machine')"
                                rules="required"
                                v-slot="{ errors }"
                            >
                                <v-select :items="machines"
                                            v-model="edited_subassembly.machine_id"
                                            :label="$t('subassembly_specifications.machine')"
                                            :prepend-icon="'mdi-state-machine'"
                                            :error-messages="errors"
                                            item-value="id" solo
                                >
                                    <template slot="selection" slot-scope="data">
                                        {{ data.item.label }}
                                    </template>
                                    <template slot="item" slot-scope="data">
                                        {{ data.item.label }}
                                    </template>
                                </v-select>
                            </validation-provider>

                            <v-row class="mt-1 mb-1">
                                <template v-for="configuration in subassembly_configuration_choose">
                                    <v-select
                                        v-model="configuration.value"
                                        style = "width : 240px"
                                        v-if="configuration.type === 'selector'"
                                        class="mr-3 ml-3"
                                        :label="configuration.title" solo
                                        :prepend-icon="configuration.icon ? configuration.icon : 'mdi-comment-outline'"
                                        :items="configuration.choice"
                                        item-text ="text"
                                        item-value ="id"
                                        :key ="configuration"
                                    />

                                    <v-text-field class="ml-3 mr-3"
                                                    v-else
                                                    v-show="!configuration.hidden"
                                                    :key="configuration.index"
                                                    :label="configuration.title" solo
                                                    v-model="configuration.value"
                                                    :type="configuration.type === 'integer' ? 'number' : ''"
                                                    :rules="configuration.required ? [v => !!v || $t('vee-validation.required_field')] : []"
                                                    :prepend-icon="configuration.icon ? configuration.icon : 'mdi-comment-outline'"
                                    />
                                </template>
                            </v-row>

                            <v-textarea rows="2" solo no-resize
                                        v-model="edited_subassembly.note"
                                        :label="$t('global.notes')"
                                        :prepend-icon="'mdi-comment-outline'"
                            />
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer/>
                            <v-btn color="primary" :disabled="invalid"
                                    @click="prepareEditSubassembly()">
                                <v-icon left>mdi-content-save</v-icon>
                                {{ $t('global.validation') }}
                            </v-btn>
                        </v-card-actions>
                    </v-form>

                    <!-- ASSOCIATED SENSORS -->
                    <v-form v-if="sub_edit_location === 'edit'" ref="formBindVirtualDevice">
                        <v-card-title class="pa-10">
                            <span class="headline row col">
                                {{ $t('subassemblies.manage_binding_virtual_devices') }}
                            </span>
                            <div class="row col-1" style="margin-top: 0px;margin-right: 0px;">
                                <v-btn @click="closeSubDialog()" style="color:red;max-width: 30px;padding: 0 0 0 5px;min-width: 10px!important;height: 30px!important;">
                                    <v-icon left style="margin-left: 5px;margin-right: 10px;">mdi-close</v-icon>
                                </v-btn>
                            </div>
                        </v-card-title>
                        <v-card-text class="pa-10 pt-0">
                            <p class="mb-1" style="color:black!important;font-size: 15px;font-weight: bold;">{{ $t('subassemblies.subassembly') }}</p>
                            <div class="row col">
                                <div v-for="filters in datas_filters" :key="filters">
                                    <v-chip v-if="filters.level == edited_subassembly.alert_level" :to="{ path: '/subassembly/' + edited_subassembly.id}" style="font-size: 14px;font-weight:bold;text-shadow: rgba(0, 0, 0, 0.29) 2px 2px 4px; color: white;" class="mr-2 mb-2 pointer" :style="{backgroundColor: filters.level_color}" >
                                        <p style="font-weight: bold;">({{ edited_subassembly.virtual_devices.length }})   {{edited_subassembly.label}}</p>
                                    </v-chip>
                                </div>
                            </div>
                            <v-row>
                                <p class="mb-1" style="color:black!important;font-size: 15px;font-weight: bold;">{{ $t('subassemblies.devices') }}</p>
                                <!------------------- Info bulle ---------------------->
                                <v-tooltip bottom dark class="mr-2">
                                    <template v-slot:activator="{ on }">
                                        <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                                            <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                                        </v-btn>
                                    </template>
                                    <template v-slot:default>
                                    <div style="min-width: 200px;">
                                        <p>Capteurs associés au sous ensemble.</p>
                                        <p>Dans le cadre d'une instrumentation tous les capteurs étant physiquement présent sur le moyen sont recensés ici.</br>
                                            Cliquer sur un des capteurs vous permettra de visualiser tous les détails associés à celui-ci.
                                        </p>
                                    </div>
                                    </template>
                                </v-tooltip>
                            </v-row>
                            <div class="row col">
                                <div v-for="device in edited_subassembly.virtual_devices" :key="device">
                                    <div v-for="filters in datas_filters" :key="filters">
                                        <v-chip v-if="filters.level == device.alert_level && filters.level_name !== 'Tout voir'" :to="{ path: '/virtual_device/' + device.id}" style="font-size: 14px;font-weight:bold;text-shadow: rgba(0, 0, 0, 0.29) 2px 2px 4px; color: white;" class="mr-2 mb-2 pointer" :style="{backgroundColor: filters.level_color}" >{{device.label}}</v-chip>
                                    </div>
                                </div>
                            </div>
                        </v-card-text>
                        <div class="mr-5 ml-5 pl-10 pr-10 mb-5" style="border-radius:2px;background-color:#ebf4ff;padding:10px;">
                            <p style="color:black!important;font-size: 14px;">{{ $t('machines.ref') }}{{ edited_subassembly.uid }}</p>
                            <p style="color:black!important;font-size: 14px;">{{ $t('machines.type') }} {{ edited_subassembly.subassembly_type }}</p>
                            <p style="color:black!important;font-size: 14px;">{{ $t('machines.created_at') }}{{ edited_subassembly.created_at }}</p>
                            <p class="mt-1" style="color:black!important;font-size: 14px;">{{ $t('machines.note') }} {{ edited_subassembly.note }}</p>
                        </div>
                        <v-divider></v-divider>

                        <v-card-text>
                            <p class="pa-5 pb-0" style="color:black!important;font-size: 15px;font-weight: bold;">{{ $t('subassemblies.binder') }}</p>
                            <v-select
                                :items="virtual_devices"
                                v-model="virtual_devices_bind_to_subassembly"
                                :label="$t('subassemblies.virtual_device_to_bind')"
                                :prepend-inner-icon="'mdi-tag-multiple-outline'"
                                item-text="label"
                                item-value="id"
                                solo chips multiple clearable
                                class="ma-5"
                            >
                            </v-select>
                        </v-card-text>

                        <v-card-actions  class="pb-3" style="display:flex;justify-content:center">
                            <v-btn v-if="$store.state.access_level <= 100 && edited_subassembly.virtual_devices.length === 0" text icon v-on="on" @click="openDialogDeleteSubassembly(edited_subassembly)" class="mr-2" dusk="logout-button" color="red" style="border: 1px solid #ff00003b;width: auto;border-radius: 4px;padding: 0px 10px;">
                                <v-icon color="red" class="mr-2">mdi-delete-forever</v-icon>
                                <div>Supprimer le sous ensemble</div>
                            </v-btn>
                            <v-btn color="primary"
                                    @click="updateBindingVirtualDevice()">
                                <v-icon left>mdi-content-save</v-icon>
                                {{ $t('global.validation') }}
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </validation-observer>
            </v-card>
        </v-dialog>

        <!-- SUB DELETE MODAL -->
        <v-dialog v-model="sub_dialog_delete" max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="headline">
                        {{ $t('subassemblies.delete_subassembly') }}
                    </span>
                </v-card-title>
                <v-divider></v-divider>
                <br/>

                <v-card-actions>
                    <v-spacer/>
                    <v-btn @click="closeSubDialog()">
                        <v-icon left>mdi-close</v-icon>
                        {{ $t('global.cancel') }}
                    </v-btn>
                    <v-btn color="error" @click="confirmSubDelete()">
                        <v-icon left>mdi-delete</v-icon>
                        {{ $t('global.delete') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- SNACKBAR INFO DISPLAY -->
        <v-snackbar
            v-model="snackbar"
            :color="snackbar_type">
            {{ snackbar_text }}
            <template v-slot:action="{ attrs }">
                <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
            </template>
        </v-snackbar>

        <!--FILTER MAX SIZE MESSAGE ITEM-->
        <template v-slot:item.message="{ item }">
            {{ item.message.substring(0, 100) + '...' }}
        </template>

        <!--ACTION BUTTONS -->
        <template v-slot:item.action="{ item }">
            <v-icon class="pointer" @click="setDataTrash(item, 1)" v-if="!machine_trash">
                mdi-eye-check
            </v-icon>
            <v-icon class="pointer" @click="setDataTrash(item, 0)" v-if="machine_trash">
                mdi-eye-remove
            </v-icon>
            <v-icon class="pointer" @click="openDialogShow(item)">
                mdi-arrow-expand-all
            </v-icon>
        </template>

        <!-- Modal next update -->
        <v-dialog v-model="dialog_next_update" max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="headline">
                        Fonctionnalité bientôt disponible
                    </span>
                </v-card-title>
                <v-divider></v-divider>
                <br/>
                <p class="pl-4 pr-4 pb-4">
                    Cette fonctionnalité est en cours de développement et sera bientôt disponible.
                    Elle vous permettra entre autre de filter et de rechercher précisément via des mots clés votre cible.
                    Un rapport, une machine, une référence...
                    Le but, vous faire gagner du temps ! 
                </p>

                <!-- Bouton pour fermer -->
                <v-card-actions class="mb-3" style="position:absolute;top:5px;right:2px">
                    <v-btn @click="closeNewUpdateDialog();" style="color:red;max-width: 30px;padding: 0 0 0 5px;min-width: 10px!important;height: 30px!important;">
                        <v-icon left>mdi-close</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-col>
</template>

<script>
import {MACHINES, BUILDINGS,SUBASSEMBLIES, SUBASSEMBLY_CONFIGURATIONS, VIRTUAL_DEVICES} from "../../../api";
import MachineState from "../../../client/components/Machine/MachineState153";
import moment from "moment";

export default {
    name: "VisionCards",
    components: {
        MachineState,
    },
    data() {
        return {
            headers: [
                {text: "Regroupement", value: "building"}
            ],
            headers2: [
                {text: "Etat de santé", value: 'alert_level', width: '92px',},
                {text: "Images machines", value: 'img', width:'250px', sortable: false,},
                {text: "Informations machines", value: 'infos', width:'60%', sortable: false,},
                {text: "Informations complémentaires", value: 'complementaires', width:'25%', sortable: false,},
                {text: "Actions", value: 'actions', width:'5%', sortable: false,},
            ],

            view_details: false,
            headers3: [
                {text: "Etat de santé", value: 'alert_level', width: '92px',},
                {text: "Informations machines", value: 'infos', width:'40%', sortable: false,},
                {text: "Sous-ensembles", value: 'subassemblies', width:'60%', sortable: false,},
            ],
            search: '',
            infoToggle: false, // Machine menu package //
            label: '', // Machine menu package //

            openedPanels: [0],
            // MACHINES
            raw_machines: [],
            dialog_next_update: false,
            raw_machines_deactivated:[],
            machines: [],
            machines_deactivated:[],
            active_machines_count:0,
            inactive_machines_count:0,
            global_machines_count:0,
            machine_trash:false,
            machines_available:false,
            deprecated_length:5,
            deprecated_available:false,
            // BUILDINGS
            buildings: [],
            building: [],
            // SUBS
            subassemblies: [],
            subassembly_id: null,
            subassembly_configurations: [],
            subassembly_configuration: null,
            subassembly_configuration_choose: null,
            // DEVICES
            virtual_devices: [],
            virtual_devices_bind_to_subassembly: [],
            loading_bind_virtual_devices: false,
            dialog_bind_virtual_devices: false,
            // GLOBAL
            loading_datatable:true,
            filter_mode:9,
            loading: true,
            search: '',
            dialog_edit: false,
            sub_dialog_edit:false,
            sub_dialog_delete:false,
            sub_edit_location:"",
            modal_sub_title:"",
            edited_index: null,
            invalid: false,
            dialog_delete: false,
            delete_id: null,
            deleted_index: null,
            valid_image: true,
            // ARRAYS
            machine_filters: [
                { level: -1, level_name: "Rapport en attente", level_color: "grey", count:0, annotation: "Etat à définir"},

                { level: 1, level_name: "Pour information : EDS 100% (vert)", level_color: "#4CAF50", count:0, percent: 100, annotation: "Etat neuf" },
                { level: 2, level_name: "Pour information : EDS 90% (vert)", level_color: "#4CAF50", count:0, percent: 90, annotation: "Comportement vibratoire correct" },
                { level: 3, level_name: "Pour information : EDS 80% (vert)", level_color: "#4CAF50", count:0, percent: 80, annotation: "Pour information" },
                { level: 9, level_name: "Pour information : EDS 90% (vert)", level_color: "#4CAF50", count:0, percent: 90, annotation: "Comportement vibratoire correct" },

                { level: 11, level_name: "A suivre : EDS 70% (jaune)", level_color: "#FFCB05", count:0, percent: 70, annotation: "A suivre lors de la prochaine campagne" },
                { level: 12, level_name: "A suivre : EDS 60% (jaune)", level_color: "#FFCB05", count:0, percent: 60, annotation: "A suivre de manière rapprochée" },
                { level: 13, level_name: "A suivre : EDS 50% (jaune)", level_color: "#FFCB05", count:0, percent: 50, annotation: "Expertise complémentaire préconisée" },

                { level: 21, level_name: "Importance élevée : EDS 40% (rouge)", level_color: "#F44336", count:0, percent: 40, annotation: "Action à prévoir au prochaine arrêt technique" },
                { level: 22, level_name: "Importance élevée : EDS 30% (rouge)", level_color: "#F44336", count:0, percent: 30, annotation: "Action à prévoir au prochaine arrêt technique" },
                { level: 23, level_name: "Importance élevée : EDS 20% (rouge)", level_color: "#F44336", count:0, percent: 20, annotation: "Action à prévoir au prochaine arrêt technique" },
            
                { level: 31, level_name: "Urgence : EDS 10% (Noir)", level_color: "black", count:0, percent: 10, annotation: "Action à prévoir dans les plus brefs délais" },
                { level: 32, level_name:"Urgence : EDS 0% (Noir)", level_color: "black", count:0, percent: 0, annotation: "Arrêt de la machine recommandé" },      
            ],
            datas_filters: [
                { level: -1, level_name: "Rapport en attente", level_color: "grey", count:0, annotation: "Etat à définir"},

                { level: 1, level_name: "Pour information : EDS 100% (vert)", level_color: "#4CAF50", count:0, percent: 100, annotation: "Etat neuf" },
                { level: 2, level_name: "Pour information : EDS 90% (vert)", level_color: "#4CAF50", count:0, percent: 90, annotation: "Comportement vibratoire correct" },
                { level: 3, level_name: "Pour information : EDS 80% (vert)", level_color: "#4CAF50", count:0, percent: 80, annotation: "Pour information" },
                { level: 9, level_name: "Pour information : EDS 90% (vert)", level_color: "#4CAF50", count:0, percent: 90, annotation: "Comportement vibratoire correct" },

                { level: 11, level_name: "A suivre : EDS 70% (jaune)", level_color: "#FFCB05", count:0, percent: 70, annotation: "A suivre lors de la prochaine campagne" },
                { level: 12, level_name: "A suivre : EDS 60% (jaune)", level_color: "#FFCB05", count:0, percent: 60, annotation: "A suivre de manière rapprochée" },
                { level: 13, level_name: "A suivre : EDS 50% (jaune)", level_color: "#FFCB05", count:0, percent: 50, annotation: "Expertise complémentaire préconisée" },

                { level: 21, level_name: "Importance élevée : EDS 40% (rouge)", level_color: "#F44336", count:0, percent: 40, annotation: "Action à prévoir au prochaine arrêt technique" },
                { level: 22, level_name: "Importance élevée : EDS 30% (rouge)", level_color: "#F44336", count:0, percent: 30, annotation: "Action à prévoir au prochaine arrêt technique" },
                { level: 23, level_name: "Importance élevée : EDS 20% (rouge)", level_color: "#F44336", count:0, percent: 20, annotation: "Action à prévoir au prochaine arrêt technique" },
            
                { level: 31, level_name: "Urgence : EDS 10% (Noir)", level_color: "black", count:0, percent: 10, annotation: "Action à prévoir dans les plus brefs délais" },
                { level: 32, level_name:"Urgence : EDS 0% (Noir)", level_color: "black", count:0, percent: 0, annotation: "Arrêt de la machine recommandé" },      
            ],
            data_filters: [
                {
                    level: 9,
                    level_name: "Tout voir",
                    level_color: "#d7d7d752",
                    count:this.global_notifications_count,
                    icon: "",
                },
                {
                    level: 3,
                    level_name: "Urgence",
                    level_color: "black",
                    count:0,
                    icon: "mdi-alert",
                },
                {
                    level: 2,
                    level_name: "Importance élevée",
                    level_color: "#F44336",
                    count:0,
                    icon: "mdi-alert",
                },
                {
                    level: 1,
                    level_name: "A suivre",
                    level_color: "#FFCB05",
                    count:0,
                    icon: "mdi-alert",
                },
                {
                    level: 0,
                    level_name: "Pour information",
                    level_color: "#4CAF50",
                    count:0,
                    icon: "mdi-check-circle",
                },
                {
                    level: -1,
                    level_name: "Autres",
                    level_color: "grey",
                    count:0,
                    icon: "mdi-help-rhombus",
                },                
            ],
            edited_machine: {
                id: null,
                label: '',
                note: '',
                site: '',
                functionality: '',
                building_id: '',
                path_plan: null,
                note:'',
            },
            edited_subassembly: {
                id: null,
                label: '',
                subassembly_type: '',
                config: null,
                note: '',
                machine_id: null,
                path_image: null,
                uid: null,
                created_at: null,
            },
            timeProps: {
                format: "24hr"
            },
            textFieldProps: {
                prependInnerIcon: "mdi-calendar",
                solo: true,
                rules: [v => !!v || this.$t('vee-validation.required_date_operation')]
            },
            openGroups: {},
        }
    },
    computed: {
        dialogTitle() {
            return this.edited_index === null ? this.$t('machines.add_machine') : this.$t('machines.edit_machine');
        },
        groupedMachines() {
            const groups = {};
            this.machines.forEach(machine => {
                const buildingName = machine.building.label;
                if (!groups[buildingName]) {
                groups[buildingName] = [];
                }
                groups[buildingName].push(machine);
            });
            return groups;
        }
    },
    created() {
        this.loading_datatable = true;
        moment.locale('fr');
        this.getData();
        this.initialize();
    },
    methods: {
        initialize() {
            this.view_details = true;

        },
        // COLLECTING ALL NEEDED DATAS
        getData() {
            // GETTING MACHINES
            this.loading_datatable = true;
            this.axios.get(MACHINES + '?trash=0')
            
            .then((success) => {
                success.data.forEach(item => {
                    item.created_at = moment(item.created_at).format("DD/MM/YYYY");
                })
                this.filter_mode = 9;
                this.raw_machines = [];
                this.machines = [];

                if (this.checkDataAvailable(success.data)){
                    this.raw_machines = success.data;
                    this.machines = success.data;
                }
                this.machine_id = this.machines.id;

            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");

            }).finally(() => {
                this.getTrashData();
            });
        },
        getTrashData() {
            // GETTING DEACTIVATED MACHINES
            this.axios.get(MACHINES + '?trash=1')

            .then((success) => {
                success.data.forEach(item => {
                    item.created_at = moment(item.created_at).format("DD/MM/YYYY");
                })
                this.filter_mode = 9;
                this.raw_machines_deactivated = [];
                this.machines_deactivated = [];

                if (this.checkTrashDataAvailable(success.data)){
                    this.raw_machines_deactivated = success.data;
                    this.machines_deactivated = success.data.slice(0, this.deprecated_length);
                }

            }).catch(() => {
                this.showSnackbar('error', "Trashed data error.");
            
            }).finally(() => {
                this.getDataCount();
                this.getSideDatas();
            });         
        },
        getSideDatas(){
            this.axios.get(BUILDINGS)
            
            .then((success) => {
                this.buildings = [];
                this.buildings = success.data;

                if (this.buildings.length > 0) {
                    this.openGroups = [this.buildings[0].label];
                } else {
                    console.warn("Aucun bâtiment trouvé.");
                    this.openGroups = [];
                }

            }).catch(() => {
                this.showSnackbar('error', "Side datas error.");
            })
        },
        getDataCount() {
            // Reset all counts
            this.data_filters.forEach(data => {data.count = 0});
            this.active_machines_count =0;
            this.inactive_machines_count =0;
            this.global_machines_count =0;
            
            // Get all last counts
            this.machines.forEach(item => {
                this.data_filters.forEach(filter => { 
                    if(item.alert_level == filter.level){
                        filter.count = filter.count +1;
                    }
                });
            });

            this.active_machines_count = this.machines.length;
            this.inactive_machines_count = this.machines_deactivated.length;
            this.global_machines_count = this.active_machines_count + this.inactive_machines_count;

            // FREEUP LOADING STEP
            this.loading_datatable = false
        },

        // DISPLAY DATA MANAGEMENT
        checkDataAvailable(datas){
            if(typeof datas[0] === 'undefined') {                    
                this.machines_available = false;
                return false;
            }else {
                this.machines_available = true;
                return true;
            }
        },
        checkTrashDataAvailable(datas){
            if(typeof datas[0] === 'undefined') {                    
                this.deprecated_available = false;
                return false;
            }else {
                this.deprecated_available = true;
                return true;
            }
        },
        setDataFiltered (filter) {
            this.loading_datatable = true;
            this.filter_mode = filter;
            this.deprecated_length=5;
            this.dataTreatment();
            this.dataTrashTreatment();
            this.loading_datatable = false;
        },
        dataTreatment(){
            if(this.checkDataAvailable(this.raw_machines)) {                    
                this.machines = [];

                if(this.filter_mode === 9){
                    this.machines = this.raw_machines;

                }else{
                    this.raw_machines.forEach(machine => {
                        if (machine.alert_level == this.filter_mode){
                            this.machines.push(machine);
                        }
                    })
                }
                
                // Then check result to si if there is somehting left in chosen datas
                this.checkDataAvailable(this.machines);
            }
        },
        dataTrashTreatment(){
            if(this.checkTrashDataAvailable(this.raw_machines_deactivated)) {                    
                this.machines_deactivated = [];

                if(this.filter_mode === 9){
                    this.machines_deactivated = this.raw_machines_deactivated;

                }else{
                    this.raw_machines_deactivated.forEach(machine => {
                        if (machine.alert_level == this.filter_mode){
                            this.machines_deactivated.push(machine);
                        }
                    })
                }

                //Get deprecated list by max length
                this.machines_deactivated.slice(0, this.deprecated_length);
                
                // Then check result to si if there is somehting left in chosen datas
                this.checkTrashDataAvailable(this.machines_deactivated);
            }
        },
        viewMore() {
            this.deprecated_length = this.deprecated_length + 10;
            this.dataTrashTreatment();
        },
        setDataTrash(data, state) {
            // Set notification seen
            if (state === 1) {
                this.axios.patch(MACHINES + '/trash/' + data.id)
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.getData();
                    this.dialog_show = false;
                })
            
            // Set notification unseen
            } if (state === 0) {
                this.axios.patch(MACHINES + '/untrash/' + data.id)
            
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.getData();
                    this.loading_datatable = true;
                    this.dialog_show = false;
                })
            }
        },

        // EXISTING TREATMENTS
        // MODALS
        openDialogAddMachine() {
            if (this.edited_index !== null) {
                this.$refs.form.reset();
                this.$refs.observer.reset();
                this.edited_index = null;
            }

            this.dialog_edit = true;
        },
        openDialogUpdateMachine(machine) {
            this.edited_index = this.machines.indexOf(machine);
            this.edited_machine = Object.assign({}, machine);
            this.dialog_edit = true;
        },
        openDialogDeleteMachine(machine) {
            this.deleted_index = this.machines.indexOf(machine);
            this.delete_id = machine.id;
            this.dialog_delete = true;
        },
        openDialogAddSubassembly() {
            this.sub_edit_location = "add"
            this.modal_sub_title = "Ajouter un sous-ensemble";
            this.edited_subassembly = [];
            this.subassembly_id = "";
            // Open modal
            this.sub_dialog_edit = true;
        },
        openDialogEditSubassembly(subassembly) {
            this.loading_bind_virtual_devices = true;
            this.modal_sub_title = "Modifier le sous-ensemble ";
            this.sub_edit_location = "edit"
            this.edited_subassembly = subassembly;
            this.subassembly_id = subassembly.id;
            this.virtual_devices_bind_to_subassembly = [];
            // Open modal
            this.sub_dialog_edit = true;

            // DEBUG ----------------------------------------------------------------------------
            console.log(this.edited_subassembly);

            this.axios.get(VIRTUAL_DEVICES + '/not_bind/subassembly/' + this.subassembly_id)
            .then((success) => {
                this.virtual_devices = success.data;
                this.dialog_bind_virtual_devices = true;

                this.virtual_devices.forEach(virtual_device => {
                    if (virtual_device.subassembly_id !== null) this.virtual_devices_bind_to_subassembly.push(virtual_device);
                });

            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'))

            }).finally(() => {
                this.loading_bind_virtual_devices = false
            });

        },
        openDialogDeleteSubassembly(subassembly) {
            this.deleted_index = this.subassemblies.indexOf(subassembly);
            this.delete_id = subassembly.id;
            this.sub_dialog_delete = true;
        },
        openDialogShow(){
            console.log("open dialog show requested");
        },
        closeDialog() {
            this.$refs.form.reset();
            this.$refs.observer.reset();
            this.dialog_edit = false;
        },
        closeSubDialog() {
            this.sub_dialog_edit = false;
            this.sub_dialog_delete = false;
        },

        // POST REQUESTS PART
        prepareEditMachine() {
            if (this.edited_index !== null) {
                this.updateMachine();
            } else {
                this.addMachine();
            }
            this.closeDialog();
        },
        updateMachine() {
            this.axios.patch(MACHINES + '/' + this.edited_machine.id,
                this.edited_machine
            ).then((success) => {
                Object.assign(this.machines[this.edited_index], success.data);
                this.edited_index = null;
                this.showSnackbar('success', this.$t('global.success_to_edit'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            }).finally(() => {
                this.getData();
                this.loading_datatable = true;
                this.closeDialog();
            })
        },
        addMachine() {
            let formData = new FormData()
            formData.append('label', this.edited_machine.label);
            formData.append('brand', this.edited_machine.brand);
            formData.append('model', this.edited_machine.model);
            formData.append('size', this.edited_machine.size);
            formData.append('functionality', this.edited_machine.functionality);
            formData.append('plan', this.edited_machine.path_plan);
            formData.append('building_id', this.edited_machine.building_id);
            formData.append('note', this.edited_machine.note);

            this.axios.post(
                MACHINES, formData, this.configurationFile
            ).then((success) => {
                this.machines.push(success.data);
                this.showSnackbar('success', this.$t('global.success_to_add'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_add'));
            }).finally(() => { 
                this.getData();
                this.loading_datatable = true;
                this.closeDialog();
            });
        },
        confirmMachineDelete() {
            this.axios.delete(
                MACHINES + '/' + this.delete_id
            ).then(() => {
                this.machines.splice(this.deleted_index, 1);
                this.showSnackbar('success', this.$t('global.success_to_delete'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_delete'));
            }).finally(() => {
                this.getData();
                this.loading_datatable = true;
                this.dialog_delete = false;
            });
        },

        // SUBS
        initialize() {
            this.getSubassemblies();
            this.getSubassemblyConfiguration();
        },

        //COLLECT DATAS
        getSubassemblies() {
            this.axios.get(
                SUBASSEMBLIES
            ).then((success) => {
                this.subassemblies = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'))
            }).finally(() => {
                this.loading = false
            });
        },
        getSubassemblyConfiguration() {
            this.axios.get(SUBASSEMBLY_CONFIGURATIONS).then((success) => {
                this.subassembly_configurations = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            });
        },

        // DATA TREATMENT
        parseSubassemblyConfigurationChoose() {
            this.edited_subassembly.subassembly_type = this.subassembly_configuration.label;
            this.subassembly_configuration_choose = JSON.parse(this.subassembly_configuration.configuration);
        },
        prepareEditSubassembly() {
            if (this.sub_edit_location === 'edit') {
                //this.updateSubassembly();
            } else {
                this.addSubassembly();
            }

            this.closeDialog();
        },

        // REQUESTS
        addSubassembly() {
                this.edited_subassembly.config = this.subassembly_configuration_choose;
                let formData = new FormData()
                formData.append('label', this.edited_subassembly.label);
                formData.append('subassembly_type', this.edited_subassembly.subassembly_type);
                formData.append('config', JSON.stringify(this.edited_subassembly.config));
                formData.append('note', this.edited_subassembly.note);
                formData.append('machine_id', this.edited_subassembly.machine_id);
                formData.append('path_image', this.edited_subassembly.path_image);

                this.axios.post(
                    SUBASSEMBLIES, formData, this.configurationFile
                ).then((success) => {
                    this.subassemblies.push(success.data);
                    this.showSnackbar('success', this.$t('global.success_to_add'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_add'));
                
                }).finally(() => {
                    this.getSubassemblies();
                    this.getData();
                    this.resetFormEditSubassembly();
                    this.closeSubDialog();
                });
        },
        confirmSubDelete() {
            this.axios.delete(
                SUBASSEMBLIES + '/' + this.delete_id
            ).then(() => {
                this.subassemblies.splice(this.deleted_index, 1);
                this.showSnackbar('success', this.$t('global.success_to_delete'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_delete'));
            }).finally(() => {
                this.getSubassemblies();
                this.getData();
                this.closeSubDialog();
            });
        },
        updateBindingVirtualDevice() {
            this.axios.put(VIRTUAL_DEVICES + '/bind/subassembly/' + this.subassembly_id,
                {
                    virtual_devices_id: this.virtual_devices_bind_to_subassembly
                }
            ).then(() => {
                this.showSnackbar('success', this.$t('global.success_to_edit'))
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'))
            }).finally(() => {
                this.virtual_devices = [];
                this.virtual_devices_bind_to_subassembly = [];
                this.getSubassemblies();
                this.getData();
                this.closeSubDialog();
            });
        },
        resetFormEditSubassembly() {
            this.$refs.formBindVirtualDevice.reset();
            this.$refs.observer.reset();
            this.subassembly_configuration = null;
            this.subassembly_configuration_choose = null;
        },
        
        // NOT USED TO BE IMPROVED
        onImageChanged() {
            if(this.edited_subassembly.path_image !== null) {
                let imageCheck = this.uploadFilesCheck(this.edited_subassembly.path_image,['image/png','image/jpg','image/jpeg'],2);
                if(!imageCheck.authorized) {
                    this.valid_image = false;
                    this.showSnackbar('error',imageCheck.output.toString());
                }
                else {
                    this.valid_image = true;
                }
            }
            if(this.edited_subassembly.path_image === null){
                this.valid_image = true;
            }
        },
        dialogNextUpdate() {
            if (this.dialog_next_update){
                this.dialog_next_update = false;
            }else{
                this.dialog_next_update = true;
            }
        },


        toggleGroup(group) {
            this.$set(this.openGroups, group, !this.isGroupOpen(group));
        },
        isGroupOpen(group) {
            return this.openGroups[group] !== undefined ? this.openGroups[group] : true;
        },
        

        viewDetails(){
            this.view_details = !this.view_details;
        },
    },
}
</script>

<style lang="scss" scoped>
    .v-expansion-panel-content__wrap {
        padding: 0!important;
        flex: 1 1 auto;
        max-width: 100%;
    } 
</style>