<template>
    <div id="building-specifications">
        <v-card elevation="2" outlined min-height="480px">
            <v-card-title class="justify-space-between">
                <v-icon>mdi-smoke-detector</v-icon>
                {{ $t('virtual_device_state.title') }}
                <v-btn elevation="3" color="amber darken-3" small @click="openDialogBindingPhysicalDevice()"
                       v-if="this.$store.state.access_level === 200 && this.$store.state.switch_state"
                >
                    <v-icon color="white" small>mdi-wrench</v-icon>
                </v-btn>
                <v-icon v-else></v-icon>
            </v-card-title>

            <v-divider/>

            <v-card-text v-if="is_physical_device_associate && !is_physical_device_load">
                <v-row align="center" justify="center">
                    <v-col cols="4">
                        <v-img aspect-ratio="1" :src="data.path_image" max-height="225" contain>
                            <template v-slot:placeholder>
                                <v-row v-if="data.path_image !== ''"
                                       class="fill-height ma-0 background-loader-image"
                                       align="center" justify="center"
                                >
                                    <v-progress-circular indeterminate color="black lighten-5"/>
                                </v-row>

                                <v-row v-else class="fill-height ma-0 background-loader-image"
                                       align="center" justify="center"
                                >
                                    {{ $t('global.no_image') }}
                                </v-row>
                            </template>
                        </v-img>
                    </v-col>

                    <v-col cols="8">
                        <v-text-field :prepend-inner-icon="'mdi-battery-charging-medium'"
                                      :label="$t('virtual_device_state.battery')"
                                      v-model="battery"
                                      outlined readonly background-color="#EEEEEE"
                        />
                        <v-text-field outlined readonly background-color="#EEEEEE"
                                      :prepend-inner-icon="'mdi-tape-measure'"
                                      :label="$t('virtual_device_state.current_dev_eui')"
                                      v-model="data.physical_device.uid"
                        />

                        <v-text-field outlined readonly background-color="#EEEEEE"
                                      :value="data.physical_device_last_emission_was !== null ? $t('virtual_device_state.last_emission_was') : $t('global.no_results')"
                                      :disabled="data.physical_device_last_emission_was === null"
                        >
                            <template v-slot:prepend-inner class="w-100">
                                <v-icon class="mr-1">mdi-broadcast</v-icon>
                            </template>

                            <template v-slot:append class="w-100">
                                <span v-if="data.physical_device_last_emission_was !== null">
                                    <v-chip
                                        :color="lastEmissionAttributeTranslation(data.physical_device_last_emission_was).color"
                                        text-color="white"
                                    >
                                        {{
                                            lastEmissionAttributeTranslation(data.physical_device_last_emission_was).time
                                        }}
                                    </v-chip>
                                </span>
                            </template>
                        </v-text-field>

                        <v-text-field
                            :label="$t('virtual_device_state.transmission_quality')"
                            :prepend-inner-icon="'mdi-wifi'" outlined readonly background-color="#EEEEEE"
                            v-model="data.physical_device_transmission_quality"
                        />

                        <v-btn
                            v-if="this.$store.state.access_level === 200 && this.$store.state.switch_state"
                            :disabled="!is_physical_device_associate" color="error" block
                            @click="dialog_unbind_physical_device = true"
                        >
                            {{ $t('virtual_device_state.dissociate_physical_device') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-text v-else-if="!is_physical_device_associate && !is_physical_device_load" class="text-center">
                {{ $t('virtual_devices_details.device_not_bind_to_physical_device') }}
            </v-card-text>

            <v-progress-linear v-else indeterminate/>
        </v-card>

        <v-dialog v-model="dialog_bind_physical_device" max-width="550px" persistent>
            <v-card>
                <validation-observer ref="observer_bind_physical_device" v-slot="{ invalid }">
                    <v-form ref="form_bind_physical_device">
                        <v-card-title>
                            <span class="headline">
                                {{ $t('virtual_device_state.manage_binding_physical_device') }}
                            </span>
                        </v-card-title>

                        <v-divider/>

                        <v-card-text>
                            <validation-provider :name="$t('virtual_device_state.physical_device_to_associate')"
                                                 rules="required" v-slot="{ errors }"
                            >
                                <v-select
                                    :items="physical_devices"
                                    v-model="physical_device_selected"
                                    :label="$t('virtual_device_state.physical_device_to_associate')"
                                    :prepend-inner-icon="'mdi-smoke-detector'"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                    :disabled="physical_devices.length === 0"
                                    :loading="physical_devices.length === 0"
                                    :error-messages="errors"
                                    item-text="uid" item-value="id"
                                    chips solo return-object
                                />
                            </validation-provider>
                        </v-card-text>

                        <v-card-actions class="justify-end">
                            <v-btn @click="closeDialogBindingPhysicalDevice()">
                                <v-icon left>mdi-close</v-icon>
                                {{ $t('global.close') }}
                            </v-btn>
                            <v-btn color="primary" :disabled="invalid" @click="updateBindingPhysicalDevice()">
                                <v-icon left>mdi-content-save</v-icon>
                                {{ $t('global.validation') }}
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </validation-observer>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_unbind_physical_device" max-width="500px" transition="dialog-top-transition"
                  v-if="this.$store.state.access_level === 200 && this.$store.state.switch_state">
            <v-card>
                <v-card-title>
                    <span class="headline">
                        {{ $t('virtual_device_state.dialog_dissociate_physical_device_title') }}
                    </span>
                </v-card-title>

                <v-divider/>

                <v-container>
                    <v-card-text class="text-center">
                        {{
                            $t('virtual_device_state.dialog_dissociate_physical_device_text', {
                                virtual_device: data.virtual_device_label,
                                physical_device: data.physical_device_uid
                            })
                        }}
                        {{ $t('global.confirm_operation') }}
                    </v-card-text>

                    <v-card-actions class="justify-end">
                        <v-btn text @click="dialog_unbind_physical_device = false">
                            {{ $t('global.cancel') }}
                        </v-btn>
                        <v-btn color="primary" @click="updateUnbindingPhysicalDevice()">
                            {{ $t('global.validation') }}
                        </v-btn>
                    </v-card-actions>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {VIRTUAL_DEVICES, PHYSICAL_DEVICES} from "../../../api";
import LayerAssets from "../LayerAssets";

export default {
    name: "VirtualDeviceState",
    components: {
        LayerAssets,
    },
    data() {
        return {
            virtual_device_id: null,
            data: {
                virtual_device_label: null,
                physical_device_uid: null,
                path_image: '',
                physical_device_battery: null,
                physical_device_last_emission_was: null,
                physical_device_transmission_quality: null,
            },

            physical_devices: [],
            physical_device_selected: null,

            is_physical_device_associate: false,
            is_physical_device_load: true,

            dialog_bind_physical_device: false,
            dialog_unbind_physical_device: false,
            dialog_dissociate: false,

            invalid: true,
        }
    },
    computed: {
        battery() {
            return (this.data.physical_device.battery ? this.data.physical_device.battery + '%' : this.$t('global.no_data'));
        }
    },
    created() {
        this.virtual_device_id = this.$route.params.id;
        this.getPhysicalDeviceStates();
        this.getPhysicalDevices();
    },
    methods: {
        getPhysicalDeviceStates() {
            this.is_physical_device_load = true;
            this.is_physical_device_associate = false;
            this.data = '';
            this.physical_device_selected = null;

            this.axios.get(
                VIRTUAL_DEVICES + '/' + this.virtual_device_id + '/state'
            ).then((success) => {
                if (success.data.physical_device) {
                    this.is_physical_device_associate = true;
                    this.data = success.data;
                }
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('error_display.error_get_physical_device'));
            }).finally(() => {
                this.is_physical_device_load = false;
            });
        },

        getPhysicalDevices() {
            this.axios.get(
                PHYSICAL_DEVICES + '/to_bind'
            ).then((success) => {
                this.physical_devices = success.data;
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('error_display.error_get_physical_devices'));
            });
        },

        updateUnbindingPhysicalDevice() {
            this.axios.put(
                PHYSICAL_DEVICES + '/' + this.data.physical_device.uid + '/dissociate'
            ).then(() => {
                this.getPhysicalDeviceStates();
                this.showGlobalSnackbar('success', this.$t('global.success_to_edit'));
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('global.failure_to_update'));
            }).finally(() => {
                this.dialog_unbind_physical_device = false;
            });
        },

        updateBindingPhysicalDevice() {
            this.axios.put(PHYSICAL_DEVICES + '/' + this.physical_device_selected.uid + '/associate', {
                'virtual_device_id': this.virtual_device_id
            }).then(() => {
                this.getPhysicalDeviceStates();
                this.showGlobalSnackbar('success', this.$t('global.success_to_edit'));
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('global.failure_to_update'));
            }).finally(() => {
                this.closeDialogBindingPhysicalDevice();
            });
        },

        openDialogBindingPhysicalDevice() {
            this.dialog_bind_physical_device = true;
            if (this.data.physical_device) {
                this.physical_device_selected = this.data.physical_device;
            }
        },

        closeDialogBindingPhysicalDevice() {
            this.$refs.form_bind_physical_device.reset();
            this.$refs.observer_bind_physical_device.reset();
            this.dialog_bind_physical_device = false;
        },
    }
}
</script>
