<template>
    <div id="building-detail">
        <v-row>
            <v-col style="background-color: white">
                <v-subheader>
                    <p class="title mb-0">
                        {{ $t('buildings.detail') }} : {{ label }}
                    </p>
                </v-subheader>
            </v-col>
        </v-row>

        <v-row>
            <v-col id="fixed-col">
                <building-state/>
            </v-col>
            <v-col>
                <building-specifications v-on:updateLabelBuilding="updateLabelBuilding"/>
            </v-col>
        </v-row>

        <datatable-operations :entity-name="'building'" class="mt-6"/>

        <datatable-building-machines class="mt-6"/>
    </div>
</template>
<script>

import DatatableBuildingMachines from "../../components/Building/DatatableBuildingMachines";
import BuildingSpecifications from "../../components/Building/BuildingSpecifications";
import BuildingState from "../../components/Building/BuildingState";
import DatatableOperations from "../../components/DatatableOperations";

export default {
    name: "DetailBuilding",
    components: {DatatableBuildingMachines, BuildingSpecifications, BuildingState, DatatableOperations},
    data() {
        return {
            label: '',
        }
    },
    methods: {
        updateLabelBuilding(item) {
            this.label = item.label;
        }
    },
}
</script>

<style scoped lang="scss">
#fixed-col {
    min-width: 638px;
    max-width: 638px !important;
}

@media only screen and (max-width: 480px) {
    #fixed-col {
        min-width: 100%;
        max-width: 100% !important;
    }
}
</style>
