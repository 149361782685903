<template>
    <div id="setting-log">
        <cards-professionals class="mt-6" v-if="cards"/>
        <datatable-professionals class="mt-6" v-if="!cards"/>
    </div>
</template>

<script>
import DatatableProfessionals from "./DatatableProfessionals";
import CardsProfessionals from "./CardsProfessionals";

export default {
    name: "Professionals",
    components: {DatatableProfessionals, CardsProfessionals},
    data() {
        return {
            infoToggle: false, // Machine menu package //
            cards: true,
        }
    },
    created() {
        this.setCards();
    },
    methods: {
        switchView() {
            this.cards = !this.cards;
        },

        setCards() {
            this.cards = true;
        },

        setDatatable() {
            this.cards = false;
        },

        infoToggler(){
            this.infoToggle = !this.infoToggle;
        }
    }
}

</script>
