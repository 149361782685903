<template>
    <div id="three-dimensional-curve-color-switch-modal">
        <v-dialog v-model="three_dimensional_curve_color_switch_open_dialog"
                  content-class="v-dialog--custom"
                  overlay-opacity="0.1"
                  origin="top left"
                  transition="scroll-x-transition"
        >
            <v-card>
                <v-color-picker v-model="color"/>
                <v-card-actions class="justify-center">
                    <v-btn @click="three_dimensional_curve_color_switch_open_dialog = false" >
                        <v-icon left>mdi-close</v-icon>
                        {{ $t('global.close') }}
                    </v-btn>
                    <v-btn color="primary" @click="colorSelected()">
                        <v-icon left>mdi-content-save</v-icon>
                        {{ $t('global.validation') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: "ThreeDimensionalCurveColorSwitchModal",
    props: { parent_color_sheet: {
            type: String,
            }
            },
    data(){
        return {
            three_dimensional_curve_color_switch_open_dialog: false,
            color:'',
            output:{
                item_source:'',
                color:'',
            }
        }
    },
    methods:{
        colorSelected(){
            this.output.color=this.color;
            this.output.item_source=this.parent_color_sheet;
            this.$emit('ColorChange',this.output);
            this.three_dimensional_curve_color_switch_open_dialog=false;
        }
    }
}
</script>
