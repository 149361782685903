<template>
    <div id="three-dimensional-curve-appearance-modal">
        <v-dialog v-model="three_dimensional_curve_appearance_open_dialog" width="650">
            <v-card>
                <v-form v-model="valid">
                <v-card-title class="headline">
                    {{ $t('three_dimensional_curve.appearance') }}
                </v-card-title>
                <v-divider/>
                    <v-card-text>
                        <h3 class="mt-4 mb-4">{{ $t('three_dimensional_curve.gradient') }}</h3>
                        <span>{{ $t('three_dimensional_curve.minimal_value') }}</span>
                        <v-alert
                            border="left"
                            colored-border
                            :color="configuration.min_color_code"
                            icon="mdi-alert-octagon-outline"
                            elevation="2"
                            class="mt-2"
                        >
                            <span class="font-12">{{$t('three_dimensional_curve.color_notice1')}}</span>
                            <span class="font-12">{{$t('three_dimensional_curve.color_notice2')}}</span>
                            <div class="color-selection mt-4">
                                <v-text-field
                                    v-model="configuration.min_color_percentage"
                                    prefix="%"
                                    class="text-field-custom-width"
                                    prepend-icon="mdi-eyedropper-variant"
                                    :rules="colorPercentageMinCheck"
                                    solo
                                />
                                <v-sheet
                                    ref="min_color_code"
                                    class="sheet-cursor"
                                    :color="configuration.min_color_code"
                                    elevation="5"
                                    rounded
                                    height="48"
                                    width="45%"
                                    @click="displayColorSwitchModal('min_color_code');"
                                />
                            </div>
                        </v-alert>
                        <span>{{ $t('three_dimensional_curve.maximal_value') }}</span>
                        <v-alert
                            border="left"
                            colored-border
                            :color="configuration.max_color_code"
                            icon="mdi-alert-octagon-outline"
                            elevation="2"
                            class="mt-2"
                        >
                            <span class="font-12">{{$t('three_dimensional_curve.color_notice3')}}</span>
                            <div class="color-selection mt-4">
                                <v-text-field
                                    v-model="configuration.max_color_percentage"
                                    prefix="%"
                                    class="text-field-custom-width"
                                    prepend-icon="mdi-eyedropper-variant"
                                    :rules="colorPercentageCheck"
                                    solo
                                />
                                <v-sheet
                                    ref="max_color_code"
                                    class="sheet-cursor"
                                    :color="configuration.max_color_code"
                                    elevation="5"
                                    rounded
                                    height="48"
                                    width="45%"
                                    @click="displayColorSwitchModal('max_color_code');"
                                />
                            </div>
                        </v-alert>
                        <v-divider/>
                        <h3 class="mt-4 mb-6">{{$t('three_dimensional_curve.curve_width')}}</h3>
                        <hr class="curve-line mb-5" v-bind:style="{ height: configuration.line_width +'px' }">
                        <v-slider
                            v-model="configuration.line_width"
                            min="1"
                            max="10"
                            color="primary"
                            track-color="blue lighten-5"
                            append-icon="mdi-plus"
                            prepend-icon="mdi-minus"
                            @click:append="increaseCurveWidth()"
                            @click:prepend="decreaseCurveWidth()"
                            thumb-label
                            :thumb-size="20"
                        ></v-slider>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn @click="closeThreeDimensionalCurveAppearanceModal()">
                            <v-icon left>mdi-close</v-icon>
                            {{ $t('global.close') }}
                        </v-btn>
                        <v-btn color="primary" @click="updateThreeDimensionalCurveConfiguration()" :disabled="!valid"
                        >
                            <v-icon left>mdi-content-save</v-icon>
                            {{ $t('global.validation') }}
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
        <three-dimensional-curve-color-switch-modal ref="color" :parent_color_sheet="color_input" @ColorChange="updateColor"/>
        <global-snackbar/>
    </div>
</template>

<script>
import ThreeDimensionalCurveColorSwitchModal from "./ThreeDimensionalCurveColorSwitchModal";
import {THREE_DIMENSION_CURVES} from "../../../../api";
import GlobalSnackbar from "../../../../global/components/GlobalSnackbar";

export default {
    name: "ThreeDimensionalCurveAppearanceModal",
    components: {GlobalSnackbar, ThreeDimensionalCurveColorSwitchModal},
    data(){
        return {
            three_dimensional_curve_appearance_open_dialog:false,
            valid:false,
            slider_width: 2,
            color_input:'',
            color1:'',
            color2:'',
            configuration:{},
            curve_id: 0,
            colorPercentageCheck: [
                v => !!v || this.$t('vee-validation.required_field'),
                v => v <= 100 || this.$t('vee-validation.color_input_limit'),
                v => v >= 0 || this.$t('vee-validation.color_input_limit'),
            ],
            colorPercentageMinCheck: [
                v => !!v || this.$t('vee-validation.required_field'),
                v => v <= 100 || this.$t('vee-validation.color_input_limit'),
                v => v >= 0 || this.$t('vee-validation.color_input_limit'),
            ]
        }
    },
    methods: {
        increaseCurveWidth() {
            this.configuration.line_width = (this.configuration.line_width + 1) || 100
        },

        decreaseCurveWidth() {
            this.configuration.line_width = (this.configuration.line_width - 1) || 0
        },

        updateColor(chosenColor){
            chosenColor.item_source === 'min_color_code' ? this.configuration.min_color_code= chosenColor.color : this.configuration.max_color_code=chosenColor.color
        },

        displayColorSwitchModal(source) {
            this.color_input= source;
            this.$refs.color.three_dimensional_curve_color_switch_open_dialog= true;
        },

        updateThreeDimensionalCurveConfiguration(){
            this.axios.patch(
                THREE_DIMENSION_CURVES + '/' + this.curve_id, {
                    configuration: this.configuration
                }
            ).then((success)=> {
                this.$emit('curveConfigurationUpdated', success.data);
                this.showGlobalSnackbar('success',this.$t('global.success_to_edit'));
                this.three_dimensional_curve_appearance_open_dialog=false;
            }).catch(()=>{
                this.showGlobalSnackbar('error',this.$t('global.success_to_edit'));
            });
        },

        closeThreeDimensionalCurveAppearanceModal() {
            this.$emit('closedDialog');
            this.three_dimensional_curve_appearance_open_dialog = false;
        }
    }
}
</script>

<style scoped>
.curve-line{
    border-width:0;
    color:#757575;
    background-color:#757575;
    width: 75%;
    border-radius: 25px;
    margin-left: auto;
    margin-right: auto;
}

h3{
    color: #212121;
}

.color-selection{
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.text-field-custom-width{
    max-width: 50%!important;
}

span{
    font-weight: normal;
    color: #212121;
}

.sheet-cursor{
    cursor: crosshair;
}

</style>
