export const AUTH_LOGIN = '/api/auth/login';
export const AUTH_ME = '/api/auth/me';
export const AUTH_REFRESH = '/api/auth/refresh';
export const AUTH_LOGOUT = '/api/auth/logout';
export const AUTH_SWITCH = '/api/auth/professional';

export const SUBASSEMBLIES = '/api/subassembly';
export const SITES = '/api/site';
export const MACHINES = '/api/machine';
export const BUILDINGS = '/api/building';
export const OPERATIONS = '/api/operation'
export const OPERATION_TIMELINES = '/api/operation_timeline'
export const DOCUMENTS = '/api/document';
export const EXTERNAL_LINK = '/api/external_link';
export const REFERENTIAL_GLOBALS = '/api/referential_global';
export const REFERENTIAL_CONTRACT_TYPES = '/api/referential_contract_type';
export const REFERENTIAL_PHYSICAL_DEVICE_TYPES = '/api/referential_physical_device_type';
export const REFERENTIAL_TAGS = '/api/referential_tag';
export const USERS = '/api/user';
export const VIRTUAL_DEVICES = '/api/virtual_device';
export const FAVORITE_CURVES = '/api/favorite_curve';
export const ROLES = '/api/role';
export const SUBASSEMBLY_CONFIGURATIONS = '/api/subassembly_configuration';
export const ADMIN_USERS = '/api/admin_user';
export const PHYSICAL_DEVICES = '/api/physical_device';
export const PROFESSIONALS = '/api/professional';
export const COMPARISON_CURVES = '/api/comparison_curve';
export const THREE_DIMENSION_CURVES = '/api/three_dimensional_curve';
export const WORD_CURVE = '/api/word_curve';
export const METRICS = '/api/metric';
export const GATEWAYS = '/api/gateway';
export const CONTRACTS = '/api/contract';
export const CAMPAIN = '/api/campain';
export const REPORT = '/api/report';

export const DOWNLOAD_CSV_SUBASSEMBLY_OPERATIONS = '/api/download/csv/subassembly/';
export const DOWNLOAD_CSV_USER = '/api/download/csv/user';
export const DOWNLOAD_CSV_CONTRACT = '/api/download/csv/contract';
export const DOWNLOAD_CSV_GATEWAY = '/api/download/csv/gateway';
export const DOWNLOAD_CSV_PHYSICAL_DEVICES = '/api/download/csv/physical_device';
export const DOWNLOAD_CSV_COMPARISON_CURVE = '/api/download/csv/comparison_curve';
export const DOWNLOAD_CSV_THREE_DIMENSION_CURVES = '/api/download/csv/three_dimension_curve';
export const DOWNLOAD_CSV_WORD_CURVE = '/api/download/csv/word_curve';
export const DOWNLOAD_CSV_TEXT_CURVE = '/api/download/csv/time_curve';
export const DOWNLOAD_CSV_FFT_CURVE = '/api/download/csv/fft_curve';

export const LOGS = '/api/log';
export const NOTIFICATIONS = '/api/notification';
export const INVITATION = '/api/invitation';
export const SUPPLIES = '/api/supplies';
export const CONTRACT_TYPES = '/api/contract_type';
