<template>
    <div id="modal-add-expert-ticket">
        <v-dialog v-model="add_ticket_dialog" width="500">
            <v-card>
                <v-card-title>
                    {{ $t('contracts_datatable.add_ticket_title') }}
                </v-card-title>

                <v-divider></v-divider>

                <v-form ref="form" v-model="valid">
                    <v-card-text class="pt-5 text-center">
                        <h3> {{ $t("contracts_datatable.add_ticket_notice") }} </h3>
                        <v-text-field
                            v-model="number_of_ticket"
                            :label="$t('contracts_datatable.add_ticket_number')"
                            type="number" filled
                            class="ticket-number-input mt-5"
                            :rules="checkDeviceNumber"
                        >
                        </v-text-field>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer/>
                        <v-btn plain @click="add_ticket_dialog=false">
                            {{ $t('global.cancel') }}
                        </v-btn>
                        <v-btn color="primary" :disabled="!valid" @click="validate()">
                            {{ $t('global.validation') }}
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
        <global-snackbar/>
    </div>
</template>

<script>
import {CONTRACTS} from "../../../api";
import GlobalSnackbar from "../../../global/components/GlobalSnackbar";

export default {
    name: "AddExpertTicket",
    components: {GlobalSnackbar},
    data() {
        return {
            valid: true,
            number_of_ticket: '',
            add_ticket_dialog: false,
            contract_id: '',
            checkDeviceNumber: [
                v => !!v || this.$t('global.empty_field'),
                v => v >= 0 || this.$t('global.error_field'),
                v => v <= 100 || this.$t('global.max_length'),
            ],
        }
    },
    methods: {
        addTicketDialogOpen(contract_id) {
            this.add_ticket_dialog = true;
            this.contract_id = contract_id;
        },

        validate() {
            this.$refs.form.validate();
            this.updateTicket();
        },

        updateTicket() {
            this.axios.patch(CONTRACTS + '/' + this.contract_id, {
                added_tickets: this.number_of_ticket
            }).then(() => {
                this.$emit('contract-updated');
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('global.error'));
            }).finally(() => {
                this.add_ticket_dialog = false;
            });
        }
    }
}
</script>

<style scoped>
.ticket-number-input {
    max-width: 200px;
    margin-left: auto !important;
    margin-right: auto !important;
}
</style>
