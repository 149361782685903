<template>
    <div id="setting-log">
        <v-row class="row mt-5">
            <v-subheader class="v-subheader col theme--light pl-5 ml-2">
                <p class="title mb-0 color-primary text-uppercase">
                    {{ $t('notification.title') }}
                </p>
                <!------------------- Info bulle ---------------------->
                <v-tooltip bottom dark class="mr-2">
                    <template v-slot:activator="{ on }">
                        <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                            <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:default>
                    <div style="min-width: 200px;">
                        <strong>{{ $t('tooltip.notifications_page_title') }}</strong>
                        <p>{{ $t('tooltip.notifications_page_content') }}</p>
                    </div>
                    </template>
                </v-tooltip>
            </v-subheader>
            <v-row cols="2" class="v-subheader col-1 theme--light mr-3">
                <v-col class="col"></v-col>
                <v-tooltip bottom dark>
                    <template v-slot:activator="{ on }">
                        <v-btn text icon v-on="on" @click="setCards()" dusk="logout-button">
                            <v-icon color="primary">mdi-card-text-outline</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('notification.list-card') }}</span>
                </v-tooltip>
                <v-tooltip  v-if="access_level == 0" bottom dark>
                    <template v-slot:activator="{ on }">
                        <v-btn text icon v-on="on" @click="setDatatable()" dusk="logout-button">
                            <v-icon color="primary">mdi-format-list-bulleted</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('notification.list-view') }}</span>
                </v-tooltip>
            </v-row>
        </v-row>
        <cards-notifications class="mt-6" v-if="cards"/>
        <datatable-notifications class="mt-6" v-if="!cards"/>
    </div>
</template>

<script>
import DatatableNotifications from "../components/DatatableNotifications";
import CardsNotifications from "../components/CardsNotifications";
/*
 
Notifiction system :
    - Be awared if somthing happened
    -> Things to be awared (TAGS)
        -> Alerts (new / update / closed)
            > Analyst -> by any measure points and any reports
            > Customer -> for any reports
        
        -> Campain (report or launch / updated / finished)
        -> Reports (new / update)
        -> Update (software)
        -> Messages (mainly published - by any customer)

    -> Criticity (TAGS_LEVEL)
        -> 0:blank
        -> 1:green
        -> 2:yellow
        -> 3:red
        -> 4:purple

    -> 

*/

export default {
    name: "Notification",
    components: {DatatableNotifications, CardsNotifications},
    data() {
        return {
            cards: true,
            ccess_level: this.$store.state.access_level,
        }
    },
    created() {
        this.setCards();
    },
    methods: {
        switchView() {
            this.cards = !this.cards;
        },

        setCards() {
            this.cards = true;
        },

        setDatatable() {
            this.cards = false;
        },
    }
}


</script>
