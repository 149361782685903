<template>
    <div id="supply-stock-supplies" class="pa-4">
        <v-row no-gutters>
            <v-col>
                <p class="title mb-0 color-primary text-uppercase">{{ $t('supply_stock.title') }}</p>
            </v-col>
        </v-row>

        <v-card width="100%" class="mt-4" elevation="0">
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="products"
                    :search="search"
                    :loading="products_datatable_loading">
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-layout class="justify-start mt-7">
                                <p class="mt-4">{{ $t('global.filter_by') }}</p>
                                <v-select
                                    :placeholder="$t('supplies_datatable.designation')"
                                    v-model="selected_designation"
                                    :items="product_names"
                                    filled dense class="ml-4" style="max-width: 500px;"
                                    chips multiple deletable-chips
                                ></v-select>
                            </v-layout>

                            <v-layout class="justify-end mt-7 max-width-300">
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    :label="$t('global.search')"
                                ></v-text-field>
                            </v-layout>
                        </v-toolbar>
                        <v-layout class="justify-end mt-2 mb-2">
                            <v-btn color="primary" class="mr-3" @click="downloadCsvSupplies()">
                                <v-progress-circular v-if="loading_csv_file"
                                                     indeterminate color="white" size="20" width="2"
                                />
                                <v-icon v-else left>mdi-download-box-outline</v-icon>
                                {{ $t('global.download') }}
                            </v-btn>
                        </v-layout>
                    </template>

                    <template v-slot:item.total="{ item }">
                        <template v-if="item.QteAppro === 0 || item.QteAppro <= item.QteMini">
                            <div class="custom-red-chip">
                                {{ item.QteAppro }}
                            </div>
                        </template>

                        <template v-else-if="item.QteAppro <= 2 * item.QteMini  && item.QteAppro >= item.QteMini">
                            <div class="custom-orange-chip">
                                {{ item.QteAppro }}
                            </div>
                        </template>

                        <template v-else-if="item.QteAppro >= 2 * item.QteMini">
                            <div class="custom-green-chip">
                                {{ item.QteAppro }}
                            </div>
                        </template>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <global-snackbar/>
    </div>
</template>

<script>
import {SUPPLIES} from "../../../../api";
import GlobalSnackbar from "../../../../global/components/GlobalSnackbar";

export default {
    name: "StockSupplies",
    components: {GlobalSnackbar},
    data() {
        return {
            products_datatable_loading: false,
            headers: [
                {text: this.$t('supplies_datatable.designation'), value: 'Nom_Produit'},
                {text: this.$t('supplies_datatable.marque'), value: 'Marque'},
                {text: this.$t('supplies_datatable.reference'), value: 'REST_ID_PROD'},
                {text: this.$t('supplies_datatable.reference_fournisseur'), value: 'Ref_Constructeur'},
                {text: this.$t('supplies_datatable.quantite_min'), value: 'QteMini'},
                {text: this.$t('supplies_datatable.quantite_stock'), value: 'QteAppro'},
                {text: this.$t('global.total'), value: 'total'},
            ],
            products: [],
            product_names: [],
            search: '',
            loading_csv_file: false,
            selected_designation: [],
            filter_selected_designation: '',
        }
    },
    watch: {
        selected_designation() {
            this.products_datatable_loading = true;
            this.filter_selected_designation = '';
            if (this.selected_designation.length !== 0) {
                this.selected_designation.forEach((supplier) => {
                    this.filter_selected_designation += (this.filter_selected_designation === '' ? supplier : ',' + supplier);
                });
            }
            this.getSupplies();
        }
    },
    created() {
        this.getSupplies();
    },
    methods: {
        getSupplies() {
            this.products_datatable_loading = true;
            this.axios.get(
                SUPPLIES + '?selected_product_name=' + this.filter_selected_designation
            ).then((success) => {
                this.products = success.data;

                    this.products.forEach(item => {
                        if (item.Nom_Produit) {
                            this.product_names.push(
                                item.Nom_Produit,
                            );
                        }
                    });
            }).catch((error) => {
                this.manageError(error.response.status,this.$t('global.error'));
            }).finally(() => {
                this.products_datatable_loading = false;
            })
        },

        suppliesDatatableFilterForCsv() {
            let result = [];
            this.products.forEach(item => {
                result.push({
                    Nom_Produit: item.Nom_Produit,
                    Ref_Constructeur: item.Ref_Constructeur,
                    QteAppro: item.QteAppro === null ? 0 : item.QteAppro,
                    QteMini: item.QteMini === null ? 0 : item.QteMini,
                    QteMaxi: item.QteMaxi === null ? 0 : item.QteMaxi,
                });
            });
            return result;
        },

        downloadCsvSupplies() {
            this.loading_csv_file = true;

            this.axios({
                url: '/api/download/csv/supply',
                data: {
                    'products': this.suppliesDatatableFilterForCsv(),
                },
                method: 'POST',
                responseType: 'blob',
            }).then((success) => {
                this.downloadCsvFile(success.data, "_supplies.csv");
            }).catch((error) => {
                this.manageError(error.response.status,this.$t('global.failure_to_download'));
            }).finally(() => {
                this.loading_csv_file = false;
            });
        },
    }
}
</script>

<style scoped>
.custom-red-chip{
    text-align:center;
    background-color: red
}
.custom-orange-chip{
    text-align:center;
    background-color: orange;
}
.custom-green-chip{
    text-align:center;
    background-color: green;
}
</style>
