<template>
    
    
    <div id="dashboard" v-if="($store.state.access_level >= 200 && $store.state.access_level <= 400) || $store.state.access_level <= 100 " style="margin-top:0px!important;padding:0px;position:relative; top:-12px;">
    
        <!-- MACHINE MENU PACKAGE -->
        <div style="padding:0px 12px;">
            <v-col class="pl-5 pr-5 pb-8" style="background-color:#001047;color:#FFFFFF;border-radius: 0px 0px 5px 5px;">
                <!-- TOP TITLE -->
                <v-row class="pt-5 mb-2" style="padding:5px;">
                    
                    <!-- LINE 1--> 
                    <v-row>
                        <v-col class="col ma-0 pa-0" style="display: flex;align-items: stretch;">
                            <!-- Etat de sante du parc -->
                            <v-card class="w-100 pl-6 pr-6" :loading="load_sites" style="background-color: #001047">
                                <v-card-title class="justify-center">
                                    <v-row>
                                        <v-col class="col-1 pa-5 ml-0 mr-5" style="display:flex;align-items: center;background-color: #ebf4ff;border-radius: 0px 0px 5px 5px;position: relative;top: -12px;padding-top: 38px !important;">
                                            <v-img contain
                                                max-height="75px"
                                                max-width="125px"
                                                :src="professional.logo"
                                            >
                                            </v-img>
                                        </v-col>
                                        <v-col class="col pa-10 pl-0">
                                            <div style="font-size: 10px;height: 18px;color:#FFFFFFB3;">Ref - {{ professional.uid }}</div>
                                            <div style="color:#FFFFFF;">{{ professional.name }}</div>
                                        </v-col>
                                        <v-col class="col-3 pa-5 pt-10">
                                            <div style="font-size: 55px;height: 55px;color: #FFFFFF;text-align: center;/* border: 1px solid grey; *//* padding: 10px; *//* width: 55px; *//* text-align: center; *//* align-items: center; *//* border-radius: 36px; *//* margin-bottom: 10px; */padding-top: 17px;"> {{ tickets }}</div>
                                            <div style="font-size: 12px;height: 22px;color: #FFFFFFB3;text-align: center;line-height: 16px;/* padding-top: 30px; */padding-top: 14px;"> Tickets d'assistance disponibles</div>
                                        </v-col>
                                        <v-col class="col-4 pa-0 pl-4">
                                            <!-- Dernière info publiée -->
                                            <v-card elevation="2" outlined class="w-100 ma-0" style="background-color: #ebf4ff;" :loading="load_sites">
                                                <v-col class="pa-0" style="overflow: hidden;">
                                                    <v-col class="col pa-0">
                                                        <!-- Stats -->
                                                        <v-col @click="dialog_new_update = true" class="pointer" style="background-color: rgb(235 244 255);;justify-content: center;align-items: center;height:135px;">
                                                            <div style="text-align: center;
                                                                        font-size: 180px;
                                                                        position: relative;
                                                                        font-weight: bold;
                                                                        padding: 10px;
                                                                        color: rgb(255 255 255);
                                                                        top: 105px;
                                                                        left: -210px;
                                                                        width: 920px;"
                                                            >V1.5.2</div>

                                                            <div style="text-align: center;
                                                                        font-size: 45px;
                                                                        color: #001047d1;
                                                                        padding: 10px;
                                                                        position: relative;
                                                                        top: -50px;
                                                                        z-index: 10;
                                                                        display:flex;
                                                                        justify-content:center;"
                                                            ><div style="font-weight: bold;">V</div>ersion 1.5.<div style="font-weight: bold;">2</div></div>
                                                            <div style="    text-align: center;
                                                                            font-size: 14px;
                                                                            padding: 10px;
                                                                            color: rgb(0 16 71);
                                                                            position: relative;
                                                                            top: -50px;
                                                                            z-index: 10;
                                                                            line-height:20px;"
                                                            >Cliquez ici pour découvrir le patch-note complet <br> toutes les nouveautés et modifications y sont renseignées.</div>
                                                        </v-col>
                                                    </v-col>
                                                </v-col>  
                                            </v-card>   

                                        </v-col>
                                    </v-row>                         
                                </v-card-title>
                            </v-card>
                        </v-col>
                    </v-row>

                </v-row>

                <!-- MACHINE INFOS -->
                <v-col class="mb-4" v-if="infoToggle == true" style="background-color:#001047;border-radius:5px;align-items:stretch;">

                    <!-- Line 2 -->
                    <v-row>
                        <v-col class="col ma-2 mr-0 pa-0 mb-0" style="display: flex;align-items: stretch;">
                            <!-- Etat de sante du parc -->
                            <v-card class="w-100 ma-0">
                                <!-- STATS BAR -->
                                <v-col style="background-color: #ebf4ff" class="mt-0 mb-2">
                                    <v-row>
                                        <v-col class="pa-2 col-2">
                                            <div style="text-align: center;font-size:40px;font-weight:bold;">{{ sites_count }}</div>
                                            <div style="text-align: center;font-size: 14px;">Parcs</div>
                                        </v-col>
                                        <v-col class="pa-2 col-2" style="border-left: 1px solid #e0e0e0;">
                                            <div style="text-align: center;font-size:40px;font-weight:bold;">{{ buildings_count }}</div>
                                            <div style="text-align: center;font-size: 14px;">Regroupements</div>
                                        </v-col>
                                        <v-col class="pa-2 col-2" style="border-left: 1px solid #e0e0e0;">
                                            <div style="text-align: center;font-size:40px;font-weight:bold;">{{ machines_count }}</div>
                                            <div style="text-align: center;font-size: 14px;">Machines</div>
                                        </v-col>
                                        <v-col class="pa-2 col-2" style="border-left: 1px solid #e0e0e0;">
                                            <div style="text-align: center;font-size:40px;font-weight:bold;">{{ subassemblies_count }}</div>
                                            <div style="text-align: center;font-size: 14px;">Sous ensembles</div>
                                        </v-col>
                                        <v-col class="pa-2 col-2" style="border-left: 1px solid #e0e0e0;">
                                            <div style="text-align: center;font-size:40px;font-weight:bold;">{{ campains_count }}</div>
                                            <div style="text-align: center;font-size: 14px;">Campagnes</div>
                                        </v-col>
                                        <v-col class="pa-2 col-2" style="border-left: 1px solid #e0e0e0;">
                                            <div style="text-align: center;font-size:40px;font-weight:bold;">{{ reports_count }}</div>
                                            <div style="text-align: center;font-size: 14px;">Rapports</div>
                                        </v-col>
                                    </v-row>
                                </v-col> 

                                <!-- PIE CHART -->
                                <v-row class="mt-5" v-if="load_done">
                                    <!-- graph -->
                                    <PieChart
                                        :title="chart.title"
                                        :subtitle="chart.subtitle"
                                        :backgroundColor="chart.backgroundColor"
                                        :gridLineColor="chart.gridLineColor"
                                        :colors="chart.colors"
                                        :thousandsSep="chart.thousandsSep"
                                        :decimalPoint="chart.decimalPoint"
                                        :height="chart.height"
                                        :yAxis="chart.yAxis"
                                        :xAxis="chart.xAxis"
                                        :series="chart.series"
                                        :customStyles="chart.customStyles"
                                        :plotOptions="Object.assign({}, chart.plotOptions, {
                                            pie: {
                                            innerSize: '40%'
                                            }
                                        })"
                                        :dataLabels="chart.dataLabels"
                                        class="col col"
                                    />
                                    <v-row class="pt-5 pb-10 col-7">
                                        <!-- 4 COLORED SQUARES -->
                                        <v-col v-if="data_0 === 0" class="ma-1 col-5" style="border-radius: 5px;display:flex;flex-direction:column;justify-content:center;background-color: #e0e0e0;">
                                            <div style="text-align: center;font-size:40px;font-weight:bold;color:white;">{{ data_0 }} </div>
                                            <div style="text-align: center;font-size: 14px;color:white;">{{ $t('dashboard.green_health_content') }}</div>
                                        </v-col>
                                        <v-col v-else class="ma-1 col-5" style="border-radius: 5px;display:flex;flex-direction:column;justify-content:center;background-color: #4CAF50;">
                                            <div style="text-align: center;font-size:40px;font-weight:bold;color:white;">{{ data_0 }} </div>
                                            <div style="text-align: center;font-size: 14px;color:white;">{{ $t('dashboard.green_health_content') }}</div>
                                        </v-col>

                                        <v-col v-if="data_1 === 0" class="ma-1 col-5" style="border-radius: 5px;display:flex;flex-direction:column;justify-content:center;background-color: #e0e0e0;">
                                            <div style="text-align: center;font-size:40px;font-weight:bold;color:white;">{{ data_1 }}</div>
                                            <div style="text-align: center;font-size: 14px;color:white;">{{ $t('dashboard.yellow_health_content') }}</div>
                                        </v-col>
                                        <v-col v-else class="ma-1 col-5" style="border-radius: 5px;display:flex;flex-direction:column;justify-content:center;background-color: #FFCB05;">
                                            <div style="text-align: center;font-size:40px;font-weight:bold;color:white;">{{ data_1 }}</div>
                                            <div style="text-align: center;font-size: 14px;color:white;">{{ $t('dashboard.yellow_health_content') }}</div>
                                        </v-col>

                                        <v-col v-if="data_2 === 0" class="ma-1 col-5" style="border-radius: 5px;display:flex;flex-direction:column;justify-content:center;background-color: #e0e0e0;">
                                            <div style="text-align: center;font-size:40px;font-weight:bold;color:white;">{{ data_2 }}</div>
                                            <div style="text-align: center;font-size: 14px;color:white;">{{ $t('dashboard.red_health_content') }}</div>
                                        </v-col>
                                        <v-col v-else class="ma-1 col-5" style="border-radius: 5px;display:flex;flex-direction:column;justify-content:center;background-color: #F44336;">
                                            <div style="text-align: center;font-size:40px;font-weight:bold;color:white;">{{ data_2 }}</div>
                                            <div style="text-align: center;font-size: 14px;color:white;">{{ $t('dashboard.red_health_content') }}</div>
                                        </v-col>

                                        <v-col v-if="data_3 === 0" class="ma-1 col-5" style="border-radius: 5px;display:flex;flex-direction:column;justify-content:center;background-color: #e0e0e0;">
                                            <div style="text-align: center;font-size:40px;font-weight:bold;color:white;">{{ data_3 }}</div>
                                            <div style="text-align: center;font-size: 14px;color:white;">{{ $t('dashboard.black_health_content') }}</div>
                                        </v-col>
                                        <v-col v-else class="ma-1 col-5" style="border-radius: 5px;display:flex;flex-direction:column;justify-content:center;background-color: black;">
                                            <div style="text-align: center;font-size:40px;font-weight:bold;color:white;">{{ data_3 }}</div>
                                            <div style="text-align: center;font-size: 14px;color:white;">{{ $t('dashboard.black_health_content') }}</div>
                                        </v-col>
                                    </v-row>
                                </v-row>
                                <v-snackbar
                                    v-model="snackbar"
                                    :color="snackbar_type">
                                    {{ snackbar_text }}
                                    <template v-slot:action="{ attrs }">
                                        <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                                    </template>
                                </v-snackbar>
                            </v-card>
                        </v-col>
                        
                        <!-- MCO -->
                        <v-col class="col-4 ma-0 pa-0" style="overflow:hidden;display:flex;align-items:strectch;">
                            <!-- Maps -->
                            <v-card elevation="0" outlined class="ma-3 mr-2 mb-0" :loading="load_sites" style="margin-top: 7px !important;width:100%;">
                                    <map-equipment :sites="$store.state.sites" :professionals_resume=" $store.state.professionals_resume"/>
                            </v-card>
                            <!-- Etat de sante du parc -->
                            <v-card elevation="2" outlined class="w-100 ma-1 mt-4" style="display:none;" :loading="load_sites">
                                <!-- OLD MAP TBDebug -->
                                <!--
                                <v-card elevation="2" outlined class="w-100 ma-1" :loading="load_sites">
                                        <map-equipment :sites="sites" :professionals_resume="professionals_resume"/>
                                </v-card>
                                -->
                                <!-- MCO DATAS -->
                                <v-col style="display:flex;justify-content:center;">
                                    <v-row class="col pa-5" style="display:flex;justify-content: center;">
                                        <!-- Stats -->
                                        <v-row class="pa-2 ma-5 col-12" style="background-color: #bbbbbb; border-radius: 5px;align-items:center;justify-content: center;border: 1px solid #808080c2;">
                                            <v-icon size="25" color="#525252" style="padding-bottom:5px">mdi-wifi</v-icon>
                                            <div style="text-align: center;font-size: 14px;color:#525252;padding:10px;">{{ $t('dashboard.network') }}</div>
                                            <!------------------- Info bulle ---------------------->
                                            <v-tooltip bottom dark class="mr-2" style="">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 v-size--default" dusk="logout-button" style="border: 1px solid #525252;width:18px;height:18px;">
                                                        <v-icon color="#525252" style="font-size:16px">mdi-information-variant</v-icon>
                                                    </v-btn>
                                                </template>
                                                <template v-slot:default>
                                                    <div style="min-width: 200px;">
                                                        <p class="pl-5">{{ $t('global.1_5_3') }}</p>
                                                    </div>
                                                </template>
                                            </v-tooltip>
                                        </v-row>

                                        <v-row class="pa-2 ma-5 col-12" style="background-color: #bbbbbb; border-radius: 5px;align-items:center;justify-content: center;border: 1px solid #808080c2;">
                                            <v-icon size="25" color="#525252" style="padding-bottom:5px">mdi-battery-charging-100</v-icon>
                                            <div style="text-align: center;font-size: 14px;color:#525252;padding:10px;">{{ $t('dashboard.battery') }}</div>
                                            <!------------------- Info bulle ---------------------->
                                            <v-tooltip bottom dark class="mr-2" style="">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 v-size--default" dusk="logout-button" style="border: 1px solid #525252;width:18px;height:18px;">
                                                        <v-icon color="#525252" style="font-size:16px">mdi-information-variant</v-icon>
                                                    </v-btn>
                                                </template>
                                                <template v-slot:default>
                                                    <div style="min-width: 200px;">
                                                        <p class="pl-5">{{ $t('global.1_5_3') }}</p>
                                                    </div>
                                                </template>
                                            </v-tooltip>
                                        </v-row>

                                        <v-row class="pa-2 ma-5 col-12" style="background-color: #bbbbbb; border-radius: 5px;align-items:center;justify-content: center;border: 1px solid #808080c2;">
                                            <v-icon size="25" color="#525252" style="padding-bottom:5px">mdi-router-wireless</v-icon>
                                            <div style="text-align: center;font-size: 14px;color:#525252;padding:10px;">{{ $t('dashboard.gateway') }}</div>
                                            <!------------------- Info bulle ---------------------->
                                            <v-tooltip bottom dark class="mr-2" style="">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 v-size--default" dusk="logout-button" style="border: 1px solid #525252;width:18px;height:18px;">
                                                        <v-icon color="#525252" style="font-size:16px">mdi-information-variant</v-icon>
                                                    </v-btn>
                                                </template>
                                                <template v-slot:default>
                                                    <div style="min-width: 200px;">
                                                        <p class="pl-5">{{ $t('global.1_5_3') }}</p>
                                                    </div>
                                                </template>
                                            </v-tooltip>
                                        </v-row>
                                    </v-row>
                                </v-col>                

                                <v-snackbar
                                    v-model="snackbar"
                                    :color="snackbar_type">
                                    {{ snackbar_text }}
                                    <template v-slot:action="{ attrs }">
                                        <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                                    </template>
                                </v-snackbar>
                            </v-card>
                        </v-col>
                    </v-row>

                </v-col>

                <!-- MACHINE LINKS -->
                <v-row v-if="infoToggle == false" style="border-top: 1px solid #ffffff1c;">

                </v-row>

                <v-row v-if="infoToggle == true">

                </v-row>
            </v-col>
        </div>

        <!-- Line3 -->
        <v-row class="pl-3 pr-5 mt-1">            
            <v-col class="col ma-2 pa-0">
                <!-- Gestion des alertes -->
                <v-card elevation="1" outlined class="w-100 ma-1" :loading="load_sites" style="border-radius: 5px; overflow: hidden;">
                    <v-data-table
                        :headers="headersAlert"
                        :items="notifications"
                        class="elevation-1 datatable-operation"
                        :items-per-page="5"
                        :loading="loading_datatable"
                        :search="search"
                    >
                        <!-- TOP -->
                        <template v-slot:top>
                            <v-toolbar flat class="pt-3">
                                <v-row class="elevation-0 pl-5 pt-2 pb-2" >
                                    <v-icon class="mr-2" style="color: black;">mdi-bell-alert-outline</v-icon>
                                    <h4>Derniers évènements</h4>
                                </v-row>
                            </v-toolbar>
                        </template>

                        <!-- CRITICITE -->
                        <template v-slot:item.crit="{ item }">
                            <div v-for="filter in data_filters" :key="filter">
                                <v-tooltip bottom dark>
                                    <template v-slot:activator="{ on }">
                                        <div v-if="item.level === filter.level">
                                            <v-btn class="ma-auto mr-5 ml-5 col-0" text icon v-on="on" link :to="'/'+ item.link" dusk="logout-button" :style="{backgroundColor: filter.level_color}">
                                                <v-icon color="white">mdi-arrow-right-circle-outline</v-icon>
                                            </v-btn>
                                        </div>
                                    </template>
                                    <span>{{ $t('notification.redirect') }}</span>
                                </v-tooltip>
                            </div>
                            
                        </template>

                        <!-- DATE -->
                        <template v-slot:item.created_at="{ item }">
                            {{ item.created_at }}
                        </template>

                        <!-- SUJET -->
                        <template v-slot:item.subject="{ item }">
                            {{ item.subject }}
                        </template>
                    </v-data-table>

                    <v-snackbar
                        v-model="snackbar"
                        :color="snackbar_type">
                        {{ snackbar_text }}
                        <template v-slot:action="{ attrs }">
                            <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                        </template>
                    </v-snackbar>
                </v-card>
            </v-col>
        </v-row>

        <!-- Line2 -->
        <v-row class="mt-1 pl-3 pr-4">
            <v-col class="col pa-0 ma-2 mr-3">
                <!-- Historique des rapports -->
                <v-card elevation="1" outlined class="w-100 ma-1" :loading="load_sites" style="border-radius: 5px; overflow: hidden;">
                    <v-data-table
                        :headers="headersReports"
                        :items="reports"
                        class="elevation-1 datatable-operation"
                        :items-per-page="5"
                        :loading="loading_datatable"
                        :search="search"
                    >
                        <!-- TOP -->
                        <template v-slot:top>
                            <v-toolbar flat class="pt-3">
                                <v-row class="elevation-0 pl-5 pt-2 pb-2" >
                                    <v-icon class="mr-2" style="color: black;">mdi-chart-box-outline</v-icon>
                                    <h4>{{ $t('dashboard.report_historic') }}</h4>
                                </v-row>
                            </v-toolbar>
                        </template>

                        <!-- CRITICITE -->
                        <template v-slot:item.crit="{ item }">
                            <div v-for="filter in data_filters" :key="filter" style="display: flex;flex-direction:column;align-items: center;">
                                <div v-for="machine in machines" :key="machine">
                                    <v-tooltip bottom dark>
                                        <template v-slot:activator="{ on }">
                                            <div v-if="item.id_machine === machine.id && item.alert_level === filter.level">
                                                <v-btn class="ma-auto mr-5 ml-5 col-0" text icon v-on="on" link :to="'/reported/'+ item.id " dusk="logout-button" :style="{backgroundColor: filter.level_color}">
                                                    <v-icon color="white">mdi-arrow-right-circle-outline</v-icon>
                                                </v-btn>
                                            </div>
                                        </template>
                                        <span>{{ $t('notification.redirect') }}</span>
                                    </v-tooltip>
                                </div>
                                <!--<div v-if="report.alert_level === filter.level" style="width: 15px;height: 15px;border-radius: 50%"></div>-->    
                            </div>
                        </template>

                        <!-- MACHINE -->
                        <template v-slot:item.name="{ item }">
                            <div v-for="machine in machines" :key="machine">
                                <div v-if="item.id_machine === machine.id">
                                    {{ machine.label }}
                                </div>         
                            </div>
                        </template>

                        <!-- CAMPAGNE -->
                        <template v-slot:item.campain="{ item }">
                            <div v-for="campain in campains" :key="campain">
                                <div v-if="item.id_campain === campain.id">
                                    {{ campain.name }}
                                </div>
                            </div>
                        </template>

                        <!-- EXPERT -->
                        <template v-slot:item.analyst="{ item }">
                            <div v-for="analyst in analysts_datas" :key="analyst">
                                <div v-if="item.analyst === analyst.id">
                                    {{ analyst.name }}
                                </div>
                            </div>
                        </template>

                        <!-- STATE -->
                        <template v-slot:item.state="{ item }">
                            <div v-if="item.released_at != null">
                                <v-icon style="color:green;">mdi-check-circle-outline</v-icon>
                            </div>
                            <div v-else>
                                <v-tooltip bottom dark class="mr-2 ml-2">
                                    <template v-slot:activator="{ on }">
                                        <v-icon style="color:#FFCB05;">mdi-progress-check</v-icon>
                                    </template>
                                    <span>En cours de validation</span>
                                </v-tooltip>
                            </div>
                        </template>

                        <!-- DATE -->
                        <template v-slot:item.released_at="{ item }">
                            {{ item.released_at_formatted }} 
                        </template>
                    </v-data-table>

                    <v-snackbar
                        v-model="snackbar"
                        :color="snackbar_type">
                        {{ snackbar_text }}
                        <template v-slot:action="{ attrs }">
                            <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                        </template>
                    </v-snackbar>
                </v-card>
            </v-col>

            <!-- Derniers évènements 
            <v-col class="col ma-2 pa-0">
                <v-card elevation="2" outlined class="w-100 ma-1" :loading="load_sites">
                    <v-card-title  class="justify-center">
                        <v-icon class="mr-2">mdi-calendar-clock</v-icon>
                        {{ $t('dashboard.last_events') }}
                    </v-card-title>

                    <v-divider></v-divider>

                    <ul style="padding: 20px!important;">
                        
                        <li v-if="last_update_available && notifications_available" class="pointer row notification_card" style="text-align: center">
                            <div class="col-1">
                                <v-icon class="mr-2">{{last_update.tag_icon}}</v-icon>
                            </div>
                            <div class="col-4">
                                {{ last_notification.created_at }}
                            </div>
                            <div class="col">
                                {{ last_notification.subject }}
                            </div>
                        </li>
                        <li v-else class="pointer row notification_card" style="text-align: center">
                            <div class="col-1">
                                <v-icon class="mr-2">{{last_update.tag_icon}}</v-icon>
                            </div>
                            <div class="col-4">
                                -
                            </div>
                            <div class="col">
                                Aucune mise à jour
                            </div>
                        </li>

                        
                        <li v-if="last_alert_available && notifications_available" class="pointer row notification_card" style="text-align: center">
                            <div class="col-1">
                                <v-icon class="mr-2">{{last_alert.tag_icon}}</v-icon>
                            </div>
                            <div class="col-4">
                                {{ last_notification.created_at }}
                            </div>
                            <div class="col">
                                {{ last_notification.subject }}
                            </div>
                        </li>
                        <li v-else class="pointer row notification_card" style="text-align: center">
                            <div class="col-1">
                                <v-icon class="mr-2">{{last_alert.tag_icon}}</v-icon>
                            </div>
                            <div class="col-4">
                                -
                            </div>
                            <div class="col">
                                Aucune alerte
                            </div>
                        </li>

                        
                        <li v-if="last_campain_available && notifications_available" class="pointer row notification_card" style="text-align: center">
                            <div class="col-1">
                                <v-icon class="mr-2">{{ last_campain.tag_icon }}</v-icon>
                            </div>
                            <div class="col-4">
                                {{ last_campain.created_at }}
                            </div>
                            <div class="col">
                                {{ last_campain.subject }}
                            </div>
                        </li>
                        <li v-else class="pointer row notification_card" style="text-align: center">
                            <div class="col-1">
                                <v-icon class="mr-2">{{last_campain.tag_icon}}</v-icon>
                            </div>
                            <div class="col-4">
                                -
                            </div>
                            <div class="col">
                                Aucune campagne
                            </div>
                        </li>

                        
                        <li v-if="last_report_available && notifications_available" class="pointer row notification_card" style="text-align: center">
                            <div class="col-1">
                                <v-icon class="mr-2">{{ last_report.tag_icon }}</v-icon>
                            </div>
                            <div class="col-4">
                                {{ last_report.created_at }}
                            </div>
                            <div class="col">
                                {{ last_report.subject }}
                            </div>
                        </li>

                        <li v-else class="pointer row notification_card" style="text-align: center">
                            <div class="col-1">
                                <v-icon class="mr-2">{{ last_report.tag_icon }}</v-icon>
                            </div>
                            <div class="col-4">
                                -
                            </div>
                            <div class="col">
                                Aucun rapport
                            </div>
                        </li>

                        
                        <li v-if="last_message_available && notifications_available" class="pointer row notification_card" style="text-align: center">
                            <div class="col-1">
                                <v-icon class="mr-2">{{last_message.tag_icon}}</v-icon>
                            </div>
                            <div class="col-4">
                                {{ notification.created_at }}
                            </div>
                            <div class="col">
                                {{ notification.subject }}
                            </div>
                        </li>

                        <li v-else class="pointer row notification_card" style="text-align: center">
                            <div class="col-1">
                                <v-icon class="mr-2">{{ last_message.tag_icon }}</v-icon>
                            </div>
                            <div class="col-4">
                                -
                            </div>
                            <div class="col">
                                Aucune information
                            </div>
                        </li>

                        
                        <li v-if="last_connection_available" class="pointer row notification_card" style="text-align: center">
                            <div class="col-1">
                                <v-icon class="mr-2">{{ last_connection.tag_icon }}</v-icon>
                            </div>
                            <div class="col-4">
                                Dernière connexion : 
                            </div>
                            <div class="col">
                                {{ me.date_last_seen }}
                            </div>
                        </li>

                        <li v-else class="pointer row notification_card" style="text-align: center">
                            <div class="col-1">
                                <v-icon class="mr-2">{{last_connection.tag_icon}}</v-icon>
                            </div>
                            <div class="col-4">
                                -
                            </div>
                            <div class="col">
                                Aucune connexion
                            </div>
                        </li>
                    </ul>


                    <v-snackbar
                        v-model="snackbar"
                        :color="snackbar_type">
                        {{ snackbar_text }}
                        <template v-slot:action="{ attrs }">
                            <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                        </template>
                    </v-snackbar>
                </v-card>
            </v-col>
             -->
        </v-row>
    
        <!-- Modal new update -->
        <v-dialog v-model="dialog_new_update" max-width="1300px">
            <v-card style="overflow:hidden;">
                <v-card-title>
                    <span class="headline">
                        Note de mise à jour
                    </span>
                </v-card-title>
                <v-divider></v-divider>

                <v-row style="padding:60px;padding-top:20px;justify-content:space-between;">
                    <v-col class="col-6" style="padding-top:0!important">
                        <v-card elevation="2" outlined class="w-100 ma-1" style="background-color: #0f1835;" :loading="load_sites">
                            <v-col class="pa-0" style="overflow: hidden;">
                                <v-col class="col pa-0">
                                    <!-- Stats -->
                                    <v-col style="background-color: #014181;justify-content: center;align-items: center;height:150px;">
                                        <div style="text-align: center;
                                                    font-size: 240px;
                                                    position: relative;
                                                    font-weight: bold;
                                                    padding: 10px;
                                                    color: rgba(191, 191, 191, 0.09);
                                                    top: -127px;
                                                    left: -85px;
                                                    width: 600px;"
                                        >V1.5.2</div>

                                        <div style="text-align: center;
                                                    font-size: 45px;
                                                    color: rgb(225, 225, 225);
                                                    font-weight: bold;
                                                    padding: 10px;
                                                    position: relative;
                                                    top: -740px;
                                                    z-index: 10;"
                                        >Version 1.5.2</div>
                                    </v-col>
                                </v-col>
                            </v-col>  
                        </v-card>
                        <br/><br/>
                        <h4>Informations communes :</h4>
                        <v-row class="pb-5 pt-5">
                            <v-carousel 
                                height = "200px"
                                cycle
                                interval="5000"
                                hide-delimiters>
                                <v-carousel-item>
                                    <p style="padding:25px 110px 0 110px">
                                        <strong>Onglet [Campagnes]</strong> : cet onglet reprends toutes les interventions que nous réalisons sur vos différents sites.<br/>
                                        Que ce soit une prestation ponctuelle, du suivi periodique ou une instrumentation vous pouvez suivre l'avancement de nos interventions.
                                    </p>
                                </v-carousel-item>

                                <v-carousel-item>
                                    <p style="padding:25px 110px 0 110px">
                                        <strong>Onglet [Parc machines]</strong> : les informations relatives à vos parcs machines et regroupements de machines.<br/>
                                        (Les regroupements permettent de relier plusieurs machines à une même entité, ex: un batiment, un secteur..)
                                    </p>
                                </v-carousel-item>

                                <v-carousel-item>
                                    <p style="padding:25px 110px 0 110px">
                                        <strong>Onglet [Equipements]</strong> : nous faisons référence à tous les équipement sur lesquels nous intervenons.<br/>
                                        Chaque équipement (machine) peut être supervisé dans son ensemble ou via des sous ensembles.
                                        Ceux ci sont accessibles depuis cette page.
                                    </p>
                                </v-carousel-item>
                            </v-carousel>
                        </v-row>
                    </v-col>

                    <v-col class="col mt-1 pa-10" style="background-color:#e0e0e0;border-radius:10px;margin-left:50px"> 
                        <h4>Ajout et modifications réalisées :</h4>
                        <ul>
                            <li>Améliorations des composantes d'ergonomies</li>
                            <li>Améliorations de l'accessibilité</li>
                            <li>Amélioration de la visibilité des rapports</li>
                            <li>Amélioration de la visibilité des alertes</li>
                            <li>Amélioration de la visibilité des données (ONLINE)</li>
                            <li>Ajout d'un centre d'aide</li>
                            <li>Ajout d'un menu de navigation (haut de page)</li>
                            <li>Optimisation de requêtes</li>
                            <li>Augmentation des niveaux de sécurités</li>
                            <li>Correction des pages non fonctionnelles</li>
                            <li>Correction des métriques non récupérées</li>                       
                        </ul>
                        <br/>
                    </v-col>
                </v-row>

                <!-- Bouton pour fermer -->
                <v-card-actions class="mb-3" style="position:absolute;top:5px;right:10px">
                    <v-btn @click="dialog_new_update = false" style="color:red;max-width: 30px;padding: 0 0 0 5px;min-width: 10px!important;height: 30px!important;">
                        <v-icon left>mdi-close</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import {SITES, NOTIFICATIONS, REPORT, CAMPAIN, AUTH_ME, USERS, PROFESSIONALS,CONTRACTS} from "../../api";
import MapEquipment from "../components/Dashboard/MapEquipment";
import moment from "moment";
import { PieChart } from 'vuejs-highcharts';
import { TimelineChart } from 'vuejs-highcharts';

export default {
    name: "Dashboard",
    components: {
        MapEquipment, PieChart, TimelineChart
    },
    props: {
        sites: {
            type: Array,
            required: true,
            default: () => [],
        },
        professionals_resume: {
            type: Array,
            required: true,
            default: () => [],
        }
    },
    created() {
        this.loading_datatable = true;
        this.initialize();
        setTimeout(async ()=> {
            await this.$store.dispatch('professionals_resume/load');
            await this.$store.dispatch('sites/load');
        },1000);
        moment.locale('fr');
    },
    data() {
        return {
            headersReports: [
                {text: "Criticité", value: 'crit', width: '5%'},
                {text: "Equipement", value: 'name', width: '30%'},
                {text: "Campagne", value: 'campain', width:'30%'},
                {text: "Analyste", value: 'analyst', width:'13%'},
                {text: "Publication", value: 'state', width:'7%'},
                {text: "Date", value: 'released_at', width:'15%'},
            ],
            headersAlert: [
                {text: "Criticité", value: 'crit', width: 'auto'},
                {text: "Description", value: 'subject', width:'90%'},
                {text: "Date", value: 'created_at', width:'10%'},
                
            ],
            infoToggle: true, // Machine menu package //
            // Gestion des alertes
            raw_notifications:[],
            raw_sites:[],
            raw_buildings:[],
            raw_machines:[],
            raw_subassemblies:[],
            raw_campains:[],
            raw_reports:[],

            notifications:[],
            sitez:[],
            buildings:[],
            machines:[],
            subassemblies:[],
            campains:[],
            reports:[],
            me:[],
            professional:{},

            global_notifications_count: 0,
            sites_count:0,
            buildings_count:0,
            machines_count:0,
            subassemblies_count:0,
            campains_count:0,
            reports_count:0,

            notifications_available: false,
            sites_available: false,
            buildings_available: false,
            machines_available: false,
            subassemblies_available: false,
            campain_available: false,
            report_available: false,

            last_alert_available: false,
            last_update_available: false,
            last_info_available: false,
            last_report_available: false,
            last_campain_available: false,
            last_me_available: false,

            // GENERAL
            access_level: this.$store.state.access_level,
            id: this.$route.params.id,
            load_done:false,
            load_sequence: 0,
            dialog_new_update:false,
            filter_mode:9,
            load_sites: false,

            //Derniers évènements 
            last_update:{ tag_icon: "mdi-reload"},
            last_alert:{ tag_icon: "mdi-alert-outline"},
            last_campain:{ tag_icon: "mdi-timeline-check-outline"},
            last_connection:{ tag_icon: "mdi-power", created_at : "Dernière connexion :"},
            last_message:{ tag_icon: "mdi-information-outline"},
            last_report:{ tag_icon: "mdi-chart-box-outline"},

            // MACHINE CHART
            chart: {
                title: 'Machines',
                backgroundColor: '#fff',
                gridLineColor: '#ccd6eb',
                colors: [
                    '#4CAF50',
                    '#FFCB05',
                    '#F44336',
                    'black',
                ],
                thousandsSep: '.',
                decimalPoint: ',',
                height: 400,
                xAxis: {
                    title: {
                        text: 'xAxis title'
                    },
                    categories: [2000, 2001, 2002, 2003]
                },
                yAxis: {
                    title: {
                        text: 'yAxis title'
                    }
                },
                series: [{
                    type: 'pie',
                    innerSize: '50%',
                    name: 'Machine',
                    data: [],
                }],
                crosshair: true,
                customStyles: {
                    color: '#666',
                },
                dataLabels: {
                    enabled: true,
                    format: '{point.y}'
                },
            },

            // Etat du parc
            data_filters: [
                { level: 9, level_name: "Tout voir", level_color: "#4CAF50", count:this.global_notifications_count},
                { level: -1, level_name: "Rapport en attente", level_color: "grey", count:0, annotation: "Etat à définir"},

                { level: 1, level_name: "Comportement vibratoire correct", level_color: "#4CAF50", count:0, percent: 100, annotation: "Etat neuf" },
                { level: 2, level_name: "Comportement vibratoire correct", level_color: "#4CAF50", count:0, percent: 90, annotation: "Comportement vibratoire correct" },
                { level: 3, level_name: "Comportement vibratoire correct", level_color: "#4CAF50", count:0, percent: 80, annotation: "Pour information" },

                { level: 11, level_name: "Comportement vibratoire à surveiller", level_color: "#FFCB05", count:0, percent: 70, annotation: "A suivre lors de la prochaine campagne" },
                { level: 12, level_name: "Comportement vibratoire à surveiller", level_color: "#FFCB05", count:0, percent: 60, annotation: "A suivre de manière rapprochée" },
                { level: 13, level_name: "Comportement vibratoire à surveiller", level_color: "#FFCB05", count:0, percent: 50, annotation: "Expertise complémentaire préconisée" },

                { level: 21, level_name: "Action à prévoir au prochain arrêt technique", level_color: "#F44336", count:0, percent: 40, annotation: "Action à prévoir au prochaine arrêt technique" },
                { level: 22, level_name: "Action à prévoir au prochain arrêt technique", level_color: "#F44336", count:0, percent: 30, annotation: "Action à prévoir au prochaine arrêt technique" },
                { level: 23, level_name: "Action à prévoir au prochain arrêt technique", level_color: "#F44336", count:0, percent: 20, annotation: "Action à prévoir au prochaine arrêt technique" },
            
                { level: 31, level_name: "Intervention urgente", level_color: "black", count:0, percent: 10, annotation: "Action à prévoir dans les plus brefs délais" },
                { level: 32, level_name: "Intervention urgente", level_color: "black", count:0, percent: 0, annotation: "Arrêt de la machine recommandé" }, 
            ],

            // Graphe historique
            historic_tags: [
                {
                    level: 4,
                    tag_name: "Rapports",
                    tag_icon: "mdi-timeline-text-outline",
                    tag_subject: "",
                    count:0
                },
                {
                    level: 3,
                    tag_name: "Alertes",
                    tag_icon: "mdi-timeline-alert-outline",
                    tag_subject: "",
                    count:0
                },
                {
                    level: 2,
                    tag_name: "Mise à jour",
                    tag_icon: "mdi-timeline-clock-outline",
                    tag_subject: "",
                    count:0
                },
                {
                    level: 1,
                    tag_name: "Prochaine campagne",
                    tag_icon: "mdi-timeline-plus-outline",
                    tag_subject: "",
                    count:0
                },
                {
                    level: 0,
                    tag_name: "infos",
                    tag_icon: "mdi-timeline-question-outline",
                    tag_subject: "",
                    count:0
                },
            ],

            // Historique des rapports
            notification_levels: [
                {
                    level: "",
                    level_name: "Tout voir",
                    level_color: "#d7d7d752",
                    count:this.global_notifications_count
                },
                {
                    level: 3,
                    level_name: "Urgence",
                    level_color: "black",
                    count:0
                },
                {
                    level: 2,
                    level_name: "Importance élevée",
                    level_color: "#F44336",
                    count:0
                },
                {
                    level: 1,
                    level_name: "A suivre",
                    level_color: "#FFCB05",
                    count:0
                },
                {
                    level: 0,
                    level_name: "Pour information",
                    level_color: "#4CAF50",
                    count:0
                },
                {
                    level: -1,
                    level_name: "Autres",
                    level_color: "grey",
                    count:0
                },
            ],

            analysts_datas:[
                {id:40,  name:"Eric Quenneville", tel:"+33 6 38 38 11 18", email:"eric.quenneville@techview.fr", logo:"https://techview.fr/wp-content/uploads/2024/10/ERIC-QUENNEVILLE-CAT-III-e1725528314226-removebg-preview-2.png"},
                {id:121, name:"Pascal Ardoin",    tel:"+33 6 74 35 31 13", email:"pascal.ardoin@techview.fr", logo:"https://techview.fr/wp-content/uploads/2024/09/PASCAL-ARDOIN-CAT-II-e1725542858336.png"},
                {id:91,  name:"Amandine Prieur",  tel:"+33 7 87 58 28 98", email:"amandine.prieur@techview.fr", logo:"https://techview.fr/wp-content/uploads/2024/12/572e2dac-42b9-46f8-a7d5-995f87877850.png"},
                {id:278,  name:"Louis Blaise",  tel:"+33 6 77 33 08 68", email:"louis.blaise@techview.fr"},
                {id:216,  name:"Guillaume Maury",  tel:"+33 6 07 46 48 40", email:"guillaume.maury@techview.fr"},
                {id:79,  name:"Sebastien Poudenx",  tel:"+33 6 68 42 45 15", email:"sebastien.poudenx@techview.fr"},
            ],
        }       
    },
    methods: {
        initialize(){
            this.getNotification();
            this.getSites();
            this.getReports();
            this.getCampains();
            this.getMe();
            this.getTeam();
            this.getProfessional();
            this.getContract();
        },
        checkDataAvailable(datas){
            if(typeof datas[0] === 'undefined') {                    
                return false;
            }else {
                return true;
            }
        },

        // Get all datas
        getNotification() {
            // GET ALL SEEN NOTIFICATIONS
            this.axios.get(NOTIFICATIONS + '/my-lasts-notifications')
            .then((success) => {
                this.filter_mode = 9;
                this.raw_notifications = [];
                this.notifications = [];

                if(!this.checkDataAvailable(success.data)) {
                    this.notifications_available = false;

                }else{
                    this.notifications_available = true;
                    this.last_alert_available = false;
                    this.last_update_available = false;
                    this.last_info_available = false;
                    this.notifications_raw = success.data;
                    this.notifications = success.data;
                    this.last_notification = success.data[0];

                    success.data.forEach(notification => {
                        notification.created_at = moment(notification.created_at).fromNow();
                    })
                }
                
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));

            }).finally(() => {
                this.getNotificationCount();
                this.loading_last_alerts = false
                this.loading_last_events = false
                this.load_sequence = this.load_sequence +1;
            })
        },
        getSites() {
            this.axios.get(SITES + '/resume')
            
            .then((success) => {
                this.raw_sites = [];
                this.sitez = [];
                this.sites_count =0;

                if (this.checkDataAvailable(success.data)){
                    this.raw_sites = success.data;
                    this.sitez = success.data;
                    this.sites_available = true;
                    this.sites_count = success.data.length;
    
                }else{
                    this.sites_available = false;
                }

            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");

            }).finally(() => {
                this.loading_datatable = false;
                this.load_sequence = this.load_sequence +1;
                this.getBuildings();
                this.getMachines();
            })
        },
        getBuildings() {
            this.raw_buildings = [];
            this.buildings = [];
            this.buildings_count=0;
            this.buildings_available = false;
            
            this.raw_sites.forEach(site => {
                if (this.checkDataAvailable(site.buildings)){
                    this.buildings_available = true;
                }
            })

            if (this.buildings_available){
                this.raw_sites.forEach(site => {
                    if (this.checkDataAvailable(site.buildings)) {
                        site.buildings.forEach(building => {
                            this.raw_buildings.push(building)
                        })
                    }
                })

                this.buildings = this.raw_buildings;
                this.buildings_count = this.raw_buildings.length;

                this.raw_buildings.forEach(item => {
                    item.created_at = moment(item.created_at).format("DD/MM/YYYY");
                })
            }
        },
        getMachines() {
            // Initialisation des variables
            this.raw_machines = [];
            this.machines = [];
            this.machines_count = 0;
            this.machines_available = false;

            // Vérification des machines dans les sites
            this.raw_sites.forEach(site => {
                if (this.checkDataAvailable(site.machines)) {
                    this.machines_available = true;
                } else {
                    console.log("Aucune machine disponible pour ce site");
                }
            });

            if (this.machines_available) {

                // Récupération de toutes les machines
                this.raw_sites.forEach(site => {
                    if (this.checkDataAvailable(site.machines)) {
                        site.machines.forEach(machine => {
                            this.raw_machines.push(machine);
                        });
                    }
                });

                // Mise à jour des variables
                this.machines = this.raw_machines;
                this.machines_count = this.raw_machines.length;

                // Calcul des alert levels
                this.data_0 = 0;
                this.data_1 = 0;
                this.data_2 = 0;
                this.data_3 = 0;

                this.machines.forEach(machine => {
                    if (machine.alert_level < 10){
                        this.data_0 = this.data_0 + 1;
                    }
                    if (machine.alert_level > 10 &&  machine.alert_level < 15) {
                        this.data_1 = this.data_1 + 1;
                    }
                    if (machine.alert_level > 20 && machine.alert_level < 25) {
                        this.data_2 = this.data_2 + 1;
                    }
                    if (machine.alert_level > 30 && machine.alert_level < 35) {
                        this.data_3 = this.data_3 + 1;
                    }
                });

                // Mise à jour des données du graphique
                this.chart.series[0].data = [
                    { name: 'Bonne santé', y: this.data_0 },
                    { name: 'A surveiller', y: this.data_1 },
                    { name: 'Important', y: this.data_2 },
                    { name: 'Urgent', y: this.data_3 }
                ];

                this.load_done = true;

                // Récupération des sous-ensembles
                this.getSubassemblies();

            } else {
                console.log("Aucune machine disponible");
            }
        },
        getSubassemblies() {
            this.raw_subassemblies = [];
            this.subassemblies = [];
            this.subassemblies_count=0;
            this.subassemblies_available = false;

            this.raw_machines.forEach(machine => {
                if (this.checkDataAvailable(machine.subassemblies)){
                    this.subassemblies_available = true;
                }
            })

            if (this.subassemblies_available){
                this.raw_machines.forEach(machine => {
                    if (this.checkDataAvailable(machine.subassemblies)){
                        this.subassemblies_count = this.subassemblies_count + machine.subassemblies.length;
                    }
                })

                this.subassemblies = this.raw_subassemblies;
            }
        },
        getReports() {
            this.axios.get(REPORT + '?trash=0')
            
            .then((success) => {
                this.raw_reports = [];
                this.reports = [];
                this.reports_count = 0;

                if (this.checkDataAvailable(success.data)){ 
                    this.raw_reports = success.data;
                    this.reports = success.data;
                    this.reports_count = success.data.length;
                    this.reports_available = true;

                    success.data.forEach(report => {
                        report.released_at_formatted = moment(report.released_at).fromNow();
                    });
                }else{
                    this.reports_available = false;
                }

            }).catch(() => {
                this.showSnackbar('error', "Reports datas error.");
            }).finally(() => {
                this.load_sequence = this.load_sequence +1;
            })
        },
        getCampains() {
            this.axios.get(CAMPAIN + '?trash=0')
            
            .then((success) => {
                this.raw_campains = [];
                this.campains = [];
                this.campains_count = 0;

                if (this.checkDataAvailable(success.data)){
                    this.raw_campains = success.data;
                    this.campains = success.data;
                    this.campains_count = success.data.length;
                    this.campains_available = true;
                
                }else{
                    this.campains_available = false;
                }

            }).catch(() => {
                this.showSnackbar('error', "Campains datas error.");
            }).finally(() => {
                this.load_sequence = this.load_sequence +1;
            })
        },
        getMe() {
            this.axios.get(AUTH_ME)
            
            .then((success) => {
                    this.me = success.data;
            }).catch(() => {
                this.showSnackbar('error', "Untrashed me data error.");
            }).finally(() => {
                this.load_sequence = this.load_sequence +1;
            })
        },
        getProfessional() {
            this.axios.get(PROFESSIONALS + "/active")
            
            .then((success) => {
                    this.professional = success.data;
            }).catch(() => {
                this.showSnackbar('error', "Untrashed me data error.");
            }).finally(() => {
                this.load_sequence = this.load_sequence +1;
            })
        },
        getTeam(){
            this.axios.get(USERS + '/team')
            
            .then((success) => {
                this.team = [];
                this.team = success.data;

            }).catch(() => {
                this.showSnackbar('error', "Team datas error.");
            }).finally(() => {
                this.load_sequence = this.load_sequence +1;
            })
        },
        getContract(){
            this.axios.get(CONTRACTS + '/professional_info')
            
            .then((success) => {
                this.contract = [];
                this.tickets = 0;
                this.contract = success.data;

                this.contract.forEach(value => {
                    this.tickets = value.expert_ticket + this.tickets;
                })

            }).catch(() => {
                this.showSnackbar('error', "Contract datas error.");
            }).finally(() => {
                this.load_sequence = this.load_sequence +1;
            })
        },

        // GET MANAGED DATA
        getMachinesCount(level = "") {
            this.data_filters.forEach(level => {level.count = 0});
            
            // Get all last counts
            this.machines.forEach(machine => {
                this.data_filters.forEach(level => {
                    if(machine.alert_level === level.level){
                        level.count = level.count +1;
                    }
                });
            });
        },
        getNotificationCount() {
                // Reset all counts
                this.notification_levels.forEach(level => {level.count = 0});
                
                if (this.checkDataAvailable(this.raw_notifications)){
                    // Get all last counts
                    this.raw_notifications.forEach(notification => {
                        this.notification_levels.forEach(level => {
                            if(notification.level === level.level){
                                level.count = level.count +1;
                            }
                        });
                    });

                    this.global_notifications_count = this.raw_notifications.length;
                }
        },
        getLastEvent(success) {
            let update_flag = 0;
            let alert_flag =0;
            let message_flag =0;
            let campain_flag =0;
            let report_flag =0;

            
            // filter flags -> LAST EVENTS
            success.data.forEach(notification => {
                all_tags = JSON.parse(notification.tags);
                all_tags.forEach(tag => {
                    if((tag == "update" || tag == "customer") && update_flag !== 9){
                        update_flag = update_flag+1;
                    }

                    if(tag == "alert" && alert_flag !== 9){
                        alert_flag = alert_flag+1;
                    };

                    if(tag == "campain" && campain_flag !== 9){
                        campain_flag = campain_flag+1;
                    };

                    if(tag == "report" && report_flag !== 9){
                        report_flag = report_flag+1;
                    };

                    if((tag == "message" || tag == "customer") && message_flag !== 9){
                        message_flag = message_flag+1;
                    };
                })
                

                if (update_flag == 2){
                    this.last_update = notification;
                    this.last_update_available = true;
                    update_flag = 9;
                }else if(update_flag !== 9){
                    update_flag = 0;
                }

                if (alert_flag == 1){
                    this.last_alert = notification;
                    this.last_alert_available = true;
                    alert_flag = 9;
                }else if(alert_flag !== 9){
                    alert_flag = 0;
                }

                if (campain_flag == 1){
                    this.last_campain = notification;
                    this.last_campain_available = true;
                    campain_flag = 9;
                }else if(campain_flag !== 9){
                    campain_flag = 0;
                }

                if (report_flag == 1){
                    this.last_report = notification;
                    this.last_report_available = true;
                    report_flag = 9;
                }else if(report_flag !== 9){
                    report_flag = 0;
                }

                if (message_flag == 2){
                    this.last_message = notification;
                    this.last_message_available = true;
                    message_flag = 9;
                }else if(message_flag !== 9){
                    message_flag = 0;
                }
            })
        },

        // Etat du parc
        getCountForFilter(index) {

            const machinesInFilter = this.machines.filter(machine => machine.filter_level === index);

            return machinesInFilter.length;
        },

        //MCO Capteurs ?? OLD
        sites() {
            const sites_map = this.$store.state.sites;
            if (sites_map !== null) {
                return sites_map;
            } else {
                return [];
            }
        },
        professionals_resume() {
            if (this.$store.state.professionals_resume === null) {
                return [];
            } else {
                return this.$store.state.professionals_resume;
            }
        },
        setCookieVolet() {
            document.cookie = "volet_seen" + '=' + true;
        },
        getCookie(id) {
            let value = document.cookie.match('(^|;)?' + id + '=([^;]*)(;|$)');
            return value ? unescape(value[2]) : null;
        },
        closeNewUpdateDialog(){
            this.setCookieVolet();
            this.dialog_new_update = false;
        },
        infoToggler(){
            this.infoToggle = !this.infoToggle;
        }
    },
}
</script>