<template>
    <div id="site-detail">
        <v-row>
            <v-col class="ml-6 mr-6 pt-5 pl-1" style="background-color: #001047;border-radius: 0px 0px 5px 5px;">
                <v-subheader class="v-subheader col theme--light">
                    <!------------------- Info bulle ---------------------->
                    <v-tooltip bottom dark class="mr-2 ml-2">
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 mr-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                                <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:default>
                        <div style="min-width: 200px;position:relative;z-index: 10;">
                            <strong>Page [Gestion d'un site]</strong>
                            <p>Toutes les machines que nous sommes susceptibles de surveiller se trouvent ici.</br>
                                Toutes les caractéristiques techniques associées au suivi en ligne sont configurés sur cette page.</br>
                                Ceci est valable par exemple pour les sous-ensembles. </br>
                                Cliquer sur l'un d'entre eux pour le visualiser en détail.
                            </p>
                        </div>
                        </template>
                    </v-tooltip>
                    <p class="title mb-0 color-white" style="color: white!important;">
                        <div style="color:#FFFFFFb3;margin-right:4px;">Configuration > </div><div @click="infoToggler()" style="color:#FFFFFF;font-weight:bold;"> {{ $t('sites.detail') }} : {{ site.label }}</div>
                    </p>
                </v-subheader>
            </v-col>
        </v-row>

        <v-row class="justify-center mt-6 ml-0 mr-0">
            <v-col id="fixed-col" class="pb-0">
                <site-state/>
            </v-col>
            <v-col class="pb-0" :class="is_mobile ? '' : 'pl-0'">
                <autocomplete-map-card :site="site"/>
            </v-col>
        </v-row>

        <datatable-site-machines class="mt-6 ml-3 mr-3"/>
    </div>
</template>

<script>
import {find} from 'lodash';
import DatatableSiteMachines from '../../components/Site/DatatableSiteMachines';
import SiteState from '../../components/Site/SiteState';
import AutocompleteMapCard from '../../components/Site/AutocompleteMapCard';
import DatatableOperations from '../../components/DatatableOperations';
import TitleToolbar from '../../components/TitleToolbar';

export default {
    name: 'DetailSite',
    components: {
        TitleToolbar,
        DatatableSiteMachines,
        SiteState,
        AutocompleteMapCard,
        DatatableOperations,
    },
    async created() {
        await this.$store.dispatch('sites/load');
    },
    computed: {
        sites() {
            return this.$store.state.sites;
        },
        site() {
            return find(this.sites, {id: parseInt(this.$route.params.id, 10)});
        },
    },
}
</script>

<style scoped lang='scss'>
#fixed-col {
    min-width: 638px;
    max-width: 638px !important;
}

@media only screen and (max-width: 480px) {
    #fixed-col {
        min-width: 100%;
        max-width: 100% !important;
    }
}
</style>
