<template>
    <div id="kpi-gateways">
        <p class="font-weight-bold">{{ $t('dashboard_admin.gateways') }}</p>

        <v-row no-gutters>
            <v-col cols="6" v-if="$store.state.access_level !== 200 ">
                <v-card class="ma-1" elevation="0">
                    <v-card-text>
                        <div class="d-flex flex-row">
                            <v-img src="/images/admin/in_stock.png"
                                   contain height="50px" max-width="50px"
                            />

                            <div class="d-block ml-5">
                                <span class="color-grey font-weight-bold">{{ $t('dashboard_admin.in_stock') }}</span>
                                <p class="color-primary font-weight-bold font-20 ma-0">{{ gateway_state[1] }}</p>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="6">
                <v-card class="ma-1" elevation="0">
                    <v-card-text>
                        <div class="d-flex flex-row">
                            <v-img src="/images/admin/in_stock_installer.png"
                                   contain height="50px" max-width="50px"
                            />

                            <div class="d-block ml-5">
                                <span class="color-grey font-weight-bold">
                                    {{ $t('dashboard_admin.in_stock_installer') }}
                                </span>
                                <p class="color-primary font-weight-bold font-20 ma-0">{{ gateway_state[2] }}</p>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="6" v-if="$store.state.access_level !== 175">
                <v-card class="ma-1" elevation="0">
                    <v-card-text>
                        <div class="d-flex flex-row">
                            <v-img src="/images/admin/installed_offline.png"
                                   contain height="50px" max-width="50px"
                            />

                            <div class="d-block ml-5">
                                <span class="color-grey font-weight-bold">
                                    {{ $t('dashboard_admin.installed_offline') }}
                                </span>
                                <p class="color-primary font-weight-bold font-20 ma-0">{{ gateway_state[-1] }}</p>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="6">
                <v-card class="ma-1" elevation="0">
                    <v-card-text>
                        <div class="d-flex flex-row">
                            <v-img src="/images/admin/installed.png"
                                   contain height="50px" max-width="50px"
                            />

                            <div class="d-block ml-5">
                                <span class="color-grey font-weight-bold">{{ $t('dashboard_admin.installed') }}</span>
                                <p class="color-primary font-weight-bold font-20 ma-0">{{ gateway_state[3] }}</p>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>

        </v-row>

        <v-snackbar v-model="snackbar" top :color="snackbar_type">
            {{ snackbar_text }}
                                <template v-slot:action="{ attrs }">
                        <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                    </template>
        </v-snackbar>
    </div>
</template>

<script>
import {GATEWAYS} from "../../../api";

export default {
    name: "kpi-gateways",
    data() {
        return {
            gateway_state: [],
        }
    },
    created() {
        this.gatewayStateCheckCount();
    },
    methods: {
        gatewayStateCheckCount() {
            this.axios.get(
            this.$store.state.access_level === 200 ? GATEWAYS + '/kpi/state_kpi' + '?installer_proprietary=true' : GATEWAYS + '/kpi/state_kpi'
            ).then((success) => {
                this.gateway_state = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            });
        }
    }
}
</script>
