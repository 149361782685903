<template>
    <div id="setting-referential">
        <v-row>
            <v-col>
                <v-subheader>
                    <p class="title mb-0 color-primary text-uppercase">
                        {{ $t('referential.title') }}
                    </p>
                </v-subheader>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <datatable-type-operation/>
            </v-col>

            <v-col>
                <datatable-part-concerned/>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <datatable-tag/>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <datatable-contract-type/>
            </v-col>
            <v-col>
                <datatable-physical-device-type/>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import DatatableTypeOperation from "../../components/Setting/Referential/DatatableTypeOperation";
import DatatablePartConcerned from "../../components/Setting/Referential/DatatablePartConcerned";
import DatatableTag from "../../components/Setting/Referential/DatatableTagReferential";
import DatatableContractType from "../../components/Setting/Referential/DatatableContractType";
import DatatablePhysicalDeviceType from "../../components/Setting/Referential/DatatablePhysicalDeviceType";

export default {
    name: "Referential",
    components: {
        DatatableTypeOperation,
        DatatablePartConcerned,
        DatatableTag,
        DatatableContractType,
        DatatablePhysicalDeviceType
    }
}
</script>
