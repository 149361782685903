import Vue from 'vue';
import VueRouter from 'vue-router';
import {store} from '../store/store';

Vue.use(VueRouter);

let unauthenticatedRoute = [
    {
        path: '/',
        redirect: '/login',
        meta: {auth: false},
    },
    {
        path: '/login',
        name: 'login',
        component: require('../global/pages/LoginView').default,
        meta: {auth: false},
    },
    {
        path: '/reset_password_user',
        component: require('../global/pages/PasswordRecoveryView').default,
        meta: {auth: false},
    },
    {
        path: '/register',
        name: 'register',
        component: require('../global/pages/RegisterView').default,
        meta: {auth: false},
    },
];

let globalRoute = [
    {
        path: '/404',
        redirect: '/dashboard'
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: require('../client/pages/Dashboard').default,
        meta: {
            auth: true,
            access_level: [0, 95, 100, 200, 300, 400]
        },
    },
    {
        path: '/dashboard_loading',
        name: 'dashboard_loading',
        component: require('../client/pages/DashboardLoading').default,
        meta: {
            auth: true,
            access_level: [0, 95, 100, 200, 300, 400]
        },
    },
    {
        path: '/dashboard_admin',
        name: 'dashboard_admin',
        component: require('../admin/pages/Dashboard').default,
        meta: {
            auth: true,
            access_level: [0, 100, 125, 150, 175]
        },
    },
    {
        path: '/user_profile',
        name: 'user_profile',
        component: require('../global/pages/ProfileView').default,
        meta: {
            auth: true,
            access_level: [0, 95, 96, 97, 98, 99, 100, 125, 150, 175, 200, 300, 400]
        },
    },
    {
        path: '/notifications',
        name: 'notifications',
        component: require('../global/pages/Notifications').default,
        meta: {
            auth: true,
            access_level: [0, 95, 96, 97, 98, 99, 100, 125, 150, 175, 200, 300, 400]
        },
    },
    {
        path: '/customers',
        name: 'professionals',
        component: require('../admin/pages/Professionals/Professionals').default,
        meta: {
            auth: true,
            access_level: [0, 95, 96, 97, 98, 99, 125]
        },
    },
    {
        path: '/inprogress/:name',
        name: 'inprogress',
        component: require('../global/pages/Inprogress').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: require('../global/pages/Notfound').default,
        meta: {
            auth: true,
        },
    },
];

let professionalRoute = [
    {
        path: '/professional/:id',
        component: require('../client/pages/Professional/Detail').default,
        meta: {
            auth: true,
            access_level: [0, 95, 96, 97, 98, 99, 100, 200]
        },
    }
];

let subassemblyRoute = [
    {
        path: '/subassembly',
        component: require('../client/pages/Subassembly/Vision').default,
        meta: {
            auth: true,
            access_level: [0, 95, 96, 97, 98, 99, 100, 200, 300, 400]
        },
    },
    {
        path: '/subassembly/:id',
        name: 'subassembly',
        component: require('../client/pages/Subassembly/Detail').default,
        meta: {
            auth: true,
            access_level: [0, 95, 96, 97, 98, 99, 100, 200, 300, 400]
        },
    }
];

let machineRoute = [
    {
        path: '/machines',
        component: require('../client/pages/Machine/Vision').default,
        meta: {
            auth: true,
            access_level: [0, 95, 96, 97, 98, 99, 100, 200, 300, 400]
        },
    },
    {
        path: '/machine/:id',
        name: 'machine',
        component: require('../client/pages/Machine/Detail').default,
        meta: {
            auth: true,
            access_level: [0, 95, 100, 200, 300, 400]
        },
    },
    {
        path: '/machine/:id/report',
        name: 'report',
        component: require('../client/pages/Machine/Report').default,
        meta: {
            auth: true,
            access_level: [0, 95, 96, 97, 98, 99, 100, 200, 300, 400]
        },
    },
    {
        path: '/machine/redit/:id',
        name: 'redit',
        component: require('../client/pages/Machine/Redit').default,
        meta: {
            auth: true,
            access_level: [0, 95, 96, 97, 98, 99]
        },
    },
    {
        path: '/machine/:id/reports',
        name: 'reports',
        component: require('../client/pages/Machine/Reports').default,
        meta: {
            auth: true,
            access_level: [0, 95, 96, 97, 98, 99, 100, 200, 300, 400]
        },
    },
    {
        path: '/machine/:id/details',
        name: 'details',
        component: require('../client/pages/Machine/Details').default,
        meta: {
            auth: true,
            access_level: [0, 95, 96, 97, 98, 99, 100, 200, 300, 400]
        },
    },
    {
        path: '/machine/:id/settings',
        name: 'settings',
        component: require('../client/pages/Machine/Settings').default,
        meta: {
            auth: true,
            access_level: [0, 95, 96, 97, 98, 99, 100, 200, 300, 400]
        },
    },
    {
        path: '/machine/:id/events',
        name: 'events',
        component: require('../client/pages/Machine/Events').default,
        meta: {
            auth: true,
            access_level: [0, 95, 96, 97, 98, 99, 100, 200, 300, 400]
        },
    },
];

let buildingRoute = [
    {
        path: '/building',
        component: require('../client/pages/Building/Vision').default,
        meta: {
            auth: true,
            access_level: [0, 95, 96, 97, 98, 99, 100, 200, 300, 400]
        },
    },
    {
        path: '/building/:id',
        name: 'building',
        component: require('../client/pages/Building/Detail').default,
        meta: {
            auth: true,
            access_level: [0, 95, 96, 97, 98, 99, 100, 200, 300, 400]
        },
    }
]

let siteRoute = [
    {
        path: '/sites',
        name: 'sites',
        component: require('../client/pages/Site/Vision').default,
        meta: {
            auth: true,
            access_level: [0, 95, 96, 97, 98, 99, 100, 200, 300, 400]
        },
    },
    {
        path: '/site/:id',
        name: 'site',
        component: require('../client/pages/Site/Detail').default,
        meta: {
            auth: true,
            access_level: [0, 95, 96, 97, 98, 99, 100, 200, 300, 400]
        },
    },
    {
        path: '/parks',
        name: 'parks',
        component: require('../client/pages/Parks/Vision').default,
        meta: {
            auth: true,
            access_level: [0, 95, 96, 97, 98, 99, 100, 200, 300, 400]
        },
    },
    {
        path: '/parks/:id',
        name: 'park',
        component: require('../client/pages/Parks/Detail').default,
        meta: {
            auth: true,
            access_level: [0, 95, 96, 97, 98, 99, 100, 200, 300, 400]
        },
    },
    {
        path: '/campains',
        name: 'campains',
        component: require('../client/pages/Campains/Vision').default,
        meta: {
            auth: true,
            access_level: [0, 95, 96, 97, 98, 99, 100, 200, 300, 400]
        },
    },
    {
        path: '/campain/:id',
        name: 'campain',
        component: require('../client/pages/Campains/Detail').default,
        meta: {
            auth: true,
            access_level: [0, 95, 96, 97, 98, 99, 100, 200, 300, 400]
        },
    },
];

let deviceVirtualRoute = [
    {
        path: '/virtual_device',
        name: 'devices',
        component: require('../client/pages/VirtualDevice/Vision').default,
        meta: {
            auth: true,
            access_level: [0, 95, 96, 97, 98, 99]
        },
    },
    {
        path: '/virtual_device/:id',
        name: 'device',
        component: require('../client/pages/VirtualDevice/Detail').default,
        meta: {
            auth: true,
            access_level: [0, 95, 96, 97, 98, 99, 100, 200, 300, 400]
        },
    }
];

let userRoute = [
    {
        path: '/users',
        name: 'users',
        component: require('../client/pages/User/Vision').default,
        meta: {
            auth: true,
            access_level: [0, 95, 96, 97, 98, 99, 100, 200, 300]
        },
    }
];

let shopkeeperRoute = [
    {
        path: '/shop/physical_devices/stock_reception',
        name: 'devices_stock_reception',
        component: require('../admin/pages/Shop/Devices/StockReception').default,
        meta: {
            auth: true,
            access_level: [0, 125]
        }
    },
    {
        path: '/shop/physical_devices/stock_exit',
        name: 'devices_stock_exit',
        component: require('../admin/pages/Shop/Devices/StockExit').default,
        meta: {
            auth: true,
            access_level: [0, 125]
        },
    },
    {
        path: '/shop/physical_devices/remote_stock_installer',
        name: 'devices_remote_stock_installer',
        component: require('../admin/pages/Shop/Devices/AddRemoteInstallerStock').default,
        meta: {
            auth: true,
            access_level: [0, 125]
        },
    },
    {
        path: '/shop/physical_devices/list',
        name: 'shop_physical_devices',
        component: require('../admin/pages/Shop/Devices/StoreDevicesDatatable').default,
        meta: {
            auth: true,
            access_level: [0, 125]
        },
    },
    {
        path: '/shop/gateways/stock_reception',
        name: 'gateways_stock_reception',
        component: require('../admin/pages/Shop/Gateways/StockReception').default,
        meta: {
            auth: true,
            access_level: [0, 125]
        },
    },
    {
        path: '/shop/gateways/stock_exit',
        name: 'gateways_stock_exit',
        component: require('../admin/pages/Shop/Gateways/StockExit').default,
        meta: {
            auth: true,
            access_level: [0, 125]
        },
    },
    {
        path: '/shop/gateways/remote_stock_installer',
        name: 'gateways_remote_stock_installer',
        component: require('../admin/pages/Shop/Gateways/AddRemoteInstallerStock').default,
        meta: {
            auth: true,
            access_level: [0, 125]
        },
    },
    {
        path: '/shop/gateways/list',
        name: 'shop_gateways',
        component: require('../admin/pages/Shop/Gateways/StoreGatewaysDatatable').default,
        meta: {
            auth: true,
            access_level: [0, 125]
        },
    },
    {
        path: '/shop/supplies/stock',
        name: 'supply_stock',
        component: require('../admin/pages/Shop/Supplies/StockSupplies').default,
        meta: {
            auth: true,
            access_level: [0, 125]
        },
    },
];

let installerRoute = [
    {
        path: '/installer/physical_devices',
        name: 'installer_physical_devices',
        component: require('../admin/pages/Installer/PhysicalDevicesDatatable').default,
        meta: {
            auth: true,
            access_level: [0, 200]
        }
    },
    {
        path: '/installer/gateways',
        name: 'installer_gateways',
        component: require('../admin/pages/Installer/GatewaysDatatable').default,
        meta: {
            auth: true,
            access_level: [0, 200]
        }
    },
];

let contractRoute = [
    {
        path: '/contracts/list',
        name: 'contracts',
        component: require('../admin/pages/Contract/ContractsDatatable').default,
        meta: {
            auth: true,
            access_level: [0, 175]
        }
    },
    {
        path: '/contracts/avenant_contract',
        name: 'avenant_contract',
        component: require('../admin/pages/Contract/AvenantContract').default,
        meta: {
            auth: true,
            access_level: [0, 175]
        },
    },
    {
        path: '/contracts/documents_import',
        name: 'documents_import',
        component: require('../admin/pages/Contract/DocumentsImport').default,
        meta: {
            auth: true,
            access_level: [0, 175]
        },
    },
];

let settingRoute = [
    {
        path: '/setting/user_management',
        component: require('../admin/pages/Setting/UserManagement').default,
        name: 'user_management',
        meta: {
            auth: true,
            access_level: 0
        },
    },
    {
        path: '/setting/referential',
        component: require('../admin/pages/Setting/Referential').default,
        meta: {
            auth: true,
            access_level: 0
        },
    },
    {
        path: '/setting/role',
        component: require('../admin/pages/Setting/Role').default,
        meta: {
            auth: true,
            access_level: 0
        },
    },
    {
        path: '/setting/subassembly_configuration',
        component: require('../admin/pages/Setting/SubassemblyConfiguration').default,
        meta: {
            auth: true,
            access_level: 0
        },
    },
    {
        path: '/setting/log',
        component: require('../admin/pages/Setting/Log').default,
        meta: {
            auth: true,
            access_level: 0
        },
    },
    {
        path: "/setting/information_banner",
        component: require('../admin/pages/Setting/InformationBanner').default,
        meta: {
            auth: true,
            access_level: 0
        },
    },
];

const defaultRedirection = [
    {
        path: '*',
        redirect: '/login'
    }
];

let routes = globalRoute.concat(
    unauthenticatedRoute, subassemblyRoute, professionalRoute, machineRoute, buildingRoute, siteRoute, deviceVirtualRoute,
    userRoute, installerRoute, settingRoute, shopkeeperRoute, contractRoute, defaultRedirection
);

export const router = new VueRouter({
    mode: 'history',
    routes: routes
});

router.beforeEach((to, from, next) => {
    if (to.meta.access_level) {
        if (to.meta.access_level <= 99) {
            store.state.access_level === to.meta.access_level ? next() : next({name: 'dashboard_admin'});

        } else if (to.meta.access_level > 99) {
            store.state.access_level <= to.meta.access_level ? next() : next({name: 'dashboard'});
        }
    }
    next();
});

Vue.router = router;
