<template>
    <div id="subassembly-configuration">
        <v-row>
            <v-col>
                <v-subheader>
                    <p class="title mb-0 color-primary text-uppercase">
                        {{ $t('subassembly_configurations.title') }}
                    </p>
                </v-subheader>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-card elevation="2" outlined>
                    <v-alert
                        border="left"
                        colored-border type="warning"
                        elevation="2"
                        class="mr-4 ml-4 mt-4" dense block
                    >
                        {{ $t('subassembly_configurations.instruction_json_1') }}<br/><br/>
                        {{ $t('subassembly_configurations.instruction_json_2') }}<br/>
                        {{ $t('subassembly_configurations.instruction_json_3') }}<br/>
                        {{ $t('subassembly_configurations.instruction_json_4') }}
                        <v-btn href="https://materialdesignicons.com/" small target="_blank" color="primary">
                            Material design icons
                        </v-btn>
                        <br/><br/>

                        <v-row>
                            <v-col>
                                <u>{{ $t('global.for_example') }}</u>
                                <pre>{{ $t('subassembly_configurations.json_example_1') }}</pre>
                            </v-col>

                            <v-col>
                                <u>{{ $t('subassembly_configurations.for_selector') }}</u>
                                <pre>{{ $t('subassembly_configurations.json_example_2') }}</pre>
                            </v-col>
                        </v-row>
                    </v-alert>

                    <v-data-table
                        :headers="headers"
                        :items="subassembly_configurations"
                        sort-by="id"
                        class="elevation-1"
                        :loading="loading"
                        :items-per-page="10"
                        :search="search">

                        <template v-slot:top>
                            <v-toolbar flat color="white" class="pt-3">
                                <v-row>
                                    <v-col cols="8">
                                        <v-btn color="primary" class="white--text mr-3"
                                               @click="openDialogAddSubassemblyConfiguration()">
                                            {{ $t('subassembly_configurations.add_subassembly_configuration') }}
                                        </v-btn>
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            v-model="search"
                                            append-icon="mdi-magnify"
                                            :label="$t('global.search')"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-dialog v-model="dialog_edit" max-width="1500px" max-height="600px">
                                    <v-card>
                                        <v-form ref="form">
                                            <validation-observer ref="observer" v-slot="{ invalid }">
                                                <v-card-title><span class="headline">{{ dialogTitle }}</span>
                                                </v-card-title>

                                                <v-snackbar
                                                    v-model="snackbar"
                                                    :color="snackbar_type">
                                                    {{ snackbar_text }}
                                                    <template v-slot:action="{ attrs }">
                                                        <v-icon @click="snackbar = false" color="white">mdi-close
                                                        </v-icon>
                                                    </template>
                                                </v-snackbar>

                                                <v-divider></v-divider>

                                                <v-card-text>
                                                    <v-row>
                                                        <v-col>
                                                            <validation-provider
                                                                :name="$t('global.label')"
                                                                rules="required" v-slot="{ errors }"
                                                            >
                                                                <v-text-field
                                                                    :label="$t('global.label')" solo
                                                                    v-model="edited_subassembly_configuration.label"
                                                                    :prepend-icon="'mdi-label-outline'"
                                                                    :error-messages="errors"
                                                                />
                                                            </validation-provider>

                                                            <validation-provider
                                                                :name="$t('subassembly_configurations.configuration')"
                                                                rules="required" v-slot="{ errors }"
                                                            >
                                                                <v-textarea
                                                                    rows="20" solo
                                                                    @click="invalid_json = true"
                                                                    :label="$t('subassembly_configurations.configuration')"
                                                                    v-model="schema_json"
                                                                    :prepend-icon="'mdi-code-json'"
                                                                    :error-messages="errors"
                                                                />
                                                            </validation-provider>
                                                        </v-col>
                                                        <v-col>
                                                            <v-alert
                                                                v-if="error_message !== ''"
                                                                border="left"
                                                                colored-border type="error"
                                                                elevation="2"
                                                                class="mr-4 ml-4" dense block
                                                            >
                                                                <h2>{{ error_message }}</h2>
                                                            </v-alert>

                                                            <v-row v-else class="pt-4">
                                                                <template v-for="input in schema_verify">
                                                                    <v-col md="6" class="pb-0 pt-0"
                                                                           v-show="!input.hidden"
                                                                    >
                                                                        <template v-if="input.type === 'selector'">
                                                                            <v-select :items="input.choice"
                                                                                      v-model="input.value"
                                                                                      item-value="id" item-text="text"
                                                                                      :label="input.title" solo
                                                                                      :prepend-icon="input.icon ? input.icon : 'mdi-comment-outline'"
                                                                                      :rules="input.required ? [v => !!v || $t('vee-validation.required_field')] : []"
                                                                            />
                                                                        </template>

                                                                        <v-text-field v-else
                                                                                      :type="input.type !== 'string' ? 'number' : 'text'"
                                                                                      :label="input.title" solo
                                                                                      :rules="input.required ? [v => !!v || $t('vee-validation.required_field')] : []"
                                                                                      :prepend-icon="input.icon ? input.icon : 'mdi-comment-outline'"
                                                                        />
                                                                    </v-col>
                                                                </template>
                                                            </v-row>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>

                                                <v-divider></v-divider>

                                                <v-card-actions>
                                                    <v-spacer/>
                                                    <v-btn @click="closeDialog()">
                                                        <v-icon left>mdi-close</v-icon>
                                                        {{ $t('global.close') }}
                                                    </v-btn>
                                                    <v-btn color="success"
                                                           @click="checkSchemaForm()"
                                                           :disabled="invalid">
                                                        <v-icon left>mdi-check-decagram-outline</v-icon>
                                                        {{ $t('subassembly_configurations.validate_json') }}
                                                    </v-btn>
                                                    <v-btn type="submit" color="primary"
                                                           @click.prevent="prepareEditSubassemblyConfiguration()"
                                                           :disabled="invalid_json">
                                                        <v-icon left>mdi-content-save</v-icon>
                                                        {{ $t('global.validation') }}
                                                    </v-btn>
                                                </v-card-actions>
                                            </validation-observer>
                                        </v-form>
                                    </v-card>
                                </v-dialog>

                                <v-dialog v-model="dialog_delete" max-width="500px">
                                    <v-card>
                                        <v-card-title>
                                            <span class="headline">{{
                                                    $t('subassembly_configurations.delete_subassembly_configuration')
                                                }}</span>
                                        </v-card-title>
                                        <v-divider></v-divider>
                                        <br/>

                                        <v-card-actions>
                                            <v-spacer/>
                                            <v-btn @click="dialog_delete = false">
                                                <v-icon left>mdi-close</v-icon>
                                                {{ $t('global.cancel') }}
                                            </v-btn>
                                            <v-btn color="error" @click="confirmDelete()">
                                                <v-icon left>mdi-delete</v-icon>
                                                {{ $t('global.delete') }}
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>

                                <v-snackbar
                                    v-model="snackbar"
                                    :color="snackbar_type">
                                    {{ snackbar_text }}
                                    <template v-slot:action="{ attrs }">
                                        <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                                    </template>
                                </v-snackbar>
                            </v-toolbar>
                        </template>

                        <template v-slot:item.configuration="{ item }">
                            {{ item.configuration.substring(0, 100) + '...' }}
                        </template>

                        <template v-slot:item.action="{ item }">
                            <v-icon class="pointer" @click="openDialogUpdateSubassemblyConfiguration(item)">
                                mdi-pencil
                            </v-icon>
                            <v-icon class="pointer" @click="openDialogDeleteSubassemblyConfiguration(item)">
                                mdi-delete
                            </v-icon>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import {SUBASSEMBLY_CONFIGURATIONS} from "../../../api";

export default {
    name: "SubassemblyConfiguration",
    data() {
        return {
            subassembly_configurations: [],
            headers: [
                {text: this.$t('global.number'), value: 'uid'},
                {text: this.$t('global.label'), value: 'label'},
                {text: this.$t('subassembly_configurations.configuration'), value: 'configuration'},
                {text: this.$t('subassembly_configurations.user_creator'), value: 'user_creator'},
                {text: this.$t('subassembly_configurations.user_editor'), value: 'user_editor'},
                {text: this.$t('global.actions'), value: 'action'}
            ],
            loading: true,
            search: '',
            invalid: false,

            dialog_edit: false,
            edited_index: null,
            edited_subassembly_configuration: {
                id: null,
                uid: '',
                label: '',
                configuration: null
            },
            loading_schema_json: false,
            error_message: '',
            schema_json: null,
            schema_verify: null,
            invalid_json: true,

            dialog_delete: false,
            delete_id: null,
            deleted_index: null
        }
    },
    computed: {
        dialogTitle() {
            return this.edited_index === null ? this.$t('subassembly_configurations.add_subassembly_configuration') : this.$t('subassembly_configurations.edit_subassembly_configuration');
        },

    },
    created() {
        this.getSubassemblyConfiguration();
    },
    methods: {
        getSubassemblyConfiguration() {
            this.axios.get(SUBASSEMBLY_CONFIGURATIONS).then((success) => {
                this.subassembly_configurations = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            }).finally(() => {
                this.loading = false
            });
        },

        openDialogAddSubassemblyConfiguration() {
            if (this.edited_index !== null) {
                this.$refs.form.reset();
                this.$refs.observer.reset();
                this.edited_index = null;
            }

            this.schema_json = null;
            this.schema_verify = null;
            this.invalid_json = true;
            this.dialog_edit = true;
        },

        openDialogUpdateSubassemblyConfiguration(subassembly_configuration) {
            this.edited_index = this.subassembly_configurations.indexOf(subassembly_configuration);
            this.edited_subassembly_configuration = Object.assign({}, subassembly_configuration);
            this.schema_json = this.edited_subassembly_configuration.configuration;

            this.schema_verify = null;
            this.invalid_json = true;

            this.dialog_edit = true;
        },

        prepareEditSubassemblyConfiguration() {
            if (this.edited_index !== null) {
                this.updateSubassemblyConfiguration();
            } else {
                this.addSubassemblyConfiguration();
            }
        },

        checkSchemaForm() {
            this.loading_schema_json = true
            this.error_message = "";

            if (!this.isJson()) {
                this.error_message = this.$t('subassembly_configurations.not_json');
            }
            if (!this.containAllProperty() && this.error_message === "") {
                this.error_message = this.$t('subassembly_configurations.not_all_property');
            }
            if (!this.allPropertySet() && this.error_message === "") {
                this.error_message = this.$t('subassembly_configurations.not_all_property_set');
            }
            if (!this.checkGoodType() && this.error_message === "") {
                this.error_message = this.$t('subassembly_configurations.not_good_type');
            }
            if (!this.allIndexDifferent() && this.error_message === "") {
                this.error_message = this.$t('subassembly_configurations.not_all_index_different');
            }
            if(!this.allValueAreGood() && this.error_message === "") {
                this.error_message = this.$t('subassembly_configurations.value_different');
            }

            this.loading_schema_json = false;
            if (this.error_message === "") {
                this.schema_verify = this.edited_subassembly_configuration.configuration;
                this.invalid_json = false;
            }
        },

        isJson() {
            let isJson = true;
            try {
                this.edited_subassembly_configuration.configuration = JSON.parse(this.schema_json);
            } catch (e) {
                isJson = false;
            }
            return isJson
        },

        containAllProperty() {
            let containAllProperty = true;

            for (let input of this.edited_subassembly_configuration.configuration) {
                if (!input.hasOwnProperty('type') && !input.hasOwnProperty('index') &&
                    !input.hasOwnProperty('title') && !input.hasOwnProperty('required')) {
                    containAllProperty = false;
                }

                if(input.type === "selector") {
                    if (!input.hasOwnProperty('choice')) {
                        containAllProperty = false;
                    } else {
                        containAllProperty = false;
                        for(const element of input.choice) {
                            containAllProperty = true;
                            if (!element.hasOwnProperty('text') && !element.hasOwnProperty('id')) {
                                containAllProperty = false;
                                break;
                            }
                        }
                    }
                }

                if (!containAllProperty) {
                    break;
                }
            }

            return containAllProperty;
        },

        allPropertySet() {
            let allPropertySet = true;

            for (let input of this.edited_subassembly_configuration.configuration) {
                if (input.type === "" || input.index === "" || input.title === "" || input.required === "") {
                    allPropertySet = false;
                }

                if(input.type === "selector") {

                    if (input.choice === "") {
                        allPropertySet = false;
                    } else {
                        allPropertySet = false;
                        for(const element of input.choice) {
                            allPropertySet = true;
                            if (element.text === "" || element.id === "") {
                                allPropertySet = false;
                                break;
                            }
                        }
                    }
                }

                if (!allPropertySet) {
                    break;
                }
            }
            return allPropertySet;
        },

        checkGoodType() {
            let goodType = true;
            for (let input of this.edited_subassembly_configuration.configuration) {
                if (input.type !== "string" && input.type !== "integer" && input.type !== "selector") goodType = false;
                if (!input.required && input.required) goodType = false;
                if (!goodType) break;
            }
            return goodType;
        },

        allIndexDifferent() {
            let allIndexDifferent = true;

            for (let input of this.edited_subassembly_configuration.configuration) {
                let result = this.edited_subassembly_configuration.configuration.filter(x => x.index === input.index);
                if (result.length > 1) {
                    allIndexDifferent = false;
                    break;
                }
            }
            return allIndexDifferent;
        },

        allValueAreGood() {
            let allValueAreGood = true;

            for (let input of this.edited_subassembly_configuration.configuration) {
                if(input.type === "selector" && input.value !== "") {
                    for(const element of input.choice) {
                        if (element.id !== input.value) {
                            allValueAreGood = false;
                        } else {
                            allValueAreGood = true;
                            break;
                        }
                    }
                }
            }

            return allValueAreGood;
        },

        updateSubassemblyConfiguration() {
            this.axios.patch(SUBASSEMBLY_CONFIGURATIONS + '/' + this.edited_subassembly_configuration.id,
                this.edited_subassembly_configuration
            ).then((success) => {
                Object.assign(this.subassembly_configurations[this.edited_index], success.data);
                this.edited_index = null;
                this.showSnackbar('success', this.$t('global.success_to_edit'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'))
            }).finally(() => {
                this.closeDialog();
            });

        },

        addSubassemblyConfiguration() {
            this.axios.post(SUBASSEMBLY_CONFIGURATIONS, this.edited_subassembly_configuration
            ).then((success) => {
                this.subassembly_configurations.push(success.data);
                this.showSnackbar('success', this.$t('global.success_to_add'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_add'));
            }).finally(() => {
                this.closeDialog();
            });
        },

        openDialogDeleteSubassemblyConfiguration(subassembly_configuration) {
            this.deleted_index = this.subassembly_configurations.indexOf(subassembly_configuration);
            this.delete_id = subassembly_configuration.id;
            this.dialog_delete = true;
        },

        confirmDelete() {
            this.axios.delete(
                SUBASSEMBLY_CONFIGURATIONS + '/' + this.delete_id
            ).then(() => {
                this.subassembly_configurations.splice(this.deleted_index, 1);
                this.showSnackbar('success', this.$t('global.success_to_delete'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_delete'));
            }).finally(() => {
                this.dialog_delete = false;
            });
        },

        closeDialog() {
            this.dialog_edit = false;
            this.$refs.form.reset();
            this.$refs.observer.reset();
        },
    }
}
</script>
