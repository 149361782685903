<template>
    <v-card class="rounded-lg" elevation="2" outlined :loading="loading">
        <v-card-title class="d-flex justify-space-between">
            <v-icon>mdi-chart-bell-curve-cumulative</v-icon>
            {{ comparison_curve.label }}
            <div>
                <v-btn elevation="3" small @click="toggleFullscreen()">
                    <v-icon color="black">mdi-fullscreen</v-icon>
                </v-btn>
                <v-btn elevation="3" small color="primary" @click="comparisonCurveCsvExport()" :loading="csv_export_loading">
                    <v-icon color="white" small>mdi-download</v-icon>
                </v-btn>

                <v-btn elevation="3" color="red darken-3" small @click="dialog_delete_comparison_curve = true">
                    <v-icon color="white" small>mdi-trash-can-outline</v-icon>
                </v-btn>
            </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
            <v-row>
                <v-col col="6">
                    <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="dateRangeText"
                                label="Période sélectionée"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="range"
                            range
                            @change="refresh"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col col="6">
                    <!--                    <v-switch
                                            :label="$t('favorite_curve.display_flags')"
                                            v-model="display_flags"
                                            color="primary" @change="hideFlags()"
                                        ></v-switch>-->
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text>
            <div id="chart-time">
                <highcharts :constructorType="'stockChart'" :updateArgs="[true, false]" class="hc"
                            :options="chartOptions" v-if="chartLoaded" ref="highcharts"></highcharts>
            </div>
        </v-card-text>

        <v-dialog v-model="dialog_delete_comparison_curve" max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="headline">{{ $t('modal_comparison_curves.delete_comparison_curves') }}</span>
                </v-card-title>
                <v-divider/>

                <v-card-text class="text-center pb-0">
                    <p class="mb-5 mt-3">{{ $t('modal_comparison_curves.delete_message') }} <strong>{{ comparison_curve.label }} ?</strong></p>
                </v-card-text>

                <v-card-actions class="mb-1">
                    <v-spacer/>
                    <v-btn @click="dialog_delete_comparison_curve = false">
                        <v-icon left>mdi-close</v-icon>
                        {{ $t('global.close') }}
                    </v-btn>
                    <v-btn type="submit" color="error"
                           @click="deleteComparisonCurves()">
                        <v-icon left>mdi-content-save</v-icon>
                        {{ $t('global.delete') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <global-snackbar/>
    </v-card>
</template>

<script>
import {each} from 'lodash';
import moment from 'moment';
import {COMPARISON_CURVES, DOWNLOAD_CSV_COMPARISON_CURVE} from "../../../../api";
import {api as fullscreen} from 'vue-fullscreen';
import GlobalSnackbar from "../../../../global/components/GlobalSnackbar";

export default {
    name: "ComparisonCurve",
    components: {GlobalSnackbar},
    props: ['comparisonCurve'],
    data() {
        return {
            date_operation_click: null,
            comparison_curve: this.comparisonCurve,
            chart_data: [],
            range: [
                moment().clone().startOf('day').subtract(5, "days").format('YYYY-MM-DD'),
                moment().clone().endOf('day').format('YYYY-MM-DD')
            ],
            chartLoaded: false,
            chartOptions: {
                time: {
                    timezone: 'Europe/Paris',
                },
                navigator: {
                },
                lang: {
                    noData: 'No data available'
                },
                scrollbar: {
                    enabled: true,
                    liveRedraw: false,
                    height: 17,
                    barBackgroundColor: '#cfcfcf',
                    barBorderRadius: 9,
                    buttonBackgroundColor: '#c9c9c9',
                    buttonArrowColor: 'white',
                    buttonBorderWidth: 3,
                    buttonBorderRadius: 11,
                    rifleColor: 'white',
                    trackBorderRadius: 9
                },
                updateArgs: [true, true, true],
                xAxis: [
                    {
                        events: {
                            setExtremes: (e) => {
                                this.zoomInOut(e);
                            }
                        },
                    },

                ],
                plotOptions: {
                    line: {
                        connectNulls: true,
                    },
                    series: {
                        showInNavigator: false,
                    }
                },
                rangeSelector: {
                    enabled: false,
                },

                yAxis: [],
                series: [],
                credits: {
                    enabled: false
                },
            },

            chart: {
                preAlert: {
                    yMin: 0,
                    yMax: 0
                },
                alert: {
                    yMin: 0,
                    yMax: 0
                },
                selected: {
                    label: null,
                    date: null,
                    value: null
                },
            },
            loading: false,
            menu: false,
            menu2: false,
            dialog_delete_comparison_curve: false,
            dialogs: {
                addOperation: false,
                dateRange: false
            },
            navigator_series: [],
            number_of_point: 200,
            csv_export_loading:false,
            metric_details: [],
        }
    },
    created() {
        window.moment = moment;
    },
    computed: {
        dateRangeText() {
            const from = moment(this.range[0]).format('DD-MM-YYYY');
            const to = moment(this.range[1]).format('DD-MM-YYYY');
            return `Du ${from} au ${to}`;
        },
    },
    mounted() {
        this.loadChart();
    },
    methods: {
        hideFlags() {
            if (this.display_flags) {
                this.chartOptions.series = [this.chartSeries.series, this.chartSeries.flags];
            } else {
                this.chartOptions.series = [this.chartSeries.series];
            }
        },

        async loadChart() {
            this.loading = true;
            let from = moment(this.range[0]).startOf('day').toISOString()
            let to = moment(this.range[1]).endOf('day').toISOString();

            if (moment(from).isAfter(to)) {
                [from, to] = [to, from];
            }

            let intervalDate = moment(from).valueOf() - moment(to).valueOf();
            if (intervalDate < 0) {
                intervalDate *= -1
            }

            let interval = this.calculInterval(intervalDate,this.number_of_point);
            let uris=[];
            this.comparison_curve.comparison_traces.forEach((trace)=> {
                uris.push(`/api/${this.comparison_curve.type_relatable}/${this.comparison_curve.id_relatable}/metric/${trace.metric_uuid}/timestamp?start=${from}&end=${to}&interval=${interval}`);
            });

            this.chart_data=[];
            for (const uri of uris) {
                const index = uris.indexOf(uri);
                await this.getMetricData(uri, this, this.comparison_curve.comparison_traces[index].metric_unit);
            }
            this.redrawChartCheck(true);
        },

        async getMetricData(uri, that, unit) {
            await axios.get(uri).then((success) => {
                const data = success.data.data_metric;
                const obj = {
                    name: this.comparison_curve.label,
                    data: data.data,
                    unit: unit
                };
                that.chart_data.push(obj);
            }).catch((error) => {
                console.log(error)
            });
        },

        async buildSeries(upDateNavigatorData) {
            if(upDateNavigatorData){
                this.chartOptions.series = [];
                this.chartOptions.navigator.series = [];
                this.chartOptions.yAxis = [];
                this.navigator_series=[];
            }
            else {
                this.chartOptions.series = [];
                this.chartOptions.yAxis = [];
            }

            each(this.chart_data,  (data, index) => {
                const metrics = data.data;
                const unit = data.unit;
                let series = [];
                if(unit === 'Text') {
                    // Construire les intervales pour les courbe Text
                    let RawData = metrics.map((sample)=> {
                        return {
                            date: moment(moment.parseZone(moment.utc(sample.date,'YYYY-MM-DDTHH:mm:ss')).local().format('YYYY-MM-DDTHH:mm:ss')),
                            value: sample.value === "" ? "Null" : sample.value
                        }
                    });
                    let data= [];
                    let color= 'red';
                    let i=0;
                    let j=0;
                    while(i < RawData.length) {
                        let x2='';
                        while (j < RawData.length) {
                            if ( RawData[j].value !== RawData[i].value ){
                                x2 = RawData[j].date;
                                data.push({
                                    start: RawData[i].date.valueOf(),
                                    end: x2.valueOf(),
                                    y:0,
                                    name: RawData[i].value.toString(),
                                    color: color
                                });
                                i=j;
                                color === 'red' ? color= 'blue' : color= 'red';
                            }
                            j++;
                            if(j === RawData.length && i < j) {
                                x2 = RawData[j-1].date;
                                data.push({
                                    start: RawData[i].date.valueOf(),
                                    end: x2.valueOf(),
                                    y:0,
                                    name: RawData[i].value.toString(),
                                    color: color
                                });
                                i= RawData.length;
                            }
                        }
                    }
                    series = data;
                }
                else {
                    series= [];
                    metrics.forEach(metric => {
                        let utcDate = new Date(metric.date).getTime();
                        series.push([utcDate, Math.round(parseFloat(metric.value) * 1e2) / 1e2]);
                        if (metric.operation) {
                            let utcOperationDate = (new Date(metric.operation.date)).getTime();
                        }
                    });
                }
                let axis, dataseries;
                if (index === 0) {
                    axis = {
                        title: {
                            text : ''
                        },
                        visible:unit !== 'Text',
                        height: Math.round(100/this.comparison_curve.comparison_traces.length)+'%'
                    };
                    if(unit === 'Text') {
                        dataseries = {
                            name: unit,
                            type:'gantt',
                            tooltip: {
                                pointFormat: '<span>Valeur: {point.name}</span><br/><span>De: {point.start:%A, %b %e,%yT%H:%M}</span><br/><span>A: {point.end:%A, %b %e,%yT%H:%M}</span>'
                            },
                            yAxis: index,
                            data: series, // a changer apres
                        }
                    }
                    else {
                        dataseries = {
                            name: unit,
                            yAxis: index,
                            marker: {
                                enabled: true,
                                symbol: 'circle',
                                radius: 3
                            },
                            data: series,
                        }
                    }
                } else {
                    axis = {
                        title: {
                            text : ''
                        },
                        visible:unit !== 'Text',
                        top: Math.round(100/this.comparison_curve.comparison_traces.length)*index+'%',
                        height: Math.round(100/this.comparison_curve.comparison_traces.length)+'%',
                        offset: 0,
                    };
                    if(unit === 'Text') {
                        dataseries = {
                            name: unit,
                            type:'gantt',
                            yAxis: index,
                            tooltip: {
                                pointFormat: '<span>Valeur: {point.name}</span><br/><span>De: {point.start:%A, %b %e,%yT%H:%M}</span><br/><span>A: {point.end:%A, %b %e,%yT%H:%M}</span>'
                            },
                            data: series,
                        }
                    }
                    else {
                        dataseries = {
                            name: unit,
                            marker: {
                                enabled: true,
                                symbol: 'circle',
                                radius: 3
                            },
                            data: series,
                            yAxis: index,
                        };
                    }

                }
                let dataSeriesNavigator;
                if(unit === 'Text') {
                    dataSeriesNavigator= {
                        type: 'gantt',
                        data: series,
                        pointPlacement: 0.5,
                        pointPadding: 0.25,

                    }
                }
                else {
                    dataSeriesNavigator= {
                        data: series
                    }
                }
                if(upDateNavigatorData) {
                    this.navigator_series.push(dataSeriesNavigator);
                }
                this.chartOptions.navigator.series = this.navigator_series;
                this.chartOptions.yAxis.push(axis);
                this.chartOptions.series.push(dataseries);

            });
            this.loading = false;
            this.chartLoaded = true;
        },

        refresh() {
            this.chart_data = [];
            this.loadChart();
        },

        toggleFullscreen() {
            let wrapper = this.$el.querySelector('#chart-time');
            wrapper.classList.add('enabled');

            fullscreen.toggle(wrapper, {
                teleport: this.teleport,
                callback: (isFullscreen) => {
                    this.fullscreen = isFullscreen;
                    if (isFullscreen === false) {
                        this.$el.querySelector('#chart-time').classList.remove('enabled');
                    }
                },
            });
        },

        deleteComparisonCurves() {
            this.axios.delete(
                COMPARISON_CURVES + '/' + this.comparison_curve.id
            ).then(() => {
                this.showGlobalSnackbar('success',this.$t('global.success_to_delete'));
                this.$emit('deleteComparisonCurves', this.comparison_curve.id);
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('global.failure_to_delete'));
            }).finally(() => {
                this.dialog_delete_comparison_curve = false;
            });
        },

        comparisonCurveCsvExport() {
            this.csv_export_loading=true;
            let globalData=[];
            let metrics_unit=[];
            this.chart_data.forEach(data => {
                globalData.push(data);
                metrics_unit.push(data.unit);
            });
            this.axios({
                    url: DOWNLOAD_CSV_COMPARISON_CURVE,
                    method: 'POST',
                    data: {
                        'data': globalData,
                        'metrics_unit' : metrics_unit
                    },
                    responseType: 'blob',
                }).then((success) => {
                    this.downloadCsvFile(success.data, "_ComparisonCurve :"+this.comparison_curve.label+"_data.csv");
                }).catch((error) => {
                    this.manageError(error.response.status, this.$t('global.failure_to_download'));
                }).finally(()=>{
                    this.csv_export_loading= false;
            });
        },

        async zoomInOut(event) {
            this.loading = true;
            this.chart_data=[];
            // The min and max extreme in milliseconds
            const min = event.min;
            const max = event.max;

            // The interval in milliseconds
            const scrollbarInterval = max - min;

            let interval = this.calculInterval(scrollbarInterval,this.number_of_point);

            let from = moment(min).toISOString();
            let to = moment(max).toISOString();

            let uris=[];
            this.comparison_curve.comparison_traces.forEach((trace)=> {
                uris.push(`/api/${this.comparison_curve.type_relatable}/${this.comparison_curve.id_relatable}/metric/${trace.metric_uuid}/timestamp?start=${from}&end=${to}&interval=${interval}`);
            });
            /*const uri1 = "machine/1/metric/76ef26dc-9f4f-11ec-9d12-ae2116f036ed/timestamp?start=2022-05-14T20:00:00&end=2022-05-15T04:00:00";
              const uri2 = "machine/1/metric/76f7dde0-9f4f-11ec-af15-363429fcd3e5/timestamp?start=2022-05-14T20:00:00&end=2022-05-15T04:00:00"*/
            for (const uri of uris) {
                const index = uris.indexOf(uri);
                await this.getMetricData(uri, this, this.comparison_curve.comparison_traces[index].metric_unit);
            }
            this.redrawChartCheck(false);
        },

         redrawChartCheck(bool){
            if(this.chart_data.length === 0) {
                this.chartLoaded=true;
                this.$nextTick(()=> {
                    this.$refs.highcharts.chart.series.forEach((x,key)=> {
                        this.$refs.highcharts.chart.series[key].setData([]);
                    });
                    this.loading= false;
                });
            }
            else {
                this.buildSeries(bool);
            }
        }
    }
}
</script>

<style scoped lang="scss">
.fullscreen-wrapper {
    background: white;
}

.fullscreen-wrapper.enabled {
    z-index: 10;
    padding: 50px;
}

.highcharts-flags-series > .highcharts-label > text {
    display: none !important;
}
</style>
