<template>
    <div id="gateway-location">
        <v-dialog v-model="gateway_location_dialog" width="900">
            <v-card>
                <v-card-title>
                    {{ $t('gateways_datatable.gateway_location') }}
                </v-card-title>

                <v-divider></v-divider>

                <v-card-text class="pt-5">
                    <v-text-field
                        readonly filled
                        v-model="current_location"
                        :label="$t('gateways_datatable.location')">
                    </v-text-field>

                    <gmap-map :center="center" :zoom="6" class="gmap-map" ref="gmap">
                        <google-map-cluster>
                            <gmap-marker
                                :icon="{ url: '/images/cluster/marker.png' }"
                                :position="{ lat: center.lat , lng: center.lng }"
                                :clickable="false" :draggable="false"
                            >
                            </gmap-marker>
                        </google-map-cluster>
                    </gmap-map>

                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import GoogleMapCluster from 'vue2-google-maps/dist/components/cluster';

export default {
    name: "GatewayLocation",
    components: {
        GoogleMapCluster,
    },
    data() {
        return {
            gateway_location_dialog: false,
            current_location: '',
            center: {lat: 0, lng: 0},
        }
    },
    methods: {
        gatewayLocationDialogOpen(address, latitude, longitude) {
            this.center = {
                lat: parseFloat(latitude),
                lng: parseFloat(longitude)
            };
            this.current_location = address;
            this.gateway_location_dialog = true;
        },
    }
}
</script>

<style scoped>
.gmap-map {
    width: 100%;
    height: 650px;
}
</style>
