<template>
    <div id="subassembly-detail">
        <v-row>
            <v-col class="ml-6 mr-6 pt-5 pl-1" style="background-color: #001047;border-radius: 0px 0px 5px 5px;">
                <v-subheader class="v-subheader col theme--light">
                    <!------------------- Info bulle ---------------------->
                    <v-tooltip bottom dark class="mr-2 ml-2">
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 mr-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                                <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:default>
                        <div style="min-width: 200px;position:relative;z-index: 10;">
                            <strong>Page [Gestion d'un sous-ensemble]</strong>
                            <p>Toutes les machines que nous sommes susceptibles de surveiller se trouvent ici.</br>
                                Toutes les caractéristiques techniques associées au suivi en ligne sont configurés sur cette page.</br>
                                Ceci est valable par exemple pour les sous-ensembles. </br>
                                Cliquer sur l'un d'entre eux pour le visualiser en détail.
                            </p>
                        </div>
                        </template>
                    </v-tooltip>
                    <p class="title mb-0 color-white" style="color: white!important;">
                        <div style="color:#FFFFFFb3;margin-right:4px;">Configuration > </div><div @click="infoToggler()" style="color:#FFFFFF;font-weight:bold;"> {{ $t('subassemblies.detail') }} : {{ label }}</div>
                    </p>
                </v-subheader>
            </v-col>
        </v-row>

        <!-- DOCUMENTS SECTION -->
        <v-row class="mt-6 ml-0 mr-0">
            <v-col id="fixed-col">
                <subassembly-state ref="subassembly_state"/>
            </v-col>
            <v-col>
                <subassembly-specifications v-on:updateSubassemblyLabel="updateSubassemblyLabel"/>
            </v-col>
            <v-col>
                <documents-upload v-bind:parentType="type" v-bind:parentId="id" v-bind:min-height="550"/>
            </v-col>
        </v-row>

        <!-- OPERATIONS -> TO ADD TO DETAILS PAGE
        <datatable-subassembly-operations ref="dt_operations" class="mt-6 ml-3 mr-3" v-on:updateSubassemblyDevices="updateSubassemblyDevices"/>
        -->

        <!-- CURVES -> TO ADD TO DETAILS PAGE -->
        <v-row v-if="access_level <= 100" class="mt-6 ml-0 mr-0">
            <template v-for="(favorite_curve) in favorite_curves" >
                
                <v-col cols="6" v-if="favorite_curve.chart_type === 1" :key="favorite_curve.label">
                    <time-curve v-bind:favoriteCurve="favorite_curve"
                                v-on:deleteFavoriteCurve="deleteFavoriteCurve"
                                v-on:updateOperationDatatable="updateOperationDatatable"
                    ></time-curve>
                </v-col>
                
                <v-col cols="6" v-else-if="$store.state.access_level <= 100" :key="favorite_curve">
                    <spectrum-curve v-bind:favoriteCurve="favorite_curve"
                                    v-on:deleteFavoriteCurve="deleteFavoriteCurve"
                    ></spectrum-curve>
                </v-col>
            </template>
        </v-row>

        <!-- WORD CURVES -> TO ADD TO DETAILS PAGE -->
        <v-row v-if="access_level <= 100" class="mt-6 ml-0 mr-0">
            <template v-for="word_curve in word_curves">
                <v-col cols="6" :key="word_curve">
                    <word-curves v-bind:word-curve="word_curve" v-on:deleteWordCurve="deleteWordCurve" class="mt-6"/>
                </v-col>
            </template>
        </v-row>
    </div>
</template>

<script>
import DatatableSubassemblyOperations from "../../components/Subassembly/Operation/DatatableSubassemblyOperations";
import DocumentsUpload from "../../components/DocumentsUpload";
import SubassemblyState from "../../components/Subassembly/SubassemblyState";
import FavoriteCurve from "../../components/Subassembly/Curve/FavoriteCurve";
import SubassemblySpecifications from "../../components/Subassembly/Specifications/SubassemblySpecifications";
import TimeCurve from '../../components/Subassembly/Curve/TimeCurve';
import SpectrumCurve from '../../components/Subassembly/Curve/SpectrumCurve';
import {FAVORITE_CURVES, WORD_CURVE} from "../../../api";
import WordCurves from "../../components/Subassembly/WordCurves/WordCurves";

export default {
    name: "SubassemblyDetail",
    components: {
        WordCurves,
        DocumentsUpload,
        DatatableSubassemblyOperations,
        SubassemblyState,
        FavoriteCurve,
        SubassemblySpecifications,
        SpectrumCurve,
        TimeCurve,
    },
    data() {
        return {
            id: this.$route.params.id,
            type: "subassembly",
            access_level: this.$store.state.access_level,
            favorite_curves: [],
            word_curves: [],
            label: ""
        };
    },
    created() {
        this.getSubassemblyFavoriteCurves();
        this.getSubassemblyWordCurves();
    },
    methods: {
        getSubassemblyFavoriteCurves() {
            this.axios.get(
                FAVORITE_CURVES + "?id_entity=" + this.id + "&type=subassembly"
            ).then((success) => {
                this.favorite_curves = success.data;
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('global.error'));
            });
        },

        getSubassemblyWordCurves() {
            this.axios.get(
                WORD_CURVE + "?id_entity=" + this.id + "&type=subassembly"
            ).then((success) => {
                this.word_curves = success.data;
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('global.error'));
            });
        },

        deleteFavoriteCurve(item) {
            this.favorite_curves.splice(this.favorite_curves.findIndex(
                favorite_curve => favorite_curve.id === item
            ), 1);
        },

        deleteWordCurve(item) {
            this.word_curves.splice(this.word_curves.findIndex(word_curve => word_curve.id === item), 1);
            this.showGlobalSnackbar('success', this.$t('global.success_to_delete'));
        },

        updateSubassemblyLabel(item) {
            this.label = item.label;
        },

        updateOperationDatatable() {
            this.$refs.dt_operations.getOperations();
        },

        updateSubassemblyDevices() {
            this.$refs.subassembly_state.getSubassembly();
        },
    }
}
</script>
<style scoped lang="scss">
#fixed-col {
    min-width: 638px;
    max-width: 638px !important;
}

@media only screen and (max-width: 480px) {
    #fixed-col {
        min-width: 100%;
        max-width: 100% !important;
    }
}
</style>
