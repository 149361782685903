var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4",attrs:{"id":"avenant-contract"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('p',{staticClass:"title mb-0 color-primary text-uppercase"},[_vm._v(_vm._s(_vm.$t('contract_avenant.title')))])])],1),_vm._v(" "),_c('v-card',{staticClass:"mt-4",attrs:{"width":"100%","elevation":"0"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{ref:"form"},[_c('v-card-text',[_c('validation-provider',{attrs:{"name":_vm.$t('contract_avenant.client'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"mt-5 ml-3 width-layout",attrs:{"items":_vm.professionals,"label":_vm.$t('contract_avenant.client'),"item-text":"name","item-value":"id","error-messages":errors,"hide-no-data":"","clearable":"","return-object":"","filled":""},model:{value:(_vm.selected_professional),callback:function ($$v) {_vm.selected_professional=$$v},expression:"selected_professional"}})]}}],null,true)}),_vm._v(" "),_c('validation-provider',{attrs:{"name":_vm.$t('contract_avenant.gx_client'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mt-5 ml-3 width-layout",attrs:{"items":_vm.gx_clients,"loading":_vm.loading_gx_clients,"menu-props":{ bottom: true, offsetY: true },"item-value":"REST_ID_CUST","item-text":"Nom_Client","label":_vm.$t('contract_avenant.gx_client'),"error":_vm.gx_contracts.length === 0 && _vm.gx_client_name_prefix !== null && _vm.selected_gx_client === null && !_vm.loading_gx_clients,"error-messages":errors,"filled":"","return-object":""},on:{"change":function($event){return _vm.getGxClientContracts()}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',{staticClass:"ma-0 pa-0"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","placeholder":_vm.$t('global.search')},on:{"input":function($event){return _vm.getGxClient()}},model:{value:(_vm.gx_client_name_prefix),callback:function ($$v) {_vm.gx_client_name_prefix=$$v},expression:"gx_client_name_prefix"}})],1)],1)]},proxy:true},{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',{staticClass:"ma-0 pa-0"},[_vm._v("\n                                        "+_vm._s(_vm.$t('global.no_results'))+"\n                                    ")])],1)]},proxy:true}],null,true),model:{value:(_vm.selected_gx_client),callback:function ($$v) {_vm.selected_gx_client=$$v},expression:"selected_gx_client"}})]}}],null,true)}),_vm._v(" "),_c('validation-provider',{attrs:{"name":_vm.$t('contract_avenant.gx_contract'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mt-3 ml-3 width-layout",attrs:{"loading":_vm.loading_gx_contracts,"hint":_vm.$t('contract_avenant.gx_contract_number_hint'),"items":_vm.gx_contracts,"label":_vm.$t('contract_avenant.gx_contract'),"error-messages":errors,"disabled":!_vm.selected_gx_client,"filled":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("\n                                            "+_vm._s(_vm.$t('global.no_results'))+"\n                                        ")])],1)],1)]},proxy:true}],null,true),model:{value:(_vm.selected_gx_contract),callback:function ($$v) {_vm.selected_gx_contract=$$v},expression:"selected_gx_contract"}})]}}],null,true)}),_vm._v(" "),_c('validation-provider',{attrs:{"name":_vm.$t('contract_avenant.label'),"rules":"required|max:90"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-3 ml-3 width-layout",attrs:{"label":_vm.$t('contract_avenant.label'),"messages":_vm.$t('contract_avenant.label_message'),"error-messages":errors,"filled":""},model:{value:(_vm.label),callback:function ($$v) {_vm.label=$$v},expression:"label"}})]}}],null,true)}),_vm._v(" "),_c('p',{staticClass:"mt-5 color-primary font-weight-bold"},[_vm._v(_vm._s(_vm.$t("new_contract.contract_type")))]),_vm._v(" "),_vm._l((_vm.contract_referential),function(referential_contract_type){return [_c('div',{key:referential_contract_type.id,staticClass:"d-flex flex-row flex-wrap"},[_c('span',{staticClass:"mt-7 ml-6 label-width"},[_vm._v(_vm._s(referential_contract_type.label)+" :")]),_vm._v(" "),_c('span',{staticClass:"mt-7 ml-6"},[_vm._v(_vm._s(_vm.$t('contract_avenant.device_number')))]),_vm._v(" "),_c('validation-provider',{attrs:{"name":_vm.$t('contract_avenant.device_number'),"rules":"numeric|max_value:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-3 ml-3 device-number-field",attrs:{"type":"number","filled":"","clearable":"","error-messages":errors},model:{value:(_vm.chosen_contract_referential[referential_contract_type.id]),callback:function ($$v) {_vm.$set(_vm.chosen_contract_referential, referential_contract_type.id, $$v)},expression:"chosen_contract_referential[referential_contract_type.id]"}})]}}],null,true)})],1),_vm._v(" "),_c('v-divider')]}),_vm._v(" "),_c('v-row',{staticClass:"ml-2 mt-2 mb-5 font-10"},[_c('v-icon',{attrs:{"left":"","color":"primary","size":"15"}},[_vm._v("mdi-alert-circle")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('contract_avenant.contract_type_number_hint')))])],1),_vm._v(" "),_c('validation-provider',{attrs:{"name":_vm.$t('new_contract.billing_period'),"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"mt-7",attrs:{"items":_vm.invoicing_periods,"label":_vm.$t('new_contract.billing_period'),"error-messages":errors,"filled":""},model:{value:(_vm.invoicing_period),callback:function ($$v) {_vm.invoicing_period=$$v},expression:"invoicing_period"}})]}}],null,true)}),_vm._v(" "),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date_renew,"transition":"scale-transition","min-width":"auto","offset-y":""},on:{"update:returnValue":function($event){_vm.date_renew=$event},"update:return-value":function($event){_vm.date_renew=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":_vm.$t('new_contract.next_withdrawal'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('new_contract.next_withdrawal'),"prepend-inner-icon":"mdi-calendar","readonly":"","filled":"","error-messages":errors},model:{value:(_vm.date_renew),callback:function ($$v) {_vm.date_renew=$$v},expression:"date_renew"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_vm._v(" "),_c('v-date-picker',{attrs:{"no-title":"","allowed-dates":_vm.disablePastDates,"scrollable":""},model:{value:(_vm.date_renew),callback:function ($$v) {_vm.date_renew=$$v},expression:"date_renew"}},[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v("\n                                "+_vm._s(_vm.$t('global.cancel'))+"\n                            ")]),_vm._v(" "),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.date_renew)}}},[_vm._v("\n                                "+_vm._s(_vm.$t('global.validation'))+"\n                            ")])],1)],1)],2),_vm._v(" "),_c('v-card-actions',{staticClass:"pr-4 pb-4 justify-end"},[_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":function($event){return _vm.validate()}}},[_vm._v("\n                        "+_vm._s(_vm.$t('global.validation'))+"\n                    ")])],1)],1)]}}])})],1),_vm._v(" "),_c('global-snackbar')],1)}
var staticRenderFns = []

export { render, staticRenderFns }