var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"password-edit"}},[_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.dialog_edit_password),callback:function ($$v) {_vm.dialog_edit_password=$$v},expression:"dialog_edit_password"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('profile.reset_password')))]),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{ref:"form"},[_c('v-card-text',{staticClass:"pb-0"},[_c('validation-provider',{attrs:{"name":_vm.$t('profile.actual_password'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"append-icon":_vm.show_actual_password ? 'mdi-eye' : 'mdi-eye-off',"label":_vm.$t('profile.actual_password'),"type":_vm.show_actual_password ? 'text' : 'password',"error-messages":errors,"filled":""},on:{"click:append":function($event){_vm.show_actual_password = !_vm.show_actual_password}},model:{value:(_vm.actual_password),callback:function ($$v) {_vm.actual_password=$$v},expression:"actual_password"}})]}}],null,true)}),_vm._v(" "),_c('validation-provider',{attrs:{"name":_vm.$t('profile.new_password'),"rules":"required|min:7","vid":"new_pass"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"append-icon":_vm.show_new_password ? 'mdi-eye' : 'mdi-eye-off',"label":_vm.$t('profile.new_password'),"type":_vm.show_new_password ? 'text' : 'password',"error-messages":errors,"filled":""},on:{"click:append":function($event){_vm.show_new_password = !_vm.show_new_password}},model:{value:(_vm.new_password),callback:function ($$v) {_vm.new_password=$$v},expression:"new_password"}})]}}],null,true)}),_vm._v(" "),_c('validation-provider',{attrs:{"name":_vm.$t('profile.confirm_new_password'),"rules":"required|min:7|confirmed:new_pass"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"append-icon":_vm.show_new_password_confirmation ? 'mdi-eye' : 'mdi-eye-off',"label":_vm.$t('profile.confirm_new_password'),"type":_vm.show_new_password_confirmation ? 'text' : 'password',"error-messages":errors,"filled":""},on:{"click:append":function($event){_vm.show_new_password_confirmation = !_vm.show_new_password_confirmation}},model:{value:(_vm.new_password_confirmation),callback:function ($$v) {_vm.new_password_confirmation=$$v},expression:"new_password_confirmation"}})]}}],null,true)})],1),_vm._v(" "),_c('v-card-actions',{staticClass:"pr-4 justify-end"},[_c('v-btn',{attrs:{"plain":""},on:{"click":function($event){_vm.dialog_edit_password = false}}},[_vm._v("\n                            "+_vm._s(_vm.$t('global.cancel'))+"\n                        ")]),_vm._v(" "),_c('v-btn',{attrs:{"loading":_vm.loading_update_password,"disabled":invalid,"color":"primary"},on:{"click":function($event){return _vm.updateUserPassword()}}},[_vm._v("\n                            "+_vm._s(_vm.$t('global.validation'))+"\n                        ")])],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }