<template>
    <v-card class="col w-100 h-100" elevation="2" outlined :loading="load_sites_map">
        <v-card-title class="justify-center">
            <v-icon class="mr-2">mdi-map-marker-multiple-outline </v-icon>
            {{ $t('global.dashboard_map_title') }}
        </v-card-title>

        <v-divider></v-divider>

        <gmap-map :center="center" :zoom="7" class="gmap-map" ref="gmap">
            <google-map-cluster :styles="clusterStyles" :zoomOnClick="true">
                <gmap-marker v-for="(site, index) in sites"
                             :icon="{ url: '/images/cluster/marker.png' }"
                             :position="{ lat:site.latitude , lng: site.longitude }"
                             :clickable="true" :draggable="false"
                             @click="markerGoTo(index)"
                             :key="index"
                             @mouseover="showByIndex = index"
                             @mouseout="showByIndex = null">

                    <gmap-info-window :opened="showByIndex === index">
                        <h3>{{ site.label }}</h3>
                        <v-divider/>
                        <h4 class="pt-2">{{ $t('global.site_state_machine') }}</h4>

                        <div class="pt-2 machine-count">
                            <span class="mt-auto mb-auto">{{ site.machines_count }}</span>
                            <v-icon size="20" color="black" class="pl-1">mdi-state-machine</v-icon>
                        </div>

                        <div class="pt-1 progress-bar-container">
                            <hr :style="{ width: calculWidth(site.machines_count, site.alert_level_resume.alert_level_0) + '%'}"
                                class="progress-bar"
                                :color="'green'"
                                v-if="site.alert_level_resume.alert_level_0 !== 0"
                            >
                            <hr :style="{ width: calculWidth(site.machines_count, site.alert_level_resume.alert_level_1) + '%'}"
                                class="progress-bar"
                                :color="'orange'"
                                v-if="site.alert_level_resume.alert_level_1 !== 0"
                            >
                            <hr :style="{ width: calculWidth(site.machines_count, site.alert_level_resume.alert_level_2) + '%'}"
                                class="progress-bar"
                                :color="'red'"
                                v-if="site.alert_level_resume.alert_level_2 !== 0"
                            >
                        </div>

                        <div class="pt-1 pb-1 progress-bar-number">
                            <span v-if="site.alert_level_resume.alert_level_0 !== 0" class="green--text pr-2 pl-2">
                                <strong>
                                    {{ site.alert_level_resume.alert_level_0 }}
                                </strong>
                            </span>

                            <span v-if="site.alert_level_resume.alert_level_1 !== 0" class="orange--text pr-2 pl-2">
                                <strong>
                                    {{ site.alert_level_resume.alert_level_1 }}
                                </strong>
                            </span>

                            <span v-if="site.alert_level_resume.alert_level_2 !== 0" class="red--text pr-2 pl-2">
                                <strong>
                                    {{ site.alert_level_resume.alert_level_2 }}
                                </strong>
                            </span>
                        </div>


                        <v-divider/>
                        <h4 class="pt-2">{{ $t('global.site_state_sensor') }}</h4>

                        <table class="ma-auto pt-2">
                            <thead>
                            <tr>
                                <th class="pa-1">
                                    <v-icon size="20" color="black">mdi-wifi-off</v-icon>
                                </th>
                                <th class="pa-1">
                                    <v-icon size="20" color="black">mdi-battery-off-outline</v-icon>
                                </th>
                                <th class="pa-1">
                                    <v-icon size="20" color="warning">mdi-alert</v-icon>
                                </th>
                                <th class="pa-1">
                                    <v-icon size="20" color="error">mdi-alert</v-icon>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-for="resume in professionals_resume">
                                <tr class="table-row" v-if="resume.label === site.label">
                                    <td>{{ resume.total_alert_keep_alive }}</td>
                                    <td>{{ resume.total_alert_battery }}</td>
                                    <td>{{ resume.total_pre_alert }}</td>
                                    <td>{{ resume.total_alert }}</td>
                                </tr>
                            </template>
                            </tbody>
                        </table>
                    </gmap-info-window>
                </gmap-marker>
            </google-map-cluster>
        </gmap-map>


    </v-card>
</template>

<script>

import GoogleMapCluster from 'vue2-google-maps/dist/components/cluster'
import {PROFESSIONALS} from "../../../api";

export default {
    name: "MapEquipment",
    components: {
        GoogleMapCluster,
    },
    props: {
        sites: {
            type: Array,
            required: true,
            default: () => [],
        },
        professionals_resume: {
            type: Array,
            required: true,
            default: () => [],
        }
    },
    watch: {
        sites() {
            this.getProfessionalSites();
        }
    },
    data() {
        return {
            showByIndex: null,
            center: {lat: 45.12505363614733, lng: 2.817760749593558},
            active_professional:null,
            clusterStyles: [
                {
                    url: "/images/cluster/m3.png",
                    height: 66,
                    width: 65,
                    textSize: 20
                },
            ],
            load_sites_map: false,
        }
    },
    methods: {
        markerGoTo(index) {
            const site = this.sites[index];
            this.$router.push({name: "site", params: {id: site.id}});
        },

        getProfessionalSites() {
            if(this.$store.state.sites.length > 0) {
                this.$refs.gmap.$mapPromise.then((map) => {
                    let bounds = new google.maps.LatLngBounds();
                    let marker_flag = false;

                    this.$store.state.sites.forEach((marker) => {
                        if(marker_flag === false){
                            if (marker.latitude != null &&  marker.longitude != null) {
                                bounds.extend(new google.maps.LatLng(marker.latitude, marker.longitude));
                            }else{
                                bounds.extend(new google.maps.LatLng(44.9241828, -0.6093114));
                                marker_flag = true;   
                            }
                        }
                    });
                    map.fitBounds(bounds);
                    if (this.$store.state.sites.length === 1) {
                        map.setZoom(13);
                    }
                    this.load_sites_map= false;
                })
            }
            else {
                this.load_sites_map= false;
            }
        },

        show(index) {
            const site = this.sites[index];
        },

        calculWidth(machine_count, alert_count) {
            return (alert_count / machine_count) * 100
        }
    },
}
</script>

<style scoped lang="scss">

.custom-clustericon {
    background: var(--cluster-color);
    color: #fff;
    border-radius: 100%;
    font-weight: bold;
    font-size: 15px;
    display: flex;
    align-items: center;
}

.custom-clustericon::before,
.custom-clustericon::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;

    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    background: var(--cluster-color);
    opacity: 0.2;
    border-radius: 100%;
}

.custom-clustericon::before {
    padding: 7px;
}

.custom-clustericon::after {
    padding: 14px;
}

.custom-clustericon-1 {
    --cluster-color: #00a2d3;
}

.custom-clustericon-2 {
    --cluster-color: #ff9b00;
}

.custom-clustericon-3 {
    --cluster-color: #ff6969;
}

.gmap-map {
    width: 100%;
    height: 500px;
}

h3 {
    width: 250px;
}

h4 {
    width: 250px;
}

.machine-count {
    display: inline-flex;
    width: 100%;
    justify-content: center;
}

.progress-bar-container {
    display: inline-flex;
    width: 100%;
}

.progress-bar-number {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.progress-bar {
    height: 15px;
    border: 0;
}

.table-row {
    text-align: center;
}
</style>
