<template>
    <div id="building-state" style="height: 100%">
        <v-card class="rounded-lg" elevation="2" outlined min-height="500px" height="100%">
            <v-card-title class="d-flex justify-space-between">
                <v-icon>mdi-floor-plan</v-icon>
                {{ $t('building_state.title') }}
                <modal-assets-edit
                    ref="assetsEdit"
                    @snackbarAssetsEdit="snackbarAssetsEdit"
                    :assets-data="building_data"
                    :file-upload-endpoint="uploadAssetImageURI"
                    :title="$t('building_state.edit')"
                    model="machines"
                    @saveChange="saveState()"
                />
                <v-btn elevation="3" @click="$refs.assetsEdit.openAssetsEdit(false)" color="amber darken-3" small>
                    <v-icon color="white" small>mdi-wrench</v-icon>
                </v-btn>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text>
                <layer-assets :assets-data="building_data" :asset-type="'machine'"/>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col v-for="(machine ,index) in building_data.assets" :key="index">
                            <v-row class="justify-center">
                                <v-chip :color="assetColor(machine)" text-color="white" justify="center"
                                        class="w-100 ma-2"
                                        @click="$router.push({ name: 'machine', params: {id: machine.id} })">
                                    {{ machine.label }}
                                </v-chip>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-snackbar
                v-model="snackbar"
                :color="snackbar_type">
                {{ snackbar_text }}
                <template v-slot:action="{ attrs }">
                    <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                </template>
            </v-snackbar>
        </v-card>
    </div>
</template>

<script>
import {BUILDINGS} from "../../../api";
import ModalAssetsEdit from "../ModalAssetsEdit";
import LayerAssets from "../LayerAssets";

export default {
    name: "BuildingState",
    components: {
        ModalAssetsEdit,
        LayerAssets,
    },
    data() {
        return {
            building_data: {
                path_image: '',
                assets: []
            },
            building_id: this.$route.params.id,
        };
    },
    created() {
        this.getMachine();
    },
    computed: {
        uploadAssetImageURI() {
            return `${BUILDINGS}/${this.building_id}/image`;
        },
    },
    methods: {
        getMachine() {
            this.axios.get(
                BUILDINGS + "/" + this.building_id + "/machines/details"
            ).then((success) => {
                this.building_data = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            });
        },

        checkActivation(activationDate) {
            let now = new Date();
            let activation = new Date(activationDate);
            let expiredDate = activation.setFullYear(activation.getFullYear() + 1);
            return now > expiredDate;
        },

        checkLastSeen(lastSeenDate) {
            let now = new Date();
            let lastSeen = new Date(lastSeenDate);
            lastSeen.setDate(lastSeen.getDate() + 1);

            return now > lastSeen;
        },

        snackbarAssetsEdit(item) {
            this.showSnackbar(item.type, item.text);
            this.getMachine();
        },

        saveState() {
            this.axios.put(
                BUILDINGS + '/' + this.building_id + '/machines/position', this.building_data
            ).then(() => {
                this.showSnackbar('success', this.$t('global.success_to_edit'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            });
        },
    }
}
</script>
