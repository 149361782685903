require('./bootstrap');

import Vue from 'vue';
import vuetify from './plugins/vuetify';
import {router} from './plugins/router';
import auth from './plugins/auth';
import i18n from './plugins/i18n';
import Global from './plugins/global.js';
import {store} from './store/store';
import VueAuth from '@websanova/vue-auth';
import Permissions from './plugins/permission.js';
import DateTimePicker from 'vuetify-datetime-picker';
import VueFullscreen from 'vue-fullscreen';
import './plugins/axios';

import Highcharts from "highcharts";
import Stock from "highcharts/modules/stock";
import HighchartsVue from 'highcharts-vue';
import noData from 'highcharts/modules/no-data-to-display';

import MomentTimezone from "moment-timezone";
import './plugins/validate';
import 'es6-promise/auto';

import SelectClient from "./client/components/SelectClient";
import AddNewClient from "./client/components/AddNewClient";
import BannerCommunication from "./global/components/BannerCommunication";
import GlobalSnackbar from "./global/components/GlobalSnackbar";

Vue.use(VueAuth, auth);
Vue.use(DateTimePicker);
Vue.use(VueFullscreen);

Stock(Highcharts);
noData(Highcharts);
Vue.use(HighchartsVue);

Highcharts.setOptions({
    lang: {
        months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
        weekdays: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
        shortMonths: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc'],
    },
});

Vue.mixin(Permissions);
Vue.mixin(Global);

Vue.$store = store;
Vue.$router = router;

import * as VueGoogleMaps from 'vue2-google-maps';
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyAEIWCCqmMzzsUAwZKhL8_GWJzo_2LKxN4',
        libraries: 'places',
    },
    installComponents: true,
});
Vue.use(VuetifyGoogleAutocomplete, {
    vueGoogleMapsCompatibility: true,
});

new Vue({
    vuetify,
    router,
    store,
    i18n,
    components: {SelectClient, AddNewClient, BannerCommunication, GlobalSnackbar},
    data: () => ({
        drawer: null,
        mini: false,
        menu: false,
    }),
    mounted() {
        $('.global-loader').hide();
    },
}).$mount('#app');
