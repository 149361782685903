<template>
    <div id="word-curve-appearance-modal">
        <v-dialog v-model="word_curve_appearance_open_dialog" width="420">
            <v-card>
                <v-form>
                    <v-card-title class="headline">
                        {{$t('three_dimensional_curve.appearance')}}
                    </v-card-title>
                </v-form>
                <v-divider class="mb-3"/>
                <v-card-text>
                    <span>{{$t('word_curve.color_1')}}</span>
                    <v-sheet
                        class="sheet-cursor mb-2"
                        ref="color1"
                        :color="configuration.color1"
                        elevation="4"
                        rounded
                        height="40"
                        @click="displayColorSwitchModal('color1');"
                    />
                    <span>{{$t('word_curve.color_2')}}</span>
                    <v-sheet
                        class="sheet-cursor mb-2"
                        ref="color2"
                        :color="configuration.color2"
                        elevation="4"
                        rounded
                        height="40"
                        @click="displayColorSwitchModal('color2');"
                    />
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn @click="word_curve_appearance_open_dialog = false">
                        <v-icon left>mdi-close</v-icon>
                        {{ $t('global.close') }}
                    </v-btn>
                    <v-btn color="primary" @click="updateWordCurveConfiguration()"
                    >
                        <v-icon left>mdi-content-save</v-icon>
                        {{ $t('global.validation') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <three-dimensional-curve-color-switch-modal ref="colorSelectModal" :parent_color_sheet="color_input" @ColorChange="updateColor"/>
        <global-snackbar/>
    </div>
</template>

<script>


import ThreeDimensionalCurveColorSwitchModal
    from "../../Machine/ThreeDimensionalCurves/ThreeDimensionalCurveColorSwitchModal";
import {WORD_CURVE} from "../../../../api";
import GlobalSnackbar from "../../../../global/components/GlobalSnackbar";
export default {
    name: "WordCurveAppearanceModal",
    components: {GlobalSnackbar, ThreeDimensionalCurveColorSwitchModal},
    data() {
        return {
            word_curve_appearance_open_dialog:false,
            color_input:'',
            color1:'',
            color2:'',
            configuration:{},
            word_curve_id:0
        }
    },
    methods:{
        updateColor(chosenColor){
            chosenColor.item_source === "color1" ? this.configuration.color1= chosenColor.color : this.configuration.color2=chosenColor.color;
        },
        displayColorSwitchModal(source){
            this.color_input=source;
            this.$refs.colorSelectModal.three_dimensional_curve_color_switch_open_dialog=true
        },
        updateWordCurveConfiguration(){
            this.axios.patch(
                WORD_CURVE + '/' + this.word_curve_id, {
                    configuration: this.configuration
                }
            ).then((success)=> {
                this.$emit('curveConfigurationUpdated', success.data);
                this.showGlobalSnackbar('success',this.$t('global.success_to_edit'));
                this.word_curve_appearance_open_dialog=false;
            }).catch(()=>{
                this.showGlobalSnackbar('error',this.$t('global.failure_to_update'));
            });

        }
    }
}
</script>

<style scoped>
span{
    font-weight: normal;
    color: #212121;
}
.sheet-cursor{
    cursor: crosshair;
}
</style>

