<template>
    <div id="modal-operation">
        <v-dialog v-model="dialog_add" max-width="500px">
            <v-card>
                <validation-observer ref="observerAdd" v-slot="{ invalid }">
                    <v-form ref="formAdd">

                        <v-card-title>
                            <span class="headline">{{ $t('operation.add_operation') }}</span>
                        </v-card-title>
                        <v-divider></v-divider>

                        <v-card-text>
                            <validation-provider :name="$t('global.name')" rules="required"
                                                 v-slot="{ errors }">
                                <v-text-field
                                    :label="$t('global.name')" solo
                                    v-model="added_operation.label"
                                    :error-messages="errors"
                                    :prepend-icon="'mdi-label-outline'"
                                />
                            </validation-provider>

                            <validation-provider :name="$t('operation.datetime_operation')" rules="required"
                                                 v-slot="{ errors }">
                                <v-datetime-picker v-model="date_operation"
                                                   :label="$t('operation.datetime_operation')"
                                                   :time-picker-props="timeProps"
                                                   :text-field-props="textFieldProps"
                                                   date-format="dd/MM/yyyy"
                                                   :error-messages="errors">
                                    <template slot="dateIcon">
                                        <v-icon color="primary">mdi-calendar</v-icon>
                                    </template>
                                    <template slot="timeIcon">
                                        <v-icon color="primary">mdi-clock</v-icon>
                                    </template>
                                    <template slot="actions" slot-scope="{ parent }">
                                        <v-btn text color="green" @click="parent.okHandler">
                                            <v-icon left>mdi-content-save</v-icon>
                                            {{ $t('global.validation') }}
                                        </v-btn>
                                    </template>
                                </v-datetime-picker>
                            </validation-provider>

                            <validation-provider :name="$t('operation.type_operation')" rules="required"
                                                 v-slot="{ errors }">
                                <v-autocomplete
                                    :items="type_operations"
                                    v-model="added_operation.id_ref_type_operation"
                                    :label="$t('operation.type_operation')"
                                    :prepend-icon="'mdi-pipe-wrench'"
                                    :error-messages="errors"
                                    item-text="value"
                                    item-value="id"
                                    solo
                                ></v-autocomplete>
                            </validation-provider>

                            <validation-provider :name="$t('operation.operation_intervener')" rules="required"
                                                 v-slot="{ errors }">
                                <v-select
                                    :items="interveners"
                                    v-model="added_operation.user_responsible_id"
                                    :label="$t('operation.operation_intervener')"
                                    :prepend-icon="'mdi-account-hard-hat'"
                                    :error-messages="errors"
                                    item-value="id"
                                    solo
                                >
                                    <template slot="selection" slot-scope="data">
                                        {{ data.item.last_name }} {{ data.item.first_name }} ({{ data.item.email }})
                                    </template>
                                    <template slot="item" slot-scope="data">
                                        {{ data.item.last_name }} {{ data.item.first_name }} ({{ data.item.email }})
                                    </template>
                                </v-select>
                            </validation-provider>

                            <validation-provider :name="$t('operation.part_concerned')" rules="required"
                                                 v-slot="{ errors }">
                                <v-autocomplete
                                    :items="parts_concerned"
                                    v-model="added_operation.id_ref_part_concerned"
                                    :label="$t('operation.part_concerned')"
                                    :prepend-icon="'mdi-share-variant'"
                                    :error-messages="errors"
                                    item-text="value"
                                    item-value="id"
                                    solo
                                ></v-autocomplete>
                            </validation-provider>

                            <validation-provider :name="$t('operation.sub_concerned')" rules="required"
                                                 v-slot="{ errors }">
                                <v-autocomplete
                                    :items="subassemblies_concerned"
                                    v-model="added_operation.subassembly_id"
                                    :label="$t('operation.part_concerned')"
                                    :prepend-icon="'mdi-share-variant'"
                                    :error-messages="errors"
                                    item-text="label"
                                    item-value="id"
                                    solo
                                ></v-autocomplete>
                            </validation-provider>

                            <v-textarea
                                rows="3" solo
                                v-model="added_operation.comment"
                                :label="$t('global.comment')"
                                :prepend-icon="'mdi-comment-outline'"
                            />
                        </v-card-text>

                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer/>
                            <v-btn @click="closeDialogAddOperation()">
                                <v-icon left>mdi-close</v-icon>
                                {{ $t('global.close') }}
                            </v-btn>
                            <v-btn type="submit" color="primary"
                                   @click.prevent="addOperation()"
                                   :disabled="invalid">
                                <v-icon left>mdi-content-save</v-icon>
                                {{ $t('global.validation') }}
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </validation-observer>

                <v-snackbar
                    v-model="snackbar"
                    :color="snackbar_type">
                    {{ snackbar_text }}
                    <template v-slot:action="{ attrs }">
                        <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                    </template>
                </v-snackbar>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_update" max-width="500px">
            <v-card>
                <validation-observer ref="observerUpdate">
                    <v-form ref="formUpdate">
                        <v-card-title>
                            <span class="headline">{{ $t('operation.update_operation') }}</span>
                        </v-card-title>

                        <v-divider/>

                        <v-card-text>
                            <validation-provider :name="$t('global.label')" rules="required"
                                                 v-slot="{ errors }">
                                <v-text-field
                                    :label="$t('global.label')" solo
                                    v-model="updated_operation.label"
                                    :error-messages="errors"
                                    :prepend-icon="'mdi-label-outline'"
                                />
                            </validation-provider>

                            <v-datetime-picker v-model="date_operation"
                                               :label="$t('operation.datetime_operation')"
                                               :time-picker-props="timeProps"
                                               :text-field-props="textFieldProps"
                                               date-format="dd/MM/yyyy"
                            >
                                <template slot="dateIcon">
                                    <v-icon color="primary">mdi-calendar</v-icon>
                                </template>
                                <template slot="timeIcon">
                                    <v-icon color="primary">mdi-clock</v-icon>
                                </template>
                                <template slot="actions" slot-scope="{ parent }">
                                    <v-btn text color="green" @click="parent.okHandler">
                                        <v-icon left>mdi-content-save</v-icon>
                                        {{ $t('global.validation') }}
                                    </v-btn>
                                </template>
                            </v-datetime-picker>

                            <v-select
                                :items="interveners"
                                v-model="updated_operation.user_responsible_id"
                                :label="$t('operation.operation_intervener')"
                                :prepend-icon="'mdi-account-hard-hat'"
                                item-value="id"
                                solo
                            >
                                <template slot="selection" slot-scope="data">
                                    {{ data.item.last_name }} {{ data.item.first_name }} ({{ data.item.email }})
                                </template>
                                <template slot="item" slot-scope="data">
                                    {{ data.item.last_name }} {{ data.item.first_name }} ({{ data.item.email }})
                                </template>
                            </v-select>

                            <v-select
                                v-if="this.$store.state.access_level <= 100"
                                :items="referential_tags"
                                v-model="updated_operation.tags"
                                :label="$t('operation.operation_tags')"
                                :prepend-icon="'mdi-tag-multiple-outline'"
                                item-text="label"
                                item-value="id"
                                solo chips multiple
                            ></v-select>
                        </v-card-text>

                        <v-divider/>

                        <v-card-actions>
                            <v-spacer/>
                            <v-btn @click="closeDialogUpdateOperation()">
                                <v-icon left>mdi-close</v-icon>
                                {{ $t('global.close') }}
                            </v-btn>
                            <v-btn type="submit" color="primary" @click.prevent="updateOperation()">
                                <v-icon left>mdi-content-save</v-icon>
                                {{ $t('global.validation') }}
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </validation-observer>

                <v-snackbar
                    v-model="snackbar"
                    :color="snackbar_type">
                    {{ snackbar_text }}
                    <template v-slot:action="{ attrs }">
                        <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                    </template>
                </v-snackbar>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {OPERATIONS, REFERENTIAL_GLOBALS, REFERENTIAL_TAGS, USERS, MACHINES} from "../../../../api";
import moment from "moment";

export default {
    name: "ModalEditOperation",
    props: ['id_machine_active'],
    data() {
        return {
            type_operations: [],
            parts_concerned: [],
            interveners: [],
            referential_tags: [],

            timeProps: {
                format: "24hr"
            },
            textFieldProps: {
                prependIcon: "mdi-calendar",
                solo: true,
                rules: [v => !!v || this.$t('vee-validation.required_date_operation')]
            },

            invalid: false,
            dialog_add: false,
            added_operation: {
                label: '',
                date_operation: '',
                comment: '',
                id_ref_type_operation: null,
                id_ref_part_concerned: null,
                subassembly_id: null,
                user_responsible_id: null
            },

            dialog_update: false,
            date_operation: '',
            updated_operation: {
                id: null,
                label: '',
                date_operation: '',
                user_responsible_id: null
            },
        }
    },
    created() {
        this.initialize();
    },
    methods: {
        initialize() {
            this.getMachine();
            this.getTypeOperations();
            this.getPartsConcerned();
            this.getReferentialTags();
            this.getInterveners();
        },

        getMachine() {
            this.axios.get(MACHINES + '/specific/' + this.$route.params.id,)
            
            .then((success) => {
                this.machine = [];
                this.machine = success.data[0];
                this.subassemblies_concerned = success.data[0].subassemblies;
                let globale_subs = {'label': 'Machine complète', 'id': '0'};
                this.subassemblies_concerned.push(globale_subs);

            }).catch(() => {
                this.showSnackbar('error', "Machines data error.");
            })
        },

        getTypeOperations() {
            this.axios.get(
                REFERENTIAL_GLOBALS + "?type=type_operation"
            ).then((success) => {
                this.type_operations = success.data;
            }).catch((error) => {
                this.manageError(error, this.$t('global.error'))
            });
        },

        getPartsConcerned() {
            this.axios.get(
                REFERENTIAL_GLOBALS + "?type=part_concerned"
            ).then((success) => {
                this.parts_concerned = success.data;
            }).catch((error) => {
                this.manageError(error, this.$t('global.error'))
            });
        },

        getReferentialTags() {
            this.axios.get(
                REFERENTIAL_TAGS
            ).then((success) => {
                this.referential_tags = success.data;
            }).catch((error) => {
                this.manageError(error, this.$t('global.error'))
            });
        },

        getInterveners() {
            this.axios.get(
                USERS + "?role_id=3"
            ).then((success) => {
                this.interveners = success.data;
            }).catch((error) => {
                this.manageError(error, this.$t('global.error'))
            });
        },

        openDialogAddOperation() {
            this.date_operation = '';
            this.dialog_add = true;
        },

        addOperation() {
            this.added_operation.date_operation = moment(this.date_operation, 'DD/MM/YYYY k:mm')
                .format('YYYY-MM-DD HH:mm:ss');

            this.axios.post(OPERATIONS, this.added_operation
            ).then(() => {
                this.$emit('showSnackbarForModalEditOperation', {
                    type: 'success',
                    text: this.$t('global.success_to_add')
                });
            }).catch((error) => {
                this.manageError(error, this.$t('global.failure_to_add'));
            }).finally(() => {
                this.closeDialogAddOperation();
            });
        },

        closeDialogAddOperation() {
            this.dialog_add = false;
            this.$refs.formAdd.reset();
            this.$refs.observerAdd.reset();
        },

        openDialogUpdateOperation(operation) {
            this.dialog_update = true;
            this.updated_operation.id = operation.id;
            this.updated_operation.label = operation.label;
            this.date_operation = operation.date_operation;
            this.updated_operation.tags = operation.tags;
            this.updated_operation.tags.forEach((tag, index, array) => {
                array[index] = tag.id;
            });
            this.updated_operation.user_responsible_id = operation.user_responsible_id;
        },

        updateOperation() {
            this.updated_operation.date_operation = moment(this.date_operation, 'DD/MM/YYYY k:mm')
                .format('YYYY-MM-DD HH:mm:ss');

            this.axios.patch(OPERATIONS + '/' + this.updated_operation.id,
                this.updated_operation
            ).then(() => {
                this.dialog_update = false;
                this.$emit('showSnackbarForModalEditOperation', {
                    type: 'success',
                    text: this.$t('global.success_to_edit')
                });
            }).catch((error) => {
                this.manageError(error, this.$t('global.failure_to_update'))
            });
        },

        closeDialogUpdateOperation() {
            this.dialog_update = false;
            this.$refs.formUpdate.reset();
            this.$refs.observerUpdate.reset();
        }
    }
}
</script>
