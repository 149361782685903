<template>
    <div>
        <!--CHIPS ALERTS-->
        <div v-if="MVAlert_enc > 0" class="ma-0 pa-0 mb-3" style="max-height:90px;border-radius:5px;">
            <div class="col-0" style="max-height:90px;color:white;font-weight:800;display:flex;justify-content:center;text-shadow:2px 2px 4px rgba(0, 0, 0, 0.29); height:100%;min-width: 8px;border-radius:5px;" >
                <v-row class="pa-0 ma-0 mb-3">

                    <v-col class="col pa-0" style=" max-width:30%;
                                                    display:flex;
                                                    flex-direction:column;
                                                    border-radius: 5px 0px 0px 5px;"
                                                    :style="{backgroundColor:assetColor(MVAlert)}"
                    >
                        <v-col style="  align-items:center;
                                        width:100%;
                                        position:relative;
                                        top:0px;
                                        padding-left: 35px;
                                        display:flex;
                                        flex-direction:row;
                                        "
                        >
                            <!-- Alerts ENC -->
                            <v-col class="col-auto pa-0" style="max-width:50%;align-items:center;justify-content:center;display:flex;flex-direction:column;" >
                                <div style="color:white;font-size:28px;font-weight:bold">{{MVAlert_enc}}</div>
                                <div style="color:white;font-size:12px;font-weight: 400;">Alertes publiées</div>
                            </v-col>
                            <!-- ICON -->
                            <v-col class="col-auto ml-12" style="  font-weight:bold;
                                                        text-align:right;"
                            >
                                <v-icon color="white" large v-if="(MVAlert.alert_level !== 0 && MVAlert.alert_level !== 3 && MVAlert.alert_level !== 9)">
                                    mdi-alert
                                </v-icon>

                                <v-icon color="white" large v-if="(MVAlert.alert_level === 3)">
                                    mdi-help-rhombus
                                </v-icon>

                                <v-icon color="white" large v-if="(MVAlert.alert_level === 9)">
                                    mdi-check-circle
                                </v-icon>                        
                            </v-col>
                            <!-- Text -->
                            <v-col class="col" style="font-size:13px;text-align:left;font-weight: 400;align-items:center;">
                                <div  v-if="MVAlert.alert_level !== null">Etat de santé actuel</div>
                                <div  v-if="MVAlert.updated_at !== null">Publié {{ MVAlert.updated_at }}</div> 
                            </v-col>
                        </v-col>
                    </v-col>
                    <v-col class="col pa-0" style=" max-width:70%;
                                                    background-color:white;
                                                    max-height:90px;
                                                    overflow:hidden;
                                                    align-items: center;
                                                    display:flex;
                                                    flex-direction:row;
                                                    text-shadow:none;
                                                    border-radius: 0px 5px 5px 0px;
                                                    justify-content:space-between;
                                                    "
                    >
                        <!-- Last alert CONTENT -->
                        <v-row style="max-width:100%" >
                            <v-col class="col pa-3" style="max-width:100%;background-color:white;height:90px;display:flex;flex-direction:column;justify-content: center;text-shadow:none;">
                                <div v-if="MVAlert.label !== null" style="font-size:18px;color:black;padding-left: 20px;"> {{ MVAlert.label }}</div>
                                <div v-if="MVAlert.date_operation !== null" style="font-size:14px;color:black;padding-left: 20px;font-weight:400;display:flex;">Créée le {{MVAlert.date_operation}}</div>
                            </v-col>
                            <v-col class="col-1 pa-3" style="border-radius: 0px 5px 5px 0px;max-width:70%;background-color:white;height:90px;display:flex;flex-direction:column;justify-content: center;text-shadow:none;align-items:center;">
                                <v-row style="align-items: center;">                            
                                    <v-tooltip bottom dark class="mr-2">
                                        <template v-slot:activator="{ on }">
                                            <v-btn text icon v-on="on" class="mr-2" dusk="logout-button">
                                                <v-icon color="#b1b1b1">mdi-eye</v-icon>
                                            </v-btn>
                                        </template>
                                        <span style="opacity:100%;">
                                            Voir l'alerte dans le journal d'alertes.
                                        </span>
                                    </v-tooltip>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </div>
        </div>

        <!-- ONGLETS -->
        <div v-if="$store.state.access_level <= 99">
            <v-row class="pt-0" style="margin:0px;height:40px;">
                <v-col class="row ml-0 mt-0 pb-0 pt-0 pl-0" style="position: relative;left:-1px;">
                    <div v-for="vue in vues" :key="vue" class="mr-1">
                        <div 
                            v-if="vue.id == active_special_vue" 
                            class="ma-auto pl-5 pr-5 pa-2"
                            style="color: black; font-weight: 400; display: flex; justify-content: left; text-shadow: rgba(0, 0, 0, 0.29) 2px 2px 4px; background-color: rgb(255 255 255); border-radius: 5px 5px 0px 0px; border-left: 1px solid rgba(211, 211, 211, 0.43); border-bottom: none; font-size: 14px; height: 40px; align-items: center; font-weight:bold;"
                        >
                            <div v-if="active_special_vue== 6" style="margin-right: 8px;">
                                <span v-if="count_operations_no_tags > 0" style="font-weight: 800;color: white;background-color: #939393;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ count_operations_no_tags }}</span>
                                <span v-if="count_operations_no_tags == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ count_operations_no_tags }}</span>
                            </div>
                            <div v-if="active_special_vue == 1" style="margin-right: 8px;">
                                <span v-if="count_operations_non_techview > 0" style="font-weight: 800;color: white;background-color: #939393;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ count_operations_non_techview }}</span>
                                <span v-if="count_operations_non_techview == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ count_operations_non_techview }}</span>
                            </div>
                            <div v-if="active_special_vue == 2" style="margin-right: 8px;">
                                <span v-if="count_operations_techview  > 0" style="font-weight: 800;color: white;background-color: rgb(230 0 0);border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ count_operations_techview }}</span>
                                <span v-if="count_operations_techview == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ count_operations_techview }}</span>
                            </div>
                            <div v-if="active_special_vue == 5" style="margin-right: 8px;">
                                <span v-if="count_operations_realised  > 0" style="font-weight: 800;color: white;background-color: #939393;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ count_operations_realised }}</span>
                                <span v-if="count_operations_realised == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ count_operations_realised }}</span>
                            </div>
                            {{ vue.label }}
                        </div>
                        <div 
                            v-else 
                            class="ma-auto pl-5 pr-5 pa-2"
                            style="color: black; font-weight: 400; display: flex; justify-content: left; text-shadow: rgba(0, 0, 0, 0.29) 2px 2px 4px; background-color: rgb(243 243 243 / 90%); border-radius: 10px 10px 0px 0px; border: 1px solid #dbdce1; border-bottom: none; font-size: 14px; height: 40px; cursor: pointer;"
                            @click="loadVue(vue.id)"
                        >
                            <div v-if="vue.id == 6" style="margin-right: 8px;">
                                <span v-if="count_operations_no_tags > 0" style="font-weight: 800;color: white;background-color: #939393;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ count_operations_no_tags }}</span>
                                <span v-if="count_operations_no_tags == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ count_operations_no_tags }}</span>
                            </div>
                            <div v-if="vue.id == 1" style="margin-right: 8px;">
                                <span v-if="count_operations_non_techview > 0" style="font-weight: 800;color: white;background-color: #939393;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ count_operations_non_techview }}</span>
                                <span v-if="count_operations_non_techview == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ count_operations_non_techview }}</span>
                            </div>
                            <div v-if="vue.id == 2" style="margin-right: 8px;">
                                <span v-if="count_operations_techview  > 0" style="font-weight: 800;color: white;background-color: rgb(230 0 0);border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ count_operations_techview }}</span>
                                <span v-if="count_operations_techview == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ count_operations_techview }}</span>
                            </div>
                            <div v-if="vue.id == 5" style="margin-right: 8px;">
                                <span v-if="count_operations_realised  > 0" style="font-weight: 800;color: white;background-color: #939393;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ count_operations_realised }}</span>
                                <span v-if="count_operations_realised == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ count_operations_realised }}</span>
                            </div>
                            {{ vue.label }}
                        </div>
                    </div>
                </v-col>
            </v-row>

            <!-- Event book -->
            <div v-if="active_special_vue == 0">
                <div class="ma-0 pa-0"> 
                    <div style="height:150px;border-radius:0px 0px 5px 5px;background-color:white;" class="ma-0 pa-0">
                        <div class="col-0" style="color:black;font-weight:800;display:flex;justify-content:center; height:100%;min-width: 8px;border-radius:5px;" >
                            <v-col class="pa-0 pt-8 pb-4" style="display:flex;justify-content:center;align-items:center;">
                                <div style="text-align:center;">  
                                    <v-icon color="primary" style="height:36px;font-size:36px;">
                                        mdi-cog-outline
                                    </v-icon>
                                    <br>
                                    <div class="pa-3 pb-0">
                                        En cours de développement
                                    </div>
                                    <p class="pb-0" style="font-weight:400">
                                        Cette onglet est actuellement en cours de développement et sera disponible prochainement.
                                    </p>
                                </div>
                            </v-col>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Customer events -->
            <div v-if="active_special_vue == 6 && count_operations_no_tags != 0">
                <v-data-table
                    :headers="headers"
                    :items="operations_no_tags"
                    class="elevation-1 datatable-operation"
                    :items-per-page="5"
                    :loading="loading_datatable"
                    :search="search"
                    :sort-by="['id']"
                    :sort-desc="[true]"
                >
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-layout class="justify-start mt-7">
                                <!-- ADD OPERATION -->
                                <v-btn color="primary" class="white--text mr-3" height="40px"
                                    @click="$refs.modalEditOperation.openDialogAddOperation()">
                                    {{ $t('operation.add_operation') }}
                                </v-btn>

                                <!-- SELECT OPERATION -->
                                <v-select
                                    class="pl-2 pr-2 max-width-500"
                                    :items="operation_types"
                                    :label="$t('operation.select_type')"
                                    v-model="operation_type_selected"
                                    item-text="value"
                                    item-value="id"
                                    @change="getOperations()"
                                    solo chips multiple deletable-chips dense
                                />
                            </v-layout>

                            <!-- SEARCH -->
                            <v-layout class="justify-end mt-7 max-width-300">
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    :label="$t('global.search')"
                                ></v-text-field>
                            </v-layout>

                            <!-- MODALS -->
                            <modal-edit-operation ref="modalEditOperation" :id_machine_active="id" v-on:showSnackbarForModalEditOperation="showSnackbarForModalEditOperation"/>
                            <modal-display-operation-timeline ref="modalDisplayOperationTimeline"/>
                            <modal-add-operation-timeline ref="modalAddOperationTimeline" v-on:showSnackbarForModalAddOperationTimeline="showSnackbarForModalAddOperationTimeline"/>
                            <modal-add-reminder ref="modalAddReminder" v-on:showSnackbarForModalAddReminder="showSnackbarForModalAddReminder"/>
                            <modal-close-operation ref="modalCloseOperation" v-on:showSnackbarForModalCloseOperation="showSnackbarForModalCloseOperation"/>

                            <v-snackbar
                                v-model="snackbar"
                                :color="snackbar_type">
                                {{ snackbar_text }}
                                <template v-slot:action="{ attrs }">
                                    <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                                </template>
                            </v-snackbar>
                        </v-toolbar>

                        <v-layout class="justify-end">
                            <v-btn color="primary" class="mr-3" @click="downloadCsvSubassemblyOperations()">
                                <v-progress-circular
                                    v-if="loading_csv_file" class="mr-2"
                                    color="white" size="20" width="2"
                                />
                                <v-icon v-else left>mdi-download-box-outline</v-icon>
                                {{ $t('global.download') }}
                            </v-btn>
                        </v-layout>

                    </template>

                    <!-- ALERT LEVEL --> 
                    <template v-slot:item.id="{ item }" >
                        <v-col class="col justify-start h-100 pt-7" style="max-width: 60px;">
                            <v-row class="row mb-3 justify-center"> #{{ item.id }} </v-row>   
                            <v-icon :color="assetColor(item)" large v-if="(item.alert_level !== 0 && item.alert_level !== 3 && item.alert_level !== 9)">
                                mdi-alert
                            </v-icon>

                            <v-icon :color="assetColor(item)" large v-if="(item.alert_level === 3)">
                                mdi-help-rhombus
                            </v-icon>

                            <v-icon :color="assetColor(item)" large v-if="(item.alert_level === 9)">
                                mdi-check-circle
                            </v-icon>
                        </v-col>
                    </template>

                    <!-- LABEL --> 
                    <template v-slot:item.label="{ item }">
                        <v-col>
                            <v-row class="pa-1 pt-4 mb-0" style="font-weight: bold;">{{ item.label }}</v-row>
                            <v-row class="pa-1 mb-0 pt-0 pb-0 pl-3 mt-0" style="font-size: 12px;">Envoyé le : {{ item.created_at }}</v-row>
                            <v-row v-if="item.intervener !== null" class="pa-1 mb-0 pt-0 pb-0 pl-3 mt-0" style="font-size: 12px;">Envoyé par : {{ item.intervener.last_name + " " + item.intervener.first_name === 'System Alerting' ? 'Techview' : item.intervener.last_name + " " + item.intervener.first_name }}</v-row>
                            <v-row v-if="!upcoming_operation" class="pa-1 mb-0 pt-0 pl-3 mt-0" style="font-size: 12px;">Clôturé le : {{ item.date_operation_realised }}</v-row>
                            <v-row class="pa-1 mb-1 pt-0 pl-3 mt-0" style="font-size: 12px;">Sous-ensemble concerné :  
                                <router-link class="ml-1" v-if="item.subassembly_id !== 0" :to="{ path: '/subassembly/' + item.subassembly.id }">
                                    {{ item.subassembly.label }}
                                </router-link>
                                <router-link class="ml-1" v-else :to="{ path: '/machine/' + item.id_ref_part_concerned + '/settings'} ">
                                    machine complète
                                </router-link>
                            </v-row>
                            <v-col v-html="item.comment" class="pa-2 mb-0 pl-5 mt-0 mb-6" style="border-left: 1px solid #80808061;font-style: italic;"></v-col>
                            <v-row class="mb-0">
                                <v-row class="pa-1 pa-1 mb-0 mt-0 ml-2">
                                    <v-tooltip top color="red" class="mr-2">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer mr-5"
                                                        v-bind="attrs" v-on="on"
                                                        color="red"
                                                        style="border:1px solid red;border-radius:5px;"
                                                        @click="$refs.modalEditOperation.openDialogSendOperation(item)"
                                                >
                                                    mdi-share
                                                </v-icon>
                                            </template>
                                            <span>Publier l'opération</span>
                                        </v-tooltip>
                                    <v-tooltip top color="primary" class="mr-2">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon class="pointer mr-2"
                                                    v-bind="attrs" v-on="on"
                                                    @click="$refs.modalDisplayOperationTimeline.openDialogViewOperationTimelines(item.id, item.type_operation ? item.type_operation.value : null)"
                                            >
                                                mdi-eye
                                            </v-icon>
                                        </template>
                                        <span>{{ $t('operation.operation_history') }}</span>
                                    </v-tooltip>
                                    <template v-if="display_btn_operation_not_end">
                                        <v-tooltip top color="primary" class="mr-2">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer mr-2"
                                                        v-bind="attrs" v-on="on"
                                                        @click="$refs.modalEditOperation.openDialogUpdateOperation(item)"
                                                >
                                                    mdi-pencil
                                                </v-icon>
                                            </template>
                                            <span>{{ $t('operation.update_operation') }}</span>
                                        </v-tooltip>
                                        <v-tooltip top color="primary" class="mr-2">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer mr-2"
                                                        v-bind="attrs" v-on="on"
                                                        @click="$refs.modalAddOperationTimeline.openDialogAddOperationTimeline(item.id, 3)"
                                                >
                                                    mdi-comment-plus-outline
                                                </v-icon>
                                            </template>
                                            <span>{{ $t('operation.add_message') }}</span>
                                        </v-tooltip>
                                        <v-tooltip top color="primary" class="mr-2">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer mr-2"
                                                        v-bind="attrs" v-on="on"
                                                        @click="$refs.modalAddOperationTimeline.openDialogAddOperationTimeline(item.id, 4)"
                                                >
                                                    mdi-account-cog-outline
                                                </v-icon>
                                            </template>
                                            <span>{{ $t('operation.expert_demand') }}</span>
                                        </v-tooltip>
                                        <v-tooltip top color="primary" class="mr-2">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer mr-2"
                                                        v-bind="attrs" v-on="on"
                                                        @click="$refs.modalAddReminder.openDialogAddReminder(item.id, item.date_reminder)"
                                                >
                                                    mdi-calendar-plus
                                                </v-icon>
                                            </template>
                                            <span>{{ $t('operation.plan_reminder') }}</span>
                                        </v-tooltip>
                                        <v-tooltip top color="primary" class="mr-2">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer mr-2"
                                                        v-bind="attrs" v-on="on"
                                                        @click="$refs.modalCloseOperation.openDialogCloseOperation(item.id)"
                                                >
                                                    mdi-archive-arrow-down
                                                </v-icon>
                                            </template>
                                            <span>{{ $t('operation.close_operation') }}</span>
                                        </v-tooltip>
                                    </template>
                                </v-row>
                            </v-row>
                        </v-col>
                    </template>

                    <!-- TAGS --> 
                    <template v-slot:item.tags="{ item }">
                        <v-chip-group>
                            <v-chip v-if="item.tags.length === 0" color="grey">{{ $t('global.no_tags_associated') }}</v-chip>
                            <v-chip v-for="tag in item.tags" class="pl-3 pr-3" link :key="tag.id">
                                {{ tag.label }}
                            </v-chip>
                        </v-chip-group>
                    </template>
                </v-data-table>
            </div>
            <!-- No Customer events -->
            <div v-if="active_special_vue == 6 && count_operations_no_tags == 0">
                <div class="ma-0 pa-0"> 
                    <div style="height:150px;border-radius:0px 0px 5px 5px;background-color:white;" class="ma-0 pa-0">
                        <div class="col-0" style="color:black;font-weight:800;display:flex;justify-content:center; height:100%;min-width: 8px;border-radius:5px;" >
                            <v-col class="pa-0 pt-8 pb-4" style="display:flex;justify-content:center;align-items:center;">
                                <div style="text-align:center;opacity:0.5;">  
                                    <v-icon color="green" style="height:36px;font-size:36px;">
                                        mdi-check
                                    </v-icon>
                                    <br>
                                    <div class="pa-3 pb-0">
                                        Aucune alerte
                                    </div>
                                    <p class="pb-0" style="font-weight:400">
                                        Aucune alerte pour cet équipement.
                                    </p>
                                </div>
                            </v-col>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Published events -->
            <div v-if="active_special_vue == 1 && count_operations_non_techview != 0">
                <v-data-table
                    :headers="headers"
                    :items="operations_non_techview"
                    class="elevation-1 datatable-operation"
                    :items-per-page="5"
                    :loading="loading_datatable"
                    :search="search"
                    :sort-by="['id']"
                    :sort-desc="[true]"
                    no-data-text="Aucune alerte"
                >
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-layout class="justify-start mt-7">
                                <!-- ADD OPERATION -->
                                <v-btn color="primary" class="white--text mr-3" height="40px"
                                    @click="$refs.modalEditOperation.openDialogAddOperation()">
                                    {{ $t('operation.add_operation') }}
                                </v-btn>

                                <!-- SELECT OPERATION -->
                                <v-select
                                    class="pl-2 pr-2 max-width-500"
                                    :items="operation_types"
                                    :label="$t('operation.select_type')"
                                    v-model="operation_type_selected"
                                    item-text="value"
                                    item-value="id"
                                    @change="getOperations()"
                                    solo chips multiple deletable-chips dense
                                />
                            </v-layout>

                            <!-- SEARCH -->
                            <v-layout class="justify-end mt-7 max-width-300">
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    :label="$t('global.search')"
                                ></v-text-field>
                            </v-layout>

                            <!-- MODALS -->
                            <modal-edit-operation ref="modalEditOperation" :id_machine_active="id" v-on:showSnackbarForModalEditOperation="showSnackbarForModalEditOperation"/>
                            <modal-display-operation-timeline ref="modalDisplayOperationTimeline"/>
                            <modal-add-operation-timeline ref="modalAddOperationTimeline" v-on:showSnackbarForModalAddOperationTimeline="showSnackbarForModalAddOperationTimeline"/>
                            <modal-add-reminder ref="modalAddReminder" v-on:showSnackbarForModalAddReminder="showSnackbarForModalAddReminder"/>
                            <modal-close-operation ref="modalCloseOperation" v-on:showSnackbarForModalCloseOperation="showSnackbarForModalCloseOperation"/>

                            <v-snackbar
                                v-model="snackbar"
                                :color="snackbar_type">
                                {{ snackbar_text }}
                                <template v-slot:action="{ attrs }">
                                    <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                                </template>
                            </v-snackbar>
                        </v-toolbar>

                        <v-layout class="justify-end">
                            <v-btn color="primary" class="mr-3" @click="downloadCsvSubassemblyOperations()">
                                <v-progress-circular
                                    v-if="loading_csv_file" class="mr-2"
                                    color="white" size="20" width="2"
                                />
                                <v-icon v-else left>mdi-download-box-outline</v-icon>
                                {{ $t('global.download') }}
                            </v-btn>
                        </v-layout>

                    </template>

                    <!-- ALERT LEVEL --> 
                    <template v-slot:item.id="{ item }" >
                        <v-col class="col justify-start h-100 pt-7" style="max-width: 60px;">
                            <v-row class="row mb-3 justify-center"> #{{ item.id }} </v-row>   
                            <v-icon :color="assetColor(item)" large v-if="(item.alert_level !== 0 && item.alert_level !== 3 && item.alert_level !== 9)">
                                mdi-alert
                            </v-icon>

                            <v-icon :color="assetColor(item)" large v-if="(item.alert_level === 3)">
                                mdi-help-rhombus
                            </v-icon>

                            <v-icon :color="assetColor(item)" large v-if="(item.alert_level === 9)">
                                mdi-check-circle
                            </v-icon>
                        </v-col>
                    </template>

                    <!-- LABEL --> 
                    <template v-slot:item.label="{ item }">
                        <v-col>
                            <v-row class="pa-1 pt-4 mb-0" style="font-weight: bold;">{{ item.label }}</v-row>
                            <v-row class="pa-1 mb-0 pt-0 pb-0 pl-3 mt-0" style="font-size: 12px;">Envoyé le : {{ item.created_at }}</v-row>
                            <v-row v-if="item.intervener !== null" class="pa-1 mb-0 pt-0 pb-0 pl-3 mt-0" style="font-size: 12px;">Envoyé par : {{ item.intervener.last_name + " " + item.intervener.first_name === 'System Alerting' ? 'Techview' : item.intervener.last_name + " " + item.intervener.first_name }}</v-row>
                            <v-row v-if="!upcoming_operation" class="pa-1 mb-0 pt-0 pl-3 mt-0" style="font-size: 12px;">Clôturé le : {{ item.date_operation_realised }}</v-row>
                            <v-row class="pa-1 mb-1 pt-0 pl-3 mt-0" style="font-size: 12px;">Sous-ensemble concerné :  
                                <router-link class="ml-1" v-if="item.subassembly_id !== 0" :to="{ path: '/subassembly/' + item.subassembly.id }">
                                    {{ item.subassembly.label }}
                                </router-link>
                                <router-link class="ml-1" v-else :to="{ path: '/machine/' + item.id_ref_part_concerned + '/settings'} ">
                                    machine complète
                                </router-link>
                            </v-row>
                            <v-col v-html="item.comment" class="pa-2 mb-0 pl-5 mt-0 mb-6" style="border-left: 1px solid #80808061;font-style: italic;"></v-col>
                            <v-row class="mb-0">
                                <v-row class="pa-1 pa-1 mb-0 mt-0 ml-2">
                                    <v-tooltip top color="red" class="mr-2">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer mr-5"
                                                        v-bind="attrs" v-on="on"
                                                        color="red"
                                                        style="border:1px solid red;border-radius:5px;"
                                                        @click="$refs.modalEditOperation.openDialogSendOperation(item)"
                                                >
                                                    mdi-share
                                                </v-icon>
                                            </template>
                                            <span>Publier l'opération</span>
                                        </v-tooltip>
                                    <v-tooltip top color="primary" class="mr-2">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon class="pointer mr-2"
                                                    v-bind="attrs" v-on="on"
                                                    @click="$refs.modalDisplayOperationTimeline.openDialogViewOperationTimelines(item.id, item.type_operation ? item.type_operation.value : null)"
                                            >
                                                mdi-eye
                                            </v-icon>
                                        </template>
                                        <span>{{ $t('operation.operation_history') }}</span>
                                    </v-tooltip>
                                    <template v-if="display_btn_operation_not_end">
                                        <v-tooltip top color="primary" class="mr-2">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer mr-2"
                                                        v-bind="attrs" v-on="on"
                                                        @click="$refs.modalEditOperation.openDialogUpdateOperation(item)"
                                                >
                                                    mdi-pencil
                                                </v-icon>
                                            </template>
                                            <span>{{ $t('operation.update_operation') }}</span>
                                        </v-tooltip>
                                        <v-tooltip top color="primary" class="mr-2">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer mr-2"
                                                        v-bind="attrs" v-on="on"
                                                        @click="$refs.modalAddOperationTimeline.openDialogAddOperationTimeline(item.id, 3)"
                                                >
                                                    mdi-comment-plus-outline
                                                </v-icon>
                                            </template>
                                            <span>{{ $t('operation.add_message') }}</span>
                                        </v-tooltip>
                                        <v-tooltip top color="primary" class="mr-2">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer mr-2"
                                                        v-bind="attrs" v-on="on"
                                                        @click="$refs.modalAddOperationTimeline.openDialogAddOperationTimeline(item.id, 4)"
                                                >
                                                    mdi-account-cog-outline
                                                </v-icon>
                                            </template>
                                            <span>{{ $t('operation.expert_demand') }}</span>
                                        </v-tooltip>
                                        <v-tooltip top color="primary" class="mr-2">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer mr-2"
                                                        v-bind="attrs" v-on="on"
                                                        @click="$refs.modalAddReminder.openDialogAddReminder(item.id, item.date_reminder)"
                                                >
                                                    mdi-calendar-plus
                                                </v-icon>
                                            </template>
                                            <span>{{ $t('operation.plan_reminder') }}</span>
                                        </v-tooltip>
                                        <v-tooltip top color="primary" class="mr-2">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer mr-2"
                                                        v-bind="attrs" v-on="on"
                                                        @click="$refs.modalCloseOperation.openDialogCloseOperation(item.id)"
                                                >
                                                    mdi-archive-arrow-down
                                                </v-icon>
                                            </template>
                                            <span>{{ $t('operation.close_operation') }}</span>
                                        </v-tooltip>
                                    </template>
                                </v-row>
                            </v-row>
                        </v-col>
                    </template>

                    <!-- TAGS --> 
                    <template v-slot:item.tags="{ item }">
                        <v-chip-group>
                            <v-chip v-if="item.tags.length === 0" color="grey">{{ $t('global.no_tags_associated') }}</v-chip>
                            <v-chip v-for="tag in item.tags" class="pl-3 pr-3" link :key="tag.id">
                                {{ tag.label }}
                            </v-chip>
                        </v-chip-group>
                    </template>
                </v-data-table>
            </div>
            <!-- No Published events -->
            <div v-if="active_special_vue == 1 && count_operations_non_techview == 0">
                <div class="ma-0 pa-0"> 
                    <div style="height:150px;border-radius:0px 0px 5px 5px;background-color:white;" class="ma-0 pa-0">
                        <div class="col-0" style="color:black;font-weight:800;display:flex;justify-content:center; height:100%;min-width: 8px;border-radius:5px;" >
                            <v-col class="pa-0 pt-8 pb-4" style="display:flex;justify-content:center;align-items:center;">
                                <div style="text-align:center;opacity:0.5;">  
                                    <v-icon color="green" style="height:36px;font-size:36px;">
                                        mdi-check
                                    </v-icon>
                                    <br>
                                    <div class="pa-3 pb-0">
                                        Aucune alerte
                                    </div>
                                    <p class="pb-0" style="font-weight:400">
                                        Aucune alerte pour cet équipement.
                                    </p>
                                </div>
                            </v-col>
                        </div>
                    </div>
                </div>
            </div>

            <!-- New events -->
            <div v-if="active_special_vue == 2 && count_operations_techview != 0">
                <v-data-table
                    :headers="headers"
                    :items="operations_techview"
                    class="elevation-1 datatable-operation"
                    :items-per-page="5"
                    :loading="loading_datatable"
                    :search="search"
                    :sort-by="['id']"
                    :sort-desc="[true]"
                    no-data-text="Aucune alerte"
                >
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-layout class="justify-start mt-7">
                                <!-- ADD OPERATION -->
                                <v-btn color="primary" class="white--text mr-3" height="40px"
                                    @click="$refs.modalEditOperation.openDialogAddOperation()">
                                    {{ $t('operation.add_operation') }}
                                </v-btn>

                                <!-- SELECT OPERATION -->
                                <v-select
                                    class="pl-2 pr-2 max-width-500"
                                    :items="operation_types"
                                    :label="$t('operation.select_type')"
                                    v-model="operation_type_selected"
                                    item-text="value"
                                    item-value="id"
                                    @change="getOperations()"
                                    solo chips multiple deletable-chips dense
                                />
                            </v-layout>

                            <!-- SEARCH -->
                            <v-layout class="justify-end mt-7 max-width-300">
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    :label="$t('global.search')"
                                ></v-text-field>
                            </v-layout>

                            <!-- MODALS -->
                            <modal-edit-operation ref="modalEditOperation" :id_machine_active="id" v-on:showSnackbarForModalEditOperation="showSnackbarForModalEditOperation"/>
                            <modal-display-operation-timeline ref="modalDisplayOperationTimeline"/>
                            <modal-add-operation-timeline ref="modalAddOperationTimeline" v-on:showSnackbarForModalAddOperationTimeline="showSnackbarForModalAddOperationTimeline"/>
                            <modal-add-reminder ref="modalAddReminder" v-on:showSnackbarForModalAddReminder="showSnackbarForModalAddReminder"/>
                            <modal-close-operation ref="modalCloseOperation" v-on:showSnackbarForModalCloseOperation="showSnackbarForModalCloseOperation"/>

                            <v-snackbar
                                v-model="snackbar"
                                :color="snackbar_type">
                                {{ snackbar_text }}
                                <template v-slot:action="{ attrs }">
                                    <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                                </template>
                            </v-snackbar>
                        </v-toolbar>

                        <v-layout class="justify-end">
                            <v-btn color="primary" class="mr-3" @click="downloadCsvSubassemblyOperations()">
                                <v-progress-circular
                                    v-if="loading_csv_file" class="mr-2"
                                    color="white" size="20" width="2"
                                />
                                <v-icon v-else left>mdi-download-box-outline</v-icon>
                                {{ $t('global.download') }}
                            </v-btn>
                        </v-layout>

                    </template>

                    <!-- ALERT LEVEL --> 
                    <template v-slot:item.id="{ item }" >
                        <v-col class="col justify-start h-100 pt-7" style="max-width: 60px;">
                            <v-row class="row mb-3 justify-center"> #{{ item.id }} </v-row>   
                            <v-icon :color="assetColor(item)" large v-if="(item.alert_level !== 0 && item.alert_level !== 3 && item.alert_level !== 9)">
                                mdi-alert
                            </v-icon>

                            <v-icon :color="assetColor(item)" large v-if="(item.alert_level === 3)">
                                mdi-help-rhombus
                            </v-icon>

                            <v-icon :color="assetColor(item)" large v-if="(item.alert_level === 9)">
                                mdi-check-circle
                            </v-icon>
                        </v-col>
                    </template>

                    <!-- LABEL --> 
                    <template v-slot:item.label="{ item }">
                        <v-col>
                            <v-row class="pa-1 pt-4 mb-0" style="font-weight: bold;">{{ item.label }}</v-row>
                            <v-row class="pa-1 mb-0 pt-0 pb-0 pl-3 mt-0" style="font-size: 12px;">Envoyé le : {{ item.created_at }}</v-row>
                            <v-row v-if="item.intervener !== null" class="pa-1 mb-0 pt-0 pb-0 pl-3 mt-0" style="font-size: 12px;">Envoyé par : {{ item.intervener.last_name + " " + item.intervener.first_name === 'System Alerting' ? 'Techview' : item.intervener.last_name + " " + item.intervener.first_name }}</v-row>
                            <v-row v-if="!upcoming_operation" class="pa-1 mb-0 pt-0 pl-3 mt-0" style="font-size: 12px;">Clôturé le : {{ item.date_operation_realised }}</v-row>
                            <v-row class="pa-1 mb-1 pt-0 pl-3 mt-0" style="font-size: 12px;">Sous-ensemble concerné :  
                                <router-link class="ml-1" v-if="item.subassembly_id !== 0" :to="{ path: '/subassembly/' + item.subassembly.id }">
                                    {{ item.subassembly.label }}
                                </router-link>
                                <router-link class="ml-1" v-else :to="{ path: '/machine/' + item.id_ref_part_concerned + '/settings'} ">
                                    machine complète
                                </router-link>
                            </v-row>
                            <v-col v-html="item.comment" class="pa-2 mb-0 pl-5 mt-0 mb-6" style="border-left: 1px solid #80808061;font-style: italic;"></v-col>
                            <v-row class="mb-0">
                                <v-row class="pa-1 pa-1 mb-0 mt-0 ml-2">
                                    <v-tooltip top color="red" class="mr-2">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer mr-5"
                                                        v-bind="attrs" v-on="on"
                                                        color="red"
                                                        style="border:1px solid red;border-radius:5px;"
                                                        @click="$refs.modalEditOperation.openDialogSendOperation(item)"
                                                >
                                                    mdi-share
                                                </v-icon>
                                            </template>
                                            <span>Publier l'opération</span>
                                        </v-tooltip>
                                    <v-tooltip top color="primary" class="mr-2">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon class="pointer mr-2"
                                                    v-bind="attrs" v-on="on"
                                                    @click="$refs.modalDisplayOperationTimeline.openDialogViewOperationTimelines(item.id, item.type_operation ? item.type_operation.value : null)"
                                            >
                                                mdi-eye
                                            </v-icon>
                                        </template>
                                        <span>{{ $t('operation.operation_history') }}</span>
                                    </v-tooltip>
                                    <template v-if="display_btn_operation_not_end">
                                        <v-tooltip top color="primary" class="mr-2">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer mr-2"
                                                        v-bind="attrs" v-on="on"
                                                        @click="$refs.modalEditOperation.openDialogUpdateOperation(item)"
                                                >
                                                    mdi-pencil
                                                </v-icon>
                                            </template>
                                            <span>{{ $t('operation.update_operation') }}</span>
                                        </v-tooltip>
                                        <v-tooltip top color="primary" class="mr-2">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer mr-2"
                                                        v-bind="attrs" v-on="on"
                                                        @click="$refs.modalAddOperationTimeline.openDialogAddOperationTimeline(item.id, 3)"
                                                >
                                                    mdi-comment-plus-outline
                                                </v-icon>
                                            </template>
                                            <span>{{ $t('operation.add_message') }}</span>
                                        </v-tooltip>
                                        <v-tooltip top color="primary" class="mr-2">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer mr-2"
                                                        v-bind="attrs" v-on="on"
                                                        @click="$refs.modalAddOperationTimeline.openDialogAddOperationTimeline(item.id, 4)"
                                                >
                                                    mdi-account-cog-outline
                                                </v-icon>
                                            </template>
                                            <span>{{ $t('operation.expert_demand') }}</span>
                                        </v-tooltip>
                                        <v-tooltip top color="primary" class="mr-2">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer mr-2"
                                                        v-bind="attrs" v-on="on"
                                                        @click="$refs.modalAddReminder.openDialogAddReminder(item.id, item.date_reminder)"
                                                >
                                                    mdi-calendar-plus
                                                </v-icon>
                                            </template>
                                            <span>{{ $t('operation.plan_reminder') }}</span>
                                        </v-tooltip>
                                        <v-tooltip top color="primary" class="mr-2">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer mr-2"
                                                        v-bind="attrs" v-on="on"
                                                        @click="$refs.modalCloseOperation.openDialogCloseOperation(item.id)"
                                                >
                                                    mdi-archive-arrow-down
                                                </v-icon>
                                            </template>
                                            <span>{{ $t('operation.close_operation') }}</span>
                                        </v-tooltip>
                                    </template>
                                </v-row>
                            </v-row>
                        </v-col>
                    </template>

                    <!-- TAGS --> 
                    <template v-slot:item.tags="{ item }">
                        <v-chip-group>
                            <v-chip v-if="item.tags.length === 0" color="grey">{{ $t('global.no_tags_associated') }}</v-chip>
                            <v-chip v-for="tag in item.tags" class="pl-3 pr-3" link :key="tag.id">
                                {{ tag.label }}
                            </v-chip>
                        </v-chip-group>
                    </template>
                </v-data-table>
            </div>
            <!-- No New events -->
            <div v-if="active_special_vue == 2 && count_operations_techview == 0">
                <div class="ma-0 pa-0"> 
                    <div style="height:150px;border-radius:0px 0px 5px 5px;background-color:white;" class="ma-0 pa-0">
                        <div class="col-0" style="color:black;font-weight:800;display:flex;justify-content:center; height:100%;min-width: 8px;border-radius:5px;" >
                            <v-col class="pa-0 pt-8 pb-4" style="display:flex;justify-content:center;align-items:center;">
                                <div style="text-align:center;opacity:0.5;">  
                                    <v-icon color="green" style="height:36px;font-size:36px;">
                                        mdi-check
                                    </v-icon>
                                    <br>
                                    <div class="pa-3 pb-0">
                                        Aucune alerte
                                    </div>
                                    <p class="pb-0" style="font-weight:400">
                                        Aucune alerte pour cet équipement.
                                    </p>
                                </div>
                            </v-col>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Checked events -->
            <div v-if="active_special_vue == 3">
                <div class="ma-0 pa-0"> 
                    <div style="height:150px;border-radius:0px 0px 5px 5px;background-color:white;" class="ma-0 pa-0">
                        <div class="col-0" style="color:black;font-weight:800;display:flex;justify-content:center; height:100%;min-width: 8px;border-radius:5px;" >
                            <v-col class="pa-0 pt-8 pb-4" style="display:flex;justify-content:center;align-items:center;">
                                <div style="text-align:center;">  
                                    <v-icon color="primary" style="height:36px;font-size:36px;">
                                        mdi-cog-outline
                                    </v-icon>
                                    <br>
                                    <div class="pa-3 pb-0">
                                        En cours de développement
                                    </div>
                                    <p class="pb-0" style="font-weight:400">
                                        Cette onglet est actuellement en cours de développement et sera disponible prochainement.
                                    </p>
                                </div>
                            </v-col>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Quart events -->
            <div v-if="active_special_vue == 4">
                <div class="ma-0 pa-0"> 
                    <div style="height:150px;border-radius:0px 0px 5px 5px;background-color:white;" class="ma-0 pa-0">
                        <div class="col-0" style="color:black;font-weight:800;display:flex;justify-content:center; height:100%;min-width: 8px;border-radius:5px;" >
                            <v-col class="pa-0 pt-8 pb-4" style="display:flex;justify-content:center;align-items:center;">
                                <div style="text-align:center;">  
                                    <v-icon color="primary" style="height:36px;font-size:36px;">
                                        mdi-cog-outline
                                    </v-icon>
                                    <br>
                                    <div class="pa-3 pb-0">
                                        En cours de développement
                                    </div>
                                    <p class="pb-0" style="font-weight:400">
                                        Cette onglet est actuellement en cours de développement et sera disponible prochainement.
                                    </p>
                                </div>
                            </v-col>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Released events -->
            <div v-if="active_special_vue == 5 && count_operations_realised != 0">
                <v-data-table
                    :headers="headers"
                    :items="operations_realised"
                    class="elevation-1 datatable-operation"
                    :items-per-page="5"
                    :loading="loading_datatable"
                    :search="search"
                    :sort-by="['id']"
                    :sort-desc="[true]"
                    no-data-text="Aucune alerte"
                >
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-layout class="justify-start mt-7">
                                <!-- ADD OPERATION -->
                                <v-btn color="primary" class="white--text mr-3" height="40px"
                                    @click="$refs.modalEditOperation.openDialogAddOperation()">
                                    {{ $t('operation.add_operation') }}
                                </v-btn>

                                <!-- SELECT OPERATION -->
                                <v-select
                                    class="pl-2 pr-2 max-width-500"
                                    :items="operation_types"
                                    :label="$t('operation.select_type')"
                                    v-model="operation_type_selected"
                                    item-text="value"
                                    item-value="id"
                                    @change="getOperations()"
                                    solo chips multiple deletable-chips dense
                                />
                            </v-layout>

                            <!-- SEARCH -->
                            <v-layout class="justify-end mt-7 max-width-300">
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    :label="$t('global.search')"
                                ></v-text-field>
                            </v-layout>

                            <!-- MODALS -->
                            <modal-edit-operation ref="modalEditOperation" :id_machine_active="id" v-on:showSnackbarForModalEditOperation="showSnackbarForModalEditOperation"/>
                            <modal-display-operation-timeline ref="modalDisplayOperationTimeline"/>
                            <modal-add-operation-timeline ref="modalAddOperationTimeline" v-on:showSnackbarForModalAddOperationTimeline="showSnackbarForModalAddOperationTimeline"/>
                            <modal-add-reminder ref="modalAddReminder" v-on:showSnackbarForModalAddReminder="showSnackbarForModalAddReminder"/>
                            <modal-close-operation ref="modalCloseOperation" v-on:showSnackbarForModalCloseOperation="showSnackbarForModalCloseOperation"/>

                            <v-snackbar
                                v-model="snackbar"
                                :color="snackbar_type">
                                {{ snackbar_text }}
                                <template v-slot:action="{ attrs }">
                                    <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                                </template>
                            </v-snackbar>
                        </v-toolbar>

                        <v-layout class="justify-end">
                            <v-btn color="primary" class="mr-3" @click="downloadCsvSubassemblyOperations()">
                                <v-progress-circular
                                    v-if="loading_csv_file" class="mr-2"
                                    color="white" size="20" width="2"
                                />
                                <v-icon v-else left>mdi-download-box-outline</v-icon>
                                {{ $t('global.download') }}
                            </v-btn>
                        </v-layout>

                    </template>

                    <!-- ALERT LEVEL --> 
                    <template v-slot:item.id="{ item }" >
                        <v-col class="col justify-start h-100 pt-7" style="max-width: 60px;">
                            <v-row class="row mb-3 justify-center"> #{{ item.id }} </v-row>   
                            <v-icon :color="assetColor(item)" large v-if="(item.alert_level !== 0 && item.alert_level !== 3 && item.alert_level !== 9)">
                                mdi-alert
                            </v-icon>

                            <v-icon :color="assetColor(item)" large v-if="(item.alert_level === 3)">
                                mdi-help-rhombus
                            </v-icon>

                            <v-icon :color="assetColor(item)" large v-if="(item.alert_level === 9)">
                                mdi-check-circle
                            </v-icon>
                        </v-col>
                    </template>

                    <!-- LABEL --> 
                    <template v-slot:item.label="{ item }">
                        <v-col>
                            <v-row class="pa-1 pt-4 mb-0" style="font-weight: bold;">
                                <p v-if="item.date_operation_realised != null">(Clôturée)</p>
                                <p style="margin-left:5px;">{{ item.label }}</p>
                            </v-row>
                            <v-row class="pa-1 mb-0 pt-0 pb-0 pl-3 mt-0" style="font-size: 12px;">Envoyé le : {{ item.date_operation_realised }}</v-row>
                            <v-row v-if="item.intervener !== null" class="pa-1 mb-0 pt-0 pb-0 pl-3 mt-0" style="font-size: 12px;">Envoyé par : {{ item.intervener.last_name + " " + item.intervener.first_name === 'System Alerting' ? 'Techview' : item.intervener.last_name + " " + item.intervener.first_name }}</v-row>
                            <v-row v-if="!upcoming_operation" class="pa-1 mb-0 pt-0 pl-3 mt-0" style="font-size: 12px;">Clôturé le : {{ item.date_operation_realised }}</v-row>
                            <v-row class="pa-1 mb-1 pt-0 pl-3 mt-0" style="font-size: 12px;">Sous-ensemble concerné :  
                                <router-link class="ml-1" v-if="item.subassembly_id !== 0" :to="{ path: '/subassembly/' + item.subassembly.id }">
                                    {{ item.subassembly.label }}
                                </router-link>
                                <router-link class="ml-1" v-else :to="{ path: '/machine/' + item.id_ref_part_concerned + '/settings'} ">
                                    machine complète
                                </router-link>
                            </v-row>
                            <v-col v-html="item.comment" class="pa-2 mb-0 pl-5 mt-0 mb-6" style="border-left: 1px solid #80808061;font-style: italic;"></v-col>
                            <v-row class="mb-0">
                                <v-row class="pa-1 pa-1 mb-0 mt-0 ml-2">
                                    <v-tooltip top color="red" class="mr-2">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer mr-5"
                                                        v-bind="attrs" v-on="on"
                                                        color="red"
                                                        style="border:1px solid red;border-radius:5px;"
                                                        @click="$refs.modalEditOperation.openDialogSendOperation(item)"
                                                >
                                                    mdi-share
                                                </v-icon>
                                            </template>
                                            <span>Publier l'opération</span>
                                        </v-tooltip>
                                    <v-tooltip top color="primary" class="mr-2">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon class="pointer mr-2"
                                                    v-bind="attrs" v-on="on"
                                                    @click="$refs.modalDisplayOperationTimeline.openDialogViewOperationTimelines(item.id, item.type_operation ? item.type_operation.value : null)"
                                            >
                                                mdi-eye
                                            </v-icon>
                                        </template>
                                        <span>{{ $t('operation.operation_history') }}</span>
                                    </v-tooltip>
                                </v-row>
                            </v-row>
                        </v-col>
                    </template>

                    <!-- TAGS --> 
                    <template v-slot:item.tags="{ item }">
                        <v-chip-group>
                            <v-chip v-if="item.tags.length === 0" color="grey">{{ $t('global.no_tags_associated') }}</v-chip>
                            <v-chip v-for="tag in item.tags" class="pl-3 pr-3" link :key="tag.id">
                                {{ tag.label }}
                            </v-chip>
                        </v-chip-group>
                    </template>
                </v-data-table>
            </div>
            <!-- No Released events -->
            <div v-if="active_special_vue == 5 && count_operations_realised == 0">
                <div class="ma-0 pa-0"> 
                    <div style="height:150px;border-radius:0px 0px 5px 5px;background-color:white;" class="ma-0 pa-0">
                        <div class="col-0" style="color:black;font-weight:800;display:flex;justify-content:center; height:100%;min-width: 8px;border-radius:5px;" >
                            <v-col class="pa-0 pt-8 pb-4" style="display:flex;justify-content:center;align-items:center;">
                                <div style="text-align:center;opacity:0.5;">  
                                    <v-icon color="green" style="height:36px;font-size:36px;">
                                        mdi-check
                                    </v-icon>
                                    <br>
                                    <div class="pa-3 pb-0">
                                        Aucune alerte
                                    </div>
                                    <p class="pb-0" style="font-weight:400">
                                        Aucune alerte pour cet équipement.
                                    </p>
                                </div>
                            </v-col>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- CUSTOMER --> 
        <div v-if="$store.state.access_level >= 100">
            <v-row class="pt-0" style="margin:0px;height:40px;">
                <v-col class="row ml-0 mt-0 pb-0 pt-0 pl-0" style="position: relative;left:-1px;">
                    <div v-for="vue in vuesUser" :key="vue" class="mr-1">
                        <div 
                            v-if="vue && vue.id && vue.id == active_special_vue_user" 
                            class="ma-auto pl-5 pr-5 pa-2"
                            style="color: black; font-weight: 400; display: flex; justify-content: left; text-shadow: rgba(0, 0, 0, 0.29) 2px 2px 4px; background-color: rgb(255 255 255); border-radius: 5px 5px 0px 0px; border-left: 1px solid rgba(211, 211, 211, 0.43); border-bottom: none; font-size: 14px; height: 40px; align-items: center; font-weight:bold;"
                        >
                            <div v-if="active_special_vue_user == 1" style="margin-right: 8px;">
                                <span v-if="count_operations_no_tags > 0" style="font-weight: 800;color: white;background-color: #939393;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ count_operations_no_tags }}</span>
                                <span v-if="count_operations_no_tags == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ count_operations_no_tags }}</span>
                            </div>
                            <div v-if="active_special_vue_user == 2" style="margin-right: 8px;">
                                <span v-if="count_operations_non_techview  > 0" style="font-weight: 800;color: white;background-color: rgb(230 0 0);border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ count_operations_non_techview }}</span>
                                <span v-if="count_operations_non_techview == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ count_operations_non_techview }}</span>
                            </div>
                            <div v-if="active_special_vue_user == 3" style="margin-right: 8px;">
                                <span v-if="count_operations_realised > 0" style="font-weight: 800;color: white;background-color: #939393;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ count_operations_realised }}</span>
                                <span v-if="count_operations_realised == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ count_operations_realised }}</span>
                            </div>
                            {{ vue.label }}
                        </div>
                        <div 
                            v-else 
                            class="ma-auto pl-5 pr-5 pa-2"
                            style="color: black; font-weight: 400; display: flex; justify-content: left; text-shadow: rgba(0, 0, 0, 0.29) 2px 2px 4px; background-color: rgb(243 243 243 / 90%); border-radius: 10px 10px 0px 0px; border: 1px solid #dbdce1; border-bottom: none; font-size: 14px; height: 40px; cursor: pointer;"
                            @click="loadVueUser(vue.id)"
                        >
                            <div v-if="vue && vue.id && vue.id == 1" style="margin-right: 8px;">
                                <span v-if="count_operations_no_tags > 0" style="font-weight: 800;color: white;background-color: #939393;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ count_operations_no_tags }}</span>
                                <span v-if="count_operations_no_tags == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ count_operations_no_tags }}</span>
                            </div>
                            <div v-if="vue && vue.id && vue.id == 2" style="margin-right: 8px;">
                                <span v-if="count_operations_non_techview  > 0" style="font-weight: 800;color: white;background-color: rgb(230 0 0);border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ count_operations_non_techview }}</span>
                                <span v-if="count_operations_non_techview == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ count_operations_non_techview }}</span>
                            </div>
                            <div v-if="vue && vue.id && vue.id == 3" style="margin-right: 8px;">
                                <span v-if="count_operations_realised > 0" style="font-weight: 800;color: white;background-color: #939393;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ count_operations_realised }}</span>
                                <span v-if="count_operations_realised == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ count_operations_realised }}</span>
                            </div>
                            {{ vue.label }}
                        </div>
                    </div>
                </v-col>
            </v-row>
            <!-- Event book -->
            <div v-if="active_special_vue_user == 0">
                <div class="ma-0 pa-0"> 
                    <div style="height:150px;border-radius:0px 0px 5px 5px;background-color:white;" class="ma-0 pa-0">
                        <div class="col-0" style="color:black;font-weight:800;display:flex;justify-content:center; height:100%;min-width: 8px;border-radius:5px;" >
                            <v-col class="pa-0 pt-8 pb-4" style="display:flex;justify-content:center;align-items:center;">
                                <div style="text-align:center;">  
                                    <v-icon color="primary" style="height:36px;font-size:36px;">
                                        mdi-cog-outline
                                    </v-icon>
                                    <br>
                                    <div class="pa-3 pb-0">
                                        En cours de développement
                                    </div>
                                    <p class="pb-0" style="font-weight:400">
                                        Cette onglet est actuellement en cours de développement et sera disponible prochainement.
                                    </p>
                                </div>
                            </v-col>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Customer events -->
            <div v-if="active_special_vue_user == 1 && count_operations_no_tags != 0">
                <v-data-table
                    :headers="headers"
                    :items="operations_no_tags"
                    class="elevation-1 datatable-operation"
                    :items-per-page="5"
                    :loading="loading_datatable"
                    :search="search"
                    :sort-by="['id']"
                    :sort-desc="[true]"
                    no-data-text="Aucune alerte"
                >
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-layout class="justify-start mt-7">
                                <!-- ADD OPERATION -->
                                <v-btn color="primary" class="white--text mr-3" height="40px"
                                    @click="$refs.modalEditOperation.openDialogAddOperation()">
                                    {{ $t('operation.add_operation') }}
                                </v-btn>

                                <!-- SELECT OPERATION -->
                                <v-select
                                    class="pl-2 pr-2 max-width-500"
                                    :items="operation_types"
                                    :label="$t('operation.select_type')"
                                    v-model="operation_type_selected"
                                    item-text="value"
                                    item-value="id"
                                    @change="getOperations()"
                                    solo chips multiple deletable-chips dense
                                />
                            </v-layout>

                            <!-- SEARCH -->
                            <v-layout class="justify-end mt-7 max-width-300">
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    :label="$t('global.search')"
                                ></v-text-field>
                            </v-layout>

                            <!-- MODALS -->
                            <modal-edit-operation ref="modalEditOperation" :id_machine_active="id" v-on:showSnackbarForModalEditOperation="showSnackbarForModalEditOperation"/>
                            <modal-display-operation-timeline ref="modalDisplayOperationTimeline"/>
                            <modal-add-operation-timeline ref="modalAddOperationTimeline" v-on:showSnackbarForModalAddOperationTimeline="showSnackbarForModalAddOperationTimeline"/>
                            <modal-add-reminder ref="modalAddReminder" v-on:showSnackbarForModalAddReminder="showSnackbarForModalAddReminder"/>
                            <modal-close-operation ref="modalCloseOperation" v-on:showSnackbarForModalCloseOperation="showSnackbarForModalCloseOperation"/>

                            <v-snackbar
                                v-model="snackbar"
                                :color="snackbar_type">
                                {{ snackbar_text }}
                                <template v-slot:action="{ attrs }">
                                    <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                                </template>
                            </v-snackbar>
                        </v-toolbar>

                        <v-layout class="justify-end">
                            <v-btn color="primary" class="mr-3" @click="downloadCsvSubassemblyOperations()">
                                <v-progress-circular
                                    v-if="loading_csv_file" class="mr-2"
                                    color="white" size="20" width="2"
                                />
                                <v-icon v-else left>mdi-download-box-outline</v-icon>
                                {{ $t('global.download') }}
                            </v-btn>
                        </v-layout>

                    </template>

                    <!-- ALERT LEVEL --> 
                    <template v-slot:item.id="{ item }" >
                        <v-col class="col justify-start h-100 pt-7" style="max-width: 60px;">
                            <v-row class="row mb-3 justify-center"> #{{ item.id }} </v-row>   
                            <v-icon :color="assetColor(item)" large v-if="(item.alert_level !== 0 && item.alert_level !== 3 && item.alert_level !== 9)">
                                mdi-alert
                            </v-icon>

                            <v-icon :color="assetColor(item)" large v-if="(item.alert_level === 3)">
                                mdi-help-rhombus
                            </v-icon>

                            <v-icon :color="assetColor(item)" large v-if="(item.alert_level === 9)">
                                mdi-check-circle
                            </v-icon>
                        </v-col>
                    </template>

                    <!-- LABEL --> 
                    <template v-slot:item.label="{ item }">
                        <v-col>
                            <v-row class="pa-1 pt-4 mb-0" style="font-weight: bold;">{{ item.label }}</v-row>
                            <v-row class="pa-1 mb-0 pt-0 pb-0 pl-3 mt-0" style="font-size: 12px;">Envoyé le : {{ item.created_at }}</v-row>
                            <v-row v-if="item.intervener !== null" class="pa-1 mb-0 pt-0 pb-0 pl-3 mt-0" style="font-size: 12px;">Envoyé par : {{ item.intervener.last_name + " " + item.intervener.first_name === 'System Alerting' ? 'Techview' : item.intervener.last_name + " " + item.intervener.first_name }}</v-row>
                            <v-row v-if="!upcoming_operation" class="pa-1 mb-0 pt-0 pl-3 mt-0" style="font-size: 12px;">Clôturé le : {{ item.date_operation_realised }}</v-row>
                            <v-row class="pa-1 mb-1 pt-0 pl-3 mt-0" style="font-size: 12px;">Sous-ensemble concerné :  
                                <router-link class="ml-1" v-if="item.subassembly_id !== 0" :to="{ path: '/subassembly/' + item.subassembly.id }">
                                    {{ item.subassembly.label }}
                                </router-link>
                                <router-link class="ml-1" v-else :to="{ path: '/machine/' + item.id_ref_part_concerned + '/settings'} ">
                                    machine complète
                                </router-link>
                            </v-row>
                            <v-col v-html="item.comment" class="pa-2 mb-0 pl-5 mt-0 mb-6" style="border-left: 1px solid #80808061;font-style: italic;"></v-col>
                            <v-row class="mb-0">
                                <v-row class="pa-1 pa-1 mb-0 mt-0 ml-2">
                                    <v-tooltip top color="red" class="mr-2">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer mr-5"
                                                        v-bind="attrs" v-on="on"
                                                        color="red"
                                                        style="border:1px solid red;border-radius:5px;"
                                                        @click="$refs.modalEditOperation.openDialogSendOperation(item)"
                                                >
                                                    mdi-share
                                                </v-icon>
                                            </template>
                                            <span>Publier l'opération</span>
                                        </v-tooltip>
                                    <v-tooltip top color="primary" class="mr-2">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon class="pointer mr-2"
                                                    v-bind="attrs" v-on="on"
                                                    @click="$refs.modalDisplayOperationTimeline.openDialogViewOperationTimelines(item.id, item.type_operation ? item.type_operation.value : null)"
                                            >
                                                mdi-eye
                                            </v-icon>
                                        </template>
                                        <span>{{ $t('operation.operation_history') }}</span>
                                    </v-tooltip>
                                    <template v-if="display_btn_operation_not_end">
                                        <v-tooltip top color="primary" class="mr-2">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer mr-2"
                                                        v-bind="attrs" v-on="on"
                                                        @click="$refs.modalEditOperation.openDialogUpdateOperation(item)"
                                                >
                                                    mdi-pencil
                                                </v-icon>
                                            </template>
                                            <span>{{ $t('operation.update_operation') }}</span>
                                        </v-tooltip>
                                        <v-tooltip top color="primary" class="mr-2">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer mr-2"
                                                        v-bind="attrs" v-on="on"
                                                        @click="$refs.modalAddOperationTimeline.openDialogAddOperationTimeline(item.id, 3)"
                                                >
                                                    mdi-comment-plus-outline
                                                </v-icon>
                                            </template>
                                            <span>{{ $t('operation.add_message') }}</span>
                                        </v-tooltip>
                                        <v-tooltip top color="primary" class="mr-2">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer mr-2"
                                                        v-bind="attrs" v-on="on"
                                                        @click="$refs.modalAddOperationTimeline.openDialogAddOperationTimeline(item.id, 4)"
                                                >
                                                    mdi-account-cog-outline
                                                </v-icon>
                                            </template>
                                            <span>{{ $t('operation.expert_demand') }}</span>
                                        </v-tooltip>
                                        <v-tooltip top color="primary" class="mr-2">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer mr-2"
                                                        v-bind="attrs" v-on="on"
                                                        @click="$refs.modalAddReminder.openDialogAddReminder(item.id, item.date_reminder)"
                                                >
                                                    mdi-calendar-plus
                                                </v-icon>
                                            </template>
                                            <span>{{ $t('operation.plan_reminder') }}</span>
                                        </v-tooltip>
                                        <v-tooltip top color="primary" class="mr-2">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer mr-2"
                                                        v-bind="attrs" v-on="on"
                                                        @click="$refs.modalCloseOperation.openDialogCloseOperation(item.id)"
                                                >
                                                    mdi-archive-arrow-down
                                                </v-icon>
                                            </template>
                                            <span>{{ $t('operation.close_operation') }}</span>
                                        </v-tooltip>
                                    </template>
                                </v-row>
                            </v-row>
                        </v-col>
                    </template>

                    <!-- TAGS --> 
                    <template v-slot:item.tags="{ item }">
                        <v-chip-group>
                            <v-chip v-if="item.tags.length === 0" color="grey">{{ $t('global.no_tags_associated') }}</v-chip>
                            <v-chip v-for="tag in item.tags" class="pl-3 pr-3" link :key="tag.id">
                                {{ tag.label }}
                            </v-chip>
                        </v-chip-group>
                    </template>
                </v-data-table>
            </div>
            <!-- No Customer events -->
            <div v-if="active_special_vue_user == 1 && count_operations_no_tags == 0">
                <div class="ma-0 pa-0"> 
                    <div style="height:150px;border-radius:0px 0px 5px 5px;background-color:white;" class="ma-0 pa-0">
                        <div class="col-0" style="color:black;font-weight:800;display:flex;justify-content:center; height:100%;min-width: 8px;border-radius:5px;" >
                            <v-col class="pa-0 pt-8 pb-4" style="display:flex;justify-content:center;align-items:center;">
                                <div style="text-align:center;opacity:0.5;">  
                                    <v-icon color="green" style="height:36px;font-size:36px;">
                                        mdi-check
                                    </v-icon>
                                    <br>
                                    <div class="pa-3 pb-0">
                                        Aucune alerte
                                    </div>
                                    <p class="pb-0" style="font-weight:400">
                                        Aucune alerte pour cet équipement.
                                    </p>
                                </div>
                            </v-col>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Published events -->
            <div v-if="active_special_vue_user == 2 && count_operations_non_techview != 0">
                <v-data-table
                    :headers="headers"
                    :items="operations_non_techview"
                    class="elevation-1 datatable-operation"
                    :items-per-page="5"
                    :loading="loading_datatable"
                    :search="search"
                    :sort-by="['id']"
                    :sort-desc="[true]"
                    no-data-text="Aucune alerte"
                >
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-layout class="justify-start mt-7">
                                <!-- ADD OPERATION -->
                                <v-btn color="primary" class="white--text mr-3" height="40px"
                                    @click="$refs.modalEditOperation.openDialogAddOperation()">
                                    {{ $t('operation.add_operation') }}
                                </v-btn>

                                <!-- SELECT OPERATION -->
                                <v-select
                                    class="pl-2 pr-2 max-width-500"
                                    :items="operation_types"
                                    :label="$t('operation.select_type')"
                                    v-model="operation_type_selected"
                                    item-text="value"
                                    item-value="id"
                                    @change="getOperations()"
                                    solo chips multiple deletable-chips dense
                                />
                            </v-layout>

                            <!-- SEARCH -->
                            <v-layout class="justify-end mt-7 max-width-300">
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    :label="$t('global.search')"
                                ></v-text-field>
                            </v-layout>

                            <!-- MODALS -->
                            <modal-edit-operation ref="modalEditOperation" :id_machine_active="id" v-on:showSnackbarForModalEditOperation="showSnackbarForModalEditOperation"/>
                            <modal-display-operation-timeline ref="modalDisplayOperationTimeline"/>
                            <modal-add-operation-timeline ref="modalAddOperationTimeline" v-on:showSnackbarForModalAddOperationTimeline="showSnackbarForModalAddOperationTimeline"/>
                            <modal-add-reminder ref="modalAddReminder" v-on:showSnackbarForModalAddReminder="showSnackbarForModalAddReminder"/>
                            <modal-close-operation ref="modalCloseOperation" v-on:showSnackbarForModalCloseOperation="showSnackbarForModalCloseOperation"/>

                            <v-snackbar
                                v-model="snackbar"
                                :color="snackbar_type">
                                {{ snackbar_text }}
                                <template v-slot:action="{ attrs }">
                                    <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                                </template>
                            </v-snackbar>
                        </v-toolbar>

                        <v-layout class="justify-end">
                            <v-btn color="primary" class="mr-3" @click="downloadCsvSubassemblyOperations()">
                                <v-progress-circular
                                    v-if="loading_csv_file" class="mr-2"
                                    color="white" size="20" width="2"
                                />
                                <v-icon v-else left>mdi-download-box-outline</v-icon>
                                {{ $t('global.download') }}
                            </v-btn>
                        </v-layout>

                    </template>

                    <!-- ALERT LEVEL --> 
                    <template v-slot:item.id="{ item }" >
                        <v-col class="col justify-start h-100 pt-7" style="max-width: 60px;">
                            <v-row class="row mb-3 justify-center"> #{{ item.id }} </v-row>   
                            <v-icon :color="assetColor(item)" large v-if="(item.alert_level !== 0 && item.alert_level !== 3 && item.alert_level !== 9)">
                                mdi-alert
                            </v-icon>

                            <v-icon :color="assetColor(item)" large v-if="(item.alert_level === 3)">
                                mdi-help-rhombus
                            </v-icon>

                            <v-icon :color="assetColor(item)" large v-if="(item.alert_level === 9)">
                                mdi-check-circle
                            </v-icon>
                        </v-col>
                    </template>

                    <!-- LABEL --> 
                    <template v-slot:item.label="{ item }">
                        <v-col>
                            <v-row class="pa-1 pt-4 mb-0" style="font-weight: bold;">{{ item.label }}</v-row>
                            <v-row class="pa-1 mb-0 pt-0 pb-0 pl-3 mt-0" style="font-size: 12px;">Envoyé le : {{ item.created_at }}</v-row>
                            <v-row v-if="item.intervener !== null" class="pa-1 mb-0 pt-0 pb-0 pl-3 mt-0" style="font-size: 12px;">Envoyé par : {{ item.intervener.last_name + " " + item.intervener.first_name === 'System Alerting' ? 'Techview' : item.intervener.last_name + " " + item.intervener.first_name }}</v-row>
                            <v-row v-if="!upcoming_operation" class="pa-1 mb-0 pt-0 pl-3 mt-0" style="font-size: 12px;">Clôturé le : {{ item.date_operation_realised }}</v-row>
                            <v-row class="pa-1 mb-1 pt-0 pl-3 mt-0" style="font-size: 12px;">Sous-ensemble concerné :  
                                <router-link class="ml-1" v-if="item.subassembly_id !== 0" :to="{ path: '/subassembly/' + item.subassembly.id }">
                                    {{ item.subassembly.label }}
                                </router-link>
                                <router-link class="ml-1" v-else :to="{ path: '/machine/' + item.id_ref_part_concerned + '/settings'} ">
                                    machine complète
                                </router-link>
                            </v-row>
                            <v-col v-html="item.comment" class="pa-2 mb-0 pl-5 mt-0 mb-6" style="border-left: 1px solid #80808061;font-style: italic;"></v-col>
                            <v-row class="mb-0">
                                <v-row class="pa-1 pa-1 mb-0 mt-0 ml-2">
                                    <v-tooltip top color="red" class="mr-2">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer mr-5"
                                                        v-bind="attrs" v-on="on"
                                                        color="red"
                                                        style="border:1px solid red;border-radius:5px;"
                                                        @click="$refs.modalEditOperation.openDialogSendOperation(item)"
                                                >
                                                    mdi-share
                                                </v-icon>
                                            </template>
                                            <span>Publier l'opération</span>
                                        </v-tooltip>
                                    <v-tooltip top color="primary" class="mr-2">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon class="pointer mr-2"
                                                    v-bind="attrs" v-on="on"
                                                    @click="$refs.modalDisplayOperationTimeline.openDialogViewOperationTimelines(item.id, item.type_operation ? item.type_operation.value : null)"
                                            >
                                                mdi-eye
                                            </v-icon>
                                        </template>
                                        <span>{{ $t('operation.operation_history') }}</span>
                                    </v-tooltip>
                                    <template v-if="display_btn_operation_not_end">
                                        <v-tooltip top color="primary" class="mr-2">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer mr-2"
                                                        v-bind="attrs" v-on="on"
                                                        @click="$refs.modalEditOperation.openDialogUpdateOperation(item)"
                                                >
                                                    mdi-pencil
                                                </v-icon>
                                            </template>
                                            <span>{{ $t('operation.update_operation') }}</span>
                                        </v-tooltip>
                                        <v-tooltip top color="primary" class="mr-2">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer mr-2"
                                                        v-bind="attrs" v-on="on"
                                                        @click="$refs.modalAddOperationTimeline.openDialogAddOperationTimeline(item.id, 3)"
                                                >
                                                    mdi-comment-plus-outline
                                                </v-icon>
                                            </template>
                                            <span>{{ $t('operation.add_message') }}</span>
                                        </v-tooltip>
                                        <v-tooltip top color="primary" class="mr-2">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer mr-2"
                                                        v-bind="attrs" v-on="on"
                                                        @click="$refs.modalAddOperationTimeline.openDialogAddOperationTimeline(item.id, 4)"
                                                >
                                                    mdi-account-cog-outline
                                                </v-icon>
                                            </template>
                                            <span>{{ $t('operation.expert_demand') }}</span>
                                        </v-tooltip>
                                        <v-tooltip top color="primary" class="mr-2">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer mr-2"
                                                        v-bind="attrs" v-on="on"
                                                        @click="$refs.modalAddReminder.openDialogAddReminder(item.id, item.date_reminder)"
                                                >
                                                    mdi-calendar-plus
                                                </v-icon>
                                            </template>
                                            <span>{{ $t('operation.plan_reminder') }}</span>
                                        </v-tooltip>
                                        <v-tooltip top color="primary" class="mr-2">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer mr-2"
                                                        v-bind="attrs" v-on="on"
                                                        @click="$refs.modalCloseOperation.openDialogCloseOperation(item.id)"
                                                >
                                                    mdi-archive-arrow-down
                                                </v-icon>
                                            </template>
                                            <span>{{ $t('operation.close_operation') }}</span>
                                        </v-tooltip>
                                    </template>
                                </v-row>
                            </v-row>
                        </v-col>
                    </template>

                    <!-- TAGS --> 
                    <template v-slot:item.tags="{ item }">
                        <v-chip-group>
                            <v-chip v-if="item.tags.length === 0" color="grey">{{ $t('global.no_tags_associated') }}</v-chip>
                            <v-chip v-for="tag in item.tags" class="pl-3 pr-3" link :key="tag.id">
                                {{ tag.label }}
                            </v-chip>
                        </v-chip-group>
                    </template>
                </v-data-table>
            </div>
            <!-- No Published events -->
            <div v-if="active_special_vue_user == 2 && count_operations_non_techview == 0">
                <div class="ma-0 pa-0"> 
                    <div style="height:150px;border-radius:0px 0px 5px 5px;background-color:white;" class="ma-0 pa-0">
                        <div class="col-0" style="color:black;font-weight:800;display:flex;justify-content:center; height:100%;min-width: 8px;border-radius:5px;" >
                            <v-col class="pa-0 pt-8 pb-4" style="display:flex;justify-content:center;align-items:center;">
                                <div style="text-align:center;opacity:0.5;">  
                                    <v-icon color="green" style="height:36px;font-size:36px;">
                                        mdi-check
                                    </v-icon>
                                    <br>
                                    <div class="pa-3 pb-0">
                                        Aucune alerte
                                    </div>
                                    <p class="pb-0" style="font-weight:400">
                                        Aucune alerte pour cet équipement.
                                    </p>
                                </div>
                            </v-col>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Released events -->
            <div v-if="active_special_vue_user == 3 && count_operations_realised != 0">
                <v-data-table
                    :headers="headers"
                    :items="operations_realised"
                    class="elevation-1 datatable-operation"
                    :items-per-page="5"
                    :loading="loading_datatable"
                    :search="search"
                    :sort-by="['id']"
                    :sort-desc="[true]"
                    no-data-text="Aucune alerte"
                >
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-layout class="justify-start mt-7">
                                <!-- ADD OPERATION -->
                                <v-btn color="primary" class="white--text mr-3" height="40px"
                                    @click="$refs.modalEditOperation.openDialogAddOperation()">
                                    {{ $t('operation.add_operation') }}
                                </v-btn>

                                <!-- SELECT OPERATION -->
                                <v-select
                                    class="pl-2 pr-2 max-width-500"
                                    :items="operation_types"
                                    :label="$t('operation.select_type')"
                                    v-model="operation_type_selected"
                                    item-text="value"
                                    item-value="id"
                                    @change="getOperationsClose()"
                                    solo chips multiple deletable-chips dense
                                />
                            </v-layout>

                            <!-- SEARCH -->
                            <v-layout class="justify-end mt-7 max-width-300">
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    :label="$t('global.search')"
                                ></v-text-field>
                            </v-layout>

                            <!-- MODALS -->
                            <modal-edit-operation ref="modalEditOperation" :id_machine_active="id" v-on:showSnackbarForModalEditOperation="showSnackbarForModalEditOperation"/>
                            <modal-display-operation-timeline ref="modalDisplayOperationTimeline"/>
                            <modal-add-operation-timeline ref="modalAddOperationTimeline" v-on:showSnackbarForModalAddOperationTimeline="showSnackbarForModalAddOperationTimeline"/>
                            <modal-add-reminder ref="modalAddReminder" v-on:showSnackbarForModalAddReminder="showSnackbarForModalAddReminder"/>
                            <modal-close-operation ref="modalCloseOperation" v-on:showSnackbarForModalCloseOperation="showSnackbarForModalCloseOperation"/>

                            <v-snackbar
                                v-model="snackbar"
                                :color="snackbar_type">
                                {{ snackbar_text }}
                                <template v-slot:action="{ attrs }">
                                    <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                                </template>
                            </v-snackbar>
                        </v-toolbar>

                        <v-layout class="justify-end">
                            <v-btn color="primary" class="mr-3" @click="downloadCsvSubassemblyOperations()">
                                <v-progress-circular
                                    v-if="loading_csv_file" class="mr-2"
                                    color="white" size="20" width="2"
                                />
                                <v-icon v-else left>mdi-download-box-outline</v-icon>
                                {{ $t('global.download') }}
                            </v-btn>
                        </v-layout>

                    </template>

                    <!-- ALERT LEVEL --> 
                    <template v-slot:item.id="{ item }" >
                        <v-col class="col justify-start h-100 pt-7" style="max-width: 60px;">
                            <v-row class="row mb-3 justify-center"> #{{ item.id }} </v-row>   
                            <v-icon :color="assetColor(item)" large v-if="(item.alert_level !== 0 && item.alert_level !== 3 && item.alert_level !== 9)">
                                mdi-alert
                            </v-icon>

                            <v-icon :color="assetColor(item)" large v-if="(item.alert_level === 3)">
                                mdi-help-rhombus
                            </v-icon>

                            <v-icon :color="assetColor(item)" large v-if="(item.alert_level === 9)">
                                mdi-check-circle
                            </v-icon>
                        </v-col>
                    </template>

                    <!-- INFOS --> 
                    <template v-slot:item.label="{ item }">
                        <v-col>
                            <v-row class="pa-1 pt-4 mb-0" style="font-weight: bold;">{{ item.label }}</v-row>
                            <v-row class="pa-1 mb-0 pt-0 pb-0 pl-3 mt-0" style="font-size: 12px;">Envoyé le : {{ item.date_operation_realised }}</v-row>
                            <v-row v-if="item.intervener !== null" class="pa-1 mb-0 pt-0 pb-0 pl-3 mt-0" style="font-size: 12px;">Envoyé par : {{ item.intervener.last_name + " " + item.intervener.first_name === 'System Alerting' ? 'Techview' : item.intervener.last_name + " " + item.intervener.first_name }}</v-row>
                            <v-row v-if="!upcoming_operation" class="pa-1 mb-0 pt-0 pl-3 mt-0" style="font-size: 12px;">Clôturé le : {{ item.date_operation_realised }}</v-row>
                            <v-row class="pa-1 mb-1 pt-0 pl-3 mt-0" style="font-size: 12px;">Sous-ensemble concerné :  
                                <router-link class="ml-1" v-if="item.subassembly_id !== 0" :to="{ path: '/subassembly/' + item.subassembly.id }">
                                    {{ item.subassembly.label }}
                                </router-link>
                                <router-link class="ml-1" v-else :to="{ path: '/machine/' + item.id_ref_part_concerned + '/settings'} ">
                                    machine complète
                                </router-link>
                            </v-row>
                            <v-col v-html="item.comment" class="pa-2 mb-0 pl-5 mt-0 mb-6" style="border-left: 1px solid #80808061;font-style: italic;"></v-col>
                            <v-row class="mb-0">
                                <v-row class="pa-1 pa-1 mb-0 mt-0 ml-2">
                                    <v-tooltip top color="red" class="mr-2">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer mr-5"
                                                        v-bind="attrs" v-on="on"
                                                        color="red"
                                                        style="border:1px solid red;border-radius:5px;"
                                                        @click="$refs.modalEditOperation.openDialogSendOperation(item)"
                                                >
                                                    mdi-share
                                                </v-icon>
                                            </template>
                                            <span>Publier l'opération</span>
                                        </v-tooltip>
                                    <v-tooltip top color="primary" class="mr-2">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon class="pointer mr-2"
                                                    v-bind="attrs" v-on="on"
                                                    @click="$refs.modalDisplayOperationTimeline.openDialogViewOperationTimelines(item.id, item.type_operation ? item.type_operation.value : null)"
                                            >
                                                mdi-eye
                                            </v-icon>
                                        </template>
                                        <span>{{ $t('operation.operation_history') }}</span>
                                    </v-tooltip>
                                </v-row>
                            </v-row>
                        </v-col>
                    </template>

                    <!-- TAGS --> 
                    <template v-slot:item.tags="{ item }">
                        <v-chip-group>
                            <v-chip v-if="item.tags.length === 0" color="grey">{{ $t('global.no_tags_associated') }}</v-chip>
                            <v-chip v-for="tag in item.tags" class="pl-3 pr-3" link :key="tag.id">
                                {{ tag.label }}
                            </v-chip>
                        </v-chip-group>
                    </template>
                </v-data-table>
            </div>
            <!-- No Released events -->
            <div v-if="active_special_vue_user == 3 && count_operations_realised == 0">
                <div class="ma-0 pa-0"> 
                    <div style="height:150px;border-radius:0px 0px 5px 5px;background-color:white;" class="ma-0 pa-0">
                        <div class="col-0" style="color:black;font-weight:800;display:flex;justify-content:center; height:100%;min-width: 8px;border-radius:5px;" >
                            <v-col class="pa-0 pt-8 pb-4" style="display:flex;justify-content:center;align-items:center;">
                                <div style="text-align:center;opacity:0.5;">  
                                    <v-icon color="green" style="height:36px;font-size:36px;">
                                        mdi-check
                                    </v-icon>
                                    <br>
                                    <div class="pa-3 pb-0">
                                        Aucune alerte
                                    </div>
                                    <p class="pb-0" style="font-weight:400">
                                        Aucune alerte pour cet équipement.
                                    </p>
                                </div>
                            </v-col>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {DOWNLOAD_CSV_SUBASSEMBLY_OPERATIONS, SUBASSEMBLIES, REFERENTIAL_GLOBALS, MACHINES} from "../../api";
import moment from 'moment';
import ModalEditOperation from "./Subassembly/Operation/ModalEditOperation";
import ModalDisplayOperationTimeline from "./Subassembly/Operation/ModalDisplayOperationTimeline";
import ModalAddOperationTimeline from "./Subassembly/Operation/ModalAddOperationTimeline";
import ModalCloseOperation from "./Subassembly/Operation/ModalCloseOperation";
import ModalAddReminder from "./Subassembly/Operation/ModalAddReminder";

export default {
    name: "DatatableOperationsV2",
    components: {
        ModalEditOperation,
        ModalDisplayOperationTimeline,
        ModalAddOperationTimeline,
        ModalCloseOperation,
        ModalAddReminder
    },
    data() {
        return {
            // Onglets
            access_level: this.$store.state.access_level,
            vues:[
                {id:0, label: "Journal des évènements"},
                {id:6, label: "Evènements externes"},
                {id:1, label: "Alertes publiées"},
                {id:2, label: "Alertes en cours"},
                {id:3, label: "Alertes traitées"},
                {id:4, label: "Quarantaine"},
                {id:5, label: "Alertes clôturées"},
            ],
            vuesUser:[
                {id:0, label: "Journal des évènements"},
                {id:1, label: "Evènements externes"},
                {id:2, label: "Alertes en cours"},
                {id:3, label: "Alertes clôturées"},
            ],
            active_special_vue: 2,
            active_special_vue_user: 2,

            filteredTagTechview: [],
            filteredWithoutTechview: [],

            operations_techview: [],
            operations_non_techview: [],
            operations_no_tags: [],
            count_operations_techview: 0,
            count_operations_non_techview: 0,
            count_operations_no_tags: 0,
            operations_realised:[],
            count_operations_realised: 0,


            operations: [],
            operations_stats:[],
            MVAlert_level:0,
            MVAlert:[],
            MVAlert_total:"",
            MVAlert_enc:"",
            MVAlert_color:"",
            getOperationsFirstTime: false,
            operation_type: null,
            headers: [
                {text: "ID", value: 'id', width: '92px'},
                {text: "Informations", value: 'label', width:'80%'},
                this.$store.state.access_level <= 499 ? {
                    text: this.$t('global.tags'),
                    value: 'tags'
                } : {align: ' d-none'},
            ],
            loading_datatable: true,
            search: '',
            upcoming_operation: true,
            display_btn_operation_not_end: true,

            operation_type_selected: [],
            operation_types: [],

            loading_csv_file: false,
            alert_level_groups: [
                { group: "Autres", levels: [-1] },
                { group: "Information", levels: [1, 2, 3, 9] },
                { group: "À suivre", levels: [11, 12, 13] },
                { group: "Importance élevée", levels: [21, 22, 23] },
                { group: "Urgence", levels: [31, 32] }
            ],
            selected_alert_groups: [],
            filtered_operations: [],
            operationFilter: 'all',
            operation_level_selected: [],
        }
    },
    created() {
        this.getOperationsOpen();
        this.getOperationsClose();
    },
    methods: {
        // REQUESTS
        getOperationsOpen() {
            this.loading_datatable = true;
            this.axios.get(MACHINES + "/" + this.$route.params.id + "/operations?upcoming_operation=true&types=" + this.operation_type_selected)
            
            .then((success) => {
                let filteredOperations = success.data;

                this.operations_techview = [];
                this.operations_non_techview = [];
                this.operations_no_tags = [];
                this.count_operations_techview = 0;
                this.count_operations_non_techview = 0;
                this.count_operations_no_tags = 0;

                filteredOperations.forEach(operation => {
                    operation.date_operation = moment(operation.date_operation).format('DD/MM/YYYY H:mm');
                    operation.created_at = moment(operation.created_at).format('DD/MM/YYYY H:mm');
                    operation.updated_at = moment(operation.updated_at).format('DD/MM/YYYY H:mm');

                    // New events
                    // getting operations with techview tag
                    let f = false;
                    operation.tags.forEach(tag => {
                        if (tag.label === "techview" && !f && operation.date_operation_realised == null) {
                            this.operations_techview.push(operation);
                            this.count_operations_techview = this.count_operations_techview +1; 
                            f = true;
                        }
                    });

                    // Customer events
                    // getting operations without any tags but not about reports published
                    if (!f && (!operation.tags || operation.tags.length === 0 || operation.tags[0] == undefined) && operation.id_ref_type_operation != 47 && operation.date_operation_realised == null) {
                        this.operations_no_tags.push(operation);
                        this.count_operations_no_tags = this.count_operations_no_tags +1;
                    }

                    // Published events
                    // getting operations with one tag or more and no techview tag or named Rapport publié
                    if ((!f && operation.tags[0] && operation.date_operation_realised == null) || (operation.id_ref_type_operation == 47 && operation.date_operation_realised == null)) {
                        this.operations_non_techview.push(operation);
                        this.count_operations_non_techview = this.count_operations_non_techview +1;
                    }
                });

                this.operations = filteredOperations;
            
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            
            }).finally(() => {
                this.stats();
                this.loading_datatable = false
            });
        },
        getOperationsClose() {
            this.loading_datatable = true;

            this.axios.get(
                MACHINES + "/" + this.$route.params.id + "/operations?upcoming_operation=false&types=" + this.operation_type_selected)
            
            .then((success) => {
                let filteredOperations = success.data;
                this.count_operations_realised = 0;
                this.operations_realised = [];

                filteredOperations.forEach(operation => {
                    operation.date_operation = moment(operation.date_operation).format('DD/MM/YYYY H:mm');
                    operation.date_operation_realised = moment(operation.date_operation_realised).format('DD/MM/YYYY H:mm');
                    
                    if (operation.date_operation_realised != null) {
                        this.operations_realised.push(operation);
                        this.count_operations_realised = this.count_operations_realised +1;
                    }
                });

                this.operations_realised = filteredOperations;

            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));

            }).finally(() => {
                this.stats();
                this.loading_datatable = false
            });
        },
        getOperationTypes() {
            this.axios.get(
                REFERENTIAL_GLOBALS + '?type=type_operation'
            ).then((success) => {
                this.operation_types = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            });
        },

        // ALGO
        filterOperationsClose() {
            this.loading_datatable = true;
            this.getOperationsClose();
        },
        filterOperationsOpen() {
            this.loading_datatable = true;
            this.getOperationsOpen();
        },
        stats(){
            if (this.getOperationsFirstTime == false){
                this.MVAlert_level = 0;
                this.MVAlert_enc = 0;

                this.operations_non_techview.forEach(operation => {
                    this.MVAlert_enc = this.MVAlert_enc + 1;
                    
                    if (this.MVAlert_level < operation.alert_level) {
                        this.MVAlert_level = operation.alert_level;
                        this.MVAlert = operation;
                    }
                });

                this.getOperationsFirstTime = true;
            }

        },

        // OTHER
        downloadCsvSubassemblyOperations() {
            this.loading_csv_file = true;

            this.axios({
                url: DOWNLOAD_CSV_SUBASSEMBLY_OPERATIONS + this.$route.params.id + "/operations?upcoming_operation=" + this.upcoming_operation,
                method: 'GET',
                responseType: 'blob',
            }).then((success) => {
                this.downloadCsvFile(success.data, "_subassembly_" + this.$route.params.id + "_operations.csv");
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_download'))
            }).finally(() => {
                this.loading_csv_file = false;
            });
        },
        showSnackbarForModalEditOperation(item) {
            this.showSnackbar(item.type, item.text);
            this.$emit('updateSubassemblyDevices')
            this.getOperations();
        },
        showSnackbarForModalAddOperationTimeline(item) {
            this.showSnackbar(item.type, item.text);
            this.getOperations();
        },
        showSnackbarForModalCloseOperation(item) {
            this.showSnackbar(item.type, item.text);
            this.$emit('updateSubassemblyDevices');
            this.getOperationsOpen();
        },
        showSnackbarForModalAddReminder(item) {
            this.showSnackbar(item.type, item.text);
            this.getOperations();
        },
        filteredAlerts() {
            this.filteredTagTechview = this.operations.filter(op => 
                op.tags && op.tags.includes("techview")
            );
            console.log("Tag techview :", filteredTagTechview)

            this.filteredWithoutTechview = this.operations.filter(op => 
                !op.tags || !op.tags.includes("techview")
            );
            console.log("Sans tag techview :", filteredWithoutTechview)
        },

        // ONGLETS
        loadVue(id) {
            this.active_special_vue = id;

            if (id === 0 || id === 1 || id === 2 || id === 3 || id === 4 || id === 6) {
                this.filterOperationsOpen();
            }

            if (id === 5) {
                this.filterOperationsClose();
            }
        },
        loadVueUser(id) {
            this.active_special_vue_user = id;

            if (id === 0 || id === 1 || id === 2) {
                this.filterOperationsOpen();
            }

            if (id === 3) {
                this.filterOperationsClose();
            }
        },
    }
}
</script>

<style scoped>
.toolbar-container {
    margin-top: 2px;
}

.datatable-operation {
    min-height: 415px;
}

.max-width-300 {
    max-width: 300px !important;
}

.max-width-500 {
    max-width: 510px !important;
}
</style>
