<template>
    <v-row class="row mt-0">
        <!-- MACHINE MENU -->
        <v-col class="col-1 pt-5 pb-5 rounded" style="height: 110%;background-color: #014181;max-width: 70px;position: fixed;top: -20px;z-index: 1;">
            <!-- REPORTS -->
            <v-tooltip bottom dark class="mr-2">
                <template v-slot:activator="{ on }">
                    <v-btn class="ma-2 mt-10 mb-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/report'}" dusk="logout-button">
                        <v-icon color="white">mdi-chart-box</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('machine.reports') }}</span>
            </v-tooltip>
            <!-- ALERTS -->
            <v-tooltip bottom dark class="mr-2">
                <template v-slot:activator="{ on }">
                    <v-btn class="ma-2 mt-5 mb-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/events'}" dusk="logout-button">
                        <v-icon color="white">mdi-alert</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('machine.alerts') }}</span>
            </v-tooltip>
            <!-- SETTINGS -->
            <v-tooltip bottom dark class="mr-2">
                <template v-slot:activator="{ on }">
                    <v-btn class="ma-2 mt-5 mb-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/settings'}" dusk="logout-button">
                        <v-icon color="white">mdi-cog</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('machine.settings') }}</span>
            </v-tooltip bottom dark class="mr-2">
            <!-- DETAILS -->
            <v-tooltip v-if="access_level <= 100 || access_level == 200 || access_level === 300" bottom dark class="mr-2">
                <template v-slot:activator="{ on }">
                    <v-btn class="ma-2 mt-5 mb-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/details'}" dusk="logout-button">
                        <v-icon color="white">mdi-chart-line</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('machine.courbes') }}</span>
            </v-tooltip>
        </v-col>        
        
        <!-- CONTENT -->
        <v-col class="col pa-0 pl-0 pr-5 pl-5" style="margin-left: 70px;">
            <!-- TOP TITLE -->
            <v-row class="row mt-0">
                <!-- PAGE TITLE -->
                <v-subheader class="v-subheader col theme--light pl-5 ml-2">
                    <p class="title mb-0 color-primary text-uppercase">
                        {{ $t('machines.reports') }}
                    </p>
                    <!------------------- Info bulle ---------------------->
                    <v-tooltip bottom dark class="mr-2">
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                                <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:default>
                        <div style="min-width: 200px;z-index: 100;">
                            <strong>{{ $t('report.tooltip_page_title') }}</strong>
                            <p>{{ $t('report.tooltip_page_title_content1') }}</br>
                                {{ $t('report.tooltip_page_title_content2') }}
                            </p>
                        </div>
                        </template>
                    </v-tooltip>
                </v-subheader>

                <!-- FORMAT SELECTOR -->
                <v-row cols="2" class="v-subheader col-1 theme--light mr-3">
                    <!-- SPACER -->
                    <v-col class="col"></v-col>
                </v-row>
            </v-row>
            <!-- STATE DATAS -->
            <v-col class="row col-12 mb-0 pa-0">
                <!-- COUNTER -->
                <v-col color="white" class="col-auto pa-0 mt-5">
                    <v-col class="col pt-0 pb-0 mr-10 rounded col ml-6" style="height: 35px;background-color: rgb(224 232 240);font-weight: bold;text-align: center;font-size: 16px;border: 1px solid #014181;line-height: 35px;color: #014181;">
                       {{ machine.label }}
                    </v-col>
                </v-col>

                <!-- GLOBAL ACTIONS -->
                <v-col class="row col ml-6 pt-8">
                    <v-tooltip bottom dark class="mr-2">
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" @click="getData()" class="mr-2" dusk="logout-button" style="border: 1px solid #01418145;">
                                <v-icon color="primary">mdi-tag-multiple</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('global.1_5_2') }}</span>
                    </v-tooltip>
                    <v-tooltip bottom dark class="mr-2">
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" @click="getData()" class="mr-2" dusk="logout-button" style="border: 1px solid #01418145;">
                                <v-icon color="primary">mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('machines.refresh') }}</span>
                    </v-tooltip>
                    <!------------------- Info bulle ---------------------->
                    <v-tooltip bottom dark class="mr-2">
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                                <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:default>
                        <div style="min-width: 200px;">
                            <p>{{ $t('report.tooltip_pdf1') }}</br>
                                {{ $t('report.tooltip_pdf2') }}
                            </p>
                        </div>
                        </template>
                    </v-tooltip>
                </v-col>

                <!-- SPACER -->
                <v-col class="row col"></v-col> 
            </v-col>

            <!-- MACHINE REPORTS CONTENT -->
            <v-col>
                <!-- DIGITAL REPORT -->
                <v-col v-if="report.type === 0 && report_available && report.step === 5 && access_level > 99" color="white" class="pa-0 pt-1 col rounded" style="margin-right:410px;">
                    <div v-if="loading_datatable == true"  style="color:grey;text-align: center;">Chargement en cours...</div>            
                    
                    <div v-if="loading_datatable == false" flat color="white" class="pt-0 mb-3">
                        <!-- REPORT HEADER -->
                        <v-row style="background-color:#1e1e1e;margin:0">
                            <v-col class="col pl-5 pr-5 pb-5">
                                <v-row style="display: flex;align-items: center;color:#ffffffe0;">
                                    <v-col class="col pb-0 pt-0">
                                        <p style="font-size: 14px; position: relative; top: 14px; background-color: #1e1e1e; z-index: 10; margin-left: 15px; padding: 0px 5px; display: inline-block;">
                                            {{ $t('report.campain') }}
                                        </p>
                                        <div link :to="'/machine/' + machine.id" style="border: 1px solid #bdbdbd45; padding: 10px; border-radius: 5px; margin: 0px 0px; font-size: 16px;">
                                            <v-chip class="mt-2" v-if="campain_available === true" v-on="on" link :to="'/campain/' + campain.id" style="font-weight: bold; background-color: #f3f3f3; border: 1px solid #e5e5e5;">{{campain.name}} ( Ref - {{ campain.affaire_number }} )</v-chip>
                                            <v-chip class="mt-2" v-if="campain_available === false" v-on="on" link :to="'/campain/' + campain.id" style="font-weight: bold; background-color: #f3f3f3; border: 1px solid #e5e5e5;">Aucune campagne associée.</v-chip>
                                            <div style="font-weight: 300;font-size: 12px;"></div>
                                        </div>
                                    </v-col>
                                    
                                    <div v-for="analyst in analysts" :key="analyst">
                                        <v-col class="col pb-0" v-if="analyst.id == report.analyst">
                                            <p style="position: relative; top: 14px; background-color: #1e1e1e; z-index: 10; margin-left: 15px; padding: 0px 5px; display: inline-block;font-size:14px">
                                                {{ $t('report.analyst') }}
                                            </p>
                                            <div style="border: 1px solid #bdbdbd45;padding: 10px;border-radius: 5px; margin: 0px 0px;">
                                                {{ analyst.first_name }} {{  analyst.last_name }}
                                            </div>
                                        </v-col>
                                    </div>

                                    <div v-for="analyst in analysts" :key="analyst.id">
                                        <v-col class="col pb-0" v-if="analyst.id == report.validator">
                                            <p style="position: relative; top: 14px; background-color: #1e1e1e; z-index: 10; margin-left: 15px; padding: 0px 5px; display: inline-block;font-size:14px">
                                                {{ $t('report.validator') }}
                                            </p> 
                                            <div style="border: 1px solid #bdbdbd45;padding: 10px;border-radius: 5px; margin: 0px 0px;">
                                                {{ analyst.first_name }} {{  analyst.last_name }}
                                            </div>
                                        </v-col>
                                    </div>
                                </v-row>

                                <!-- SUBASSEMBLIES -->
                                <p style="font-size: 14px; position: relative; top: 14px; background-color: #1e1e1e; z-index: 10; margin-left: 15px; padding: 0px 5px; display: inline-block;color:#ffffffe0;">
                                    {{ $t('report.subassembly_equipment') }}
                                </p>
                                <v-row class="ma-0" style="border: 1px solid #bdbdbd45; border-radius: 5px">
                                    <div v-for="subassembly in machine.subassemblies" :key="subassembly.id" style="padding: 10px; margin: 0px 0px;">
                                        <v-chip style="font-size: 12px" class="mr-2 mt-1">
                                            {{ subassembly.label }}
                                        </v-chip>
                                    </div>
                                </v-row>
                            </v-col>
                        </v-row>

                        <!--CHIPS-->
                        <div v-for="filters in machine_filters" :key="filters">
                            <div  v-if="filters.level == report.alert_level && filters.level_name !== 'Tout voir'" class="col-0" style="color:white;font-weight:800;display:flex;justify-content:center;text-shadow:2px 2px 4px rgba(0, 0, 0, 0.29); height:100%;min-width: 8px;border-radius:2px 0px 0px 2px;" :style="{backgroundColor: filters.level_color}">
                                <v-row>
                                    <div class="col-0 pl-10 pa-5">
                                        <v-chip  v-on="on" link :to="'/machine/' + machine.id" style="font-weight: bold; background-color: #f3f3f3; border: 1px solid #e5e5e5;">{{machine.label}} ( type : {{machine.functionality}} )</v-chip>
                                    </div>
                                    <v-col class="col pl-2 pa-6">
                                        {{ $t('report.state') }}{{ report.indice }}%
                                    </v-col>
                                </v-row>
                            </div>
                        </div>  

                        <v-card>
                            <validation-observer ref="observer" v-slot="{ invalid }">
                                <v-card-text class="pb-0 pt-2">
                                    <v-col class="pa-0">
                                        <v-row class="pt-2" style="border-bottom:1px solid #80808040;">
                                            <v-col class="pa-0 pl-3 pr-3 mt-10 col">
                                                <!-- REPORT PRECO -->
                                                <p class="d-inline-block" style="position: relative; top: 17px; background-color: white; z-index: 10; margin-left: 15px; padding: 0px 5px; font-weight: bold; width: 212px">
                                                    {{ $t('report.preco') }}
                                                </p>
                                                <div style="border: 1px solid #bdbdbd; padding: 20px; border-radius: 5px; margin: 0px 0px;">
                                                    <div v-html="report.preco"></div>
                                                </div>

                                                <!-- REPORT CONTEXT -->
                                                <p class="d-inline-block" style="position: relative; top: 17px; background-color: white; z-index: 10; margin-left: 15px; padding: 0px 5px; font-weight: bold; width: 259px">
                                                    {{ $t('report.context') }}
                                                </p>
                                                <div style="border: 1px solid #bdbdbd; padding: 20px; border-radius: 5px;margin: 0px 0px;">
                                                    <div v-html="report.context"></div>
                                                </div>
                                            </v-col>

                                            <!-- REPORT IMAGES -->
                                            <v-col class="pa-0 col">
                                                <template>
                                                    <v-col>
                                                        <highcharts :options="lineChartOptions"></highcharts>
                                                    </v-col>
                                                </template>
                                            </v-col>
                                        </v-row>


                                        <!-- REPORT DEFAULTS -->
                                        <v-col v-if="report.defaults && access_level <= 99" class="col">
                                            <p style="position: relative;top: 17px; background-color: white;z-index: 10;margin-left: 15px;padding: 0px 5px;font-weight: bold; display:inline-block">
                                                {{ $t('report.default') }}
                                            </p>
                                            <div style="border: 1px solid #bdbdbd; padding: 20px; border-radius: 5px; margin: 0px 0px;">
                                                <div v-html="report.defaults"></div>
                                            </div>
                                        </v-col>
                                        <!-- REPORT COMMENT -->
                                        <v-col class="col">
                                            <p style="position: relative; top: 17px; background-color: white; z-index: 10; margin-left: 15px; padding: 0px 5px;font-weight: bold; display:inline-block">
                                                {{ $t('report.comment') }}
                                            </p>
                                            <div style="border: 1px solid #bdbdbd; padding: 20px; border-radius: 5px; margin: 0px 0px;">
                                                <div v-html="report.comment"></div>
                                            </div>
                                        </v-col>

                                        <!-- REPORT IMAGES --> 
                                        <v-col class="col">
                                            <v-col class="pt-5 rounded" style="background-color: whitesmoke;border: 1px solid rgba(211, 211, 211, 0.43);border-bottom: none;">
                                                <h4 class="pt-1 pb-5 ml-2">{{ $t('report.spectre') }}</h4>
                                                <v-carousel v-if="img_urls != 0" hide-delimiters>
                                                    <v-carousel-item 
                                                        v-for="img in img_urls" :key="img"
                                                        :src=img
                                                        height="400"
                                                        progress="primary"
                                                        contain
                                                    ></v-carousel-item>
                                                </v-carousel>
                                            </v-col>
                                        </v-col>
                                    </v-col>
                                </v-card-text>
                            </validation-observer>
                        </v-card>

                        <!-- SNACKBAR INFO DISPLAY -->
                        <v-snackbar
                            v-model="snackbar"
                            :color="snackbar_type">
                            {{ snackbar_text }}
                            <template v-slot:action="{ attrs }">
                                <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                            </template>
                        </v-snackbar>
                    </div>
                </v-col>

                <!-- DIGITAL REPORT -->
                <v-col v-if="report.type === 0 && report_available && report.step > 0 && access_level <= 99" color="white" class="pa-0 pt-1 col rounded" style="margin-right:410px;">
                    <div v-if="loading_datatable == true"  style="color:grey;text-align: center;">{{ $t('global.loading') }}</div>            
                    
                    <div v-if="loading_datatable == false" flat color="white" class="pt-0 mb-3">
                        <!-- REPORT HEADER -->
                        <v-row style="background-color:#1e1e1e;margin:0">
                            <v-col class="col pl-5 pr-5 pb-5">
                                <v-row style="display: flex;align-items: center;color:#ffffffe0;">
                                    <v-col class="col pb-0 pt-0">
                                        <p style="font-size: 14px; position: relative; top: 14px; background-color: #1e1e1e; z-index: 10; margin-left: 15px; padding: 0px 5px; display: inline-block;">
                                            {{ $t('report.campain') }}
                                        </p>
                                        <div link :to="'/machine/' + machine.id" style="border: 1px solid #bdbdbd45; padding: 10px; border-radius: 5px; margin: 0px 0px; font-size: 16px;">
                                            <v-chip v-if="campain_available === true" class="mt-2" v-on="on" link :to="'/campain/' + campain.id" style="font-weight: bold; background-color: #f3f3f3; border: 1px solid #e5e5e5;">{{campain.name}} ( Ref - {{ campain.affaire_number }} )</v-chip>
                                            <v-chip class="mt-2" v-if="campain_available === false" v-on="on" link :to="'/campain/' + campain.id" style="font-weight: bold; background-color: #f3f3f3; border: 1px solid #e5e5e5;">Aucune campagne associée.</v-chip>
                                            <div style="font-weight: 300;font-size: 12px;"></div>
                                        </div>
                                    </v-col>
                                    
                                    <div v-for="analyst in analysts" :key="analyst">
                                        <v-col class="col pb-0" v-if="analyst.id == report.analyst">
                                            <p style="position: relative; top: 14px; background-color: #1e1e1e; z-index: 10; margin-left: 15px; padding: 0px 5px; display: inline-block; ">
                                                {{ $t('report.analyst') }}
                                            </p>
                                            <div style="border: 1px solid #bdbdbd45;padding: 10px;border-radius: 5px; margin: 0px 0px;">
                                                {{ analyst.first_name }} {{  analyst.last_name }}
                                            </div>
                                        </v-col>
                                    </div>

                                    <div v-for="analyst in analysts" :key="analyst.id">
                                        <v-col class="col pb-0" v-if="analyst.id == report.validator">
                                            <p style="position: relative; top: 14px; background-color: #1e1e1e; z-index: 10; margin-left: 15px; padding: 0px 5px; display: inline-block;">
                                                {{ $t('report.validator') }}
                                            </p> 
                                            <div style="border: 1px solid #bdbdbd45;padding: 10px;border-radius: 5px; margin: 0px 0px;">
                                                {{ analyst.first_name }} {{  analyst.last_name }}
                                            </div>
                                        </v-col>
                                    </div>
                                </v-row>

                                <!-- SUBASSEMBLIES -->
                                <p style="font-size: 14px; position: relative; top: 14px; background-color: #1e1e1e; z-index: 10; margin-left: 15px; padding: 0px 5px; display: inline-block;color:#ffffffe0;">
                                    {{ $t('report.subassembly_equipment') }}
                                </p>
                                <v-row class="ma-0" style="border: 1px solid #bdbdbd45; border-radius: 5px">
                                    <div v-for="subassembly in machine.subassemblies" :key="subassembly.id" style="padding: 10px; margin: 0px 0px;">
                                        <v-chip style="font-size: 12px" class="mr-2 mt-1">
                                            {{ subassembly.label }}
                                        </v-chip>
                                    </div>
                                </v-row>
                            </v-col>
                        </v-row>

                        <!--CHIPS-->
                        <div v-for="filters in machine_filters" :key="filters">
                            <div  v-if="filters.level == report.alert_level && filters.level_name !== 'Tout voir'" class="col-0" style="color:white;font-weight:800;display:flex;justify-content:center;text-shadow:2px 2px 4px rgba(0, 0, 0, 0.29); height:100%;min-width: 8px;border-radius:2px 0px 0px 2px;" :style="{backgroundColor: filters.level_color}">
                                <v-row>
                                    <div class="col-0 pl-10 pa-5">
                                        <v-chip  v-on="on" link :to="'/machine/' + machine.id" style="font-weight: bold; background-color: #f3f3f3; border: 1px solid #e5e5e5;">{{machine.label}} ( type : {{machine.functionality}} )</v-chip>
                                    </div>
                                    <v-col class="col pl-2 pa-6">
                                        {{ $t('report.state') }}{{ report.indice }}%
                                    </v-col>
                                    <v-col v-if="report.step < 5" class="col-2 pl-2 pr-10 pa-6" style="text-align: right;font-size:14px;font-weight: 300;">
                                        <v-chip  v-on="on" link :to="'/machine/redit/' + report.id" style="font-weight: bold; background-color: #ffdfb1; border: 1px solid #c78b61;">Modifier le rapport</v-chip>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>  

                        <v-card>
                            <validation-observer ref="observer" v-slot="{ invalid }">
                                <v-card-text class="pb-0 pt-2">
                                    <v-col class="pa-0">
                                        <v-row class="pt-2" style="border-bottom:1px solid #80808040;">
                                            <v-col class="pa-0 pl-3 pr-3 mt-10 col">
                                                <!-- REPORT PRECO -->
                                                <p class="d-inline-block" style="position: relative; top: 17px; background-color: white; z-index: 10; margin-left: 15px; padding: 0px 5px; font-weight: bold; width: 212px">
                                                    {{ $t('report.preco') }}
                                                </p>
                                                <div style="border: 1px solid #bdbdbd; padding: 20px; border-radius: 5px; margin: 0px 0px;">
                                                    <div v-html="report.preco"></div>
                                                </div>

                                                <!-- REPORT CONTEXT -->
                                                <p class="d-inline-block" style="position: relative; top: 17px; background-color: white; z-index: 10; margin-left: 15px; padding: 0px 5px; font-weight: bold; width: 259px">
                                                    {{ $t('report.context') }}
                                                </p>
                                                <div style="border: 1px solid #bdbdbd; padding: 20px; border-radius: 5px;margin: 0px 0px;">
                                                    <div v-html="report.context"></div>
                                                </div>
                                            </v-col>

                                            <!-- REPORT IMAGES -->
                                            <v-col class="pa-0 col">
                                                <template>
                                                    <v-col>
                                                        <highcharts :options="lineChartOptions"></highcharts>
                                                    </v-col>
                                                </template>
                                            </v-col>
                                        </v-row>


                                        <!-- REPORT DEFAULTS -->
                                        <v-col v-if="report.defaults && access_level <= 99" class="col">
                                            <p style="position: relative;top: 17px; background-color: white;z-index: 10;margin-left: 15px;padding: 0px 5px;font-weight: bold; display:inline-block">
                                                {{ $t('report.default') }}
                                            </p>
                                            <div style="border: 1px solid #bdbdbd; padding: 20px; border-radius: 5px; margin: 0px 0px;">
                                                <div v-html="report.defaults"></div>
                                            </div>
                                        </v-col>
                                        <!-- REPORT COMMENT -->
                                        <v-col class="col">
                                            <p style="position: relative; top: 17px; background-color: white; z-index: 10; margin-left: 15px; padding: 0px 5px;font-weight: bold; display:inline-block">
                                                {{ $t('report.comment') }}
                                            </p>
                                            <div style="border: 1px solid #bdbdbd; padding: 20px; border-radius: 5px; margin: 0px 0px;">
                                                <div v-html="report.comment"></div>
                                            </div>
                                        </v-col>

                                        <!-- REPORT IMAGES --> 
                                        <v-col class="col">
                                            <v-col class="pt-5 rounded" style="background-color: whitesmoke;border: 1px solid rgba(211, 211, 211, 0.43);border-bottom: none;">
                                                <h4 class="pt-1 pb-5 ml-2">{{ $t('report.spectre') }}</h4>
                                                <v-carousel v-if="img_urls != 0" hide-delimiters>
                                                    <v-carousel-item 
                                                        v-for="img in img_urls" :key="img"
                                                        :src=img
                                                        height="400"
                                                        progress="primary"
                                                        contain
                                                    ></v-carousel-item>
                                                </v-carousel>
                                            </v-col>
                                        </v-col>
                                    </v-col>
                                </v-card-text>
                            </validation-observer>
                        </v-card>

                        <!-- SNACKBAR INFO DISPLAY -->
                        <v-snackbar
                            v-model="snackbar"
                            :color="snackbar_type">
                            {{ snackbar_text }}
                            <template v-slot:action="{ attrs }">
                                <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                            </template>
                        </v-snackbar>
                    </div>
                </v-col>

                <!-- PDF REPORT -->
                <v-col class="pa-0 rounded" v-if="report.type === 2 && report_available && report.step === 5 && access_level > 99">
                        <!-- REPORT HEADER -->
                        <v-row style="background-color:#1e1e1e;margin:0">
                            <v-col class="col pl-5 pr-5 pb-5">
                                <v-row style="display: flex;align-items: center;color:#ffffffe0;">
                                    <v-col class="col pb-0 pt-0">
                                        <p style="font-size: 14px; position: relative; top: 14px; background-color: #1e1e1e; z-index: 10; margin-left: 15px; padding: 0px 5px; display: inline-block;">
                                            {{ $t('report.campain') }}
                                        </p>
                                        <div link :to="'/machine/' + machine.id" style="border: 1px solid #bdbdbd45; padding: 10px; border-radius: 5px; margin: 0px 0px; font-size: 16px;">
                                            <v-chip class="mt-2" v-if="campain_available === true" v-on="on" link :to="'/campain/' + campain.id" style="font-weight: bold; background-color: #f3f3f3; border: 1px solid #e5e5e5;">{{campain.name}} ( Ref - {{ campain.affaire_number }} )</v-chip>
                                            <v-chip class="mt-2" v-if="campain_available === false" v-on="on" link :to="'/campain/' + campain.id" style="font-weight: bold; background-color: #f3f3f3; border: 1px solid #e5e5e5;">Aucune campagne associée.</v-chip>
                                            <div style="font-weight: 300;font-size: 12px;"></div>
                                        </div>
                                    </v-col>
                                    
                                    <div v-for="analyst in analysts" :key="analyst">
                                        <v-col class="col pb-0" v-if="analyst.id == report.analyst">
                                            <p style="position: relative; top: 14px; background-color: #1e1e1e; z-index: 10; margin-left: 15px; padding: 0px 5px; display: inline-block; ">
                                                {{ $t('report.analyst') }}
                                            </p>
                                            <div style="border: 1px solid #bdbdbd45;padding: 10px;border-radius: 5px; margin: 0px 0px;">
                                                {{ analyst.first_name }} {{  analyst.last_name }}
                                            </div>
                                        </v-col>
                                    </div>

                                    <div v-for="analyst in analysts" :key="analyst.id">
                                        <v-col class="col pb-0" v-if="analyst.id == report.validator">
                                            <p style="position: relative; top: 14px; background-color: #1e1e1e; z-index: 10; margin-left: 15px; padding: 0px 5px; display: inline-block;">
                                                {{ $t('report.validator') }}
                                            </p> 
                                            <div style="border: 1px solid #bdbdbd45;padding: 10px;border-radius: 5px; margin: 0px 0px;">
                                                {{ analyst.first_name }} {{  analyst.last_name }}
                                            </div>
                                        </v-col>
                                    </div>
                                </v-row>

                                <!-- SUBASSEMBLIES -->
                                <p style="font-size: 14px; position: relative; top: 14px; background-color: #1e1e1e; z-index: 10; margin-left: 15px; padding: 0px 5px; display: inline-block;color:#ffffffe0;">
                                    {{ $t('report.subassembly_equipment') }}
                                </p>
                                <v-row class="ma-0" style="border: 1px solid #bdbdbd45; border-radius: 5px">
                                    <div v-for="subassembly in machine.subassemblies" :key="subassembly.id" style="padding: 10px; margin: 0px 0px;">
                                        <v-chip style="font-size: 12px" class="mr-2 mt-1">
                                            {{ subassembly.label }}
                                        </v-chip>
                                    </div>
                                </v-row>
                            </v-col>
                        </v-row>

                        <!--CHIPS-->
                        <div v-for="filters in machine_filters" :key="filters">
                            <div  v-if="filters.level == report.alert_level && filters.level_name !== 'Tout voir'" class="col-0" style="color:white;font-weight:800;display:flex;justify-content:center;text-shadow:2px 2px 4px rgba(0, 0, 0, 0.29); height:100%;min-width: 8px;border-radius:2px 0px 0px 2px;" :style="{backgroundColor: filters.level_color}">
                                <v-row>
                                    <div class="col-0 pl-10 pa-5">
                                        <v-chip  v-on="on" link :to="'/machine/' + machine.id" style="font-weight: bold; background-color: #f3f3f3; border: 1px solid #e5e5e5;">{{machine.label}} ( type : {{machine.functionality}} )</v-chip>
                                    </div>
                                    <v-col class="col pl-2 pa-6">
                                        {{ $t('report.state') }}{{ report.indice }}%
                                    </v-col>
                                </v-row>
                            </div>
                        </div>  

                    <PDFViewer
                        id="pdf"
                        :source="report_url"
                        style="height: 79vh;"
                        @download="handleDownload"
                        v-if ="pdf_report_available"
                    />

                    <v-col class="pa-3 pt-10 rounded" v-if="!pdf_report_available">
                        <li v-if="!notifications_available" class="rounded row notification_card mb-1 pa-10" style="border: 2px dotted #80808059;color: grey;justify-content: center;">
                            <div style="color:grey;text-align: center;">{{ $t('report.unavailable_file1') }}<br> {{ $t('report.unavailable_file2') }}</div>
                        </li>
                    </v-col>
                </v-col>

                <!-- PDF REPORT -->
                <v-col class="pa-0 rounded" v-if="report.type === 2 && report_available && report.step > 0 && access_level <= 99">
                        <!-- REPORT HEADER -->
                        <v-row style="background-color:#1e1e1e;margin:0">
                            <v-col class="col pl-5 pr-5 pb-5">
                                <v-row style="display: flex;align-items: center;color:#ffffffe0;">
                                    <v-col class="col pb-0 pt-0">
                                        <p style="font-size: 14px; position: relative; top: 14px; background-color: #1e1e1e; z-index: 10; margin-left: 15px; padding: 0px 5px; display: inline-block;">
                                            {{ $t('report.campain') }}
                                        </p>
                                        <div link :to="'/machine/' + machine.id" style="border: 1px solid #bdbdbd45; padding: 10px; border-radius: 5px; margin: 0px 0px; font-size: 16px;">
                                            <v-chip class="mt-2" v-if="campain_available === true" v-on="on" link :to="'/campain/' + campain.id" style="font-weight: bold; background-color: #f3f3f3; border: 1px solid #e5e5e5;">{{campain.name}} ( Ref - {{ campain.affaire_number }} )</v-chip>
                                            <v-chip class="mt-2" v-if="campain_available === false" v-on="on" link :to="'/campain/' + campain.id" style="font-weight: bold; background-color: #f3f3f3; border: 1px solid #e5e5e5;">Aucune campagne associée.</v-chip>
                                            <div style="font-weight: 300;font-size: 12px;"></div>
                                        </div>
                                    </v-col>
                                    
                                    <div v-for="analyst in analysts" :key="analyst">
                                        <v-col class="col pb-0" v-if="analyst.id == report.analyst">
                                            <p style="position: relative; top: 14px; background-color: #1e1e1e; z-index: 10; margin-left: 15px; padding: 0px 5px; display: inline-block; ">
                                                {{ $t('report.analyst') }}
                                            </p>
                                            <div style="border: 1px solid #bdbdbd45;padding: 10px;border-radius: 5px; margin: 0px 0px;">
                                                {{ analyst.first_name }} {{  analyst.last_name }}
                                            </div>
                                        </v-col>
                                    </div>

                                    <div v-for="analyst in analysts" :key="analyst.id">
                                        <v-col class="col pb-0" v-if="analyst.id == report.validator">
                                            <p style="position: relative; top: 14px; background-color: #1e1e1e; z-index: 10; margin-left: 15px; padding: 0px 5px; display: inline-block;">
                                                {{ $t('report.validator') }}
                                            </p> 
                                            <div style="border: 1px solid #bdbdbd45;padding: 10px;border-radius: 5px; margin: 0px 0px;">
                                                {{ analyst.first_name }} {{  analyst.last_name }}
                                            </div>
                                        </v-col>
                                    </div>
                                </v-row>

                                <!-- SUBASSEMBLIES -->
                                <p style="font-size: 14px; position: relative; top: 14px; background-color: #1e1e1e; z-index: 10; margin-left: 15px; padding: 0px 5px; display: inline-block;color:#ffffffe0;">
                                    {{ $t('report.subassembly_equipment') }}
                                </p>
                                <v-row class="ma-0" style="border: 1px solid #bdbdbd45; border-radius: 5px">
                                    <div v-for="subassembly in machine.subassemblies" :key="subassembly.id" style="padding: 10px; margin: 0px 0px;">
                                        <v-chip style="font-size: 12px" class="mr-2 mt-1">
                                            {{ subassembly.label }}
                                        </v-chip>
                                    </div>
                                </v-row>
                            </v-col>
                        </v-row>

                        <!--CHIPS-->
                        <div v-for="filters in machine_filters" :key="filters">
                            <div  v-if="filters.level == report.alert_level && filters.level_name !== 'Tout voir'" :to="{ path: '/campain/' + campain.id}" class="col-0" style="color:white;font-weight:800;display:flex;justify-content:center;text-shadow:2px 2px 4px rgba(0, 0, 0, 0.29); height:100%;min-width: 8px;border-radius:2px 0px 0px 2px;" :style="{backgroundColor: filters.level_color}">
                                <v-row>
                                    <div class="col-0 pl-10 pa-5">
                                        <v-chip  v-on="on" link :to="'/machine/' + machine.id" style="font-weight: bold; background-color: #f3f3f3; border: 1px solid #e5e5e5;">{{machine.label}} ( type : {{machine.functionality}} )</v-chip>
                                    </div>
                                    <v-col class="col pl-2 pa-6">
                                        {{ $t('report.state') }}{{ report.indice }}%
                                    </v-col>
                                    <v-col v-if="report.step < 5" class="col-2 pl-2 pr-10 pa-6" style="text-align: right;font-size:14px;font-weight: 300;">
                                        <v-chip  v-on="on" link :to="'/machine/redit/' + report.id" style="font-weight: bold; background-color: #ffdfb1; border: 1px solid #c78b61;">Modifier le rapport</v-chip>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>  

                    <PDFViewer
                        id="pdf"
                        :source="report_url"
                        style="height: 79vh;"
                        @download="handleDownload"
                        v-if ="pdf_report_available"
                    />

                    <v-col class="pa-3 pt-10 rounded" v-if="!pdf_report_available">
                        <li v-if="!notifications_available" class="rounded row notification_card mb-1 pa-10" style="border: 2px dotted #80808059;color: grey;justify-content: center;">
                            <div style="color:grey;text-align: center;">{{ $t('report.unavailable_file1') }}<br>{{ $t('report.unavailable_file2') }}<strong>{{ $t('report.mail') }}</strong></div>
                        </li>
                    </v-col>
                </v-col>

                <!-- VOID REPORT -->
                <v-col class="pa-3 pt-10 rounded" v-if="!report_available || (report_available && report.step < 5 && access_level > 99) || (report_available && report.step === 0 && access_level <= 99)">
                    <li v-if="!notifications_available" class="rounded row notification_card mb-1 pa-10" style="border: 2px dotted #80808059;color: grey;justify-content: center;">
                        <div style="color:grey;text-align: center;">{{ $t('report.no_report1') }}<br> {{ $t('report.no_report2') }}<strong>{{ $t('report.mail') }}</strong></div>
                    </li>
                </v-col>
            </v-col>
        </v-col>
    </v-row>
</template>

<script>
import {CAMPAIN,MACHINES,REPORT,USERS,SITES,DOCUMENTS} from "../../../api";
import Line from "./components/Line.vue";
import moment from "moment";
import {api as fullscreen} from 'vue-fullscreen';
import PDFViewer from 'pdf-viewer-vue/dist/vue2-pdf-viewer'

export default {
    name: "CampainVision",
    components:{Line, PDFViewer},
    data() {
        return {
            // CAMPAINS ----------------
            campain_id:null,
            raw_campain: [],
            campain: [],
            campain_available: false,
            // MACHINE ------------------
            machine_id:null,
            raw_machine: [],
            machine: [],
            // Other infos ------------------
            analysts: [],
            sites:[],
            // REPORT ----------------------
            raw_report:{},
            team:[],
            report_available:false,
            report: {},
            report_url:"",
            machine_filters: [
                { level: -1, level_name: "Rapport en attente", level_color: "grey", count:0, annotation: "Etat à définir"},

                { level: 1, level_name: "Pour information : EDS 100% (vert)", level_color: "#4CAF50", count:0, percent: 100, annotation: "Etat neuf" },
                { level: 2, level_name: "Pour information : EDS 90% (vert)", level_color: "#4CAF50", count:0, percent: 90, annotation: "Comportement vibratoire correct" },
                { level: 3, level_name: "Pour information : EDS 80% (vert)", level_color: "#4CAF50", count:0, percent: 80, annotation: "Pour information" },

                { level: 11, level_name: "A suivre : EDS 70% (jaune)", level_color: "#FFCB05", count:0, percent: 70, annotation: "A suivre lors de la prochaine campagne" },
                { level: 12, level_name: "A suivre : EDS 60% (jaune)", level_color: "#FFCB05", count:0, percent: 60, annotation: "A suivre de manière rapprochée" },
                { level: 13, level_name: "A suivre : EDS 50% (jaune)", level_color: "#FFCB05", count:0, percent: 50, annotation: "Expertise complémentaire préconisée" },

                { level: 21, level_name: "Importance élevée : EDS 40% (rouge)", level_color: "#F44336", count:0, percent: 40, annotation: "Action à prévoir au prochaine arrêt technique" },
                { level: 22, level_name: "Importance élevée : EDS 30% (rouge)", level_color: "#F44336", count:0, percent: 30, annotation: "Action à prévoir au prochaine arrêt technique" },
                { level: 23, level_name: "Importance élevée : EDS 20% (rouge)", level_color: "#F44336", count:0, percent: 20, annotation: "Action à prévoir au prochaine arrêt technique" },
            
                { level: 31, level_name: "Urgence : EDS 10% (Noir)", level_color: "black", count:0, percent: 10, annotation: "Action à prévoir dans les plus brefs délais" },
                { level: 32, level_name:"Urgence : EDS 0% (Noir)", level_color: "black", count:0, percent: 0, annotation: "Arrêt de la machine recommandé" },      
            ],
            prestas:[
                {id:0, name:"Mesures vibratoires"},
                {id:1, name:"Thermographie"},
                {id:2, name:"Mesures ultrasons"},
                {id:3, name:"Equilibrages"},
                {id:4, name:"Expertise avancée"},
                {id:5, name:"Surveillance Online"},
            ],
            types:[
                {id:0, name:"Ponctuel"},
                {id:1, name:"Annuel"},
                {id:2, name:"Semestriel"},
                {id:3, name:"Quadrmestriel"}, 
                {id:4, name:"Trimestriel"},
                {id:5, name:"Continu"},
            ],
            report_types:[
                {id:0, name:"Digital"},
                {id:1, name:"Automatique"},
                {id:2, name:"PDF"},
            ],
            report_steps:[
                {id:0, name:"En attente", level_color: "grey"},
                {id:1, name:"En cours d'édition",level_color: "#ef780e"},
                {id:2, name:"Prêt pour validation",level_color: "#d40eef"},
                {id:3, name:"Validé",level_color: "#089d2d"},
                {id:4, name:"Envoyé",level_color: "#089d2d"},
                {id:5, name:"Archivé",level_color: "grey"},
            ],
            report_tags:[
                {id:0, name:"Défauts BF"},
                {id:1, name:"Défauts HF"},
                {id:2, name:"Défaut électrique"},
                {id:3, name:"Moyen particulier"},
                {id:4, name:"Contre visite"},
                {id:5, name:"Rapport préliminaire"},
            ],

            // GLOBAL --------------------
            access_level: this.$store.state.access_level,
            id: this.$route.params.id,
            loading_datatable:true,
            lineChartOptions: {
                title: {text: 'Evolution de l\'indice de santé machine'},
                time: {timezone: 'Europe/Paris',},
                lang: {noData: this.$t('three_dimensional_curve.data_loading_error')},
                noData: {
                    style: {
                        fontWeight: 'bold',
                        fontSize: '15px',
                        color: '#303030'
                    }
                },
                loading: {hideDuration: 1000,showDuration: 1000},
                scrollbar: {
                    liveRedraw: false,
                    height: 17,
                    barBackgroundColor: '#cfcfcf',
                    barBorderRadius: 9,
                    buttonBackgroundColor: '#c9c9c9',
                    buttonArrowColor: 'white',
                    buttonBorderWidth: 3,
                    buttonBorderRadius: 11,
                    rifleColor: 'white',
                    trackBorderRadius: 9
                },
                rangeSelector: {enabled: false,},
                updateArgs: [true, true, true],
                yAxis: {
                    title: {
                        text: '',
                    },
                    plotBands: [],
                },
                plotOptions: {
                    line: {
                        connectNulls: true,
                    },
                    series: {
                        marker: {
                            enabled: true,
                            radius: 4
                        }
                    }
                },
                series: [{
                    name: 'Data',
                    data: []
                }],
                credits: {
                    enabled: false
                },
            },
            chart: {
                type: 'line',
                preAlert: {
                    yMin: 0,
                    yMax: 0
                },
                alert: {
                    yMin: 0,
                    yMax: 0
                },
                selected: {
                    label: null,
                    date: null,
                    value: null
                },
            },
            raw_report_indices: [],
            report_indices: [],
            report_series: [],
            report_indices_available:false,
            checkInterval:'',

            // Uploads
            dragover: false,
            files_to_upload: [],
            uploaded_files: [],
            loading: true,
            external_link: {
                id_relatable: 0,
                type_relatable: 'report',
                link: ''
            },
            external_links: [],
            dialog_add: false,
            valid: true,
            result: [],

            // Uploads (img)
            img_dragover: false,
            img_files_to_upload: [],
            img_uploaded_files: [],
            img_loading: true,
            img_external_link: {
                id_relatable: this.$route.params.id,
                type_relatable: 'report_images',
                link: ''
            },
            img_external_links: [],
            img_result: [],
            img_available:false,
            img_urls:[],
        }
    },
    computed: {
        dialogTitle() {
            return this.edited_index === null ? this.$t('campain.add') : this.$t('campain.edit');
        }
    },
    watch:{
    },
    created() {
        moment.locale('fr');
        this.initialize();
        this.setMetricThresholds();
        this.resizeIframe();

    },
    methods: {
        // STARTING PROCESS
        initialize() {
            this.loading_datatable = true;
            this.getData();

        },
        // COLLECTING ALL NEEDED DATAS
        getData() {
            // GETTING CAMPAINS
            this.axios.get(REPORT + "/specific_machine/" + this.id)
            
            .then((success) => {
                this.raw_report = success.data;
                this.report = success.data;

                if (this.report.id != 0 && this.report.id != null && this.report.id != ""){
                    this.report_available = true;

                    this.report.tags = JSON.parse(this.report.tags);
                    this.report.created_at = moment(this.report.created_at).format("DD/MM/YYYY");
                    this.report.updated_at = moment(this.report.created_at).fromNow();
                    this.campain_id = this.report.id_campain;
                    this.machine_id = this.report.id_machine;
                    this.report_available = true;

                    if(this.report.type == 2){
                        this.getDocuments();
                    }
                }else{
                    this.report_available = false; 
                }

            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");

            }).finally(() => {
                this.getSideDatas();
                this.loading_datatable = false;
            })
        },
        getSideDatas(){
            this.getMachine();
            
            if (this.report_available){
                if (this.campain_id != null){
                    this.getCampain();
                }
                this.getAnalysts();
                this.img_getDocuments();
                this.getIndices();
                //this.getSite();
            }
        },
        getIndices() {
            // GETTING CAMPAINS
            this.axios.get(REPORT + "/machines_indices/" + this.id)
            
            .then((success) => {
                this.raw_report_indices = [];
                this.report_indices = [];
                this.report_series = [];

                if (typeof success.data[0] !== 'undefined'){
                    this.report_indices_available = true;
                    this.raw_report_indices = success.data;

                    success.data.forEach(report => {
                        this.report_series.push(report.indice);
                    })
                    
                    this.lineChartOptions.series[0].data = this.report_series;

                }else{
                    this.report_indices_available = false;
                }

            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");

            })
        },
        getCampain(){
            this.axios.get(CAMPAIN + '/specific/' + this.report.id_campain )
            
            .then((success) => {
                success.data.forEach(item => {
                    item.created_at = moment(item.created_at).format("DD/MM/YYYY");
                })
                
                this.campain = [];
                this.campain = success.data[0];
                this.campain_available = true;

            }).catch(() => {
                this.campain_available = false;
                //this.showSnackbar('error', "Side datas error.");
            })
        },
        getMachine() {
            this.axios.get(MACHINES + '/specific/' + this.id)
            
            .then((success) => {
                this.machine = [];
                this.machine = success.data[0];

            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");
            })
        },
        getAnalysts(){
            this.axios.get(USERS + '/team')
            
            .then((success) => {
                this.analysts = [];
                this.analysts = success.data;

            }).catch(() => {
                this.showSnackbar('error', "Analysts datas error.");
            })
        },
        getSite() {
            this.axios.get(SITES + '/resume')
            
            .then((success) => {
                this.sites = [];
                this.sites = success.data;

            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");
            })
        },
        img_getDocuments() {
            this.axios.get(DOCUMENTS + '/getFiles', {
                    params: {
                        entity_id: this.report.id,
                        type: 'report_images',
                        professional_id: this.$store.state.professional_id
                    }
                }

            ).then((success) => {
                this.img_uploaded_files = [];
                this.img_urls = [];
                
                if (this.checkDataAvailable(success.data)){
                    success.data.forEach((path) => {
                        let fileName = path.replace(/^.*[\\\/]/, '');
                        this.img_uploaded_files.push({
                            file_name: fileName,
                            path: path
                        });
                    });

                    this.img_uploaded_files.reverse();
                    this.img_uploaded_files.forEach(img_url => {
                        this.img_urls.push(img_url.path);
                        this.img_available = true;
                    })

                }else{
                    this.img_available = false;
                }
            }).catch(() => {
                this.showSnackbar('error', 'img_GetDocuments error');

            }).finally(() => {
                this.img_result = this.img_uploaded_files;
                this.img_loading = false;
            });
        },
        // DISPLAY DATA MANAGEMENT
        checkDataAvailable(datas){
            if(typeof datas[0] === 'undefined') {                    
                return false;
            }else {
                return true;
            }
        },
        //REQUESTS
        updateReport() {
            this.axios.patch(REPORT + "/" + this.report.id,  this.report)
            .then((success) => {
                Object.assign(this.report, success.data[0]);
                this.getData();
                this.loading_datatable = true;
                this.showSnackbar('success', this.$t('global.success_to_edit'));
            
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            
            })
        },
        // Fonction pour modifier le style de l'élément avec la classe "viewer-item"
        modifyStyle(iframe) {
            try {
                // Obtenez le document de l'iframe
                let iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

                // Créez un observeur de mutation pour surveiller les modifications du DOM
                let observer = new MutationObserver(function(mutations) {
                    mutations.forEach(function(mutation) {
                        // Vérifiez si l'élément avec la classe "viewer-item" a été modifié
                        let viewerItem = iframeDocument.querySelector('.viewer-item');
                        if (viewerItem && mutation.target === viewerItem) {
                            // Modifiez la largeur de l'élément
                            viewerItem.style.width = '800px';
                        }
                    });
                });

                // Configuration de l'observeur :
                let config = { attributes: true, childList: true, characterData: true, subtree: true };

                // Passez l'élément cible (document de l'iframe) et la configuration à l'observeur
                observer.observe(iframeDocument, config);
            } catch (error) {
                console.error('An error occurred: ', error);
            }
        },
        checkIframe() {
            let iframe = document.querySelector('iframe');
            if (iframe) {
                // Si l'iframe existe, exécutez votre code
                iframe.addEventListener('load', function() {
                    this.modifyStyle(iframe);
                });

                // Arrêtez de vérifier
                clearInterval(this.checkInterval);
            }
        },
        resizeIframe(){
            this.checkInterval = setInterval(this.checkIframe, 500);
        },
        setMetricThresholds() {
            this.lineChartOptions.yAxis.plotBands = [];
            this.lineChartOptions.yAxis.plotBands.push(
                {
                    from: 100,
                    to: 75,
                    color: 'rgba(76, 175, 80, 0.25)',
                    label: {
                        text: 'Etat de santé convenable'
                    }
                },
                {
                    from: 75,
                    to: 45,
                    color: 'rgba(255, 203, 5, 0.25)',
                    label: {
                        text: 'Etat de santé affecté (à suivre)'
                    }
                },
                {
                    from: 45,
                    to: 15,
                    color: 'rgba(244, 67, 54, 0.25)',
                    label: {
                        text: 'Etat de santé dégradé (importance élevée)'
                    }
                },
                {
                    from: 15,
                    to: 0,
                    color: 'rgba(0, 0, 0, 0.25)',
                    label: {
                        text: 'Etat de santé critique (urgence)'
                    }
                }
            );
        },
        getDocuments() {
            this.axios.get(DOCUMENTS + '/getFiles', {
                params: {
                    entity_id: this.report.id,
                    type: 'report',
                    professional_id: this.$store.state.professional_id
                }
            }

            ).then((success) => {
                this.uploaded_files = [];
                
                if (this.checkDataAvailable(success.data)){
                    success.data.forEach((path) => {
                        let fileName = path.replace(/^.*[\\\/]/, '');
                        this.uploaded_files.push({
                            file_name: fileName,
                            path: path
                        });
                    });

                    this.uploaded_files.reverse();
                    this.report_url = this.uploaded_files[0].path;
                    this.pdf_report_available = true;

                }else{
                    this.pdf_report_available = false;
                }

            }).catch(() => {
                this.showSnackbar('error', 'GetDocuments error');

            }).finally(() => {
                //this.mergeExternalLinkAndDocuments();
                this.loading = false;
            });
        },
    },

}
</script>

<style>
.pdf-viewer__body iframe html body .viewer-container .scroller.viewer .viewer-content .viewer-item>div {
    width: 75% !important;
}

.header__preview .start .title {
    font-size: 13px !important;
}

.pdf-viewer__header {
    box-shadow: 0px 3px 10px 2px #00000021!important;
    z-index: 1!important;
}
</style>