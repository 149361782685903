var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"col mt-12",staticStyle:{"margin-top":"0px!important","padding":"0px","position":"relative","top":"-12px"}},[_c('div',{staticStyle:{"padding":"0px 12px"}},[_c('v-col',{staticClass:"pl-5 pr-5",staticStyle:{"background-color":"#001047","color":"#FFFFFF","border-radius":"0px 0px 5px 5px"}},[_c('v-row',{staticClass:"pl-0 pt-5 mb-5",staticStyle:{"height":"52px","padding":"5px"}},[_c('v-col',{staticClass:"row mb-0 pa-0 mt-1 col-auto",on:{"click":function($event){return _vm.infoToggler()}}},[(_vm.machine)?_c('v-col',{staticClass:"col-auto pa-0 mr-5",attrs:{"color":"white"}},[_c('v-col',{staticClass:"col pt-0 pb-0 mr-10 rounded col ml-6",staticStyle:{"height":"35px","background-color":"rgb(92 101 123)","font-weight":"bold","text-align":"center","font-size":"16px","border":"1px solid #ffffff5c","line-height":"35px","color":"#FFFFFF"}},[_vm._v("\n                            "+_vm._s(_vm.machine.label)+"\n                        ")])],1):_vm._e()],1),_vm._v(" "),_c('v-subheader',{staticClass:"v-subheader col theme--light pl-3 ml-2"},[_c('v-tooltip',{staticClass:"mr-2 ml-2",attrs:{"bottom":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2 mr-2 v-size--default",staticStyle:{"border":"1px solid #cacaca","width":"18px","height":"18px"},attrs:{"text":"","icon":"","dusk":"logout-button"},on:{"click":function($event){return _vm.getNotification()}}},on),[_c('v-icon',{staticStyle:{"font-size":"16px"},attrs:{"color":"darkgrey"}},[_vm._v("mdi-information-variant")])],1)]}},{key:"default",fn:function(){return [_c('div',{staticStyle:{"min-width":"200px","position":"relative","z-index":"10"}},[_c('strong',[_vm._v("Page [Bibliothèque documentaire]")]),_vm._v(" "),_c('p',[_vm._v("Tous les documents techniques associés à cette machine sont stockés et disponible ici."),_c('br'),_vm._v("\n                                Des documentations aux références roulements en passant par le suivi de nos instruments de mesure tout est visible depuis cet onglet.\n                            ")])])]},proxy:true}])}),_vm._v(" "),_c('p',{staticClass:"title mb-0 color-white",staticStyle:{"color":"white!important"}}),_c('div',{staticStyle:{"color":"#FFFFFFb3","margin-right":"4px"}},[_vm._v("Equipements > "+_vm._s(_vm.machine.label)+" > ")]),_c('div',{staticStyle:{"color":"#FFFFFF","font-weight":"bold"},on:{"click":function($event){return _vm.infoToggler()}}},[_vm._v(" "+_vm._s(_vm.$t('machines.documents')))]),_vm._v(" "),_c('p')],1),_vm._v(" "),_c('v-col',{staticClass:"row mt-1 col-auto pa-0 pr-5"},[_c('v-tooltip',{staticClass:"mr-2",attrs:{"bottom":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",staticStyle:{"border":"1px solid #FFFFFF45"},attrs:{"text":"","icon":"","dusk":"logout-button"},on:{"click":function($event){return _vm.getData()}}},on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-tag-multiple")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('global.1_5_2')))])]),_vm._v(" "),_c('v-tooltip',{staticClass:"mr-2",attrs:{"bottom":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",staticStyle:{"border":"1px solid #FFFFFF45"},attrs:{"text":"","icon":"","dusk":"logout-button"},on:{"click":function($event){return _vm.getData()}}},on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-refresh")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('machines.refresh')))])]),_vm._v(" "),(_vm.infoToggle == true)?_c('v-tooltip',{staticClass:"mr-2",attrs:{"bottom":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",staticStyle:{"border":"1px solid #FFFFFF45"},attrs:{"text":"","icon":"","dusk":"logout-button"},on:{"click":function($event){return _vm.infoToggler()}}},on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-minus-box-multiple")])],1)]}}],null,false,1946972511)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('machines.minify')))])]):_vm._e(),_vm._v(" "),(_vm.infoToggle == false)?_c('v-tooltip',{staticClass:"mr-2",attrs:{"bottom":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",staticStyle:{"border":"1px solid #FFFFFF45"},attrs:{"text":"","icon":"","dusk":"logout-button"},on:{"click":function($event){return _vm.infoToggler()}}},on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-plus-box-multiple")])],1)]}}],null,false,1294084137)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('machines.plus')))])]):_vm._e()],1)],1),_vm._v(" "),(_vm.infoToggle == true)?_c('v-col',{staticClass:"mb-4",staticStyle:{"min-height":"250px","background-color":"#e8edff","border-radius":"5px"}},[_c('v-row',[_c('v-col',{staticClass:"col-4"},[_c('machine-state')],1),_vm._v(" "),_c('v-col',{staticClass:"col pl-0"},[_c('machine-specifications',{on:{"updateLabelMachine":_vm.updateLabelMachine}}),_vm._v(" "),_c('v-row',[_c('v-col',{staticClass:"col"},[_c('machine-subassemblies')],1)],1)],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.infoToggle == false)?_c('v-row',{staticStyle:{"border-top":"1px solid #ffffff1c"}},[_c('v-row',{staticStyle:{"margin":"0px"}},[_c('v-row',{staticClass:"ml-5 pt-5 pb-5 rounded"},[_c('v-tooltip',{staticClass:"mr-2",attrs:{"bottom":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-5 col-0",attrs:{"text":"","icon":"","to":{ path: '/machine/' + _vm.id + '/events'},"dusk":"logout-button"}},on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-alert")])],1)]}}],null,false,4183479652)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('machine.alerts')))])]),_vm._v(" "),_c('v-tooltip',{staticClass:"mr-2",attrs:{"bottom":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-5 col-0",attrs:{"text":"","icon":"","to":{ path: '/machine/' + _vm.id + '/report'},"dusk":"logout-button"}},on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-chart-box")])],1)]}}],null,false,3657792463)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('machine.reports')))])]),_vm._v(" "),_c('v-tooltip',{staticClass:"mr-2",attrs:{"bottom":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-5 col-0",attrs:{"text":"","icon":"","to":{ path: '/machine/' + _vm.id + '/details'},"dusk":"logout-button"}},on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-chart-line")])],1)]}}],null,false,3455320536)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('machine.courbes')))])]),_vm._v(" "),(_vm.$store.state.access_level <= 100)?_c('v-tooltip',{staticClass:"mr-2",attrs:{"bottom":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-5 col-0",attrs:{"text":"","icon":"","to":{ path: '/machine/' + _vm.id + '/sandbox'},"dusk":"logout-button"}},on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-vector-curve")])],1)]}}],null,false,3241519151)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('machines.sandbox')))])]):_vm._e(),_vm._v(" "),_c('v-tooltip',{staticClass:"mr-2",attrs:{"bottom":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-5 col-0",attrs:{"text":"","icon":"","to":{ path: '/machine/' + _vm.id + '/documents'},"dusk":"logout-button"}},on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-file-document")])],1)]}}],null,false,1411248013)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('machines.documents')))])]),_vm._v(" "),_c('v-tooltip',{staticClass:"mr-2",attrs:{"bottom":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-5 col-0",attrs:{"text":"","icon":"","to":{ path: '/machine/' + _vm.id + '/settings'},"dusk":"logout-button"}},on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-cog")])],1)]}}],null,false,2077824571)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('machine.settings')))])])],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.infoToggle == true)?_c('v-row',[_c('v-row',{staticStyle:{"margin":"0px"}},[_c('v-row',{staticClass:"ml-5 pt-5 pb-5 rounded"},[_c('v-tooltip',{staticClass:"mr-2",attrs:{"bottom":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-5 col-0",attrs:{"text":"","icon":"","to":{ path: '/machine/' + _vm.id + '/events'},"dusk":"logout-button"}},on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-alert")])],1)]}}],null,false,4183479652)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('machine.alerts')))])]),_vm._v(" "),_c('v-tooltip',{staticClass:"mr-2",attrs:{"bottom":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-5 col-0",attrs:{"text":"","icon":"","to":{ path: '/machine/' + _vm.id + '/report'},"dusk":"logout-button"}},on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-chart-box")])],1)]}}],null,false,3657792463)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('machine.reports')))])]),_vm._v(" "),_c('v-tooltip',{staticClass:"mr-2",attrs:{"bottom":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-5 col-0",attrs:{"text":"","icon":"","to":{ path: '/machine/' + _vm.id + '/details'},"dusk":"logout-button"}},on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-chart-line")])],1)]}}],null,false,3455320536)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('machine.courbes')))])]),_vm._v(" "),(_vm.$store.state.access_level <= 100)?_c('v-tooltip',{staticClass:"mr-2",attrs:{"bottom":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-5 col-0",attrs:{"text":"","icon":"","to":{ path: '/machine/' + _vm.id + '/sandbox'},"dusk":"logout-button"}},on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-vector-curve")])],1)]}}],null,false,3241519151)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('machines.sandbox')))])]):_vm._e(),_vm._v(" "),_c('v-tooltip',{staticClass:"mr-2",attrs:{"bottom":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-5 col-0",attrs:{"text":"","icon":"","to":{ path: '/machine/' + _vm.id + '/documents'},"dusk":"logout-button"}},on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-file-document")])],1)]}}],null,false,1411248013)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('machines.documents')))])]),_vm._v(" "),_c('v-tooltip',{staticClass:"mr-2",attrs:{"bottom":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-5 col-0",attrs:{"text":"","icon":"","to":{ path: '/machine/' + _vm.id + '/settings'},"dusk":"logout-button"}},on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-cog")])],1)]}}],null,false,2077824571)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('machine.settings')))])])],1)],1)],1):_vm._e()],1)],1),_vm._v(" "),_c('v-col',{staticClass:"col pa-0"},[_c('v-col',[_c('div',{attrs:{"id":"machine-detail"}},[_c('v-col',{staticClass:"pa-0"},[_c('v-col',{staticClass:"pa-0 pt-3"},[_c('documents-upload',{attrs:{"parentType":_vm.type,"parentId":_vm.id,"min-height":525}})],1)],1)],1),_vm._v(" "),_c('v-snackbar',{attrs:{"color":_vm.snackbar_type},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-icon',{attrs:{"color":"white"},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("mdi-close")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v("\n                "+_vm._s(_vm.snackbar_text)+"\n                ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }