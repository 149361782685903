import Vue from 'vue';
import VueRouter from 'vue-router';
import {store} from '../store/store';

Vue.use(VueRouter);

let unauthenticatedRoute = [
    {
        path: '/',
        redirect: '/login',
        meta: {w: false,},
    },
    {
        path: '/login',
        name: 'login',
        component: require('../global/pages/LoginView').default,
        meta: {w: false,},
    },
    {
        path: '/reset_password_user',
        component: require('../global/pages/PasswordRecoveryView').default,
        meta: {w: false,},
    },
    {
        path: '/register',
        name: 'register',
        component: require('../global/pages/RegisterView').default,
        meta: {w: false,},
    },
];

let globalRoute = [
    {
        path: '/404',
        redirect: '/dashboard'
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: require('../client/pages/Dashboard').default,
        meta: {
            w: true,
            z: '6E371PLmVuz1rfrZrZ'
        },
    },
    {
        path: '/dashboard_loading',
        name: 'dashboard_loading',
        component: require('../client/pages/DashboardLoading').default,
        meta: {
            w: true,
            z: '6E371PLmVuz1rfrZrZ'
        },
    },
    {
        path: '/dashboard_admin',
        name: 'dashboard_admin',
        component: require('../admin/pages/Dashboard').default,
        meta: {
            w: true,
            z: '3x3eCAZewsn9EYFdZ0'
        },
    },
    {
        path: '/user_profile',
        name: 'user_profile',
        component: require('../global/pages/ProfileView').default,
        meta: {
            w: true,
            z: '6E371PLmVuz1rfrZrZ'
        },
    },
    {
        path: '/notifications',
        name: 'notifications',
        component: require('../global/pages/Notifications').default,
        meta: {
            w: true,
            z: '6E371PLmVuz1rfrZrZ'
        },
    },
    {
        path: '/mco-panel',
        name: 'mco-panel',
        component: require('../admin/pages/Mcopanel').default,
        meta: {
            w: true,
            z: '3x3eCAZewsn9EYFdZ0'
        },
    },
    {
        path: '/alerts-panel',
        name: 'alerts-panel',
        component: require('../admin/pages/AlertsPanel').default,
        meta: {
            w: true,
            z: '3x3eCAZewsn9EYFdZ0'
        },
    },
    {
        path: '/campains-panel',
        name: 'campains-panel',
        component: require('../admin/pages/CampainPanel').default,
        meta: {
            w: true,
            z: '3x3eCAZewsn9EYFdZ0'
        },
    },
    {
        path: '/reports-panel',
        name: 'reports-panel',
        component: require('../admin/pages/ReportPanel').default,
        meta: {
            w: true,
            z: '3x3eCAZewsn9EYFdZ0'
        },
    },
    {
        path: '/customers',
        name: 'professionals',
        component: require('../admin/pages/Professionals/Professionals').default,
        meta: {
            w: true,
            z: '3x3eCAZewsn9EYFdZ0'
        },
    },
    {
        path: '/inprogress/:name',
        name: 'inprogress',
        component: require('../global/pages/Inprogress').default,
        meta: {
            w: true,
        },
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: require('../global/pages/Notfound').default,
        meta: {
            w: true,
        },
    },
    {
        path: '/dev_sandbox',
        name: 'dev_sandbox',
        component: require('../global/pages/Sandbox').default,
        meta: {
            w: true,
            z: '3x3eCAZewsn9EYFdZ0'
        },
    },
    {
        path: '/dev_recette',
        name: 'dev_recette',
        component: require('../global/pages/Recette').default,
        meta: {
            w: true,
            z: '3x3eCAZewsn9EYFdZ0'
        },
    },
    {
        path: '/apps',
        name: 'apps',
        component: require('../global/pages/Apps').default,
        meta: {
            w: true,
            z: '3x3eCAZewsn9EYFdZ0'
        },
    },
    {
        path: '/help',
        name: 'help',
        component: require('../global/pages/Help').default,
        meta: {
            w: true,
            z: '6E371PLmVuz1rfrZrZ'
        },
    },
];

let professionalRoute = [
    {
        path: '/professional/:id',
        component: require('../client/pages/Professional/Detail').default,
        meta: {
            w: true,
            z: '3x3eCAZewsn9EYFdZ0'
        },
    }
];

let subassemblyRoute = [
    {
        path: '/subassembly',
        component: require('../client/pages/Subassembly/Vision').default,
        meta: {
            w: true,
            z: '6E371PLmVuz1rfrZrZ'
        },
    },
    {
        path: '/subassembly/:id',
        name: 'subassembly',
        component: require('../client/pages/Subassembly/Detail').default,
        meta: {
            w: true,
            z: '6E371PLmVuz1rfrZrZ'
        },
    }
];

let machineRoute = [
    {
        path: '/machines',
        component: require('../client/pages/Machine/Vision').default,
        meta: {
            w: true,
            z: '6E371PLmVuz1rfrZrZ'
        },
    },
    {
        path: '/machine/:id',
        name: 'machine',
        component: require('../client/pages/Machine/Detail').default,
        meta: {
            w: true,
            z: '6E371PLmVuz1rfrZrZ'
        },
    },
    {
        path: '/machine/:id/report',
        name: 'report',
        component: require('../client/pages/Machine/Report').default,
        meta: {
            w: true,
            z: '6E371PLmVuz1rfrZrZ'
        },
    },
    {
        path: '/reporting/:id',
        name: 'reporting',
        component: require('../client/pages/Machine/ReportSpecific').default,
        meta: {
            w: true,
            z: '6E371PLmVuz1rfrZrZ'
        },
    },
    {
        path: '/machine/redit/:id',
        name: 'redit',
        component: require('../client/pages/Machine/Redit').default,
        meta: {
            w: true,
            z: '3x3eCAZewsn9EYFdZ0'
        },
    },
    {
        path: '/machine/:id/reports',
        name: 'reports',
        component: require('../client/pages/Machine/Reports').default,
        meta: {
            w: true,
            z: '6E371PLmVuz1rfrZrZ'
        },
    },
    {
        path: '/machine/:id/details',
        name: 'details',
        component: require('../client/pages/Machine/Details').default,
        meta: {
            w: true,
            z: '6E371PLmVuz1rfrZrZ'
        },
    },
    {
        path: '/machine/:id/sandbox',
        name: 'details',
        component: require('../client/pages/Machine/Sandbox').default,
        meta: {
            w: true,
            z: '3x3eCAZewsn9EYFdZ0'
        },
    },
    {
        path: '/machine/:id/settings',
        name: 'settings',
        component: require('../client/pages/Machine/Settings').default,
        meta: {
            w: true,
            z: '6E371PLmVuz1rfrZrZ'
        },
    },
    {
        path: '/machine/:id/documents',
        name: 'documents',
        component: require('../client/pages/Machine/Documents').default,
        meta: {
            w: true,
            z: '6E371PLmVuz1rfrZrZ'
        },
    },
    {
        path: '/machine/:id/events',
        name: 'events',
        component: require('../client/pages/Machine/Events').default,
        meta: {
            w: true,
            z: '6E371PLmVuz1rfrZrZ'
        },
    },
    {
        path: '/reported/:id',
        name: 'reported',
        component: require('../client/pages/Machine/ReportSpecific').default,
        meta: {
            w: true,
            z: '6E371PLmVuz1rfrZrZ'
        },
    },
];

let buildingRoute = [
    {
        path: '/building',
        component: require('../client/pages/Building/Vision').default,
        meta: {
            w: true,
            z: '6E371PLmVuz1rfrZrZ'
        },
    },
    {
        path: '/building/:id',
        name: 'building',
        component: require('../client/pages/Building/Detail').default,
        meta: {
            w: true,
            z: '6E371PLmVuz1rfrZrZ'
        },
    }
]

let siteRoute = [
    {
        path: '/sites',
        name: 'sites',
        component: require('../client/pages/Site/Vision').default,
        meta: {
            w: true,
            z: '6E371PLmVuz1rfrZrZ'
        },
    },
    {
        path: '/site/:id',
        name: 'site',
        component: require('../client/pages/Site/Detail').default,
        meta: {
            w: true,
            z: '6E371PLmVuz1rfrZrZ'
        },
    },
    {
        path: '/parks',
        name: 'parks',
        component: require('../client/pages/Parks/Vision').default,
        meta: {
            w: true,
            z: '6E371PLmVuz1rfrZrZ'
        },
    },
    {
        path: '/parks/:id',
        name: 'park',
        component: require('../client/pages/Parks/Detail').default,
        meta: {
            w: true,
            z: '6E371PLmVuz1rfrZrZ'
        },
    },
    {
        path: '/campains',
        name: 'campains',
        component: require('../client/pages/Campains/Vision').default,
        meta: {
            w: true,
            z: '6E371PLmVuz1rfrZrZ'
        },
    },
    {
        path: '/campain/:id',
        name: 'campain',
        component: require('../client/pages/Campains/Detail').default,
        meta: {
            w: true,
            z: '6E371PLmVuz1rfrZrZ'
        },
    },
];

let deviceVirtualRoute = [
    {
        path: '/virtual_device',
        name: 'devices',
        component: require('../client/pages/VirtualDevice/Vision').default,
        meta: {
            w: true,
            z: '3x3eCAZewsn9EYFdZ0'
        },
    },
    {
        path: '/virtual_device/:id',
        name: 'device',
        component: require('../client/pages/VirtualDevice/Detail').default,
        meta: {
            w: true,
            z: '3x3eCAZewsn9EYFdZ0'
        },
    }
];

let userRoute = [
    {
        path: '/users',
        name: 'users',
        component: require('../client/pages/User/Vision').default,
        meta: {
            w: true,
            z: '3x3eCAZewsn9EYFdZ0'
        },
    },
    {
        path: '/tnWjK3VljKmJ6H55x8/verify',
        name: 'tnWjK3VljKmJ6H55x8',
        component: require('../admin/pages/User/Verify').default,
        meta: {
            w: true,
            z: '3x3eCAZewsn9EYFdZ0'
        }
    },
];

let shopkeeperRoute = [
    {
        path: '/shop/physical_devices/stock_reception',
        name: 'devices_stock_reception',
        component: require('../admin/pages/Shop/Devices/StockReception').default,
        meta: {
            w: true,
            z: 'tnWjK3VljKmJ6H55x8'
        }
    },
    {
        path: '/shop/physical_devices/stock_exit',
        name: 'devices_stock_exit',
        component: require('../admin/pages/Shop/Devices/StockExit').default,
        meta: {
            w: true,
            z: 'tnWjK3VljKmJ6H55x8'
        },
    },
    {
        path: '/shop/physical_devices/remote_stock_installer',
        name: 'devices_remote_stock_installer',
        component: require('../admin/pages/Shop/Devices/AddRemoteInstallerStock').default,
        meta: {
            w: true,
            z: 'tnWjK3VljKmJ6H55x8'
        },
    },
    {
        path: '/shop/physical_devices/list',
        name: 'shop_physical_devices',
        component: require('../admin/pages/Shop/Devices/StoreDevicesDatatable').default,
        meta: {
            w: true,
            z: 'tnWjK3VljKmJ6H55x8'
        },
    },
    {
        path: '/shop/gateways/stock_reception',
        name: 'gateways_stock_reception',
        component: require('../admin/pages/Shop/Gateways/StockReception').default,
        meta: {
            w: true,
            z: 'tnWjK3VljKmJ6H55x8'
        },
    },
    {
        path: '/shop/gateways/stock_exit',
        name: 'gateways_stock_exit',
        component: require('../admin/pages/Shop/Gateways/StockExit').default,
        meta: {
            w: true,
            z: 'tnWjK3VljKmJ6H55x8'
        },
    },
    {
        path: '/shop/gateways/remote_stock_installer',
        name: 'gateways_remote_stock_installer',
        component: require('../admin/pages/Shop/Gateways/AddRemoteInstallerStock').default,
        meta: {
            w: true,
            z: 'tnWjK3VljKmJ6H55x8'
        },
    },
    {
        path: '/shop/gateways/list',
        name: 'shop_gateways',
        component: require('../admin/pages/Shop/Gateways/StoreGatewaysDatatable').default,
        meta: {
            w: true,
            z: 'tnWjK3VljKmJ6H55x8'
        },
    },
    {
        path: '/shop/supplies/stock',
        name: 'supply_stock',
        component: require('../admin/pages/Shop/Supplies/StockSupplies').default,
        meta: {
            w: true,
            z: 'tnWjK3VljKmJ6H55x8'
        },
    },
];

let installerRoute = [
    {
        path: '/installer/physical_devices',
        name: 'installer_physical_devices',
        component: require('../admin/pages/Installer/PhysicalDevicesDatatable').default,
        meta: {
            w: true,
            z: 'tnWjK3VljKmJ6H55x8'
        }
    },
    {
        path: '/installer/gateways',
        name: 'installer_gateways',
        component: require('../admin/pages/Installer/GatewaysDatatable').default,
        meta: {
            w: true,
            z: 'tnWjK3VljKmJ6H55x8'
        }
    },
];

let contractRoute = [
    {
        path: '/contracts/list',
        name: 'contracts',
        component: require('../admin/pages/Contract/ContractsDatatable').default,
        meta: {
            w: true,
            z: 'tnWjK3VljKmJ6H55x8'
        }
    },
    {
        path: '/contracts/avenant_contract',
        name: 'avenant_contract',
        component: require('../admin/pages/Contract/AvenantContract').default,
        meta: {
            w: true,
            z: 'tnWjK3VljKmJ6H55x8'
        },
    },
    {
        path: '/contracts/documents_import',
        name: 'documents_import',
        component: require('../admin/pages/Contract/DocumentsImport').default,
        meta: {
            w: true,
            z: 'tnWjK3VljKmJ6H55x8'
        },
    },
];

let settingRoute = [
    {
        path: '/setting/user_management',
        component: require('../admin/pages/Setting/UserManagement').default,
        name: 'user_management',
        meta: {
            w: true,
            z: 'tnWjK3VljKmJ6H55x8'
        },
    },
    {
        path: '/setting/referential',
        component: require('../admin/pages/Setting/Referential').default,
        meta: {
            w: true,
            z: 'tnWjK3VljKmJ6H55x8'
        },
    },
    {
        path: '/setting/role',
        component: require('../admin/pages/Setting/Role').default,
        meta: {
            w: true,
            z: 'tnWjK3VljKmJ6H55x8'
        },
    },
    {
        path: '/setting/subassembly_configuration',
        component: require('../admin/pages/Setting/SubassemblyConfiguration').default,
        meta: {
            w: true,
            z: 'tnWjK3VljKmJ6H55x8'
        },
    },
    {
        path: '/setting/log',
        component: require('../admin/pages/Setting/Log').default,
        meta: {
            w: true,
            z: 'tnWjK3VljKmJ6H55x8'
        },
    },
    {
        path: "/setting/information_banner",
        component: require('../admin/pages/Setting/InformationBanner').default,
        meta: {
            w: true,
            z: 'tnWjK3VljKmJ6H55x8'
        },
    },
];

const defaultRedirection = [
    {
        path: '*',
        redirect: '/login'
    }
];

let routes = globalRoute.concat(
    unauthenticatedRoute, 
    subassemblyRoute, 
    professionalRoute, 
    machineRoute, 
    buildingRoute, 
    siteRoute, 
    deviceVirtualRoute,
    userRoute, 
    installerRoute, 
    settingRoute, 
    shopkeeperRoute, 
    contractRoute, 
    defaultRedirection
);

const l = {
    'tnWjK3VljKmJ6H55x8' : 0,
    '3x3eCAZewsn9EYFdZ0' : 99,
    'DhK5M1RPcttbSH8EUO' : 180,
    '6E371PLmVuz1rfrZrZ' : 499,
};

let x = false;

export const router = new VueRouter({
    mode: 'history',
    routes: routes
});

router.beforeEach((to, from, next) => {
    if (to.meta.w && to.meta.z) {        
        if (to.meta.z in l && to.meta.z === l["tnWjK3VljKmJ6H55x8"] && l[to.meta.z] === store.state.access_level && localStorage.t) {x = true};
        if (to.meta.z in l && to.meta.z != l["tnWjK3VljKmJ6H55x8"] && l[to.meta.z] >= store.state.access_level) {x = true};
        x === true ? next() : next({name: 'dashboard'});
    }
    next();
});

Vue.router = router;
