<template>
    <div id="user-management">
        <v-row>
            <v-col>
                <v-subheader>
                    <p class="title mb-0 color-primary text-uppercase">
                        {{ $t('account_management.title') }}
                    </p>
                </v-subheader>
            </v-col>
            <v-col style="display:flex;justify-content: flex-end;align-items:center;">
                <v-btn color="primary" class="mr-3" @click="$refs.addUser.dialog_add_user = true">
                    {{ $t('account_management.add_user') }}
                </v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-card class="mb-5">
                    <v-card-title>
                            Invitations
                        <v-spacer/>
                    </v-card-title>

                    <v-data-table
                        :headers="invited_headers"
                        :items="invited_users"
                        sort-by="id"
                        class="elevation-1"
                        :search="search"
                        :loading="loading"
                    >
                        <template v-slot:top>
                            <v-toolbar flat color="white">
                                <v-layout class="justify-end mt-7 max-width-300">
                                    <v-text-field
                                        v-model="search"
                                        append-icon="mdi-magnify"
                                        :label="$t('global.search')"
                                    />
                                </v-layout>

                                <add-user ref="addUser" :roles="roles" v-on:getUsers="getUsers()"/>

                                <resend-invitation ref="resendInvitation"/>

                                <global-snackbar/>
                            </v-toolbar>
                        </template>

                        <template v-slot:item.professionals="{ item }">
                            <template v-if="item.role_id === 7 || item.role_id === 8 || item.role_id === 9 || item.role_id === 10 || item.role_id === 11">
                                {{ $t('global.all') }}
                            </template>
                            <template v-else>
                                <span class="information-client-associate" v-if="item.professionals.length !== 0"
                                      @click="openModalDetailAssociateClients(item.professionals)">
                                     {{ item.professionals.length }}
                                </span>
                            </template>
                        </template>

                        <template v-slot:item.action="{ item }">
                            <v-tooltip top color="primary">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon @click="$refs.resendInvitation.openModalSendMail(item)"
                                            v-bind="attrs"
                                            v-on="on">
                                        mdi-send
                                    </v-icon>
                                </template>
                                <span>{{ $t('login.email_resend')}}</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-card>

                <v-card>
                    <v-card-title>
                            Comptes actifs
                        <v-spacer/>
                    </v-card-title>

                    <v-data-table
                        :headers="headers"
                        :items="users"
                        sort-by="id"
                        class="elevation-1"
                        :search="search"
                        :loading="loading"
                    >
                        <template v-slot:top>
                            <v-toolbar flat color="white">
                                <v-layout class="justify-start mt-7">
                                    <p class="mt-2">{{ $t('global.filter_by') }}</p>

                                    <v-select
                                        :items="roles"
                                        v-model="role_id"
                                        :label="$t('account_management.role')"
                                        item-text="name" item-value="id"
                                        @change="getUsers()"
                                        :menu-props="{ bottom: true, offsetY: true }"
                                        solo dense class="ml-4 max-width-400"
                                        clearable
                                    />

                                    <v-select
                                        :items="professionals"
                                        v-model="professionals_id"
                                        :label="$t('account_management.associate_client')"
                                        :menu-props="{ bottom: true, offsetY: true }"
                                        item-text="name" item-value="id"
                                        solo dense chips multiple class="ml-4 max-width-400"
                                        clearable
                                    />
                                </v-layout>

                                <v-layout class="justify-end mt-7 max-width-300">
                                    <v-text-field
                                        v-model="search"
                                        append-icon="mdi-magnify"
                                        :label="$t('global.search')"
                                    />
                                </v-layout>

                                <add-user ref="addUser" :roles="roles" v-on:getUsers="getUsers()"/>

                                <associated-professional ref="associatedProfessional"
                                                         :associated_professionals="associated_professionals"/>

                                <bind-professional-user ref="bindProfessionalUser" v-on:getUsers="getUsers()"/>

                                <change-password-user ref="changePasswordUser"/>

                                <delete-user ref="deleteUser" :users="users"/>

                                <edit-user ref="editUser" :users="users"/>

                                <resend-invitation ref="resendInvitation"/>

                                <global-snackbar/>
                            </v-toolbar>

                            <v-layout class="justify-end mt-2 mb-2">
                                <v-btn color="primary" class="mr-3" @click="downloadCsvUser()">
                                    <v-progress-circular v-if="loading_csv_file"
                                                         indeterminate color="white" size="20" width="2"
                                    />
                                    <v-icon v-else left>mdi-download-box-outline</v-icon>
                                    {{ $t('global.download') }}
                                </v-btn>
                            </v-layout>
                        </template>

                        <template v-slot:item.professionals="{ item }">
                            <template v-if="item.role_id === 7 || item.role_id === 8 || item.role_id === 9 || item.role_id === 10 || item.role_id === 11">
                                {{ $t('global.all') }}
                            </template>
                            <template v-else>
                                <span class="information-client-associate" v-if="item.professionals.length !== 0"
                                      @click="openModalDetailAssociateClients(item.professionals)">
                                     {{ item.professionals.length }}
                                </span>
                            </template>
                        </template>

                        <template v-slot:item.action="{ item }">
                            <v-tooltip top color="primary">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-if="item.access_level === 100 ||item.access_level === 200"
                                            @click="$refs.bindProfessionalUser.openDialogBindProfessional(item)"
                                            v-bind="attrs"
                                            v-on="on">
                                        mdi-link-box-variant-outline
                                    </v-icon>
                                </template>
                                <span>{{$t('account_management.associate_clients')}}</span>
                            </v-tooltip>
                            <v-tooltip top color="primary">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon class="pointer" v-if="item.role_name !== 'Invité'"
                                            @click="$refs.editUser.openDialogUpdateUserInstallerExpert(item)"
                                            v-bind="attrs"
                                            v-on="on">
                                        mdi-pencil
                                    </v-icon>
                                </template>
                                <span>{{ $t('account_management.edit_user')}}</span>
                            </v-tooltip>
                            <v-tooltip top color="primary">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon class="pointer" v-if="item.role_name !== 'Invité'"
                                            @click="$refs.deleteUser.openDialogDeleteUserInstallerExpert(item)"
                                            v-bind="attrs"
                                            v-on="on">
                                        mdi-delete
                                    </v-icon>
                                </template>
                                <span> {{ $t('account_management.delete_user')}}</span>
                            </v-tooltip>
                            <v-tooltip top color="primary">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon @click="$refs.changePasswordUser.openDialogEditPassword(item)"
                                            v-if="$store.state.access_level <= 200 && item.role_name !== 'Invité'"
                                            v-bind="attrs"
                                            v-on="on">
                                        mdi-lock
                                    </v-icon>
                                </template>
                                <span> {{ $t('users.edit_password')}}</span>
                            </v-tooltip>
                            <v-tooltip top color="primary">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon @click="$refs.resendInvitation.openModalSendMail(item)"
                                            v-if="item.role_name === 'Invité'"
                                            v-bind="attrs"
                                            v-on="on">
                                        mdi-send
                                    </v-icon>
                                </template>
                                <span>{{ $t('login.email_resend')}}</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import {ADMIN_USERS, DOWNLOAD_CSV_USER, PROFESSIONALS, ROLES} from "../../../api";
import GlobalSnackbar from "../../../global/components/GlobalSnackbar";
import AddUser from "../../components/Setting/User/AddUser";
import AssociatedProfessional from "../../components/Setting/User/AssociatedProfessional";
import EditUser from "../../components/Setting/User/EditUser";
import DeleteUser from "../../components/Setting/User/DeleteUser";
import ChangePasswordUser from "../../components/Setting/User/ChangePasswordUser";
import BindProfessionalUser from "../../components/Setting/User/BindProfessionalUser";
import ResendInvitation from "../../components/Setting/User/ResendInvitation";

export default {
    name: "UserManagement",
    components: {
        AddUser,
        AssociatedProfessional,
        BindProfessionalUser,
        ChangePasswordUser,
        EditUser,
        DeleteUser,
        ResendInvitation,
        GlobalSnackbar,
    },
    data() {
        return {
            users: [],
            invited_users: [],
            professionals: [],
            professionals_id: [],
            filter_professionals_id: '',
            roles: [],
            role_id: null,
            headers: [
                {text: this.$t('account_management.gender'), value: 'gender'},
                {text: this.$t('account_management.first_name'), value: 'first_name'},
                {text: this.$t('account_management.last_name'), value: 'last_name'},
                {text: this.$t('account_management.email'), value: 'email'},
                {text: this.$t('account_management.phone'), value: 'phone'},
                {text: this.$t('account_management.role'), value: 'role_name'},
                {text: this.$t('account_management.associated_clients'), value: 'professionals'},
                {text: this.$t('account_management.created_at'), value: 'created_at'},
                {text: this.$t('account_management.date_last_seen'), value: 'date_last_seen'},
                {text: this.$t('global.actions'), value: 'action'}
            ],

            invited_headers: [    
                {text: this.$t('account_management.associated_clients'), value: 'professionals'},
                {text: this.$t('account_management.email'), value: 'email'},
                {text: this.$t('account_management.role'), value: 'role'},
                {text: "Clé d'inscription", value: 'token'},
                {text: "Date d'invitation", value: 'created_at'},
                {text: "Date de relance", value: 'updated_at'},
                {text: this.$t('global.actions'), value: 'action'},
            ],

            invalid: false,
            loading: true,
            search: '',

            dialog_detail_associated_professionals: false,
            associated_professionals: [],
        }
    },
    watch: {
        professionals_id() {
            this.loading = true;
            this.filter_professionals_id = '';

            if (this.professionals_id.length !== 0) {
                this.professionals_id.forEach((professional_id) => {
                    this.filter_professionals_id += (this.filter_professionals_id === '' ? professional_id : ',' + professional_id);
                });
            }
            this.getUsers();
        },
    },
    created() {
        this.getUsers();
        this.getProfessionals();
        this.getRoles();
    },
    methods: {
        getUsers() {
            this.loading = true;

            this.axios.get(
                ADMIN_USERS + '?role_id=' + this.role_id + '&professionals_id=' + this.filter_professionals_id
            ).then((success) => {
                success.data.forEach(user => {
                    user.date_last_seen = (user.date_last_seen !== null ? this.moment(user.date_last_seen).format('DD/MM/YYYY kk:mm') : '');
                    user.created_at = this.moment(user.created_at).format('DD/MM/YYYY kk:mm');
                });
                this.users = success.data;
                this.getInvitations();
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('error_display.error_get_users'));
            })
        },

        getInvitations() {
            this.axios.get(
                ADMIN_USERS + '/invitations'
            ).then((success) => {
                success.data.forEach(data => {
                    this.invited_users.push({
                        email: data.email,
                        role: data.role,
                        professionals: [data.professional_id],
                        token: data.invitation_token,
                        created_at:this.moment(data.created_at).format('DD/MM/YYYY kk:mm'),
                        updated_at:this.moment(data.updated_at).format('DD/MM/YYYY kk:mm'),
                    });
                });
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('error_display.error_get_invitations'));
            }).finally(() => {
                this.loading = false;
            });
        },

        getProfessionals() {
            this.axios.get(PROFESSIONALS).then((success) => {
                this.professionals = success.data;
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('error_display.error_get_professionals'));
            });
        },

        getRoles() {
            this.axios.get(ROLES)
            
            .then((success) => {
                this.roles = success.data;
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('error_display.error_get_roles'));
            });
        },

        openModalDetailAssociateClients(associatedClients) {
            this.associated_professionals = associatedClients;
            this.$refs.associatedProfessional.dialog_detail_associated_professionals = true;
        },

        downloadCsvUser() {
            this.loading_csv_file = true;
            this.axios({
                url: DOWNLOAD_CSV_USER + '?role_id=' + this.role_id + '&professionals_id=' + this.filter_professionals_id,
                method: 'GET',
                responseType: 'blob',
            }).then((success) => {
                this.downloadCsvFile(success.data, "_users.csv");
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('global.failure_to_download'));
            }).finally(() => {
                this.loading_csv_file = false;
            });
        },
    }
}
</script>

<style>
.information-client-associate {
    cursor: pointer;
    color: #1976d2;
    text-decoration: underline;
}
</style>
