<template>
    <div id="modal-add-operation-timeline">
        <v-dialog v-model="dialog_operation_timeline_edit" scrollable max-width="500px">
            <validation-observer ref="observer" v-slot="{ invalid }">
                <v-card>
                    <v-form ref="form">
                        <v-card-title>
                            <span class="headline">{{ dialog_title }}</span>
                        </v-card-title>
                        <v-divider/>

                        <v-card-text>
                            <div class="text-center mb-3" v-if="edited_operation_timeline.type === 4">
                                <v-progress-circular v-if="expert_tickets === null"
                                                     :size="50"
                                                     color="primary"
                                                     indeterminate
                                />
                                <div v-else>
                                    <h2>{{ expert_tickets }}</h2>
                                    <p class="mt-1">{{ $t('subassembly_configurations.remained_expert_ticket') }}</p>
                                    <template v-if="expert_tickets === 0">
                                        <p class="mt-1 red--text">{{ $t('subassembly_configurations.no_remained_expert_ticket')}}</p>
                                    </template>
                                </div>
                            </div>
                            <v-divider class="mb-4" v-if="edited_operation_timeline.type === 4"/>
                            <validation-provider :name="$t('global.comment')" rules="required" v-slot="{ errors }">
                                <v-textarea
                                    v-model="edited_operation_timeline.comment"
                                    rows="3" solo
                                    :label="$t('global.comment')"
                                    :prepend-icon="'mdi-comment-outline'"
                                    :error-messages="errors"
                                />
                            </validation-provider>

                            <v-file-input
                                v-model="edited_operation_timeline.image"
                                :label="$t('operation.join_image')"
                                :prepend-icon="'mdi-camera'"
                                @change="onImageChanged"
                                :messages="$t('global.optional')"
                                solo
                            ></v-file-input>

                            <v-file-input
                                v-model="edited_operation_timeline.file"
                                :label="$t('operation.join_file')"
                                :messages="$t('global.optional')"
                                @change="onFileChanged"
                                solo
                            ></v-file-input>
                        </v-card-text>

                        <v-snackbar
                            v-model="snackbar"
                            :color="snackbar_type">
                            {{ snackbar_text }}
                            <template v-slot:action="{ attrs }">
                                <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                            </template>
                        </v-snackbar>

                        <v-divider/>
                        <v-card-actions>
                            <v-spacer/>
                            <v-btn @click="dialog_operation_timeline_edit = false">
                                <v-icon left>mdi-close</v-icon>
                                {{ $t('global.close') }}
                            </v-btn>
                            <v-btn type="submit"
                                   color="primary"
                                   @click.prevent="addOperationTimeline()"
                                   :disabled="invalid || (expert_tickets === 0 && edited_operation_timeline.type === 4)">
                                <v-icon left>mdi-content-save</v-icon>
                                {{ $t('global.validation') }}
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </validation-observer>
        </v-dialog>
    </div>
</template>

<script>
import {OPERATION_TIMELINES, PROFESSIONALS} from "../../../../api";

export default {
    name: "ModalAddOperationTimeline",
    data() {
        return {
            operation_id: null,
            dialog_title: '',
            dialog_operation_timeline_edit: false,
            invalid: false,
            expert_tickets:null,
            valid_image:true,
            valid_file:true,
            edited_operation_timeline: {
                id: null,
                comment: '',
                type: null,
                file: null,
                image: null,
                operation_id: null
            },
        }
    },
    methods: {
        getRemainedExpertTickets(){
            this.axios.get(
                PROFESSIONALS + '/expert_ticket'
            ).then((success)=>{
                  this.expert_tickets= success.data.expert_ticket;
            }).catch((error)=>{
                this.showSnackbar('error', this.$t('global.error'));
            });
        },
        openDialogAddOperationTimeline(operationId, type) {
            this.edited_operation_timeline.type = type;
            this.dialog_title = (type === 3 ? this.$t('operation.add_message') : this.$t('operation.expert_demand'));
            this.operation_id = operationId;
            this.getRemainedExpertTickets();
            this.dialog_operation_timeline_edit = true;
        },

        addOperationTimeline() {
            if(!this.valid_image || !this.valid_file) {
                this.showSnackbar('error', this.$t('global.uploaded_file_error'));
            }
            else {
                let formData = new FormData();
                formData.append('type', this.edited_operation_timeline.type);
                formData.append('comment', this.edited_operation_timeline.comment);
                formData.append('file', this.valid_file ? this.edited_operation_timeline.file : null);
                formData.append('image', this.valid_image ? this.edited_operation_timeline.image : null);
                formData.append('operation_id', this.operation_id);
                this.axios.post(
                    OPERATION_TIMELINES, formData, this.configurationFile
                ).then(() => {
                    this.dialog_operation_timeline_edit = false;
                    if(this.edited_operation_timeline.type === 4) {
                        this.updateExpertTicketNumber();
                    }
                    this.$emit('showSnackbarForModalAddOperationTimeline', {
                        type: 'success',
                        text: this.$t('global.success_to_add')
                    });
                }).catch(() => {
                    this.$emit('showSnackbarForModalAddOperationTimeline', {
                        type: 'error',
                        text: this.$t('global.failure_to_add')
                    });
                }).finally(() => {
                    this.$refs.form.reset();
                    this.$refs.observer.reset();
                });
            }
        },

        updateExpertTicketNumber(){
            this.axios.put(
                PROFESSIONALS + '/contrat/expert_ticket'
            ).then((success) => {
                this.expert_tickets=success.data
            }).catch((error)=>{
                this.showSnackbar('error', this.$t('global.error'));
            })
        },


        onImageChanged() {
            if(this.edited_operation_timeline.image !== null) {
                let imageCheck = this.uploadFilesCheck(this.edited_operation_timeline.image,['image/png','image/jpg','image/jpeg'],2);
                if(!imageCheck.authorized) {
                    this.valid_image = false;
                    this.showSnackbar('error',imageCheck.output.toString());
                }
                else {
                    this.valid_image = true;
                }
            }
            if(this.edited_operation_timeline.image === null){
                this.valid_image = true;
            }
        },
        onFileChanged() {
            if(this.edited_operation_timeline.file !== null) {
                let fileCheck = this.uploadFilesCheck(this.edited_operation_timeline.file,['application/pdf','text/csv'],4);
                if(!fileCheck.authorized) {
                    this.valid_file = false;
                    this.showSnackbar('error',fileCheck.output.toString());
                }
                else {
                    this.valid_file = true;
                }
            }
            if(this.edited_operation_timeline.file === null ) {
                this.valid_file = true;
            }
        }
    }
}
</script>

<style scoped>
h2{
    font-size: 30px;
    color: #004181;
}
</style>
