<template>
    <div id="modal-close-operation">
        <v-dialog v-model="dialog_close_operation" max-width="500px">
            <validation-observer ref="observer" v-slot="{ invalid }">
                <v-card>
                    <v-form ref="form">
                        <v-card-title>
                            <span class="headline">
                                {{ $t('operation.close_operation') }}
                            </span>
                        </v-card-title>
                        <v-divider></v-divider>

                        <v-card-text><br/>
                            <validation-provider :name="$t('global.comment')" rules="required" v-slot="{ errors }">
                                <v-textarea
                                    v-model="operation_timeline_close.comment"
                                    rows="3" solo
                                    :label="$t('global.comment')"
                                    :prepend-icon="'mdi-comment-outline'"
                                    :error-messages="errors"
                                />
                            </validation-provider>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer/>
                            <v-btn @click="dialog_close_operation = false">
                                <v-icon left>mdi-close</v-icon>
                                {{ $t('global.cancel') }}
                            </v-btn>
                            <v-btn color="primary"
                                   :disabled="invalid"
                                   @click.prevent="confirmCloseOperation()">
                                <v-icon left>mdi-archive-arrow-down</v-icon>
                                {{ $t('operation.close') }}
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </validation-observer>
        </v-dialog>
    </div>
</template>

<script>
import {OPERATIONS} from "../../../../api";

export default {
    name: "ModalCloseOperation",
    data() {
        return {
            dialog_close_operation: false,
            invalid: false,
            operation_timeline_close: {
                comment: '',
                operation_id: null
            },
        }
    },
    methods: {
        openDialogCloseOperation(operationId) {
            this.operation_timeline_close.operation_id = operationId;
            this.dialog_close_operation = true;
        },

        confirmCloseOperation() {
            this.axios.post(
                OPERATIONS + '/' + this.operation_timeline_close.operation_id + '/close', this.operation_timeline_close
            ).then(() => {
                this.dialog_close_operation = false;
                this.$emit('showSnackbarForModalCloseOperation', {
                    type: 'success',
                    text: this.$t('operation.success_to_close')
                });
            }).catch(() => {
                this.$emit('showSnackbarForModalCloseOperation', {
                    type: 'error',
                    text: this.$t('operation.failure_to_close')
                });
            }).finally(() => {
                this.$refs.form.reset();
                this.$refs.observer.reset();
            });
        }
    }
}
</script>
