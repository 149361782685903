<template>
    <v-container>
        <h3>LISTE DES COMPOSANTS DEJA CREES</h3>

        <div class="mt-5 mb-5"></div>

        <!-- DATA TABLE -->
        <v-data-table
            :headers="testHeaders"  
            :items="testDataTable"
            hide-default-footer
            :items-per-page="-1"
            item-value="id"
            class="text-center"
            :sort-by="['test_date']"
            :sort-desc="[false]"
        >
            <!-- TOP -->
            <!-- Template pour afficher quelque chose au dessus de la data table -->
            <template v-slot:top>
                <v-toolbar flat>
                    <div class="elevation-0 pl-5 pt-2 pb-2" >
                        <h4>Data table</h4>
                    </div>

                    <v-layout class="justify-end mt-7 mr-12 max-width-300">
                        <v-text-field
                            v-model="search2"
                            append-icon="mdi-magnify"
                            :label="$t('global.search')"
                        ></v-text-field>
                    </v-layout>
                </v-toolbar>
            </template>

            <!-- SI pas de template la table affiche par défaut ce qu'il y a dans value qui est géré dans le header -->

            <!-- Template personnalisé d'une des colonnes -->
            <template v-slot:item.test1="{ item }">
                <p style="font-weight: 800;">{{ item.test1 }}</p>
                <p>Template personnalisé</p>
            </template>
        </v-data-table>

        <div class="mt-5 mb-5"></div>

        <!-- TOOLTIP -->
        <v-tooltip bottom dark class="mr-2 ml-2">
            <!-- Ce qui est tout le temps visible -->
            <template v-slot:activator="{ on }">
                <!--
                Pas obligé de mettre un bouton
                Toujours mettre v-on="on" et ajouter @click="" pour permettre de rediriger quelque part
                -->
                <v-btn text icon v-on="on" class="ml-2 mr-2" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                    <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                </v-btn>
            </template>
            <!-- Ce qui est affiché au survol -->
            <template v-slot:default>
                <div style="min-width: 200px;position:relative;z-index: 10;">
                    <strong>Texte au survol</strong>
                    <p>Ce texte n'est affiché qu'au survol</p>
                </div>
            </template>
        </v-tooltip>

        <div class="mt-5 mb-5"></div>
        
        <!-- ONGLETS -->
        <div>
            <v-row class="pt-0" style="margin:0px;height:40px;">
                <v-col class="row ml-0 mt-0 pb-0 pt-0 pl-0" style="position: relative;left:-1px;">
                    <div v-for="vue in vues" :key="vue" class="mr-1">
                        <div v-if="vue.id == active_special_vue" class="ma-auto pl-5 pr-5 pa-2"
                            style="
                                color: black;
                                font-weight: 400;
                                display: flex;
                                justify-content: left;
                                text-shadow: rgba(0, 0, 0, 0.29) 2px 2px 4px;
                                background-color: rgb(255 255 255);
                                border-radius: 5px 5px 0px 0px;
                                border-left: 1px solid rgba(211, 211, 211, 0.43);
                                border-bottom: none;
                                font-size: 14px;
                                height: 40px;
                                align-items: center;
                                font-weight:bold;
                            "
                        >
                            {{vue.label}}
                        </div>
                        <div v-else class="ma-auto pl-5 pr-5 pa-2"
                            style="
                                color: black;
                                font-weight: 400;
                                display: flex;
                                justify-content: left;
                                text-shadow: rgba(0, 0, 0, 0.29) 2px 2px 4px;
                                background-color: rgb(243 243 243 / 90%);
                                border-radius: 10px 10px 0px 0px;
                                border: 1px solid #dbdce1;
                                border-bottom: none;
                                font-size: 14px;
                                height: 40px;
                                cursor: pointer;
                            "
                            @click="loadVue(vue.id)"
                        >
                            {{vue.label}}
                        </div>
                    </div>
                </v-col>
            </v-row>
            <!-- Onglet 1 -->
            <div v-if="active_special_vue == 0">
                <p>Ceci est l'onglet 1</p>
            </div>
            <!-- Onglet 2 -->
            <div v-if="active_special_vue == 1">
                <p>Ceci est l'onglet 2</p>
            </div>
            <!-- Onglet 3 -->
            <div v-if="active_special_vue == 2">
                <p>Ceci est l'onglet 3</p>
            </div>
        </div>

        <div class="mt-5 mb-5"></div>
        
        <!-- EXPANSION PANEL -->
        <v-expansion-panels style="border-radius: 0px 0px 5px 5px;">
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <p>Titre du panel</p>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                    <p class="ml-5">Ici on retrouve le contenu du Panel qui n'est affiché que lorsqu'on le déplie</p>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-container>
  </template>
  
  <script>
  export default {
    data() {
      return {
        headers: [
          { text: 'Nom', value: 'name' },
          { text: 'Âge', value: 'age' },
        ],
        items: [
          { name: 'Alice', age: 25, details: 'Détails sur Alice' },
          { name: 'Bob', age: 30, details: 'Détails sur Bob' },
        ],

        // DATA TABLE
        testHeaders: [
            { text: "Test 1", value: "test1", width: '25%', sortable: false},
            { text: "Test 2", value: "test2",},
            { text: "Test 3", value: "test3",},
            { text: "Test 4", value: "test_date",},
        ],
        testDataTable: [
            {test1: "Je suis la 1ere donnée du test 1", test2: "Je suis la 1ere donnée du test 2", test3: "Je suis la 1ere donnée du test 3", test_date: "01/01/2024"},
            {test1: "Je suis la 2e donnée du test 1", test2: "Je suis la 2e donnée du test 2", test3: "Je suis la 2e donnée du test 3", test_date: "02/01/2024"}
        ],

        // ONGLETS
        vues:[
            {id:0, label: "Onglet 1"},
            {id:1, label: "Onglet 2"},
            {id:2, label: "Onglet 3"},
        ],
        active_special_vue: 0,
      };
    },
    methods: {
        // ONGLETS
        loadVue(id) {
            this.active_special_vue = id;
        },
    },
  };
  </script>
  
  <style>
  .v-container {
    margin-top: 20px;
  }
  </style>