var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"modal-operation"}},[_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog_add),callback:function ($$v) {_vm.dialog_add=$$v},expression:"dialog_add"}},[_c('v-card',[_c('validation-observer',{ref:"observerAdd",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{ref:"formAdd"},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('operation.add_operation')))])]),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-card-text',[_c('v-text-field',{attrs:{"label":_vm.$t('global.name'),"solo":"","prepend-icon":'mdi-label-outline'},model:{value:(_vm.added_operation.label),callback:function ($$v) {_vm.$set(_vm.added_operation, "label", $$v)},expression:"added_operation.label"}}),_vm._v(" "),_c('validation-provider',{attrs:{"name":_vm.$t('operation.type_operation'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.type_operations,"label":_vm.$t('operation.type_operation'),"prepend-icon":'mdi-pipe-wrench',"error-messages":errors,"item-text":"value","item-value":"id","solo":""},model:{value:(_vm.added_operation.id_ref_type_operation),callback:function ($$v) {_vm.$set(_vm.added_operation, "id_ref_type_operation", $$v)},expression:"added_operation.id_ref_type_operation"}})]}}],null,true)}),_vm._v(" "),_c('validation-provider',{attrs:{"name":_vm.$t('operation.operation_intervener'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.interveners,"label":_vm.$t('operation.operation_intervener'),"prepend-icon":'mdi-account-hard-hat',"error-messages":errors,"item-value":"id","solo":""},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v("\n                                    "+_vm._s(data.item.last_name)+" "+_vm._s(data.item.first_name)+" ("+_vm._s(data.item.email)+")\n                                ")]}},{key:"item",fn:function(data){return [_vm._v("\n                                    "+_vm._s(data.item.last_name)+" "+_vm._s(data.item.first_name)+" ("+_vm._s(data.item.email)+")\n                                ")]}}],null,true),model:{value:(_vm.added_operation.user_responsible_id),callback:function ($$v) {_vm.$set(_vm.added_operation, "user_responsible_id", $$v)},expression:"added_operation.user_responsible_id"}})]}}],null,true)}),_vm._v(" "),_c('validation-provider',{attrs:{"name":_vm.$t('operation.part_concerned'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.parts_concerned,"label":_vm.$t('operation.part_concerned'),"prepend-icon":'mdi-share-variant',"error-messages":errors,"item-text":"value","item-value":"id","solo":""},model:{value:(_vm.added_operation.id_ref_part_concerned),callback:function ($$v) {_vm.$set(_vm.added_operation, "id_ref_part_concerned", $$v)},expression:"added_operation.id_ref_part_concerned"}})]}}],null,true)}),_vm._v(" "),_c('v-textarea',{attrs:{"rows":"3","solo":"","label":_vm.$t('global.comment'),"prepend-icon":'mdi-comment-outline'},model:{value:(_vm.added_operation.comment),callback:function ($$v) {_vm.$set(_vm.added_operation, "comment", $$v)},expression:"added_operation.comment"}})],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{on:{"click":function($event){return _vm.closeDialogAddOperation()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close")]),_vm._v("\n                            "+_vm._s(_vm.$t('global.close'))+"\n                        ")],1),_vm._v(" "),_c('v-btn',{attrs:{"type":"submit","color":"primary","disabled":invalid},on:{"click":function($event){$event.preventDefault();return _vm.addOperation()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v("\n                            "+_vm._s(_vm.$t('global.validation'))+"\n                        ")],1)],1)],1)]}}])}),_vm._v(" "),_c('v-snackbar',{attrs:{"color":_vm.snackbar_type},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-icon',{attrs:{"color":"white"},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("mdi-close")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v("\n                "+_vm._s(_vm.snackbar_text)+"\n                ")])],1)],1),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog_update),callback:function ($$v) {_vm.dialog_update=$$v},expression:"dialog_update"}},[_c('v-card',[_c('validation-observer',{ref:"observerUpdate"},[_c('v-form',{ref:"formUpdate"},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('operation.update_operation')))])]),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-card-text',[_c('validation-provider',{attrs:{"name":_vm.$t('global.label'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('global.label'),"solo":"","error-messages":errors,"prepend-inner-icon":'mdi-label-outline'},model:{value:(_vm.updated_operation.label),callback:function ($$v) {_vm.$set(_vm.updated_operation, "label", $$v)},expression:"updated_operation.label"}})]}}])}),_vm._v(" "),_c('v-select',{attrs:{"items":_vm.interveners,"label":_vm.$t('operation.operation_intervener'),"prepend-inner-icon":'mdi-account-hard-hat',"item-value":"id","solo":""},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v("\n                                "+_vm._s(data.item.last_name)+" "+_vm._s(data.item.first_name)+" ("+_vm._s(data.item.email)+")\n                            ")]}},{key:"item",fn:function(data){return [_vm._v("\n                                "+_vm._s(data.item.last_name)+" "+_vm._s(data.item.first_name)+" ("+_vm._s(data.item.email)+")\n                            ")]}}]),model:{value:(_vm.updated_operation.user_responsible_id),callback:function ($$v) {_vm.$set(_vm.updated_operation, "user_responsible_id", $$v)},expression:"updated_operation.user_responsible_id"}})],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{on:{"click":function($event){return _vm.closeDialogUpdateOperation()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close")]),_vm._v("\n                            "+_vm._s(_vm.$t('global.close'))+"\n                        ")],1),_vm._v(" "),_c('v-btn',{attrs:{"type":"submit","color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.updateOperation()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v("\n                            "+_vm._s(_vm.$t('global.validation'))+"\n                        ")],1)],1)],1)],1),_vm._v(" "),_c('v-snackbar',{attrs:{"color":_vm.snackbar_type},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-icon',{attrs:{"color":"white"},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("mdi-close")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v("\n                "+_vm._s(_vm.snackbar_text)+"\n                ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }