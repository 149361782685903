<template>
    <v-card class="rounded-lg" elevation="2" outlined>
        <v-card-subtitle>
            <v-btn @click="setLogSeenAll()" color="primary" class="mt-3" :disabled="log_seen">
                {{ $t('logs.view_all') }}
            </v-btn>
            <v-btn @click="openDialogDeleteDuplicates" color="error" class="mt-3">
                {{ $t('logs.del_duplicates') }}
            </v-btn>
            <v-spacer/>
        </v-card-subtitle>

        <v-data-table
            :headers="headers"
            :items="logs"
            class="elevation-1"
            :item-per-page=-1
            :search="search"
            sort-by="id"
            :sort-desc=true
        >
            <template v-slot:top>
                <v-toolbar flat color="white" class="pt-3 mb-3">
                    <v-row>
                        <v-col cols="2">
                            <v-btn @click="filterLogs()" class="mt-3">
                                {{ $t('logs.view_false') }}
                                <v-switch
                                    v-model="log_seen"
                                    color="primary" disabled
                                    class="mt-5 pr-2 pl-2"
                                ></v-switch>
                                {{ $t('logs.view_true') }}
                            </v-btn>
                        </v-col>
                        <v-col cols="4">
                            <v-select
                                class="mt-1"
                                :items="log_levels"
                                :label="$t('logs.select_logs')"
                                v-model="log_level_selected"
                                item-text="level_name"
                                item-value="level"
                                @change="getLogs()"
                                solo chips multiple deletable-chips
                            >
                            </v-select>
                        </v-col>
                        <v-col cols="2"/>
                        <v-col>
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                :label="$t('global.search')"
                                class="mt-3"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-snackbar
                        v-model="snackbar"
                        :color="snackbar_type">
                        {{ snackbar_text }}
                        <template v-slot:action="{ attrs }">
                            <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                        </template>
                    </v-snackbar>

                    <v-dialog v-model="dialog_show" max-width="500px" eager>
                        <v-card>
                            <v-card-title>
                                <h2>{{ $t('logs.modal_title') }}{{ log.id }}</h2>
                            </v-card-title>
                            <v-card-text>

                                <v-row>
                                    <v-col>
                                        <h3>{{ $t('logs.level') }}</h3>
                                        <p>{{ log.level_name }} : {{ log.level }}</p>
                                    </v-col>
                                    <v-col>
                                        <h3>{{ $t('logs.created_at') }}</h3>
                                        <p>{{ log.created_at }}</p>
                                    </v-col>
                                </v-row>

                                <h3>{{ $t('logs.message') }}</h3>
                                <p>{{ log.message }}</p>

                                <v-row>
                                    <v-col>
                                        <h3>{{ $t('logs.context') }}</h3>
                                        <pre>{{ jsonParseContext }}</pre>
                                    </v-col>
                                    <v-col>
                                        <h3>{{ $t('logs.extra') }}</h3>
                                        <p>{{ jsonParseExtra }}</p>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn @click="dialog_show = false">
                                    <v-icon left>mdi-close</v-icon>
                                    {{ $t('global.close') }}
                                </v-btn>

                                <v-btn @click="setLogSeen(log)" color="primary" v-if="!log.seen">
                                    <v-icon left>mdi-eye-check</v-icon>
                                    {{ $t('logs.set_view_true') }}
                                </v-btn>

                                <v-btn @click="setLogSeen(log)" color="primary" v-else>
                                    <v-icon left>mdi-eye-remove</v-icon>
                                    {{ $t('logs.set_view_false') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <v-dialog v-model="dialog_delete" max-width="500px">
                        <v-card>
                            <v-card-title>
                                <span class="headline">{{ $t('logs.del_duplicates') }}</span>
                            </v-card-title>
                            <v-divider/>
                            <br/>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn @click="dialog_delete = false">
                                    <v-icon left>mdi-close</v-icon>
                                    {{ $t('global.cancel') }}
                                </v-btn>
                                <v-btn color="error" @click="confirmDelete">
                                    <v-icon left>mdi-delete</v-icon>
                                    {{ $t('logs.del_duplicates') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                </v-toolbar>
            </template>

            <template v-slot:item.message="{ item }">
                {{ item.message.substring(0, 100) + '...' }}
            </template>

            <template v-slot:item.action="{ item }">
                <v-icon class="pointer" @click="setLogSeen(item)" v-if="!log_seen">
                    mdi-eye-check
                </v-icon>
                <v-icon class="pointer" @click="setLogSeen(item)" v-else>
                    mdi-eye-remove
                </v-icon>
                <v-icon class="pointer" @click="openDialogShow(item)">
                    mdi-arrow-expand-all
                </v-icon>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
import {LOGS} from "../../../../api";
import moment from "moment";

export default {
    name: "DatatableLog",
    data() {
        return {
            logs: [],
            log: {
                id: null,
                message: '',
                level: null,
                level_name: '',
                context: '',
                extra: '',
                seen: 0,
                created_at: '',
            },
            headers: [
                {text: this.$t("logs.id"), value: 'id'},
                {text: this.$t("logs.created_at"), value: 'created_at'},
                {text: this.$t("logs.message"), value: 'message'},
                {text: this.$t("logs.level"), value: 'level'},
                {text: this.$t("logs.level_name"), value: 'level_name'},
                {text: this.$t("logs.context"), value: 'context'},
                {text: this.$t("logs.extra"), value: 'extra'},
                {text: this.$t("logs.actions"), value: 'action'},
            ],
            loading_datatable: true,
            search: '',
            log_seen: false,

            dialog_show: false,
            dialog_delete: false,

            log_level_selected: [],
            log_levels: [
                {
                    level: 100,
                    level_name: "Debug"
                },
                {
                    level: 200,
                    level_name: "Info"
                },
                {
                    level: 250,
                    level_name: "Notice"
                },
                {
                    level: 300,
                    level_name: "Warning"
                },
                {
                    level: 400,
                    level_name: "Error"
                },
                {
                    level: 500,
                    level_name: "Critical"
                },
                {
                    level: 550,
                    level_name: "Alert"
                },
                {
                    level: 600,
                    level_name: "Emergency"
                },
            ]
        }
    },
    created() {
        this.getLogs();
    },
    computed: {
        jsonParseContext() {
            if (this.log.context !== "") {
                return JSON.parse(this.log.context);
            }
        },

        jsonParseExtra() {
            if (this.log.context !== "") {
                return JSON.parse(this.log.extra);
            }
        },
    },
    methods: {
        getLogs() {
            this.loading_datatable = true;
            this.axios.get(
                LOGS + '?log_seen=' + this.log_seen + '&log_level_selected=' + this.log_level_selected
            ).then((success) => {
                success.data.forEach(log => {
                    log.created_at = moment(log.created_at).format('DD/MM/YYYY HH:mm');
                })
                this.logs = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            }).finally(() => {
                this.loading_datatable = false
            })
        },

        filterLogs() {
            this.log_seen = !this.log_seen;
            this.getLogs();
        },

        setLogSeenAll() {
            this.axios.patch(
                LOGS + '/seen_all'
            ).then(() => {
                this.showSnackbar('success', this.$t('global.success_to_edit'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            }).finally(() => {
                this.getLogs()
            })
        },

        setLogSeen(log) {
            this.axios.put(
                LOGS + '/' + log.id
            ).then(() => {
                this.logs.splice(this.logs.indexOf(log), 1)
                this.dialog_show = false;
                this.showSnackbar('success', this.$t('global.success_to_edit'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            }).finally(() => {
                this.getLogs()
            })
        },

        openDialogShow(log) {
            this.log = this.logs[this.logs.indexOf(log)];
            this.dialog_show = true;
        },

        openDialogDeleteDuplicates() {
            this.dialog_delete = true;
        },

        confirmDelete() {
            this.axios.delete(
                LOGS + '/delete_duplicates'
            ).then(() => {
                this.dialog_delete = false;
                this.showSnackbar('success', this.$t('global.success_to_delete'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_delete'));
            }).finally(() => {
                this.getLogs()
            })
        },
    }
}
</script>
