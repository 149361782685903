var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"2","outlined":""}},[_c('v-data-table',{staticClass:"elevation-1 datatable-setting-min-height",attrs:{"headers":_vm.headers,"items":_vm.machine_subassemblies,"sort-by":"id","loading":_vm.loading,"items-per-page":5,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"pt-3",attrs:{"flat":"","color":"white"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"elevation-0 pl-5 pt-2 pb-2"},[_c('h4',[_vm._v(_vm._s(_vm.$t('datatable_machine_subassemblies.title')))])])]),_vm._v(" "),_c('v-col',{staticStyle:{"display":"flex","justify-content":"end"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('global.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog_bind_subassembly),callback:function ($$v) {_vm.dialog_bind_subassembly=$$v},expression:"dialog_bind_subassembly"}},[_c('v-card',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{ref:"form"},[_c('v-card-title',{staticClass:"justify-center"},[_c('span',{staticClass:"headline"},[_vm._v("\n                                "+_vm._s(_vm.$t('datatable_machine_subassemblies.associate_to_another_machine'))+"\n                            ")])]),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('br'),_vm._v(" "),_c('v-card-text',[_c('validation-provider',{attrs:{"name":_vm.$t('global.label'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.machines,"label":_vm.$t('navbar.machines'),"prepend-inner-icon":'mdi-state-machine',"item-text":"label","item-value":"id","solo":"","error-messages":errors},model:{value:(_vm.machine_id_to_bind),callback:function ($$v) {_vm.machine_id_to_bind=$$v},expression:"machine_id_to_bind"}})]}}],null,true)})],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{on:{"click":function($event){return _vm.closeDialog()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close")]),_vm._v("\n                                        "+_vm._s(_vm.$t('global.cancel'))+"\n                                    ")],1),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":function($event){return _vm.bindSubassembly()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-link-variant-off")]),_vm._v("\n                                        "+_vm._s(_vm.$t('global.dissociate'))+"\n                                    ")],1)],1)],1)]}}])})],1)],1),_vm._v(" "),_c('v-snackbar',{attrs:{"color":_vm.snackbar_type},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-icon',{attrs:{"color":"white"},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("mdi-close")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v("\n                    "+_vm._s(_vm.snackbar_text)+"\n                    ")])],1)]},proxy:true},{key:"item.label",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ path: '/subassembly/' + item.id}}},[_vm._v("\n                "+_vm._s(item.label)+"\n            ")])]}},{key:"item.machine.path_plan",fn:function(ref){
var item = ref.item;
return [(item.machine.path_plan)?_c('v-img',{staticClass:"mb-2 mt-2",attrs:{"aspect-ratio":"1","src":item.machine.path_plan,"height":"200","contain":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0 background-loader-image",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black lighten-5"}})],1)]},proxy:true}],null,true)}):_vm._e()]}},{key:"item.path_image",fn:function(ref){
var item = ref.item;
return [(item.path_image)?_c('v-img',{staticClass:"mb-2 mt-2",attrs:{"aspect-ratio":"1","src":item.path_image,"height":"200","contain":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0 background-loader-image",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black lighten-5"}})],1)]},proxy:true}],null,true)}):_vm._e()]}},{key:"item.virtual_devices",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[(item.virtual_devices.length === 0)?_c('span',[_vm._v(_vm._s(_vm.$t('datatable_machine_subassemblies.no_virtual_devices')))]):_vm._l((item.virtual_devices),function(virtual_device){return [_c('v-chip',{staticClass:"mt-1 mb-1",attrs:{"color":_vm.assetColor(virtual_device),"text-color":"white"},on:{"click":function($event){_vm.$store.state.access_level !== 300 && _vm.$store.state.access_level !== 400 ? _vm.$router.push({ name: 'device', params: {id: virtual_device.id} }) : ''}}},[_vm._v("\n                        "+_vm._s(virtual_device.label)+"\n                    ")]),_vm._v(" "),_c('br')]})],2)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"pointer",on:{"click":function($event){return _vm.openDialogBindSubassembly(item)}}},[_vm._v("mdi-link-variant-off")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }