<template>
    <div id="global-snackbar">
        <v-snackbar v-model="show_snackbar" :color="type_snackbar">
            {{ text_snackbar }}
            <template v-slot:action>
                <v-icon @click="$store.dispatch('snackbar/hideSnackbar')" color="white">mdi-close</v-icon>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
export default {
    name: "GlobalSnackbar",
    computed: {
        show_snackbar: {
            get: function () {
                return this.$store.state.show_snackbar;
            },
            set: function (value) {
                this.$store.dispatch("snackbar/hideSnackbar");
            },
        },
        type_snackbar: function () {
            return this.$store.state.type_snackbar;
        },
        text_snackbar: function () {
            return this.$store.state.text_snackbar;
        },
    },
}
</script>
