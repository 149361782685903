<template>
    <div id="add-new-client">
        <v-tooltip bottom dark class="mr-2">
            <template v-slot:activator="{ on }">
                <v-btn text icon v-on="on" @click="dialog_add = true" class="mr-2" dusk="logout-button" style="border: 1px solid #01418145;">
                    <v-icon color="primary">mdi-plus</v-icon>
                </v-btn>
            </template>
            <span>{{ $t('clients.add-clients') }}</span>
        </v-tooltip>
        <v-dialog v-model="dialog_add" max-width="500px">
            <v-snackbar
                v-model="snackbar"
                :color="snackbar_type">
                {{ snackbar_text }}
                <template v-slot:action="{ attrs }">
                    <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                </template>
            </v-snackbar>
            <v-card>
                <validation-observer ref="observer" v-slot="{ invalid }">
                    <v-form ref="form">
                        <v-card-title>
                            <span class="headline">{{ $t('add_new_client.add_client') }}</span>
                        </v-card-title>
                        <v-divider></v-divider>

                        <v-card-text>
                            <validation-provider :name="$t('global.name')" rules="required"
                                                 v-slot="{ errors }">
                                <v-text-field clearable
                                              :label="$t('global.name')"
                                              v-model="professional.name"
                                              :prepend-icon="'mdi-label-outline'"
                                              :error-messages="errors"
                                />
                            </validation-provider>

                            <validation-provider :name="$t('sites.address')" rules="required"
                                                 v-slot="{ errors }">
                                <vuetify-google-autocomplete
                                    id="map"
                                    clearable
                                    ref="clientAddress"
                                    prepend-icon="mdi-map-marker"
                                    v-model="professional.address"
                                    @placechanged="setPlace"
                                    :error-messages="errors"
                                >
                                    <template #label>
                                        {{$t('sites.address')}}
                                    </template>
                                </vuetify-google-autocomplete>

                            </validation-provider>

                            <validation-provider :name="$t('add_new_client.email')" rules="required|email"
                                                 v-slot="{ errors }">
                                <v-text-field clearable
                                              :label="$t('add_new_client.email')"
                                              v-model="professional.mail"
                                              :prepend-icon="'mdi-at'"
                                              :error-messages="errors"
                                />
                            </validation-provider>
                            <validation-provider :name="$t('add_new_client.phone_number')" rules="required|digits:10"
                                                 v-slot="{ errors }">
                                <v-text-field clearable
                                              :label="$t('add_new_client.phone_number')"
                                              v-model="professional.phone_number"
                                              :prepend-icon="'mdi-phone'"
                                              :error-messages="errors"
                                />
                            </validation-provider>
                            <!-- OFFER -->
                            <validation-provider :name="$t('global.name')" rules="required"
                                                 v-slot="{ errors }">
                                <v-select
                                    :items="offers_labels"
                                    v-model="professional.offer"
                                    :label="$t('users.offer')"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                    :prepend-icon="'mdi-sitemap'"
                                    item-text="label"
                                    item-value="id"
                                    chips clearable
                                />
                            </validation-provider>
                            <!-- tags -->
                            <validation-provider :name="$t('global.name')" rules="required"
                                                 v-slot="{ errors }">
                                <v-combobox
                                    :items="tags"
                                    v-model="professional.tags"
                                    :label="$t('users.tags')"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                    :prepend-icon="'mdi-sitemap'"
                                    item-text="label"
                                    item-value="id"
                                    multiple chips clearable
                                />
                            </validation-provider>
                            <!-- logo -->
                            <validation-provider :name="$t('global.name')" rules="required"
                                                 v-slot="{ errors }">
                                <v-text-field solo
                                              :label="$t('users.logo')"
                                              v-model="professional.logo"
                                              :prepend-icon="'mdi-label-outline'"
                                              :error-messages="errors"
                                />
                            </validation-provider>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer/>
                            <v-btn @click="closeDialog();">
                                <v-icon left>mdi-close</v-icon>
                                {{ $t('global.close') }}
                            </v-btn>
                            <v-btn type="submit" color="primary" :disabled="invalid"
                                   @click.prevent="addProfessional()">
                                <v-icon left>mdi-content-save</v-icon>
                                {{ $t('global.validation') }}
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </validation-observer>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import {PROFESSIONALS} from "../../api";

export default {
    name: "AddNewClient",
    data() {
        return {
            invalid: false,
            dialog_add: false,
            offers_labels:[],
            professional: {
                name: '',
                mail: '',
                address: '',
                phone_number: '',
                offer: '',
                tags: '',
                state: '',
                logo: '',
                latitude: null,
                longitude: null
            },
            offers: [    
            {
                    level: 3,
                    level_name: "ONLINE & OFFLINE",
                    level_color: "#9900FF",
                    count:0
                },
                {
                    level: 2,
                    level_name: "CUSTOM",
                    level_color: "#CC3366",
                    count:0
                },
                {
                    level: 1,
                    level_name: "OFFLINE",
                    level_color: "#00ffff",
                    count:0
                },
                {
                    level: 0,
                    level_name: "ONLINE",
                    level_color: "#ff00ff",
                    count:0
                },
            ],
            tags: [
                "Interne",
                "Externe",
                "Démo",
                "Techview ONE",
                "Techview Edge",
                "Campagnes",
                "Ponctuel",
                "Machines tournantes",
                "Machines outils",
                "Thermographie",
                "Ultrasons",
                "Custom",
                "POC",
                "Projet",
                "ATEX",
                "IFM VSE",
                "DEV",
            ]
        }
    },
    created() {
        this.setOffersLabelsList();
    },
    methods: {
        addProfessional() {
            if(!this.professional.latitude || !this.professional.longitude){
                this.showSnackbar('error', this.$t('global.unvalid_address'));
            }
            else{
                this.axios.post(
                    PROFESSIONALS, this.professional
                ).then(() => {
                    location.reload();
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_add'));
                }).finally(() => {
                    this.professional.latitude=null;
                    this.professional.longitude=null;
                    this.closeDialog();
                });
            }
        },

        //TODO : Get offer from database
        getOffers() {
            //----------------------------------------------------------------------------------------
            //DEBUG :
            //console.log("");
            return true;
        },


        //TODO : Get tags from database
        getTags() {
            //----------------------------------------------------------------------------------------
            //DEBUG :
            //console.log("");
            return true;
        },

        setPlace(addressData) {
            this.professional.latitude = addressData.latitude;
            this.professional.longitude = addressData.longitude;
        },

        closeDialog() {
            this.$refs.clientAddress.clear();
            this.$refs.observer.reset();
            this.$refs.form.reset();
            this.dialog_add = false;
        },

        setOffersLabelsList(){
            this.offers.forEach(offer => {
                this.offers_labels.push(offer.level_name);
            });
        }
    }
}
</script>
