var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"machine-vision"}},[_c('v-col',{staticClass:"col col-12 mt-0 pt-0 pa-0",staticStyle:{"margin-top":"0px!important","padding":"0px","position":"relative","top":"-12px"}},[_c('div',{staticStyle:{"padding":"0px 12px"}},[_c('v-col',{staticClass:"pl-5 pr-5",staticStyle:{"background-color":"#001047","color":"#FFFFFF","border-radius":"0px 0px 5px 5px"}},[_c('v-row',{staticClass:"pl-0 pt-5 mb-5",staticStyle:{"height":"52px","padding":"5px"}},[_c('v-subheader',{staticClass:"v-subheader col theme--light"},[_c('v-tooltip',{staticClass:"mr-2 ml-2",attrs:{"bottom":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2 mr-2 v-size--default",staticStyle:{"border":"1px solid #cacaca","width":"18px","height":"18px"},attrs:{"text":"","icon":"","dusk":"logout-button"},on:{"click":function($event){return _vm.getNotification()}}},on),[_c('v-icon',{staticStyle:{"font-size":"16px"},attrs:{"color":"darkgrey"}},[_vm._v("mdi-information-variant")])],1)]}},{key:"default",fn:function(){return [_c('div',{staticStyle:{"min-width":"200px","position":"relative","z-index":"10"}},[_c('strong',[_vm._v("Page [Equipements]")]),_vm._v(" "),_c('p',[_vm._v("Toutes les machines que nous sommes susceptibles de surveiller se trouvent ici."),_c('br'),_vm._v("\n                                    Toutes les caractéristiques techniques associées au suivi en ligne sont configurés sur cette page."),_c('br'),_vm._v("\n                                    Ceci est valable par exemple pour les sous-ensembles. "),_c('br'),_vm._v("\n                                    Cliquer sur l'un d'entre eux pour le visualiser en détail.\n                                ")])])]},proxy:true}])}),_vm._v(" "),_c('p',{staticClass:"title mb-0 color-white",staticStyle:{"color":"white!important"}}),_c('div',{staticStyle:{"color":"#FFFFFFb3","margin-right":"4px"}},[_vm._v("Equipements > ")]),_c('div',{staticStyle:{"color":"#FFFFFF","font-weight":"bold"},on:{"click":function($event){return _vm.infoToggler()}}},[_vm._v(" "+_vm._s(_vm.$t('global.equipement_details')))]),_vm._v(" "),_c('p')],1),_vm._v(" "),_c('v-col',{staticClass:"row mt-1 col-auto pa-0 pr-5"},[(_vm.access_level <= 100)?_c('v-tooltip',{staticClass:"mr-2",attrs:{"bottom":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",staticStyle:{"border":"1px solid #FFFFFF45"},attrs:{"text":"","icon":"","dusk":"logout-button"},on:{"click":function($event){return _vm.$refs.VisionCards.openDialogAddMachine()}}},on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-plus")])],1)]}}],null,false,155326591)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('machines.add_machine')))])]):_vm._e(),_vm._v(" "),_c('v-tooltip',{staticClass:"mr-2",attrs:{"bottom":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",staticStyle:{"border":"1px solid #FFFFFF45"},attrs:{"text":"","icon":"","dusk":"logout-button"},on:{"click":function($event){return _vm.getData()}}},on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-tag-multiple")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('global.1_5_2')))])]),_vm._v(" "),_c('v-tooltip',{staticClass:"mr-2",attrs:{"bottom":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",staticStyle:{"border":"1px solid #FFFFFF45"},attrs:{"text":"","icon":"","dusk":"logout-button"},on:{"click":function($event){return _vm.getData()}}},on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-refresh")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('campain.refresh')))])])],1)],1),_vm._v(" "),(_vm.infoToggle == true)?_c('v-col',{staticClass:"mb-5",staticStyle:{"min-height":"250px","background-color":"#e8edff","border-radius":"5px"}},[_c('v-row')],1):_vm._e(),_vm._v(" "),(_vm.infoToggle == true)?_c('v-row',{staticStyle:{"border-top":"1px solid #ffffff1c"}},[_c('v-row',{staticClass:"pb-2 pt-2 pl-5",staticStyle:{"margin":"0px","min-height":"50px"}}),_vm._v(" "),_c('v-row',[_c('v-row',{staticClass:"v-subheader theme--light mr-3"},[_c('v-col',{staticClass:"col"}),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","dusk":"logout-button"},on:{"click":function($event){return _vm.setCards()}}},on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-card-text-outline")])],1)]}}],null,false,1339268795)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('notification.list-card')))])]),_vm._v(" "),(_vm.access_level <= 99)?_c('v-tooltip',{attrs:{"bottom":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","dusk":"logout-button"},on:{"click":function($event){return _vm.setDatatable()}}},on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-format-list-bulleted")])],1)]}}],null,false,1016746493)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('notification.list-view')))])]):_vm._e()],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.infoToggle == true)?_c('v-row',[_c('v-row',{staticClass:"pb-2",staticStyle:{"margin":"0px"}})],1):_vm._e()],1)],1)]),_vm._v(" "),(_vm.cards)?_c('vision-cards',{ref:"VisionCards",staticClass:"mt-0"}):_vm._e(),_vm._v(" "),(!_vm.cards)?_c('datatable-notifications',{staticClass:"mt-4"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }