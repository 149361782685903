<template>
    <div id="datatable-users">
        <v-card class="rounded-lg" elevation="2" outlined>
            <v-card-title>
                <v-spacer/>
            </v-card-title>

            <h2 v-if="this.$store.state.professional_id === null && this.$store.state.access_level !== 300"
                class="pl-4 pb-6"
            >
                <v-alert border="left" elevation="2" dense block
                         colored-border color="red darken-4" class="mr-4 ml-4 mt-4"
                >
                    <h2>{{ $t('global.no_client_selected') }}</h2>
                </v-alert>
            </h2>

            <v-data-table
                :headers="headers"
                :items="users"
                class="elevation-1"
                :item-per-page="5"
                :search="search"
                :loading="loading_datatable"
                v-else
            >
                <template v-slot:top>
                    <v-toolbar flat color="white" class="pt-3">
                        <v-layout class="justify-start">
                            <v-btn color="primary" class="white--text mr-3" @click="dialog_add = true">
                                {{ $t('users.add_user') }}
                            </v-btn>
                        </v-layout>

                        <v-card-title class="mt-5">
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                :label="$t('global.search')"
                            />
                        </v-card-title>

                        <v-dialog v-model="dialog_add" max-width="500px" eager>
                            <v-card>
                                <validation-observer ref="add_observer" v-slot="{ invalid }">
                                    <v-form ref="add_form">
                                        <v-card-title>
                                            <span class="headline">{{ $t('users.add_user') }}</span>
                                        </v-card-title>

                                        <v-divider/>

                                        <v-card-text>
                                            <validation-provider :name="$t('account_management.email')"
                                                                 rules="required" v-slot="{ errors }"
                                            >
                                                <v-textarea
                                                    :label="$t('account_management.email')"
                                                    v-model="add_users_email"
                                                    prepend-icon="mdi-at" solo
                                                    :error-messages="errors"
                                                />
                                            </validation-provider>
                                            <validation-provider :name="$t('users.role')"
                                                                 rules="required" v-slot="{ errors }"
                                            >
                                                <v-select
                                                    :items="roles"
                                                    v-model="role"
                                                    :label="$t('users.role')"
                                                    :prepend-icon="'mdi-alpha-r-circle-outline'"
                                                    :menu-props="{ bottom: true, offsetY: true }"
                                                    item-text="name" return-object
                                                    :error-messages="errors" solo
                                                />
                                            </validation-provider>
                                        </v-card-text>

                                        <v-card-actions>
                                            <v-spacer/>
                                            <v-btn @click="closeDialogAdd()">
                                                <v-icon left>mdi-close</v-icon>
                                                {{ $t('global.close') }}
                                            </v-btn>
                                            <v-btn type="submit" color="primary" :disabled="invalid"
                                                   @click.prevent="sendInvitationMail()"
                                            >
                                                <v-icon left>mdi-content-save</v-icon>
                                                {{ $t('global.validation') }}
                                            </v-btn>
                                        </v-card-actions>
                                    </v-form>
                                </validation-observer>
                            </v-card>
                        </v-dialog>

                        <v-dialog v-model="dialog_edit" max-width="500px" eager>
                            <v-card>
                                <validation-observer ref="observer" v-slot="{ invalid }">
                                    <v-form ref="form">
                                        <v-card-title>
                                            <span class="headline">{{ $t('users.edit_user') }}</span>
                                        </v-card-title>

                                        <v-divider/>

                                        <v-card-text>
                                            <validation-provider :name="$t('users.gender')" rules="required"
                                                                 v-slot="{ errors }">
                                                <v-text-field
                                                    :label="$t('users.gender')"
                                                    v-model="edited_user.gender"
                                                    :prepend-icon="'mdi-account-circle-outline'"
                                                    :error-messages="errors" solo
                                                />
                                            </validation-provider>

                                            <validation-provider :name="$t('users.last_name')" rules="required"
                                                                 v-slot="{ errors }">
                                                <v-text-field
                                                    :label="$t('users.last_name')"
                                                    :prepend-icon="'mdi-account-circle-outline'"
                                                    v-model="edited_user.last_name"
                                                    :error-messages="errors" solo
                                                />
                                            </validation-provider>

                                            <validation-provider :name="$t('users.first_name')" rules="required"
                                                                 v-slot="{ errors }">
                                                <v-text-field
                                                    :label="$t('users.first_name')"
                                                    :prepend-icon="'mdi-account-circle-outline'"
                                                    v-model="edited_user.first_name"
                                                    :error-messages="errors" solo
                                                />
                                            </validation-provider>

                                            <validation-provider :name="$t('users.email')" rules="required|email"
                                                                 v-slot="{ errors }">
                                                <v-text-field
                                                    :label="$t('users.email')"
                                                    :prepend-icon="'mdi-email-outline'"
                                                    v-model="edited_user.email"
                                                    :error-messages="errors" solo
                                                />
                                            </validation-provider>

                                            <validation-provider :name="$t('users.phone')" rules="required|digits:10"
                                                                 v-slot="{ errors }">
                                                <v-text-field
                                                    :label="$t('users.phone')"
                                                    :prepend-icon="'mdi-phone-outline'"
                                                    v-model="edited_user.phone"
                                                    :error-messages="errors" solo
                                                />
                                            </validation-provider>

                                            <validation-provider :name="$t('users.user_tags')"
                                                v-if="$store.state.access_level < 300"
                                            >
                                                <v-select
                                                    :items="referential_tags"
                                                    v-model="edited_user.tags"
                                                    :label="$t('users.user_tags')"
                                                    :prepend-icon="'mdi-tag-multiple-outline'"
                                                    :menu-props="{ bottom: true, offsetY: true }"
                                                    item-text="label"
                                                    item-value="id"
                                                    solo chips multiple deletable-chips
                                                />
                                            </validation-provider>

                                            <validation-provider
                                                :name="$t('users.role')"
                                                rules="required"
                                                v-slot="{ errors }"
                                            >
                                                <v-select
                                                    :items="roles"
                                                    v-model="edited_user.role_id"
                                                    :label="$t('users.role')"
                                                    :menu-props="{ bottom: true, offsetY: true }"
                                                    :prepend-icon="'mdi-alpha-r-circle-outline'"
                                                    item-text="name" item-value="id"
                                                    :error-messages="errors" solo
                                                />
                                            </validation-provider>

                                            <validation-provider :name="$t('users.password')" rules="required|min:7"
                                                                 vid="password"
                                                                 v-slot="{ errors }" v-if="edited_index === null">
                                                <v-text-field
                                                    v-model="edited_user.password"
                                                    :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                                                    :type="show_password ? 'text' : 'password'"
                                                    :label="$t('users.password')"
                                                    :prepend-icon="'mdi-lock-outline'"
                                                    :error-messages="errors" solo
                                                    @click:append="show_password = !show_password"
                                                />
                                            </validation-provider>

                                            <validation-provider :name="$t('users.password_confirm')"
                                                                 rules="required|confirmed:password"
                                                                 v-slot="{ errors }" v-if="edited_index === null">
                                                <v-text-field
                                                    v-model="edited_user.password_confirm" solo
                                                    :label="$t('users.password_confirm')"
                                                    :prepend-icon="'mdi-lock-check-outline'"
                                                    :error-messages="errors"
                                                    :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                                                    :type="show_confirm_password ? 'text' : 'password'"
                                                    @click:append="show_confirm_password = !show_confirm_password"
                                                />
                                            </validation-provider>
                                        </v-card-text>

                                        <v-card-actions>
                                            <v-spacer/>
                                            <v-btn @click="closeDialogEdit()">
                                                <v-icon left>mdi-close</v-icon>
                                                {{ $t('global.close') }}
                                            </v-btn>
                                            <v-btn type="submit" color="primary" :disabled="invalid"
                                                   @click.prevent="updateUser()">
                                                <v-icon left>mdi-content-save</v-icon>
                                                {{ $t('global.validation') }}
                                            </v-btn>
                                        </v-card-actions>
                                    </v-form>
                                </validation-observer>
                            </v-card>
                        </v-dialog>

                        <v-dialog v-model="dialog_delete" max-width="500px">
                            <v-card>
                                <v-card-title>
                                    <span class="headline">{{ $t('users.delete_user') }}</span>
                                </v-card-title>
                                <v-divider/>
                                <br/>

                                <v-card-actions>
                                    <v-spacer/>
                                    <v-btn @click="dialog_delete = false">
                                        <v-icon left>mdi-close</v-icon>
                                        {{ $t('global.cancel') }}
                                    </v-btn>
                                    <v-btn color="error" @click="confirmDelete()">
                                        <v-icon left>mdi-delete</v-icon>
                                        {{ $t('global.delete') }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>

                        <v-dialog v-model="dialog_change_password" max-width="500px">
                            <v-card :loading="loading_change_password">
                                <v-card-title>
                                    <span class="headline">{{ $t('users.edit_password') }}</span>
                                </v-card-title>
                                <v-divider/>

                                <v-card-text class="pt-4 d-flex justify-center font-weight-bold"
                                             v-if="new_password !== ''"
                                >
                                    <span>{{ $t('users.new_password') }} {{ new_password }}</span>
                                </v-card-text>

                                <v-card-actions class="mt-4">
                                    <v-spacer/>
                                    <v-btn @click="closeDialogChangePassword()">
                                        <v-icon left>mdi-close</v-icon>
                                        {{ $t('global.close') }}
                                    </v-btn>
                                    <v-btn type="submit" color="primary" v-if="new_password === ''"
                                           @click.prevent="updatePassword()">
                                        <v-icon left>mdi-content-save</v-icon>
                                        {{ $t('global.validation') }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>

                        <resend-invitation ref="resendInvitation"/>

                        <associate-user-to-site ref="associateUserToSite" v-on:getUsers="getUsers()"/>
                    </v-toolbar>
                </template>

                <template v-slot:item.tags="{ item }">
                    <template v-if="item.tags">
                        <v-chip-group>
                            <v-chip v-if="item.tags.length === 0" color="grey">
                                {{ $t('global.no_tags_associated') }}
                            </v-chip>
                            <v-chip v-for="tag in item.tags" class="pl-3 pr-3" link :key="tag.id">
                                {{ tag.label }}
                            </v-chip>
                        </v-chip-group>
                    </template>
                </template>

                <template v-slot:header.sites="{ header }">
                    {{ header.text }}
                    <v-tooltip top color="primary">
                        <span>{{ $t('users.description_associated_site') }}</span>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon color="primary" v-bind="attrs" v-on="on" small>mdi-information-outline</v-icon>
                        </template>
                    </v-tooltip>
                </template>

                <template v-slot:item.sites="{ item }">
                    <template v-if="item.sites">
                        <v-chip-group>
                            <v-chip v-if="item.sites.length === 0" color="grey">
                                {{ $t('users.no_sites_associated') }}
                            </v-chip>
                            <v-chip v-for="site in item.sites" class="pl-3 pr-3" link :key="site.id">
                                {{ site.label }}
                            </v-chip>
                        </v-chip-group>
                    </template>
                </template>

                <template v-slot:item.action="{ item }">
                    <v-icon @click="openDialogUpdateUser(item)" v-if="item.role_name !== 'Invité'">mdi-pencil</v-icon>
                    <v-icon @click="openDialogDeleteUser(item)" v-if="item.role_name !== 'Invité'">mdi-delete</v-icon>
                    <v-icon @click="openDialogEditPassword(item)"
                            v-if="$store.state.access_level <= 200 && item.role_name !== 'Invité'"
                    >
                        mdi-lock
                    </v-icon>
                    <v-icon @click="$refs.resendInvitation.openModalSendMail(item)" v-if="item.role_name === 'Invité'">
                        mdi-send
                    </v-icon>
                    <v-icon v-if="$store.state.access_level <= 300 && item.role_name !== 'Invité'"
                            @click="$refs.associateUserToSite.openDialogBindProfessional(item)"
                    >
                        mdi-link-box-variant-outline
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>

    </div>
</template>

<script>
import {PROFESSIONALS, REFERENTIAL_TAGS, ROLES, USERS} from "../../../api";
import ResendInvitation from "../../../admin/components/Setting/User/ResendInvitation";
import AssociateUserToSite from "./AssociateUserToSite";

export default {
    name: 'DatatableUsers',
    components: {ResendInvitation, AssociateUserToSite},
    data() {
        return {
            referential_tags: [],
            users: [],
            roles: [],
            headers: [
                {text: this.$t('users.last_name'), value: 'last_name'},
                {text: this.$t('users.first_name'), value: 'first_name'},
                {text: this.$t('users.email'), value: 'email'},
                {text: this.$t('users.phone'), value: 'phone'},
                {text: this.$t('users.role'), value: 'role_name'},
                {text: this.$t('users.role'), value: 'role_name'},
                this.$store.state.access_level < 300 ? {
                    text: this.$t('users.tags'),
                    value: 'tags'
                } : {align: ' d-none'},
                {text: this.$t('users.site'), value: 'sites'},
                {text: this.$t('global.actions'), value: 'action'},
            ],
            loading_datatable: true,
            search: '',
            show_password: false,
            show_confirm_password: false,

            dialog_edit: false,
            dialog_add: false,
            edited_index: null,
            edited_user: {
                id: null,
                gender: '',
                last_name: '',
                first_name: '',
                email: '',
                phone: '',
                tags: [],
                role_id: null,
                password: '',
                password_confirm: '',
            },
            invalid: false,

            dialog_delete: false,
            delete_id: null,
            deleted_index: null,

            dialog_change_password: false,
            new_password: '',
            loading_change_password: false,

            add_users_email: '',
            role: ''
        }
    },
    created() {
        if (this.$store.state.professional_id !== null || this.$store.state.access_level === 300) {
            this.userTableDisplay();
        }
    },
    methods: {
        userTableDisplay() {
            this.getUsers();
            if (this.$store.state.access_level === 100) {
                this.getInvitations();
            }
            this.getReferentialTags();
            this.getRoles();
        },

        getUsers() {
            this.loading_datatable = true;
            this.axios.get(
                PROFESSIONALS + '/users'
            ).then((success) => {
                this.users = success.data;
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('global.error'));
            }).finally(() => {
                this.loading_datatable = false;
            })
        },

        getInvitations() {
            this.axios.get(
                '/api/user_invitations'
            ).then((success) => {
                success.data.forEach(data => {
                    this.users.push({
                        email: data.email,
                        role_name: 'Invité',
                    });
                });
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('error_display.error_get_invitations'));
            }).finally(() => {
                this.loading_datatable = false;
            });
        },

        getRoles() {
            this.loading_datatable = true;
            this.roles = [];

            this.axios.get(
                ROLES
            ).then((success) => {
                success.data.forEach((role) => {
                    if (role.access_level === 300 || role.access_level === 400) {
                        this.roles.push(role);
                    }
                });
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('global.error'));
            }).finally(() => {
                this.loading_datatable = false;
            })
        },

        getReferentialTags() {
            this.axios.get(
                REFERENTIAL_TAGS
            ).then((success) => {
                this.referential_tags = success.data;
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('global.error'));
            });
        },

        openDialogUpdateUser(user) {
            this.edited_index = this.users.indexOf(user);
            this.edited_user = Object.assign({}, user);
            this.edited_user.tags.forEach((tag, index, array) => {
                array[index] = tag.id;
            });
            this.dialog_edit = true;
        },

        openDialogDeleteUser(user) {
            this.deleted_index = this.users.indexOf(user);
            this.delete_id = user.id;
            this.dialog_delete = true;
        },

        openDialogEditPassword(user) {
            this.edited_user = Object.assign({}, user);
            this.dialog_change_password = true;
        },

        sendInvitationMail() {
            this.axios.post('/api/send_invitation', {
                users_email: this.add_users_email,
                sender_name: this.$auth.user().last_name + ' ' + this.$auth.user().first_name,
                access_level: this.role.access_level,
                professional_id: true
            }).then((success) => {
                if (success.data.number_invitation_send === success.data.number_invitation) {
                    this.showGlobalSnackbar('success', this.$t('global.success_to_add'));
                } else {
                    this.showGlobalSnackbar('warning', this.$t('add_new_client.incomplete_invitation', {
                        invitation_receive: success.data.number_invitation_send,
                        invitation_send: success.data.number_invitation
                    }));
                }
                this.userTableDisplay();
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('global.error'))
            }).finally(() => {
                this.dialog_add = false;
                this.$refs.add_observer.reset();
                this.$refs.add_form.reset();
            });
        },

        updateUser() {
            this.axios.put(
                USERS + '/' + this.edited_user.id, this.edited_user
            ).then((success) => {
                Object.assign(this.users[this.edited_index], success.data);
                this.showGlobalSnackbar('success', this.$t('global.success_to_edit'));
                this.edited_index = null;
            }).catch(() => {
                this.showGlobalSnackbar('error', this.$t('global.failure_to_update'));
            }).finally(() => {
                this.closeDialogEdit();
            })
        },

        updatePassword() {
            this.loading_change_password = true;
            this.axios.get(
                USERS + '/new_password/' + this.edited_user.id
            ).then((success) => {
                this.new_password = success.data;
                this.loading_change_password = false;
            }).catch(() => {
                this.showGlobalSnackbar('error', this.$t('global.failure_to_update'));
            });
        },

        confirmDelete() {
            this.axios.delete(
                USERS + '/' + this.delete_id
            ).then(() => {
                this.users.splice(this.deleted_index, 1)
                this.showGlobalSnackbar('success', this.$t('global.success_to_delete'));
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('global.failure_to_delete'));
            }).finally(() => {
                this.dialog_delete = false;
            });
        },

        closeDialogEdit() {
            this.dialog_edit = false;
            this.$refs.form.reset();
            this.$refs.observer.reset();
        },

        closeDialogChangePassword() {
            this.dialog_change_password = false;
            this.new_password = '';
        },

        closeDialogAdd() {
            this.dialog_add = false;
            this.$refs.add_form.reset();
            this.$refs.add_observer.reset();
        }
    }
}
</script>
