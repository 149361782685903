import {SITES} from '../../api';

const state = () => ({
    sites: [],
    buildings: [],
});

const mutations = {
    setSites(state, payload) {
        this.state.sites = payload;
    },
};

const actions = {
    async load({commit}) {
        await axios.get(SITES)
            .then((response) => {
                commit('setSites', response.data);
            })
            .catch((error) => {
                console.log('Request products error', error);
            });
    },
};

const getters = {
    sites: state => {
        return state.sites;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
