<template>
    <v-col class="col mt-12" style="margin-top:0px!important;padding:0px;position:relative; top:-12px;">
        <!-- MACHINE MENU PACKAGE -->
        <div style="padding:0px 12px;">
            <v-col class="pl-5 pr-5" style="background-color:#001047;color:#FFFFFF;border-radius: 0px 0px 5px 5px;">
                <!-- TOP TITLE -->
                <v-row class="pl-0 pt-5 mb-5" style="height: 52px; padding:5px;">
                    <!-- MACHINE NAME -->
                    <v-col class="row mb-0 pa-0 mt-1 col-auto" @click="infoToggler()">
                        <!-- COUNTER -->
                        <v-col color="white" class="col-auto pa-0 mr-5" v-if="machine">
                            <v-col class="col pt-0 pb-0 mr-10 rounded col ml-6" style="height: 35px;background-color: rgb(92 101 123);font-weight: bold;text-align: center;font-size: 16px;border: 1px solid #ffffff5c;line-height: 35px;color: #FFFFFF;">
                                {{ machine.label }}
                            </v-col>
                        </v-col>
                    </v-col>
                    
                    <!-- PAGE TITLE -->
                    <v-subheader class="v-subheader col theme--light pl-3 ml-2">
                        <!------------------- Info bulle ---------------------->
                        <v-tooltip bottom dark class="mr-2 ml-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 mr-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                                    <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                                </v-btn>
                            </template>
                            <template v-slot:default>
                            <div style="min-width: 200px;position:relative;z-index: 10;">
                                <strong>Page [Centre d'analyses en ligne]</strong>
                                <p>Cet espace vous permet de manipuler toutes les données issues de la surveillance à distance.</br>
                                </p>
                            </div>
                            </template>
                        </v-tooltip>
                        <p class="title mb-0 color-white" style="color: white!important;">
                            <div style="color:#FFFFFFb3;margin-right:4px;">Equipements > {{ machine.label }} > </div><div @click="infoToggler()" style="color:#FFFFFF;font-weight:bold;"> {{ $t('machines.sandbox') }}</div>
                        </p>
                    </v-subheader>

                    <!-- GLOBAL ACTIONS -->
                    <v-col class="row mt-1 col-auto pa-0 pr-5">
                        <v-tooltip bottom dark class="mr-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="getData()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                    <v-icon color="white">mdi-tag-multiple</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('global.1_5_2') }}</span>
                        </v-tooltip>
                        <v-tooltip bottom dark class="mr-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="getData()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                    <v-icon color="white">mdi-refresh</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('machines.refresh') }}</span>
                        </v-tooltip>
                        
                        <v-tooltip v-if="infoToggle == true" bottom dark class="mr-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="infoToggler()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                    <v-icon color="white">mdi-minus-box-multiple</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('machines.minus') }}</span>
                        </v-tooltip>

                        <v-tooltip v-if="infoToggle == false" bottom dark class="mr-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="infoToggler()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                    <v-icon color="white">mdi-plus-box-multiple</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('machines.plus') }}</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
                <!-- MACHINE INFOS -->
                <v-col class="mb-4" v-if="infoToggle == true" style="min-height: 250px; background-color:#e8edff;border-radius:5px;">
                    <v-row>
                        <v-col class="col-4">
                            <machine-state/>
                        </v-col>
                        <v-col class="col pl-0">
                            <machine-specifications v-on:updateLabelMachine="updateLabelMachine"/>
                            <v-row>
                                <v-col class="col">
                                    <machine-subassemblies/>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
                <!-- MACHINE LINKS -->
                <v-row v-if="infoToggle == false" style="border-top: 1px solid #ffffff1c;">
                    <v-row style="margin:0px;">
                        <v-row class="ml-5 pt-5 pb-5 rounded" style="">
                            <!-- ALERTS -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/events'}" dusk="logout-button">
                                        <v-icon color="white">mdi-alert</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.alerts') }}</span>
                            </v-tooltip>

                            <!-- REPORTS -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/report'}" dusk="logout-button">
                                        <v-icon color="white">mdi-chart-box</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.reports') }}</span>
                            </v-tooltip>

                            <!-- DETAILS -->
                            <v-tooltip v-if="access_level <= 100 || access_level == 200 || access_level === 300" bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/details'}" dusk="logout-button">
                                        <v-icon color="white">mdi-chart-line</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.courbes') }}</span>
                            </v-tooltip>

                            <!-- SANDBOX -->
                            <v-tooltip bottom dark class="mr-2" v-if="$store.state.access_level <= 100">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" class="mr-5 col-0" :to="{ path: '/machine/' + id + '/sandbox'}" dusk="logout-button">
                                    <v-icon color="red">mdi-vector-curve</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('machines.sandbox') }}</span>
                        </v-tooltip>

                            <!-- Documents -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/documents'}" dusk="logout-button">
                                        <v-icon color="white">mdi-file-document</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machines.documents') }}</span>
                            </v-tooltip bottom dark class="mr-2">
                            
                            <!-- SETTINGS -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/settings'}" dusk="logout-button">
                                        <v-icon color="white">mdi-cog</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.settings') }}</span>
                            </v-tooltip bottom dark class="mr-2">
                        </v-row>     
                    </v-row>
                </v-row>

                <v-row v-if="infoToggle == true">
                    <v-row style="margin:0px;">
                        <v-row class="ml-5 pt-5 pb-5 rounded" style="">
                            <!-- ALERTS -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/events'}" dusk="logout-button">
                                        <v-icon color="white">mdi-alert</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.alerts') }}</span>
                            </v-tooltip>

                            <!-- REPORTS -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/report'}" dusk="logout-button">
                                        <v-icon color="white">mdi-chart-box</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.reports') }}</span>
                            </v-tooltip>

                            <!-- DETAILS -->
                            <v-tooltip v-if="access_level <= 100 || access_level == 200 || access_level === 300" bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/details'}" dusk="logout-button">
                                        <v-icon color="white">mdi-chart-line</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.courbes') }}</span>
                            </v-tooltip>

                            <!-- SANDBOX -->
                            <v-tooltip bottom dark class="mr-2" v-if="$store.state.access_level <= 100">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" class="mr-5 col-0" :to="{ path: '/machine/' + id + '/sandbox'}" dusk="logout-button">
                                    <v-icon color="red">mdi-vector-curve</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('machines.sandbox') }}</span>
                        </v-tooltip>

                            <!-- Documents -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/documents'}" dusk="logout-button">
                                        <v-icon color="white">mdi-file-document</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machines.documents') }}</span>
                            </v-tooltip bottom dark class="mr-2">
                            
                            <!-- SETTINGS -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/settings'}" dusk="logout-button">
                                        <v-icon color="white">mdi-cog</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.settings') }}</span>
                            </v-tooltip bottom dark class="mr-2">
                        </v-row>     
                    </v-row>
                </v-row>
            </v-col>
        </div>      
        
        <!-- CONTENT -->
        <v-col class="col pa-3 pt-6">
            <v-card class="pa-5">
                <!-- STYLE CHART -->
                <v-dialog width="200" v-model="dialogVisible">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn @click="openDialog" icon v-bind="attrs" v-on="on" style="width: 50px; background-color: #efefef; margin-left: 10px;">
                            <v-icon>mdi-cog-outline</v-icon>
                        </v-btn>
                    </template>
                    <!-- Contenu du menu de style -->
                    <v-card>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" style="flex-direction: column; display: flex; align-items: center; text-align: center;">
                                    <label>Couleur :</label>
                                    <input type="color" v-model="selectedColor" style="width: 50px;"/>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" style="flex-direction: column; display: flex; align-items: center; text-align: center;">                    
                                    <label>Style de ligne :</label>
                                    <select v-model="selectedLineStyle" style="width: 50px; text-align: center; background-color: #efefef;">
                                        <option value="Solid">───</option>
                                        <option value="Dash">---</option>
                                        <option value="Dot">•••</option>
                                        <option value="DashDot">-•-</option>                                                        
                                    </select>
                                </v-col>
                            </v-row>
                            
                            <v-row>
                                <v-col cols="12" style="flex-direction: column; display: flex; align-items: center; text-align: center;">
                                    <label>Taille de la ligne :</label>
                                    <input type="number" v-model="selectedLineWidth" min="0" style="width: 50px;"/>
                                </v-col>
                            </v-row>

                            
                            <v-row>
                                <v-col cols="12" style="flex-direction: column; display: flex; align-items: center; text-align: center;">   
                                    <label>Style de point :</label>
                                    <select v-model="selectedMarkerType" style="width: 50px; text-align: center; background-color: #efefef;">
                                        <option value="circle">●</option>
                                        <option value="square">■</option>
                                        <option value="diamond">◆</option>
                                        <option value="triangle">▲</option>
                                        <option value="triangle-down">▼</option>
                                    </select>
                                </v-col>
                            </v-row>

                            
                            <v-row>
                                <v-col cols="12" style="flex-direction: column; display: flex; align-items: center; text-align: center;">   
                                    <label>Taille du point :</label>
                                    <input type="number" v-model="selectedPointSize" min="1" style="width: 50px;"/>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" style="flex-direction: column; display: flex; align-items: center; text-align: center; margin-top: 10px;">
                                    <v-btn @click="applyStyleToSelectedCurves" style="width: 120px; background-color: #efefef; border-radius: 5px;">Appliquer</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-dialog>

                <!-- CHART -->
                <div>
                    <figure class="highcharts-figure" style="display: flex; flex-direction: column;">
                        <div id="container" style="width:100%; height:600px;"></div>
                    </figure>
                </div>
            </v-card>

            <!-- DATAS TABLE -->
            <v-card class="mt-6">
                <v-data-table
                    v-model="selected"
                    :headers="headers"
                    :items="seriesList"
                    item-key="name"
                    show-select
                    class="elevation-1"
                    :item-per-page=-1
                    hide-default-footer
                >
                    <!-- METRIQUE -->
                    <template v-slot:item.name="{ item }" >
                        <v-row style="padding: 20px 0;">
                            <p>
                                [metric type]
                                <br><span :style="{ color: item.color || '#000000' }">{{ item.name }}</span>
                                <br><span style="color: grey; font-style: italic; font-size: 14px">Type capteurs</span>
                            </p>
                        </v-row> 
                    </template>

                    <!-- UNITE -->
                    <template v-slot:item.unit="{ item }" >
                        {{ item.unit }}
                    </template>

                    <!-- NB POINTS -->
                    <template v-slot:item.data_infos.points_number="{ item }" >
                        {{ item.data_infos.points_number }}
                    </template>

                    <!-- VALEUR MOY -->
                    <template v-slot:item.data_infos.avg="{ item }" >
                        {{ item.data_infos.avg }}
                    </template>

                    <!-- VALEUR MIN -->
                    <template v-slot:item.data_infos.min="{ item }" >
                        {{ item.data_infos.min }}
                    </template>

                    <!-- VALEUR MAX -->
                    <template v-slot:item.data_infos.max="{ item }" >
                        {{ item.data_infos.max }}
                    </template>

                    <!-- ACTIONS -->
                    <template v-slot:item.actions="{ item }" >
                        <div style="display: flex; align-items: center; justify-content: center;">
                            <v-btn @click="toggleDisplay(item)" style="width: 50px; background-color: #efefef; margin-right: 10px;">
                                <v-icon>{{ item.toggle ? 'mdi-eye-outline' : 'mdi-eye-off-outline' }}</v-icon>
                            </v-btn>
                            <v-btn @click="togglePlotbands(item)" style="width: 50px; background-color: #efefef;">
                                <v-icon>mdi-window-open</v-icon>
                            </v-btn>
                        </div>
                    </template>
                </v-data-table>
            </v-card>
            

            <!-- SNACKBAR -->
            <v-snackbar
                v-model="snackbar"
                :color="snackbar_type">
                {{ snackbar_text }}
                <template v-slot:action="{ attrs }">
                    <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                </template>
            </v-snackbar>
        </v-col>
    </v-col>
</template>

<script>

import DocumentsUpload from "../../components/DocumentsUpload";
import DatatableMachineSubassemblies from "../../components/Machine/DatatableMachineSubassemblies";
import DatatableOperations from "../../components/DatatableOperations";
import ModalComparisonCurves from "../../components/Machine/ComparisonCurves/ModalComparisonCurves";
import ComparisonCurves from "../../components/Machine/ComparisonCurves/ComparisonCurves";
import ThreeDimensionalCurves from "../../components/Machine/ThreeDimensionalCurves/ThreeDimensionalCurves";
// FROM SUBASSEMBLY DETAILS
import DatatableSubassemblyOperations from "../../components/Subassembly/Operation/DatatableSubassemblyOperations";
import SubassemblyState from "../../components/Subassembly/SubassemblyState";
import FavoriteCurve from "../../components/Subassembly/Curve/FavoriteCurve";
import SubassemblySpecifications from "../../components/Subassembly/Specifications/SubassemblySpecifications";
import TimeCurve from '../../components/Subassembly/Curve/TimeCurve';
import SpectrumCurve from '../../components/Subassembly/Curve/SpectrumCurve';
import WordCurves from "../../components/Subassembly/WordCurves/WordCurves";

import {COMPARISON_CURVES, THREE_DIMENSION_CURVES, FAVORITE_CURVES, WORD_CURVE, MACHINES} from "../../../api";
import moment from "moment";

import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import Data from 'highcharts/modules/data';
import SeriesLabel from 'highcharts/modules/series-label';
import ExportData from 'highcharts/modules/export-data';
import Accessibility from 'highcharts/modules/accessibility';
import { forEach } from "lodash";

// Machine menu package //
import MachineSpecifications from "../../components/Machine/MachineSpecifications152";
import MachineState from "../../components/Machine/MachineState152";
import MachineSubassemblies from "../../components/Machine/MachineSubassemblies152";

Exporting(Highcharts);
Data(Highcharts);
SeriesLabel(Highcharts);
ExportData(Highcharts);
Accessibility(Highcharts);

export default {
    name: "DetailMachine",
    components: {
        ThreeDimensionalCurves,
        DatatableMachineSubassemblies,
        DatatableOperations,
        ModalComparisonCurves,
        ComparisonCurves,
        WordCurves,
        DocumentsUpload,
        DatatableSubassemblyOperations,
        SubassemblyState,
        FavoriteCurve,
        SubassemblySpecifications,
        SpectrumCurve,
        TimeCurve,
        MachineSpecifications,
        MachineState,
        MachineSubassemblies,
    },
    data() {
        return {
            selected: [],
            headers: [
                {text: "Métrique", value: "name", width: '38%', sortable: false,},    
                {text: "Unité", value: "unit", width: '10%'},
                {text: "Nombre de points", value: "data_infos.points_number", width: '12%'},
                {text: "Valeur moyenne", value: "data_infos.avg", width: '10%'},
                {text: "Valeur min.", value: "data_infos.min", width: '10%'},
                {text: "Valeur max", value: "data_infos.max", width: '10%'},
                {text: "Affichage", value: "actions", width: '10%', sortable: false,},
            ],
            id: this.$route.params.id,
            access_level: this.$store.state.access_level,
            
            type: "machine",
            machine:[],
            infoToggle: false,
            label: '',
            comparison_curves: [],
            three_dimensional_curves: [],
            machineTitle:"",

            type2: "subassembly",
            favorite_curves: [],
            word_curves: [],

            //chart
            selectedColor: '#7cb5ec',
            selectedLineStyle: 'Solid',
            selectedMarkerStyle: 'circle',
            menu: false,
            dateRangeText: '',
            range: [null, null],
            startTime: null,
            endTime: null,

            chartSeries: {
                series: null,
                flags: null,
            },
            info_data: {
                points_number: 0,
                avg: 0,
                min: 0,
                max: 0,
            },
            curves_form: [
                {color: "#FF0000", dashStyle: "Solid", lineWidth: 1, marker: {symbol: "triangle",}},
                {color: "#00FF00", dashStyle: "Solid", lineWidth: 1, marker: {symbol: "triangle-down",}},
                {color: "#DEDE00", dashStyle: "Solid", lineWidth: 1, marker: {symbol: "circle",}},
                {color: "#0000FF", dashStyle: "Solid", lineWidth: 1, marker: {symbol: "square",}},
                {color: "#FF00FF", dashStyle: "Solid", lineWidth: 1, marker: {symbol: "diamond",}},
                {color: "#00C7C7", dashStyle: "Solid", lineWidth: 1, marker: {symbol: "triangle",}},
                {color: "#C0C0C0", dashStyle: "Solid", lineWidth: 1, marker: {symbol: "triangle-down",}},
                {color: "#D2691E", dashStyle: "Solid", lineWidth: 1, marker: {symbol: "circle",}},
                {color: "#000000", dashStyle: "Solid", lineWidth: 1, marker: {symbol: "circle",}},
                {color: "#800000", dashStyle: "Solid", lineWidth: 1, marker: {symbol: "square",}},
                {color: "#008000", dashStyle: "Solid", lineWidth: 1, marker: {symbol: "diamond",}},
                {color: "#808000", dashStyle: "Solid", lineWidth: 1, marker: {symbol: "triangle",}},
                {color: "#000080", dashStyle: "Solid", lineWidth: 1, marker: {symbol: "triangle-down",}},
                {color: "#800080", dashStyle: "Solid", lineWidth: 1, marker: {symbol: "circle",}},
                {color: "#008080", dashStyle: "Solid", lineWidth: 1, marker: {symbol: "square",}},
                {color: "#808080", dashStyle: "Solid", lineWidth: 1, marker: {symbol: "diamond",}},
                
            ],
            curves:[],

            dialogVisible: false,
            selectedCurves: [],
            selectedColor: "#000000",
            selectedLineStyle: "Solid",
            selectedLineWidth: 2,
            selectedMarkerType: "circle",
            selectedPointSize: 4,

            seriesList: [],
            plotbandsAdded: false,
            prealertThreshold: 120,
            alertThreshold: 150,

            series1: {
                metric_thresholds: {
                    alert_high: 150,
                    prealert_high: 120
                },
                data_metric: {
                    data: [
                        {
                            date: "2024-10-14T21:16:00Z",
                            value: "120.49580812454224"
                        },
                        {
                            date: "2024-10-14T22:14:00Z",
                            value: "126.72863006591797"
                        },
                        {
                            date: "2024-10-14T23:12:00Z",
                            value: "129.21741008758545"
                        },
                        {
                            date: "2024-10-15T00:10:00Z",
                            value: "129.3234944343567"
                        },
                        {
                            date: "2024-10-15T01:08:00Z",
                            value: "139.64290618896484"
                        },
                        {
                            date: "2024-10-15T02:06:00Z",
                            value: "127.64248847961426"
                        },
                        {
                            date: "2024-10-15T03:04:00Z",
                            value: "129.81590032577515"
                        },
                        {
                            date: "2024-10-15T04:02:00Z",
                            value: "126.31728649139404"
                        },
                        {
                            date: "2024-10-15T05:00:00Z",
                            value: "128.0212640762329"
                        },
                        {
                            date: "2024-10-15T05:58:00Z",
                            value: "127.42592096328735"
                        },
                        {
                            date: "2024-10-15T06:56:00Z",
                            value: "127.67024040222168"
                        },
                        {
                            date: "2024-10-15T07:54:00Z",
                            value: "132.63826370239258"
                        },
                        {
                            date: "2024-10-15T08:52:00Z",
                            value: "121.0106611251831"
                        },
                        {
                            date: "2024-10-15T09:50:00Z",
                            value: "118.26249361038208"
                        },
                        {
                            date: "2024-10-15T10:48:00Z",
                            value: "119.52393054962158"
                        },
                        {
                            date: "2024-10-15T11:46:00Z",
                            value: "116.61292314529419"
                        },
                        {
                            date: "2024-10-15T12:44:00Z",
                            value: "117.05234050750732"
                        },
                        {
                            date: "2024-10-15T13:42:00Z",
                            value: "122.20193147659302"
                        },
                        {
                            date: "2024-10-15T14:40:00Z",
                            value: "113.00199031829834"
                        },
                        {
                            date: "2024-10-15T15:38:00Z",
                            value: "117.83583164215088"
                        },
                        {
                            date: "2024-10-15T16:36:00Z",
                            value: "113.66026401519775"
                        },
                        {
                            date: "2024-10-15T17:34:00Z",
                            value: "114.11890983581543"
                        },
                        {
                            date: "2024-10-15T18:32:00Z",
                            value: "123.00220727920532"
                        },
                        {
                            date: "2024-10-15T19:30:00Z",
                            value: "120.30211687088013"
                        },
                        {
                            date: "2024-10-15T20:28:00Z",
                            value: "118.7548041343689"
                        },
                        {
                            date: "2024-10-15T21:26:00Z",
                            value: "125.36543607711792"
                        },
                        {
                            date: "2024-10-15T22:24:00Z",
                            value: "116.53835773468018"
                        },
                        {
                            date: "2024-10-15T23:22:00Z",
                            value: "119.02621984481812"
                        },
                        {
                            date: "2024-10-16T00:20:00Z",
                            value: "117.24570989608765"
                        },
                        {
                            date: "2024-10-16T01:18:00Z",
                            value: "119.9781060218811"
                        },
                        {
                            date: "2024-10-16T02:16:00Z",
                            value: "132.48802423477173"
                        },
                        {
                            date: "2024-10-16T03:14:00Z",
                            value: "127.22657918930054"
                        },
                        {
                            date: "2024-10-16T04:12:00Z",
                            value: "119.68623399734497"
                        },
                        {
                            date: "2024-10-16T05:10:00Z",
                            value: "126.5294075012207"
                        },
                        {
                            date: "2024-10-16T06:08:00Z",
                            value: "131.00926876068115"
                        },
                        {
                            date: "2024-10-16T07:06:00Z",
                            value: "126.08872652053833"
                        },
                        {
                            date: "2024-10-16T08:04:00Z",
                            value: "116.85807704925537"
                        },
                        {
                            date: "2024-10-16T09:02:00Z",
                            value: "126.40278339385986"
                        },
                        {
                            date: "2024-10-16T10:00:00Z",
                            value: "116.06724262237549"
                        },
                        {
                            date: "2024-10-16T10:58:00Z",
                            value: "116.7768120765686"
                        },
                        {
                            date: "2024-10-16T11:56:00Z",
                            value: "122.07282781600952"
                        },
                        {
                            date: "2024-10-16T12:54:00Z",
                            value: "119.24129724502563"
                        },
                        {
                            date: "2024-10-16T13:52:00Z",
                            value: "110.16175746917725"
                        },
                        {
                            date: "2024-10-16T14:50:00Z",
                            value: "115.68574905395508"
                        },
                        {
                            date: "2024-10-16T15:48:00Z",
                            value: "115.14540910720825"
                        },
                        {
                            date: "2024-10-16T16:46:00Z",
                            value: "119.57077980041504"
                        },
                        {
                            date: "2024-10-16T17:44:00Z",
                            value: "116.6229248046875"
                        },
                        {
                            date: "2024-10-16T18:42:00Z",
                            value: "118.56456995010376"
                        },
                        {
                            date: "2024-10-16T19:40:00Z",
                            value: "120.2122449874878"
                        },
                        {
                            date: "2024-10-16T20:38:00Z",
                            value: "132.7992558479309"
                        },
                        {
                            date: "2024-10-16T21:36:00Z",
                            value: "127.94162034988403"
                        },
                        {
                            date: "2024-10-16T22:34:00Z",
                            value: "121.83084487915039"
                        },
                        {
                            date: "2024-10-16T23:32:00Z",
                            value: "125.60487985610962"
                        },
                        {
                            date: "2024-10-17T00:30:00Z",
                            value: "126.85604095458984"
                        },
                        {
                            date: "2024-10-17T01:28:00Z",
                            value: "128.83706092834473"
                        },
                        {
                            date: "2024-10-17T02:26:00Z",
                            value: "132.48313665390015"
                        },
                        {
                            date: "2024-10-17T03:24:00Z",
                            value: "120.38999795913696"
                        },
                        {
                            date: "2024-10-17T04:22:00Z",
                            value: "118.3869481086731"
                        },
                        {
                            date: "2024-10-17T05:20:00Z",
                            value: "121.23717069625854"
                        },
                        {
                            date: "2024-10-17T06:18:00Z",
                            value: "124.59520101547241"
                        },
                        {
                            date: "2024-10-17T07:16:00Z",
                            value: "119.79007720947266"
                        },
                        {
                            date: "2024-10-17T08:14:00Z",
                            value: "133.53568315505981"
                        },
                        {
                            date: "2024-10-17T09:12:00Z",
                            value: "118.64705085754395"
                        },
                        {
                            date: "2024-10-17T10:10:00Z",
                            value: "122.16553688049316"
                        },
                        {
                            date: "2024-10-17T11:08:00Z",
                            value: "120.91201543807983"
                        },
                        {
                            date: "2024-10-17T12:06:00Z",
                            value: "116.04307889938354"
                        },
                        {
                            date: "2024-10-17T13:04:00Z",
                            value: "122.83053398132324"
                        },
                        {
                            date: "2024-10-17T14:02:00Z",
                            value: "134.07195806503296"
                        },
                        {
                            date: "2024-10-17T15:00:00Z",
                            value: "140.26060104370117"
                        },
                        {
                            date: "2024-10-17T15:58:00Z",
                            value: "134.3489408493042"
                        },
                        {
                            date: "2024-10-17T16:56:00Z",
                            value: "140.62519073486328"
                        },
                        {
                            date: "2024-10-17T17:54:00Z",
                            value: "121.59168720245361"
                        },
                        {
                            date: "2024-10-17T18:52:00Z",
                            value: "122.3855972290039"
                        },
                        {
                            date: "2024-10-17T19:50:00Z",
                            value: "130.07606267929077"
                        },
                        {
                            date: "2024-10-17T20:48:00Z",
                            value: "126.09527111053467"
                        },
                        {
                            date: "2024-10-17T21:46:00Z",
                            value: "123.70967864990234"
                        },
                        {
                            date: "2024-10-17T22:44:00Z",
                            value: "128.63829135894775"
                        },
                        {
                            date: "2024-10-17T23:42:00Z",
                            value: "128.16654443740845"
                        },
                        {
                            date: "2024-10-18T00:40:00Z",
                            value: "126.2900710105896"
                        },
                        {
                            date: "2024-10-18T01:38:00Z",
                            value: "128.43503952026367"
                        },
                        {
                            date: "2024-10-18T02:36:00Z",
                            value: "123.16629886627197"
                        },
                        {
                            date: "2024-10-18T03:34:00Z",
                            value: "134.98560190200806"
                        },
                        {
                            date: "2024-10-18T04:32:00Z",
                            value: "126.86642408370972"
                        },
                        {
                            date: "2024-10-18T05:30:00Z",
                            value: "127.40586996078491"
                        },
                        {
                            date: "2024-10-18T06:28:00Z",
                            value: "124.14151430130005"
                        },
                        {
                            date: "2024-10-18T07:26:00Z",
                            value: "122.54308462142944"
                        },
                        {
                            date: "2024-10-18T08:24:00Z",
                            value: "124.10058975219727"
                        },
                        {
                            date: "2024-10-18T09:22:00Z",
                            value: "122.28703498840332"
                        },
                        {
                            date: "2024-10-18T10:20:00Z",
                            value: "129.3450951576233"
                        },
                        {
                            date: "2024-10-18T11:18:00Z",
                            value: "124.73859786987305"
                        },
                        {
                            date: "2024-10-18T12:16:00Z",
                            value: "126.75328254699707"
                        },
                        {
                            date: "2024-10-18T13:14:00Z",
                            value: "124.53625202178955"
                        },
                        {
                            date: "2024-10-18T14:12:00Z",
                            value: "128.3927321434021"
                        },
                        {
                            date: "2024-10-18T15:10:00Z",
                            value: "139.5243763923645"
                        },
                        {
                            date: "2024-10-18T16:08:00Z",
                            value: "131.10164403915405"
                        },
                        {
                            date: "2024-10-18T17:06:00Z",
                            value: "135.08044481277466"
                        },
                        {
                            date: "2024-10-18T18:04:00Z",
                            value: "129.80624437332153"
                        },
                        {
                            date: "2024-10-18T19:02:00Z",
                            value: "134.04725790023804"
                        },
                        {
                            date: "2024-10-18T20:00:00Z",
                            value: "127.99065113067627"
                        },
                        {
                            date: "2024-10-18T20:58:00Z",
                            value: "132.46406316757202"
                        },
                        {
                            date: "2024-10-18T21:56:00Z",
                            value: "126.6897201538086"
                        },
                        {
                            date: "2024-10-18T22:54:00Z",
                            value: "126.53735876083374"
                        },
                        {
                            date: "2024-10-18T23:52:00Z",
                            value: "127.92835235595703"
                        },
                        {
                            date: "2024-10-19T00:50:00Z",
                            value: "130.8250069618225"
                        },
                        {
                            date: "2024-10-19T01:48:00Z",
                            value: "126.718270778656"
                        },
                        {
                            date: "2024-10-19T02:46:00Z",
                            value: "131.0092806816101"
                        },
                        {
                            date: "2024-10-19T03:44:00Z",
                            value: "139.2904281616211"
                        },
                        {
                            date: "2024-10-19T04:42:00Z",
                            value: "142.46021509170532"
                        },
                        {
                            date: "2024-10-19T05:40:00Z",
                            value: "132.8189492225647"
                        },
                        {
                            date: "2024-10-19T06:38:00Z",
                            value: "130.4774522781372"
                        },
                        {
                            date: "2024-10-19T07:36:00Z",
                            value: "138.1911039352417"
                        },
                        {
                            date: "2024-10-19T08:34:00Z",
                            value: "130.65131902694702"
                        },
                        {
                            date: "2024-10-19T09:32:00Z",
                            value: "124.69491958618164"
                        },
                        {
                            date: "2024-10-19T10:30:00Z",
                            value: "122.93180227279663"
                        },
                        {
                            date: "2024-10-19T11:28:00Z",
                            value: "117.84650087356567"
                        },
                        {
                            date: "2024-10-19T12:26:00Z",
                            value: "121.37371301651001"
                        },
                        {
                            date: "2024-10-19T13:24:00Z",
                            value: "119.78106498718262"
                        },
                        {
                            date: "2024-10-19T14:22:00Z",
                            value: "118.54524612426758"
                        },
                        {
                            date: "2024-10-19T15:20:00Z",
                            value: "121.2064266204834"
                        },
                        {
                            date: "2024-10-19T16:18:00Z",
                            value: "120.88481187820435"
                        },
                        {
                            date: "2024-10-19T17:16:00Z",
                            value: "122.43337631225586"
                        },
                        {
                            date: "2024-10-19T18:14:00Z",
                            value: "132.3729395866394"
                        },
                        {
                            date: "2024-10-19T19:12:00Z",
                            value: "128.62907648086548"
                        },
                        {
                            date: "2024-10-19T20:10:00Z",
                            value: "130.27772903442383"
                        },
                        {
                            date: "2024-10-19T21:08:00Z",
                            value: "125.57960748672485"
                        },
                        {
                            date: "2024-10-19T22:06:00Z",
                            value: "126.89052820205688"
                        },
                        {
                            date: "2024-10-19T23:04:00Z",
                            value: "125.5361557006836"
                        },
                        {
                            date: "2024-10-20T00:02:00Z",
                            value: "128.59337329864502"
                        },
                        {
                            date: "2024-10-20T01:00:00Z",
                            value: "131.03320598602295"
                        },
                        {
                            date: "2024-10-20T01:58:00Z",
                            value: "133.732008934021"
                        },
                        {
                            date: "2024-10-20T02:56:00Z",
                            value: "130.4381251335144"
                        },
                        {
                            date: "2024-10-20T03:54:00Z",
                            value: "136.29448413848877"
                        },
                        {
                            date: "2024-10-20T04:52:00Z",
                            value: "140.84676504135132"
                        },
                        {
                            date: "2024-10-20T05:50:00Z",
                            value: "124.48296546936035"
                        },
                        {
                            date: "2024-10-20T06:48:00Z",
                            value: "127.56564617156982"
                        },
                        {
                            date: "2024-10-20T07:46:00Z",
                            value: "129.90589141845703"
                        },
                        {
                            date: "2024-10-20T08:44:00Z",
                            value: "124.0025281906128"
                        },
                        {
                            date: "2024-10-20T09:42:00Z",
                            value: "118.13939809799194"
                        },
                        {
                            date: "2024-10-20T10:40:00Z",
                            value: "116.8300986289978"
                        },
                        {
                            date: "2024-10-20T11:38:00Z",
                            value: "118.75280141830444"
                        },
                        {
                            date: "2024-10-20T12:36:00Z",
                            value: "117.9059386253357"
                        },
                        {
                            date: "2024-10-20T13:34:00Z",
                            value: "118.24883222579956"
                        },
                        {
                            date: "2024-10-20T14:32:00Z",
                            value: "117.88482666015625"
                        },
                        {
                            date: "2024-10-20T15:30:00Z",
                            value: "117.97560453414917"
                        },
                        {
                            date: "2024-10-20T16:28:00Z",
                            value: "117.6202654838562"
                        },
                        {
                            date: "2024-10-20T17:26:00Z",
                            value: "124.30610656738281"
                        },
                        {
                            date: "2024-10-20T18:24:00Z",
                            value: "126.01959705352783"
                        },
                        {
                            date: "2024-10-20T19:22:00Z",
                            value: "130.96178770065308"
                        },
                        {
                            date: "2024-10-20T20:20:00Z",
                            value: "126.83966159820557"
                        },
                        {
                            date: "2024-10-20T21:18:00Z",
                            value: "127.00344324111938"
                        },
                        {
                            date: "2024-10-20T22:16:00Z",
                            value: "127.58711576461792"
                        },
                        {
                            date: "2024-10-20T23:14:00Z",
                            value: "132.27393627166748"
                        },
                        {
                            date: "2024-10-21T00:12:00Z",
                            value: "136.64237260818481"
                        },
                        {
                            date: "2024-10-21T01:10:00Z",
                            value: "139.5808458328247"
                        },
                        {
                            date: "2024-10-21T02:08:00Z",
                            value: "137.19033002853394"
                        },
                        {
                            date: "2024-10-21T03:06:00Z",
                            value: "151.40001773834229"
                        },
                        {
                            date: "2024-10-21T04:04:00Z",
                            value: "137.5361204147339"
                        },
                        {
                            date: "2024-10-21T05:02:00Z",
                            value: "137.52973079681396"
                        },
                        {
                            date: "2024-10-21T06:00:00Z",
                            value: "147.73545265197754"
                        },
                        {
                            date: "2024-10-21T06:58:00Z",
                            value: "133.7940216064453"
                        },
                        {
                            date: "2024-10-21T07:56:00Z",
                            value: "131.90264701843262"
                        },
                        {
                            date: "2024-10-21T08:54:00Z",
                            value: "124.99552965164185"
                        },
                        {
                            date: "2024-10-21T09:52:00Z",
                            value: "128.11497449874878"
                        },
                        {
                            date: "2024-10-21T10:50:00Z",
                            value: "125.68135261535645"
                        },
                        {
                            date: "2024-10-21T11:48:00Z",
                            value: "119.81303691864014"
                        },
                        {
                            date: "2024-10-21T12:46:00Z",
                            value: "119.88774538040161"
                        },
                        {
                            date: "2024-10-21T13:44:00Z",
                            value: "116.89170598983765"
                        },
                        {
                            date: "2024-10-21T14:42:00Z",
                            value: "113.0655288696289"
                        },
                        {
                            date: "2024-10-21T15:40:00Z",
                            value: "119.55821514129639"
                        },
                        {
                            date: "2024-10-21T16:38:00Z",
                            value: "121.32763862609863"
                        },
                        {
                            date: "2024-10-21T17:36:00Z",
                            value: "123.75717163085938"
                        },
                        {
                            date: "2024-10-21T18:34:00Z",
                            value: "123.4722375869751"
                        },
                        {
                            date: "2024-10-21T19:32:00Z",
                            value: "124.24885034561157"
                        },
                        {
                            date: "2024-10-21T20:30:00Z",
                            value: "120.73441743850708"
                        },
                        {
                            date: "2024-10-21T21:28:00Z",
                            value: "126.73228979110718"
                        },
                        {
                            date: "2024-10-21T22:26:00Z",
                            value: "127.48160362243652"
                        },
                        {
                            date: "2024-10-21T23:24:00Z",
                            value: "129.37711477279663"
                        },
                        {
                            date: "2024-10-22T00:22:00Z",
                            value: "125.58513879776001"
                        },
                        {
                            date: "2024-10-22T01:20:00Z",
                            value: "128.76920700073242"
                        },
                        {
                            date: "2024-10-22T02:18:00Z",
                            value: "126.91081762313843"
                        },
                        {
                            date: "2024-10-22T03:16:00Z",
                            value: "125.18882751464844"
                        },
                        {
                            date: "2024-10-22T04:14:00Z",
                            value: "127.49555110931396"
                        },
                        {
                            date: "2024-10-22T05:12:00Z",
                            value: "127.2390365600586"
                        },
                        {
                            date: "2024-10-22T06:10:00Z",
                            value: "1.0902805253863335"
                        },
                        {
                            date: "2024-10-22T07:08:00Z",
                            value: "1.0626590810716152"
                        },
                        {
                            date: "2024-10-22T08:06:00Z",
                            value: "1.0369839146733284"
                        },
                        {
                            date: "2024-10-22T09:04:00Z",
                            value: "1.0701818391680717"
                        },
                        {
                            date: "2024-10-22T10:02:00Z",
                            value: "1.0916460305452347"
                        },
                        {
                            date: "2024-10-22T11:00:00Z",
                            value: "1.0894857347011566"
                        },
                        {
                            date: "2024-10-22T11:58:00Z",
                            value: "1.0316374711692333"
                        },
                        {
                            date: "2024-10-22T12:56:00Z",
                            value: "1.0095996782183647"
                        },
                        {
                            date: "2024-10-22T13:54:00Z",
                            value: ""
                        },
                        {
                            date: "2024-10-22T14:52:00Z",
                            value: ""
                        },
                        {
                            date: "2024-10-22T15:50:00Z",
                            value: ""
                        },
                        {
                            date: "2024-10-22T16:48:00Z",
                            value: ""
                        },
                        {
                            date: "2024-10-22T17:46:00Z",
                            value: ""
                        },
                        {
                            date: "2024-10-22T18:44:00Z",
                            value: ""
                        },
                        {
                            date: "2024-10-22T19:42:00Z",
                            value: ""
                        },
                        {
                            date: "2024-10-22T20:40:00Z",
                            value: ""
                        },
                        {
                            date: "2024-10-22T21:38:00Z",
                            value: ""
                        },
                    ],
                    "operations": []
                }
            },
            series2: [
                    {
                        id: 1407,
                        id_relatable: 36,
                        type_relatable: "subassembly",
                        label: "Génératrice arbre supérieur : Globale HF palier haut",
                        chart_type: 1,
                        metric_uuid: "99159a7e-a388-11ec-98bc-d6683707f645",
                        metric_unit: "G RMS",
                        rotation_speed: null,
                        virtual_device_id: 132,
                        professional_id: 14,
                        created_at: "2023-01-27T16:23:28.000000Z",
                        updated_at: "2023-01-27T16:23:28.000000Z"
                    },
                    {
                        id: 1408,
                        id_relatable: 36,
                        type_relatable: "subassembly",
                        label: "Génératrice arbre supérieur : Globale BF palier haut",
                        chart_type: 1,
                        metric_uuid: "9925b06c-a388-11ec-bf64-ae2116f036ed",
                        metric_unit: "mm/s RMS",
                        rotation_speed: null,
                        virtual_device_id: 132,
                        professional_id: 14,
                        created_at: "2023-01-27T16:23:47.000000Z",
                        updated_at: "2023-01-27T16:23:47.000000Z"
                    },
                    {
                        id: 1409,
                        id_relatable: 36,
                        type_relatable: "subassembly",
                        label: "Génératrice arbre supérieur : Globale HF palier bas",
                        chart_type: 1,
                        metric_uuid: "993999f6-a388-11ec-aaf6-ae2116f036ed",
                        metric_unit: "G RMS",
                        rotation_speed: null,
                        virtual_device_id: 132,
                        professional_id: 14,
                        created_at: "2023-01-27T16:24:01.000000Z",
                        updated_at: "2023-01-27T16:24:01.000000Z"
                    },
                    {
                        id: 1410,
                        id_relatable: 36,
                        type_relatable: "subassembly",
                        label: "Génératrice arbre supérieur : Globale BF palier bas",
                        chart_type: 1,
                        metric_uuid: "99505b96-a388-11ec-89cd-d6683707f645",
                        metric_unit: "mm/s RMS",
                        rotation_speed: null,
                        virtual_device_id: 132,
                        professional_id: 14,
                        created_at: "2023-01-27T16:24:16.000000Z",
                        updated_at: "2023-01-27T16:24:16.000000Z"
                    },
                    {
                        id: 1411,
                        id_relatable: 36,
                        type_relatable: "subassembly",
                        label: "Génératrice arbre inferieur: Globale HF palier haut",
                        chart_type: 1,
                        metric_uuid: "1904068e-a38a-11ec-ad6c-363429fcd3e5",
                        metric_unit: "G RMS",
                        rotation_speed: null,
                        virtual_device_id: 133,
                        professional_id: 14,
                        created_at: "2023-01-27T16:24:55.000000Z",
                        updated_at: "2023-01-27T16:24:55.000000Z"
                    },
                    {
                        id: 1412,
                        id_relatable: 36,
                        type_relatable: "subassembly",
                        label: "Génératrice arbre inferieur : Globale BF palier haut",
                        chart_type: 1,
                        metric_uuid: "e2f49094-a38f-11ec-8c99-ae2116f036ed",
                        metric_unit: "mm/s RMS",
                        rotation_speed: null,
                        virtual_device_id: 133,
                        professional_id: 14,
                        created_at: "2023-01-27T16:25:15.000000Z",
                        updated_at: "2023-01-27T16:25:15.000000Z"
                    },
                    {
                        id: 1413,
                        id_relatable: 36,
                        type_relatable: "subassembly",
                        label: "Génératrice arbre inferieur : Globale HF palier bas",
                        chart_type: 1,
                        metric_uuid: "997d5d9e-a388-11ec-8b6c-363429fcd3e5",
                        metric_unit: "G RMS",
                        rotation_speed: null,
                        virtual_device_id: 132,
                        professional_id: 14,
                        created_at: "2023-01-27T16:25:44.000000Z",
                        updated_at: "2023-01-27T16:25:44.000000Z"
                    },
                    {
                        id: 1414,
                        id_relatable: 36,
                        type_relatable: "subassembly",
                        label: "Génératrice arbre inferieur : Globale BF palier bas",
                        chart_type: 1,
                        metric_uuid: "9990534a-a388-11ec-9758-d6683707f645",
                        metric_unit: "mm/s RMS",
                        rotation_speed: null,
                        virtual_device_id: 132,
                        professional_id: 14,
                        created_at: "2023-01-27T16:26:09.000000Z",
                        updated_at: "2023-01-27T16:26:09.000000Z"
                    },
                    {
                        id: 1415,
                        id_relatable: 36,
                        type_relatable: "subassembly",
                        label: "Multiplicateur : Globale HF palier haut",
                        chart_type: 1,
                        metric_uuid: "ba241f3c-a389-11ec-9b25-363429fcd3e5",
                        metric_unit: "G RMS",
                        rotation_speed: null,
                        virtual_device_id: 133,
                        professional_id: 14,
                        created_at: "2023-01-27T16:27:01.000000Z",
                        updated_at: "2023-01-27T16:27:01.000000Z"
                    },
                    {
                        id: 1416,
                        id_relatable: 36,
                        type_relatable: "subassembly",
                        label: "Multiplicateur : Globale BF palier haut",
                        chart_type: 1,
                        metric_uuid: "56e02a88-a389-11ec-835b-ae2116f036ed",
                        metric_unit: "mm/s RMS",
                        rotation_speed: null,
                        virtual_device_id: 133,
                        professional_id: 14,
                        created_at: "2023-01-27T16:27:35.000000Z",
                        updated_at: "2023-01-27T16:27:35.000000Z"
                    },
                    {
                        id: 1417,
                        id_relatable: 36,
                        type_relatable: "subassembly",
                        label: "Multiplicateur : Globale HF palier bas",
                        chart_type: 1,
                        metric_uuid: "d60dea98-a389-11ec-833c-ae2116f036ed",
                        metric_unit: "G RMS",
                        rotation_speed: null,
                        virtual_device_id: 133,
                        professional_id: 14,
                        created_at: "2023-01-27T16:28:10.000000Z",
                        updated_at: "2023-01-27T16:28:10.000000Z"
                    },
                    {
                        id: 1418,
                        id_relatable: 36,
                        type_relatable: "subassembly",
                        label: "Multiplicateur : Globale BF palier bas",
                        chart_type: 1,
                        metric_uuid: "97a64232-a389-11ec-9bdb-ae2116f036ed",
                        metric_unit: "mm/s RMS",
                        rotation_speed: null,
                        virtual_device_id: 133,
                        professional_id: 14,
                        created_at: "2023-01-27T16:28:36.000000Z",
                        updated_at: "2023-01-27T16:28:36.000000Z"
                    },
                    {
                        id: 1419,
                        id_relatable: 36,
                        type_relatable: "subassembly",
                        label: "Génératrice arbre inferieur : Résonance palier haut",
                        chart_type: 1,
                        metric_uuid: "d4cff02a-a38b-11ec-af20-ae2116f036ed",
                        metric_unit: "mm/s RMS",
                        rotation_speed: null,
                        virtual_device_id: 133,
                        professional_id: 14,
                        created_at: "2023-01-27T16:30:13.000000Z",
                        updated_at: "2023-01-27T16:30:13.000000Z"
                    },
                    {
                        id: 1420,
                        id_relatable: 36,
                        type_relatable: "subassembly",
                        label: "Génératrice arbre inferieur : Résonance palier bas",
                        chart_type: 1,
                        metric_uuid: "1a0abff6-a389-11ec-b780-363429fcd3e5",
                        metric_unit: "mm/s RMS",
                        rotation_speed: null,
                        virtual_device_id: 132,
                        professional_id: 14,
                        created_at: "2023-01-27T16:30:36.000000Z",
                        updated_at: "2023-01-27T16:30:36.000000Z"
                    },
            ],
        }
    },
    mounted() {
        this.initChart();
        this.seriesList = this.seriesList.map(curve => {
            curve.data_infos = this.buildTable(curve);
            return curve;
        });
    },
    created() {
        this.getMachine();
        //this.getMachineComparisonCurves();
        //this.getMachineThreeDimensionalCurves();
        //this.getSubassemblyFavoriteCurves();
        //this.getSubassemblyWordCurves();
    },
    watch: {
        Curves: {
            handler(newCurve) {
                newCurve.forEach((curve) => {
                this.updateSeries(curve.index, curve);
            });
            },
            deep: true,
        },
    },
    methods: {
        // GET ALL DATAS
        getMachine() {
            this.axios.get(MACHINES + '/specific/' + this.id)
            
            .then((success) => {
                this.machine = [];
                this.machine = success.data[0];

            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");
            })
        },
        checkDataAvailable(datas){
            if(typeof datas[0] === 'undefined') {                    
                return false;
            }else {
                return true;
            }
        },
        getMachineComparisonCurves() {
            this.axios.get(
                MACHINES + "/comparison-curves/" + this.id
            ).then((success) => {
                this.comparison_curves = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            });
        },
        getMachineThreeDimensionalCurves() {
            this.axios.get(
                MACHINES + "/td-curves/" + this.id
            ).then((success) => {
                    this.three_dimensional_curves = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            });
        },
        getSubassemblyFavoriteCurves() {
            this.axios.get(
                MACHINES + "/favorite-curves/" + this.id
            ).then((success) => {
                this.favorite_curves = success.data;
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('global.error'));
            });
        },
        getSubassemblyWordCurves() {
            this.axios.get(
                MACHINES + "/word-curves/" + this.id
            ).then((success) => {
                this.word_curves = success.data;
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('global.error'));
            });
        },

        refresh() {
            // Logique pour mettre à jour les données en fonction de la plage de dates sélectionnée
            console.log(this.range);
            this.updateDateRangeText();
        },
        refreshStartTime() {
            this.updateDateRangeText();
        },
        refreshEndTime() {
            this.updateDateRangeText();
        },
        updateDateRangeText() {
            const fromDate = moment(this.range[0]).format('DD-MM-YYYY');
            const toDate = moment(this.range[1]).format('DD-MM-YYYY');
            const fromTime = this.startTime ? moment(this.startTime, 'HH:mm').format('HH:mm') : '00:00';
            const toTime = this.endTime ? moment(this.endTime, 'HH:mm').format('HH:mm') : '23:59';
            this.dateRangeText = `Du ${fromDate} ${fromTime} au ${toDate} ${toTime}`;
        },

        /////////////////////////////
        // SANDBOX DATA MANAGEMENT //
        /////////////////////////////
        
        // SANDBOX CHART MANAGMENT
        initChart() {
            // Premiere serie
            let flag = 0;
            let newValue = 0;

            // For each existing curves
            this.series2.forEach(curveSerie => {
                // Random series generation
                    let temporarySerie = {
                        metric_thresholds: {
                            alert_high: 150,
                            prealert_high: 120,
                        },
                        data_metric: {
                            data: []
                        },
                    }
                // Generating complete series 
                    this.series1.data_metric.data.forEach(temp => {
                        newValue = parseFloat(temp.value) + this.getRandomInt(10);
                        temporarySerie.data_metric.data.push({date: temp.date, value: newValue});
                    })
                // For fake datas
                    temporarySerie.data_infos = this.buildTable(temporarySerie);
                    //this.buildTable(temporarySerie);    
                    this.buildSeries(flag, temporarySerie, curveSerie);
                // ------------------------------------------------------------------------
                // For real datas
                // currentCurve = this.buildTable(temporarySerie);
                // this.buildSeries(flag, temporarySerie, curveSerie, currentCurve, true);

                flag = flag + 1;
            })

            Highcharts.chart('container', {
                chart: {
                    scrollablePlotArea: {
                        minWidth: 700,
                    },
                    zoomType: 'xy',
                },
                title: {
                    text: 'Sandbox',
                    align: 'left',
                },
                xAxis: {
                    type: 'datetime',
                    tickInterval: 24 * 3600 * 1000,
                    tickWidth: 0,
                    gridLineWidth: 1,
                    labels: {
                        align: 'left',
                        x: 3,
                        y: -3,
                        format: '{value:%Y-%m-%d %H:%M:%S}'
                    },
                },
                yAxis: {
                    title: {
                        text: null,
                    },
                    labels: {
                        align: 'left',
                        x: 3,
                        y: 16,
                        format: '{value:.,0f}',
                    },
                    showFirstLabel: false,
                    plotBands: [],
                },
                legend: {
                    align: 'left',
                    verticalAlign: 'top',
                    borderWidth: 0,
                },
                tooltip: {
                    shared: true,
                    crosshairs: true,
                },
                plotOptions: {
                    series: {
                        cursor: 'pointer',
                        className: 'popup-on-click',
                        marker: {
                            lineWidth: 1,
                        },
                        events: {
                            click: function () {
                                const curve = this;
                                this.update({
                                    color: curve.display.color,
                                    dashStyle: curve.display.lineType,
                                    marker: {
                                        symbol: curve.display.markerType,
                                        radius: curve.display.heightPoints,
                                    },
                                });
                            },
                        },
                    },
                },
                credits: {
                    enabled: false,
                },
                series: this.seriesList.map((serie, index) => ({
                    ...serie,
                    dataLabels: {
                        enabled: false
                    },
                }))
            })

            const chart = Highcharts.charts[0];
            console.log(chart);
            console.log(this.seriesList);
        },
        buildSeries(index, data, favorite_curve) {

            if (!data || !data.data_metric || !data.data_metric.data) {
                console.error('Les données sont mal formatées ou manquantes');
                return;
            }

            const metrics = data.data_metric.data;
            const thresholds = data.metric_thresholds;
            const operations = data.data_metric.data;
            const modalOps = this.$refs.modalOperation;

            let dataComponents = this;
            let series = [];
            let flags = [];

            // Date management
            metrics.forEach(metric => {
                let utcDate = (new Date(metric.date)).getTime();
                series.push([utcDate, parseFloat((parseFloat(metric.value) * 1e2 / 1e2).toFixed(4))]);

                if (metric.operation) {
                    let utcOperationDate = (new Date(metric.operation.date)).getTime();
                    let flag = {
                        x: utcOperationDate,
                        title: ' ',
                        text: metric.operation.label
                    };
                    flags.push(flag);
                }
            });

            // Operations management
            operations.forEach(operation => {
                let utcOperationDate = (new Date(operation.date_operation)).getTime();
                let flag = {
                    x: utcOperationDate,
                    title: ' ',
                    text: operation.label
                };
                flags.push(flag);
            });

            const info_data = this.buildTable(data);
    
            // Series configuration
            let dataSeries = {
                name: favorite_curve.label,
                id: 'dataseries_' + index,
                index: index,
                data: series,
                unit: favorite_curve.metric_unit,
                
                toggle: true,
                color: this.curves_form[index].color,
                dashStyle: this.curves_form[index].dashStyle,
                lineWidth: this.curves_form[index].lineWidth,
                heightPoints: this.curves_form[index].heightPoints,
                
                //data_infos: this.info_data,
                data_infos: info_data,
                metric_thresholds: thresholds,
                metric_name: favorite_curve.label,
                metric_UUID: favorite_curve.metric_uuid,
                metric_device: favorite_curve.virtual_device_id,
                
                marker: {
                    symbol: this.curves_form[index].marker.symbol,
                },
                plotbandsAdded: false,
                events: {
                    click: function (e) {
                        const value = {
                            favorite_curve: favorite_curve,
                            date: moment(e.point.category).toDate(),
                        };
                        dataComponents.date_operation_click = value;
                        modalOps.openDialogAddOperation(value);
                    },
                },
                navigatorOptions: {
                    visible: false
                },
            };

            // Operation icon management
            let dataFlags = {
                type: 'flags',
                data: flags,
                shape: 'url(/images/wrench.png)',
                width: 15
            };

            if (this.display_flags) {
                this.seriesList.push([dataSeries, dataFlags]);
            } else {
                this.seriesList.push(dataSeries);
            }
            console.log('Série ajoutée :', dataSeries);

        },
        renderChart() {
            if (this.seriesList.length === 0) {
                console.error('seriesList is empty. Chart will not be initialized.');
                return;
            }

            const chart = Highcharts.chart('container', {
                chart: {
                    scrollablePlotArea: {
                        minWidth: 700,
                    },
                    zoomType: 'xy',
                    events: {
                        load: () => {
                            this.updatePlotbands();
                            this.togglePlotbands();
                        }
                    }
                },
                title: {
                    text: 'Sandbox',
                    align: 'left',
                },
                xAxis: {
                    type: 'datetime',
                    tickInterval: 7 * 24 * 3600 * 1000,
                    tickWidth: 0,
                    gridLineWidth: 1,
                    labels: {
                        align: 'left',
                        x: 3,
                        y: -3,
                        format: '{value:%Y-%m-%d %H:%M:%S}'
                    },
                },
                yAxis: {
                    title: {
                        text: null,
                    },
                    labels: {
                        align: 'left',
                        x: 3,
                        y: 16,
                        format: '{value:.,0f}',
                    },
                    showFirstLabel: false,
                    plotBands: [],
                },
                legend: {
                    align: 'left',
                    verticalAlign: 'top',
                    borderWidth: 0,
                },
                tooltip: {
                    shared: true,
                    crosshairs: true,
                },
                plotOptions: {
                    series: {
                        cursor: 'pointer',
                        className: 'popup-on-click',
                        marker: {
                            lineWidth: 1,
                            radius: 3,
                        },
                    },
                },
                credits: {
                    enabled: false,
                },
                series: this.seriesList.map((serie, index) => ({
                    ...serie,
                    states: {
                        hover: {
                            lineWidth: 3,
                            marker: {
                                radius: 4,
                            }
                        }
                    }
                }))
            })

            console.log('chart', chart)
        },
        buildTable(serie) {
            let info_data = {};
            let maxPoint = -Infinity;
            let minPoint = Infinity;
            let sumPoint = 0;
            let nbPoints = 0;

            serie.data_metric.data.forEach((point) => {
                const value = parseFloat(point.value);
                if (!isNaN(value)) {
                    if (value > maxPoint) {
                        maxPoint = value;
                    }
                    if (value < minPoint) {
                        minPoint = value;
                    }
                    sumPoint+= value;
                    nbPoints++;
                }
                /*if (point.value != "" && point.value > maxPoint) {
                    maxPoint = parseFloat(point.value)
                }
                if (point.value != "" && point.value < minPoint ) {
                    minPoint = parseFloat(point.value)
                }
                if (point.value != "") {
                    sumPoint = parseFloat(point.value) + sumPoint
                    nbPoints = nbPoints + 1
                }*/
            });
            console.log(info_data)
            console.log(maxPoint)
            console.log(minPoint)
            console.log(sumPoint)
            console.log(nbPoints)
            
            info_data.points_number = nbPoints;
            info_data.avg = nbPoints > 0 ? parseFloat((sumPoint / nbPoints).toFixed(4)) : 0;
            info_data.min = nbPoints > 0 ? parseFloat(minPoint.toFixed(4)) : 0;
            info_data.max = nbPoints > 0 ? parseFloat(maxPoint.toFixed(4)) : 0;

            /*this.info_data.points_number = nbPoints;
            this.info_data.avg = parseFloat((sumPoint / nbPoints).toFixed(4));
            this.info_data.min = parseFloat(minPoint.toFixed(4));
            this.info_data.max = parseFloat(maxPoint.toFixed(4));
            */

            return info_data;

        },


        // SERIES MANAGEMENT
        toggleDisplay(curve) {
            curve.toggle = !curve.toggle;
            const index = this.seriesList.findIndex(s => s.metric_UUID === curve.metric_UUID);
            const chart = Highcharts.charts[0];
            
            if (chart && chart.series[index]) {
                chart.series[index].setVisible(curve.toggle, true);
            }
        },
        /*updateCurveStyle(curve) {
            const index = this.seriesList.findIndex(s => s.metric_UUID === curve.metric_UUID);
            if (index !== -1) {
                this.updateSeries(index, curve);
            }
        },
        updateSeries(index, displayOptions) {
            const chart = Highcharts.charts[0];
            if (chart && chart.series[index]) {
                chart.series[index].update({
                    color: displayOptions.color || '#000000',
                    dashStyle: displayOptions.dashStyle || 'Solid',
                    lineWidth: displayOptions.lineWidth || 1,
                    marker: {
                        symbol: displayOptions.marker.symbol || 'circle',
                        radius: displayOptions.marker.radius || 3,
                    }
                }, true);
            }
        },*/
        getRandomInt(max) {
            let PlusOrMinus = Math.random() < 0.5 ? -1 : 1;
            return Math.floor(Math.random() * max * PlusOrMinus);
        },

        applyStyleToSelectedCurves() {
            if (!this.selected || this.selected.length === 0) {
                console.warn("Aucune courbe sélectionnée.");
                return;
            }

            this.selected.forEach((selectedItem) => {
                const curveId = selectedItem.metric_UUID || selectedItem;

                const series = this.seriesList.find(serie => serie.metric_UUID === curveId);

                if (series) {
                    series.color = this.selectedColor;
                    series.dashStyle = this.selectedLineStyle;
                    series.lineWidth = this.selectedLineWidth;
                    series.marker = {
                        symbol: this.selectedMarkerType,
                        radius: this.selectedPointSize,
                    };

                    const chartSeries = Highcharts.charts[0].series.find(s => s.options.metric_UUID === curveId);

                    if (chartSeries) {
                        chartSeries.update({
                            color: this.selectedColor,
                            dashStyle: this.selectedLineStyle,
                            lineWidth: this.selectedLineWidth,
                            marker: {
                                symbol: this.selectedMarkerType,
                                radius: this.selectedPointSize,
                            },
                        }, false);
                    } else {
                        console.warn(`La série avec l'ID ${curveId} n'a pas été trouvée dans le graphique.`);
                    }
                } else {
                    console.warn(`La série avec l'ID ${curveId} n'a pas été trouvée dans la liste des séries.`);
                }
            });

            if (Highcharts.charts[0]) {
                Highcharts.charts[0].redraw();
            }

            this.closeDialog();
        },


        /*applyStyleToSelectedCurves() {
            this.selectedCurves.forEach((curveId) => {

                const series = this.seriesList.find(serie => serie.metric_UUID === curveId);

                if (series) {

                    series.color = this.selectedColor;
                    series.dashStyle = this.selectedLineStyle;
                    series.lineWidth = this.selectedLineWidth;
                    series.marker = {
                        symbol: this.selectedMarkerType,
                        radius: this.selectedPointSize,
                    };

                    const chartSeries = Highcharts.charts[0].series.find(s => s.options.metric_UUID === curveId);
                    if (chartSeries) {
                        
                        chartSeries.update({
                            color: this.selectedColor,
                            dashStyle: this.selectedLineStyle,
                            lineWidth: this.selectedLineWidth,
                            marker: {
                                symbol: this.selectedMarkerType,
                                radius: this.selectedPointSize,
                            },
                        }, false);
                    }
                }
            });

            Highcharts.charts[0].redraw();
        },*/

        /*applyStyleToSelectedCurves() {
            // On boucle à travers toutes les courbes sélectionnées
            this.selectedCurves.forEach((curveId) => {
                // On recherche la courbe dans la liste des séries
                const series = this.seriesList.find(serie => serie.metric_UUID === curveId);

                if (series) {
                    // Mise à jour des propriétés de la courbe sélectionnée
                    series.color = this.selectedColor;
                    series.dashStyle = this.selectedLineStyle;
                    series.lineWidth = this.selectedLineWidth;
                    series.marker = {
                        symbol: this.selectedMarkerType,
                        radius: this.selectedPointSize,
                    };

                    // Mise à jour de la courbe dans le graphique Highcharts
                    const chartSeries = Highcharts.charts[0].series.find(s => s.options.metric_UUID === curveId);
                    if (chartSeries) {
                        // On applique les styles à la courbe correspondante
                        chartSeries.update({
                            color: this.selectedColor,  // Nouvelle couleur
                            dashStyle: this.selectedLineStyle,  // Nouveau style de ligne
                            lineWidth: this.selectedLineWidth,  // Nouvelle taille de ligne
                            marker: {
                                symbol: this.selectedMarkerType,  // Nouveau style de point
                                radius: this.selectedPointSize,   // Nouvelle taille de point
                            },
                        }, false); // false évite le redessin immédiat
                    }
                }
            });

            // Redessiner le graphique après avoir appliqué toutes les modifications
            Highcharts.charts[0].redraw();
        },*/

        //  Seuil
        updatePlotbands() {
            if (this.plotbandsAdded) {
                this.togglePlotbands();
                this.togglePlotbands();
            }
        },
        togglePlotbands(curve) {
            const index = this.seriesList.findIndex(s => s.metric_UUID === curve.metric_UUID);
            const chart = Highcharts.charts[0];
            const yAxis = chart.yAxis[0];

            if (!chart || index === -1) return;

            const series = chart.series[index];
            const seriesId = series.options.id || series.name || curve.metric_UUID;

            // Identifiant unique pour chaque plotband
            const preAlertBandId = `preAlertBand_${seriesId}`;
            const alertBandId = `alertBand_${seriesId}`;

            // Vérification de l'existence des plotbands pour cette série
            if (curve.plotbandsAdded) {
                // Retirer les plotbands
                yAxis.removePlotBand(preAlertBandId);
                yAxis.removePlotBand(alertBandId);
                curve.plotbandsAdded = false;
            } else {
                // Ajouter les plotbands
                yAxis.addPlotBand({
                    from: this.prealertThreshold,
                    to: this.alertThreshold,
                    color: '#ffff0045',
                    id: preAlertBandId,
                    label: {
                        text: 'Zone de pré-alerte',
                        align: 'center',
                        style: {
                            color: '#000000'
                        }
                    }
                }); 

                yAxis.addPlotBand({
                    from: this.alertThreshold,
                    to: this.alertThreshold + 9999999,
                    color: '#ff000045',
                    id: alertBandId,
                    label: {
                        text: 'Zone d\'alerte',
                        align: 'center',
                        style: {
                            color: '#000000'
                        }
                    }
                });

                curve.plotbandsAdded = true;
            }
        },

        // Machine menu package //
        updateLabelMachine(item) {
            this.label = item.label;
        },
        infoToggler(){
            this.infoToggle = !this.infoToggle;
        },


        // Dialog style chart
        openDialog() {
            this.dialogVisible = true
        },
        closeDialog() {
            this.dialogVisible = false
        },
    }
}

</script>

<style scoped lang="scss">
.highcharts-figure,
.highcharts-data-table table {
  width: 100%;
}

.highcharts-data-table table {
  font-family: Verdana, sans-serif;
  border-collapse: collapse;
  border: 1px solid #ebebeb;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
}

.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}

.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}

.highcharts-data-table tr:hover {
  background: #f1f7ff;
}

.highcharts-description {
  margin: 0.3rem 10px;
}

#fixed-col {
    min-width: 638px;
    max-width: 638px !important;
}

.color_white {
    color: white!important;
}

@media only screen and (max-width: 480px) {
    #fixed-col {
        min-width: 100%;
        max-width: 100% !important;
    }
}
</style>