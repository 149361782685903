"use strict";

import Vue from 'vue';
import axios from "axios";

axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if ((error.response.status === 401 && error.response.message === 'TokenRefreshTimeExpired') || (Vue.$router.currentRoute.meta.auth && error.response.status === 401)){
            Vue.$router.push({name: 'login'});
        }
        return Promise.reject(error);
    }
);

Plugin.install = function (Vue) {
    Vue.axios = axios;
    window.axios = axios;
    Object.defineProperties(Vue.prototype, {
        axios: {
            get() {
                return axios;
            }
        },
        $axios: {
            get() {
                return axios;
            }
        },
    });
};

Vue.use(Plugin);

export default Plugin;
