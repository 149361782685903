<template>
    <div id="associate-user-to-site">
        <v-dialog v-model="dialog_bind_sites" max-width="500px" persistent>
            <v-card>
                <v-form ref="formBindVirtualDevice">
                    <v-card-title>
                        <span class="headline">{{ $t('users.associate_sites') }}</span>
                    </v-card-title>

                    <v-divider/>

                    <v-card-text class="pb-0">
                        <v-select
                            :loading="loading_dialog_bind_sites"
                            :items="sites_not_bind_user"
                            v-model="sites_bind_to_user"
                            :label="$t('users.sites_to_associate')"
                            :menu-props="{ bottom: true, offsetY: true }"
                            :prepend-icon="'mdi-sitemap'"
                            item-text="label"
                            item-value="id"
                            solo chips multiple
                        />
                    </v-card-text>

                    <v-card-actions class="justify-end">
                        <v-btn @click="dialog_bind_sites = false">
                            <v-icon left>mdi-close</v-icon>
                            {{ $t('global.close') }}
                        </v-btn>
                        <v-btn color="primary" @click="updateBindingSite()">
                            <v-icon left>mdi-content-save</v-icon>
                            {{ $t('global.validation') }}
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {SITES} from "../../../api";

export default {
    name: 'BindProfessionalUser',
    data() {
        return {
            dialog_bind_sites: false,
            loading_dialog_bind_sites: false,
            sites_bind_to_user: [],
            sites_not_bind_user: [],
            user_id: null,
        }
    },
    methods: {
        openDialogBindProfessional(user) {
            this.user_id = user.id;
            this.loading_dialog_bind_sites = true;
            this.dialog_bind_sites = true;

            this.axios.get(
                SITES + '/not_bind/user/' + this.user_id
            ).then((success) => {
                this.sites_not_bind_user = success.data.sites;

                success.data.sites_bind_to_user.forEach(site => {
                    this.sites_bind_to_user.push(site);
                });
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('error_display.error_get_professionals_not_bind'));
            }).finally(() => {
                this.loading_dialog_bind_sites = false;
            });
        },

        updateBindingSite() {
            this.axios.put(SITES + '/bind/user/' + this.user_id, {
                sites_id: this.sites_bind_to_user
            }).then(() => {
                this.showGlobalSnackbar('success', this.$t('global.success_to_edit'));
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('users.error_bind_sites'));
            }).finally(() => {
                this.sites_not_bind_user = [];
                this.sites_bind_to_user = [];
                this.$emit('getUsers');
                this.dialog_bind_sites = false;
            });
        },
    }
}
</script>
