<template>
    <div id="avenant-contract" class="pa-4">
        <v-row no-gutters>
            <v-col>
                <p class="title mb-0 color-primary text-uppercase">{{ $t('contract_avenant.title') }}</p>
            </v-col>
        </v-row>

        <v-card width="100%" class="mt-4" elevation="0">
            <validation-observer ref="observer" v-slot="{ invalid }">
                <v-form ref="form">
                    <v-card-text>

                        <validation-provider :name="$t('contract_avenant.client')" rules="required" v-slot="{ errors }">
                            <v-autocomplete
                                class="mt-5 ml-3 width-layout"
                                v-model="selected_professional"
                                :items="professionals"
                                :label="$t('contract_avenant.client')"
                                item-text="name" item-value="id"
                                :error-messages="errors"
                                hide-no-data clearable return-object filled
                            >
                            </v-autocomplete>
                        </validation-provider>

                        <validation-provider :name="$t('contract_avenant.gx_client')" rules="required"
                                             v-slot="{ errors }"
                        >
                            <v-select
                                class="mt-5 ml-3 width-layout"
                                v-model="selected_gx_client"
                                :items="gx_clients"
                                :loading="loading_gx_clients"
                                :menu-props="{ bottom: true, offsetY: true }"
                                item-value="REST_ID_CUST"
                                item-text="Nom_Client"
                                :label="$t('contract_avenant.gx_client')"
                                @change="getGxClientContracts()"
                                :error="gx_contracts.length === 0 && gx_client_name_prefix !== null && selected_gx_client === null && !loading_gx_clients"
                                :error-messages="errors"
                                filled
                                return-object
                            >
                                <template v-slot:prepend-item>
                                    <v-list-item>
                                        <v-list-item-content class="ma-0 pa-0">
                                            <v-text-field
                                                v-model="gx_client_name_prefix"
                                                append-icon="mdi-magnify"
                                                :placeholder="$t('global.search')"
                                                @input="getGxClient()"
                                            />
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                                <template v-slot:no-data>
                                    <v-list-item>
                                        <v-list-item-content class="ma-0 pa-0">
                                            {{ $t('global.no_results')}}
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-select>
                        </validation-provider>

                        <validation-provider :name="$t('contract_avenant.gx_contract')" rules="required"
                                             v-slot="{ errors }"
                        >
                            <v-select
                                :loading="loading_gx_contracts"
                                class="mt-3 ml-3 width-layout"
                                :hint="$t('contract_avenant.gx_contract_number_hint')"
                                v-model="selected_gx_contract"
                                :items="gx_contracts"
                                :label="$t('contract_avenant.gx_contract')"
                                :error-messages="errors"
                                :disabled="!selected_gx_client"
                                filled
                            >
                                <template v-slot:no-data>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ $t('global.no_results') }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-select>
                        </validation-provider>

                        <validation-provider :name="$t('contract_avenant.label')" rules="required|max:90"
                                             v-slot="{ errors }"
                        >
                            <v-text-field
                                class="mt-3 ml-3 width-layout"
                                v-model="label"
                                :label="$t('contract_avenant.label')"
                                :messages="$t('contract_avenant.label_message')"
                                :error-messages="errors"
                                filled
                            >
                            </v-text-field>
                        </validation-provider>

                        <p class="mt-5 color-primary font-weight-bold">{{ $t("new_contract.contract_type") }}</p>

                        <template v-for="referential_contract_type in contract_referential">
                            <div class="d-flex flex-row flex-wrap" :key="referential_contract_type.id">

                                <span class="mt-7 ml-6 label-width">{{ referential_contract_type.label }} :</span>
                                <span class="mt-7 ml-6">{{ $t('contract_avenant.device_number') }}</span>

                                <validation-provider :name="$t('contract_avenant.device_number')"
                                                     rules="numeric|max_value:200" v-slot="{ errors }"
                                >
                                    <v-text-field
                                        class="mt-3 ml-3 device-number-field"
                                        type="number" filled clearable
                                        v-model="chosen_contract_referential[referential_contract_type.id]"
                                        :error-messages="errors"
                                    >
                                    </v-text-field>
                                </validation-provider>
                            </div>

                            <v-divider/>
                        </template>

                        <v-row class="ml-2 mt-2 mb-5 font-10">
                            <v-icon left color="primary" size="15">mdi-alert-circle</v-icon>
                            <span>{{ $t('contract_avenant.contract_type_number_hint') }}</span>
                        </v-row>

                        <validation-provider :name="$t('new_contract.billing_period')" rules="required|numeric"
                                             v-slot="{ errors }"
                        >
                            <v-autocomplete
                                class="mt-7"
                                :items="invoicing_periods"
                                v-model="invoicing_period"
                                :label="$t('new_contract.billing_period')"
                                :error-messages="errors"
                                filled
                            />
                        </validation-provider>

                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :return-value.sync="date_renew"
                            transition="scale-transition" min-width="auto" offset-y
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <validation-provider :name="$t('new_contract.next_withdrawal')" rules="required"
                                                     v-slot="{ errors }">
                                    <v-text-field
                                        v-model="date_renew"
                                        :label="$t('new_contract.next_withdrawal')"
                                        prepend-inner-icon="mdi-calendar"
                                        readonly filled
                                        v-bind="attrs"
                                        v-on="on"
                                        :error-messages="errors"
                                    />
                                </validation-provider>
                            </template>

                            <v-date-picker
                                v-model="date_renew"
                                no-title
                                :allowed-dates="disablePastDates"
                                scrollable
                            >
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="menu = false">
                                    {{ $t('global.cancel') }}
                                </v-btn>
                                <v-btn text color="primary" @click="$refs.menu.save(date_renew)">
                                    {{ $t('global.validation') }}
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-card-text>

                    <v-card-actions class="pr-4 pb-4 justify-end">
                        <v-btn color="primary" :disabled="invalid" @click="validate()">
                            {{ $t('global.validation') }}
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </validation-observer>
        </v-card>
        <global-snackbar/>
    </div>
</template>

<script>
import {CONTRACTS, PROFESSIONALS, REFERENTIAL_CONTRACT_TYPES} from "../../../api";
import GlobalSnackbar from "../../../global/components/GlobalSnackbar";

export default {
    name: "AvenantContract",
    components: {GlobalSnackbar},
    data() {
        return {
            invalid: false,
            contract_referential: [],
            chosen_contract_referential: [],
            label: '',
            selected_gx_client: null,
            gx_clients: [],
            selected_gx_contract: '',
            gx_contracts: [],
            date_renew: '',
            invoicing_period: '',
            selected_professional: '',
            professionals: [],
            menu: false,
            gx_client_name_prefix: null,
            loading_gx_clients: false,
            loading_gx_contracts:false,
            block_gx_fetch:false,
            timeout_id:null,
            custom_errors:'champs obligatoire'

        }
    },
    watch: {
        gx_client_name_prefix() {
            this.resetAutoCompleteClientGx();
        },
    },
    created() {
        this.getProfessionals();
        this.getReferentialContractTypes();
    },
    methods: {
        getProfessionals() {
            this.axios.get(
                PROFESSIONALS
            ).then((success) => {
                this.professionals = success.data;
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('global.error'));
            })
        },

        getReferentialContractTypes() {
            this.axios.get(
                REFERENTIAL_CONTRACT_TYPES
            ).then((success) => {
                this.contract_referential = success.data;
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('global.error'));
            });
        },

        validate() {
            this.$refs.form.validate();
            this.axios.post(CONTRACTS, {
                selected_professional: this.selected_professional.id,
                selected_gx_client: this.selected_gx_client.REST_ID_CUST,
                selected_gx_contract: this.selected_gx_contract,
                chosen_contract_referential: this.chosen_contract_referential,
                date_renew: this.date_renew,
                invoicing_period: this.invoicing_period,
                label: this.label
            }).then(() => {
                this.showGlobalSnackbar('success', this.$t('global.success_to_add'));
                setTimeout(() => {
                    this.$router.push({name: 'contracts'});
                }, 1000);
            }).catch((error) => {
                error.response.status = 404 ? this.manageError(error.response.status,this.$t('contract_avenant.contract_number_exist'),this.$t('contract_avenant.contract_number_exist')) : this.manageError(error.response.status, this.$t('global.error'));
                this.$refs.observer.reset();
                this.$refs.form.reset();
                this.gx_client_name_prefix = null;
            });
        },
        async getGxClient() {

            clearTimeout(this.timeout_id);
            this.timeout_id= setTimeout(async ()=>{
                if(this.gx_client_name_prefix){
                    if (this.gx_client_name_prefix.length >= 4) {
                        this.loading_gx_clients = true;
                        this.block_gx_fetch= true;
                        this.selected_gx_contract='';
                        await this.axios.get(
                            CONTRACTS + '/gx/clients?client_name_prefix=' + this.gx_client_name_prefix
                        ).then((success) => {
                            if (Object.keys(success.data).length !== 0) {
                                this.gx_clients = success.data;
                            }
                            else {
                                this.gx_clients =[];

                            }
                            this.resetAutoCompleteClientGx();
                        }).catch((error) => {
                            this.manageError(error.response.status, this.$t('global.error'));
                        }).finally(() => {
                            this.loading_gx_clients = false;
                            this.block_gx_fetch= false;
                        });
                    }
                }
            },1000);
        },

        resetAutoCompleteClientGx() {
            if (!this.gx_client_name_prefix && this.gx_clients.length >= 1) {
                this.loading_gx_clients = false;
                this.gx_clients = [];
                this.gx_contracts = [];
                this.selected_gx_client=null;
            }
        },

        getGxClientContracts() {
                this.loading_gx_contracts = true;
                this.axios.get(
                    CONTRACTS + '/gx/client_contracts/' + this.selected_gx_client.REST_ID_CUST
                ).then((success) => {
                    this.gx_contracts = success.data;
                }).catch((error) => {
                    this.manageError(error.response.status, this.$t('global.error'));
                }).finally(()=>{
                    this.loading_gx_contracts = false;
                });
        },
        disablePastDates(val) {
            return val >= new Date().toISOString().substring(0, 10);
        },
    },
}
</script>

<style scoped>
.width-layout {
    max-width: 400px;
}

.device-number-field {
    max-width: 90px;
}

.label-width {
    width: 180px;
}
</style>
