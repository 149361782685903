<template>
    <v-card class="rounded-lg" elevation="2" outlined min-height="525px" height="100%">
        <v-card-title class="d-flex justify-space-between">
            <v-icon>mdi-floor-plan</v-icon>
            {{ $t('machine_state.title') }}
            <modal-assets-edit
                ref="assetsEdit"
                @snackbarAssetsEdit="snackbarAssetsEdit"
                :assets-data="machine_data"
                :file-upload-endpoint="uploadAssetImageURI"
                :title="$t('machine_state.edit')"
                model="subassemblies"
                @saveChange="saveState()"
            />
            <v-btn elevation="3" @click="$refs.assetsEdit.openAssetsEdit(false)" color="amber darken-3" small>
                <v-icon color="white" small>mdi-wrench</v-icon>
            </v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
            <layer-assets :assets-data="machine_data" :asset-type="'subassembly'"/>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text>
            <v-row>
                <p>Sous ensemble</p>
                <!------------------- Info bulle ---------------------->
                <v-tooltip bottom dark class="mr-2">
                    <template v-slot:activator="{ on }">
                        <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                            <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:default>
                    <div style="min-width: 200px;position:relative;z-index: 10;">
                        <p>Cliquez sur la pastille du sous ensemble pour le voir en détail</p>
                    </div>
                    </template>
                </v-tooltip>
            </v-row>
            <v-container>
                <v-row>
                    <v-col v-for="(subassembly ,index) in machine_data.assets" :key="index">
                        <v-row class="justify-center">
                            <v-chip :color="assetColor(subassembly)" text-color="white" justify="center"
                                    class="w-100 ma-2"
                                    @click="$router.push({ name: 'subassembly', params: {id: subassembly.id} })">
                                {{ subassembly.label }}
                            </v-chip>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-snackbar
            v-model="snackbar"
            :color="snackbar_type">
            {{ snackbar_text }}
            <template v-slot:action="{ attrs }">
                <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
            </template>
        </v-snackbar>
    </v-card>
</template>

<script>
import {MACHINES} from "../../../api";
import ModalAssetsEdit from "../ModalAssetsEdit";
import LayerAssets from "../LayerAssets";

export default {
    name: "MachineState",
    components: {
        ModalAssetsEdit,
        LayerAssets,
    },
    data() {
        return {
            machine_data: {
                path_image: '',
                assets: []
            },
            machine_id: this.$route.params.id,
        };
    },
    created() {
        this.getMachine();
    },
    computed: {
        uploadAssetImageURI() {
            return `${MACHINES}/${this.machine_id}/image`;
        },
    },
    methods: {
        getMachine() {
            this.axios.get(
                MACHINES + "/" + this.machine_id + "/subassemblies/details"
            ).then((success) => {
                this.machine_data = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            });
        },

        checkActivation(activationDate) {
            let now = new Date();
            let activation = new Date(activationDate);
            let expiredDate = activation.setFullYear(activation.getFullYear() + 1);
            return now > expiredDate;
        },

        checkLastSeen(lastSeenDate) {
            let now = new Date();
            let lastSeen = new Date(lastSeenDate);
            lastSeen.setDate(lastSeen.getDate() + 1);

            return now > lastSeen;
        },

        snackbarAssetsEdit(item) {
            this.showSnackbar(item.type, item.text);
            this.getMachine();
        },

        saveState() {
            this.axios.put(
                MACHINES + '/' + this.machine_id + '/subassemblies/position', this.machine_data
            ).then(() => {
                this.showSnackbar('success', this.$t('global.success_to_edit'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            });
        },
    }
}
</script>
