var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg",attrs:{"elevation":"2","outlined":"","min-height":"480px","height":"100%"}},[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('v-icon',[_vm._v("mdi-map-marker-outline")]),_vm._v("\n        "+_vm._s(_vm.$t('site_autocomplete_card.title'))+"\n        "),_c('v-btn',{attrs:{"elevation":"3","color":"amber darken-3","small":""},on:{"click":_vm.openEdit}},[_c('v-icon',{attrs:{"color":"white","small":""}},[_vm._v("mdi-wrench")])],1)],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-card-text',[_c('gmap-map',{ref:"autocompleteMap",staticStyle:{"width":"100%","height":"475px"},attrs:{"center":_vm.center,"zoom":6,"options":{
                        zoomControl: true,
                        mapTypeControl: false,
                        scaleControl: false,
                        streetViewControl: false,
                        rotateControl: false,
                        fullscreenControl: false,
                        disableDefaultUi: false
                  }}},[(_vm.place.position.lat)?_c('gmap-marker',{key:_vm.site.id,attrs:{"position":{ lat:_vm.place.position.lat , lng: _vm.place.position.lng },"clickable":false,"draggable":false,"icon":{ url: '/images/cluster/marker.png' }}}):_vm._e()],1),_vm._v(" "),_c('v-dialog',{ref:"editDialog",attrs:{"max-width":"600px","eager":""},model:{value:(_vm.dialogEdit),callback:function ($$v) {_vm.dialogEdit=$$v},expression:"dialogEdit"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("\n                    "+_vm._s(_vm.$t('site_autocomplete_card.edit_title'))+"\n                ")])]),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-card-text',{staticClass:"pa-4"},[_c('validation-provider',{attrs:{"name":_vm.$t('global.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"solo":"","label":_vm.$t('global.name'),"prepend-icon":"mdi-label-outline","error-messages":errors},model:{value:(_vm.edited_site.label),callback:function ($$v) {_vm.$set(_vm.edited_site, "label", $$v)},expression:"edited_site.label"}})]}}])}),_vm._v(" "),_c('validation-provider',{attrs:{"name":_vm.$t('sites.address'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('vuetify-google-autocomplete',{attrs:{"id":"autocomplete","solo":"","prepend-icon":"mdi-map-marker","placeholder":_vm.$t('sites.address')},on:{"placechanged":_vm.setPlace},model:{value:(_vm.edited_site.address),callback:function ($$v) {_vm.$set(_vm.edited_site, "address", $$v)},expression:"edited_site.address"}})]}}])}),_vm._v(" "),_c('v-textarea',{attrs:{"rows":"2","solo":"","no-resize":"","label":_vm.$t('global.notes'),"prepend-icon":'mdi-comment-outline'},model:{value:(_vm.edited_site.note),callback:function ($$v) {_vm.$set(_vm.edited_site, "note", $$v)},expression:"edited_site.note"}})],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{on:{"click":_vm.discard}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close")]),_vm._v("\n                        "+_vm._s(_vm.$t('global.cancel'))+"\n                    ")],1),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.savePlace()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v("\n                        "+_vm._s(_vm.$t('global.validation'))+"\n                    ")],1)],1)],1)],1)],1),_vm._v(" "),_c('v-snackbar',{attrs:{"color":_vm.snackbar_type},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                  var attrs = ref.attrs;
return [_c('v-icon',{attrs:{"color":"white"},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("mdi-close")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v("\n        "+_vm._s(_vm.snackbar_text)+"\n        ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }