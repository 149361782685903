<template>
    <v-col class="col mt-12" style="margin-top:0px!important;padding:0px;position:relative; top:-12px;">
        <!-- MACHINE MENU PACKAGE -->
        <div style="padding:0px 12px;">
            <v-col class="pl-5 pr-5" style="background-color:#001047;color:#FFFFFF;border-radius: 0px 0px 5px 5px;">
                <!-- TOP TITLE -->
                <v-row class="pl-0 pt-5 mb-5" style="height: 52px; padding:5px;">
                    <!-- MACHINE NAME -->
                    <v-col class="row mb-0 pa-0 mt-1 col-auto" @click="infoToggler()">
                        <!-- COUNTER -->
                        <v-col color="white" class="col-auto pa-0 mr-5" v-if="machine">
                            <v-col class="col pt-0 pb-0 mr-10 rounded col ml-6" style="height: 35px;background-color: rgb(92 101 123);font-weight: bold;text-align: center;font-size: 16px;border: 1px solid #ffffff5c;line-height: 35px;color: #FFFFFF;">
                                {{ machine.label }}
                            </v-col>
                        </v-col>
                    </v-col>
                    
                    <!-- PAGE TITLE -->
                    <v-subheader class="v-subheader col theme--light pl-3 ml-2">
                        <!------------------- Info bulle ---------------------->
                        <v-tooltip bottom dark class="mr-2 ml-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 mr-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                                    <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                                </v-btn>
                            </template>
                            <template v-slot:default>
                            <div style="min-width: 200px;position:relative;z-index: 10;">
                                <strong>Page [Mesures & surveillances]</strong>
                                <p>Nos instruments de mesures sont interconnectés à nos services en ligne.</br>
                                    Ceux-ci peuvent donc renvoyer leurs données sur cet onglet de manière indépendante pour chaque machine.</br></br>
                                    **Attention, la visualisation des courbes sur cet écran n'est pas individuel dans cette version.</br>
                                    Nous modifierons ces paramètres dans notre prochaine version [1.5.2] pour vous permettre d'avoir une visualisation liée à votre compte uniquement.
                                </p>
                            </div>
                            </template>
                        </v-tooltip>
                        <p class="title mb-0 color-white" style="color: white!important;">
                            <div style="color:#FFFFFFb3;margin-right:4px;">Equipements > {{ machine.label }} > </div><div @click="infoToggler()" style="color:#FFFFFF;font-weight:bold;"> {{ $t('machines.mesures') }}</div>
                        </p>
                    </v-subheader>

                    <!-- GLOBAL ACTIONS -->
                    <v-col class="row mt-1 col-auto pa-0 pr-5">
                        <v-tooltip bottom dark class="mr-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="getData()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                    <v-icon color="white">mdi-tag-multiple</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('global.1_5_2') }}</span>
                        </v-tooltip>
                        <v-tooltip bottom dark class="mr-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="getData()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                    <v-icon color="white">mdi-refresh</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('machines.refresh') }}</span>
                        </v-tooltip>
                        
                        <v-tooltip v-if="infoToggle == true" bottom dark class="mr-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="infoToggler()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                    <v-icon color="white">mdi-minus-box-multiple</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('machines.refresh') }}</span>
                        </v-tooltip>

                        <v-tooltip v-if="infoToggle == false" bottom dark class="mr-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="infoToggler()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                    <v-icon color="white">mdi-plus-box-multiple</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('machines.refresh') }}</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
                <!-- MACHINE INFOS -->
                <v-col class="mb-4" v-if="infoToggle == true" style="min-height: 250px; background-color:#e8edff;border-radius:5px;">
                    <v-row>
                        <v-col class="col-4">
                            <machine-state/>
                        </v-col>
                        <v-col class="col pl-0">
                            <machine-specifications v-on:updateLabelMachine="updateLabelMachine"/>
                            <v-row>
                                <v-col class="col">
                                    <machine-subassemblies/>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
                <!-- MACHINE LINKS -->
                <v-row v-if="infoToggle == false" style="border-top: 1px solid #ffffff1c;">
                    <v-row class="col-6 pl-0" style="margin:0px;">
                        <v-row class="ml-5 pt-2 pb-2 rounded" style="">
                            <!-- ALERTS -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/events'}" dusk="logout-button">
                                        <v-icon color="white">mdi-alert</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.alerts') }}</span>
                            </v-tooltip>

                            <!-- REPORTS -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/report'}" dusk="logout-button">
                                        <v-icon color="white">mdi-chart-box</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.reports') }}</span>
                            </v-tooltip>

                            <!-- DETAILS -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/details'}" dusk="logout-button">
                                        <v-icon color="white">mdi-chart-line</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.courbes') }}</span>
                            </v-tooltip>

                            <!-- SANDBOX -->
                            <v-tooltip bottom dark class="mr-2" v-if="$store.state.access_level <= 100">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" class="mr-5 col-0" :to="{ path: '/machine/' + id + '/sandbox'}" dusk="logout-button">
                                    <v-icon color="red">mdi-vector-curve</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('machines.sandbox') }}</span>
                        </v-tooltip>

                            <!-- Documents -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/documents'}" dusk="logout-button">
                                        <v-icon color="white">mdi-file-document</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machines.documents') }}</span>
                            </v-tooltip bottom dark class="mr-2">
                            
                            <!-- SETTINGS -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/settings'}" dusk="logout-button">
                                        <v-icon color="white">mdi-cog</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.settings') }}</span>
                            </v-tooltip bottom dark class="mr-2">
                        </v-row>     
                    </v-row>
                </v-row>

                <v-row v-if="infoToggle == true">
                    <v-row class="col-6" style="margin:0px;">
                        <v-row class="ml-5 pt-2 pb-2 rounded" style="">
                            <!-- ALERTS -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/events'}" dusk="logout-button">
                                        <v-icon color="white">mdi-alert</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.alerts') }}</span>
                            </v-tooltip>

                            <!-- REPORTS -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/report'}" dusk="logout-button">
                                        <v-icon color="white">mdi-chart-box</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.reports') }}</span>
                            </v-tooltip>

                            <!-- DETAILS -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/details'}" dusk="logout-button">
                                        <v-icon color="white">mdi-chart-line</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.courbes') }}</span>
                            </v-tooltip>

                            <!-- SANDBOX -->
                            <v-tooltip bottom dark class="mr-2" v-if="$store.state.access_level <= 100">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" class="mr-5 col-0" :to="{ path: '/machine/' + id + '/sandbox'}" dusk="logout-button">
                                    <v-icon color="red">mdi-vector-curve</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('machines.sandbox') }}</span>
                        </v-tooltip>

                            <!-- Documents -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/documents'}" dusk="logout-button">
                                        <v-icon color="white">mdi-file-document</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machines.documents') }}</span>
                            </v-tooltip bottom dark class="mr-2">
                            
                            <!-- SETTINGS -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/settings'}" dusk="logout-button">
                                        <v-icon color="white">mdi-cog</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.settings') }}</span>
                            </v-tooltip bottom dark class="mr-2">
                        </v-row>     
                    </v-row>
                </v-row>
            </v-col>
        </div>       
        
        <!--CHIPS-->
        <div class="ma-0 pa-0 ma-3" id="ChipsAlert" style="border-radius:5px;overflow:hidden;"> 
            <div v-for="virtual_device in virtualDevices" :key="virtual_device" class="ma-0 mb-0 pa-0" style="border-bottom: 1px solid #00000003;">
                <div class="col-0" style="color:white;font-weight:800;display:flex;justify-content:center;text-shadow:2px 2px 4px rgba(0, 0, 0, 0.29); height:70px;min-width: 8px;border-radius:5px;" >
                    <v-row class="pa-0 ma-0">
                        <!-- TYPE --> 
                        <v-col class="col pa-0" style=" max-width:30%;
                                                        display:flex;
                                                        flex-direction:column;"
                                                        :style="{backgroundColor:assetColor(virtual_device)}"
                        >
                            <v-col class="pa-0" style="  align-items:center;
                                            width:100%;
                                            position:relative;
                                            top:0px;
                                            padding-left: 35px;
                                            display:flex;
                                            flex-direction:row;
                                            "
                            >
                                <!-- ICON -->
                                <v-col class="col-auto ml-12" style="  font-weight:bold;
                                                            text-align:right;"
                                >
                                    <v-icon color="white" large v-if="(virtual_device.alert_level != 0 && virtual_device.alert_level != 3 && virtual_device.alert_level != 9)">
                                        mdi-alert
                                    </v-icon>

                                    <v-icon color="white" large v-if="(virtual_device.alert_level == 3)">
                                        mdi-help-rhombus
                                    </v-icon>

                                    <v-icon color="white" large v-if="(virtual_device.alert_level == 9 || virtual_device.alert_level == 0)">
                                        mdi-check-circle
                                    </v-icon>                        
                                </v-col>
                                <!-- Text -->
                                <v-col class="col" style="font-size:13px;text-align:left;font-weight: 400;align-items:center;">
                                    <div style="font-size:16px;font-weight:bold;">{{ virtual_device.label }}</div>
                                    <div>{{ virtual_device.uid }}</div>
                                </v-col>
                            </v-col>
                        </v-col>
                        <v-row class="col pa-0 ma-0">
                            <!-- LAST SEEN - REF --> 
                            <v-row  v-if="virtual_device.physical_device" class="col pa-0 ma-0" 
                                    style=" color:black;
                                            max-width:100%;
                                            background-color:
                                            white;height:70px;
                                            display:flex;
                                            justify-content:center;
                                            text-shadow:none;
                                            align-items:center;
                                            background-color:white;">
                                <!-- LINKS --> 
                                <v-col v-if="access_level < 100 && virtual_device.physical_device" class="col-1 pa-2 pt-3" style="max-width:50px;background-color:white;height:70px;display:flex;flex-direction:column;justify-content: center;text-shadow:none;align-items:center;">
                                    <!-- virtualDevice -->                          
                                    <v-row class="pa-0 ma-0">
                                        <a :href="'https://chirpstack.techview.app/#/organizations/1/applications/2/devices/' + virtual_device.physical_device.uid" style="margin-right: 5px;">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon class="pointer"
                                                            v-bind="attrs" v-on="on"
                                                            color="#eb8f00"
                                                    >
                                                        mdi-cog-box
                                                    </v-icon>
                                                </template>
                                                <span>Voir la configuration Chirpstack du capteur</span>
                                            </v-tooltip>
                                        </a>
                                    </v-row>
                                </v-col>

                                <!-- REFERENCE -->
                                <v-col class="ml-0 pa-0" v-if="access_level < 100 && virtual_device.physical_device && virtual_device.physical_device.uid !==null">
                                    <div class="ma-0 pa-0" color="primary" style="font-weight: bold; cursor:pointer;font-size:14px;" @click="$router.push({ name: 'physical_device', params: {id: virtual_device.physical_device.id} })">{{virtual_device.physical_device.uid}}</div>
                                    <p style="font-weight:normal;font-size:12px;">{{ virtual_device.physical_device.hardware_type }}</p>
                                </v-col>

                                <!-- REFERENCE -->
                                <v-col class="ml-13 pa-0" v-if="access_level >= 100 && virtual_device.physical_device && virtual_device.physical_device.uid !==null">
                                    <div class="ma-0 pa-0" color="primary" style="font-weight: bold; cursor:pointer;font-size:14px;" @click="$router.push({ name: 'physical_device', params: {id: virtual_device.physical_device.id} })">{{virtual_device.physical_device.uid}}</div>
                                    <p style="font-weight:normal;font-size:12px;">{{ virtual_device.physical_device.hardware_type }}</p>
                                </v-col>

                                <!-- LAST SEEN -->
                                <div class="ml-4" v-if="virtual_device.physical_device && virtual_device.physical_device.date_last_seen !==null">
                                    <v-chip small :color="lastEmissionAttributeTranslation(virtual_device.physical_device.date_last_seen).color" style="font-weight: normal;font-size:12px;">
                                        <v-icon color="blue-grey darken-3" class="mr-1">
                                            mdi-router-wireless
                                        </v-icon>
                                        {{ lastEmissionAttributeTranslation(virtual_device.physical_device.date_last_seen).time }}
                                    </v-chip>
                                </div>

                                <!-- BATTERY -->
                                <div class="ml-4 mr-8" v-if="virtual_device.physical_device && virtual_device.physical_device.battery !==null" style="text-align: center;font-weight: normal;font-size:12px;min-width: 50px;">
                                    <p v-if="virtual_device.physical_device.battery >= 90">
                                        <v-icon style="color: #6AAE5E;">mdi-battery</v-icon>
                                        <br>{{ virtual_device.physical_device.battery }}%
                                    </p>
                                    <p v-if="virtual_device.physical_device.battery < 90 && virtual_device.physical_device.battery >= 80">
                                        <v-icon style="color: #6AAE5E;">mdi-battery-80</v-icon>
                                        <br>{{ virtual_device.physical_device.battery }}%
                                    </p>
                                    <p v-if="virtual_device.physical_device.battery < 80 && virtual_device.physical_device.battery >= 70">
                                        <v-icon style="color: #6AAE5E;">mdi-battery-70</v-icon>
                                        <br>{{ virtual_device.physical_device.battery }}%
                                    </p>
                                    <p v-if="virtual_device.physical_device.battery < 70 && virtual_device.physical_device.battery >= 60">
                                        <v-icon style="color: #6AAE5E;">mdi-battery-60</v-icon>
                                        <br>{{ virtual_device.physical_device.battery }}%
                                    </p>
                                    <p v-if="virtual_device.physical_device.battery < 60 && virtual_device.physical_device.battery >= 50">
                                        <v-icon style="color: #F3B03D;">mdi-battery-50</v-icon>
                                        <br>{{ virtual_device.physical_device.battery }}%
                                    </p>
                                    <p v-if="virtual_device.physical_device.battery < 50 && virtual_device.physical_device.battery >= 40">
                                        <v-icon style="color: #F3B03D;">mdi-battery-40</v-icon>
                                        <br>{{ virtual_device.physical_device.battery }}%
                                    </p>
                                    <p v-if="virtual_device.physical_device.battery < 40 && virtual_device.physical_device.battery >= 30">
                                        <v-icon style="color: #F3B03D;">mdi-battery-30</v-icon>
                                        <br>{{ virtual_device.physical_device.battery }}%
                                    </p>
                                    <p v-if="virtual_device.physical_device.battery < 30 && virtual_device.physical_device.battery >= 20">
                                        <v-icon style="color: #D91419;">mdi-battery-20</v-icon>
                                        <br>{{ virtual_device.physical_device.battery }}%
                                    </p>
                                    <p v-if="virtual_device.physical_device.battery < 20 && virtual_device.physical_device.battery >= 10">
                                        <v-icon style="color: #D91419;">mdi-battery-10</v-icon>
                                        <br>{{ virtual_device.physical_device.battery }}%
                                    </p>
                                    <p v-if="virtual_device.physical_device.battery < 10">
                                        <v-icon style="color: #D91419;">mdi-battery-alert-variant-outline</v-icon>
                                        <br>{{ virtual_device.physical_device.battery }}%
                                    </p>
                                </div>
                            </v-row>

                            <v-row  v-if="!virtual_device.physical_device" class="col pa-0 ma-0" 
                                    style=" color:black;
                                            max-width:100%;
                                            background-color:
                                            white;height:70px;
                                            display:flex;
                                            justify-content:center;
                                            text-shadow:none;
                                            align-items:center;
                                            background-color:white;">
                                <!-- REFERENCE -->
                                <v-col class="ml-13 pa-0" >
                                    <div class="ma-0 pa-0" style="font-weight: bold; cursor:pointer;font-size:14px;color:grey;" >Capteur virtuel</div>
                                    <p style="font-weight:normal;font-size:12px;color:grey;">Aucun capteur physique n'est lié à ce point de mesure.</p>
                                </v-col>
                            </v-row>

                            <!-- LINKS --> 
                            <v-col class="col-auto pa-3 mt-0" style="  max-width:100%;
                                                                    background-color:white;
                                                                    height:70px;
                                                                    display:flex;
                                                                    flex-direction:row;
                                                                    justify-content:center;
                                                                    text-shadow:none;
                                                                    align-items:center;">
                                <!-- virtualDevice -->                          
                                <v-row class="pa-0 ma-0" v-if="access_level < 100">
                                    <a style="margin-right: 15px;" @click="$router.push({ name: 'virtual_device', params: {id: virtual_device.id} })">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer"
                                                        v-bind="attrs" v-on="on"
                                                        color="grey"
                                                >
                                                    mdi-circle-box
                                                </v-icon>
                                            </template>
                                            <span>Voir la fiche de vie du point de mesure</span>
                                        </v-tooltip>
                                    </a>

                                    <a style="margin-right: 15px;" v-if="virtual_device.physical_device" @click="$router.push({ name: 'physical_device', params: {id: virtual_device.physical_device.id} })">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer"
                                                        v-bind="attrs" v-on="on"
                                                        color="grey"
                                                >
                                                    mdi-eye
                                                </v-icon>
                                            </template>
                                            <span>Voir la fiche de vie du capteur physique</span>
                                        </v-tooltip>
                                    </a>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-row>
                </div>
            </div>
        </div> 

        <!-- CONTENT -->
        <v-row id="details_page" class="col pa-0 ma-0 pl-3 pr-3" >
            <!-- MACHINE CURVES panel -->
            <v-col class="col col-4 pa-0 ma-0 pt-0 pr-3" style="max-width: 30%;">
                <v-col class="pa-0 ma-0 elevation-1 pt-2 pb-2" style="background-color: white;border-radius:5px;height:auto;">
                    <div v-if="active_tab == -1"  class="pa-3 ma-1 ml-3 mr-3" style="background-color: #0010470f;border-radius: 5px;font-weight:bold;">
                        <v-icon color="orange" class="mr-3">mdi-star-box</v-icon>
                        Indicateurs favoris
                    </div>
                    <div v-else class="pa-3 ma-1 ml-3 mr-3" @click="getFavorites()" style="cursor:pointer;">
                        <v-icon color="grey" class="mr-3">mdi-star-box</v-icon>
                        Indicateurs favoris
                    </div>
                    
                    <div v-for="virtual_device in virtualDevices" :key="virtual_device">
                        <div v-if="active_tab == virtual_device.id && active_tab > -1" class="pa-3 ma-1 ml-3 mr-3" style="background-color: #0010470f;border-radius: 5px;font-weight:bold;">
                            <v-icon color="primary" class="mr-3">mdi-circle-box</v-icon>
                            {{ virtual_device.label }}
                        </div>
                        <div v-else class="pa-3 ma-1 ml-3 mr-3" @click="getMetricsOfVirtualDevices(virtual_device.id)" style="cursor:pointer;">
                            <v-icon color="grey" class="mr-3">mdi-circle-box</v-icon>
                            {{ virtual_device.label }}
                        </div>                    
                    </div>
                
                </v-col>
            </v-col>

            <v-col v-if="active_tab > -1" class="col pa-0 ma-0" style="max-width: 100%;">
                <div v-for="virtual_device in virtualDevices" :key="virtual_device">
                    <!-- MACHINE CURVES CONTENT -->
                    <v-col v-if="active_tab == virtual_device.id" class="col col pa-0 ma-0 pt-0" style="max-width: 100%;">
                        <v-data-table
                            :headers="headers"
                            :items="metrics"
                            :item-per-page=-1
                            :search="search"
                            :sort-by="['metric_name']"
                            :sort-desc="[true]"
                            class="elevation-1"
                            style="border-radius:5px;overflow:hidden;"
                        >
                            <template v-slot:top>
                                <v-toolbar flat color="white" class="pt-3 mb-3">
                                    <v-row>
                                        <v-col>
                                            <v-text-field
                                                v-model="search"
                                                append-icon="mdi-magnify"
                                                :label="$t('global.search')"
                                                class="mt-3"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-toolbar>
                            </template>

                            <!-- REFERENCE -->
                            <template v-slot:item.metric_name="{ item }" style="padding:0px;border:none;">
                                <v-expansion-panels class="elevation-0" style="border-radius:none;">
                                    <v-expansion-panel @click="upTicker()">
                                    <v-expansion-panel-header class="row col ma-0" style="display: flex;flex-direction: row;justify-content: space-between;align-items: center;"> 
                                        <v-icon v-if="item.metric_type == 'FFT'" color="grey" class="mr-3 col-1">mdi-chart-bar</v-icon>
                                        <v-icon v-else color="grey" class="mr-3 col-1">mdi-chart-bell-curve-cumulative</v-icon>
                                        <div>{{item.metric_name}} ({{item.metric_unit}}) </br> <div style="font-size: 11px;font-style: italic;margin-top:4px;">Source : {{item.metric_uuid}}</div></div>
                                        <!--
                                        <div class="row ma-0 mr-4" style="opacity:0.5;justify-content:end;color:white;font-size: 11px;">
                                            <div class="pa-2" v-if="item.metric_setting_open.alert_low" style="background-color: red;border-radius:5px 0px 0px 5px"> {{item.metric_setting_open.alert_low}} </div>
                                            <div class="pa-2" v-else style="background-color: red;border-radius:5px 0px 0px 5px"> - </div>
                                            <div class="pa-2" v-if="item.metric_setting_open.prealert_low" style="background-color: #ffcb05;color:black;"> {{item.metric_setting_open.prealert_low}} </div>
                                            <div class="pa-2" v-else style="background-color: #ffcb05;"> - </div>
                                            <div class="pa-2" style="background-color: black;"> > / < </div>
                                            <div class="pa-2" v-if="item.metric_setting_open.prealert_high" style="background-color: #ffcb05;color:black;"> {{item.metric_setting_open.prealert_high}} </div>
                                            <div class="pa-2" v-else style="background-color: #ffcb05;"> - </div>
                                            <div class="pa-2" v-if="item.metric_setting_open.alert_high" style="background-color: red;border-radius:0px 5px 5px 0px"> {{item.metric_setting_open.alert_high}} </div>
                                            <div class="pa-2" v-else style="background-color: red;border-radius:0px 5px 5px 0px"> - </div>
                                        </div>
                                        -->
                                    </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-col cols="12 pa-0" style="border-top: 1px solid #00000014;" v-if="item.metric_type != 'FFT' && item.metric_type != 'TEXT'">
                                                <time-curve-free v-bind:ticker="ticker" v-bind:item="item" v-bind:id="id"></time-curve-free>
                                            </v-col>
                                            <v-col cols="12 pa-0" style="border-top: 1px solid #00000014;" v-if="item.metric_type == 'FFT' && (access_level <= 100 || access_level == 201)">
                                                <spectrum-curve-free v-bind:item="item" v-bind:id="id" v-bind:sub_id="virtual_device.subassembly_id"></spectrum-curve-free>
                                            </v-col>  
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </template>
                        </v-data-table>
                    </v-col>
                </div>
            </v-col>

            <!-- MACHINE FAVORITES CONTENT -->
            <v-col v-if="active_tab === -1" class="col pa-0 ma-0" style="max-width: 100%;">
                <!-- Site -->
                <v-row class="pt-0" style="margin:0px;height:40px;">
                    <!-- Sites tab -->
                    <v-col class="row ml-0 mt-0 pb-0 pt-0 pl-0" style="position: relative;left:-1px;">
                        <div v-for="site in sites" :key="site" class="mr-1">
                            <div v-if="site.id == active_special_curve" class="ma-auto pl-5 pr-5 pa-2"
                                style="
                                    color: black;
                                    font-weight: 400;
                                    display: flex;
                                    justify-content: left;
                                    text-shadow: rgba(0, 0, 0, 0.29) 2px 2px 4px;
                                    background-color: rgb(255 255 255);
                                    border-radius: 5px 5px 0px 0px;
                                    border-left: 1px solid rgba(211, 211, 211, 0.43);
                                    border-bottom: none;
                                    font-size: 14px;
                                    height: 40px;
                                    align-items: center;
                                    font-weight:bold;
                                "
                            >
                                {{site.label}}
                            </div>
                            <div v-else class="ma-auto pl-5 pr-5 pa-2"
                                style="
                                    color: black;
                                    font-weight: 400;
                                    display: flex;
                                    justify-content: left;
                                    text-shadow: rgba(0, 0, 0, 0.29) 2px 2px 4px;
                                    background-color: rgb(243 243 243 / 90%);
                                    border-radius: 10px 10px 0px 0px;
                                    border: 1px solid #dbdce1;
                                    border-bottom: none;
                                    font-size: 14px;
                                    height: 40px;
                                    cursor: pointer;
                                "
                                @click="loadSite(site.id)"
                            >
                                {{site.label}}
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <!-- Favorite curves (Compared curves) --> 
                <v-data-table
                    :headers="headers"
                    :items="comparison_curves"
                    :item-per-page=-1
                    :search="search"
                    :sort-by="['label']"
                    :sort-desc="[false]"
                    class="elevation-1"
                    style="border-radius:0px 5px 5px 5px;overflow:hidden;"
                    v-if="active_special_curve == 0"
                >
                    <template v-slot:top>
                        <!-- ADD CURVES BUTTONS -->
                        <v-row class="justify-end mt-0 col pa-0 pl-6" style="align-items: center;">
                            <v-col class="col pa-2">
                                <favorite-curve v-bind:parentType="type2" justify-end
                                                v-on:getSubassemblyFavoriteCurves="getSubassemblyFavoriteCurves"
                                                v-on:getSubassemblyWordCurves="getSubassemblyWordCurves"
                                                >
                                </favorite-curve>
                            </v-col>

                            <v-col class="col pa-2">
                                <modal-comparison-curves v-bind:parentType="type" justify-end
                                                        v-on:getMachineComparisonCurves="getMachineComparisonCurves"
                                                        v-on:getMachineThreeDimensionalCurves="getMachineThreeDimensionalCurves">
                                </modal-comparison-curves>
                            </v-col>
                            <v-toolbar flat color="white" class="pt-3 mb-3 col-4">
                                <v-row>
                                    <v-col>
                                        <v-text-field
                                            v-model="search"
                                            append-icon="mdi-magnify"
                                            :label="$t('global.search')"
                                            class="mt-3"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-toolbar>
                        </v-row>
                    </template>

                    <!-- REFERENCE -->
                    <template v-slot:item.metric_name="{ item }" style="padding:0px;border:none;">
                        <v-expansion-panels accordion class="elevation-0" style="border-radius:none;">
                            <v-expansion-panel>
                            <v-expansion-panel-header> 
                                <v-icon color="grey" class="mr-3 col-1">mdi-chart-areaspline</v-icon>
                                <div>{{item.label}} </br> 
                                    <div v-for="trace in item.comparison_traces" :key="trace" style="font-size: 11px;font-style: italic;margin-top:4px;">
                                        Source : {{trace.metric_uuid}} ({{ trace.metric_unit }})
                                    </div>
                                </div>
                            </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-col cols="12 pa-0" style="border-top: 1px solid #00000014;">
                                        <comparison-curves  
                                            v-bind:item="item"
                                            v-on:deleteComparisonCurves="deleteComparisonCurves"
                                            :key="item.id"
                                        />
                                    </v-col>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </template>
                </v-data-table>

                <!-- Favorite curves (simple curves + spectrum curves) --> 
                <v-data-table
                    :headers="headers"
                    :items="favorite_curves"
                    :item-per-page=-1
                    :search="search"
                    :sort-by="['label']"
                    :sort-desc="[false]"
                    class="elevation-1"
                    style="border-radius:0px 5px 5px 5px;overflow:hidden;"
                    v-if="active_special_curve == 1"
                >
                    <template v-slot:top>
                        <!-- ADD CURVES BUTTONS -->
                        <v-row class="justify-end mt-0 col pa-0 pl-6" style="align-items: center;">
                            <v-col class="col pa-2">
                                <favorite-curve v-bind:parentType="type2" justify-end
                                                v-on:getSubassemblyFavoriteCurves="getSubassemblyFavoriteCurves"
                                                v-on:getSubassemblyWordCurves="getSubassemblyWordCurves"
                                                >
                                </favorite-curve>
                            </v-col>

                            <v-col class="col pa-2">
                                <modal-comparison-curves v-bind:parentType="type" justify-end
                                                        v-on:getMachineComparisonCurves="getMachineComparisonCurves"
                                                        v-on:getMachineThreeDimensionalCurves="getMachineThreeDimensionalCurves">
                                </modal-comparison-curves>
                            </v-col>
                            <v-toolbar flat color="white" class="pt-3 mb-3 col-4">
                                <v-row>
                                    <v-col>
                                        <v-text-field
                                            v-model="search"
                                            append-icon="mdi-magnify"
                                            :label="$t('global.search')"
                                            class="mt-3"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-toolbar>
                        </v-row>
                    </template>

                    <!-- REFERENCE -->
                    <template v-slot:item.metric_name="{ item }" style="padding:0px;border:none;">
                        <v-expansion-panels accordion class="elevation-0" style="border-radius:none;">
                            <v-expansion-panel>
                            <v-expansion-panel-header>
                                <v-icon v-if="item.metric_unit == 'FFT'" color="grey" class="mr-3 col-1">mdi-chart-bar</v-icon>
                                <v-icon v-else color="grey" class="mr-3 col-1">mdi-chart-bell-curve-cumulative</v-icon>
                                <div>{{item.label}} ({{item.metric_unit}}) </br> <div style="font-size: 11px;font-style: italic;margin-top:4px;">Source : {{item.metric_uuid}}</div></div>
                            </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-col cols="12 pa-0" style="border-top: 1px solid #00000014;" v-if="item.chart_type === 12 || item.chart_type === 1" :key="item.label">
                                        <time-curve 
                                            v-bind:item="item"
                                            v-on:deleteFavoriteCurve="deleteFavoriteCurve"
                                            v-on:updateOperationDatatable="updateOperationDatatable"
                                            v-bind:uuid="item.metric_uuid"
                                        ></time-curve>
                                    </v-col>
                                    <v-col cols="12 pa-0" style="border-top: 1px solid #00000014;" v-if="item.chart_type === 22 || item.chart_type === 2">
                                        <spectrum-curve 
                                            v-bind:item="item"
                                            v-on:deleteFavoriteCurve="deleteFavoriteCurve"
                                        ></spectrum-curve>
                                    </v-col>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </template>
                </v-data-table>

                <!-- Favorite curves (text curves) --> 
                <v-data-table
                    :headers="headers"
                    :items="word_curves"
                    :item-per-page=-1
                    :search="search"
                    :sort-by="['label']"
                    :sort-desc="[false]"
                    class="elevation-1"
                    style="border-radius:0px 5px 5px 5px;overflow:hidden;"
                    v-if="active_special_curve == 2"
                >
                    <template v-slot:top>
                        <!-- ADD CURVES BUTTONS -->
                        <v-row class="justify-end mt-0 col pa-0 pl-6" style="align-items: center;">
                            <v-col class="col pa-2">
                                <favorite-curve v-bind:parentType="type2" justify-end
                                                v-on:getSubassemblyFavoriteCurves="getSubassemblyFavoriteCurves"
                                                v-on:getSubassemblyWordCurves="getSubassemblyWordCurves"
                                                >
                                </favorite-curve>
                            </v-col>

                            <v-col class="col pa-2">
                                <modal-comparison-curves v-bind:parentType="type" justify-end
                                                        v-on:getMachineComparisonCurves="getMachineComparisonCurves"
                                                        v-on:getMachineThreeDimensionalCurves="getMachineThreeDimensionalCurves">
                                </modal-comparison-curves>
                            </v-col>
                            <v-toolbar flat color="white" class="pt-3 mb-3 col-4">
                                <v-row>
                                    <v-col>
                                        <v-text-field
                                            v-model="search"
                                            append-icon="mdi-magnify"
                                            :label="$t('global.search')"
                                            class="mt-3"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-toolbar>
                        </v-row>
                    </template>

                    <!-- REFERENCE -->
                    <template v-slot:item.metric_name="{ item }" style="padding:0px;border:none;">
                        <v-expansion-panels accordion class="elevation-0" style="border-radius:none;">
                            <v-expansion-panel>
                            <v-expansion-panel-header>
                                <v-icon color="grey" class="mr-3 col-1">mdi-chart-gantt</v-icon>
                                <div>{{item.label}} ({{item.metric_unit}}) </br> <div style="font-size: 11px;font-style: italic;margin-top:4px;"> Source : {{item.metric_uuid}}</div></div>
                            </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-col cols="12 pa-0" style="border-top: 1px solid #00000014;">
                                        <word-curves    
                                            v-bind:item="item" 
                                            v-on:deleteWordCurve="deleteWordCurve" 
                                        />
                                    </v-col>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </template>
                </v-data-table>

                <!-- Favorite curves (3D graphs) --> 
                <v-data-table
                    :headers="headers"
                    :items="three_dimensional_curves"
                    :item-per-page=-1
                    :search="search"
                    :sort-by="['label']"
                    :sort-desc="[false]"
                    class="elevation-1"
                    style="border-radius:0px 5px 5px 5px;overflow:hidden;"
                    v-if="active_special_curve == 3"
                >
                    <template v-slot:top>
                        <!-- ADD CURVES BUTTONS -->
                        <v-row class="justify-end mt-0 col pa-0 pl-6" style="align-items: center;">
                            <v-col class="col pa-2">
                                <favorite-curve v-bind:parentType="type2" justify-end
                                                v-on:getSubassemblyFavoriteCurves="getSubassemblyFavoriteCurves"
                                                v-on:getSubassemblyWordCurves="getSubassemblyWordCurves"
                                                >
                                </favorite-curve>
                            </v-col>

                            <v-col class="col pa-2">
                                <modal-comparison-curves v-bind:parentType="type" justify-end
                                                        v-on:getMachineComparisonCurves="getMachineComparisonCurves"
                                                        v-on:getMachineThreeDimensionalCurves="getMachineThreeDimensionalCurves">
                                </modal-comparison-curves>
                            </v-col>
                            <v-toolbar flat color="white" class="pt-3 mb-3 col-4">
                                <v-row>
                                    <v-col>
                                        <v-text-field
                                            v-model="search"
                                            append-icon="mdi-magnify"
                                            :label="$t('global.search')"
                                            class="mt-3"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-toolbar>
                        </v-row>
                    </template>

                    <!-- REFERENCE -->
                    <template v-slot:item.metric_name="{ item }" style="padding:0px;border:none;">
                        <v-expansion-panels accordion class="elevation-0" style="border-radius:none;">
                            <v-expansion-panel>
                            <v-expansion-panel-header>
                                <v-icon color="grey" class="mr-3 col-1">mdi-rotate-3d</v-icon>
                                <div>{{item.label}} </br>
                                    <div style="font-size: 11px;font-style: italic;margin-top:4px;">Axe x : {{item.metric_1_uuid}} ({{ item.metric_1_unit }})</div>
                                    <div style="font-size: 11px;font-style: italic;margin-top:4px;">Axe y : {{item.metric_2_uuid}} ({{ item.metric_2_unit }})</div>
                                    <div style="font-size: 11px;font-style: italic;margin-top:4px;">Axe z : {{item.metric_3_uuid}} ({{ item.metric_3_unit }})</div>
                                    <div style="font-size: 11px;font-style: italic;margin-top:4px;">Source : {{item.configuration.metric_uuid}}</div>
                                </div>
                            </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-col cols="12 pa-0" style="border-top: 1px solid #00000014;">
                                        <three-dimensional-curves   
                                            v-bind:item="item"
                                            v-on:deleteThreeDimensionalCurve="deleteThreeDimensionalCurve"
                                            :key="item.id"
                                        />
                                    </v-col>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-col>

    <!-- OPERATIONS
    <div id="machine-detail" style="display:none;">
        <v-row class="mt-0">
            <template v-for="(favorite_curve) in favorite_curves" >
                <v-col cols="12" v-if="favorite_curve.chart_type === 12" :key="favorite_curve.label">
                    <time-curve v-bind:favoriteCurve="favorite_curve"
                                v-on:deleteFavoriteCurve="deleteFavoriteCurve"
                                v-on:updateOperationDatatable="updateOperationDatatable"
                    ></time-curve>
                </v-col>

                <v-col cols="6" v-if="favorite_curve.chart_type === 1" :key="favorite_curve.label">
                    <time-curve v-bind:favoriteCurve="favorite_curve"
                                v-on:deleteFavoriteCurve="deleteFavoriteCurve"
                                v-on:updateOperationDatatable="updateOperationDatatable"
                    ></time-curve>
                </v-col>
                
                <v-col cols="12" v-if="favorite_curve.chart_type === 22 && $store.state.access_level <= 100" :key="favorite_curve">
                    <spectrum-curve v-bind:favoriteCurve="favorite_curve"
                                    v-on:deleteFavoriteCurve="deleteFavoriteCurve"
                    ></spectrum-curve>
                </v-col>

                <v-col cols="6" v-if="favorite_curve.chart_type === 2 && $store.state.access_level <= 100" :key="favorite_curve">
                    <spectrum-curve v-bind:favoriteCurve="favorite_curve"
                                    v-on:deleteFavoriteCurve="deleteFavoriteCurve"
                    ></spectrum-curve>
                </v-col>
            </template>

            <template v-for="word_curve in word_curves">
                <v-col cols="6" :key="word_curve">
                    <word-curves v-bind:word-curve="word_curve" v-on:deleteWordCurve="deleteWordCurve" class="mt-6"/>
                </v-col>
            </template>

            <template v-for="(comparison_curve) in comparison_curves">
                <v-col cols="6" :key="comparison_curve">
                    <comparison-curves v-bind:comparison-curve="comparison_curve"
                                    v-on:deleteComparisonCurves="deleteComparisonCurves"
                                    :key="comparison_curve.id"
                    />
                </v-col>
            </template>

            <template v-for="three_dimensional_curve in three_dimensional_curves">
                <v-col cols="6">
                    <three-dimensional-curves v-bind:three-dimensional-curve="three_dimensional_curve"
                                            v-on:deleteThreeDimensionalCurve="deleteThreeDimensionalCurve"
                                            :key="three_dimensional_curve.id"
                    />
                </v-col>
            </template>
        </v-row>
    </div>
        -->
    <!-- SNACKBAR -->
    <v-snackbar
        v-model="snackbar"
        :color="snackbar_type">
        {{ snackbar_text }}
        <template v-slot:action="{ attrs }">
            <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
        </template>
    </v-snackbar>
</template>

<script>

// Get all virtual devices
// Get all metrics for all virtual devices
// -> Make second tab for virtual device resume 
// VIB (TOTAL_ENERGY / TEMPERATURE / BATTERY)
// ANALOG (BATTERY)

// Then create panel to get all Virtual devices
// On click on one virtual device, launch datatable associated to this datas
// Above those tabs add favorite data tabs
// Those favorite metrics are all data metrics to first follow (all types of curves)

import DocumentsUpload from "../../components/DocumentsUpload";
import DatatableMachineSubassemblies from "../../components/Machine/DatatableMachineSubassemblies";
import DatatableOperations from "../../components/DatatableOperations";
import ModalComparisonCurves from "../../components/Machine/ComparisonCurves/ModalComparisonCurves";
import ComparisonCurves from "../../components/Machine/ComparisonCurves/ComparisonCurves";
import ThreeDimensionalCurves from "../../components/Machine/ThreeDimensionalCurves/ThreeDimensionalCurves";
// FROM SUBASSEMBLY DETAILS
import DatatableSubassemblyOperations from "../../components/Subassembly/Operation/DatatableSubassemblyOperations";
import SubassemblyState from "../../components/Subassembly/SubassemblyState";
import FavoriteCurve from "../../components/Subassembly/Curve/FavoriteCurve";
import SubassemblySpecifications from "../../components/Subassembly/Specifications/SubassemblySpecifications";
// CURVES
import TimeCurve from '../../components/Subassembly/Curve/TimeCurve';
import TimeCurveFree from '../../components/Subassembly/Curve/TimeCurveFree';
import SpectrumCurve from '../../components/Subassembly/Curve/SpectrumCurve';
import SpectrumCurveFree from '../../components/Subassembly/Curve/SpectrumCurveFree';
import WordCurves from "../../components/Subassembly/WordCurves/WordCurves";

// Machine menu package //
import MachineSpecifications from "../../components/Machine/MachineSpecifications152";
import MachineState from "../../components/Machine/MachineState152";
import MachineSubassemblies from "../../components/Machine/MachineSubassemblies152";

import {MACHINES, VIRTUAL_DEVICES} from "../../../api";
import moment from "moment";

export default {
    name: "DetailMachine",
    components: {
        ThreeDimensionalCurves,
        DatatableMachineSubassemblies,
        DatatableOperations,
        ModalComparisonCurves,
        ComparisonCurves,
        WordCurves,
        DocumentsUpload,
        DatatableSubassemblyOperations,
        SubassemblyState,
        FavoriteCurve,
        SubassemblySpecifications,
        SpectrumCurve,
        TimeCurve,
        TimeCurveFree,
        SpectrumCurveFree,
        MachineSpecifications,      // Machine menu package //
        MachineState,               // Machine menu package //
        MachineSubassemblies,       // Machine menu package //
    },
    data() {
        return {
            id: this.$route.params.id,
            access_level: this.$store.state.access_level,
            active_tab: -1,
            active_special_curve: 1,
            ticker: 0,
            infoToggle: false,  // Machine menu package //
            label: '',          // Machine menu package //

            type: "machine",
            type2: "subassembly",
            machine:[],
            machineTitle:"",
            search:'',

            headers: [
                {text: "Métriques (indicateurs sur mesure)", value: 'metric_name'}
            ],
            sites:[
                {id:0, label: "Courbes comparées"},
                {id:1, label: "Courbes simples"},
                {id:2, label: "Courbes textuelles"},
                {id:3, label: "Graphiques 3D"},
            ],

            comparison_curves: [],
            three_dimensional_curves: [],
            favorite_curves: [],
            word_curves: [],
            virtualDevices:[],
            metrics:[],
            metrics2:[],
            metric_focused:[],

            machine_filters: [
                {
                    level: 9,
                    level_name: "Tout voir",
                    level_color: "#d7d7d752",
                    count:this.global_notifications_count
                },
                {
                    level: 3,
                    level_name: "Urgence",
                    level_color: "black",
                    count:0
                },
                {
                    level: 2,
                    level_name: "Importance élevée",
                    level_color: "#F44336",
                    count:0
                },
                {
                    level: 1,
                    level_name: "A suivre",
                    level_color: "#FFCB05",
                    count:0
                },
                {
                    level: 0,
                    level_name: "Pour information",
                    level_color: "#4CAF50",
                    count:0
                },
                {
                    level: -1,
                    level_name: "En attente",
                    level_color: "grey",
                    count:0
                },                
            ],
        }
    },
    created() {
        this.loading_data_table = true;
        this.getMachine();
        this.getMachineComparisonCurves();
        this.getMachineThreeDimensionalCurves();
        this.getSubassemblyFavoriteCurves();
        this.getSubassemblyWordCurves();
    },
    methods: {
        
        getMachine() {
            this.axios.get(MACHINES + '/specific/' + this.id)
            
            .then((success) => {
                this.machine = [];
                this.machine = success.data[0];

            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");

            }).finally(() => {
                this.getVirtualDevices();
            });
        },
        getVirtualDevices() {
            this.virtualDevices = [];

            this.axios.get(
                MACHINES + "/" + this.id + "/virtual_devices"
            
            ).then((success) => {
                success.data.forEach((virtualDevice) => {
                    virtualDevice["id_machine"] = this.id;
                }); 
                this.virtualDevices = success.data;
            
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'))
            });
        },
        getMetricsOfVirtualDevices(id) {
            this.virtualDevices.forEach((virtualDevice) => {
                if(virtualDevice.id == id){
                    this.axios.get(VIRTUAL_DEVICES + "/" + virtualDevice.id + "/metric_types"
                
                    ).then((success) => {                               
                        this.metrics = [];

                        if (this.$store.state.access_level === 200 || this.$store.state.access_level === 300) {
                            success.data.forEach((metric) => {
                                if (metric.metric_type !== "FFT" && metric.metric_unit !== "fft") {
                                    this.metrics.push(metric);
                                }
                            });
                        }else{
                            this.metrics = success.data;
                        }

                        this.metrics.forEach((metric) => {
                            metric['metric_setting_open'] = JSON.parse(metric.metric_setting);
                        });

                        console.log(this.metrics);

                    }).catch(() => {
                        this.showSnackbar('error', this.$t('global.error'))
                    })
                }
            })

            this.active_tab = id;
        },
        getFavorites(){
            this.active_tab = -1;
        },
        upTicker(){
            this.ticker = this.ticker +1;
        },
        updateOperationDatatable() {
            this.$refs.dt_operations.getOperations();
        },

        // ---------------------
        // GET FAVORITES CURVES
        getMachineComparisonCurves() {
            this.axios.get(
                MACHINES + "/comparison-curves/" + this.id
            ).then((success) => {
                this.comparison_curves = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            });
        },
        getMachineThreeDimensionalCurves() {
            this.axios.get(
                MACHINES + "/td-curves/" + this.id
            ).then((success) => {
                    this.three_dimensional_curves = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            });
        },
        getSubassemblyFavoriteCurves() {
            this.axios.get(
                MACHINES + "/favorite-curves/" + this.id
            ).then((success) => {
                this.favorite_curves = success.data;
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('global.error'));
            });
        },
        getSubassemblyWordCurves() {
            this.axios.get(
                MACHINES + "/word-curves/" + this.id
            ).then((success) => {
                this.word_curves = success.data;
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('global.error'));
            });
        },
        // DELETE FAVORITES CURVES
        deleteComparisonCurves(item) {
            let removed_item= this.comparison_curves.splice(this.comparison_curves.findIndex(
                comparison_curve => comparison_curve.id === item
            ), 1);
        },
        deleteThreeDimensionalCurve(item){
            this.three_dimensional_curves.splice(this.three_dimensional_curves.findIndex(
                three_dimensional_curve => three_dimensional_curve.id === item
            ),1);
            this.showSnackbar('success', this.$t('global.success_to_delete'));
        },
        deleteFavoriteCurve(item) {
            this.favorite_curves.splice(this.favorite_curves.findIndex(
                favorite_curve => favorite_curve.id === item
            ), 1);
        },
        deleteWordCurve(item) {
            this.word_curves.splice(this.word_curves.findIndex(word_curve => word_curve.id === item), 1);
            this.showGlobalSnackbar('success', this.$t('global.success_to_delete'));
        },

        // Machine menu package //
        updateLabelMachine(item) {
            this.label = item.label;
        },
        infoToggler(){
            this.infoToggle = !this.infoToggle;
        },
        loadSite(id){
            this.active_special_curve = id;
        },
    }
}
</script>

<style>
#details_page td.text-start {
    padding: 0!important;
}
</style>

<style scoped lang="scss">
#fixed-col {
    min-width: 638px;
    max-width: 638px !important;
}

@media only screen and (max-width: 480px) {
    #fixed-col {
        min-width: 100%;
        max-width: 100% !important;
    }
}
</style>
