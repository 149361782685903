<template>
    <v-col
        style=" position: fixed;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-color: rgb(20 20 20);
                z-index: 999;
                padding: 0;
                margin: 0;
                display:flex;
                flex-direction: column;
                justify-content: center;
                align-items:center;
                "
    > 
    <v-row>
        <v-col class="col" style="display: flex;align-items:center;width:90px;margin-right:20px;">
            <img src="/images/techview_logo_white.png" alt="Logo Techview" style="height:90px;opacity:0.7">
        </v-col>
        
        <v-col class="col" style="display:flex; align-items:start; flex-direction:column; justify-content:center; min-width: 550px;">
            <div class="container pa-0 ma-0">    
                <div class="progress2 progress-moved pa-0">
                    <div class="progress-bar2" >
                    </div>                       
                </div> 
            </div>
            <div v-if="step == 0" style="align-items: center;display: flex;color: #ffffff47;margin-top: 10px;font-size:12px;">Récupération des données de connexion, vérification de l'authentification...</div>
            <div v-if="step == 1" style="align-items: center;display: flex;color: #ffffff47;margin-top: 10px;font-size:12px;">Enregistrement des données réalisée avec succés, connexion autorisée </div>
            <div v-if="step == 2" style="align-items: center;display: flex;color: #ffffff47;margin-top: 10px;font-size:12px;"><strong style="color:#ffffffde;margin-right:3px;">Bonjour {{ me.first_name }},</strong> vous allez être redirigé dans un instant</div>
            <div v-if="step == -1" style="align-items: center;display: flex;color: #ffffff47;margin-top: 10px;font-size:12px;">Connexion refusée, vous êtes invité à vous connecter à nouveau</div>
        </v-col>
    </v-row>
    
    </v-col>
</template>

<script>
import {AUTH_ME} from "../../../api";

export default {   
    name: "Verify",
    data() {
        return {
            me: {},
            step: 0
        }
    },
    created() {
        this.data()
    },
    methods: {

        async data() {
            await this.sleep(1000);
            
            this.axios.get(AUTH_ME)
            
            .then((success) => {
                if(success.data){
                    this.redirect(success.data);
                }else{
                    this.exit();
                }

            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
                this.exit();
            })
        },

        async redirect(data) {
            this.me = data;
            this.step = 1;
            await this.sleep(1000);
            
            if (this.me.access_level === 0 && this.me.role_id === 1){
                this.step = 2;
                await this.sleep(1000);

                localStorage.t = 'tnWjK3VljKmJ6H55x8';
                this.$router.push({name: 'mco-panel'});
            
            }else{
                this.exit();
            }
        },

        async exit() {
            this.step = -1;
            await this.sleep(1000);

            this.logout();
        },

        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
    }
}
</script>

<style>

.loader_init{
    display:inline-block;
    width:64px;
    height:64px
}

.loader_init:after{
    content:" ";
    display:block;
    width:46px;
    height:46px;
    margin:1px;
    border-radius:50%;
    border-color:#004181 transparent;
    border-style:solid;
    border-width:5px;
    -webkit-animation:lds-dual-ring 1.2s linear infinite;
    animation:lds-dual-ring 1.2s linear infinite
}

.container {
  width: 100%;
  text-align: left;
}
 
.progress2 {
  padding: 6px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.25);  
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
}

.progress-bar2 {
  height: 12px;
  border-radius: 30px;
  background-image: 
    linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
  transition: 0.4s linear;  
  transition-property: width, background-color;    
}

.progress-moved .progress-bar2 {
  width: 100%; 
  background-color: green;  
  animation: progressAnimation 2s;
}

@keyframes progressAnimation {
  0%   { width: 5%; background-color: white;}
  100% { width: 100%; background-color: white; }
}

</style>