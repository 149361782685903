<template>
    <div id="virtual-device-location">
        <v-card elevation="2" outlined>
            <v-card-title class="justify-space-between">
                <v-icon>mdi-map-marker</v-icon>
                {{ $t('virtual_device_localisation.title') }}
                <v-icon/>
            </v-card-title>

            <v-divider/>

            <v-card-text v-if="is_address && is_coordinates && !is_address_loading && !is_coordinates_loading">
                <v-text-field
                    :prepend-inner-icon="'mdi-label-outline'"
                    :label="$t('virtual_device_localisation.label')"
                    outlined readonly background-color="#EEEEEE"
                    v-model="address"
                />

                <gmap-map :center="center" :zoom="12" class="gmap-map" ref="gmap">
                    <google-map-cluster>
                        <gmap-marker
                            :icon="{ url: '/images/cluster/marker.png' }"
                            :position="{ lat: center.lat , lng: center.lng }"
                            :clickable="true" :draggable="false"
                        />
                    </google-map-cluster>
                </gmap-map>
            </v-card-text>

            <v-card-text class="text-center"
                         v-else-if="(!is_address || !is_coordinates) && !is_address_loading && !is_coordinates_loading"
            >
                {{ $t('virtual_devices_details.device_not_bind_to_subassembly') }}
            </v-card-text>
            <v-progress-linear v-else indeterminate/>
        </v-card>
    </div>
</template>

<script>
import {VIRTUAL_DEVICES} from "../../../api";
import GoogleMapCluster from 'vue2-google-maps/dist/components/cluster'

export default {
    name: 'VirtualDeviceLocation',
    components: {GoogleMapCluster},
    data() {
        return {
            virtual_device_id: null,
            center: {lat: 0.0, lng: 0.0},
            address: '',
            is_coordinates: false,
            is_coordinates_loading: true,
            is_address: false,
            is_address_loading: true,
        }
    },
    created() {
        this.initialize();
    },
    methods: {
        initialize(){
            this.virtual_device_id = this.$route.params.id;
            this.isLoading = true;
            this.getCoordinates();
            this.getAddress();
        },

        getCoordinates() {
            this.is_coordinates = false;
            this.is_coordinates_loading = true;

            this.axios.get(
                VIRTUAL_DEVICES + '/' + this.virtual_device_id + '/building'
            ).then((success) => {
                if (success.data.latitude && success.data.longitude) {
                    this.center.lat = success.data.latitude;
                    this.center.lng = success.data.longitude;
                    this.is_coordinates = true;
                }
                this.is_coordinates_loading = false;
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('error_display.error_get_coord_building'));
            });
        },

        getAddress() {
            this.is_address = false;
            this.is_address_loading = true;

            this.axios.get(
                VIRTUAL_DEVICES + '/' + this.virtual_device_id + '/site'
            ).then((success) => {
                if (success.data.address) {
                    this.address = success.data.address;
                    this.is_address = true;
                }
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('error_display.error_get_address_site'));
            }).finally(() => {
                this.is_address_loading = false;
            });
        }
    }
}
</script>

<style scoped>
.gmap-map {
    width: 100%;
    height: 300px;
}
</style>
