import Vue from 'vue';
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import * as Cookies from "js-cookie";
import sites from './modules/sites';
import professionals_resume from './modules/professionals_resume';
import snackbar from './modules/snackbar';
import CryptoJS from 'crypto-js';
import {NOTIFICATIONS} from '../api';

Vue.use(Vuex);
export const store = new Vuex.Store({
    state: {
        role_id: null,
        access_level: 777,
        professional_id: null,
        professional_uid: null,
        professional_name: null,
        switch_state: false,
        refresh_information_banner: 0,
        notif:0,
    },
    mutations: {  
        setUserRoleId(state, roleId) {
            this.state.role_id = roleId;
        },
        setUserAccessLevel(state, accessLevel) {
            this.state.access_level = accessLevel;
        },
        setProfessionalId(state, professionalId) {
            this.state.professional_id = professionalId;
        },
        setProfessionalData(state, professional) {
            this.state.professional_id = professional.id;
            this.state.professional_uid = professional.uid;
            this.state.professional_name = professional.name;
            this.state.professional_logo = professional.logo;
        },
        setSwitchState(state, switchState) {
            this.state.switch_state = switchState;
        },
        setRefreshInformationBanner(state, refreshInformationBanner) {
            this.state.refresh_information_banner = refreshInformationBanner;
        },
        setNotification(state, notif) {
            this.state.notif = notif;
        },
    },

    plugins: [createPersistedState({
        storage: {
            getItem: (key) => {
                if (Cookies.get(key) === undefined) {
                    return Cookies.get(key);
                
                } else {
                    try {
                        let decData = CryptoJS.enc.Base64.parse(Cookies.get(key)).toString(CryptoJS.enc.Utf8);
                        let bytes = CryptoJS.AES.decrypt(decData, 'secret').toString(CryptoJS.enc.Utf8);
                        return JSON.parse(bytes);
                    } catch (error) {
                        return Cookies.remove(key);
                    }
                }
            },
            setItem: (key, value) => {
                let encJson = CryptoJS.AES.encrypt(JSON.stringify(value), 'secret').toString()
                let encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson))

                Cookies.set(key, encData, {
                    expires: 395,
                    secure: false
                });
            },
            removeItem: key => {
                Cookies.remove(key);
            }
        },
    })],

    modules: {sites, professionals_resume, snackbar}
});

Vue.store = store;
