<template>
    <div id="building-specifications" style="height: 100%">
        <v-card elevation="2" outlined min-height="500px" height="100%">
            <v-card-title class="justify-space-between">
                <v-icon>mdi-view-list-outline</v-icon>
                {{ $t('building_specifications.title') }}
                <v-btn elevation="3" color="amber darken-3" small @click="openDialogUpdateBuilding()">
                    <v-icon color="white" small>mdi-wrench</v-icon>
                </v-btn>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text>
                <v-row>
                    <v-col>
                        <v-text-field :prepend-inner-icon="'mdi-sitemap'"
                                      :label="$t('subassembly_specifications.site')"
                                      v-model="building.site.label"
                                      class="text-field-cursor-pointer"
                                      @click="$router.push({ name: 'site', params: {id: building.site_id} })"
                                      outlined readonly background-color="#EEEEEE"
                        />
                    </v-col>
                    <v-col>
                        <v-text-field :prepend-inner-icon="'mdi-label-outline'"
                                      :label="$t('global.name')"
                                      v-model="building.label"
                                      outlined readonly background-color="#EEEEEE"
                        />
                    </v-col>
                </v-row>

                <v-textarea
                    v-model="building.note"
                    rows="2" outlined readonly no-resize background-color="#EEEEEE"
                    :label="$t('global.notes')"
                    :prepend-inner-icon="'mdi-comment-outline'"
                />

                <v-img aspect-ratio="1" :src="building.site.path_plan" height="200" contain>
                    <template v-slot:placeholder>
                        <v-row
                            class="fill-height ma-0 background-loader-image"
                            align="center" justify="center"
                        >
                            <v-progress-circular indeterminate color="black lighten-5" v-if="no_path_image === false"/>

                            <p class="ma-0" v-else>
                                {{ $t('global.no_image') }}
                            </p>
                        </v-row>
                    </template>
                </v-img>
            </v-card-text>
        </v-card>

        <v-dialog v-model="dialog_edit" max-width="500px">
            <v-card>
                <validation-observer ref="observer" v-slot="{ invalid }">
                    <v-form ref="form">
                        <v-card-title>
                            <span class="headline">{{ $t('buildings.edit_building') }}</span>
                        </v-card-title>
                        <v-divider></v-divider>

                        <v-card-text>
                            <validation-provider :name="$t('global.name')"
                                                 rules="required"
                                                 v-slot="{ errors }">
                                <v-text-field solo
                                              :label="$t('global.name')"
                                              v-model="edited_building.label"
                                              :prepend-icon="'mdi-bookmark-check-outline'"
                                              :error-messages="errors"
                                />
                            </validation-provider>

                            <validation-provider :name="$t('buildings.site')" rules="required"
                                                 v-slot="{ errors }">
                                <v-select
                                    :items="sites"
                                    v-model="edited_building.site_id"
                                    :label="$t('buildings.site')"
                                    :prepend-icon="'mdi-sitemap'"
                                    :error-messages="errors"
                                    item-value="id"
                                    item-text="label" solo
                                >
                                </v-select>
                            </validation-provider>

                            <v-textarea
                                v-model="edited_building.note"
                                rows="2" solo no-resize
                                :label="$t('global.notes')"
                                :prepend-icon="'mdi-comment-outline'"
                            />
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer/>
                            <v-btn @click="dialog_edit = false">
                                <v-icon left>mdi-close</v-icon>
                                {{ $t('global.close') }}
                            </v-btn>
                            <v-btn type="submit" color="primary" :disabled="invalid"
                                   @click.prevent="updateBuildingDetails()">
                                <v-icon left>mdi-content-save</v-icon>
                                {{ $t('global.validation') }}
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </validation-observer>
            </v-card>
        </v-dialog>

        <v-snackbar
            v-model="snackbar"
            :color="snackbar_type">
            {{ snackbar_text }}
            <template v-slot:action="{ attrs }">
                <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
            </template>
        </v-snackbar>
    </div>
</template>

<script>

import {BUILDINGS, SITES} from "../../../api";

export default {
    name: "BuildingSpecifications",
    data() {
        return {
            building_id: null,
            building: {
                label: '',
                note: '',
                site_id: null,
                site: {
                    label: '',
                    path_plan: ''
                }
            },
            sites: [],
            edited_building: null,
            dialog_edit: false,
            invalid: false,

            no_path_image: false,
        }
    },
    created() {
        this.building_id = this.$route.params.id;
        this.getBuildingDetails();
        this.getSites();
    },
    methods: {
        getBuildingDetails() {
            this.axios.get(
                BUILDINGS + '/' + this.building_id
            ).then((success) => {
                this.building = success.data;
                this.$emit('updateLabelBuilding', {label: this.building.label});
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            });
        },

        getSites() {
            this.axios.get(
                SITES
            ).then((success) => {
                this.sites = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            });
        },

        openDialogUpdateBuilding() {
            this.edited_building = Object.assign({}, this.building);
            this.dialog_edit = true;
        },

        updateBuildingDetails() {
            this.axios.patch(
                BUILDINGS + '/' + this.building_id, this.edited_building
            ).then((success) => {
                this.building = success.data;
                this.$emit('updateLabelBuilding', {label: this.building.label});
                this.showSnackbar('success', this.$t('global.success_to_edit'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            }).finally(() => {
                this.dialog_edit = false
                this.resetForm();
            });
        },

        resetForm() {
            this.$refs.form.reset();
            this.$refs.observer.reset();
        }
    },
    mounted() {
        this.$nextTick(function () {
            setTimeout(() => {
                this.no_path_image = true;
            }, 5000);
        })
    }
}
</script>
