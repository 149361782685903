var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"row mt-0"},[_c('v-col',{staticClass:"col-1 pt-5 pb-5 rounded",staticStyle:{"height":"110%","background-color":"#014181","max-width":"70px","position":"fixed","top":"-20px","z-index":"1"}},[_c('v-tooltip',{staticClass:"mr-2",attrs:{"bottom":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-2 mt-10 mb-5 col-0",attrs:{"text":"","icon":"","to":{ path: '/machine/' + _vm.id + '/report'},"dusk":"logout-button"}},on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-chart-box")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('machine.reports')))])]),_vm._v(" "),_c('v-tooltip',{staticClass:"mr-2",attrs:{"bottom":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-2 mt-5 mb-5 col-0",attrs:{"text":"","icon":"","to":{ path: '/machine/' + _vm.id + '/events'},"dusk":"logout-button"}},on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-alert")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('machine.alerts')))])]),_vm._v(" "),_c('v-tooltip',{staticClass:"mr-2",attrs:{"bottom":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-2 mt-5 mb-5 col-0",attrs:{"text":"","icon":"","to":{ path: '/machine/' + _vm.id + '/settings'},"dusk":"logout-button"}},on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-cog")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('machine.settings')))])]),_vm._v(" "),(_vm.access_level <= 100 || _vm.access_level == 200 || _vm.access_level === 300)?_c('v-tooltip',{staticClass:"mr-2",attrs:{"bottom":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-2 mt-5 mb-5 col-0",attrs:{"text":"","icon":"","to":{ path: '/machine/' + _vm.id + '/details'},"dusk":"logout-button"}},on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-chart-line")])],1)]}}],null,false,3052825786)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('machine.courbes')))])]):_vm._e()],1),_vm._v(" "),_c('v-col',{staticClass:"col pa-0 pl-5 pr-5",staticStyle:{"margin-left":"70px"}},[_c('v-row',{staticClass:"row mt-0"},[_c('v-subheader',{staticClass:"v-subheader col theme--light pl-5 ml-2"},[_c('p',{staticClass:"title mb-0 color-primary text-uppercase"},[_vm._v("\n                    évènements \n                ")]),_vm._v(" "),_c('v-tooltip',{staticClass:"mr-2",attrs:{"bottom":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2 v-size--default",staticStyle:{"border":"1px solid #cacaca","width":"18px","height":"18px"},attrs:{"text":"","icon":"","dusk":"logout-button"},on:{"click":function($event){return _vm.getNotification()}}},on),[_c('v-icon',{staticStyle:{"font-size":"16px"},attrs:{"color":"darkgrey"}},[_vm._v("mdi-information-variant")])],1)]}},{key:"default",fn:function(){return [_c('div',{staticStyle:{"min-width":"200px","position":"relative","z-index":"10"}},[_c('strong',[_vm._v("Page [Evènements]")]),_vm._v(" "),_c('p',[_vm._v("Toutes les opérations de maintenance, alertes de surveillance et autres informations associées à ce moyen sont enregistrées ici."),_c('br'),_vm._v("\n                            Tout évènement est important quant au suivi optimal de l'état de santé du moyen et son historique."),_c('br'),_c('br'),_vm._v("\n                            Dans ce cas n'hésitez pas à nous faire part de ces évènements "),_c('strong',[_vm._v(" en créant de nouvelles opérations.")])])])]},proxy:true}])})],1),_vm._v(" "),_c('v-row',{staticClass:"v-subheader col-1 theme--light mr-3",attrs:{"cols":"2"}},[_c('v-col',{staticClass:"col"})],1)],1),_vm._v(" "),_c('v-col',{staticClass:"row col-12 mb-0 pa-0 mt-8",staticStyle:{"align-items":"flex-end"}},[(_vm.machine)?_c('v-col',{staticClass:"col-auto pa-0 mt-0",attrs:{"color":"white"}},[_c('v-col',{staticClass:"col pt-0 pb-0 mr-10 rounded col ml-6",staticStyle:{"height":"35px","background-color":"rgb(224 232 240)","font-weight":"bold","text-align":"center","font-size":"16px","border":"1px solid #014181","line-height":"35px","color":"#014181"}},[_vm._v("\n                    "+_vm._s(_vm.machine.label)+"\n                ")])],1):_vm._e(),_vm._v(" "),_c('v-col',{staticClass:"row col ml-6"},[_c('v-tooltip',{staticClass:"mr-2",attrs:{"bottom":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",staticStyle:{"border":"1px solid #01418145"},attrs:{"text":"","icon":"","dusk":"logout-button"},on:{"click":function($event){return _vm.getData()}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-tag-multiple")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('global.1_5_2')))])]),_vm._v(" "),_c('v-tooltip',{staticClass:"mr-2",attrs:{"bottom":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",staticStyle:{"border":"1px solid #01418145"},attrs:{"text":"","icon":"","dusk":"logout-button"},on:{"click":function($event){return _vm.getData()}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-refresh")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('machines.refresh')))])])],1),_vm._v(" "),_c('v-col',{staticClass:"row col"})],1),_vm._v(" "),_c('v-col',[_c('datatable-operations-v-2',{staticClass:"mt-0",attrs:{"entity-name":'machine'}}),_vm._v(" "),_c('v-snackbar',{attrs:{"color":_vm.snackbar_type},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-icon',{attrs:{"color":"white"},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("mdi-close")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v("\n                "+_vm._s(_vm.snackbar_text)+"\n                ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }