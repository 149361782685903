var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"profile-view"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('p',{staticClass:"title mb-0 color-primary text-uppercase"},[_vm._v(_vm._s(_vm.$t('profile.profile')))])])],1),_vm._v(" "),_c('v-card',{staticClass:"mt-4",attrs:{"elevation":"0"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{ref:"form"},[_c('v-card-text',[_c('validation-provider',{attrs:{"name":_vm.$t('profile.last_name'),"rules":"required|alpha|max:40"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('profile.last_name'),"error-messages":errors,"filled":"","clearable":""},model:{value:(_vm.edit_user.last_name),callback:function ($$v) {_vm.$set(_vm.edit_user, "last_name", $$v)},expression:"edit_user.last_name"}})]}}],null,true)}),_vm._v(" "),_c('validation-provider',{attrs:{"name":_vm.$t('profile.first_name'),"rules":"required|alpha|max:40"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('profile.first_name'),"error-messages":errors,"filled":"","clearable":""},model:{value:(_vm.edit_user.first_name),callback:function ($$v) {_vm.$set(_vm.edit_user, "first_name", $$v)},expression:"edit_user.first_name"}})]}}],null,true)}),_vm._v(" "),_c('validation-provider',{attrs:{"name":_vm.$t('profile.email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('profile.email'),"error-messages":errors,"filled":"","clearable":""},model:{value:(_vm.edit_user.email),callback:function ($$v) {_vm.$set(_vm.edit_user, "email", $$v)},expression:"edit_user.email"}})]}}],null,true)}),_vm._v(" "),_c('validation-provider',{attrs:{"name":_vm.$t('profile.phone'),"rules":"required|digits:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('profile.phone'),"error-messages":errors,"filled":"","clearable":""},model:{value:(_vm.edit_user.phone),callback:function ($$v) {_vm.$set(_vm.edit_user, "phone", $$v)},expression:"edit_user.phone"}})]}}],null,true)}),_vm._v(" "),_c('v-btn',{attrs:{"block":_vm.is_mobile,"color":"primary"},on:{"click":function($event){_vm.$refs.passwordEdit.dialog_edit_password = true}}},[_vm._v("\n                        "+_vm._s(_vm.$t('profile.modify_own_password'))+"\n                    ")])],1),_vm._v(" "),_c('v-card-actions',{staticClass:"px-4 pb-4 justify-end"},[_c('v-btn',{attrs:{"disabled":invalid,"block":_vm.is_mobile,"loading":_vm.loading_user_profile,"color":"primary"},on:{"click":function($event){return _vm.updateUserProfile()}}},[_vm._v("\n                        "+_vm._s(_vm.$t('profile.update_profile'))+"\n                    ")])],1)],1)]}}])})],1),_vm._v(" "),_c('professional-tree-structure'),_vm._v(" "),_c('password-edit',{ref:"passwordEdit"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }