var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-not-connected",attrs:{"id":"register-view"}},[_c('v-row',{staticClass:"h-100",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"lg":"4","md":"6","sm":"8","cols":"10"}},[_c('v-card',{staticClass:"elevation-12",attrs:{"align-self":"center","loading":_vm.loading}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',[_c('v-card-title',{staticClass:"primary white--text"},[_vm._v("\n                            "+_vm._s(_vm.$t('register.login'))+"\n                        ")]),_vm._v(" "),(!_vm.loading)?[_c('v-card-text',{staticClass:"pt-4"},[_c('validation-provider',{staticClass:"mb-2",attrs:{"rules":"required","name":_vm.$t('register.gender')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mb-2",attrs:{"items":_vm.genders,"item-value":"value","item-text":"label","label":_vm.$t('register.gender'),"prepend-icon":"mdi-card-account-details-outline","single-line":""},model:{value:(_vm.user.gender),callback:function ($$v) {_vm.$set(_vm.user, "gender", $$v)},expression:"user.gender"}})]}}],null,true)}),_vm._v(" "),_c('validation-provider',{staticClass:"mb-2",attrs:{"rules":"required","name":_vm.$t('register.first_name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('register.first_name'),"error-messages":errors,"prepend-icon":"mdi-account","type":"text"},model:{value:(_vm.user.first_name),callback:function ($$v) {_vm.$set(_vm.user, "first_name", $$v)},expression:"user.first_name"}})]}}],null,true)}),_vm._v(" "),_c('validation-provider',{staticClass:"mb-2",attrs:{"rules":"required","name":_vm.$t('register.last_name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('register.last_name'),"error-messages":errors,"prepend-icon":"mdi-account","type":"text"},model:{value:(_vm.user.last_name),callback:function ($$v) {_vm.$set(_vm.user, "last_name", $$v)},expression:"user.last_name"}})]}}],null,true)}),_vm._v(" "),_c('validation-provider',{staticClass:"mb-2",attrs:{"rules":"required|min:10|max:10|numeric","name":_vm.$t('register.phone')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('register.phone'),"error-messages":errors,"prepend-icon":"mdi-phone","type":"text"},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}})]}}],null,true)}),_vm._v(" "),_c('validation-provider',{staticClass:"mb-2",attrs:{"rules":"required|email","name":_vm.$t('register.email')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('register.email'),"error-messages":errors,"prepend-icon":"mdi-at","type":"email","readonly":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})]}}],null,true)}),_vm._v(" "),_c('validation-provider',{staticClass:"mb-2",attrs:{"rules":"required|min:7","name":_vm.$t('register.password'),"vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('register.password'),"prepend-icon":"mdi-lock-check-outline","type":_vm.show_password ? 'text' : 'password',"append-icon":_vm.show_password ? 'mdi-eye' : 'mdi-eye-off',"error-messages":errors},on:{"click:append":function($event){_vm.show_password = !_vm.show_password}},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})]}}],null,true)}),_vm._v(" "),_c('validation-provider',{staticClass:"mb-2",attrs:{"rules":"required|confirmed:password","name":_vm.$t('register.password')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('register.password'),"prepend-icon":"mdi-lock-check-outline","type":_vm.show_confirm_password ? 'text' : 'password',"append-icon":_vm.show_confirm_password ? 'mdi-eye' : 'mdi-eye-off',"error-messages":errors},on:{"click:append":function($event){_vm.show_confirm_password = !_vm.show_confirm_password}},model:{value:(_vm.user.confirm_password),callback:function ($$v) {_vm.$set(_vm.user, "confirm_password", $$v)},expression:"user.confirm_password"}})]}}],null,true)})],1),_vm._v(" "),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"disabled":invalid,"loading":_vm.register_button_loading,"type":"submit","color":"primary","dusk":"login-button"},on:{"click":function($event){$event.preventDefault();return _vm.storeUserFromInvitationAndLogin()}}},[_vm._v("\n                                    "+_vm._s(_vm.$t('register.login'))+"\n                                ")])],1)]:_c('v-progress-linear',{attrs:{"indeterminate":"","color":"blue"}})],2)]}}])})],1)],1)],1),_vm._v(" "),_c('global-snackbar')],1)}
var staticRenderFns = []

export { render, staticRenderFns }