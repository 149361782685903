<template>
    <v-col class="col col-12 mt-0 pt-0 pa-0 mb-6" style="margin-top:0px!important;padding:0px;position:relative; top:0px;">
        <!-- PANEL TITLE -->
        <v-row>
            <v-col class="ml-6 mr-6 pt-5 pl-1" style="background-color: #001047;border-radius: 0px 0px 5px 5px;">
                <v-subheader class="v-subheader col theme--light">
                    <!------------------- Info bulle ---------------------->
                    <v-tooltip bottom dark class="mr-2 ml-2">
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 mr-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                                <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:default>
                        <div style="min-width: 200px;position:relative;z-index: 10;">
                            <strong>Page [MCO Parcs machines clients]</strong>
                            <p>Tous les capteurs installés sur site sont répertoriés sur cette page.</br>
                                L'ojectif de cette centralisation d'information est de détecter les capteur ayant un défaut de fonctionnement.</br>
                                Qu'ils soient déconnectés, nécessitant un remplacement de batterie ou un défaut matériel. </br>
                                Cliquer sur l'un d'entre eux pour le visualiser en détail.
                            </p>
                        </div>
                        </template>
                    </v-tooltip>
                    <p class="title mb-0 color-white" style="color: white!important;">
                        <div style="color:#FFFFFFb3;margin-right:4px;">Administration > </div><div style="color:#FFFFFF;font-weight:bold;"> MCO Parcs machines clients</div>
                    </p>
                </v-subheader>
            </v-col>
        </v-row>

        <!-- RESUME INFO -->
        <v-row class="ml-2 mr-2 mt-6 mb-3" style="border-radius: 5px;">
            <!-- PROFESSIONALS -->
            <v-col class="col pa-0" no-gutters>
                <!-- RESUME ALL CUSTOMERS -->
                <v-col class="pa-0 mb-3">
                    <v-card class="ma-1" elevation="0">
                        <v-card-text>
                            <div class="d-flex flex-row">
                                <v-img contain height="50px" max-width="50px" src="/images/admin/in_stock.png"/>
                                <div class="d-block ml-5">
                                    <span class="color-grey font-weight-bold">Clients actifs</span>
                                    <p class="color-primary font-weight-bold font-20 ma-0">{{ totalProfessionals }}</p>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
                
                <!-- RESUME SENSORS INFECTED -->
                <v-col class="pa-0">
                    <v-card class="ma-1" elevation="0">
                        <v-card-text>
                            <div class="d-flex flex-row">
                                <v-img contain height="50px" max-width="50px" src="/images/admin/installed_offline.png"/>

                                <div class="d-block ml-5">
                                    <span class="color-grey font-weight-bold">Clients impactés</span>
                                    <p class="color-primary font-weight-bold font-20 ma-0">
                                        {{ totalProfessionalsSensorsOFFLINE }}
                                        <span v-if="ClientsImpactRatio <= 5" style="font-size:14px; font-weight: 600;color:green;"> ({{ ClientsImpactRatio }}%) </span>
                                        <span v-if="ClientsImpactRatio <= 10 && ClientsImpactRatio < 95" style="font-size:14px; font-weight: 600;color:orange;"> ({{ ClientsImpactRatio }}%) </span>
                                        <span v-if="ClientsImpactRatio > 10" style="font-size:14px; font-weight: 600;color:red;"> ({{ ClientsImpactRatio }}%) </span>
                                        
                                    </p>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-col>

            <!-- SENSORS 1 -->
            <v-col class="col pa-0 ml-1" no-gutters>
                <!-- RESUME ALL -->
                <v-col class="pa-0 mb-3">
                    <v-card class="ma-1" elevation="0">
                        <v-card-text class="pb-3" style="height: 82px;">
                            <div class="d-flex flex-row">
                                <div class="d-block ml-5 mt-1">
                                    <span v-if="functionnalDevicesRatio >= 95" style="margin-top:10px;font-size:32px; font-weight: 600;color:green;"> {{ functionnalDevicesRatio }}% </span>
                                    <span v-if="functionnalDevicesRatio >= 90 && functionnalDevicesRatio < 95" style="margin-top:10px;font-size:32px; font-weight: 600;color:orange;"> {{ functionnalDevicesRatio }}% </span>
                                    <span v-if="functionnalDevicesRatio < 90" style="margin-top:10px;font-size:32px; font-weight: 600;color:red;"> {{ functionnalDevicesRatio }}% </span>
                                
                                    <div class="pa-0 ma-0 ml-0">
                                        <span class="color-grey font-weight-bold">Taux MCO capteurs</span>
                                    </div>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>

                <!-- RESUME ONLINE -->
                <v-col class="pa-0">
                    <v-card class="ma-1" elevation="0">
                        <v-card-text>
                            <div class="d-flex flex-row">
                                <v-img contain height="50px" max-width="50px" src="/images/admin/installed.png"/>
                                <div class="d-block ml-5">
                                    <span class="color-grey font-weight-bold">
                                        Capteurs connectés (< 12h)
                                    </span>
                                    <p class="color-primary font-weight-bold font-20 ma-0">{{ totalDevicesONLINE }} / {{totalDevicesActivated}}</p>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-col>

            <!-- SENSORS 2 -->
            <v-col class="col pa-0 ml-1" no-gutters>               
                <!-- RESUME STOCK -->
                <v-col class="pa-0 mb-3">
                    <v-card class="ma-1" elevation="0">
                        <v-card-text>
                            <div class="d-flex flex-row">
                                <v-img src="/images/admin/in_stock.png"
                                    contain height="50px" max-width="50px"
                                />

                                <div class="d-block ml-5">
                                    <span class="color-grey font-weight-bold">Capteurs en stock</span>
                                    <p class="color-primary font-weight-bold font-20 ma-0">{{ filteredDevicesStock.length }} / {{ totalDevices }}</p>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>

                <!-- RESUME OFFLINE -->
                <v-col class="pa-0">
                    <v-card class="ma-1" elevation="0">
                        <v-card-text>
                            <div class="d-flex flex-row">
                                <v-img contain height="50px" max-width="50px" src="/images/admin/installed_offline.png"/>
                                <div class="d-block ml-5">
                                    <span class="color-grey font-weight-bold">
                                        Capteurs hors ligne (> 12h)
                                    </span>
                                    <p class="color-primary font-weight-bold font-20 ma-0">{{ totalDevicesOFFLINE }}</p>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-col>

            <!-- GATEWAYS 1 -->
            <v-col class="col pa-0 ml-1" no-gutters>
                <!-- RESUME ALL -->
                <v-col class="pa-0 mb-3">
                    <v-card class="ma-1" elevation="0">
                        <v-card-text class="pb-3" style="height: 82px;">
                            <div class="d-flex flex-row">
                                <div class="d-block ml-5 mt-1" >
                                    <span v-if="functionnalGatewaysRatio >= 95" style="margin-top:10px;font-size:32px; font-weight: 600;color:green;"> {{ functionnalGatewaysRatio }}% </span>
                                    <span v-if="functionnalGatewaysRatio >= 90 && functionnalGatewaysRatio < 95" style="margin-top:10px;font-size:32px; font-weight: 600;color:orange;"> {{ functionnalGatewaysRatio }}% </span>
                                    <span v-if="functionnalGatewaysRatio < 90" style="margin-top:10px;font-size:32px; font-weight: 600;color:red;"> {{ functionnalGatewaysRatio }}% </span>
                                
                                    <div class="pa-0 ma-0 ml-0">
                                        <span class="color-grey font-weight-bold">Taux MCO passerelles</span>
                                    </div>
                                
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>

                <!-- RESUME ONLINE -->
                <v-col class="pa-0">
                    <v-card class="ma-1" elevation="0">
                        <v-card-text>
                            <div class="d-flex flex-row">
                                <v-img contain height="50px" max-width="50px" src="/images/admin/installed.png"/>
                                <div class="d-block ml-5">
                                    <span class="color-grey font-weight-bold">
                                        Passerelles connectées (< 12h)
                                    </span>
                                    <p class="color-primary font-weight-bold font-20 ma-0">{{ totalGatewaysONLINE }}</p>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-col>

            <!-- GATEWAYS 2 -->
            <v-col class="col pa-0 ml-1" no-gutters>              
                <!-- RESUME ALL -->
                <v-col class="pa-0 mb-3">
                    <v-card class="ma-1" elevation="0">
                        <v-card-text>
                            <div class="d-flex flex-row">
                                <v-img contain height="50px" max-width="50px" src="/images/admin/in_stock_installer.png"/>
                                <div class="d-block ml-5">
                                    <span class="color-grey font-weight-bold">Passerelles</span>
                                    <p class="color-primary font-weight-bold font-20 ma-0">{{ totalGateways }}</p>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>

                <!-- RESUME OFFLINE -->
                <v-col class="pa-0">
                    <v-card class="ma-1" elevation="0">
                        <v-card-text>
                            <div class="d-flex flex-row">
                                <v-img contain height="50px" max-width="50px" src="/images/admin/installed_offline.png"/>
                                <div class="d-block ml-5">
                                    <span class="color-grey font-weight-bold">
                                        Passerelles hors ligne (> 12h)
                                    </span>
                                    <p class="color-primary font-weight-bold font-20 ma-0">{{ totalGatewaysOFFLINE }}</p>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-col>
        </v-row>

        <!-- Content -->
        <div class="ml-3 mr-3 pt-0 mt-2" style="border-radius: 5px 5px 0 0;">
            <!-- Différents onglets -->
            <v-col class="col pa-0 ma-0" style="max-width: 100%;">
                <!-- Vue -->
                <v-row class="pt-0" style="margin:0px;height:40px;">
                    <!-- Vues tab -->
                    <v-col class="row ml-0 mt-0 pb-0 pt-0 pl-0" style="position: relative;left:-1px;">
                        <div v-for="vue in vues" :key="vue" class="mr-1">
                            <div v-if="vue.id == active_special_vue" class="ma-auto pl-5 pr-5 pa-2"
                                style="
                                    color: black;
                                    font-weight: 400;
                                    display: flex;
                                    justify-content: left;
                                    text-shadow: rgba(0, 0, 0, 0.29) 2px 2px 4px;
                                    background-color: rgb(255 255 255);
                                    border-radius: 5px 5px 0px 0px;
                                    border-left: 1px solid rgba(211, 211, 211, 0.43);
                                    border-bottom: none;
                                    font-size: 14px;
                                    height: 40px;
                                    align-items: center;
                                    font-weight:bold;
                                "
                            >     
                                <div v-if="active_special_vue == 0 " style="margin-right: 8px;">
                                    <span v-if="filteredDevicesOffline.length > 0" style="font-weight: 800;color: white;background-color: #ff0000;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ filteredDevicesOffline.length }}</span>
                                </div>

                                <div v-if="active_special_vue == 1 " style="margin-right: 8px;">
                                    <span v-if="filteredDevicesOffline.length > 0" style="font-weight: 800;color: white;background-color: #ff0000;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ filteredDevicesLowBattery.length }}</span>
                                </div>

                                <div v-if="active_special_vue == 2 " style="margin-right: 8px;">
                                    <span v-if="filteredDevicesOffline.length > 0" style="font-weight: 800;color: white;background-color: #ff0000;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ filteredGatewaysOffline.length }}</span>
                                </div>     

                                {{vue.label}}
                            </div>
                            <div v-else class="ma-auto pl-5 pr-5 pa-2"
                                style="
                                    color: black;
                                    font-weight: 400;
                                    display: flex;
                                    justify-content: left;
                                    text-shadow: rgba(0, 0, 0, 0.29) 2px 2px 4px;
                                    background-color: rgb(243 243 243 / 90%);
                                    border-radius: 10px 10px 0px 0px;
                                    border: 1px solid #dbdce1;
                                    border-bottom: none;
                                    font-size: 14px;
                                    height: 40px;
                                    cursor: pointer;
                                "
                                @click="loadVue(vue.id)"
                            >
                                <div v-if="vue.id == 0" style="margin-right: 8px;">
                                    <span v-if="filteredDevicesOffline.length > 0" style="font-weight: 800;color: white;background-color: #ff0000;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ filteredDevicesOffline.length }}</span>
                                </div>

                                <div v-if="vue.id == 1" style="margin-right: 8px;">
                                    <span v-if="filteredDevicesOffline.length > 0" style="font-weight: 800;color: white;background-color: #ff0000;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ filteredDevicesLowBattery.length }}</span>
                                </div>

                                <div v-if="vue.id == 2" style="margin-right: 8px;">
                                    <span v-if="filteredDevicesOffline.length > 0" style="font-weight: 800;color: white;background-color: #ff0000;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ filteredGatewaysOffline.length }}</span>
                                </div>

                                {{vue.label}}
                            </div>
                        </div>
                    </v-col>
                </v-row>

                <!-- Capteurs hors ligne (0) -->
                <div v-if="active_special_vue == 0">
                    <v-data-table
                        :headers="offlineHeaders"
                        :items="filteredDevicesOffline"
                        :loading="loading"
                        hide-default-footer
                        :items-per-page="-1"
                        item-value="id"
                        class="text-center"
                        :sort-by="['date_last_seen']"
                        :sort-desc="[false]"
                    >
                        <!-- TOP -->
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-layout class="justify-start mt-2 mb-2 max-width-50">
                                    <v-btn color="primary" class="mr-0 mt-0" @click="downloadCsvPhysicalDevice" style="max-width:36px!important;min-width:36px!important;border-radius: 20px;">
                                        <v-progress-circular v-if="loading_csv_file"
                                                                indeterminate color="white" size="20" width="2"
                                        />
                                        <v-icon v-else center style="font-size: 18px;">mdi-download-box-outline</v-icon>
                                    </v-btn>

                                    <v-row class="elevation-0 pl-5 pt-2 pb-2"  style="align-items: center;">
                                        <h4>Liste des capteurs hors ligne (> 3j) : </h4>
                                        <span v-if="(filteredDevicesOffline.length / totalDevicesActivated) <= 0.05" style="font-weight: 800; color: green; padding-left:8px;">{{ filteredDevicesOffline.length }}</span>
                                        <span v-if="(filteredDevicesOffline.length / totalDevicesActivated) > 0.05 && (filteredDevicesOffline.length / totalDevicesActivated) <= 0.1" style="font-weight: 800; color: orange; padding-left:8px;">{{ filteredDevicesOffline.length }}</span>
                                        <span v-if="(filteredDevicesOffline.length / totalDevicesActivated) > 0.1" style="font-weight: 800; color: red; padding-left:8px;">{{ filteredDevicesOffline.length }}</span>
                                    </v-row>
                                </v-layout>

                                <v-layout class="justify-end mt-7 mr-12 max-width-300">
                                    <v-text-field
                                        v-model="search2"
                                        append-icon="mdi-magnify"
                                        :label="$t('global.search')"
                                    ></v-text-field>
                                </v-layout>

                                <!-- TODO: To be created
                                <v-layout class="justify-end mt-7 max-width-300" style="margin-left:50px">
                                    <v-text-field
                                        v-model="search"
                                        append-icon="mdi-magnify"
                                        :label="$t('global.search')"
                                    ></v-text-field>
                                </v-layout>
                                -->
                            </v-toolbar>
                        </template>

                        <!-- PROFESSIONAL -->
                        <template v-slot:item.professionals_label="{ item }">
                            <p style="font-weight: 800;">{{ item.professionals_label }}</p>
                        </template>

                        <!-- EQUIPEMENT -->
                        <template v-slot:item.machine_label="{ item }">
                            <v-col>
                                <p style="font-weight: 600;">{{ item.machine_label }}</p>
                                <p style="font-style: italic;">{{ item.machine_function }}</p>
                            </v-col>
                        </template>

                        <!-- REFERENCE -->
                        <template v-slot:item.uid="{ item }">
                            <v-col>
                                <div class="ma-0 pa-0" color="primary" style="font-weight: 600; cursor:pointer;">{{item.uid}}</div>
                                <p style="font-style: italic;">{{ item.device_type_label }}</p>
                            </v-col>
                        </template>

                        <!-- Subassembly -->
                        <template v-slot:item.subassembly_label="{ item }">                            
                            <div class="ma-0 pa-0" color="primary" style="font-weight: 600; cursor:pointer;">{{item.subassembly_label}}</div>
                            <p style="font-style: italic;font-size: 10px;">{{ item.subassembly_uid }}</p>
                        </template>

                        <!-- virtualDevice -->
                        <template v-slot:item.virtual_device_label="{ item }">                            
                            <v-row class="pa-0 ma-0">
                                <a :href="item.link" style="margin-right: 5px;">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer"
                                                        v-bind="attrs" v-on="on"
                                                        color="#eb8f00"
                                                >
                                                    mdi-cog-box
                                                </v-icon>
                                            </template>
                                            <span>Voir la configuration Chirpstack du capteur</span>
                                        </v-tooltip>
                                </a>
                                <div class="ma-0 pa-0" color="primary" style="cursor:pointer;">{{item.virtual_device_label}}</div>
                            </v-row>
                            <p style="font-style: italic;font-size: 10px;">{{ item.virtual_device_uid }}</p>
                        </template>

                        <!-- BATTERY -->
                        <template v-slot:item.battery="{ item }">
                            <div style="text-align: center;">
                                <p v-if="item.battery >= 90">
                                    <v-icon style="color: #6AAE5E;">mdi-battery</v-icon>
                                    <br>{{ item.battery }}%
                                </p>
                                <p v-if="item.battery < 90 && item.battery >= 80">
                                    <v-icon style="color: #6AAE5E;">mdi-battery-80</v-icon>
                                    <br>{{ item.battery }}%
                                </p>
                                <p v-if="item.battery < 80 && item.battery >= 70">
                                    <v-icon style="color: #6AAE5E;">mdi-battery-70</v-icon>
                                    <br>{{ item.battery }}%
                                </p>
                                <p v-if="item.battery < 70 && item.battery >= 60">
                                    <v-icon style="color: #6AAE5E;">mdi-battery-60</v-icon>
                                    <br>{{ item.battery }}%
                                </p>
                                <p v-if="item.battery < 60 && item.battery >= 50">
                                    <v-icon style="color: #F3B03D;">mdi-battery-50</v-icon>
                                    <br>{{ item.battery }}%
                                </p>
                                <p v-if="item.battery < 50 && item.battery >= 40">
                                    <v-icon style="color: #F3B03D;">mdi-battery-40</v-icon>
                                    <br>{{ item.battery }}%
                                </p>
                                <p v-if="item.battery < 40 && item.battery >= 30">
                                    <v-icon style="color: #F3B03D;">mdi-battery-30</v-icon>
                                    <br>{{ item.battery }}%
                                </p>
                                <p v-if="item.battery < 30 && item.battery >= 20">
                                    <v-icon style="color: #D91419;">mdi-battery-20</v-icon>
                                    <br>{{ item.battery }}%
                                </p>
                                <p v-if="item.battery < 20 && item.battery >= 10">
                                    <v-icon style="color: #D91419;">mdi-battery-10</v-icon>
                                    <br>{{ item.battery }}%
                                </p>
                                <p v-if="item.battery < 10">
                                    <v-icon style="color: #D91419;">mdi-battery-alert-variant-outline</v-icon>
                                    <br>{{ item.battery }}%
                                </p>
                            </div>
                        </template>

                        <!-- LAST SEEN -->
                        <template v-slot:item.date_last_seen="{ item }">
                            <v-chip small :color="lastEmissionAttributeTranslation(item.date_last_seen).color">
                                <v-icon color="blue-grey darken-3" class="mr-1">
                                    mdi-router-wireless
                                </v-icon>
                                {{ lastEmissionAttributeTranslation(item.date_last_seen).time }}
                            </v-chip>
                        </template>

                        <!-- Regroupement + Park -->
                        <template v-slot:item.building_label="{ item }">
                            <v-col>
                                <p style="font-weight: 600;">{{ item.building_label }}</p>
                                <p style="font-style: italic;">{{ item.sites_label }}</p>
                            </v-col>
                        </template>

                        <!-- STATE -->
                        <template v-slot:item.state="{ item }">
                            <div v-if="item.state == -3">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" style="color: #D91419;">mdi-trash-can-outline</v-icon>
                                    </template>
                                    <span>Au rebus</span>
                                </v-tooltip>
                            </div>
                            <div v-if="item.state == -2">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" style="color: #D91419;">mdi-help-rhombus-outline</v-icon>
                                    </template>
                                    <span>Perdu</span>
                                </v-tooltip>
                            </div>
                            <div v-if="item.state == -1">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" style="color: #F3B03D;">mdi-progress-wrench</v-icon>
                                    </template>
                                    <span>En maintenance</span>
                                </v-tooltip>
                            </div>
                            <div v-if="item.state == 1">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" style="color: #6AAE5E;">mdi-archive-outline</v-icon>
                                    </template>
                                    <span>En stock</span>
                                </v-tooltip>
                            </div>
                            <div v-if="item.state == 2">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" style="color: #ffeb3b;">mdi-account-hard-hat</v-icon>
                                    </template>
                                    <span>Chez l'installateur</span>
                                </v-tooltip>
                            </div>
                            <div v-if="item.state == 3">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" style="color: #6AAE5E;">mdi-check-circle-outline</v-icon>
                                    </template>
                                    <span>En fonctionnement</span>
                                </v-tooltip>
                            </div>
                            <div v-if="item.state == 4">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" style="color: #2196f3;">mdi-cog-clockwise</v-icon>
                                    </template>
                                    <span>A tester</span>
                                </v-tooltip>
                            </div>
                        </template>
                        
                        <!-- ACTIONS -->
                        <template v-slot:item.action="{ item }">
                            <v-col>
                                <v-row>
                                    <template>
                                        <v-tooltip top color="primary">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.id, -3, true)">
                                                    <v-icon v-bind="attrs" v-on="on">mdi-delete-empty</v-icon>
                                                </v-btn>
                                            </template>

                                            <span>{{ $t('physical_devices_datatable.device_scrapping_notice') }}</span>
                                        </v-tooltip>
                                    </template>

                                    <template>
                                        <v-tooltip top color="primary">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.id, 1, true)">
                                                    <v-icon v-bind="attrs" v-on="on">mdi-database</v-icon>
                                                </v-btn>
                                            </template>

                                            <span>{{ $t('physical_devices_datatable.device_storing_notice') }}</span>
                                        </v-tooltip>
                                    </template>

                                    <template>
                                        <v-tooltip top color="primary">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.id, 3, true)">
                                                    <v-icon v-bind="attrs" v-on="on">mdi-video-input-antenna</v-icon>
                                                </v-btn>
                                            </template>

                                            <span>{{ $t('physical_devices_datatable.device_on_operation_state') }}</span>
                                        </v-tooltip>
                                    </template>
                                </v-row>

                                <v-row>
                                    <template>
                                        <v-tooltip top color="primary">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.id, -1, true)">
                                                    <v-icon v-bind="attrs" v-on="on">mdi-cog-refresh</v-icon>
                                                </v-btn>
                                            </template>

                                            <span>{{ $t('physical_devices_datatable.device_in_maintenance_state') }}</span>
                                        </v-tooltip>
                                    </template>

                                    <template>
                                        <v-tooltip top color="primary">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.id, -2, true)">
                                                    <v-icon v-bind="attrs" v-on="on">mdi-progress-close</v-icon>
                                                </v-btn>
                                            </template>

                                            <span>{{ $t('physical_devices_datatable.device_lost_state') }}</span>
                                        </v-tooltip>
                                    </template>

                                    <template>
                                        <v-tooltip top color="primary">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.id, 4, true)">
                                                    <v-icon v-bind="attrs" v-on="on">mdi-clipboard-search</v-icon>
                                                </v-btn>
                                            </template>

                                            <span>{{ $t('physical_devices_datatable.device_testing_notice') }}</span>
                                        </v-tooltip>
                                    </template>
                                </v-row>
                            </v-col>       
                        </template>
                    </v-data-table>
                </div>

                <!-- Capteurs batteries faibles (1) -->
                <div v-if="active_special_vue == 1">
                    <v-data-table
                        :headers="lowBatteryHeaders"
                        :items="filteredDevicesLowBattery"
                        hide-default-footer
                        :items-per-page="-1"
                        class="text-center"
                        :search="search2"
                        :sort-by="['battery']"
                        :sort-desc="[false]"
                    >
                        <!-- TOP -->
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-layout class="justify-start mt-2 mb-2 max-width-50">
                                    <v-btn color="primary" class="mr-0 mt-0" @click="downloadCsvGateway" style="max-width:36px!important;min-width:36px!important;border-radius: 20px;">
                                        <v-progress-circular v-if="loading_csv_file" indeterminate color="white" size="20" width="2"/>
                                        <v-icon v-else center style="font-size: 18px;">mdi-download-box-outline</v-icon>
                                    </v-btn>
                                    <v-row class="elevation-0 pl-5 pt-2 pb-2"  style="align-items: center;">
                                        <h4>Liste des capteurs en batteries faible (< 20%) : </h4>
                                        <span v-if="(filteredDevicesLowBattery.length / totalDevicesActivated) <= 0.05" style="font-weight: 800; color: green; padding-left:8px;">{{ filteredDevicesLowBattery.length }}</span>
                                        <span v-if="(filteredDevicesLowBattery.length / totalDevicesActivated) > 0.05 && (filteredDevicesLowBattery.length / totalDevicesActivated) <= 0.1" style="font-weight: 800; color: orange; padding-left:8px;">{{ filteredDevicesLowBattery.length }}</span>
                                        <span v-if="(filteredDevicesLowBattery.length / totalDevicesActivated) > 0.1" style="font-weight: 800; color: red; padding-left:8px;">{{ filteredDevicesLowBattery.length }}</span>
                                    </v-row>
                                </v-layout>

                                <v-layout class="justify-end mt-7 mr-12 max-width-300">
                                    <v-text-field
                                        v-model="search"
                                        append-icon="mdi-magnify"
                                        :label="$t('global.search')"
                                    ></v-text-field>
                                </v-layout>
                            </v-toolbar>
                        </template>

                        <!-- PROFESSIONAL -->
                        <template v-slot:item.professionals_label="{ item }">
                            <p style="font-weight:800;">{{ item.professionals_label }}</p>
                        </template>

                        <!-- EQUIPEMENT -->
                        <template v-slot:item.machine_label="{ item }">
                            <v-col>
                                <p style="font-weight: 600;">{{ item.machine_label }}</p>
                                <p style="font-style: italic;">{{ item.machine_function }}</p>
                            </v-col>
                        </template>

                        <!-- REFERENCE -->
                        <template v-slot:item.uid="{ item }">
                            <v-col>
                                <div class="ma-0 pa-0" color="primary" style="font-weight: 600; cursor:pointer;" @click="$router.push({ name: 'physical_device', params: {id: item.uid} })">{{item.uid}}</div>
                                <p style="font-style: italic;">{{ item.device_type_label }}</p>
                            </v-col>
                        </template>

                        <!-- Subassembly -->
                        <template v-slot:item.subassembly_label="{ item }">                            
                            <div class="ma-0 pa-0" color="primary" style="font-weight: 600; cursor:pointer;">{{item.subassembly_label}}</div>
                            <p style="font-style: italic;font-size: 10px;">{{ item.subassembly_uid }}</p>
                        </template>

                        <!-- virtualDevice -->
                        <template v-slot:item.virtual_device_label="{ item }">                            
                            <v-row class="pa-0 ma-0">
                                <a :href="item.link" style="margin-right: 5px;">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="pointer"
                                                        v-bind="attrs" v-on="on"
                                                        color="#eb8f00"
                                                >
                                                    mdi-cog-box
                                                </v-icon>
                                            </template>
                                            <span>Voir la configuration Chirpstack du capteur</span>
                                        </v-tooltip>
                                </a>
                                <div class="ma-0 pa-0" color="primary" style="cursor:pointer;">{{item.virtual_device_label}}</div>
                            </v-row>
                            <p style="font-style: italic;font-size: 10px;">{{ item.virtual_device_uid }}</p>
                        </template>

                        <!-- BATTERY -->
                        <template v-slot:item.battery="{ item }">
                            <div style="text-align: center;">
                                <p v-if="item.battery >= 90">
                                    <v-icon style="color: #6AAE5E;">mdi-battery</v-icon>
                                    <br>{{ item.battery }}%
                                </p>
                                <p v-if="item.battery < 90 && item.battery >= 80">
                                    <v-icon style="color: #6AAE5E;">mdi-battery-80</v-icon>
                                    <br>{{ item.battery }}%
                                </p>
                                <p v-if="item.battery < 80 && item.battery >= 70">
                                    <v-icon style="color: #6AAE5E;">mdi-battery-70</v-icon>
                                    <br>{{ item.battery }}%
                                </p>
                                <p v-if="item.battery < 70 && item.battery >= 60">
                                    <v-icon style="color: #6AAE5E;">mdi-battery-60</v-icon>
                                    <br>{{ item.battery }}%
                                </p>
                                <p v-if="item.battery < 60 && item.battery >= 50">
                                    <v-icon style="color: #F3B03D;">mdi-battery-50</v-icon>
                                    <br>{{ item.battery }}%
                                </p>
                                <p v-if="item.battery < 50 && item.battery >= 40">
                                    <v-icon style="color: #F3B03D;">mdi-battery-40</v-icon>
                                    <br>{{ item.battery }}%
                                </p>
                                <p v-if="item.battery < 40 && item.battery >= 30">
                                    <v-icon style="color: #F3B03D;">mdi-battery-30</v-icon>
                                    <br>{{ item.battery }}%
                                </p>
                                <p v-if="item.battery < 30 && item.battery >= 20">
                                    <v-icon style="color: #D91419;">mdi-battery-20</v-icon>
                                    <br>{{ item.battery }}%
                                </p>
                                <p v-if="item.battery < 20 && item.battery >= 10">
                                    <v-icon style="color: #D91419;">mdi-battery-10</v-icon>
                                    <br>{{ item.battery }}%
                                </p>
                                <p v-if="item.battery < 10">
                                    <v-icon style="color: #D91419;">mdi-battery-alert-variant-outline</v-icon>
                                    <br>{{ item.battery }}%
                                </p>
                            </div>
                        </template>

                        <!-- LAST SEEN -->
                        <template v-slot:item.date_last_seen="{ item }">
                            <v-chip small :color="lastEmissionAttributeTranslation(item.date_last_seen).color">
                                <v-icon color="blue-grey darken-3" class="mr-1">
                                    mdi-router-wireless
                                </v-icon>
                                {{ lastEmissionAttributeTranslation(item.date_last_seen).time }}
                            </v-chip>
                        </template>

                        <!-- Regroupement + Park -->
                        <template v-slot:item.building_label="{ item }">
                            <v-col>
                                <p style="font-weight: 600;">{{ item.building_label }}</p>
                                <p style="font-style: italic;">{{ item.sites_label }}</p>
                            </v-col>
                        </template>

                        <!-- STATE -->
                        <template v-slot:item.state="{ item }">
                            <div v-if="item.state == -3">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" style="color: #D91419;">mdi-trash-can-outline</v-icon>
                                    </template>
                                    <span>Au rebus</span>
                                </v-tooltip>
                            </div>
                            <div v-if="item.state == -2">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" style="color: #D91419;">mdi-help-rhombus-outline</v-icon>
                                    </template>
                                    <span>Perdu</span>
                                </v-tooltip>
                            </div>
                            <div v-if="item.state == -1">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" style="color: #F3B03D;">mdi-progress-wrench</v-icon>
                                    </template>
                                    <span>En maintenance</span>
                                </v-tooltip>
                            </div>
                            <div v-if="item.state == 1">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" style="color: #6AAE5E;">mdi-archive-outline</v-icon>
                                    </template>
                                    <span>En stock</span>
                                </v-tooltip>
                            </div>
                            <div v-if="item.state == 2">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" style="color: #ffeb3b;">mdi-account-hard-hat</v-icon>
                                    </template>
                                    <span>Chez l'installateur</span>
                                </v-tooltip>
                            </div>
                            <div v-if="item.state == 3">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" style="color: #6AAE5E;">mdi-check-circle-outline</v-icon>
                                    </template>
                                    <span>En fonctionnement</span>
                                </v-tooltip>
                            </div>
                            <div v-if="item.state == 4">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" style="color: #2196f3;">mdi-cog-clockwise</v-icon>
                                    </template>
                                    <span>A tester</span>
                                </v-tooltip>
                            </div>
                        </template>
                        
                        <!-- ACTIONS -->
                        <template v-slot:item.action="{ item }">
                            <v-col>
                                <v-row>
                                    <template>
                                        <v-tooltip top color="primary">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.id, -3, true)">
                                                    <v-icon v-bind="attrs" v-on="on">mdi-delete-empty</v-icon>
                                                </v-btn>
                                            </template>

                                            <span>{{ $t('physical_devices_datatable.device_scrapping_notice') }}</span>
                                        </v-tooltip>
                                    </template>

                                    <template>
                                        <v-tooltip top color="primary">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.id, 1, true)">
                                                    <v-icon v-bind="attrs" v-on="on">mdi-database</v-icon>
                                                </v-btn>
                                            </template>

                                            <span>{{ $t('physical_devices_datatable.device_storing_notice') }}</span>
                                        </v-tooltip>
                                    </template>

                                    <template>
                                        <v-tooltip top color="primary">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.id, 3, true)">
                                                    <v-icon v-bind="attrs" v-on="on">mdi-video-input-antenna</v-icon>
                                                </v-btn>
                                            </template>

                                            <span>{{ $t('physical_devices_datatable.device_on_operation_state') }}</span>
                                        </v-tooltip>
                                    </template>
                                </v-row>

                                <v-row>
                                    <template>
                                        <v-tooltip top color="primary">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.id, -1, true)">
                                                    <v-icon v-bind="attrs" v-on="on">mdi-cog-refresh</v-icon>
                                                </v-btn>
                                            </template>

                                            <span>{{ $t('physical_devices_datatable.device_in_maintenance_state') }}</span>
                                        </v-tooltip>
                                    </template>

                                    <template>
                                        <v-tooltip top color="primary">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.id, -2, true)">
                                                    <v-icon v-bind="attrs" v-on="on">mdi-progress-close</v-icon>
                                                </v-btn>
                                            </template>

                                            <span>{{ $t('physical_devices_datatable.device_lost_state') }}</span>
                                        </v-tooltip>
                                    </template>

                                    <template>
                                        <v-tooltip top color="primary">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.id, 4, true)">
                                                    <v-icon v-bind="attrs" v-on="on">mdi-clipboard-search</v-icon>
                                                </v-btn>
                                            </template>

                                            <span>{{ $t('physical_devices_datatable.device_testing_notice') }}</span>
                                        </v-tooltip>
                                    </template>
                                </v-row>
                            </v-col>       
                        </template>
                    </v-data-table>
                </div>

                <!-- Passerelles hors ligne (2) -->
                <div v-if="active_special_vue == 2">
                    <v-data-table
                        :headers="headersGateways"
                        :search="search"
                        :items="filteredGatewaysOffline"
                        :loading="gateway_data_table_loading"
                        hide-default-footer
                        :items-per-page="-1"
                        :sort-by="['date_last_seen']"
                        :sort-desc="[false]"
                    >
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-layout class="justify-start mt-2 mb-2 max-width-50">
                                    <v-btn color="primary" class="mr-0 mt-0" @click="downloadCsvGateway" style="max-width:36px!important;min-width:36px!important;border-radius: 20px;">
                                        <v-progress-circular v-if="loading_csv_file" indeterminate color="white" size="20" width="2"/>
                                        <v-icon v-else center style="font-size: 18px;">mdi-download-box-outline</v-icon>
                                    </v-btn>
                                    <v-row class="elevation-0 pl-5 pt-2 pb-2" style="align-items: center;">
                                        <h4>Liste des passerelles hors ligne (> 3j) : </h4>
                                        <span v-if="(filteredGatewaysOffline.length / totalGateways) <= 0.05" style="font-weight: 800; color: green; padding-left:8px;">{{ filteredGatewaysOffline.length }}</span>
                                        <span v-if="(filteredGatewaysOffline.length / totalGateways) > 0.05 && (filteredGatewaysOffline.length / totalGateways) <= 0.1" style="font-weight: 800; color: orange; padding-left:8px;">{{ filteredGatewaysOffline.length }}</span>
                                        <span v-if="(filteredGatewaysOffline.length / totalGateways) > 0.1" style="font-weight: 800; color: red; padding-left:8px;">{{ filteredGatewaysOffline.length }}</span>
                                    </v-row>
                                </v-layout>

                                <v-layout class="justify-end mt-7 mr-12 max-width-300">
                                    <v-text-field
                                        v-model="search"
                                        append-icon="mdi-magnify"
                                        :label="$t('global.search')"
                                    ></v-text-field>
                                </v-layout>
                            </v-toolbar>
                        </template>

                        <!-- REFERENCE -->
                        <template v-slot:item.id="{ item }">
                            <v-col>
                                <div class="ma-0 pa-0" color="primary" style="font-weight: 600; cursor:pointer;" @click="$router.push({ name: 'gateway', params: {id: item.id} })">{{item.hardware_mac}}</div>
                            </v-col>
                        </template>

                        <template v-slot:item.date_last_seen="{ item }">
                            <template v-if="item.date_last_seen !==null">
                                <v-chip small :color="lastEmissionAttributeTranslation(item.date_last_seen).color">
                                    <v-icon color="blue-grey darken-3" class="mr-1">
                                        mdi-router-wireless
                                    </v-icon>
                                    {{ lastEmissionAttributeTranslation(item.date_last_seen).time }}
                                </v-chip>
                            </template>

                            <template v-else>
                                <v-icon>mdi-cancel</v-icon>
                            </template>
                        </template>

                        <!-- STATE -->
                        <template v-slot:item.state="{ item }">
                            <div v-if="item.state == -3">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" style="color: #D91419;">mdi-trash-can-outline</v-icon>
                                    </template>
                                    <span>Au rebus</span>
                                </v-tooltip>
                            </div>
                            <div v-if="item.state == -2">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" style="color: #D91419;">mdi-help-rhombus-outline</v-icon>
                                    </template>
                                    <span>Perdu</span>
                                </v-tooltip>
                            </div>
                            <div v-if="item.state == -1">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" style="color: #F3B03D;">mdi-progress-wrench</v-icon>
                                    </template>
                                    <span>En maintenance</span>
                                </v-tooltip>
                            </div>
                            <div v-if="item.state == 1">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" style="color: #6AAE5E;">mdi-archive-outline</v-icon>
                                    </template>
                                    <span>En stock</span>
                                </v-tooltip>
                            </div>
                            <div v-if="item.state == 2">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" style="color: #ffeb3b;">mdi-account-hard-hat</v-icon>
                                    </template>
                                    <span>Chez l'installateur</span>
                                </v-tooltip>
                            </div>
                            <div v-if="item.state == 3">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" style="color: #6AAE5E;">mdi-check-circle-outline</v-icon>
                                    </template>
                                    <span>En fonctionnement</span>
                                </v-tooltip>
                            </div>
                            <div v-if="item.state == 4">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" style="color: #2196f3;">mdi-cog-clockwise</v-icon>
                                    </template>
                                    <span>A tester</span>
                                </v-tooltip>
                            </div>
                        </template>

                        <template v-slot:item.user="{ item }">
                            <span>{{ checkGatewayAndPhysicalDevicesOwnership(item.user, item.state) }}</span>
                        </template>

                        <template v-slot:item.address="{ item }">
                            <span class="address-button"
                                @click="$refs.location.gatewayLocationDialogOpen(item.address, item.latitude, item.longitude)">
                                {{ item.address }}
                            </span>
                        </template>

                        <template v-slot:item.action="{ item }">
                            <v-col>
                                <v-row>
                                    <v-tooltip top color="primary">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.uid, -3, false)">
                                                <v-icon v-bind="attrs" v-on="on">mdi-delete-empty</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('gateways_datatable.gateway_scrapping_notice') }}</span>
                                    </v-tooltip>

                                    <v-tooltip top color="primary">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.uid, 1, false)">
                                                <v-icon v-bind="attrs" v-on="on">mdi-database</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('gateways_datatable.gateway_storing_notice') }}</span>
                                    </v-tooltip>

                                    <v-tooltip top color="primary">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.uid, 3, false)">
                                                <v-icon v-bind="attrs" v-on="on">mdi-video-input-antenna</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('gateways_datatable.gateway_on_operation_state') }}</span>
                                    </v-tooltip>
                                </v-row>

                                <v-row>
                                    <v-tooltip top color="primary">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.uid, -1, false)">
                                                <v-icon v-bind="attrs" v-on="on">mdi-cog-refresh</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('gateways_datatable.gateway_in_maintenance_state') }}</span>
                                    </v-tooltip>

                                    <v-tooltip top color="primary">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.uid, -2, false)">
                                                <v-icon v-bind="attrs" v-on="on">mdi-progress-close</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('gateways_datatable.gateway_lost_state') }}</span>
                                    </v-tooltip>

                                    <v-tooltip top color="primary">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.uid, 4, false)">
                                                <v-icon v-bind="attrs" v-on="on">mdi-clipboard-search</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('gateways_datatable.gateway_to_test_state') }}</span>
                                    </v-tooltip>
                                </v-row>
                            </v-col>
                        </template>
                    </v-data-table> 
                </div>
                
                <!-- Expansion panels pour regrouper les professionnels (3)-->
                <div v-if="active_special_vue == 3" class="elevation-1 mb-0">
                    <v-toolbar flat>
                        <v-layout class="justify-start mt-2 mb-2 max-width-50">
                            <v-btn color="primary" class="mr-0 mt-0" @click="downloadCsvPhysicalDevice" style="max-width:36px!important;min-width:36px!important;border-radius: 20px;">
                                <v-progress-circular v-if="loading_csv_file"
                                                        indeterminate color="white" size="20" width="2"
                                />
                                <v-icon v-else center style="font-size: 18px;">mdi-download-box-outline</v-icon>
                            </v-btn>

                            <div class="elevation-0 pl-5 pt-2 pb-2" >
                                <h4>Liste des capteurs par clients</h4>
                            </div>
                        </v-layout>

                        <v-layout class="justify-end mt-7 mr-12 max-width-300">
                            <v-text-field
                                v-model="search2"
                                append-icon="mdi-magnify"
                                :label="$t('global.search')"
                            ></v-text-field>
                        </v-layout>

                        <!-- TODO: To be created
                        <v-layout class="justify-end mt-7 max-width-300" style="margin-left:50px">
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                :label="$t('global.search')"
                            ></v-text-field>
                        </v-layout>
                        -->
                    </v-toolbar>

                    <v-expansion-panels v-if="TAB_sensors" style="border-radius: 0px 0px 5px 5px;">
                        <v-expansion-panel v-for="professional in groupedDevicesProfessionalComputed" :key="professional">
                            <v-expansion-panel-header>
                                <v-row class="d-flex align-center" style="justify-content: space-between; max-height:60px;min-height:60px;">
                                    <div style="margin-left:20px; display: flex; align-items: center;">
                                        <div v-if="professional.professionals_logo != '' || professional.professionals_logo != 'null'" style="display: flex; justify-content: center; width: 100px;">
                                            <img :src="professional.professionals_logo" alt="Logo" style="max-width:55px; max-height: 45px;"/>
                                        </div>
                                        <div v-else style="display: flex; justify-content: center; width: 100px;">
                                            <img src="https://techview.fr/wp-content/uploads/2025/01/Capture4777.jpg" alt="Logo" style="max-width:75px; max-height: 75px;"/>
                                        </div>
                                        <span style="margin-left: 20px;">{{ professional.professional }} ({{ professional.deviceCount }} capteurs)</span>
                                    </div>
                                    
                                    <div style="margin-right:20px; display: flex;">
                                        <div style="display: flex; align-items: center;">
                                            <p v-if="professional.batteryBetween20And60 > 0" style="margin-right: 10px;"><v-icon style="color:orange">mdi-battery-50</v-icon> ({{ professional.batteryBetween20And60  }})</p>
                                            <p v-if="professional.batteryLessThan20 > 0" style="margin-right: 10px;"><v-icon style="color:red">mdi-battery-alert-variant-outline</v-icon> ({{ professional.batteryLessThan20  }})</p>
                                        </div>
                                        <div style=" display: flex; align-items: center;justify-content: flex-end;">
                                            <p v-if="professional.between12And3Days > 0" style="margin-right: 10px;"><v-icon style="color:orange">mdi-router-wireless</v-icon> ({{ professional.between12And3Days  }})</p>
                                            <p v-if="professional.moreThan3Days > 0" style="margin-right: 10px;"><v-icon style="color:red">mdi-router-wireless</v-icon> ({{ professional.moreThan3Days  }})</p>
                                            <p style="width: 150px; display: flex; justify-content: flex-end;">{{ professional.oldestDateLastSeen }}</p>
                                        </div>
                                        
                                    </div>
                                </v-row>
                            </v-expansion-panel-header>

                            <v-expansion-panel-content>
                                <!-- DATA TABLE CAPTEURS -->
                                <v-data-table
                                    v-if="groupedDevicesProfessionalComputed  && groupedDevicesProfessionalComputed .length > 0"
                                    :headers="equipmentHeaders"
                                    :items="professional.devices"
                                    item-value="label"
                                    hide-default-footer
                                    :items-per-page="-1"
                                    class="text-center"
                                    :sort-by="['dateLastSeen']"
                                    :sort-desc="[false]"
                                    style="background-color:#00000008;"
                                    :search="search2"
                                >

                                    <!-- EQUIPEMENT -->
                                    <template v-slot:item.equipement="{ item }">
                                        <v-col>
                                            <p style="font-weight: 600;">{{ item.equipement }}</p>
                                            <p style="font-style: italic;">{{ item.function }}</p>
                                        </v-col>
                                    </template>

                                    <!-- REFERENCE -->
                                    <template v-slot:item.id="{ item }">
                                        <v-col>
                                            <div class="ma-0 pa-0" color="primary" style="font-weight: 600; cursor:pointer;" @click="$router.push({ name: 'physical_device', params: {id: item.id} })">{{item.id}}</div>
                                            <p style="font-style: italic;">{{ item.type }}</p>
                                        </v-col>
                                    </template>

                                    <!-- Subassembly -->
                                    <template v-slot:item.subassembly="{ item }">                            
                                        <div class="ma-0 pa-0" color="primary" style="font-weight: 600; cursor:pointer;">{{item.subassembly}}</div>
                                        <p style="font-style: italic;font-size: 10px;">{{ item.sseUid }}</p>
                                    </template>

                                    <!-- virtualDevice -->
                                    <template v-slot:item.virtualDevice="{ item }">                            
                                        <v-row class="pa-0 ma-0">
                                            <a :href="item.link" style="margin-right: 5px;">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon class="pointer"
                                                                    v-bind="attrs" v-on="on"
                                                                    color="#eb8f00"
                                                            >
                                                                mdi-cog-box
                                                            </v-icon>
                                                        </template>
                                                        <span>Voir la configuration Chirpstack du capteur</span>
                                                    </v-tooltip>
                                            </a>
                                            <div class="ma-0 pa-0" color="primary" style="cursor:pointer;">{{item.virtualDevice}}</div>
                                        </v-row>
                                        <p style="font-style: italic;font-size: 10px;">{{ item.vdUid }}</p>
                                    </template>

                                    <!-- BATTERY -->
                                    <template v-slot:item.battery="{ item }">
                                        <div style="text-align: center;">
                                            <p v-if="item.battery >= 90">
                                                <v-icon style="color: #6AAE5E;">mdi-battery</v-icon>
                                                <br>{{ item.battery }}%
                                            </p>
                                            <p v-if="item.battery < 90 && item.battery >= 80">
                                                <v-icon style="color: #6AAE5E;">mdi-battery-80</v-icon>
                                                <br>{{ item.battery }}%
                                            </p>
                                            <p v-if="item.battery < 80 && item.battery >= 70">
                                                <v-icon style="color: #6AAE5E;">mdi-battery-70</v-icon>
                                                <br>{{ item.battery }}%
                                            </p>
                                            <p v-if="item.battery < 70 && item.battery >= 60">
                                                <v-icon style="color: #6AAE5E;">mdi-battery-60</v-icon>
                                                <br>{{ item.battery }}%
                                            </p>
                                            <p v-if="item.battery < 60 && item.battery >= 50">
                                                <v-icon style="color: #F3B03D;">mdi-battery-50</v-icon>
                                                <br>{{ item.battery }}%
                                            </p>
                                            <p v-if="item.battery < 50 && item.battery >= 40">
                                                <v-icon style="color: #F3B03D;">mdi-battery-40</v-icon>
                                                <br>{{ item.battery }}%
                                            </p>
                                            <p v-if="item.battery < 40 && item.battery >= 30">
                                                <v-icon style="color: #F3B03D;">mdi-battery-30</v-icon>
                                                <br>{{ item.battery }}%
                                            </p>
                                            <p v-if="item.battery < 30 && item.battery >= 20">
                                                <v-icon style="color: #D91419;">mdi-battery-20</v-icon>
                                                <br>{{ item.battery }}%
                                            </p>
                                            <p v-if="item.battery < 20 && item.battery >= 10">
                                                <v-icon style="color: #D91419;">mdi-battery-10</v-icon>
                                                <br>{{ item.battery }}%
                                            </p>
                                            <p v-if="item.battery < 10">
                                                <v-icon style="color: #D91419;">mdi-battery-alert-variant-outline</v-icon>
                                                <br>{{ item.battery }}%
                                            </p>
                                        </div>
                                    </template>

                                    <!-- LAST SEEN -->
                                    <template v-slot:item.dateLastSeen="{ item }">
                                        <template v-if="item.dateLastSeen !==null">
                                            <v-chip small :color="lastEmissionAttributeTranslation(item.dateLastSeen).color">
                                                <v-icon color="blue-grey darken-3" class="mr-1">
                                                    mdi-router-wireless
                                                </v-icon>
                                                {{ lastEmissionAttributeTranslation(item.dateLastSeen).time }}
                                            </v-chip>
                                        </template>
                                    </template>

                                    <!-- Regroupement + Park -->
                                    <template v-slot:item.regroupement="{ item }">
                                        <v-col>
                                            <p style="font-weight: 600;">{{ item.regroupement }}</p>
                                            <p style="font-style: italic;">{{ item.park }}</p>
                                        </v-col>
                                    </template>

                                    <!-- STATE -->
                                    <template v-slot:item.state="{ item }">
                                        <div v-if="item.state == -3">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon v-on="on" style="color: #D91419;">mdi-trash-can-outline</v-icon>
                                                </template>
                                                <span>Au rebus</span>
                                            </v-tooltip>
                                        </div>
                                        <div v-if="item.state == -2">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon v-on="on" style="color: #D91419;">mdi-help-rhombus-outline</v-icon>
                                                </template>
                                                <span>Perdu</span>
                                            </v-tooltip>
                                        </div>
                                        <div v-if="item.state == -1">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon v-on="on" style="color: #F3B03D;">mdi-progress-wrench</v-icon>
                                                </template>
                                                <span>En maintenance</span>
                                            </v-tooltip>
                                        </div>
                                        <div v-if="item.state == 1">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon v-on="on" style="color: #6AAE5E;">mdi-archive-outline</v-icon>
                                                </template>
                                                <span>En stock</span>
                                            </v-tooltip>
                                        </div>
                                        <div v-if="item.state == 2">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon v-on="on" style="color: #ffeb3b;">mdi-account-hard-hat</v-icon>
                                                </template>
                                                <span>Chez l'installateur</span>
                                            </v-tooltip>
                                        </div>
                                        <div v-if="item.state == 3">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon v-on="on" style="color: #6AAE5E;">mdi-check-circle-outline</v-icon>
                                                </template>
                                                <span>En fonctionnement</span>
                                            </v-tooltip>
                                        </div>
                                        <div v-if="item.state == 4">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon v-on="on" style="color: #2196f3;">mdi-cog-clockwise</v-icon>
                                                </template>
                                                <span>A tester</span>
                                            </v-tooltip>
                                        </div>
                                    </template>

                                    <!-- LINK 
                                    <template v-slot:item.link="{ item }">                            
                                        <a :href="item.link">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon class="pointer"
                                                            v-bind="attrs" v-on="on"
                                                            color="grey"
                                                    >
                                                        mdi-cog-box
                                                    </v-icon>
                                                </template>
                                                <span>Voir la configuration Chirpstack du capteur</span>
                                            </v-tooltip>
                                        </a>
                                    </template>
                                    -->

                                    <!-- ACTIONS -->
                                    <template v-slot:item.action="{ item }">
                                        <v-col>
                                            <v-row>
                                                <template>
                                                    <v-tooltip top color="primary">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.id, -3, true)">
                                                                <v-icon v-bind="attrs" v-on="on">mdi-delete-empty</v-icon>
                                                            </v-btn>
                                                        </template>

                                                        <span>{{ $t('physical_devices_datatable.device_scrapping_notice') }}</span>
                                                    </v-tooltip>
                                                </template>

                                                <template>
                                                    <v-tooltip top color="primary">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.id, 1, true)">
                                                                <v-icon v-bind="attrs" v-on="on">mdi-database</v-icon>
                                                            </v-btn>
                                                        </template>

                                                        <span>{{ $t('physical_devices_datatable.device_storing_notice') }}</span>
                                                    </v-tooltip>
                                                </template>

                                                <template>
                                                    <v-tooltip top color="primary">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.id, 3, true)">
                                                                <v-icon v-bind="attrs" v-on="on">mdi-video-input-antenna</v-icon>
                                                            </v-btn>
                                                        </template>

                                                        <span>{{ $t('physical_devices_datatable.device_on_operation_state') }}</span>
                                                    </v-tooltip>
                                                </template>
                                            </v-row>

                                            <v-row>
                                                <template>
                                                    <v-tooltip top color="primary">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.id, -1, true)">
                                                                <v-icon v-bind="attrs" v-on="on">mdi-cog-refresh</v-icon>
                                                            </v-btn>
                                                        </template>

                                                        <span>{{ $t('physical_devices_datatable.device_in_maintenance_state') }}</span>
                                                    </v-tooltip>
                                                </template>

                                                <template>
                                                    <v-tooltip top color="primary">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.id, -2, true)">
                                                                <v-icon v-bind="attrs" v-on="on">mdi-progress-close</v-icon>
                                                            </v-btn>
                                                        </template>

                                                        <span>{{ $t('physical_devices_datatable.device_lost_state') }}</span>
                                                    </v-tooltip>
                                                </template>

                                                <template>
                                                    <v-tooltip top color="primary">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.id, 4, true)">
                                                                <v-icon v-bind="attrs" v-on="on">mdi-clipboard-search</v-icon>
                                                            </v-btn>
                                                        </template>

                                                        <span>{{ $t('physical_devices_datatable.device_testing_notice') }}</span>
                                                    </v-tooltip>
                                                </template>
                                            </v-row>
                                        </v-col>       
                                    </template>
                                                        
                                </v-data-table>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </div>

                <!-- Vue passerelles tous clients (4) -->
                <div v-if="active_special_vue == 4">
                    <v-data-table
                        :headers="headersGateways"
                        :search="search"
                        :items="gateways"
                        :loading="gateway_data_table_loading"
                        hide-default-footer
                        :items-per-page="-1"
                        :sort-by="['date_last_seen']"
                        :sort-desc="[false]"
                    >
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-layout class="justify-start mt-2 mb-2 max-width-50">
                                    <v-btn color="primary" class="mr-0 mt-0" @click="downloadCsvGateway" style="max-width:36px!important;min-width:36px!important;border-radius: 20px;">
                                        <v-progress-circular v-if="loading_csv_file" indeterminate color="white" size="20" width="2"/>
                                        <v-icon v-else center style="font-size: 18px;">mdi-download-box-outline</v-icon>
                                    </v-btn>
                                    <div class="elevation-0 pl-5 pt-2 pb-2" >
                                        <h4>Liste des passerelles par clients</h4>
                                    </div>
                                </v-layout>

                                <v-layout class="justify-end mt-7 mr-12 max-width-300">
                                    <v-text-field
                                        v-model="search"
                                        append-icon="mdi-magnify"
                                        :label="$t('global.search')"
                                    ></v-text-field>
                                </v-layout>
                            </v-toolbar>
                        </template>

                        <!-- REFERENCE -->
                        <template v-slot:item.id="{ item }">
                            <v-col>
                                <div class="ma-0 pa-0" color="primary" style="font-weight: 600; cursor:pointer;" @click="$router.push({ name: 'gateway', params: {id: item.id} })">{{item.hardware_mac}}</div>
                            </v-col>
                        </template>

                        <template v-slot:item.date_last_seen="{ item }">
                            <template v-if="item.date_last_seen !==null">
                                <v-chip small :color="lastEmissionAttributeTranslation(item.date_last_seen).color">
                                    <v-icon color="blue-grey darken-3" class="mr-1">
                                        mdi-router-wireless
                                    </v-icon>
                                    {{ lastEmissionAttributeTranslation(item.date_last_seen).time }}
                                </v-chip>
                            </template>

                            <template v-else>
                                <v-icon>mdi-cancel</v-icon>
                            </template>
                        </template>

                        <!-- STATE -->
                        <template v-slot:item.state="{ item }">
                            <div v-if="item.state == -3">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" style="color: #D91419;">mdi-trash-can-outline</v-icon>
                                    </template>
                                    <span>Au rebus</span>
                                </v-tooltip>
                            </div>
                            <div v-if="item.state == -2">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" style="color: #D91419;">mdi-help-rhombus-outline</v-icon>
                                    </template>
                                    <span>Perdu</span>
                                </v-tooltip>
                            </div>
                            <div v-if="item.state == -1">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" style="color: #F3B03D;">mdi-progress-wrench</v-icon>
                                    </template>
                                    <span>En maintenance</span>
                                </v-tooltip>
                            </div>
                            <div v-if="item.state == 1">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" style="color: #6AAE5E;">mdi-archive-outline</v-icon>
                                    </template>
                                    <span>En stock</span>
                                </v-tooltip>
                            </div>
                            <div v-if="item.state == 2">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" style="color: #ffeb3b;">mdi-account-hard-hat</v-icon>
                                    </template>
                                    <span>Chez l'installateur</span>
                                </v-tooltip>
                            </div>
                            <div v-if="item.state == 3">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" style="color: #6AAE5E;">mdi-check-circle-outline</v-icon>
                                    </template>
                                    <span>En fonctionnement</span>
                                </v-tooltip>
                            </div>
                            <div v-if="item.state == 4">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" style="color: #2196f3;">mdi-cog-clockwise</v-icon>
                                    </template>
                                    <span>A tester</span>
                                </v-tooltip>
                            </div>
                        </template>

                        <template v-slot:item.user="{ item }">
                            <span>{{ checkGatewayAndPhysicalDevicesOwnership(item.user, item.state) }}</span>
                        </template>

                        <template v-slot:item.address="{ item }">
                            <span class="address-button"
                                @click="$refs.location.gatewayLocationDialogOpen(item.address, item.latitude, item.longitude)">
                                {{ item.address }}
                            </span>
                        </template>

                        <template v-slot:item.action="{ item }">
                            <v-col>
                                <v-row>
                                    <v-tooltip top color="primary">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.uid, -3, false)">
                                                <v-icon v-bind="attrs" v-on="on">mdi-delete-empty</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('gateways_datatable.gateway_scrapping_notice') }}</span>
                                    </v-tooltip>

                                    <v-tooltip top color="primary">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.uid, 1, false)">
                                                <v-icon v-bind="attrs" v-on="on">mdi-database</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('gateways_datatable.gateway_storing_notice') }}</span>
                                    </v-tooltip>

                                    <v-tooltip top color="primary">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.uid, 3, false)">
                                                <v-icon v-bind="attrs" v-on="on">mdi-video-input-antenna</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('gateways_datatable.gateway_on_operation_state') }}</span>
                                    </v-tooltip>
                                </v-row>

                                <v-row>
                                    <v-tooltip top color="primary">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.uid, -1, false)">
                                                <v-icon v-bind="attrs" v-on="on">mdi-cog-refresh</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('gateways_datatable.gateway_in_maintenance_state') }}</span>
                                    </v-tooltip>

                                    <v-tooltip top color="primary">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.uid, -2, false)">
                                                <v-icon v-bind="attrs" v-on="on">mdi-progress-close</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('gateways_datatable.gateway_lost_state') }}</span>
                                    </v-tooltip>

                                    <v-tooltip top color="primary">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.uid, 4, false)">
                                                <v-icon v-bind="attrs" v-on="on">mdi-clipboard-search</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('gateways_datatable.gateway_to_test_state') }}</span>
                                    </v-tooltip>
                                </v-row>
                            </v-col>
                        </template>
                    </v-data-table>
                </div>
                
                <!-- Vue stock (5)-->
                <div v-if="active_special_vue == 5">
                    <v-data-table
                        :headers="stockHeaders"
                        :items="filteredDevicesStock"
                        hide-default-footer
                        :items-per-page="-1"
                        class="text-center"
                        :search="search2"
                        :sort-by="['date_last_seen']"
                        :sort-desc="[true]"
                    >
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-layout class="justify-start mt-2 mb-2 max-width-50">
                                    <v-btn color="primary" class="mr-0 mt-0" @click="downloadCsvGateway" style="max-width:36px!important;min-width:36px!important;border-radius: 20px;">
                                        <v-progress-circular v-if="loading_csv_file" indeterminate color="white" size="20" width="2"/>
                                        <v-icon v-else center style="font-size: 18px;">mdi-download-box-outline</v-icon>
                                    </v-btn>
                                    <div class="elevation-0 pl-5 pt-2 pb-2" >
                                        <h4>Liste des capteurs en stock : <span style="font-weight:800;">{{ filteredDevicesStock.length}}</span></h4>
                                    </div>
                                </v-layout>

                                <v-layout class="justify-end mt-7 mr-12 max-width-300">
                                    <v-text-field
                                        v-model="search"
                                        append-icon="mdi-magnify"
                                        :label="$t('global.search')"
                                    ></v-text-field>
                                </v-layout>
                            </v-toolbar>
                        </template>

                        <!-- BATTERY -->
                        <template v-slot:item.battery="{ item }">
                            <div>
                                <p v-if="item.battery >= 90">
                                    <v-icon style="color: #6AAE5E;">mdi-battery</v-icon>
                                    <br>{{ item.battery }}%
                                </p>
                                <p v-if="item.battery < 90 && item.battery >= 80">
                                    <v-icon style="color: #6AAE5E;">mdi-battery-80</v-icon>
                                    <br>{{ item.battery }}%
                                </p>
                                <p v-if="item.battery < 80 && item.battery >= 70">
                                    <v-icon style="color: #6AAE5E;">mdi-battery-70</v-icon>
                                    <br>{{ item.battery }}%
                                </p>
                                <p v-if="item.battery < 70 && item.battery >= 60">
                                    <v-icon style="color: #6AAE5E;">mdi-battery-60</v-icon>
                                    <br>{{ item.battery }}%
                                </p>
                                <p v-if="item.battery < 60 && item.battery >= 50">
                                    <v-icon style="color: #F3B03D;">mdi-battery-50</v-icon>
                                    <br>{{ item.battery }}%
                                </p>
                                <p v-if="item.battery < 50 && item.battery >= 40">
                                    <v-icon style="color: #F3B03D;">mdi-battery-40</v-icon>
                                    <br>{{ item.battery }}%
                                </p>
                                <p v-if="item.battery < 40 && item.battery >= 30">
                                    <v-icon style="color: #F3B03D;">mdi-battery-30</v-icon>
                                    <br>{{ item.battery }}%
                                </p>
                                <p v-if="item.battery < 30 && item.battery >= 20">
                                    <v-icon style="color: #D91419;">mdi-battery-20</v-icon>
                                    <br>{{ item.battery }}%
                                </p>
                                <p v-if="item.battery < 20 && item.battery >= 10">
                                    <v-icon style="color: #D91419;">mdi-battery-10</v-icon>
                                    <br>{{ item.battery }}%
                                </p>
                                <p v-if="item.battery < 10">
                                    <v-icon style="color: #D91419;">mdi-battery-alert-variant-outline</v-icon>
                                    <br>{{ item.battery }}%
                                </p>
                            </div>
                        </template>

                        <!-- LAST SEEN -->
                        <template v-slot:item.date_last_seen="{ item }">
                            <template v-if="item.date_last_seen !==null">
                                <v-chip small :color="lastEmissionAttributeTranslation(item.date_last_seen).color">
                                    <v-icon color="blue-grey darken-3" class="mr-1">
                                        mdi-router-wireless
                                    </v-icon>
                                    {{ lastEmissionAttributeTranslation(item.date_last_seen).time }}
                                </v-chip>
                            </template>
                        </template>

                        <!-- STATE -->
                        <template v-slot:item.state="{ item }">
                            <div v-if="item.state == -3">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" style="color: #D91419;">mdi-trash-can-outline</v-icon>
                                    </template>
                                    <span>Au rebus</span>
                                </v-tooltip>
                            </div>
                            <div v-if="item.state == -2">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" style="color: #D91419;">mdi-help-rhombus-outline</v-icon>
                                    </template>
                                    <span>Perdu</span>
                                </v-tooltip>
                            </div>
                            <div v-if="item.state == -1">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" style="color: #F3B03D;">mdi-progress-wrench</v-icon>
                                    </template>
                                    <span>En maintenance</span>
                                </v-tooltip>
                            </div>
                            <div v-if="item.state == 1">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" style="color: #6AAE5E;">mdi-archive-outline</v-icon>
                                    </template>
                                    <span>En stock</span>
                                </v-tooltip>
                            </div>
                            <div v-if="item.state == 2">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" style="color: #ffeb3b;">mdi-account-hard-hat</v-icon>
                                    </template>
                                    <span>Chez l'installateur</span>
                                </v-tooltip>
                            </div>
                            <div v-if="item.state == 3">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" style="color: #6AAE5E;">mdi-check-circle-outline</v-icon>
                                    </template>
                                    <span>En fonctionnement</span>
                                </v-tooltip>
                            </div>
                            <div v-if="item.state == 4">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" style="color: #2196f3;">mdi-cog-clockwise</v-icon>
                                    </template>
                                    <span>A tester</span>
                                </v-tooltip>
                            </div>
                        </template>

                        <!-- ACTIONS -->
                        <template v-slot:item.action="{ item }">
                            <v-col>
                                <v-row>
                                    <template>
                                        <v-tooltip top color="primary">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.id, -3, true)">
                                                    <v-icon v-bind="attrs" v-on="on">mdi-delete-empty</v-icon>
                                                </v-btn>
                                            </template>

                                            <span>{{ $t('physical_devices_datatable.device_scrapping_notice') }}</span>
                                        </v-tooltip>
                                    </template>

                                    <template>
                                        <v-tooltip top color="primary">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.id, 1, true)">
                                                    <v-icon v-bind="attrs" v-on="on">mdi-database</v-icon>
                                                </v-btn>
                                            </template>

                                            <span>{{ $t('physical_devices_datatable.device_storing_notice') }}</span>
                                        </v-tooltip>
                                    </template>

                                    <template>
                                        <v-tooltip top color="primary">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.id, 3, true)">
                                                    <v-icon v-bind="attrs" v-on="on">mdi-video-input-antenna</v-icon>
                                                </v-btn>
                                            </template>

                                            <span>{{ $t('physical_devices_datatable.device_on_operation_state') }}</span>
                                        </v-tooltip>
                                    </template>
                                </v-row>

                                <v-row>
                                    <template>
                                        <v-tooltip top color="primary">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.id, -1, true)">
                                                    <v-icon v-bind="attrs" v-on="on">mdi-cog-refresh</v-icon>
                                                </v-btn>
                                            </template>

                                            <span>{{ $t('physical_devices_datatable.device_in_maintenance_state') }}</span>
                                        </v-tooltip>
                                    </template>

                                    <template>
                                        <v-tooltip top color="primary">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.id, -2, true)">
                                                    <v-icon v-bind="attrs" v-on="on">mdi-progress-close</v-icon>
                                                </v-btn>
                                            </template>

                                            <span>{{ $t('physical_devices_datatable.device_lost_state') }}</span>
                                        </v-tooltip>
                                    </template>

                                    <template>
                                        <v-tooltip top color="primary">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.id, 4, true)">
                                                    <v-icon v-bind="attrs" v-on="on">mdi-clipboard-search</v-icon>
                                                </v-btn>
                                            </template>

                                            <span>{{ $t('physical_devices_datatable.device_testing_notice') }}</span>
                                        </v-tooltip>
                                    </template>
                                </v-row>
                            </v-col>       
                        </template>
                    </v-data-table>
                </div>
                
            </v-col>

            <gateway-location ref="location"/>
            <device-update-confirmation ref="confirm" @Performed-action="refreshPhysicalDeviceDataTable"/>
            <global-snackbar/>
        </div>
    </v-col>
</template>

<script>
import {DOWNLOAD_CSV_PHYSICAL_DEVICES, PHYSICAL_DEVICES, GATEWAYS, DOWNLOAD_CSV_GATEWAY} from "../../api";
import GlobalSnackbar from "../../global/components/GlobalSnackbar";
import DeviceUpdateConfirmation from "../components/Installer/DeviceUpdateConfirmation";
import GatewayLocation from "../components/Shop/Gateways/GatewayLocation";
import moment from "moment";

// link to device from chirpstack https://chirpstack.techview.app/#/organizations/1/applications/2/devices/0018b21000009EAF

export default {
    name: "DevicesDatatable",
    components: {DeviceUpdateConfirmation, GlobalSnackbar, GatewayLocation},
    data() {
        return {
            loading: false,
            devices: [],
            stockHeaders: [
                { text: "ID", value: "id" },
                { text: "UID", value: "uid" },
                { text: "Batterie", value: "battery" },
                { text: "Date Dernière Vue", value: "date_last_seen" },
                { text: "État", value: "state" },
                { text: "Actions", value: "action" },
            ],
            offlineHeaders: [
                { text: "Dernière remontée", value: "date_last_seen", width: '10%' },
                { text: "Professionnel", value: "professionals_label", width: '13%'},
                { text: "Regroupement", value: "building_label", width: '13%' },
                { text: "Equipement", value: "machine_label", width: '13%' },
                { text: "Sous-ensemble", value: "subassembly_label", width: '10%' },
                { text: "Point de mesure", value: "virtual_device_label", width: '10%', sortable: false },
                { text: "Référence", value: "uid", width: '7%' },
                { text: "Batterie", value: "battery", width: '6%', sortable: false},
                { text: "Etat", value: "state", width: '5%', sortable: false },
                { text: "Actions", value: 'action', width: '15%'}
            ],
            lowBatteryHeaders: [
                { text: "Batterie", value: "battery", width: '6%', sortable: false},
                { text: "Professionnel", value: "professionals_label", width: '13%'},
                { text: "Regroupement", value: "building_label", width: '13%' },
                { text: "Equipement", value: "machine_label", width: '13%' },
                { text: "Sous-ensemble", value: "subassembly_label", width: '10%' },
                { text: "Point de mesure", value: "virtual_device_label", width: '10%', sortable: false },
                { text: "Référence", value: "uid", width: '7%' },
                { text: "Dernière remontée", value: "date_last_seen", width: '10%' },
                { text: "Etat", value: "state", width: '5%', sortable: false },
                { text: "Actions", value: 'action', width: '15%'}
            ],
            equipmentHeaders: [
                { text: "Regroupement", value: "regroupement", width: '15%' },
                { text: "Equipement", value: "equipement", width: '15%' },
                { text: "Sous-ensemble", value: "subassembly", width: '14%' },
                { text: "Point de mesure", value: "virtualDevice", width: '10%' },
                { text: "Référence", value: "id", width: '10%' },
                { text: "Batterie", value: "battery", width: '8%' },
                { text: "Dernière remontée", value: "dateLastSeen", width: '10%' },
                { text: "Etat", value: "state", width: '5%' },
                { text: "Actions", value: 'action', width: '12%'}
            ],
            filteredDevicesOffline: [],
            filteredDevicesLowBattery: [],
            filteredDevicesStock: [],

            lowBatteryDevices: [],
            inactiveDevices: [],
            devicesWithoutProfessional: [],
            active_special_vue: 0,
            vues:[
                {id:3, label: "Parc capteurs clients"},
                {id:0, label: "Capteurs hors ligne"},
                {id:1, label: "Capteurs batteries faibles"},
                {id:4, label: "Parc passerelles clients"},
                {id:2, label: "Passerelles hors ligne"},
                {id:5, label: "Stock capteurs"},
            ],

            // TABS
            TAB_sensors : true,
            TAB_gateways : true,

            // DEVICES
            physical_device_states: [
                {id: '-3', name: this.$t('gateways_datatable.gateway_scrapped_state')},         // Au rebus
                {id: '-2', name: this.$t('gateways_datatable.gateway_lost_state')},             // Perdu
                {id: '-1', name: this.$t('gateways_datatable.gateway_in_maintenance_state')},   // En maintenance
                {id: '1', name: this.$t('gateways_datatable.gateway_in_storage_state')},        // En stock
                {id: '2', name: this.$t('gateways_datatable.gateway_at_the_installer_state')},  // Chez l'installateur
                {id: '3', name: this.$t('gateways_datatable.gateway_on_operation_state')},      // En fonctionnement
                {id: '4', name: this.$t('gateways_datatable.gateway_to_test_state')},           // A tester
            ],
            filter_selected_physical_device_state: "",
            selected_physical_device_states: [],
            search: '',
            search2: '',
            loading_csv_file: false,
            physical_device_data_table_loading: false,
            physical_devices: [],
            professionals: [],
            contracts: [],
            professionals_available: false,
            loading_datatable: true,
            filter_mode:9,
            raw_machines: [],
            machines: [],
            raw_buildings: [],
            buildings: [],
            raw_sites: [],
            sites: [],
            sites_available: false,
            sites_count: 0,
            active_site: 0,
            
            groupedProfessionals: [],
            

            // resume
            totalDevices: 0,
            totalDevicesONLINE: 0,
            totalDevicesOFFLINE: 0,
            totalProfessionals: 0,
            totalProfessionalsSensorsOFFLINE: 0,
            totalDevicesActivated: 0,
            functionnalDevicesRatio: 0,
            ClientsImpactRatio: 0,

            // resume by rofessional
            lessThan12Hours: 0,
            between12And3Days: 0,
            moreThan3Days: 0,
            functionalRatio: 0,
            RatioDevicesProfessional: 0,
            deviceCount: 0,
            batteryLessThan20: 0,
            batteryBetween20And60: 0,
            batteryMoreThan60: 0,
            

            //////////////////////
            // GATEWAYS //////////
            filteredGatewaysOffline: [],
            search: '',
            loading_csv_file: false,
            gateway_data_table_loading: true,
            selected_gateway_states: [],
            filter_selected_state: "",
            gateways: [],
            gateway_states: [
                {id: '-3', name: this.$t('gateways_datatable.gateway_scrapped_state')},         // Au rebus
                {id: '-2', name: this.$t('gateways_datatable.gateway_lost_state')},             // Perdu
                {id: '-1', name: this.$t('gateways_datatable.gateway_in_maintenance_state')},   // En maintenance
                {id: '1', name: this.$t('gateways_datatable.gateway_in_storage_state')},        // En stock
                {id: '2', name: this.$t('gateways_datatable.gateway_at_the_installer_state')},  // Chez l'installateur
                {id: '3', name: this.$t('gateways_datatable.gateway_on_operation_state')},      // En fonctionnement
                {id: '4', name: this.$t('gateways_datatable.gateway_to_test_state')},           // A tester
                
            ],
            headersGateways: [
                {text: "Client", value: 'user'},    
                {text: this.$t('gateways_datatable.hardware_mac'), value: 'hardware_mac'},
                {text: this.$t('gateways_datatable.location'), value: 'address'},
                {text: this.$t('gateways_datatable.last_emission'), value: 'date_last_seen'},
                {text: this.$t('gateways_datatable.state'), value: 'state'},
                {text: this.$t('global.actions'), value: 'action'}
            ],

            totalGateways: 0,
            totalGatewaysONLINE: 0,
            totalGatewaysOFFLINE: 0,
            totalProfessionalsGatewaysOFFLINE: 0,
            functionnalGatewaysRatio: 0,
            totalStockGateways: 0,
            totalGatewaysActivated: 0,
            totalProfessionalsGateways: 0,
        }
    },
    computed: {
        groupedDevicesProfessionalComputed() {
            const grouped = {};

            this.physical_devices.forEach((device) => {
                const professional = device.professionals_label;
                const professionalLogo = device.professionals_logo;
                const dateLastSeen = new Date(device.date_last_seen);
                const batteryLevel = device.battery;

                const now = new Date();
                const diffInSeconds = Math.floor(
                    (now.getTime() - dateLastSeen.getTime()) / 1000
                );

                const countBatteryLevel = (group) => {
                    if (batteryLevel < 20) {
                        group.batteryLessThan20 += 1;
                    } else if (batteryLevel >= 20 && batteryLevel <= 60) {
                        group.batteryBetween20And60 += 1;
                    } else if (batteryLevel > 60) {
                        group.batteryMoreThan60 += 1;
                    }
                };

                if (!professional) {
                    return;
                }

                // Regrouper par professionnel
                if (!grouped[professional]) {
                    grouped[professional] = {
                        professional,
                        professionals_logo: professionalLogo,
                        devices: [],
                        oldestDateLastSeen: dateLastSeen,
                        lastSeenCategory: 0,
                        deviceCount: 0,
                        lessThan12Hours: 0,
                        between12And3Days: 0,
                        moreThan3Days: 0,
                        functionalRatio: 0,
                        RatioDevicesProfessional: 0,
                        batteryLessThan20: 0,
                        batteryBetween20And60: 0,
                        batteryMoreThan60: 0,
                    };
                }

                // Ajouter l'équipement à la liste
                grouped[professional].devices.push({
                    label: device.physical_device_name,
                    id: device.uid,
                    type: device.device_type_label,
                    subassembly: device.subassembly_label,
                    virtualDevice: device.virtual_device_label,
                    virtualDeviceId: device.virtual_device_id,
                    dateLastSeen: device.date_last_seen,
                    battery: device.battery,
                    park: device.sites_label,
                    regroupement: device.building_label,
                    equipement: device.machine_label,
                    function: device.machine_function,
                    state: device.state,
                    link: "https://chirpstack.techview.app/#/organizations/1/applications/2/devices/" + device.uid,
                    vdUid: device.virtual_device_uid,
                    machineUid: device.machine_uid,
                    sseUid: device.subassembly_uid,
                });

                // Mettre à jour les catégories de temps pour ce professionnel
                if (diffInSeconds < 43200) {
                    grouped[professional].lessThan12Hours++;
                } else if (diffInSeconds >= 43200 && diffInSeconds < 259200) {
                    grouped[professional].between12And3Days++;
                } else if (diffInSeconds >= 259200) {
                    grouped[professional].moreThan3Days++;
                }

                countBatteryLevel(grouped[professional]);

                // Mettre à jour la plus ancienne date si nécessaire
                if (dateLastSeen < grouped[professional].oldestDateLastSeen) {
                    grouped[professional].oldestDateLastSeen = dateLastSeen;
                }

                grouped[professional].deviceCount += 1;
            });

            // Convertir l'objet en tableau et trier les professionnels
            const result = Object.values(grouped);

            for (const professional of result) {
                professional.oldestDateLastSeen = moment(professional.oldestDateLastSeen).fromNow();
            }

            // Calculer les ratios pour chaque professionnel
            result.forEach((group) => {
                group.functionalRatio = Math.round(
                    (group.lessThan12Hours / group.deviceCount) * 100
                );
                group.RatioDevicesProfessional = moment(group.RatioDevicesProfessional).fromNow();
            });

            return result;
        },
    },

    watch: {
        // DEVICES
        selected_physical_device_states() {
            this.physical_device_data_table_loading = true;
            this.filter_selected_physical_device_state = '';
            if (this.selected_physical_device_states.length !== 0) {
                this.selected_physical_device_states.forEach((selected_physical_device_state) => {
                    this.filter_selected_physical_device_state += (this.filter_selected_physical_device_state === '' ? selected_physical_device_state : ',' + selected_physical_device_state);
                });
            }
            this.getPhysicalDevices();
        },

        // GATEWAYS
        selected_gateway_states() {
            this.gateway_data_table_loading = true;
            this.filter_selected_state = '';
            if (this.selected_gateway_states.length !== 0) {
                this.selected_gateway_states.forEach((selected_gateway_state) => {
                    this.filter_selected_state += (this.filter_selected_state === '' ? selected_gateway_state : ',' + selected_gateway_state);
                });
            }
            this.getGateways();
        }
    },
    created() {
        this.getPhysicalDevices();
        this.getGateways();
        moment.locale('fr');
    },
    methods: {
        ///////////////////////
        // DEVICES
        ///////////////////////
        getPhysicalDevices() {
            this.physical_device_data_table_loading = true;
            this.axios.get( PHYSICAL_DEVICES + '?selected_physical_device_state=' + this.filter_selected_physical_device_state
            
            ).then((success) => {
                this.physical_devices = success.data;

                this.filterDevices();
            
            }).catch((error) => {
                console.error(this.$t('global.error'));
            }).finally(() => {
                this.calculateTotalDevices();
                this.calculateTotalProfessionals();
                this.physical_device_data_table_loading = false;
            });
        },

        formatDate(date) {
            return moment(date).format("DD/MM/YYYY HH:mm:ss");
        },
        async refreshPhysicalDeviceDataTable() {
            this.showGlobalSnackbar('success', this.$t('global.success_to_edit'));
            await this.getPhysicalDevices();
        },
        downloadCsvPhysicalDevice() {
            this.loading_csv_file = true;
            this.axios({
                url: DOWNLOAD_CSV_PHYSICAL_DEVICES + '?selected_physical_device_state=' + this.filter_selected_physical_device_state,
                method: 'GET',
                responseType: 'blob',
            }).then((success) => {
                this.downloadCsvFile(success.data, "_physical_devices.csv");
            }).catch((error) => {
                this.manageError(error.response.status,this.$t('global.failure_to_download'));
            }).finally(() => {
                this.loading_csv_file = false;
            });
        },
        groupedDevices() {
            const grouped = {};

            this.physical_devices.forEach((device) => {
                const professional = device.professionals_label;
                const professionalLogo = device.professionals_logo;
                const dateLastSeen = new Date(device.date_last_seen);

                // Regrouper uniquement par professionnel
                if (!grouped[professional]) {
                    grouped[professional] = {
                        professional,
                        professionals_logo: professionalLogo,
                        devices: [],
                        oldestDateLastSeen: dateLastSeen,
                    };
                } else {
                    if (dateLastSeen < grouped[professional].oldestDateLastSeen) {
                        grouped[professional].oldestDateLastSeen = dateLastSeen;
                    }
                }

                // Ajouter les équipements au professionnel
                grouped[professional].devices.push({
                    label: device.physical_device_name,
                    id: device.physical_device_uid,
                    type: device.physical_device_type,
                    subassembly: device.subassembly_label,
                    virtualDevice: device.virtual_device_label,
                    dateLastSeen: moment(device.date_last_seen).fromNow(),
                    battery: device.battery,
                    
                });
            });

            // Convertir les dates en format lisible
            for (const professionalKey in grouped) {
                const professional = grouped[professionalKey];
                professional.oldestDateLastSeen = moment(professional.oldestDateLastSeen).fromNow();
            }

            return Object.values(grouped);
        },

        filterDevices() {
            const nowTimestamp = Math.floor(Date.now() / 1000);

            // Capteurs hors ligne 
            this.filteredDevicesOffline = this.physical_devices.filter(device => {
                const deviceTimestamp = Math.floor(new Date(device.date_last_seen).getTime() / 1000);
                const diff = nowTimestamp - deviceTimestamp;
                return diff > 259200 && device.professionals_label;
            });

            //Capteurs avec batterie < 20%
            this.filteredDevicesLowBattery = this.physical_devices.filter(device => 
                device.battery < 20 && device.professionals_label
            );

            // Capteurs sans professionnel
            this.filteredDevicesStock = this.physical_devices.filter(device => 
                !device.professionals_label
            );
        },
        loadVue(id) {
            this.active_special_vue = id;
        },

        ///////////////////////
        // GATEWAYS
        ///////////////////////
        getGateways() {
            this.axios.get(
                GATEWAYS + '?selected_gateway_states=' + this.filter_selected_state
            ).then((success) => {
                this.gateways = success.data;
                this.filterOfflineGateways();
            }).catch((error) => {
                this.manageError(error.response.status,this.$t('global.error'));
            }).finally(() => {
                this.calculateTotalGateways();
                this.gateway_data_table_loading = false;
            });
        },
        async refreshGatewayDataTable() {
            this.showGlobalSnackbar('success', this.$t('global.success_to_edit'));
            await this.getGateways();
        },
        downloadCsvGateway() {
            this.loading_csv_file = true;
            this.axios({
                url: DOWNLOAD_CSV_GATEWAY + '?selected_gateway_states=' + this.filter_selected_state,
                method: 'GET',
                responseType: 'blob',
            }).then((success) => {
                this.downloadCsvFile(success.data, "_gateways.csv");
            }).catch((error) => {
                this.manageError(error.response.status,this.$t('global.failure_to_download'));
            }).finally(() => {
                this.loading_csv_file = false;
            });
        },
        calculateTotalGateways() {
            this.totalGateways = 0;
            this.totalGatewaysONLINE = 0;
            this.totalGatewaysOFFLINE = 0;
            this.functionnalGatewaysRatio = 0;
            
            this.gateways.forEach((gateway) => {
                this.totalGateways = this.totalGateways + 1 ;
                
                if (gateway.state == 3) {
                    this.totalGatewaysActivated = this.totalGatewaysActivated + 1 ;
                    
                    const now = new Date();
                    let diff = Math.floor(((now.getTime() + (now.getTimezoneOffset() * 60 * 1000)) - new Date(gateway.date_last_seen).getTime()) / 1000);
                    if (diff > 43200){
                        this.totalGatewaysOFFLINE = this.totalGatewaysOFFLINE + 1;
                    }else{
                        this.totalGatewaysONLINE = this.totalGatewaysONLINE + 1;
                    }
                }
            });

            this.functionnalGatewaysRatio = Math.round((this.totalGatewaysONLINE / this.totalGatewaysActivated)*100)
        },


        filterOfflineGateways() {
            const nowTimestamp = Math.floor(Date.now() / 1000);
            
            this.filteredGatewaysOffline = this.gateways.filter(gateway => {
                const gatewayTimestamp = Math.floor(new Date(gateway.date_last_seen).getTime() / 1000);
                const diff = nowTimestamp - gatewayTimestamp;
                return diff > 259200;
            });
        },

        ///////////////////////
        // Data management
        ///////////////////////
        calculateTotalProfessionals() {
            const professionalsSet = [];
            const professionalsOFFLINESet = [];
            this.totalProfessionals = 0;
            this.totalProfessionalsSensorsOFFLINE = 0;
            this.ClientsImpactRatio = 0;

            this.physical_devices.forEach((device) => {
                if (device.professionals_label && device.professionals_label !== "" && !professionalsSet.includes(device.professionals_label)) {
                    professionalsSet.push(device.professionals_label);
                }

                const now = new Date();
                let diff = Math.floor(((now.getTime() + (now.getTimezoneOffset() * 60 * 1000)) - new Date(device.date_last_seen).getTime()) / 1000);
                if (!professionalsOFFLINESet.includes(device.professionals_label) && diff > 43200){
                    professionalsOFFLINESet.push(device.professionals_label);
                }
            });

            this.totalProfessionals = professionalsSet.length;
            this.totalProfessionalsSensorsOFFLINE = professionalsOFFLINESet.length;

            this.ClientsImpactRatio = Math.round((this.totalProfessionalsSensorsOFFLINE / this.totalProfessionals)*100)

        },
        calculateTotalDevices() {
            this.totalDevices = 0;
            this.totalDevicesActivated = 0;
            this.totalDevicesONLINE = 0;
            this.totalDevicesOFFLINE = 0;
            this.functionnalDevicesRatio = 0;
            
            this.physical_devices.forEach((device) => {
                this.totalDevices = this.totalDevices + 1 ;
                
                if (device.state == 3) {
                    this.totalDevicesActivated = this.totalDevicesActivated + 1 ;
                    
                    const now = new Date();
                    let diff = Math.floor(((now.getTime() + (now.getTimezoneOffset() * 60 * 1000)) - new Date(device.date_last_seen).getTime()) / 1000);
                    if (diff > 43200){
                        this.totalDevicesOFFLINE = this.totalDevicesOFFLINE + 1;
                    }else{
                        this.totalDevicesONLINE = this.totalDevicesONLINE + 1;
                    }
                }
            });

            this.functionnalDevicesRatio = Math.round((this.totalDevicesONLINE / this.totalDevicesActivated)*100)
        },
        calculateOfflineSensorsPerProfessional() {
            this.totalDevicesByProfessional = 0;
            this.totalDevicesByProfessionalMore12 = 0;
            this.totalDeviceByProfessionalMore1D = 0;
            this.totalDeviceByProfessionalLess12 = 0;
            this.functionnalDevicesByProfessionalRatio = 0;

            this.physical_devices.forEach((professional) => {
                
                
                if (device.state != "") {
                    this.totalDevicesByProfessional = this.totalDevicesByProfessional + 1 ;
                    
                    const now = new Date();
                    let diff = Math.floor(((now.getTime() + (now.getTimezoneOffset() * 60 * 1000)) - new Date(device.dateLastSeen).getTime()) / 1000);
                    if (diff >= 43200 && diff < 259200){
                        this.totalDevicesByProfessionalMore12 = this.totalDevicesByProfessionalMore12 + 1;
                    }else if (diff > 259200) {
                        this.totalDeviceByProfessionalMore1D = this.totalDeviceByProfessionalMore1D + 1;
                    } else {
                        this.totalDeviceByProfessionalLess12 = this.totalDeviceByProfessionalLess12 + 1;
                    }
                }
            });

            this.functionnalDevicesByProfessionalRatio = Math.round((this.totalDeviceByProfessionalLess12 / this.totalDevicesActivated)*100)
        },



        lastEmissionAttributeTranslation(last_seen_date) {
            if (last_seen_date !== null) {
                const now = new Date();
                let diff = Math.floor(((now.getTime() + (now.getTimezoneOffset() * 60 * 1000)) - new Date(last_seen_date).getTime()) / 1000);
                let output = {
                    color: '',
                    time: '',
                }
                // On 12 hours timelaps
                if (diff <= 43200 && diff >= 0) {
                    output.color = 'green lighten-1';
                    if (diff <= 60) {
                        output.time = this.$t('global.elapsed_time') + diff + ' ' + (diff === 1 ? this.$t('global.second') : this.$t('global.seconds'));
                    }
                    // if the number of seconds is lesser than 1 hour, the output is per minutes
                    else if (diff <= 3600 && diff > 60) {
                        output.time = this.$t('global.elapsed_time') + Math.round((diff / 60)) + ' ' + (Math.round((diff / 60)) === 1 ? this.$t('global.minute') : this.$t('global.minutes'));
                    }
                    // if the number od seconds is more than hour ,the output is per hours
                    else if (diff > 3600) {
                        output.time = this.$t('global.elapsed_time') + Math.round((diff / 3600)) + ' ' + (Math.round((diff / 36000)) === 1 ? this.$t('global.hour') : this.$t('global.hours'));
                    }
                
                // Between 12 hours and 3 days
                } else if (diff > 43200 && diff <= 259200) { 
                    output.color = 'orange';
                    output.time = this.$t('global.elapsed_time') + Math.round((diff / 86400)) + ' ' + (Math.round((diff / 86400)) === 1 ? this.$t('global.day') : this.$t('global.days'));
                
                // More than 3 days
                } else if (diff > 259200) {
                    output.color = 'red lighten-1';
                    if (diff <= 2678400) {
                        output.time = this.$t('global.elapsed_time') + Math.round((diff / 86400)) + ' ' + (Math.round((diff / 86400)) === 1 ? this.$t('global.day') : this.$t('global.days'));
                    } else if (diff <= 32140800) {
                        output.time = this.$t('global.elapsed_time') + Math.round((diff / 2678400)) + ' ' + this.$t('global.months');
                    } else {
                        output.time = this.$t('global.elapsed_time') + "+12" + this.$t('global.months');
                    }
                }

                return output;
            }
        },
    },
}
</script>

<style>
.v-expansion-panel-content__wrap {
    padding: 0!important;
}

.address-button {
    text-decoration: underline;
    cursor: pointer;
    transition: color 0.3s;
}

.address-button:hover {
    color: #004181;
}

.active-tab {
    color: black;
    font-weight: bold;
    display: flex;
    justify-content: center;
    background-color: white;
    border-radius: 5px 5px 0 0;
    border-left: 1px solid rgba(211, 211, 211, 0.43);
    border-bottom: none;
    font-size: 14px;
    height: 40px;
    align-items: center;
    padding: 0 10px;
}

.inactive-tab {
    color: black;
    display: flex;
    justify-content: center;
    background-color: rgb(243 243 243 / 90%);
    border-radius: 10px 10px 0 0;
    border: 1px solid #dbdce1;
    border-bottom: none;
    font-size: 14px;
    height: 40px;
    cursor: pointer;
    padding: 0 10px;
}
</style>