<template>
    <v-card class="rounded-lg" elevation="2" outlined>
        <!-- ALL DATATABLE -->
        <v-data-table
            :headers="headers"
            :items="professionals"
            class="elevation-1"
            :item-per-page=-1
            :search="search"
            sort-by="created_at"
            :sort-desc=true
        >
            <template v-slot:top>
                <v-toolbar flat color="white" class="pt-3 mb-3">
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                :label="$t('global.search')"
                                class="mt-3"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-snackbar
                        v-model="snackbar"
                        :color="snackbar_type">
                        {{ snackbar_text }}
                        <template v-slot:action="{ attrs }">
                            <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                        </template>
                    </v-snackbar>
                    
                    <!-- VIEW NOTIF -->
                    <v-dialog v-model="dialog_show" max-width="500px" eager>
                        <v-card>
                            <v-card-title>
                                <h2>{{ $t('notification.modal_title') }}{{ professional.name }}</h2>
                            </v-card-title>
                            <v-card-text>

                                <h3>{{ $t('notification.message') }}</h3>
                                <p>{{ professional.mail }}</p>

                                <h3>{{ $t('notification.message') }}</h3>
                                <p>{{ professional.phone }}</p>

                                <h3>{{ $t('notification.message') }}</h3>
                                <p>{{ professional.address }}</p>

                                <v-row>
                                    <v-col>
                                        <h3>{{ $t('notification.created_at') }}</h3>
                                        <p>{{ notification.created_at }}</p>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn @click="dialog_show = false">
                                    <v-icon left>mdi-close</v-icon>
                                    {{ $t('global.close') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <v-dialog v-model="dialog_delete" max-width="500px">
                        <v-card>
                            <v-card-title>
                                <span class="headline">{{ $t('notification.del_duplicates') }}</span>
                            </v-card-title>
                            <v-divider/>
                            <br/>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn @click="dialog_delete = false">
                                    <v-icon left>mdi-close</v-icon>
                                    {{ $t('global.cancel') }}
                                </v-btn>
                                <v-btn color="error" @click="confirmDelete">
                                    <v-icon left>mdi-delete</v-icon>
                                    {{ $t('notification.del_duplicates') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>

            <!--FILTER MAX SIZE MESSAGE ITEM-->
            <template v-slot:item.message="{ item }">
                {{ item.message.substring(0, 100) + '...' }}
            </template>

            <!--ACTION BUTTONS -->
            <template v-slot:item.action="{ item }">
                <v-icon class="pointer" @click="" v-if="!notification_seen">
                    mdi-eye-check
                </v-icon>
                <v-icon class="pointer" @click="" v-if="notification_seen">
                    mdi-eye-remove
                </v-icon>
                <v-icon class="pointer" @click="openDialogShow(item)">
                    mdi-arrow-expand-all
                </v-icon>
            </template>

        </v-data-table>
    </v-card>
</template>

<script>
import {USERS} from "../../../api";
import moment from "moment";

export default {
    name: "DatatableProfessionals",
    data() {
        return {
            professionnals: [],
            headers: [
                {text: this.$t("notification.created_at"), value: 'id'},
                {text: this.$t("notification.subject"), value: 'uid'},
                {text: this.$t("notification.message"), value: 'name'},
                {text: this.$t("notification.level_name"), value: 'mail'},
                {text: this.$t("notification.tags"), value: 'phone'},
                {text: this.$t("notification.from"), value: 'address'},
                {text: this.$t("notification.from"), value: 'created_at'},
                {text: this.$t("notification.actions"), value: 'action'},
            ],
            loading_datatable: true,
            dialog_show: false,
            dialog_delete: false,
        }
    },
    created() {
        this.getProfessional();
    },
    computed: {
        jsonParseTags() {
            if (this.notification.tags !== "") {
                return JSON.parse(this.notification.tags);
            }
        },
    },
    methods: {
        
        getProfessional() {
            this.loading_datatable = true;
            this.axios.get(USERS + '/professionals')

            .then((success) => {
                success.data.forEach(professional => {
                    professional.created_at = moment(professional.created_at).format('DD/MM/YYYY HH:mm');
                })
                
                //----------------------------------------------------------------------------------------
                //DEBUG :
                //console.log("data returned "+ success.data);

                this.professionals = success.data;

                //----------------------------------------------------------------------------------------
                //DEBUG :
                //console.log("data parsed "+ this.notifications);
                //console.log("data collapsed "+ this.notifications[0].subject);
            
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            
            }).finally(() => {
                this.loading_datatable = false
            })
        },

        confirmDelete(uid) {
            this.axios.delete(USERS + '/professionals/delete?uid=' + uid)
            
            .then(() => {
                this.Dialog_delete = false;
                this.showSnackbar('success', this.$t('global.success_to_delete'));
            
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_delete'));
            
            }).finally(() => {
                this.getNotification()
            })
        },

    }
}
</script>
