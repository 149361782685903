<template>
    <v-row class="row mt-0">
        <!-- MACHINE MENU -->
        <v-col class="col-1 pt-5 pb-5 rounded" style="height: 110%;background-color: #014181;max-width: 70px;position: fixed;top: -20px;z-index: 1;">
            <!-- REPORTS -->
            <v-tooltip bottom dark class="mr-2">
                <template v-slot:activator="{ on }">
                    <v-btn class="ma-2 mt-10 mb-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/report'}" dusk="logout-button">
                        <v-icon color="white">mdi-chart-box</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('machine.reports') }}</span>
            </v-tooltip>
            <!-- ALERTS -->
            <v-tooltip bottom dark class="mr-2">
                <template v-slot:activator="{ on }">
                    <v-btn class="ma-2 mt-5 mb-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/events'}" dusk="logout-button">
                        <v-icon color="white">mdi-alert</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('machine.alerts') }}</span>
            </v-tooltip>
            <!-- SETTINGS -->
            <v-tooltip bottom dark class="mr-2">
                <template v-slot:activator="{ on }">
                    <v-btn class="ma-2 mt-5 mb-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/settings'}" dusk="logout-button">
                        <v-icon color="white">mdi-cog</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('machine.settings') }}</span>
            </v-tooltip>
            <!-- DETAILS -->
            <v-tooltip v-if="access_level <= 100 || access_level == 200 || access_level === 300" bottom dark class="mr-2">
                <template v-slot:activator="{ on }">
                    <v-btn class="ma-2 mt-5 mb-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/details'}" dusk="logout-button">
                        <v-icon color="white">mdi-chart-line</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('machine.courbes') }}</span>
            </v-tooltip>
        </v-col>          
        
        <!-- CONTENT -->
        <v-col class="col pa-0 pl-5 pr-5" style="margin-left: 70px;">
            <!-- TOP TITLE -->
            <v-row class="row mt-0">
                <!-- PAGE TITLE -->
                <v-subheader class="v-subheader col theme--light pl-5 ml-2">
                    <p class="title mb-0 color-primary text-uppercase">
                        évènements 
                    </p>
                    <!------------------- Info bulle ---------------------->
                    <v-tooltip bottom dark class="mr-2">
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                                <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:default>
                        <div style="min-width: 200px;position:relative;z-index: 10;">
                            <strong>Page [Evènements]</strong>
                            <p>Toutes les opérations de maintenance, alertes de surveillance et autres informations associées à ce moyen sont enregistrées ici.</br>
                                Tout évènement est important quant au suivi optimal de l'état de santé du moyen et son historique.</br></br>
                                Dans ce cas n'hésitez pas à nous faire part de ces évènements <strong> en créant de nouvelles opérations.</strong>
                            </p>
                        </div>
                        </template>
                    </v-tooltip>
                </v-subheader>

                <!-- FORMAT SELECTOR -->
                <v-row cols="2" class="v-subheader col-1 theme--light mr-3">
                    <!-- SPACER -->
                    <v-col class="col"></v-col>
                </v-row>
            </v-row>

            <!-- STATE DATAS -->
            <v-col class="row col-12 mb-0 pa-0 mt-8" style="align-items: flex-end;">
                <!-- COUNTER -->
                <v-col color="white" class="col-auto pa-0 mt-0" v-if="machine">
                    <v-col class="col pt-0 pb-0 mr-10 rounded col ml-6" style="height: 35px;background-color: rgb(224 232 240);font-weight: bold;text-align: center;font-size: 16px;border: 1px solid #014181;line-height: 35px;color: #014181;">
                        {{ machine.label }}
                    </v-col>
                </v-col>

                <!-- GLOBAL ACTIONS -->
                <v-col class="row col ml-6">
                    <v-tooltip bottom dark class="mr-2">
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" @click="getData()" class="mr-2" dusk="logout-button" style="border: 1px solid #01418145;">
                                <v-icon color="primary">mdi-tag-multiple</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('global.1_5_2') }}</span>
                    </v-tooltip>
                    <v-tooltip bottom dark class="mr-2">
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" @click="getData()" class="mr-2" dusk="logout-button" style="border: 1px solid #01418145;">
                                <v-icon color="primary">mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('machines.refresh') }}</span>
                    </v-tooltip>
                </v-col>

                <!-- SPACER -->
                <v-col class="row col"></v-col> 

            </v-col>

            <!-- MACHINE EVENTS CONTENT -->
            <v-col>
                <!-- OPERATIONS -->
                <datatable-operations-v-2 :entity-name="'machine'" class="mt-0"/>

                <!-- SNACKBAR -->
                <v-snackbar
                    v-model="snackbar"
                    :color="snackbar_type">
                    {{ snackbar_text }}
                    <template v-slot:action="{ attrs }">
                        <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                    </template>
                </v-snackbar>
            </v-col>
        </v-col>
    </v-row>
</template>

<script>
import {CAMPAIN,MACHINES,REPORT,USERS,SITES,DOCUMENTS} from "../../../api";
import DatatableOperationsV2 from '../../components/DatatableOperationsV2.vue';
import ModalEditOperation from "../../components/Subassembly/Operation/ModalEditOperation";
import moment from "moment";

export default {
    name: "EventsMachine",
    components: {DatatableOperationsV2,ModalEditOperation},
    data() {
        return {
            id: this.$route.params.id,
            access_level: this.$store.state.access_level,
            type: "machine",
            label: '',
            machineTitle:"",
            comparison_curves: [],
            three_dimensional_curves: [],
            machine:[],
        }
    },
    created() {
        moment.locale('fr');
        this.getMachine();
    },
    methods: {
        getMachine() {
            this.axios.get(MACHINES + '/specific/' + this.id)
            
            .then((success) => {
                this.machine = [];
                this.machine = success.data[0];

            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");
            })
        },

        checkDataAvailable(datas){
            if(typeof datas[0] === 'undefined') {                    
                return false;
            }else {
                return true;
            }
        },
    }
}
</script>

<style scoped lang="scss">
#fixed-col {
    min-width: 638px;
    max-width: 638px !important;
}
@media only screen and (max-width: 480px) {
    #fixed-col {
        min-width: 100%;
        max-width: 100% !important;
    }
}
</style>
