import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';
import fr from 'vuetify/es5/locale/fr';

Vue.use(Vuetify);

const opts = {
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        themes: {
            light: {
                primary: '#001047',
                secondary: '#f1f2f2',
                accent: '#0E4CEF',
                error: '#c1201f',
                info: '#32b3ee',
                success: '#169316',
                warning: '#c96d11',
                black: '#000000',
                red: '#d70000',
                orange:'#e3811b',
                grey:'#e7e7e7',
                darkgrey: '#cacaca',
                flashGreen:"#00db37"
            },
            dark: {
                primary: '#014181',
                secondary: '#f1f2f2',
                accent: '#0E4CEF',
                error: '#c1201f',
                info: '#32b3ee',
                success: '#169316',
                warning: '#c96d11',
                black: '#000000',
                red: '#d70000',
                orange:'#e3811b',
            },
        },
    },
    lang: {
        locales: {fr},
        current: 'fr'
    },
};

export default new Vuetify(opts);
