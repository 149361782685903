<template>
    <div id="word-curves">
        <v-card elevation="0" :loading="word_curve_chart_loading">
            <v-card-text>
                <v-row style="align-items: center;justify-content: space-between;">
                    <v-col class="col-4">
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="dateRangeText"
                                    :label="$t('three_dimensional_curve.selected_period')"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                />
                            </template>
                            <v-date-picker
                                v-model="range"
                                range
                                @change="refreshChartDisplay()"
                            />
                        </v-menu>
                    </v-col>

                    <div class="mr-3">
                        <v-btn class="ml-0" elevation="3" small color="orange" @click="displayWordCurveAppearanceModal()" v-if="!word_curve_chart_error_loading">
                            <v-icon color="white" small> mdi-wrench</v-icon>
                        </v-btn>
                        <v-btn class="ml-0" elevation="3" color="red darken-3" small @click="dialog_delete_word_curve = true">
                            <v-icon color="white" small>mdi-trash-can-outline</v-icon>
                        </v-btn>
                        <v-btn class="ml-0" elevation="3" small color="primary" @click="wordCurveCsvExport()" :loading="csv_export_loading">
                            <v-icon color="white" small>mdi-download</v-icon>
                        </v-btn>
                        <v-btn class="ml-0" elevation="3" small @click="toggleFullscreen()" v-if="!word_curve_chart_error_loading">
                            <v-icon color="black">mdi-fullscreen</v-icon>
                        </v-btn>
                    </div>
                </v-row>

                <figure id="chart" class="highcharts-figure">
                    <highcharts :constructorType="'ganttChart'" :options="chartOptions" ref="highcharts"/>
                </figure>
            </v-card-text>

            <v-dialog v-model="dialog_delete_word_curve" max-width="500px">
                <v-card>
                    <v-card-title>
                        <span class="headline">{{ $t('word_curve.delete') }}</span>
                    </v-card-title>
                    <v-divider/>

                    <v-card-text class="text-center pb-0">
                        <p class="mb-5 mt-3">{{ $t('word_curve.delete_message') }} <strong>{{ word_curve.label }} ?</strong></p>
                    </v-card-text>

                    <v-card-actions class="mb-1">
                        <v-spacer/>
                        <v-btn @click="dialog_delete_word_curve = false">
                            <v-icon left>mdi-close</v-icon>
                            {{ $t('global.close') }}
                        </v-btn>
                        <v-btn type="submit" color="error" @click="deleteWordCurve()">
                            <v-icon left>mdi-content-save</v-icon>
                            {{ $t('global.delete') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <word-curve-color-switch-modal ref="color" @curveConfigurationUpdated="refreshChartDisplay()"/>
            <global-snackbar/>
        </v-card>
    </div>
</template>

<script>
import moment from "moment";
import WordCurveColorSwitchModal from "./WordCurveAppearanceModal";
import Highcharts from 'highcharts'
import gantt from 'highcharts/modules/gantt';
import {api as fullscreen} from "vue-fullscreen";
import {DOWNLOAD_CSV_WORD_CURVE, WORD_CURVE} from "../../../../api";
import GlobalSnackbar from "../../../../global/components/GlobalSnackbar";
gantt(Highcharts);

export default {
    name: "WordCurves",
    components: {GlobalSnackbar, WordCurveColorSwitchModal},
    props: ['item'],
    data() {
        return {
            menu: false,
            word_curve_chart_loading: true,
            word_curve_chart_error_loading:false,
            dialog_delete_word_curve:false,
            word_curve: this.item,
            formatted_data_csv:[],
            range: [
                moment.utc().startOf('day').subtract(5,'d').local().format('YYYY-MM-DD'),
                moment.utc().endOf('day').subtract(1,'d').local().format('YYYY-MM-DD')
            ],
            curve_data:[],
            chartOptions:{
                title: {
                    text: ''
                },
                chart: {
                    height: '320px',
                },

                time: {
                    timezone: 'Europe/Paris',
                    useUTC: true,
                },
                legend: {
                    enabled: false
                },
                navigator: {
                    enabled: true,
                    adaptToUpdatedData: false,
                    series: {
                        type: 'gantt',
                        pointPlacement: 0.25,
                        pointPadding: 0.25,
                        data: []
                    },
                    yAxis: {
                        categories: ['']
                    }
                },
                credits: {
                    enabled: false
                },
                rangeSelector: {
                    enabled: false,
                },
                scrollbar: {
                    enabled: true,
                    liveRedraw: false,
                    height: 17,
                    barBackgroundColor: '#cfcfcf',
                    barBorderRadius: 9,
                    buttonBackgroundColor: '#c9c9c9',
                    buttonArrowColor: 'white',
                    buttonBorderWidth: 3,
                    buttonBorderRadius: 11,
                    rifleColor: 'white',
                    trackBorderRadius: 9
                },
                xAxis: [
                    {
                        events: {
                            setExtremes: (e) => {
                                this.zoomInOut(e);
                            }
                        },
                        opposite:false,
                        grid: {
                            enabled: false,
                        }
                    },
                    {
                        visible:false,
                        grid: {
                            enabled: false,
                        }
                    }
                ],
                yAxis: {
                    uniqueNames: true,
                    title: {
                        text: ''
                    },
                    categories: [''],
                    grid: {
                        enabled: false,
                    }
                },
                tooltip: {
                    pointFormat: '<span>Valeur: {point.name}</span><br/><span>De: {point.start:%A, %b %e,%yT%H:%M}</span><br/><span>A: {point.end:%A, %b %e,%yT%H:%M}</span>',
                    outside: true
                },
                series: [{
                    data: [],
                }]
            },
            timeout_id:null,
            number_of_point:200,
            csv_export_loading:false,
            navigator_series: [],
        }
    },
    computed: {
        dateRangeText() {
            const from = moment.utc(this.range[0]).local().format('DD-MM-YYYY');
            const to = moment.utc(this.range[1]).local().format('DD-MM-YYYY');
            return `Du ${from} au ${to}`;
        },
    },
    async mounted() {
        this.$refs.highcharts.chart.hideNoData();
        this.$refs.highcharts.chart.showLoading();
        this.metric_details = await this.getMetricDetails(this.word_curve.metric_uuid);
        this.wordCurveDataConstruction();
    },
    methods:{
        refreshChartDisplay() {
            this.word_curve_chart_loading = true;
            this.wordCurveDataConstruction();
        },

        async wordCurveDataConstruction() {
            this.$refs.highcharts.chart.showLoading();
            let from = moment.utc(this.range[0]).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
            let to = moment.utc(this.range[1]).endOf('day').format('YYYY-MM-DDTHH:mm:ss');
            if (moment(from).isAfter(to)) {
                [from, to] = [to, from];
            }
            let intervalDate = moment(from).valueOf() - moment(to).valueOf();
            if (intervalDate < 0) {
                intervalDate *= -1
            }

            let interval = this.calculInterval(intervalDate,this.number_of_point);
            let url = `/api/${this.word_curve.type_relatable}/${this.word_curve.id_relatable}/metric/${this.word_curve.metric_uuid}/timestamp?start=${moment(from).utc().format("YYYY-MM-DDTHH:mm:ss")}&end=${moment(to).utc().format("YYYY-MM-DDTHH:mm:ss")}&interval=${interval}`;
            await this.axios.get(url).then((success)=>{
                this.curve_data = this.fromUtcDateConversion(success.data.data_metric.data);
            }).catch(() => {
                this.$refs.highcharts.chart.hideLoading();
                this.$refs.highcharts.chart.hideNoData();
                this.$refs.highcharts.chart.showNoData(this.$t('three_dimensional_curve.data_loading_error'));
            });
            /*let uri = "subassembly/1/metric/772ac5fc-9f4f-11ec-adac-d6683707f645/timestamp?start=2022-05-14T20:00:00&end=2022-05-15T13:00:00Z";
            await this.axios({
                baseURL:'https://fae42947-c508-413a-af83-b4ecfcea41cd.mock.pstmn.io/api/',
                url:uri,
                method:'GET',
                responseType: 'json'
            }).then((success)=>{
                this.curve_data= success.data
            }).catch((error)=>{
                this.manageError(404, this.$t('three_dimensional_curve.data_loading_error'),this.$t('three_dimensional_curve.data_loading_error'));
            });*/
            if (this.curve_data.length >= 1) {
                this.checkChartFeasibility(true);
            } else {
                this.chartOptions.series[0].data=[];
                this.chartOptions.navigator.series.data=[];
                this.$refs.highcharts.chart.hideLoading();
                this.$refs.highcharts.chart.showNoData(this.$t('global.curve_no_data'));
                this.word_curve_chart_loading= false;
            }
        },

        checkChartFeasibility(upDateNavigatorData){
            if(this.curve_data.length < 3){
                this.word_curve_chart_loading= false;
                this.$refs.highcharts.chart.hideLoading();
                this.$refs.highcharts.chart.showNoData(this.$t('three_dimensional_curve.data_loading_error'));
            }
            else
            {
                this.wordCurveDataFormatter(upDateNavigatorData);
            }
        },

        wordCurveDataFormatter(upDateNavigatorData) {
            this.$refs.highcharts.chart.hideNoData();
            this.$refs.highcharts.chart.hideLoading();
            this.formatted_data_csv=[];
            let RawData= this.curve_data.map((sample)=>{
                return {
                    date: moment(moment.parseZone(moment.utc(sample.date,'YYYY-MM-DDTHH:mm:ss')).local().format('YYYY-MM-DDTHH:mm:ss')),
                    value: sample.value === "" ? "Null" : sample.value
                }
            });
            let data= [];
            let color= this.word_curve.configuration.color2;
            let i=0;
            let j=0;
            while(i < RawData.length) {
                let x2='';
                while (j < RawData.length) {
                  if ( RawData[j].value !== RawData[i].value ){
                      x2 = RawData[j].date;
                      data.push({
                          start: RawData[i].date.valueOf(),
                          end: x2.valueOf(),
                          y:0,
                          name: RawData[i].value.toString(),
                          color: color
                      });
                      this.formatted_data_csv.push({
                          period: RawData[i].date.toISOString()+'=>'+x2.toISOString(),
                          value: RawData[i].value
                      });
                      i=j;
                      color === this.word_curve.configuration.color1 ? color=this.word_curve.configuration.color2 : color=this.word_curve.configuration.color1;
                  }
                  j++;
                 if(j === RawData.length && i < j) {
                     x2 = RawData[j-1].date;
                     data.push({
                         start: RawData[i].date.valueOf(),
                         end: x2.valueOf(),
                         y:0,
                         name: RawData[i].value.toString(),
                         color: color
                     });
                     this.formatted_data_csv.push({
                         period: RawData[i].date.toISOString()+'=>'+x2.toISOString(),
                         value: RawData[i].value
                     });
                     i= RawData.length;
                 }
                }
            }
            if(upDateNavigatorData) {
                this.navigator_series = [{
                    data : data
                }]
            }
            this.chartOptions.navigator.series.data= this.navigator_series[0].data;
            this.chartOptions.series[0].data= data;
            this.chartOptions.title.text = this.word_curve.virtual_device.label + ' - ' + this.metric_details[0].metric_name;
            if(!this.$refs.highcharts.chart.hasData()) {
                this.$refs.highcharts.chart.hideNoData();
                this.$refs.highcharts.chart.showNoData(this.$t('global.curve_no_data'));
            }
            this.word_curve_chart_loading= false;
        },

        toggleFullscreen(){
            let wrapper = this.$el.querySelector('#chart');
            wrapper.classList.add('enabled');

            fullscreen.toggle(wrapper, {
                teleport: this.teleport,
                callback: (isFullscreen) => {
                    this.fullscreen = isFullscreen;
                    if (isFullscreen === false) {
                        this.$el.querySelector('#chart').classList.remove('enabled');
                    }
                },
            });
        },

        deleteWordCurve(){
            this.axios.delete(
                WORD_CURVE + '/' + this.word_curve.id
            ).then(() => {
                this.$emit('deleteWordCurve',this.word_curve.id);
            }).catch(() => {
                this.showGlobalSnackbar('error', this.$t('global.failure_to_delete'));
            }).finally(() => {
                this.dialog_delete_word_curve = false;
            });
        },

        displayWordCurveAppearanceModal(){
            this.$refs.color.word_curve_appearance_open_dialog= true;
            this.$refs.color.configuration= this.word_curve.configuration;
            this.$refs.color.word_curve_id= this.word_curve.id;
        },

        wordCurveCsvExport(){
            if(this.formatted_data_csv.length > 0)
            {
                this.csv_export_loading= true;
                this.axios({
                    url: DOWNLOAD_CSV_WORD_CURVE,
                    method: 'POST',
                    data: {
                        'data': this.formatted_data_csv,
                    },
                    responseType: 'blob',
                }).then((success) => {
                    this.downloadCsvFile(success.data, "_WordCurve : "+ this.word_curve.label+"_data.csv");
                }).catch(() => {
                    this.showGlobalSnackbar('error', this.$t('global.failure_to_download'));
                }).finally(() => {
                    this.csv_export_loading= false;
                });
            }
        },

        async zoomInOut(event) {
            this.word_curve_chart_loading = true;
            this.$refs.highcharts.chart.showLoading();
            // The min and max extreme in milliseconds
            const min = event.min;
            const max = event.max;

            // The interval in milliseconds
            const scrollbarInterval = max - min;

            let interval = this.calculInterval(scrollbarInterval,this.number_of_point);

            let from = moment(min).utc().format('YYYY-MM-DDTHH:mm:ss');
            let to = moment(max).utc().format('YYYY-MM-DDTHH:mm:ss');

            let url = `/api/${this.word_curve.type_relatable}/${this.word_curve.id_relatable}/metric/${this.word_curve.metric_uuid}/timestamp?start=${from}&end=${to}&interval=${interval}`;
            await this.axios.get(url).then((success)=>{
                this.curve_data= this.fromUtcDateConversion(success.data.data_metric.data);
            }).catch(()=> {
                this.$refs.highcharts.chart.hideLoading();
                this.$refs.highcharts.chart.showNoData(this.$t('three_dimensional_curve.data_loading_error'));
            }).finally(()=>{
                this.word_curve_chart_loading= false;
            });
            if (this.curve_data.length >= 1) {
                this.checkChartFeasibility(false);
            } else {
                this.$refs.highcharts.chart.hideLoading();
                this.$refs.highcharts.chart.showNoData(this.$t('three_dimensional_curve.data_loading_error'));
            }
        },

    }
}
</script>

<style scoped>
.highcharts-figure,
.highcharts-data-table table {
    max-width: 670px;
    margin: auto auto;
}

.highcharts-data-table table {
    font-family: Verdana, sans-serif;
    border-collapse: collapse;
    border: 1px solid #ebebeb;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    max-width: 500px;
}

.highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
}

.highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
    padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
}

.highcharts-data-table tr:hover {
    background: #f1f7ff;
}
</style>
