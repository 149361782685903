<template>
    <div id="associated-professional">
        <v-dialog v-model="dialog_detail_associated_professionals" max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="headline">{{ $t('account_management.associated_clients') }}</span>
                </v-card-title>

                <v-divider/>

                <v-card-text>
                    <template v-for="(associated_professional, index) in associated_professionals">
                        <p :key="associated_professional.id" class="pt-4 title">
                            {{ associated_professional.name }}
                        </p>
                        <hr v-if="associated_professionals.length !== index + 1"/>
                    </template>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: "AssociatedProfessional",
    props: {
        associated_professionals: {
            type: Array,
            require: true,
        }
    },
    data() {
        return {
            dialog_detail_associated_professionals: false,
        }
    },
}
</script>
