<template>
    <div id="banner-communication" class="w-100">
        <v-alert v-if="enable_banner" :color="banner_color" class="ma-0 rounded-0 d-flex" style="padding:15px 25px">
            <template v-slot:prepend>
                <v-icon :color="text_color">{{ 'mdi-' + icon }}</v-icon>
            </template>

            <v-row>
                <v-col class="ml-2 pt-4" style="font-size:13px;">
                    <span :style="'color: ' + text_color">{{ title }}</span>
                </v-col>
                <v-col class="d-flex justify-end pt-4" style="font-size: 12px;">
                    <span class="font-weight-bold text-decoration-underline pointer"
                          @click="dialog_display_information = true"
                          :style="'color: ' + text_color"
                    >
                        {{ $t('information_banner.more_informations') }}
                    </span>
                </v-col>
            </v-row>
        </v-alert>

        <v-dialog v-model="dialog_display_information" max-width="60%">
            <v-card>
                <v-card-title>
                    <span class="headline">{{ $t('information_banner.information') }}</span>
                </v-card-title>

                <v-divider/>

                <v-card-text class="mt-4">
                        <span class="black--text font-16 banner-description">
                            {{ description }}
                        </span>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {REFERENTIAL_GLOBALS} from "../../api";

export default {
    name: "BannerCommunication",
    data() {
        return {
            enable_banner: false,
            icon: '',
            text_color: '',
            banner_color: '',
            title: '',
            description: '',
            dialog_display_information: false
        }
    },
    watch: {
        '$store.state.refresh_information_banner': function() {
            this.getBannerInformation();
        }
    },
    created() {
        this.getBannerInformation();
    },
    methods: {
        getBannerInformation() {
            this.axios.get(
                REFERENTIAL_GLOBALS + '/banner_information'
            ).then((success) => {
                this.enable_banner = success.data.is_enable;
                if (this.enable_banner) {
                    let jsonConfiguration = JSON.parse(success.data.value);
                    this.icon = jsonConfiguration.icon;
                    this.text_color = jsonConfiguration.text_color;
                    this.banner_color = jsonConfiguration.banner_color;
                    this.title = jsonConfiguration.title;
                    this.description = jsonConfiguration.description;
                }
            }).catch(() => {
                this.showGlobalSnackbar('error', this.$t('global.error'));
            });
        }
    }
}
</script>

<style>
.v-alert__wrapper {
    width: 100% !important;
}

 .banner-description {
     white-space: pre-line;
 }
</style>
