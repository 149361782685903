var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg mt-6",attrs:{"elevation":"2","outlined":""}},[_c('v-card-title',{staticClass:"justify-center"},[_vm._v("\n        "+_vm._s(_vm.$t('operation.title'))+"\n    ")]),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.operations,"items-per-page":5,"search":_vm.search,"loading":_vm.loading_datatable},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-layout',{staticClass:"justify-start"},[_c('v-btn',{staticClass:"ml-2 mt-6",on:{"click":function($event){return _vm.filterOperations()}}},[_vm._v("\n                        "+_vm._s(_vm.$t('operation.operation_passed'))+"\n                        "),_c('v-switch',{staticClass:"mt-5 pr-2 pl-2",attrs:{"color":"primary","disabled":""},model:{value:(_vm.upcoming_operation),callback:function ($$v) {_vm.upcoming_operation=$$v},expression:"upcoming_operation"}}),_vm._v("\n                        "+_vm._s(_vm.$t('operation.operation_upcoming'))+"\n                    ")],1),_vm._v(" "),_c('v-select',{staticClass:"pr-2 pl-2 operation-type-select max-width-500",attrs:{"items":_vm.operation_types,"label":_vm.$t('operation.select_type'),"item-text":"value","item-value":"id","solo":"","chips":"","multiple":"","deletable-chips":"","dense":""},on:{"change":function($event){return _vm.getOperations()}},model:{value:(_vm.operation_type_selected),callback:function ($$v) {_vm.operation_type_selected=$$v},expression:"operation_type_selected"}})],1),_vm._v(" "),_c('v-layout',{staticClass:"justify-end mt-7 max-width-300"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('global.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:"item.building_name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ path: '/building/' + item.subassembly.machine.building.id }}},[_vm._v("\n                "+_vm._s(item.subassembly.machine.building.label)+"\n            ")])]}},{key:"item.machine_name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ path: '/machine/' + item.subassembly.machine.id }}},[_vm._v("\n                "+_vm._s(item.subassembly.machine.label)+"\n            ")])]}},{key:"item.subassembly_name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ path: '/subassembly/' + item.subassembly.id }}},[_vm._v("\n                "+_vm._s(item.subassembly.label)+"\n            ")])]}},{key:"item.alert_level",fn:function(ref){
var item = ref.item;
return [(item.alert_level !== '0')?_c('v-icon',{attrs:{"color":_vm.assetColor(item),"large":""}},[_vm._v("\n                mdi-alert\n            ")]):_vm._e()]}},{key:"item.user_responsible_id",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(item.intervener.first_name + " " + item.intervener.last_name)+"\n        ")]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm.getStateOperationLabel(item.state))+"\n        ")]}},{key:"item.tags",fn:function(ref){
var item = ref.item;
return [_c('v-chip-group',[(item.tags.length === 0)?_c('v-chip',{attrs:{"color":"grey"}},[_vm._v(_vm._s(_vm.$t('global.no_tags_associated')))]):_vm._e(),_vm._v(" "),_vm._l((item.tags),function(tag){return _c('v-chip',{key:tag.id,staticClass:"pl-3 pr-3",attrs:{"link":""}},[_vm._v(_vm._s(tag.label))])})],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }