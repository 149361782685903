<template>
    <v-col class="col mt-12" style="margin-top:0px!important;padding:0px;position:relative; top:-12px;">
        <!-- MACHINE MENU PACKAGE -->
        <div style="padding:0px 12px;">
            <v-col class="pl-5 pr-5" style="background-color:#001047;color:#FFFFFF;border-radius: 0px 0px 5px 5px;">
                <!-- TOP TITLE -->
                <v-row class="pl-0 pt-5 mb-5" style="height: 52px; padding:5px;">
                    <!-- MACHINE NAME -->
                    <v-col class="row mb-0 pa-0 mt-1 col-auto" @click="infoToggler()">
                        <!-- COUNTER -->
                        <v-col color="white" class="col-auto pa-0 mr-5" v-if="machine">
                            <v-col class="col pt-0 pb-0 mr-10 rounded col ml-6" style="height: 35px;background-color: rgb(92 101 123);font-weight: bold;text-align: center;font-size: 16px;border: 1px solid #ffffff5c;line-height: 35px;color: #FFFFFF;">
                                {{ machine.label }}
                            </v-col>
                        </v-col>
                    </v-col>
                    
                    <!-- PAGE TITLE -->
                    <v-subheader class="v-subheader col theme--light pl-3 ml-2">
                        <!------------------- Info bulle ---------------------->
                        <v-tooltip bottom dark class="mr-2 ml-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 mr-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                                    <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                                </v-btn>
                            </template>
                            <template v-slot:default>
                            <div style="min-width: 200px;position:relative;z-index: 10;">
                                <strong>Page [Journal des évènements]</strong>
                                <p>Toutes les opérations de maintenance, alertes de surveillance et autres informations associées à ce moyen sont enregistrées ici.</br>
                                    Tout évènement est important quant au suivi optimal de l'état de santé du moyen et son historique.</br></br>
                                    Dans ce cas n'hésitez pas à nous faire part de ces évènements <strong> en créant de nouvelles opérations.</strong>
                                </p>
                            </div>
                            </template>
                        </v-tooltip>
                        <p class="title mb-0 color-white" style="color: white!important;">
                            <div style="color:#FFFFFFb3;margin-right:4px;">Equipements > {{ machine.label }} > </div><div @click="infoToggler()" style="color:#FFFFFF;font-weight:bold;"> {{ $t('machines.alerts') }}</div>
                        </p>
                    </v-subheader>

                    <!-- GLOBAL ACTIONS -->
                    <v-col class="row mt-1 col-auto pa-0 pr-5">
                        <v-tooltip bottom dark class="mr-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="getData()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                    <v-icon color="white">mdi-tag-multiple</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('global.1_5_2') }}</span>
                        </v-tooltip>
                        <v-tooltip bottom dark class="mr-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="getData()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                    <v-icon color="white">mdi-refresh</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('machines.refresh') }}</span>
                        </v-tooltip>
                        
                        <v-tooltip v-if="infoToggle == true" bottom dark class="mr-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="infoToggler()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                    <v-icon color="white">mdi-minus-box-multiple</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('machines.minify') }}</span>
                        </v-tooltip>

                        <v-tooltip v-if="infoToggle == false" bottom dark class="mr-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="infoToggler()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                    <v-icon color="white">mdi-plus-box-multiple</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('machines.plus') }}</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
                <!-- MACHINE INFOS -->
                <v-col class="mb-4" v-if="infoToggle == true" style="min-height: 250px; background-color:#e8edff;border-radius:5px;">
                    <v-row>
                        <v-col class="col-4">
                            <machine-state/>
                        </v-col>
                        <v-col class="col pl-0">
                            <machine-specifications v-on:updateLabelMachine="updateLabelMachine"/>
                            <v-row>
                                <v-col class="col">
                                    <machine-subassemblies/>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
                <!-- MACHINE LINKS -->
                <v-row v-if="infoToggle == false" style="border-top: 1px solid #ffffff1c;">
                    <v-row style="margin:0px;">
                        <v-row class="ml-5 pt-5 pb-5 rounded" style="">
                            <!-- ALERTS -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/events'}" dusk="logout-button">
                                        <v-icon color="white">mdi-alert</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.alerts') }}</span>
                            </v-tooltip>

                            <!-- REPORTS -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/report'}" dusk="logout-button">
                                        <v-icon color="white">mdi-chart-box</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.reports') }}</span>
                            </v-tooltip>

                            <!-- DETAILS -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/details'}" dusk="logout-button">
                                        <v-icon color="white">mdi-chart-line</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.courbes') }}</span>
                            </v-tooltip>

                            <!-- SANDBOX -->
                            <v-tooltip bottom dark class="mr-2" v-if="$store.state.access_level <= 100">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" class="mr-5 col-0" :to="{ path: '/machine/' + id + '/sandbox'}" dusk="logout-button">
                                    <v-icon color="red">mdi-vector-curve</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('machines.sandbox') }}</span>
                        </v-tooltip>

                            <!-- Documents -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/documents'}" dusk="logout-button">
                                        <v-icon color="white">mdi-file-document</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machines.documents') }}</span>
                            </v-tooltip bottom dark class="mr-2">
                            
                            <!-- SETTINGS -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/settings'}" dusk="logout-button">
                                        <v-icon color="white">mdi-cog</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.settings') }}</span>
                            </v-tooltip bottom dark class="mr-2">
                        </v-row>     
                    </v-row>
                </v-row>

                <v-row v-if="infoToggle == true">
                    <v-row style="margin:0px;">
                        <v-row class="ml-5 pt-5 pb-5 rounded" style="">
                            <!-- ALERTS -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/events'}" dusk="logout-button">
                                        <v-icon color="white">mdi-alert</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.alerts') }}</span>
                            </v-tooltip>

                            <!-- REPORTS -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/report'}" dusk="logout-button">
                                        <v-icon color="white">mdi-chart-box</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.reports') }}</span>
                            </v-tooltip>

                            <!-- DETAILS -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/details'}" dusk="logout-button">
                                        <v-icon color="white">mdi-chart-line</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.courbes') }}</span>
                            </v-tooltip>

                            <!-- SANDBOX -->
                            <v-tooltip bottom dark class="mr-2" v-if="$store.state.access_level <= 100">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" class="mr-5 col-0" :to="{ path: '/machine/' + id + '/sandbox'}" dusk="logout-button">
                                    <v-icon color="red">mdi-vector-curve</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('machines.sandbox') }}</span>
                        </v-tooltip>

                            <!-- Documents -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/documents'}" dusk="logout-button">
                                        <v-icon color="white">mdi-file-document</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.documents') }}</span>
                            </v-tooltip bottom dark class="mr-2">
                            
                            <!-- SETTINGS -->
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/settings'}" dusk="logout-button">
                                        <v-icon color="white">mdi-cog</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.settings') }}</span>
                            </v-tooltip bottom dark class="mr-2">
                        </v-row>     
                    </v-row>
                </v-row>
            </v-col>
        </div>       

        <!-- CONTENT -->
        <v-col class="mt-0 col pa-0">
            <!-- MACHINE EVENTS CONTENT -->
            <v-col>
                <!-- OPERATIONS -->
                <datatable-operations-v-2 :entity-name="'machine'" class="mt-0"/>

                <!-- SNACKBAR -->
                <v-snackbar
                    v-model="snackbar"
                    :color="snackbar_type">
                    {{ snackbar_text }}
                    <template v-slot:action="{ attrs }">
                        <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                    </template>
                </v-snackbar>
            </v-col>
        </v-col>
    </v-col>
</template>

<script>
import {CAMPAIN,MACHINES,REPORT,USERS,SITES,DOCUMENTS} from "../../../api";
import DatatableOperationsV2 from '../../components/DatatableOperationsV2.vue';
import ModalEditOperation from "../../components/Subassembly/Operation/ModalEditOperation";
import moment from "moment";

// Machine menu package //
import MachineSpecifications from "../../components/Machine/MachineSpecifications152";
import MachineState from "../../components/Machine/MachineState152";
import MachineSubassemblies from "../../components/Machine/MachineSubassemblies152";

export default {
    name: "EventsMachine",
    components: {
        DatatableOperationsV2,
        ModalEditOperation,
        MachineSpecifications,      // Machine menu package //
        MachineState,               // Machine menu package //
        MachineSubassemblies,       // Machine menu package //
    },
    data() {
        return {
            id: this.$route.params.id,
            access_level: this.$store.state.access_level,
            type: "machine",
            machineTitle:"",
            comparison_curves: [],
            three_dimensional_curves: [],
            machine:[],
            infoToggle: false, // Machine menu package //
            label: '', // Machine menu package //
        }
    },
    created() {
        moment.locale('fr');
        this.getMachine();
    },
    methods: {
        getMachine() {
            this.axios.get(MACHINES + '/specific/' + this.id)
            
            .then((success) => {
                this.machine = [];
                this.machine = success.data[0];

            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");
            })
        },

        checkDataAvailable(datas){
            if(typeof datas[0] === 'undefined') {                    
                return false;
            }else {
                return true;
            }
        },

        // Machine menu package //
        updateLabelMachine(item) {
            this.label = item.label;
        },
        infoToggler(){
            this.infoToggle = !this.infoToggle;
        }
    }
}
</script>

<style scoped lang="scss">
#fixed-col {
    min-width: 638px;
    max-width: 638px !important;
}
@media only screen and (max-width: 480px) {
    #fixed-col {
        min-width: 100%;
        max-width: 100% !important;
    }
}
</style>
