<template>
    <div id="devices-stock-reception" class="pa-4">
        <v-row no-gutters>
            <v-col>
                <p class="title mb-0 color-primary text-uppercase">
                    {{ $t('physical_devices_stock_reception.title') }}
                </p>
            </v-col>
        </v-row>

        <v-card width="100%" class="mt-4" elevation="0">
            <validation-observer ref="observer" v-slot="{ invalid }">
                <v-form ref="form">
                    <v-card-text>
                        <validation-provider :name="$t('physical_devices_stock_reception.command_number')"
                                             rules="required" v-slot="{ errors }"
                        >
                            <v-select
                                :items="gx_orders_numbers"
                                v-model="gx_order_number"
                                :hint="$t('contract_avenant.gx_client_name_hint')"
                                :loading="gx_orders_fetch"
                                :label="$t('physical_devices_stock_reception.command_number')"
                                :error-messages="errors"
                                :disabled="block_gx_fetch"
                                :menu-props="{ bottom: true, offsetY: true }"
                                filled
                            >
                                <template v-slot:prepend-item>
                                    <v-list-item>
                                        <v-list-item-content class="ma-0 pa-0">
                                            <v-text-field
                                                v-model="gx_order_number_prefix"
                                                append-icon="mdi-magnify"
                                                :placeholder="$t('global.search')"
                                                @input="getGxOrdersNumber()"
                                            />
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>

                                <template v-slot:no-data>
                                    <v-list-item>
                                        <v-list-item-content class="ma-0 pa-0">
                                            {{ $t('global.no_results')}}
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-select>
                        </validation-provider>

                        <validation-provider :name="$t('physical_devices_stock_reception.device_type')" rules="required"
                                             v-slot="{ errors }"
                        >
                            <v-autocomplete
                                v-model="selected_device_type"
                                :items="device_types"
                                item-text="label"
                                :label="$t('physical_devices_stock_reception.device_type')"
                                :error-messages="errors"
                                return-object
                                filled
                            />
                        </validation-provider>

                        <validation-provider :name="$t('physical_devices_stock_reception.device_list')" rules="required"
                                             v-slot="{ errors }"
                        >
                            <v-textarea
                                v-model="qr_code_input"
                                :label="$t('physical_devices_stock_reception.device_list')"
                                :messages="$t('physical_devices_remote_installer_stock.device_list_description')"
                                :error-messages="errors"
                                auto-grow
                                filled
                            />
                        </validation-provider>

                        <template v-if="physical_devices_after_check">
                            <template v-for="(checked_device,index) in physical_devices_after_check">
                                <v-row class="mt-2 ml-4">
                                    <v-chip v-if="checked_device.physical_device.DEV_EUI"
                                            :color="checked_device.color"
                                            class="mb-2"
                                            :key="checked_device.physical_device.DEV_EUI"
                                            small
                                            draggable
                                            dark
                                    >
                                        <v-icon left class="mr-1" color="white" size="17">
                                            {{ checked_device.icon }}
                                        </v-icon>
                                        <span class="font-10">{{ checked_device.physical_device.DEV_EUI }}</span>
                                        <!--<v-icon right class="ml-2" color="white" size="15"
                                                @click="refreshChipsAfterDelete(index,checked_device.physical_device.DEV_EUI)"
                                        >
                                            mdi-close-circle
                                        </v-icon>-->
                                    </v-chip>
                                </v-row>
                            </template>
                        </template>
                    </v-card-text>

                    <v-card-actions class="pr-4 pb-4">
                        <v-spacer/>
                        <v-btn @click="validate()" :disabled="invalid" color="primary">
                            {{ $t('global.validation') }}
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </validation-observer>

            <v-row class="ml-3 font-10">
                <v-icon left color="primary" size="15">mdi-alert-circle</v-icon>
                <span>{{ $t('physical_devices_stock_reception.physical_device_update_hint') }}</span>
                <v-icon left color="red" size="12" class="ml-2">mdi-color-helper</v-icon>
                <span>{{ $t('global.exist_notice') }}</span>
                <v-icon left color="green" size="12" class="ml-2">mdi-color-helper</v-icon>
                <span> {{ $t('global.valid') }}</span>
            </v-row>
        </v-card>
        <global-snackbar/>
    </div>
</template>

<script>
import {PHYSICAL_DEVICES, REFERENTIAL_PHYSICAL_DEVICE_TYPES} from "../../../../api";
import GlobalSnackbar from "../../../../global/components/GlobalSnackbar";

export default {
    name: "StockReception",
    components: {GlobalSnackbar},
    data() {
        return {
            invalid: false,
            gx_orders_fetch: false,
            gx_order_number: null,
            device_types: [],
            selected_device_type: '',
            qr_code_input: null,
            gx_orders_numbers: [],
            gx_order_number_prefix: null,
            physical_devices: [],
            physical_devices_after_check: [],
            block_gx_fetch: false,
            timeout_id: null,
            error_collector: {
                found: 0,
                success: 0,
                chirp_stack_exist_reply: {
                    count: 0,
                    physical_devices: ''
                },
            },
        }
    },
    watch: {
        qr_code_input(val) {
            this.physical_devices = this.dataExtraction(val.match(this.global_regex));
            if (val.length < 2) {
                this.physical_devices = [];
                this.physical_devices_after_check = [];
            }
            this.refreshChips();
            this.chipsRendering();
        },
        gx_order_number_prefix() {
            this.resetAutoCompleteOrderGx();
        }
    },
    created() {
        this.getDevicesTypes();
    },
    methods: {
        getDevicesTypes() {
            axios.get(
                REFERENTIAL_PHYSICAL_DEVICE_TYPES
            ).then(success => {
                this.device_types = success.data
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('global.error'));
            })
        },

        async validate() {
            this.$refs.form.validate();
            if (this.physical_devices_after_check.length === 0) {
                this.manageError(0, this.$t('global.invalid_sensor_number'));
                this.qr_code_input = '';
            } else {
                for (let i = 0; i < this.physical_devices_after_check.length; i++) {
                    if (this.physical_devices_after_check[i].color === 'green') {
                        await axios.post(
                            PHYSICAL_DEVICES,
                            {
                                device_chirpstack_profile_id: this.selected_device_type.device_profil_chirpstack_id,
                                device_uid: this.physical_devices_after_check[i].physical_device.DEV_EUI.toString().split(':')[1],
                                device_model: this.physical_devices_after_check[i].physical_device.Model.toString().split(':')[1],
                                selected_device_type_id: this.selected_device_type.id,
                                physical_device_app_key: this.physical_devices_after_check[i].physical_device.APP_KEY.toString().split(':')[1],
                                gx_order_number: this.gx_order_number,
                            }
                        ).catch((error) => {
                            if (error.response.status === 409) {
                                this.error_collector.chirp_stack_exist_reply.physical_devices += this.error_collector.chirp_stack_exist_reply.count === 0 ? error.response.data + ' ' : ' ' + this.physical_devices_after_check[i].physical_device.DEV_EUI.toString().split(':')[1];
                                this.error_collector.chirp_stack_exist_reply.count++;
                            }
                        });
                    }
                }
                this.physicalDeviceCheck();
                this.confirmationMessageDisplay();
                this.resetAfterValidation();
            }
        },

        resetAfterValidation() {
            this.$refs.observer.reset();
            this.$refs.form.reset();
            this.qr_code_input = "";
            this.gx_client_name_prefix = null;
            this.physical_devices = [];
            this.physical_devices_after_check = [];
            this.error_collector = {
                found: 0,
                success: 0,
                chirp_stack_exist_reply: {
                    count: 0,
                    physical_devices: ''
                },
            }
        },

        physicalDeviceCheck() {
            this.error_collector.found = 0;
            this.error_collector.success = 0;

            this.physical_devices_after_check.forEach((physical_device) => {
                physical_device.color === 'red' ? this.error_collector.found++ : this.error_collector.success++;
            });
        },

        confirmationMessageDisplay() {
            let successMessage = this.$t('physical_devices_stock_reception.device_success_add_filter');

            if (this.error_collector.found === 0 && this.error_collector.chirp_stack_exist_reply.count === 0 && this.error_collector.success > 0) {
                successMessage = this.$t('physical_devices_stock_reception.device_success_add');
                this.showGlobalSnackbar('success', successMessage);
            } else if (this.error_collector.found === 0 && this.error_collector.chirp_stack_exist_reply.count > 0) {
                successMessage = this.error_collector.chirp_stack_exist_reply.physical_devices;
                this.showGlobalSnackbar('warning', successMessage);
            } else if (this.error_collector.found > 0 && this.error_collector.success === 0) {
                this.manageError(0, this.$t('physical_devices_stock_reception.not_added'));
            } else {
                this.showGlobalSnackbar('success', successMessage);
            }
        },

        async getGxOrdersNumber() {
            clearTimeout(this.timeout_id);
            this.timeout_id = setTimeout(async () => {
                if (this.gx_order_number_prefix) {
                    if (this.gx_order_number_prefix.length >= 4) {
                        this.gx_orders_fetch = true;
                        this.block_gx_fetch = true;
                        await this.axios.get(
                            '/api/gx/orders_number?order_number_prefix=' + this.gx_order_number_prefix
                        ).then((success) => {
                            if (Object.keys(success.data).length !== 0) {
                                this.gx_orders_numbers = success.data;
                            } else {
                                this.gx_orders_numbers = [];
                            }
                            this.resetAutoCompleteOrderGx();
                        }).catch((error) => {
                            this.manageError(error.response.status, this.$t('global.error'));
                        }).finally(() => {
                            this.gx_orders_fetch = false;
                            this.block_gx_fetch = false;
                        });
                    }
                }
            }, 1000);
        },

        resetAutoCompleteOrderGx() {
            if (!this.gx_order_number_prefix && this.gx_orders_numbers.length >= 1) {
                this.gx_orders_numbers = [];
                this.gx_orders_fetch = false;
                this.gx_order_number = null;
            }
        },

        chipsRendering() {
            if (this.physical_devices) {
                for (let index in this.physical_devices) {
                    this.axios.get(PHYSICAL_DEVICES + '/' + this.physical_devices[index].DEV_EUI.toString().split(':')[1])
                        .then((success) => {
                            let chipColor = 'green';
                            let chipIcon = 'mdi-checkbox-marked-circle-outline';
                            if (success.data.id) {
                                chipColor = 'red';
                                chipIcon = "mdi-alert-octagon";
                            }

                            if (this.physical_devices_after_check.length === 0) {
                                this.physical_devices_after_check.push({
                                    physical_device: this.physical_devices[index],
                                    color: chipColor,
                                    icon: chipIcon
                                });
                            } else {
                                let item_index = 0;
                                item_index = this.physical_devices_after_check.findIndex(obj => obj.physical_device.DEV_EUI === this.physical_devices[index].DEV_EUI)
                                if (item_index === -1) {
                                    this.physical_devices_after_check.push({
                                        physical_device: this.physical_devices[index],
                                        color: chipColor,
                                        icon: chipIcon
                                    });
                                }
                            }
                        });
                }
            }
        },

        refreshChipsAfterDelete(index, dev_eui) {
            if (this.physical_devices_after_check && this.qr_code_input.length > 0) {
                this.physical_devices_after_check.splice(index, 1);
                this.physical_devices.splice(index, 1);
                this.qr_code_input = this.qr_code_input.replace(dev_eui, "");
            }
        },

        refreshChips() {
            if (!this.physical_devices) {
                this.physical_devices_after_check = [];
            }
            if (this.physical_devices_after_check) {
                for (let i = this.physical_devices_after_check.length - 1; i >= 0; i--) {
                    if (!this.physical_devices.find(physical_device => {
                        physical_device.DEV_EUI === this.physical_devices_after_check[i].physical_device.DEV_EUI
                    })) {
                        this.physical_devices_after_check.splice(i, 1);
                    }
                }
            }
        },
    }
}
</script>
