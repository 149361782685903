import {PROFESSIONALS} from '../../api';

const state = () => ({
    professionals_resume: [],
});

const mutations = {
    setProfessionalsResume(state, payload) {
        this.state.professionals_resume = payload;
    },
};

const actions = {
    async load({commit}) {
        await axios.get(
            PROFESSIONALS + '/sites/resume'
        ).then((success) => {
            commit('setProfessionalsResume', success.data);
        }).catch((error) => {
            console.log('Request products error', error);
        });
    },
};

const getters = {
    professionals_resume: state => {
        return state.professionals_resume;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
