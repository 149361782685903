<template>
    <v-col class="pl-0 mt-0">
        <v-subheader class="v-subheader col theme--light pl-5 ml-1">
            <p class="title mb-0 color-primary text-uppercase">
                {{ $t('park.title') }}
            </p>
            <!------------------- Info bulle ---------------------->
            <v-tooltip bottom dark class="mr-2">
                <template v-slot:activator="{ on }">
                    <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                        <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                    </v-btn>
                </template>
                <template v-slot:default>
                <div style="min-width: 200px;">
                    <strong>Page [Parc machines]</strong>
                    <p>
                        Cette page recense tous les parcs machines, regroupements et machines associés à un compte client.</br>
                        De plus elle permet d'avoir une vision d'ensemble de l'état de santé de vos moyens.</br>
                        Ainsi les parcs comprenants des machines ayant un état de santé dégradé seront eux même impactés par correspondance.
                    </p>
                    <p>
                        Vous trouverez notamment les regroupements vous permettant de référencer et classer vos équipements.</br> 
                        Les regroupements seront d'une grande utilité dans le cadre de nos campagnes.
                    </p>
                </div>
                </template>
            </v-tooltip>
        </v-subheader>
        <!-- content -->
        <v-col color="white" class="ml-0 mr-4 pl-0 col">
            <!-- TOP DATA BAR -->
            <v-col class="row col-12 mb-0 pa-0">
                <!-- COUNTER -->
                <v-col color="white" class="col-2 pa-0 mt-5">
                    <v-col v-if="sites_count > 1 && machines_count > 1" class="col pt-0 pb-0 mr-10 rounded col ml-6" style="height: 35px;background-color: rgb(224 232 240);font-weight: bold;text-align: center;font-size: 16px;border: 1px solid #014181;line-height: 35px;color: #014181;">
                        {{ sites_count }} parcs et {{ machines_count }} machines.
                    </v-col>
                    <v-col v-if="sites_count > 1 && machines_count == 1" class="col pt-0 pb-0 mr-10 rounded col ml-6" style="height: 35px;background-color: rgb(224 232 240);font-weight: bold;text-align: center;font-size: 16px;border: 1px solid #014181;line-height: 35px;color: #014181;">
                        {{ sites_count }} parcs et {{ machines_count }} machine.
                    </v-col>
                    <v-col v-if="sites_count > 1 && machines_count == 0" class="col pt-0 pb-0 mr-10 rounded col ml-6" style="height: 35px;background-color: rgb(224 232 240);font-weight: bold;text-align: center;font-size: 16px;border: 1px solid #014181;line-height: 35px;color: #014181;">
                        {{ sites_count }} parcs et aucune machine.
                    </v-col>

                    <v-col v-if="sites_count == 1 && machines_count > 1" class="col pt-0 pb-0 mr-10 rounded col ml-6" style="height: 35px;background-color: rgb(224 232 240);font-weight: bold;text-align: center;font-size: 16px;border: 1px solid #014181;line-height: 35px;color: #014181;">
                        {{ sites_count }} parc et {{ machines_count }} machines.
                    </v-col>
                    <v-col v-if="sites_count == 1 && machines_count == 1" class="col pt-0 pb-0 mr-10 rounded col ml-6" style="height: 35px;background-color: rgb(224 232 240);font-weight: bold;text-align: center;font-size: 16px;border: 1px solid #014181;line-height: 35px;color: #014181;">
                        {{ sites_count }} parc et {{ machines_count }} machine.
                    </v-col>
                    <v-col v-if="sites_count == 1 && machines_count == 0" class="col pt-0 pb-0 mr-10 rounded col ml-6" style="height: 35px;background-color: rgb(224 232 240);font-weight: bold;text-align: center;font-size: 16px;border: 1px solid #014181;line-height: 35px;color: #014181;">
                        {{ sites_count }} parc et aucune machine.
                    </v-col>

                    <v-col v-if="sites_count == 0" class="col pt-0 pb-0 mr-10 rounded col ml-6" style="height: 35px;background-color: rgb(224 232 240);font-weight: bold;text-align: center;font-size: 16px;border: 1px solid #014181;line-height: 35px;color: #014181;">
                        Aucun parc référencé.
                    </v-col>
                </v-col>

                <!-- GLOBAL ACTIONS -->
                <v-col class="row col ml-6 pt-8">
                    <v-tooltip v-if="access_level <= 99 && sites_available == false" bottom dark class="mr-2">
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" @click="openDialogAddSite()" class="mr-2" dusk="logout-button" style="border: 1px solid #01418145;">
                                <v-icon color="primary">mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Ajouter un parc machines</span>
                    </v-tooltip>
                    <v-tooltip bottom dark class="mr-2">
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" @click="initialize()" class="mr-2" dusk="logout-button" style="border: 1px solid #01418145;">
                                <v-icon color="primary">mdi-tag-multiple</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('global.1_5_2') }}</span>
                    </v-tooltip>
                    <v-tooltip bottom dark class="mr-2">
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" @click="initialize()" class="mr-2" dusk="logout-button" style="border: 1px solid #01418145;">
                                <v-icon color="primary">mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('campain.refresh') }}</span>
                    </v-tooltip>
                </v-col>

                <!-- SPACER -->
                <v-col class="row col"></v-col> 

                <!-- SEARCH -->       
                <v-col @click="dialogNextUpdate()" class="col-4 pa-0">
                    <v-text-field 
                        v-model="search"
                        append-icon="mdi-magnify"
                        :label="$t('global.search')"
                        class="mt-1"
                        disabled
                    ></v-text-field>
                </v-col>
            </v-col>

            <!-- Sites tab -->
            <v-col v-if="active_site !== null && sites_available" class="row ml-3 mt-1 pb-0 pt-0">
                <div v-for="site in sites" :key="site" class="mr-1">
                    <div v-if="site.id === active_site" class="ma-auto pl-5 pr-5 pa-2"
                        style="
                            color: black;
                            font-weight: 400;
                            display: flex;
                            justify-content: left;
                            text-shadow: rgba(0, 0, 0, 0.29) 2px 2px 4px;
                            background-color: rgb(255 255 255);
                            border-radius: 10px 10px 0px 0px;
                            border-left: 1px solid rgba(211, 211, 211, 0.43);
                            border-bottom: none;
                            font-size: 14px;
                            height: 40px;
                            align-items: center;
                        "
                    >
                        {{site.label}}
                    </div>
                    <div v-else class="ma-auto pl-5 pr-5 pa-2"
                        style="
                            color: black;
                            font-weight: 400;
                            display: flex;
                            justify-content: left;
                            text-shadow: rgba(0, 0, 0, 0.29) 2px 2px 4px;
                            background-color: rgb(245 245 245);
                            border-radius: 10px 10px 0px 0px;
                            border: 1px solid #e6e6e6;
                            border-bottom: none;
                            font-size: 14px;
                            height: 40px;
                            cursor: pointer;
                        "
                        @click="loadSite(site.id)"
                    >
                        {{site.label}}
                    </div>
                </div>
                <div v-if="access_level <= 99" class="mr-0">
                    <div class="ma-auto"
                        style="
                            color: black;
                            font-weight: 400;
                            display: flex;
                            justify-content: left;
                            text-shadow: rgba(0, 0, 0, 0.29) 2px 2px 4px;
                            background-color: rgb(245 245 245);
                            border-radius: 10px 10px 0px 0px;
                            border: 1px solid #e6e6e6;
                            font-size: 14px;
                            border-bottom: none;
                            height: 40px;
                            cursor: pointer;
                        "
                    >
                        <v-tooltip bottom dark class="mr-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="openDialogAddSite()" class="pa-5" dusk="logout-button">
                                    <v-icon color="primary">mdi-plus</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('park.add_park') }}</span>
                        </v-tooltip> 
                    </div>
                </div>
            </v-col> 
            
            <div v-if="loading_datatable == true" style="color:grey;text-align: center;">
                <li class="rounded row notification_card mb-1 pa-10 mt-15 ml-6" style="color: grey;justify-content: center;">
                    <div style="color:grey;text-align: center;"><strong>Chargement en cours...</strong></div>
                </li>
            </div>            

            <div v-if="loading_datatable == false" flat color="white" class="pt-0 mb-3">
                <!-- ALL DATATABLE -->
                <div v-for="site in sites" :key="site" flat color="white" class="pt-0 mb-3 mt-3">
                    <!-- DATA LIST -->
                    <template v-if="active_site !== null && active_site === site.id">
                        <ul id="notifications-cards">
                            
                            <!-- ACTIVE DATA (on non trashed data and non closed data)-->
                            <li class="row" v-if="sites_count > 0">
                                <div class="rounded row notification_card mb-0 elevation-1 ml-0 mr-0 mt-0" style="border: solid 1px #80808021;background-color: #ffffff;">
                                    <div class="row pa-3">
                                        <!--CHIPS-->
                                        <div v-for="filters in machine_filters" :key="filters">
                                            <div  v-if="filters.level == site.alert_level && filters.level_name !== 'Tout voir'"  class="col-0 mr-5" style="color:white;font-weight:800;display:flex;justify-content:center;text-shadow:2px 2px 4px rgba(0, 0, 0, 0.29); height:100%;min-width: 8px;border-radius:2px 0px 0px 2px;" :style="{backgroundColor: filters.level_color}"></div>
                                        </div>
                                        
                                        <!--INFOS-->
                                        <div class="col-4">
                                            <v-chip class="mb-5 mt-5" v-on="on" link :to="'/site/'+ site.id" style="font-weight: bold; background-color: #f3f3f3; border: 1px solid #e5e5e5;">{{site.label}}</v-chip>
                                            <div style="font-size: 12px;margin-left: 10px;">Ref - {{site.uid}}</div>
                                            <div style="font-size: 12px;margin-left: 10px;">Adresse : {{site.address}}</div>
                                            <div style="font-size: 12px;margin-left: 10px;">Créé le : {{site.created_at}}</div>
                                            <ColumnChart :xAxis="chart.xAxis" :series="chart.series" class="mt-5"/>
                                        </div>

                                        <!-- ACTIONS -->
                                        <v-col class="col-1 pa-2" style="max-width: 80px;">
                                            <v-tooltip v-if="!site.trash" bottom dark>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn class="ma-auto mr-5 ml-5 col-0" text icon v-on="on" link :to="'/site/'+ site.id" dusk="logout-button">
                                                        <v-icon color="primary">mdi-dots-horizontal-circle</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>{{ $t('park.redirect') }}</span>
                                            </v-tooltip>
                                            <v-tooltip v-if="!site.trash && access_level <= 99 " bottom dark>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn class="ma-auto mr-5 ml-5 col-0" text icon v-on="on" @click="openDialogAddBuilding(site)" dusk="logout-button">
                                                        <v-icon color="primary">mdi-plus-circle-outline</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>{{ $t('park.add_regroupment') }}</span>
                                            </v-tooltip>
                                            <v-tooltip v-if="!site.trash && access_level <= 99 " bottom dark>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn class="ma-auto mr-5 ml-5 col-0" text icon v-on="on" @click="openDialogUpdateSite(site)" dusk="logout-button">
                                                        <v-icon color="primary">mdi-circle-edit-outline</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>{{ $t('park.edit_park') }}</span>
                                            </v-tooltip>
                                            <v-tooltip v-if="!site.trash && access_level === 0 " bottom dark>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn class="ma-auto mr-5 ml-5 col-0 mt-2" text icon v-on="on" @click="pushTrashSite(site.id)" dusk="logout-button">
                                                        <v-icon color="red">mdi-delete-alert</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>{{ $t('park.desactivate_park') }}</span>
                                            </v-tooltip>
                                        </v-col>

                                        <!-- UPDATES -->
                                        <v-col style="border-left: 1px solid #d3d3d36e; font-size:12px;background-color: whitesmoke;">
                                            <v-row >
                                                <v-col class="pa-2 ma-5" style="background-color: white;border-radius: 5px;">
                                                    <div style="text-align: center;font-size:40px;font-weight:bold;">{{ site.buildings.length }}</div>
                                                    <div style="text-align: center;font-size: 14px;">Regroupements</div>
                                                </v-col>
                                                <v-col class="pa-2 ma-5" style="background-color: white;border-radius: 5px;">
                                                    <div style="text-align: center;font-size:40px;font-weight:bold;">{{ machines.length }}</div>
                                                    <div style="text-align: center;font-size: 14px;">Machines</div>
                                                </v-col>
                                                <v-col class="pa-2 ma-5" style="background-color: white;border-radius: 5px;">
                                                    <div style="text-align: center;font-size:40px;font-weight:bold;">{{ campains_count }}</div>
                                                    <div style="text-align: center;font-size: 14px;">Campagnes</div>
                                                </v-col>
                                                <v-col class="pa-2 ma-5" style="background-color: white;border-radius: 5px;">
                                                    <div style="text-align: center;font-size:40px;font-weight:bold;">{{ reports_count }}</div>
                                                    <div style="text-align: center;font-size: 14px;">Rapports</div>
                                                </v-col>
                                            </v-row>

                                            <div  v-if="campain_available && campain.presta !== 5" class="col pl-5 pr-5 pb-0">
                                                <v-col class="col col pa-0 pt-0 pb-1 ma-0 ml-0 mt-2" style="text-align: center;">
                                                    <div v-for="presta in prestas" :key="presta">
                                                        <div v-for="typex in types" :key="typex">
                                                            <v-chip v-if="presta.id === campain.presta && typex.id === campain.type" class="mb-5 mt-5" v-on="on" link :to="'/campain/' + campain.id" style="font-weight: bold; background-color: #f3f3f3; border: 1px solid #e5e5e5;">{{campain.name}}  -  {{ presta.name }} ({{ typex.name.toLowerCase().trim() }})</v-chip>
                                                        </div>
                                                    </div>
                                                </v-col>
                                                <div class="row row col pa-10 pt-5 pb-5 ma-0 ml-0 mt-1 mb-5" style="
                                                    align-items: center;
                                                    justify-content: center;
                                                    text-align: center;
                                                    background-color: whitesmoke;
                                                    border-radius: 100px;
                                                ">
                                                    <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                        <!-- CUSTOMER VIEW -->
                                                        <v-icon v-if="campain.step <= 11 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 12 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 13 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                        <v-icon v-if="campain.step > 13 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                                        <!-- ADMIN VIEW -->
                                                        <v-icon v-if="campain.step === 11 && access_level <= 100" @click="updateStep(campain.id,12)" class="pointer" color="orange" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 12 && access_level <= 100" @click="updateStep(campain.id,13)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 13 && access_level <= 100" @click="updateStep(campain.id,11)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                        <v-icon v-if="campain.step > 13 && access_level <= 100" @click="updateStep(campain.id,11)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                                    </p>
                                                    <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                        <!-- CUSTOMER VIEW -->
                                                        <v-icon v-if="campain.step === 21 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 22 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 23 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                        <v-icon v-if="campain.step < 21 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step > 23 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                                        <!-- ADMIN VIEW -->
                                                        <v-icon v-if="campain.step === 21 && access_level <= 100" @click="updateStep(campain.id,22)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 22 && access_level <= 100" @click="updateStep(campain.id,23)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 23 && access_level <= 100" @click="updateStep(campain.id,21)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                        <v-icon v-if="campain.step < 21 && access_level <= 100" @click="updateStep(campain.id,21)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step > 23 && access_level <= 100" @click="updateStep(campain.id,21)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                                    </p>
                                                    <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                        <!-- CUSTOMER VIEW -->
                                                        <v-icon v-if="campain.step === 31 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 32 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 33 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                        <v-icon v-if="campain.step < 31 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step > 33 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                                        <!-- ADMIN VIEW -->
                                                        <v-icon v-if="campain.step === 31 && access_level <= 100" @click="updateStep(campain.id,32)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 32 && access_level <= 100" @click="updateStep(campain.id,33)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 33 && access_level <= 100" @click="updateStep(campain.id,31)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                        <v-icon v-if="campain.step < 31 && access_level <= 100" @click="updateStep(campain.id,31)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step > 33 && access_level <= 100" @click="updateStep(campain.id,31)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                                    </p>
                                                    <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                        <!-- CUSTOMER VIEW -->
                                                        <v-icon v-if="campain.step === 41 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 42 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 43 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                        <v-icon v-if="campain.step < 41 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                        <!-- ADMIN VIEW -->
                                                        <v-icon v-if="campain.step === 41 && access_level <= 100" @click="updateStep(campain.id,42)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 42 && access_level <= 100" @click="updateStep(campain.id,43)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 43 && access_level <= 100" @click="updateStep(campain.id,41)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                        <v-icon v-if="campain.step < 41 && access_level <= 100" @click="updateStep(campain.id,41)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                    </p>
                                                </div>
                                                <div class="col" style="
                                                    position: relative;
                                                    border: 1px solid #656565;
                                                    top: -58px;
                                                    width: 70%;
                                                    left: 15%;
                                                    border-radius: 15px;
                                                    height: 2px;
                                                    padding: 0px;
                                                "></div>
                                                <div class="row row col pa-10 pt-0 pb-0 ma-0 ml-0" style="
                                                    align-items: center;
                                                    justify-content: center;
                                                    text-align: center;
                                                    position: relative;
                                                    top: -57px;
                                                ">
                                                    <p class="ml-0 mb-0 col" v-if="campain.step === 11 || campain.step === 12 || campain.step === 13" style="z-index: 10;font-weight: bold;">Préparation</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step != 11 && campain.step != 12 && campain.step != 13" style="z-index: 10;">Préparation</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step === 21 || campain.step === 22 || campain.step === 23" style="z-index: 10;font-weight: bold;">Intervention</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step != 21 && campain.step != 22 && campain.step != 23" style="z-index: 10;">Intervention</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step === 31 || campain.step === 32 || campain.step === 33" style="z-index: 10;font-weight: bold;">Analyses</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step != 31 && campain.step != 32 && campain.step != 33" style="z-index: 10;">Analyses</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step === 41 || campain.step === 42 || campain.step === 43" style="z-index: 10;font-weight: bold;">Rapports</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step != 41 && campain.step != 42 && campain.step != 43" style="z-index: 10;">Rapports</p>
                                                </div>
                                                <v-row class="row pl-10 pr-10" style="position: relative; top:-35px;">
                                                    <v-col class="row col pa-0 pt-0 pb-1 ma-0 ml-10 mr-10" style="text-align: center;">
                                                        <div style="font-weight: bold;font-size: 13px;" class="mb-2 col col">
                                                            <div>Equipements concernés : </div> 
                                                            <v-chip class="ml-0 mb-0">{{ campain.machine_list.length }} / {{ machines_count }}</v-chip>
                                                        </div>
                                                        <div style="font-weight: bold;font-size: 13px;" class="mb-2 col col">
                                                            <div>Rapports disponibles: </div>
                                                            <v-chip class="ml-0 mb-0">{{ campain.machine_list.length }} / {{ campain.machine_list.length }}</v-chip>
                                                        </div> 
                                                    </v-col>
                                                </v-row>                       
                                            </div>

                                            <div  v-if="campain_available && campain.presta === 5" class="col pl-5 pr-5 pb-0">
                                                <v-col class="col col pa-0 pt-0 pb-1 ma-0 ml-0 mt-2" style="text-align: center;">
                                                    <div v-for="presta in prestas" :key="presta">
                                                        <div v-for="typex in types" :key="typex">
                                                            <v-chip v-if="presta.id === campain.presta && typex.id === campain.type" class="ml-0 mb-0">{{ presta.name }} ({{ typex.name.toLowerCase().trim() }})</v-chip>
                                                        </div>
                                                    </div>
                                                </v-col>
                                                <div class="row row col pa-10 pt-5 pb-5 ma-0 ml-0 mt-1 mb-5" style="
                                                    align-items: center;
                                                    justify-content: center;
                                                    text-align: center;
                                                    background-color: whitesmoke;
                                                    border-radius: 100px;
                                                ">
                                                    <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                        <!-- CUSTOMER VIEW -->
                                                        <v-icon v-if="campain.step <= 11 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 12 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 13 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                        <v-icon v-if="campain.step > 13 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                                        <!-- ADMIN VIEW -->
                                                        <v-icon v-if="campain.step === 11 && access_level <= 100" @click="updateStep(campain.id,12)" class="pointer" color="orange" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 12 && access_level <= 100" @click="updateStep(campain.id,13)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 13 && access_level <= 100" @click="updateStep(campain.id,11)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                        <v-icon v-if="campain.step > 13 && access_level <= 100" @click="updateStep(campain.id,11)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                                    </p>
                                                    <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                        <!-- CUSTOMER VIEW -->
                                                        <v-icon v-if="campain.step === 21 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 22 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 23 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                        <v-icon v-if="campain.step < 21 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step > 23 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                                        <!-- ADMIN VIEW -->
                                                        <v-icon v-if="campain.step === 21 && access_level <= 100" @click="updateStep(campain.id,22)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 22 && access_level <= 100" @click="updateStep(campain.id,23)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 23 && access_level <= 100" @click="updateStep(campain.id,21)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                        <v-icon v-if="campain.step < 21 && access_level <= 100" @click="updateStep(campain.id,21)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step > 23 && access_level <= 100" @click="updateStep(campain.id,21)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                                    </p>
                                                    <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                        <!-- CUSTOMER VIEW -->
                                                        <v-icon v-if="campain.step === 31 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 32 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 33 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                        <v-icon v-if="campain.step < 31 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step > 33 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                                        <!-- ADMIN VIEW -->
                                                        <v-icon v-if="campain.step === 31 && access_level <= 100" @click="updateStep(campain.id,32)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 32 && access_level <= 100" @click="updateStep(campain.id,33)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 33 && access_level <= 100" @click="updateStep(campain.id,31)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                        <v-icon v-if="campain.step < 31 && access_level <= 100" @click="updateStep(campain.id,31)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step > 33 && access_level <= 100" @click="updateStep(campain.id,31)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                                    </p>
                                                    <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                        <!-- CUSTOMER VIEW -->
                                                        <v-icon v-if="campain.step === 41 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 42 && access_level > 100" color="flashGreen" style="font-size: 36px;">mdi-play-network</v-icon>
                                                        <v-icon v-if="campain.step === 43 && access_level > 100" color="red" style="font-size: 36px;">mdi-close-network-outline</v-icon>
                                                        <v-icon v-if="campain.step < 41 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                        <!-- ADMIN VIEW -->
                                                        <v-icon v-if="campain.step === 41 && access_level <= 100" @click="updateStep(campain.id,42)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 42 && access_level <= 100" @click="updateStep(campain.id,43)" class="pointer" color="flashGreen" style="font-size: 36px;">mdi-play-network</v-icon>
                                                        <v-icon v-if="campain.step === 43 && access_level <= 100" @click="updateStep(campain.id,41)" class="pointer" color="red" style="font-size: 36px;">mdi-close-network-outline</v-icon>
                                                        <v-icon v-if="campain.step < 41 && access_level <= 100" @click="updateStep(campain.id,41)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                    </p>
                                                </div>
                                                <div v-if="campain.step === 42" class="col" style="
                                                    position: relative;
                                                    border: 1px solid #00db37;
                                                    top: -58px;
                                                    width: 70%;
                                                    left: 15%;
                                                    border-radius: 15px;
                                                    height: 2px;
                                                    padding: 0px;
                                                "></div>
                                                <div v-if="campain.step === 43" class="col" style="
                                                    position: relative;
                                                    border: 1px solid #d70000;
                                                    top: -58px;
                                                    width: 70%;
                                                    left: 15%;
                                                    border-radius: 15px;
                                                    height: 2px;
                                                    padding: 0px;
                                                "></div>
                                                <div v-if="campain.step < 42" class="col" style="
                                                    position: relative;
                                                    border: 1px solid #656565;
                                                    top: -58px;
                                                    width: 70%;
                                                    left: 15%;
                                                    border-radius: 15px;
                                                    height: 2px;
                                                    padding: 0px;
                                                "></div>
                                                <div class="row row col pa-10 pt-0 pb-0 ma-0 ml-0" style="
                                                    align-items: center;
                                                    justify-content: center;
                                                    text-align: center;
                                                    position: relative;
                                                    top: -57px;
                                                ">
                                                    <p class="ml-0 mb-0 col" v-if="campain.step === 11 || campain.step === 12 || campain.step === 13" style="z-index: 10;font-weight: bold;">Préparation</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step != 11 && campain.step != 12 && campain.step != 13" style="z-index: 10;">Préparation</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step === 21 || campain.step === 22 || campain.step === 23" style="z-index: 10;font-weight: bold;">Instrumentation</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step != 21 && campain.step != 22 && campain.step != 23" style="z-index: 10;">Instrumentation</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step === 31 || campain.step === 32 || campain.step === 33" style="z-index: 10;font-weight: bold;">Configuration</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step != 31 && campain.step != 32 && campain.step != 33" style="z-index: 10;">Configuration</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step === 41 || campain.step === 42 || campain.step === 43" style="z-index: 10;font-weight: bold;">Surveillance</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step != 41 && campain.step != 42 && campain.step != 43" style="z-index: 10;">Surveillance</p>
                                                </div>
                                                <v-row class="row pl-10 pr-10" style="position: relative; top:-35px;">
                                                    <v-col class="row col pa-0 pt-0 pb-1 ma-0 ml-10 mr-10" style="text-align: center;">
                                                        <div style="font-weight: bold;font-size: 13px;" class="mb-2 col col">
                                                            <div>Equipements concernés : </div> 
                                                            <v-chip class="ml-0 mb-0">{{ campain.machine_list.length }} / {{ machines_count }}</v-chip>
                                                        </div>
                                                        <div style="font-weight: bold;font-size: 13px;" class="mb-2 col col">
                                                            <div>Rapports disponibles: </div>
                                                            <v-chip class="ml-0 mb-0">{{ available_reports_count }} / {{ campain.machine_list.length }}</v-chip>
                                                        </div> 
                                                    </v-col>
                                                </v-row>                          
                                            </div>

                                            <div  v-if="!campain_available" class="col pl-5 pr-5 pb-0">
                                                <v-row class="row ma-0 mt-15 mb-5 pa-15 rounded" style="border: 2px dotted #80808059;color: grey;justify-content: center;">
                                                    <div style="color:grey;text-align: center;">Aucune campagne n'est en cours sur ce parc machines. <br> Dès qu'une campagne sera lancée son avancement apparaîtra ici.</div>
                                                </v-row>
                                            </div>
                                        </v-col>
                                    </div>
                                </div>
                            </li>

                            <!-- BUILDINGS LIST -->
                            <li class="row " v-for="building in site.buildings" :key="building">
                                <div class="rounded row notification_card mb-0 elevation-1 ml-0 mr-0 mt-3" style="border: solid 1px #80808021;background-color: #ffffff;">
                                    <div class="row pa-3">
                                        <!--CHIPS-->
                                        <div v-for="filters in machine_filters" :key="filters">
                                            <div  v-if="filters.level == building.alert_level && filters.level_name !== 'Tout voir'"  class="col-0 mr-5" style="color:white;font-weight:800;display:flex;justify-content:center;text-shadow:2px 2px 4px rgba(0, 0, 0, 0.29); height:100%;min-width: 8px;border-radius:2px 0px 0px 2px;" :style="{backgroundColor: filters.level_color}"></div>
                                        </div>
                                        
                                        <!--INFOS-->
                                        <div class="col-4">
                                            <v-chip class="mb-1 mt-1" v-on="on" link :to="'/machines'" style="font-weight: bold; background-color: #f3f3f3; border: 1px solid #e5e5e5;">Regroupement : {{building.label}}</v-chip>
                                            <div style="font-size: 12px;margin-left: 10px;">Ref - {{building.uid}}</div>
                                            <div style="font-size: 12px;margin-left: 10px;">Créée le : {{building.created_at}}</div>
                                        </div>

                                        <!-- ACTIONS -->
                                        <v-col class="col-1 pa-2" style="max-width: 80px;">
                                            <v-tooltip v-if="!site.trash" bottom dark>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn class="ma-auto mr-5 ml-5 col-0" text icon v-on="on" link :to="'/building/'+ building.id" dusk="logout-button">
                                                        <v-icon color="primary">mdi-dots-horizontal-circle</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Voir le regroupement en détail.</span>
                                            </v-tooltip>
                                            <v-tooltip v-if="access_level <= 99" bottom dark>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn class="ma-auto mr-5 ml-5 col-0" text icon v-on="on" @click="openDialogUpdateBuilding(building)" dusk="logout-button">
                                                        <v-icon color="primary">mdi-circle-edit-outline</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>{{ $t('park.edit_park') }}</span>
                                            </v-tooltip>
                                            <v-tooltip v-if="!building.trash && access_level === 0" bottom dark>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn class="ma-auto mr-5 ml-5 col-0 mt-2" text icon v-on="on" @click="pushBuildingTrash(building.id)" dusk="logout-button">
                                                        <v-icon color="red">mdi-delete-alert</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>{{ $t('park.desactivate_park') }}</span>
                                            </v-tooltip>
                                        </v-col>

                                        <!-- UPDATES -->
                                        <div class="col pl-5 pr-5 pb-0" style="border-left: 1px solid #d3d3d36e; font-size:12px;background-color: whitesmoke;">
                                            <v-row style="align-items: center;">
                                                <div class="pl-4" style="font-weight: bold;font-size: 13px;">Equipements :</div>
                                                <!------------------- Info bulle ---------------------->
                                                <v-tooltip bottom dark class="mr-2" style="">
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn text icon v-on="on" @click="getNotification()" class="mb-5 mt-5 ml-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                                                            <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <template v-slot:default>
                                                    <div style="min-width: 200px;">
                                                        <p>Liste des machines recensées par regroupement.</p>
                                                        <p>**Cliquer sur une des machines ci dessous vous redirigera vers sa page.</br>
                                                            Vous y trouverez directement les derniers rapports, évènements ou encore les caractéristiques mécanique du moyen.
                                                        </p>
                                                    </div>
                                                    </template>
                                                </v-tooltip>
                                            </v-row> 
                                            <v-row v-if="!machines[0]" class="row ma-0 mt-5 mb-5 pa-5 rounded" style="border: 2px dotted #80808059;color: grey;justify-content: center;">
                                                <div style="color:grey;text-align: center;">Aucune machine n'est associé à ce regroupement. </br> Il est possible d'associer une machine à un regroupement depuis l'onglet <strong>[Equipements]</strong>.</div>
                                            </v-row>

                                            <!-- CHIPS -->
                                            <v-row v-if="machines[0]" class="row col pa-0 pt-1 pb-1 ma-0 ml-0">
                                                <div v-for="machine in machines" :key="machine">
                                                    <div v-for="filters in machine_filters" :key="filters">
                                                        <v-chip v-if=" filters.level == machine.alert_level && filters.level_name !== 'Tout voir' && building.id == machine.building_id" :to="{ path: '/machine/' + machine.id + '/report'}" style="font-size: 12px;font-weight:bold;text-shadow: rgba(0, 0, 0, 0.29) 2px 2px 4px; color: white;" class="mr-2 mb-2 pointer" :style="{backgroundColor: filters.level_color}" >({{ machine.subassemblies.length }})   {{machine.label}}</v-chip>
                                                    </div> 
                                                </div>  
                                            </v-row> 
                                        </div>
                                    </div>
                                </div>

                            </li>
                        </ul>
                    </template>
                </div>

                <!-- ALL DATA VOID (all categories)-->
                <div v-if="sites_count == 0 && access_level <= 99" class="rounded row notification_card mb-1 pa-10 mt-15 ml-3 mr-2" style="border: 2px dotted #80808059;color: grey;justify-content: center;">
                    <div style="color:grey;text-align: center;">Ce compte client n'a pas de parcs machines. <br> Créez le premier parc machine pour ce client site <strong>via le bouton (+) ci dessus.</strong></div>
                </div>

                <div v-if="sites_count == 0 && access_level > 99" class="rounded row notification_card mb-1 pa-10 mt-15 ml-3 mr-2" style="border: 2px dotted #80808059;color: grey;justify-content: center;">
                    <div style="color:grey;text-align: center;">Vous trouverez ici tous les parcs machines de votre entreprise. 
                        <br> Vous pourrez suivre l'avancement de nos diverses interventions dés qu'un parc sera disponible dans cette section.
                    </div>
                </div>
            </div>

                <!-- Modal add site -->
                <v-dialog v-model="dialog_edit" max-width="500px">
                    <v-card>
                        <validation-observer ref="observer" v-slot="{ invalid }">
                            <v-form ref="form">
                                <v-card-title>
                                    <span class="headline">{{ dialogTitle }}</span>
                                </v-card-title>

                                <v-divider/>

                                <v-card-text>
                                    <validation-provider :name="$t('global.name')" rules="required"
                                                            v-slot="{ errors }">
                                        <v-text-field solo
                                                        :label="$t('global.name')"
                                                        v-model="edited_site.label"
                                                        prepend-icon="mdi-label-outline"
                                                        :error-messages="errors"
                                        />
                                    </validation-provider>

                                    <validation-provider :name="$t('sites.address')" rules="required"
                                                            v-slot="{ errors }">
                                        <vuetify-google-autocomplete
                                            id="autocomplete-site" solo
                                            ref="siteAddress"
                                            prepend-icon="mdi-map-marker"
                                            v-model="edited_site.address"
                                            @placechanged="setPlace"
                                            :error-messages="errors"
                                        >
                                            <template #label>
                                                {{$t('sites.address')}}
                                            </template>
                                        </vuetify-google-autocomplete>

                                    </validation-provider>

                                    <!--
                                    <v-file-input
                                        v-if="edited_index === null"
                                        v-model="edited_site.path_plan"
                                        :label="$t('sites.site_plan')"
                                        solo
                                        @change="onImageChanged"
                                    />
                                    -->

                                    <v-textarea rows="2" solo no-resize
                                                v-model="edited_site.note"
                                                :label="$t('global.notes')"
                                                :prepend-icon="'mdi-comment-outline'"
                                    />
                                </v-card-text>

                                <v-card-actions class="justify-end">
                                    <v-btn @click="closeDialog()">
                                        <v-icon left>mdi-close</v-icon>
                                        {{ $t('global.close') }}
                                    </v-btn>
                                    <v-btn type="submit" color="primary" :disabled="invalid"
                                            @click.prevent="prepareEditSite()">
                                        <v-icon left>mdi-content-save</v-icon>
                                        {{ $t('global.validation') }}
                                    </v-btn>
                                </v-card-actions>
                            </v-form>
                        </validation-observer>
                    </v-card>
                </v-dialog>
                <!-- Delete button (if 0 buildings associated) -->
                <v-dialog v-model="dialog_delete" max-width="500px">
                    <v-card>
                        <v-card-title>
                            <span class="headline">{{ $t('sites.delete_site') }}</span>
                        </v-card-title>

                        <v-divider/>

                        <v-card-actions class="justify-end mt-4">
                            <v-btn @click="dialog_delete = false">
                                <v-icon left>mdi-close</v-icon>
                                {{ $t('global.cancel') }}
                            </v-btn>
                            <v-btn color="error" @click="confirmDeleteSite()">
                                <v-icon left>mdi-delete</v-icon>
                                {{ $t('global.delete') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                
                <!-- Modal add building -->
                <v-dialog v-model="building_dialog_edit" max-width="500px">
                    <v-card>
                        <validation-observer ref="observer" v-slot="{ invalid }">
                            <v-form ref="buildingForm">
                                <v-card-title>
                                    <span class="headline">{{ dialogTitleBuilding }}</span>
                                </v-card-title>
                                <v-divider></v-divider>

                                <v-card-text>
                                    <validation-provider :name="$t('global.name')" rules="required"
                                                            v-slot="{ errors }">
                                        <v-text-field solo
                                                        :label="$t('global.name')"
                                                        v-model="edited_building.label"
                                                        :prepend-icon="'mdi-label-outline'"
                                                        :error-messages="errors"
                                        />
                                    </validation-provider>

                                    <!--
                                    <v-file-input
                                        v-if="building_edited_index === null"
                                        v-model="edited_building.path_plan"
                                        :label="$t('buildings.building_plan')"
                                        solo
                                        @change="onImageChanged"
                                    ></v-file-input>
                                    -->

                                    <v-textarea rows="2" solo no-resize
                                                v-model="edited_building.note"
                                                :label="$t('global.notes')"
                                                :prepend-icon="'mdi-comment-outline'"
                                    />
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer/>
                                    <v-btn @click="closeDialogBuildings()">
                                        <v-icon left>mdi-close</v-icon>
                                        {{ $t('global.close') }}
                                    </v-btn>
                                    <v-btn type="submit" color="primary" :disabled="invalid"
                                            @click.prevent="prepareEditBuilding()">
                                        <v-icon left>mdi-content-save</v-icon>
                                        {{ $t('global.validation') }}
                                    </v-btn>
                                </v-card-actions>
                            </v-form>
                        </validation-observer>
                    </v-card>
                </v-dialog>
                <!-- Modal delete building -->
                <v-dialog v-model="building_dialog_delete" max-width="500px">
                    <v-card>
                        <v-card-title>
                            <span class="headline">
                                {{ $t('buildings.delete_building') }}
                            </span>
                        </v-card-title>
                        <v-divider></v-divider>
                        <br/>

                        <v-card-actions>
                            <v-spacer/>
                            <v-btn @click="dialog_delete = false">
                                <v-icon left>mdi-close</v-icon>
                                {{ $t('global.cancel') }}
                            </v-btn>
                            <v-btn color="error" @click="confirmDeleteBuilding()">
                                <v-icon left>mdi-delete</v-icon>
                                {{ $t('global.delete') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <!-- SNACKBAR INFO DISPLAY -->
                <v-snackbar
                    v-model="snackbar"
                    :color="snackbar_type">
                    {{ snackbar_text }}
                    <template v-slot:action="{ attrs }">
                        <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                    </template>
                </v-snackbar>

            <!-- Modal next update -->
            <v-dialog v-model="dialog_next_update" max-width="500px">
                <v-card>
                    <v-card-title>
                        <span class="headline">
                            Fonctionnalité bientôt disponible
                        </span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <br/>
                    <p class="pl-4 pr-4 pb-4">
                        Cette fonctionnalité est en cours de développement et sera bientôt disponible.
                        Elle vous permettra entre autre de filter et de rechercher précisément via des mots clés votre cible.
                        Un rapport, une machine, une référence...
                        Le but, vous faire gagner du temps ! 
                    </p>

                    <!-- Bouton pour fermer -->
                    <v-card-actions class="mb-3" style="position:absolute;top:5px;right:2px">
                        <v-btn @click="closeNewUpdateDialog();" style="color:red;max-width: 30px;padding: 0 0 0 5px;min-width: 10px!important;height: 30px!important;">
                            <v-icon left>mdi-close</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-col>
    </v-col>
</template>

<script>
import {CAMPAIN,MACHINES,BUILDINGS,REPORT,USERS,SITES} from "../../../api";
import moment from "moment";
import { ColumnChart } from 'vuejs-highcharts';

export default {
    name: "CampainVision",
    components: {ColumnChart},
    data() {
        return {
            // DATAS ----------------
            raw_sites: [],
            dialog_next_update: false,
            sites: [],
            sites_available:false,
            sites_count:0,
            active_site:0,
            // MACHINES
            raw_machines: [],
            machines: [],
            machines_available:false,
            machines_count:0,
            machine_list:[],
            serialized_machine_list:'',
            // CAMPAINS
            raw_campains: [],
            campain: [],
            campain_available:false,
            // REPORTS
            reports:[],
            reports_count:0,
            reports_done_count:0,
            available_reports_count:0,
            // USERS
            buildings_raw:[],
            team: [],
            
            // GLOBAL ----------------
            access_level: this.$store.state.access_level,
            id: this.$route.params.id,
            loading_datatable: true,
            loading_campain: true,
            loading_buildings: true,
            loading: true,
            edited_index: null,
            building_edited_index:null,
            dialog_next_update: false,

            dialog_edit: false,
            dialog_delete: false,
            delete_id: null,
            deleted_index: null,
            edited_site: {
                id: null,
                label: '',
                address: '',
                note: '',
                latitude: '',
                longitude: '',
                path_plan: null
            },

            building_dialog_edit: false,
            building_dialog_delete: false,
            building_delete_id: null,
            building_deleted_index: null,
            edited_building: {
                id: null,
                label: '',
                note: '',
                site_id: '',
                path_plan: null
            },

            invalid: false,
            search: '',
            valid_image:null,
            
            // ARRAYS ----------------
            data_filters: [
                {
                    level: 9,
                    level_name: "Tout voir",
                    level_color: "#d7d7d752",
                    count:this.global_notifications_count
                },
                {
                    level: 0,
                    level_name: "En préparation",
                    level_color: "#ef780e",
                    count:0
                },
                {
                    level: 1,
                    level_name: "En cours d'intervention",
                    level_color: "#0e4cef82",
                    count:0
                },
                {
                    level: 2,
                    level_name: "En cours d'analyses",
                    level_color: "#0e4cefc9",
                    count:0
                },
                {
                    level: 3,
                    level_name: "Rapports en cours d'édition",
                    level_color: "#0e4cef",
                    count:0
                },
                {
                    level: -1,
                    level_name: "Terminées",
                    level_color: "#4caf50",
                    count:0
                },                
            ],
            machine_filters: [
                {
                    level: 9,
                    level_name: "Tout voir",
                    level_color: "#d7d7d752",
                    count:this.global_notifications_count
                },
                {
                    level: 3,
                    level_name: "Urgence",
                    level_color: "black",
                    count:0
                },
                {
                    level: 2,
                    level_name: "Importance élevée",
                    level_color: "#F44336",
                    count:0
                },
                {
                    level: 1,
                    level_name: "A suivre",
                    level_color: "#FFCB05",
                    count:0
                },
                {
                    level: 0,
                    level_name: "Pour information",
                    level_color: "#4CAF50",
                    count:0
                },
                {
                    level: -1,
                    level_name: "En attente",
                    level_color: "grey",
                    count:0
                },                
            ],
            chart: {
                xAxis: {
                categories: ["Bon état", "Défaut naissant", "Défaut avéré", "Défaut urgent"]
                },
                series: [
                {
                    name: 'Machines',
                    colors: ['#0E4CEF75'],
                    data: []
                },
                ]
            },
            edited_campain: {
                id: null,
                name: '',
                presta:'',
                type: '',
                affaire_number:'',
                manager: '',
                id_park: null,
                machine_list:[],
                description: '',
                tags:[],                          
            },
            prestas:[
                {id:0, name:"Mesures vibratoires"},
                {id:1, name:"Thermographie"},
                {id:2, name:"Mesures ultrasons"},
                {id:3, name:"Equilibrages"},
                {id:4, name:"Expertise avancée"},
                {id:5, name:"Surveillance Online"},
            ],
            types:[
                {id:0, name:"Ponctuel"},
                {id:1, name:"Annuel"},
                {id:2, name:"Semestriel"},
                {id:3, name:"Quadrmestriel"}, 
                {id:4, name:"Trimestriel"},
                {id:5, name:"Continu"},
            ],
            tags:[
                {id:0, name:"Caractérisation machine"},
                {id:1, name:"Création BDD"},
                {id:2, name:"Etude approfondie"},
                {id:3, name:"Machines outils"},
                {id:4, name:"Machines tournantes"},
                {id:5, name:"Surveillance à distance"},
                {id:6, name:"Trés basse vitesse"},
                {id:7, name:"Historique"},
            ],
            timeProps: {
                format: "24hr"
            },
            textFieldProps: {
                prependInnerIcon: "mdi-calendar",
                solo: true,
                rules: [v => !!v || this.$t('vee-validation.required_date_operation')]
            },
        }
    },
    computed: {
        dialogTitle() {
            return this.edited_index === null ? this.$t('sites.add_site') : this.$t('sites.edit_site');
        },
        dialogTitleBuilding() {
            return this.building_edited_index === null ? this.$t('buildings.add_building') : this.$t('buildings.edit_building');
        }
    },
    created() {
        moment.locale('fr');
        this.initialize();
    },
    methods: {
        // COLLECTING ALL NEEDED DATAS
        initialize() {
            this.loading_datatable = true;
            this.getSites();

        },
        getSites() {
            this.axios.get(SITES + '/resume')
            
            .then((success) => {
                this.raw_sites = [];
                this.sites = [];

                if (this.checkDataAvailable(success.data)){
                    success.data.forEach(item => {
                        item.created_at = moment(item.created_at).format("DD/MM/YYYY");
                        item.buildings.forEach( building=> {
                            building.created_at = moment(building.created_at).format("DD/MM/YYYY");
                            if (building.trash != 1){
                                this.buildings_raw.push(building);
                            }
                        })
                    })

                    this.raw_sites = success.data;
                    this.sites = success.data;
                    this.sites_available = true;
                    this.sites_count = this.raw_sites.length;

                    if (this.id){
                        this.active_site = this.id;
                    }else{
                        this.active_site = this.sites[0].id;
                    }
                }else{
                    this.sites_available = false;
                    this.active_site = null;
                }

            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");

            }).finally(() => {
                this.getCampains();
                this.getMachines();
                this.getReports();
                this.loading_datatable = false;
            })
        },
        getCampains() {
            this.axios.get(CAMPAIN + '?trash=0&park='+ this.active_site )
            
            .then((success) => {
                this.filter_mode = 9;
                this.raw_campains = [];
                this.campain = [];

                if (this.checkDataAvailable(success.data)){  
                    success.data.forEach(item => {
                        item.created_at = moment(item.created_at).format("DD/MM/YYYY");
                        item.machine_list = JSON.parse(item.machine_list);
                    })

                    this.raw_campains = success.data;
                    this.campain_available = true;      
                    let flag = 0;

                    success.data.forEach(campain => {
                        if(campain.closed_at === null && campain.id_park === this.active_site && flag < 1){
                            this.campain = campain;
                            flag = flag + 1;
                        }
                    })
                }else{
                    this.campain_available = false; 
                }

            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");

            })
        },
        getMachines() {
            if (this.checkDataAvailable(this.raw_sites)){
                this.machines_count = 0;

                this.raw_sites.forEach(site => {
                    if (site.id === this.active_site){
                        this.machines = site.machines;
                    }
                    this.machines_count = this.machines_count + site.machines.length;
                })

                this.machines_available = true;
                let data_0 = 0;
                let data_1 = 0;
                let data_2 = 0;
                let data_3 = 0;

                this.machines.forEach(machine => {
                    if (machine.alert_level == 0){
                        data_0 = data_0 +1;
                    }
                    if (machine.alert_level == 1){
                        data_1 = data_1 +1;
                    }
                    if (machine.alert_level == 2){
                        data_2 = data_2 +1;
                    }
                    if (machine.alert_level == 3){
                        data_3 = data_3 +1;
                    }
                })

                this.chart.series[0].data = [data_0, data_1 ,data_2 ,data_3];

            }else{
                this.machines_available = false;
            }
        },
        getReports() {
            this.campains_count = 0;
            this.reports_count = 0;
            
            let condition = this.filterReports();

            if (condition){
                this.axios.get(REPORT + '?trash=0&machines=' + this.serialized_machine_list)
                
                .then((success) => {
                    this.raw_reports = [];
                    this.reports = [];

                    if (this.checkDataAvailable(success.data)){
                        this.raw_reports = success.data;
                        this.reports = success.data;
                        this.getCounts();
                        this.reports_available = true;
                    }else{
                        this.reports_available = false;
                    }

                }).catch(() => {
                    this.showSnackbar('error', "Reports datas error.");
                })
            }
        },
        getTeam(){
            this.axios.get(USERS + '/team')
            
            .then((success) => {
                this.team = [];
                this.team = success.data;

            }).catch(() => {
                this.showSnackbar('error', "Team datas error.");
            })
        },
        getCounts(){
            this.reports_count = this.reports.length;

            this.raw_campains.forEach(campain => {
                if(campain.id_park == this.active_site)
                this.campains_count = this.campains_count +1;
            })

            this.raw_reports.forEach(report => {
                if(report.released_at != null)
                this.available_reports_count = this.available_reports_count +1;
            })
        },

        // DISPLAY DATA MANAGEMENT
        checkDataAvailable(datas){
            if(typeof datas[0] === 'undefined') {                    
                return false;
            }else {
                return true;
            }
        },
        getSitesCount() {
            // Reset all counts
            this.data_filters.forEach(data => {data.count = 0});
            this.active_campains_count =0;
            this.inactive_campains_count =0;
            this.global_campains_count =0;
            
            // Get all last counts
            this.campain.forEach(item => {  
                if(item.closed_at === null){
                    this.active_campains_count = this.active_campains_count +1;
                }
                
                this.data_filters.forEach(filter => { 
                    if(item.state == filter.level){
                        filter.count = filter.count +1;
                    }
                });
            });

            this.inactive_campains_count = this.trashed_campains.length;
            this.global_campains_count = this.active_campains_count + this.inactive_campains_count;

            // FREEUP LOADING STEP
            this.loading_datatable = false
        },
        loadSite(id) {
            this.active_site = id;
            this.getCampains();
            this.getMachines();
            this.getReports();
        },
        filterReports(){
            if (this.checkDataAvailable(this.raw_sites)){
                this.machine_list = [];
                let machine_flag = 0;

                this.raw_sites.forEach(site => {
                    if (site.id === this.active_site){
                        site.machines.forEach(machine => {
                            this.machine_list.push(machine.id);
                            machine_flag = machine_flag+1;
                        })
                    }
                })
                if (this.machine_list !== [] && machine_flag > 0){
                    this.serialized_machine_list = this.machine_list.join('-');
                    return true;
                }else{
                    return false;
                }
            }else{
                return false;
            }
        },
        prepareEditSite() {
            if (this.edited_index !== null) {
                this.updateSite();
            } else {
                this.addSite();
            }
        },
        prepareEditBuilding() {
            if (this.building_edited_index !== null) {
                this.updateBuilding();
            } else {
                this.addBuilding();
            }
        },
        onImageChanged() {
            if(this.edited_subassembly.path_image !== null) {
                let imageCheck = this.uploadFilesCheck(this.edited_subassembly.path_image,['image/png','image/jpg','image/jpeg'],2);
                if(!imageCheck.authorized) {
                    this.valid_image = false;
                    this.showSnackbar('error',imageCheck.output.toString());
                }
                else {
                    this.valid_image = true;
                }
            }
            if(this.edited_subassembly.path_image === null){
                this.valid_image = true;
            }
        },

        // POST REQUESTS PART
        setInactive(id) {
            this.axios.patch(CAMPAIN + '/close/' + id)
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.getData();
                    this.dialog_show = false;
                })
        },
        setActive(id) {
            this.axios.patch(CAMPAIN + '/open/' + id)
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.getData();
                    this.dialog_show = false;
                })
        },
        pushTrashSite(id) {
            this.axios.patch(SITES + '/trash/' + id)
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.initialize();
                    this.dialog_show = false;
                })
        },
        pushBuildingTrash(id) {
            this.axios.patch(BUILDINGS + '/trash/' + id)
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.initialize();
                    this.dialog_show = false;
                })
        },
        updateSite() {
            this.axios.patch(SITES + '/' + this.edited_site.id,
                this.edited_site
            
            ).then((success) => {
                Object.assign(this.sites[this.edited_index], success.data);
                this.edited_index = null;
                this.showGlobalSnackbar('success', this.$t('global.success_to_edit'));
            
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('global.failure_to_update'));
            
            }).finally(() => {
                this.closeDialog();
                this.initialize();
            });
        },
        addSite() {
            let formData = new FormData();
            formData.append('label', this.edited_site.label);
            formData.append('address', this.edited_site.address);
            formData.append('note', this.edited_site.note);
            formData.append('latitude', this.edited_site.latitude);
            formData.append('longitude', this.edited_site.longitude);
            formData.append('plan', null);
            this.axios.post(
                SITES, formData, this.configurationFile
            ).then((success) => {
                //this.sites.push(success.data);
                this.showSnackbar('success', this.$t('global.success_to_add'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_add'));
            }).finally(() => {
                this.edited_site.longitude='';
                this.edited_site.latitude='';
                this.closeDialog();
                this.initialize();
            });
        },
        confirmDeleteSite() {
            this.axios.delete(
                SITES + '/' + this.delete_id
            ).then(() => {
                this.sites.splice(this.deleted_index, 1);
                this.showGlobalSnackbar('success', this.$t('global.success_to_delete'));
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('global.failure_to_delete'));
            }).finally(() => {
                this.dialog_delete = false;
                this.initialize();
            });
        },
        updateBuilding() {
            this.axios.patch(BUILDINGS + '/' + this.edited_building.id,
                this.edited_building
            
            ).then((success) => {
                //Object.assign(this.buildings[this.building_edited_index], success.data);
                this.building_edited_index = null;
                this.showSnackbar('success', this.$t('global.success_to_edit'));
            
            }).catch(() => {
                this.showSnackbar('error', "Building update error.");
            
            }).finally(() => {
                this.closeDialogBuildings();
                this.initialize();
            });
        },
        addBuilding() {
            let formData = new FormData()
            formData.append('label', this.edited_building.label);
            formData.append('note', this.edited_building.note);
            formData.append('plan', this.edited_building.path_plan);
            formData.append('site_id', this.active_site);

            this.axios.post(
                BUILDINGS, formData, this.configurationFile

            ).then((success) => {
                //this.buildings.push(success.data);
                this.showSnackbar('success', this.$t('global.success_to_add'));

            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_add'));
            }).finally(() => {
                this.closeDialogBuildings();
                this.initialize();
            });
        },
        confirmDeleteBuilding() {
            this.axios.delete(
                BUILDINGS + '/' + this.delete_id
            ).then(() => {
                this.buildings.splice(this.deleted_index, 1);
                this.showSnackbar('success', this.$t('global.success_to_delete'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_delete'));
            }).finally(() => {
                this.dialog_delete = false;
                this.initialize();
            });
        },

        // MODALS
        //Dialogs sites
        openDialogAddSite() {
            if (this.edited_index !== null) {
                this.edited_index = null;
                this.$refs.observer.reset();
                this.$refs.form.reset();
                //this.resetEditedSite();
            }
            this.dialog_edit = true;
        },
        openDialogUpdateSite(site) {
            this.edited_index = this.sites.indexOf(site);
            this.edited_site = Object.assign({}, site);
            this.dialog_edit = true;
        },
        openDialogDeleteSite(site) {
            this.deleted_index = this.sites.indexOf(site);
            this.delete_id = site.id;
            this.dialog_delete = true;
        },

        //Dialogs buildings
        openDialogAddBuilding() {
            if (this.building_edited_index !== null) {
                this.building_edited_index = null;
                this.$refs.buildingObserver.reset();
                this.$refs.buildingForm.reset();
                //this.resetEditedBuilding();
            }
            this.building_dialog_edit = true;
        },
        openDialogUpdateBuilding(building) {
            this.building_edited_index = 0;
            this.edited_building = Object.assign({}, building);
            this.building_dialog_edit = true;
        },
        openDialogDeleteBuilding(building) {
            //this.building__index = 0;
            this.building_delete_id = building.id;
            this.building_dialog_delete = true;
        },

        //Global dialogs
        openDialogShow() {
            console.log("open dialog show requested");
        },
        closeDialog() {
            this.$refs.observer.reset();
            this.$refs.form.reset();
            this.dialog_edit = false;
            this.dialog_delete = false;
        },
        closeDialogBuildings() {
            this.$refs.observer.reset();
            this.$refs.buildingForm.reset();
            this.building_dialog_delete = false;
            this.building_dialog_edit = false;
        },
        dialogNextUpdate() {
            if (this.dialog_next_update){
                this.dialog_next_update = false;
            }else{
                this.dialog_next_update = true;
            }
        },
        resetEditedSite(){
            this.edited_site = {
                id: null,
                label: '',
                address: '',
                note: '',
                latitude: '',
                longitude: '',
                path_plan: null
            }
        },
        resetEditedBuilding(){
            this.edited_building = {
                id: null,
                label: '',
                note: '',
                site_id: '',
                path_plan: null
            }
        },
        closeNewUpdateDialog(){
            this.setCookieVolet();
            this.dialog_new_update = false;
        }
    }
}
</script>