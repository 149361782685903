<template>
    <v-row class="row mt-5">
        <!-- content -->
        <v-col color="white" class="ml-0 mr-4 pl-2 col">
            <!-- TOP DATA BAR -->
            <v-col class="row col-12 mb-0 pa-0">
                <!-- COUNTER -->
                <v-col color="white" class="col-2 pa-0 mt-5">
                    <v-col class="col pt-0 pb-0 mr-10 rounded col ml-6" style="height: 35px;background-color: rgb(224 232 240);font-weight: bold;text-align: center;font-size: 16px;border: 1px solid #014181;line-height: 35px;color: #014181;">
                        {{ active_campains_count }} campagne(s) en cours.
                    </v-col>
                </v-col>

                <!-- GLOBAL ACTIONS -->
                <v-col class="row col ml-6 pt-8">
                    <v-tooltip v-if="access_level <= 100" bottom dark class="mr-2">
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" @click="openDialogAddCampain()" class="mr-2" dusk="logout-button" style="border: 1px solid #01418145;">
                                <v-icon color="primary">mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('campain.add_campain') }}</span>
                    </v-tooltip>
                    <v-tooltip bottom dark class="mr-2">
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" @click="getData()" class="mr-2" dusk="logout-button" style="border: 1px solid #01418145;">
                                <v-icon color="primary">mdi-tag-multiple</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('global.1_5_2') }}</span>
                    </v-tooltip>
                    <v-tooltip bottom dark class="mr-2">
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" @click="getData()" class="mr-2" dusk="logout-button" style="border: 1px solid #01418145;">
                                <v-icon color="primary">mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('campain.refresh') }}</span>
                    </v-tooltip>
                </v-col>

                <!-- SPACER -->
                <v-col class="row col"></v-col> 

                <!-- SEARCH -->       
                <v-col @click="dialogNextUpdate()" class="col-4 pa-0">
                    <v-text-field 
                        v-model="search"
                        append-icon="mdi-magnify"
                        :label="$t('global.search')"
                        class="mt-1"
                        disabled
                    ></v-text-field>
                </v-col>
            </v-col>
            
            <!-- GLOBAL FILTERS -->
            <v-col class="row col mb-5 mt-0">
                <div
                    v-for="filters in data_filters"
                    :key="filters.level"
                    class="mr-0"
                >
                    <div
                    class="ma-auto col-10 pl-3 pr-15 v-chip v-chip--clickable v-chip--link v-chip--no-color theme--light v-size--default"
                    style="
                        color: black;
                        font-weight: 400;
                        display: flex;
                        justify-content: left;
                        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.29);
                        background-color: #d7d7d752;
                        font-size: 14px;
                        height: 40px;
                    "
                    @click="setDataFiltered(filters.level)"
                    >
                    <div
                        v-if="filters.level_name !== 'Tout voir'"
                        class="mr-3"
                        style="color: white;min-width: 30px; min-height: 20px; border-radius:20px;font-weight: bold;text-align: center;"
                        :style="{backgroundColor: filters.level_color}"
                    >{{filters.count}}</div>
                    {{filters.level_name}}
                    </div>
                </div>
            </v-col> 
            
            <div v-if="loading_datatable == true" style="color:grey;text-align: center;">
                <li class="rounded row notification_card mb-1 pa-10 mt-15 ml-6" style="color: grey;justify-content: center;">
                    <div style="color:grey;text-align: center;"><strong>Chargement en cours...</strong></div>
                </li>
            </div>          
            
            <!-- ALL DATATABLE -->
            <div v-if="loading_datatable == false" flat color="white" class="pt-0 mb-3">
                <!-- DATA LIST -->
                <template>
                    <ul id="notifications-cards">
                        <!-- ACTIVE DATA (on non trashed data and non closed data)-->
                        <li class="row" v-for="campain in campains" :key="campain" >
                            <div v-if="campain && campain !== 'undefined' && campain !== null && typeof campain === 'object' && campain.closed_at === null" class="rounded row notification_card mb-0 elevation-1 ml-0 mr-0 mt-3" style="border: solid 1px #80808021;background-color: #ffffff;">
                                <div class="row pa-3">
                                    <!--CHIPS-->
                                    <div v-for="filters in data_filters" :key="filters">
                                        <div  v-if="filters.level == campain.state && filters.level_name !== 'Tout voir'" :to="{ path: '/campain/' + campain.id}" class="col-0 mr-5" style="color:white;font-weight:800;display:flex;justify-content:center;text-shadow:2px 2px 4px rgba(0, 0, 0, 0.29); height:100%;min-width: 8px;border-radius:2px 0px 0px 2px;" :style="{backgroundColor: filters.level_color}"></div>
                                    </div>   
                                    
                                    <!--INFOS-->
                                    <div class="col-3">
                                        <v-chip class="mb-5 mt-5" v-on="on" link :to="'/campain/' + campain.id" style="font-weight: bold; background-color: #f3f3f3; border: 1px solid #e5e5e5;">{{campain.name}}</v-chip>
                                        <div style="font-size: 12px;margin-left: 10px;">Ref - {{campain.uid}}</div>
                                        <div style="font-size: 12px;margin-left: 10px;">Créée le : {{campain.created_at}}</div>
                                        <div v-for="tviewer in team" :key="tviewer">
                                            <div v-if="tviewer.id === campain.creator" style="font-size: 12px;margin-left: 10px;">Créé par : {{tviewer.first_name}} {{tviewer.last_name}}</div>
                                        </div>
                                        <div v-for="analyst in analysts" :key="analyst">
                                            <div v-if="analyst.id === campain.manager" style="font-size: 12px;margin-left: 10px;margin-top:15px;font-weight: bold;">Chargé de campagne : {{analyst.first_name}} {{analyst.last_name}}</div>
                                        </div>
                                        <div style="font-size: 12px;margin-left: 10px;">N° de dossier : {{campain.affaire_number}}</div>
                                        <div v-for="site in sites" :key="site">
                                            <div v-if="site.id === campain.id_park" style="font-size: 12px;margin-left: 10px;">Parc machine associé : {{site.label}}</div>
                                        </div>
                                        <v-chip v-if="campain.step === 43 && access_level <= 100" class="mb-5 mt-10 pointer pl-2 pr-5" @click="setClosed(campain.id)" style="font-weight: bold; background-color: #089d2d1c; border: 1px solid #089d2d;color:#089d2d;">
                                            <v-icon class="mr-2" style="font-size: 18px;color:#089d2d;">mdi-lock-open</v-icon>
                                            Clôturer la campagne
                                        </v-chip>
                                    </div>

                                    <!-- UPDATES -->
                                    <div  v-if="campain.presta !== 5" class="col pl-5 pr-5 pb-0" style="border-left: 1px solid #d3d3d36e; font-size:12px;background-color: whitesmoke;">
                                        <v-col class="col col pa-0 pt-0 pb-1 ma-0 ml-0 mt-2" style="text-align: center;">
                                            <div v-for="presta in prestas" :key="presta">
                                                <div v-for="typex in types" :key="typex">
                                                    <v-chip v-if="presta.id === campain.presta && typex.id === campain.type" class="ml-0 mb-0">{{ presta.name }} ({{ typex.name.toLowerCase().trim() }})</v-chip>
                                                </div>
                                            </div>
                                        </v-col>
                                        <div class="row row col pa-10 pt-5 pb-5 ma-0 ml-0 mt-1 mb-5" style="
                                            align-items: center;
                                            justify-content: center;
                                            text-align: center;
                                            background-color: whitesmoke;
                                            border-radius: 100px;
                                        ">
                                            <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                <!-- CUSTOMER VIEW -->
                                                <v-icon v-if="campain.step <= 11 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 12 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 13 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step > 13 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                                <!-- ADMIN VIEW -->
                                                <v-icon v-if="campain.step === 11 && access_level <= 100" @click="updateStep(campain.id,12)" class="pointer" color="orange" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 12 && access_level <= 100" @click="updateStep(campain.id,13)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 13 && access_level <= 100" @click="updateStep(campain.id,11)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step > 13 && access_level <= 100" @click="updateStep(campain.id,11)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                            </p>
                                            <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                <!-- CUSTOMER VIEW -->
                                                <v-icon v-if="campain.step >= 13 && campain.step <= 21 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 22 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 23 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step < 13 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step > 23 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                                <!-- ADMIN VIEW -->
                                                <v-icon v-if="campain.step >= 13 && campain.step <= 21 && access_level <= 100" @click="updateStep(campain.id,22)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 22 && access_level <= 100" @click="updateStep(campain.id,23)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 23 && access_level <= 100" @click="updateStep(campain.id,21)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step < 13 && access_level <= 100" @click="updateStep(campain.id,21)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step > 23 && access_level <= 100" @click="updateStep(campain.id,21)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                            </p>
                                            <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                <!-- CUSTOMER VIEW -->
                                                <v-icon v-if="campain.step >= 23 && campain.step <= 31 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 32 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 33 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step < 23 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step > 33 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                                <!-- ADMIN VIEW -->
                                                <v-icon v-if="campain.step >= 23 && campain.step <= 31 && access_level <= 100" @click="updateStep(campain.id,32)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 32 && access_level <= 100" @click="updateStep(campain.id,33)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 33 && access_level <= 100" @click="updateStep(campain.id,31)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step < 23 && access_level <= 100" @click="updateStep(campain.id,31)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step > 33 && access_level <= 100" @click="updateStep(campain.id,31)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                            </p>
                                            <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                <!-- CUSTOMER VIEW -->
                                                <v-icon v-if="campain.step >= 33 && campain.step <= 41 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 42 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 43 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step < 33 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                <!-- ADMIN VIEW -->
                                                <v-icon v-if="campain.step >= 33 && campain.step <= 41 && access_level <= 100" @click="updateStep(campain.id,42)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 42 && access_level <= 100" @click="updateStep(campain.id,43)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 43 && access_level <= 100" @click="updateStep(campain.id,41)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step < 33 && access_level <= 100" @click="updateStep(campain.id,41)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            </p>
                                        </div>
                                        <div class="col" style="
                                            position: relative;
                                            border: 1px solid #656565;
                                            top: -58px;
                                            width: 70%;
                                            left: 15%;
                                            border-radius: 15px;
                                            height: 2px;
                                            padding: 0px;
                                        "></div>
                                        <div class="row row col pa-10 pt-0 pb-0 ma-0 ml-0" style="
                                            align-items: center;
                                            justify-content: center;
                                            text-align: center;
                                            position: relative;
                                            top: -57px;
                                        ">
                                            <p class="ml-0 mb-0 col" v-if="campain.step === 11 || campain.step === 12 || campain.step === 13" style="z-index: 10;font-weight: bold;">Préparation</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step != 11 && campain.step != 12 && campain.step != 13" style="z-index: 10;">Préparation</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step === 21 || campain.step === 22 || campain.step === 23" style="z-index: 10;font-weight: bold;">Intervention</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step != 21 && campain.step != 22 && campain.step != 23" style="z-index: 10;">Intervention</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step === 31 || campain.step === 32 || campain.step === 33" style="z-index: 10;font-weight: bold;">Analyses</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step != 31 && campain.step != 32 && campain.step != 33" style="z-index: 10;">Analyses</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step === 41 || campain.step === 42 || campain.step === 43" style="z-index: 10;font-weight: bold;">Rapports</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step != 41 && campain.step != 42 && campain.step != 43" style="z-index: 10;">Rapports</p>
                                        </div>
                                        <v-row class="row pl-10 pr-10" style="position: relative; top:-35px;">
                                            <v-col class="row col pa-0 pt-0 pb-1 ma-0 ml-10 mr-10" style="text-align: center;">
                                                <div style="font-weight: bold;font-size: 13px;" class="mb-2 col col">
                                                    <div>Equipements concernés : </div> 
                                                    <v-chip class="ml-0 mb-0">{{ campain.machine_list.length }} / {{ machines_count }}</v-chip>
                                                </div>
                                                <div style="font-weight: bold;font-size: 13px;" class="mb-2 col col">
                                                    <div>Rapports disponibles: </div>
                                                    <v-chip class="ml-0 mb-0">{{ campain.machine_list.length }} / {{ campain.machine_list.length }}</v-chip>
                                                </div> 
                                            </v-col>
                                        </v-row>                          
                                    </div>

                                    <div  v-if="campain.presta === 5" class="col pl-5 pr-5 pb-0" style="border-left: 1px solid #d3d3d36e; font-size:12px;background-color: whitesmoke;">
                                        <v-col class="col col pa-0 pt-0 pb-1 ma-0 ml-0 mt-2" style="text-align: center;">
                                            <div v-for="presta in prestas" :key="presta">
                                                <div v-for="typex in types" :key="typex">
                                                    <v-chip v-if="presta.id === campain.presta && typex.id === campain.type" class="ml-0 mb-0">{{ presta.name }} ({{ typex.name.toLowerCase().trim() }})</v-chip>
                                                </div>
                                            </div>
                                        </v-col>
                                        <div class="row row col pa-10 pt-5 pb-5 ma-0 ml-0 mt-1 mb-5" style="
                                            align-items: center;
                                            justify-content: center;
                                            text-align: center;
                                            background-color: whitesmoke;
                                            border-radius: 100px;
                                        ">
                                            <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                <!-- CUSTOMER VIEW -->
                                                <v-icon v-if="campain.step <= 11 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 12 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 13 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step > 13 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                                <!-- ADMIN VIEW -->
                                                <v-icon v-if="campain.step === 11 && access_level <= 100" @click="updateStep(campain.id,12)" class="pointer" color="orange" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 12 && access_level <= 100" @click="updateStep(campain.id,13)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 13 && access_level <= 100" @click="updateStep(campain.id,11)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step > 13 && access_level <= 100" @click="updateStep(campain.id,11)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                            </p>
                                            <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                <!-- CUSTOMER VIEW -->
                                                <v-icon v-if="campain.step >= 13 && campain.step <= 21 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 22 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 23 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step < 13 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step > 23 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                                <!-- ADMIN VIEW -->
                                                <v-icon v-if="campain.step >= 13 && campain.step <= 21 && access_level <= 100" @click="updateStep(campain.id,22)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 22 && access_level <= 100" @click="updateStep(campain.id,23)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 23 && access_level <= 100" @click="updateStep(campain.id,21)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step < 13 && access_level <= 100" @click="updateStep(campain.id,21)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step > 23 && access_level <= 100" @click="updateStep(campain.id,21)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                            </p>
                                            <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                <!-- CUSTOMER VIEW -->
                                                <v-icon v-if="campain.step >= 23 && campain.step <= 31 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 32 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 33 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step < 23 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step > 33 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                                <!-- ADMIN VIEW -->
                                                <v-icon v-if="campain.step >= 23 && campain.step <= 31 && access_level <= 100" @click="updateStep(campain.id,32)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 32 && access_level <= 100" @click="updateStep(campain.id,33)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 33 && access_level <= 100" @click="updateStep(campain.id,31)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step < 23 && access_level <= 100" @click="updateStep(campain.id,31)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step > 33 && access_level <= 100" @click="updateStep(campain.id,31)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                            </p>
                                            <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                <!-- CUSTOMER VIEW -->
                                                <v-icon v-if="campain.step >= 33 && campain.step <= 41 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 42 && access_level > 100" color="flashGreen" style="font-size: 36px;">mdi-play-network</v-icon>
                                                <v-icon v-if="campain.step === 43 && access_level > 100" color="red" style="font-size: 36px;">mdi-close-network-outline</v-icon>
                                                <v-icon v-if="campain.step < 33 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                <!-- ADMIN VIEW -->
                                                <v-icon v-if="campain.step >= 33 && campain.step <= 41 && access_level <= 100" @click="updateStep(campain.id,42)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 42 && access_level <= 100" @click="updateStep(campain.id,43)" class="pointer" color="flashGreen" style="font-size: 36px;">mdi-play-network</v-icon>
                                                <v-icon v-if="campain.step === 43 && access_level <= 100" @click="updateStep(campain.id,41)" class="pointer" color="red" style="font-size: 36px;">mdi-close-network-outline</v-icon>
                                                <v-icon v-if="campain.step < 33 && access_level <= 100" @click="updateStep(campain.id,41)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            </p>
                                        </div>
                                        <div v-if="campain.step === 42" class="col" style="
                                            position: relative;
                                            border: 1px solid #00db37;
                                            top: -58px;
                                            width: 70%;
                                            left: 15%;
                                            border-radius: 15px;
                                            height: 2px;
                                            padding: 0px;
                                        "></div>
                                        <div v-if="campain.step === 43" class="col" style="
                                            position: relative;
                                            border: 1px solid #d70000;
                                            top: -58px;
                                            width: 70%;
                                            left: 15%;
                                            border-radius: 15px;
                                            height: 2px;
                                            padding: 0px;
                                        "></div>
                                        <div v-if="campain.step < 42" class="col" style="
                                            position: relative;
                                            border: 1px solid #656565;
                                            top: -58px;
                                            width: 70%;
                                            left: 15%;
                                            border-radius: 15px;
                                            height: 2px;
                                            padding: 0px;
                                        "></div>
                                        <div class="row row col pa-10 pt-0 pb-0 ma-0 ml-0" style="
                                            align-items: center;
                                            justify-content: center;
                                            text-align: center;
                                            position: relative;
                                            top: -57px;
                                        ">
                                            <p class="ml-0 mb-0 col" v-if="campain.step === 11 || campain.step === 12 || campain.step === 13" style="z-index: 10;font-weight: bold;">Préparation</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step != 11 && campain.step != 12 && campain.step != 13" style="z-index: 10;">Préparation</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step === 21 || campain.step === 22 || campain.step === 23" style="z-index: 10;font-weight: bold;">Instrumentation</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step != 21 && campain.step != 22 && campain.step != 23" style="z-index: 10;">Instrumentation</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step === 31 || campain.step === 32 || campain.step === 33" style="z-index: 10;font-weight: bold;">Configuration</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step != 31 && campain.step != 32 && campain.step != 33" style="z-index: 10;">Configuration</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step === 41 || campain.step === 42 || campain.step === 43" style="z-index: 10;font-weight: bold;">Surveillance</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step != 41 && campain.step != 42 && campain.step != 43" style="z-index: 10;">Surveillance</p>
                                        </div>
                                        <v-row class="row pl-10 pr-10" style="position: relative; top:-35px;">
                                            <v-col class="row col pa-0 pt-0 pb-1 ma-0 ml-10 mr-10" style="text-align: center;">
                                                <div style="font-weight: bold;font-size: 13px;" class="mb-2 col col">
                                                    <div>Equipements concernés : </div> 
                                                    <v-chip class="ml-0 mb-0">{{ campain.machine_list.length }} / {{ machines_count }}</v-chip>
                                                </div>
                                                <div style="font-weight: bold;font-size: 13px;" class="mb-2 col col">
                                                    <div>Rapports disponibles: </div>
                                                    <v-chip class="ml-0 mb-0">{{ campain.machine_list.length }} / {{ campain.machine_list.length }}</v-chip>
                                                </div> 
                                            </v-col>
                                        </v-row>                          
                                    </div>

                                    <!--MORE INFOS -->
                                    <div :to="{ path: '/campain/' + campain.id}" class="col-3 pl-5" style="border-left: 1px solid #d3d3d36e; font-size:12px;">
                                        <div style="font-weight: bold;font-size: 13px;">Informations complémentaires :</div> 
                                        <v-row class="row col pa-0 pt-0 pb-1 ma-0 ml-0">
                                            <p class="ml-0 mb-0">{{ campain.description }}</p>
                                        </v-row> 
                                        <v-row class="mt-5">
                                            <v-chip v-for="tag in getCampainTags(campain)" class="pl-3 pr-3 ma-2" link :key="tag">
                                                {{ tag }}
                                            </v-chip>
                                        </v-row>                              
                                    </div>

                                    <!-- ACTIONS -->
                                    <v-col class="col-1 pa-2" style="max-width: 80px;">
                                        <v-tooltip v-if="!campain.trash && access_level <= 99" bottom dark>
                                            <template v-slot:activator="{ on }">
                                                <v-btn class="ma-auto mr-5 ml-5 col-0" text icon v-on="on" link :to="'/campain/' + campain.id" dusk="logout-button">
                                                    <v-icon color="primary">mdi-dots-horizontal-circle</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Voir la campagne en détail</span>
                                        </v-tooltip>
                                        <v-tooltip v-if="!campain.trash && access_level <= 99 " bottom dark>
                                            <template v-slot:activator="{ on }">
                                                <v-btn class="ma-auto mr-5 ml-5 col-0" text icon v-on="on" @click="openDialogUpdateCampain(campain)" dusk="logout-button">
                                                    <v-icon color="primary">mdi-circle-edit-outline</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{ $t('campain.edit_campain') }}</span>
                                        </v-tooltip>
                                        <v-tooltip v-if="!campain.trash && access_level <= 99" bottom dark>
                                            <template v-slot:activator="{ on }">
                                                <v-btn class="ma-auto mr-5 ml-5 col-0 mt-2" text icon v-on="on" @click="setDataTrash(campain,1)" dusk="logout-button">
                                                    <v-icon color="red">mdi-delete-alert</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{ $t('campain.desactivate_campain') }}</span>
                                        </v-tooltip>
                                    </v-col>
                                </div>
                            </div>
                        </li>

                        <!-- CLOSED DATA (on non trashed data and closed data)-->
                        <li class="row" v-for="campain in campains" :key="campain">                          
                            <div v-if="campain && campain !== 'undefined' && campain !== null && typeof campain === 'object' && campain.closed_at != null" class="rounded row notification_card mb-0 elevation-1 ml-0 mr-0 mt-3" style="border: solid 1px #80808021;background-color: #ffffff;">
                                <div class="row pa-3" >
                                    <!--CHIPS-->
                                    <div v-for="filters in data_filters" :key="filters" style="background-color: whitesmoke;">
                                        <div  v-if="filters.level == campain.state && filters.level_name !== 'Tout voir'" :to="{ path: '/campain/' + campain.id}" class="col-0 mr-5" style="color:white;font-weight:800;display:flex;justify-content:center;text-shadow:2px 2px 4px rgba(0, 0, 0, 0.29); height:100%;min-width: 8px;border-radius:2px 0px 0px 2px;background-color: #565656;"></div>
                                    </div>   
                                    
                                    <!--INFOS-->
                                    <div class="col-3" style="background-color: whitesmoke;">
                                        <v-chip class="mb-5 mt-5" v-on="on" link :to="'/campain/' + campain.id" style="font-weight: bold; background-color: #f3f3f3; border: 1px solid #e5e5e5;">Clôturée : {{campain.name}}</v-chip>
                                        <div style="font-size: 12px;margin-left: 10px;">Ref - {{campain.uid}}</div>
                                        <div style="font-size: 12px;margin-left: 10px;">Créée le : {{campain.created_at}}</div>
                                        <div v-for="tviewer in team" :key="tviewer">
                                            <div v-if="tviewer.id === campain.creator" style="font-size: 12px;margin-left: 10px;">Créé par : {{tviewer.first_name}} {{tviewer.last_name}}</div>
                                        </div>
                                        <div v-for="analyst in analysts" :key="analyst">
                                            <div v-if="analyst.id === campain.manager" style="font-size: 12px;margin-left: 10px;margin-top:15px;font-weight: bold;">Chargé de campagne : {{analyst.first_name}} {{analyst.last_name}}</div>
                                        </div>
                                        <div style="font-size: 12px;margin-left: 10px;">N° de dossier : {{campain.affaire_number}}</div>
                                        <div v-for="site in sites" :key="site">
                                            <div v-if="site.id === campain.id_park" style="font-size: 12px;margin-left: 10px;">Parc machine associé : {{site.label}}</div>
                                        </div>
                                        <v-chip v-if="campain.step === 43  && access_level <= 100" class="mb-5 mt-10 pointer pl-2 pr-5" @click="setOpened(campain.id)" style="font-weight: bold; background-color: #d700001c; border: 1px solid #d70000;color:#d70000;">
                                            <v-icon class="mr-2" style="font-size: 18px;color:#d70000;">mdi-lock</v-icon>
                                            Ouvrir la campagne
                                        </v-chip>
                                    </div>

                                    <!-- UPDATES -->
                                    <div  v-if="campain.presta !== 5" class="col pl-5 pr-5 pb-0" style="border-left: 1px solid #d3d3d36e; font-size:12px;background-color: whitesmoke;">
                                        <v-col class="col col pa-0 pt-0 pb-1 ma-0 ml-0" style="text-align: center;">
                                            <div v-for="presta in prestas" :key="presta">
                                                <div v-for="typex in types" :key="typex">
                                                    <v-chip v-if="presta.id === campain.presta && typex.id === campain.type" class="ml-0 mb-0">{{ presta.name }} ({{ typex.name.toLowerCase().trim() }})</v-chip>
                                                </div>
                                            </div>
                                        </v-col>
                                        <div class="row row col pa-10 pt-5 pb-5 ma-0 ml-0 mt-1 mb-5" style="
                                            align-items: center;
                                            justify-content: center;
                                            text-align: center;
                                            background-color: whitesmoke;
                                            border-radius: 100px;
                                        ">
                                            <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                <!-- CUSTOMER VIEW -->
                                                <v-icon v-if="campain.step <= 11" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 12" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 13 " style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step > 13" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                            </p>
                                            <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                <!-- CUSTOMER VIEW -->
                                                <v-icon v-if="campain.step === 21" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 22" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 23" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step < 21"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step > 23" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>                                            </p>
                                            <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                <!-- CUSTOMER VIEW -->
                                                <v-icon v-if="campain.step === 31" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 32" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 33" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step < 31"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step > 33" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                            </p>
                                            <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                <!-- CUSTOMER VIEW -->
                                                <v-icon v-if="campain.step === 41" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 42" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 43" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step < 41"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            </p>
                                        </div>
                                        <div class="col" style="
                                            position: relative;
                                            border: 1px solid #656565;
                                            top: -58px;
                                            width: 70%;
                                            left: 15%;
                                            border-radius: 15px;
                                            height: 2px;
                                            padding: 0px;
                                        "></div>
                                        <div class="row row col pa-10 pt-0 pb-0 ma-0 ml-0" style="
                                            align-items: center;
                                            justify-content: center;
                                            text-align: center;
                                            position: relative;
                                            top: -57px;
                                        ">
                                            <p class="ml-0 mb-0 col" v-if="campain.step === 11 || campain.step === 12 || campain.step === 13" style="z-index: 10;font-weight: bold;">Préparation</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step != 11 && campain.step != 12 && campain.step != 13" style="z-index: 10;">Préparation</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step === 21 || campain.step === 22 || campain.step === 23" style="z-index: 10;font-weight: bold;">Intervention</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step != 21 && campain.step != 22 && campain.step != 23" style="z-index: 10;">Intervention</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step === 31 || campain.step === 32 || campain.step === 33" style="z-index: 10;font-weight: bold;">Analyses</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step != 31 && campain.step != 32 && campain.step != 33" style="z-index: 10;">Analyses</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step === 41 || campain.step === 42 || campain.step === 43" style="z-index: 10;font-weight: bold;">Rapports</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step != 41 && campain.step != 42 && campain.step != 43" style="z-index: 10;">Rapports</p>
                                        </div>
                                        <v-row class="row pl-10 pr-10" style="position: relative; top:-35px;">
                                            <v-col class="row col pa-0 pt-0 pb-1 ma-0 ml-10 mr-10" style="text-align: center;">
                                                <div style="font-weight: bold;font-size: 13px;" class="mb-2 col col">
                                                    <div>Equipements concernés : </div> 
                                                    <v-chip class="ml-0 mb-0">{{ campain.machine_list.length }} / {{ machines_count }}</v-chip>
                                                </div>
                                                <div style="font-weight: bold;font-size: 13px;" class="mb-2 col col">
                                                    <div>Rapports disponibles: </div>
                                                    <v-chip class="ml-0 mb-0">{{ campain.machine_list.length }} / {{ campain.machine_list.length }}</v-chip>
                                                </div> 
                                            </v-col>
                                        </v-row>                          
                                    </div>

                                    <div  v-if="campain.presta === 5" class="col pl-5 pr-5" style="border-left: 1px solid #d3d3d36e; font-size:12px;background-color: whitesmoke;">
                                        <v-col class="col col pa-0 pt-0 pb-1 ma-0 ml-0" style="text-align: center;">
                                            <div v-for="presta in prestas" :key="presta">
                                                <div v-for="typex in types" :key="typex">
                                                    <v-chip v-if="presta.id === campain.presta && typex.id === campain.type" class="ml-0 mb-0">{{ presta.name }} {{ typex.name }}</v-chip>
                                                </div>
                                            </div>
                                        </v-col>
                                        <div class="row row col pa-10 pt-5 pb-5 ma-0 ml-0 mt-1 mb-5" style="
                                            align-items: center;
                                            justify-content: center;
                                            text-align: center;
                                            background-color: whitesmoke;
                                            border-radius: 100px;
                                        ">
                                            <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                <!-- CUSTOMER VIEW -->
                                                <v-icon v-if="campain.step <= 11 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 12 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 13 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step > 13 && access_level > 100" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                                </p>
                                            <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                <!-- CUSTOMER VIEW -->
                                                <v-icon v-if="campain.step === 21 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 22 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 23 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step < 21 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step > 23 && access_level > 100" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                            </p>
                                            <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                <!-- CUSTOMER VIEW -->
                                                <v-icon v-if="campain.step === 31 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 32 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 33 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step < 31 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step > 33 && access_level > 100" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                            </p>
                                            <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                <!-- CUSTOMER VIEW -->
                                                <v-icon v-if="campain.step === 41 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 42 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-play-network</v-icon>
                                                <v-icon v-if="campain.step === 43 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-close-network-outline</v-icon>
                                                <v-icon v-if="campain.step < 41 && access_level > 100" style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            </p>
                                        </div>
                                        <div v-if="campain.step === 42" class="col" style="
                                            position: relative;
                                            border: 1px solid #00db37;
                                            top: -58px;
                                            width: 70%;
                                            left: 15%;
                                            border-radius: 15px;
                                            height: 2px;
                                            padding: 0px;
                                        "></div>
                                        <div v-if="campain.step === 43" class="col" style="
                                            position: relative;
                                            border: 1px solid #d70000;
                                            top: -58px;
                                            width: 70%;
                                            left: 15%;
                                            border-radius: 15px;
                                            height: 2px;
                                            padding: 0px;
                                        "></div>
                                        <div v-if="campain.step < 42" class="col" style="
                                            position: relative;
                                            border: 1px solid #656565;
                                            top: -58px;
                                            width: 70%;
                                            left: 15%;
                                            border-radius: 15px;
                                            height: 2px;
                                            padding: 0px;
                                        "></div>
                                        <div class="row row col pa-10 pt-0 pb-0 ma-0 ml-0" style="
                                            align-items: center;
                                            justify-content: center;
                                            text-align: center;
                                            position: relative;
                                            top: -57px;
                                        ">
                                            <p class="ml-0 mb-0 col" v-if="campain.step === 11 || campain.step === 12 || campain.step === 13" style="z-index: 10;font-weight: bold;">Préparation</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step != 11 && campain.step != 12 && campain.step != 13" style="z-index: 10;">Préparation</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step === 21 || campain.step === 22 || campain.step === 23" style="z-index: 10;font-weight: bold;">Instrumentation</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step != 21 && campain.step != 22 && campain.step != 23" style="z-index: 10;">Instrumentation</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step === 31 || campain.step === 32 || campain.step === 33" style="z-index: 10;font-weight: bold;">Configuration</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step != 31 && campain.step != 32 && campain.step != 33" style="z-index: 10;">Configuration</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step === 41 || campain.step === 42 || campain.step === 43" style="z-index: 10;font-weight: bold;">Surveillance</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step != 41 && campain.step != 42 && campain.step != 43" style="z-index: 10;">Surveillance</p>
                                        </div>
                                        <v-row class="row pl-10 pr-10" style="position: relative; top:-35px;">
                                            <v-col class="row col pa-0 pt-0 pb-1 ma-0 ml-10 mr-10" style="text-align: center;">
                                                <div style="font-weight: bold;font-size: 13px;" class="mb-2 col col">
                                                    <div>Equipements concernés : </div> 
                                                    <v-chip class="ml-0 mb-0">{{ campain.machine_list.length }} / {{ machines_count }}</v-chip>
                                                </div>
                                                <div style="font-weight: bold;font-size: 13px;" class="mb-2 col col">
                                                    <div>Rapports disponibles: </div>
                                                    <v-chip class="ml-0 mb-0">{{ campain.machine_list.length }} / {{ campain.machine_list.length }}</v-chip>
                                                </div> 
                                            </v-col>
                                        </v-row>                          
                                    </div>

                                    <!--MORE INFOS -->
                                    <div :to="{ path: '/campain/' + campain.id}" class="col-3 pl-5" style="border-left: 1px solid #d3d3d36e; font-size:12px;background-color: whitesmoke;">
                                        <div style="font-weight: bold;font-size: 13px;">Informations complémentaires :</div> 
                                        <v-row class="row col pa-0 pt-0 pb-1 ma-0 ml-0">
                                            <p class="ml-0 mb-0">{{ campain.description }}</p>
                                        </v-row> 
                                        <v-row class="mt-5">
                                            <v-chip v-for="tag in getCampainTags(campain)" class="pl-3 pr-3 ma-2" link :key="tag">
                                                {{ tag }}
                                            </v-chip>
                                        </v-row>                              
                                    </div>

                                    <!-- ACTIONS -->
                                    <v-col class="col-1 pa-2" style="max-width: 80px;background-color: whitesmoke;">
                                        <v-tooltip v-if="!campain.trash && access_level <= 100 " bottom dark>
                                            <template v-slot:activator="{ on }">
                                                <v-btn class="ma-auto mr-5 ml-5 col-0" text icon v-on="on" @click="openDialogUpdateCampain(campain)" dusk="logout-button">
                                                    <v-icon color="primary">mdi-circle-edit-outline</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{ $t('campain.edit_campain') }}</span>
                                        </v-tooltip>
                                        <v-tooltip v-if="!campain.trash && access_level <= 100 " bottom dark>
                                            <template v-slot:activator="{ on }">
                                                <v-btn class="ma-auto mr-5 ml-5 col-0 mt-2" text icon v-on="on" @click="setDataTrash(campain,1)" dusk="logout-button">
                                                    <v-icon color="red">mdi-delete-alert</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{ $t('campain.desactivate_campain') }}</span>
                                        </v-tooltip>
                                    </v-col>
                                </div>
                            </div>
                        </li>
                    
                        <!-- ACTIVE DATA VOID -->
                        <li v-if="!campains_available && deprecated_available" class="rounded row notification_card mb-1 pa-10" style="border: 2px dotted #80808059;color: grey;justify-content: center;">
                            <div style="color:grey;text-align: center;">Vous n'avez aucune campagne en cours.</div>
                        </li>

                        <!-- TRASHED DATA TITLE IF PRESENT (for admin and analysts on trashed data)-->
                        <li v-if="deprecated_available && campains_available && access_level <= 100" class="row col-12 pb-5 mb-5 mt-10" style="text-align:center;display: flex;justify-content: center;border-bottom: 1px solid #8080801c;">
                            campains désactivées
                        </li>

                        <!-- TRASHED DATA (for admin and analysts on trashed data)-->
                        <li class="rounded pointer row notification_card mb-5" v-for="campain in trashed_campains" :key="campain" style="border: solid 1px #80808010;background-color: #d9d9d9cc;color: grey;">                          
                            <div v-if="deprecated_available && access_level <= 100" class="row pa-3">
                                <!--CHIPS-->
                                <div v-for="filters in data_filters" :key="filters">
                                    <div  v-if="filters.level == campain.state && filters.level_name !== 'Tout voir'" @click="openDialogShow(campain)" class="col-0 mr-5" style="color:white;font-weight:800;display:flex;justify-content:center;text-shadow:2px 2px 4px rgba(0, 0, 0, 0.29); height:100%;min-width: 8px;border-radius:2px 0px 0px 2px;" :style="{backgroundColor: filters.level_color}"></div>
                                </div>
                                
                                <!--INFOS-->
                                <div class="col-3 ma-auto">
                                    <v-chip class="mb-5 mt-5" style="font-weight: bold; background-color: #f3f3f3; border: 1px solid #e5e5e5;">{{campain.name}}</v-chip>
                                    <div style="font-size: 12px;margin-left: 15px;">Ref - {{campain.uid}}</div>
                                    <div style="font-size: 12px;margin-left: 15px;">Créée le : {{campain.created_at}}</div>
                                    <div style="font-size: 12px;margin-left: 15px;">N° de dossier : {{campain.affaire_number}}</div>
                                    <div style="font-size: 12px;margin-left: 15px;">Créateur : {{campain.creator}}</div>
                                    <div style="font-size: 12px;margin-left: 15px;">Chargé de campagne : {{campain.manager}}</div>
                                </div>

                                <!--COMMENT-->
                                <div :to="{ path: '/campain/' + campain.id}" class="col pl-10" style="border-left: 1px solid #d3d3d36e; font-size:12px;">
                                    <div style="font-weight: bold;font-size: 13px;">Fonction :</div> 
                                    <v-row class="row col pa-0 pt-1 pb-1 ma-0">
                                        <p class="mb-0">{{ campain.functionality }}</p>
                                    </v-row> 
                                    <div style="font-weight: bold;font-size: 13px;" class="mt-2">Informations complémentaires :</div> 
                                    <v-row class="row col pa-0 pt-1 pb-1 ma-0">
                                        <p class="mb-0">{{ campain.note }}</p>
                                    </v-row>                                
                                </div>

                                <!--ACTIONS -->
                                <v-col class="col-1 pa-2" style="max-width: 80px;">
                                    <v-tooltip v-if="campain.trash" bottom dark>
                                        <template v-slot:activator="{ on }">
                                            <v-btn class="ma-auto mr-5 ml-5 col-0" text icon v-on="on" @click="setDataTrash(campain,0)" dusk="logout-button">
                                                <v-icon color="primary">mdi-delete-restore</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('campain.restore') }}</span>
                                    </v-tooltip>
                                    <v-tooltip v-if="campain.trash && $store.state.access_level === 0" bottom dark>
                                        <template v-slot:activator="{ on }">
                                            <v-btn class="ma-auto mr-5 ml-5 col-0 mt-2" text icon v-on="on" @click="openDialogDeleteCampain(campain,0)" dusk="logout-button">
                                                <v-icon color="red">mdi-delete-forever</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('campain.delete') }}</span>
                                    </v-tooltip>
                                </v-col>
                            </div>
                        </li>

                        <!-- TRASHED DATA VIEW MORE (for admin and analysts on trashed data)-->
                        <li v-if="deprecated_available && inactive_campains_count > 5 && access_level <= 100" class="row notification_card mb-1" style="display: flex;flex-direction: row;justify-content: center;align-items: center;font-size: 16px;">
                            <v-tooltip bottom dark>
                                <template v-slot:activator="{ on }">
                                    <v-btn text icon v-on="on" @click="viewMore()" dusk="logout-button">
                                        <v-icon color="primary">mdi-arrow-down-thin-circle-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>@{{ $t('campain.show-more') }}</span>
                            </v-tooltip>
                        </li>

                        <!-- ALL DATA VOID (all categories)-->
                        <li v-if="!campains_available && !deprecated_available && filter_mode == 9 && access_level <= 100" class="rounded row notification_card mb-1 pa-10" style="border: 2px dotted #80808059;color: grey;justify-content: center;">
                            <div style="color:grey;text-align: center;">Vous n'avez pas de campagnes disponibles. <br> Créez votre premiere campagne via le bouton (+) ci dessus.</div>
                        </li>

                        <li v-if="!campains_available && !deprecated_available && filter_mode == 9 && access_level > 100" class="rounded row notification_card mb-1 pa-10" style="border: 2px dotted #80808059;color: grey;justify-content: center;">
                            <div style="color:grey;text-align: center;">Vous retrouverez ici toutes les interventions appelées "campagnes". 
                                <br> Dès qu'une campagne sera enregistrée elle sera disponible dans cette section.
                                <br> Vous pourrez ainsi suivre l'avancement de ces diverses interventions.
                            </div>
                        </li>

                        <!-- ALL DATA VOID (specific categories)-->
                        <li v-if="!campains_available && !deprecated_available && filter_mode != 9" class="rounded row notification_card mb-1 pa-10" style="color: grey;justify-content: center;">
                            <div style="color:grey;text-align: center;">Aucune campagne présente dans cette catégorie.</div>
                        </li>
                    </ul>
                </template>

                <!-- campain ADD / EDIT MODAL -->
                <v-dialog v-model="dialog_edit" max-width="750px">
                    <v-card>
                        <validation-observer ref="observer" v-slot="{ invalid }">
                            <v-form ref="form">
                                <v-card-title>
                                    <span class="headline">{{ dialogTitle }}</span>
                                </v-card-title>
                                <v-divider></v-divider>

                                <v-card-text>
                                    <v-col>
                                        <!-- CAMPAIN NAME -->
                                        <validation-provider :name="$t('campain.add_name')" rules="required"
                                                                v-slot="{ errors }">
                                            <v-text-field solo
                                                            :label="$t('campain.add_name')"
                                                            v-model="edited_campain.name"
                                                            :prepend-icon="'mdi-label-outline'"
                                                            :error-messages="errors"
                                            />
                                        </validation-provider>

                                        <!-- CAMPAIN PRESTA -->
                                        <validation-provider :name="$t('campain.add_presta')" rules="required"
                                                                v-slot="{ errors }">
                                            <v-select
                                                :items="prestas"
                                                v-model="edited_campain.presta"
                                                :label="$t('campain.add_presta')"
                                                :prepend-icon="'mdi-book'"
                                                :error-messages="errors"
                                                item-value="id"
                                                item-text="name" solo
                                            >
                                            </v-select>
                                        </validation-provider> 

                                        <!-- CAMPAIN TYPE -->
                                        <validation-provider :name="$t('campain.add_type')" rules="required"
                                                                v-slot="{ errors }">
                                            <v-select
                                                :items="types"
                                                v-model="edited_campain.type"
                                                :label="$t('campain.add_type')"
                                                :prepend-icon="'mdi-book'"
                                                :error-messages="errors"
                                                item-value="id"
                                                item-text="name" solo
                                            >
                                            </v-select>
                                        </validation-provider>

                                        <!-- CAMPAIN AFFAIRE NUMBER -->
                                        <validation-provider :name="$t('campain.add_affaire_number')" rules="required"
                                                                v-slot="{ errors }">
                                            <v-text-field solo
                                                            :label="$t('campain.add_affaire_number')"
                                                            v-model="edited_campain.affaire_number"
                                                            :prepend-icon="'mdi-qrcode'"
                                                            :error-messages="errors"
                                            />
                                        </validation-provider>

                                        <!-- CAMPAIN MANAGER -->
                                        <validation-provider :name="$t('campain.add_manager')" rules="required"
                                                                v-slot="{ errors }">
                                            <v-select
                                                :items="analysts"
                                                v-model="edited_campain.manager"
                                                :label="$t('campain.add_manager')"
                                                :prepend-icon="'mdi-account-hard-hat'"
                                                :error-messages="errors"
                                                item-value="id"
                                                item-text="first_name" solo
                                            >
                                            </v-select>
                                        </validation-provider>

                                        <!-- CAMPAIN PARK -->
                                        <validation-provider :name="$t('campain.add_park')" rules="required"
                                                                v-slot="{ errors }">
                                            <v-select
                                                :items="sites"
                                                v-model="edited_campain.id_park"
                                                :label="$t('campain.add_park')"
                                                :prepend-icon="'mdi-factory'"
                                                :error-messages="errors"
                                                item-value="id"
                                                item-text="label" solo
                                            >
                                            </v-select>
                                        </validation-provider>

                                        <!-- CAMPAIN MACHINES -->
                                        <validation-provider :name="$t('campain.add_machines')" rules="required"
                                                                v-slot="{ errors }">
                                            <v-select
                                                :items="machines"
                                                v-model="edited_campain.machine_list"
                                                :label="$t('campain.add_machines')"
                                                :prepend-icon="'mdi-car-turbocharger'"
                                                :error-messages="errors"
                                                item-value="id"
                                                item-text="label" solo chips clearable multiple
                                            >
                                            <template v-slot:prepend-item>
                                                <v-list-item
                                                    subtitle="Prochainement disponible, la capacité à sélectionner toutes les machines"
                                                    title="A venir :"
                                                    disabled
                                                >
                                                    <template v-slot:prepend>
                                                        <v-avatar color="primary" icon="mdi-food-apple"></v-avatar>
                                                    </template>
                                                </v-list-item>
                                                <v-divider class="mt-2 mb-2"></v-divider>
                                                <!--
                                                <v-list-item
                                                    title="Sélectionner toutes les machines"
                                                    @click="toggle"
                                                >
                                                    <template v-slot:prepend>
                                                    <v-checkbox-btn
                                                        :indeterminate="likesSomeFruit && !likesAllFruit"
                                                        :model-value="likesAllFruit"
                                                    ></v-checkbox-btn>
                                                    </template>
                                                </v-list-item>
                                                -->
                                            </template>

                                            <template slot="selection" slot-scope="data">
                                                <!-- HTML that describe how select should render selected items -->
                                                <v-chip  v-on="on">{{data.item.label}}</v-chip>
                                            </template>
                                            <template slot="item" slot-scope="data">
                                                <!-- HTML that describe how select should render items when the select is open -->
                                                <v-col>
                                                    <div><strong>{{ data.item.label }}</strong></div>
                                                    <div style="font-size:12px;">{{ data.item.building.label }}</div>
                                                </v-col>
                                            </template>
                                            </v-select>
                                        </validation-provider>
                                        
                                        <!-- CAMPAIN DESCRIPTION -->
                                        <v-textarea rows="2" solo no-resize
                                                    v-model="edited_campain.description"
                                                    :label="$t('global.notes')"
                                                    :prepend-icon="'mdi-comment-outline'"
                                        />

                                        <!-- CAMPAIN TAGS -->
                                        <validation-provider :name="$t('campain.add_tags')" rules="required"
                                                                v-slot="{ errors }">
                                            <v-select
                                                :items="tags"
                                                v-model="edited_campain.tags"
                                                :label="$t('campain.add_tags')"
                                                :prepend-icon="'mdi-tag'"
                                                :error-messages="errors"
                                                item-value="name"
                                                item-text="name" solo chips clearable multiple
                                            >
                                            </v-select>
                                        </validation-provider>
                                    </v-col>
                                </v-card-text>

                                <v-card-actions class="mb-3" style="position:absolute;top:10px;right:10px">
                                    <v-btn @click="dialog_edit = false;" style="color:red;max-width: 30px;padding: 0 0 0 5px;min-width: 10px!important;height: 30px!important;">
                                        <v-icon left>mdi-close</v-icon>
                                    </v-btn>
                                </v-card-actions>

                                <v-card-actions class="pb-5" style="display:flex;justify-content:center">
                                    <v-btn type="submit" color="primary" :disabled="invalid"
                                            @click.prevent="prepareEditCampain()">
                                        <v-icon left>mdi-content-save</v-icon>
                                        {{ $t('global.validation') }}
                                    </v-btn>
                                </v-card-actions>
                            </v-form>
                        </validation-observer>
                    </v-card>
                </v-dialog>

                <!-- CAMPAIN DELETE MODAL -->
                <v-dialog v-model="dialog_delete" max-width="500px">
                    <v-card>
                        <v-card-title>
                            <span class="headline">
                                {{ $t('campain.delete_campain') }}
                            </span>
                        </v-card-title>
                        <v-divider></v-divider>
                        <br/>

                        <v-card-actions>
                            <v-spacer/>
                            <v-btn @click="dialog_delete = false">
                                <v-icon left>mdi-close</v-icon>
                                {{ $t('global.cancel') }}
                            </v-btn>
                            <v-btn color="error" @click="confirmCampainDelete()">
                                <v-icon left>mdi-delete</v-icon>
                                {{ $t('global.delete') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <!-- SNACKBAR INFO DISPLAY -->
                <v-snackbar
                    v-model="snackbar"
                    :color="snackbar_type">
                    {{ snackbar_text }}
                    <template v-slot:action="{ attrs }">
                        <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                    </template>
                </v-snackbar>
            </div>

            <!--FILTER MAX SIZE MESSAGE ITEM-->
            <template v-slot:item.message="{ item }">
                {{ item.message.substring(0, 100) + '...' }}
            </template>

            <!--ACTION BUTTONS -->
            <template v-slot:item.action="{ item }">
                <v-icon class="pointer" @click="setDataTrash(item, 1)" v-if="!campains_trash">
                    mdi-eye-check
                </v-icon>
                <v-icon class="pointer" @click="setDataTrash(item, 0)" v-if="campains_trash">
                    mdi-eye-remove
                </v-icon>
                <v-icon class="pointer" @click="openDialogShow(item)">
                    mdi-arrow-expand-all
                </v-icon>
            </template>

            <!-- Modal next update -->
            <v-dialog v-model="dialog_next_update" max-width="500px">
                <v-card>
                    <v-card-title>
                        <span class="headline">
                            Fonctionnalité bientôt disponible
                        </span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <br/>
                    <p class="pl-4 pr-4 pb-4">
                        Cette fonctionnalité est en cours de développement et sera bientôt disponible.
                        Elle vous permettra entre autre de filter et de rechercher précisément via des mots clés votre cible.
                        Un rapport, une machine, une référence...
                        Le but, vous faire gagner du temps ! 
                    </p>

                    <!-- Bouton pour fermer -->
                    <v-card-actions class="mb-3" style="position:absolute;top:5px;right:2px">
                        <v-btn @click="closeNewUpdateDialog();" style="color:red;max-width: 30px;padding: 0 0 0 5px;min-width: 10px!important;height: 30px!important;">
                            <v-icon left>mdi-close</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<script>
import {CAMPAIN,SUBASSEMBLIES,MACHINES,REPORT,USERS,SITES} from "../../../api";
import moment from "moment";

export default {
    name: "CampainVision",
    data() {
        return {
            // CAMPAINS ----------------
            raw_campains: [],
            campains: [],
            raw_trashed_campains: [],
            trashed_campains: [],
            //-
            campains_trash:false,
            campains_available:false,
            deprecated_length:5,
            deprecated_available:false,
            dialog_next_update: false,
            //-
            active_campains_count:0,
            inactive_campains_count:0,
            global_campains_count:0,
            
            // GLOBAL ----------------
            loading_datatable:true,
            loading: true,
            access_level: this.$store.state.access_level,
            //-
            search: '',
            filter_mode:9,
            edited_index: null,
            invalid: false,
            //-
            dialog_edit: false,
            dialog_delete: false,
            delete_id: null,
            deleted_index: null,
            
            // ARRAYS ----------------
            data_filters: [
                {
                    level: 9,
                    level_name: "Tout voir",
                    level_color: "#d7d7d752",
                    count:this.global_notifications_count
                },
                {
                    level: 0,
                    level_name: "En préparation",
                    level_color: "#ef780e",
                    count:0
                },
                {
                    level: 1,
                    level_name: "En cours d'intervention",
                    level_color: "#0e4cef82",
                    count:0
                },
                {
                    level: 2,
                    level_name: "En cours d'analyses",
                    level_color: "#0e4cefc9",
                    count:0
                },
                {
                    level: 3,
                    level_name: "Rapports en cours d'édition",
                    level_color: "#0e4cef",
                    count:0
                },
                {
                    level: -1,
                    level_name: "Terminées",
                    level_color: "#565656",
                    count:0
                },                
            ],
            edited_campain: {
                id: null,
                name: '',
                presta:'',
                type: '',
                affaire_number:'',
                manager: '',
                id_park: null,
                machine_list:[],
                description: '',
                tags:[],                          
            },
            analysts: [],
            team: [],
            machines: [],
            machines_count:0,
            reports_count:0,
            reports_done_count:0,
            sites: [],
            prestas:[
                {id:0, name:"Mesures vibratoires"},
                {id:1, name:"Thermographie"},
                {id:2, name:"Mesures ultrasons"},
                {id:3, name:"Equilibrages"},
                {id:4, name:"Expertise avancée"},
                {id:5, name:"Surveillance Online"},
            ],
            types:[
                {id:0, name:"Ponctuel"},
                {id:1, name:"Annuel"},
                {id:2, name:"Semestriel"},
                {id:3, name:"Quadrmestriel"}, 
                {id:4, name:"Trimestriel"},
                {id:5, name:"Continu"},
            ],
            tags:[
                {id:0, name:"Caractérisation machine"},
                {id:1, name:"Création BDD"},
                {id:2, name:"Etude approfondie"},
                {id:3, name:"Machines outils"},
                {id:4, name:"Machines tournantes"},
                {id:5, name:"Surveillance à distance"},
                {id:6, name:"Trés basse vitesse"},
                {id:7, name:"Historique"},
            ],
            timeProps: {
                format: "24hr"
            },
            textFieldProps: {
                prependInnerIcon: "mdi-calendar",
                solo: true,
                rules: [v => !!v || this.$t('vee-validation.required_date_operation')]
            },
        }
    },
    computed: {
        dialogTitle() {
            return this.edited_index === null ? this.$t('campain.add') : this.$t('campain.edit');
        }
    },
    created() {
        moment.locale('fr');
        this.initialize();
    },
    methods: {
        // COLLECTING ALL NEEDED DATAS
        initialize() {
            this.loading_datatable = true;
            this.getData();
            this.getSideDatas();
            //this.getReports();

        },
        getData() {
            // GETTING CAMPAINS
            this.axios.get(CAMPAIN + '?trash=0')
            
            .then((success) => {
                success.data.forEach(item => {
                    item.created_at = moment(item.created_at).format("DD/MM/YYYY");
                    item.machine_list = JSON.parse(item.machine_list);
                })
                this.filter_mode = 9;
                this.raw_campains = [];
                this.campains = [];

                if (this.checkDataAvailable(success.data)){
                    this.raw_campains = success.data;
                    this.campains = success.data;
                }

            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");

            }).finally(() => {
                this.getTrashData();
            });
        },
        getTrashData() {
            // GETTING DEACTIVATED campainS
            this.axios.get(CAMPAIN + '?trash=1')

            .then((success) => {
                success.data.forEach(item => {
                    item.created_at = moment(item.created_at).format("DD/MM/YYYY");
                    item.machine_list = JSON.parse(item.machine_list);
                })
                this.filter_mode = 9;
                this.raw_trashed_campains = [];
                this.trashed_campains = [];

                if (this.checkTrashDataAvailable(success.data)){
                    this.raw_trashed_campains = success.data;
                    this.trashed_campains = success.data.slice(0, this.deprecated_length);
                }

            }).catch(() => {
                this.showSnackbar('error', "Trashed data error.");
            
            }).finally(() => {
                this.getDataCount();
            });         
        },
        getSideDatas(){
            this.axios.get(REPORT + '?trash=0')
            
            .then((success) => {
                this.reports = [];
                this.reports = success.data;

            }).catch(() => {
                this.showSnackbar('error', "Side datas error.");
            }).finally(() => {
                this.getAnalysts();
                this.getTeam();
                this.getMachines();
                this.getSites();
            })
        },
        getAnalysts(){
            this.axios.get(USERS + '/analysts')
            
            .then((success) => {
                this.analysts = [];
                this.analysts = success.data;

            }).catch(() => {
                this.showSnackbar('error', "Analysts datas error.");
            })
        },
        getTeam(){
            this.axios.get(USERS + '/team')
            
            .then((success) => {
                this.team = [];
                this.team = success.data;

            }).catch(() => {
                this.showSnackbar('error', "Team datas error.");
            })
        },
        getMachines() {
            this.axios.get(MACHINES + '/resume')
            
            .then((success) => {
                this.machines = [];
                this.machines = success.data;
                this.machines_count = this.machines.length;

            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");
            })
        },
        getSites() {
            this.axios.get(SITES + '/resume')
            
            .then((success) => {
                this.sites = [];
                this.sites = success.data;

            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");
            })
        },
        getDataCount() {
            // Reset all counts
            this.data_filters.forEach(data => {data.count = 0});
            this.active_campains_count =0;
            this.inactive_campains_count =0;
            this.global_campains_count =0;
            
            // Get all last counts
            this.campains.forEach(item => {  
                if(item.closed_at === null){
                    this.active_campains_count = this.active_campains_count +1;
                }
                
                this.data_filters.forEach(filter => { 
                    if(item.state == filter.level){
                        filter.count = filter.count +1;
                    }
                });
            });

            this.inactive_campains_count = this.trashed_campains.length;
            this.global_campains_count = this.active_campains_count + this.inactive_campains_count;

            // FREEUP LOADING STEP
            this.loading_datatable = false
        },
        getCampainTags(campain) {
            if (campain.tags != "") {
                return JSON.parse(campain.tags);
            }
        },
        getReports() {
            this.axios.get(
                SUBASSEMBLIES
            ).then((success) => {
                this.subassemblies = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'))
            }).finally(() => {
                this.loading = false
            });
        },

        // DISPLAY DATA MANAGEMENT
        checkDataAvailable(datas){
            if(typeof datas[0] === 'undefined') {                    
                this.campains_available = false;
                return false;
            }else {
                this.campains_available = true;
                return true;
            }
        },
        checkTrashDataAvailable(datas){
            if(typeof datas[0] === 'undefined') {                    
                this.deprecated_available = false;
                return false;
            }else {
                this.deprecated_available = true;
                return true;
            }
        },
        setDataFiltered (filter) {
            this.loading_datatable = true;
            this.filter_mode = filter;
            this.deprecated_length=5;
            this.dataTreatment();
            this.dataTrashTreatment();
            this.loading_datatable = false;
        },
        dataTreatment(){
            if(this.checkDataAvailable(this.raw_campains)) {                    
                this.campains = [];

                if(this.filter_mode === 9){
                    this.campains = this.raw_campains;

                }else{
                    this.raw_campains.forEach(campain => {
                        if (campain.state == this.filter_mode){
                            this.campains.push(campain);
                        }
                    })
                }
                
                // Then check result to si if there is somehting left in chosen datas
                this.checkDataAvailable(this.campains);
            }
        },
        dataTrashTreatment(){
            if(this.checkTrashDataAvailable(this.raw_trashed_campains)) {                    
                this.trashed_campains = [];

                if(this.filter_mode === 9){
                    this.trashed_campains = this.raw_trashed_campains;

                }else{
                    this.raw_trashed_campains.forEach(campain => {
                        if (campain.state == this.filter_mode){
                            this.trashed_campains.push(campain);
                        }
                    })
                }

                //Get deprecated list by max length
                this.trashed_campains.slice(0, this.deprecated_length);
                
                // Then check result to si if there is somehting left in chosen datas
                this.checkTrashDataAvailable(this.trashed_campains);
            }
        },
        viewMore() {
            this.deprecated_length = this.deprecated_length + 10;
            this.dataTrashTreatment();
        },

        // POST REQUESTS PART
        prepareEditCampain() {
            if (this.edited_index !== null) {
                this.updateCampain();
            } else {
                this.addCampain();
            }
            this.closeDialog();
        },
        setDataTrash(data, state) {
            // Set notification seen
            if (state === 1) {
                this.axios.patch(CAMPAIN + '/trash/' + data.id)
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.getData();
                    this.dialog_show = false;
                })
            
            // Set notification unseen
            } if (state === 0) {
                this.axios.patch(CAMPAIN + '/untrash/' + data.id)
            
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.getData();
                    this.dialog_show = false;
                })
            }
        },
        updateCampain() {
            this.axios.patch(CAMPAIN + '/' + this.edited_campain.id,
                this.edited_campain
            ).then((success) => {
                Object.assign(this.campains[this.edited_index], success.data);
                this.edited_index = null;
                this.showSnackbar('success', this.$t('global.success_to_edit'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            }).finally(() => {
                this.getData();
                this.loading_datatable = true;
                this.closeDialog();
            })
        },
        updateStep(id, step_id){
            this.axios.patch(CAMPAIN + '/step/' + id + "/" + step_id
            ).then((success) => {
                this.getData();

            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            })
        },
        addCampain() {
            let formData = new FormData()
            formData.append('name', this.edited_campain.name);
            formData.append('presta', this.edited_campain.presta);
            formData.append('type', this.edited_campain.type);
            formData.append('affaire_number', this.edited_campain.affaire_number);
            formData.append('manager', this.edited_campain.manager);
            formData.append('id_park', this.edited_campain.id_park);
            formData.append('machine_list', [JSON.stringify(this.edited_campain.machine_list)]);
            formData.append('description', this.edited_campain.description);
            formData.append('tags', [JSON.stringify(this.edited_campain.tags)]);

            this.axios.post(CAMPAIN, formData)

            .then((success) => {
                this.campains.push(success.data);
                this.showSnackbar('success', this.$t('global.success_to_add'));

            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_add'));

            }).finally(() => {
                this.getData();
                this.loading_datatable = true;
                this.closeDialog();
            });
        },
        confirmCampainDelete() {
            this.axios.delete(
                CAMPAIN + '/delete/' + this.delete_id
            ).then(() => {
                this.campains.splice(this.deleted_index, 1);
                this.showSnackbar('success', this.$t('global.success_to_delete'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_delete'));
            }).finally(() => {
                this.loading_datatable = true;
                this.getData();
                this.dialog_delete = false;
            });
        },
        setClosed(id){
            this.axios.patch(CAMPAIN + '/close/' + id)
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.getData();
                    this.dialog_show = false;
                })
        },
        setOpened(id){
            this.axios.patch(CAMPAIN + '/open/' + id)
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.getData();
                    this.dialog_show = false;
                })
        },

        // MODALS
        openDialogAddCampain() {
            if (this.edited_index !== null) {
                this.$refs.form.reset();
                this.$refs.observer.reset();
                this.edited_index = null;
            }

            this.dialog_edit = true;
        },
        openDialogUpdateCampain(campain) {
            this.edited_index = this.campains.indexOf(campain);
            this.edited_campain = Object.assign({}, campain);
            this.dialog_edit = true;
        },
        openDialogDeleteCampain(campain) {
            this.deleted_index = this.campains.indexOf(campain);
            this.delete_id = campain.id;
            this.dialog_delete = true;
        },
        openDialogShow(){
            console.log("open dialog show requested");
        },
        closeDialog() {
            this.$refs.form.reset();
            this.$refs.observer.reset();
            this.dialog_edit = false;
        },
        dialogNextUpdate() {
            if (this.dialog_next_update){
                this.dialog_next_update = false;
            }else{
                this.dialog_next_update = true;
            }
        },
        closeNewUpdateDialog(){
            this.setCookieVolet();
            this.dialog_new_update = false;
        }
    }
}
</script>