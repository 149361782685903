<template>
    <div id="setting-log">
        <v-row>
            <v-col>
                <v-subheader>
                    <p class="title mb-0 color-primary text-uppercase">
                        {{ $t('logs.title') }}
                    </p>
                </v-subheader>
            </v-col>
        </v-row>

        <datatable-log class="mt-6"/>
    </div>
</template>

<script>
import DatatableLog from "../../components/Setting/Log/DatatableLog";

export default {
    name: "Log",
    components: {DatatableLog},
}
</script>
