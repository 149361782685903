<template>
    <div id="setting-log">
        <v-row class="row mt-5">
            <v-subheader class="v-subheader col theme--light pl-5 ml-2">
                <p class="title mb-0 color-primary text-uppercase">
                    {{ $t('professionals.title-customers') }}
                </p>
                <!------------------- Info bulle ---------------------->
                <v-tooltip bottom dark class="mr-2">
                    <template v-slot:activator="{ on }">
                        <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                            <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:default>
                    <div style="min-width: 200px;">
                        <strong>{{ $t('tooltip.professional_page_title') }}</strong>
                        <p>{{ $t('tooltip.professional_page_content1') }}</p>
                        <p>{{ $t('tooltip.professional_page_content2') }}</p>
                        <p>{{ $t('tooltip.professional_page_content3') }}</p>
                        <p>{{ $t('tooltip.professional_page_content4') }}</p>
                        <p>{{ $t('tooltip.professional_page_content5') }}</p>
                    </div>
                    </template>
                </v-tooltip>
            </v-subheader>
            <v-row cols="2" class="v-subheader col-1 theme--light mr-3">
                <v-col class="col"></v-col>
                <v-tooltip bottom dark>
                    <template v-slot:activator="{ on }">
                        <v-btn text icon v-on="on" @click="setCards()" dusk="logout-button">
                            <v-icon color="primary">mdi-card-text-outline</v-icon>
                        </v-btn>
                    </template> 
                    <span>{{ $t('professionals.cards') }}</span>
                </v-tooltip>
                <!--
                    <v-tooltip bottom dark>
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" @click="setDatatable()" dusk="logout-button">
                                <v-icon color="primary">mdi-format-list-bulleted</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('global.notifications') }}</span>
                    </v-tooltip>
                -->
            </v-row>
        </v-row>
        <cards-professionals class="mt-6" v-if="cards"/>
        <datatable-professionals class="mt-6" v-if="!cards"/>
    </div>
</template>

<script>
import DatatableProfessionals from "./DatatableProfessionals";
import CardsProfessionals from "./CardsProfessionals";

export default {
    name: "Professionals",
    components: {DatatableProfessionals, CardsProfessionals},
    data() {
        return {
            cards: true,
        }
    },
    created() {
        this.setCards();
    },
    methods: {
        switchView() {
            this.cards = !this.cards;
        },

        setCards() {
            this.cards = true;
        },

        setDatatable() {
            this.cards = false;
        },
    }
}


</script>
