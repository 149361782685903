<template>
    <div id="subassembly-vision">
        <v-row>
            <v-col style="background-color: white">
                <v-subheader>
                    <p class="title mb-0">
                        {{ $t('buildings.title') }}
                    </p>
                </v-subheader>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        <v-spacer/>
                    </v-card-title>

                    <v-data-table
                        :headers="headers"
                        :items="buildings"
                        sort-by="id"
                        class="elevation-1"
                        :loading="loading"
                        :search="search">

                        <template v-slot:top>
                            <v-toolbar flat color="white">
                                <v-layout class="justify-start">
                                    <v-btn color="primary" class="white--text mr-3" @click="openDialogAddBuilding()">
                                        {{ $t('buildings.add_building') }}
                                    </v-btn>
                                </v-layout>

                                <v-card-title class="mt-5">
                                    <v-text-field
                                        v-model="search"
                                        append-icon="mdi-magnify"
                                        :label="$t('global.search')"
                                    ></v-text-field>
                                </v-card-title>

                                <v-dialog v-model="dialog_edit" max-width="500px">
                                    <v-card>
                                        <validation-observer ref="observer" v-slot="{ invalid }">
                                            <v-form ref="form">
                                                <v-card-title>
                                                    <span class="headline">{{ dialogTitle }}</span>
                                                </v-card-title>
                                                <v-divider></v-divider>

                                                <v-card-text>
                                                    <validation-provider :name="$t('global.name')" rules="required"
                                                                         v-slot="{ errors }">
                                                        <v-text-field solo
                                                                      :label="$t('global.name')"
                                                                      v-model="edited_building.label"
                                                                      :prepend-icon="'mdi-label-outline'"
                                                                      :error-messages="errors"
                                                        />
                                                    </validation-provider>

                                                    <validation-provider :name="$t('buildings.site')" rules="required"
                                                                         v-slot="{ errors }">
                                                        <v-select
                                                            :items="sites"
                                                            v-model="edited_building.site_id"
                                                            :label="$t('buildings.site')"
                                                            :prepend-icon="'mdi-sitemap'"
                                                            :error-messages="errors"
                                                            item-value="id"
                                                            item-text="label" solo
                                                        >
                                                        </v-select>
                                                    </validation-provider>

                                                    <v-file-input
                                                        v-if="edited_index === null"
                                                        v-model="edited_building.path_plan"
                                                        :label="$t('buildings.building_plan')"
                                                        solo
                                                        @change="onImageChanged"
                                                    ></v-file-input>

                                                    <v-textarea rows="2" solo no-resize
                                                                v-model="edited_building.note"
                                                                :label="$t('global.notes')"
                                                                :prepend-icon="'mdi-comment-outline'"
                                                    />
                                                </v-card-text>

                                                <v-card-actions>
                                                    <v-spacer/>
                                                    <v-btn @click="closeDialog()">
                                                        <v-icon left>mdi-close</v-icon>
                                                        {{ $t('global.close') }}
                                                    </v-btn>
                                                    <v-btn type="submit" color="primary" :disabled="invalid"
                                                           @click.prevent="prepareEditBuilding()">
                                                        <v-icon left>mdi-content-save</v-icon>
                                                        {{ $t('global.validation') }}
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-form>
                                        </validation-observer>
                                    </v-card>
                                </v-dialog>

                                <v-dialog v-model="dialog_delete" max-width="500px">
                                    <v-card>
                                        <v-card-title>
                                            <span class="headline">
                                                {{ $t('buildings.delete_building') }}
                                            </span>
                                        </v-card-title>
                                        <v-divider></v-divider>
                                        <br/>

                                        <v-card-actions>
                                            <v-spacer/>
                                            <v-btn @click="dialog_delete = false">
                                                <v-icon left>mdi-close</v-icon>
                                                {{ $t('global.cancel') }}
                                            </v-btn>
                                            <v-btn color="error" @click="confirmDelete()">
                                                <v-icon left>mdi-delete</v-icon>
                                                {{ $t('global.delete') }}
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-toolbar>
                        </template>

                        <template v-slot:item.label="{ item }">
                            <router-link :to="{ path: '/building/' + item.id}">
                                {{ item.label }}
                            </router-link>
                        </template>

                        <template v-slot:item.site="{ item }">
                            <router-link :to="{ path: '/site/' + item.site.id}">
                                {{ item.site.label }}
                            </router-link>
                        </template>

                        <template v-slot:item.path_plan="{ item }">
                            <v-img aspect-ratio="1" v-if="item.path_plan" class="mb-2 mt-2" :src="item.path_plan"
                                   height="200" contain>
                                <template v-slot:placeholder>
                                    <v-row
                                        class="fill-height ma-0 background-loader-image"
                                        align="center" justify="center"
                                    >
                                        <v-progress-circular indeterminate
                                                             color="black lighten-5"></v-progress-circular>
                                    </v-row>
                                </template>
                            </v-img>
                        </template>

                        <template v-slot:item.machines="{ item }">
                            <span v-if="item.machines.length === 0">{{ $t('buildings.no_machines') }}</span>
                            <template v-else v-for="machine in item.machines">
                                <v-chip :key="machine.id" class="mt-1 mb-1" :color="assetColor(machine)"
                                        @click="$router.push({ name: 'machine', params: {id: machine.id} })"
                                        text-color="white"
                                >
                                    {{ machine.label }}
                                </v-chip>
                                <br/>
                            </template>
                        </template>

                        <template v-slot:item.action="{ item }">
                            <v-icon class="pointer" @click="openDialogUpdateBuilding(item)">mdi-pencil</v-icon>
                            <v-icon class="pointer" @click="openDialogDeleteBuilding(item)"
                                    v-if="item.machines.length === 0">
                                mdi-delete
                            </v-icon>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
        <v-snackbar
            v-model="snackbar"
            :color="snackbar_type">
            {{ snackbar_text }}
            <template v-slot:action="{ attrs }">
                <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import {BUILDINGS, SITES} from "../../../api";

export default {
    name: "BuildingVision",
    data() {
        return {
            buildings: [],
            sites: [],
            headers: [
                {text: this.$t('global.name'), value: 'label'},
                {text: this.$t('buildings.site'), value: 'site'},
                {text: this.$t('datatable_machine.building_plan'), value: 'path_plan'},
                {text: this.$t('buildings.machines'), value: 'machines'},
                {text: this.$t('buildings.total_subassemblies'), value: 'subassemblies_count'},
                {text: this.$t('global.actions'), value: 'action'}
            ],
            loading: true,
            search: '',

            dialog_edit: false,
            edited_index: null,
            edited_building: {
                id: null,
                label: '',
                note: '',
                site_id: '',
                path_plan: null
            },
            invalid: false,

            dialog_delete: false,
            delete_id: null,
            deleted_index: null,
            valid_image: true,
        }
    },
    computed: {
        dialogTitle() {
            return this.edited_index === null ? this.$t('buildings.add_building') : this.$t('buildings.edit_building');
        }
    },
    created() {
        this.getBuildings();
        this.getSites();
    },
    methods: {
        getBuildings() {
            this.axios.get(
                BUILDINGS
            ).then((success) => {
                this.buildings = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            }).finally(() => {
                this.loading = false;
            });
        },

        getSites() {
            this.axios.get(
                SITES
            ).then((success) => {
                this.sites = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            });
        },

        openDialogAddBuilding() {
            if (this.edited_index !== null) {
                this.$refs.form.reset();
                this.$refs.observer.reset();
                this.edited_index = null;
            }

            this.dialog_edit = true;
        },

        openDialogUpdateBuilding(building) {
            this.edited_index = this.buildings.indexOf(building);
            this.edited_building = Object.assign({}, building);
            this.dialog_edit = true;
        },

        prepareEditBuilding() {
            if (this.edited_index !== null) {
                this.updateBuilding();
            } else {
                this.addBuilding();
            }
        },

        updateBuilding() {
            this.axios.patch(BUILDINGS + '/' + this.edited_building.id,
                this.edited_building
            ).then((success) => {
                Object.assign(this.buildings[this.edited_index], success.data);
                this.edited_index = null;
                this.showSnackbar('success', this.$t('global.success_to_edit'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            }).finally(() => {
                this.closeDialog();
            });
        },

        addBuilding() {
            let formData = new FormData()
            formData.append('label', this.edited_building.label);
            formData.append('note', this.edited_building.note);
            formData.append('plan', this.edited_building.path_plan);
            formData.append('site_id', this.edited_building.site_id);

            this.axios.post(
                BUILDINGS, formData, this.configurationFile
            ).then((success) => {
                this.buildings.push(success.data);
                this.showSnackbar('success', this.$t('global.success_to_add'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_add'));
            }).finally(() => {
                this.closeDialog();
            });
        },

        openDialogDeleteBuilding(building) {
            this.deleted_index = this.buildings.indexOf(building);
            this.delete_id = building.id;
            this.dialog_delete = true;
        },

        confirmDelete() {
            this.axios.delete(
                BUILDINGS + '/' + this.delete_id
            ).then(() => {
                this.buildings.splice(this.deleted_index, 1);
                this.showSnackbar('success', this.$t('global.success_to_delete'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_delete'));
            }).finally(() => {
                this.dialog_delete = false;
            });
        },

        closeDialog() {
            this.dialog_edit = false;
            this.$refs.form.reset();
            this.$refs.observer.reset();
        },

        onImageChanged() {
            if(this.edited_building.path_plan !== null) {
                let imageCheck = this.uploadFilesCheck(this.edited_building.path_plan,['image/png','image/jpg','image/jpeg'],2);
                if(!imageCheck.authorized) {
                    this.valid_image = false;
                    this.showSnackbar('error',imageCheck.output.toString());
                }
                else {
                    this.valid_image = true;
                }
            }
            if(this.edited_building.path_plan === null){
                this.valid_image = true;
            }
        },
    }
}
</script>
