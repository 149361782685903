<template>
    <div id="change-password-user">
        <v-dialog v-model="dialog_change_password" max-width="500px">
            <v-card :loading="loading_change_password">
                <v-card-title>
                    <span class="headline">{{ dialogTitle }}</span>
                </v-card-title>

                <v-divider/>

                <v-card-text class="pt-4 pb-0 d-flex justify-center font-weight-bold" v-if="new_password !== ''">
                    <span>{{ $t('users.new_password') }} {{ new_password }}</span>
                </v-card-text>

                <v-card-actions class="mt-4 justify-end">
                    <v-btn @click="closeDialogChangePassword()">
                        <v-icon left>mdi-close</v-icon>
                        {{ $t('global.close') }}
                    </v-btn>
                    <v-btn type="submit" color="primary" v-if="new_password === ''" @click.prevent="updatePassword()">
                        <v-icon left>mdi-content-save</v-icon>
                        {{ $t('global.validation') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {ADMIN_USERS} from "../../../../api";

export default {
    name: 'ChangePasswordUser',
    data() {
        return {
            dialog_change_password: false,
            new_password: '',
            loading_change_password: false,
        }
    },
    computed: {
        dialogTitle() {
            return this.new_password === '' ? this.$t('users.edit_password') : this.$t('users.new_password_title');
        }
    },
    methods: {
        updatePassword() {
            this.loading_change_password = true;

            this.axios.get(
                ADMIN_USERS + '/new_password/' + this.edited_user.id
            ).then((success) => {
                this.new_password = success.data;
                this.loading_change_password = false;
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('global.error_reset_password'), this.$t('error_display.user_not_found'));
            });
        },

        openDialogEditPassword(user) {
            this.edited_user = Object.assign({}, user);
            this.dialog_change_password = true;
        },

        closeDialogChangePassword() {
            this.dialog_change_password = false;
            this.new_password = '';
        },
    }
}
</script>
