<template>
    <v-card :loading="loading_configuration_banner" elevation="2" outlined>
        <v-card-text>
            <v-switch v-model="enable_banner" :label=" $t('information_banner.activate_banner')"
                      class="ml-2 mt-1" inset
            />

            <v-expand-transition>
                <div v-show="enable_banner" class="pb-4">

                    <v-text-field :prepend-inner-icon="'mdi-' + icon" v-model="icon"
                                  :label="$t('information_banner.icon_when_alert')" class="mt-2" filled
                    >
                        <template v-slot:append>
                            <a href="https://materialdesignicons.com" class="mt-2"
                               target="_blank">https://materialdesignicons.com
                            </a>
                        </template>
                    </v-text-field>

                    <v-row>
                        <v-col>
                            <p class="mb-0">
                                {{ $t('information_banner.text_color') }}
                            </p>

                            <v-btn @click="dialog_display_text_color = true" :color="selected_text_color"
                                   class="sheet-cursor w-100" x-large
                            />

                            <v-dialog v-model="dialog_display_text_color" content-class="v-dialog--custom"
                                      overlay-opacity="0.1" origin="top left" transition="scroll-x-transition"
                            >
                                <v-color-picker v-model="selected_text_color"/>
                            </v-dialog>
                        </v-col>

                        <v-col>
                            <p class="mb-0">
                                {{ $t('information_banner.banner_color') }}
                            </p>

                            <v-btn @click="dialog_display_banner_color = true" :color="selected_banner_color"
                                   class="sheet-cursor w-100" x-large
                            />

                            <v-dialog v-model="dialog_display_banner_color" content-class="v-dialog--custom"
                                      overlay-opacity="0.1" origin="top left" transition="scroll-x-transition"
                            >
                                <v-color-picker v-model="selected_banner_color"/>
                            </v-dialog>
                        </v-col>
                    </v-row>

                    <v-text-field v-model="title" :label="$t('information_banner.title_banner')"
                                  class="mt-6" filled
                    />

                    <v-textarea v-model="description" :label="$t('information_banner.description')"
                                auto-grow filled
                    />

                    <div id="preview-alert">
                        <v-alert :color="selected_banner_color" class="ma-0 rounded-0 d-flex">
                            <template v-slot:prepend>
                                <v-icon :color="selected_text_color">{{ 'mdi-' + icon }}</v-icon>
                            </template>

                            <v-row>
                                <v-col class="ml-2">
                                    <span :style="'color: ' + selected_text_color">{{ title }}</span>
                                </v-col>
                                <v-col class="d-flex justify-end">
                                    <span class="font-weight-bold text-decoration-underline pointer"
                                          :style="'color: ' + selected_text_color"
                                          @click="dialog_preview_banner = true"
                                    >
                                        {{ $t('information_banner.more_informations') }}
                                    </span>
                                </v-col>
                            </v-row>
                        </v-alert>

                        <v-dialog v-model="dialog_preview_banner" max-width="500px">
                            <v-card>
                                <v-card-title>
                                    <span class="headline">{{ $t('information_banner.information') }}</span>
                                </v-card-title>

                                <v-divider/>

                                <v-card-text class="mt-4">
                                    <span class="black--text font-16 banner-description">
                                        {{ description }}
                                    </span>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </div>
                </div>
            </v-expand-transition>

            <v-card-actions class="justify-end pa-0">
                <v-btn large color="primary" @click="updateBanner()" :loading="loading_update_configuration">
                    {{ $t('information_banner.update') }}
                </v-btn>
            </v-card-actions>
        </v-card-text>
    </v-card>
</template>

<script>
import BannerCommunication from "../../../../global/components/BannerCommunication"
import {REFERENTIAL_GLOBALS} from "../../../../api";

export default {
    name: "InformationBannerConfiguration",
    data() {
        return {
            loading_configuration_banner: true,
            id_referential_global: null,
            enable_banner: false,
            icon: '',

            dialog_display_text_color: false,
            selected_text_color: '',
            dialog_display_banner_color: false,
            selected_banner_color: '',

            title: '',
            description: '',

            dialog_preview_banner: false,
            loading_update_configuration: false
        }
    },
    components: {BannerCommunication},
    created() {
        this.getConfigurationBanner();
    },
    methods: {
        getConfigurationBanner() {
            this.axios.get(
                REFERENTIAL_GLOBALS + "?type=information_banner"
            ).then((success) => {
                this.id_referential_global = success.data[0].id;
                this.enable_banner = success.data[0].is_enable;

                let jsonConfiguration = JSON.parse(success.data[0].value);
                this.icon = jsonConfiguration.icon;
                this.selected_text_color = jsonConfiguration.text_color;
                this.selected_banner_color = jsonConfiguration.banner_color;
                this.title = jsonConfiguration.title;
                this.description = jsonConfiguration.description;
            }).catch(() => {
                this.showGlobalSnackbar('error', this.$t('global.error'));
            }).finally(() => {
                this.loading_configuration_banner = false
            });
        },

        updateBanner() {
            this.loading_update_configuration = this.loading_configuration_banner = true;

            this.axios.patch(REFERENTIAL_GLOBALS + '/' + this.id_referential_global, {
                value: JSON.stringify({
                    icon: this.icon,
                    text_color: this.selected_text_color,
                    banner_color: this.selected_banner_color,
                    title: this.title,
                    description: this.description
                }),
                is_enable: this.enable_banner
            }).then(() => {
                this.showGlobalSnackbar('success', this.$t('global.success_to_edit'));
                this.$store.commit('setRefreshInformationBanner', this.$store.state.switch_state++);
            }).catch(() => {
                this.showGlobalSnackbar('error', this.$t('global.failure_to_update'));
            }).finally(() => {
                this.loading_update_configuration = this.loading_configuration_banner = false;
            });
        },
    }
}
</script>

<style>
.banner-description {
    white-space: pre-line;
}
</style>
