var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"three-dimensional-curve-settings-modal"}},[_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.three_dimensional_curve_settings_open_dialog),callback:function ($$v) {_vm.three_dimensional_curve_settings_open_dialog=$$v},expression:"three_dimensional_curve_settings_open_dialog"}},[_c('v-card',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{ref:"form"},[_c('v-card-title',{staticClass:"headline"},[_vm._v("\n                        "+_vm._s(_vm.title === 'add' ? _vm.$t('three_dimensional_curve.add_three_d_curve') : _vm.$t('three_dimensional_curve.curve_settings'))+"\n                    ")]),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-card-text',[_c('validation-provider',{attrs:{"name":_vm.$t('global.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-0",attrs:{"label":_vm.$t('global.name'),"prepend-icon":"mdi-label-outline","error-messages":errors,"solo":""},model:{value:(_vm.label),callback:function ($$v) {_vm.label=$$v},expression:"label"}})]}}],null,true)}),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('h3',{staticClass:"mt-3 mb-2"},[_vm._v(_vm._s(_vm.$t('global.x_axis')))]),_vm._v(" "),_c('validation-provider',{attrs:{"name":_vm.$t('global.devices'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.virtual_devices,"item-text":"label","prepend-icon":"mdi-chemical-weapon","label":_vm.$t('global.devices'),"error-messages":errors,"return-object":"","solo":""},on:{"change":function($event){return _vm.getMetricsOfVirtualDevice1()}},model:{value:(_vm.three_dimensional_curve_detail.virtual_device_1),callback:function ($$v) {_vm.$set(_vm.three_dimensional_curve_detail, "virtual_device_1", $$v)},expression:"three_dimensional_curve_detail.virtual_device_1"}})]}}],null,true)}),_vm._v(" "),_c('validation-provider',{attrs:{"name":_vm.$t('global.devices'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.virtual_device_metric_1_types,"item-text":"metric_name","item-value":"metric_uuid","prepend-icon":"mdi-tape-measure","label":_vm.$t('modal_comparison_curves.physical_value'),"error-messages":errors,"disabled":_vm.disabled_virtual_device_metric_1_types,"loading":_vm.loading_virtual_device_metric_1_types,"solo":""},model:{value:(_vm.metric_name_1),callback:function ($$v) {_vm.metric_name_1=$$v},expression:"metric_name_1"}})]}}],null,true)}),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('h3',{staticClass:"mt-3 mb-2"},[_vm._v(_vm._s(_vm.$t('global.y_axis')))]),_vm._v(" "),_c('validation-provider',{attrs:{"name":_vm.$t('global.devices'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.virtual_devices,"item-text":"label","prepend-icon":"mdi-chemical-weapon","label":_vm.$t('global.devices'),"error-messages":errors,"return-object":"","solo":""},on:{"change":function($event){return _vm.getMetricsOfVirtualDevice2()}},model:{value:(_vm.three_dimensional_curve_detail.virtual_device_2),callback:function ($$v) {_vm.$set(_vm.three_dimensional_curve_detail, "virtual_device_2", $$v)},expression:"three_dimensional_curve_detail.virtual_device_2"}})]}}],null,true)}),_vm._v(" "),_c('validation-provider',{attrs:{"name":_vm.$t('global.devices'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.virtual_device_metric_2_types,"item-text":"metric_name","item-value":"metric_uuid","prepend-icon":"mdi-tape-measure","label":_vm.$t('modal_comparison_curves.physical_value'),"error-messages":errors,"disabled":_vm.disabled_virtual_device_metric_2_types,"loading":_vm.loading_virtual_device_metric_2_types,"solo":""},model:{value:(_vm.metric_name_2),callback:function ($$v) {_vm.metric_name_2=$$v},expression:"metric_name_2"}})]}}],null,true)}),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('h3',{staticClass:"mt-3 mb-2"},[_vm._v(_vm._s(_vm.$t('global.z_axis')))]),_vm._v(" "),_c('validation-provider',{attrs:{"name":_vm.$t('global.devices'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.virtual_devices,"item-text":"label","prepend-icon":"mdi-chemical-weapon","label":_vm.$t('global.devices'),"error-messages":errors,"return-object":"","solo":""},on:{"change":function($event){return _vm.getMetricsOfVirtualDevice3()}},model:{value:(_vm.three_dimensional_curve_detail.virtual_device_3),callback:function ($$v) {_vm.$set(_vm.three_dimensional_curve_detail, "virtual_device_3", $$v)},expression:"three_dimensional_curve_detail.virtual_device_3"}})]}}],null,true)}),_vm._v(" "),_c('validation-provider',{attrs:{"name":_vm.$t('global.devices'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.virtual_device_metric_3_types,"item-text":"metric_name","item-value":"metric_uuid","prepend-icon":"mdi-tape-measure","label":_vm.$t('modal_comparison_curves.physical_value'),"error-messages":errors,"disabled":_vm.disabled_virtual_device_metric_3_types,"loading":_vm.loading_virtual_device_metric_3_types,"solo":""},model:{value:(_vm.metric_name_3),callback:function ($$v) {_vm.metric_name_3=$$v},expression:"metric_name_3"}})]}}],null,true)}),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('h3',{staticClass:"mt-3 mb-2"},[_vm._v(_vm._s(_vm.$t('three_dimensional_curve.influence_factor')))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$t('three_dimensional_curve.influence_factor_hint')))]),_vm._v(" "),_c('validation-provider',{attrs:{"name":_vm.$t('global.devices'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.virtual_devices,"item-text":"label","prepend-icon":"mdi-chemical-weapon","label":_vm.$t('global.devices'),"error-messages":errors,"return-object":"","solo":""},on:{"change":function($event){return _vm.getMetricsOfVirtualDevice()}},model:{value:(_vm.virtual_device_metric_id),callback:function ($$v) {_vm.virtual_device_metric_id=$$v},expression:"virtual_device_metric_id"}})]}}],null,true)}),_vm._v(" "),_c('validation-provider',{attrs:{"name":_vm.$t('global.devices'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.virtual_device_metric_types,"item-text":"metric_name","item-value":"metric_uuid","prepend-icon":"mdi-tape-measure","label":_vm.$t('modal_comparison_curves.physical_value'),"error-messages":errors,"disabled":_vm.disabled_virtual_device_metric_types,"loading":_vm.loading_virtual_device_metric_types,"solo":""},model:{value:(_vm.metric_name),callback:function ($$v) {_vm.metric_name=$$v},expression:"metric_name"}})]}}],null,true)})],1),_vm._v(" "),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{on:{"click":function($event){return _vm.closeEditThreeDimensionalCurveModal()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close")]),_vm._v("\n                            "+_vm._s(_vm.$t('global.close'))+"\n                        ")],1),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":function($event){$event.preventDefault();return _vm.addOrUpdate3dCurve()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v("\n                            "+_vm._s(_vm.$t('global.validation'))+"\n                        ")],1)],1)],1)]}}])})],1)],1),_vm._v(" "),_c('GlobalSnackbar')],1)}
var staticRenderFns = []

export { render, staticRenderFns }