<template>
  <div id="title-toolbar">
      <v-row class="mb-3">
          <v-col style="background-color: white">
              <v-subheader>
                  <p class="title mb-0">{{ title }}</p>
              </v-subheader>
          </v-col>
      </v-row>
  </div>
</template>

<script>
export default {
    name: 'TitleToolbar',
    props: {
        title: {
            type: String,
            required: true
        }
    }
}
</script>
