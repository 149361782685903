<template>
    <div id="edit-contract">
        <v-dialog v-model="edit_contract_dialog" width="750">
            <v-card>
                <v-card-title>
                    {{ $t('contracts_datatable.edit') }}
                </v-card-title>

                <v-divider></v-divider>

                <validation-observer ref="observer" v-slot="{ invalid }">
                    <v-form ref="form">
                        <v-card-text>
                            <validation-provider :name="$t('contract_avenant.label')" rules="required|max:90"
                                                 v-slot="{ errors }"
                            >
                                <v-text-field
                                    :label="$t('contract_avenant.label')"
                                    :messages="$t('contract_avenant.label_message')"
                                    v-model="edit_contract.label"
                                    class="mt-3" filled
                                    :error-messages="errors"
                                >
                                </v-text-field>
                            </validation-provider>

                            <p class="mt-5 color-primary font-weight-bold">{{ $t("new_contract.contract_type") }}</p>

                            <template v-for="referential_contract_type in contract_referential">
                                <div class="d-flex flex-row flex-wrap">
                                    <span class="mt-7 ml-6 label-width">{{ referential_contract_type.label }} :</span>
                                    <span class="mt-7 ml-6">{{ $t('contract_avenant.device_number') }}</span>

                                    <validation-provider :name="$t('contract_avenant.device_number')"
                                                         rules="numeric|max_value:200" v-slot="{ errors }"
                                    >
                                        <v-text-field
                                            class="mt-3 ml-3 device-number-field"
                                            type="number" filled clearable
                                            v-model="edit_contract.chosen_contract_referential[referential_contract_type.id]"
                                            :error-messages="errors"
                                        >
                                        </v-text-field>
                                    </validation-provider>
                                </div>
                                <v-divider></v-divider>
                            </template>

                            <v-row class="ml-2 mt-2 mb-5 font-10">
                                <v-icon left color="primary" size="15">mdi-alert-circle</v-icon>
                                <span>{{ $t('contract_avenant.contract_type_number_hint') }}</span>
                            </v-row>

                            <validation-provider :name="$t('new_contract.billing_period')"
                                                 rules="required|numeric|alpha_dash" v-slot="{ errors }"
                            >
                                <v-autocomplete
                                    class="mt-7"
                                    v-model="edit_contract.invoicing_period"
                                    :items="invoicing_periods"
                                    :label="$t('new_contract.billing_period')"
                                    :error-messages="errors"
                                    filled
                                >
                                </v-autocomplete>
                            </validation-provider>

                            <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                :return-value.sync="edit_contract.date_renew"
                                transition="scale-transition" min-width="auto" offset-y
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <validation-provider :name="$t('new_contract.next_withdrawal')" rules="required"
                                                         v-slot="{ errors }">
                                        <v-text-field
                                            v-model="edit_contract.date_renew"
                                            :label="$t('new_contract.next_withdrawal')"
                                            prepend-inner-icon="mdi-calendar"
                                            readonly filled
                                            v-on="on" v-bind="attrs"
                                            :error-messages="errors"
                                        ></v-text-field>
                                    </validation-provider>
                                </template>
                                <v-date-picker
                                    v-model="edit_contract.date_renew"
                                    no-title scrollable
                                    :allowed-dates="disablePastDates()"
                                >
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="menu = false">
                                        {{ $t('global.cancel') }}
                                    </v-btn>
                                    <v-btn text color="primary" @click="$refs.menu.save(edit_contract.date_renew)">
                                        {{ $t('global.validation') }}
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-card-text>

                        <v-card-actions class="justify-center">
                            <v-btn text link @click="edit_contract_dialog = false">
                                {{ $t('global.cancel') }}
                            </v-btn>
                            <v-btn color="primary" :disabled="invalid" @click="updateContract()">
                                {{ $t('global.validation') }}
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </validation-observer>
            </v-card>
        </v-dialog>
        <global-snackbar/>
    </div>
</template>

<script>
import {CONTRACTS, REFERENTIAL_CONTRACT_TYPES} from "../../../api";
import GlobalSnackbar from "../../../global/components/GlobalSnackbar";

export default {
    name: "EditContract",
    components: {GlobalSnackbar},
    data() {
        return {
            edit_contract: {
                id: 0,
                label: '',
                chosen_contract_referential: [],
                contract_types: [],
                date_renew: '',
                invoicing_period: '',
            },
            edit_contract_dialog: false,
            invalid: false,
            contract_referential: [],
            menu: false,
        }
    },
    created() {
        this.getReferentialContractTypes();
    },
    methods: {
        getReferentialContractTypes() {
            this.axios.get(
                REFERENTIAL_CONTRACT_TYPES
            ).then((success) => {
                this.contract_referential = success.data;
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('global.error'));
            });
        },

        editDialogOpen(item) {
            this.edit_contract_dialog = true;
            this.edit_contract.id = item.id
            this.edit_contract.label = item.label;
            this.edit_contract.invoicing_period = item.invoicing_period;
            this.edit_contract.date_renew = item.date_renew;
            this.edit_contract.contract_types = item.contract_types;

            this.contract_referential.forEach((contract_referential) => {
                item.contract_types.forEach((contract_type) => {
                    if (contract_type.referential_contract_type_id === contract_referential.id) {
                        this.edit_contract.chosen_contract_referential[contract_referential.id] = contract_type.value_max
                    }
                });
            });
        },

        disablePastDates(val) {
            return val >= new Date().toISOString().substring(0, 10);
        },

        updateContract() {
            this.$refs.form.validate();
            let cancelContractUpdate=false;
            this.edit_contract.contract_types.forEach((contract_type, index) => {
                if (contract_type.associated_devices_count > this.edit_contract.chosen_contract_referential[index]) {
                    cancelContractUpdate = true;
                }
            });

            if(cancelContractUpdate){
                this.manageError(0, this.$t('error_display.error_contract_type_max_value'));
            }
            else{
                this.axios.patch(
                    CONTRACTS + '/' + this.edit_contract.id, this.edit_contract
                ).catch((error) => {
                    this.manageError(error.response.status, this.$t('global.error'));
                }).finally(() => {
                    this.edit_contract_dialog = false;
                    this.$emit('contract-updated');
                });
            }
        },
    }
}
</script>

<style scoped>
.device-number-field {
    max-width: 180px;
}

.label-width {
    width: 180px;
}
</style>
