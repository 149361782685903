<template>
    <div id="dashboard" v-if="$store.state.access_level >= 200 || $store.state.access_level <= 100 " style="text-align: center;">
        
    </div>
</template>

<script>

export default {
    name: "DashboardLoading",

    created() {
        this.$router.push({name: 'dashboard'});
    }
}
    
</script>