<template>
    <div id="modal-operation-timeline">
        <v-dialog v-model="dialog_timeline_view" scrollable max-width="700px">
            <v-card :loading="loading_operation_timelines">
                <v-card-title>
                    <span class="headline">{{ $t('operation.operation_history') }}</span>
                    <span class="headline" v-if="type_operation !== null">&nbsp;- {{ type_operation }}</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-timeline v-if="operation_timelines.length > 0">
                        <v-timeline-item v-for="operation_timeline in operation_timelines"
                                         :key="operation_timeline.id"
                                         :color="getTypeOperationColor(operation_timeline.type)">

                            <span slot="opposite">
                                <span>
                                    {{ $t('global.type') }} :
                                    {{ getTypeOperationLabel(operation_timeline.type) }}
                                </span><br/>
                                <b>{{ operation_timeline.date_operation_timeline }}</b>
                            </span>

                            <v-card class="elevation-2">
                                <v-card-title class="headline">
                                    {{
                                        operation_timeline.user.last_name + " " +
                                        operation_timeline.user.first_name
                                    }}
                                </v-card-title>
                                <v-card-text>
                                    <v-col v-html="operation_timeline.comment"></v-col><br/>

                                    <v-btn v-if="operation_timeline.is_file" :href="operation_timeline.path_file"
                                           download text block outlined class="text-truncate">
                                        <v-icon left>mdi-file</v-icon>
                                        {{ operation_timeline.path_file.replace(/^.*[\\\/]/, '') }}
                                    </v-btn>

                                    <v-img v-if="operation_timeline.is_image" aspect-ratio="1"
                                           :src="operation_timeline.path_image" contain>
                                    </v-img>
                                </v-card-text>
                            </v-card>

                        </v-timeline-item>
                    </v-timeline>

                    <template v-else><br/>
                        <h2>{{ $t('operation.empty_operation_timelines') }}</h2>
                    </template>
                </v-card-text>

                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn @click="dialog_timeline_view = false">
                        <v-icon left>mdi-close</v-icon>
                        {{ $t('global.close') }}
                    </v-btn>
                </v-card-actions>
            </v-card>

            <v-snackbar
                v-model="snackbar"
                :color="snackbar_type">
                {{ snackbar_text }}
                <template v-slot:action="{ attrs }">
                    <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                </template>
            </v-snackbar>
        </v-dialog>
    </div>
</template>

<script>
import {OPERATION_TIMELINES, OPERATIONS} from "../../../../api";
import moment from "moment";

export default {
    name: "ModalDisplayTimelineOperation",

    data() {
        return {
            operation_timelines: [],
            loading_operation_timelines: false,
            dialog_timeline_view: false,
            operation_id: null,
            type_operation: null,
        }
    },
    methods: {
        openDialogViewOperationTimelines(id_operation, type_operation) {
            this.operation_id = id_operation;
            this.type_operation = type_operation;
            this.getOperationTimelines();
        },

        getOperationTimelines() {
            this.loading_operation_timelines = true;
            this.axios.get(
                OPERATIONS + "/" + this.operation_id + "/operation_timelines"
            ).then((success) => {
                success.data.forEach(operation_timeline => {
                    operation_timeline.date_operation_timeline = moment(operation_timeline.date_operation_timeline).format('DD/MM/YYYY k:mm')
                });

                this.operation_timelines = success.data;
                this.dialog_timeline_view = true;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            }).finally(() => {
                this.loading_operation_timelines = false;
            });
        },

        getTypeOperationLabel(type) {
            let typeLabel = "";

            switch (type) {
                case 1:
                    typeLabel = this.$t('operation.operation_create');
                    break;
                case 2:
                    typeLabel = this.$t('operation.operation_update');
                    break;
                case 3:
                    typeLabel = this.$t('operation.message_add');
                    break;
                case 4:
                    typeLabel = this.$t('operation.expert_ask');
                    break;
                case 5:
                    typeLabel = this.$t('operation.reminder');
                    break;
                case 6:
                    typeLabel = this.$t('operation.close');
                    break;
                default:
                    typeLabel = this.$t('operation.undefined_type');
            }

            return typeLabel;
        },

        getTypeOperationColor(type) {
            let typeColor = "";

            switch (type) {
                case 1:
                case 6:
                    typeColor = "green";
                    break;
                case 2:
                    typeColor = "yellow";
                    break;
                case 3:
                    typeColor = "primary";
                    break;
                case 4:
                    typeColor = "orange";
                    break;
                case 5:
                    typeColor = "purple";
                    break;
                default:
                    typeColor = "black";
            }

            return typeColor;
        }
    }
}
</script>
