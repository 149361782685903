<template>
    <v-col class="col col-12 mt-0 pt-0 pa-0" style="margin-top:0px!important;padding:0px;position:relative; top:-12px;">
        <div v-if="access_level < 100">
            <!-- MACHINE MENU PACKAGE -->
            <div style="padding:0px 12px;">
                <v-col class="pl-5 pr-5" style="background-color:#001047;color:#FFFFFF;border-radius: 0px 0px 5px 5px;">
                    <!-- TOP TITLE -->
                    <v-row class="pl-0 pt-5 mb-5" style="height: 52px; padding:5px;">
                        <!-- PAGE TITLE -->
                        <v-subheader class="v-subheader col theme--light">
                            <!------------------- Info bulle ---------------------->
                            <v-tooltip bottom dark class="mr-2 ml-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 mr-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                                        <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                                    </v-btn>
                                </template>
                                <template v-slot:default>
                                    <div style="min-width: 200px;position:relative;z-index: 10;">
                                        <strong>Page [Gestion des comptes clients]</strong>
                                        <p>Cette page à été spécialement développé suivant les process d'interventions de Techview.</br>
                                            Dans le cadre de nos campagnes OFFLINE (sur le terrain) et ONLINE (à distance) nous souhaitons que tous nos clients soient informés en temps réel de l'avancement de leurs projets.</br></br>
                                            En ce sens toutes les interventions qui seront pévues et réalisées seront accessible depuis cette page.</br>
                                            Cliquer sur le titre d'une campagne vous permettra de suivre en détail l'avancement des rapports et de les consulter.
                                        </p>
                                    </div>
                                </template>
                            </v-tooltip>
                            <p class="title mb-0 color-white" style="color: white!important;">
                                <div style="color:#FFFFFFb3;margin-right:4px;">Clients > </div><div @click="infoToggler()" style="color:#FFFFFF;font-weight:bold;"> Gestion des comptes clients ({{ active_professionals_count }}/{{ global_professionals_count }})</div>
                            </p>
                        </v-subheader>

                        <!-- GLOBAL ACTIONS -->
                        <v-col class="row mt-1 col-auto pa-0 pr-5">
                            <!-- Bouton ajout nouveau client -->
                            <add-new-client></add-new-client>
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn text icon v-on="on" @click="getData()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                        <v-icon color="white">mdi-tag-multiple</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('global.1_5_2') }}</span>
                            </v-tooltip>
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn text icon v-on="on" @click="getData()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                        <v-icon color="white">mdi-refresh</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('campain.refresh') }}</span>
                            </v-tooltip>
                            
                            <!--
                            <v-tooltip v-if="infoToggle == true" bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn text icon v-on="on" @click="infoToggler()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                        <v-icon color="white">mdi-minus-box-multiple</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machines.refresh') }}</span>
                            </v-tooltip>

                            <v-tooltip v-if="infoToggle == false" bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn text icon v-on="on" @click="infoToggler()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                        <v-icon color="white">mdi-plus-box-multiple</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machines.refresh') }}</span>
                            </v-tooltip>
                            -->
                        </v-col>
                    </v-row>

                    <!-- MACHINE INFOS (UNUSED) -->
                    <v-col class="mb-5" v-if="infoToggle == true" style="min-height: 250px; background-color:#e8edff;border-radius:5px;">
                        <v-row>

                            <!-- insert HEADER sub content HERE -->

                        </v-row>
                    </v-col>
                    
                    <!-- MACHINE LINKS -->
                    <v-row v-if="infoToggle == false" style="border-top: 1px solid #ffffff1c;">
                        <v-row class="pb-2" style="margin:0px;">
                            <v-row class="ml-3 pt-4 pb-0 rounded" style="height:68px;">
                                <!-- BUTTON EXAMPLE 
                                <v-tooltip bottom dark class="mr-2">
                                    <template v-slot:activator="{ on }">
                                        <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/events'}" dusk="logout-button">
                                            <v-icon color="white">mdi-alert</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('machine.alerts') }}</span>
                                </v-tooltip>
                                -->

                                <div
                                    v-for="filters in professional_offer"
                                    :key="filters.level"
                                    class="mr-0"
                                    style="height:40px; padding: 4px 0px;"
                                >
                                    <div
                                    class="ma-0 v-chip v-chip--clickable v-chip--link v-chip--no-color theme--light v-size--default"
                                    style="
                                        color: white;
                                        font-weight: 400;
                                        display: flex;
                                        justify-content: left;
                                        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.29);
                                        background-color: #ffffff8c;
                                        font-size: 14px;
                                        height: 32px;
                                        margin-right: 12px !important;
                                    "
                                    @click="setDataFiltered(filters.level)"
                                    >
                                    <div
                                        v-if="filters.level_name !== 'Tout voir'"
                                        class="mr-2"
                                        style="color: white;min-width: 30px; min-height: 20px; border-radius:20px;font-weight: bold;text-align: center;"
                                        :style="{backgroundColor: filters.level_color}"
                                    >{{filters.count}}</div>
                                    {{filters.level_name}}
                                    </div>
                                </div>
                            </v-row>     
                        </v-row>
                    </v-row>

                    <v-row v-if="infoToggle == true">
                        <v-row class="pb-2" style="margin:0px;">
                            <v-row class="ml-3 pt-4 pb-0 rounded" style="">
                                <!-- BUTTON EXAMPLE 
                                <v-tooltip bottom dark class="mr-2">
                                    <template v-slot:activator="{ on }">
                                        <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/events'}" dusk="logout-button">
                                            <v-icon color="white">mdi-alert</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('machine.alerts') }}</span>
                                </v-tooltip>
                                -->

                                <div
                                    v-for="filters in professional_offer"
                                    :key="filters.level"
                                    class="mr-0"
                                    style="height:40px; padding: 4px 0px;"
                                >
                                    <div
                                    class="ma-0 v-chip v-chip--clickable v-chip--link v-chip--no-color theme--light v-size--default"
                                    style="
                                        color: white;
                                        font-weight: 400;
                                        display: flex;
                                        justify-content: left;
                                        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.29);
                                        background-color: #ffffff8c;
                                        font-size: 14px;
                                        height: 32px;
                                        margin-right: 12px !important;
                                    "
                                    @click="setDataFiltered(filters.level)"
                                    >
                                    <div
                                        v-if="filters.level_name !== 'Tout voir'"
                                        class="mr-3"
                                        style="color: white;min-width: 30px; min-height: 20px; border-radius:20px;font-weight: bold;text-align: center;"
                                        :style="{backgroundColor: filters.level_color}"
                                    >{{filters.count}}</div>
                                    {{filters.level_name}}
                                    </div>
                                </div>
                            </v-row>     
                        </v-row>
                    </v-row>
                </v-col>
            </div>
                
            <v-col color="white" class="ml-0 mr-4 mt-3 pl-3 col">
                <v-data-table
                    :headers="headers"
                    :items="professionals"
                    class="elevation-1 datatable-operation"
                    :items-per-page="-1"
                    :loading="loading_datatable"
                    :search="search"
                    @click:row="openDialogShow"
                >
                    <!-- TOP -->
                    <template v-slot:top>
                        <v-toolbar flat color="white" class="pt-3">
                            <v-col class="col-8">
                                <div class="elevation-0 pl-5 pt-2 pb-2" >
                                    <h4>Liste des clients</h4>
                                </div>
                            </v-col>
                            <v-col style="display: flex; justify-content: end;">
                                <v-layout class="justify-end mt-7 max-width-300">
                                    <v-text-field
                                        v-model="search"
                                        append-icon="mdi-magnify"
                                        :label="$t('global.search')"
                                    ></v-text-field>
                                </v-layout>
                            </v-col>
                            
                        </v-toolbar>
                    </template>

                    <!-- TYPES -->
                    <template v-slot:item.offer="{ item }">
                        <div v-if="item.offer === 0" style="width: 90px; color: rgb(255, 0, 255); text-transform: uppercase; text-align: center; display: flex; align-items: center; justify-content: center; font-weight: 800;">Online</div>
                        <div v-if="item.offer === 1" style="width: 90px; color: rgb(0 212 212); text-transform: uppercase; text-align: center; display: flex; align-items: center; justify-content: center; font-weight: 800;">Offline</div>
                        <div v-if="item.offer === 2" style="width: 90px; color: rgb(204 51 102); text-transform: uppercase; text-align: center; display: flex; align-items: center; justify-content: center; font-weight: 800;">Custom</div>
                        <div v-if="item.offer === 3" style="width: 90px; color: rgb(153 0 255); text-transform: uppercase; text-align: center; display: flex; align-items: center; justify-content: center; font-weight: 800;">Online & Offline</div>
                    </template>

                    <!-- LOGOS -->
                    <template v-slot:item.logo="{ item }">
                        <div v-if="item.logo !== null" class="col mr-2 pa-0">
                            <img :src="item.logo" :alt="item.name" style="height: auto;width: 90px;">
                        </div>   
                        <div v-else class="row col-0 ma-auto mr-10 ml-10 pa-2" style="background-color: #8080808a;border-radius: 75px;max-width: 55px;">
                            <img src="https://techview.fr/wp-content/uploads/2023/08/techview_logo_white-Copie.png" :alt="item.name" style="height: auto;width: 40px;opacity: 0.5;margin:auto;">
                        </div>
                    </template>

                    <!-- INFORMATIONS -->
                    <template v-slot:item.name="{ item }">
                        <div class="ma-auto" style="padding: 20px 0;">
                            <div style="font-weight: bold;">{{item.name}}</div>
                            <div v-if="access_level <= 100">ID client : {{item.id}}</div>
                            <div style="font-size: 12px;">{{ $t('professionals.ref') }}{{item.uid}}</div>
                            <div style="font-size: 12px;">{{ $t('professionals.created') }}{{item.created_at}}</div>
                            <div v-for="contract in contracts" :key="contract">
                                <div class="mt-1" v-if="item.id === contract.professional_id" style="font-size: 12px;font-weight:bold;">{{ $t('professionals.number_contract') }} {{contract.contract_number}}</div>
                                <div v-if="item.id === contract.professional_id" style="font-size: 12px;">{{ $t('professionals.etablished_contract') }} {{contract.date_renew}} {{ $t('professionals.duration') }} {{contract.invoicing_period}} {{ $t('professionals.month') }}</div>
                            </div>
                        </div>
                    </template>

                    <!-- INFORMATIONS COMPLEMENTAIRES -->
                    <template v-slot:item.complementaires="{ item }">
                        <div class="col pl-10" style="border-left: 1px solid #d3d3d36e; font-size:12px;">
                            <!--<div style="font-weight: bold;font-size: 13px;">Informations complémentaires :</div> -->
                            <v-row class="row col pa-0 pt-1 pb-1 ma-0 ml-2">
                                <v-icon class="mr-2" style="font-size: 16px;">mdi-email</v-icon>
                                <p class="ml-2 mb-0">{{ item.mail }}</p>
                            </v-row>
                            <v-row class="row col pa-0 pb-1 ma-0 ml-2">
                                <v-icon class="mr-2" style="font-size: 16px;">mdi-phone-outline</v-icon>
                                <p class="ml-2 mb-0">{{ item.phone }}</p>
                            </v-row>
                            <v-row class="row col pa-0 ma-0 ml-2">
                                <v-icon class="mr-2" style="font-size: 16px;">mdi-map-marker</v-icon>
                                <p class="ml-2 mb-0">{{ item.address }}</p>
                            </v-row>   
                            <v-row v-for="contract in contracts" :key="contract" style="font-weight: 800;">
                                <v-icon v-if="item.id === contract.professional_id" color="primary" class="mr-2 ml-5" style="font-size: 16px;">mdi-ticket</v-icon>
                                <p v-if="item.id === contract.professional_id" class="ml-2 mb-0">{{ contract.expert_ticket }} tickets d'expertises.</p>
                            </v-row>
                        </div>
                        
                    </template>

                    <!-- TAGS -->
                    <template v-slot:item.tags="{ item }">
                        <v-chip-group class="row">
                            <v-chip v-for="tag in getProfessionalTags(item)" class="pl-3 pr-3" link :key="tag">
                                {{ tag }}
                            </v-chip>
                        </v-chip-group>
                    </template>

                    <!-- ACTIONS -->
                    <template v-slot:item.actions="{ item }">
                        <v-col class="col-1 pa-2" style="max-width: 80px;">
                            <!-- Bouton 1 : Sélectionner -->
                            <v-tooltip bottom dark>
                                <template v-slot:activator="{ on }">
                                    <v-btn class="col-0" text icon v-on="on" @click="switchProfessional(item.id)">
                                        <v-icon color="primary">mdi-vector-link</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('professionals.select') }}</span>
                            </v-tooltip>

                            <!-- Bouton 2 : Modifier -->
                            <v-tooltip bottom dark>
                                <template v-slot:activator="{ on }">
                                    <v-btn class="col-0" text icon v-on="on" @click="openDialogShow(selectedProfessional)">
                                        <v-icon color="primary">mdi-circle-edit-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('professionals.edit') }}</span>
                            </v-tooltip>

                            <!-- Bouton 3 : Désactiver -->
                            <v-tooltip bottom dark>
                                <template v-slot:activator="{ on }">
                                    <v-btn class="col-0" text icon v-on="on" @click="setDataTrash(selectedProfessional, 1)">
                                        <v-icon color="red">mdi-delete-alert</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('professionals.disable') }}</span>
                            </v-tooltip>
                        </v-col>
                    </template>
                </v-data-table>
            </v-col>

            <ul>
                <!-- DATA LIST VOID -->
                <li v-if="!professionals_available" class="rounded row notification_card mb-1 pa-10" style="border: 2px dotted #80808059;color: grey;justify-content: center;">
                    <div style="color:grey;text-align: center;">Aucun client actif trouvé. <br> Consultez ci dessous la liste des clients inactifs.</div>
                </li>
                <!-- TRASHED MENU -->
                <li v-if="professionals_available" class="row col-12 pb-5 mb-5 mt-10" style="text-align:center;display: flex;justify-content: center;border-bottom: 1px solid #8080801c;">
                    Clients désactivés
                </li>
                <!-- TRASHED LIST -->
                <li class="rounded pointer row notification_card mb-1" v-for="professional in professionals_deactivated" :key="professional.id" style="border: solid 1px #80808010;background-color: #d9d9d9cc;color: grey;">                          
                    <div v-if="deprecated_available" class="row pa-3">
                        <!--CHIPS-->
                        <div v-for="offer in professional_offer" :key="offer">
                            <div  v-if="offer.level == professional.offer && offer.level_name !== 'Tout voir'" @click="openDialogShow(professional)" class="col-0 mr-5" style="color:white;font-weight:800;display:flex;justify-content:center;text-shadow:2px 2px 4px rgba(0, 0, 0, 0.29); height:100%;min-width: 8px;border-radius:2px 0px 0px 2px;" :style="{backgroundColor: offer.level_color}"></div>
                        </div>
                        <!--LOGO-->
                        <div @click="openDialogShow(professional)" v-if="professional.logo !== null" class="col col-1 ma-auto mr-2 pa-5">
                            <img :src="professional.logo" :alt="professional.name" style="height: auto;width: 90px;">
                        </div>   
                        <div @click="openDialogShow(professional)" v-else class="row col-0 ma-auto mr-10 ml-10 pa-2" style="background-color: #8080808a;border-radius: 75px;max-width: 55px;">
                            <img src="https://techview.fr/wp-content/uploads/2023/08/techview_logo_white-Copie.png" :alt="professional.name" style="height: auto;width: 40px;opacity: 0.5;margin:auto;">
                        </div>    
                        
                        <!--INFOS-->
                        <div @click="openDialogShow(professional)" class="col-3 ma-auto">
                            <div style="font-weight: bold;">{{professional.name}}</div>
                            <div style="font-size: 12px;">Ref - {{professional.uid}}</div>
                            <div style="font-size: 12px;">Créé {{professional.created_at}}</div>
                            <div v-for="contract in contracts" :key="contract">
                                <div class="mt-1" v-if="professional.id === contract.professional_id" style="font-size: 12px;font-weight: bold;">Contrat n° {{contract.contract_number}}</div>
                                <div v-if="professional.id === contract.professional_id" style="font-size: 12px;">Durée du contrat {{contract.date_renew}} ({{contract.invoicing_period}} mois)</div>
                            </div>
                        </div>
                        <div @click="openDialogShow(professional)" class="col pl-10" style="border-left: 1px solid #d3d3d36e; font-size:12px;">
                            <div style="font-weight: bold;font-size: 13px;">Informations complémentaires :</div>                                 
                            <v-row class="row col pa-0 pt-1 pb-1 ma-0 ml-2">
                                <v-icon class="mr-2" style="font-size: 16px;">mdi-email</v-icon>
                                <p>{{ professional.mail }}</p>
                            </v-row>
                            <v-row class="row col pa-0 pb-1 ma-0 ml-2">
                                <v-icon class="mr-2" style="font-size: 16px;">mdi-phone-outline</v-icon>
                                <p>{{ professional.phone }}</p>
                            </v-row>
                            <v-row class="row col pa-0 ma-0 ml-2">
                                <v-icon class="mr-2" style="font-size: 16px;">mdi-map-marker</v-icon>
                                <p>{{ professional.address }}</p>
                            </v-row>
                            <v-row v-for="contract in contracts" :key="contract">
                                <v-icon v-if="professional.id === contract.professional_id" color="primary" class="mr-2 ml-5" style="font-size: 16px;">mdi-ticket</v-icon>
                                <p v-if="professional.id === contract.professional_id" class="ml-2 mb-0">{{ contract.expert_ticket }} tickets d'expertises.</p>
                            </v-row>                                       
                        </div>
                        <template class="col">
                            <v-chip-group>
                                <v-chip v-for="tag in getProfessionalTags(professional)" class="pl-3 pr-3" link :key="tag">
                                    {{ tag }}
                                </v-chip>
                            </v-chip-group>
                        </template>
                        <v-tooltip v-if="professional.trash" bottom dark>
                            <template v-slot:activator="{ on }">
                                <v-btn class="ma-auto mr-5 ml-5 col-0" text icon v-on="on" @click="setDataTrash(professional,0)" dusk="logout-button">
                                    <v-icon color="primary">mdi-delete-restore</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('professionals.restore') }}</span>
                        </v-tooltip>
                    </div>

                    <div v-if="!deprecated_available" class="row pa-3">
                        <div class="ma-4 col-1 pl-3 pr-3 v-chip v-chip--clickable v-chip--link v-chip--no-color theme--light v-size--default" style="color:transparent;font-weight:800;display:flex;justify-content:center;text-shadow:2px 2px 4px rgba(0, 0, 0, 0.29)" >Aucun client désactivé.</div>
                    </div>

                </li>
                <!-- TRASHED -->
                <li class="row notification_card mb-1" style="display: flex;flex-direction: row;justify-content: center;align-items: center;font-size: 16px;">
                    <v-tooltip bottom dark>
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" @click="viewMore()" dusk="logout-button">
                                <v-icon color="primary">mdi-arrow-down-thin-circle-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('professionals.show-more') }}</span>
                    </v-tooltip>
                </li>
            </ul>

            <!-- MODAL DETAILS -->
            <v-dialog v-model="dialog_show" max-width="900px" eager>
                <v-card style="overflow:hidden">
                    <v-card-title class="row mt-3" style="display:flex;justify-content:center;">
                        <h3 style="font-size:1.5rem;font-weight:800;" >{{ professional.name }}</h3>
                    </v-card-title>
                    <!--LOGO-->
                    <div @click="openDialogShow(professional)" v-if="professional.logo !== null" class="col col-3">
                            <img :src="professional.logo" :alt="professional.name" style="height: auto;width: 90px;position:absolute;top:10px;left:10px;">
                    </div>

                    <div @click="openDialogShow(professional)" v-else class="row col-0 ma-auto mr-10 ml-10 pa-2" style="background-color: #8080808a;border-radius: 75px;max-width: 55px;">
                        <img src="https://techview.fr/wp-content/uploads/2023/08/techview_logo_white-Copie.png" :alt="professional.name" style="height: auto;width: 40px;opacity: 0.5;margin:auto;position:absolute;top:10px;left:18px;">
                    </div>

                    <div class="col-12" style="display:flex;align-items:flex-end;justify-content: space-between;">
                        <div class="pl-3" style="font-size: 12px;font-weight: 800;">{{ $t('professionals.ref') }}{{professional.uid}}</div>
                        <p style="font-size: 12px;margin-right:30px;font-weight: 800;margin-bottom: 0!important;">{{ $t('professionals.created') }}{{ professional.created_at }}</p>
                    </div>

                    <!-- Etat de santé -->
                    <!--<div class="column">
                        <div style="display:flex;justify-content:center;align-items: baseline">
                            <p style="font-weight: bold;font-size: 15px;">{{ $t('professionals.health') }}</p>-->
                            <!------------------- Info bulle ---------------------->
                            <!--<v-tooltip bottom dark class="mr-2" style="">
                                <template v-slot:activator="{ on }">
                                    <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                                        <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                                    </v-btn>
                                </template>
                                <template v-slot:default>
                                <div style="min-width: 200px;">
                                    <strong>{{ $t('tooltip.color-title') }}</strong>
                                    <div style="display:flex;align-items:center">
                                        <div style="width: 10px;height: 10px;background-color:#4CAF50;border-radius: 50%;margin-bottom:5px"></div>
                                        <p class="pl-5">{{ $t('tooltip.green-content') }}</p>
                                    </div>
                                    <div style="display:flex;align-items:center">
                                        <div style="width: 10px;height: 10px;background-color:#FFCB05;border-radius: 50%;margin-bottom:5px"></div>
                                        <p class="pl-5">{{ $t('tooltip.yellow-content') }}</p>
                                    </div>
                                    <div style="display:flex;align-items:center">
                                        <div style="width: 10px;height: 10px;background-color:#F44336;border-radius: 50%;margin-bottom:5px"></div>
                                        <p class="pl-5">{{ $t('tooltip.red-content') }}</p>
                                    </div>
                                    <div style="display:flex;align-items:center">
                                        <div style="width: 10px;height: 10px;background-color: black;border-radius: 50%;margin-bottom:5px"></div>
                                        <p class="pl-5">{{ $t('tooltip.black-content') }}</p>
                                    </div>
                                </div>
                                </template>
                            </v-tooltip>
                        </div>-->
                        <!--
                        <div v-for="n_level in data_filters" :key="n_level" id="alertBar" style="width: 80%;height:30px;">
                            <div v-if="n_level.level === 3" id="black-bar" :style="{backgroundColor: n_level.level_color, width: n_level.width} ">{{ n_level.count }}</div>
                            <div v-if="n_level.level === 2" id="red-bar" :style="{backgroundColor: n_level.level_color, width: n_level.width} ">{{ n_level.count }}</div>
                            <div v-if="n_level.level === 1" id="yellow-bar" :style="{backgroundColor: n_level.level_color, width: n_level.width} ">{{ n_level.count }}</div>
                            <div v-if="n_level.level === 0" id="green-bar" :style="{backgroundColor: n_level.level_color, width: n_level.width} ">{{ n_level.count }}</div>
                            <div v-if="n_level.level === -1" id="grey-bar" :style="{backgroundColor: n_level.level_color, width: n_level.width} ">{{ n_level.count }}</div>
                        </div>
                    </div>-->
                    
                    <!-- Infos générales -->
                    <v-card-text>
                        <div style="border-radius:2px;background-color:#ebf4ff;padding:10px;margin:20px 0px;color:black!important">
                            <div class="row">
                                <div class="column col-6 pa-5 pl-5">
                                    <div style="font-weight: bold;font-size: 15px;">{{ $t('professionals.contract_info') }} :</div>
                                    <v-card-title class="row" style="display:flex;justify-content:space-between;">
                                        <div class="column">
                                            <div v-for="contract in contracts" :key="contract">
                                                <div class="mt-1" v-if="professional.id === contract.professional_id" style="font-size: 12px;font-weight:bold;">{{ $t('professionals.number_contract') }} {{contract.contract_number}}</div>
                                                <div v-if="professional.id === contract.professional_id" style="font-size: 12px;">{{ $t('professionals.etablished_contract') }} {{contract.date_renew}} {{ $t('professionals.duration') }} {{contract.invoicing_period}} {{ $t('professionals.month') }}</div>
                                            </div>
                                        </div>
                                    </v-card-title>
                                    <v-row class="pl-4" v-for="contract in contracts" :key="contract">
                                        <v-icon v-if="professional.id === contract.professional_id" class="mr-2 pb-1" style="font-size: 20px;">mdi-ticket</v-icon>
                                        <p class="col-11 pa-0" style="font-weight:800" v-if="professional.id === contract.professional_id">{{ contract.expert_ticket }} tickets d'expertises.</p>
                                    </v-row>
                                </div>
                                <div class="column col-6 pa-5 pl-5" style="border-left: 1px solid white;">
                                    <div style="font-weight: bold;font-size: 15px;">{{ $t('professionals.contact_info') }} :</div>
                                    <v-row class="row col pa-0 ma-0 pt-2">
                                        <v-icon class="mr-2 pb-1" style="font-size:20px;">mdi-email</v-icon>
                                        <p class="col-10 pa-0">{{ professional.mail }}</p>
                                    </v-row>
                                    <v-row class="row col pa-0 ma-0 pt-2">
                                        <v-icon class="mr-2 pb-1" style="font-size:20px;">mdi-phone-outline</v-icon>
                                        <p class="col-10 pa-0">{{ professional.phone }}</p>
                                    </v-row>
                                    <v-row class="row col pa-0 ma-0 pt-2">
                                        <v-icon class="mr-2 pb-1" style="font-size:20px;">mdi-map-marker</v-icon>
                                        <p class="col-10 pa-0">{{ professional.address }}</p>
                                    </v-row>
                                </div>
                            </div>
                        </div>
                        <v-row class="mt-5">
                            <v-col style="display:flex;flex-direction:column;align-items: center;">
                                <h4>{{ $t('notification.tags') }}</h4>
                                <template>
                                    <v-chip-group>
                                        <v-chip v-for="tag in jsonParseTags" class="pl-3 pr-3" link :key="tag">
                                            {{ tag }}
                                        </v-chip>
                                    </v-chip-group>
                                </template>
                            </v-col>
                            <v-col class="pt-7" style="display: flex;flex-direction: column;align-items: center;">
                                <v-row style="display:flex;justify-content: center;">
                                    <h4 style="text-align:center">{{ $t('notification.level') }}</h4>
                                    <!------------------- Info bulle ---------------------->
                                    <v-tooltip bottom dark class="mr-2">
                                        <template v-slot:activator="{ on }">
                                            <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                                                <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                                            </v-btn>
                                        </template>
                                        <template v-slot:default>
                                        <div style="min-width: 200px;">
                                            <p>{{ $t('tooltip.criticality-content') }}</p>
                                        </div>
                                        </template>
                                    </v-tooltip>
                                </v-row>
                                <p class="ma-4 pl-3 pr-3 v-chip v-chip--clickable v-chip--link v-chip--no-color theme--light v-size--default" style="width:150px;color:white;font-weight:800;display:flex;justify-content:center;text-shadow:2px 2px 4px rgba(0, 0, 0, 0.8)" :style="{backgroundColor: professional_state_color}">{{ professional.state }}</p>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="mb-3" style="position:absolute;top:20px;right:2px">
                        <v-btn @click="dialog_show = false;" style="color:red;max-width: 30px;padding: 0 0 0 5px;min-width: 10px!important;height: 30px!important;">
                            <v-icon left>mdi-close</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>

        <!-- FORBIDEN -->
        <div v-else>

        </div>
    </v-col>
</template>

<script>
import {PROFESSIONALS, AUTH_SWITCH, CONTRACTS, MACHINES} from "../../../api";
import moment from "moment";
import AddNewClient from "../../../client/components/AddNewClient";

export default {
    name: "CardsProfessionals",
    components: {AddNewClient},
    data() {
        return {
            access_level: this.$store.state.access_level,
           
            infoToggle: false,                  // Machine menu package //
            loading_datatable: true,            
            dialog_next_update: false,
            dialog_show: false,
            dialog_delete: false,
            deprecated_available:false,
            professionals_available:false,
            professional_trash:false,
            add_modal:false,

            search: '',
            filter_mode:"",
            professional_state_color:"#4CAF50",
            deprecated_length:5,
            global_machines_count:0,
            active_machines_count:0,
            inactive_machines_count:0,
            active_professionals_count:0,
            global_professionals_count:0,

            headers: [
                {text: "Type", value: 'offer', width: '92px'},
                {text: "Logo", value: 'logo', width: '100px', sortable: false},
                {text: "Informations", value: 'name', width:'30%'},
                {text: "Informations complémentaires", value: 'complementaires', width:'30%', sortable: false},
                {text: "Tags", value: 'tags', width:'30%'},
                {text: "Actions", value: 'actions', width:'10%', sortable: false},
            ],
            raw_machines: [],
            machines: [],
            professionals: [],
            contracts: [],
            professionals_deactivated: [],
            offer_selected: [],
            tag_selected: [],
            professional: {
                id: null,
                uid: null,
                name: '',
                mail:'',
                phone:'',
                address:'',
                offer:'',
                tags:'',
                state:'',
                logo:'',
                trash:0,
                latitude:'',
                longitude:'',
                created_at:'',
                updated_at:'',
                contract:[],
            },
            professional_offer: [
                {
                    level: "",
                    level_name: "Tout voir",
                    level_color: "#d7d7d752",
                    count:0,
                },
                {
                    level: 3,
                    level_name: "ONLINE & OFFLINE",
                    level_color: "#9900FF",
                    count:0
                },
                {
                    level: 2,
                    level_name: "CUSTOM",
                    level_color: "#CC3366",
                    count:0
                },
                {
                    level: 1,
                    level_name: "OFFLINE",
                    level_color: "#00e2e2",
                    count:0
                },
                {
                    level: 0,
                    level_name: "ONLINE",
                    level_color: "#ff00ff",
                    count:0
                },
            ],
            data_filters: [
                {
                    level: 9,
                    level_name: "Tout voir",
                    level_color: "#d7d7d752",
                    count:this.global_notifications_count,
                    width:""
                },
                {
                    level: 3,
                    level_name: "Urgence",
                    level_color: "black",
                    count:0,
                    width:""
                },
                {
                    level: 2,
                    level_name: "Importance élevée",
                    level_color: "#F44336",
                    count:0,
                    width:""
                },
                {
                    level: 1,
                    level_name: "A suivre",
                    level_color: "#FFCB05",
                    count:0,
                    width:""
                },
                {
                    level: 0,
                    level_name: "Pour information",
                    level_color: "#4CAF50",
                    count:0,
                    width:""
                },
                {
                    level: -1,
                    level_name: "Autres",
                    level_color: "grey",
                    count:0,
                    width:""
                },                
            ],
        }
        
    },
    created() {
        // RIGHTS CHECKING
        
        
        moment.locale('fr');
        this.professionals = [];
        this.professionals_deactivated = [];
        this.active_professionals_count = 0;
        this.getData();
    },
    computed: {
        jsonParseTags() {
            if (this.professional.tags != "") {
                return JSON.parse(this.professional.tags);
            }
        },
    },
    methods: {
        getData() {
            this.loading_datatable = true;
            // GETTING COMMON CUSTOMERS
            this.axios.get(PROFESSIONALS + '?trash=0&offer=' + this.filter_mode )
            .then((success) => {
                success.data.forEach(item => {
                    item.created_at = moment(item.created_at).fromNow();
                })

                this.professionals = [];
                this.contracts = [];
                this.professionals = success.data;

                if(typeof this.professionals[0] === 'undefined') {                    
                    this.professionals_available = false;
                
                }else {
                    // TO DO 
                    // Create request path to get all contracts in one request
                    //Then push it into every data from here

                    this.professionals_available = true;
                    this.getSideDatas();
                }

            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            });

            // GETTING DEACTIVATED CUSTOMERS
            this.axios.get(PROFESSIONALS + '?trash=1&offer=' + this.filter_mode )
            .then((success) => {
                success.data.forEach(item => {
                    item.created_at = moment(item.created_at).fromNow();
                })
                
                this.professionals_deactivated = [];
                this.professionals_deactivated = success.data.slice(0, this.deprecated_length);

                if(typeof this.professionals_deactivated[0] === 'undefined') {
                    this.deprecated_available = false;
                }else {
                    this.deprecated_available = true;
                }

            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            
            }).finally(() => {
                this.getDataCount();
                this.loading_datatable = false
            });         
        },
        getDataCount(level = "") {
            this.axios.get(PROFESSIONALS)
            
            .then((success) => {           
                // Reset all counts
                this.professional_offer.forEach(offer => {offer.count = 0});
                this.active_professionals_count =0;
                this.global_professionals_count =0;
                
                // Get all last counts
                success.data.forEach(item => {
                    if (item.trash === 0){
                        this.active_professionals_count = this.active_professionals_count +1;
                        this.professional_offer.forEach(offer => { 
                            if(item.offer === offer.level){
                                offer.count = offer.count +1;
                            }
                        });
                    };
                });

                this.global_professionals_count = success.data.length;
                
            }).catch(() => {
                this.showSnackbar('error', "Notification count not found.");
            });
        },
        getSideDatas(id){
            this.axios.get(CONTRACTS + '/global')

            .then((success) => {
                success.data.forEach(item => {
                    item.created_at = moment(item.created_at).format("DD/MM/YYYY");
                })
                
                this.contracts = success.data

            }).catch((error) => {
                this.manageError(error.response.status, "Contrat indisponible.");
            })
        },
        setDataFiltered (filter) {
            this.filter_mode = filter;
            this.deprecated_length=5;
            this.getData();
        },
        getProfessionalTags(professional) {
            if (professional.tags != "") {
                return JSON.parse(professional.tags);
            }
        },
        setDataTrash(data, state) {
            // Set notification seen
            if (state === 1) {
                this.axios.patch(PROFESSIONALS + '/trash/' + data.id)
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.getData();
                    this.dialog_show = false;
                })
            
            // Set notification unseen
            } if (state === 0) {
                this.axios.patch(PROFESSIONALS + '/untrash/' + data.id)
            
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.getData();
                    this.dialog_show = false;
                })
            }
        },
        getChipsColor(offer) {
            if (offer != "") {
                this.professional_offer.forEach(item => {
                    if(item.level === offer) {
                        //----------------------------------------------------------------------------------------
                        //DEBUG :
                        console.log("getChipsColor element color setted -> "+ item.level_color);

                        return item.level_color;
                    }else{
                        return "grey";
                    }
                })
            }

            //----------------------------------------------------------------------------------------
            //DEBUG :
            //console.log("getChipsColor passed on element "+ offer);

        },
        openDialogShow(professional) {
            this.professional = professional;
            this.dialog_show = true;      

            //----------------------------------------------------------------------------------------
            //DEBUG :
            //console.log("data this.notification "+ this.notification.subject);
            //console.log("data this.dialog_show "+ this.dialog_show);
        },
        getDataMachines() {
            // GETTING MACHINES
            this.loading_datatable = true;
            this.axios.get(MACHINES + '?trash=0')
            
            .then((success) => {
                this.raw_machines = success.data;
                this.machines = success.data;

            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");
            });
        },
        getCountMachines() {
            // Reset all counts
            this.data_filters.forEach(data => {data.count = 0});
            this.global_machines_count =0;
            
            // Get all last counts
            this.machines.forEach(item => {                       
                this.global_machines_count = global_machines_count+1;
                this.data_filters.forEach(filter => { 
                    if(item.alert_level == filter.level){
                        filter.count = filter.count +1;
                    }
                });
            });
        },
        // Fonction pour calculer le pourcentage total
        calculatePercentages(count) {
            return (count / this.global_machines_count) * 100
        },
        // Fonction pour créer la ligne d'alerte
        createAlertLine() {
            this.getDataMachines();
            this.getCountMachines();

            this.data_filters.forEach(level => {
                if (level.level !== 9) {
                    this.data_filters.width = this.calculatePercentages(level.count)+"%";
                }
            });
        },
        confirmDelete() {
            this.axios.delete(NOTIFICATIONS + '/delete_duplicates')
            
            .then(() => {
                this.Dialog_delete = false;
                this.showSnackbar('success', this.$t('global.success_to_delete'));
            
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_delete'));
            
            }).finally(() => {
                this.getData()
            })
        },
        customSearch (value, search, item) {
          if (Array.isArray(value)) {
              return value.some(item=>Object.values(item).some(v=>v&&v.toString().toLowerCase().includes(search)))
          }
          return Object.values(item).some(v=>v&&v.toString().toLowerCase().includes(search))
        },
        openAddShow () {
            this.add_modal = true;

            //----------------------------------------------------------------------------------------
            //DEBUG :
            //console.log("data this.notification "+ this.notification.subject);
            //console.log("data this.dialog_show "+ this.dialog_show);
        },
        // Sélectionner un client
        switchProfessional(id) {
            this.axios.put( AUTH_SWITCH + '/' + id ).then(() => {
                // Load new professional ID - chosen one
                this.refreshProfessional(id);

                if (this.$route.name === "dashboard") {
                    this.$store.dispatch('sites/load');
                    this.$store.dispatch('professionals_resume/load');
                }

            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            }).finally(()=>{
                this.$router.push({name: 'dashboard_loading'});
            })
        },
        refreshProfessional(id){
            // Store professional data to display
            this.professionals.forEach((professional) => {
                if (professional.id === id ){
                    this.$store.commit('setProfessionalData', professional);
                }
            });
        },
        viewMore() {
            this.deprecated_length = this.deprecated_length + 10;
            this.getData();
        },
        dialogNextUpdate() {
            if (this.dialog_next_update){
                this.dialog_next_update = false;
            }else{
                this.dialog_next_update = true;
            }
        },
        closeNewUpdateDialog(){
            this.setCookieVolet();
            this.dialog_new_update = false;
        },

        
        // Package
        infoToggler(){
            this.infoToggle = !this.infoToggle;
        }
    }
}
</script>

<style>
.v-slide-group__content{
    display: flex!important;
    flex-direction: row!important;
    flex-wrap: wrap!important;
    padding: 10px!important;
    width: 100%!important;
}

.v-data-table tbody tr {
  cursor: pointer;
}
</style>