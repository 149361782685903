<template>
    <div id="edit-user">
        <v-dialog v-model="dialog_edit" max-width="500px">
            <v-card>
                <validation-observer ref="edit_observer" v-slot="{ invalid }">
                    <v-form ref="edit_form">
                        <v-card-title>
                            <span class="headline">{{ $t('account_management.edit_user') }}</span>
                        </v-card-title>

                        <v-divider/>

                        <v-card-text>
                            <validation-provider :name="$t('account_management.gender')"
                                                 rules="required" v-slot="{ errors }"
                            >
                                <v-text-field solo
                                              :label="$t('account_management.gender')"
                                              v-model="edited_user.gender"
                                              prepend-icon="mdi-card-account-details-outline"
                                              :error-messages="errors"
                                />
                            </validation-provider>

                            <validation-provider :name="$t('account_management.first_name')"
                                                 rules="required" v-slot="{ errors }"
                            >
                                <v-text-field solo
                                              :label="$t('account_management.first_name')"
                                              v-model="edited_user.first_name"
                                              prepend-icon="mdi-label-outline"
                                              :error-messages="errors"
                                />
                            </validation-provider>

                            <validation-provider :name="$t('account_management.last_name')"
                                                 rules="required" v-slot="{ errors }"
                            >
                                <v-text-field solo
                                              :label="$t('account_management.last_name')"
                                              v-model="edited_user.last_name"
                                              prepend-icon="mdi-label-outline"
                                              :error-messages="errors"
                                />
                            </validation-provider>

                            <validation-provider :name="$t('account_management.phone')"
                                                 rules="required||digits:10" v-slot="{ errors }"
                            >
                                <v-text-field solo
                                              :label="$t('account_management.phone')"
                                              v-model="edited_user.phone"
                                              prepend-icon="mdi-phone" type="number"
                                              :error-messages="errors"
                                />
                            </validation-provider>

                            <validation-provider :name="$t('account_management.email')"
                                                 rules="required|email" v-slot="{ errors }"
                            >
                                <v-text-field solo
                                              :label="$t('account_management.email')"
                                              v-model="edited_user.email"
                                              prepend-icon="mdi-at"
                                              :error-messages="errors"
                                />
                            </validation-provider>
                        </v-card-text>

                        <v-card-actions class="justify-end">
                            <v-btn @click="closeEditDialog()">
                                <v-icon left>mdi-close</v-icon>
                                {{ $t('global.close') }}
                            </v-btn>
                            <v-btn type="submit" color="primary" :disabled="invalid"
                                   @click.prevent="updateUserInstallerExpert()">
                                <v-icon left>mdi-content-save</v-icon>
                                {{ $t('global.validation') }}
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </validation-observer>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {ADMIN_USERS} from "../../../../api";

export default {
    name: 'EditUser',
    props: {
        users: {
            type: Array,
            require: true,
        },
    },
    data() {
        return {
            edited_user: {
                id: null,
                first_name: '',
                last_name: '',
                gender: '',
                phone: '',
                email: '',
                password: '',
                password_confirm: '',
                role_id: null
            },

            dialog_edit: false,
            edited_index: null,
        }
    },
    methods: {
        openDialogUpdateUserInstallerExpert(user) {
            this.edited_index = this.users.indexOf(user);
            this.edited_user = Object.assign({}, user);
            this.dialog_edit = true;
        },

        updateUserInstallerExpert() {
            this.axios.patch(
                ADMIN_USERS + '/' + this.edited_user.id, this.edited_user
            ).then((success) => {
                success.data.date_last_seen = (success.data.date_last_seen !== null ? this.moment(success.data.date_last_seen).format('DD/MM/YYYY kk:mm') : '');
                success.data.created_at = this.moment(success.data.created_at).format('DD/MM/YYYY kk:mm');

                Object.assign(this.users[this.edited_index], success.data);
                this.edited_index = null;
                this.showGlobalSnackbar('success', this.$t('global.success_to_edit'));
            }).catch(() => {
                this.manageError(error.response.status, this.$t('global.failure_to_update'), this.$t('error_display.user_not_found'));
            }).finally(() => {
                this.closeEditDialog();
            });
        },

        closeEditDialog() {
            this.dialog_edit = false;
            this.$refs.edit_form.reset();
            this.$refs.edit_observer.reset();
        },
    }
}
</script>
