<template>
    <v-form ref="form" class="row mt-0" style="background-color: transparent !important;">
        <!-- MACHINE MENU -->
        
        <!-- REPORT VIEW -->
        <v-col v-if="access_level <= 99" color="white" class="pa-10 pt-1 col" style="margin-right:410px;">
            <div v-if="loading_datatable == true"  style="color:grey;text-align: center;">
                <li class="rounded row notification_card mb-1 pa-10 mt-15 ml-6" style="color: grey;justify-content: center;">
                    <div style="color:grey;text-align: center;"><strong>Chargement en cours...</strong></div>
                </li>
            </div>            
            
            <div v-if="loading_datatable == false" flat color="white" class="pt-0 mb-3">
                <!-- campain ADD / EDIT MODAL -->
                <v-row style="color:#ffffffe0;background-color:#1e1e1e;margin:0">
                    <v-col class="col-8">
                        <v-col v-on="on" link :to="'/machine/' + machine.id" style="font-weight: bold;border: 1px solid #bdbdbd45;border-radius:5px">Campagne : {{campain.name}}</v-col>
                        <!--SUBASSEMBLIES-->
                        <div class="col pl-4 mt-1" style="border: 1px solid #bdbdbd45; font-size:12px;border-radius:5px;display:flex;align-items:center;">  
                            <div style="font-weight: bold;font-size: 13px;">Sous-ensemble(s) :</div> 
                            <v-row class="row col pa-0 pt-2 ml-2">
                                <div v-for="subassembly in machine.subassemblies" :key="subassembly.id">
                                    <v-chip link :to="'/subassembly/' + subassembly.id" style="font-size: 12px;" class="mr-2 mb-2">{{subassembly.label}}</v-chip>
                                </div>  
                            </v-row> 
                        </div>
                    </v-col>
                    <v-col class="col pl-5 ma-3 ml-0" style="border: 1px solid #bdbdbd45;border-radius:5px;text-align:center;display:flex;flex-direction:column;justify-content:center">
                        <div v-for="analyst in analysts" :key="analyst">
                            <div v-if="analyst.id == report.validator" >
                                Validé par : {{ analyst.first_name }} {{  analyst.last_name }}
                            </div>
                        </div>
                        
                        <div v-for="analyst in analysts" :key="analyst">
                            <div v-if="analyst.id == report.analyst" >
                                Analysé par : {{ analyst.first_name }} {{  analyst.last_name }}
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <!--CHIPS-->
                <div v-for="filters in machine_filters" :key="filters">
                    <div  v-if="filters.level == report.alert_level && filters.level_name !== 'Tout voir'" :to="{ path: '/campain/' + campain.id}" class="col-0" style="color:white;font-weight:800;display:flex;justify-content:center;text-shadow:2px 2px 4px rgba(0, 0, 0, 0.29); height:100%;min-width: 8px;border-radius:2px 0px 0px 2px;" :style="{backgroundColor: filters.level_color}">
                        <v-row>
                            <div class="col-0 pl-10 pa-5">
                                <v-chip  v-on="on" link :to="'/machine/' + machine.id + '/report'" style="font-weight: bold; background-color: #f3f3f3; border: 1px solid #e5e5e5;">{{machine.label}} (type : {{machine.functionality}})</v-chip>
                            </div>
                            <v-col class="col pl-2 pa-6">
                                Etat de santé général de l'équipement : {{ report.indice }}%
                            </v-col>
                        </v-row>
                    </div>
                </div>  
                
                <!--  DIGITAL REPORT CONTENT -->
                <v-card v-if="report.type === 0">
                    <validation-observer ref="observer" v-slot="{ invalid }">
                        <v-card-text class="pa-0">
                            <v-col>
                                <v-row class="pt-5" style="border-bottom:1px solid #80808040;">
                                    <!-- REPORT CONTEXT -->
                                    <v-col class="col">
                                        <h4 class="pa-5 pt-5 pb-0 ml-1">Contexte</h4>
                                        <vue-editor id="editor0" v-model="report.context" class="col col"></vue-editor>
                                    </v-col>
                                    <!-- REPORT IMAGES -->
                                    <v-col class="pa-0 col-6 pt-1">
                                        <template>
                                            <v-col>
                                                <highcharts :options="lineChartOptions"></highcharts>
                                            </v-col>
                                        </template>
                                    </v-col>
                                </v-row>

                                <v-col class="pt-5">
                                    <!-- REPORT DEFAULTS -->
                                    <h4 class="pa-5 pb-0 ml-1">1 - Vérification des défauts potentiels et cheminement de l'analyse (INTERNE)</h4>
                                    <vue-editor id="editor2" v-model="report.defaults" class="col col"></vue-editor>
                                    <!-- REPORT COMMENTS -->
                                    <h4 class="pa-5 pb-0 ml-1">2 - Complément d'informations et commentaires sur l'analyse (CLIENT)</h4>
                                    <vue-editor id="editor3" v-model="report.comment" class="col col"></vue-editor>
                                </v-col>
                                
                                <v-col class="pt-5 rounded" style="background-color: whitesmoke;border: 1px solid rgba(211, 211, 211, 0.43);border-bottom: none;">
                                    <h4 class="pa-5 pb-0 ml-1">3 - Prises de vues (AMS ou interface Techview)</h4>
                                    
                                    <!-- uploader -->
                                    <div :min-height="minHeight + 'px'" elevation="2" outlined height="100%" class="col col">
                                        <v-card-text>
                                            <v-row class="mb-4 mr-2 ml-2 mt-2">
                                                <v-card
                                                    :class="{ 'grey lighten-2': img_dragover }"
                                                    class="w-100" height="110"
                                                    @dragenter.prevent="img_dragover = true"
                                                    @dragleave.prevent="img_dragover = false"
                                                    @dragover.prevent="img_dragover = true"
                                                    @drop.prevent="img_onDrop($event)"
                                                    elevation="2" outlined
                                                >
                                                    <v-card-text class="mt-12 text-center">
                                                        {{ $t('document.put_file_to_download') }}
                                                    </v-card-text>
                                                </v-card>
                                            </v-row>

                                            <v-row v-if="external_link_activated" class="ma-2">
                                                <v-btn @click="openDialogAddExternalLink()" block color="primary">
                                                    {{ $t('document.add_link_extern_document') }}
                                                </v-btn>
                                            </v-row>

                                            <v-row>
                                                <v-col class="text-center mb-1">
                                                    {{ $t('document.element_already_download') }}
                                                </v-col>
                                            </v-row>

                                            <v-progress-linear color="primary" indeterminate v-if="img_loading"></v-progress-linear>

                                            <v-virtual-scroll :items="img_result" height="150" item-height="50" class="mt-1">
                                                <template v-slot:default="{ item }">
                                                    <v-list-item>
                                                        <!--
                                                        <v-list-item-content v-if="item.link">
                                                            <v-list-item-title>
                                                                <a :href="item.link" target="_blank"> {{ item.link }}</a>
                                                            </v-list-item-title>
                                                        </v-list-item-content>

                                                        <v-list-item-action v-if="item.link">
                                                            <v-btn @click.stop="removeExternalLink(item.id)" icon>
                                                                <v-icon> mdi-close-circle</v-icon>
                                                            </v-btn>
                                                        </v-list-item-action>
                                                        -->
                                                        <v-list-item-content v-if="item.file_name">
                                                            <v-list-item-title>
                                                                <a :href="item.path" download> {{ item.file_name }}</a>
                                                            </v-list-item-title>
                                                        </v-list-item-content>

                                                        <v-list-item-action v-if="item.file_name">
                                                            <v-btn @click.stop="img_removeDocument(item.file_name)" icon>
                                                                <v-icon> mdi-close-circle</v-icon>
                                                            </v-btn>
                                                        </v-list-item-action>
                                                    </v-list-item>

                                                    <v-divider></v-divider>
                                                </template>
                                            </v-virtual-scroll>
                                        </v-card-text>

                                        <template v-if="external_link_activated">
                                            <v-dialog v-model="dialog_add" max-width="500px">
                                                <v-card>
                                                    <v-card-title>
                                                        <span class="headline">{{ $t('document.add_link_extern_document') }}</span>
                                                    </v-card-title>
                                                    <v-divider></v-divider>

                                                    <v-card-text><br/>
                                                        <v-form v-model="valid">
                                                            <v-text-field
                                                                label="URL" solo
                                                                :prepend-icon="'mdi-web'"
                                                                v-model="external_link.link"
                                                            ></v-text-field>
                                                        </v-form>
                                                    </v-card-text>

                                                    <v-card-actions class="justify-end">
                                                        <v-btn @click="addExternalLink()" color="primary">
                                                            <v-icon left>mdi-content-save</v-icon>
                                                            {{ $t('global.validation') }}
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-dialog>

                                            <v-snackbar
                                                v-model="snackbar"
                                                :color="snackbar_type">
                                                {{ snackbar_text }}
                                                <template v-slot:action="{ attrs }">
                                                    <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                                                </template>
                                            </v-snackbar>
                                        </template>
                                    </div> 
                                                                       
                                    <!-- IMAGES CAROUSEL -->
                                    <v-carousel class="col" hide-delimiters>
                                        <v-carousel-item 
                                            v-for="img in img_urls" :key="img"
                                            :src=img
                                            height="400"
                                            progress="primary"
                                            contain
                                        ></v-carousel-item>
                                    </v-carousel>
                                    
                                </v-col>

                                <v-col class="pt-5">
                                    <!-- REPORT PRECO -->
                                    <h4 class="pa-5 pb-0 ml-1">4 - Etablissement des préconisations</h4>
                                    <vue-editor id="editor1" v-model="report.preco" class="col col"></vue-editor>
                                </v-col>
                            </v-col>
                        </v-card-text>
                    </validation-observer>
                </v-card>

                <!-- AUTOMATIC REPORT CONTENT-->
                <v-card v-if="report.type === 1">
                </v-card>

                <!-- PDF REPORT CONTENT -->
                <v-card v-if="report.type === 2">
                    <v-col class="pa-0 " v-if="report.type === 2">
                        <!--CHIPS-->
                        <div class="mt-3">
                            <!-- uploader -->
                            <div :min-height="minHeight + 'px'" elevation="2" outlined height="100%">
                                <v-card-text class="row">
                                    <v-row v-if="!pdf_report_available" class="mb-4 mr-2 ml-2 mt-2">
                                        <v-card
                                            :class="{ 'grey lighten-2': dragover }"
                                            class="w-100" height="120"
                                            @dragenter.prevent="dragover = true"
                                            @dragleave.prevent="dragover = false"
                                            @dragover.prevent="dragover = true"
                                            @drop.prevent="onDrop($event)"
                                            elevation="2" outlined
                                        >
                                            <v-card-text class="mt-12 text-center">
                                                {{ $t('document.put_file_to_download') }}
                                            </v-card-text>
                                        </v-card>
                                    </v-row>

                                    <v-row v-if="!pdf_report_available && external_link_activated" class="ma-2">
                                        <v-btn @click="openDialogAddExternalLink()" block color="primary">
                                            {{ $t('document.add_link_extern_document') }}
                                        </v-btn>
                                    </v-row>

                                    <v-col v-if="pdf_report_available" class="col pa-5 ml-5">
                                        <v-row>
                                            <v-col class="text-center mb-1">
                                                {{ $t('Version en cours') }}
                                            </v-col>
                                        </v-row>

                                        <v-progress-linear color="primary" indeterminate v-if="loading"></v-progress-linear>

                                        <v-virtual-scroll :items="result" height="80" item-height="50" class="mt-1">
                                            <template v-slot:default="{ item }">
                                                <v-list-item>
                                                    <!--
                                                    <v-list-item-content v-if="item.link">
                                                        <v-list-item-title>
                                                            <a :href="item.link" target="_blank"> {{ item.link }}</a>
                                                        </v-list-item-title>
                                                    </v-list-item-content>

                                                    <v-list-item-action v-if="item.link">
                                                        <v-btn @click.stop="removeExternalLink(item.id)" icon>
                                                            <v-icon> mdi-close-circle</v-icon>
                                                        </v-btn>
                                                    </v-list-item-action>
                                                    -->

                                                    <v-list-item-content v-if="item.file_name">
                                                        <v-list-item-title>
                                                            <a @click="launchUrl(item.path)"> {{ item.file_name }}</a>
                                                        </v-list-item-title>
                                                    </v-list-item-content>

                                                    <v-list-item-action v-if="item.file_name">
                                                        <v-btn :href="item.path" download icon>
                                                            <v-icon> mdi-arrow-down-bold-circle</v-icon>
                                                        </v-btn>
                                                    </v-list-item-action>

                                                    <v-list-item-action v-if="item.file_name">
                                                        <v-btn @click.stop="removeDocument(item.file_name)" icon>
                                                            <v-icon> mdi-close-circle</v-icon>
                                                        </v-btn>
                                                    </v-list-item-action>
                                                </v-list-item>

                                                <v-divider></v-divider>
                                            </template>
                                        </v-virtual-scroll>
                                    </v-col>
                                </v-card-text>

                                <template v-if="external_link_activated">
                                    <v-dialog v-model="dialog_add" max-width="500px">
                                        <v-card>
                                            <v-card-title>
                                                <span class="headline">{{ $t('document.add_link_extern_document') }}</span>
                                            </v-card-title>
                                            <v-divider></v-divider>

                                            <v-card-text><br/>
                                                <v-form v-model="valid">
                                                    <v-text-field
                                                        label="URL" solo
                                                        :prepend-icon="'mdi-web'"
                                                        v-model="external_link.link"
                                                    ></v-text-field>
                                                </v-form>
                                            </v-card-text>

                                            <v-card-actions class="justify-end">
                                                <v-btn @click="addExternalLink()" color="primary">
                                                    <v-icon left>mdi-content-save</v-icon>
                                                    {{ $t('global.validation') }}
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>

                                    <v-snackbar
                                        v-model="snackbar"
                                        :color="snackbar_type">
                                        {{ snackbar_text }}
                                        <template v-slot:action="{ attrs }">
                                            <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                                        </template>
                                    </v-snackbar>
                                </template>
                            </div>  
                        </div>  

                        <PDFViewer
                            id="pdf"
                            :source="report_url"
                            style="height: 79vh;"
                            @download="handleDownload"
                            v-if="pdf_report_available"
                        />
                    </v-col>
                </v-card>

                <!-- SNACKBAR INFO DISPLAY -->
                <v-snackbar
                    v-model="snackbar"
                    :color="snackbar_type">
                    {{ snackbar_text }}
                    <template v-slot:action="{ attrs }">
                        <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                    </template>
                </v-snackbar>
            </div>
        </v-col>

        <!-- MORE DATA INFOS -->
        <v-col v-if="access_level <= 99" class="col-1 pt-5 pb-5 pa-0 rounded v-navigation-report" style="height: 110%;;min-width: 400px;position: fixed;right:0px;top: -20px;z-index: 9;background-color: whitesmoke;border-left: 1px solid rgba(211, 211, 211, 0.43);overflow-y: auto;">
            <v-row class="pt-5 pb-3 pr-1 pl-1" style="background-color: whitesmoke;border-bottom: 1px solid rgba(211, 211, 211, 0.43);">
                <v-icon class="mr-2 ml-6 pr-4" style="font-size: 30px;color:#014181;border-right: 1px solid #e3e3e3;">mdi-account-edit</v-icon>
                <v-col class="pt-5 pb-5">
                    <v-row v-for="step in report_steps" :key="step" class="row pa-0">
                        <v-row class="mb-1 mt-1 ml-2 mr-0" v-if="step.id === report.step" style="min-width: 10px;max-width: 10px;min-height: 10px;max-height: 10px;position:relative;top:5px;border-radius:25px;" :style="{backgroundColor: step.level_color}"></v-row>
                        <v-row class="mb-1 mt-1 ml-2" v-if="step.id === report.step" style="font-size:12px;font-weight: bold;font-size:14px;">{{step.name}}</v-row>
                    </v-row>   
                    
                    <div class="mt-5" style="font-size: 12px;margin-left: 8px;">Dernier éditeur du rapport</div>
                    <v-col v-for="analyst in analysts" :key="analyst" class="pa-0">
                        <v-row class="mb-0 mt-1 ml-2" v-if="analyst.id === report.analyst" style="font-weight: bold;font-size:14px;">{{analyst.first_name}} {{analyst.last_name}}</v-row>
                        <v-row class="mb-1 mt-0 ml-2" v-if="analyst.id === report.analyst" style="font-size:12px;">{{report.updated_at}}</v-row>
                        <v-row class="mb-0 mt-5 ml-2" v-if="analyst.id === report.analyst" style="font-size: 12px;margin-left: 10px;">Ref - {{report.uid}}</v-row>
                        <v-row class="mb-1 mt-0 ml-2" v-if="analyst.id === report.analyst" style="font-size: 12px;margin-left: 10px;font-weight: bold;">Révision : {{report.rev}}</v-row>
                    </v-col>
                </v-col>
            </v-row>

            <!-- REPORT INFOS -->
            <div class="col col pl-5 pr-5" style="background-color:white;">         
                
                <v-row class="mt-1 mb-5">
                    <v-card-actions v-if="access_level <= 100 && (report.step == 1 || report.step == 4)" class="pa-1 col col" style="display:flex;justify-content:center">
                        <v-btn class="col col" type="submit" :disabled="invalid" style="color: #017f00;background-color: #a3f182b3;border:1px solid #017f009e !important"
                                @click.prevent="setReportState(report, 1)">
                            <v-icon center style="font-size:28px;">mdi-book-clock-outline</v-icon>
                            Demander une validation
                        </v-btn>
                    </v-card-actions>

                    <v-card-actions v-if="access_level <= 100 && access_level >= 96 && report.step == 2" class="pa-1 col col" style="display:flex;justify-content:center">
                        <v-btn class="col col" type="submit" :disabled="invalid" style="color: #930000;background-color: #d7000063;border:1px solid #d700005e !important"
                                @click.prevent="setReportState(report, 2)">
                            <v-icon center style="font-size:28px;">mdi-book-cancel-outline</v-icon>
                            Relancer l'édition
                        </v-btn>
                    </v-card-actions>
                    <v-card-actions v-if="access_level <= 95 && report.step == 2" class="pa-1 col col" style="display:flex;justify-content:center">
                        <v-btn class="col col" type="submit" :disabled="invalid" style="color: #9f4300;background-color: #e5b673;border:1px solid #a77d62 !important"
                                @click.prevent="setReportState(report, 4)">
                            <v-icon center style="font-size:28px;">mdi-book-cancel-outline</v-icon>
                            Refuser la validation
                        </v-btn>
                    </v-card-actions>
                    <v-card-actions v-if="access_level <= 95 && (report.step == 1 || report.step == 2 || report.step == 4)" class="pa-1 col col" style="display:flex;justify-content:center">
                        <v-btn class="col col" type="submit" :disabled="invalid" style="color: #017f00;background-color: #a3f182b3;border:1px solid #017f009e !important"
                                @click.prevent="setReportState(report, 3)">
                            <v-icon center style="font-size:28px;">mdi-book-check-outline</v-icon>
                            Valider le rapport
                        </v-btn>
                    </v-card-actions>

                    <v-card-actions v-if="access_level <= 95 && report.step == 3" class="pa-1 col col" style="display:flex;justify-content:center">
                        <v-btn class="col col" type="submit" :disabled="invalid" style="color: #930000;background-color: #d7000063;border:1px solid #d700005e !important"
                                @click.prevent="setReportState(report, 5)">
                            <v-icon center style="font-size:28px;">mdi-book-check-outline</v-icon>
                            Annuler la validation
                        </v-btn>
                    </v-card-actions>

                    <v-card-actions v-if="(access_level <= 100 && report.step == 3) || (access_level <= 95 && report.step == 6)" class="pa-1 col col" style="display:flex;justify-content:center">
                        <v-btn class="col col" type="submit" :disabled="invalid" style="color: white;background-color: #0e4cef;border: 1px solid #0e4cef !important;"
                                @click.prevent="setReportState(report, 6)">
                            <v-icon center style="font-size:28px;">mdi-book-play-outline</v-icon>
                            Publier le rapport
                        </v-btn>
                    </v-card-actions>
                    <v-card-actions v-if="access_level <= 95 && report.step == 5" class="pa-1 col col" style="display:flex;justify-content:center">
                        <v-btn class="col col" type="submit" :disabled="invalid" style="color: #930000;background-color: #d7000063;border:1px solid #d700005e !important"
                                @click.prevent="setReportState(report, 7)">
                            <v-icon center style="font-size:28px;">mdi-book-cog-outline</v-icon>
                            Retravailler le rapport
                        </v-btn>
                    </v-card-actions>
                </v-row>

                <validation-provider :name="$t('campain.add_name')" rules="required" v-slot="{ errors }">
                    <v-select
                        label="Type de rapport"
                        v-model="report.type"
                        :items="report_types"
                        :prepend-icon="'mdi-chart-box'"
                        variant="outlined"
                        :error-messages="errors"
                        item-value="id"
                        item-text="name" underlined 
                    ></v-select>
                </validation-provider>

                <validation-provider :name="$t('campain.add_name')" rules="required" v-slot="{ errors }">
                    <v-select
                        label="Etat de santé"
                        v-model="report.alert_level"
                        :items="machine_filters"
                        :prepend-icon="'mdi-speedometer'"
                        variant="outlined"
                        :error-messages="errors"
                        item-value="level"
                        item-text="level_name" 
                        underlined 
                    >
                        <template slot="selection" slot-scope="data">
                            <!-- HTML that describe how select should render selected items -->
                            <v-col>
                                <div>{{ data.item.annotation }}</div>
                                <div style="font-size:12px;">{{ data.item.level_name }}</div>
                            </v-col>
                        </template>
                        <template slot="item" slot-scope="data">
                            <!-- HTML that describe how select should render items when the select is open -->
                            <v-col>
                                <div><strong>{{ data.item.annotation }}</strong></div>
                                <div style="font-size:12px;">{{ data.item.level_name }}</div>
                            </v-col>
                        </template>
                    </v-select>
                </validation-provider>

                <validation-provider :name="$t('campain.add_name')" rules="required" v-slot="{ errors }">
                    <v-select
                        label="Tags"
                        v-model="report.tags"
                        :items="report_tags"
                        :prepend-icon="'mdi-tag'"
                        variant="outlined"
                        :error-messages="errors"
                        item-value="name"
                        item-text="name" underlined multiple chips clearable
                    ></v-select>
                </validation-provider>

                <v-row v-if="report.step <= 1 || report.step == 4 || (access_level <= 95 && report.step == 6)" class="mt-1 mb-1">
                    <v-card-actions class="pb-1 col col" style="display:flex;justify-content:center">
                        <v-btn class="col col" type="submit" color="primary" :disabled="invalid"
                                @click.prevent="updateReport()">
                            <v-icon left>mdi-content-save</v-icon>
                            Enregistrer
                        </v-btn>
                    </v-card-actions>
                </v-row>
                <v-row v-else class="mt-1 mb-1">
                    <v-card-actions class="pb-1 col col" style="display:flex;justify-content:center">
                        <v-btn class="col col" type="submit" color="grey" disabled
                                @click.prevent="updateReport()">
                            <v-icon left>mdi-content-save</v-icon>
                            Enregistrer
                        </v-btn>
                    </v-card-actions>
                </v-row>
            </div>

           <!-- CAMPAIN INFOS -->
           <div class="col col pl-5 pr-5" style="background-color:whitesmoke; border-top: 1px solid rgba(211, 211, 211, 0.43);">
                <v-chip class="mt-2" v-on="on" link :to="'/campain/' + campain.id" style="font-weight: bold; background-color: #f3f3f3; border: 1px solid #e5e5e5;">Campagne : {{campain.name}}</v-chip>
                <v-col class="col col pa-0 pt-0 pb-1 ma-0 ml-0 mt-2 mb-2">
                    <div v-for="presta in prestas" :key="presta">
                        <div v-for="typex in types" :key="typex">
                            <v-chip v-if="presta.id === campain.presta && typex.id === campain.type" class="ml-0 mb-0" style="font-size:12px;">{{ presta.name }} ({{ typex.name.toLowerCase().trim() }})</v-chip>
                        </div>
                    </div>
                </v-col>
                <div style="font-size: 12px;margin-left: 10px;">Ref - {{campain.uid}}</div>
                <div style="font-size: 12px;margin-left: 10px;">Créée le : {{campain.created_at}}</div>
                <div v-for="tviewer in analysts" :key="tviewer">
                    <div v-if="tviewer.id === campain.creator" style="font-size: 12px;margin-left: 10px;">Créé par : {{tviewer.first_name}} {{tviewer.last_name}}</div>
                </div>
                <div v-for="analyst in analysts" :key="analyst">
                    <div v-if="analyst.id === campain.manager" style="font-size: 12px;margin-left: 10px;margin-top:15px;font-weight: bold;">Chargé de campagne : {{analyst.first_name}} {{analyst.last_name}}</div>
                </div>
                <div style="font-size: 12px;margin-left: 10px;">N° de dossier : {{campain.affaire_number}}</div>
                <div v-for="site in sites" :key="site">
                    <div v-if="site.id === campain.id_park" style="font-size: 12px;margin-left: 10px;">Parc machine associé : {{site.label}}</div>
                </div>
            </div>
        </v-col>

        
    </v-form>
</template>

<script>
import {CAMPAIN,MACHINES,REPORT,USERS,SITES,DOCUMENTS, EXTERNAL_LINK} from "../../../api";
import moment from "moment";
import Line from "./components/Line.vue";
import {api as fullscreen} from 'vue-fullscreen';
import PDFViewer from 'pdf-viewer-vue/dist/vue2-pdf-viewer'
import VirtualDeviceLocation from '../../components/VirtualDevice/VirtualDeviceLocation.vue';
// Basic Use - Covers most scenarios
// import { VueEditor } from "vue2-editor";
// Advanced Use - Hook into Quill's API for Custom Functionality
import { VueEditor, Quill } from "vue2-editor";

const authorizedTypes = [
    {name: 'png', value: 'image/png'},
    {name: 'jpg', value: 'image/jpeg'},
    {name: 'pdf', value: 'application/pdf'}
];

export default {
    name: "CampainVision",
    components:{Line, PDFViewer, VirtualDeviceLocation,VueEditor},
    props: {
        minHeight: {
            type: Number,
            required: false,
            default: 350
        },
    },
    data() {
        this.authorizedTypes = authorizedTypes;
        return {
            // CAMPAINS ----------------
            campain_id:'',
            raw_campain: [],
            campain: [],
            // MACHINE ------------------
            machine_id:'',
            raw_machine: [],
            machine: [],
            parentType:"report",
            parentId:this.$route.params.id,
            // Other infos ------------------
            analysts: [],
            sites:[],
            // REPORT ----------------------
            raw_report:[],
            report_url:"",
            report: {
                id: null,
                uid:'',
                id_campain:'',
                id_machine:'',
                creator:'',
                manager:'',
                validator:'',
                type:'',
                step:'',
                alert_level:'',
                indice:'',
                tags:[], 
                trash:'',
                context: '',
                comment: '',
                preco:'',
                defaults: [],
                images:[],
                created_at:'',
                updated_at:'',
                released_at:'',               
            },
            machine_filters: [
                { level: -1, level_name: "Rapport en attente", level_color: "grey", count:0, annotation: "Etat à définir"},

                { level: 1, level_name: "Pour information : EDS 100% (vert)", level_color: "#4CAF50", count:0, percent: 100, annotation: "Etat neuf" },
                { level: 2, level_name: "Pour information : EDS 90% (vert)", level_color: "#4CAF50", count:0, percent: 90, annotation: "Comportement vibratoire correct" },
                { level: 3, level_name: "Pour information : EDS 80% (vert)", level_color: "#4CAF50", count:0, percent: 80, annotation: "Pour information" },

                { level: 11, level_name: "A suivre : EDS 70% (jaune)", level_color: "#FFCB05", count:0, percent: 70, annotation: "A suivre lors de la prochaine campagne" },
                { level: 12, level_name: "A suivre : EDS 60% (jaune)", level_color: "#FFCB05", count:0, percent: 60, annotation: "A suivre de manière rapprochée" },
                { level: 13, level_name: "A suivre : EDS 50% (jaune)", level_color: "#FFCB05", count:0, percent: 50, annotation: "Expertise complémentaire préconisée" },

                { level: 21, level_name: "Importance élevée : EDS 40% (rouge)", level_color: "#F44336", count:0, percent: 40, annotation: "Action à prévoir au prochaine arrêt technique" },
                { level: 22, level_name: "Importance élevée : EDS 30% (rouge)", level_color: "#F44336", count:0, percent: 30, annotation: "Action à prévoir au prochaine arrêt technique" },
                { level: 23, level_name: "Importance élevée : EDS 20% (rouge)", level_color: "#F44336", count:0, percent: 20, annotation: "Action à prévoir au prochaine arrêt technique" },
            
                { level: 31, level_name: "Urgence : EDS 10% (Noir)", level_color: "black", count:0, percent: 10, annotation: "Action à prévoir dans les plus brefs délais" },
                { level: 32, level_name:"Urgence : EDS 0% (Noir)", level_color: "black", count:0, percent: 0, annotation: "Arrêt de la machine recommandé" },              
            ],
            prestas:[
                {id:0, name:"Mesures vibratoires"},
                {id:1, name:"Thermographie"},
                {id:2, name:"Mesures ultrasons"},
                {id:3, name:"Equilibrages"},
                {id:4, name:"Expertise avancée"},
                {id:5, name:"Surveillance Online"},
            ],
            types:[
                {id:0, name:"Ponctuel"},
                {id:1, name:"Annuel"},
                {id:2, name:"Semestriel"},
                {id:3, name:"Quadrmestriel"}, 
                {id:4, name:"Trimestriel"},
                {id:5, name:"Continu"},
            ],
            report_types:[
                {id:0, name:"PDF"},
                {id:1, name:"(VIB) Digital"},
                {id:2, name:"(VIB) Automatique"},
            ],
            report_steps:[
                {id:0, name:"En attente", level_color: "grey"},
                {id:1, name:"En cours d'édition",level_color: "#ef780e"},
                {id:2, name:"En attente de validation",level_color: "#d40eef"},
                {id:3, name:"Validé",level_color: "#089d2d"},
                {id:4, name:"En cours de rectification",level_color: "#d40eef"},
                {id:5, name:"Livré",level_color: "#0e4cef"},
                {id:6, name:"En cours de rectification (post-livraison)",level_color: "#d40eef"},
            ],
            report_tags:[
                {id:1, name:"Rapport préliminaire"},
                {id:2, name:"Expertise avancée"},
                {id:3, name:"Expertise ODS"},
                {id:4, name:"Epertise Ultrasons"},
                {id:5, name:"Installation électrique"},
                {id:6, name:"Moyen particulier"},
                {id:7, name:"Contre visite"},
                {id:8, name:"Défauts de roulement"},
                {id:9, name:"Défauts BF"},
                {id:10, name:"Défauts HF"},
                {id:11, name:"Défauts électriques"},
            ],
            lineChartOptions: {
                title: {text: '(Indice) - Evolution de l\'état de santé machine'},
                time: {timezone: 'Europe/Paris',},
                lang: {noData: this.$t('three_dimensional_curve.data_loading_error')},
                noData: {
                    style: {
                        fontWeight: 'bold',
                        fontSize: '15px',
                        color: '#303030'
                    }
                },
                loading: {hideDuration: 1000,showDuration: 1000},
                scrollbar: {
                    liveRedraw: false,
                    height: 17,
                    barBackgroundColor: '#cfcfcf',
                    barBorderRadius: 9,
                    buttonBackgroundColor: '#c9c9c9',
                    buttonArrowColor: 'white',
                    buttonBorderWidth: 3,
                    buttonBorderRadius: 11,
                    rifleColor: 'white',
                    trackBorderRadius: 9
                },
                rangeSelector: {enabled: false,},
                updateArgs: [true, true, true],
                yAxis: {
                    title: {
                        text: 'Niveau de santé (%)',
                    },
                    plotBands: [],
                },
                plotOptions: {
                    line: {
                        connectNulls: true,
                    },
                    series: {
                        marker: {
                            enabled: true,
                            radius: 4
                        }
                    }
                },
                series: [{
                    name: 'Etat de santé',
                    data: []
                }],
                credits: {
                    enabled: false
                },
            },

            // GLOBAL --------------------
            access_level: this.$store.state.access_level,
            id: this.$route.params.id,
            loading_datatable:true,

            // Uploads
            dragover: false,
            files_to_upload: [],
            uploaded_files: [],
            loading: true,
            external_link: {
                id_relatable: this.$route.params.id,
                type_relatable: 'report',
                link: ''
            },
            external_links: [],
            dialog_add: false,
            valid: true,
            result: [],
            pdf_report_available:false,
            external_link_activated:false,

            // Uploads (img)
            img_dragover: false,
            img_files_to_upload: [],
            img_uploaded_files: [],
            img_loading: true,
            img_external_link: {
                id_relatable: this.$route.params.id,
                type_relatable: 'report_images',
                link: ''
            },
            img_external_links: [],
            img_result: [],
            img_available:false,
            img_urls:[],
        }
    },
    computed: {
        dialogTitle() {
            return this.edited_index === null ? this.$t('campain.add') : this.$t('campain.edit');
        }
    },
    created() {
        moment.locale('fr');
        this.initialize();
        this.getExternalLinks();
        this.getDocuments();
        this.img_getDocuments();
        this.setMetricThresholds();
    },
    methods: {
        // STARTING PROCESS
        initialize() {
            this.loading_datatable = true;
            this.getData();
        },

        indiceReturned() {
            let new_indice =  this.machine_filters.find(filter => filter.annotation == this.health_state);
            return new_indice.percent;
        },

        // COLLECTING ALL NEEDED DATAS
        getData() {
            // GETTING CAMPAINS
            this.axios.get(REPORT + "/specific/" + this.id)
            
            .then((success) => {
                this.raw_report = success.data;
                this.report = success.data;

                if (this.report.id != 0 && this.report.id != null && this.report.id != ""){
                    this.report_available = true;

                    this.report.tags = JSON.parse(this.report.tags);
                    this.report.created_at = moment(this.report.created_at).format("DD/MM/YYYY");
                    this.report.updated_at = moment(this.report.updated_at).fromNow();
                    this.campain_id = this.report.id_campain;
                    this.machine_id = this.report.id_machine;

                }else{
                    this.report_available = false; 
                }

            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");

            }).finally(() => {
                this.getSideDatas();
                this.getIndices();
                this.loading_datatable = false;
            })
        },
        getSideDatas(){
            if (this.report){
                this.getCampain();
                this.getMachine();
                this.getAnalysts();
                this.getSite();
            }
        },
        getCampain(){
            this.axios.get(CAMPAIN + '/specific/' + this.campain_id )
            
            .then((success) => {
                success.data.forEach(item => {
                    item.created_at = moment(item.created_at).format("DD/MM/YYYY");
                })
                
                this.campain = [];
                this.campain = success.data[0];

            }).catch(() => {
                this.showSnackbar('error', "Side datas error.");
            })
        },
        getMachine() {
            this.axios.get(MACHINES + '/specific/' + this.machine_id)
            
            .then((success) => {
                this.machine = [];
                this.machine = success.data[0];

            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");
            })
        },
        getAnalysts(){
            this.axios.get(USERS + '/team')
            
            .then((success) => {
                this.analysts = [];
                this.analysts = success.data;

            }).catch(() => {
                this.showSnackbar('error', "Analysts datas error.");
            })
        },
        getSite() {
            this.axios.get(SITES + '/resume')
            
            .then((success) => {
                this.sites = [];
                this.sites = success.data;

            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");
            })
        },
        getIndices() {
            // GETTING CAMPAINS
            this.axios.get(REPORT + "/machines_indices/" + this.report.id_machine)
            
            .then((success) => {
                this.raw_report_indices = [];
                this.report_indices = [];
                this.report_series = [];

                if (typeof success.data[0] !== 'undefined'){
                    this.report_indices_available = true;
                    this.raw_report_indices = success.data;

                    success.data.forEach(report => {
                        this.report_series.push(report.indice);
                    })
                    
                    this.lineChartOptions.series[0].data = this.report_series;

                }else{
                    this.report_indices_available = false;
                }
            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");

            })
        },
        setMetricThresholds() {
            this.lineChartOptions.yAxis.plotBands = [];
            this.lineChartOptions.yAxis.plotBands.push(
                {
                    from: 100,
                    to: 75,
                    color: 'rgba(76, 175, 80, 0.25)',
                    label: {
                        text: 'Etat de santé convenable'
                    }
                },
                {
                    from: 75,
                    to: 45,
                    color: 'rgba(255, 203, 5, 0.25)',
                    label: {
                        text: 'Etat de santé affecté (à suivre)'
                    }
                },
                {
                    from: 45,
                    to: 15,
                    color: 'rgba(244, 67, 54, 0.25)',
                    label: {
                        text: 'Etat de santé dégradé (importance élevée)'
                    }
                },
                {
                    from: 15,
                    to: 0,
                    color: 'rgba(0, 0, 0, 0.25)',
                    label: {
                        text: 'Etat de santé critique (urgence)'
                    }
                }
            );
        },

        // DISPLAY DATA MANAGEMENT
        checkDataAvailable(datas){
            if(typeof datas[0] === 'undefined') {                    
                return false;
            }else {
                return true;
            }
        },
        setMetricThresholds() {
            this.lineChartOptions.yAxis.plotBands = [];
            this.lineChartOptions.yAxis.plotBands.push(
                {
                    from: 100,
                    to: 75,
                    color: 'rgba(76, 175, 80, 0.25)',
                    label: {
                        text: 'Etat de santé convenable'
                    }
                },
                {
                    from: 75,
                    to: 45,
                    color: 'rgba(255, 203, 5, 0.25)',
                    label: {
                        text: 'Etat de santé affecté (à suivre)'
                    }
                },
                {
                    from: 45,
                    to: 15,
                    color: 'rgba(244, 67, 54, 0.25)',
                    label: {
                        text: 'Etat de santé dégradé (importance élevée)'
                    }
                },
                {
                    from: 15,
                    to: 0,
                    color: 'rgba(0, 0, 0, 0.25)',
                    label: {
                        text: 'Etat de santé critique (urgence)'
                    }
                }
            );
        },

        //REQUESTS
        updateReport() {
            this.machine_filters.forEach(filter => {
                if (filter.level ==  this.report.alert_level){
                    this.report.indice = filter.percent;
                }
            })
            
            this.axios.patch(REPORT + "/" + this.report.id,  this.report)
            .then((success) => {
                Object.assign(this.report, success.data[0]);
                this.getData();
                this.loading_datatable = true;
                this.showSnackbar('success', this.$t('global.success_to_edit'));
            
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            
            }).finally(() => {
                this.loading_datatable = true;
                this.initialize();
            })
        },
        setReportState(report, state) {
            //  transition ASK VALIDATION STEP [2]
            if (state === 1) {
                this.axios.patch( REPORT + '/ask_validate/' + report.id)
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.initialize();
                })
            }
            //  transition UNASK VALIDATION STEP [1]
            if (state === 2) {
                this.axios.patch( REPORT + '/unask_validate/' + report.id)
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.initialize();
                })
            }
            //  transition VALIDATION STEP [3]
            if (state === 3) {
                this.axios.patch( REPORT + '/certify/' + report.id )
            
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.initialize();
                })
            }
            //  transition VALIDATION STEP [4]
            if (state === 4) {
                this.axios.patch( REPORT + '/failed_certify/' + report.id )
            
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.initialize();
                })
            }
            //  transition UNVALIDATION STEP [4]
            if (state === 5) {
                this.axios.patch( REPORT + '/uncertify/' + report.id )
            
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.initialize();
                })
            }
            //  transition RELEASE STEP [5]
            if (state === 6) {
                this.axios.patch( REPORT + '/release/' + report.id )
            
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.initialize();
                })
            }
            //  transition UNRELEASE STEP [6]
            if (state === 7) {
                this.axios.patch( REPORT + '/unrelease/' + report.id )
            
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.initialize();
                })
            }
        },

        // UPLOADS (report)
        onDrop(e) {
            this.dragover = false;
            // If there are already uploaded files remove them
            if (this.files_to_upload.length > 0) this.files_to_upload = [];
            // If user has uploaded multiple files but the component is not multiple throw error
            let file;
            for (let i = 0; i < e.dataTransfer.files.length; i++) {
                file = e.dataTransfer.files.item(i);
                let docCheck = this.uploadFilesCheck(file,['image/png','image/jpg','image/jpeg','application/pdf','text/csv'],10);
                if(!docCheck.authorized ) {
                    this.showSnackbar('error',docCheck.output.toString());
                }
                else {
                    this.files_to_upload.push(file);
                }
            }
            if (this.files_to_upload.length > 0) this.uploadDocuments();
        },
        uploadDocuments() {
            this.loading = true;
            this.files_to_upload.forEach((file) => {
                let formData = new FormData();
                formData.append('files[]', file);
                formData.append('entity_id', this.id);
                formData.append('type', "report");
                formData.append('professional_id', this.$store.state.professional_id);

                axios.post(DOCUMENTS + '/upload', formData, this.configurationFile)
                    .then(() => {
                        this.getDocuments();
                    })
                    .catch((error) => {
                        if (error.response.status === 413) {
                            this.showSnackbar('warning', this.$t('document.too_large'));
                        } else {
                            this.showSnackbar('error', this.$t('global.failure_to_add'));
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            });
        },
        getDocuments() {
            this.axios.get(DOCUMENTS + '/getFiles', {
                    params: {
                        entity_id: this.$route.params.id,
                        type: 'report',
                        professional_id: this.$store.state.professional_id
                    }
                }

            ).then((success) => {
                this.uploaded_files = [];
                
                if (this.checkDataAvailable(success.data)){
                    success.data.forEach((path) => {
                        let fileName = path.replace(/^.*[\\\/]/, '');
                        this.uploaded_files.push({
                            file_name: fileName,
                            path: path
                        });
                    });

                    this.uploaded_files.reverse();
                    this.report_url = this.uploaded_files[0].path;
                    this.pdf_report_available = true;

                }else{
                    this.pdf_report_available = false;
                }
            }).catch(() => {
                this.showSnackbar('error', 'GetDocuments error');

            }).finally(() => {
                this.mergeExternalLinkAndDocuments();
                this.loading = false;
            });
        },
        launchUrl(url){
            this.report_url = url;
        },
        removeDocument(fileName) {
            this.loading = true;
            this.axios.delete(DOCUMENTS + '/delete', {
                    params: {
                        file_name: fileName,
                        entity_id: this.$route.params.id,
                        type: 'report'
                    }
                }
            ).then(() => {
                this.getDocuments();
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_delete'))
            }).finally(() => {
                this.loading = false;
            });
        },

        // UPLOADS (imgs)
        img_onDrop(e) {
            this.img_dragover = false;
            // If there are already uploaded files remove them
            if (this.img_files_to_upload.length > 0) this.img_files_to_upload = [];
            // If user has uploaded multiple files but the component is not multiple throw error
            let file;
            for (let i = 0; i < e.dataTransfer.files.length; i++) {
                file = e.dataTransfer.files.item(i);
                let docCheck = this.uploadFilesCheck(file,['image/png','image/jpg','image/jpeg'],10);
                if(!docCheck.authorized ) {
                    this.showSnackbar('error',docCheck.output.toString());
                }
                else {
                    this.img_files_to_upload.push(file);
                }
            }
            if (this.img_files_to_upload.length > 0) this.img_uploadDocuments();
        },
        img_uploadDocuments() {
            this.img_loading = true;

            this.img_files_to_upload.forEach((file) => {
                let formData = new FormData();
                formData.append('files[]', file);
                formData.append('entity_id', this.id);
                formData.append('type', "report_images");
                formData.append('professional_id', this.$store.state.professional_id);

                axios.post(DOCUMENTS + '/upload', formData, this.configurationFile)
                
                .then(() => {
                    this.img_getDocuments();
                })
                
                .catch((error) => {
                    if (error.response.status === 413) {
                        this.showSnackbar('warning', this.$t('document.too_large'));
                    } else {
                        this.showSnackbar('error', this.$t('global.failure_to_add'));
                    }
                })
                
                .finally(() => {
                    this.img_loading = false;
                });
            });
        },
        img_getDocuments() {
            this.axios.get(DOCUMENTS + '/getFiles', {
                    params: {
                        entity_id: this.$route.params.id,
                        type: 'report_images',
                        professional_id: this.$store.state.professional_id
                    }
                }

            ).then((success) => {
                this.img_uploaded_files = [];
                this.img_urls = [];
                
                if (this.checkDataAvailable(success.data)){
                    success.data.forEach((path) => {
                        let fileName = path.replace(/^.*[\\\/]/, '');
                        this.img_uploaded_files.push({
                            file_name: fileName,
                            path: path
                        });
                    });

                    this.img_uploaded_files.reverse();
                    this.img_uploaded_files.forEach(img_url => {
                        this.img_urls.push(img_url.path);
                        this.img_available = true;
                    })

                }else{
                    this.img_available = false;
                }
            }).catch(() => {
                this.showSnackbar('error', 'img_GetDocuments error');

            }).finally(() => {
                this.img_result = this.img_uploaded_files;
                this.img_loading = false;
            });
        },
        img_launchUrl(url){
            this.report_url = url;
        },
        img_removeDocument(fileName) {
            this.img_loading = true;
            this.axios.delete(DOCUMENTS + '/delete', {
                    params: {
                        file_name: fileName,
                        entity_id: this.$route.params.id,
                        type: 'report_images'
                    }
                }
            ).then(() => {
                this.img_getDocuments();
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_delete'))
            }).finally(() => {
                this.loading = false;
            });
        },

        // EXTERNAL LINKS MANAGEMENT (not used on 1.5.1)
        getExternalLinks() {
            this.axios.get(EXTERNAL_LINK, {
                    params: {
                        ['id_report']: this.$route.params.id,
                        type: 'report'
                    }
                }
            ).then((success) => {
                this.external_links = success.data;
                this.mergeExternalLinkAndDocuments();
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            }).finally(() => {
                this.loading = false;
            });
        },
        removeExternalLink(id) {
            this.loading = true;
            this.axios.delete(EXTERNAL_LINK + '/' + id, {
                    params: {
                        id: id
                    }
                }
            ).then(() => {
                this.getExternalLinks();
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_delete'))
            }).finally(() => {
                this.loading = false;
            });
        },
        openDialogAddExternalLink() {
            this.resetExternalLinkObject();
            this.dialog_add = true;

        },
        resetExternalLinkObject() {
            this.external_link.link = '';
        },
        closeDialog() {
            this.dialog_add = false;
        },
        addExternalLink() {
            if (!this.validURL()) {
                this.showSnackbar('error', 'Veuillez saisir une url valide');
            } else {
                this.loading = true;

                this.axios.post(EXTERNAL_LINK, this.external_link
                ).then((success) => {
                    this.result.push(success.data);
                    this.external_links.push(success.data);
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_add'))
                }).finally(() => {
                    this.closeDialog();
                    this.loading = false;
                });
            }
        },
        validURL() {
            let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
            this.valid = !!pattern.test(this.external_link.link);
            return this.valid;
        },
        mergeExternalLinkAndDocuments() {
            this.result = this.uploaded_files.concat(this.external_links);
        },
    },
}
</script>

<style>
.v-responsive__content{
    width: auto !important;
}
</style>