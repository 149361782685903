<template>
    <div id="gateways-list" class="pa-4">
        <v-row no-gutters>
            <v-col>
                <p class="title mb-0 color-primary text-uppercase">{{ $t('gateways_datatable.title') }}</p>
            </v-col>
        </v-row>

        <v-card width="100%" class="mt-4" elevation="0">
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :search="search"
                    :items="gateways"
                    :loading="gateway_data_table_loading"
                >
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-layout class="justify-start mt-7">
                                <p class="mt-3">{{ $t('global.filter_by') }}</p>
                                <v-select
                                    :items="gateway_states"
                                    v-model="selected_gateway_states"
                                    :placeholder="$t('gateways_datatable.filter')"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                    item-text="name" item-value="id"
                                    filled dense class="ml-4" style="max-width: 500px;"
                                    chips multiple deletable-chips
                                />
                            </v-layout>

                            <v-layout class="justify-end mt-7 max-width-300">
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    :label="$t('global.search')"
                                />
                            </v-layout>
                        </v-toolbar>

                        <v-layout class="justify-end mt-2 mb-2">
                            <v-btn color="primary" class="mr-3" @click="downloadCsvGateway()">
                                <v-progress-circular v-if="loading_csv_file"
                                                     indeterminate color="white" size="20" width="2"
                                />
                                <v-icon v-else left>mdi-download-box-outline</v-icon>
                                {{ $t('global.download') }}
                            </v-btn>
                        </v-layout>
                    </template>

                    <template v-slot:item.date_last_seen="{ item }">
                        <template v-if="item.date_last_seen !==null">
                            <v-chip small :color="lastEmissionAttributeTranslation(item.date_last_seen).color">
                                <v-icon color="blue-grey darken-3" class="mr-1">
                                    mdi-sort-clock-descending-outline
                                </v-icon>
                                {{ lastEmissionAttributeTranslation(item.date_last_seen).time }}
                            </v-chip>
                        </template>

                        <template v-else>
                            <v-icon>mdi-cancel</v-icon>
                        </template>
                    </template>

                    <template v-slot:item.gx_order_number="{ item }">
                        <template v-if="item.gx_order_number">
                            <span>{{ item.gx_order_number }}</span>
                        </template>
                        <template v-else>
                            <v-icon>mdi-cancel</v-icon>
                        </template>
                    </template>

                    <template v-slot:item.state="{ item }">
                        <span>{{ checkGatewayAndPhysicalDevicesStates(item.state) }}</span>
                    </template>

                    <template v-slot:item.user="{ item }">
                        <span>{{ checkGatewayAndPhysicalDevicesOwnership(item.user, item.state) }}</span>
                    </template>

                    <template v-slot:item.address="{ item }">
                        <span class="address-button"
                              @click="$refs.location.gatewayLocationDialogOpen(item.address, item.latitude, item.longitude)"
                        >
                            {{ item.address }}
                        </span>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <gateway-location ref="location"/>
        <global-snackbar/>
    </div>
</template>

<script>
import {DOWNLOAD_CSV_GATEWAY, GATEWAYS} from "../../../api";
import GatewayLocation from "../../components/Shop/Gateways/GatewayLocation";
import InstallerSwitchInterface from "../../components/Installer/InstallerSwitchInterface";
import GlobalSnackbar from "../../../global/components/GlobalSnackbar";

export default {
    name: "GatewaysDatatable",
    components: {
        GatewayLocation,
        InstallerSwitchInterface,
        GlobalSnackbar
    },
    data() {
        return {
            search: '',
            loading_csv_file: false,
            gateway_data_table_loading: true,
            selected_gateway_states: [],
            filter_selected_state: "",
            gateways: [],
            gateway_states: [
                {id: '1', name: this.$t('gateways_datatable.gateway_in_storage_state')},
                {id: '3', name: this.$t('gateways_datatable.gateway_on_operation_state')},
                {id: '-3', name: this.$t('gateways_datatable.gateway_scrapped_state')},
                {id: '-1', name: this.$t('gateways_datatable.gateway_in_maintenance_state')},
                {id: '2', name: this.$t('gateways_datatable.gateway_at_the_installer_state')},
                {id: '4', name: this.$t('gateways_datatable.gateway_to_test_state')},
                {id: '-2', name: this.$t('gateways_datatable.gateway_lost_state')}
            ],
            headers: [
                {text: this.$t('gateways_datatable.hardware_mac'), value: 'hardware_mac'},
                {text: this.$t('physical_devices_datatable.gx_order_number'), value: 'gx_order_number'},
                {text: this.$t('gateways_datatable.state'), value: 'state'},
                {text: this.$t('gateways_datatable.site'), value: 'user'},
                {text: this.$t('gateways_datatable.location'), value: 'address'},
                {text: this.$t('gateways_datatable.last_emission'), value: 'date_last_seen'}
            ],
        }
    },
    watch: {
        selected_gateway_states() {
            this.gateway_data_table_loading = true;
            this.filter_selected_state = '';
            if (this.selected_gateway_states.length !== 0) {
                this.selected_gateway_states.forEach((selected_gateway_state) => {
                    this.filter_selected_state += (this.filter_selected_state === '' ? selected_gateway_state : ',' + selected_gateway_state);
                });
            }
            this.getGateways();
        }
    },
    created() {
        this.getGateways();
    },
    methods: {
        getGateways() {
            this.axios.get(
                GATEWAYS + '?selected_gateway_states=' + this.filter_selected_state + '&installer_proprietary=true'
            ).then((success) => {
                this.gateways = success.data;
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('global.error'));
            }).finally(() => {
                this.gateway_data_table_loading = false;
            });
        },

        downloadCsvGateway() {
            this.loading_csv_file = true;
            this.prepareDownloadCsvFile(
                this.$store.state.access_level === 200 ? DOWNLOAD_CSV_GATEWAY + '?selected_gateway_states=' + this.filter_selected_state + '&installer_proprietary=true': DOWNLOAD_CSV_GATEWAY + '?selected_gateway_states=' + this.filter_selected_state, '_gateways.csv'
            ).finally(() => {
                this.loading_csv_file = false;
            });
        },
    },
}
</script>

<style scoped>
.address-button {
    text-decoration: underline;
    cursor: pointer;
    transition: color 0.3s;
}

.address-button:hover {
    color: #004181;
}
</style>
