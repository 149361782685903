<template>
    <v-card elevation="2" outlined>
        <v-card-title class="justify-center">
            {{ $t('referential.parts_concerned') }}
        </v-card-title>
        <v-divider></v-divider>

        <v-data-table
            :headers="headers"
            :items="parts_concerned"
            sort-by="id"
            class="elevation-1 datatable-setting-min-height"
            :loading="loading"
            :items-per-page="5"
            :search="search">

            <template v-slot:top>
                <v-alert
                    border="left"
                    colored-border type="warning"
                    elevation="2"
                    class="mr-4 ml-4 mt-4" dense block
                >
                    {{ $t('referential.info_datatable_part_concerned') }}
                </v-alert>

                <v-toolbar flat color="white" class="pt-3">
                    <v-row>
                        <v-col cols="8">
                            <v-btn color="primary" class="white--text mr-3" @click="openDialogAddPartConcerned()">
                                {{ $t('referential.add_part_concerned') }}
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                :label="$t('global.search')"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-dialog v-model="dialog_edit" max-width="500px">
                        <v-card>
                            <validation-observer ref="observer" v-slot="{ invalid }">
                                <v-form ref="form">
                                    <v-card-title><span class="headline">{{ dialogTitle }}</span></v-card-title>
                                    <v-divider></v-divider>

                                    <v-card-text>
                                        <validation-provider :name="$t('global.value')" rules="required"
                                                             v-slot="{ errors }">
                                            <v-text-field
                                                :label="$t('global.value')"
                                                v-model="edited_part_concerned.value"
                                                :prepend-icon="'mdi-label-outline'"
                                                :error-messages="errors" solo
                                            />
                                        </validation-provider>
                                    </v-card-text>

                                    <v-divider></v-divider>

                                    <v-card-actions>
                                        <v-spacer/>
                                        <v-btn @click="closeDialog()">
                                            <v-icon left>mdi-close</v-icon>
                                            {{ $t('global.close') }}
                                        </v-btn>
                                        <v-btn type="submit" color="primary" :disabled="invalid"
                                               @click.prevent="prepareEditPartConcerned()">
                                            <v-icon left>mdi-content-save</v-icon>
                                            {{ $t('global.validation') }}
                                        </v-btn>
                                    </v-card-actions>
                                </v-form>
                            </validation-observer>
                        </v-card>
                    </v-dialog>

                    <v-dialog v-model="dialog_delete" max-width="500px">
                        <v-card>
                            <v-card-title>
                                <span class="headline">{{ $t('referential.delete_part_concerned') }}</span>
                            </v-card-title>
                            <v-divider></v-divider>
                            <br/>

                            <v-card-actions>
                                <v-spacer/>
                                <v-btn @click="dialog_delete = false">
                                    <v-icon left>mdi-close</v-icon>
                                    {{ $t('global.cancel') }}
                                </v-btn>
                                <v-btn color="error" @click="confirmDelete()">
                                    <v-icon left>mdi-delete</v-icon>
                                    {{ $t('global.delete') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <v-snackbar
                        v-model="snackbar"
                        :color="snackbar_type">
                        {{ snackbar_text }}
                        <template v-slot:action="{ attrs }">
                            <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                        </template>
                    </v-snackbar>
                </v-toolbar>
            </template>

            <template v-slot:item.action="{ item }">
                <v-icon class="pointer" @click="openDialogUpdatePartConcerned(item)">mdi-pencil</v-icon>
                <v-icon class="pointer" @click="openDialogDeletePartConcerned(item)">mdi-delete</v-icon>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
import {REFERENTIAL_GLOBALS} from "../../../../api";

export default {
    name: "DatatablePartConcerned",
    data() {
        return {
            parts_concerned: [],
            headers: [
                {text: this.$t('global.intern_number'), value: 'uid'},
                {text: this.$t('global.value'), value: 'value'},
                {text: this.$t('global.actions'), value: 'action'}
            ],
            loading: true,
            search: '',
            invalid: false,

            dialog_edit: false,
            edited_index: null,
            edited_part_concerned: {
                id: null,
                type: 'part_concerned',
                value: ''
            },
            valid: true,

            dialog_delete: false,
            delete_id: null,
            deleted_index: null,
        }
    },
    computed: {
        dialogTitle() {
            return this.edited_index === null ? this.$t('referential.add_part_concerned') : this.$t('referential.edit_part_concerned');
        }
    },
    created() {
        this.getPartsConcerned();
    },
    methods: {
        getPartsConcerned() {
            this.axios.get(
                REFERENTIAL_GLOBALS + "?type=part_concerned"
            ).then((success) => {
                this.parts_concerned = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            }).finally(() => {
                this.loading = false
            });
        },

        openDialogAddPartConcerned() {
            if (this.edited_index !== null) {
                this.$refs.form.reset();
                this.$refs.observer.reset();
                this.edited_index = null;
            }

            this.dialog_edit = true;
        },

        openDialogUpdatePartConcerned(part_concerned) {
            this.edited_index = this.parts_concerned.indexOf(part_concerned);
            this.edited_part_concerned = Object.assign({}, part_concerned);
            this.dialog_edit = true;
        },

        prepareEditPartConcerned() {
            if (this.edited_index !== null) {
                this.updatePartConcerned();
            } else {
                this.addPartConcerned();
            }
        },

        updatePartConcerned() {
            this.axios.patch(REFERENTIAL_GLOBALS + '/' + this.edited_part_concerned.id,
                this.edited_part_concerned
            ).then((success) => {
                Object.assign(this.parts_concerned[this.edited_index], success.data);
                this.edited_index = null;
                this.showSnackbar('success', this.$t('global.success_to_edit'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            }).finally(() => {
                this.closeDialog();
            });
        },

        addPartConcerned() {
            this.axios.post(REFERENTIAL_GLOBALS, this.edited_part_concerned
            ).then((success) => {
                this.parts_concerned.push(success.data);
                this.showSnackbar('success', this.$t('global.success_to_add'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_add'));
            }).finally(() => {
                this.closeDialog();
            });
        },

        openDialogDeletePartConcerned(part_concerned) {
            this.deleted_index = this.parts_concerned.indexOf(part_concerned);
            this.delete_id = part_concerned.id;
            this.dialog_delete = true;
        },

        confirmDelete() {
            this.axios.delete(
                REFERENTIAL_GLOBALS + '/' + this.delete_id
            ).then(() => {
                this.parts_concerned.splice(this.deleted_index, 1);
                this.showSnackbar('success', this.$t('global.success_to_delete'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_delete'));
            }).finally(() => {
                this.dialog_delete = false;
            });
        },

        closeDialog() {
            this.dialog_edit = false;
            this.$refs.form.reset();
            this.$refs.observer.reset();
        }
    }
}
</script>
