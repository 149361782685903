<template>
    <v-col class="col col-12 mt-0 pt-0 pa-0" style="margin-top:0px!important;padding:0px;position:relative; top:-12px;">
        
        <!-- MACHINE MENU PACKAGE -->
        <div class="pl-3 pr-3">
            <v-col class="pl-5 pr-5" style="background-color:#001047;color:#FFFFFF;border-radius: 0px 0px 5px 5px;">
                <!-- TOP TITLE -->
                <v-row class="pl-0 pt-5 mb-5" style="height: 52px; padding:5px;">                    
                    <!-- PAGE TITLE -->
                    <v-subheader class="v-subheader col theme--light pl-3 ml-2">
                        <p class="title mb-0 color-white" style="color: white!important;">
                            <div style="color:#FFFFFFb3;margin-right:4px;margin-left:4px;"> Centre d'aide > </div><div style="color:#FFFFFF;font-weight:bold;"> Documentations</div>
                        </p>
                    </v-subheader>

                    <!-- GLOBAL ACTIONS -->
                    <v-col class="row mt-1 col-auto pa-0 pr-5">
                        <v-tooltip bottom dark class="mr-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="initialize()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                    <v-icon color="white">mdi-tag-multiple</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('global.1_5_2') }}</span>
                        </v-tooltip>
                        <v-tooltip bottom dark class="mr-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="initialize()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                    <v-icon color="white">mdi-refresh</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('machines.refresh') }}</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
            </v-col>
        </div>   

        <div v-for="vue in vues" :key="vue.id" class="mr-1">
            <div 
                v-if="vue.id == active_special_vue" 
                class="ma-auto pl-5 pr-5 pa-2"
                style="color: black; font-weight: 400; display: flex; justify-content: left; text-shadow: rgba(0, 0, 0, 0.29) 2px 2px 4px; background-color: rgb(255 255 255); border-radius: 5px 5px 0px 0px; border-left: 1px solid rgba(211, 211, 211, 0.43); border-bottom: none; font-size: 14px; height: 40px; align-items: center; font-weight:bold;"
            >
                {{ vue.label }}
            </div>
            <div 
                v-else 
                class="ma-auto pl-5 pr-5 pa-2"
                style="color: black; font-weight: 400; display: flex; justify-content: left; text-shadow: rgba(0, 0, 0, 0.29) 2px 2px 4px; background-color: rgb(243 243 243 / 90%); border-radius: 10px 10px 0px 0px; border: 1px solid #dbdce1; border-bottom: none; font-size: 14px; height: 40px; cursor: pointer;"
                @click="loadVue(vue.id)"
            >                             
                {{ vue.label }}
            </div>
        </div>

        <!--CHIPS NO ALERT-->
        <div class="ma-0 pa-0" id="ChipsNoAlert"> 
            <div style="height:150px;border-radius:5px;background-color:white;" class="ma-3 pa-0">
                <div class="col-0" style="color:black;font-weight:800;display:flex;justify-content:center; height:100%;min-width: 8px;border-radius:5px;" >
                    <v-col class="pa-0 pl-8 pr-8" style="display:flex;justify-content:start;align-items:center;">
                        <div style="text-align:left;">  
                            <v-icon color="primary" style="height:36px;font-size:36px;">
                                mdi-lifebuoy
                            </v-icon>
                            <br>
                            <div class="pa-3 pl-0 pb-0">
                                Bienvenue sur notre centre d'aide.</br><div style="font-weight: 400;">Retrouvez ici toutes nos documentations, en cas de besoin n'hésitez pas à nous contacter.</div>
                            </div>
                            <p class="pb-0" style="font-weight:400;font-size:12px;">
                                Dernière publication le 12/03/2025 : Documentation utilisateur 1.5.2
                            </p>
                        </div>
                    </v-col>
                </div>
            </div>
        </div> 

        <!-- CONTENT AZ -->
        <v-form v-if="access_level == 0 && importMode" ref="form" class="row mt-0" style="background-color: transparent !important;">
        
            <!-- REPORT VIEW -->
            <v-col color="white" class="pa-6  pt-0 col">
                <div v-if="loading_datatable == true"  style="color:grey;text-align: center;">
                    <li class="rounded row notification_card mb-1 pa-10 mt-15 ml-6" style="color: grey;justify-content: center;">
                        <div style="color:grey;text-align: center;"><strong>Chargement en cours...</strong></div>
                    </li>
                </div>            
                
                <div v-if="loading_datatable == false" flat color="white" class="pt-0 mb-3">
                    <!-- PDF REPORT VIB OR THERMO CONTENT -->
                    <v-card class="mt-3">
                        <v-row class="pa-0 pl-3 pr-3">
                            <!--CHIPS-->
                            <div class="mt-0 pl-3 pr-0" style="width:30%">
                                <!-- uploader V2 -->
                                <v-row class="ma-0 pa-0" style="justify-content: space-between;">
                                    <h4 class="pa-5 pt-5 pb-0 ml-1">Documents disponibles</h4>
                                    <div style="display: flex;">
                                        <v-switch
                                            v-model="importMode"
                                            class="mr-4"
                                        ></v-switch>
                                        <div class="mt-0 pb-0 mt-2" v-if="!pdf_report_available" style="background-color: #ff5b00b0; border-radius:5px 0px 0px 5px;height:40px; width:40px;display:flex;justify-content:center;">
                                            <v-icon color="white">mdi-progress-download</v-icon>
                                        </div>
                                        <div class="mt-0 pb-0 mt-2" v-if="pdf_report_available" style="background-color: #56565678; border-radius:5px 0px 0px 5px;height:40px; width:40px;display:flex;justify-content:center;">
                                        <v-icon color="white">mdi-file-pdf-box</v-icon>
                                    </div>
                                    </div>
                                </v-row>

                                <v-card v-if="importMode" width="100%" class="mt-0 pr-3" elevation="0" >
                                    <validation-observer v-slot="{ invalid }">
                                        <!-- Drag zone -->
                                        <v-card-text>
                                            <div class="file-input-zone-container">
                                                <div :class="['dropzone', dragging ? 'dropzone-over' : '']" @dragenter="dragging = true"
                                                    @dragleave="dragging = false"
                                                >
                                                    <div class="dropzone-info" @drag="onChange">
                                                        <span class="fa fa-cloud-upload dropzone-title"></span>
                                                        <v-icon class="mt-4" large color="primary"> mdi-cloud-download-outline</v-icon>

                                                        <div class="dropzone-upload-limit-info">
                                                            <div class="mt-4"><strong> {{ $t('documents_import.help') }}</strong></div>
                                                            <span class="mt-2">En cliquant sur ce cadre vous pouvez également</br>sélectionner le fichier que vous souhaitez importer.</span>
                                                            <span class="mt-0">Une fois vos fichiers sélectionnés cliquez sur le</br> bouton importer pour enregistrer vos documents.</span>
                                                        </div>
                                                    </div>

                                                    <input type="file" @change="onChange" accept="application/pdf" multiple>
                                                </div>
                                            </div>

                                            <div v-for="file in files" class="mt-6">
                                                <p class="uploaded-file-name pl-3 pr-3">{{ file.name }}</p>
                                                <v-icon @click="removeFile(file)" class="remove-file">mdi-close-circle-outline</v-icon>
                                            </div>
                                        </v-card-text>

                                        <!-- Validation zone + validation popup -->
                                        <v-card-actions class="pa-4 pt-0" style="width:100%;">
                                            <v-dialog transition="dialog-top-transition" v-model="dialog" width="600">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn v-bind="attrs" v-on="on" :disabled="isValid" color="primary" style="width:100%;">
                                                        Importer les documents sélectionnés
                                                    </v-btn>
                                                </template>

                                                <v-card>
                                                    <v-card-title>
                                                        {{ $t('documents_import.dialog_send_title') }}
                                                    </v-card-title>

                                                    <v-divider></v-divider>

                                                    <v-container>
                                                        <v-card-text class="text-center">
                                                            {{
                                                                $t('documents_import.dialog_send_text', {client_name: selected_professional.name})
                                                            }}
                                                        </v-card-text>

                                                        <v-card-actions class="justify-center">
                                                            <v-btn text link class="cancel-btn" @click="dialog=false">
                                                                {{ $t('global.cancel') }}
                                                            </v-btn>
                                                            <v-btn color="primary" @click="sendFiles()">
                                                                {{ $t('global.validation') }}
                                                            </v-btn>
                                                        </v-card-actions>
                                                    </v-container>
                                                </v-card>
                                            </v-dialog>
                                        </v-card-actions>
                                    </validation-observer>
                                </v-card>

                                <!-- uploader -->
                                <div :min-height="minHeight + 'px'" elevation="2" outlined height="100%" class="mt-5 pr-3">
                                    <v-card-text class="row pr-4" >
                                        <v-row v-if="!pdf_report_available && external_link_activated" class="mb-4 mr-2 ml-2 mt-2">
                                            <v-card
                                                :class="{ 'grey lighten-2': dragover }"
                                                class="w-100" height="120"
                                                @dragenter.prevent="dragover = true"
                                                @dragleave.prevent="dragover = false"
                                                @dragover.prevent="dragover = true"
                                                @drop.prevent="onDrop($event)"
                                                elevation="2" outlined
                                            >
                                                <v-card-text class="mt-12 text-center">
                                                    {{ $t('document.put_file_to_download') }}
                                                </v-card-text>
                                            </v-card>
                                        </v-row>

                                        <v-col v-if="pdf_report_available" class="col pa-0 ml-3">
                                            <v-row v-if="importMode">
                                                <v-col class="mb-1" style="font-weight:bold;border-bottom:1px solid #00000038;margin-left:14px;padding-bottom:1px;">
                                                    Documents enregistrés :
                                                </v-col>
                                            </v-row>

                                            <v-progress-linear color="primary" indeterminate v-if="loading_datatable"></v-progress-linear>

                                            <v-virtual-scroll :items="uploaded_files"  item-height="50" class="mt-1">
                                                <template v-slot:default="{ item }">
                                                    <v-list-item class="pr-0">
                                                        <v-list-item-content v-if="item.file_name">
                                                            <v-list-item-title>
                                                                <a @click.stop="readDocument(item.file_name)"> {{ item.file_name }}</a>
                                                            </v-list-item-title>
                                                        </v-list-item-content>

                                                        <v-list-item-action v-if="item.file_name">
                                                            <v-btn :href="item.path" download icon>
                                                                <v-icon> mdi-arrow-down-bold-circle</v-icon>
                                                            </v-btn>
                                                        </v-list-item-action>

                                                        <v-list-item-action v-if="item.file_name" style="margin-left:0px;">
                                                            <v-btn @click.stop="removeDocument(item.file_name)" icon>
                                                                <v-icon> mdi-close-circle</v-icon>
                                                            </v-btn>
                                                        </v-list-item-action>
                                                    </v-list-item>

                                                    <v-divider></v-divider>
                                                </template>
                                            </v-virtual-scroll>
                                        </v-col>
                                    </v-card-text>

                                    <template v-if="external_link_activated">
                                        <v-dialog v-model="dialog_add" max-width="500px">
                                            <v-card>
                                                <v-card-title>
                                                    <span class="headline">{{ $t('document.add_link_extern_document') }}</span>
                                                </v-card-title>
                                                <v-divider></v-divider>

                                                <v-card-text><br/>
                                                    <v-form v-model="valid">
                                                        <v-text-field
                                                            label="URL" solo
                                                            :prepend-icon="'mdi-web'"
                                                            v-model="external_link.link"
                                                        ></v-text-field>
                                                    </v-form>
                                                </v-card-text>

                                                <v-card-actions class="justify-end">
                                                    <v-btn @click="addExternalLink()" color="primary">
                                                        <v-icon left>mdi-content-save</v-icon>
                                                        {{ $t('global.validation') }}
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>

                                        <v-snackbar
                                            v-model="snackbar"
                                            :color="snackbar_type">
                                            {{ snackbar_text }}
                                            <template v-slot:action="{ attrs }">
                                                <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                                            </template>
                                        </v-snackbar>
                                    </template>
                                </div>  
                            </div>  

                            <PDFViewer
                                id="pdf"
                                :source="report_url"
                                style="height: 79vh;max-width:70%;background-color:white;border-radius: 0px 5px 0px 5px;"
                                v-if="pdf_report_available && !loading_datatable"
                            />
                            <div v-if="!pdf_report_available && !loading_datatable" style="height: 79vh;max-width:70%;background-color:white;border-radius: 0px 5px 0px 5px;text-align: center;width: 100%;background-color: #50505030;justify-content: center;display: flex;color: #666666;padding-top: 100px;}"> Chargement en cours...</div>

                            <div v-if="!pdf_report_available && loading_datatable" style="height: 79vh;max-width:70%;background-color:white;border-radius: 0px 5px 0px 5px;text-align: center;width: 100%;background-color: #50505030;justify-content: center;display: flex;color: #666666;padding-top: 100px;}"> Oups ! Aucun document ne parvient à être chargé.</div>
                        </v-row>
                    </v-card>
                </div>
            </v-col>
        </v-form>


        <!-- CONTENT UTS -->
        <div v-if="access_level != 0 ||  (access_level == 0 && !importMode)" class="row mt-0" style="background-color: transparent !important;" id="content">
            
            <!-- REPORT VIEW -->
            <v-col color="white" class="pa-6 pb-0 pt-0 col">
                <div v-if="loading_datatable == true"  style="color:grey;text-align: center;">
                    <li class="rounded row notification_card mb-1 pa-10 mt-15 ml-6" style="color: grey;justify-content: center;">
                        <div style="color:grey;text-align: center;"><strong>Chargement en cours...</strong></div>
                    </li>
                </div>            
                
                <div v-if="loading_datatable == false" flat color="white" class="pt-0 mb-3">
                    <!-- PDF REPORT VIB OR THERMO CONTENT -->
                    <v-card class="mt-3">
                        
                        <v-row class="pa-0 pl-3 pr-3">
                            <!--CHIPS-->
                            <div class="mt-0 pl-3 pr-0 pt-3" style="width:30%">
                                <!-- uploader V2 -->
                                <v-row class="ma-0 pa-0" style="justify-content: space-between;">
                                    <h4 class="pa-5 pt-3 pb-0 ml-1">Documents disponibles</h4>
                                    <div style="display: flex;">
                                        <v-switch
                                            v-model="importMode"
                                            class="mr-4 mt-2"
                                            v-if="access_level == 0"
                                        ></v-switch>
                                        <div class="mt-0 pb-0" v-if="!pdf_report_available" style="background-color: #ff5b00b0; border-radius:5px 0px 0px 5px;height:40px; width:40px;display:flex;justify-content:center;">
                                            <v-icon color="white">mdi-progress-download</v-icon>
                                        </div>
                                        <div class="mt-0 pb-0" v-if="pdf_report_available" style="background-color: #56565678; border-radius:5px 0px 0px 5px;height:40px; width:40px;display:flex;justify-content:center;">
                                        <v-icon color="white">mdi-file-pdf-box</v-icon>
                                    </div>
                                    </div>
                                </v-row>

                                <!-- uploader -->
                                <div :min-height="minHeight + 'px'" elevation="2" outlined height="100%" class="mt-5 pr-3" style="height:100%">
                                    <v-card-text class="row pr-4" >
                                        <v-row v-if="!pdf_report_available && external_link_activated" class="mb-4 mr-2 ml-2 mt-2">
                                            <v-card
                                                :class="{ 'grey lighten-2': dragover }"
                                                class="w-100" height="120"
                                                @dragenter.prevent="dragover = true"
                                                @dragleave.prevent="dragover = false"
                                                @dragover.prevent="dragover = true"
                                                @drop.prevent="onDrop($event)"
                                                elevation="2" outlined
                                            >
                                                <v-card-text class="mt-12 text-center">
                                                    {{ $t('document.put_file_to_download') }}
                                                </v-card-text>
                                            </v-card>
                                        </v-row>

                                        <v-row v-if="!pdf_report_available && external_link_activated" class="ma-2">
                                            <v-btn @click="openDialogAddExternalLink()" block color="primary">
                                                {{ $t('document.add_link_extern_document') }}
                                            </v-btn>
                                        </v-row>

                                        <v-col v-if="pdf_report_available" class="col pa-0 ml-3">

                                            <v-progress-linear color="primary" indeterminate v-if="!pdf_report_available"></v-progress-linear>

                                            <v-virtual-scroll :items="uploaded_files"  item-height="50" class="mt-1" style="overflow:hidden;height:100%;">
                                                <template v-slot:default="{ item }">
                                                    <v-list-item class="pr-0">
                                                        <v-list-item-content v-if="item.file_name">
                                                            <v-list-item-title>
                                                                <a @click.stop="readDocument(item.file_name)"> {{ item.file_name }}</a>
                                                            </v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>

                                                    <v-divider></v-divider>
                                                </template>
                                            </v-virtual-scroll>
                                        </v-col>
                                    </v-card-text>

                                    <template v-if="external_link_activated">
                                        <v-dialog v-model="dialog_add" max-width="500px">
                                            <v-card>
                                                <v-card-title>
                                                    <span class="headline">{{ $t('document.add_link_extern_document') }}</span>
                                                </v-card-title>
                                                <v-divider></v-divider>

                                                <v-card-text><br/>
                                                    <v-form v-model="valid">
                                                        <v-text-field
                                                            label="URL" solo
                                                            :prepend-icon="'mdi-web'"
                                                            v-model="external_link.link"
                                                        ></v-text-field>
                                                    </v-form>
                                                </v-card-text>

                                                <v-card-actions class="justify-end">
                                                    <v-btn @click="addExternalLink()" color="primary">
                                                        <v-icon left>mdi-content-save</v-icon>
                                                        {{ $t('global.validation') }}
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>

                                        <v-snackbar
                                            v-model="snackbar"
                                            :color="snackbar_type">
                                            {{ snackbar_text }}
                                            <template v-slot:action="{ attrs }">
                                                <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                                            </template>
                                        </v-snackbar>
                                    </template>
                                </div>  
                            </div>  

                            <PDFViewer
                                id="pdf"
                                :source="report_url"
                                style="height: 79vh;max-width:70%;background-color:white;border-radius: 0px 5px 0px 5px;"
                                v-if="pdf_report_available"
                            />
                            <div v-if="!pdf_report_available" style="height: 79vh;max-width:70%;background-color:white;border-radius: 0px 5px 0px 5px;text-align: center;width: 100%;background-color: #50505030;justify-content: center;display: flex;color: #666666;padding-top: 100px;}"> Aucun document à visionner.</div>
                        </v-row>

                    </v-card>               
                </div>
            </v-col>
        </div>

        <!-- SNACKBAR INFO DISPLAY -->
        <v-snackbar
            v-model="snackbar"
            :color="snackbar_type">
            {{ snackbar_text }}
            <template v-slot:action="{ attrs }">
                <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
            </template>
        </v-snackbar>
    </v-col>
</template>

<script>
import {DOCUMENTS} from "../../api";
import moment from "moment";
import PDFViewer from 'pdf-viewer-vue/dist/vue2-pdf-viewer'
import { VueEditor } from "vue2-editor";

const authorizedTypes = [
    {name: 'png', value: 'image/png'},
    {name: 'jpg', value: 'image/jpeg'},
    {name: 'pdf', value: 'application/pdf'}
];

export default {
    name: "CampainVision",
    components:{
        PDFViewer,
        VueEditor,
    },
    props: {
        minHeight: {
            type: Number,
            required: false,
            default: 350
        },
    },
    data() {
        this.authorizedTypes = authorizedTypes;
        return {
            infoToggle2: false, // bottom menu //
            infoToggle: false, // Machine menu package //
            label: '', // Machine menu package //
            importMode: true,
            // CAMPAINS ----------------
            campain_id:'',
            raw_campain: [],
            campain: [],
            // MACHINE ------------------
            machine_id:'',
            raw_machine: [],
            machine: [],
            parentType:"report",
            parentId:this.$route.params.id,
            // Other infos ------------------
            analysts: [],
            sites:[],
            // REPORT ----------------------
            raw_report:[],
            report_url:"",
            report_nb:0,
            reversed_indices:0,
            previous_indice:-1,
            previous_date:"",
            report_position:0,
            report: {
                id: null,
                uid:'',
                id_campain:'',
                id_machine:'',
                creator:'',
                manager:'',
                validator:'',
                type:'',
                step:'',
                alert_level:'',
                indice:'',
                tags:[], 
                trash:'',
                context: '',
                comment: '',
                preco:'',
                defaults: [],
                images:[],
                created_at:'',
                updated_at:'',
                released_at:'',
                intervenant:'',  
                intervention_date:'',  
                interlocutor:'',
                presta:'',               
            },
            machine_filters: [
                { level: -1, level_name: "Rapport en attente", level_color: "grey", count:0, annotation: "Etat à définir"},
                { level: 1, level_name: "Pour information : EDS 100% (vert)", level_color: "#4CAF50", count:0, percent: 100, annotation: "Etat neuf" },
                { level: 2, level_name: "Pour information : EDS 90% (vert)", level_color: "#4CAF50", count:0, percent: 90, annotation: "Comportement vibratoire correct" },
                { level: 3, level_name: "Pour information : EDS 80% (vert)", level_color: "#4CAF50", count:0, percent: 80, annotation: "Pour information" },
                { level: 11, level_name: "A suivre : EDS 70% (jaune)", level_color: "#FFCB05", count:0, percent: 70, annotation: "A suivre lors de la prochaine campagne" },
                { level: 12, level_name: "A suivre : EDS 60% (jaune)", level_color: "#FFCB05", count:0, percent: 60, annotation: "A suivre de manière rapprochée" },
                { level: 13, level_name: "A suivre : EDS 50% (jaune)", level_color: "#FFCB05", count:0, percent: 50, annotation: "Expertise complémentaire préconisée" },
                { level: 21, level_name: "Importance élevée : EDS 40% (rouge)", level_color: "#F44336", count:0, percent: 40, annotation: "Action à prévoir au prochain arrêt technique" },
                { level: 22, level_name: "Importance élevée : EDS 30% (rouge)", level_color: "#F44336", count:0, percent: 30, annotation: "Action à prévoir au prochain arrêt technique" },
                { level: 23, level_name: "Importance élevée : EDS 20% (rouge)", level_color: "#F44336", count:0, percent: 20, annotation: "Action à prévoir au prochain arrêt technique" },
                { level: 31, level_name: "Urgence : EDS 10% (Noir)", level_color: "black", count:0, percent: 10, annotation: "Action à prévoir dans les plus brefs délais" },
                { level: 32, level_name:"Urgence : EDS 0% (Noir)", level_color: "black", count:0, percent: 0, annotation: "Arrêt de la machine recommandé" },
            ],
            prestas:[
                {id:0, name:"Mesures vibratoires"},
                {id:1, name:"Thermographie"},
                {id:2, name:"Mesures ultrasons"},
                {id:3, name:"Equilibrages"},
                {id:4, name:"Expertise avancée"},
                {id:5, name:"Surveillance Online"},
            ],
            types:[
                {id:0, name:"Ponctuel"},
                {id:1, name:"Annuel"},
                {id:2, name:"Semestriel"},
                {id:3, name:"Quadrmestriel"}, 
                {id:4, name:"Trimestriel"},
                {id:5, name:"Continu"},
            ],
            report_types:[
                {id:0, name:"(VIB) Rapport au format PDF"},
                {id:1, name:"(VIB) Rapport au format digital"},
                {id:2, name:"(VIB) Rapport généré par les algorithmes"},
                {id:3, name:"(THERMO) Rapport au format PDF"},
            ],
            report_steps:[
                {id:0, name:"En attente", level_color: "grey"},
                {id:1, name:"En cours d'édition",level_color: "#ef780e"},
                {id:2, name:"En attente de validation",level_color: "#d40eef"},
                {id:3, name:"Validé",level_color: "#089d2d"},
                {id:4, name:"En cours de rectification",level_color: "#d40eef"},
                {id:5, name:"Livré",level_color: "#0e4cef"},
                {id:6, name:"En cours de rectification (post-livraison)",level_color: "#d40eef"},
            ],
            report_tags:[
                {id:1, name:"Rapport préliminaire"},
                {id:2, name:"Expertise avancée"},
                {id:3, name:"Expertise ODS"},
                {id:4, name:"Epertise Ultrasons"},
                {id:5, name:"Installation électrique"},
                {id:6, name:"Moyen particulier"},
                {id:7, name:"Contre visite"},
                {id:8, name:"Défauts de roulement"},
                {id:9, name:"Défauts BF"},
                {id:10, name:"Défauts HF"},
                {id:11, name:"Défauts électriques"},
            ],
            analysts_datas:[
                {id:40,  name:"Eric Quenneville", tel:"+33 6 38 38 11 18", email:"eric.quenneville@techview.fr", logo:"https://techview.fr/wp-content/uploads/2024/10/ERIC-QUENNEVILLE-CAT-III-e1725528314226-removebg-preview-2.png"},
                {id:121, name:"Pascal Ardoin",    tel:"+33 6 74 35 31 13", email:"pascal.ardoin@techview.fr", logo:"https://techview.fr/wp-content/uploads/2024/09/PASCAL-ARDOIN-CAT-II-e1725542858336.png"},
                {id:91,  name:"Amandine Prieur",  tel:"+33 7 87 58 28 98", email:"amandine.prieur@techview.fr", logo:"https://techview.fr/wp-content/uploads/2024/12/572e2dac-42b9-46f8-a7d5-995f87877850.png"},
                {id:278, name:"Louis Blaise",  tel:"+33 6 77 33 08 68", email:"louis.blaise@techview.fr"},
                {id:216, name:"Guillaume Maury",  tel:"+33 6 07 46 48 40", email:"guillaume.maury@techview.fr"},
                {id:79,  name:"Sébastien Poudenx",  tel:"+33 6 68 42 45 15", email:"sebastien.poudenx@techview.fr"},
            ],
            lineChartOptions: {
                title: {text: '(Indice) - Evolution de l\'état de santé machine'},
                time: {timezone: 'Europe/Paris',},
                lang: {noData: this.$t('three_dimensional_curve.data_loading_error')},
                height:140,
                noData: {
                    style: {
                        fontWeight: 'bold',
                        fontSize: '15px',
                        color: '#303030'
                    }
                },
                loading: {hideDuration: 1000,showDuration: 1000},
                scrollbar: {
                    liveRedraw: false,
                    height: 17,
                    barBackgroundColor: '#cfcfcf',
                    barBorderRadius: 9,
                    buttonBackgroundColor: '#c9c9c9',
                    buttonArrowColor: 'white',
                    buttonBorderWidth: 3,
                    buttonBorderRadius: 11,
                    rifleColor: 'white',
                    trackBorderRadius: 9
                },
                rangeSelector: {enabled: false,},
                updateArgs: [true, true, true],
                yAxis: {
                    title: {
                        text: 'Niveau de santé (%)',
                    },
                    plotBands: [],
                },
                plotOptions: {
                    line: {
                        connectNulls: true,
                    },
                    series: {
                        marker: {
                            enabled: true,
                            radius: 4
                        }
                    }
                },
                series: [{
                    name: 'Etat de santé',
                    data: []
                }],
                credits: {
                    enabled: false
                },
            },

            // GLOBAL --------------------
            access_level: this.$store.state.access_level,
            id: this.$route.params.id,
            loading_datatable:true,

            // Uploads V2
            alert_file_send: false,
            alert_file_error: false,
            alert_message: '',
            professionals: [],
            selected_professional: '',
            professionals_load: false,
            contracts: [],
            selected_contract: '',
            contracts_load: false,
            files: [],
            dragging: false,
            dialog: false,

            // Uploads
            dragover: false,
            files_to_upload: [],
            uploaded_files: [],
            loading: true,
            external_link: {
                id_relatable: this.$route.params.id,
                type_relatable: 'report',
                link: ''
            },
            external_links: [],
            dialog_add: false,
            valid: true,
            result: [],
            pdf_report_available:false,
            external_link_activated:false,

            // Uploads (img)
            img_dragover: false,
            img_files_to_upload: [],
            img_uploaded_files: [],
            img_loading: true,
            img_external_link: {
                id_relatable: this.$route.params.id,
                type_relatable: 'report_images',
                link: ''
            },
            img_external_links: [],
            img_result: [],
            img_available:false,
            img_urls:[],
        }
    },
    computed: {
        isValid() {
            let isValid = false
            if (this.files.length > 0 ) {
                isValid = true
            }
            return !isValid
        }
    },
    created() {
        moment.locale('fr');
        this.initialize();
    },
    methods: {
        // STARTING PROCESS
        initialize() {
            this.loading_datatable = true;
            this.getDocuments();
        },

        uploadDocuments() {
            this.loading_datatable = true;
            this.files_to_upload.forEach((file) => {
                let formData = new FormData();
                formData.append('files[]', file);
                formData.append('entity_id', 0);
                formData.append('type', "doc");

                axios.post(DOCUMENTS + '/uploadCommon', formData, this.configurationFile)
                    .then(() => {
                        this.getDocuments();
                    })
                    .catch((error) => {
                        if (error.response.status === 413) {
                            this.showSnackbar('warning', this.$t('document.too_large'));
                        } else {
                            this.showSnackbar('error', this.$t('global.failure_to_add'));
                        }
                    })
                    .finally(() => {
                        this.loading_datatable = false;
                    });
            });
        },
        getDocuments() {
            this.axios.get(DOCUMENTS + '/getCommonFiles', {
                    params: {
                        entity_id: 0,
                        type: "doc"
                    }
                }

            ).then((success) => {
                this.uploaded_files = [];
                console.log("pdf_report_available = " + this.pdf_report_available);
                
                if (this.checkDataAvailable(success.data)){
                    success.data.forEach((path) => {
                        let fileName = path.replace(/^.*[\\\/]/, '');
                        this.uploaded_files.push({
                            file_name: fileName,
                            path: path
                        });
                    });

                    this.uploaded_files.reverse();
                    this.report_url = this.uploaded_files[0].path;
                    this.showDocument();

                }else{
                    console.log("pdf_report_available = " + this.pdf_report_available);
                    this.pdf_report_available = false;
                }
            }).catch(() => {
                this.showSnackbar('error', 'GetDocuments error');

            }).finally(() => {
                this.mergeExternalLinkAndDocuments();
                this.loading_datatable = false;
            });
        },
        removeDocument(fileName) {
            this.loading_datatable = true;
            this.axios.delete(DOCUMENTS + '/deleteCommon', {
                    params: {
                        file_name: fileName,
                        entity_id: 0,
                        type: "doc"
                    }
                }
            ).then(() => {
                this.getDocuments();
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_delete'))
            }).finally(() => {
                this.loading_datatable = false;
            });
        },
        readDocument(fileName) {
            this.axios.get(DOCUMENTS + '/getCommonFiles', {
                    params: {
                        entity_id: 0,
                        type: 'doc',
                    }
                }

            ).then((success) => {
                this.uploaded_files = [];
                
                if (this.checkDataAvailable(success.data)){
                    success.data.forEach((path) => {
                        let fileName = path.replace(/^.*[\\\/]/, '');
                        this.uploaded_files.push({
                            file_name: fileName,
                            path: path
                        });
                    });

                    this.uploaded_files.reverse();
                    this.uploaded_files.forEach(item => {
                        if (fileName == item.file_name){
                            this.report_url = item.path;
                        }
                        
                    })
                    this.pdf_report_available = true;
                }else{
                    this.pdf_report_available = false;
                }
            }).catch(() => {
                this.showSnackbar('error', 'GetDocuments error');

            }).finally(() => {
                this.mergeExternalLinkAndDocuments();
            });
        },
        async showDocument(){
            await this.sleep(2000);
            this.pdf_report_available = true;
        },
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },

        // Uploads V2 methods
        onDrop(e) {
            this.dragover = false;
            // If there are already uploaded files remove them
            if (this.files_to_upload.length > 0) this.files_to_upload = [];
            // If user has uploaded multiple files but the component is not multiple throw error
            let file;
            for (let i = 0; i < e.dataTransfer.files.length; i++) {
                file = e.dataTransfer.files.item(i);
                let docCheck = this.uploadFilesCheck(file,['image/png','image/jpg','image/jpeg','application/pdf','text/csv'],10);
                if(!docCheck.authorized ) {
                    this.showSnackbar('error',docCheck.output.toString());
                }
                else {
                    this.files_to_upload.push(file);
                }
            }
            if (this.files_to_upload.length > 0) this.uploadDocuments();
        },
        onChange(e) {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                this.dragging = false;
                return;
            }
            this.createFile(files[0]);
        },
        createFile(file) {
            this.files.push(file);
            this.dragging = false;
        },
        removeFile(file) {
            this.files.splice(this.files.indexOf(file), 1);
        },
        sendFiles() {
            this.loading = true;
            this.dialog = false;
            
            this.files.forEach((file) => {
                let formData = new FormData();
                let docCheck = this.uploadFilesCheck(file,['application/pdf'],10);
                
                // if form factor NOK + file unavailable -> do not upload
                if(!docCheck.authorized || this.files.length < 1) {
                    this.showSnackbar('error',docCheck.output.toString());
                }

                // if form factor OK + file available -> upload
                if (docCheck.authorized && this.files.length > 0) {
                    formData.append('files[]', file);
                    formData.append('entity_id', 0);
                    formData.append('type', "doc");

                    axios.post(DOCUMENTS + '/uploadCommon', formData, this.configurationFile)
                        .then(() => {
                            this.getDocuments();
                            this.dialog = false;
                            this.alert_file_send = true;
                            setTimeout(() => {
                                this.alert_file_send = false;
                            }, 2500);
                        })
                        .catch((error) => {
                            if (error.response.status === 413) {
                                this.showSnackbar('warning', this.$t('document.too_large'));
                            } else {
                                this.showSnackbar('error', this.$t('global.failure_to_add'));
                            }
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            });
        },
        loadVue(id) {
            this.active_special_vue = id;

        },
        // DISPLAY DATA MANAGEMENT
        checkDataAvailable(datas){
            if(datas[0] != "" || datas[0] != 'undefined') {                    
                return true;
            }else {
                return false;
            }
        },

        // EXTERNAL LINKS MANAGEMENT (not used on 1.5.1)
        closeDialog() {
            this.dialog_add = false;
        },
        validURL() {
            let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
            this.valid = !!pattern.test(this.external_link.link);
            return this.valid;
        },
        mergeExternalLinkAndDocuments() {
            this.result = this.uploaded_files.concat(this.external_links);
        },
    },
}
</script>

<style>
.full-width-text .v-select__selections {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.pdf-viewer__body .loading-mask {
    z-index: 4!important;
}

.full-width-text .v-select__selection {
  flex: 1 1 auto;
  text-align: left;
}

.v-responsive__content{
    width: auto !important;
}

.custom-v-select .v-select__selections {
  width: 100%; /* S'assurer que l'élément prend toute la largeur */
}

.custom-v-select .v-select__selection {
  text-overflow: ellipsis; /* Ajuste l'affichage si le texte dépasse */
  white-space: nowrap; /* Évite les retours à la ligne */
  overflow: hidden; /* Masque le contenu qui déborde */
}

.custom-v-select input {
  display: none; /* Supprime visuellement l'élément <input> */
}
</style>

<style scoped>
.file-input-zone-container {
    text-align: center;
}

.dropzone {
    width: 100%;
    height: 200px;
    position: relative;
    border: 2px dashed #e1e1e1;
}

.dropzone:hover {
    border: 1px dashed #004181;
}

.dropzone-info {
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
}

.dropzone-title {
    color: #787878;
}

.dropzone input {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.dropzone-upload-limit-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.dropzone-btn {
    margin-left: auto;
    margin-right: auto;
}

.uploaded-file-name {
    display: inline;
}

.remove-file {
    display: inline;
    float: right;
}

.width-layout {
    max-width: 350px;
}

.cancel-btn {
    text-decoration: black underline;
}

.header__preview .end {
    display: none !important;
}

.header__preview .center {
    align-items: center;
    display: flex;
    width: 50% !important;
}
</style>