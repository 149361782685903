<template>
    <v-card class="rounded-lg mt-6" elevation="2" outlined>

        <v-card-title class="justify-center">
            {{ $t('operation.title') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-data-table
            :headers="headers"
            :items="operations"
            :items-per-page="5"
            :search="search"
            :loading="loading_datatable"
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-layout class="justify-start">
                        <v-btn @click="filterOperations()" class="ml-2 mt-6">
                            {{ $t('operation.operation_passed') }}
                            <v-switch
                                v-model="upcoming_operation"
                                color="primary" disabled
                                class="mt-5 pr-2 pl-2"
                            ></v-switch>
                            {{ $t('operation.operation_upcoming') }}
                        </v-btn>

                        <v-select
                            class="pr-2 pl-2 operation-type-select max-width-500"
                            :items="operation_types"
                            :label="$t('operation.select_type')"
                            v-model="operation_type_selected"
                            item-text="value"
                            item-value="id"
                            @change="getOperations()"
                            solo chips multiple deletable-chips dense
                        >
                        </v-select>
                    </v-layout>

                    <v-layout class="justify-end mt-7 max-width-300">
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            :label="$t('global.search')"
                        ></v-text-field>
                    </v-layout>
                </v-toolbar>
            </template>

            <template v-slot:item.building_name="{ item }">
                <router-link :to="{ path: '/building/' + item.subassembly.machine.building.id }">
                    {{ item.subassembly.machine.building.label }}
                </router-link>
            </template>

            <template v-slot:item.machine_name="{ item }">
                <router-link :to="{ path: '/machine/' + item.subassembly.machine.id }">
                    {{ item.subassembly.machine.label }}
                </router-link>
            </template>

            <template v-slot:item.subassembly_name="{ item }">
                <router-link :to="{ path: '/subassembly/' + item.subassembly.id }">
                    {{ item.subassembly.label }}
                </router-link>
            </template>

            <template v-slot:item.alert_level="{ item }">
                <v-icon :color="assetColor(item)" large v-if="item.alert_level !== '0'">
                    mdi-alert
                </v-icon>
            </template>

            <template v-slot:item.user_responsible_id="{ item }">
                {{ item.intervener.first_name + " " + item.intervener.last_name }}
            </template>

            <template v-slot:item.state="{ item }">
                {{ getStateOperationLabel(item.state) }}
            </template>

            <template v-slot:item.tags="{ item }">
                <v-chip-group>
                    <v-chip v-if="item.tags.length === 0" color="grey">{{ $t('global.no_tags_associated') }}</v-chip>
                    <v-chip v-for="tag in item.tags" class="pl-3 pr-3" link :key="tag.id">{{ tag.label }}</v-chip>
                </v-chip-group>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
import moment from "moment";
import {REFERENTIAL_GLOBALS} from "../../api";

export default {
    name: "DatatableOperations",
    components: {},
    props: {
        entityName: {
            type: String,
            require: true,
            default: () => {
            },
        }
    },
    data() {
        return {
            headers: [
                {text: this.$t('global.name'), value: 'label'},
                this.entityName === 'site' ? {
                    text: this.$t('machines.building'),
                    value: 'building_name'
                } : {align: ' d-none'},
                this.entityName === 'site' || this.entityName === 'building' ? {
                    text: this.$t('subassemblies.machine'),
                    value: 'machine_name'
                } : {align: ' d-none'},
                {text: this.$t('machines.subassemblies'), value: 'subassembly_name'},
                {text: this.$t('global.alert_level'), value: 'alert_level'},
                {text: this.$t('operation.alert_date'), value: 'date_operation'},
                {text: this.$t('operation.closing_date'), value: 'date_operation_realised'},
                {text: this.$t('operation.intervener'), value: 'user_responsible_id'},
                {text: this.$t('operation.type_operation'), value: 'type_operation.value'},
                {text: this.$t('operation.part_concerned'), value: 'part_concerned.value'},
                {text: this.$t('global.state'), value: 'state'},
                this.$store.state.access_level === 100 ? {
                    text: this.$t('global.tags'),
                    value: 'tags'
                } : {align: ' d-none'},
                {text: this.$t('global.last_comment'), value: 'comment'}
            ],
            operations: [],
            loading_datatable: true,
            search: '',
            upcoming_operation: true,
            operation_type_selected: [],
            operation_types: [],
        }
    },
    created() {
        this.getOperations();
        this.getOperationTypes();
    },
    methods: {
        getOperations() {
            this.loading_datatable = true;
            this.axios.get(
                "/api/" + this.entityName + "/" + this.$route.params.id + "/operations?upcoming_operation=" + this.upcoming_operation + "&types=" + this.operation_type_selected
            ).then((success) => {
                success.data.forEach(operation => {
                    operation.date_operation = moment(operation.date_operation).format('DD/MM/YYYY HH:mm');
                    if (operation.date_operation_realised) operation.date_operation_realised = moment(operation.date_operation_realised).format('DD/MM/YYYY HH:mm');
                })
                this.operations = success.data;
            }).finally(() => {
                this.loading_datatable = false
            })
        },

        getOperationTypes() {
            this.axios.get(
                REFERENTIAL_GLOBALS + '?type=type_operation'
            ).then((success) => {
                this.operation_types = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            });
        },

        filterOperations() {
            this.loading_datatable = true;
            this.upcoming_operation = !this.upcoming_operation;
            this.getOperations();
        },
    }
}
</script>

<style>
.operation-type-select {
    margin-top: 21px !important;
}

.max-width-300 {
    max-width: 300px !important;
}

.max-width-500 {
    max-width: 500px !important;
}
</style>
