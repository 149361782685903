import bearer from '@websanova/vue-auth/dist/drivers/auth/bearer';
import axios from '@websanova/vue-auth/dist/drivers/http/axios.1.x';
import router from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x';
import {AUTH_LOGIN, AUTH_LOGOUT, AUTH_ME, AUTH_REFRESH} from "../api";

/**
 * Authentication configuration, some of the options can be override in method calls
 */
const config = {
    auth: bearer,
    http: axios,
    router: router,
    parseUserData: function (data) {
        return data || {};
    },
    authRedirect: {
        path: '/login'
    },
    loginData: {
        url: AUTH_LOGIN,
        redirect: '',
        fetchData: true
    },
    fetchData: {
        url: AUTH_ME
    },
    refreshData: {
        url: AUTH_REFRESH,
    },
    logoutData:{
        url: AUTH_LOGOUT,
        makeRequest: true,
    }
};

export default config;
