<template>
    <v-autocomplete
        :items="professionals"
        v-model="professional_id"
        :label="$t('global.search_professional')"
        item-text="name"
        item-value="id"
        chips
        class="mt-7 select-client" solo
        id="selector"
        @change="switchProfessional()"
        style="max-width:334px;"
    >
        <template v-slot:selection="data" style="background-color:red;">
            <v-chip
            id="custom-select"
            v-bind="data.attrs"
            :input-value="data.selected"
            @click="data.select"
            @click:close="remove(data.item)"
            style="width:100%;height:80px;border-radius:0px;background-color:transparent;padding:0px;"
            >
                <v-avatar left style="max-width:75px!important;max-height:60px!important;min-width:75px!important;min-height:60px!important;border-radius: 0px;">
                    <v-img contain :src="data.item.logo" style="min-width:70px!important;min-height:60px!important;max-width:70px!important;max-height:60px!important;border-radius:none!important;"></v-img>
                </v-avatar >
                <div style="display:flex;flex-direction:column;margin-left:5px;">
                    <div style="font-size:10px;font-style:italic;margin-bottom:-5px;">Ref - {{ data.item.uid }}</div>
                    <div style="font-size:16px;font-weight:bold">{{ data.item.name }}</div> 
                </div>
            </v-chip>
        </template>
    </v-autocomplete>

</template>

<script>
import {USERS, AUTH_SWITCH} from "../../api";

export default {
    name: "SelectClient",
    data() {
        return {
            professionals: [],
            active_professional: null,
            professional_id: null,
        }
    },
    created() {
        this.getProfessionals();
    },
    methods: {
        // Récupération client
        async getProfessionals() {
            await this.axios.get(USERS + '/professionals').then((success) => {
                success.data.forEach( professional => {
                    if(professional.trash === 0){
                        this.professionals.push(professional);
                    }
                })

                // Load default professional ID - first accessible
                if (this.$store.state.professional_id === null) {
                    this.professional_id = 0;
                    this.switchProfessional();
                    
                    //----------------------------------------------------------------------------------------
                    // DEBUG :
                    //console.log("ID = null : professional list -> "+this.professionals+ " - First ID found");
                
                    // Load actual professional ID - already known
                } else {
                    this.professional_id = this.$store.state.professional_id;
                    this.refreshProfessional(this.professional_id);

                    //----------------------------------------------------------------------------------------
                    // DEBUG :
                    //console.log("ID != null "+ this.professional_id +" : professional list -> "+this.professionals);
                    //console.log("ID != null "+ this.professional_id +" : professional name -> "+this.professionals[this.professional_id].name);
                    //console.log("ID != null "+ this.professional_id +" : professional uid -> "+this.professionals[this.professional_id].uid);
                }

            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            }).finally(() => {
                this.loading = false
            });
        },
        // Sélectionner un client
        switchProfessional() {
            this.axios.put( AUTH_SWITCH + '/' + this.professional_id ).then(() => {
                // Load new professional ID - chosen one
                this.refreshProfessional(this.professional_id);
                
                //----------------------------------------------------------------------------------------
                //DEBUG :
                //console.log("ID switch "+ this.professional_id +" : professional list -> " + this.professionals);
                //console.log("ID switch "+ this.professional_id +" : new professional name -> "+this.professionals[this.professional_id].name);
                //console.log("ID switch "+ this.professional_id +" : new professional uid -> "+this.professionals[this.professional_id].uid);

                if (this.$route.name === "dashboard") {
                    this.$store.dispatch('sites/load');
                    this.$store.dispatch('professionals_resume/load');
                } 
                
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            }).finally(()=>{
                this.$router.push({name: 'dashboard_loading'});
            })
        },
        refreshProfessional(id){
            // Store professional data to display
            if (id === 0){
                this.$store.commit('setProfessionalData', professional[0]);
                
            }else{
                this.professionals.forEach((professional) => {
                    if (professional.id === id ){
                        this.$store.commit('setProfessionalData', professional);
                    }
                });
            }
        }
    }
}
</script>

<style>
.select-client {
    max-width: 334px !important;
}

.select-client .v-select__selections {
    display: flex!important;
    flex-direction: column!important;
}

#selector .v-autocomplete:not(.v-input--is-disabled).v-select.v-text-field input {
    position: relative!important;
    top: -7px!important;
    left: 14px!important;
    background-color: #0010470d!important;
    min-width: 315px!important;
    z-index: 0!important;
    color: #00104782!important;
    padding-left: 8px!important;
    border-radius: 5px!important;
}

</style>
