<template>
    <div id="devices-list" class="pa-4">
        <v-row no-gutters>
            <v-col>
                <p class="title mb-0 color-primary text-uppercase">{{ $t('physical_devices_datatable.title') }}</p>
            </v-col>
        </v-row>
        <v-card width="100%" class="mt-4" elevation="0">
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="physical_devices"
                    :search="search"
                    :loading="physical_device_data_table_loading"
                >
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-layout class="justify-start mt-7">
                                <p class="mt-4">{{ $t('global.filter_by') }}</p>
                                <v-select
                                    :items="physical_device_states"
                                    v-model="selected_physical_device_states"
                                    :placeholder="$t('physical_devices_datatable.filter')"
                                    item-text="name" item-value="id"
                                    filled dense class="ml-4 filter-input-width"
                                    chips multiple deletable-chips
                                ></v-select>
                            </v-layout>

                            <v-layout class="justify-end mt-7 max-width-300">
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    :label="$t('global.search')"
                                ></v-text-field>
                            </v-layout>
                        </v-toolbar>
                        <v-layout class="justify-end mt-2 mb-2">
                            <v-btn color="primary" class="mr-3" @click="downloadCsvPhysicalDevice">
                                <v-progress-circular v-if="loading_csv_file"
                                                     indeterminate color="white" size="20" width="2"
                                />
                                <v-icon v-else left>mdi-download-box-outline</v-icon>
                                {{ $t('global.download') }}
                            </v-btn>
                        </v-layout>
                    </template>

                    <template v-slot:item.date_last_seen="{ item }">
                        <template v-if="item.date_last_seen !==null">
                            <v-chip small :color="lastEmissionAttributeTranslation(item.date_last_seen).color">
                                <v-icon color="blue-grey darken-3" class="mr-1">
                                    mdi-sort-clock-descending-outline
                                </v-icon>
                                {{ lastEmissionAttributeTranslation(item.date_last_seen).time }}
                            </v-chip>
                        </template>

                        <template v-else>
                            <v-icon>mdi-cancel</v-icon>
                        </template>
                    </template>

                    <template v-slot:item.state="{ item }">
                        <span>{{ checkGatewayAndPhysicalDevicesStates(item.state) }}</span>
                    </template>

                    <template v-slot:item.first_name="{ item }">
                        <template v-if="item.first_name"> {{ item.first_name }}</template>
                        <template v-else><v-icon>mdi-cancel</v-icon></template>
                    </template>

                    <template v-slot:item.battery="{ item }">
                        <template v-if="item.battery || item.battery===0">
                            <span>{{ item.battery }}%</span>
                        </template>
                        <template v-else>
                            <v-icon>mdi-cancel</v-icon>
                        </template>
                    </template>

                    <template v-slot:item.gx_order_number="{ item }">
                        <template v-if="item.gx_order_number">
                            <span>{{ item.gx_order_number }}</span>
                        </template>
                        <template v-else>
                            <v-icon>mdi-cancel</v-icon>
                        </template>
                    </template>

                    <template v-slot:item.virtual_device_label="{ item }">
                        <template v-if="item.virtual_device_label === null">
                            <span>{{ $t('global.not_attached') }} </span>
                        </template>
                        <template v-else>
                            <span> {{ item.virtual_device_label }}</span>
                        </template>
                    </template>

                    <template v-slot:item.subassembly_label="{ item }">
                        <template v-if="item.virtual_device_label === null || item.subassembly_label === null">
                            <span>{{ $t('global.not_attached') }}</span>
                        </template>

                        <template v-else>
                            <v-dialog transition="dialog-top-transition" max-width="600">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on">
                                        <v-icon>mdi-wrench</v-icon>
                                    </v-btn>
                                </template>

                                <template v-slot:default="subassembly_dialog">
                                    <v-card>
                                        <v-card-title>
                                            {{ $t('physical_devices_datatable.physical_device_subassembly') }}
                                        </v-card-title>

                                        <v-divider/>

                                        <v-card-text class="mt-7">
                                            <v-text-field
                                                readonly filled
                                                :value=" item.subassembly_label "
                                                :label="$t('global.name')">
                                            </v-text-field>

                                            <v-text-field
                                                readonly filled
                                                :value=" item.building_label"
                                                :label="$t('machines.building')">
                                            </v-text-field>

                                            <v-text-field
                                                readonly filled
                                                :value=" item.machine_label "
                                                :label="$t('subassemblies.machine')">
                                            </v-text-field>

                                            <v-text-field
                                                readonly filled
                                                :value=" item.site_label "
                                                :label="$t('buildings.site')">
                                            </v-text-field>
                                        </v-card-text>

                                        <v-card-actions class="justify-end">
                                            <v-btn text @click="subassembly_dialog.value = false">
                                                {{ $t('global.close') }}
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </template>
                            </v-dialog>
                        </template>
                    </template>

                    <template v-slot:item.action="{ item }">

                        <template v-if="(item.state === 4 ||  item.state === -1) && (item.virtual_device_id === null)">
                            <v-tooltip top color="primary">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.uid,-3)">
                                        <v-icon v-bind="attrs" v-on="on">mdi-delete-empty</v-icon>
                                    </v-btn>
                                </template>

                                <span>{{ $t('physical_devices_datatable.device_scrapping_notice') }}</span>
                            </v-tooltip>
                        </template>

                        <template v-if="item.state === 4 && (item.virtual_device_id === null)">
                            <v-tooltip top color="primary">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.uid,1)">
                                        <v-icon v-bind="attrs" v-on="on">mdi-database</v-icon>
                                    </v-btn>
                                </template>

                                <span>{{ $t('physical_devices_datatable.device_storing_notice') }}</span>
                            </v-tooltip>
                        </template>

                        <template v-if="item.state === 2 && item.virtual_device_id !== null">
                            <v-tooltip top color="primary">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.uid,3)">
                                        <v-icon v-bind="attrs" v-on="on">mdi-video-input-antenna</v-icon>
                                    </v-btn>
                                </template>

                                <span>{{ $t('physical_devices_datatable.device_on_operation_state') }}</span>
                            </v-tooltip>
                        </template>

                        <template v-if="item.state === 3 && item.virtual_device_id === null">
                            <v-tooltip top color="primary">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.uid,-1)">
                                        <v-icon v-bind="attrs" v-on="on">mdi-cog-refresh</v-icon>
                                    </v-btn>
                                </template>

                                <span>{{ $t('physical_devices_datatable.device_in_maintenance_state') }}</span>
                            </v-tooltip>
                        </template>

                        <template v-if="item.state === 3 && item.virtual_device_id === null">
                            <v-tooltip top color="primary">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.uid,-2)">
                                        <v-icon v-bind="attrs" v-on="on">mdi-progress-close</v-icon>
                                    </v-btn>
                                </template>

                                <span>{{ $t('physical_devices_datatable.device_lost_state') }}</span>
                            </v-tooltip>
                        </template>

                        <template v-if="item.state === -1 && item.virtual_device_id === null">
                            <v-tooltip top color="primary">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon @click="$refs.confirm.openConfirmationAlert(item.uid,4)">
                                        <v-icon v-bind="attrs" v-on="on">mdi-clipboard-search</v-icon>
                                    </v-btn>
                                </template>

                                <span>{{ $t('physical_devices_datatable.device_testing_notice') }}</span>
                            </v-tooltip>
                        </template>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <device-update-confirmation ref="confirm" @Performed-action="refreshPhysicalDeviceDataTable"/>
        <global-snackbar/>
    </div>
</template>

<script>
import {DOWNLOAD_CSV_PHYSICAL_DEVICES, PHYSICAL_DEVICES} from "../../../../api";
import GlobalSnackbar from "../../../../global/components/GlobalSnackbar";
import DeviceUpdateConfirmation from "../../../components/Installer/DeviceUpdateConfirmation";

export default {
    name: "DevicesDatatable",
    components: {DeviceUpdateConfirmation, GlobalSnackbar},
    data() {
        return {
            physical_device_states: [
                {id: '1', name: this.$t('gateways_datatable.gateway_in_storage_state')},
                {id: '3', name: this.$t('gateways_datatable.gateway_on_operation_state')},
                {id: '-3', name: this.$t('gateways_datatable.gateway_scrapped_state')},
                {id: '-1', name: this.$t('gateways_datatable.gateway_in_maintenance_state')},
                {id: '2', name: this.$t('gateways_datatable.gateway_at_the_installer_state')},
                {id: '4', name: this.$t('gateways_datatable.gateway_to_test_state')},
                {id: '-2', name: this.$t('gateways_datatable.gateway_lost_state')}
            ],
            filter_selected_physical_device_state: "",
            selected_physical_device_states: [],
            search: '',
            loading_csv_file: false,
            physical_device_data_table_loading: false,
            headers: [
                {text: this.$t('physical_devices_datatable.physical_device_uid'), value: 'uid'},
                {text: this.$t('physical_devices_datatable.gx_order_number'), value: 'gx_order_number'},
                {text: this.$t('global.state'), value: 'state'},
                {text: this.$t('physical_devices_datatable.installer_name'), value: 'first_name'},
                {text: this.$t('virtual_devices.virtual_device'), value: 'virtual_device_label'},
                {text: this.$t('virtual_device_state.battery'), value: 'battery'},
                {text: this.$t('gateways_datatable.last_emission'), value: 'date_last_seen'},
                {text: this.$t('global.subassembly'), value: 'subassembly_label'},
                {text: this.$t('global.actions'), value: 'action'},
            ],
            physical_devices: [],
        }
    },
    watch: {
        selected_physical_device_states() {
            this.physical_device_data_table_loading = true;
            this.filter_selected_physical_device_state = '';
            if (this.selected_physical_device_states.length !== 0) {
                this.selected_physical_device_states.forEach((selected_physical_device_state) => {
                    this.filter_selected_physical_device_state += (this.filter_selected_physical_device_state === '' ? selected_physical_device_state : ',' + selected_physical_device_state);
                });
            }
            this.getPhysicalDevices();
        }
    },
    created() {
        this.getPhysicalDevices();
    },
    methods: {
        getPhysicalDevices() {
            this.physical_device_data_table_loading = true;
            this.axios.get(
                PHYSICAL_DEVICES + '?selected_physical_device_state=' + this.filter_selected_physical_device_state
            ).then((success) => {
                this.physical_devices = success.data;
            }).catch((error) => {
                this.manageError(error.response.status,this.$t('global.error'));
            }).finally(() => {
                this.physical_device_data_table_loading = false;
            });
        },

        downloadCsvPhysicalDevice() {
            this.loading_csv_file = true;
            this.axios({
                url: DOWNLOAD_CSV_PHYSICAL_DEVICES + '?selected_physical_device_state=' + this.filter_selected_physical_device_state,
                method: 'GET',
                responseType: 'blob',
            }).then((success) => {
                this.downloadCsvFile(success.data, "_physical_devices.csv");
            }).catch((error) => {
                this.manageError(error.response.status,this.$t('global.failure_to_download'));
            }).finally(() => {
                this.loading_csv_file = false;
            });
        },
        async refreshPhysicalDeviceDataTable() {
            this.showGlobalSnackbar('success', this.$t('global.success_to_edit'));
            await this.getPhysicalDevices();
        },
    },
}
</script>

<style scoped>
.filter-input-width {
    max-width: 500px !important;
}
</style>
