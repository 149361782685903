<template>
    <div id="gateways-stock-reception" class="pa-4">
        <v-row no-gutters>
            <v-col>
                <p class="title mb-0 color-primary text-uppercase">
                    {{ $t('gateways_stock_reception.title') }}
                </p>
            </v-col>
        </v-row>
        <v-card width="100%" class="mt-4" elevation="0">
            <validation-observer ref="observer" v-slot="{ errors }">
                <v-form ref="form">
                    <v-card-text>
                        <validation-provider :name="$t('gateways_stock_reception.command_number')" rules="required"
                                             v-slot="{ errors }">
                            <v-select
                                v-model="gx_order_number"
                                :hint="$t('contract_avenant.gx_client_name_hint')"
                                :loading="gx_orders_fetch"
                                :items="gx_orders_numbers"
                                :label="$t('gateways_stock_reception.command_number')"
                                :error-messages="errors"
                                :disabled="block_gx_fetch"
                                :menu-props="{ bottom: true, offsetY: true }"
                                filled
                            >
                                <template v-slot:prepend-item>
                                    <v-list-item>
                                        <v-list-item-content class="ma-0 pa-0">
                                            <v-text-field
                                                v-model="gx_order_number_prefix"
                                                append-icon="mdi-magnify"
                                                :placeholder="$t('global.search')"
                                                @input="getGxOrdersNumber()"
                                            />
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                                <template v-slot:no-data>
                                    <v-list-item>
                                        <v-list-item-content class="ma-0 pa-0">
                                            {{ $t('global.no_results')}}
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-select>
                        </validation-provider>
                        <validation-provider :name="$t('gateways_stock_reception.gateway_list_description')"
                                             rules="required" v-slot="{ errors }">
                            <v-textarea
                                v-model="qr_code_input"
                                :label="$t('gateways_stock_reception.gateway_list')"
                                :messages="$t('gateways_stock_reception.gateway_list_description')"
                                :error-messages="errors"
                                auto-grow
                                filled
                            >
                            </v-textarea>
                        </validation-provider>
                        <template v-if="gateways_after_check">
                            <template v-for="(checked_gateway,index) in gateways_after_check">
                                <v-row class="mt-2 ml-4">
                                    <v-chip v-if="checked_gateway.gateway"
                                            :color="checked_gateway.color"
                                            class="mb-2"
                                            :key="checked_gateway.gateway"
                                            small
                                            draggable
                                            dark
                                    >
                                        <v-icon
                                            left
                                            class="mr-1"
                                            color="white"
                                            size="17"
                                        > {{ checked_gateway.icon }}
                                        </v-icon>
                                        <span class="font-10">
                                         {{ checked_gateway.gateway }}
                                    </span>
                                        <v-icon right
                                                class="ml-2"
                                                color="white"
                                                size="15"
                                                @click="refreshChipsAfterDelete(index,checked_gateway.gateway)">
                                            mdi-close-circle
                                        </v-icon>
                                    </v-chip>
                                </v-row>
                            </template>
                        </template>
                    </v-card-text>
                    <v-card-actions class="pr-4 pb-4">
                        <v-spacer/>
                        <v-btn
                            color="primary"
                            :disabled="invalid"
                            @click="validate"
                        >
                            {{ $t('global.validation') }}
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </validation-observer>
            <v-row class="ml-3 font-10">
                <v-icon left color="primary" size="15">mdi-alert-circle</v-icon>
                <span>{{ $t('physical_devices_stock_reception.physical_device_update_hint') }}</span>
                <v-icon left color="red" size="12" class="ml-2">
                    mdi-color-helper
                </v-icon>
                <span>
                    {{ $t('global.exist_notice') }}
                </span>
                <v-icon left color="green" size="12" class="ml-2">
                    mdi-color-helper
                </v-icon>
                <span>
                    {{ $t('global.valid') }}
                </span>
            </v-row>
        </v-card>
        <global-snackbar/>
    </div>
</template>

<script>
import {GATEWAYS} from "../../../../api";
import GlobalSnackbar from "../../../../global/components/GlobalSnackbar";

export default {
    name: "StockReception",
    components: {GlobalSnackbar},
    data() {
        return {
            invalid: false,
            gx_order_number: null,
            gx_orders_numbers: [],
            gx_order_number_prefix: null,
            gx_orders_fetch: false,
            qr_code_input: '',
            gateways: [],
            gateways_after_check: [],
            checkEmptyRule: [
                v => !!v || this.$t('global.empty_field'),
            ],
            block_gx_fetch:false,
            timeout_id:null,
            chips_rendering_timeout_id:null,
            error_collector:{
                found:0,
                success:0,
                chirp_stack_exist_reply: {
                    count :0,
                    gateway:''
                },
            },
        }
    },
    watch: {
        qr_code_input(val) {
            clearTimeout(this.chips_rendering_timeout_id);
            this.chips_rendering_timeout_id= setTimeout(()=>{
                this.gateways = val.match(this.gateway_regex);
                if (val.length < 2) {
                    this.gateways = [];
                    this.gateways_after_check = [];
                }
                this.refreshChips();
                this.chipsRendering();
            },1500);
        },
        gx_order_number_prefix(){
            this.resetAutoCompleteOrderGx();
        }

    },
    methods: {
        async validate() {
            this.$refs.form.validate();
            if (this.gateways_after_check.length === 0) {
                this.manageError(0,this.$t('global.invalid_gateway_number'));
                this.qr_code_input = '';
            } else {
                for (let i = 0; i < this.gateways_after_check.length; i++) {
                    if (this.gateways_after_check[i].color === 'green') {
                        await this.axios.post(
                            GATEWAYS,
                            {
                                gateway_uid: this.gateways_after_check[i].gateway,
                                gx_order_number: this.gx_order_number
                            }
                        ).catch((error) => {
                            if(error.response.status === 409) {
                                this.error_collector.chirp_stack_exist_reply.gateway += this.error_collector.chirp_stack_exist_reply.count === 0 ? error.response.data+ ' ' : ' '+this.gateways_after_check[i].gateway;
                                this.error_collector.chirp_stack_exist_reply.count++;
                            }
                        })
                    }
                }
                this.gatewayStateCheck();
                this.confirmationMessageDisplay();
                this.$refs.observer.reset();
                this.$refs.form.reset();
                this.qr_code_input = "";
                this.gateways = [];
                this.gateways_after_check = [];
                this.error_collector= {
                    found:0,
                    success:0,
                        chirp_stack_exist_reply: {
                            count :0,
                            gateway:''
                    },
                };
            }
        },
        gatewayStateCheck(){
            this.error_collector.found=0;
            this.error_collector.success=0;

            this.gateways_after_check.forEach((gateway)=>{
                gateway.color === 'red' ? this.error_collector.found++ : this.error_collector.success++;
            });
        },
        confirmationMessageDisplay() {

            let successMessage = this.$t('gateways_stock_reception.gateway_success_add_filter');

            if(this.error_collector.found === 0 && this.error_collector.chirp_stack_exist_reply.count === 0 && this.error_collector.success > 0){
                this.showGlobalSnackbar('success', this.$t('gateways_stock_reception.gateway_success_add'));
            }
            else if(this.error_collector.found === 0 && this.error_collector.chirp_stack_exist_reply.count > 0){
                successMessage= this.error_collector.chirp_stack_exist_reply.gateway;
                this.showGlobalSnackbar('warning', successMessage);
            }
            else if(this.error_collector.found > 0 && this.error_collector.success === 0){
                this.manageError(0, this.$t('gateways_stock_reception.not_added'));
            }
            else {
                this.showGlobalSnackbar('success',successMessage);
            }

        },
        async getGxOrdersNumber() {
            clearTimeout(this.timeout_id);
            this.timeout_id= setTimeout(async ()=> {
                if (this.gx_order_number_prefix) {
                    if (this.gx_order_number_prefix.length >= 4) {
                        this.gx_orders_fetch = true;
                        this.block_gx_fetch= true;
                        await this.axios.get(
                            '/api/gx/orders_number?order_number_prefix=' + this.gx_order_number_prefix
                        ).then((success) => {
                            if(Object.keys(success.data).length !== 0) {
                                this.gx_orders_numbers = success.data;
                            }
                            else {
                                this.gx_orders_numbers=[];
                            }
                            this.resetAutoCompleteOrderGx();
                        }).catch((error) => {
                            this.manageError(error.response.status,this.$t('global.error'));
                        }).finally(() => {
                            this.gx_orders_fetch = false;
                            this.block_gx_fetch=false;
                        });
                    }
                }
            },1000);
        },
        resetAutoCompleteOrderGx(){
            if(!this.gx_order_number_prefix && this.gx_orders_numbers.length >= 1){
                this.gx_orders_numbers = [];
                this.gx_orders_fetch = false;
                this.gx_order_number=null;
            }
        },
        chipsRendering() {
            if (this.gateways) {
                this.gateways.forEach((gateway,index) => {
                    this.axios.get(GATEWAYS + '/' + gateway)
                        .then((success) => {
                            let chipColor = '';
                            let chipIcon = '';

                            if (success.data.id || gateway.length > 16 || gateway.length < 16) {
                                chipColor = 'red';
                                chipIcon = "mdi-alert-octagon"
                            } else {
                                chipColor = 'green'
                                chipIcon = 'mdi-checkbox-marked-circle-outline'
                            }
                            if (this.gateways.length === 0) {
                                this.gateways_after_check.push({
                                    gateway: gateway,
                                    color: chipColor,
                                    icon: chipIcon
                                });
                            } else {
                                let item_index = 0;
                                item_index = this.gateways_after_check.findIndex(obj => obj.gateway === gateway)
                                if (item_index === -1) {
                                    this.gateways_after_check.push({
                                        gateway: gateway,
                                        color: chipColor,
                                        icon: chipIcon
                                    });
                                }
                            }
                        });
                });
            }
        },
        refreshChipsAfterDelete(index, gateway_eui) {
            if (this.gateways_after_check && this.qr_code_input.length > 0) {
                this.gateways_after_check.splice(index, 1);
                this.gateways.splice(index, 1);
                this.qr_code_input = this.qr_code_input.replace(gateway_eui, "");
            }
        },
        refreshChips() {
            if (!this.gateways) {
                this.gateways_after_check = [];
            }
            if (this.gateways_after_check) {
                for (let i = this.gateways_after_check.length - 1; i >= 0; i--) {
                    if (!this.gateways.find(gateway => {
                        gateway === this.gateways_after_check[i].gateway;
                    })) {
                        this.gateways_after_check.splice(i, 1);
                    }
                }
            }
        },
    }
}
</script>
