export default {
    "fr": {
        "global": {
            "logout": "Déconnexion",
            "notifications": "Centre de notifications",
            "error": "Erreur interne du serveur - Veuillez réessayer.",
            "validation": "Valider",
            "delete": "Supprimer",
            "dissociate": "Dissocier",
            "close": "Fermer",
            "cancel": "Annuler",
            "search": "Rechercher",
            "send": "Envoyer",
            "failure_to_add": "Échec de l'ajout",
            "success_to_add": "Ajout réussi",
            "success_to_import": "Import réussi",
            "success_to_edit": "Mise à jour réussi",
            "success_to_delete": "Suppression réussie",
            "failure_to_update": "Échec de la mise à jour.",
            "failure_to_delete": "Échec de la suppression.",
            "failure_to_import": "Échec de l'import.",
            "failure_to_download": "Échec du téléchargement.",
            'failure_to_find_email': "Aucune compte n'est associé à l'adresse email spécifié",
            "number": "Numéro",
            "intern_number": "Numéro interne",
            "value": "Valeur",
            "actions": "Actions",
            "name": "Nom",
            "state": "Etat",
            "comment": "Commentaire",
            "last_comment": "Dernier commentaire",
            "color": "Couleur",
            "optional": "Optionnel",
            "add": "Ajouter",
            "status": "Statut",
            "devices": "Capteurs",
            "device": " Le capteur sera en état : ",
            "gateway": "Le Passerelle sera en état : ",
            "metrics": "Mesures",
            "label": "Label",
            "tags": "Tags",
            "no_tags_associated": "Aucun tags associés",
            "notes": "Notes",
            "start_date": "Date de début",
            "end_date": "Date de fin",
            "alert": "Alerte",
            "alert_level": "Niveau d'alerte",
            "virtual_device_name": "Nom du capteur",
            "professional": "Professionnel",
            "type": "Type",
            "subassembly": "Sous ensemble",
            "for_example": "Par exemple :",
            "modify_notes": "Modifier les notes",
            "all": "Tous",
            "search_professional": "Sélectionner un compte",
            "clients": "Clients",
            "clients_list": "Voir tous les clients",
            "repertories": "Répertoire clients",
            "years": "Année(s)",
            "months": "mois",
            "days": "jours",
            "day": "jour",
            "hours": "heures",
            "hour": "heure",
            "schedule": "Horaire",
            "seconds": "secondes",
            "second": "seconde",
            "minutes": "minutes",
            "minute": "minute",
            "upload_new_img": "changer d'image",
            "date": "Date",
            "image": "Image",
            "visualization": "Visualisation",
            "general_state": "Etat général",
            "no_image": "Aucune image à charger",
            "dashboard_map_title": "Cartes des équipements",
            "site_state_machine": "Etat du parc : machines",
            "site_state_sensor": "Etat du parc : capteurs",
            "no_client_selected": "Aucun client sélectionné. Veuillez créer ou sélectionner un client.",
            "id": "ID",
            "uid": "UUID Virtuel",
            "lora_uid": "UID LORA",
            "unvalid_address": "Adresse saisie non trouvée. Veuillez sélectionner une adresse depuis la liste des propositions.",
            "edit": "Éditer",
            "download": "Télécharger",
            "filter_by": "Filtrer par : ",
            "empty_field": "Champ Vide, Veuillez à le remplir",
            "error_field": " Veuillez à vérifier le champ",
            "update": "Mettre à jour",
            "profil": "Profil",
            "chirp_stack_profile_number": "Numéro de Profil ChirpStack",
            "not_attached": "Non attaché",
            "not_found": "Non récupéré",
            "access_denied_title": "Accès refusé",
            "access_denied_text": "Vous n'avez pas accès à cette page",
            "forgotten_password": "Mot de passe oublié ?",
            "click_here": "Click Ici",
            "stand_by": "Veuillez patienter...",
            "update_alert": 'des nouvelles mises à jour auront lieu ! Veuillez confirmer avant de continuer.',
            "attention": "Attention!!:",
            "exist": "un des éléments scannés existe déja",
            "does_not_exist": "un des éléments scannés n'existe pas",
            "total": "Total",
            "exist_notice": " L'élément existe déjà",
            "not_exist_notice": " L'élément n'existe pas",
            "valid": " L'élément est valide",
            "password_not_match": "les nouveaux mots de passe ne correspondent pas",
            "email_exist": "Un des email spécifiés possède déja un compte",
            "invitation_sent": "Les invitations ont été envoyées aux destinataires",
            "max_length": "Vous avez depassé la limite acceptable",
            "password_unmatch": " Le mot de passe actuel que vous avez spécifié n'est pas correcte",
            "invalid_sensor_number": "Aucun numéro de capteur n'est valide",
            "invalid_gateway_number": "Aucun numéro de passerelle n'est valide",
            "elapsed_time": "Il y a ",
            "confirmation_message": "Confirmation",
            "inscription": "Inscription",
            "no_data": "Pas de données",
            "confirm_operation": "Etes-vous sûre d'effectuer cette opération ?",
            "add_graphic": "Ajouter une courbe simple",
            "network_down": "Un problème est survenu lors de la connexion",
            "no_results": "Aucun résultat",
            "curve": "Courbe",
            "x_axis": "Axe X",
            "y_axis": "Axe Y",
            "z_axis": "Axe Z",
            "too_many_files": "Trop de fichiers !",
            "authorized_file_extension": "Formats tolérés pour les fichiers: ",
            "uploaded_file_error": "Impossible de soumettre le message ! Pensez à vérifier vos fichier",
            "curve_no_data": "Aucune donnée enregistrée pendant la période sélectionnée",
            "loading": "Chargement en cours...",
            "1_5_2": "Cette fonctionnalité sera disponible dans la prochaine version (1.5.3)",
            "1_5_3": "Cette fonctionnalité sera disponible dans la prochaine version (1.5.3)",
            "campain_date":"Date de la campagne",
            "park_details": "Sites & regroupements",
            "equipement_details": "Machines & sous-ensembles",
        },
        "park": {
            "title": "Parc machines",
            "add_park": "Ajouter un parc machines",
            "add_regroupment": "Ajouter un regroupement de machines",
            "edit_park": "Editer un parc machines",
            "desactivate_park": "Désactiver un parc machines",
            "redirect": "Voir le parc machines en détails",
        },
        "navbar": {
            "dashboard": "Tableau de bord",
            "dashboard_admin": "Mon espace",
            "assets": "Actifs",
            "subassemblies": "Sous ensembles",
            "machines": "Equipements",
            "buildings": "Regroupements",
            "sites": "Parc machines",
            "modules": "Modules",
            "parameters": "Paramètres",
            "settings": "Réglages",
            "referentials": "Référentiels",
            "vision": "Vision",
            "installation": "Installation",
            "alerts": "Journal des évènements",
            "logs": "Logs",
            "roles": "Rôles",
            "virtual_devices": "Gestion capteurs",
            "subassembly_json": "Json sous ensembles",
            "users": "Gestion utilisateurs",
            "manage_users_admin": "Gestion des comptes",
            "store": "Espace magasinier",
            "devices": "Capteurs",
            "gateways": "Passerelles",
            "supplies": "Fournitures",
            "receiving_stock": "Réception stock",
            "stock_removal": "Sortie du stock",
            "add_remote_installer_stock": "Ajout stock (Installateur)",
            "physical_devices_list": "Capteurs en stock",
            "gateways_list": "Passerelles en stock",
            "stock_supplies": "Stock fournitures",
            "installer": "Espace Manager",
            "contract_management": "Espace assistant",
            "contracts_list": "Liste des contrats",
            "new_contract": "Nouveau contrat",
            "contract_avenant": "Nouveau contrat/Avenant",
            "documents_import": "Import de documents",
            "information_banner": "Bandeau d'information",
            "admin_section": "Administration",
            "analysts": "Espace analyste",
            "alerts_center": "Centre d'alertes",
            "calculs_center": "Centre de calculs",
            "mco_center": "Panneau MCO",
            "campains": "Campagnes",
        },
        "login": {
            "title": "Connexion",
            "email": "Email",
            "email_resend": "Renvoyer un email d'invitation",
            "password": "Mot de passe",
            "remember_me": "Se souvenir",
            "forgot_password": "Mot de passe oublié ?",
            "login": "connexion",
            "login_failed": "Login ou mot de passe incorrect",
            "error_during_login": "Erreur rencontrée lors de la connexion",
            "current_password": "Mot de passe actuel",
            "new_password_confirmation": "Confirmation du nouveau mot de passe",
            "forgot_password_title": "Réinitialisez votre mot de passe",
            "forgot_password_text": "Merci de renseigner votre email pour réinitialiser votre mot de passe",
            "edit_password": "Réinitialisez votre mot de passe",
            "forgot_password_notice": "Veuillez entrer votre adresse email, nous vous enverrons un lien pour réinitialiser votre mot de passe.",
            "forgot_password_advice": "Assurez-vous de vérifier votre boîte de réception après la validation.",
            "forgot_password_retransmission": "Si vous n'avez pas encore reçu d'email",
            "recovery_email_sent": "Un email de réinitialisation de mot de passe a été envoyé",
            "password_mismatch": "les mots de passe ne sont pas conformes",
            "error_reset_password": "Erreur rencontrée lors de la réinitialisation de votre mot de passe"
        },
        "password_recovery": {
            "new_password": "Nouveau mot de passe",
            "set_new_password": "Choisissez un nouveau mot de passe",
            "password": "Mot de passe",
            "new_password_confirmation": "Confirmation du nouveau mot de passe",
            "error_get_password_reset": "Erreur rencontrée lors de la récupération de la réinitialisation du mot de passe",
            "password_reset_not_found": "Token de réinitialisation de mot de passe non trouvé",
            "password_reset": "Mot de passe réinitialisé",
            "error_during_reset_password": "Erreur rencontrée lors de la réinitialisation du mot de passe",
        },
        "register": {
            "login": "Connexion",
            "gender": "Civilité",
            "first_name": "Prénom",
            "last_name": "Nom",
            "email": "Adresse email",
            "phone": "Téléphone",
            "password": "Mot de passe",
            "password_confirm": "Confirmation du mot de passe",
            "error_get_invitation": "Erreur rencontrée lors de la récupération de l'invitation",
            "invitation_not_found": "Invitation non trouvée",
            "error_during_inscription": "Erreur rencontrée lors de l'inscription",
            "error_during_login": "Erreur rencontrée lors de la connexion"
        },
        "profile": {
            "profile": "Profil",
            "last_name": "Nom",
            "first_name": "Prénom",
            "email": "Adresse email",
            "phone": "Téléphone",
            "modify_own_password": "Modifier mon mot de passe",
            "update_profile": "Mettre à jour",
            "profile_updated": "Profile mis à jour",
            "error_update_profile": "Erreur rencontrée lors de la mis à jour du profil",
            "reset_password": "Réinitialisez votre mot de passe",
            "actual_password": "Mot de passe actuel",
            "new_password": "Nouveau mot de passe",
            "confirm_new_password": "Confirmation du nouveau mot de passe",
            "password_update": "Mot de passe mis à jour",
            "wrong_password": "Mauvais mot de passe",
            "error_update_password": "Erreur rencontrée lors de la mise à jour du mot de passe"
        },
        "information_banner": {
            "title": "configurer un Bandeau d'information",
            "icon_when_alert": "Icone lorsque l'alerte est déclenchée",
            "banner_color": "Couleur du bandeau",
            "text_color": "Couleur du texte",
            "activate_banner": "Activer le bandeau",
            "title_banner": "Titre",
            "description": "Description",
            "update": "Mettre à jour",
            "information": "Information",
            "more_informations": "Plus d'infos"
        },
        "icon_picker_component": {
            "see_more": "Voir plus",
            "search_icon": "Rechercher une icône",
            "no_icon_found": "Pas d'icône trouvée",
        },
        "subassemblies": {
            "title": "Gestion des sous ensemble / Machines tournantes",
            "detail": "Détails du sous ensemble",
            "serial_number": "N° de série",
            "date_installation": "Date d'installation",
            "machine": "Machine",
            "subassembly":"Sous-ensemble",
            "devices": "Capteurs",
            "choice": "Choix",
            "add_subassembly": "Ajouter un sous ensemble",
            "edit_subassembly": "Editer un sous ensemble",
            "delete_subassembly": "Supprimer un sous ensemble",
            "manage_binding_virtual_devices": "Configuration du sous-ensemble.",
            "binder": "Associer des capteurs à ce sous-ensemble :",
            "virtual_device_to_bind": "Capteurs à associés",
            "choose_subassembly_configuration": "Choix du fichier de configuration du sous ensemble",
            "subassembly_type": "Type du sous ensemble",
            "invalid_rotation_speed": "Vitesse de rotation invalide",
            "rotation_speed": "Vitesse de rotation: Tr/min"
        },
        "operation": {
            "title": "Journal de Maintenance : Liste des opérations passées et à venir",
            "alert_date": "Date de l'alerte",
            "closing_date": "Date de clôture",
            "operation_intervener": "Intervenant pour l'opération",
            "operation_tags": "Tags pour l'opération",
            "periodicity": "Périodicité",
            "type_operation": "Type de l'opération",
            "part_concerned": "Type de sous-ensemble concerné",
            "subassembly_concerned": "Sous-ensemble concerné",
            "subassembly": "Sous ensemble",
            "intervener": "Intervenant",
            "operation_passed": "Clôturées",
            "operation_upcoming": "En cours",
            "add_operation": "Ajouter une opération",
            "update_operation": "Modifier l'opération",
            "empty_operation_timelines": "Aucune timeline pour cette opération",
            "operation_history": "Historique de l'opération",
            "add_message": "Ajouter un message",
            "expert_demand": "Demande expert",
            "join_file": "Joindre un fichier",
            "join_image": "Joindre une image",
            "state_operation": "Etat de l'opération",
            "to_realized": "A réaliser",
            "in_progress": "En cours",
            "block": "Bloquer",
            "close": "Clôturer",
            "undefined_state": "Statut non défini",
            "undefined_type": "Type non défini",
            "datetime_intervention": "Date de l'intervention",
            "datetime_operation": "Date de l'opération",
            "close_operation": "Clôturer une opération",
            "confirm_close_operation": "Êtes-vous sur de vouloir clôturer cette opération ?",
            "success_to_close": "Opération clôturée avec succès",
            "failure_to_close": "Erreur lors de la clôture",
            "operation_create": "Opération créée",
            "operation_update": "Opération mis à jour",
            "message_add": "Message ajouté",
            "expert_ask": "Question à un expert",
            "reminder": "Rappel",
            "plan_reminder": "Planifier un rappel",
            "date_sending_reminder": "Date d'envoi de l'email",
            "reminder_add": "Rappel ajouté",
            "select_type": "Type d'opération"
        },
        "sites": {
            "title": "Gestion du parc machines",
            "detail": "Détails du parc",
            "add_site": "Ajouter un parc machine",
            "edit_site": "Modifier un parc machine",
            "delete_site": "Supprimer un parc machine",
            "address": "Adresse",
            "country": "Pays",
            "site_plan": "Plan du site physique",
            "buildings": "Regroupements",
            "total_machines": "Nombre de machines",
            "city": "Ville",
            "postal_code": "Code postale",
            "no_buildings": "Aucun regroupement associé"
        },
        "sandbox": {
            "machine_choice": "Choisir l'équipement'",
            "machine_choice_title": "Équipement",
            "heure_date": "Date et heure",
            "period": "Période sélectionnée",
        },
        "datatable_machine": {
            "title": "Liste des machines dans les regroupements du site",
            "machine_name": "Nom de la machine",
            "building_name": "Nom du regroupement",
            "building_plan": "Plan du regroupement",
            "subassemblies": "Sous ensembles"
        },
        "datatable_building_machines": {
            "title": "Liste des machines dans le regroupement",
            "machine_name": "Nom de la machine",
            "location": "Emplacement",
            "subassemblies": "Sous ensembles",
            "associate_to_another_building": "Associer la machine à un autre regroupement"
        },
        "building_specifications": {
            "title": "Caractéristiques du regroupement"
        },
        "machine_specifications": {
            "title": "Caractéristiques de la machine"
        },
        "buildings": {
            "title": "Gestion des regroupements",
            "detail": "Détails du regroupement",
            "add_building": "Ajouter un regroupement",
            "edit_building": "Modifier un regroupement",
            "delete_building": "Supprimer un regroupement",
            "total_subassemblies": "Nombre de sous ensemble",
            "building_photo": "Photo du regroupement",
            "site": "Site",
            "machines": "Machines",
            "no_machines": "Aucune machine associée",
            "building_plan": "Plan du regroupement"
        },
        "machines": {
            "title": "Gestion des équipements",
            "detail": "Détails de l'équipement",
            "add_machine": "Ajouter un équipement",
            "edit_machine": "Modifier un équipement",
            "delete_machine": "Supprimer définitivement un équipement",
            "desactivate_machine": "Désactiver une machine",
            "restore_machine": "Restaurer une machine",
            "utilisation": "Utilisation",
            "total_virtual_devices": "Nombre de capteurs virtuels",
            "building": "Regroupement :",
            "batiment": "Regroupement :",
            "subassemblies": "Sous ensemble",
            "machine_photo": "Photo de l'équipement",
            "no_subassemblies": "Aucun sous ensemble associé",
            "select-machine": "Sélectionner comme machine",
            "show-more": "Voir plus (10)",
            "tags": "Tags",
            "refresh": "Rafraîchir",
            "sandbox": "Centre d'analyses en ligne",
            "minify": "Masquer les détails machine.",
            "plus": "Afficher les détails machine.",
            "documents": "Bibliothéque documentaire",
            "click": "Cliquez ici pour ajouter un sous-ensemble",
            "fonction": "Fonction",
            "more_info": "Informations complémentaires",
            "no_machine": "Aucune machine active trouvée",
            "create_machine": "Créez une machine ci dessus ou activez une machine existante inactive",
            "desactivate": "Machines désactivées",
            "no_equipment": "Vous n'avez aucun équipement",
            "first_equipment": "Créez votre premier équipement via le bouton (+) ci dessus",
            "no_equipment_category": "Aucun équipement présent dans cette catégorie",
            "ref": "Ref - ",
            "created_at": "Créé le : ",
            "type": "Type",
            "note": "Note :",
            "active_machine": "machines actives",
            "all_activated": "Toutes les machines sont actives.",
            "reports": "Rapports & expertises",
            "settings": "Caractéristiques & paramètres machine",
            "alerts": "Journal des évènements",
            "mesures": "Mesures & surveillances",
            "box": "Informations supplémentaires",
        },
        "device": {
            "title": "Détail du capteur",
        },
        "referential": {
            "title": "Gestion des référentiels",
            "type_operations": "Type d'opérations",
            "add_type_operation": "Ajouter un type d'opération",
            "edit_type_operation": "Editer un type d'opération",
            "delete_type_operation": "Supprimer un type d'opération",
            "parts_concerned": "Partie concernés",
            "add_part_concerned": "Ajouter une partie concernée",
            "edit_part_concerned": "Editer une partie concernée",
            "delete_part_concerned": "Supprimer une partie concernée",
            "expiration_day": "Date d'expiration",
            "add_referential_tag": "Ajouter un tag",
            "edit_referential_tag": "Editer un tag",
            "delete_referential_tag": "Supprimer un tag",
            "info_datatable_type_operation": "Permets de configurer les différents type d'opération réalisable sur un sous-ensemble.",
            "info_datatable_part_concerned": "Permets de configurer les différents éléments qui pourront être touchés par une opération.",
            "info_datatable_tag": "Permets de configurer les tags qui pourront être liés à des opérations, des utilisateurs...",
            "contract_types": "Type de contrats",
            "add_contract_type": "Ajouter un type de contrat",
            "edit_contract_type": "Editer un type de contrat",
            "delete_contract_type": "Supprimer un type de contrat",
            "info_datatable_contract_type": "Permets de configurer les différents types de contrat à lié à un client",
            "physical_device_types": "Types de capteurs physiques",
            "add_physical_device_type": "Ajouter un type de capteur physique",
            "edit_physical_device_type": "Editer un type de capteur physique",
            "delete_physical_device_type": "Supprimer un type de capteur physique",
            "info_datatable_physical_device_type": "Permets de configurer les différents types de capteurs physiques reçu via les commandes",
            "separate_mail_with": "Séparer les adresses mails par un espace"
        },
        "vee-validation": {
            "alpha": "Le champ {_field_} ne peut contenir que des lettres",
            "alpha_num": "Le champ {_field_} ne peut contenir que des caractères alpha-numériques",
            "alpha_dash": "Le champ {_field_} ne peut contenir que des caractères alpha-numériques, tirets ou soulignés",
            "alpha_spaces": "Le champ {_field_} ne peut contenir que des lettres ou des espaces",
            "between": "Le champ {_field_} doit être compris entre {min} et {max}",
            "confirmed": "Le champ {_field_} ne correspond pas",
            "digits": "Le champ {_field_} doit être un nombre entier de {length} chiffres",
            "dimensions": "Le champ {_field_} doit avoir une taille de {width} pixels par {height} pixels",
            "email": "Le champ {_field_} doit être une adresse e-mail valide",
            "excluded": "Le champ {_field_} doit être une valeur valide",
            "ext": "Le champ {_field_} doit être un fichier valide",
            "image": "Le champ {_field_} doit être une image",
            "integer": "Le champ {_field_} doit être un entier",
            "length": "Le champ {_field_} doit contenir {length} caractères",
            "max_value": "Le champ {_field_} doit avoir une valeur de {max} ou moins",
            "max": "Le champ {_field_} ne peut pas contenir plus de {length} caractères",
            "mimes": "Le champ {_field_} doit avoir un type MIME valide",
            "min_value": "Le champ {_field_} doit avoir une valeur de {min} ou plus",
            "min": "Le champ {_field_} doit contenir au minimum {length} caractères",
            "numeric": "Le champ {_field_} ne peut contenir que des chiffres",
            "oneOf": "Le champ {_field_} doit être une valeur valide",
            "regex": "Le champ {_field_} est invalide",
            "required": "Le champ {_field_} est obligatoire",
            "required_if": "Le champ {_field_} est obligatoire lorsque {target} possède cette valeur",
            "size": "Le champ {_field_} doit avoir un poids inférieur à {size}KB",
            "required_date_operation": "Le champ Date de l'opération est obligatoire",
            "required_date_intervention": "Le champ Date de l'intervention est obligatoire",
            "required_field": "Ce champ est requis",
            "color_input_limit": "Ce champ doit être compris entre 0 et 100"
        },
        "document": {
            "title": "Bibliothèque documentaire",
            "unauthorized_file_type_error": "Formats tolérés pour les fichiers: jpg, png, pdf",
            "add_link_extern_document": "Ajouter un lien",
            "put_file_to_download": "Déposer les fichiers ici pour les télécharger",
            "element_already_download": "Éléments déjà téléchargés",
            "too_large": "Fichier trop volumineux. Taille maximum accepté : 10 Mo.",
        },
        "subassembly_state": {
            "title": "Etat du sous ensemble",
            "put_image": "Glissez une photo ici (jpg,png)",
            "edit": "Edition du sous ensemble"
        },
        "favorite_curve": {
            "title": "Courbe favorite",
            "add_favorite_curve": "Ajouter une courbe",
            "add_curve": "Ajouter une courbe",
            "physical_value": "Grandeur physique",
            "high_frequency": "Haute fréquence",
            "low_frequency": "Basse fréquence",
            "order": "Ordre",
            "hertz": "Hertz",
            "display_thresholds_alerts": "Afficher les seuils d'alertes",
            "display_flags": "Afficher les opérations",
            "delete_favorite_curve": "Supprimer une courbe favorite",
            "no_data_for_this_date": "Aucune donnée pour cette date",
            "update_thresholds": "Modification des seuils d'alertes/pré-alertes",
            "pre_alert_high": "Pré-alerte haute",
            "alert_high": "Alerte haute",
            "pre_alert_low": "Pré-alerte basse",
            "alert_low": "Alerte basse",
            "linear_scale": "Linéaire",
            "log_scale": "Logarithmique",
        },
        "subassembly_specifications": {
            "title": "Caractéristiques",
            "building": "Regroupement",
            "machine": "Machine",
            "site": "Site",
            "date_installation": "Date d'installation",
            "see_more": "Voir plus...",
            "all_characteristics": "Ensemble des caractéristiques"
        },
        "roles": {
            "title": "Gestion des rôles",
            "access_level": "Niveau d'accès",
            "add_role": "Ajouter un rôle",
            "edit_role": "Modifier un rôle",
            "delete_role": "Supprimer un rôle",
            "total_user": "Nombre d'utilisateurs",
        },
        "virtual_devices": {
            "title": "Gestion des capteurs virtuels",
            "add_virtual_device": "Ajouter un capteur virtuel",
            "edit_virtual_device": "Modifier un capteur virtuel",
            "delete_virtual_device": "Supprimer un capteur virtuel",
            "last_time_see": "Dernier relevé",
            "device_not_bind": "Capteur non associé",
            "hardware": "Capteur physique",
            "virtual_device": "Capteur virtuel",
            "import_virtual_device": "Importer un capteur virtuel",
            "physical_device_reference": "Référence capteur physique"
        },
        "virtual_devices_details": {
            "device_not_bind_to_subassembly": "Aucun sous-ensemble lié",
            "device_not_bind_to_physical_device": "Aucun capteur physique lié"
        },
        "users": {
            "title": "Gestion des utilisateurs",
            "first_name": "Prénom",
            "last_name": "Nom",
            "email": "Adresse email",
            "phone": "Téléphone",
            "tags": "Tags",
            "offer": "Type d'offre",
            "logo": "Lien du logo",
            "role": "Rôle",
            "user_tags": "Tags pour l'utilisateur",
            "add_user": "Ajouter un utilisateur",
            "edit_user": "Editer un utilisateur",
            "gender": "Civilité",
            "password": "Mot de passe",
            "password_confirm": "Confirmation du mot de passe",
            "delete_user": "Supprimer un utilisateur",
            "edit_password": "Modifier le mot de passe de l'utilisateur",
            "edit_own_password": "Modifier mon mot de passe",
            "new_password_title": "Nouveau mot de passe :",
            "new_password": "Voici le nouveau mot de passe :",
            "site": "Site",
            "description_associated_site": "L'utilisateur recevra les alertes des sites sur lesquels il est associé",
            "no_sites_associated": "Aucun site associé",
            "associate_sites": "Associer des sites",
            "sites_to_associate": "Sites à associés",
            "error_bind_sites": "Erreur rencontrée lors de l'association à l'utilisateur"
        },
        "logs": {
            "title": "Gestion des Logs",
            "modal_title": "Log n°",
            "view_all": "Tout passer en vu",
            "del_duplicates": "Supprimer les doublons",
            "id": "Id log",
            "created_at": "Date de création",
            "message": "Message",
            "level": "Niveau du log",
            "level_name": "Nom du niveau",
            "context": "Contexte",
            "extra": "Extra",
            "actions": "Actions",
            "view_false": "Non vu",
            "view_true": "Vu",
            "select_logs": "Niveau des logs",
            "set_view_false": "Marquer en non vue",
            "set_view_true": "Marquer en vue",
        },
        "notification": {
            "title": "Centre de notifications",
            "modal_title": "Notification : ",
            "view_all": "Tout passer en vu",
            "del_duplicates": "Supprimer les doublons",
            "id": "Id notification",
            "subject":"Sujet",
            "from":"Envoyé par",
            "tags":"Tags associés",
            "from":"Envoyé par",
            "created_at": "Date de création",
            "message": "Message",
            "level": "Criticité",
            "level_name": "Niveau de criticité",
            "actions": "Actions",
            "view_false": "Non vu",
            "view_true": "Vu",
            "select_notification": "Niveaux de criticité",
            "set_view_false": "Marquer en non vue",
            "set_view_true": "Marquer en vue",
            "display_view_true": "Afficher les notifications vues",
            "display_view_false": "Afficher les notifications non-vues",
            "list-view": "Liste",
            "list-card": "Cartes",
            "refresh": "Rafraîchir",
            "tool-type-title": "Test 1",
            "tool-type-message": "Test message infobulle",
            "tags": "Tags",
            "show-more": "Voir plus (10)",
            "ras": "Rien à signaler",
            "consult": "Consultez ci dessous l'historique de vos notifications",
            "historic": "Historique des notifications",
            "redirect": "Redirection vers l'évènement",
        },
        "professionals": {
            "title": "Détail du professionel",
            "modal_title": "Notification : ",
            "view_all": "Tout passer en vu",
            "del_duplicates": "Supprimer les doublons",
            "id": "Id notification",
            "subject":"Sujet",
            "from":"Envoyé par",
            "tags":"Tags associés",
            "from":"Envoyé par",
            "created_at": "Date de création",
            "message": "Message",
            "level": "Criticité",
            "level_name": "Niveau de criticité",
            "actions": "Actions",
            "view_false": "Non vu",
            "view_true": "Vu",
            "select_notification": "Niveaux de criticité",
            "set_view_false": "Marquer en non vue",
            "set_view_true": "Marquer en vue",
            "display_view_true": "Afficher les notifications vues",
            "display_view_false": "Afficher les notifications non-vues",
            "title-customers": "Répertoire clients",
            "cards": "Affichage carte",
            "tags": "Tags",
            "refresh": "Rafraîchir",
            "select": "Sélectionner comme client actif",
            "edit": "Editer la fiche client",
            "disable": "Désactiver le client",
            "restore": "Réactiver le client",
            "show-more": "Voir plus (10)",
            "contract_info": "Informations contractuelles",
            "contact_info": "Informations de contact",
            "created": "Créé ",
            "ref": "Ref - ",
            "health": "Etat de santé",
            "duration": "pour une durée de",
            "etablished_contract": "Contrat établi le",
            "number_contract": "Contrat n°",
            "month":"mois",
        },
        "tooltip": {
            "test1": "Test 1 du titre",
            "test2": "Test 2 du contenu",
            "criticality-title": "La criticité d'une machine",
            "criticality-content": "La criticité d'une machine indique son importance dans le processus de production et l'impact potentiel de ses pannes sur l'ensemble du système.",
            "color-title": "Légende",
            "color-content": "Mettre la légende adaptée",
            "green-content": "Information",
            "green-content": "Aucun problème",
            "yellow-content": "Défaut naissant",
            "red-content": "Défaut avéré",
            "black-content": "Urgence",
            "capteurs": "Liste des capteurs installés sur le sous-ensemble, la couleur définit l'état de santé du sous-ensemble au point de mesure",
            "equipement": "Cliquer sur l'équipement vous redirigera vers sa page",
            "capteurs-title": "Explication des capteurs",
            "parentheses": "(*) Nombre de capteurs associés",
            "subassemblies": "Cliquer sur le sous-ensemble ci-dessous vous redirigera vers la page des sous-ensembles",
            "professional_page_title": "Bienvenue dans le Répertoire clients",
            "professional_page_content1": "Cette page vous permet de gérer efficacement l'ensemble des clients.",
            "professional_page_content2": "Voici ce que vous pouvez faire ici :",
            "professional_page_content3": 'Ajouter de nouveaux clients : Utilisez le bouton "Ajouter un client" pour enregistrer un nouveau client dans la base de données.',
            "professional_page_content4": "Consulter les informations des clients : Parcourez la liste pour voir les détails de chaque client",
            "professional_page_content5": "Rechercher et filtrer : Utilisez la barre de recherche pour trouver rapidement un client spécifique. Vous pouvez également appliquer des filtres pour affiner la liste selon le type de contrat",
            "notifications_page_title": "Centre de notifications",
            "notifications_page_content": "Cette page est une liste de toutes les notifications",
            "machine_page_title": "Gestion des équipements",
            "machine_page_content": "Cette page est une liste de toutes les machines",
            "machine_details_page_title": "Mesures & surveillances",
            "machine_details_page_content": "Toutes les données collectées par nos capteurs IoT et autre module Edge embarqués sont disponible sur cette page.",
            "machine_events_page_title": "Journal des évènements",
            "machine_events_page_content": "Le journal des évènements contient tous les évènements liés à votre moyen. Que ce soit une défaillance vu par nos algorithmes, une remarque de l'un de nos experts ou une information suite à une action de maintenance, tout l'historique est disponible ici.",
            "machine_reports_page_title": "Rapport & expertises", 
            "machine_reports_page_content": "Retrouver ici toutes les analyses réalisées par nos collaborateurs. Ces analyses contiennent les diagnostics et préconisations vous permettant d'optimiser vos actions de maintenance.",
            "machine_settings_page_title": "Paramètres machine",
            "machine_settings_page_content": "Cette page est une liste de tous les paramètres de la machine",
            "campain_page_title": "Liste des campagnes",
            "campain_page_content": "Cette page est une liste de toutes les campagnes",
            "park_page_title": "Parc machine",
            "park_page_content": "Cette page est votre parc machine",
            "error_pdf_content": "Si le PDF ne s'affiche pas correctement vérifier que AdBlock est désactivé",
        },
        "machine": {
            "select": "Sélectionner comme machine",
            "edit": "Editer la machine",
            "reports": "Rapports & expertises",
            "settings": "Caractéristiques & paramètres machine",
            "courbes": "Mesures & surveillances",
            "alerts": "Journal des évènements",
        },
        "report": {
            "tooltip_page_title": "Page [Rapports & expertises]",
            "tooltip_page_title_content1": "Retrouvez dans cet onglet les derniers rapports édités par nos équipes ou nos algorithmes.",
            "tooltip_page_title_content2": "Tous les rapports publiés sont ainsi accessibles en tous temps.",
            "tooltip_pdf1": "Si le rapport est au format PDF il se peut qu'il ne s'affiche pas.",
            "tooltip_pdf2": "Si l'erreur venait à se produire, veuillez désactiver votre bloqueur de publicité.",
            "campain": "Campagne",
            "analyst": "Analyste",
            "validator": "Validateur",
            "subassembly_equipment": "Sous-ensembles",
            "state": "Etat de santé global de la machine : ",
            "preco": "Préconisations de maintenance",
            "context": "Contexte de la réalisation des mesures",
            "default": "Défauts relevés",
            "comment": "Commentaires de l'analyste",
            "spectre": "Spectres spécifiques et complements d'informations",
            "machine": "Machine",
            "subassembly": "Sous ensemble",
            "unavailable_file1": "Le fichier associé n'est pas disponible.",
            "unavailable_file2": "Pour plus d'information veuillez contacter notre support à ",
            "no_report1": "Aucun rapport n'est disponible pour cette machine.",
            "no_report2": "Vous serez notifié dés la publication du prochain rapport, en cas besoin veuillez nous contacter à l'adresse ",
            "report_incoming": "[Rapport en cours de rédaction]",
            "mail": "support@techview.fr",
            "presta": "Prestation réalisée",
            "equipment": "Equipement",
            "health-state": "Etat de santé",

        },
        "clients": {
            "add-clients": "Ajouter un nouveau client",
        },
        "campain": {
            "add_intervention_date": "Date(s) de l'intervention",
            "list_card": "Liste des campagnes",
            "add": "Ajouter une campagne",
            "tags": "Tags",
            "edit": "Editer la campagne",
            "title": "Historique de nos interventions",
            "title_details": "Détails de la campagne",
            "refresh": "Rafraîchir",
            "select": "Sélectionner la campagne",
            "desactivate": "Désactiver la campagne",
            "ref": "Ref -",
            "created at": "Créée le :",
            "folder_number": "N° de dossier :",
            "park": "Parc machine associé :",
            "campain_manager": "Chargé de campagne :",
            "creator": "Créateur :",
            "machines_concerned": "Liste des machines concernées :",
            "reports_associated": "Rapports associés disponibles :",
            "infos": "Informations complémentaires :",
            "no_campain": "Aucune campagne active trouvée",
            "create_campain": "Créez une campagne ci dessus ou activez une campagne existante inactive",
            "desactivate_campain": "Désactiver la campagne",
            "fonction": "Fonction :",
            "all_activated": "Toutes les campagnes sont actives",
            "no_equipment": "Vous n'avez aucun équipement",
            "first_equipment": "Créez votre premier équipement via le bouton (+) ci dessus",
            "no_equipment_category": "Aucun équipement présent dans cette catégorie",
            "end": "campagnes terminées",
            "delete_campain": "Supprimer la campagne",
            "restore": "Restaurer la campagne",
            "delete": "Supprimer définitivement la campagne",
            "show_more": "Voir plus (10)",
            "add_presta":"Prestation",
            "add_type":"Type d'intervention",
            "add_affaire_number":"Numéro d'affaire",
            "add_manager":"Chargé de mission",
            "add_park":"Parc machine concerné",
            "add_machines":"Machines concernées",
            "add_name":"Intitulé",
            "add_tags":"Tags associés",
            "add_campain":"Ajouter une campagne ",
            "edit_campain": "Editer la campagne",
            "campain_date":"Date de la campagne",
            "notes":"Description de la campagne"
        },
        "dashboard": {
            "specific_tracking": "Suivi spécifique :",
            "alert_management": "Gestion des alertes :",
            "last_events": "Derniers évènements :",
            "report_historic": "Historique des rapports :",
            "state_park": "Etat de santé du parc :",
            "historic_graph": "Evènements :",
            "sensors_mco": "MCO Capteurs :",
            "alert_management_site": "SITE",
            "alert_management_machine": "EQUIPEMENT",
            "alert_management_solution": "SOLUTION",
            "alert_management_date": "Date",
            "alert_management_id": "ID",
            "alert_management_subject": "Description",
            "alert_management_criticity": "Criticité",
            "network": "Réseau",
            "battery": "Batterie",
            "gateway": "Passerelle",
            "filters": "Filtres :",
            "connect": "Connecté :",
            "disconnect": "Deconnecté :",
            "proportion": "Proportion co/déco",
            "quality": "Qualité",
            "installation": "Etat installation",
            "state": "Etat",
            "virtual_name": "Nom virtuel",
            "subassembly": "Sous-ensemble",
            "type": "Type",
            "hardware_id": "Hardware ID",
            "report_historic_criticity": "Criticité",
            "report_historic_machine": "Machine",
            "report_historic_name": "Campagne",
            "report_historic_expert": "Expert",
            "report_historic_date": "Date",
            "green_health_content": "Comportement vibratoire correct",
            "yellow_health_content": "Comportement vibratoire à surveiller",
            "red_health_content": "Action à prévoir au prochain arrêt technique",
            "black_health_content": "Intervention urgente",
            "add_element": "Choisissez vos éléments",
        },
        "devices": {
            "title": "Détail du capteur virtuel"
        },
        "subassembly_configurations": {
            "title": "Gestion des JSON pour les sous ensembles",
            "remained_expert_ticket": "Tickets expert restants",
            "no_remained_expert_ticket": "Vous ne pourrez plus faire une demande expert",
            "user_creator": "Utilisateur créateur",
            "user_editor": "Dernier utilisateur éditeur",
            "configuration": "Configuration",
            "add_subassembly_configuration": "Ajouter un json",
            "edit_subassembly_configuration": "Modifier un json",
            "delete_subassembly_configuration": "Supprimer un json",
            "not_json": "Le fichier n'est pas au format JSON.",
            "not_all_property": "Le JSON ne contient pas toutes les propriétés nécessaires.",
            "not_all_property_set": "Certaines propriétés du JSON ne sont pas remplies.",
            "not_good_type": "Les propriétés types ne sont pas au bon format.",
            "not_all_index_different": "Tous les index du json ne sont pas différents.",
            "value_different": "La valeur renseignée ne correspond pas à un choix disponibles",
            "validate_json": "Vérifier le JSON",
            "instruction_json_1": "Permets de configurer les différents JSON qui permettront de générer des formulaires différents selon le type de sous-ensemble voulu.",
            "instruction_json_2": "Toutes les formulaires doivent avoir le même format (sinon il ne pourra pas être validé). Les propriétés index, type et title sont obligatoires. Il est possible de rajouter la propriété icon.",
            "instruction_json_3": "Les index doivent tous avoir une valeur différente et ne doivent pas être présent plusieurs fois dans un objet. Le type à deux choix possible string ou integer. Pareil pour required qui sera soit true soit false.",
            "instruction_json_4": "Pour les icônes il faudra utiliser ceux de cette bibliothèque : ",
            "json_example_1": "[{\n" +
                "    \"index\": \"bearing_0_in\",\n" +
                "    \"type\": \"string\",\n" +
                "    \"title\": \"Reference roulement interieur moteur\",\n" +
                "    \"required\": \"true\"\n" +
                "    \"hidden\": \"false\"\n" +
                "}, {\n" +
                "\t\"index\": \"gear_0_driving\",\n" +
                "\t\"type\": \"integer\",\n" +
                "\t\"title\": \"Nbr de dent pignon menant moteur\",\n" +
                "\t\"icon\": \"mdi-account-plus\",\n" +
                "\t\"required\": \"false\"\n" +
                "    \"hidden\": \"true\"\n" +
                "}]",
            "for_selector": "Pour une liste déroulante :",
            "json_example_2": "[{\n" +
                "    \"index\": \"index_text\",\n" +
                "    \"type\": \"selector\",\n" +
                "    \"title\": \"Sélection du choix\",\n" +
                "    \"choice\": [{\n" +
                "           \"id\": \"1\",\n" +
                "           \"text\": \"Choix 1\"\n" +
                "       }, {\n" +
                "           \"id\": \"2\",\n" +
                "           \"text\": \"Choix 2\"\n" +
                "    }],\n" +
                "    \"value\": \"\",\n" +
                "    \"required\": \"true\",\n" +
                "    \"hidden\": \"false\"\n" +
                "}]",
        },
        "account_management": {
            "title": "Gestion des comptes",
            "add_user": "Ajouter un ou des compte(s)",
            "edit_user": "Modifier un utilisateur",
            "delete_user": "Supprimer un utilisateur",
            "gender": "Civilité",
            "first_name": "Prénom",
            "last_name": "Nom",
            "email": "Email",
            "phone": "Téléphone",
            "password": "Mot de passe",
            "confirm_password": "Confirmer le mot de passe",
            "role": "Rôle",
            "associated_clients": "Clients associés",
            "client_to_bind": "Client à associé(s)",
            "no_client_associated": "Aucun client associé.",
            "associate_clients": "Associer des clients",
            "clients_to_bind": "Clients à associés",
            "associate_client": "Clients associés",
            "created_at": "Date de création du contact",
            "date_last_seen": "Date de dernière connexion",
            "resend_mail_invitation": "Renvoyer un email d'invitation",
            "resend_mail_to": "Vous allez renvoyé un mail d'invitation à l'addresse {email}.",
            "invitation_send": "Toutes les invitations ont été envoyées",
            "invitation_resend": "Invitation renvoyée",
        },
        "virtual_device_specification": {
            "title": "Caractéristiques",
            "label": "Label",
            "type": "Type",
            "model": "Modèle",
            "supplier": "Fournisseur",
            "date_installation": "Date d'installation",
            "last_time_see": "Dernier relevé",
            "edit_virtual_device": "Edition du capteur",
            "no_physical_device_associate": "Pas de capteur physique associé",
            "subscription_expire_at": "L'abonnement expire dans",
            "no_contract_associated": "Aucun contrat associé",
            "expired": "Expiré",
            "dialog_manage_contract_title": "Gestion abonnement capteur virtuel",
            "dialog_manage_contract_associate": "Associer au contrat client",
            "dialog_manage_contract_dissociate": "Dissocier du contrat client",
            "dialog_manage_contract_associate_number_contract": "N° contrat",
            "dialog_manage_contract_associate_type_contract": "Solution",
            "dialog_manage_contract_associate_title": "Association contrat client",
            "dialog_manage_contract_associate_text": "Vous allez associer le capteur virtuel {virtual_device} au contrat client.Etes-vous sûre d'effectuer cette opération ?",
            "dialog_manage_contract_dissociate_title": "Dissociation contrat client",
            "dialog_manage_contract_dissociate_text": "Vous allez dissocier le capteur virtuel {virtual_device} au contrat client.Etes-vous sûre d'effectuer cette opération ?",
            "dialog_manage_contract_none_physical_device_associate": "Un capteur physique doit être associé pour avoir accès à cette action",
        },
        "virtual_device_state": {
            "title": "Etat du capteur physique",
            "battery": "Batterie",
            "subassembly": "Sous ensemble",
            "last_emission_was": "Dernière émission il y a ",
            "subscription_expire_at": "L'abonnement expire dans ",
            "no_subassembly_associated": "Aucun sous ensemble associé",
            "manage_binding_physical_device": "Modification du capteur physique associé",
            "physical_device_to_associate": "Capteur physique à associé",
            "current_dev_eui": "Dev EUI actuel",
            "transmission_quality": "Qualité d'émission (SF)",
            "dissociate_physical_device": "Dissocier le capteur physique",
            "dialog_dissociate_physical_device_title": "Dissocier le capteur physique",
            "dialog_dissociate_physical_device_text": "Vous allez dissocier le capteur virtuel {virtual_device} du capteur physique {physical_device}.",
        },
        "virtual_device_subassemblies": {
            "title": "Sous-ensemble lié au capteur virtuel",
            "label": "Label",
            "manage_binding_subassemblies": "Modification du sous-ensemble associé",
            "subassembly_to_associate": "Sous-ensemble à associé",
            "dialog_dissociate_subassembly_title": "Dissociation du sous-ensemble",
            "dialog_dissociate_subassembly_text": "Vous allez dissocier le sous-ensemble {subassembly} au capteur virtuel.",
            "change_image": "Changer l'image",
        },
        "virtual_device_localisation": {
            "title": "Localisation",
            "label": "Adresse postale",
        },
        "machine_state": {
            "title": "Etat de la machine",
            "put_image": "Glissez une photo ici (jpg,png)",
            "edit": "Edition de la machine"
        },
        "building_state": {
            "title": "Etat du regroupement",
            "put_image": "Glissez une photo ici (jpg,png)",
            "edit": "Edition du regroupement"
        },
        "site_state": {
            "title": "Etat du site",
            "put_image": "Glissez une photo ici (jpg,png)",
            "edit": "Edition du site"
        },
        "site_autocomplete_card": {
            "edit_title": "Modifier l'addresse de la machine",
            "title": "Adresse du site",
        },
        "datatable_machine_subassemblies": {
            "title": "Liste des sous ensembles dans la machine",
            "location_subassembly": "Emplacement du sous ensemble",
            "virtual_devices": "Capteur virtuels",
            "associate_to_another_machine": "Associer le sous ensemble à une autre machine",
            "no_virtual_devices": "Pas de capteurs virtuels"
        },
        "state_professional_park": {
            "title": "Etat du parc",
            "no_site_for_this_client": "Aucun site pour ce client"
        },
        "add_new_client": {
            "add_client": "Créer un nouveau compte client",
            "email": "Email",
            "phone_number": "Téléphone",
            "incomplete_invitation": "Attention, {invitation_receive} sur {invitation_send} invitations ont été envoyé. Soit l'utilisateur existe déjà, soit une invitation lui à déjà été envoyé."
        },
        "modal_comparison_curves": {
            "add_comparison_curves": "Ajouter une comparaison de courbe",
            "add_comparison_curves_check_notice": "Spécifiez au moins 2 métriques pour pouvoir ajouter une comparaison de courbe",
            "physical_value": "Grandeur physique",
            "delete_comparison_curves": "Supprimer une comparaison de courbe",
            "delete_message": "Souhaitez-vous supprimer la comparaison de courbe"
        },
        "dashboard_admin": {
            "title": "Tableau de bord",
            "physical_devices": "Capteurs physiques",
            "gateways": "Passerelles",
            "to_test": "A tester",
            "awaiting_receipt": "En attente de réception",
            "in_stock": "En stock",
            "in_stock_installer": "En stock (Installateur)",
            "installed_offline": "Installé(s) hors ligne",
            "installed": "Installé(s)",
            "low_battery": "Batterie faible",
            "gateway_number": "N° de passerelle",
            "gateway_address": "Adresse",
            "gateway_state": "Etat",
        },
        "installer_redirection_notice": {
            "redirection_client": "Vous allez être redirigé vers l'interface Client.",
            "redirection_ask_client": "Souhaitez-vous être redirigé ?",
            "warning_redirection_client": "ATTENTION : Fermeture de votre session ADMIN",
            "cancel_redirection_client": "REVENIR À L'INTERFACE ADMIN",
            "redirection_admin": "Vous allez être redirigé vers l'interface Admin.",
            "redirection_ask_admin": "Souhaitez-vous être redirigé ?",
            "warning_redirection_admin": "ATTENTION : Fermeture de votre session CLIENT",
            "cancel_redirection_admin": "REVENIR À L'INTERFACE CLIENT",
            "admin_space": "Espace Admin",
            "client_space": "Espace Client",
            "to_admin_space": "Se connecter à l'espace Admin",
            "to_client_space": "Se connecter à l'espace Client",
        },
        "physical_devices_stock_reception": {
            "title": "Réception stock de capteurs physiques",
            "command_number": "Numéro de commande GX",
            "device_type": "Type de capteur",
            "device_list": "Liste des capteurs de la commande",
            "device_list_description": "Scanner ou saisir le numéro des capteurs concernés",
            "device_success_add": "Votre commande a bien été mis en stock de capteurs physiques",
            "device_success_add_filter": "Seulement la liste de capteurs physiques valide a bien été ajouté au stock",
            "physical_device_update_hint": "Seuls les éléments en vert seront modifiés",
            "not_added": "Aucun capteur n'a été ajouté au stock",
            "chirp_stack_exist_reply": "Ces capteurs existent dans Chirpstack:",
            "get_physical_device_error": "Erreur rencontrée lors de la vérification de l'existence du capteur.",
            "get_physical_device_type_error": "Erreur rencontrée lors de la récupération des types de capteur."
        },
        "physical_devices_stock_exit": {
            "title": "Mettre des capteurs au rebus",
            "device_list": "Liste des capteurs de la commande",
            "device_list_description": "Scanner ou saisir le numéro des capteurs concernés",
            "device_success_exit": "La liste de capteurs physiques a bien été sortie du stock",
            "device_success_exit_filter": "Seulement la liste de capteurs physiques valide a bien été sortie du stock",
            "inappropriate_physical_device_state": "Cycle de vie du capteur non respecté",
            "inappropriate_physical_device_state_error": "Un ou plusieurs capteurs ne respectent pas le cycle de vie du capteur",
            "physical_device_linked_contract": "Le capteur est lié à un contrat",
            "physical_device_linked_contract_error": "Un ou plusieurs capteurs sont liés à un contrat",
            "physical_device_does_not_exist_stock": "Un ou plusieurs capteurs ne sont pas dans le stock actuel",
            "not_exit": "Aucun capteur n'est sorti du stock.Veuillez vérifier leur etat actuel"

        },
        "physical_devices_remote_installer_stock": {
            "title": "Ajout de capteurs physiques sur stock déporté installateur",
            "installer": "Choix de l'installateur",
            "device_list": "Liste des capteurs de la commande",
            "device_list_description": "Cliquez sur ce champ puis commencez à scanner",
            "not_affected": "Aucun capteur n'est affecté à l'installateur choisi.Veuillez vérifier leur etat actuel",
            "device_success_affectation": "La liste de capteurs physiques a bien été affectée à l'installateur",
            "device_success_affectation_filter": "Seulement les capteurs physiques valides ont été affectés à l'installateur",
        },
        "physical_devices_datatable": {
            "title": "Liste des capteurs physiques",
            "filter": "Tous les états de capteur",
            "installer_name": "Nom installateur",
            "device_history_modal_title": "Historique du capteur",
            "device_history_modal_action": "Action",
            "device_history_modal_made_on": "Effectué le",
            "device_history_modal_by": "Par",
            "device_history_notice": "Afficher l'historique du capteur",
            "device_scrapping_notice": "Mettre le capteur au rebus",
            "device_test_waiting_notice": "Mettre le capteur en attente de test",
            "device_storing_notice": "Mettre le capteur en stock",
            "device_testing_notice": "Mettre le capteur en test",
            "device_on_operation_state": "En fonctionnement",
            "device_in_maintenance_state": "En maintenance",
            "device_lost_state": "Perdu",
            "physical_device_subassembly": "Sous-ensemble associé",
            "physical_device_uid": "EUI",
            "gx_order_number": "Numéro de commande GX"
        },
        "gateways_stock_reception": {
            "title": "Réception stock passerelles",
            "command_number": "Numéro de commande GX",
            "gateway_list": "Liste des passerelles de la commande",
            "gateway_list_description": "Cliquez sur ce champ puis commencez à scanner",
            "gateway_success_add": "Votre commande a bien été mis en stock passerelle",
            "not_added": "Aucune passerelle n'a été ajoutée au stock",
            "gateway_success_add_filter": "Seulement les passerelles valides ont été ajoutées au stock",
            "chirp_stack_exist_reply": "Ces passerelles existent dans Chirpstack:"
        },
        "gateways_stock_exit": {
            "title": "Mettre des passerelles au rebus",
            "gateway_list": "Liste des passerelles de la commande",
            "gateway_list_description": "Scanner ou saisir le numéro des passerelles concernés",
            "gateway_success_exit": "La liste de passerelles à bien été sortie du stock",
            "not_exit": "Aucune passerelle n'est sortie du stock.Veuillez vérifier leur état actuel",
            "gateway_success_exit_filter": "Seulement la liste de passerelle valide a bien été sortie du stock",
        },
        "gateways_remote_installer_stock": {
            "title": "Ajout de passerelle sur stock déporté installateur",
            "installer": "Choix de l'installateur",
            "gateway_list": "Liste des capteurs de la commande",
            "gateway_list_description": "Scanner ou saisir le numéro des capteurs concernés",
            "gateway_success_affectation": "La liste de passerelles a été affectée à l'installateur",
            "not_affected": "Aucune passerelle n'est affectée à l'installateur choisi.Veuillez vérifier leur état actuel",
            "gateway_success_affectation_filter": "Seulement les passerelles valides ont été affectées à l'installateur",
        },
        "gateways_datatable": {
            "title": "Liste des passerelles",
            "hardware_mac": "Hardware MAC",
            "state": "Etat",
            "site": "Emplacement",
            "owned_by": "possédé par",
            "location": "Localisation",
            "last_emission": "Dernière émission",
            "filter": "Tous les états de passerelle",
            "gateway_history_modal_title": "Historique de passerelle",
            "gateway_history_modal_action": "Action",
            "gateway_history_modal_made_on": "Effectué le",
            "gateway_history_modal_by": "Par",
            "gateway_location": "Localisation passerelle",
            "gateway_history_notice": "Afficher l'historique de passerelle",
            "gateway_scrapping_notice": "Mettre la passerelle en rebus",
            "gateway_test_waiting_notice": "Mettre la passerelle en attente de test",
            "gateway_storing_notice": "Mettre la passerelle en stock",
            "gateway_testing_notice": "Mettre la passerelle en test",
            "gateway_on_operation_state": "En fonctionnement",
            "gateway_in_storage_state": "En Stock",
            "gateway_scrapped_state": "Au rebus",
            "gateway_in_maintenance_state": "En maintenance",
            "gateway_at_the_installer_state": "Chez l'installateur",
            "gateway_to_test_state": "A tester",
            "gateway_lost_state": "Perdu"
        },
        "supply_stock": {
            "title": "Stock fournitures"
        },
        "contracts_datatable": {
            "title": "Liste des contrats",
            "contract_number": "Numéro de contrat",
            "filter": "Client",
            "label": "Libellé",
            'edit': "Editer le contrat",
            "birthdate": "Date d'anniversaire",
            "techview_one": "Techview One",
            "techview_edge": "Techview Edge",
            "techview_advanced": "Techview Advanced",
            "techview_thermal": "Techview Thermal",
            "expert": "Tickets expert restants",
            "billing_period": "Période de facturation / Mois",
            "next_debit_date": "Date de prochain prélèvement",
            "contract_document": "Documents du contrat",
            "contract_history_modal_title": "Historique du contrat",
            "contract_history_modal_action": "Action",
            "contract_history_modal_made_on": "Effectué le",
            "contract_history_modal_by": "Par",
            "add_ticket_notification": "Ajouter des tickets",
            "add_ticket_title": "Ajouter des tickets",
            "add_ticket_notice": "Combien de tickets souhaitez-vous ajouter au client ?",
            "add_ticket_number": "Nombre de tickets",
            "contract_download": "Télécharger le contrat",
            "none_contract_found": "Aucun document",
            "current_contract_type_value": "Valeur actuelle",
            "max_contract_type_value": "Valeur maximale disponible",
        },
        "new_contract": {
            "title": "Création d'un nouveau contrat",
            "contract_number": "Numéro de contrat GX",
            "client": "Client",
            "billing_period": "Période de facturation / Mois",
            "contract_type": "Type de contrat",
            "device_number": "Nombre de capteurs",
            "next_withdrawal": "Date de prochain prélèvement"
        },
        "contract_avenant": {
            "title": "Création d'un avenant au contrat",
            "client": "Client",
            "gx_client": "GX_Client",
            "gx_contract": "Numéro de contrat GX",
            "contract_number": "CONTRAT N°",
            "contract_type": "Type de contrat",
            "device_number": "Nombre de capteurs",
            "label": "Libellé du contrat",
            "label_message": "Renseigner un libellé explicite permettant à l'installateur de connaitre le sité associé au contrat",
            "gx_client_name_hint": "Tapez les 4 premiers caractères pour lancer la recherche",
            "gx_contract_number_hint": "Choisissez d'abord un client GX",
            "contract_type_number_hint": "Tout type de contrat non spécifié se verra attribuer un 0 comme un nombre de capteur automatiquement",
            "contract_number_exist": "le numéro de contrat existe déjà",
        },
        "documents_import": {
            "title": "Import des documents",
            "client": "Nom du client",
            "contract_number": "N°_contract",
            "help": "Faites Glisser vos fichiers ici (format PDF uniquement)",
            "notice": "Vous pouver également",
            "selection_button": "Sélectionner Les fichiers A importer",
            "add_link_button": "ajouter un lien",
            "dialog_link_title": "Ajouter un lien",
            "dialog_link_input_label": "Lien",
            "dialog_send_title": "Importation de document",
            "dialog_send_text": "Vous allez importer des documents pour le client {client_name}! Etes-vous sûrs d'effectuer cette opération ?",
            "dialog_send_second_text": "Etes-vous sûre d'effectuer cette opération",
            "alert_success_import": "Les documents ont été importé avec succès",
            "alert_warning_import_type": "Veuillez sélectionner un type de fichier autorisé",
            "alert_warning_import_size": "Votre fichier ne doit pas dépasser 5 MB",
            "alert_warning_import_already_selected": "Ce document est déjà sélectionné",
        },
        "supplies_datatable": {
            "filter": "Fournisseurs",
            "designation": "Désignation",
            "marque": "Marque",
            "reference": "REST_ID_PROD",
            "fournisseur": "Fournisseur",
            "reference_fournisseur": "Reference Fournisseur",
            "quantite_commande": "Quantité commandé",
            "quantite_stock": "Quantité stock",
            "quantite_min": "Quantité Min",
        },
        "error_display": {
            "entity_not_found": "Entité non trouvée",
            "authorization_required": "Autorisation requise",
            "error_get_users": "Erreur rencontrée lors de la récupération des utilisateurs",
            "error_get_virtual_devices": "Erreur rencontrée lors de la récupération des capteurs",
            "error_get_professionals": "Erreur rencontrée lors de la récupération des professionnels",
            "error_get_roles": "Erreur rencontrée lors de la récupération des rôles",
            "error_get_invitations": "Erreur rencontrée lors de la récupération des invitations",
            "error_get_professionals_not_bind": "Erreur rencontrée lors de la récupération des professionnels",
            "error_bind_professionals": "Erreur rencontrée l'ajout des professionnels au client",
            "user_not_found": "Utilisateur non trouvé",
            "error_reset_password": "Erreur rencontrée lors de la réinitialisation du mot de passe",
            "error_send_mail_reset_password": "Erreur rencontrée lors de l'envoi de l'email",
            "error_during_update_password": "Erreur rencontré lors de la mis à jour du mot de passe",
            "error_get_physical_devices": "Erreur rencontrée lors de la récupération des capteurs physiques",
            "error_get_physical_device": "Erreur rencontrée lors de la récupération du capteur physique",
            "error_get_subassembly_virtual_device": "Erreur rencontrée lors de la récupération du sous ensemble du capteur virtuel",
            "error_get_subassemblies": "Erreur rencontrée lors de la récupération des sous ensembles",
            "error_get_coord_building": "Erreur rencontrée lors de la récupération de la localisation du capteur virtuel",
            "error_get_address_site": "Erreur rencontrée lors de la récupération de l'adresse du capteur virtuel",
            "error_get_sites": "Erreur rencontrée lors de la récupération des sites",
            "error_bad_request": "La syntaxe de la requête est erronée",
            "error_time_out": "Temps d’attente d’une requête du client, écoulé côté serveur",
            "error_internal_server": "Erreur interne du serveur",
            "error_bad_gateway_error": "Erreur mauvaise passerelle",
            "error_contract_type_max_value": "Vous avez déja un capteur virtuel lié à un type de contrat que vous voulez mettre sa valeur maximale à 0,Pensez à dissocier le capteur virtuel avant d'effectuer cette action !"
        },
        "word_curve": {
            "title": "Titre de la courbe",
            "delete": "Supprimer la courbe",
            "delete_message": "Souhaitez-vous supprimer la courbe",
            "color_1": "Couleur 1",
            "color_2": "Couleur 2",
            "favorite_curve_metric": "Cette métrique vous permettra d'ajouter une courbe TEXT",
            "word_curve_metric": "Cette métrique vous permettra d'ajouter une courbe 2D",
            "insufficient_data": "La métrique n'a aucune donnée durant la période sélectionée",
            "error_contract_type_max_value": "Vous avez déja un capteur virtuel lié à un type de contrat que vous voulez mettre sa valeur maximale à 0,Pensez à dissocier le capteur virtuel avant d'effectuer cette action !",
            "error_extern_api_connection": "Impossible d'extraire les métriques du capteur",
            "error_display_favorite_curve": "Aucune donnée enregistrée dans cette période"
        },
        "three_dimensional_curve": {
            "title": "Courbe 3D",
            "graphical_settings": "Paramètres du graphique",
            "appearance": "Apparence de la courbe",
            "selected_period": "Période sélectionée",
            "delete": "Supprimer la courbe 3D",
            "delete_message": "Souhaitez-vous supprimer la courbe 3D",
            "add_curve": "Ajouter une courbe multipoints",
            "add_three_d_curve": "Ajouter une courbe 3D",
            "curve_settings": "Paramètres courbe 3D",
            "influence_factor": "Grandeur d'influence",
            "influence_factor_hint": "Jeu de donnée qui influencera le dégradé de couleur appliqué sur la trajectoire",
            "gradient": "Dégradé",
            "minimal_value": "Valeur minimale",
            "maximal_value": "Valeur maximale",
            "curve_width": "Epaisseur de la courbe",
            "starting_time": "Heure du début",
            "ending_time": "Heure de fin",
            "not_valid_metrics": "Les métriques que vous aviez spécifiées ne sont pas valides",
            "null_metric": "Une des métriques spécifiées n'a aucune donnée",
            "data_loading_error": "Impossible d'extraire les données de la courbe",
            "color_notice1": "Les valeurs de la grandeur d'influence sont ramenées entre 0 et 100%.",
            "color_notice2": "Les valeurs inférieures ou égales à la valeur minimale seront teintées avec la première couleur.",
            "color_notice3": "Même raisonnement pour la valeur maximale.Les valeurs intermédiaires seront proportionnellement teintées.",
        },
    }
}