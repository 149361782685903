<template>
    <div id="delete-user">
        <v-dialog v-model="dialog_delete" max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="headline">{{$t('account_management.delete_user') }}</span>
                </v-card-title>

                <v-divider/>

                <v-card-actions class="justify-end pt-4">
                    <v-btn @click="dialog_delete = false">
                        <v-icon left>mdi-close</v-icon>
                        {{ $t('global.cancel') }}
                    </v-btn>
                    <v-btn color="error" @click="confirmDeleteUserInstallerExpert()">
                        <v-icon left>mdi-delete</v-icon>
                        {{ $t('global.delete') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import {ADMIN_USERS} from "../../../../api";

export default {
    name: 'DeleteUser',
    props: {
        users: {
            type: Array,
            require: true,
        },
    },
    data() {
        return {
            dialog_delete: false,
            delete_id: null,
            deleted_index: null
        }
    },
    methods: {
        openDialogDeleteUserInstallerExpert(user) {
            this.deleted_index = this.users.indexOf(user);
            this.delete_id = user.id;
            this.dialog_delete = true;
        },

        confirmDeleteUserInstallerExpert() {
            this.axios.delete(
                ADMIN_USERS + '/' + this.delete_id
            ).then(() => {
                this.users.splice(this.deleted_index, 1);
                this.showGlobalSnackbar('success', this.$t('global.success_to_delete'));
            }).catch(() => {
                this.manageError(error.response.status, this.$t('global.failure_to_update'), this.$t('error_display.user_not_found'));
            }).finally(() => {
                this.dialog_delete = false;
            });
        },
    }
}
</script>
