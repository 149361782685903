<template v-if="access_level == 0">
    <div id="setting-information_banner">
        <v-row>
            <v-col>
                <v-subheader>
                    <p class="title mb-0 color-primary text-uppercase">
                        {{ $t('information_banner.title') }}
                    </p>
                </v-subheader>
            </v-col>
        </v-row>

        <information-banner-configuration class="mt-6 "/>
    </div>
</template>

<script>
import InformationBannerConfiguration from "../../components/Setting/InformationBanner/InformationBannerConfiguration";

export default {
    name: "InformationBanner",
    components: {InformationBannerConfiguration},
    data() {
        return {
            access_level: this.$store.state.access_level,
        }
    }

}
</script>
