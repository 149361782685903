<template>
    <div id="machine-vision">
        <v-col class="col col-12 mt-0 pt-0 pa-0" style="margin-top:0px!important;padding:0px;position:relative; top:-12px;">

            <!-- MACHINE MENU PACKAGE -->
            <div style="padding:0px 12px;">
                <v-col class="pl-5 pr-5" style="background-color:#001047;color:#FFFFFF;border-radius: 0px 0px 5px 5px;">
                    <!-- TOP TITLE -->
                    <v-row class="pl-0 pt-5 mb-5" style="height: 52px; padding:5px;">
                        <!-- PAGE TITLE -->
                        <v-subheader class="v-subheader col theme--light">
                            <!------------------- Info bulle ---------------------->
                            <v-tooltip bottom dark class="mr-2 ml-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 mr-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                                        <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                                    </v-btn>
                                </template>
                                <template v-slot:default>
                                <div style="min-width: 200px;position:relative;z-index: 10;">
                                    <strong>Page [Equipements]</strong>
                                    <p>Toutes les machines que nous sommes susceptibles de surveiller se trouvent ici.</br>
                                        Toutes les caractéristiques techniques associées au suivi en ligne sont configurés sur cette page.</br>
                                        Ceci est valable par exemple pour les sous-ensembles. </br>
                                        Cliquer sur l'un d'entre eux pour le visualiser en détail.
                                    </p>
                                </div>
                                </template>
                            </v-tooltip>
                            <p class="title mb-0 color-white" style="color: white!important;">
                                <div style="color:#FFFFFFb3;margin-right:4px;">Equipements > </div><div @click="infoToggler()" style="color:#FFFFFF;font-weight:bold;"> {{ $t('global.equipement_details') }}</div>
                            </p>
                        </v-subheader>

                        <!-- GLOBAL ACTIONS -->
                        <v-col class="row mt-1 col-auto pa-0 pr-5">
                            <v-tooltip v-if="access_level <= 100" bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn text icon v-on="on" @click="$refs.VisionCards.openDialogAddMachine()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                        <v-icon color="white">mdi-plus</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machines.add_machine') }}</span>
                            </v-tooltip>
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn text icon v-on="on" @click="getData()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                        <v-icon color="white">mdi-tag-multiple</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('global.1_5_2') }}</span>
                            </v-tooltip>
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn text icon v-on="on" @click="getData()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                        <v-icon color="white">mdi-refresh</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('campain.refresh') }}</span>
                            </v-tooltip>
                            <!--
                            <v-tooltip v-if="infoToggle == true" bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn text icon v-on="on" @click="infoToggler()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                        <v-icon color="white">mdi-minus-box-multiple</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machines.refresh') }}</span>
                            </v-tooltip>

                            <v-tooltip v-if="infoToggle == false" bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn text icon v-on="on" @click="infoToggler()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                        <v-icon color="white">mdi-plus-box-multiple</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machines.refresh') }}</span>
                            </v-tooltip>
                            -->
                        </v-col>
                    </v-row>

                    <!-- MACHINE INFOS (UNUSED) -->
                    <v-col class="mb-5" v-if="infoToggle == true" style="min-height: 250px; background-color:#e8edff;border-radius:5px;">
                        <v-row>

                            <!-- insert HEADER sub content HERE -->

                        </v-row>
                    </v-col>

                    <!-- MACHINE LINKS -->
                    <v-row v-if="infoToggle == true" style="border-top: 1px solid #ffffff1c;">
                        <v-row class="pb-2 pt-2 pl-5" style="margin:0px;min-height:50px;">
                            
                        </v-row>
                        <v-row>
                            <!-- FORMAT SELECTOR -->
                            <v-row class="v-subheader theme--light mr-3">
                                <!-- SPACER -->
                                <v-col class="col"></v-col>

                                <!-- CARD VIEW -->
                                <v-tooltip bottom dark>
                                    <template v-slot:activator="{ on }">
                                        <v-btn text icon v-on="on" @click="setCards()" dusk="logout-button">
                                            <v-icon color="white">mdi-card-text-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('notification.list-card') }}</span>
                                </v-tooltip>

                                <!-- LIST VIEW -->
                                <v-tooltip v-if="access_level <= 99" bottom dark>
                                    <template v-slot:activator="{ on }">
                                        <v-btn text icon v-on="on" @click="setDatatable()" dusk="logout-button">
                                            <v-icon color="white">mdi-format-list-bulleted</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('notification.list-view') }}</span>
                                </v-tooltip>
                            </v-row>
                        </v-row>
                    </v-row>

                    <v-row v-if="infoToggle == true">
                        <v-row class="pb-2" style="margin:0px;">

                        </v-row>
                    </v-row>
                </v-col>
            </div>
        </v-col>

        <!-- IMPORT CONTENT -->
        <vision-cards class="mt-0" v-if="cards" ref="VisionCards"/>
        <datatable-notifications class="mt-4" v-if="!cards"/>
    </div>
</template>

<script>
import DatatableNotifications from "../../components/Machine/VisionDatatable";
import VisionCards from "../../components/Machine/VisionCards";
import { MACHINES } from "../../../api";

export default {
    name: "Vision",
    components: {DatatableNotifications, VisionCards},
    data() {
        return {
            cards: true,
            id: this.$route.params.id,
            access_level: this.$store.state.access_level,
            infoToggle: false, // Machine menu package //
            data_filters: [
                {
                    level: 9,
                    level_name: "Tout voir",
                    level_color: "#d7d7d752",
                    count:this.global_notifications_count
                },
                {
                    level: 3,
                    level_name: "Urgence",
                    level_color: "black",
                    count:0
                },
                {
                    level: 2,
                    level_name: "Importance élevée",
                    level_color: "#F44336",
                    count:0
                },
                {
                    level: 1,
                    level_name: "A suivre",
                    level_color: "#FFCB05",
                    count:0
                },
                {
                    level: 0,
                    level_name: "Pour information",
                    level_color: "#4CAF50",
                    count:0
                },
                {
                    level: -1,
                    level_name: "Autres",
                    level_color: "grey",
                    count:0
                },                
            ],
        }
    },
    created() {
        this.setCards();
    },
    methods: {
        getMachines() {
            this.axios.get(REPORT + "/specific_machine/" + this.id)
            
            .then((success) => {
                if (this.checkDataAvailable(success.data)){
                    machineTitle = success.data.label
                }
            })
        },
        checkDataAvailable(datas){
            if(typeof datas[0] === 'undefined') {                    
                return false;
            }else {
                return true;
            }
        },
        switchView() {
            this.cards = !this.cards;
        },

        setCards() {
            this.cards = true;
        },

        setDatatable() {
            this.cards = false;
        },
        infoToggler(){
            this.infoToggle = !this.infoToggle;
        }
    }
}


</script>
