<template>
    <div id="add-user">
        <v-dialog v-model="dialog_add_user" max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="headline">{{ $t('account_management.add_user') }}</span>
                </v-card-title>

                <v-alert border="left" colored-border type="warning" elevation="2" class="mr-4 ml-4 mt-4" dense block>
                    {{ $t('referential.separate_mail_with') }}
                </v-alert>

                <validation-observer ref="add_observer" v-slot="{ invalid }">
                    <v-form ref="add_form">
                        <v-card-text>
                            <validation-provider :name="$t('account_management.email')"
                                                 rules="required" v-slot="{ errors }"
                            >
                                <v-textarea
                                    :label="$t('account_management.email')"
                                    v-model="add_users_email"
                                    prepend-icon="mdi-at" solo
                                    :error-messages="errors"
                                />
                            </validation-provider>

                            <validation-provider :name="$t('account_management.role')"
                                                 rules="required" v-slot="{ errors }"
                            >
                                <v-select
                                    :items="roles"
                                    v-model="add_user_role_id"
                                    :label="$t('account_management.role')"
                                    :prepend-icon="'mdi-alpha-r-circle-outline'"
                                    item-text="name" item-value="id"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                    :error-messages="errors" solo
                                ></v-select>
                            </validation-provider>
                        </v-card-text>

                        <v-card-actions class="justify-end">
                            <v-btn @click="closeAddDialog()">
                                <v-icon left>mdi-close</v-icon>
                                {{ $t('global.close') }}
                            </v-btn>

                            <v-btn type="submit" color="primary" :disabled="invalid"
                                   @click.prevent="sendInvitationMail()">
                                <v-icon left>mdi-content-save</v-icon>
                                {{ $t('global.validation') }}
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </validation-observer>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {ADMIN_USERS} from "../../../../api";

export default {
    name: 'AddUser',
    props: {
        roles: {
            type: Array,
            require: true,
        }
    },
    data() {
        return {
            dialog_add_user: false,
            add_users_email: '',
            add_user_role_id: null,
        }
    },
    methods: {
        sendInvitationMail() {
            this.axios.post(ADMIN_USERS + '/send_invitation', {
                add_users_email: this.add_users_email,
                sender_name: this.$auth.user().last_name + " " + this.$auth.user().first_name,
                user_role: this.add_user_role_id,
            }).then((success) => {
                this.manageResultSendInvitation(success);
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('error_display.error_send_invitations'));
            }).finally(() => {
                this.closeAddDialog();
            });
        },

        manageResultSendInvitation(success) {
            let snackbarType = 'success';
            let snackbarText = this.$t('account_management.invitation_send');

            if (success.data.number_invitation_send !== success.data.number_invitation) {
                snackbarType = 'warning';
                snackbarText = this.$t('add_new_client.incomplete_invitation', {
                    invitation_receive: success.data.number_invitation_send,
                    invitation_send: success.data.number_invitation
                });
            }

            this.showGlobalSnackbar( snackbarType, snackbarText);
            this.$emit('getUsers');
            this.$emit('getInvitations');
        },

        closeAddDialog() {
            this.dialog_add_user = false;
            this.$refs.add_observer.reset();
            this.$refs.add_form.reset();
        },
    }
}
</script>
