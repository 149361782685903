<template>
    <div id="modal-add-comparison-curve">
        <validation-observer ref="observer" v-slot="{ invalid }" class="mb-3">
            <v-form ref="form" class="mt-1">

                <validation-provider :name="$t('global.devices')" rules="required"
                                     v-slot="{ errors }">
                    <v-select
                        :items="virtual_devices"
                        v-model="edited_comparison_trace.virtual_device_id"
                        :label="$t('global.devices')"
                        :error-messages="errors"
                        :prepend-icon="'mdi-chemical-weapon'"
                        item-text="label"
                        item-value="id"
                        :menu-props="{ auto:true,overflowY: true }"
                        @change="getMetricsOfVirtualDevice()"
                        solo
                    />
                </validation-provider>

                <validation-provider :name="$t('modal_comparison_curves.physical_value')" rules="required"
                                     v-slot="{ errors }">
                    <v-select
                        :items="virtual_device_metric_1_types"
                        v-model="metric_name_1"
                        :label="$t('modal_comparison_curves.physical_value')"
                        :error-messages="errors"
                        :prepend-icon="'mdi-tape-measure'"
                        item-text="metric_name"
                        item-value="metric_uuid"
                        :disabled="disabled_virtual_device_metric_1_types"
                        :loading="loading_virtual_device_metric_1_types"
                        solo
                    />
                </validation-provider>
            </v-form>
        </validation-observer>
        <v-row class="justify-end mt-3">
                <v-btn @click="deleteComparisonCurve()"
                       class="mb-2"
                       dark
                       small
                       color="red"
                >
                    <v-icon dark size="20">mdi-trash-can-outline</v-icon>
                </v-btn>
        </v-row>
        <global-snackbar/>
    </div>
</template>

<script>
import {MACHINES, VIRTUAL_DEVICES} from "../../../../api";
import GlobalSnackbar from "../../../../global/components/GlobalSnackbar";

export default {
    name: "ModalAddComparisonCurves",
    components: {GlobalSnackbar},
    props: ["parentType","traceIndex"],
    data() {
        return {
            virtual_devices: [],
            virtual_device_metric_1_types: [],
            invalid: false,

            metric_name_1: '',
            disabled_virtual_device_metric_1_types: true,
            loading_virtual_device_metric_1_types: false,
            edited_comparison_trace: {
                metric_uuid: null,
                metric_unit: null,
                metric_name: null,
                virtual_device_id: null,
            }
        }
    },
    watch: {
        metric_name_1: function (val) {
            if (val !== null) {
                this.edited_comparison_trace.metric_uuid = val;

                let metric = this.virtual_device_metric_1_types.filter(function (metric) {
                    if (metric.metric_uuid === val) return metric;
                })[0];

                this.edited_comparison_trace.metric_unit = metric.metric_unit;
                this.edited_comparison_trace.metric_name = metric.metric_name;

            }
            this.$emit('setTraceDetail',this.edited_comparison_trace,this.traceIndex);
        },
    },
    created() {
        this.getMachineVirtualDevices();
    },
    methods: {
        getMachineVirtualDevices() {
            this.axios.get(
                MACHINES + "/" + this.$route.params.id + "/virtual_devices"
            ).then((success) => {
                this.virtual_devices = success.data;
            }).catch((error) => {
                this.manageError(error.response.status,this.$t('global.error'));
            });
        },
        getMetricsOfVirtualDevice() {
            this.loading_virtual_device_metric_1_types = true;

            this.axios.get(
                VIRTUAL_DEVICES + "/" + this.edited_comparison_trace.virtual_device_id + "/metric_types"
            ).then((success) => {
                this.virtual_device_metric_1_types=[];
                success.data.forEach((metric) => {
                    if (metric.metric_type !== "FFT" && metric.metric_unit !== "fft") {
                        this.virtual_device_metric_1_types.push(metric);
                    }
                });
                this.disabled_virtual_device_metric_1_types = false;
            }).catch((error) => {
                this.manageError(error.response.status,this.$t('error_display.error_extern_api_connection'));
            }).finally(() => {
                this.loading_virtual_device_metric_1_types = false;
            });
            /*this.axios(
                {
                    baseURL:'https://836d7f1a-2138-4bc2-968c-5441475b7ed8.mock.pstmn.io/',
                    url:'metrics',
                    method:'GET',
                    responseType: 'json'
                }
            ).then((success)=>{
                this.virtual_device_metric_1_types = success.data;
                this.disabled_virtual_device_metric_1_types = false;
            }).finally(()=>{
                this.loading_virtual_device_metric_1_types = false;
            });*/
        },
        deleteComparisonCurve(){
            this.$emit('deleteCurveForm',this.traceIndex);
            //this.$destroy();
            //this.$el.parentNode.removeChild(this.$el);
        },

    }
}
</script>
