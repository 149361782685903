<template>
    <div id="subassembly-vision">
        <v-row>
            <v-col style="background-color: white">
                <v-subheader>
                    <p class="title mb-0">
                        {{ $t('subassemblies.title') }}
                    </p>
                </v-subheader>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        <v-spacer/>
                    </v-card-title>

                    <v-data-table
                        :headers="headers"
                        :items="subassemblies"
                        sort-by="id"
                        class="elevation-1"
                        :loading="loading">

                        <template v-slot:top>
                            <v-toolbar flat color="white">

                                <v-layout class="justify-start">
                                    <v-btn color="primary" class="mb-2 white--text" @click="dialog_edit = true">
                                        {{ $t('subassemblies.add_subassembly') }}
                                    </v-btn>
                                </v-layout>

                                <v-dialog v-model="dialog_edit" max-width="700px" persistent>
                                    <v-card>
                                        <validation-observer ref="observer" v-slot="{ invalid }">
                                            <v-form ref="formBindVirtualDevice">
                                                <v-card-title>
                                            <span class="headline">
                                                {{ $t('subassemblies.add_subassembly') }}
                                            </span>
                                                </v-card-title>
                                                <v-divider></v-divider>

                                                <v-card-text>
                                                    <validation-provider
                                                        :name="$t('subassemblies.choose_subassembly_configuration')"
                                                        rules="required"
                                                        v-slot="{ errors }"
                                                    >
                                                        <v-select :items="subassembly_configurations"
                                                                  v-model="subassembly_configuration"
                                                                  :label="$t('subassemblies.choose_subassembly_configuration')"
                                                                  :prepend-icon="'mdi-code-json'"
                                                                  :error-messages="errors"
                                                                  return-object
                                                                  @change="parseSubassemblyConfigurationChoose()"
                                                                  item-text="label"
                                                                  item-value="configuration" solo
                                                        >
                                                        </v-select>
                                                    </validation-provider>

                                                    <validation-provider :name="$t('global.label')" rules="required"
                                                                         v-slot="{ errors }">
                                                        <v-text-field solo
                                                                      :label="$t('global.name')"
                                                                      v-model="edited_subassembly.label"
                                                                      :prepend-icon="'mdi-label-outline'"
                                                                      :error-messages="errors"
                                                        />
                                                    </validation-provider>

                                                    <v-file-input
                                                        v-if="edited_index === null"
                                                        v-model="edited_subassembly.path_image"
                                                        :label="$t('global.image')"
                                                        solo
                                                        @change="onImageChanged"
                                                    ></v-file-input>

                                                    <validation-provider
                                                        :name="$t('subassembly_specifications.machine')"
                                                        rules="required"
                                                        v-slot="{ errors }"
                                                    >
                                                        <v-select :items="machines"
                                                                  v-model="edited_subassembly.machine_id"
                                                                  :label="$t('subassembly_specifications.machine')"
                                                                  :prepend-icon="'mdi-state-machine'"
                                                                  :error-messages="errors"
                                                                  item-value="id" solo
                                                        >
                                                            <template slot="selection" slot-scope="data">
                                                                {{ data.item.label }}
                                                            </template>
                                                            <template slot="item" slot-scope="data">
                                                                {{ data.item.label }}
                                                            </template>
                                                        </v-select>
                                                    </validation-provider>

                                                    <v-row class="mt-1 mb-1">
                                                        <template v-for="configuration in subassembly_configuration_choose">
                                                            <v-select
                                                                v-model="configuration.value"
                                                                style = "width : 240px"
                                                                v-if="configuration.type === 'selector'"
                                                                class="mr-3 ml-3"
                                                                :label="configuration.title" solo
                                                                :prepend-icon="configuration.icon ? configuration.icon : 'mdi-comment-outline'"
                                                                :items="configuration.choice"
                                                                item-text ="text"
                                                                item-value ="id"
                                                            />

                                                            <v-text-field class="ml-3 mr-3"
                                                                          v-else
                                                                          v-show="!configuration.hidden"
                                                                          :key="configuration.index"
                                                                          :label="configuration.title" solo
                                                                          v-model="configuration.value"
                                                                          :type="configuration.type === 'integer' ? 'number' : ''"
                                                                          :rules="configuration.required ? [v => !!v || $t('vee-validation.required_field')] : []"
                                                                          :prepend-icon="configuration.icon ? configuration.icon : 'mdi-comment-outline'"
                                                            />
                                                        </template>
                                                    </v-row>

                                                    <v-textarea rows="2" solo no-resize
                                                                v-model="edited_subassembly.note"
                                                                :label="$t('global.notes')"
                                                                :prepend-icon="'mdi-comment-outline'"
                                                    />
                                                </v-card-text>

                                                <v-card-actions>
                                                    <v-spacer/>
                                                    <v-btn @click="closeDialog()">
                                                        <v-icon left>mdi-close</v-icon>
                                                        {{ $t('global.close') }}
                                                    </v-btn>
                                                    <v-btn color="primary" :disabled="invalid"
                                                           @click="prepareEditSubassembly()">
                                                        <v-icon left>mdi-content-save</v-icon>
                                                        {{ $t('global.validation') }}
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-form>
                                        </validation-observer>
                                    </v-card>
                                </v-dialog>

                                <v-dialog v-model="dialog_delete" max-width="500px">
                                    <v-card>
                                        <v-card-title>
                                            <span class="headline">
                                                {{ $t('subassemblies.delete_subassembly') }}
                                            </span>
                                        </v-card-title>
                                        <v-divider></v-divider>
                                        <br/>

                                        <v-card-actions>
                                            <v-spacer/>
                                            <v-btn @click="closeDialog()">
                                                <v-icon left>mdi-close</v-icon>
                                                {{ $t('global.cancel') }}
                                            </v-btn>
                                            <v-btn color="error" @click="confirmDelete()">
                                                <v-icon left>mdi-delete</v-icon>
                                                {{ $t('global.delete') }}
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>

                                <v-dialog v-model="dialog_bind_virtual_devices" max-width="700px" persistent>
                                    <v-card>
                                        <v-form ref="formBindVirtualDevice">
                                            <v-card-title>
                                            <span class="headline">
                                                {{ $t('subassemblies.manage_binding_virtual_devices') }}
                                            </span>
                                            </v-card-title>
                                            <v-divider></v-divider>

                                            <v-card-text>
                                                <v-select
                                                    :items="virtual_devices"
                                                    v-model="virtual_devices_bind_to_subassembly"
                                                    :label="$t('subassemblies.virtual_device_to_bind')"
                                                    :prepend-inner-icon="'mdi-tag-multiple-outline'"
                                                    item-text="label"
                                                    item-value="id"
                                                    solo chips multiple
                                                >
                                                </v-select>
                                            </v-card-text>

                                            <v-card-actions>
                                                <v-spacer/>
                                                <v-btn @click="closeDialog()">
                                                    <v-icon left>mdi-close</v-icon>
                                                    {{ $t('global.close') }}
                                                </v-btn>
                                                <v-btn color="primary"
                                                       @click="updateBindingVirtualDevice()">
                                                    <v-icon left>mdi-content-save</v-icon>
                                                    {{ $t('global.validation') }}
                                                </v-btn>
                                            </v-card-actions>
                                        </v-form>
                                    </v-card>
                                </v-dialog>
                            </v-toolbar>
                        </template>

                        <template v-slot:item.label="{ item }">
                            <router-link :to="{ path: '/subassembly/' + item.id}">
                                {{ item.label }}
                            </router-link>
                        </template>

                        <template v-slot:item.machine="{ item }">
                            <router-link :to="{ path: '/machine/' + item.machine.id}">
                                {{ item.machine.label }}
                            </router-link>
                        </template>

                        <template v-slot:item.virtual_devices="{ item }">
                            <span v-if="item.virtual_devices.length === 0">Aucun device associé</span>
                            <v-chip-group v-else>
                                <v-chip v-for="device in item.virtual_devices" :key="device.id"
                                        :color="assetColor(device)"
                                        @click="$store.state.access_level !== 300 && $store.state.access_level !== 400 ? $router.push({ name: 'device', params: {id: device.id} }) : ''"
                                        text-color="white"
                                >
                                    {{ device.label }}
                                </v-chip>
                            </v-chip-group>
                        </template>

                        <template v-slot:item.action="{ item }">
                            <v-icon v-if="$store.state.access_level !== 300 && $store.state.access_level !== 400" @click="openDialogBindVirtualDevice(item)">mdi-link-box-variant-outline</v-icon>
                            <v-icon v-if="item.virtual_devices.length === 0" class="pointer"
                                    @click="openDialogDeleteSubassembly(item)">
                                mdi-delete
                            </v-icon>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
        <v-snackbar
            v-model="snackbar"
            :color="snackbar_type">
            {{ snackbar_text }}
            <template v-slot:action="{ attrs }">
                <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import {SUBASSEMBLIES, SUBASSEMBLY_CONFIGURATIONS, VIRTUAL_DEVICES, MACHINES} from "../../../api";
import DocumentsUpload from "../../components/DocumentsUpload";
import moment from "moment";

export default {
    name: "SubassemblyVision",
    components: {DocumentsUpload},
    data() {
        return {
            headers: [
                {text: this.$t('global.name'), value: 'label'},
                {text: this.$t('subassemblies.subassembly_type'), value: 'subassembly_type'},
                {text: this.$t('subassemblies.machine'), value: 'machine'},
                {text: this.$t('subassemblies.devices'), value: 'virtual_devices'},
                {text: this.$t('global.notes'), value: 'note'},
                {text: this.$t('global.actions'), value: 'action'},
            ],
            subassemblies: [],
            virtual_devices: [],
            machines: [],
            virtual_devices_bind_to_subassembly: [],
            loading: true,
            invalid: true,

            subassembly_configurations: [],
            subassembly_configuration: null,
            subassembly_configuration_choose: null,
            dialog_edit: false,
            edited_index: null,
            edited_subassembly: {
                id: null,
                label: '',
                subassembly_type: '',
                config: null,
                note: '',
                machine_id: null,
                path_image: null
            },
            loading_bind_virtual_devices: false,
            dialog_bind_virtual_devices: false,
            subassembly_id: null,

            dialog_delete: false,
            delete_id: null,
            deleted_index: null,

            timeProps: {
                format: "24hr"
            },
            textFieldProps: {
                prependInnerIcon: "mdi-calendar",
                solo: true,
                rules: [v => !!v || this.$t('vee-validation.required_date_operation')]
            },
            valid_image: true,
        }
    },
    created() {
        this.initialize();
    },
    methods: {
        initialize() {
            this.getSubassemblies();
            this.getSubassemblyConfiguration();
            this.getMachines();
        },

        getSubassemblies() {
            this.axios.get(
                SUBASSEMBLIES
            ).then((success) => {
                this.subassemblies = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'))
            }).finally(() => {
                this.loading = false
            });
        },

        getMachines() {
            this.axios.get(
                MACHINES
            ).then((success) => {
                this.machines = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'))
            });
        },

        getSubassemblyConfiguration() {
            this.axios.get(SUBASSEMBLY_CONFIGURATIONS).then((success) => {
                this.subassembly_configurations = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            });
        },

        parseSubassemblyConfigurationChoose() {
            this.edited_subassembly.subassembly_type = this.subassembly_configuration.label;
            this.subassembly_configuration_choose = JSON.parse(this.subassembly_configuration.configuration);
        },

        prepareEditSubassembly() {
            if (this.edited_index !== null) {
                //this.updateSubassembly();
            } else {
                this.addSubassembly();
            }

            this.closeDialog();
        },

        addSubassembly() {
                this.edited_subassembly.config = this.subassembly_configuration_choose;
                let formData = new FormData()
                formData.append('label', this.edited_subassembly.label);
                formData.append('subassembly_type', this.edited_subassembly.subassembly_type);
                formData.append('config', JSON.stringify(this.edited_subassembly.config));
                formData.append('note', this.edited_subassembly.note);
                formData.append('machine_id', this.edited_subassembly.machine_id);
                formData.append('path_image', this.edited_subassembly.path_image);

                this.axios.post(
                    SUBASSEMBLIES, formData, this.configurationFile
                ).then((success) => {
                    this.subassemblies.push(success.data);
                    this.showSnackbar('success', this.$t('global.success_to_add'));
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_add'));
                }).finally(() => {
                    this.resetFormEditSubassembly();
                });
        },

        openDialogDeleteSubassembly(subassembly) {
            this.deleted_index = this.subassemblies.indexOf(subassembly);
            this.delete_id = subassembly.id;
            this.dialog_delete = true;
        },

        confirmDelete() {
            this.axios.delete(
                SUBASSEMBLIES + '/' + this.delete_id
            ).then(() => {
                this.subassemblies.splice(this.deleted_index, 1);
                this.showSnackbar('success', this.$t('global.success_to_delete'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_delete'));
            }).finally(() => {
                this.closeDialog();
            });
        },

        openDialogBindVirtualDevice(subassembly) {
            this.loading_bind_virtual_devices = true;
            this.subassembly_id = subassembly.id;

            this.axios.get(
                VIRTUAL_DEVICES + '/not_bind/subassembly/' + this.subassembly_id
            ).then((success) => {
                this.virtual_devices = success.data;
                this.dialog_bind_virtual_devices = true;

                this.virtual_devices.forEach(virtual_device => {
                    if (virtual_device.subassembly_id !== null) this.virtual_devices_bind_to_subassembly.push(virtual_device);
                });
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'))
            }).finally(() => {
                this.loading_bind_virtual_devices = false
            });
        },

        updateBindingVirtualDevice() {
            this.axios.put(VIRTUAL_DEVICES + '/bind/subassembly/' + this.subassembly_id,
                {
                    virtual_devices_id: this.virtual_devices_bind_to_subassembly
                }
            ).then(() => {
                this.showSnackbar('success', this.$t('global.success_to_edit'))
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'))
            }).finally(() => {
                this.virtual_devices = [];
                this.virtual_devices_bind_to_subassembly = [];
                this.getSubassemblies();
                this.closeDialog();
            });
        },

        closeDialog() {
            this.dialog_edit = false;
            this.dialog_delete = false;
            this.dialog_bind_virtual_devices = false;
        },

        resetFormEditSubassembly() {
            this.$refs.formBindVirtualDevice.reset();
            this.$refs.observer.reset();
            this.subassembly_configuration = null;
            this.subassembly_configuration_choose = null;
        },

        onImageChanged() {
            if(this.edited_subassembly.path_image !== null) {
                let imageCheck = this.uploadFilesCheck(this.edited_subassembly.path_image,['image/png','image/jpg','image/jpeg'],2);
                if(!imageCheck.authorized) {
                    this.valid_image = false;
                    this.showSnackbar('error',imageCheck.output.toString());
                }
                else {
                    this.valid_image = true;
                }
            }
            if(this.edited_subassembly.path_image === null){
                this.valid_image = true;
            }
        },
    }
}
</script>
