<template>
    <div id="password-edit">
        <v-dialog v-model="dialog_edit_password" max-width="500">
            <v-card>
                <v-card-title>{{ $t('profile.reset_password') }}</v-card-title>

                <v-divider/>

                <validation-observer ref="observer" v-slot="{ invalid }">
                    <v-form ref="form">
                        <v-card-text class="pb-0">
                            <validation-provider :name="$t('profile.actual_password')" rules="required"
                                                 v-slot="{ errors }"
                            >
                                <v-text-field v-model="actual_password"
                                              :append-icon="show_actual_password ? 'mdi-eye' : 'mdi-eye-off'"
                                              :label="$t('profile.actual_password')"
                                              :type="show_actual_password ? 'text' : 'password'"
                                              @click:append="show_actual_password = !show_actual_password"
                                              :error-messages="errors" filled
                                />
                            </validation-provider>

                            <validation-provider :name="$t('profile.new_password')" rules="required|min:7"
                                                 vid="new_pass"
                                                 v-slot="{ errors }"
                            >
                                <v-text-field v-model="new_password"
                                              :append-icon="show_new_password ? 'mdi-eye' : 'mdi-eye-off'"
                                              :label="$t('profile.new_password')"
                                              :type="show_new_password ? 'text' : 'password'"
                                              @click:append="show_new_password = !show_new_password"
                                              :error-messages="errors" filled
                                />
                            </validation-provider>

                            <validation-provider :name="$t('profile.confirm_new_password')"
                                                 rules="required|min:7|confirmed:new_pass" v-slot="{ errors }"
                            >
                                <v-text-field v-model="new_password_confirmation"
                                              :append-icon="show_new_password_confirmation ? 'mdi-eye' : 'mdi-eye-off'"
                                              :label="$t('profile.confirm_new_password')"
                                              :type="show_new_password_confirmation ? 'text' : 'password'"
                                              @click:append="show_new_password_confirmation = !show_new_password_confirmation"
                                              :error-messages="errors" filled
                                />
                            </validation-provider>
                        </v-card-text>

                        <v-card-actions class="pr-4 justify-end">
                            <v-btn plain @click="dialog_edit_password = false">
                                {{ $t('global.cancel') }}
                            </v-btn>
                            <v-btn :loading="loading_update_password" :disabled="invalid" @click="updateUserPassword()"
                                   color="primary"
                            >
                                {{ $t('global.validation') }}
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </validation-observer>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {USERS} from "../../api";

export default {
    name: 'PasswordEditComponent',
    data() {
        return {
            invalid: false,
            dialog_edit_password: false,
            loading_update_password: false,

            actual_password: '',
            show_actual_password: false,
            new_password: '',
            show_new_password: false,
            new_password_confirmation: '',
            show_new_password_confirmation: false,
        }
    },
    methods: {
        validate() {

            this.updateUserPassword();
        },

        updateUserPassword() {
            if (this.$refs.form.validate()) {
                this.loading_update_password = true;

                this.axios.put(USERS + '/password', {
                    'actual_password': this.actual_password,
                    'new_password': this.new_password,
                    'new_password_confirmation': this.new_password_confirmation
                }).then(() => {
                    this.showGlobalSnackbar('success', this.$t('profile.password_update'));
                    this.dialog_edit_password = false;
                    this.$refs.form.reset();
                    this.$refs.observer.reset();
                }).catch((error) => {
                    let errorMessage = this.$t('profile.error_update_password');
                    if (error.response.status === 409) {
                        errorMessage = this.$t('profile.wrong_password');
                    }
                    this.manageError(error.response.status, errorMessage);
                }).finally(() => {
                    this.loading_update_password = false;
                });
            }
        }
    }
}
</script>
