<template>
    <v-col class="col col-12 mt-0 pt-0 pa-0" style="margin-top:0px!important;padding:0px;position:relative; top:-12px;">  
        <!-- MACHINE MENU PACKAGE -->
        <div style="padding:0px 12px;">
            <v-col class="pl-5 pr-5" style="background-color:#001047;color:#FFFFFF;border-radius: 0px 0px 5px 5px;">
                <!-- TOP TITLE -->
                <v-row class="pl-0 pt-5 mb-5" style="height: 52px; padding:5px;">
                    <!-- PAGE TITLE -->
                    <v-subheader class="v-subheader col theme--light">
                        <!------------------- Info bulle ---------------------->
                        <v-tooltip bottom dark class="mr-2 ml-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 mr-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                                    <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                                </v-btn>
                            </template>
                            <template v-slot:default>
                            <div style="min-width: 200px;position:relative;z-index: 10;">
                                <strong>Page [Suivi des campagnes]</strong>
                                <p>Cette page à été spécialement développé pour les experts de Techview.</br>
                                    vous retrouverez dans cette pages toutes les campagnes en cours et cloturées de chaque expert.</br>
                                    Vous pourrez aussi les voir sur un planning afin de voir la charge de travail de chaque expert.
                                </p>
                            </div>
                            </template>
                        </v-tooltip>
                        <p class="title mb-0 color-white" style="color: white!important;">
                            <div style="color:#FFFFFFb3;margin-right:4px;">Interventions > </div><div @click="infoToggler()" style="color:#FFFFFF;font-weight:bold;">Suivi des campagnes</div>
                        </p>
                    </v-subheader>

                    <!-- GLOBAL ACTIONS -->
                    <v-col class="row mt-1 col-auto pa-0 pr-5">
                        <v-tooltip v-if="access_level <= 100" bottom dark class="mr-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="openDialogAddCampain()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                    <v-icon color="white">mdi-plus</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('campain.add_campain') }}</span>
                        </v-tooltip>
                        <v-tooltip bottom dark class="mr-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="getData()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                    <v-icon color="white">mdi-tag-multiple</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('global.1_5_2') }}</span>
                        </v-tooltip>
                        <v-tooltip bottom dark class="mr-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="getData()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                    <v-icon color="white">mdi-refresh</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('campain.refresh') }}</span>
                        </v-tooltip>
                        <!--
                        <v-tooltip v-if="infoToggle == true" bottom dark class="mr-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="infoToggler()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                    <v-icon color="white">mdi-minus-box-multiple</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('machines.refresh') }}</span>
                        </v-tooltip>

                        <v-tooltip v-if="infoToggle == false" bottom dark class="mr-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="infoToggler()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                    <v-icon color="white">mdi-plus-box-multiple</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('machines.refresh') }}</span>
                        </v-tooltip>
                        -->
                    </v-col>
                </v-row>

                <!-- MACHINE INFOS (UNUSED) -->
                <!--
                <v-row>
                    <v-row class="pb-2" style="margin:0px;">
                        <v-row class="ml-3 pt-4 pb-0 rounded" style="">
                            <div v-for="filters in data_filters2" :key="filters.level" class="mr-0" style="height:40px; padding: 4px 0px;">
                                <div
                                class="ma-0 v-chip v-chip--clickable v-chip--link v-chip--no-color theme--light v-size--default"
                                style="
                                    color: white;
                                    font-weight: 400;
                                    display: flex;
                                    justify-content: left;
                                    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.29);
                                    background-color: #ffffff8c;
                                    font-size: 14px;
                                    height: 32px;
                                    margin-right: 12px !important;
                                ">
                                    <div class="mr-3" style="color: white;min-width: 30px; min-height: 20px; border-radius:20px;font-weight: bold;text-align: center;" :style="{backgroundColor: filters.level_color}">
                                        {{filters.count}}
                                    </div>
                                    {{filters.level_name}}
                                </div>
                            </div>
                        </v-row>     
                    </v-row>
                </v-row>
            -->
            </v-col>
        </div>

        <v-row>
            <v-col class="pt-0 pa-0 ml-6 mt-8" style="margin:0px; max-width:40px;">
                <v-col class="col ml-0 mt-0 pa-0" style="position: relative;left:-1px; width:40px">
                    <div v-for="vueLeft in vuesCalendar" :key="vueLeft.id" class="mb-1">
                        <div v-if="vueLeft.id == calendar_active_special_vue" 
                            class="ma-auto pl-2 pr-2 pa-2"
                            style="color: primary; font-weight: 400; display: flex; justify-content: left; text-shadow: rgba(0, 0, 0, 0.29) 2px 2px 4px; background-color: rgb(255 255 255); border-radius: 5px 0px 0px 5px; border-left: 1px solid rgba(211, 211, 211, 0.43); border-right: none; font-size: 14px; height: 64px; align-items: center; font-weight:bold;"
                        >
                            <v-icon>{{ vueLeft.label }}</v-icon>
                            <!--
                            <div v-if="calendar_active_special_vue == 0 " style="margin-right: 8px;">
                                <v-icon>mdi-format-list-bulleted-square</v-icon>
                            </div>
                            <div v-if="calendar_active_special_vue == 1 " style="margin-right: 8px;">
                                <v-icon>mdi-calendar-multiselect</v-icon>
                            </div>
                            -->
                        </div>
                        <div v-else 
                            class="ma-auto pl-2 pr-2 pa-2"
                            style="color: black; font-weight: 400; display: flex; justify-content: left; text-shadow: rgba(0, 0, 0, 0.29) 2px 2px 4px; background-color: rgb(243 243 243 / 90%); border-radius: 10px 0px 0px 10px; border: 1px solid #dbdce1; border-right: none; font-size: 14px; height: 64px; cursor: pointer;"
                            @click="loadVueCalendar(vueLeft.id)"
                        >
                            <v-icon>{{ vueLeft.label }}</v-icon>
                        <!--
                            
                            <div v-if="calendar_active_special_vue == 0 " style="margin-right: 8px;">
                                <v-icon>mdi-format-list-bulleted-square</v-icon>
                            </div>
                            <div v-if="calendar_active_special_vue == 1 " style="margin-right: 8px;">
                                <v-icon>mdi-calendar-multiselect</v-icon>
                            </div>
                        -->
                        </div>
                    </div>
                </v-col>
            </v-col>

            <!-- content -->
            <v-col color="white" class="mr-6 pl-0 mt-8 col">
                <v-row class="ml-0" 
                     style="background-color: white;
                            height: 150px;
                            border-radius: 0px 5px 0px 0px;"
                >

                </v-row>
                <v-row class="ml-0 pl-8 pr-2" 
                    style="background-color: #ffffff;
                        height: 90px;
                        border-top: 1px solid #80808038;
                        border-radius: 0px 0px 5px 5px;
                        overflow: hidden;"
                >
                    <!-- Sélection expert -->
                    <v-col class="pl-0 col">
                        <h3 style="margin-bottom:5px;">Filtrer par chargé de campagne</h3>
                        <v-select
                            v-model="selectedExperts"
                            :items="analysts_datas"
                            item-text="name"
                            item-value="id"
                            multiple
                            chips
                            clearable
                            outlined
                            dense
                        ></v-select>
                    </v-col>
                    
                    <!-- Sélection expert -->
                    <v-col class="pl-0 col">
                        <h3 style="margin-bottom:5px;">Filtrer par secteur</h3>
                        <v-select
                            v-model="selectedExperts"
                            :items="analysts_datas"
                            item-text="name"
                            item-value="id"
                            multiple
                            chips
                            clearable
                            outlined
                            dense
                        ></v-select>
                    </v-col>

                    <!-- Sélection step -->
                    <v-col class="pl-0 col">
                        <h3 style="margin-bottom:5px;">Filtrer par étapes</h3>
                        <v-select
                            v-model="selectedFilters"
                            :items="filterOptions"
                            item-text="text"
                            item-value="value"
                            multiple
                            chips
                            clearable
                            outlined
                            dense
                        ></v-select>
                    </v-col>
                </v-row>
                

                <!-- Liste -->
                <div v-if="calendar_active_special_vue == 0" class="pt-7">
                    <!-- Différents onglets -->
                    <v-col class="col pa-0 ma-0" style="max-width: 100%;">
                        <!-- Vue -->
                        <v-row class="pt-0" style="margin:0px;height:40px;">
                            <v-col class="row ml-0 mt-0 pa-0" style="position: relative;left:-1px;">
                                <!-- Onglets normaux -->
                                <div v-for="vue in vues" :key="vue.id" class="mr-1">
                                    <div 
                                        v-if="vue.id == active_special_vue" 
                                        class="ma-auto pl-5 pr-5 pa-2"
                                        style="color: black; font-weight: 400; display: flex; justify-content: left; text-shadow: rgba(0, 0, 0, 0.29) 2px 2px 4px; background-color: rgb(255 255 255); border-radius: 5px 5px 0px 0px; border-left: 1px solid rgba(211, 211, 211, 0.43); border-bottom: none; font-size: 14px; height: 40px; align-items: center; font-weight:bold;"
                                    >
                                        <div v-if="active_special_vue == 0 " style="margin-right: 8px;">
                                            <span v-if="campains_vibratoire_nb > 0" style="font-weight: 800;color: white;background-color: #001047;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_vibratoire_nb }}</span>
                                            <span v-if="campains_vibratoire_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_vibratoire_nb }}</span>
                                        </div>
                                        <div v-if="active_special_vue == 5 " style="margin-right: 8px;">
                                            <span v-if="campains_online_nb > 0" style="font-weight: 800;color: white;background-color: #001047;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_online_nb }}</span>
                                            <span v-if="campains_online_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_online_nb }}</span>
                                        </div>
                                        <div v-if="active_special_vue == 1 " style="margin-right: 8px;">
                                            <span v-if="campains_thermographie_nb > 0" style="font-weight: 800;color: white;background-color: #001047;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_thermographie_nb }}</span>
                                            <span v-if="campains_thermographie_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_thermographie_nb }}</span>
                                        </div>
                                        <div v-if="active_special_vue == 2 " style="margin-right: 8px;">
                                            <span v-if="campains_ultrasons_nb > 0" style="font-weight: 800;color: white;background-color: #001047;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_ultrasons_nb }}</span>
                                            <span v-if="campains_ultrasons_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_ultrasons_nb }}</span>
                                        </div>
                                        <div v-if="active_special_vue == 3 " style="margin-right: 8px;">
                                            <span v-if="campains_equilibrage_nb > 0" style="font-weight: 800;color: white;background-color: #001047;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_equilibrage_nb }}</span>
                                            <span v-if="campains_equilibrage_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_equilibrage_nb }}</span>
                                        </div>
                                        <div v-if="active_special_vue == 4 " style="margin-right: 8px;">
                                            <span v-if="campains_expertises_nb > 0" style="font-weight: 800;color: white;background-color: #001047;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_expertises_nb }}</span>
                                            <span v-if="campains_expertises_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_expertises_nb }}</span>
                                        </div>
                                        {{ vue.label }}
                                    </div>
                                    <div 
                                        v-else 
                                        class="ma-auto pl-5 pr-5 pa-2"
                                        style="color: black; font-weight: 400; display: flex; justify-content: left; text-shadow: rgba(0, 0, 0, 0.29) 2px 2px 4px; background-color: rgb(243 243 243 / 90%); border-radius: 10px 10px 0px 0px; border: 1px solid #dbdce1; border-bottom: none; font-size: 14px; height: 40px; cursor: pointer;"
                                        @click="loadVue(vue.id)"
                                    >
                                        <div v-if="vue.id == 0" style="margin-right: 8px;">
                                            <span v-if="campains_vibratoire_nb > 0" style="font-weight: 800;color: white;background-color: #939393;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_vibratoire_nb }}</span>
                                            <span v-if="campains_vibratoire_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_vibratoire_nb }}</span>
                                        </div>
                                        <div v-if="vue.id == 5" style="margin-right: 8px;">
                                            <span v-if="campains_online_nb > 0" style="font-weight: 800;color: white;background-color: #939393;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_online_nb }}</span>
                                            <span v-if="campains_online_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_online_nb }}</span>
                                        </div>
                                        <div v-if="vue.id == 1" style="margin-right: 8px;">
                                            <span v-if="campains_thermographie_nb > 0" style="font-weight: 800;color: white;background-color: #939393;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_thermographie_nb }}</span>
                                            <span v-if="campains_thermographie_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_thermographie_nb }}</span>
                                        </div>
                                        <div v-if="vue.id == 2" style="margin-right: 8px;">
                                            <span v-if="campains_ultrasons_nb > 0" style="font-weight: 800;color: white;background-color: #939393;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_ultrasons_nb }}</span>
                                            <span v-if="campains_ultrasons_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_ultrasons_nb }}</span>
                                        </div>
                                        <div v-if="vue.id == 3" style="margin-right: 8px;">
                                            <span v-if="campains_equilibrage_nb > 0" style="font-weight: 800;color: white;background-color: #939393;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_equilibrage_nb }}</span>
                                            <span v-if="campains_equilibrage_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_equilibrage_nb }}</span>
                                        </div>
                                        <div v-if="vue.id == 4" style="margin-right: 8px;">
                                            <span v-if="campains_expertises_nb > 0" style="font-weight: 800;color: white;background-color: #939393;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_expertises_nb }}</span>
                                            <span v-if="campains_expertises_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_expertises_nb }}</span>
                                        </div>
                                        {{ vue.label }}
                                    </div>
                                </div>

                                <!-- Onglet admin -->
                                <div v-if="access_level == 0" class="ml-auto" style="margin-left: auto; display: flex;">
                                    <div v-if="active_special_vue == 99" 
                                        class="ma-0 pl-5 pr-5 pa-2"
                                        style="color: black; font-weight: 400; display: flex; justify-content: left; text-shadow: rgba(0, 0, 0, 0.29) 2px 2px 4px; background-color: rgb(255 255 255); border-radius: 5px 5px 0px 0px; border-left: 1px solid rgba(211, 211, 211, 0.43); border-bottom: none; font-size: 14px; height: 40px; align-items: center; font-weight:bold;"
                                    >
                                        <v-icon color="red" style="height:36px;font-size:30px;">
                                            mdi-delete-outline
                                        </v-icon>
                                    </div>
                                    <div v-else 
                                        class="ma-0 pl-5 pr-5 pa-1"
                                        style="color: black; font-weight: 400; display: flex; justify-content: left; text-shadow: rgba(0, 0, 0, 0.29) 2px 2px 4px; background-color: rgb(243 243 243 / 90%); border-radius: 10px 10px 0px 0px; border: 1px solid #dbdce1; border-bottom: none; font-size: 14px; height: 40px; cursor: pointer;"
                                        @click="loadVue(99)"
                                    >
                                        <v-icon color="red" style="height:36px;font-size:30px;">
                                            mdi-delete-outline
                                        </v-icon>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>

                    <!-- DATA TABLE if view details -->
                    <v-data-table
                        :headers="headers"
                        :items="campains_displayed"
                        class="elevation-1 datatable-operation"
                        style="border-radius: 5px; overflow: hidden;"
                        :items-per-page="-1"
                        :loading="loading_datatable"
                        :search="search"
                        :sort-by="['id']"
                        :sort-desc="[true]"
                        v-if="campains && campains.length > 0 && campains_available && view_details && campains_displayed.length > 0"
                    >
                        <!-- TOP -->
                        <template v-slot:top>
                            <v-toolbar flat v-if="campains_available">
                                <div> 
                                    <v-tooltip v-if="view_details" bottom dark>
                                        <template v-slot:activator="{ on }">
                                            <v-btn class="ma-0 mr-5 col-auto elevation-3" v-on="on" text icon @click="viewDetails()" dusk="logout-button" color="primary" style="max-width: 50px !important;min-width: 45px !important;border-radius: 25px;background-color: #001047;">
                                                <v-icon color="white">mdi-eye</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Afficher le détail des campagnes</span>
                                    </v-tooltip>
                                    <v-tooltip v-if="!view_details" bottom dark>
                                        <template v-slot:activator="{ on }">
                                            <v-btn class="ma-0 mr-5 col-auto elevation-3" v-on="on" text icon @click="viewDetails()" dusk="logout-button" color="red" style="max-width: 50px !important;min-width: 45px !important;border-radius: 25px;background-color: #f15959;">
                                                <v-icon color="white">mdi-eye-off</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Masquer le détail des campagnes</span>
                                    </v-tooltip>
                                </div>

                                <div class="col col-8">
                                    <div class="elevation-0 pl-5 pt-2 pb-2" >
                                        Liste des campagnes
                                    </div>
                                </div>
                                
                                <!-- SEARCH -->
                                <v-col style="display: flex; justify-content: end;">
                                    <v-layout class="justify-end mt-7 max-width-300">
                                        <v-text-field
                                            v-model="search"
                                            append-icon="mdi-magnify"
                                            :label="$t('global.search')"
                                        ></v-text-field>
                                    </v-layout>
                                </v-col>

                                <v-snackbar
                                    v-model="snackbar"
                                    :color="snackbar_type">
                                    {{ snackbar_text }}
                                    <template v-slot:action="{ attrs }">
                                        <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                                    </template>
                                </v-snackbar>
                            </v-toolbar>
                        </template>

                        <!-- INFOS CAMPAGNES -->
                        <template v-slot:item.name="{ item }">
                            <div style="height:100%;padding-left:20px;">
                                <div v-if="item && item !== 'undefined' && item !== null && typeof item === 'object'">
                                    <v-col class="ma-0 pa-0" style="justify-content:space-between;">
                                        <!-- CAMPAIN NAME --> 
                                        <v-col class="mb-2 mt-0 mr-1" v-on="on" link :to="'/campain/' + item.id" style="font-weight: bold;cursor:pointer; border-radius:25px;">                                   
                                            <div style="display:flex;flex-direction:row; justify-content:space-between">
                                                <v-row>
                                                    <!-- CAMPAIN STATE --> 
                                                    <div class="pt-2">
                                                        <div v-if="item.step < 13 && item.closed_at === null"                   style="width: 15px; background-color: rgb(239 120 14);        height:15px;border-radius:15px;margin-right:12px;position:relative;margin-left:5px;top:3px;     "></div>
                                                        <div v-if="item.step > 20 && item.step < 23 && item.closed_at === null" style="width: 15px; background-color: rgb(14 76 239 / 51%);   height:15px;border-radius:15px;margin-right:12px;position:relative;margin-left:5px;top:3px;      "></div>
                                                        <div v-if="item.step > 22 && item.step < 33 && item.closed_at === null" style="width: 15px; background-color: rgb(14 76 239 / 79%);   height:15px;border-radius:15px;margin-right:12px;position:relative;margin-left:5px;top:3px;      "></div>
                                                        <div v-if="item.step > 32 && item.step < 43 && item.closed_at === null" style="width: 15px; background-color: rgb(14, 76, 239);     height:15px;border-radius:15px;margin-right:12px;position:relative;margin-left:5px;top:3px;      "></div>
                                                        <div v-if="item.step === 43 && item.closed_at === null"                 style="width: 15px; background-color: rgb(14, 76, 239);     height:15px;border-radius:15px;margin-right:12px;position:relative;margin-left:5px;top:3px;      "></div>
                                                        <div v-if="item.closed_at != null"                                      style="width: 15px; background-color: rgb(86, 86, 86);      height:15px;border-radius:15px;margin-right:12px;position:relative;margin-left:5px;top:3px;      "></div>
                                                    </div>
                                                
                                                    <v-chip v-if="item.label !== null" class="mb-1 mt-1" v-on="on" link :to="'/campain/' + item.id" style="font-weight: bold; background-color: #f3f3f3; border: 1px solid #e5e5e5;">{{item.name}}</v-chip>
                                                </v-row>

                                                <div v-for="professional in professionals" :key="professional" style="display:flex;align-items:center;">
                                                    <img v-if="item.id_professional == professional.id" :src="professional.logo" :alt="professional.name" style="max-height: 50px;max-width: 60px; margin-right:10px;">
                                                    <div v-if="item.id_professional == professional.id" class="mb-1 mt-1" style="font-weight: bold; font-size:16px;">{{professional.name}}</div>
                                                </div>
                                            </div>
                                            <v-row v-for="presta in prestas" :key="presta">
                                                <div v-for="typex in types" :key="typex">
                                                    <div v-if="presta.id === item.presta && typex.id === item.type" style="font-weight:normal;font-size: 12px;margin-left: 32px;">{{ presta.name }} ({{ typex.name.toLowerCase().trim() }})</div>
                                                </div>
                                            </v-row>
                                        </v-col>
                                    </v-col>
                                    <!-- CAMPAIN DETAILS --> 
                                    <v-row class="ml-6 mb-0">
                                        <v-col>
                                            <div style="font-size: 12px;margin-left: 10px;">Référence : {{item.uid}}</div>
                                            <div style="font-size: 12px;margin-left: 10px;">Créée le : {{item.created_at}}</div>
                                            <div v-for="tviewer in team" :key="tviewer">
                                                <div v-if="tviewer.id === item.creator" style="font-size: 12px;margin-left: 10px;margin-bottom:5px;">Créé par : {{tviewer.first_name}} {{tviewer.last_name}}</div>
                                            </div>
                                        </v-col>
                                        <v-col>
                                            <div v-for="analyst in analysts_datas" :key="analyst">
                                                <div v-if="analyst.id === item.manager" style="font-size: 12px;margin-left: 10px;font-weight: bold;">Chargé de campagne : {{analyst.name}}</div>
                                            </div>
                                            <div style="font-size: 12px;margin-left: 10px;">N° de dossier : {{item.affaire_number}}</div>
                                            <div v-for="site in sites" :key="site">
                                                <div v-if="site.id === item.id_park" style="font-size: 12px;margin-left: 10px;">Parc machine associé : {{site.label}}</div>
                                            </div>
                                        </v-col>
                                    </v-row>

                                    <div v-if="item.description != null" class="ml-12" style="font-weight: bold;font-size: 12px;">Informations complémentaires :</div> 
                                    <p v-if="item.description != null" class="ml-12 mb-0" style="font-size: 12px; margin-bottom:15px;">{{ item.description }}</p>

                                    <v-row class="col pa-2 pl-0 ml-10 mt-2 mb-0">
                                        <div v-if="item.closed_at != null">
                                            <v-tooltip v-if="!item.trash && access_level <= 99 && item.step == 43 && item.state == -1" bottom dark>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn class="ma-0 col-0" text icon v-on="on" link :to="'/campain/' + item.id" dusk="logout-button">
                                                        <v-icon color="red">mdi-lock-open</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Ouvrir la campagne</span>
                                            </v-tooltip>
                                        </div>

                                        <div v-else>
                                            <v-tooltip v-if="!item.trash && access_level <= 99 && item.step == 43 && item.state != -1" bottom dark>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn class="ma-0 col-0" text icon v-on="on" link :to="'/campain/' + item.id" dusk="logout-button">
                                                        <v-icon color="green">mdi-lock</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Cloturer la campagne</span>
                                            </v-tooltip>
                                        </div>
                                        
                                        <v-tooltip bottom dark>
                                            <template v-slot:activator="{ on }">
                                                <v-btn class="ma-0 col-0" text icon v-on="on" link :to="'/campain/' + item.id" dusk="logout-button">
                                                    <v-icon color="primary">mdi-eye</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Afficher les détails de la campagne</span>
                                        </v-tooltip>

                                        <v-tooltip v-if="!item.trash && access_level <= 99 " bottom dark>
                                            <template v-slot:activator="{ on }">
                                                <v-btn class="ma-0 col-0" text icon v-on="on" @click="openDialogUpdateCampain(item)" dusk="logout-button">
                                                    <v-icon color="primary">mdi-circle-edit-outline</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{ $t('campain.edit_campain') }}</span>
                                        </v-tooltip>

                                        <v-tooltip v-if="!item.trash && access_level <= 99" bottom dark>
                                            <template v-slot:activator="{ on }">
                                                <v-btn class="ma-0 col-0" text icon v-on="on" @click="setDataTrash(item,1)" dusk="logout-button">
                                                    <v-icon color="red">mdi-delete-alert</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{ $t('campain.desactivate_campain') }}</span>
                                        </v-tooltip>
                                    </v-row>
                                </div>
                            </div>
                        </template>

                        <!-- CAMPAIN END STATE -->
                        <template v-slot:item.description="{ item }" style="display:flex;flex-direction:column;width:100%;">
                            <!-- IF OPEN --> 
                            <div v-if="item && item !== 'undefined' && item !== null && typeof item === 'object' && item.closed_at === null">
                                <!-- OPEN OTHERS -->
                                <div  v-if="item.presta !== 5" class="col pa-0 ma-0" style="height:110px;overflow:hidden;position:relative;top: 10px;">
                                    <div class="row row col pa-10 pt-5 pb-5 ma-0 ml-0 mt-1 mb-5" style="
                                        align-items: center;
                                        justify-content: center;
                                        text-align: center;
                                        border-radius: 100px;
                                    ">
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step <= 11 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 12 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 13 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step > 13 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                            <!-- ADMIN VIEW -->
                                            <v-icon v-if="item.step === 11 && access_level <= 100" @click="updateStep(item.id,12)" class="pointer" color="orange" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 12 && access_level <= 100" @click="updateStep(item.id,13)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 13 && access_level <= 100" @click="updateStep(item.id,11)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step > 13 && access_level <= 100" @click="updateStep(item.id,11)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                        </p>
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step >= 13 && item.step <= 21 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 22 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 23 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 13 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step > 23 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                            <!-- ADMIN VIEW -->
                                            <v-icon v-if="item.step >= 13 && item.step <= 21 && access_level <= 100" @click="updateStep(item.id,22)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 22 && access_level <= 100" @click="updateStep(item.id,23)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 23 && access_level <= 100" @click="updateStep(item.id,21)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 13 && access_level <= 100" @click="updateStep(item.id,21)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step > 23 && access_level <= 100" @click="updateStep(item.id,21)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                        </p>
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step >= 23 && item.step <= 31 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 32 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 33 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 23 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step > 33 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                            <!-- ADMIN VIEW -->
                                            <v-icon v-if="item.step >= 23 && item.step <= 31 && access_level <= 100" @click="updateStep(item.id,32)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 32 && access_level <= 100" @click="updateStep(item.id,33)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 33 && access_level <= 100" @click="updateStep(item.id,31)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 23 && access_level <= 100" @click="updateStep(item.id,31)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step > 33 && access_level <= 100" @click="updateStep(item.id,31)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                        </p>
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step >= 33 && item.step <= 41 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 42 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 43 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 33 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <!-- ADMIN VIEW -->
                                            <v-icon v-if="item.step >= 33 && item.step <= 41 && access_level <= 100" @click="updateStep(item.id,42)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 42 && access_level <= 100" @click="updateStep(item.id,43)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 43 && access_level <= 100" @click="updateStep(item.id,41)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 33 && access_level <= 100" @click="updateStep(item.id,41)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                        </p>
                                    </div>
                                    
                                    <div class="col" style="
                                        position: relative;
                                        border: 1px solid #656565;
                                        top: -58px;
                                        width: 70%;
                                        left: 15%;
                                        border-radius: 15px;
                                        height: 2px;
                                        padding: 0px;
                                    "></div>
                                    
                                    <div class="row row col pa-10 pt-0 pb-0 ma-0 ml-0" style="
                                        align-items: center;
                                        justify-content: center;
                                        text-align: center;
                                        position: relative;
                                        top: -57px;
                                    ">
                                        <p class="ml-0 mb-0 col" v-if="item.step === 11 || item.step === 12 || item.step === 13" style="z-index: 10;font-weight: bold;">Préparation</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 11 && item.step != 12 && item.step != 13" style="z-index: 10;">Préparation</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step === 21 || item.step === 22 || item.step === 23" style="z-index: 10;font-weight: bold;">Intervention</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 21 && item.step != 22 && item.step != 23" style="z-index: 10;">Intervention</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step === 31 || item.step === 32 || item.step === 33" style="z-index: 10;font-weight: bold;">Analyses</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 31 && item.step != 32 && item.step != 33" style="z-index: 10;">Analyses</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step === 41 || item.step === 42 || item.step === 43" style="z-index: 10;font-weight: bold;">Rapports</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 41 && item.step != 42 && item.step != 43" style="z-index: 10;">Rapports</p>
                                    </div>                         
                                </div>
                                
                                <!-- OPEN ONLINE -->
                                <div  v-if="item.presta === 5" class="col pa-0 ma-0" style="height:110px;overflow:hidden;position:relative;top: 10px;">
                                    <div class="row row col pa-10 pt-5 pb-5 ma-0 ml-0 mt-1 mb-5" style="
                                        align-items: center;
                                        justify-content: center;
                                        text-align: center;
                                        border-radius: 100px;
                                    ">
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step <= 11 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 12 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 13 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step > 13 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                            <!-- ADMIN VIEW -->
                                            <v-icon v-if="item.step === 11 && access_level <= 100" @click="updateStep(item.id,12)" class="pointer" color="orange" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 12 && access_level <= 100" @click="updateStep(item.id,13)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 13 && access_level <= 100" @click="updateStep(item.id,11)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step > 13 && access_level <= 100" @click="updateStep(item.id,11)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                        </p>
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step >= 13 && item.step <= 21 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 22 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 23 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 13 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step > 23 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                            <!-- ADMIN VIEW -->
                                            <v-icon v-if="item.step >= 13 && item.step <= 21 && access_level <= 100" @click="updateStep(item.id,22)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 22 && access_level <= 100" @click="updateStep(item.id,23)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 23 && access_level <= 100" @click="updateStep(item.id,21)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 13 && access_level <= 100" @click="updateStep(item.id,21)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step > 23 && access_level <= 100" @click="updateStep(item.id,21)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                        </p>
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step >= 23 && item.step <= 31 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 32 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 33 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 23 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step > 33 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                            <!-- ADMIN VIEW -->
                                            <v-icon v-if="item.step >= 23 && item.step <= 31 && access_level <= 100" @click="updateStep(item.id,32)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 32 && access_level <= 100" @click="updateStep(item.id,33)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 33 && access_level <= 100" @click="updateStep(item.id,31)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 23 && access_level <= 100" @click="updateStep(item.id,31)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step > 33 && access_level <= 100" @click="updateStep(item.id,31)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                        </p>
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step >= 33 && item.step <= 41 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 42 && access_level > 100" color="flashGreen" style="font-size: 36px;">mdi-play-network</v-icon>
                                            <v-icon v-if="item.step === 43 && access_level > 100" color="red" style="font-size: 36px;">mdi-close-network-outline</v-icon>
                                            <v-icon v-if="item.step < 33 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <!-- ADMIN VIEW -->
                                            <v-icon v-if="item.step >= 33 && item.step <= 41 && access_level <= 100" @click="updateStep(item.id,42)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 42 && access_level <= 100" @click="updateStep(item.id,43)" class="pointer" color="flashGreen" style="font-size: 36px;">mdi-play-network</v-icon>
                                            <v-icon v-if="item.step === 43 && access_level <= 100" @click="updateStep(item.id,41)" class="pointer" color="red" style="font-size: 36px;">mdi-close-network-outline</v-icon>
                                            <v-icon v-if="item.step < 33 && access_level <= 100" @click="updateStep(item.id,41)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                        </p>
                                    </div>
                                    <div v-if="item.step === 42" class="col" style="
                                        position: relative;
                                        border: 1px solid #00db37;
                                        top: -58px;
                                        width: 70%;
                                        left: 15%;
                                        border-radius: 15px;
                                        height: 2px;
                                        padding: 0px;
                                    "></div>
                                    <div v-if="item.step === 43" class="col" style="
                                        position: relative;
                                        border: 1px solid #d70000;
                                        top: -58px;
                                        width: 70%;
                                        left: 15%;
                                        border-radius: 15px;
                                        height: 2px;
                                        padding: 0px;
                                    "></div>
                                    <div v-if="item.step < 42" class="col" style="
                                        position: relative;
                                        border: 1px solid #656565;
                                        top: -58px;
                                        width: 70%;
                                        left: 15%;
                                        border-radius: 15px;
                                        height: 2px;
                                        padding: 0px;
                                    "></div>
                                    <div class="row row col pa-10 pt-0 pb-0 ma-0 ml-0" style="
                                        align-items: center;
                                        justify-content: center;
                                        text-align: center;
                                        position: relative;
                                        top: -57px;
                                    ">
                                        <p class="ml-0 mb-0 col" v-if="item.step === 11 || item.step === 12 || item.step === 13" style="z-index: 10;font-weight: bold;">Préparation</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 11 && item.step != 12 && item.step != 13" style="z-index: 10;">Préparation</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step === 21 || item.step === 22 || item.step === 23" style="z-index: 10;font-weight: bold;">Instrumentation</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 21 && item.step != 22 && item.step != 23" style="z-index: 10;">Instrumentation</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step === 31 || item.step === 32 || item.step === 33" style="z-index: 10;font-weight: bold;">Configuration</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 31 && item.step != 32 && item.step != 33" style="z-index: 10;">Configuration</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step === 41 || item.step === 42 || item.step === 43" style="z-index: 10;font-weight: bold;">Surveillance</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 41 && item.step != 42 && item.step != 43" style="z-index: 10;">Surveillance</p>
                                    </div>                         
                                </div>
                            </div>

                            <!-- IF CLOSED -->
                            <div v-if="item && item !== 'undefined' && item !== null && typeof item === 'object' && item.closed_at != null">
                                <!-- CLOSED OTHERS -->
                                <div  v-if="item.presta !== 5" class="col pa-0 ma-0" style="height:110px;overflow:hidden;position:relative;top: 10px;">
                                    <div class="row row col pa-10 pt-5 pb-5 ma-0 ml-0 mt-1 mb-5" style="
                                        align-items: center;
                                        justify-content: center;
                                        text-align: center;
                                        border-radius: 100px;
                                    ">
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step <= 11" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 12" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 13 " style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step > 13" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                        </p>
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step === 21" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 22" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 23" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 21"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step > 23" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>                                            </p>
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step === 31" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 32" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 33" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 31"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step > 33" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                        </p>
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step === 41" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 42" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 43" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 41"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                        </p>
                                    </div>
                                    <div class="col" style="
                                        position: relative;
                                        border: 1px solid #656565;
                                        top: -58px;
                                        width: 70%;
                                        left: 15%;
                                        border-radius: 15px;
                                        height: 2px;
                                        padding: 0px;
                                    "></div>
                                    <div class="row row col pa-10 pt-0 pb-0 ma-0 ml-0" style="
                                        align-items: center;
                                        justify-content: center;
                                        text-align: center;
                                        position: relative;
                                        top: -57px;
                                    ">
                                        <p class="ml-0 mb-0 col" v-if="item.step === 11 || item.step === 12 || item.step === 13" style="z-index: 10;font-weight: bold;">Préparation</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 11 && item.step != 12 && item.step != 13" style="z-index: 10;">Préparation</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step === 21 || item.step === 22 || item.step === 23" style="z-index: 10;font-weight: bold;">Intervention</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 21 && item.step != 22 && item.step != 23" style="z-index: 10;">Intervention</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step === 31 || item.step === 32 || item.step === 33" style="z-index: 10;font-weight: bold;">Analyses</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 31 && item.step != 32 && item.step != 33" style="z-index: 10;">Analyses</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step === 41 || item.step === 42 || item.step === 43" style="z-index: 10;font-weight: bold;">Rapports</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 41 && item.step != 42 && item.step != 43" style="z-index: 10;">Rapports</p>
                                    </div>                       
                                </div>

                                <!-- CLOSED ONLINE -->
                                <div  v-if="item.presta === 5" class="col pa-0 ma-0" style="height:110px;overflow:hidden;position:relative;top: 10px;">
                                    <div class="row row col pa-10 pt-5 pb-5 ma-0 ml-0 mt-1 mb-5" style="
                                        align-items: center;
                                        justify-content: center;
                                        text-align: center;
                                        border-radius: 100px;
                                    ">
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step <= 11 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 12 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 13 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step > 13 && access_level > 100" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                            </p>
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step === 21 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 22 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 23 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 21 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step > 23 && access_level > 100" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                        </p>
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step === 31 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 32 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 33 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 31 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step > 33 && access_level > 100" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                        </p>
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step === 41 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 42 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-play-network</v-icon>
                                            <v-icon v-if="item.step === 43 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-close-network-outline</v-icon>
                                            <v-icon v-if="item.step < 41 && access_level > 100" style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                        </p>
                                    </div>
                                    <div v-if="item.step === 42" class="col" style="
                                        position: relative;
                                        border: 1px solid #00db37;
                                        top: -58px;
                                        width: 70%;
                                        left: 15%;
                                        border-radius: 15px;
                                        height: 2px;
                                        padding: 0px;
                                    "></div>
                                    <div v-if="item.step === 43" class="col" style="
                                        position: relative;
                                        border: 1px solid #d70000;
                                        top: -58px;
                                        width: 70%;
                                        left: 15%;
                                        border-radius: 15px;
                                        height: 2px;
                                        padding: 0px;
                                    "></div>
                                    <div v-if="item.step < 42" class="col" style="
                                        position: relative;
                                        border: 1px solid #656565;
                                        top: -58px;
                                        width: 70%;
                                        left: 15%;
                                        border-radius: 15px;
                                        height: 2px;
                                        padding: 0px;
                                    "></div>
                                    <div class="row row col pa-10 pt-0 pb-0 ma-0 ml-0" style="
                                        align-items: center;
                                        justify-content: center;
                                        text-align: center;
                                        position: relative;
                                        top: -57px;
                                    ">
                                        <p class="ml-0 mb-0 col" v-if="item.step === 11 || item.step === 12 || item.step === 13" style="z-index: 10;font-weight: bold;">Préparation</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 11 && item.step != 12 && item.step != 13" style="z-index: 10;">Préparation</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step === 21 || item.step === 22 || item.step === 23" style="z-index: 10;font-weight: bold;">Instrumentation</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 21 && item.step != 22 && item.step != 23" style="z-index: 10;">Instrumentation</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step === 31 || item.step === 32 || item.step === 33" style="z-index: 10;font-weight: bold;">Configuration</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 31 && item.step != 32 && item.step != 33" style="z-index: 10;">Configuration</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step === 41 || item.step === 42 || item.step === 43" style="z-index: 10;font-weight: bold;">Surveillance</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 41 && item.step != 42 && item.step != 43" style="z-index: 10;">Surveillance</p>
                                    </div>                        
                                </div>
                            </div>

                            <v-row style="justify-content:end;margin-top:10px;margin-bottom:10px;padding-right: 100px;">
                                <v-chip v-for="tag in getCampainTags(item)" class="pl-3 pr-3 ma-2" link :key="tag" style="font-size:12px;">
                                    {{ tag }}
                                </v-chip>
                            </v-row>    
                        </template>
                    </v-data-table>

                    <!-- DATA TABLE without view details -->
                    <v-data-table
                        :headers="headers2"
                        :items="campains_displayed"
                        class="elevation-1 datatable-operation"
                        style="border-radius: 5px; overflow: hidden;"
                        :items-per-page="-1"
                        :loading="loading_datatable"
                        :search="search"
                        :sort-by="['id']"
                        :sort-desc="[true]"
                        v-if="campains && campains.length > 0 && campains_available && !view_details && campains_displayed.length > 0"
                    >
                        <!-- TOP -->
                        <template v-slot:top>
                            <v-toolbar flat v-if="campains_available">
                                <div> 
                                    <v-tooltip v-if="view_details" bottom dark>
                                        <template v-slot:activator="{ on }">
                                            <v-btn class="ma-0 mr-5 col-auto elevation-3" v-on="on" text icon @click="viewDetails()" dusk="logout-button" color="primary" style="max-width: 50px !important;min-width: 45px !important;border-radius: 25px;background-color: #001047;">
                                                <v-icon color="white">mdi-eye</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Afficher le détail des campagnes</span>
                                    </v-tooltip>
                                    <v-tooltip v-if="!view_details" bottom dark>
                                        <template v-slot:activator="{ on }">
                                            <v-btn class="ma-0 mr-5 col-auto elevation-3" v-on="on" text icon @click="viewDetails()" dusk="logout-button" color="red" style="max-width: 50px !important;min-width: 45px !important;border-radius: 25px;background-color: #f15959;">
                                                <v-icon color="white">mdi-eye-off</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Masquer le détail des campagnes</span>
                                    </v-tooltip>
                                </div>

                                <div class="col col-8">
                                    <div class="elevation-0 pl-5 pt-2 pb-2" >
                                        Liste des campagnes
                                    </div>
                                </div>

                                <!-- SEARCH -->
                                <v-col style="display: flex; justify-content: end;">
                                    <v-layout class="justify-end mt-7 max-width-300">
                                        <v-text-field
                                            v-model="search"
                                            append-icon="mdi-magnify"
                                            :label="$t('global.search')"
                                        ></v-text-field>
                                    </v-layout>
                                </v-col>

                                <v-snackbar
                                    v-model="snackbar"
                                    :color="snackbar_type">
                                    {{ snackbar_text }}
                                    <template v-slot:action="{ attrs }">
                                        <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                                    </template>
                                </v-snackbar>
                            </v-toolbar>
                        </template>

                        <!-- INFOS CAMPAGNES -->
                        <template v-slot:item.name="{ item }">
                            <div style="height:100%;padding-left:20px;">
                                <div v-if="item && item !== 'undefined' && item !== null && typeof item === 'object'">
                                    <v-col class="ma-0 pa-0" style="justify-content:space-between;">
                                        <!-- CAMPAIN NAME --> 
                                        <v-col class="mb-0 mt-2 mr-6 pb-1" v-on="on" link :to="'/campain/' + item.id" style="font-weight: bold;cursor:pointer; border-radius:25px;">                                   
                                            <div style="display:flex;flex-direction:column;">
                                                <v-row>
                                                    <!-- CAMPAIN STATE --> 
                                                    <div class="pt-2">
                                                        <div v-if="item.step < 13 && item.closed_at === null"                   style="width: 15px; background-color: rgb(239 120 14);        height:15px;border-radius:15px;margin-right:12px;position:relative;margin-left:5px;top:3px;     "></div>
                                                        <div v-if="item.step > 20 && item.step < 23 && item.closed_at === null" style="width: 15px; background-color: rgb(14 76 239 / 51%);   height:15px;border-radius:15px;margin-right:12px;position:relative;margin-left:5px;top:3px;      "></div>
                                                        <div v-if="item.step > 22 && item.step < 33 && item.closed_at === null" style="width: 15px; background-color: rgb(14 76 239 / 79%);   height:15px;border-radius:15px;margin-right:12px;position:relative;margin-left:5px;top:3px;      "></div>
                                                        <div v-if="item.step > 32 && item.step < 43 && item.closed_at === null" style="width: 15px; background-color: rgb(14, 76, 239);     height:15px;border-radius:15px;margin-right:12px;position:relative;margin-left:5px;top:3px;      "></div>
                                                        <div v-if="item.step === 43 && item.closed_at === null"                 style="width: 15px; background-color: rgb(14, 76, 239);     height:15px;border-radius:15px;margin-right:12px;position:relative;margin-left:5px;top:3px;      "></div>
                                                        <div v-if="item.closed_at != null"                                      style="width: 15px; background-color: rgb(86, 86, 86);      height:15px;border-radius:15px;margin-right:12px;position:relative;margin-left:5px;top:3px;      "></div>
                                                    </div>
                                                
                                                    <v-chip v-if="item.label !== null" class="mb-1 mt-1" v-on="on" link :to="'/campain/' + item.id" style="font-weight: bold; background-color: #f3f3f3; border: 1px solid #e5e5e5;">{{item.name}}</v-chip>
                                                </v-row>

                                                <div v-for="professional in professionals" :key="professional" style="display:flex;margin:10px;align-items:center;">
                                                    <img v-if="item.id_professional == professional.id" :src="professional.logo" :alt="professional.name" style="max-height: 35px ;max-width: 45px;">
                                                    <div v-if="item.id_professional == professional.id" class="mb-1 mt-1" style="font-weight: bold; font-size:16px;">{{professional.name}}</div>
                                                </div>

                                                <div v-for="expert in analysts_datas" :key="expert" style="margin-bottom:10px;">
                                                    <div v-if="expert.id == item.manager">Chargé de campagne : {{ expert.name }}</div>
                                                </div>
                                            </div>
                                            <v-row v-for="presta in prestas" :key="presta">
                                                <div v-for="typex in types" :key="typex">
                                                    <div v-if="presta.id === item.presta && typex.id === item.type" style="font-weight:normal;font-size: 12px;margin-left: 32px;">{{ presta.name }} ({{ typex.name.toLowerCase().trim() }})</div>
                                                </div>
                                            </v-row>
                                        </v-col>
                                    </v-col>
                                    <!-- Actions -->
                                    <v-row class="col pa-2 pl-0 ml-10 mt-0 mb-0 pt-0" style="align-items:end;">
                                        <div v-if="item.closed_at != null">
                                            <v-tooltip v-if="!item.trash && access_level <= 99 && item.step == 43 && item.state == -1" bottom dark>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn class="ma-0 col-0" text icon v-on="on" link :to="'/campain/' + item.id" dusk="logout-button">
                                                        <v-icon color="red">mdi-lock-open</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Ouvrir la campagne</span>
                                            </v-tooltip>
                                        </div>

                                        <div v-else>
                                            <v-tooltip v-if="!item.trash && access_level <= 99 && item.step == 43 && item.state != -1" bottom dark>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn class="ma-0 col-0" text icon v-on="on" link :to="'/campain/' + item.id" dusk="logout-button">
                                                        <v-icon color="green">mdi-lock</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Cloturer la campagne</span>
                                            </v-tooltip>
                                        </div>
                                        
                                        <v-tooltip bottom dark>
                                            <template v-slot:activator="{ on }">
                                                <v-btn class="ma-0 col-0" text icon v-on="on" link :to="'/campain/' + item.id" dusk="logout-button">
                                                    <v-icon color="primary">mdi-eye</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Afficher les détails de la campagne</span>
                                        </v-tooltip>

                                        <v-tooltip v-if="!item.trash && access_level <= 99 " bottom dark>
                                            <template v-slot:activator="{ on }">
                                                <v-btn class="ma-0 col-0" text icon v-on="on" @click="openDialogUpdateCampain(item)" dusk="logout-button">
                                                    <v-icon color="primary">mdi-circle-edit-outline</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{ $t('campain.edit_campain') }}</span>
                                        </v-tooltip>

                                        <v-tooltip v-if="!item.trash && access_level <= 99" bottom dark>
                                            <template v-slot:activator="{ on }">
                                                <v-btn class="ma-0 col-0 mt-2" text icon v-on="on" @click="setDataTrash(item,1)" dusk="logout-button">
                                                    <v-icon color="red">mdi-delete-alert</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{ $t('campain.desactivate_campain') }}</span>
                                        </v-tooltip>
                                    </v-row>
                                </div>
                            </div>
                        </template>

                        <!-- TAGS -->
                        <template v-slot:item.tags="{ item }">
                            <v-row style="margin-top:10px;margin-bottom:10px;">
                                <v-chip v-for="tag in getCampainTags(item)" class="pl-3 pr-3 ma-2" link :key="tag" style="font-size:12px;">
                                    {{ tag }}
                                </v-chip>
                            </v-row>    
                        </template>

                        <!-- CAMPAIN END STATE -->
                        <template v-slot:item.description="{ item }" style="display:flex;flex-direction:column;width:100%;">
                            <!-- IF OPEN --> 
                            <div v-if="item && item !== 'undefined' && item !== null && typeof item === 'object' && item.closed_at === null">
                                <!-- OPEN OTHERS -->
                                <div  v-if="item.presta !== 5" class="col pa-0 ma-0" style="height:110px;overflow:hidden;position:relative;top: 10px;">
                                    <div class="row row col pa-10 pt-0 pb-5 ma-0 ml-0 mt-1 mb-5" style="
                                        align-items: center;
                                        justify-content: center;
                                        text-align: center;
                                        border-radius: 100px;
                                    ">
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step <= 11 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 12 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 13 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step > 13 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                            <!-- ADMIN VIEW -->
                                            <v-icon v-if="item.step === 11 && access_level <= 100" @click="updateStep(item.id,12)" class="pointer" color="orange" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 12 && access_level <= 100" @click="updateStep(item.id,13)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 13 && access_level <= 100" @click="updateStep(item.id,11)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step > 13 && access_level <= 100" @click="updateStep(item.id,11)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                        </p>
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step >= 13 && item.step <= 21 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 22 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 23 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 13 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step > 23 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                            <!-- ADMIN VIEW -->
                                            <v-icon v-if="item.step >= 13 && item.step <= 21 && access_level <= 100" @click="updateStep(item.id,22)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 22 && access_level <= 100" @click="updateStep(item.id,23)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 23 && access_level <= 100" @click="updateStep(item.id,21)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 13 && access_level <= 100" @click="updateStep(item.id,21)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step > 23 && access_level <= 100" @click="updateStep(item.id,21)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                        </p>
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step >= 23 && item.step <= 31 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 32 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 33 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 23 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step > 33 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                            <!-- ADMIN VIEW -->
                                            <v-icon v-if="item.step >= 23 && item.step <= 31 && access_level <= 100" @click="updateStep(item.id,32)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 32 && access_level <= 100" @click="updateStep(item.id,33)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 33 && access_level <= 100" @click="updateStep(item.id,31)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 23 && access_level <= 100" @click="updateStep(item.id,31)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step > 33 && access_level <= 100" @click="updateStep(item.id,31)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                        </p>
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step >= 33 && item.step <= 41 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 42 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 43 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 33 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <!-- ADMIN VIEW -->
                                            <v-icon v-if="item.step >= 33 && item.step <= 41 && access_level <= 100" @click="updateStep(item.id,42)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 42 && access_level <= 100" @click="updateStep(item.id,43)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 43 && access_level <= 100" @click="updateStep(item.id,41)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 33 && access_level <= 100" @click="updateStep(item.id,41)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                        </p>
                                    </div>
                                    
                                    <div class="col" style="
                                        position: relative;
                                        border: 1px solid #656565;
                                        top: -58px;
                                        width: 70%;
                                        left: 15%;
                                        border-radius: 15px;
                                        height: 2px;
                                        padding: 0px;
                                    "></div>
                                    
                                    <div class="row row col pa-10 pt-0 pb-0 ma-0 ml-0" style="
                                        align-items: center;
                                        justify-content: center;
                                        text-align: center;
                                        position: relative;
                                        top: -57px;
                                    ">
                                        <p class="ml-0 mb-0 col" v-if="item.step === 11 || item.step === 12 || item.step === 13" style="z-index: 10;font-weight: bold;">Préparation</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 11 && item.step != 12 && item.step != 13" style="z-index: 10;">Préparation</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step === 21 || item.step === 22 || item.step === 23" style="z-index: 10;font-weight: bold;">Intervention</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 21 && item.step != 22 && item.step != 23" style="z-index: 10;">Intervention</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step === 31 || item.step === 32 || item.step === 33" style="z-index: 10;font-weight: bold;">Analyses</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 31 && item.step != 32 && item.step != 33" style="z-index: 10;">Analyses</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step === 41 || item.step === 42 || item.step === 43" style="z-index: 10;font-weight: bold;">Rapports</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 41 && item.step != 42 && item.step != 43" style="z-index: 10;">Rapports</p>
                                    </div>                         
                                </div>
                                
                                <!-- OPEN ONLINE -->
                                <div  v-if="item.presta === 5" class="col pa-0 ma-0" style="height:110px;overflow:hidden;position:relative;top: 10px;">
                                    <div class="row row col pa-10 pt-0 pb-5 ma-0 ml-0 mt-1 mb-5" style="
                                        align-items: center;
                                        justify-content: center;
                                        text-align: center;
                                        border-radius: 100px;
                                    ">
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step <= 11 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 12 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 13 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step > 13 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                            <!-- ADMIN VIEW -->
                                            <v-icon v-if="item.step === 11 && access_level <= 100" @click="updateStep(item.id,12)" class="pointer" color="orange" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 12 && access_level <= 100" @click="updateStep(item.id,13)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 13 && access_level <= 100" @click="updateStep(item.id,11)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step > 13 && access_level <= 100" @click="updateStep(item.id,11)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                        </p>
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step >= 13 && item.step <= 21 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 22 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 23 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 13 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step > 23 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                            <!-- ADMIN VIEW -->
                                            <v-icon v-if="item.step >= 13 && item.step <= 21 && access_level <= 100" @click="updateStep(item.id,22)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 22 && access_level <= 100" @click="updateStep(item.id,23)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 23 && access_level <= 100" @click="updateStep(item.id,21)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 13 && access_level <= 100" @click="updateStep(item.id,21)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step > 23 && access_level <= 100" @click="updateStep(item.id,21)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                        </p>
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step >= 23 && item.step <= 31 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 32 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 33 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 23 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step > 33 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                            <!-- ADMIN VIEW -->
                                            <v-icon v-if="item.step >= 23 && item.step <= 31 && access_level <= 100" @click="updateStep(item.id,32)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 32 && access_level <= 100" @click="updateStep(item.id,33)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 33 && access_level <= 100" @click="updateStep(item.id,31)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 23 && access_level <= 100" @click="updateStep(item.id,31)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step > 33 && access_level <= 100" @click="updateStep(item.id,31)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                        </p>
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step >= 33 && item.step <= 41 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 42 && access_level > 100" color="flashGreen" style="font-size: 36px;">mdi-play-network</v-icon>
                                            <v-icon v-if="item.step === 43 && access_level > 100" color="red" style="font-size: 36px;">mdi-close-network-outline</v-icon>
                                            <v-icon v-if="item.step < 33 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <!-- ADMIN VIEW -->
                                            <v-icon v-if="item.step >= 33 && item.step <= 41 && access_level <= 100" @click="updateStep(item.id,42)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 42 && access_level <= 100" @click="updateStep(item.id,43)" class="pointer" color="flashGreen" style="font-size: 36px;">mdi-play-network</v-icon>
                                            <v-icon v-if="item.step === 43 && access_level <= 100" @click="updateStep(item.id,41)" class="pointer" color="red" style="font-size: 36px;">mdi-close-network-outline</v-icon>
                                            <v-icon v-if="item.step < 33 && access_level <= 100" @click="updateStep(item.id,41)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                        </p>
                                    </div>
                                    <div v-if="item.step === 42" class="col" style="
                                        position: relative;
                                        border: 1px solid #00db37;
                                        top: -58px;
                                        width: 70%;
                                        left: 15%;
                                        border-radius: 15px;
                                        height: 2px;
                                        padding: 0px;
                                    "></div>
                                    <div v-if="item.step === 43" class="col" style="
                                        position: relative;
                                        border: 1px solid #d70000;
                                        top: -58px;
                                        width: 70%;
                                        left: 15%;
                                        border-radius: 15px;
                                        height: 2px;
                                        padding: 0px;
                                    "></div>
                                    <div v-if="item.step < 42" class="col" style="
                                        position: relative;
                                        border: 1px solid #656565;
                                        top: -58px;
                                        width: 70%;
                                        left: 15%;
                                        border-radius: 15px;
                                        height: 2px;
                                        padding: 0px;
                                    "></div>
                                    <div class="row row col pa-10 pt-0 pb-0 ma-0 ml-0" style="
                                        align-items: center;
                                        justify-content: center;
                                        text-align: center;
                                        position: relative;
                                        top: -57px;
                                    ">
                                        <p class="ml-0 mb-0 col" v-if="item.step === 11 || item.step === 12 || item.step === 13" style="z-index: 10;font-weight: bold;">Préparation</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 11 && item.step != 12 && item.step != 13" style="z-index: 10;">Préparation</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step === 21 || item.step === 22 || item.step === 23" style="z-index: 10;font-weight: bold;">Instrumentation</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 21 && item.step != 22 && item.step != 23" style="z-index: 10;">Instrumentation</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step === 31 || item.step === 32 || item.step === 33" style="z-index: 10;font-weight: bold;">Configuration</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 31 && item.step != 32 && item.step != 33" style="z-index: 10;">Configuration</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step === 41 || item.step === 42 || item.step === 43" style="z-index: 10;font-weight: bold;">Surveillance</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 41 && item.step != 42 && item.step != 43" style="z-index: 10;">Surveillance</p>
                                    </div>                         
                                </div>
                            </div>

                            <!-- IF CLOSED -->
                            <div v-if="item && item !== 'undefined' && item !== null && typeof item === 'object' && item.closed_at != null">
                                <!-- CLOSED OTHERS -->
                                <div  v-if="item.presta !== 5" class="col pa-0 ma-0" style="height:110px;overflow:hidden;position:relative;top: 10px;">
                                    <div class="row row col pa-10 pt-0 pb-5 ma-0 ml-0 mt-1 mb-5" style="
                                        align-items: center;
                                        justify-content: center;
                                        text-align: center;
                                        border-radius: 100px;
                                    ">
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step <= 11" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 12" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 13 " style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step > 13" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                        </p>
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step === 21" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 22" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 23" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 21"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step > 23" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>                                            </p>
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step === 31" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 32" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 33" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 31"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step > 33" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                        </p>
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step === 41" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 42" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 43" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 41"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                        </p>
                                    </div>
                                    <div class="col" style="
                                        position: relative;
                                        border: 1px solid #656565;
                                        top: -58px;
                                        width: 70%;
                                        left: 15%;
                                        border-radius: 15px;
                                        height: 2px;
                                        padding: 0px;
                                    "></div>
                                    <div class="row row col pa-10 pt-0 pb-0 ma-0 ml-0" style="
                                        align-items: center;
                                        justify-content: center;
                                        text-align: center;
                                        position: relative;
                                        top: -57px;
                                    ">
                                        <p class="ml-0 mb-0 col" v-if="item.step === 11 || item.step === 12 || item.step === 13" style="z-index: 10;font-weight: bold;">Préparation</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 11 && item.step != 12 && item.step != 13" style="z-index: 10;">Préparation</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step === 21 || item.step === 22 || item.step === 23" style="z-index: 10;font-weight: bold;">Intervention</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 21 && item.step != 22 && item.step != 23" style="z-index: 10;">Intervention</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step === 31 || item.step === 32 || item.step === 33" style="z-index: 10;font-weight: bold;">Analyses</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 31 && item.step != 32 && item.step != 33" style="z-index: 10;">Analyses</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step === 41 || item.step === 42 || item.step === 43" style="z-index: 10;font-weight: bold;">Rapports</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 41 && item.step != 42 && item.step != 43" style="z-index: 10;">Rapports</p>
                                    </div>                       
                                </div>

                                <!-- CLOSED ONLINE -->
                                <div  v-if="item.presta === 5" class="col pa-0 ma-0" style="height:110px;overflow:hidden;position:relative;top: 10px;">
                                    <div class="row row col pa-10 pt-0 pb-5 ma-0 ml-0 mt-1 mb-5" style="
                                        align-items: center;
                                        justify-content: center;
                                        text-align: center;
                                        border-radius: 100px;
                                    ">
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step <= 11 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 12 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 13 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step > 13 && access_level > 100" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                            </p>
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step === 21 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 22 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 23 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 21 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step > 23 && access_level > 100" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                        </p>
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step === 31 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 32 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 33 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 31 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step > 33 && access_level > 100" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                        </p>
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step === 41 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 42 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-play-network</v-icon>
                                            <v-icon v-if="item.step === 43 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-close-network-outline</v-icon>
                                            <v-icon v-if="item.step < 41 && access_level > 100" style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                        </p>
                                    </div>
                                    <div v-if="item.step === 42" class="col" style="
                                        position: relative;
                                        border: 1px solid #00db37;
                                        top: -58px;
                                        width: 70%;
                                        left: 15%;
                                        border-radius: 15px;
                                        height: 2px;
                                        padding: 0px;
                                    "></div>
                                    <div v-if="item.step === 43" class="col" style="
                                        position: relative;
                                        border: 1px solid #d70000;
                                        top: -58px;
                                        width: 70%;
                                        left: 15%;
                                        border-radius: 15px;
                                        height: 2px;
                                        padding: 0px;
                                    "></div>
                                    <div v-if="item.step < 42" class="col" style="
                                        position: relative;
                                        border: 1px solid #656565;
                                        top: -58px;
                                        width: 70%;
                                        left: 15%;
                                        border-radius: 15px;
                                        height: 2px;
                                        padding: 0px;
                                    "></div>
                                    <div class="row row col pa-10 pt-0 pb-0 ma-0 ml-0" style="
                                        align-items: center;
                                        justify-content: center;
                                        text-align: center;
                                        position: relative;
                                        top: -57px;
                                    ">
                                        <p class="ml-0 mb-0 col" v-if="item.step === 11 || item.step === 12 || item.step === 13" style="z-index: 10;font-weight: bold;">Préparation</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 11 && item.step != 12 && item.step != 13" style="z-index: 10;">Préparation</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step === 21 || item.step === 22 || item.step === 23" style="z-index: 10;font-weight: bold;">Instrumentation</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 21 && item.step != 22 && item.step != 23" style="z-index: 10;">Instrumentation</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step === 31 || item.step === 32 || item.step === 33" style="z-index: 10;font-weight: bold;">Configuration</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 31 && item.step != 32 && item.step != 33" style="z-index: 10;">Configuration</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step === 41 || item.step === 42 || item.step === 43" style="z-index: 10;font-weight: bold;">Surveillance</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 41 && item.step != 42 && item.step != 43" style="z-index: 10;">Surveillance</p>
                                    </div>                        
                                </div>
                            </div>
                        </template>
                    </v-data-table>

                    <!-- DATA TABLE TRASH DATA -->
                    <v-data-table
                        :headers="headers"
                        :items="trashed_campains"
                        class="elevation-1 datatable-operation"
                        style="border-radius: 5px; overflow: hidden;"
                        :items-per-page="-1"
                        :loading="loading_datatable"
                        :search="search"
                        :sort-by="['id']"
                        :sort-desc="[true]"
                        v-if="trashed_campains && trashed_campains.length > 0 && active_special_vue == -1"
                    >
                        <!-- TOP -->
                        <template v-slot:top>
                            <v-toolbar flat v-if="trashed_campains">
                                <div class="col col-8">
                                    <div class="elevation-0 pl-5 pt-2 pb-2" >
                                        Liste des campagnes désactivées
                                    </div>
                                </div>
                                
                                <!-- SEARCH -->
                                <v-col style="display: flex; justify-content: end;">
                                    <v-layout class="justify-end mt-7 max-width-300">
                                        <v-text-field
                                            v-model="search"
                                            append-icon="mdi-magnify"
                                            :label="$t('global.search')"
                                        ></v-text-field>
                                    </v-layout>
                                </v-col>

                                <v-snackbar
                                    v-model="snackbar"
                                    :color="snackbar_type">
                                    {{ snackbar_text }}
                                    <template v-slot:action="{ attrs }">
                                        <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                                    </template>
                                </v-snackbar>
                            </v-toolbar>
                        </template>

                        <!-- INFOS CAMPAGNES -->
                        <template v-slot:item.name="{ item }">
                            <div style="height:100%;padding-left:20px;">
                                <div v-if="item && item !== 'undefined' && item !== null && typeof item === 'object'">
                                    <v-col class="ma-0 pa-0" style="justify-content:space-between;">
                                        <!-- CAMPAIN NAME --> 
                                        <v-col class="mb-2 mt-0 mr-1" v-on="on" link :to="'/campain/' + item.id" style="font-weight: bold;cursor:pointer; border-radius:25px;">                                   
                                            <div style="display:flex;flex-direction:column;">
                                                <v-row>
                                                    <!-- CAMPAIN STATE --> 
                                                    <div class="pt-2">
                                                        <div v-if="item.step < 13 && item.closed_at === null"                   style="width: 15px; background-color: rgb(239 120 14);        height:15px;border-radius:15px;margin-right:12px;position:relative;margin-left:5px;top:3px;     "></div>
                                                        <div v-if="item.step > 20 && item.step < 23 && item.closed_at === null" style="width: 15px; background-color: rgb(14 76 239 / 51%);   height:15px;border-radius:15px;margin-right:12px;position:relative;margin-left:5px;top:3px;      "></div>
                                                        <div v-if="item.step > 22 && item.step < 33 && item.closed_at === null" style="width: 15px; background-color: rgb(14 76 239 / 79%);   height:15px;border-radius:15px;margin-right:12px;position:relative;margin-left:5px;top:3px;      "></div>
                                                        <div v-if="item.step > 32 && item.step < 43 && item.closed_at === null" style="width: 15px; background-color: rgb(14, 76, 239);     height:15px;border-radius:15px;margin-right:12px;position:relative;margin-left:5px;top:3px;      "></div>
                                                        <div v-if="item.step === 43 && item.closed_at === null"                 style="width: 15px; background-color: rgb(14, 76, 239);     height:15px;border-radius:15px;margin-right:12px;position:relative;margin-left:5px;top:3px;      "></div>
                                                        <div v-if="item.closed_at != null"                                      style="width: 15px; background-color: rgb(86, 86, 86);      height:15px;border-radius:15px;margin-right:12px;position:relative;margin-left:5px;top:3px;      "></div>
                                                    </div>
                                                
                                                    <v-chip v-if="item.label !== null" class="mb-1 mt-1" v-on="on" link :to="'/campain/' + item.id" style="font-weight: bold; background-color: #f3f3f3; border: 1px solid #e5e5e5;">{{item.name}}</v-chip>
                                                </v-row>

                                                <div v-for="professional in professionals" :key="professional">
                                                    <img v-if="item.id_professional == professional.id" :src="professional.logo" :alt="professional.name" style="height: auto;width: 45px;">
                                                    <div v-if="item.id_professional == professional.id" class="mb-1 mt-1" style="font-weight: bold;">{{professional.name}}</div>
                                                </div>
                                            </div>
                                            <v-row v-for="presta in prestas" :key="presta">
                                                <div v-for="typex in types" :key="typex">
                                                    <div v-if="presta.id === item.presta && typex.id === item.type" style="font-weight:normal;font-size: 12px;margin-left: 32px;">{{ presta.name }} ({{ typex.name.toLowerCase().trim() }})</div>
                                                </div>
                                            </v-row>
                                        </v-col>
                                    </v-col>
                                    <!-- CAMPAIN DETAILS --> 
                                    <v-row class="ml-6 mb-0">
                                        <v-col>
                                            <div style="font-size: 12px;margin-left: 10px;">Référence : {{item.uid}}</div>
                                            <div style="font-size: 12px;margin-left: 10px;">Créée le : {{item.created_at}}</div>
                                            <div v-for="tviewer in team" :key="tviewer">
                                                <div v-if="tviewer.id === item.creator" style="font-size: 12px;margin-left: 10px;margin-bottom:5px;">Créé par : {{tviewer.first_name}} {{tviewer.last_name}}</div>
                                            </div>
                                        </v-col>
                                        <v-col>
                                            <div v-for="analyst in analysts_datas" :key="analyst">
                                                <div v-if="analyst.id === item.manager" style="font-size: 12px;margin-left: 10px;font-weight: bold;">Chargé de campagne : {{analyst.first_name}} {{analyst.last_name}}</div>
                                            </div>
                                            <div style="font-size: 12px;margin-left: 10px;">N° de dossier : {{item.affaire_number}}</div>
                                            <div v-for="site in sites" :key="site">
                                                <div v-if="site.id === item.id_park" style="font-size: 12px;margin-left: 10px;">Parc machine associé : {{site.label}}</div>
                                            </div>
                                        </v-col>
                                    </v-row>

                                    <div v-if="item.description != null" class="ml-12" style="font-weight: bold;font-size: 12px;">Informations complémentaires :</div> 
                                    <p v-if="item.description != null" class="ml-12 mb-0" style="font-size: 12px; margin-bottom:15px;">{{ item.description }}</p>

                                    <v-row class="col pa-2 pl-0 ml-10 mt-2 mb-0">
                                        <div v-if="item.trash && access_level == 0">
                                            <v-tooltip bottom dark>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn class="ma-0 col-0" text icon v-on="on" @click="setDataUntrash()" dusk="logout-button">
                                                        <v-icon color="green">mdi-delete-restore</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Réactiver la campagne</span>
                                            </v-tooltip>
                                        </div>
                                    </v-row>
                                </div>
                            </div>
                        </template>

                        <!-- CAMPAIN END STATE -->
                        <template v-slot:item.description="{ item }" style="display:flex;flex-direction:column;width:100%;">
                            <!-- IF OPEN --> 
                            <div v-if="item && item !== 'undefined' && item !== null && typeof item === 'object' && item.closed_at === null">
                                <!-- OPEN OTHERS -->
                                <div  v-if="item.presta !== 5" class="col pa-0 ma-0" style="height:110px;overflow:hidden;position:relative;top: 10px;">
                                    <div class="row row col pa-10 pt-5 pb-5 ma-0 ml-0 mt-1 mb-5" style="
                                        align-items: center;
                                        justify-content: center;
                                        text-align: center;
                                        border-radius: 100px;
                                    ">
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step <= 11 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 12 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 13 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step > 13 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                            <!-- ADMIN VIEW -->
                                            <v-icon v-if="item.step === 11 && access_level <= 100" @click="updateStep(item.id,12)" class="pointer" color="orange" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 12 && access_level <= 100" @click="updateStep(item.id,13)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 13 && access_level <= 100" @click="updateStep(item.id,11)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step > 13 && access_level <= 100" @click="updateStep(item.id,11)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                        </p>
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step >= 13 && item.step <= 21 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 22 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 23 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 13 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step > 23 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                            <!-- ADMIN VIEW -->
                                            <v-icon v-if="item.step >= 13 && item.step <= 21 && access_level <= 100" @click="updateStep(item.id,22)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 22 && access_level <= 100" @click="updateStep(item.id,23)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 23 && access_level <= 100" @click="updateStep(item.id,21)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 13 && access_level <= 100" @click="updateStep(item.id,21)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step > 23 && access_level <= 100" @click="updateStep(item.id,21)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                        </p>
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step >= 23 && item.step <= 31 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 32 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 33 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 23 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step > 33 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                            <!-- ADMIN VIEW -->
                                            <v-icon v-if="item.step >= 23 && item.step <= 31 && access_level <= 100" @click="updateStep(item.id,32)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 32 && access_level <= 100" @click="updateStep(item.id,33)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 33 && access_level <= 100" @click="updateStep(item.id,31)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 23 && access_level <= 100" @click="updateStep(item.id,31)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step > 33 && access_level <= 100" @click="updateStep(item.id,31)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                        </p>
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step >= 33 && item.step <= 41 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 42 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 43 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 33 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <!-- ADMIN VIEW -->
                                            <v-icon v-if="item.step >= 33 && item.step <= 41 && access_level <= 100" @click="updateStep(item.id,42)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 42 && access_level <= 100" @click="updateStep(item.id,43)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 43 && access_level <= 100" @click="updateStep(item.id,41)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 33 && access_level <= 100" @click="updateStep(item.id,41)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                        </p>
                                    </div>
                                    
                                    <div class="col" style="
                                        position: relative;
                                        border: 1px solid #656565;
                                        top: -58px;
                                        width: 70%;
                                        left: 15%;
                                        border-radius: 15px;
                                        height: 2px;
                                        padding: 0px;
                                    "></div>
                                    
                                    <div class="row row col pa-10 pt-0 pb-0 ma-0 ml-0" style="
                                        align-items: center;
                                        justify-content: center;
                                        text-align: center;
                                        position: relative;
                                        top: -57px;
                                    ">
                                        <p class="ml-0 mb-0 col" v-if="item.step === 11 || item.step === 12 || item.step === 13" style="z-index: 10;font-weight: bold;">Préparation</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 11 && item.step != 12 && item.step != 13" style="z-index: 10;">Préparation</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step === 21 || item.step === 22 || item.step === 23" style="z-index: 10;font-weight: bold;">Intervention</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 21 && item.step != 22 && item.step != 23" style="z-index: 10;">Intervention</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step === 31 || item.step === 32 || item.step === 33" style="z-index: 10;font-weight: bold;">Analyses</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 31 && item.step != 32 && item.step != 33" style="z-index: 10;">Analyses</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step === 41 || item.step === 42 || item.step === 43" style="z-index: 10;font-weight: bold;">Rapports</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 41 && item.step != 42 && item.step != 43" style="z-index: 10;">Rapports</p>
                                    </div>                         
                                </div>
                                
                                <!-- OPEN ONLINE -->
                                <div  v-if="item.presta === 5" class="col pa-0 ma-0" style="height:110px;overflow:hidden;position:relative;top: 10px;">
                                    <div class="row row col pa-10 pt-5 pb-5 ma-0 ml-0 mt-1 mb-5" style="
                                        align-items: center;
                                        justify-content: center;
                                        text-align: center;
                                        border-radius: 100px;
                                    ">
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step <= 11 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 12 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 13 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step > 13 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                            <!-- ADMIN VIEW -->
                                            <v-icon v-if="item.step === 11 && access_level <= 100" @click="updateStep(item.id,12)" class="pointer" color="orange" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 12 && access_level <= 100" @click="updateStep(item.id,13)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 13 && access_level <= 100" @click="updateStep(item.id,11)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step > 13 && access_level <= 100" @click="updateStep(item.id,11)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                        </p>
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step >= 13 && item.step <= 21 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 22 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 23 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 13 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step > 23 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                            <!-- ADMIN VIEW -->
                                            <v-icon v-if="item.step >= 13 && item.step <= 21 && access_level <= 100" @click="updateStep(item.id,22)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 22 && access_level <= 100" @click="updateStep(item.id,23)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 23 && access_level <= 100" @click="updateStep(item.id,21)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 13 && access_level <= 100" @click="updateStep(item.id,21)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step > 23 && access_level <= 100" @click="updateStep(item.id,21)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                        </p>
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step >= 23 && item.step <= 31 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 32 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 33 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 23 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step > 33 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                            <!-- ADMIN VIEW -->
                                            <v-icon v-if="item.step >= 23 && item.step <= 31 && access_level <= 100" @click="updateStep(item.id,32)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 32 && access_level <= 100" @click="updateStep(item.id,33)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 33 && access_level <= 100" @click="updateStep(item.id,31)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 23 && access_level <= 100" @click="updateStep(item.id,31)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step > 33 && access_level <= 100" @click="updateStep(item.id,31)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                        </p>
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step >= 33 && item.step <= 41 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 42 && access_level > 100" color="flashGreen" style="font-size: 36px;">mdi-play-network</v-icon>
                                            <v-icon v-if="item.step === 43 && access_level > 100" color="red" style="font-size: 36px;">mdi-close-network-outline</v-icon>
                                            <v-icon v-if="item.step < 33 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <!-- ADMIN VIEW -->
                                            <v-icon v-if="item.step >= 33 && item.step <= 41 && access_level <= 100" @click="updateStep(item.id,42)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 42 && access_level <= 100" @click="updateStep(item.id,43)" class="pointer" color="flashGreen" style="font-size: 36px;">mdi-play-network</v-icon>
                                            <v-icon v-if="item.step === 43 && access_level <= 100" @click="updateStep(item.id,41)" class="pointer" color="red" style="font-size: 36px;">mdi-close-network-outline</v-icon>
                                            <v-icon v-if="item.step < 33 && access_level <= 100" @click="updateStep(item.id,41)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                        </p>
                                    </div>
                                    <div v-if="item.step === 42" class="col" style="
                                        position: relative;
                                        border: 1px solid #00db37;
                                        top: -58px;
                                        width: 70%;
                                        left: 15%;
                                        border-radius: 15px;
                                        height: 2px;
                                        padding: 0px;
                                    "></div>
                                    <div v-if="item.step === 43" class="col" style="
                                        position: relative;
                                        border: 1px solid #d70000;
                                        top: -58px;
                                        width: 70%;
                                        left: 15%;
                                        border-radius: 15px;
                                        height: 2px;
                                        padding: 0px;
                                    "></div>
                                    <div v-if="item.step < 42" class="col" style="
                                        position: relative;
                                        border: 1px solid #656565;
                                        top: -58px;
                                        width: 70%;
                                        left: 15%;
                                        border-radius: 15px;
                                        height: 2px;
                                        padding: 0px;
                                    "></div>
                                    <div class="row row col pa-10 pt-0 pb-0 ma-0 ml-0" style="
                                        align-items: center;
                                        justify-content: center;
                                        text-align: center;
                                        position: relative;
                                        top: -57px;
                                    ">
                                        <p class="ml-0 mb-0 col" v-if="item.step === 11 || item.step === 12 || item.step === 13" style="z-index: 10;font-weight: bold;">Préparation</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 11 && item.step != 12 && item.step != 13" style="z-index: 10;">Préparation</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step === 21 || item.step === 22 || item.step === 23" style="z-index: 10;font-weight: bold;">Instrumentation</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 21 && item.step != 22 && item.step != 23" style="z-index: 10;">Instrumentation</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step === 31 || item.step === 32 || item.step === 33" style="z-index: 10;font-weight: bold;">Configuration</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 31 && item.step != 32 && item.step != 33" style="z-index: 10;">Configuration</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step === 41 || item.step === 42 || item.step === 43" style="z-index: 10;font-weight: bold;">Surveillance</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 41 && item.step != 42 && item.step != 43" style="z-index: 10;">Surveillance</p>
                                    </div>                         
                                </div>
                            </div>

                            <!-- IF CLOSED -->
                            <div v-if="item && item !== 'undefined' && item !== null && typeof item === 'object' && item.closed_at != null">
                                <!-- CLOSED OTHERS -->
                                <div  v-if="item.presta !== 5" class="col pa-0 ma-0" style="height:110px;overflow:hidden;position:relative;top: 10px;">
                                    <div class="row row col pa-10 pt-5 pb-5 ma-0 ml-0 mt-1 mb-5" style="
                                        align-items: center;
                                        justify-content: center;
                                        text-align: center;
                                        border-radius: 100px;
                                    ">
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step <= 11" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 12" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 13 " style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step > 13" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                        </p>
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step === 21" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 22" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 23" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 21"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step > 23" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>                                            </p>
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step === 31" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 32" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 33" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 31"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step > 33" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                        </p>
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step === 41" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 42" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 43" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 41"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                        </p>
                                    </div>
                                    <div class="col" style="
                                        position: relative;
                                        border: 1px solid #656565;
                                        top: -58px;
                                        width: 70%;
                                        left: 15%;
                                        border-radius: 15px;
                                        height: 2px;
                                        padding: 0px;
                                    "></div>
                                    <div class="row row col pa-10 pt-0 pb-0 ma-0 ml-0" style="
                                        align-items: center;
                                        justify-content: center;
                                        text-align: center;
                                        position: relative;
                                        top: -57px;
                                    ">
                                        <p class="ml-0 mb-0 col" v-if="item.step === 11 || item.step === 12 || item.step === 13" style="z-index: 10;font-weight: bold;">Préparation</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 11 && item.step != 12 && item.step != 13" style="z-index: 10;">Préparation</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step === 21 || item.step === 22 || item.step === 23" style="z-index: 10;font-weight: bold;">Intervention</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 21 && item.step != 22 && item.step != 23" style="z-index: 10;">Intervention</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step === 31 || item.step === 32 || item.step === 33" style="z-index: 10;font-weight: bold;">Analyses</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 31 && item.step != 32 && item.step != 33" style="z-index: 10;">Analyses</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step === 41 || item.step === 42 || item.step === 43" style="z-index: 10;font-weight: bold;">Rapports</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 41 && item.step != 42 && item.step != 43" style="z-index: 10;">Rapports</p>
                                    </div>                       
                                </div>

                                <!-- CLOSED ONLINE -->
                                <div  v-if="item.presta === 5" class="col pa-0 ma-0" style="height:110px;overflow:hidden;position:relative;top: 10px;">
                                    <div class="row row col pa-10 pt-5 pb-5 ma-0 ml-0 mt-1 mb-5" style="
                                        align-items: center;
                                        justify-content: center;
                                        text-align: center;
                                        border-radius: 100px;
                                    ">
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step <= 11 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 12 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 13 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step > 13 && access_level > 100" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                            </p>
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step === 21 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 22 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 23 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 21 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step > 23 && access_level > 100" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                        </p>
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step === 31 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 32 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                            <v-icon v-if="item.step === 33 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                            <v-icon v-if="item.step < 31 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step > 33 && access_level > 100" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                        </p>
                                        <p class="ml-0 mb-0 col" style="z-index: 10;">
                                            <!-- CUSTOMER VIEW -->
                                            <v-icon v-if="item.step === 41 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                            <v-icon v-if="item.step === 42 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-play-network</v-icon>
                                            <v-icon v-if="item.step === 43 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-close-network-outline</v-icon>
                                            <v-icon v-if="item.step < 41 && access_level > 100" style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                        </p>
                                    </div>
                                    <div v-if="item.step === 42" class="col" style="
                                        position: relative;
                                        border: 1px solid #00db37;
                                        top: -58px;
                                        width: 70%;
                                        left: 15%;
                                        border-radius: 15px;
                                        height: 2px;
                                        padding: 0px;
                                    "></div>
                                    <div v-if="item.step === 43" class="col" style="
                                        position: relative;
                                        border: 1px solid #d70000;
                                        top: -58px;
                                        width: 70%;
                                        left: 15%;
                                        border-radius: 15px;
                                        height: 2px;
                                        padding: 0px;
                                    "></div>
                                    <div v-if="item.step < 42" class="col" style="
                                        position: relative;
                                        border: 1px solid #656565;
                                        top: -58px;
                                        width: 70%;
                                        left: 15%;
                                        border-radius: 15px;
                                        height: 2px;
                                        padding: 0px;
                                    "></div>
                                    <div class="row row col pa-10 pt-0 pb-0 ma-0 ml-0" style="
                                        align-items: center;
                                        justify-content: center;
                                        text-align: center;
                                        position: relative;
                                        top: -57px;
                                    ">
                                        <p class="ml-0 mb-0 col" v-if="item.step === 11 || item.step === 12 || item.step === 13" style="z-index: 10;font-weight: bold;">Préparation</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 11 && item.step != 12 && item.step != 13" style="z-index: 10;">Préparation</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step === 21 || item.step === 22 || item.step === 23" style="z-index: 10;font-weight: bold;">Instrumentation</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 21 && item.step != 22 && item.step != 23" style="z-index: 10;">Instrumentation</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step === 31 || item.step === 32 || item.step === 33" style="z-index: 10;font-weight: bold;">Configuration</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 31 && item.step != 32 && item.step != 33" style="z-index: 10;">Configuration</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step === 41 || item.step === 42 || item.step === 43" style="z-index: 10;font-weight: bold;">Surveillance</p>
                                        <p class="ml-0 mb-0 col" v-if="item.step != 41 && item.step != 42 && item.step != 43" style="z-index: 10;">Surveillance</p>
                                    </div>                        
                                </div>
                            </div>

                            <v-row style="justify-content:end;margin-top:10px;margin-bottom:10px;padding-right: 100px;">
                                <v-chip v-for="tag in getCampainTags(item)" class="pl-3 pr-3 ma-2" link :key="tag" style="font-size:12px;">
                                    {{ tag }}
                                </v-chip>
                            </v-row>    
                        </template>
                    </v-data-table>

                    <!-- NO CAMPAINS -->
                    <div v-if="campains_displayed.length == 0 && active_special_vue != -1">
                        <div class="ma-0 pa-0"> 
                            <div style="height:150px;border-radius:0px 0px 5px 5px;background-color:white;" class="ma-0 pa-0">
                                <div class="col-0" style="color:black;font-weight:800;display:flex;justify-content:center; height:100%;min-width: 8px;border-radius:5px;" >
                                    <v-col class="pa-0 pt-8 pb-4" style="display:flex;justify-content:center;align-items:center;">
                                        <div style="text-align:center;opacity:0.5;">  
                                            <v-icon color="primary" style="height:36px;font-size:36px;">
                                                mdi-calendar-question
                                            </v-icon>
                                            <br>
                                            <div class="pa-3 pb-0">
                                                Une campagne prévue ?
                                            </div>
                                            <p class="pb-0" style="font-weight:400">
                                                Nous n'avons encore jamais réalisé de campagne pour vous sur ce type de prestation.
                                            </p>
                                        </div>
                                    </v-col>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- NO CAMPAINS TRASH DATA -->
                    <div v-if="trashed_campains.length == 0 && active_special_vue == -1">
                        <div class="ma-0 pa-0"> 
                            <div style="height:150px;border-radius:0px 0px 5px 5px;background-color:white;" class="ma-0 pa-0">
                                <div class="col-0" style="color:black;font-weight:800;display:flex;justify-content:center; height:100%;min-width: 8px;border-radius:5px;" >
                                    <v-col class="pa-0 pt-8 pb-4" style="display:flex;justify-content:center;align-items:center;">
                                        <div style="text-align:center;opacity:0.5;">  
                                            <v-icon color="green" style="height:36px;font-size:36px;">
                                                mdi-delete-outline
                                            </v-icon>
                                            <br>
                                            <p class="pb-0" style="font-weight:400">
                                                Pas de campagne désactivée.
                                            </p>
                                        </div>
                                    </v-col>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- DATA VOID  --> 
                    <!-- ALL DATA VOID (specific categories)-->
                    <div v-if="!campains_available && !deprecated_available && filter_mode != 9" class="rounded row notification_card mb-1 pa-10" style="color: grey;justify-content: center;">
                        <div style="color:grey;text-align: center;">Aucune campagne présente dans cette catégorie.</div>
                    </div>

                    <!--FILTER MAX SIZE MESSAGE ITEM-->
                    <template v-slot:item.message="{ item }">
                        {{ item.message.substring(0, 100) + '...' }}
                    </template>
                </div>

                <!-- Calendrier -->
                <div v-if="calendar_active_special_vue == 1" class="mt-3">
                    <v-row class="fill-height">
                        <v-col>
                            <v-sheet height="64">
                                <v-toolbar flat>
                                    <v-btn outlined class="mr-4" @click="setToday">
                                        Aujourd'hui
                                    </v-btn>

                                    <v-spacer></v-spacer>

                                    <v-btn fab text small @click="prev">
                                        <v-icon small>
                                            mdi-chevron-left
                                        </v-icon>
                                    </v-btn>

                                    <v-toolbar-title v-if="$refs.calendar">
                                        {{ $refs.calendar.title }}
                                    </v-toolbar-title>

                                    <v-btn fab text small @click="next">
                                        <v-icon small>
                                            mdi-chevron-right
                                        </v-icon>
                                    </v-btn>

                                    <v-spacer></v-spacer>

                                    <v-btn @click="toggleColorMode">
                                        {{ useExpertColors ? "Afficher couleurs des Prestas" : "Afficher couleurs des Experts" }}
                                    </v-btn>

                                    <v-menu bottom right>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn outlined v-bind="attrs" v-on="on">
                                                <span>{{ typeToLabel[type] }}</span>
                                                <v-icon right>
                                                    mdi-menu-down
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item @click="type = 'day'">
                                                <v-list-item-title>Jours</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item @click="type = 'week'">
                                                <v-list-item-title>Semaine</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item @click="type = 'month'">
                                                <v-list-item-title>Mois</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </v-toolbar>
                            </v-sheet>
                            <v-sheet height="600">
                                <v-calendar ref="calendar" v-model="focus" color="primary" 
                                            :show-all="true" :events="events" :event-color="getEventColor"
                                            :type="type" @click:event="showEvent" @click:more="viewDay"
                                            @click:date="viewDay" @change="updateRange" :show-week-numbers="true"
                                >
                                    <template v-slot:day-content="{ day, attributes }">
                                        <div class="day-container">
                                            <span>{{ day.day }}</span>
                                            <div v-if="attributes.length">
                                                <div v-for="attr in attributes" :key="attr.key" class="event">
                                                    {{ attr.customData.title }}
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </v-calendar>
                                <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>
                                    <v-card color="grey lighten-4" min-width="350px" flat>
                                        <v-toolbar :color="selectedEvent.color" dark>
                                            <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>

                                            <v-spacer></v-spacer>
                                        </v-toolbar>
                                        <v-card-text>
                                            <span v-html="selectedEvent.experts_name"></span>
                                            <br>
                                            <span v-html="formattedDate"></span>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-btn text color="secondary" @click="selectedOpen = false">
                                                Cancel
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-menu>
                            </v-sheet>

                            <v-row class="pt-4" style="justify-content: space-between;">
                                <v-col class="col-10 pl-12">
                                    <h3>Légende experts</h3>
                                    <v-row class="mt-0">
                                        <div style="width:20px; height:20px; border-radius: 50%; background-color: #ff03037a; margin-right: 10px;"></div>
                                        <p>Eric Quenneville</p>
                                    </v-row>
                                    <v-row class="mt-2">
                                        <div style="width:20px; height:20px; border-radius: 50%; background-color: #027a248a; margin-right: 10px;"></div>
                                        <p>Pascal Ardoin</p>
                                    </v-row>
                                    <v-row class="mt-2">
                                        <div style="width:20px; height:20px; border-radius: 50%; background-color: #7a46028a; margin-right: 10px;"></div>
                                        <p>Amandine Prieur</p>
                                    </v-row>
                                    <v-row class="mt-2">
                                        <div style="width:20px; height:20px; border-radius: 50%; background-color: #0362ff9e; margin-right: 10px;"></div>
                                        <p>Louis Blaise</p>
                                    </v-row>
                                </v-col>

                                <v-col>
                                    <h3>Légende prestations</h3>
                                    <v-row class="mt-2">
                                        <div style="width:20px; height:20px; border-radius: 50%; background-color: #001db4; margin-right: 10px;"></div>
                                        <p>Mesures vibratoires</p>
                                    </v-row>
                                    <v-row class="mt-2">
                                        <div style="width:20px; height:20px; border-radius: 50%; background-color: #c80000; margin-right: 10px;"></div>
                                        <p>Thermographie</p>
                                    </v-row>
                                    <v-row class="mt-2">
                                        <div style="width:20px; height:20px; border-radius: 50%; background-color: #ca8400; margin-right: 10px;"></div>
                                        <p>Mesures ultrasons</p>
                                    </v-row>
                                    <v-row class="mt-2">
                                        <div style="width:20px; height:20px; border-radius: 50%; background-color: #12ca00; margin-right: 10px;"></div>
                                        <p>Equilibrages</p>
                                    </v-row>
                                    <v-row class="mt-2">
                                        <div style="width:20px; height:20px; border-radius: 50%; background-color: #9100ca; margin-right: 10px;"></div>
                                        <p>Expertise avancée</p>
                                    </v-row>
                                    <v-row class="mt-2">
                                        <div style="width:20px; height:20px; border-radius: 50%; background-color: #f0f; margin-right: 10px;"></div>
                                        <p>Surveillance Online</p>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>

                <!-- Calendrier + liste -->
                <div v-if="calendar_active_special_vue == 2">
                    <v-row>
                        <v-col class="col-6" style="background-color:white; margin-left: 12px; margin-top: 28px; border-radius:5px;">
                            <v-row>
                                <v-col>
                                    <v-sheet height="64">
                                        <v-toolbar flat class="d-flex justify-center align-center">
                                            <v-btn fab text small @click="prevCalList">
                                                <v-icon small>
                                                    mdi-chevron-left
                                                </v-icon>
                                            </v-btn>

                                            <v-toolbar-title>
                                                {{ formattedMonth }}
                                            </v-toolbar-title>

                                            <v-btn fab text small @click="nextCalList">
                                                <v-icon small>
                                                    mdi-chevron-right
                                                </v-icon>
                                            </v-btn>
                                        </v-toolbar>
                                    </v-sheet>
                                </v-col>
                            </v-row>

                            <v-col v-for="campain in filteredCampains" :key="campain.id" style="border: 1px solid #0000002b; padding: 10px; border-radius: 10px; margin: 10px 0;">
                                <v-row style="padding:10px">
                                    <div>
                                        <div v-if="campain.presta == 0" style="width:20px; height:20px; border-radius: 50%; background-color: #001db4; margin-right: 10px;"></div>
                                        <div v-if="campain.presta == 1" style="width:20px; height:20px; border-radius: 50%; background-color: #c80000; margin-right: 10px;"></div>
                                        <div v-if="campain.presta == 2" style="width:20px; height:20px; border-radius: 50%; background-color: #ca8400; margin-right: 10px;"></div>
                                        <div v-if="campain.presta == 3" style="width:20px; height:20px; border-radius: 50%; background-color: #12ca00; margin-right: 10px;"></div>
                                        <div v-if="campain.presta == 4" style="width:20px; height:20px; border-radius: 50%; background-color: #9100ca; margin-right: 10px;"></div>
                                        <div v-if="campain.presta == 5" style="width:20px; height:20px; border-radius: 50%; background-color: #f0f; margin-right: 10px;"></div>
                                    </div>

                                    <v-row class="align-center">
                                        <template v-for="professional in professionals" :key="professional.id">
                                            <template v-if="campain.id_professional == professional.id">
                                                <img :src="professional.logo" :alt="professional.name" style="max-height: 35px; max-width: 45px; margin-right: 10px; margin-left:20px;">
                                                <div style="font-weight: 800; margin-right: 5px;">
                                                    {{ professional.name }}
                                                </div>
                                            </template>
                                        </template>

                                        <template v-if="!professionals.some(p => p.id === campain.id_professional)">
                                            <div style="opacity: 0.5; font-style: italic;">Aucun professionnel</div>
                                        </template>

                                        <template v-for="presta in prestas" :key="presta.id">
                                            <div v-if="presta.id == campain.presta">
                                                ({{ presta.accro }})
                                            </div>
                                        </template>
                                    </v-row>
                                        

                                    <div style="margin-right:25px;">{{ campain.name }}</div>
                                </v-row>

                                <v-row style="padding: 0 10px 10px 10px">
                                    <div v-if="getExpert(campain.manager)" style="margin-right: 10%;">
                                        Chargé de campagne : {{ getExpert(campain.manager).name }}
                                    </div>
                                    
                                    <div style="font-style: italic;">Campagne du {{ campain.intervention_date_start }} au {{ campain.intervention_date_end }}</div>
                                </v-row>

                            </v-col>
                        </v-col>

                        <v-col class="col" style="margin-top:18px;">
                            <v-col class="col pa-0 ma-0" style="max-width: 100%;">
                                <!-- Vue -->
                                <v-row class="pt-0" style="margin:0px;height:40px;">
                                    <v-col class="row ml-0 mt-0 pa-0" style="position: relative;left:-1px;">
                                        <!-- Onglets normaux -->
                                        <div v-for="vue in vues" :key="vue.id" class="mr-1">
                                            <div 
                                                v-if="vue.id == active_special_vue" 
                                                class="ma-auto pl-5 pr-5 pa-2"
                                                style="color: black; font-weight: 400; display: flex; justify-content: left; text-shadow: rgba(0, 0, 0, 0.29) 2px 2px 4px; background-color: rgb(255 255 255); border-radius: 5px 5px 0px 0px; border-left: 1px solid rgba(211, 211, 211, 0.43); border-bottom: none; font-size: 14px; height: 40px; align-items: center; font-weight:bold;"
                                            >
                                                <div v-if="active_special_vue == 0 " style="margin-right: 8px;">
                                                    <span v-if="campains_vibratoire_nb > 0" style="font-weight: 800;color: white;background-color: #001047;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_vibratoire_nb }}</span>
                                                    <span v-if="campains_vibratoire_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_vibratoire_nb }}</span>
                                                </div>
                                                <div v-if="active_special_vue == 5 " style="margin-right: 8px;">
                                                    <span v-if="campains_online_nb > 0" style="font-weight: 800;color: white;background-color: #001047;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_online_nb }}</span>
                                                    <span v-if="campains_online_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_online_nb }}</span>
                                                </div>
                                                <div v-if="active_special_vue == 1 " style="margin-right: 8px;">
                                                    <span v-if="campains_thermographie_nb > 0" style="font-weight: 800;color: white;background-color: #001047;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_thermographie_nb }}</span>
                                                    <span v-if="campains_thermographie_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_thermographie_nb }}</span>
                                                </div>
                                                <div v-if="active_special_vue == 2 " style="margin-right: 8px;">
                                                    <span v-if="campains_ultrasons_nb > 0" style="font-weight: 800;color: white;background-color: #001047;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_ultrasons_nb }}</span>
                                                    <span v-if="campains_ultrasons_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_ultrasons_nb }}</span>
                                                </div>
                                                <div v-if="active_special_vue == 3 " style="margin-right: 8px;">
                                                    <span v-if="campains_equilibrage_nb > 0" style="font-weight: 800;color: white;background-color: #001047;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_equilibrage_nb }}</span>
                                                    <span v-if="campains_equilibrage_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_equilibrage_nb }}</span>
                                                </div>
                                                <div v-if="active_special_vue == 4 " style="margin-right: 8px;">
                                                    <span v-if="campains_expertises_nb > 0" style="font-weight: 800;color: white;background-color: #001047;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_expertises_nb }}</span>
                                                    <span v-if="campains_expertises_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_expertises_nb }}</span>
                                                </div>
                                                {{ vue.accro }}
                                            </div>
                                            <div 
                                                v-else 
                                                class="ma-auto pl-5 pr-5 pa-2"
                                                style="color: black; font-weight: 400; display: flex; justify-content: left; text-shadow: rgba(0, 0, 0, 0.29) 2px 2px 4px; background-color: rgb(243 243 243 / 90%); border-radius: 10px 10px 0px 0px; border: 1px solid #dbdce1; border-bottom: none; font-size: 14px; height: 40px; cursor: pointer;"
                                                @click="loadVue(vue.id)"
                                            >
                                                <div v-if="vue.id == 0" style="margin-right: 8px;">
                                                    <span v-if="campains_vibratoire_nb > 0" style="font-weight: 800;color: white;background-color: #939393;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_vibratoire_nb }}</span>
                                                    <span v-if="campains_vibratoire_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_vibratoire_nb }}</span>
                                                </div>
                                                <div v-if="vue.id == 5" style="margin-right: 8px;">
                                                    <span v-if="campains_online_nb > 0" style="font-weight: 800;color: white;background-color: #939393;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_online_nb }}</span>
                                                    <span v-if="campains_online_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_online_nb }}</span>
                                                </div>
                                                <div v-if="vue.id == 1" style="margin-right: 8px;">
                                                    <span v-if="campains_thermographie_nb > 0" style="font-weight: 800;color: white;background-color: #939393;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_thermographie_nb }}</span>
                                                    <span v-if="campains_thermographie_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_thermographie_nb }}</span>
                                                </div>
                                                <div v-if="vue.id == 2" style="margin-right: 8px;">
                                                    <span v-if="campains_ultrasons_nb > 0" style="font-weight: 800;color: white;background-color: #939393;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_ultrasons_nb }}</span>
                                                    <span v-if="campains_ultrasons_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_ultrasons_nb }}</span>
                                                </div>
                                                <div v-if="vue.id == 3" style="margin-right: 8px;">
                                                    <span v-if="campains_equilibrage_nb > 0" style="font-weight: 800;color: white;background-color: #939393;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_equilibrage_nb }}</span>
                                                    <span v-if="campains_equilibrage_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_equilibrage_nb }}</span>
                                                </div>
                                                <div v-if="vue.id == 4" style="margin-right: 8px;">
                                                    <span v-if="campains_expertises_nb > 0" style="font-weight: 800;color: white;background-color: #939393;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_expertises_nb }}</span>
                                                    <span v-if="campains_expertises_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_expertises_nb }}</span>
                                                </div>
                                                {{ vue.accro }}
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-data-table
                                :headers="headers3"
                                :items="filteredCampains"
                                class="elevation-1 datatable-operation"
                                style="border-radius: 5px; overflow: hidden;"
                                :items-per-page="-1"
                                :loading="loading_datatable"
                                :search="search"
                                :sort-by="['id']"
                                :sort-desc="[true]"
                                v-if="filteredCampains.length > 0"
                            >
                                <!-- INFOS CAMPAGNES -->
                                <template v-slot:item.name="{ item }">
                                    <div style="height:100%; padding-left:20px;">
                                        <div v-if="item && item !== 'undefined' && item !== null && typeof item === 'object'">
                                            <v-col class="ma-0 pa-0" style="justify-content:space-between;">
                                                <!-- CAMPAIN NAME -->
                                                <v-col class="mb-0 mt-2 mr-6 pb-1" v-on="on" link :to="'/campain/' + item.id" style="font-weight: bold; cursor:pointer; border-radius:25px;">                                   
                                                    <div style="display:flex; flex-direction:column; gap:5px;">
                                                        <v-chip v-if="item.label !== null" class="mb-1 mt-1" v-on="on" link :to="'/campain/' + item.id" style="font-weight: bold; background-color: #f3f3f3; border: 1px solid #e5e5e5;">
                                                            {{ item.name }}
                                                        </v-chip>

                                                        <!-- Affichage du professionnel en une seule ligne -->
                                                        <div v-if="getProfessional(item.id_professional)" style="display:flex; align-items:center; gap:10px;">
                                                            <img :src="getProfessional(item.id_professional).logo" :alt="getProfessional(item.id_professional).name" style="max-height: 35px; max-width: 45px;">
                                                            <div style="font-weight: bold; font-size:16px;">{{ getProfessional(item.id_professional).name }}</div>
                                                        </div>

                                                        <!-- Affichage du chargé de campagne -->
                                                        <div v-if="getExpert(item.manager)">Chargé de campagne : {{ getExpert(item.manager).name }}</div>
                                                    </div>
                                                </v-col>
                                            </v-col>
                                        </div>
                                    </div>
                                </template>

                                <!-- TAGS -->
                                <template v-slot:item.tags="{ item }">
                                    <v-row style="margin-top:10px;margin-bottom:10px;">
                                        <div v-for="tag in getCampainTags(item)" :key="tag" class="pl-1 pr-1" style="font-size:14px;">
                                            {{ tag }} |
                                        </div>
                                    </v-row>    
                                </template>

                                <!-- CAMPAIN END STATE -->
                                <template v-slot:item.description="{ item }" style="display:flex;flex-direction:column;width:100%;">
                                    <div v-for="step in steps" :key="step">
                                        <div v-if="step.level == item.step && item.closed_at == null" style="color:orange">{{ step.name }}</div>
                                        <div v-if="item.closed_at != null" style="color:green">Clôturée</div>
                                    </div>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    
                </div>

                <!-- MODALS     --> 
                <!-- campain ADD / EDIT MODAL -->
                <v-dialog v-model="dialog_edit" max-width="750px">
                    <v-card>
                        <validation-observer ref="observer" v-slot="{ invalid }">
                            <v-form ref="form">
                                <v-card-title>
                                    <span class="headline">{{ dialogTitle }}</span>
                                </v-card-title>
                                <v-divider></v-divider>

                                <v-card-text>
                                    <v-col>
                                        <!-- CAMPAIN NAME -->
                                        <validation-provider :name="$t('campain.add_name')" rules="required"
                                                                v-slot="{ errors }">
                                            <v-text-field solo
                                                            :label="$t('campain.add_name')"
                                                            v-model="edited_campain.name"
                                                            :prepend-icon="'mdi-label-outline'"
                                                            :error-messages="errors"
                                            />
                                        </validation-provider>

                                        <!-- CAMPAIN PRESTA -->
                                        <validation-provider :name="$t('campain.add_presta')" rules="required"
                                                                v-slot="{ errors }">
                                            <v-select
                                                :items="prestas"
                                                v-model="edited_campain.presta"
                                                :label="$t('campain.add_presta')"
                                                :prepend-icon="'mdi-book'"
                                                :error-messages="errors"
                                                item-value="id"
                                                item-text="name" solo
                                            >
                                            </v-select>
                                        </validation-provider> 

                                        <!-- CAMPAIN TYPE -->
                                        <validation-provider :name="$t('campain.add_type')" rules="required"
                                                                v-slot="{ errors }">
                                            <v-select
                                                :items="types"
                                                v-model="edited_campain.type"
                                                :label="$t('campain.add_type')"
                                                :prepend-icon="'mdi-book'"
                                                :error-messages="errors"
                                                item-value="id"
                                                item-text="name" solo
                                            >
                                            </v-select>
                                        </validation-provider>

                                        <!-- CAMPAIN AFFAIRE NUMBER -->
                                        <validation-provider :name="$t('campain.add_affaire_number')" rules="required"
                                                                v-slot="{ errors }">
                                            <v-text-field solo
                                                            :label="$t('campain.add_affaire_number')"
                                                            v-model="edited_campain.affaire_number"
                                                            :prepend-icon="'mdi-qrcode'"
                                                            :error-messages="errors"
                                            />
                                        </validation-provider>

                                        <!-- CAMPAIN MANAGER -->
                                        <validation-provider :name="$t('campain.add_manager')" rules="required"
                                                                v-slot="{ errors }">
                                            <v-select
                                                :items="analysts"
                                                v-model="edited_campain.manager"
                                                :label="$t('campain.add_manager')"
                                                :prepend-icon="'mdi-account-hard-hat'"
                                                :error-messages="errors"
                                                item-value="id"
                                                item-text="first_name" solo
                                            >
                                            </v-select>
                                        </validation-provider>

                                        <!-- CAMPAIN INTERVENTION DATE -->
                                        <validation-provider :name="$t('campain.add_intervention_date')" rules="required"
                                                                v-slot="{ errors }">
                                            <v-menu v-model="dateMenu" :close-on-content-click="false" transition="scale-transition" offset-y>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        solo
                                                        v-model="formattedInterventionDate"
                                                        :label="$t('campain.add_intervention_date')"
                                                        :prepend-icon="'mdi-calendar'"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        :error-messages="errors"
                                                    />
                                                </template>
                                                <v-date-picker v-model="edited_campain.intervention_date" @input="dateMenu = false"></v-date-picker>
                                            </v-menu>
                                        </validation-provider>

                                        <!-- CAMPAIN PARK -->
                                        <validation-provider :name="$t('campain.add_park')" rules="required"
                                                                v-slot="{ errors }">
                                            <v-select
                                                :items="sites"
                                                v-model="edited_campain.id_park"
                                                :label="$t('campain.add_park')"
                                                :prepend-icon="'mdi-factory'"
                                                :error-messages="errors"
                                                item-value="id"
                                                item-text="label" solo
                                            >
                                            </v-select>
                                        </validation-provider>

                                        <!-- CAMPAIN MACHINES -->
                                        <validation-provider :name="$t('campain.add_machines')" rules="required"
                                                                v-slot="{ errors }">
                                            <v-select
                                                :items="machines"
                                                v-model="edited_campain.machine_list"
                                                :label="$t('campain.add_machines')"
                                                :prepend-icon="'mdi-car-turbocharger'"
                                                :error-messages="errors"
                                                item-value="id"
                                                item-text="label" solo chips clearable multiple
                                            >
                                            <template v-slot:prepend-item>
                                                <v-list-item
                                                    subtitle="Prochainement disponible, la capacité à sélectionner toutes les machines"
                                                    title="A venir :"
                                                    disabled
                                                >
                                                    <template v-slot:prepend>
                                                        <v-avatar color="primary" icon="mdi-food-apple"></v-avatar>
                                                    </template>
                                                </v-list-item>
                                                <v-divider class="mt-2 mb-2"></v-divider>
                                                <!--
                                                <v-list-item
                                                    title="Sélectionner toutes les machines"
                                                    @click="toggle"
                                                >
                                                    <template v-slot:prepend>
                                                    <v-checkbox-btn
                                                        :indeterminate="likesSomeFruit && !likesAllFruit"
                                                        :model-value="likesAllFruit"
                                                    ></v-checkbox-btn>
                                                    </template>
                                                </v-list-item>
                                                -->
                                            </template>

                                            <template slot="selection" slot-scope="data">
                                                <!-- HTML that describe how select should render selected items -->
                                                <v-chip  v-on="on">{{data.item.label}}</v-chip>
                                            </template>
                                            <template slot="item" slot-scope="data">
                                                <!-- HTML that describe how select should render items when the select is open -->
                                                <v-col>
                                                    <div><strong>{{ data.item.label }}</strong></div>
                                                    <div style="font-size:12px;">{{ data.item.building.label }}</div>
                                                </v-col>
                                            </template>
                                            </v-select>
                                        </validation-provider>
                                        
                                        <!-- CAMPAIN DESCRIPTION -->
                                        <validation-provider :name="$t('campain.notes')" rules="required"
                                                                v-slot="{ errors }">
                                            <v-textarea rows="2" solo no-resize
                                                    v-model="edited_campain.description"
                                                    :label="$t('campain.notes')"
                                                    :prepend-icon="'mdi-comment-outline'"
                                                    :error-messages="errors"
                                            />
                                        </validation-provider>

                                        <!-- CAMPAIN TAGS -->
                                        <validation-provider :name="$t('campain.add_tags')" rules="required"
                                                                v-slot="{ errors }">
                                            <v-select
                                                :items="tags"
                                                v-model="edited_campain.tags"
                                                :label="$t('campain.add_tags')"
                                                :prepend-icon="'mdi-tag'"
                                                :error-messages="errors"
                                                item-value="name"
                                                item-text="name" solo chips clearable multiple
                                            >
                                            </v-select>
                                        </validation-provider>
                                    </v-col>
                                </v-card-text>

                                <v-card-actions class="mb-3" style="position:absolute;top:10px;right:10px">
                                    <v-btn @click="dialog_edit = false;" style="color:red;max-width: 30px;padding: 0 0 0 5px;min-width: 10px!important;height: 30px!important;">
                                        <v-icon left>mdi-close</v-icon>
                                    </v-btn>
                                </v-card-actions>

                                <v-card-actions class="pb-5" style="display:flex;justify-content:center">
                                    <v-btn type="submit" color="primary" :disabled="invalid"
                                            @click.prevent="prepareEditCampain()">
                                        <v-icon left>mdi-content-save</v-icon>
                                        {{ $t('global.validation') }}
                                    </v-btn>
                                </v-card-actions>
                            </v-form>
                        </validation-observer>
                    </v-card>
                </v-dialog>

                <!-- CAMPAIN DELETE MODAL -->
                <v-dialog v-model="dialog_delete" max-width="500px">
                    <v-card>
                        <v-card-title>
                            <span class="headline">
                                {{ $t('campain.delete_campain') }}
                            </span>
                        </v-card-title>
                        <v-divider></v-divider>
                        <br/>

                        <v-card-actions>
                            <v-spacer/>
                            <v-btn @click="dialog_delete = false">
                                <v-icon left>mdi-close</v-icon>
                                {{ $t('global.cancel') }}
                            </v-btn>
                            <v-btn color="error" @click="confirmCampainDelete()">
                                <v-icon left>mdi-delete</v-icon>
                                {{ $t('global.delete') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <!--ACTION BUTTONS -->
                <template v-slot:item.action="{ item }">
                    <v-icon class="pointer" @click="setDataTrash(item, 1)" v-if="!campains_trash">
                        mdi-eye-check
                    </v-icon>
                    <v-icon class="pointer" @click="setDataTrash(item, 0)" v-if="campains_trash">
                        mdi-eye-remove
                    </v-icon>
                    <v-icon class="pointer" @click="openDialogShow(item)">
                        mdi-arrow-expand-all
                    </v-icon>
                </template>

                <!-- Modal next update -->
                <v-dialog v-model="dialog_next_update" max-width="500px">
                    <v-card>
                        <v-card-title>
                            <span class="headline">
                                Fonctionnalité bientôt disponible
                            </span>
                        </v-card-title>
                        <v-divider></v-divider>
                        <br/>
                        <p class="pl-4 pr-4 pb-4">
                            Cette fonctionnalité est en cours de développement et sera bientôt disponible.
                            Elle vous permettra entre autre de filter et de rechercher précisément via des mots clés votre cible.
                            Un rapport, une machine, une référence...
                            Le but, vous faire gagner du temps ! 
                        </p>

                        <!-- Bouton pour fermer -->
                        <v-card-actions class="mb-3" style="position:absolute;top:5px;right:2px">
                            <v-btn @click="closeNewUpdateDialog();" style="color:red;max-width: 30px;padding: 0 0 0 5px;min-width: 10px!important;height: 30px!important;">
                                <v-icon left>mdi-close</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>  
    </v-col>
</template>

<script>
import { forEach } from "lodash";
import {CAMPAIN,SUBASSEMBLIES,MACHINES,REPORT,USERS,SITES, PROFESSIONALS} from "../../api";
import moment from "moment";

export default {
    name: "VisionCards",
    data() {
        return {
            filterOptions: [ 
                { text: "Toutes", value: "all" },
                { text: "Clôturé", value: "closed" },
                { text: "Préparation", value: "preparation" },
                { text: "Intervention", value: "intervention" },
                { text: "Analyse", value: "analyse" },
                { text: "Rapport", value: "rapport" },
            ],
            selectedFilters: [],
            campains_vibratoire_nb: 0,
            campains_online_nb: 0,
            campains_thermographie_nb: 0,
            campains_ultrasons_nb: 0,
            campains_equilibrage_nb: 0,
            campains_expertises_nb: 0,

            selectedLevel: null,
            campains_displayed: [],
            active_special_vue: 0,
            calendar_active_special_vue: 0,
            activeVue: 0,
            vuesCalendar:[
                {id:0, label: "mdi-format-list-bulleted-square"},
                {id:1, label: "mdi-calendar-multiselect"},
                {id:2, label: "mdi-tooltip-minus"},
            ],
            vues:[
                {id:0, label: "Vibratoires (Offline)", accro:"VIB (OFF)"},    // 0 et null
                {id:5, label: "Vibratoires (Online)", accro:"VIB (OL)"},      // 5
                {id:1, label: "Thermographies", accro:"TH"},                  // 1
                {id:2, label: "Ultrasons", accro:"US"},                       // 2
                {id:3, label: "Equilibrage", accro:"EQ"},                     // 3
                {id:4, label: "Expertises avancées", accro:"EA"},             // 4
                
            ],
            selectedExperts: [],
            analysts_datas:[
                {id:40,  name:"Eric Quenneville",  color:"#ff03037a", tel:"+33 6 38 38 11 18", email:"eric.quenneville@techview.fr", logo:"https://techview.fr/wp-content/uploads/2024/10/ERIC-QUENNEVILLE-CAT-III-e1725528314226-removebg-preview-2.png"},
                {id:121, name:"Pascal Ardoin",  color:"#027a248a",   tel:"+33 6 74 35 31 13", email:"pascal.ardoin@techview.fr", logo:"https://techview.fr/wp-content/uploads/2024/09/PASCAL-ARDOIN-CAT-II-e1725542858336.png"},
                {id:91,  name:"Amandine Prieur",  color:"#7a46028a", tel:"+33 7 87 58 28 98", email:"amandine.prieur@techview.fr", logo:"https://techview.fr/wp-content/uploads/2024/12/572e2dac-42b9-46f8-a7d5-995f87877850.png"},
                {id:278, name:"Louis Blaise",  color:"#0362ff9e", tel:"+33 6 77 33 08 68", email:"louis.blaise@techview.fr"},
                {id:216, name:"Guillaume Maury",  color:"#00000075", tel:"+33 6 07 46 48 40", email:"guillaume.maury@techview.fr"},
                {id:79,  name:"Sébastien Poudenx",  color:"#00000075", tel:"+33 6 68 42 45 15", email:"sebastien.poudenx@techview.fr"},
            ],
            prestas:[
                {id:0, name:"Mesures vibratoires", color:"#001db4", accro:"VIB"},
                {id:1, name:"Thermographie", color:"#c80000", accro:"TH"},
                {id:2, name:"Mesures ultrasons", color:"#ca8400", accro:"US"},
                {id:3, name:"Equilibrages", color:"#12ca00", accro:"EQ"},
                {id:4, name:"Expertise avancée", color:"#9100ca", accro:"EA"},
                {id:5, name:"Surveillance Online", color:"#f0f", accro:"VIB"},
            ],
            headers: [
                {text: "Informations campagnes", value: 'name', width: '40%', sortable: false},
                {text: "Etat d'avancement", value: 'description', width:'40%'},
            ],
            headers2: [
                {text: "Informations campagnes", value: 'name', width: '30%', sortable: false},
                {text: "Tags", value: 'tags', width: '30%', sortable: false},
                {text: "Etat d'avancement", value: 'description', width:'40%'},
            ],
            headers3: [
                {text: "Informations campagnes", value: 'name', width: '40%', sortable: false},
                {text: "Tags", value: 'tags', width: '40%', sortable: false},
                {text: "Etat d'avancement", value: 'description', width:'20%'},
            ],
            infoToggle: false, // Machine menu package //
            // CAMPAINS ----------------
            raw_campains: [],
            campains: [],
            raw_trashed_campains: [],
            trashed_campains: [],
            view_details: false,
            //-
            campains_trash:false,
            campains_available:false,
            deprecated_length:5,
            deprecated_available:false,
            dialog_next_update: false,
            //-
            active_campains_count:0,
            inactive_campains_count:0,
            global_campains_count:0,
            //-
            menu: false,
            edited_campain: {
                date: null
            },
            
            // GLOBAL ----------------
            loading_datatable:true,
            loading: true,
            access_level: this.$store.state.access_level,
            //-
            search: '',
            filter_mode:9,
            edited_index: null,
            invalid: false,
            //-
            dialog_edit: false,
            dialog_delete: false,
            delete_id: null,
            deleted_index: null,
            
            // ARRAYS ----------------
            selectedFilter: 9,
            data_filters: [
                {
                    level: 9,
                    level_name: "Tout voir",
                    level_color: "#d7d7d752",
                    count:this.global_notifications_count
                },
                {
                    level: 0,
                    level_name: "En préparation",
                    level_color: "#ef780e",
                    count:0
                },
                {
                    level: 1,
                    level_name: "En cours d'intervention",
                    level_color: "#0e4cef82",
                    count:0
                },
                {
                    level: 2,
                    level_name: "En cours d'analyses",
                    level_color: "#0e4cefc9",
                    count:0
                },
                {
                    level: 3,
                    level_name: "Rapports en cours d'édition",
                    level_color: "#0e4cef",
                    count:0
                },
                {
                    level: -1,
                    level_name: "Terminées",
                    level_color: "#565656",
                    count:0
                },                
            ],
            data_filters2: [
                {level: 0, level_name: "En préparation", level_color: "#ef780e", count:0},
                {level: 1, level_name: "En cours d'intervention", level_color: "#0e4cef82", count:0},
                {level: 2, level_name: "En cours d'analyses", level_color: "#0e4cefc9", count:0},
                {level: 3, level_name: "Rapports en cours d'édition", level_color: "#0e4cef", count:0},
                {level: -1, level_name: "Terminées", level_color: "#565656", count:0},                
            ],
            steps: [
                {level: 11, name:"Préparation"},
                {level: 12, name:"Préparation"},
                {level: 13, name:"Préparation"},
                {level: 21, name:"Intervention"},
                {level: 22, name:"Intervention"},
                {level: 23, name:"Intervention"},
                {level: 31, name:"Analyses"},
                {level: 32, name:"Analyses"},
                {level: 33, name:"Analyses"},
                {level: 41, name:"Rapports"},
                {level: 42, name:"Rapports"},
                {level: 43, name:"Rapports"},
            ],
            edited_campain: {
                id: null,
                name: '',
                presta:'',
                type: '',
                affaire_number:'',
                manager: '',
                id_park: null,
                machine_list:[],
                description: '',
                tags:[],                          
            },
            analysts: [],
            team: [],
            machines: [],
            machines_count:0,
            reports_count:0,
            reports_done_count:0,
            sites: [],
            types:[
                {id:0, name:"Ponctuel"},
                {id:1, name:"Annuel"},
                {id:2, name:"Semestriel"},
                {id:3, name:"Quadrmestriel"}, 
                {id:4, name:"Trimestriel"},
                {id:5, name:"Continu"},
            ],
            tags:[
                {id:0, name:"Caractérisation machine"},
                {id:1, name:"Création BDD"},
                {id:2, name:"Etude approfondie"},
                {id:3, name:"Machines outils"},
                {id:4, name:"Machines tournantes"},
                {id:5, name:"Surveillance à distance"},
                {id:6, name:"Trés basse vitesse"},
                {id:7, name:"Historique"},
            ],
            timeProps: {
                format: "24hr"
            },
            textFieldProps: {
                prependInnerIcon: "mdi-calendar",
                solo: true,
                rules: [v => !!v || this.$t('vee-validation.required_date_operation')]
            },

            // CALENDAR
            useExpertColors: false,
            focus: '',
            type: 'month',
            typeToLabel: {
                month: 'Mois',
                week: 'Semaine',
                day: 'Jours',
            },
            selectedEvent: {},
            selectedElement: null,
            selectedOpen: false,
            events: [],
            colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
            names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],

            // CALENDAR + LIST
            currentDate: new Date(),

            focus: new Date().toISOString().substr(0, 10),
            type: "month",
            showCalendar: true,
        }
    },
    computed: {
        dialogTitle() {
            return this.edited_index === null ? this.$t('campain.add') : this.$t('campain.edit');
        },
        selectedExpertsDetails() {
            return this.experts.filter(e => this.selectedExperts.includes(e.id));
        },

        // CALENDAR
        daysInMonth() {
            return new Date(this.currentYear, this.currentMonth + 1, 0).getDate();
        },
        firstDayOfMonth() {
            return new Date(this.currentYear, this.currentMonth, 1).getDay() || 7;
        },
        formattedDate() {
            if (!this.selectedEvent.start || !this.selectedEvent.end) return "";
            
            const options = { day: '2-digit', month: 'long', year: 'numeric' };
            const startDate = new Date(this.selectedEvent.start).toLocaleDateString('fr-FR', options);
            const endDate = new Date(this.selectedEvent.end).toLocaleDateString('fr-FR', options);

            return `Du ${startDate} au ${endDate}`;
        },

        // CALENDAR + LIST
        filteredCampains() {            
            const currentMonth = new Date(this.focus).getMonth() + 1;
            const currentYear = new Date(this.focus).getFullYear();

            const filtered = this.campains.filter((campain) => {
                const startMonth = new Date(campain.intervention_date_start).getMonth() + 1;
                const startYear = new Date(campain.intervention_date_start).getFullYear();
                const endMonth = new Date(campain.intervention_date_end).getMonth() + 1;
                const endYear = new Date(campain.intervention_date_end).getFullYear();

                return (
                    (startYear === currentYear && startMonth === currentMonth) ||
                    (endYear === currentYear && endMonth === currentMonth)
                );
            });

            // Mise à jour des compteurs
            this.campains_vibratoire_nb = filtered.filter(c => c.type === 'vibratoire').length;
            this.campains_online_nb = filtered.filter(c => c.type === 'online').length;
            this.campains_thermographie_nb = filtered.filter(c => c.type === 'thermographie').length;
            this.campains_ultrasons_nb = filtered.filter(c => c.type === 'ultrasons').length;
            this.campains_equilibrage_nb = filtered.filter(c => c.type === 'equilibrage').length;
            this.campains_expertises_nb = filtered.filter(c => c.type === 'expertises').length;

            return filtered;
        },
        formattedMonth() {
            if (!this.focus) return "Chargement...";
            return new Date(this.focus).toLocaleDateString("fr-FR", { month: "long", year: "numeric" });
        },

    },
    created() {
        moment.locale('fr');
        this.initialize();
    },
    methods: {
        // COLLECTING ALL NEEDED DATAS
        initialize() {
            this.loading_datatable = true;
            this.view_details = true;
            this.getData();
            //this.getSideDatas();
            //this.getReports();

        },
        getData() {
            // GETTING CAMPAINS
            this.axios.get(CAMPAIN + '/all?trash=0')
            
            .then((success) => {
                this.campains_vibratoire_nb = 0;
                this.campains_online_nb = 0;
                this.campains_thermographie_nb = 0;
                this.campains_ultrasons_nb = 0;
                this.campains_equilibrage_nb = 0;
                this.campains_expertises_nb = 0;

                success.data.forEach(item => {
                    item.created_at = moment(item.created_at).format("DD/MM/YYYY");
                    item.machine_list = JSON.parse(item.machine_list);

                    if (item.presta == 0 || item.presta == null) {this.campains_vibratoire_nb = this.campains_vibratoire_nb +1};
                    if (item.presta == 5) {this.campains_online_nb = this.campains_online_nb +1};
                    if (item.presta == 1) {this.campains_thermographie_nb = this.campains_thermographie_nb +1};
                    if (item.presta == 2) {this.campains_ultrasons_nb = this.campains_ultrasons_nb +1};
                    if (item.presta == 3) {this.campains_equilibrage_nb = this.campains_equilibrage_nb +1};
                    if (item.presta == 4) {this.campains_expertises_nb = this.campains_expertises_nb +1};
                })
                this.filter_mode = 9;
                this.raw_campains = [];
                this.campains = [];

                if (this.checkDataAvailable(success.data)){
                    this.raw_campains = success.data;
                    this.campains = success.data;
                }

                this.updateCampains();

            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");

            }).finally(() => {
                this.getTrashData();
                //this.loadVue(0);

                this.getAnalysts();
                this.getTeam();
                this.getMachines();
                this.getSites();
                this.getProfessionals();
            })
        },
        updateCampains() {
            let filteredCampains = [...this.raw_campains];

            // Filtrer par experts si un ou plusieurs sont sélectionnés
            if (this.selectedExperts.length > 0) {
                filteredCampains = filteredCampains.filter(item => 
                    this.selectedExperts.includes(item.manager)
                );
            }

            // Filtrer par étapes si une ou plusieurs sont sélectionnées (sauf "all")
            if (this.selectedFilters.length > 0 && !this.selectedFilters.includes("all")) {
                filteredCampains = filteredCampains.filter(item => {
                    const stepCategory = this.getStepCategory(item.step, item.closed_at);
                    return this.selectedFilters.includes(stepCategory);
                });

                // Si "Clôturé" N'EST PAS sélectionné, exclure les clôturés
                if (!this.selectedFilters.includes("closed")) {
                    filteredCampains = filteredCampains.filter(item => !item.closed_at);
                }
            }

            // Si aucun expert et aucune étape ne sont sélectionnés, afficher tout
            if (this.selectedExperts.length === 0 && this.selectedFilters.length === 0) {
                filteredCampains = [...this.raw_campains];
            }

            this.campains = filteredCampains;

            this.updateCounters();
            this.displayCampains(this.active_special_vue);
            this.updateRange({ start: this.focus.start, end: this.focus.end });
        },
        getStepCategory(step, closed_at) {
            if (closed_at) return "closed";
            if ([11, 12, 13].includes(step)) return "preparation";
            if ([21, 22, 23].includes(step)) return "intervention";
            if ([31, 32, 33].includes(step)) return "analyse";
            if ([41, 42, 43].includes(step)) return "rapport";
            return null;
        },
        updateCounters() {
            this.campains_vibratoire_nb = 0;
            this.campains_online_nb = 0;
            this.campains_thermographie_nb = 0;
            this.campains_ultrasons_nb = 0;
            this.campains_equilibrage_nb = 0;
            this.campains_expertises_nb = 0;

            this.campains.forEach(item => {
                if (item.presta == 0 || item.presta == null) { this.campains_vibratoire_nb++; }
                if (item.presta == 5) { this.campains_online_nb = this.campains_online_nb +1; }
                if (item.presta == 1) { this.campains_thermographie_nb = this.campains_thermographie_nb +1; }
                if (item.presta == 2) { this.campains_ultrasons_nb = this.campains_ultrasons_nb +1; }
                if (item.presta == 3) { this.campains_equilibrage_nb = this.campains_equilibrage_nb +1; }
                if (item.presta == 4) { this.campains_expertises_nb = this.campains_expertises_nb +1; }
            });
        },

        getDataVue() {
            // GETTING CAMPAINS
            this.axios.get(CAMPAIN + '?trash=0')
            
            .then((success) => {
                this.campains_vibratoire_nb = 0;
                this.campains_online_nb = 0;
                this.campains_thermographie_nb = 0;
                this.campains_ultrasons_nb = 0;
                this.campains_equilibrage_nb = 0;
                this.campains_expertises_nb = 0;

                success.data.forEach(item => {
                    item.created_at = moment(item.created_at).format("DD/MM/YYYY");
                    item.machine_list = JSON.parse(item.machine_list);

                    if (item.presta == 0 || item.presta == null) {this.campains_vibratoire_nb = this.campains_vibratoire_nb +1};
                    if (item.presta == 5) {this.campains_online_nb = this.campains_online_nb +1};
                    if (item.presta == 1) {this.campains_thermographie_nb = this.campains_thermographie_nb +1};
                    if (item.presta == 2) {this.campains_ultrasons_nb = this.campains_ultrasons_nb +1};
                    if (item.presta == 3) {this.campains_equilibrage_nb = this.campains_equilibrage_nb +1};
                    if (item.presta == 4) {this.campains_expertises_nb = this.campains_expertises_nb +1};
                })
                this.filter_mode = 9;
                this.raw_campains = [];
                this.campains = [];

                if (this.checkDataAvailable(success.data)){
                    this.raw_campains = success.data;
                    this.campains = success.data;
                }
            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");

            }).finally(() => {
                this.getTrashData();
                this.displayCampains(this.active_special_vue);
            })
        },
        getTrashData() {
            // GETTING DEACTIVATED campainS
            this.axios.get(CAMPAIN + '/all?trash=1')

            .then((success) => {
                success.data.forEach(item => {
                    item.created_at = moment(item.created_at).format("DD/MM/YYYY");
                    item.machine_list = JSON.parse(item.machine_list);
                })
                this.filter_mode = 9;
                this.raw_trashed_campains = [];
                this.trashed_campains = [];

                if (this.checkTrashDataAvailable(success.data)){
                    this.raw_trashed_campains = success.data;
                    this.trashed_campains = success.data.slice(0, this.deprecated_length);
                }

            }).catch(() => {
                this.showSnackbar('error', "Trashed data error.");
            
            }).finally(() => {
                this.getDataCount();
            });         
        },

        getAnalysts(){
            this.axios.get(USERS + '/analysts')
            
            .then((success) => {
                this.analysts = [];
                this.analysts = success.data;

            }).catch(() => {
                this.showSnackbar('error', "Analysts datas error.");
            })
        },
        getTeam(){
            this.axios.get(USERS + '/team')
            
            .then((success) => {
                this.team = [];
                this.team = success.data;

            }).catch(() => {
                this.showSnackbar('error', "Team datas error.");
            })
        },
        getMachines() {
            this.axios.get(MACHINES + '/resume')
            
            .then((success) => {
                this.machines = [];
                this.machines = success.data;
                this.machines_count = this.machines.length;

            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");
            })
        },
        getSites() {
            this.axios.get(SITES + '/resume')
            
            .then((success) => {
                this.sites = [];
                this.sites = success.data;

            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");
            })
        },
        getDataCount() {
            // Reset all counts
            this.data_filters2.forEach(data => {data.count = 0});
            this.active_campains_count =0;
            this.inactive_campains_count =0;
            this.global_campains_count =0;
            
            // Get all last counts
            this.campains.forEach(item => {  
                if(item.closed_at === null){
                    this.active_campains_count = this.active_campains_count +1;
                }
                
                this.data_filters2.forEach(filter => { 
                    if(item.state == filter.level){
                        filter.count = filter.count +1;
                    }
                });
            });

            this.inactive_campains_count = this.trashed_campains.length;
            this.global_campains_count = this.active_campains_count + this.inactive_campains_count;

            // FREEUP LOADING STEP
            this.loading_datatable = false
        },
        getCampainTags(campain) {
            if (campain.tags != "") {
                return JSON.parse(campain.tags);
            }
        },
        getReports() {
            this.axios.get(
                SUBASSEMBLIES
            ).then((success) => {
                this.subassemblies = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'))
            }).finally(() => {
                this.loading = false
            });
        },
        getProfessionals() {
            // GETTING COMMON CUSTOMERS
            this.axios.get(PROFESSIONALS + '?trash=0')
            .then((success) => {
                success.data.forEach(item => {
                    item.created_at = moment(item.created_at).fromNow();
                })

                this.professionals = [];
                this.contracts = [];
                this.professionals = success.data;

                if(typeof this.professionals[0] === 'undefined') {                    
                    this.professionals_available = false;
                
                }else {
                    this.professionals_available = true;
                }

            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            });

            // GETTING DEACTIVATED CUSTOMERS
            this.axios.get(PROFESSIONALS + '?trash=1' )
            .then((success) => {
                success.data.forEach(item => {
                    item.created_at = moment(item.created_at).fromNow();
                })
                
                this.professionals_deactivated = [];
                this.professionals_deactivated = success.data.slice(0, this.deprecated_length);

                if(typeof this.professionals_deactivated[0] === 'undefined') {
                    this.deprecated_available = false;
                }else {
                    this.deprecated_available = true;
                }

            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            
            }).finally(() => {
                this.loading_datatable = false
            });         
        },

        // DISPLAY DATA MANAGEMENT
        checkDataAvailable(datas){
            if(typeof datas[0] === 'undefined') {
                this.campains_available = false;
                return false;
            }else {
                this.campains_available = true;
                return true;
            }
        },
        checkTrashDataAvailable(datas){
            if(typeof datas[0] === 'undefined') {                    
                this.deprecated_available = false;
                return false;
            }else {
                this.deprecated_available = true;
                return true;
            }
        },
        setDataFiltered (filter) {
            this.loading_datatable = true;
            this.filter_mode = filter;
            this.deprecated_length = 5;
            this.dataTreatment();
            this.dataTrashTreatment();
            this.loading_datatable = false;
        },
        dataTreatment(){
            if(this.checkDataAvailable(this.raw_campains)) {                    
                this.campains = [];

                if(this.filter_mode === 9){
                    this.campains = this.raw_campains;

                }else{
                    this.raw_campains.forEach(campain => {
                        if (campain.state == this.filter_mode){
                            this.campains.push(campain);
                        }
                    })
                }
                
                // Then check result to si if there is somehting left in chosen datas
                this.checkDataAvailable(this.campains);
            }
        },
        dataTrashTreatment(){
            if(this.checkTrashDataAvailable(this.raw_trashed_campains)) {                    
                this.trashed_campains = [];

                if(this.filter_mode === 9){
                    this.trashed_campains = this.raw_trashed_campains;

                }else{
                    this.raw_trashed_campains.forEach(campain => {
                        if (campain.state == this.filter_mode){
                            this.trashed_campains.push(campain);
                        }
                    })
                }

                //Get deprecated list by max length
                this.trashed_campains.slice(0, this.deprecated_length);
                
                // Then check result to si if there is somehting left in chosen datas
                this.checkTrashDataAvailable(this.trashed_campains);
            }
        },
        viewMore() {
            this.deprecated_length = this.deprecated_length + 10;
            this.dataTrashTreatment();
        },
        viewDetails(){
            this.view_details = !this.view_details;
        },

        // POST REQUESTS PART
        prepareEditCampain() {
            if (this.edited_index !== null) {
                this.updateCampain();
            } else {
                this.addCampain();
            }
            this.closeDialog();
        },
        setDataTrash(data, state) {
            // Set notification seen
            if (state === 1) {
                this.axios.patch(CAMPAIN + '/trash/' + data.id)
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.getData();
                    this.dialog_show = false;
                })
            
            // Set notification unseen
            } if (state === 0) {
                this.axios.patch(CAMPAIN + '/untrash/' + data.id)
            
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.getData();
                    this.dialog_show = false;
                })
            }
        },
        setDataUntrash(data, trash) {
            // Set notification seen
            if (trash === 1) {
                this.axios.patch(CAMPAIN + '/untrash/' + data.id)
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.getData();
                    this.dialog_show = false;
                })
            }
        },
        updateCampain() {
            this.axios.patch(CAMPAIN + '/' + this.edited_campain.id,
                this.edited_campain
            ).then((success) => {
                Object.assign(this.campains[this.edited_index], success.data);
                this.edited_index = null;
                this.showSnackbar('success', this.$t('global.success_to_edit'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            }).finally(() => {
                this.getData();
                this.loading_datatable = true;
                this.closeDialog();
            })
        },
        updateStep(id, step_id){
            this.axios.patch(CAMPAIN + '/step/' + id + "/" + step_id
            ).then((success) => {
                this.getDataVue();
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            })
        },
        addCampain() {
            let formData = new FormData()
            formData.append('name', this.edited_campain.name);
            formData.append('presta', this.edited_campain.presta);
            formData.append('type', this.edited_campain.type);
            formData.append('affaire_number', this.edited_campain.affaire_number);
            formData.append('manager', this.edited_campain.manager);
            formData.append('id_park', this.edited_campain.id_park);
            formData.append('machine_list', [JSON.stringify(this.edited_campain.machine_list)]);
            formData.append('description', this.edited_campain.description);
            formData.append('tags', [JSON.stringify(this.edited_campain.tags)]);

            this.axios.post(CAMPAIN, formData)

            .then((success) => {
                this.campains.push(success.data);
                this.showSnackbar('success', this.$t('global.success_to_add'));

            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_add'));

            }).finally(() => {
                this.getData();
                this.loading_datatable = true;
                this.closeDialog();
            });
        },
        confirmCampainDelete() {
            this.axios.delete(
                CAMPAIN + '/delete/' + this.delete_id
            ).then(() => {
                this.campains.splice(this.deleted_index, 1);
                this.showSnackbar('success', this.$t('global.success_to_delete'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_delete'));
            }).finally(() => {
                this.loading_datatable = true;
                this.getData();
                this.dialog_delete = false;
            });
        },
        setClosed(id){
            this.axios.patch(CAMPAIN + '/close/' + id)
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.getData();
                    this.dialog_show = false;
                })
        },
        setOpened(id){
            this.axios.patch(CAMPAIN + '/open/' + id)
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.getData();
                    this.dialog_show = false;
                })
        },

        // MODALS
        openDialogAddCampain() {
            if (this.edited_index !== null) {
                this.$refs.form.reset();
                this.$refs.observer.reset();
                this.edited_index = null;
            }

            this.dialog_edit = true;
        },
        openDialogUpdateCampain(campain) {
            this.edited_index = this.campains.indexOf(campain);
            this.edited_campain = Object.assign({}, campain);
            this.dialog_edit = true;
        },
        openDialogDeleteCampain(campain) {
            this.deleted_index = this.campains.indexOf(campain);
            this.delete_id = campain.id;
            this.dialog_delete = true;
        },
        openDialogShow(){
            console.log("open dialog show requested");
        },
        closeDialog() {
            this.$refs.form.reset();
            this.$refs.observer.reset();
            this.dialog_edit = false;
        },
        dialogNextUpdate() {
            if (this.dialog_next_update){
                this.dialog_next_update = false;
            }else{
                this.dialog_next_update = true;
            }
        },
        closeNewUpdateDialog(){
            this.setCookieVolet();
            this.dialog_new_update = false;
        },
        infoToggler(){
            this.infoToggle = !this.infoToggle;
        },
        goToDetail(item) {
            if (item && item.id) {
            this.$router.push(`/campain/${item.id}`);
            }
        },


        // ONGLETS
        loadVueCalendar(id) {
            this.calendar_active_special_vue = id;
        },
        loadVue(id) {
            this.active_special_vue = id;
            this.displayCampains(id);
        },
        displayCampains(id) {
            this.loading_datatable = true;
            this.campains_displayed = [];

            this.campains.forEach(campain => {
                if (campain.presta === id) this.campains_displayed.push(campain);
            });
            this.loading_datatable = false;
        },

        // CALENDAR
        viewDay ({ date }) {
            this.focus = date
            this.type = 'day'
        },
        getEventColor (event) {
            return event.color
        },
        setToday () {
            this.focus = ''
        },
        prev () {
            this.$refs.calendar.prev()
        },
        next () {
            this.$refs.calendar.next()
        },
        showEvent ({ nativeEvent, event }) {
            const open = () => {
                this.selectedEvent = event
                this.selectedElement = nativeEvent.target
                requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
            }

            if (this.selectedOpen) {
                this.selectedOpen = false
                requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
                open()
            }

            nativeEvent.stopPropagation()
        },

        updateRange({ start, end }) {
            const events = [];

            this.campains.forEach(campain => {
                const min = new Date(`${campain.intervention_date_start}T00:00:00`);
                const max = new Date(`${campain.intervention_date_end}T23:59:59`);

                let presta_color = "";
                let expert_color = "";
                let presta_professional = "";
                let experts_name = "";

                this.prestas.forEach(presta => {
                    if (campain.presta == presta.id) {
                        presta_color = presta.color;
                    }
                });

                this.professionals.forEach(professional => {
                    if (campain.id_professional == professional.id) {
                        presta_professional = professional.name;
                    }
                });

                this.analysts_datas.forEach(expert => {
                    if (campain.manager == expert.id) {
                        experts_name = expert.name;
                        expert_color = expert.color;
                    }
                });

                const eventColor = this.useExpertColors ? expert_color : presta_color;

                events.push({
                    name: presta_professional + " - " + campain.name,
                    start: min,
                    end: max,
                    color: eventColor,
                    timed: true,
                    expert: experts_name,
                    description: campain.description,
                    affaire_number: campain.affaire_number,
                    state: campain.state,
                    step: campain.step,
                    type: campain.type,
                });
            });

            this.events = events;
        },
        rnd (a, b) {
            return Math.floor((b - a + 1) * Math.random()) + a
        },
        toggleColorMode() {
            this.useExpertColors = !this.useExpertColors;
            this.updateRange({ start: this.currentStart, end: this.currentEnd }); // Réactualise les événements
        },

        // CALENDAR + LIST
        prevCalList() {
            let date = new Date(this.focus);
            date.setMonth(date.getMonth() - 1);
            this.focus = date.toISOString().substr(0, 10);
        },
        nextCalList() {
            let date = new Date(this.focus);
            date.setMonth(date.getMonth() + 1);
            this.focus = date.toISOString().substr(0, 10);
        },
        getProfessional(professionalId) {
            return this.professionals.find(pro => pro.id === professionalId) || null;
        },
        getExpert(managerId) {
            return this.analysts_datas.find(expert => expert.id === managerId) || null;
        },
    },
    watch: {
        selectedExperts(newValue) {
            this.updateCampains();
        },
        selectedFilters(newValue) {
            this.updateCampains();
        },
        filteredCampains: {
            handler() {
                this.updateCounters();
            },
            deep: true
        },
    },
}
</script>

<style scoped>
.calendar {
  max-width: 300px;
  margin: auto;
  text-align: center;
  font-family: Arial, sans-serif;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.weekdays, .days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}
.weekdays span, .days span {
  padding: 10px;
  background: #f4f4f4;
  border-radius: 4px;
}
.today {
  background: #ff5722;
  color: white;
}
.empty {
  visibility: hidden;
}
button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}
</style>