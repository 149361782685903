<template>
    <v-card class="rounded-lg" elevation="2" outlined min-height="450px">
        <v-card-title class="justify-center">
            {{ $t('operation.title') }}
        </v-card-title>

        <v-divider/>

        <v-data-table
            :headers="headers"
            :items="operations"
            class="elevation-1 datatable-operation"
            :items-per-page="5"
            :loading="loading_datatable"
            :search="search"
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-layout class="justify-start mt-7">
                        <v-btn color="primary" class="white--text mr-3" height="40px"
                               @click="$refs.modalEditOperation.openDialogAddOperation()">
                            {{ $t('operation.add_operation') }}
                        </v-btn>

                        <v-btn @click="filterOperations()" height="40px">
                            {{ $t('operation.operation_passed') }}
                            <v-switch v-model="upcoming_operation" color="primary" disabled class="mt-5 pr-2 pl-2"/>
                            {{ $t('operation.operation_upcoming') }}
                        </v-btn>

                        <v-select
                            class="pl-2 pr-2 max-width-500"
                            :items="operation_types"
                            :label="$t('operation.select_type')"
                            v-model="operation_type_selected"
                            item-text="value"
                            item-value="id"
                            @change="getOperations()"
                            solo chips multiple deletable-chips dense
                        />
                    </v-layout>

                    <v-layout class="justify-end mt-7 max-width-300">
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            :label="$t('global.search')"
                        ></v-text-field>
                    </v-layout>

                    <modal-edit-operation ref="modalEditOperation"
                                          v-on:showSnackbarForModalEditOperation="showSnackbarForModalEditOperation"/>

                    <modal-display-operation-timeline ref="modalDisplayOperationTimeline"/>

                    <modal-add-operation-timeline ref="modalAddOperationTimeline"
                                                  v-on:showSnackbarForModalAddOperationTimeline="showSnackbarForModalAddOperationTimeline"/>

                    <modal-add-reminder ref="modalAddReminder"
                                        v-on:showSnackbarForModalAddReminder="showSnackbarForModalAddReminder"/>

                    <modal-close-operation ref="modalCloseOperation"
                                           v-on:showSnackbarForModalCloseOperation="showSnackbarForModalCloseOperation"/>

                    <v-snackbar
                        v-model="snackbar"
                        :color="snackbar_type">
                        {{ snackbar_text }}
                        <template v-slot:action="{ attrs }">
                            <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                        </template>
                    </v-snackbar>
                </v-toolbar>

                <v-layout class="justify-end">
                    <v-btn color="primary" class="mr-3" @click="downloadCsvSubassemblyOperations()">
                        <v-progress-circular
                            v-if="loading_csv_file" class="mr-2"
                            color="white" size="20" width="2"
                        />
                        <v-icon v-else left>mdi-download-box-outline</v-icon>
                        {{ $t('global.download') }}
                    </v-btn>
                </v-layout>

            </template>

            <template v-slot:item.metric="{ item }">
                <template v-if="item.metric !== null">
                    <template v-if="item.metric.virtual_device !== null">
                        {{ item.metric.virtual_device }}
                    </template>
                </template>
            </template>

            <template v-slot:item.alert_level="{ item }">
                <v-icon :color="assetColor(item)" large v-if="(item.alert_level !== '0')">
                    mdi-alert
                </v-icon>
            </template>

            <template v-slot:item.intervener="{ item }">
                {{
                    item.intervener.last_name + " " + item.intervener.first_name === 'System Alerting' ? 'Techview' :
                        item.intervener.last_name + " " + item.intervener.first_name
                }}
            </template>

            <template v-slot:item.state="{ item }">
                {{ getStateOperationLabel(item.state) }}
            </template>

            <template v-slot:item.tags="{ item }">
                <v-chip-group>
                    <v-chip v-if="item.tags.length === 0" color="grey">{{ $t('global.no_tags_associated') }}</v-chip>
                    <v-chip v-for="tag in item.tags" class="pl-3 pr-3" link :key="tag.id">
                        {{ tag.label }}
                    </v-chip>
                </v-chip-group>
            </template>

            <template v-slot:item.action="{ item }">
                <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="pointer"
                                v-bind="attrs" v-on="on"
                                @click="$refs.modalDisplayOperationTimeline.openDialogViewOperationTimelines(item.id, item.type_operation ? item.type_operation.value : null)"
                        >
                            mdi-eye
                        </v-icon>
                    </template>
                    <span>{{ $t('operation.operation_history') }}</span>
                </v-tooltip>
                <template v-if="display_btn_operation_not_end">
                    <v-tooltip top color="primary">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon class="pointer"
                                    v-bind="attrs" v-on="on"
                                    @click="$refs.modalEditOperation.openDialogUpdateOperation(item)"
                            >
                                mdi-pencil
                            </v-icon>
                        </template>
                        <span>{{ $t('operation.update_operation') }}</span>
                    </v-tooltip>
                    <v-tooltip top color="primary">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon class="pointer"
                                    v-bind="attrs" v-on="on"
                                    @click="$refs.modalAddOperationTimeline.openDialogAddOperationTimeline(item.id, 3)"
                            >
                                mdi-comment-plus-outline
                            </v-icon>
                        </template>
                        <span>{{ $t('operation.add_message') }}</span>
                    </v-tooltip>
                    <v-tooltip top color="primary">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon class="pointer"
                                    v-bind="attrs" v-on="on"
                                    @click="$refs.modalAddOperationTimeline.openDialogAddOperationTimeline(item.id, 4)"
                            >
                                mdi-account-cog-outline
                            </v-icon>
                        </template>
                        <span>{{ $t('operation.expert_demand') }}</span>
                    </v-tooltip>
                    <v-tooltip top color="primary">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon class="pointer"
                                    v-bind="attrs" v-on="on"
                                    @click="$refs.modalAddReminder.openDialogAddReminder(item.id, item.date_reminder)"
                            >
                                mdi-calendar-plus
                            </v-icon>
                        </template>
                        <span>{{ $t('operation.plan_reminder') }}</span>
                    </v-tooltip>
                    <v-tooltip top color="primary">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon class="pointer"
                                    v-bind="attrs" v-on="on"
                                    @click="$refs.modalCloseOperation.openDialogCloseOperation(item.id)"
                            >
                                mdi-archive-arrow-down
                            </v-icon>
                        </template>
                        <span>{{ $t('operation.close_operation') }}</span>
                    </v-tooltip>
                </template>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
import {DOWNLOAD_CSV_SUBASSEMBLY_OPERATIONS, SUBASSEMBLIES, REFERENTIAL_GLOBALS} from "../../../../api";
import moment from 'moment';
import ModalEditOperation from "./ModalEditOperation";
import ModalDisplayOperationTimeline from "./ModalDisplayOperationTimeline";
import ModalAddOperationTimeline from "./ModalAddOperationTimeline";
import ModalCloseOperation from "./ModalCloseOperation";
import ModalAddReminder from "./ModalAddReminder";

export default {
    name: "DatatableSubassemblyOperations",
    components: {
        ModalEditOperation,
        ModalDisplayOperationTimeline,
        ModalAddOperationTimeline,
        ModalCloseOperation,
        ModalAddReminder
    },
    data() {
        return {
            operations: [],
            operation_type: null,
            headers: [
                {text: this.$t('global.name'), value: 'label'},
                {text: this.$t('global.virtual_device_name'), value: 'metric.virtual_device.label'},
                {text: this.$t('global.alert_level'), value: 'alert_level'},
                {text: this.$t('operation.alert_date'), value: 'date_operation'},
                {text: this.$t('operation.closing_date'), value: 'date_operation_realised'},
                {text: this.$t('operation.intervener'), value: 'intervener'},
                {text: this.$t('operation.type_operation'), value: 'type_operation.value'},
                {text: this.$t('operation.part_concerned'), value: 'part_concerned.value'},
                {text: this.$t('global.state'), value: 'state'},
                this.$store.state.access_level <= 100 ? {
                    text: this.$t('global.tags'),
                    value: 'tags'
                } : {align: ' d-none'},
                {text: this.$t('global.last_comment'), value: 'comment'},
                {text: this.$t('global.actions'), value: 'action'}
            ],
            loading_datatable: true,
            search: '',
            upcoming_operation: true,
            display_btn_operation_not_end: true,

            operation_type_selected: [],
            operation_types: [],

            loading_csv_file: false,
        }
    },
    created() {
        this.getOperations();
        this.getOperationTypes();
    },
    methods: {
        getOperations() {
            this.loading_datatable = true;

            this.axios.get(
                SUBASSEMBLIES + "/" + this.$route.params.id + "/operations?upcoming_operation=" + this.upcoming_operation + "&types=" + this.operation_type_selected
            ).then((success) => {
                success.data.forEach(operation => {
                    operation.date_operation = moment(operation.date_operation).format('DD/MM/YYYY H:mm');
                    if (operation.date_operation_realised) {
                        operation.date_operation_realised = moment(operation.date_operation_realised).format('DD/MM/YYYY H:mm');
                    }
                });

                this.display_btn_operation_not_end = this.upcoming_operation;
                this.operations = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            }).finally(() => {
                this.loading_datatable = false
            });
        },

        getOperationTypes() {
            this.axios.get(
                REFERENTIAL_GLOBALS + '?type=type_operation'
            ).then((success) => {
                this.operation_types = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            });
        },

        filterOperations() {
            this.loading_datatable = true;
            this.upcoming_operation = !this.upcoming_operation;
            this.getOperations();
        },

        downloadCsvSubassemblyOperations() {
            this.loading_csv_file = true;

            this.axios({
                url: DOWNLOAD_CSV_SUBASSEMBLY_OPERATIONS + this.$route.params.id + "/operations?upcoming_operation=" + this.upcoming_operation,
                method: 'GET',
                responseType: 'blob',
            }).then((success) => {
                this.downloadCsvFile(success.data, "_subassembly_" + this.$route.params.id + "_operations.csv");
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_download'))
            }).finally(() => {
                this.loading_csv_file = false;
            });
        },

        showSnackbarForModalEditOperation(item) {
            this.showSnackbar(item.type, item.text);
            this.$emit('updateSubassemblyDevices')
            this.getOperations();
        },

        showSnackbarForModalAddOperationTimeline(item) {
            this.showSnackbar(item.type, item.text);
            this.getOperations();
        },

        showSnackbarForModalCloseOperation(item) {
            this.showSnackbar(item.type, item.text);
            this.$emit('updateSubassemblyDevices');
            this.getOperations();
        },

        showSnackbarForModalAddReminder(item) {
            this.showSnackbar(item.type, item.text);
            this.getOperations();
        }
    }
}
</script>

<style scoped>
.toolbar-container {
    margin-top: 2px;
}

.datatable-operation {
    min-height: 415px;
}

.max-width-300 {
    max-width: 300px !important;
}

.max-width-500 {
    max-width: 510px !important;
}
</style>
