<template>
    <div id="machine-specifications">
        <v-card elevation="2" outlined max-height="350px" height="100%" style="box-shadow: none!important;border:none!important;background-color: #e8edff!important;">
            <v-card-text class="row col">
                <v-col class="mt-2 pr-8">
                    <div @click="$router.push({ name: 'site', params: {id: machine.building.site.id} })" style="cursor: pointer;">
                        <strong style="margin-right: 4px;">Site :</strong> {{ machine.building.site.label }} 
                        <v-icon @click="$router.push({ name: 'site', params: {id: machine.building.site.id} })" color="primary" small>mdi-link-variant</v-icon>
                    </div>
                    <div @click="$router.push({ name: 'building', params: {id: machine.building.id} })" style="cursor: pointer;">
                        <strong style="margin-right: 4px;">Regroupement :</strong> {{ machine.building.label }} 
                        <v-icon @click="$router.push({ name: 'building', params: {id: machine.building.id} })" color="primary" small>mdi-link-variant</v-icon>
                    </div>
                    <div><strong style="margin-right: 4px;">Fonction :</strong> {{ machine.functionality }}</div>
                    <v-textarea
                        v-model="machine.note"
                        rows="2" outlined readonly no-resize 
                        :label="$t('global.notes')"
                        :prepend-inner-icon="'mdi-comment-outline'" class="mt-4"
                        style="background-color: #e8edff;color:white!important;border-color:white!important;font-size: 12px;"
                    />
                    <v-card-title class="d-flex justify-start pt-1 pl-0 pb-0" style="position: relative;top:-20px;">
                        <v-btn elevation="3" style="background-color: #001047e6" small @click="openDialogUpdateMachine()">
                            <v-icon color="white" small>mdi-tooltip-edit</v-icon>
                        </v-btn>
                    </v-card-title>

                </v-col>
                <v-col class="mt-2 col-4">
                    <div>Référence Techview : {{ machine.id }} - <strong> {{ machine.uid }} </strong> <v-icon  @click="copyValue(machine.uid)" color="primary" small>mdi-content-copy</v-icon></div>
                    <div>Chargé d'affaire : (A venir) <v-icon class="ml-1" @click="$router.push({ name: 'site', params: {id: machine.building.site.id} })" color="primary" small>mdi-link-variant</v-icon></div>
                    <div>Date de création : {{ machine.created_at }} </div>
                    <div>Dernière modification : {{ machine.updated_at }} </div>
                    <div>Dernier rapport : (A venir) <v-icon class="ml-1" @click="$router.push({ name: 'site', params: {id: machine.building.site.id} })" color="primary" small>mdi-link-variant</v-icon></div>
                    <div>Dernière alerte : (A venir) <v-icon class="ml-1" @click="$router.push({ name: 'site', params: {id: machine.building.site.id} })" color="primary" small>mdi-link-variant</v-icon></div>
                    <div class="mt-2"></div>
                    <div>Niveau d'alerte en cours : {{ machine.alert_level }} </div>
                    <div>Nombre de sous-ensembles : {{ nbSubs }} </div>
                    <div>Nombre de capteurs : {{ nbSensors }} </div>
                    <div>Types de capteurs : (A venir) </div>
                </v-col>
            </v-card-text>
        </v-card>

        <v-dialog v-model="dialog_edit" max-width="500px">
            <v-card>
                <validation-observer ref="observer" v-slot="{ invalid }">
                    <v-form ref="form">
                        <v-card-title>
                            <span class="headline">{{ $t('machines.edit_machine') }}</span>
                        </v-card-title>
                        <v-divider></v-divider>

                        <v-card-text>
                            <validation-provider :name="$t('machines.building')" rules="required"
                                                 v-slot="{ errors }">
                                <v-select
                                    :items="buildings"
                                    v-model="edited_machine.building_id"
                                    :label="$t('machines.building')"
                                    :prepend-icon="'mdi-office-building-outline'"
                                    :error-messages="errors"
                                    item-value="id"
                                    item-text="label" solo
                                >
                                </v-select>
                            </validation-provider>

                            <validation-provider :name="$t('global.name')"
                                                 rules="required"
                                                 v-slot="{ errors }">
                                <v-text-field solo
                                              :label="$t('global.name')"
                                              v-model="edited_machine.label"
                                              :prepend-icon="'mdi-bookmark-check-outline'"
                                              :error-messages="errors"
                                />
                            </validation-provider>

                            <validation-provider :name="$t('machines.utilisation')"
                                                 rules="required"
                                                 v-slot="{ errors }">
                                <v-text-field solo
                                              :label="$t('machines.utilisation')"
                                              v-model="edited_machine.functionality"
                                              :prepend-icon="'mdi-function'"
                                              :error-messages="errors"
                                />
                            </validation-provider>

                            <v-textarea
                                v-model="edited_machine.note"
                                rows="2" solo no-resize
                                :label="$t('global.notes')"
                                :prepend-icon="'mdi-comment-outline'"
                            />
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer/>
                            <v-btn @click="dialog_edit = false">
                                <v-icon left>mdi-close</v-icon>
                                {{ $t('global.close') }}
                            </v-btn>
                            <v-btn type="submit" color="primary" :disabled="invalid"
                                   @click.prevent="updateMachineDetails()">
                                <v-icon left>mdi-content-save</v-icon>
                                {{ $t('global.validation') }}
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </validation-observer>
            </v-card>
        </v-dialog>

        <v-snackbar
            v-model="snackbar"
            :color="snackbar_type">
            {{ snackbar_text }}
            <template v-slot:action="{ attrs }">
                <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
            </template>
        </v-snackbar>
    </div>
</template>

<script>

import {MACHINES, BUILDINGS} from "../../../api";
import moment from "moment";

export default {
    name: "MachineSpecifications",
    data() {
        return {
            buildings: [],
            machine_id: null,
            nbSensors:0,
            nbSubs:0,
            machine: {
                id: null,
                label: '',
                functionality: '',
                note: '',
                building: {
                    id: null,
                    label: '',
                    site: {
                        id: null,
                        label: ''
                    }
                }
            },
            edited_machine: null,
            dialog_edit: false,
            invalid: false,

            no_path_image: false,
        }
    },
    created() {
        this.machine_id = this.$route.params.id;
        moment.locale('fr');
        this.getMachineDetails();
        this.getBuildings();
    },
    methods: {
        getMachineDetails() {
            this.axios.get(
                MACHINES + '/' + this.machine_id
            ).then((success) => {
                success.data.created_at = moment(success.data.created_at).format("DD/MM/YYYY");
                success.data.updated_at = moment(success.data.updated_at).format("DD/MM/YYYY");
                
                let flag_sensors = 0;
                success.data.subassemblies.forEach(sub => {
                    flag_sensors = flag_sensors + sub.virtual_devices.length;
                })
                this.nbSubs = success.data.subassemblies.length;
                this.nbSensors = flag_sensors;

                this.machine = success.data;
                this.$emit('updateLabelMachine', {label: this.machine.label});
            }).catch(() => {
                this.showSnackbar('error', "Get machine error spec152");
            });
        },

        getBuildings() {
            this.axios.get(
                BUILDINGS
            ).then((success) => {
                this.buildings = success.data;
            }).catch(() => {
                this.showSnackbar('error', "Get buildings error spec152");
            });
        },

        openDialogUpdateMachine() {
            this.edited_machine = Object.assign({}, this.machine);
            this.dialog_edit = true;
        },

        updateMachineDetails() {
            this.axios.patch(
                MACHINES + '/' + this.machine_id, this.edited_machine
            ).then((success) => {
                this.machine = success.data;
                this.$emit('updateLabelMachine', {label: this.machine.label});
                this.showSnackbar('success', this.$t('global.success_to_edit'));
            
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            
            }).finally(() => {
                this.dialog_edit = false
                this.resetForm();
            });
        },

        resetForm() {
            this.$refs.form.reset();
            this.$refs.observer.reset();
        },

        async copyValue(mytext) {   
            navigator.clipboard.writeText(mytext);
            this.showSnackbar('success', "Copié !");
        }
    },
    mounted() {
        this.$nextTick(function () {
            setTimeout(() => {
                this.no_path_image = true;
            }, 5000);
        })
    }
}
</script>
