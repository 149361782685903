<template>
    <v-autocomplete
        :items="professionals"
        v-model="professional_id"
        :label="$t('global.search_professional')"
        :prepend-inner-icon="'mdi-magnify'"
        item-text="name"
        item-value="id"
        class="mt-7 select-client" solo
        @change="switchProfessional()"
    ></v-autocomplete>
</template>

<script>
import {USERS, AUTH_SWITCH} from "../../api";

export default {
    name: "SelectClient",
    data() {
        return {
            professionals: [],
            active_professional: null,
            professional_id: null,
        }
    },
    
    created() {
        this.getProfessionals();
    },
    methods: {
        // Récupération client
        async getProfessionals() {
            await this.axios.get(USERS + '/professionals').then((success) => {
                success.data.forEach( professional => {
                    if(professional.trash === 0){
                        this.professionals.push(professional);
                    }
                })

                // Load default professional ID - first accessible
                if (this.$store.state.professional_id === null) {
                    this.refreshProfessional(0);
                    
                    //----------------------------------------------------------------------------------------
                    // DEBUG :
                    //console.log("ID = null : professional list -> "+this.professionals+ " - First ID found");
                
                    // Load actual professional ID - already known
                } else {
                    this.professional_id = this.$store.state.professional_id;
                    this.refreshProfessional(this.professional_id);

                    //----------------------------------------------------------------------------------------
                    // DEBUG :
                    //console.log("ID != null "+ this.professional_id +" : professional list -> "+this.professionals);
                    //console.log("ID != null "+ this.professional_id +" : professional name -> "+this.professionals[this.professional_id].name);
                    //console.log("ID != null "+ this.professional_id +" : professional uid -> "+this.professionals[this.professional_id].uid);
                }

            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            }).finally(() => {
                this.loading = false
            });
        },

        // Sélectionner un client
        switchProfessional() {
            this.axios.put( AUTH_SWITCH + '/' + this.professional_id ).then(() => {
                // Load new professional ID - chosen one
                this.refreshProfessional(this.professional_id);
                
                //----------------------------------------------------------------------------------------
                //DEBUG :
                //console.log("ID switch "+ this.professional_id +" : professional list -> " + this.professionals);
                //console.log("ID switch "+ this.professional_id +" : new professional name -> "+this.professionals[this.professional_id].name);
                //console.log("ID switch "+ this.professional_id +" : new professional uid -> "+this.professionals[this.professional_id].uid);

                if (this.$route.name === "dashboard") {
                    this.$store.dispatch('sites/load');
                    this.$store.dispatch('professionals_resume/load');
                } 
                
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            }).finally(()=>{
                this.$router.push({name: 'dashboard_loading'});
            })
        },

        refreshProfessional(id){
            // Store professional data to display
            if (id === 0){
                this.$store.commit('setProfessionalData', professional[0]);
                
            }else{
                this.professionals.forEach((professional) => {
                    if (professional.id === id ){
                        this.$store.commit('setProfessionalData', professional);
                    }
                });
            }
        }
    }
}
</script>

<style>
.select-client {
    max-width: 400px !important;
}
</style>
