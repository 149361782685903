<template>
    <div id="bind-professional-user">
        <v-dialog v-model="dialog_bind_professional" max-width="500px" persistent>
            <v-card>
                <v-form ref="formBindVirtualDevice">
                    <v-card-title>
                        <span class="headline">{{ $t('account_management.associate_clients') }}</span>
                    </v-card-title>

                    <v-divider/>

                    <v-card-text>
                        <v-select
                            :items="professionals_not_bind_user"
                            v-model="professionals_bind_to_user"
                            :label="$t('account_management.clients_to_bind')"
                            :menu-props="{ bottom: true, offsetY: true }"
                            :prepend-icon="'mdi-account-multiple-outline'"
                            item-text="name"
                            item-value="id"
                            solo chips multiple
                        />
                    </v-card-text>

                    <v-card-actions class="justify-end">
                        <v-btn @click="dialog_bind_professional = false">
                            <v-icon left>mdi-close</v-icon>
                            {{ $t('global.close') }}
                        </v-btn>
                        <v-btn color="primary" @click="updateBindingProfessional()">
                            <v-icon left>mdi-content-save</v-icon>
                            {{ $t('global.validation') }}
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {PROFESSIONALS} from "../../../../api";

export default {
    name: 'BindProfessionalUser',
    data() {
        return {
            professionals_not_bind_user: [],
            dialog_bind_professional: false,
            loading_bind_professionals: false,
            professionals_bind_to_user: [],
            user_id: null,
        }
    },
    methods: {
        openDialogBindProfessional(user) {
            this.loading_bind_professionals = true;
            this.user_id = user.id;

            this.axios.get(
                PROFESSIONALS + '/not_bind/user/' + this.user_id
            ).then((success) => {
                this.professionals_not_bind_user = success.data.professionals;
                this.dialog_bind_professional = true;

                success.data.professionals_bind_to_user.forEach(professional => {
                    this.professionals_bind_to_user.push(professional);
                });
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('error_display.error_get_professionals_not_bind'));
            }).finally(() => {
                this.loading_bind_professionals = false;
            });
        },

        updateBindingProfessional() {
            this.axios.put(PROFESSIONALS + '/bind/user/' + this.user_id, {
                professionals_id: this.professionals_bind_to_user
            }).then(() => {
                this.showGlobalSnackbar('success', this.$t('global.success_to_edit'));
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('error_display.error_bind_professionals'), this.$t('error_display.user_not_found'));
            }).finally(() => {
                this.professionals_not_bind_user = [];
                this.professionals_bind_to_user = [];
                this.$emit('getUsers');
                this.dialog_bind_professional = false;
            });
        },
    }
}
</script>
