<template>
    <v-col class="mt-5 pl-0">
        <!-- PAGE TITLE -->
        <v-subheader class="v-subheader col theme--light pl-5 ml-2">
            <p class="title mb-0 color-primary text-uppercase">
                Campagne et rapports
            </p>
            <!------------------- Info bulle ---------------------->
            <v-tooltip bottom dark class="mr-2">
                <template v-slot:activator="{ on }">
                    <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                        <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                    </v-btn>
                </template>
                <template v-slot:default>
                    <div style="min-width: 200px;">
                        <strong>Page [Détail d'une campagne]</strong>
                        <p>La totalité des livrables que Techview puisse remettre dés suite d'une intervention se retrouveront ici.</br>
                            Vous pourrez notamment suivre l'avancement de l'édition de nos rapports mais aussi les consulter.
                        </p>
                    </div>
                </template>
            </v-tooltip>
        </v-subheader>
        
        <!-- content -->
        <v-col color="white" class="ml-0 mr-4 pl-2 col">
            <!-- TOP DATA BAR -->
            <v-col class="row col-12 mb-0 pa-0">
                <!-- COUNTER -->
                <div v-for="campain in campains" :key="campain">
                    <v-col v-if="campain.presta === 5 && campain.step !== 43" color="white" class="col pa-0 mt-5">
                        <div v-for="status in states_ONLINE" :key="status">
                            <v-col v-if="status.level === campain.state" class="col pt-0 pb-0 mr-10 rounded col ml-6" style="height: 35px;font-weight: bold;text-align: center;font-size: 16px;line-height: 35px;" :style="{backgroundColor: status.level_bg, color:status.level_color, border: status.level_border}">
                                <v-icon style="font-size: 25px;position:relative;left:-6px;top:-2px;" :style="{color:status.level_color}">{{ status.level_icon }}</v-icon> {{ status.level_name }}
                            </v-col>
                        </div>
                    </v-col>
                    <v-col v-if="campain.presta === 5 && campain.step === 43" color="white" class="col pa-0 mt-5">
                        <div v-for="status in states_ONLINE" :key="status">
                            <v-col v-if="status.level === campain.state" class="col pt-0 pb-0 mr-10 rounded col ml-6" style="height: 35px;font-weight: bold;text-align: center;font-size: 16px;line-height: 35px;" :style="{backgroundColor: status.level_bg, color:status.level_color, border: status.level_border}">
                                <v-icon style="font-size: 25px;position:relative;left:-6px;top:-2px;color:#089d2d" >mdi-pause-box</v-icon> Surveillance en pause.
                            </v-col>
                        </div>
                    </v-col>
                    <v-col v-if="campain.presta !== 5 && campain.step !== 43" color="white" class="col pa-0 mt-5">
                        <div v-for="status in states_OFFLINE" :key="status">
                            <v-col v-if="status.level === campain.state" class="col pt-0 pb-0 mr-10 rounded col ml-6" style="height: 35px;font-weight: bold;text-align: center;font-size: 16px;line-height: 35px;" :style="{backgroundColor: status.level_bg, color:status.level_color, border: status.level_border}">
                                <v-icon style="font-size: 25px;position:relative;left:-6px;top:-2px;" :style="{color:status.level_color}">{{ status.level_icon }}</v-icon> {{ status.level_name }}
                            </v-col>
                        </div>
                    </v-col>
                    <v-col v-if="campain.presta !== 5 && campain.step === 43" color="white" class="col pa-0 mt-5">
                        <div v-for="status in states_OFFLINE" :key="status">
                            <v-col v-if="status.level === campain.state" class="col pt-0 pb-0 mr-10 rounded col ml-6" style="height: 35px;font-weight: bold;text-align: center;font-size: 16px;line-height: 35px;background-color: #089d2d1c;color:#089d2d;border:1px solid #089d2d">
                                <v-icon style="font-size: 25px;position:relative;left:-6px;top:-2px;color:#089d2d" >mdi-check</v-icon> Campagne terminée.
                            </v-col>
                        </div>
                    </v-col>
                </div>

                <!-- GLOBAL ACTIONS -->
                <v-col class="row col ml-6 pt-8">
                    <v-tooltip bottom dark class="mr-2">
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" @click="initialize()" class="mr-2" dusk="logout-button" style="border: 1px solid #01418145;">
                                <v-icon color="primary">mdi-tag-multiple</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('global.1.5.2') }}</span>
                    </v-tooltip>
                    <v-tooltip bottom dark class="mr-2">
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" @click="initialize()" class="mr-2" dusk="logout-button" style="border: 1px solid #01418145;">
                                <v-icon color="primary">mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('campain.refresh') }}</span>
                    </v-tooltip>
                </v-col>

                <!-- SPACER -->
                <v-col class="row col"></v-col> 

                <!-- SEARCH -->       
                <v-col @click="dialogNextUpdate()" class="col-4 pa-0">
                    <v-text-field 
                        v-model="search"
                        append-icon="mdi-magnify"
                        :label="$t('global.search')"
                        class="mt-1"
                        disabled
                    ></v-text-field>
                </v-col>
            </v-col>

            <div v-if="loading_datatable == true" style="color:grey;text-align: center;">
                <li class="rounded row notification_card mb-1 pa-10 mt-15 ml-6" style="color: grey;justify-content: center;">
                    <div style="color:grey;text-align: center;"><strong>Chargement en cours...</strong></div>
                </li>
            </div>           
            
            <!-- ALL DATATABLE -->
            <div v-if="loading_datatable == false" flat color="white" class="pt-0 mb-3 mt-1">
                <!-- DATA LIST -->
                <template>
                    <ul id="notifications-cards">
                        <!-- ACTIVE DATA (on non trashed data and non closed data)-->
                        <li class="row" v-for="campain in campains" :key="campain" >
                            <div v-if="campain && campain !== 'undefined' && campain !== null && typeof campain === 'object' && campain.closed_at === null" class="rounded row notification_card mb-0 elevation-1 ml-0 mr-0 mt-3" style="border: solid 1px #80808021;background-color: #ffffff;">
                                <div class="row pa-3">
                                    <!--CHIPS-->
                                    <div v-for="filters in states_OFFLINE" :key="filters">
                                        <div  v-if="filters.level == campain.state && filters.level_name !== 'Tout voir'" :to="{ path: '/campain/' + campain.id}" class="col-0 mr-5" style="color:white;font-weight:800;display:flex;justify-content:center;text-shadow:2px 2px 4px rgba(0, 0, 0, 0.29); height:100%;min-width: 8px;border-radius:2px 0px 0px 2px;" :style="{backgroundColor: filters.level_color}"></div>
                                    </div>  
                                    
                                    <!--INFOS-->
                                    <div class="col-3">
                                        <v-chip class="mb-5 mt-5" v-on="on" link :to="'/campain/' + campain.id" style="font-weight: bold; background-color: #f3f3f3; border: 1px solid #e5e5e5;">{{campain.name}}</v-chip>
                                        <div style="font-size: 12px;margin-left: 10px;">Ref - {{campain.uid}}</div>
                                        <div style="font-size: 12px;margin-left: 10px;">Créée le : {{campain.created_at}}</div>
                                        <div v-for="tviewer in team" :key="tviewer">
                                            <div v-if="tviewer.id === campain.creator" style="font-size: 12px;margin-left: 10px;">Créé par : {{tviewer.first_name}} {{tviewer.last_name}}</div>
                                        </div>
                                        <div v-for="analyst in analysts" :key="analyst">
                                            <div v-if="analyst.id === campain.manager" style="font-size: 12px;margin-left: 10px;margin-top:15px;font-weight: bold;">Chargé de campagne : {{analyst.first_name}} {{analyst.last_name}}</div>
                                        </div>
                                        <div style="font-size: 12px;margin-left: 10px;">N° de dossier : {{campain.affaire_number}}</div>
                                        <div v-for="site in sites" :key="site">
                                            <div v-if="site.id === campain.id_park" style="font-size: 12px;margin-left: 10px;">Parc machine associé : {{site.label}}</div>
                                        </div>
                                        <v-chip v-if="campain.step === 43 && access_level <= 100" class="mb-5 mt-10 pointer pl-2 pr-5" @click="setClosed(id)" style="font-weight: bold; background-color: #089d2d1c; border: 1px solid #089d2d;color:#089d2d;">
                                            <v-icon class="mr-2" style="font-size: 18px;color:#089d2d;">mdi-lock-open</v-icon>
                                            Clôturer la campagne
                                        </v-chip>
                                    </div>

                                    <!-- UPDATES -->
                                    <div  v-if="campain.presta !== 5" class="col pl-5 pr-5 pb-0" style="border-left: 1px solid #d3d3d36e; font-size:12px;background-color: whitesmoke;">
                                        <v-col class="col col pa-0 pt-0 pb-1 ma-0 ml-0 mt-2" style="text-align: center;">
                                            <div v-for="presta in prestas" :key="presta">
                                                <div v-for="typex in types" :key="typex">
                                                    <v-chip v-if="presta.id === campain.presta && typex.id === campain.type" class="ml-0 mb-0">{{ presta.name }} ({{ typex.name.toLowerCase().trim() }})</v-chip>
                                                </div>
                                            </div>
                                        </v-col>
                                        <div class="row row col pa-10 pt-5 pb-5 ma-0 ml-0 mt-1 mb-5" style="
                                            align-items: center;
                                            justify-content: center;
                                            text-align: center;
                                            background-color: whitesmoke;
                                            border-radius: 100px;
                                        ">
                                            <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                <!-- CUSTOMER VIEW -->
                                                <v-icon v-if="campain.step <= 11 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 12 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 13 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step > 13 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                                <!-- ADMIN VIEW -->
                                                <v-icon v-if="campain.step === 11 && access_level <= 100" @click="updateStep(campain.id,12)" class="pointer" color="orange" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 12 && access_level <= 100" @click="updateStep(campain.id,13)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 13 && access_level <= 100" @click="updateStep(campain.id,11)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step > 13 && access_level <= 100" @click="updateStep(campain.id,11)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                            </p>
                                            <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                <!-- CUSTOMER VIEW -->
                                                <v-icon v-if="campain.step >= 13 && campain.step <= 21 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 22 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 23 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step < 13 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step > 23 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                                <!-- ADMIN VIEW -->
                                                <v-icon v-if="campain.step >= 13 && campain.step <= 21 && access_level <= 100" @click="updateStep(campain.id,22)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 22 && access_level <= 100" @click="updateStep(campain.id,23)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 23 && access_level <= 100" @click="updateStep(campain.id,21)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step < 13 && access_level <= 100" @click="updateStep(campain.id,21)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step > 23 && access_level <= 100" @click="updateStep(campain.id,21)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                            </p>
                                            <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                <!-- CUSTOMER VIEW -->
                                                <v-icon v-if="campain.step >= 23 && campain.step <= 31 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 32 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 33 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step < 23 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step > 33 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                                <!-- ADMIN VIEW -->
                                                <v-icon v-if="campain.step >= 23 && campain.step <= 31 && access_level <= 100" @click="updateStep(campain.id,32)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 32 && access_level <= 100" @click="updateStep(campain.id,33)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 33 && access_level <= 100" @click="updateStep(campain.id,31)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step < 23 && access_level <= 100" @click="updateStep(campain.id,31)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step > 33 && access_level <= 100" @click="updateStep(campain.id,31)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                            </p>
                                            <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                <!-- CUSTOMER VIEW -->
                                                <v-icon v-if="campain.step >= 33 && campain.step <= 41 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 42 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 43 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step < 33 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                <!-- ADMIN VIEW -->
                                                <v-icon v-if="campain.step >= 33 && campain.step <= 41 && access_level <= 100" @click="updateStep(campain.id,42)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 42 && access_level <= 100" @click="updateStep(campain.id,43)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 43 && access_level <= 100" @click="updateStep(campain.id,41)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step < 33 && access_level <= 100" @click="updateStep(campain.id,41)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            </p>
                                        </div>
                                        <div class="col" style="
                                            position: relative;
                                            border: 1px solid #656565;
                                            top: -58px;
                                            width: 70%;
                                            left: 15%;
                                            border-radius: 15px;
                                            height: 2px;
                                            padding: 0px;
                                        "></div>
                                        <div class="row row col pa-10 pt-0 pb-0 ma-0 ml-0" style="
                                            align-items: center;
                                            justify-content: center;
                                            text-align: center;
                                            position: relative;
                                            top: -57px;
                                        ">
                                            <p class="ml-0 mb-0 col" v-if="campain.step === 11 || campain.step === 12 || campain.step === 13" style="z-index: 10;font-weight: bold;">Préparation</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step != 11 && campain.step != 12 && campain.step != 13" style="z-index: 10;">Préparation</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step === 21 || campain.step === 22 || campain.step === 23" style="z-index: 10;font-weight: bold;">Intervention</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step != 21 && campain.step != 22 && campain.step != 23" style="z-index: 10;">Intervention</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step === 31 || campain.step === 32 || campain.step === 33" style="z-index: 10;font-weight: bold;">Analyses</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step != 31 && campain.step != 32 && campain.step != 33" style="z-index: 10;">Analyses</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step === 41 || campain.step === 42 || campain.step === 43" style="z-index: 10;font-weight: bold;">Rapports</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step != 41 && campain.step != 42 && campain.step != 43" style="z-index: 10;">Rapports</p>
                                        </div>
                                        <v-row class="row pl-10 pr-10" style="position: relative; top:-35px;">
                                            <v-col class="row col pa-0 pt-0 pb-1 ma-0 ml-10 mr-10" style="text-align: center;">
                                                <div style="font-weight: bold;font-size: 13px;" class="mb-2 col col">
                                                    <div>Equipements concernés : </div> 
                                                    <v-chip class="ml-0 mb-0">{{ campain.machine_list.length }} / {{ machines_count }}</v-chip>
                                                </div>
                                                <div style="font-weight: bold;font-size: 13px;" class="mb-2 col col">
                                                    <div>Rapports disponibles: </div>
                                                    <v-chip class="ml-0 mb-0">{{ available_reports_count }} / {{ reports.length }}</v-chip>
                                                </div> 
                                            </v-col>
                                        </v-row>                          
                                    </div>

                                    <div  v-if="campain.presta === 5" class="col pl-5 pr-5 pb-0" style="border-left: 1px solid #d3d3d36e; font-size:12px;background-color: whitesmoke;">
                                        <v-col class="col col pa-0 pt-0 pb-1 ma-0 ml-0 mt-2" style="text-align: center;">
                                            <div v-for="presta in prestas" :key="presta">
                                                <div v-for="typex in types" :key="typex">
                                                    <v-chip v-if="presta.id === campain.presta && typex.id === campain.type" class="ml-0 mb-0">{{ presta.name }} ({{ typex.name.toLowerCase().trim() }})</v-chip>
                                                </div>
                                            </div>
                                        </v-col>
                                        <div class="row row col pa-10 pt-5 pb-5 ma-0 ml-0 mt-1 mb-5" style="
                                            align-items: center;
                                            justify-content: center;
                                            text-align: center;
                                            background-color: whitesmoke;
                                            border-radius: 100px;
                                        ">
                                            <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                <!-- CUSTOMER VIEW -->
                                                <v-icon v-if="campain.step <= 11 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 12 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 13 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step > 13 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                                <!-- ADMIN VIEW -->
                                                <v-icon v-if="campain.step === 11 && access_level <= 100" @click="updateStep(campain.id,12)" class="pointer" color="orange" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 12 && access_level <= 100" @click="updateStep(campain.id,13)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 13 && access_level <= 100" @click="updateStep(campain.id,11)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step > 13 && access_level <= 100" @click="updateStep(campain.id,11)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                            </p>
                                            <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                <!-- CUSTOMER VIEW -->
                                                <v-icon v-if="campain.step >= 13 && campain.step <= 21 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 22 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 23 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step < 13 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step > 23 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                                <!-- ADMIN VIEW -->
                                                <v-icon v-if="campain.step >= 13 && campain.step <= 21 && access_level <= 100" @click="updateStep(campain.id,22)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 22 && access_level <= 100" @click="updateStep(campain.id,23)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 23 && access_level <= 100" @click="updateStep(campain.id,21)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step < 13 && access_level <= 100" @click="updateStep(campain.id,21)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step > 23 && access_level <= 100" @click="updateStep(campain.id,21)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                            </p>
                                            <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                <!-- CUSTOMER VIEW -->
                                                <v-icon v-if="campain.step >= 23 && campain.step <= 31 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 32 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 33 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step < 23 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step > 33 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                                <!-- ADMIN VIEW -->
                                                <v-icon v-if="campain.step >= 23 && campain.step <= 31 && access_level <= 100" @click="updateStep(campain.id,32)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 32 && access_level <= 100" @click="updateStep(campain.id,33)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 33 && access_level <= 100" @click="updateStep(campain.id,31)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step < 23 && access_level <= 100" @click="updateStep(campain.id,31)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step > 33 && access_level <= 100" @click="updateStep(campain.id,31)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                            </p>
                                            <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                <!-- CUSTOMER VIEW -->
                                                <v-icon v-if="campain.step >= 33 && campain.step <= 41 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 42 && access_level > 100" color="flashGreen" style="font-size: 36px;">mdi-play-network</v-icon>
                                                <v-icon v-if="campain.step === 43 && access_level > 100" color="red" style="font-size: 36px;">mdi-close-network-outline</v-icon>
                                                <v-icon v-if="campain.step < 33 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                <!-- ADMIN VIEW -->
                                                <v-icon v-if="campain.step >= 33 && campain.step <= 41 && access_level <= 100" @click="updateStep(campain.id,42)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 42 && access_level <= 100" @click="updateStep(campain.id,43)" class="pointer" color="flashGreen" style="font-size: 36px;">mdi-play-network</v-icon>
                                                <v-icon v-if="campain.step === 43 && access_level <= 100" @click="updateStep(campain.id,41)" class="pointer" color="red" style="font-size: 36px;">mdi-close-network-outline</v-icon>
                                                <v-icon v-if="campain.step < 33 && access_level <= 100" @click="updateStep(campain.id,41)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            </p>
                                        </div>
                                        <div v-if="campain.step === 42" class="col" style="
                                            position: relative;
                                            border: 1px solid #00db37;
                                            top: -58px;
                                            width: 70%;
                                            left: 15%;
                                            border-radius: 15px;
                                            height: 2px;
                                            padding: 0px;
                                        "></div>
                                        <div v-if="campain.step === 43" class="col" style="
                                            position: relative;
                                            border: 1px solid #d70000;
                                            top: -58px;
                                            width: 70%;
                                            left: 15%;
                                            border-radius: 15px;
                                            height: 2px;
                                            padding: 0px;
                                        "></div>
                                        <div v-if="campain.step < 42" class="col" style="
                                            position: relative;
                                            border: 1px solid #656565;
                                            top: -58px;
                                            width: 70%;
                                            left: 15%;
                                            border-radius: 15px;
                                            height: 2px;
                                            padding: 0px;
                                        "></div>
                                        <div class="row row col pa-10 pt-0 pb-0 ma-0 ml-0" style="
                                            align-items: center;
                                            justify-content: center;
                                            text-align: center;
                                            position: relative;
                                            top: -57px;
                                        ">
                                            <p class="ml-0 mb-0 col" v-if="campain.step === 11 || campain.step === 12 || campain.step === 13" style="z-index: 10;font-weight: bold;">Préparation</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step != 11 && campain.step != 12 && campain.step != 13" style="z-index: 10;">Préparation</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step === 21 || campain.step === 22 || campain.step === 23" style="z-index: 10;font-weight: bold;">Instrumentation</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step != 21 && campain.step != 22 && campain.step != 23" style="z-index: 10;">Instrumentation</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step === 31 || campain.step === 32 || campain.step === 33" style="z-index: 10;font-weight: bold;">Configuration</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step != 31 && campain.step != 32 && campain.step != 33" style="z-index: 10;">Configuration</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step === 41 || campain.step === 42 || campain.step === 43" style="z-index: 10;font-weight: bold;">Surveillance</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step != 41 && campain.step != 42 && campain.step != 43" style="z-index: 10;">Surveillance</p>
                                        </div>
                                        <v-row class="row pl-10 pr-10" style="position: relative; top:-35px;">
                                            <v-col class="row col pa-0 pt-0 pb-1 ma-0 ml-10 mr-10" style="text-align: center;">
                                                <div style="font-weight: bold;font-size: 13px;" class="mb-2 col col">
                                                    <div>Equipements concernés : </div> 
                                                    <v-chip class="ml-0 mb-0">{{ campain.machine_list.length }} / {{ machines_count }}</v-chip>
                                                </div>
                                                <div style="font-weight: bold;font-size: 13px;" class="mb-2 col col">
                                                    <div>Rapports disponibles: </div>
                                                    <v-chip class="ml-0 mb-0">{{ campain.machine_list.length }} / {{ campain.machine_list.length }}</v-chip>
                                                </div> 
                                            </v-col>
                                        </v-row>                          
                                    </div>

                                    <!--MORE INFOS -->
                                    <div :to="{ path: '/campain/' + campain.id}" class="col-3 pl-5" style="border-left: 1px solid #d3d3d36e; font-size:12px;">
                                        <div style="font-weight: bold;font-size: 13px;">Informations complémentaires :</div> 
                                        <v-row class="row col pa-0 pt-0 pb-1 ma-0 ml-0">
                                            <p class="ml-0 mb-0">{{ campain.description }}</p>
                                        </v-row> 
                                        <v-row class="mt-5">
                                            <v-chip v-for="tag in getCampainTags(campain)" class="pl-3 pr-3 ma-2" link :key="tag">
                                                {{ tag }}
                                            </v-chip>
                                        </v-row>                              
                                    </div>

                                    <!-- ACTIONS -->
                                    <v-col v-if="access_level <= 99" class="col-1 pa-2" style="max-width: 80px;">
                                        <v-tooltip v-if="!campain.trash && access_level <= 99" bottom dark>
                                            <template v-slot:activator="{ on }">
                                                <v-btn class="ma-auto mr-5 ml-5 col-0" text icon v-on="on" @click="openDialogUpdateCampain(campain)" dusk="logout-button">
                                                    <v-icon color="primary">mdi-circle-edit-outline</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{ $t('campain.edit_campain') }}</span>
                                        </v-tooltip>
                                    </v-col>
                                </div>
                            </div>
                        </li>

                        <!-- CLOSED DATA (on non trashed data and closed data)-->
                        <li class="row" v-for="campain in campains" :key="campain">                          
                            <div v-if="campain && campain !== 'undefined' && campain !== null && typeof campain === 'object' && campain.closed_at != null" class="rounded row notification_card mb-0 elevation-1 ml-0 mr-0 mt-3" style="border: solid 1px #80808021;background-color: #ffffff;">
                                <div class="row pa-3" >
                                    <!--CHIPS-->
                                    <div v-for="filters in data_filters" :key="filters" style="background-color: whitesmoke;">
                                        <div  v-if="filters.level == campain.state && filters.level_name !== 'Tout voir'" :to="{ path: '/campain/' + campain.id}" class="col-0 mr-5" style="color:white;font-weight:800;display:flex;justify-content:center;text-shadow:2px 2px 4px rgba(0, 0, 0, 0.29); height:100%;min-width: 8px;border-radius:2px 0px 0px 2px;background-color: #565656;"></div>
                                    </div>   
                                    
                                    <!--INFOS-->
                                    <div class="col-3 pl-10" style="background-color: whitesmoke;">
                                        <v-chip class="mb-5 mt-5" v-on="on" link :to="'/campain/' + campain.id" style="font-weight: bold; background-color: #f3f3f3; border: 1px solid #e5e5e5;">{{campain.name}}</v-chip>
                                        <div style="font-size: 12px;margin-left: 10px;">Ref - {{campain.uid}}</div>
                                        <div style="font-size: 12px;margin-left: 10px;">Créée le : {{campain.created_at}}</div>
                                        <div v-for="tviewer in team" :key="tviewer">
                                            <div v-if="tviewer.id === campain.creator" style="font-size: 12px;margin-left: 10px;">Créé par : {{tviewer.first_name}} {{tviewer.last_name}}</div>
                                        </div>
                                        <div v-for="analyst in analysts" :key="analyst">
                                            <div v-if="analyst.id === campain.manager" style="font-size: 12px;margin-left: 10px;margin-top:15px;font-weight: bold;">Chargé de campagne : {{analyst.first_name}} {{analyst.last_name}}</div>
                                        </div>
                                        <div style="font-size: 12px;margin-left: 10px;">N° de dossier : {{campain.affaire_number}}</div>
                                        <div v-for="site in sites" :key="site">
                                            <div v-if="site.id === campain.id_park" style="font-size: 12px;margin-left: 10px;">Parc machine associé : {{site.label}}</div>
                                        </div>
                                        <v-chip v-if="campain.step === 43" class="mb-5 mt-10 pointer pl-2 pr-5" @click="setOpened(id)" style="font-weight: bold; background-color: #d700001c; border: 1px solid #d70000;color:#d70000;">
                                            <v-icon class="mr-2" style="font-size: 18px;color:#d70000;">mdi-lock</v-icon>
                                            Ouvrir la campagne
                                        </v-chip>
                                    </div>

                                    <!-- UPDATES -->
                                    <div  v-if="campain.presta !== 5" class="col pl-5 pr-5 pb-0" style="border-left: 1px solid #d3d3d36e; font-size:12px;background-color: whitesmoke;">
                                        <v-col class="col col pa-0 pt-0 pb-1 ma-0 ml-0" style="text-align: center;">
                                            <div v-for="presta in prestas" :key="presta">
                                                <div v-for="typex in types" :key="typex">
                                                    <v-chip v-if="presta.id === campain.presta && typex.id === campain.type" class="ml-0 mb-0">{{ presta.name }} ({{ typex.name.toLowerCase().trim() }})</v-chip>
                                                </div>
                                            </div>
                                        </v-col>
                                        <div class="row row col pa-10 pt-5 pb-5 ma-0 ml-0 mt-1 mb-5" style="
                                            align-items: center;
                                            justify-content: center;
                                            text-align: center;
                                            background-color: whitesmoke;
                                            border-radius: 100px;
                                        ">
                                            <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                <!-- CUSTOMER VIEW -->
                                                <v-icon v-if="campain.step <= 11" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 12" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 13 " style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step > 13" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                            </p>
                                            <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                <!-- CUSTOMER VIEW -->
                                                <v-icon v-if="campain.step === 21" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 22" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 23" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step < 21"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step > 23" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>                                            </p>
                                            <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                <!-- CUSTOMER VIEW -->
                                                <v-icon v-if="campain.step === 31" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 32" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 33" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step < 31"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step > 33" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                            </p>
                                            <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                <!-- CUSTOMER VIEW -->
                                                <v-icon v-if="campain.step === 41" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 42" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 43" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step < 41"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            </p>
                                        </div>
                                        <div class="col" style="
                                            position: relative;
                                            border: 1px solid #656565;
                                            top: -58px;
                                            width: 70%;
                                            left: 15%;
                                            border-radius: 15px;
                                            height: 2px;
                                            padding: 0px;
                                        "></div>
                                        <div class="row row col pa-10 pt-0 pb-0 ma-0 ml-0" style="
                                            align-items: center;
                                            justify-content: center;
                                            text-align: center;
                                            position: relative;
                                            top: -57px;
                                        ">
                                            <p class="ml-0 mb-0 col" v-if="campain.step === 11 || campain.step === 12 || campain.step === 13" style="z-index: 10;font-weight: bold;">Préparation</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step != 11 && campain.step != 12 && campain.step != 13" style="z-index: 10;">Préparation</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step === 21 || campain.step === 22 || campain.step === 23" style="z-index: 10;font-weight: bold;">Intervention</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step != 21 && campain.step != 22 && campain.step != 23" style="z-index: 10;">Intervention</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step === 31 || campain.step === 32 || campain.step === 33" style="z-index: 10;font-weight: bold;">Analyses</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step != 31 && campain.step != 32 && campain.step != 33" style="z-index: 10;">Analyses</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step === 41 || campain.step === 42 || campain.step === 43" style="z-index: 10;font-weight: bold;">Rapports</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step != 41 && campain.step != 42 && campain.step != 43" style="z-index: 10;">Rapports</p>
                                        </div>
                                        <v-row class="row pl-10 pr-10" style="position: relative; top:-35px;">
                                            <v-col class="row col pa-0 pt-0 pb-1 ma-0 ml-10 mr-10" style="text-align: center;">
                                                <div style="font-weight: bold;font-size: 13px;" class="mb-2 col col">
                                                    <div>Equipements concernés : </div> 
                                                    <v-chip class="ml-0 mb-0">{{ campain.machine_list.length }} / {{ machines_count }}</v-chip>
                                                </div>
                                                <div style="font-weight: bold;font-size: 13px;" class="mb-2 col col">
                                                    <div>Rapports disponibles: </div>
                                                    <v-chip class="ml-0 mb-0">{{ campain.machine_list.length }} / {{ campain.machine_list.length }}</v-chip>
                                                </div> 
                                            </v-col>
                                        </v-row>                          
                                    </div>

                                    <div  v-if="campain.presta === 5" class="col pl-5 pr-5" style="border-left: 1px solid #d3d3d36e; font-size:12px;background-color: whitesmoke;">
                                        <v-col class="col col pa-0 pt-0 pb-1 ma-0 ml-0" style="text-align: center;">
                                            <div v-for="presta in prestas" :key="presta">
                                                <div v-for="typex in types" :key="typex">
                                                    <v-chip v-if="presta.id === campain.presta && typex.id === campain.type" class="ml-0 mb-0">{{ presta.name }} {{ typex.name }}</v-chip>
                                                </div>
                                            </div>
                                        </v-col>
                                        <div class="row row col pa-10 pt-5 pb-5 ma-0 ml-0 mt-1 mb-5" style="
                                            align-items: center;
                                            justify-content: center;
                                            text-align: center;
                                            background-color: whitesmoke;
                                            border-radius: 100px;
                                        ">
                                            <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                <!-- CUSTOMER VIEW -->
                                                <v-icon v-if="campain.step <= 11 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 12 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 13 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step > 13 && access_level > 100" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                                </p>
                                            <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                <!-- CUSTOMER VIEW -->
                                                <v-icon v-if="campain.step === 21 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 22 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 23 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step < 21 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step > 23 && access_level > 100" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                            </p>
                                            <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                <!-- CUSTOMER VIEW -->
                                                <v-icon v-if="campain.step === 31 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 32 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 33 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                                <v-icon v-if="campain.step < 31 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step > 33 && access_level > 100" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                            </p>
                                            <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                <!-- CUSTOMER VIEW -->
                                                <v-icon v-if="campain.step === 41 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                                <v-icon v-if="campain.step === 42 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-play-network</v-icon>
                                                <v-icon v-if="campain.step === 43 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-close-network-outline</v-icon>
                                                <v-icon v-if="campain.step < 41 && access_level > 100" style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                            </p>
                                        </div>
                                        <div v-if="campain.step === 42" class="col" style="
                                            position: relative;
                                            border: 1px solid #00db37;
                                            top: -58px;
                                            width: 70%;
                                            left: 15%;
                                            border-radius: 15px;
                                            height: 2px;
                                            padding: 0px;
                                        "></div>
                                        <div v-if="campain.step === 43" class="col" style="
                                            position: relative;
                                            border: 1px solid #d70000;
                                            top: -58px;
                                            width: 70%;
                                            left: 15%;
                                            border-radius: 15px;
                                            height: 2px;
                                            padding: 0px;
                                        "></div>
                                        <div v-if="campain.step < 42" class="col" style="
                                            position: relative;
                                            border: 1px solid #656565;
                                            top: -58px;
                                            width: 70%;
                                            left: 15%;
                                            border-radius: 15px;
                                            height: 2px;
                                            padding: 0px;
                                        "></div>
                                        <div class="row row col pa-10 pt-0 pb-0 ma-0 ml-0" style="
                                            align-items: center;
                                            justify-content: center;
                                            text-align: center;
                                            position: relative;
                                            top: -57px;
                                        ">
                                            <p class="ml-0 mb-0 col" v-if="campain.step === 11 || campain.step === 12 || campain.step === 13" style="z-index: 10;font-weight: bold;">Préparation</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step != 11 && campain.step != 12 && campain.step != 13" style="z-index: 10;">Préparation</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step === 21 || campain.step === 22 || campain.step === 23" style="z-index: 10;font-weight: bold;">Instrumentation</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step != 21 && campain.step != 22 && campain.step != 23" style="z-index: 10;">Instrumentation</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step === 31 || campain.step === 32 || campain.step === 33" style="z-index: 10;font-weight: bold;">Configuration</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step != 31 && campain.step != 32 && campain.step != 33" style="z-index: 10;">Configuration</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step === 41 || campain.step === 42 || campain.step === 43" style="z-index: 10;font-weight: bold;">Surveillance</p>
                                            <p class="ml-0 mb-0 col" v-if="campain.step != 41 && campain.step != 42 && campain.step != 43" style="z-index: 10;">Surveillance</p>
                                        </div>
                                        <v-row class="row pl-10 pr-10" style="position: relative; top:-35px;">
                                            <v-col class="row col pa-0 pt-0 pb-1 ma-0 ml-10 mr-10" style="text-align: center;">
                                                <div style="font-weight: bold;font-size: 13px;" class="mb-2 col col">
                                                    <div>Equipements concernés : </div> 
                                                    <v-chip class="ml-0 mb-0">{{ campain.machine_list.length }} / {{ machines_count }}</v-chip>
                                                </div>
                                                <div style="font-weight: bold;font-size: 13px;" class="mb-2 col col">
                                                    <div>Rapports disponibles: </div>
                                                    <v-chip class="ml-0 mb-0">{{ campain.machine_list.length }} / {{ campain.machine_list.length }}</v-chip>
                                                </div> 
                                            </v-col>
                                        </v-row>                          
                                    </div>

                                    <!--MORE INFOS -->
                                    <div :to="{ path: '/campain/' + campain.id}" class="col-3 pl-5" style="border-left: 1px solid #d3d3d36e; font-size:12px;background-color: whitesmoke;">
                                        <div style="font-weight: bold;font-size: 13px;">Informations complémentaires :</div> 
                                        <v-row class="row col pa-0 pt-0 pb-1 ma-0 ml-0">
                                            <p class="ml-0 mb-0">{{ campain.description }}</p>
                                        </v-row> 
                                        <v-row class="mt-5">
                                            <v-chip v-for="tag in getCampainTags(campain)" class="pl-3 pr-3 ma-2" link :key="tag">
                                                {{ tag }}
                                            </v-chip>
                                        </v-row>                              
                                    </div>

                                    <!-- ACTIONS -->
                                    <v-col class="col-1 pa-2" style="max-width: 80px;background-color: whitesmoke;">
                                        <v-tooltip v-if="!campain.trash && access_level <= 99 " bottom dark>
                                            <template v-slot:activator="{ on }">
                                                <v-btn class="ma-auto mr-5 ml-5 col-0" disabled text icon v-on="on" @click="openDialogUpdateCampain(campain)" dusk="logout-button">
                                                    <v-icon color="primary">mdi-circle-edit-outline</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{ $t('campain.edit_campain') }}</span>
                                        </v-tooltip>
                                    </v-col>
                                </div>
                            </div>
                        </li>
                    </ul>
                </template>

                <!-- campain ADD / EDIT MODAL -->
                <v-dialog v-model="dialog_edit" max-width="750px">
                    <v-card>
                        <validation-observer ref="observer" v-slot="{ invalid }">
                            <v-form ref="form">
                                <v-card-title>
                                    <span class="headline">{{ dialogTitle }}</span>
                                </v-card-title>
                                <v-divider></v-divider>

                                <v-card-text>
                                    <v-col>
                                        <!-- CAMPAIN NAME -->
                                        <validation-provider :name="$t('campain.add_name')" rules="required"
                                                                v-slot="{ errors }">
                                            <v-text-field solo
                                                            :label="$t('campain.add_name')"
                                                            v-model="edited_campain.name"
                                                            :prepend-icon="'mdi-label-outline'"
                                                            :error-messages="errors"
                                            />
                                        </validation-provider>

                                        <!-- CAMPAIN PRESTA -->
                                        <validation-provider :name="$t('campain.add_presta')" rules="required"
                                                                v-slot="{ errors }">
                                            <v-select
                                                :items="prestas"
                                                v-model="edited_campain.presta"
                                                :label="$t('campain.add_presta')"
                                                :prepend-icon="'mdi-book'"
                                                :error-messages="errors"
                                                item-value="id"
                                                item-text="name" solo
                                            >
                                            </v-select>
                                        </validation-provider> 

                                        <!-- CAMPAIN TYPE -->
                                        <validation-provider :name="$t('campain.add_type')" rules="required"
                                                                v-slot="{ errors }">
                                            <v-select
                                                :items="types"
                                                v-model="edited_campain.type"
                                                :label="$t('campain.add_type')"
                                                :prepend-icon="'mdi-book'"
                                                :error-messages="errors"
                                                item-value="id"
                                                item-text="name" solo
                                            >
                                            </v-select>
                                        </validation-provider>

                                        <!-- CAMPAIN AFFAIRE NUMBER -->
                                        <validation-provider :name="$t('campain.add_affaire_number')" rules="required"
                                                                v-slot="{ errors }">
                                            <v-text-field solo
                                                            :label="$t('campain.add_affaire_number')"
                                                            v-model="edited_campain.affaire_number"
                                                            :prepend-icon="'mdi-qrcode'"
                                                            :error-messages="errors"
                                            />
                                        </validation-provider>

                                        <!-- CAMPAIN MANAGER -->
                                        <validation-provider :name="$t('campain.add_manager')" rules="required"
                                                                v-slot="{ errors }">
                                            <v-select
                                                :items="analysts"
                                                v-model="edited_campain.manager"
                                                :label="$t('campain.add_manager')"
                                                :prepend-icon="'mdi-account-hard-hat-outline'"
                                                :error-messages="errors"
                                                item-value="id"
                                                item-text="first_name" solo
                                            >
                                            </v-select>
                                        </validation-provider>

                                        <!-- CAMPAIN PARK -->
                                        <validation-provider :name="$t('campain.add_park')" rules="required"
                                                                v-slot="{ errors }">
                                            <v-select
                                                :items="sites"
                                                v-model="edited_campain.id_park"
                                                :label="$t('campain.add_park')"
                                                :prepend-icon="'mdi-factory'"
                                                :error-messages="errors"
                                                item-value="id"
                                                item-text="label" solo
                                            >
                                            </v-select>
                                        </validation-provider>

                                        <!-- CAMPAIN MACHINES -->
                                        <validation-provider :name="$t('campain.add_machines')" rules="required"
                                                                v-slot="{ errors }">
                                            <v-select
                                                :items="machines"
                                                v-model="edited_campain.machine_list"
                                                :label="$t('campain.add_machines')"
                                                :prepend-icon="'mdi-car-turbocharger'"
                                                :error-messages="errors"
                                                item-value="id"
                                                item-text="label" solo chips clearable multiple
                                            >
                                            </v-select>
                                        </validation-provider>
                                        
                                        <!-- CAMPAIN DESCRIPTION -->
                                        <v-textarea rows="2" solo no-resize
                                                    v-model="edited_campain.description"
                                                    :label="$t('global.notes')"
                                                    :prepend-icon="'mdi-comment-outline'"
                                        />

                                        <!-- CAMPAIN TAGS -->
                                        <validation-provider :name="$t('campain.add_tags')" rules="required"
                                                                v-slot="{ errors }">
                                            <v-select
                                                :items="tags"
                                                v-model="edited_campain.tags"
                                                :label="$t('campain.add_tags')"
                                                :prepend-icon="'mdi-tag'"
                                                :error-messages="errors"
                                                item-value="name"
                                                item-text="name" solo chips clearable multiple
                                            >
                                            </v-select>
                                        </validation-provider>
                                    </v-col>
                                </v-card-text>

                                <v-card-actions class="mb-3" style="position:absolute;top:10px;right:10px">
                                    <v-btn @click="dialog_edit = false;" style="color:red;max-width: 30px;padding: 0 0 0 5px;min-width: 10px!important;height: 30px!important;">
                                        <v-icon left>mdi-close</v-icon>
                                    </v-btn>
                                </v-card-actions>

                                <v-card-actions class="pb-5" style="display:flex;justify-content:center">
                                    <v-btn type="submit" color="primary" :disabled="invalid"
                                            @click.prevent="prepareEditCampain()">
                                        <v-icon left>mdi-content-save</v-icon>
                                        {{ $t('global.validation') }}
                                    </v-btn>
                                </v-card-actions>
                            </v-form>
                        </validation-observer>
                    </v-card>
                </v-dialog>

                <!-- CAMPAIN DELETE MODAL -->
                <v-dialog v-model="dialog_delete" max-width="500px">
                    <v-card>
                        <v-card-title>
                            <span class="headline">
                                {{ $t('campain.delete_campain') }}
                            </span>
                        </v-card-title>
                        <v-divider></v-divider>
                        <br/>

                        <v-card-actions>
                            <v-spacer/>
                            <v-btn @click="dialog_delete = false">
                                <v-icon left>mdi-close</v-icon>
                                {{ $t('global.cancel') }}
                            </v-btn>
                            <v-btn color="error" @click="confirmCampainDelete()">
                                <v-icon left>mdi-delete</v-icon>
                                {{ $t('global.delete') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <!-- SNACKBAR INFO DISPLAY -->
                <v-snackbar
                    v-model="snackbar"
                    :color="snackbar_type">
                    {{ snackbar_text }}
                    <template v-slot:action="{ attrs }">
                        <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                    </template>
                </v-snackbar>
            
                <!-- GLOBAL FILTERS -->
                <v-col class="row col mb-5 mt-5">
                    <div
                        v-for="filters in machine_filters_titles"
                        :key="filters.level"
                        class="mr-0"
                    >
                        <div
                        class="ma-auto col-10 pl-3 pr-15 v-chip v-chip--clickable v-chip--link v-chip--no-color theme--light v-size--default"
                        style="
                            color: black;
                            font-weight: 400;
                            display: flex;
                            justify-content: left;
                            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.29);
                            background-color: #d7d7d752;
                            font-size: 14px;
                            height: 40px;
                        "
                        @click="setDataFiltered(filters.level)"
                        >
                        <div
                            v-if="filters.level_name !== 'Tout voir'"
                            class="mr-3"
                            style="color: white;min-width: 30px; min-height: 20px; border-radius:20px;font-weight: bold;text-align: center;"
                            :style="{backgroundColor: filters.level_color}"
                        >{{filters.count}}</div>
                        {{filters.level_name}}
                        </div>
                    </div>
                </v-col> 

                <!-- REPORTS -->
                <div v-if="loading_reports == true" style="color:grey;text-align: center;">
                    <li class="rounded row notification_card mb-1 pa-10 mt-15 ml-6" style="color: grey;justify-content: center;">
                        <div style="color:grey;text-align: center;"><strong>Chargement en cours...</strong></div>
                    </li>
                </div>  

                <div v-if="loading_reports == false" flat color="white" class="pt-0 mb-3 mt-1">
                    <template>
                        <!-- REPORTS DATA -->
                        <ul v-if="reports && reports !== 'undefined' && reports !== null && typeof reports === 'object'" id="notifications-cards">
                            <!-- ACTIVE DATA -->
                            <li class="row pa-3" v-for="report in reports" :key="report" >
                                <div v-if="access_level <= 100" class="rounded pointer row notification_card mb-0 elevation-1" style="border: solid 1px #80808021;background-color: #ffffff;">
                                    <div class="row pa-3">
                                        <!--CHIPS-->
                                        <div v-for="steps in machine_filters" :key="steps">
                                            <div v-if="steps.level == report.alert_level && steps.level_name !== 'Tout voir'" class="col-0 mr-5" style="color:white;font-weight:800;display:flex;justify-content:center;text-shadow:2px 2px 4px rgba(0, 0, 0, 0.29); height:100%;min-width: 8px;border-radius:2px 0px 0px 2px;" :style="{backgroundColor: steps.level_color}"></div>
                                        </div>
  
                                        <!--INFOS-->
                                        <div class="col-3">
                                            <div class="row" style="align-items: center;">
                                                <div v-for="machine in machines" :key="machine">
                                                    <v-chip v-if="machine.id == report.id_machine" class="mb-5 mt-5" v-on="on" link :to="'/machine/' + report.id_machine + '/report'" style="font-weight: bold; background-color: #f3f3f3; border: 1px solid #e5e5e5;">{{machine.label}}</v-chip>
                                                </div>
                                                <!------------------- Info bulle ---------------------->
                                                <v-tooltip bottom dark class="mr-2" style="">
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                                                            <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <template v-slot:default>
                                                    <div style="min-width: 200px;">
                                                        <p>Cliquer sur le titre de l'équipement pour visualiser tout ce qui le concerne en détail.</br>
                                                           (Ex :  ses rapports, ses évènements ou encore ses caractéristiques.)
                                                        </p>
                                                    </div>
                                                    </template>
                                                </v-tooltip>
                                            </div>
                                            <div v-for="machine in machines" :key="machine">
                                                <div v-if="machine.id == report.id_machine" style="font-size: 12px;margin-left: 10px;">{{ $t('machines.ref') }}{{machine.uid}}</div>
                                                <div v-if="machine.id == report.id_machine" style="font-size: 12px;margin-left: 10px;">{{ $t('machines.building') }} {{machine.building.label}}</div>
                                            </div>
                                            <div v-for="steps in data_filters" :key="steps">
                                                <v-chip v-if="report.step === steps.level && steps.level_name !== 'Tout voir'" class="ml-2 mb-2 mt-2" :style="{backgroundColor: steps.level_color}" style="font-size: 11px;color:white;">
                                                    <v-icon class="mr-1" style="font-size: 12px;color:white" >{{ steps.level_icon }}</v-icon>
                                                    {{steps.level_name}}
                                                </v-chip>
                                            </div>
                                        </div>

                                        <!-- UPDATES -->
                                        <div v-if="report.step === 0" class="col-6 pa-12" style="border-left: 1px solid #d3d3d36e; font-size:12px">
                                            <div v-if="report.step === 0">
                                                <li class="rounded row notification_card pa-5" style="border: 2px dotted #80808059;color: grey;justify-content: center;">
                                                    <div style="color:grey;text-align: center;">Pas de recommandations associées. <br> Pour en ajouter veuillez éditer le rapport.</div>
                                                </li>
                                            </div>
                                        </div>
                                        <div v-if="report.step  > 0" class="col-6 pa-1 pl-10 pr-10" style="border-left: 1px solid #d3d3d36e; font-size:12px">
                                            <div v-if="report.step > 0">
                                                <div v-if="report.step > 0" class="pa-5" style="border-bottom: 1px solid #d3d3d36e;font-size: 12px;margin-left: 10px;font-weight:bold;display: flex;">
                                                    <p>Etat de santé de la machine : {{ report.indice }}%</p>
                                                </div>
                                                <div v-if="report.preco === null" class="pa-5" style="border-bottom: 1px solid #d3d3d36e;font-size: 12px;margin-left: 10px;">Aucune préconisation associée.</div>
                                                <div v-else v-html="report.preco" class="pa-5" style="font-size: 12px;margin-left: 10px;border-bottom: 1px solid #d3d3d36e;"></div>
                                                <v-chip v-for="tag in getReportTags(report)" class="pl-3 pr-3 ma-2" link :key="tag">
                                                    {{ tag }}
                                                </v-chip>
                                            </div>
                                        </div>

                                        <!-- ACTIONS -->
                                        <v-col v-if="access_level <= 99" class="col pa-auto" style="border-left: 1px solid #d3d3d36e;">
                                            <v-card-actions v-if="campains[0].closed_at == null && access_level <= 99 && (report.step == 1 || report.step == 4)" class="pa-1 col col" style="display:flex;justify-content:center">
                                                <v-btn class="col col" type="submit" :disabled="invalid" style="color: #017f00;background-color: #a3f182b3;border:1px solid #017f009e !important"
                                                        @click.prevent="setReportState(report, 1)">
                                                    <v-icon center style="font-size:28px;margin-right:10px;">mdi-book-clock-outline</v-icon>
                                                    Demander une validation
                                                </v-btn>
                                            </v-card-actions>

                                            <v-card-actions v-if="campains[0].closed_at == null && access_level <= 99 && access_level >= 96 && report.step == 2" class="pa-1 col col" style="display:flex;justify-content:center">
                                                <v-btn class="col col" type="submit" :disabled="invalid" style="color: #930000;background-color: #d7000063;border:1px solid #d700005e !important"
                                                        @click.prevent="setReportState(report, 2)">
                                                    <v-icon center style="font-size:28px;margin-right:10px;">mdi-book-cancel-outline</v-icon>
                                                    Relancer l'édition
                                                </v-btn>
                                            </v-card-actions>
                                            <v-card-actions v-if="campains[0].closed_at == null && access_level <= 95 && report.step == 2" class="pa-1 col col" style="display:flex;justify-content:center">
                                                <v-btn class="col col" type="submit" :disabled="invalid" style="color: #9f4300;background-color: #e5b673;border:1px solid #a77d62 !important"
                                                        @click.prevent="setReportState(report, 4)">
                                                    <v-icon center style="font-size:28px;margin-right:10px;">mdi-book-cancel-outline</v-icon>
                                                    Refuser la validation
                                                </v-btn>
                                            </v-card-actions>
                                            <v-card-actions v-if="campains[0].closed_at == null && access_level <= 95 && (report.step == 1 || report.step == 2 || report.step == 4)" class="pa-1 col col" style="display:flex;justify-content:center">
                                                <v-btn class="col col" type="submit" :disabled="invalid" style="color: #017f00;background-color: #a3f182b3;border:1px solid #017f009e !important"
                                                        @click.prevent="setReportState(report, 3)">
                                                    <v-icon center style="font-size:28px;margin-right:10px;">mdi-book-check-outline</v-icon>
                                                    Valider le rapport
                                                </v-btn>
                                            </v-card-actions>

                                            <v-card-actions v-if="campains[0].closed_at == null && access_level <= 95 && report.step == 3" class="pa-1 col col" style="display:flex;justify-content:center">
                                                <v-btn class="col col" type="submit" :disabled="invalid" style="color: #930000;background-color: #d7000063;border:1px solid #d700005e !important"
                                                        @click.prevent="setReportState(report, 5)">
                                                    <v-icon center style="font-size:28px;margin-right:10px;">mdi-book-check-outline</v-icon>
                                                    Annuler la validation
                                                </v-btn>
                                            </v-card-actions>

                                            <v-card-actions v-if="campains[0].closed_at == null && (access_level <= 99 && report.step == 3) || (access_level <= 95 && report.step == 6)" class="pa-1 col col" style="display:flex;justify-content:center">
                                                <v-btn class="col col" type="submit" :disabled="invalid" style="color: white;background-color: #0e4cef;border: 1px solid #0e4cef !important;"
                                                        @click.prevent="setReportState(report, 6)">
                                                    <v-icon center style="font-size:28px;margin-right:10px;">mdi-book-play-outline</v-icon>
                                                    Publier le rapport
                                                </v-btn>
                                            </v-card-actions>
                                            <v-card-actions v-if="campains[0].closed_at == null && access_level <= 95 && report.step == 5" class="pa-1 col col" style="display:flex;justify-content:center">
                                                <v-btn class="col col" type="submit" :disabled="invalid" style="color: #930000;background-color: #d7000063;border:1px solid #d700005e !important"
                                                        @click.prevent="setReportState(report, 7)">
                                                    <v-icon center style="font-size:28px;margin-right:10px;">mdi-book-cog-outline</v-icon>
                                                    Retravailler le rapport
                                                </v-btn>
                                            </v-card-actions>
                                            <v-card-actions v-if="access_level <= 99 && report.step == 5" class="pa-1 col col" style="display:flex;justify-content:center">
                                                <v-btn class="col col" type="submit" :disabled="invalid" style="color: white;background-color: #014181;border:1px solid #014181 !important"
                                                    v-on="on" link :to="'/machine/' + report.id_machine + '/report'">
                                                    Consulter le rapport
                                                </v-btn>
                                            </v-card-actions>
                                            <v-card-actions v-if="campains[0].closed_at == null && access_level <= 99" class="pa-1 col col" style="display:flex;justify-content:center">
                                                <v-btn class="col col" type="submit" :disabled="invalid" style="color: white;background-color: #014181;border:1px solid #014181 !important"
                                                    v-on="on" link :to="'/machine/redit/' + report.id ">
                                                    Modifier le rapport
                                                </v-btn>
                                            </v-card-actions>
                                        </v-col>
                                    </div>
                                </div>

                                <div v-if="report.released_at == null && access_level > 99" class="rounded pointer row notification_card mb-0 elevation-1" style="border: solid 1px #80808021;background-color: #ffffff;">
                                    <div class="row pa-3">
                                        <!--CHIPS-->
                                        <div v-for="steps in machine_filters" :key="steps">
                                            <div v-if="steps.level == report.alert_level && steps.level_name !== 'Tout voir'" class="col-0 mr-5" style="color:white;font-weight:800;display:flex;justify-content:center;text-shadow:2px 2px 4px rgba(0, 0, 0, 0.29); height:100%;min-width: 8px;border-radius:2px 0px 0px 2px;background-color: grey;"></div>
                                        </div>
  
                                        <!--INFOS-->
                                        <div class="col-3">
                                            <div class="row" style="align-items: center;">
                                                <div v-for="machine in machines" :key="machine">
                                                    <v-chip v-if="machine.id == report.id_machine" class="mb-5 mt-5" v-on="on" link :to="'/machine/' + report.id_machine + '/report'" style="font-weight: bold; background-color: #f3f3f3; border: 1px solid #e5e5e5;">{{machine.label}}</v-chip>
                                                </div>
                                                <!------------------- Info bulle ---------------------->
                                                <v-tooltip bottom dark class="mr-2" style="">
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                                                            <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <template v-slot:default>
                                                    <div style="min-width: 200px;">
                                                        <p>Cliquer sur le titre de l'équipement pour visualiser tout ce qui le concerne en détail.</br>
                                                           (Ex :  ses rapports, ses évènements ou encore ses caractéristiques.)
                                                        </p>
                                                    </div>
                                                    </template>
                                                </v-tooltip>
                                            </div>
                                            <div v-for="machine in machines" :key="machine">
                                                <div v-if="machine.id == report.id_machine" style="font-size: 12px;margin-left: 10px;">{{ $t('machines.ref') }}{{machine.uid}}</div>
                                                <div v-if="machine.id == report.id_machine" style="font-size: 12px;margin-left: 10px;">{{ $t('machines.building') }} {{machine.building.label}}</div>
                                            </div>
                                            <div v-for="steps in data_filters" :key="steps">
                                                <v-chip v-if="report.step === steps.level && steps.level_name !== 'Tout voir'" class="ml-2 mb-2 mt-2" :style="{backgroundColor: steps.level_color}" style="font-size: 11px;color:white;">
                                                    <v-icon class="mr-1" style="font-size: 12px;color:white" >{{ steps.level_icon }}</v-icon>
                                                    {{steps.level_name}}
                                                </v-chip>
                                            </div>
                                        </div>

                                        <!-- UPDATES -->
                                        <div class="col pa-12" style="border-left: 1px solid #d3d3d36e; font-size:12px;background-color: whitesmoke;">
                                            <div>
                                                <li class="rounded row notification_card pa-5" style="border: 2px dotted #80808059;color: grey;justify-content: center;">
                                                    <div style="color:grey;text-align: center;">Ce rapport n'est pas encore terminé. <br> Vous recevrez une notification dés qu'il sera disponible.</div>
                                                </li>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="report.released_at != null && access_level > 99" class="rounded pointer row notification_card mb-0 elevation-1" style="border: solid 1px #80808021;background-color: #ffffff;">
                                    <div class="row pa-3">
                                        <!--CHIPS-->
                                        <div v-for="steps in machine_filters" :key="steps">
                                            <div v-if="steps.level == report.alert_level && steps.level_name !== 'Tout voir'" class="col-0 mr-5" style="color:white;font-weight:800;display:flex;justify-content:center;text-shadow:2px 2px 4px rgba(0, 0, 0, 0.29); height:100%;min-width: 8px;border-radius:2px 0px 0px 2px;" :style="{backgroundColor: steps.level_color}"></div>
                                        </div>
  
                                        <!--INFOS-->
                                        <div class="col-3">
                                            <div class="row" style="align-items: center;">
                                                <div v-for="machine in machines" :key="machine">
                                                    <v-chip v-if="machine.id == report.id_machine" class="mb-5 mt-5" v-on="on" link :to="'/machine/' + report.id_machine + '/report'" style="font-weight: bold; background-color: #f3f3f3; border: 1px solid #e5e5e5;">{{machine.label}}</v-chip>
                                                </div>
                                                <!------------------- Info bulle ---------------------->
                                                <v-tooltip bottom dark class="mr-2" style="">
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                                                            <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <template v-slot:default>
                                                    <div style="min-width: 200px;">
                                                        <p>Cliquer sur le titre de l'équipement pour visualiser tout ce qui le concerne en détail.</br>
                                                           (Ex :  ses rapports, ses évènements ou encore ses caractéristiques.)
                                                        </p>
                                                    </div>
                                                    </template>
                                                </v-tooltip>
                                            </div>
                                            <div v-for="machine in machines" :key="machine">
                                                <div v-if="machine.id == report.id_machine" style="font-size: 12px;margin-left: 10px;">{{ $t('machines.ref') }}{{machine.uid}}</div>
                                                <div v-if="machine.id == report.id_machine" style="font-size: 12px;margin-left: 10px;">{{ $t('machines.building') }} {{machine.building.label}}</div>
                                            </div>
                                            <div v-for="steps in data_filters" :key="steps">
                                                <v-chip v-if="report.step === steps.level && steps.level_name !== 'Tout voir'" class="ml-2 mb-2 mt-2" :style="{backgroundColor: steps.level_color}" style="font-size: 11px;color:white;">
                                                    <v-icon class="mr-1" style="font-size: 12px;color:white" >{{ steps.level_icon }}</v-icon>
                                                    {{steps.level_name}}
                                                </v-chip>
                                            </div>
                                        </div>

                                        <!-- UPDATES -->
                                        <div v-if="report.step === 0" class="col-6 pa-12" style="border-left: 1px solid #d3d3d36e; font-size:12px">
                                            <div v-if="report.step === 0">
                                                <li class="rounded row notification_card pa-5" style="border: 2px dotted #80808059;color: grey;justify-content: center;">
                                                    <div style="color:grey;text-align: center;">Pas de recommandations associées. <br> Pour en ajouter veuillez éditer le rapport.</div>
                                                </li>
                                            </div>
                                        </div>
                                        <div v-if="report.step  > 0" class="col-6 pa-1 pl-10 pr-10" style="border-left: 1px solid #d3d3d36e; font-size:12px">
                                            <div v-if="report.step > 0">
                                                <div v-if="report.step > 0" class="pa-5" style="border-bottom: 1px solid #d3d3d36e;font-size: 12px;margin-left: 10px;font-weight:bold;display: flex;">
                                                    <p>Etat de santé de la machine : {{ report.indice }}%</p>
                                                </div>

                                                <div v-if="report.preco === null" class="pa-5" style="border-bottom: 1px solid #d3d3d36e;font-size: 12px;margin-left: 10px;">Aucune préconisation associée.</div>
                                                <div v-else v-html="report.preco" class="pa-5" style="font-size: 12px;margin-left: 10px;border-bottom: 1px solid #d3d3d36e;"></div>
                                                <v-chip v-for="tag in getReportTags(report)" class="pl-3 pr-3 ma-2" link :key="tag">
                                                    {{ tag }}
                                                </v-chip>
                                            </div>
                                        </div>

                                        <!-- ACTIONS -->
                                        <v-col v-if="access_level > 100" class="col pa-auto" style="border-left: 1px solid #d3d3d36e;">
                                            <v-card-actions v-if="access_level > 100 && report.step == 5" class="pa-1 col col" style="display:flex;justify-content:center">
                                                <v-btn class="col col" type="submit" :disabled="invalid" style="color: white;background-color: #014181;border:1px solid #014181 !important"
                                                    v-on="on" link :to="'/machine/' + report.id_machine + '/report'">
                                                    <v-icon center style="font-size:28px;margin-right:10px;">mdi-book-check-outline</v-icon>
                                                    Consulter le rapport
                                                </v-btn>
                                            </v-card-actions>
                                        </v-col>
                                    </div>
                                </div>
                            </li>
                        </ul>

                        <!-- REPORTS DATA VOID -->
                        <ul v-else id="notifications-cards">
                            <li class="rounded row notification_card mb-1 pa-10" style="border: 2px dotted #80808059;color: grey;justify-content: center;">
                                <div style="color:grey;text-align: center;">La campagne ne comporte aucun raports disponibles. <br> Pour en ajouter veuillez associer une ou plusieurs machines à celle-ci.</div>
                            </li>
                        </ul>
                    </template>
                </div>
            </div>
            
            <!--FILTER MAX SIZE MESSAGE ITEM-->
            <template v-slot:item.message="{ item }">
                {{ item.message.substring(0, 100) + '...' }}
            </template>

            <!--ACTION BUTTONS -->
            <template v-slot:item.action="{ item }">
                <v-icon class="pointer" @click="setDataTrash(item, 1)" v-if="!campains_trash">
                    mdi-eye-check
                </v-icon>
                <v-icon class="pointer" @click="setDataTrash(item, 0)" v-if="campains_trash">
                    mdi-eye-remove
                </v-icon>
                <v-icon class="pointer" @click="openDialogShow(item)">
                    mdi-arrow-expand-all
                </v-icon>
            </template>

            <!-- Modal next update -->
            <v-dialog v-model="dialog_next_update" max-width="500px">
                <v-card>
                    <v-card-title>
                        <span class="headline">
                            Fonctionnalité bientôt disponible
                        </span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <br/>
                    <p class="pl-4 pr-4">
                        Cette fonctionnalité est en cours de développement et sera bientôt disponible.
                        Elle vous permettra entre autre de filter et de rechercher précisément via des mots clés votre cible.
                        Un rapport, une machine, une référence...
                        Le but, vous faire gagner du temps ! 
                    </p>
                    <!-- Bouton pour fermer -->
                    <v-card-actions class="mb-3" style="position:absolute;top:20px;right:2px">
                        <v-btn @click="dialog_show = false;" style="color:red;max-width: 30px;padding: 0 0 0 5px;min-width: 10px!important;height: 30px!important;">
                            <v-icon left>mdi-close</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-col>
    </v-col>
</template>

<script>
import {CAMPAIN,SUBASSEMBLIES,MACHINES,REPORT,USERS,SITES} from "../../../api";
import moment from "moment";

export default {
    name: "CampainDetail",
    data() {
        return {
            // CAMPAINS ----------------
            raw_campains: [],
            campains: [],
            raw_trashed_campains: [],
            trashed_campains: [],
            //-
            campains_trash:false,
            dialog_next_update: false,
            campains_available:false,
            deprecated_length:5,
            deprecated_available:false,
            //-
            active_campains_count:0,
            inactive_campains_count:0,
            global_campains_count:0,
            //-
            raw_reports:[],
            reports:[],
            available_reports_count:0,
            
            // GLOBAL ----------------
            loading_datatable:true,
            loading_reports:true,
            loading: true,
            access_level: this.$store.state.access_level,
            id: this.$route.params.id,
            machine_listed: "",
            //-
            search: '',
            filter_mode:9,
            edited_index: null,
            invalid: false,
            //-
            dialog_edit: false,
            dialog_delete: false,
            delete_id: null,
            deleted_index: null,
            
            // ARRAYS ----------------
            states_OFFLINE: [
                {
                    level: 3,
                    level_name: "Etablissement des rapports",
                    level_icon: "mdi-book-sync",
                    level_color: "#089d2d",
                    level_bg: "#089d2d1c",
                    level_border: "1px solid #089d2d",
                    count:0
                },
                {
                    level: 2,
                    level_name: "Analyses en cours",
                    level_icon: "mdi-cloud-sync",
                    level_color: "#014181",
                    level_bg: "#e0e8f0",
                    level_border: "1px solid #014181",
                    count:0
                },
                {
                    level: 1,
                    level_name: "Mesures en cours",
                    level_icon: "mdi-account-hard-hat",
                    level_color: "#014181",
                    level_bg: "#e0e8f0",
                    level_border: "1px solid #014181",
                    count:0
                },
                {
                    level: 0,
                    level_name: "En cours de préparation",
                    level_icon: "mdi-cog",
                    level_color: "#c97700",
                    level_bg: "#9d60081c",
                    level_border: "1px solid #c97700",
                    count:0
                },              
            ],
            states_ONLINE: [
                {
                    level: 3,
                    level_name: "Surveillance active (Online)",
                    level_icon: "mdi-cloud-sync",
                    level_color: "#089d2d",
                    level_bg: "#089d2d1c",
                    level_border: "1px solid #089d2d",
                    count:0
                },
                {
                    level: 2,
                    level_name: "Configuration en cours",
                    level_icon: "mdi-cog",
                    level_color: "#014181",
                    level_bg: "#e0e8f0",
                    level_border: "1px solid #014181",
                    count:0
                },
                {
                    level: 1,
                    level_name: "Instrumentation en cours",
                    level_icon: "mdi-account-hard-hat",
                    level_color: "#014181",
                    level_bg: "#e0e8f0",
                    level_border: "1px solid #014181",
                    count:0
                },
                {
                    level: 0,
                    level_name: "En cours de préparation",
                    level_icon: "mdi-cog",
                    level_color: "#c97700",
                    level_bg: "#9d60081c",
                    level_border: "1px solid #c97700",
                    count:0
                },              
            ],
            data_filters: [
                {
                    level: 9,
                    level_name: "Tout voir",
                    level_color: "#d7d7d752",
                    count:this.global_notifications_count
                },
                {
                    level: 3,
                    level_name: "Rapport terminé",
                    level_color: "#4caf50",
                    level_icon: "mdi-clipboard-check",
                    count:0
                },
                {
                    level: 2,
                    level_name: "Rapport en cours de validation",
                    level_color: "#0e4cef",
                    level_icon: "mdi-clipboard-pulse",
                    count:0
                },
                {
                    level: 1,
                    level_name: "Rapport en cours d'édition",
                    level_color: "#ef780e",
                    level_icon: "mdi-clipboard-edit",
                    count:0
                },
                {
                    level: 0,
                    level_name: "Rapport en attente",
                    level_color: "grey",
                    level_icon: "clipboard-clock",
                    count:0
                },              
            ],

            machine_filters: [
                { level: 9, level_name: "Tout voir", level_color: "#d7d7d752", count:this.global_notifications_count},
                { level: -1, level_name: "Rapport en attente", level_color: "grey", count:0, annotation: "Etat à définir"},

                { level: 1, level_name: "Pour information : EDS 100% (vert)", level_color: "#4CAF50", count:0, percent: 100, annotation: "Etat neuf" },
                { level: 2, level_name: "Pour information : EDS 90% (vert)", level_color: "#4CAF50", count:0, percent: 90, annotation: "Comportement vibratoire correct" },
                { level: 3, level_name: "Pour information : EDS 80% (vert)", level_color: "#4CAF50", count:0, percent: 80, annotation: "Pour information" },

                { level: 11, level_name: "A suivre : EDS 70% (jaune)", level_color: "#FFCB05", count:0, percent: 70, annotation: "A suivre lors de la prochaine campagne" },
                { level: 12, level_name: "A suivre : EDS 60% (jaune)", level_color: "#FFCB05", count:0, percent: 60, annotation: "A suivre de manière rapprochée" },
                { level: 13, level_name: "A suivre : EDS 50% (jaune)", level_color: "#FFCB05", count:0, percent: 50, annotation: "Expertise complémentaire préconisée" },

                { level: 21, level_name: "Importance élevée : EDS 40% (rouge)", level_color: "#F44336", count:0, percent: 40, annotation: "Action à prévoir au prochaine arrêt technique" },
                { level: 22, level_name: "Importance élevée : EDS 30% (rouge)", level_color: "#F44336", count:0, percent: 30, annotation: "Action à prévoir au prochaine arrêt technique" },
                { level: 23, level_name: "Importance élevée : EDS 20% (rouge)", level_color: "#F44336", count:0, percent: 20, annotation: "Action à prévoir au prochaine arrêt technique" },
            
                { level: 31, level_name: "Urgence : EDS 10% (Noir)", level_color: "black", count:0, percent: 10, annotation: "Action à prévoir dans les plus brefs délais" },
                { level: 32, level_name:"Urgence : EDS 0% (Noir)", level_color: "black", count:0, percent: 0, annotation: "Arrêt de la machine recommandé" },               
            ],

            machine_filters_titles: [
                {
                    level: 9,
                    level_name: "Tout voir",
                    level_color: "#d7d7d752",
                    count:this.global_notifications_count
                },
                {
                    level: 3,
                    level_name: "Urgence",
                    level_color: "black",
                    count:0
                },
                {
                    level: 2,
                    level_name: "Importance élevée",
                    level_color: "#F44336",
                    count:0
                },
                {
                    level: 1,
                    level_name: "A suivre",
                    level_color: "#FFCB05",
                    count:0
                },
                {
                    level: 0,
                    level_name: "Pour information",
                    level_color: "#4CAF50",
                    count:0
                },
                {
                    level: -1,
                    level_name: "En attente",
                    level_color: "grey",
                    count:0
                },                
            ],
            edited_campain: {
                id: null,
                name: '',
                presta:'',
                type: '',
                affaire_number:'',
                manager: '',
                id_park: null,
                machine_list:[],
                description: '',
                tags:[],                          
            },
            analysts: [],
            team: [],
            machines: [],
            machines_count:0,
            reports_count:0,
            reports_done_count:0,
            sites: [],
            prestas:[
                {id:0, name:"Mesures vibratoires"},
                {id:1, name:"Thermographie"},
                {id:2, name:"Mesures ultrasons"},
                {id:3, name:"Equilibrages"},
                {id:4, name:"Expertise avancée"},
                {id:5, name:"Surveillance Online"},
            ],
            types:[
                {id:0, name:"Ponctuel"},
                {id:1, name:"Annuel"},
                {id:2, name:"Semestriel"},
                {id:3, name:"Quadrmestriel"}, 
                {id:4, name:"Trimestriel"},
                {id:5, name:"Continu"},
            ],
            tags:[
                {id:0, name:"Caractérisation machine"},
                {id:1, name:"Création BDD"},
                {id:2, name:"Etude approfondie"},
                {id:3, name:"Machines outils"},
                {id:4, name:"Machines tournantes"},
                {id:5, name:"Surveillance à distance"},
                {id:6, name:"Trés basse vitesse"},
                {id:7, name:"Historique"},
            ],
            timeProps: {
                format: "24hr"
            },
            textFieldProps: {
                prependInnerIcon: "mdi-calendar",
                solo: true,
                rules: [v => !!v || this.$t('vee-validation.required_date_operation')]
            },
        }
    },
    computed: {
        dialogTitle() {
            return this.edited_index === null ? this.$t('campain.add') : this.$t('campain.edit');
        }
    },
    created() {
        moment.locale('fr');
        this.initialize();
    },
    methods: {
        // COLLECTING ALL NEEDED DATAS
        initialize() {
            this.loading_datatable = true;
            this.loading_reports=true;
            this.getData();

        },
        getData() {
            // GETTING CAMPAINS
            this.axios.get(CAMPAIN + '/specific/' + this.id)
            
            .then((success) => {
                success.data.forEach(item => {
                    item.created_at = moment(item.created_at).format("DD/MM/YYYY");
                    item.machine_list = JSON.parse(item.machine_list);
                })
                this.filter_mode = 9;
                this.raw_campains = [];
                this.campains = [];

                if (this.checkDataAvailable(success.data)){
                    this.raw_campains = success.data;
                    this.campains = success.data;
                    this.machine_listed = this.campains[0].machine_list.join('-');
                }

            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");

            }).finally(() => {
                this.loading_datatable = false;
                this.getSideDatas();
            });
        },
        getSideDatas(){
            this.axios.get(REPORT + '/campain/' + this.id)
            
            .then((success) => {
                this.raw_reports = [];
                this.reports = [];
                this.raw_reports = success.data;
                
                success.data.forEach(report => {
                    if(this.campains[0].machine_list.includes(report.id_machine)){
                        this.reports.push(report);
                    }
                }) 

                this.getDataCount();

            }).catch(() => {
                this.showSnackbar('error', "Side datas error.");
            }).finally(() => {
                this.getAnalysts();
                this.getTeam();
                this.getMachines();
                this.getSites();
            })
        },
        getAnalysts(){
            this.axios.get(USERS + '/analysts')
            
            .then((success) => {
                this.analysts = [];
                this.analysts = success.data;

            }).catch(() => {
                this.showSnackbar('error', "Analysts datas error.");
            })
        },
        getTeam(){
            this.axios.get(USERS + '/team')
            
            .then((success) => {
                this.team = [];
                this.team = success.data;

            }).catch(() => {
                this.showSnackbar('error', "Team datas error.");
            })
        },
        getMachines() {
            this.axios.get(MACHINES + '/specific/' + this.machine_listed)
            
            .then((success) => {
                this.machines = [];
                this.machines = success.data;
                this.machines_count = this.machines.length;

            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");
            }).finally(() => {
                this.loading_reports = false;
            })
        },
        getSites() {
            this.axios.get(SITES + '/resume')
            
            .then((success) => {
                this.sites = [];
                this.sites = success.data;

            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");
            })
        },
        // DISPLAY DATA MANAGEMENT
        checkDataAvailable(datas){
            if(typeof datas[0] === 'undefined') {                    
                this.campains_available = false;
                return false;
            }else {
                this.campains_available = true;
                return true;
            }
        },
        setDataFiltered (filter) {
            this.loading_reports = true;
            this.filter_mode = filter;
            //this.deprecated_length=5;
            this.dataTreatment();
            //this.dataTrashTreatment();
            this.loading_reports = false;
        },
        dataTreatment(){
            if(this.checkDataAvailable(this.raw_reports)) {                    
                this.reports = [];

                if(this.filter_mode === 9){
                    this.reports = this.raw_reports;

                }else{
                    this.raw_reports.forEach(report => {
                        if (report.alert_level >= 1 && report.alert_level <= 3 && this.filter_mode == 0){
                            this.reports.push(report);
                        }

                        if (report.alert_level >= 11 && report.alert_level <= 13 && this.filter_mode == 1){
                            this.reports.push(report);
                        }

                        if (report.alert_level >= 21 && report.alert_level <= 23 && this.filter_mode == 2){
                            this.reports.push(report);
                        }

                        if (report.alert_level >= 31 && report.alert_level <= 32 && this.filter_mode == 3){
                            this.reports.push(report);
                        }

                    })
                }
                
                // Then check result to si if there is somehting left in chosen datas
                this.checkDataAvailable(this.reports);
            }
        },
        getCampainTags(campain) {
            if (campain.tags != "") {
                return JSON.parse(campain.tags);
            }
        },
        getReportTags(report) {
            if (report.tags != "") {
                return JSON.parse(report.tags);
            }
        },
        getDataCount() {
            // Reset all counts
            this.machine_filters_titles.forEach(data => {data.count = 0});
            
            // Get all last counts
            this.reports.forEach(item => {  
                if(item.step === 4){
                    this.available_reports_count = this.available_reports_count +1;
                }
                
                this.machine_filters_titles.forEach(filter => { 
                    if (item.alert_level >= 1 && item.alert_level <= 3 && filter.level == 0){
                        filter.count = filter.count +1;
                    }

                    if (item.alert_level >= 11 && item.alert_level <= 13 && filter.level == 1){
                        filter.count = filter.count +1;
                    }

                    if (item.alert_level >= 21 && item.alert_level <= 23 && filter.level == 2){
                        item.count = filter.count +1;
                    }

                    if (item.alert_level >= 31 && item.alert_level <= 32 && filter.level == 3){
                        filter.count = filter.count +1;
                    }

                    if (item.alert_level == -1 && filter.level == -1){
                        filter.count = filter.count +1;
                    }
                });
            });

            // FREEUP LOADING STEP
            this.loading_datatable = false
        },
        viewMore() {
            this.deprecated_length = this.deprecated_length + 10;
            this.dataTrashTreatment();
        },
        // EXISTING TREATMENTS
        // MODALS
        openDialogUpdateCampain(campain) {
            this.edited_index = this.campains.indexOf(campain);
            this.edited_campain = Object.assign({}, campain);
            this.dialog_edit = true;
        },
        openDialogDeleteCampain(campain) {
            this.deleted_index = this.campains.indexOf(campain);
            this.delete_id = campain.id;
            this.dialog_delete = true;
        },
        openDialogAddReport() {
            this.sub_edit_location = "add"
            this.modal_sub_title = "Ajouter un sous-ensemble";
            this.edited_subassembly = [];
            this.subassembly_id = "";
            // Open modal
            this.sub_dialog_edit = true;
        },
        openDialogEditReport(subassembly) {
            this.loading_bind_virtual_devices = true;
            this.modal_sub_title = "Modifier le sous-ensemble ";
            this.sub_edit_location = "edit"
            this.edited_subassembly = subassembly;
            this.subassembly_id = subassembly.id;
            this.virtual_devices_bind_to_subassembly = [];
            // Open modal
            this.sub_dialog_edit = true;

            // DEBUG ----------------------------------------------------------------------------
            console.log(this.edited_subassembly);

            this.axios.get(VIRTUAL_DEVICES + '/not_bind/subassembly/' + this.subassembly_id)
            .then((success) => {
                this.virtual_devices = success.data;
                this.dialog_bind_virtual_devices = true;

                this.virtual_devices.forEach(virtual_device => {
                    if (virtual_device.subassembly_id !== null) this.virtual_devices_bind_to_subassembly.push(virtual_device);
                });

            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'))

            }).finally(() => {
                this.loading_bind_virtual_devices = false
            });

        },
        openDialogDeleteReport(subassembly) {
            this.deleted_index = this.subassemblies.indexOf(subassembly);
            this.delete_id = subassembly.id;
            this.sub_dialog_delete = true;
        },
        openDialogShow(){
            console.log("open dialog show requested");
        },
        closeDialog() {
            this.$refs.form.reset();
            this.$refs.observer.reset();
            this.dialog_edit = false;
        },
        closeReportDialog() {
            this.sub_dialog_edit = false;
            this.sub_dialog_delete = false;
        },
        // POST REQUESTS PART
        prepareEditCampain() {
            if (this.edited_index !== null) {
                this.updateCampain();
            } else {
                this.addCampain();
            }
            this.closeDialog();
        },
        updateCampain() {
            this.axios.patch(CAMPAIN + '/' + this.edited_campain.id,
                this.edited_campain
            ).then((success) => {
                Object.assign(this.campains[this.edited_index], success.data);
                this.edited_index = null;
                this.showSnackbar('success', this.$t('global.success_to_edit'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            }).finally(() => {
                this.getData();
                this.loading_datatable = true;
                this.closeDialog();
            })
        },
        updateStep(id, step_id){
            this.axios.patch(CAMPAIN + '/step/' + id + "/" + step_id
            ).then((success) => {
                this.getData();

            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            })
        },
        confirmCampainDelete() {
            this.axios.delete(
                CAMPAIN + '/delete/' + this.delete_id
            ).then(() => {
                this.campains.splice(this.deleted_index, 1);
                this.showSnackbar('success', this.$t('global.success_to_delete'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_delete'));
            }).finally(() => {
                this.loading_datatable = true;
                this.getData();
                this.dialog_delete = false;
            });
        },
        setClosed(id){
            this.axios.patch(CAMPAIN + '/close/' + id)
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.getData();
                    this.dialog_show = false;
                })
        },
        setOpened(id){
            this.axios.patch(CAMPAIN + '/open/' + id)
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.getData();
                    this.dialog_show = false;
                })
        },
        setReportState(report, state) {
            //  transition ASK VALIDATION STEP [2]
            if (state === 1) {
                this.axios.patch( REPORT + '/ask_validate/' + report.id)
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.initialize();
                })
            }
            //  transition UNASK VALIDATION STEP [1]
            if (state === 2) {
                this.axios.patch( REPORT + '/unask_validate/' + report.id)
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.initialize();
                })
            }
            //  transition VALIDATION STEP [3]
            if (state === 3) {
                this.axios.patch( REPORT + '/certify/' + report.id )
            
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.initialize();
                })
            }
            //  transition VALIDATION STEP [4]
            if (state === 4) {
                this.axios.patch( REPORT + '/failed_certify/' + report.id )
            
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.initialize();
                })
            }
            //  transition UNVALIDATION STEP [4]
            if (state === 5) {
                this.axios.patch( REPORT + '/uncertify/' + report.id )
            
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.initialize();
                })
            }
            //  transition RELEASE STEP [5]
            if (state === 6) {
                this.axios.patch( REPORT + '/release/' + report.id )
            
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.initialize();
                })
            }
            //  transition UNRELEASE STEP [6]
            if (state === 7) {
                this.axios.patch( REPORT + '/unrelease/' + report.id )
            
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.initialize();
                })
            }
        },
        // TO MANAGE FOR REPORTS MANAGEMENT
        //COLLECT DATAS
        getReports() {
            this.axios.get(
                SUBASSEMBLIES
            ).then((success) => {
                this.subassemblies = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'))
            }).finally(() => {
                this.loading = false
            });
        },
        // DATA TREATMENT
        prepareEditReports() {
            if (this.sub_edit_location === 'edit') {
                //this.updateSubassembly();
            } else {
                this.addReport();
            }

            this.closeDialog();
        },
        // REQUESTS
        addReport() {
                this.edited_subassembly.config = this.subassembly_configuration_choose;
                let formData = new FormData()
                formData.append('label', this.edited_subassembly.label);
                formData.append('subassembly_type', this.edited_subassembly.subassembly_type);
                formData.append('config', JSON.stringify(this.edited_subassembly.config));
                formData.append('note', this.edited_subassembly.note);
                formData.append('campain_id', this.edited_subassembly.campain_id);
                formData.append('path_image', this.edited_subassembly.path_image);

                this.axios.post(
                    SUBASSEMBLIES, formData, this.configurationFile
                ).then((success) => {
                    this.subassemblies.push(success.data);
                    this.showSnackbar('success', this.$t('global.success_to_add'));
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_add'));
                }).finally(() => {
                    this.getReports();
                    this.getData();
                    this.resetFormEditReport();
                    this.closeReportDialog();
                });
        },
        confirmReportDelete() {
            this.axios.delete(
                SUBASSEMBLIES + '/' + this.delete_id
            ).then(() => {
                this.subassemblies.splice(this.deleted_index, 1);
                this.showSnackbar('success', this.$t('global.success_to_delete'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_delete'));
            }).finally(() => {
                this.getReports();
                this.getData();
                this.closeReportDialog();
            });
        },
        resetFormEditReport() {
            this.$refs.formBindVirtualDevice.reset();
            this.$refs.observer.reset();
            this.subassembly_configuration = null;
            this.subassembly_configuration_choose = null;
        },
        // NOT USED TO BE IMPROVED
        onImageChanged() {
            if(this.edited_subassembly.path_image !== null) {
                let imageCheck = this.uploadFilesCheck(this.edited_subassembly.path_image,['image/png','image/jpg','image/jpeg'],2);
                if(!imageCheck.authorized) {
                    this.valid_image = false;
                    this.showSnackbar('error',imageCheck.output.toString());
                }
                else {
                    this.valid_image = true;
                }
            }
            if(this.edited_subassembly.path_image === null){
                this.valid_image = true;
            }
        },
        dialogNextUpdate() {
            if (this.dialog_next_update){
                this.dialog_next_update = false;
            }else{
                this.dialog_next_update = true;
            }
        },
    }
}
</script>