var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"2","outlined":""}},[_c('v-card-title',{staticClass:"justify-center"},[_vm._v("\n        "+_vm._s(_vm.$t('datatable_machine.title'))+"\n    ")]),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-1 datatable-setting-min-height",attrs:{"headers":_vm.headers,"items":_vm.site_machines,"sort-by":"id","loading":_vm.loading,"items-per-page":5,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"pt-3",attrs:{"flat":"","color":"white"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}}),_vm._v(" "),_c('v-col',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('global.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_vm._v(" "),_c('v-snackbar',{attrs:{"color":_vm.snackbar_type},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-icon',{attrs:{"color":"white"},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("mdi-close")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v("\n                    "+_vm._s(_vm.snackbar_text)+"\n                    ")])],1)]},proxy:true},{key:"item.label",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ path: '/machine/' + item.id}}},[_vm._v("\n                "+_vm._s(item.label)+"\n            ")])]}},{key:"item.path_plan",fn:function(ref){
var item = ref.item;
return [(item.path_plan)?_c('v-img',{staticClass:"mb-2 mt-2",attrs:{"aspect-ratio":"1","src":item.path_plan,"height":"200","contain":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0 background-loader-image",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black lighten-5"}})],1)]},proxy:true}],null,true)}):_vm._e()]}},{key:"item.building",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ path: '/building/' + item.building.id}}},[_vm._v("\n                "+_vm._s(item.building.label)+"\n            ")])]}},{key:"item.building.path_plan",fn:function(ref){
var item = ref.item;
return [(item.building.path_plan)?_c('v-img',{staticClass:"mb-2 mt-2",attrs:{"aspect-ratio":"1","src":item.building.path_plan,"height":"200","contain":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0 background-loader-image",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black lighten-5"}})],1)]},proxy:true}],null,true)}):_vm._e()]}},{key:"item.subassemblies",fn:function(ref){
var item = ref.item;
return [(item.subassemblies.length === 0)?_c('span',[_vm._v(_vm._s(_vm.$t('machines.no_subassemblies')))]):_c('v-chip-group',_vm._l((item.subassemblies),function(subassembly){return _c('v-chip',{key:subassembly.id,attrs:{"color":_vm.assetColor(subassembly),"text-color":"white"},on:{"click":function($event){return _vm.$router.push({ name: 'subassembly', params: {id: subassembly.id} })}}},[_vm._v("\n                    "+_vm._s(subassembly.label)+"\n                ")])}),1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"pointer"},[_vm._v("mdi-link-variant-off")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }