import Vue from 'vue';
import VueInternationalization from 'vue-i18n';
import Locale from '../traduction.js';

Vue.use(VueInternationalization);

export default new VueInternationalization({
    locale: 'fr',
    messages: Locale,
});
