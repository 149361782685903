<template>
    <v-card elevation="2" outlined>
        <v-card-title class="justify-center">
            {{ $t('global.tags') }}
        </v-card-title>
        <v-divider></v-divider>

        <v-data-table
            :headers="headers"
            :items="referential_tags"
            sort-by="id"
            class="elevation-1 datatable-setting-min-height"
            :loading="loading"
            :items-per-page="5"
            :search="search">

            <template v-slot:top>
                <v-alert
                    border="left"
                    colored-border type="warning"
                    elevation="2"
                    class="mr-4 ml-4 mt-4" dense block
                >
                    {{ $t('referential.info_datatable_tag') }}
                </v-alert>

                <v-toolbar flat color="white" class="pt-3">
                    <v-row>
                        <v-col cols="10">
                            <v-btn color="primary" class="white--text mr-3" @click="openDialogAddReferentialTag()">
                                {{ $t('referential.add_referential_tag') }}
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                :label="$t('global.search')"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-dialog v-model="dialog_edit" max-width="500px">
                        <v-card>
                            <validation-observer ref="observer" v-slot="{ invalid }">
                                <v-form ref="form">

                                    <v-card-title><span class="headline">{{ dialogTitle }}</span></v-card-title>
                                    <v-divider></v-divider>

                                    <v-card-text>
                                        <validation-provider :name="$t('global.value')" rules="required"
                                                             v-slot="{ errors }">
                                            <v-text-field
                                                :label="$t('global.value')"
                                                v-model="edited_referential_tag.label"
                                                :prepend-icon="'mdi-label-outline'"
                                                :error-messages="errors" solo
                                            />
                                        </validation-provider>

                                        <validation-provider :name="$t('referential.expiration_day')"
                                                             rules="required"
                                                             v-slot="{ errors }">
                                            <v-text-field
                                                :label="$t('referential.expiration_day')"
                                                v-model="edited_referential_tag.expiration_day"
                                                :prepend-icon="'mdi-counter'" solo
                                                type="number" :error-messages="errors"
                                            />
                                        </validation-provider>
                                    </v-card-text>

                                    <v-divider></v-divider>

                                    <v-card-actions>
                                        <v-spacer/>
                                        <v-btn @click="closeDialog()">
                                            <v-icon left>mdi-close</v-icon>
                                            {{ $t('global.close') }}
                                        </v-btn>
                                        <v-btn type="submit" color="primary" :disabled="invalid"
                                               @click.prevent="prepareEditReferentialTag()">
                                            <v-icon left>mdi-content-save</v-icon>
                                            {{ $t('global.validation') }}
                                        </v-btn>
                                    </v-card-actions>
                                </v-form>
                            </validation-observer>
                        </v-card>
                    </v-dialog>

                    <v-dialog v-model="dialog_delete" max-width="500px">
                        <v-card>
                            <v-card-title>
                                <span class="headline">{{ $t('referential.delete_referential_tag') }}</span>
                            </v-card-title>
                            <v-divider></v-divider>
                            <br/>

                            <v-card-actions>
                                <v-spacer/>
                                <v-btn @click="dialog_delete = false">
                                    <v-icon left>mdi-close</v-icon>
                                    {{ $t('global.cancel') }}
                                </v-btn>
                                <v-btn color="error" @click="confirmDelete()">
                                    <v-icon left>mdi-delete</v-icon>
                                    {{ $t('global.delete') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <v-snackbar
                        v-model="snackbar"
                        :color="snackbar_type">
                        {{ snackbar_text }}
                        <template v-slot:action="{ attrs }">
                            <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                        </template>
                    </v-snackbar>
                </v-toolbar>
            </template>

            <template v-slot:item.color="{ item }">
                <v-icon :color="item.color">
                    mdi-rectangle
                </v-icon>
                {{ item.color }}
            </template>


            <template v-slot:item.action="{ item }">
                <v-icon class="pointer" @click="openDialogUpdateReferentialTag(item)">mdi-pencil</v-icon>
                <v-icon class="pointer" @click="openDialogDeleteReferentialTag(item)">mdi-delete</v-icon>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
import {REFERENTIAL_TAGS} from "../../../../api";

export default {
    name: "DatatableTagReferential",
    data() {
        return {
            referential_tags: [],
            headers: [
                {text: this.$t('global.intern_number'), value: 'uid'},
                {text: this.$t('global.value'), value: 'label'},
                {text: this.$t('referential.expiration_day'), value: 'expiration_day'},
                {text: this.$t('global.actions'), value: 'action'}
            ],
            loading: true,
            search: '',
            invalid: false,

            dialog_edit: false,
            edited_index: null,
            edited_referential_tag: {
                id: null,
                label: '',
                expiration_day: null,
                color: ''
            },
            valid: true,

            dialog_delete: false,
            delete_id: null,
            deleted_index: null,
        }
    },
    computed: {
        dialogTitle() {
            return this.edited_index === null ? this.$t('referential.add_referential_tag') : this.$t('referential.edit_referential_tag');
        }
    },
    created() {
        this.getReferentialTags();
    },
    methods: {
        getReferentialTags() {
            this.axios.get(
                REFERENTIAL_TAGS
            ).then((success) => {
                this.referential_tags = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            }).finally(() => {
                this.loading = false
            });
        },

        openDialogAddReferentialTag() {
            if (this.edited_index !== null) {
                this.$refs.form.reset();
                this.$refs.observer.reset();
                this.edited_index = null;
            }

            this.dialog_edit = true;
        },

        openDialogUpdateReferentialTag(referential_tag) {
            this.edited_index = this.referential_tags.indexOf(referential_tag);
            this.edited_referential_tag = Object.assign({}, referential_tag);
            this.dialog_edit = true;
        },

        prepareEditReferentialTag() {
            if (this.edited_index !== null) {
                this.updateReferentialTag();
            } else {
                this.addReferentialTag();
            }
        },

        updateReferentialTag() {
            this.axios.patch(REFERENTIAL_TAGS + '/' + this.edited_referential_tag.id,
                this.edited_referential_tag
            ).then((success) => {
                Object.assign(this.referential_tags[this.edited_index], success.data);
                this.edited_index = null;
                this.showSnackbar('success', this.$t('global.success_to_edit'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            }).finally(() => {
                this.closeDialog();
            });
        },

        addReferentialTag() {
            this.axios.post(REFERENTIAL_TAGS, this.edited_referential_tag
            ).then((success) => {
                this.referential_tags.push(success.data);
                this.showSnackbar('success', this.$t('global.success_to_add'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_add'));
            }).finally(() => {
                this.closeDialog();
            });
        },

        openDialogDeleteReferentialTag(referential_tag) {
            this.deleted_index = this.referential_tags.indexOf(referential_tag);
            this.delete_id = referential_tag.id;
            this.dialog_delete = true;
        },

        confirmDelete() {
            this.axios.delete(
                REFERENTIAL_TAGS + '/' + this.delete_id
            ).then(() => {
                this.referential_tags.splice(this.deleted_index, 1);
                this.showSnackbar('success', this.$t('global.success_to_delete'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_delete'));
            }).finally(() => {
                this.dialog_delete = false;
            });
        },

        closeDialog() {
            this.dialog_edit = false;
            this.$refs.form.reset();
            this.$refs.observer.reset();
        }
    }
}
</script>
