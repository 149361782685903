<template>
    <v-card class="rounded-lg" elevation="2" outlined max-height="100px" height="100%" style="box-shadow: none!important;border:none!important;background-color: #d3d3d3!important;">

        <v-card-text class="pa-0">
            <layer-assets style="max-height:190px;border: 1px solid #b7b7b7;border-radius: 2px;text-align: center;background-color: #d3d3d3!important;" :assets-data="machine_data" :asset-type="'subassembly'"/>
        </v-card-text>
        <v-snackbar
            v-model="snackbar"
            :color="snackbar_type">
            {{ snackbar_text }}
            <template v-slot:action="{ attrs }">
                <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
            </template>
        </v-snackbar>
    </v-card>
</template>

<script>
import {MACHINES} from "../../../api";
import ModalAssetsEdit from "../ModalAssetsEdit";
import LayerAssets from "../LayerAssets";

export default {
    name: "MachineState",
    components: {
        ModalAssetsEdit,
        LayerAssets,
    },
    props: ["machineOn"],
    data() {
        return {
            machine_data: {
                path_image: '',
                assets: []
            },
            machine_id: this.$props.machineOn,
        };
    },
    created() {
        this.getMachine();
    },
    computed: {
        uploadAssetImageURI() {
            return `${MACHINES}/${this.machine_id}/image`;
        },
    },
    methods: {
        getMachine() {
            this.axios.get(
                MACHINES + "/" + this.machine_id + "/subassemblies/details"
            ).then((success) => {
                this.machine_data = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            });
        },

        checkActivation(activationDate) {
            let now = new Date();
            let activation = new Date(activationDate);
            let expiredDate = activation.setFullYear(activation.getFullYear() + 1);
            return now > expiredDate;
        },
        checkLastSeen(lastSeenDate) {
            let now = new Date();
            let lastSeen = new Date(lastSeenDate);
            lastSeen.setDate(lastSeen.getDate() + 1);

            return now > lastSeen;
        },
        snackbarAssetsEdit(item) {
            this.showSnackbar(item.type, item.text);
            this.getMachine();
        },
        saveState() {
            this.axios.put(
                MACHINES + '/' + this.machine_id + '/subassemblies/position', this.machine_data
            ).then(() => {
                this.showSnackbar('success', this.$t('global.success_to_edit'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            });
        },
    }
}
</script>
