<template>
    <div id="devices-add-remote-installer-stock" class="pa-4">
        <v-row no-gutters>
            <v-col>
                <p class="title mb-0 color-primary text-uppercase">
                    {{ $t('physical_devices_remote_installer_stock.title') }}
                </p>
            </v-col>
        </v-row>

        <v-card width="100%" class="mt-4" elevation="0">
            <v-form ref="form" v-model="valid">
                <v-card-text>
                    <v-autocomplete
                        v-model="selected_installer"
                        :rules="[v => !!v || this.$t('global.empty_field')]"
                        :items="installers"
                        :label="$t('physical_devices_remote_installer_stock.installer')"
                        :item-text="setInstallerName" item-value="id"
                        filled
                    >
                        <template v-slot:item="{item}">
                            {{item.first_name+ ' ' +item.last_name}}
                        </template>
                    </v-autocomplete>

                    <v-textarea
                        v-model="qr_code_input"
                        :label="$t('physical_devices_remote_installer_stock.device_list')"
                        :messages="$t('physical_devices_remote_installer_stock.device_list_description')"
                        :rules="[v => !!v || this.$t('global.empty_field')]"
                        auto-grow filled required
                    >
                    </v-textarea>

                    <template v-if="physical_devices_after_check">
                        <template v-for="(checked_device,index) in physical_devices_after_check">
                            <v-row class="mt-2 ml-4">
                                <v-chip v-if="checked_device.physical_device.DEV_EUI"
                                        :color="checked_device.color"
                                        :key="checked_device.physical_device.DEV_EUI"
                                        class="mb-2" small draggable dark
                                >
                                    <v-icon left class="mr-1" color="white" size="17">{{ checked_device.icon }}</v-icon>
                                    <span class="font-10">{{ checked_device.physical_device.DEV_EUI }}</span>

                                    <!--<v-icon right class="ml-2" color="white" size="15"
                                            @click="refreshChipsAfterDelete(index,checked_device.physical_device.DEV_EUI)"
                                    >
                                        mdi-close-circle
                                    </v-icon>-->
                                </v-chip>
                            </v-row>
                        </template>
                    </template>
                </v-card-text>

                <v-card-actions class="pr-4 pb-4">
                    <v-spacer/>
                    <v-btn color="primary" @click="validate" :disabled="!valid">
                        {{ $t('global.validation') }}
                    </v-btn>
                </v-card-actions>
            </v-form>

            <v-row class="ml-3 font-10">
                <v-icon left color="primary" size="15">mdi-alert-circle</v-icon>
                <span>{{ $t('physical_devices_stock_reception.physical_device_update_hint') }}</span>
                <v-icon left color="red" size="12" class="ml-2">mdi-color-helper</v-icon>
                <span>{{ $t('global.not_exist_notice') }}</span>
                <v-icon left color="orange" size="12" class="ml-2" >mdi-color-helper</v-icon>
                <span>{{ $t('physical_devices_stock_exit.inappropriate_physical_device_state') }}</span>
                <v-icon left color="green" size="12" class="ml-2">mdi-color-helper</v-icon>
                <span>{{ $t('global.exist_notice') }}</span>
            </v-row>
        </v-card>
        <global-snackbar/>
    </div>
</template>

<script>
import {PHYSICAL_DEVICES, USERS} from "../../../../api";
import GlobalSnackbar from "../../../../global/components/GlobalSnackbar";

export default {
    name: "AddRemoteInstallerStock",
    components: {GlobalSnackbar},
    data() {
        return {
            valid: true,
            installers: [],
            selected_installer: null,
            qr_code_input: '',
            physical_devices: [],
            physical_devices_after_check: [],
            error_collector:{
                not_found:0,
                life_cycle:0,
                success:0
            },
        }
    },
    watch: {
        qr_code_input(val) {
            this.physical_devices = this.dataExtraction(val.match(this.global_regex));
            if (val.length < 2) {
                this.physical_devices = [];
                this.physical_devices_after_check = [];
            }
            this.refreshChips();
            this.chipsRendering();
        }
    },
    created() {
        this.getAllInstallers();
    },
    methods: {
        getAllInstallers() {
            this.axios.get(
                USERS + '/installers'
            ).then((success) => {
                this.installers = success.data;
            }).catch((error) => {
                this.manageError(error.response.status,this.$t('global.error'));
            });
        },

        async validate() {
            this.$refs.form.validate();
            if (this.physical_devices_after_check.length === 0) {
                this.manageError(0,this.$t('global.invalid_sensor_number'));
                this.qr_code_input = '';
            } else {
                for (let i = 0; i < this.physical_devices_after_check.length; i++) {
                    if (this.physical_devices_after_check[i].color === 'green') {
                        await this.axios.patch(
                            PHYSICAL_DEVICES + '/' + this.physical_devices_after_check[i].physical_device.DEV_EUI.toString().split(':')[1],
                            {
                                state: 2,
                                selected_installer: this.selected_installer
                            }
                        );
                    }
                }
                this.physicalDeviceCheck();
                this.confirmationMessageDisplay();
                this.$refs.form.reset();
                this.qr_code_input = "";
                this.physical_devices = [];
                this.physical_devices_after_check = [];
            }
        },
        physicalDeviceCheck(){
            this.error_collector.not_found=0;
            this.error_collector.life_cycle=0;
            this.error_collector.success=0;

            this.physical_devices_after_check.forEach((physical_device)=>{
                switch (physical_device.color){
                    case 'red':
                        this.error_collector.not_found++;
                        break;
                    case 'orange':
                        this.error_collector.life_cycle++;
                        break;
                    default:
                        this.error_collector.success++;
                }
            });
        },
        confirmationMessageDisplay() {
            if(this.error_collector.life_cycle > 0 && this.error_collector.not_found === 0 && this.error_collector.success === 0){
                this.manageError(0, this.$t('physical_devices_stock_exit.inappropriate_physical_device_state_error'));

            }
            else if(this.error_collector.life_cycle === 0 && this.error_collector.not_found > 0 && this.error_collector.success === 0){
                this.manageError(0, this.$t('physical_devices_stock_exit.physical_device_does_not_exist_stock'));

            }
            else if(this.error_collector.life_cycle > 0 && this.error_collector.not_found > 0 && this.error_collector.success === 0)
            {
                this.manageError(0, this.$t('physical_devices_remote_installer_stock.not_affected'));

            }
            else if(this.error_collector.life_cycle === 0 && this.error_collector.not_found === 0 && this.error_collector.success > 0 ){
                this.showGlobalSnackbar('success', this.$t('physical_devices_remote_installer_stock.device_success_affectation'));

            }
            else if(this.error_collector.life_cycle >= 0 && this.error_collector.not_found >= 0 && this.error_collector.success > 0){
                this.showGlobalSnackbar('success', this.$t('physical_devices_remote_installer_stock.device_success_affectation_filter'));
            }
        },
        chipsRendering() {
            if (this.physical_devices) {
                for (let index in this.physical_devices) {
                    this.axios.get(PHYSICAL_DEVICES + '/' + this.physical_devices[index].DEV_EUI.toString().split(':')[1])
                        .then((success) => {
                            let chipColor = '';
                            let chipIcon = '';

                            if (!success.data.id) {
                                chipColor = 'red';
                                chipIcon = "mdi-alert-octagon";
                            }
                            else {
                                chipColor = 'green';
                                chipIcon = 'mdi-checkbox-marked-circle-outline';
                                if(success.data.state !== 1 || success.data.virtual_device_id !== null){
                                    chipColor = 'orange';
                                    chipIcon = "mdi-alert-octagon";
                                }
                            }

                            if (this.physical_devices_after_check.length === 0) {
                                this.physical_devices_after_check.push({
                                    physical_device: this.physical_devices[index],
                                    color: chipColor,
                                    icon: chipIcon
                                });
                            } else {
                                let item_index = 0;
                                item_index = this.physical_devices_after_check.findIndex(obj => obj.physical_device.DEV_EUI === this.physical_devices[index].DEV_EUI)
                                if (item_index === -1) {
                                    this.physical_devices_after_check.push({
                                        physical_device: this.physical_devices[index],
                                        color: chipColor,
                                        icon: chipIcon
                                    });
                                }
                            }
                        });
                }
            }
        },

        refreshChipsAfterDelete(index, dev_eui) {
            if (this.physical_devices_after_check && this.qr_code_input.length > 0) {
                this.physical_devices_after_check.splice(index, 1);
                this.physical_devices.splice(index, 1);
                this.qr_code_input = this.qr_code_input.replace(dev_eui, "");
            }
        },

        refreshChips() {
            if (!this.physical_devices) {
                this.physical_devices_after_check = [];
            }

            if (this.physical_devices_after_check) {
                for (let i = this.physical_devices_after_check.length - 1; i >= 0; i--) {
                    if (!this.physical_devices.find(physical_device => {
                        physical_device.DEV_EUI === this.physical_devices_after_check[i].physical_device.DEV_EUI
                    })) {
                        this.physical_devices_after_check.splice(i, 1);
                    }
                }
            }
        },
        setInstallerName(item){
            return `${item.first_name}  ${item.last_name}`;
        }
    },
}
</script>
