<template>

    <v-card class="rounded-lg" elevation="2" outlined min-height="480px" height="100%">
        <v-card-title class="d-flex justify-space-between">
            <v-icon>mdi-map-marker-outline</v-icon>
            {{ $t('site_autocomplete_card.title') }}
            <v-btn elevation="3" @click="openEdit" color="amber darken-3" small>
                <v-icon color="white" small>mdi-wrench</v-icon>
            </v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
            <gmap-map :center="center"
                      :zoom="6" style="width: 100%; height: 475px" ref="autocompleteMap"
                      :options="{
                            zoomControl: true,
                            mapTypeControl: false,
                            scaleControl: false,
                            streetViewControl: false,
                            rotateControl: false,
                            fullscreenControl: false,
                            disableDefaultUi: false
                      }"
            >
                <gmap-marker v-if='place.position.lat'
                             :position="{ lat:place.position.lat , lng: place.position.lng }"
                             :clickable="false" :draggable="false"
                             :icon="{ url: '/images/cluster/marker.png' }"
                             :key="site.id"
                ></gmap-marker>
            </gmap-map>

            <v-dialog v-model="dialogEdit" max-width="600px" ref="editDialog" eager>
                <v-card>
                    <v-card-title>
                    <span class="headline">
                        {{ $t('site_autocomplete_card.edit_title') }}
                    </span>
                    </v-card-title>

                    <v-divider></v-divider>

                    <v-card-text class="pa-4">

                        <validation-provider :name="$t('global.name')" rules="required"
                                             v-slot="{ errors }">
                            <v-text-field solo
                                          :label="$t('global.name')"
                                          v-model="edited_site.label"
                                          prepend-icon="mdi-label-outline"
                                          :error-messages="errors"
                            />
                        </validation-provider>

                        <validation-provider :name="$t('sites.address')" rules="required"
                                             v-slot="{ errors }">
                            <vuetify-google-autocomplete
                                id="autocomplete"
                                solo
                                prepend-icon="mdi-map-marker"
                                v-model="edited_site.address"
                                :placeholder="$t('sites.address')"
                                @placechanged="setPlace"
                            >
                            </vuetify-google-autocomplete>
                        </validation-provider>

                        <v-textarea rows="2" solo no-resize
                                    v-model="edited_site.note"
                                    :label="$t('global.notes')"
                                    :prepend-icon="'mdi-comment-outline'"
                        />
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer/>
                        <v-btn @click="discard">
                            <v-icon left>mdi-close</v-icon>
                            {{ $t('global.cancel') }}
                        </v-btn>
                        <v-btn color="primary" @click="savePlace()">
                            <v-icon left>mdi-content-save</v-icon>
                            {{ $t('global.validation') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card-text>
        <v-snackbar
            v-model="snackbar"
            :color="snackbar_type">
            {{ snackbar_text }}
            <template v-slot:action="{ attrs }">
                <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
            </template>
        </v-snackbar>
    </v-card>

</template>

<script>
import {SITES} from "../../../api";
import {cloneDeep} from 'lodash';

export default {
    name: "AutocompleteMapCard",
    components: {},
    props: {
        site: {
            type: Object,
            required: true,
            default: () => {
            },
        },
    },
    data() {
        return {
            dialogEdit: false,
            edit: false,
            center: {lat: this.site.latitude, lng: this.site.longitude},
            place: {
                position: {
                    lat: null,
                    lng: null
                },
                address_components: null,
            },
            address_components: null,
            edited_site: {
                id: null,
                label: '',
                address: '',
                note: '',
                latitude: '',
                longitude: '',
            },
            dataPlace: {},
        }
    },
    computed: {
        enableColor() {
            let color = 'grey';
            if (!this.disabled) {
                color = 'primary';
            }
            return color;
        },
    },
    methods: {
        setPlace(addressData) {
            this.edited_site.latitude = addressData.latitude;
            this.edited_site.longitude = addressData.longitude;

            this.place.position.lat = this.edited_site.latitude;
            this.place.position.lng = this.edited_site.longitude;
            this.center = {lat: this.place.position.lat, lng: this.place.position.lng};
        },

        savePlace() {
            this.axios.patch(SITES + '/' + this.edited_site.id,
                this.edited_site
            ).then(() => {
                this.site.label = this.edited_site.label;
                this.site.address = this.edited_site.address;
                this.site.latitude = this.edited_site.latitude;
                this.site.longitude = this.edited_site.longitude;
                this.site.note = this.edited_site.note;
                this.showSnackbar('success', this.$t('global.success_to_edit'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            }).finally(() => {
                this.closeDialog();
            });
        },

        discard() {
            this.place.position = {lat: this.site.latitude, lng: this.site.longitude};
            this.center = {lat: this.site.latitude, lng: this.site.longitude};
            this.dialogEdit = false;
            this.edit = false;
        },

        openEdit() {
            this.dialogEdit = true;
            this.edit = true;
            this.dataPlace = cloneDeep(this.site);
            this.place.position = {lat: this.dataPlace.latitude, lng: this.dataPlace.longitude};
            this.edited_site.id = this.dataPlace.id;
            this.edited_site.label = this.dataPlace.label;
            this.edited_site.address = this.dataPlace.address;
            this.edited_site.latitude = this.dataPlace.latitude;
            this.edited_site.longitude = this.dataPlace.longitude;
            this.edited_site.note = this.dataPlace.note;
        },

        closeDialog() {
            this.dialogEdit = false;
            this.edit = false;
        },
    },
    mounted() {
        this.dataPlace = cloneDeep(this.site);
        this.place.position = {lat: this.dataPlace.latitude, lng: this.dataPlace.longitude};
        this.edited_site.address = this.dataPlace.address;
    }
}
</script>
<style scoped lang="scss">
.myInput {
    padding: 5px 10px;
    border: 1px solid #555;
    border-radius: 5px;
    display: block;
    width: 100%;
}

.fix-top {
    margin-top: -6px;
    min-width: 44px !important;
    padding: 0 5px !important;
}
</style>
