<template>
    <div id="virtual-device-subassemblies">
        <v-card elevation="2" outlined>
            <v-card-title class="justify-space-between">
                <v-icon>mdi-crop-rotate</v-icon>
                {{ $t('virtual_device_subassemblies.title') }}
                <v-btn v-if="$store.state.access_level !== 300 && $store.state.access_level !== 400" elevation="3" color="amber darken-3" small @click="openDialogBindingSubassembly()">
                    <v-icon color="white" small>mdi-wrench</v-icon>
                </v-btn>
                <v-icon v-if="$store.state.access_level === 300 || $store.state.access_level === 400"/>
            </v-card-title>

            <v-divider/>

            <v-card-text v-if="is_subassembly_associate && !loading_subassembly">
                <v-text-field :prepend-inner-icon="'mdi-label-outline'"
                              outlined readonly background-color="#EEEEEE" :disabled="true"
                >
                    <template v-slot:prepend-inner>
                        <router-link :to="{ path: '/subassembly/' + subassembly.id}" class="mt-1">
                            {{ subassembly.label }}
                        </router-link>
                    </template>
                </v-text-field>

                <v-img aspect-ratio="1" :src="subassembly.path_image" max-height="300" contain>
                    <template v-slot:placeholder>
                        <v-row
                            v-if="subassembly.path_image !== ''"
                            class="fill-height ma-0 background-loader-image"
                            align="center" justify="center"
                        >
                            <v-progress-circular indeterminate color="black lighten-5"/>
                        </v-row>
                        <v-row v-else
                               class="fill-height ma-0 background-loader-image" align="center" justify="center"
                        >
                            {{ $t('global.no_image') }}
                        </v-row>
                    </template>
                </v-img>
            </v-card-text>

            <v-card-text v-else-if="!is_subassembly_associate && !loading_subassembly" class="text-center">
                {{ $t('virtual_devices_details.device_not_bind_to_subassembly') }}
            </v-card-text>

            <v-progress-linear v-else indeterminate/>
        </v-card>

        <v-dialog v-model="dialog_bind_subassembly" max-width="550px" persistent>
            <v-card>
                <v-form ref="formBindVirtualDevice">
                    <v-card-title>
                        <span class="headline">
                            {{ $t('virtual_device_subassemblies.manage_binding_subassemblies') }}
                        </span>
                    </v-card-title>

                    <v-divider/>

                    <v-card-text>
                        <v-select
                            :items="subassemblies"
                            v-model="subassembly_selected"
                            :label="$t('virtual_device_subassemblies.subassembly_to_associate')"
                            :prepend-inner-icon="'mdi-crop-rotate'"
                            :menu-props="{ bottom: true, offsetY: true }"
                            :loading="loading_subassemblies"
                            item-text="label" item-value="id"
                            chips solo
                        >
                            <template v-slot:append-outer>
                                <v-icon :disabled="!is_subassembly_associate" @click="dialog_dissociate = true">
                                    mdi-delete
                                </v-icon>
                            </template>
                        </v-select>
                    </v-card-text>

                    <v-card-actions class="justify-end">
                        <v-btn @click="dialog_bind_subassembly = false">
                            <v-icon left>mdi-close</v-icon>
                            {{ $t('global.close') }}
                        </v-btn>
                        <v-btn color="primary" @click="updateBindingSubassembly()">
                            <v-icon left>mdi-content-save</v-icon>
                            {{ $t('global.validation') }}
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_dissociate" max-width="450px">
            <v-card>
                <v-card-title>
                    {{ $t('virtual_device_subassemblies.dialog_dissociate_subassembly_title') }}
                </v-card-title>

                <v-divider/>

                <v-card-text class="text-center mt-4">
                    {{
                        $t('virtual_device_subassemblies.dialog_dissociate_subassembly_text', {subassembly: subassembly.uid})
                    }}<br/>
                    {{
                        $t('global.confirm_operation')
                    }}
                </v-card-text>

                <v-card-actions class="justify-end">
                    <v-btn text @click="dialog_dissociate = false" class="btn-cancel">
                        {{ $t('global.cancel') }}
                    </v-btn>
                    <v-btn color="primary" @click="unbindSubassembly()">
                        {{ $t('global.validation') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {SUBASSEMBLIES, VIRTUAL_DEVICES} from "../../../api";
import LayerAssets from "../LayerAssets";

export default {
    name: 'VirtualDeviceSubassemblies',
    components: {LayerAssets},
    data() {
        return {
            virtual_device_id: null,
            loading_subassembly: true,
            subassembly: {
                path_image: ''
            },
            loading_subassemblies: true,
            subassemblies: [],
            is_subassembly_associate: false,
            subassembly_selected: null,

            dialog_bind_subassembly: false,
            dialog_dissociate: false,
        }
    },
    created() {
        this.virtual_device_id = this.$route.params.id;
        this.getSubassemblyAssociate();
        this.getSubassemblies();
    },
    methods: {
        getSubassemblyAssociate() {
            this.loading_subassembly = true;
            this.subassembly = {
                path_image: ''
            };

            this.axios.get(
                VIRTUAL_DEVICES + '/' + this.virtual_device_id + '/subassembly'
            ).then((success) => {
                if (success.data.id) {
                    this.is_subassembly_associate = true
                    this.subassembly = success.data;
                    this.subassembly_selected = this.subassembly.id
                }
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('error_display.error_get_subassembly_virtual_device'))
            }).finally(() => {
                this.loading_subassembly = false;
            });
        },

        getSubassemblies() {
            this.loading_subassemblies = true;

            this.axios.get(
                SUBASSEMBLIES
            ).then((success) => {
                this.subassemblies = success.data;
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('error_display.error_get_subassemblies'));
            }).finally(() => {
                this.loading_subassemblies = false;
            });
        },

        updateBindingSubassembly() {
            this.axios.put(
                VIRTUAL_DEVICES + '/' + this.virtual_device_id + '/bind/subassembly/' + this.subassembly_selected
            ).then(() => {
                this.getSubassemblyAssociate();
                this.showGlobalSnackbar('success', this.$t('global.success_to_edit'));
                this.$emit('reloadLocationWidget')
            }).catch((error) => {
                this.manageError(error.status.response, this.$t('global.failure_to_update'));
            }).finally(() => {
                this.dialog_bind_subassembly = false;
            });
        },

        unbindSubassembly() {
            this.axios.put(
                VIRTUAL_DEVICES + '/' + this.virtual_device_id + '/unbind/subassembly'
            ).then(() => {
                this.subassembly_selected = null;
                this.is_subassembly_associate = false;
                this.dialog_dissociate = false;
                this.getSubassemblyAssociate();
                this.$emit('reloadLocationWidget')
                this.showGlobalSnackbar('success', this.$t('global.success_to_edit'));
            }).catch((error) => {
                this.manageError(error.status.response, this.$t('global.failure_to_update'));
            }).finally(() => {
                this.dialog_bind_subassembly = false;
            });
        },

        openDialogBindingSubassembly() {
            this.dialog_bind_subassembly = true;
            this.subassembly_selected = this.subassembly.id;
        },
    }
}
</script>
