<template>
    <v-col class="col col-12 mt-0 pt-0 pa-0" style="margin-top:0px!important;padding:0px;position:relative; top:-12px;">  
        <!-- MACHINE MENU PACKAGE -->
        <div style="padding:0px 12px;">
            <v-col class="pl-5 pr-5" style="background-color:#001047;color:#FFFFFF;border-radius: 0px 0px 5px 5px;">
                <!-- TOP TITLE -->
                <v-row class="pl-0 pt-5 mb-5" style="height: 52px; padding:5px;">
                    <!-- PAGE TITLE -->
                    <v-subheader class="v-subheader col theme--light">
                        <!------------------- Info bulle ---------------------->
                        <v-tooltip bottom dark class="mr-2 ml-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 mr-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                                    <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                                </v-btn>
                            </template>
                            <template v-slot:default>
                            <div style="min-width: 200px;position:relative;z-index: 10;">
                                <strong>Page [Liste des campagnes]</strong>
                                <p>Cette page à été spécialement développé suivant les process d'interventions de Techview.</br>
                                    Dans le cadre de nos campagnes OFFLINE (sur le terrain) et ONLINE (à distance) nous souhaitons que tous nos clients soient informés en temps réel de l'avancement de leurs projets.</br></br>
                                    En ce sens toutes les interventions qui seront pévues et réalisées seront accessible depuis cette page.</br>
                                    Cliquer sur le titre d'une campagne vous permettra de suivre en détail l'avancement des rapports et de les consulter.
                                </p>
                            </div>
                            </template>
                        </v-tooltip>
                        <p class="title mb-0 color-white" style="color: white!important;">
                            <div style="color:#FFFFFFb3;margin-right:4px;">Campagnes > </div><div @click="infoToggler()" style="color:#FFFFFF;font-weight:bold;"> {{ $t('campain.title') }}</div>
                        </p>
                    </v-subheader>

                    <!-- GLOBAL ACTIONS -->
                    <v-col class="row mt-1 col-auto pa-0 pr-5">
                        <v-tooltip v-if="access_level <= 100" bottom dark class="mr-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="openDialogAddCampain()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                    <v-icon color="white">mdi-plus</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('campain.add_campain') }}</span>
                        </v-tooltip>
                        <v-tooltip bottom dark class="mr-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="getData()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                    <v-icon color="white">mdi-tag-multiple</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('global.1_5_2') }}</span>
                        </v-tooltip>
                        <v-tooltip bottom dark class="mr-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="getData()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                    <v-icon color="white">mdi-refresh</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('campain.refresh') }}</span>
                        </v-tooltip>
                        <!--
                        <v-tooltip v-if="infoToggle == true" bottom dark class="mr-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="infoToggler()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                    <v-icon color="white">mdi-minus-box-multiple</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('machines.refresh') }}</span>
                        </v-tooltip>

                        <v-tooltip v-if="infoToggle == false" bottom dark class="mr-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="infoToggler()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                    <v-icon color="white">mdi-plus-box-multiple</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('machines.refresh') }}</span>
                        </v-tooltip>
                        -->
                    </v-col>
                </v-row>

                <!-- MACHINE INFOS (UNUSED) -->
                <v-col class="mb-5" v-if="infoToggle == true" style="min-height: 250px; background-color:#e8edff;border-radius:5px;">
                    <v-row>

                        <!-- insert HEADER sub content HERE -->

                    </v-row>
                </v-col>
                
                <!-- MACHINE LINKS -->
                <v-row v-if="infoToggle == false" style="border-top: 1px solid #ffffff1c;">
                    <v-row class="pb-2" style="margin:0px;">
                        <v-row class="ml-3 pt-4 pb-0 rounded" style="height:68px;">
                            <!-- BUTTON EXAMPLE 
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/events'}" dusk="logout-button">
                                        <v-icon color="white">mdi-alert</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.alerts') }}</span>
                            </v-tooltip>
                            -->

                            <div
                                v-for="filters in data_filters2"
                                :key="filters.level"
                                class="mr-0"
                                style="height:40px; padding: 4px 0px;"
                            >
                                <div
                                class="ma-0 v-chip v-chip--clickable v-chip--link v-chip--no-color theme--light v-size--default"
                                style="
                                    color: white;
                                    font-weight: 400;
                                    display: flex;
                                    justify-content: left;
                                    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.29);
                                    background-color: #ffffff8c;
                                    font-size: 14px;
                                    height: 32px;
                                    margin-right: 12px !important;
                                ">
                                <div
                                    v-if="filters.level_name !== 'Tout voir'"
                                    class="mr-2"
                                    style="color: white;min-width: 30px; min-height: 20px; border-radius:20px;font-weight: bold;text-align: center;"
                                    :style="{backgroundColor: filters.level_color}"
                                >{{filters.count}}</div>
                                {{filters.level_name}}
                                </div>
                            </div>
                        </v-row>     
                    </v-row>
                </v-row>

                <v-row v-if="infoToggle == true">
                    <v-row class="pb-2" style="margin:0px;">
                        <v-row class="ml-3 pt-4 pb-0 rounded" style="">
                            <!-- BUTTON EXAMPLE 
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/events'}" dusk="logout-button">
                                        <v-icon color="white">mdi-alert</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.alerts') }}</span>
                            </v-tooltip>
                            -->

                            <div v-for="filters in data_filters2" :key="filters.level" class="mr-0" style="height:40px; padding: 4px 0px;">
                                <div
                                class="ma-0 v-chip v-chip--clickable v-chip--link v-chip--no-color theme--light v-size--default"
                                style="
                                    color: white;
                                    font-weight: 400;
                                    display: flex;
                                    justify-content: left;
                                    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.29);
                                    background-color: #ffffff8c;
                                    font-size: 14px;
                                    height: 32px;
                                    margin-right: 12px !important;
                                ">
                                    <div class="mr-3" style="color: white;min-width: 30px; min-height: 20px; border-radius:20px;font-weight: bold;text-align: center;" :style="{backgroundColor: filters.level_color}">
                                        {{filters.count}}
                                    </div>
                                    {{filters.level_name}}
                                </div>
                            </div>
                        </v-row>     
                    </v-row>
                </v-row>
            </v-col>
        </div>
        
        <!-- content -->
        <v-col color="white" class="ml-0 mr-4 pl-3 mt-3 col">
            <!-- Différents onglets -->
            <v-col class="col pa-0 ma-0" style="max-width: 100%;">
                <!-- Vue -->
                <v-row class="pt-0" style="margin:0px;height:40px;">
                    <v-col class="row ml-0 mt-0 pa-0" style="position: relative;left:-1px;">
                        <!-- Onglets normaux -->
                        <div v-for="vue in vues" :key="vue.id" class="mr-1">
                            <div 
                                v-if="vue.id == active_special_vue" 
                                class="ma-auto pl-5 pr-5 pa-2"
                                style="color: black; font-weight: 400; display: flex; justify-content: left; text-shadow: rgba(0, 0, 0, 0.29) 2px 2px 4px; background-color: rgb(255 255 255); border-radius: 5px 5px 0px 0px; border-left: 1px solid rgba(211, 211, 211, 0.43); border-bottom: none; font-size: 14px; height: 40px; align-items: center; font-weight:bold;"
                            >
                                <div v-if="active_special_vue == 0 " style="margin-right: 8px;">
                                    <span v-if="campains_vibratoire_nb > 0" style="font-weight: 800;color: white;background-color: #001047;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_vibratoire_nb }}</span>
                                    <span v-if="campains_vibratoire_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_vibratoire_nb }}</span>
                                </div>
                                <div v-if="active_special_vue == 5 " style="margin-right: 8px;">
                                    <span v-if="campains_online_nb > 0" style="font-weight: 800;color: white;background-color: #001047;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_online_nb }}</span>
                                    <span v-if="campains_online_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_online_nb }}</span>
                                </div>
                                <div v-if="active_special_vue == 1 " style="margin-right: 8px;">
                                    <span v-if="campains_thermographie_nb > 0" style="font-weight: 800;color: white;background-color: #001047;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_thermographie_nb }}</span>
                                    <span v-if="campains_thermographie_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_thermographie_nb }}</span>
                                </div>
                                <div v-if="active_special_vue == 2 " style="margin-right: 8px;">
                                    <span v-if="campains_ultrasons_nb > 0" style="font-weight: 800;color: white;background-color: #001047;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_ultrasons_nb }}</span>
                                    <span v-if="campains_ultrasons_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_ultrasons_nb }}</span>
                                </div>
                                <div v-if="active_special_vue == 3 " style="margin-right: 8px;">
                                    <span v-if="campains_equilibrage_nb > 0" style="font-weight: 800;color: white;background-color: #001047;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_equilibrage_nb }}</span>
                                    <span v-if="campains_equilibrage_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_equilibrage_nb }}</span>
                                </div>
                                <div v-if="active_special_vue == 4 " style="margin-right: 8px;">
                                    <span v-if="campains_expertises_nb > 0" style="font-weight: 800;color: white;background-color: #001047;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_expertises_nb }}</span>
                                    <span v-if="campains_expertises_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_expertises_nb }}</span>
                                </div>
                                {{ vue.label }}
                            </div>
                            <div 
                                v-else 
                                class="ma-auto pl-5 pr-5 pa-2"
                                style="color: black; font-weight: 400; display: flex; justify-content: left; text-shadow: rgba(0, 0, 0, 0.29) 2px 2px 4px; background-color: rgb(243 243 243 / 90%); border-radius: 10px 10px 0px 0px; border: 1px solid #dbdce1; border-bottom: none; font-size: 14px; height: 40px; cursor: pointer;"
                                @click="loadVue(vue.id)"
                            >
                                <div v-if="vue.id == 0" style="margin-right: 8px;">
                                    <span v-if="campains_vibratoire_nb > 0" style="font-weight: 800;color: white;background-color: #939393;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_vibratoire_nb }}</span>
                                    <span v-if="campains_vibratoire_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_vibratoire_nb }}</span>
                                </div>
                                <div v-if="vue.id == 5" style="margin-right: 8px;">
                                    <span v-if="campains_online_nb > 0" style="font-weight: 800;color: white;background-color: #939393;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_online_nb }}</span>
                                    <span v-if="campains_online_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_online_nb }}</span>
                                </div>
                                <div v-if="vue.id == 1" style="margin-right: 8px;">
                                    <span v-if="campains_thermographie_nb > 0" style="font-weight: 800;color: white;background-color: #939393;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_thermographie_nb }}</span>
                                    <span v-if="campains_thermographie_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_thermographie_nb }}</span>
                                </div>
                                <div v-if="vue.id == 2" style="margin-right: 8px;">
                                    <span v-if="campains_ultrasons_nb > 0" style="font-weight: 800;color: white;background-color: #939393;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_ultrasons_nb }}</span>
                                    <span v-if="campains_ultrasons_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_ultrasons_nb }}</span>
                                </div>
                                <div v-if="vue.id == 3" style="margin-right: 8px;">
                                    <span v-if="campains_equilibrage_nb > 0" style="font-weight: 800;color: white;background-color: #939393;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_equilibrage_nb }}</span>
                                    <span v-if="campains_equilibrage_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_equilibrage_nb }}</span>
                                </div>
                                <div v-if="vue.id == 4" style="margin-right: 8px;">
                                    <span v-if="campains_expertises_nb > 0" style="font-weight: 800;color: white;background-color: #939393;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_expertises_nb }}</span>
                                    <span v-if="campains_expertises_nb == 0" style="font-weight: 800;color: white;background-color: #9393935c;border-radius: 20px;padding: 3px 5px;font-size: 11px;">{{ campains_expertises_nb }}</span>
                                </div>
                                {{ vue.label }}
                            </div>
                        </div>

                        <!-- Onglet admin -->
                        <div v-if="access_level == 0" class="ml-auto" style="margin-left: auto; display: flex;">
                            <div v-if="active_special_vue == 99" 
                                class="ma-0 pl-5 pr-5 pa-2"
                                style="color: black; font-weight: 400; display: flex; justify-content: left; text-shadow: rgba(0, 0, 0, 0.29) 2px 2px 4px; background-color: rgb(255 255 255); border-radius: 5px 5px 0px 0px; border-left: 1px solid rgba(211, 211, 211, 0.43); border-bottom: none; font-size: 14px; height: 40px; align-items: center; font-weight:bold;"
                            >
                                <v-icon color="red" style="height:36px;font-size:30px;">
                                    mdi-delete-outline
                                </v-icon>
                            </div>
                            <div v-else 
                                class="ma-0 pl-5 pr-5 pa-1"
                                style="color: black; font-weight: 400; display: flex; justify-content: left; text-shadow: rgba(0, 0, 0, 0.29) 2px 2px 4px; background-color: rgb(243 243 243 / 90%); border-radius: 10px 10px 0px 0px; border: 1px solid #dbdce1; border-bottom: none; font-size: 14px; height: 40px; cursor: pointer;"
                                @click="loadVue(99)"
                            >
                                <v-icon color="red" style="height:36px;font-size:30px;">
                                    mdi-delete-outline
                                </v-icon>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-col>

            <!-- DATA TABLE if view details -->
            <v-data-table
                :headers="headers"
                :items="campains_displayed"
                class="elevation-1 datatable-operation"
                style="border-radius: 5px; overflow: hidden;"
                :items-per-page="-1"
                :loading="loading_datatable"
                :search="search"
                :sort-by="['id']"
                :sort-desc="[true]"
                v-if="campains && campains.length > 0 && campains_available && view_details && campains_displayed.length > 0"
            >
                <!-- TOP -->
                <template v-slot:top>
                    <v-toolbar flat v-if="campains_available">
                        <div> 
                            <v-tooltip v-if="view_details" bottom dark>
                                <template v-slot:activator="{ on }">
                                    <v-btn class="ma-0 mr-5 col-auto elevation-3" v-on="on" text icon @click="viewDetails()" dusk="logout-button" color="primary" style="max-width: 50px !important;min-width: 45px !important;border-radius: 25px;background-color: #001047;">
                                        <v-icon color="white">mdi-eye</v-icon>
                                    </v-btn>
                                </template>
                                <span>Afficher le détail des campagnes</span>
                            </v-tooltip>
                            <v-tooltip v-if="!view_details" bottom dark>
                                <template v-slot:activator="{ on }">
                                    <v-btn class="ma-0 mr-5 col-auto elevation-3" v-on="on" text icon @click="viewDetails()" dusk="logout-button" color="red" style="max-width: 50px !important;min-width: 45px !important;border-radius: 25px;background-color: #f15959;">
                                        <v-icon color="white">mdi-eye-off</v-icon>
                                    </v-btn>
                                </template>
                                <span>Masquer le détail des campagnes</span>
                            </v-tooltip>
                        </div>

                        <div class="col col-8">
                            <div class="elevation-0 pl-5 pt-2 pb-2" >
                                Liste des campagnes
                            </div>
                        </div>
                        
                        <!-- SEARCH -->
                        <v-col style="display: flex; justify-content: end;">
                            <v-layout class="justify-end mt-7 max-width-300">
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    :label="$t('global.search')"
                                ></v-text-field>
                            </v-layout>
                        </v-col>

                        <v-snackbar
                            v-model="snackbar"
                            :color="snackbar_type">
                            {{ snackbar_text }}
                            <template v-slot:action="{ attrs }">
                                <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                            </template>
                        </v-snackbar>
                    </v-toolbar>
                </template>

                <!-- INFOS CAMPAGNES -->
                <template v-slot:item.name="{ item }">
                    <div style="height:100%;padding-left:20px;">
                        <div v-if="item && item !== 'undefined' && item !== null && typeof item === 'object'">
                            <v-col class="ma-0 pa-0" style="justify-content:space-between;">
                                <!-- CAMPAIN NAME --> 
                                <v-col class="mb-2 mt-0 mr-1" v-on="on" link :to="'/campain/' + item.id" style="font-weight: bold;cursor:pointer; border-radius:25px;">                                   
                                    <div style="display:flex;flex-direction:row;">
                                        <!-- CAMPAIN STATE --> 
                                        <div class="pt-2">
                                            <div v-if="item.step < 13 && item.closed_at === null"                   style="width: 15px; background-color: rgb(239 120 14);        height:15px;border-radius:15px;margin-right:12px;position:relative;margin-left:5px;top:3px;     "></div>
                                            <div v-if="item.step > 20 && item.step < 23 && item.closed_at === null" style="width: 15px; background-color: rgb(14 76 239 / 51%);   height:15px;border-radius:15px;margin-right:12px;position:relative;margin-left:5px;top:3px;      "></div>
                                            <div v-if="item.step > 22 && item.step < 33 && item.closed_at === null" style="width: 15px; background-color: rgb(14 76 239 / 79%);   height:15px;border-radius:15px;margin-right:12px;position:relative;margin-left:5px;top:3px;      "></div>
                                            <div v-if="item.step > 32 && item.step < 43 && item.closed_at === null" style="width: 15px; background-color: rgb(14, 76, 239);     height:15px;border-radius:15px;margin-right:12px;position:relative;margin-left:5px;top:3px;      "></div>
                                            <div v-if="item.step === 43 && item.closed_at === null"                 style="width: 15px; background-color: rgb(14, 76, 239);     height:15px;border-radius:15px;margin-right:12px;position:relative;margin-left:5px;top:3px;      "></div>
                                            <div v-if="item.closed_at != null"                                      style="width: 15px; background-color: rgb(86, 86, 86);      height:15px;border-radius:15px;margin-right:12px;position:relative;margin-left:5px;top:3px;      "></div>
                                        </div>
                                    
                                        <v-chip v-if="item.label !== null" class="mb-1 mt-1" v-on="on" link :to="'/campain/' + item.id" style="font-weight: bold; background-color: #f3f3f3; border: 1px solid #e5e5e5;">{{item.name}}</v-chip>
                                    
                                    </div>
                                    <div v-for="presta in prestas" :key="presta">
                                        <div v-for="typex in types" :key="typex">
                                            <div v-if="presta.id === item.presta && typex.id === item.type" style="font-weight:normal;font-size: 12px;margin-left: 32px;">{{ presta.name }} ({{ typex.name.toLowerCase().trim() }})</div>
                                        </div>
                                    </div>
                                </v-col>
                            </v-col>
                            <!-- CAMPAIN DETAILS --> 
                            <v-row class="ml-6 mb-0">
                                <v-col>
                                    <div style="font-size: 12px;margin-left: 10px;">Référence : {{item.uid}}</div>
                                    <div style="font-size: 12px;margin-left: 10px;">Créée le : {{item.created_at}}</div>
                                    <div v-for="tviewer in team" :key="tviewer">
                                        <div v-if="tviewer.id === item.creator" style="font-size: 12px;margin-left: 10px;margin-bottom:5px;">Créé par : {{tviewer.first_name}} {{tviewer.last_name}}</div>
                                    </div>
                                </v-col>
                                <v-col>
                                    <div v-for="analyst in analysts" :key="analyst">
                                        <div v-if="analyst.id === item.manager" style="font-size: 12px;margin-left: 10px;font-weight: bold;">Chargé de campagne : {{analyst.first_name}} {{analyst.last_name}}</div>
                                    </div>
                                    <div style="font-size: 12px;margin-left: 10px;">N° de dossier : {{item.affaire_number}}</div>
                                    <div v-for="site in sites" :key="site">
                                        <div v-if="site.id === item.id_park" style="font-size: 12px;margin-left: 10px;">Parc machine associé : {{site.label}}</div>
                                    </div>
                                </v-col>
                            </v-row>

                            <div v-if="item.description != null" class="ml-12" style="font-weight: bold;font-size: 12px;">Informations complémentaires :</div> 
                            <p v-if="item.description != null" class="ml-12 mb-0" style="font-size: 12px; margin-bottom:15px;">{{ item.description }}</p>

                            <v-row class="col pa-2 pl-0 ml-10 mt-2 mb-0">
                                <div v-if="item.closed_at != null">
                                    <v-tooltip v-if="!item.trash && access_level <= 99 && item.step == 43 && item.state == -1" bottom dark>
                                        <template v-slot:activator="{ on }">
                                            <v-btn class="ma-0 col-0" text icon v-on="on" link :to="'/campain/' + item.id" dusk="logout-button">
                                                <v-icon color="red">mdi-lock-open</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Ouvrir la campagne</span>
                                    </v-tooltip>
                                </div>

                                <div v-else>
                                    <v-tooltip v-if="!item.trash && access_level <= 99 && item.step == 43 && item.state != -1" bottom dark>
                                        <template v-slot:activator="{ on }">
                                            <v-btn class="ma-0 col-0" text icon v-on="on" link :to="'/campain/' + item.id" dusk="logout-button">
                                                <v-icon color="green">mdi-lock</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Cloturer la campagne</span>
                                    </v-tooltip>
                                </div>
                                
                                <v-tooltip bottom dark>
                                    <template v-slot:activator="{ on }">
                                        <v-btn class="ma-0 col-0" text icon v-on="on" link :to="'/campain/' + item.id" dusk="logout-button">
                                            <v-icon color="primary">mdi-eye</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Afficher les détails de la campagne</span>
                                </v-tooltip>

                                <v-tooltip v-if="!item.trash && access_level <= 99 " bottom dark>
                                    <template v-slot:activator="{ on }">
                                        <v-btn class="ma-0 col-0" text icon v-on="on" @click="openDialogUpdateCampain(item)" dusk="logout-button">
                                            <v-icon color="primary">mdi-circle-edit-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('campain.edit_campain') }}</span>
                                </v-tooltip>

                                <v-tooltip v-if="!item.trash && access_level <= 99" bottom dark>
                                    <template v-slot:activator="{ on }">
                                        <v-btn class="ma-0 col-0" text icon v-on="on" @click="setDataTrash(item,1)" dusk="logout-button">
                                            <v-icon color="red">mdi-delete-alert</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('campain.desactivate_campain') }}</span>
                                </v-tooltip>
                            </v-row>
                        </div>
                    </div>
                </template>

                <!-- CAMPAIN END STATE -->
                <template v-slot:item.description="{ item }" style="display:flex;flex-direction:column;width:100%;">
                    <!-- IF OPEN --> 
                    <div v-if="item && item !== 'undefined' && item !== null && typeof item === 'object' && item.closed_at === null">
                        <!-- OPEN OTHERS -->
                        <div  v-if="item.presta !== 5" class="col pa-0 ma-0" style="height:110px;overflow:hidden;position:relative;top: 10px;">
                            <div class="row row col pa-10 pt-5 pb-5 ma-0 ml-0 mt-1 mb-5" style="
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                border-radius: 100px;
                            ">
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step <= 11 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 12 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 13 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step > 13 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                    <!-- ADMIN VIEW -->
                                    <v-icon v-if="item.step === 11 && access_level <= 100" @click="updateStep(item.id,12)" class="pointer" color="orange" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 12 && access_level <= 100" @click="updateStep(item.id,13)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 13 && access_level <= 100" @click="updateStep(item.id,11)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step > 13 && access_level <= 100" @click="updateStep(item.id,11)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                </p>
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step >= 13 && item.step <= 21 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 22 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 23 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 13 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step > 23 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                    <!-- ADMIN VIEW -->
                                    <v-icon v-if="item.step >= 13 && item.step <= 21 && access_level <= 100" @click="updateStep(item.id,22)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 22 && access_level <= 100" @click="updateStep(item.id,23)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 23 && access_level <= 100" @click="updateStep(item.id,21)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 13 && access_level <= 100" @click="updateStep(item.id,21)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step > 23 && access_level <= 100" @click="updateStep(item.id,21)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                </p>
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step >= 23 && item.step <= 31 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 32 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 33 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 23 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step > 33 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                    <!-- ADMIN VIEW -->
                                    <v-icon v-if="item.step >= 23 && item.step <= 31 && access_level <= 100" @click="updateStep(item.id,32)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 32 && access_level <= 100" @click="updateStep(item.id,33)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 33 && access_level <= 100" @click="updateStep(item.id,31)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 23 && access_level <= 100" @click="updateStep(item.id,31)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step > 33 && access_level <= 100" @click="updateStep(item.id,31)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                </p>
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step >= 33 && item.step <= 41 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 42 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 43 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 33 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <!-- ADMIN VIEW -->
                                    <v-icon v-if="item.step >= 33 && item.step <= 41 && access_level <= 100" @click="updateStep(item.id,42)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 42 && access_level <= 100" @click="updateStep(item.id,43)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 43 && access_level <= 100" @click="updateStep(item.id,41)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 33 && access_level <= 100" @click="updateStep(item.id,41)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                </p>
                            </div>
                            
                            <div class="col" style="
                                position: relative;
                                border: 1px solid #656565;
                                top: -58px;
                                width: 70%;
                                left: 15%;
                                border-radius: 15px;
                                height: 2px;
                                padding: 0px;
                            "></div>
                            
                            <div class="row row col pa-10 pt-0 pb-0 ma-0 ml-0" style="
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                position: relative;
                                top: -57px;
                            ">
                                <p class="ml-0 mb-0 col" v-if="item.step === 11 || item.step === 12 || item.step === 13" style="z-index: 10;font-weight: bold;">Préparation</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 11 && item.step != 12 && item.step != 13" style="z-index: 10;">Préparation</p>
                                <p class="ml-0 mb-0 col" v-if="item.step === 21 || item.step === 22 || item.step === 23" style="z-index: 10;font-weight: bold;">Intervention</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 21 && item.step != 22 && item.step != 23" style="z-index: 10;">Intervention</p>
                                <p class="ml-0 mb-0 col" v-if="item.step === 31 || item.step === 32 || item.step === 33" style="z-index: 10;font-weight: bold;">Analyses</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 31 && item.step != 32 && item.step != 33" style="z-index: 10;">Analyses</p>
                                <p class="ml-0 mb-0 col" v-if="item.step === 41 || item.step === 42 || item.step === 43" style="z-index: 10;font-weight: bold;">Rapports</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 41 && item.step != 42 && item.step != 43" style="z-index: 10;">Rapports</p>
                            </div>                         
                        </div>
                        
                        <!-- OPEN ONLINE -->
                        <div  v-if="item.presta === 5" class="col pa-0 ma-0" style="height:110px;overflow:hidden;position:relative;top: 10px;">
                            <div class="row row col pa-10 pt-5 pb-5 ma-0 ml-0 mt-1 mb-5" style="
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                border-radius: 100px;
                            ">
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step <= 11 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 12 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 13 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step > 13 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                    <!-- ADMIN VIEW -->
                                    <v-icon v-if="item.step === 11 && access_level <= 100" @click="updateStep(item.id,12)" class="pointer" color="orange" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 12 && access_level <= 100" @click="updateStep(item.id,13)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 13 && access_level <= 100" @click="updateStep(item.id,11)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step > 13 && access_level <= 100" @click="updateStep(item.id,11)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                </p>
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step >= 13 && item.step <= 21 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 22 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 23 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 13 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step > 23 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                    <!-- ADMIN VIEW -->
                                    <v-icon v-if="item.step >= 13 && item.step <= 21 && access_level <= 100" @click="updateStep(item.id,22)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 22 && access_level <= 100" @click="updateStep(item.id,23)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 23 && access_level <= 100" @click="updateStep(item.id,21)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 13 && access_level <= 100" @click="updateStep(item.id,21)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step > 23 && access_level <= 100" @click="updateStep(item.id,21)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                </p>
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step >= 23 && item.step <= 31 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 32 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 33 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 23 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step > 33 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                    <!-- ADMIN VIEW -->
                                    <v-icon v-if="item.step >= 23 && item.step <= 31 && access_level <= 100" @click="updateStep(item.id,32)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 32 && access_level <= 100" @click="updateStep(item.id,33)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 33 && access_level <= 100" @click="updateStep(item.id,31)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 23 && access_level <= 100" @click="updateStep(item.id,31)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step > 33 && access_level <= 100" @click="updateStep(item.id,31)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                </p>
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step >= 33 && item.step <= 41 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 42 && access_level > 100" color="flashGreen" style="font-size: 36px;">mdi-play-network</v-icon>
                                    <v-icon v-if="item.step === 43 && access_level > 100" color="red" style="font-size: 36px;">mdi-close-network-outline</v-icon>
                                    <v-icon v-if="item.step < 33 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <!-- ADMIN VIEW -->
                                    <v-icon v-if="item.step >= 33 && item.step <= 41 && access_level <= 100" @click="updateStep(item.id,42)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 42 && access_level <= 100" @click="updateStep(item.id,43)" class="pointer" color="flashGreen" style="font-size: 36px;">mdi-play-network</v-icon>
                                    <v-icon v-if="item.step === 43 && access_level <= 100" @click="updateStep(item.id,41)" class="pointer" color="red" style="font-size: 36px;">mdi-close-network-outline</v-icon>
                                    <v-icon v-if="item.step < 33 && access_level <= 100" @click="updateStep(item.id,41)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                </p>
                            </div>
                            <div v-if="item.step === 42" class="col" style="
                                position: relative;
                                border: 1px solid #00db37;
                                top: -58px;
                                width: 70%;
                                left: 15%;
                                border-radius: 15px;
                                height: 2px;
                                padding: 0px;
                            "></div>
                            <div v-if="item.step === 43" class="col" style="
                                position: relative;
                                border: 1px solid #d70000;
                                top: -58px;
                                width: 70%;
                                left: 15%;
                                border-radius: 15px;
                                height: 2px;
                                padding: 0px;
                            "></div>
                            <div v-if="item.step < 42" class="col" style="
                                position: relative;
                                border: 1px solid #656565;
                                top: -58px;
                                width: 70%;
                                left: 15%;
                                border-radius: 15px;
                                height: 2px;
                                padding: 0px;
                            "></div>
                            <div class="row row col pa-10 pt-0 pb-0 ma-0 ml-0" style="
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                position: relative;
                                top: -57px;
                            ">
                                <p class="ml-0 mb-0 col" v-if="item.step === 11 || item.step === 12 || item.step === 13" style="z-index: 10;font-weight: bold;">Préparation</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 11 && item.step != 12 && item.step != 13" style="z-index: 10;">Préparation</p>
                                <p class="ml-0 mb-0 col" v-if="item.step === 21 || item.step === 22 || item.step === 23" style="z-index: 10;font-weight: bold;">Instrumentation</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 21 && item.step != 22 && item.step != 23" style="z-index: 10;">Instrumentation</p>
                                <p class="ml-0 mb-0 col" v-if="item.step === 31 || item.step === 32 || item.step === 33" style="z-index: 10;font-weight: bold;">Configuration</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 31 && item.step != 32 && item.step != 33" style="z-index: 10;">Configuration</p>
                                <p class="ml-0 mb-0 col" v-if="item.step === 41 || item.step === 42 || item.step === 43" style="z-index: 10;font-weight: bold;">Surveillance</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 41 && item.step != 42 && item.step != 43" style="z-index: 10;">Surveillance</p>
                            </div>                         
                        </div>
                    </div>

                    <!-- IF CLOSED -->
                    <div v-if="item && item !== 'undefined' && item !== null && typeof item === 'object' && item.closed_at != null">
                        <!-- CLOSED OTHERS -->
                        <div  v-if="item.presta !== 5" class="col pa-0 ma-0" style="height:110px;overflow:hidden;position:relative;top: 10px;">
                            <div class="row row col pa-10 pt-5 pb-5 ma-0 ml-0 mt-1 mb-5" style="
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                border-radius: 100px;
                            ">
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step <= 11" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 12" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 13 " style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step > 13" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                </p>
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step === 21" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 22" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 23" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 21"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step > 23" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>                                            </p>
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step === 31" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 32" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 33" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 31"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step > 33" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                </p>
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step === 41" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 42" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 43" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 41"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                </p>
                            </div>
                            <div class="col" style="
                                position: relative;
                                border: 1px solid #656565;
                                top: -58px;
                                width: 70%;
                                left: 15%;
                                border-radius: 15px;
                                height: 2px;
                                padding: 0px;
                            "></div>
                            <div class="row row col pa-10 pt-0 pb-0 ma-0 ml-0" style="
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                position: relative;
                                top: -57px;
                            ">
                                <p class="ml-0 mb-0 col" v-if="item.step === 11 || item.step === 12 || item.step === 13" style="z-index: 10;font-weight: bold;">Préparation</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 11 && item.step != 12 && item.step != 13" style="z-index: 10;">Préparation</p>
                                <p class="ml-0 mb-0 col" v-if="item.step === 21 || item.step === 22 || item.step === 23" style="z-index: 10;font-weight: bold;">Intervention</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 21 && item.step != 22 && item.step != 23" style="z-index: 10;">Intervention</p>
                                <p class="ml-0 mb-0 col" v-if="item.step === 31 || item.step === 32 || item.step === 33" style="z-index: 10;font-weight: bold;">Analyses</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 31 && item.step != 32 && item.step != 33" style="z-index: 10;">Analyses</p>
                                <p class="ml-0 mb-0 col" v-if="item.step === 41 || item.step === 42 || item.step === 43" style="z-index: 10;font-weight: bold;">Rapports</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 41 && item.step != 42 && item.step != 43" style="z-index: 10;">Rapports</p>
                            </div>                       
                        </div>

                        <!-- CLOSED ONLINE -->
                        <div  v-if="item.presta === 5" class="col pa-0 ma-0" style="height:110px;overflow:hidden;position:relative;top: 10px;">
                            <div class="row row col pa-10 pt-5 pb-5 ma-0 ml-0 mt-1 mb-5" style="
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                border-radius: 100px;
                            ">
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step <= 11 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 12 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 13 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step > 13 && access_level > 100" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                    </p>
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step === 21 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 22 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 23 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 21 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step > 23 && access_level > 100" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                </p>
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step === 31 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 32 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 33 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 31 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step > 33 && access_level > 100" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                </p>
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step === 41 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 42 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-play-network</v-icon>
                                    <v-icon v-if="item.step === 43 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-close-network-outline</v-icon>
                                    <v-icon v-if="item.step < 41 && access_level > 100" style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                </p>
                            </div>
                            <div v-if="item.step === 42" class="col" style="
                                position: relative;
                                border: 1px solid #00db37;
                                top: -58px;
                                width: 70%;
                                left: 15%;
                                border-radius: 15px;
                                height: 2px;
                                padding: 0px;
                            "></div>
                            <div v-if="item.step === 43" class="col" style="
                                position: relative;
                                border: 1px solid #d70000;
                                top: -58px;
                                width: 70%;
                                left: 15%;
                                border-radius: 15px;
                                height: 2px;
                                padding: 0px;
                            "></div>
                            <div v-if="item.step < 42" class="col" style="
                                position: relative;
                                border: 1px solid #656565;
                                top: -58px;
                                width: 70%;
                                left: 15%;
                                border-radius: 15px;
                                height: 2px;
                                padding: 0px;
                            "></div>
                            <div class="row row col pa-10 pt-0 pb-0 ma-0 ml-0" style="
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                position: relative;
                                top: -57px;
                            ">
                                <p class="ml-0 mb-0 col" v-if="item.step === 11 || item.step === 12 || item.step === 13" style="z-index: 10;font-weight: bold;">Préparation</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 11 && item.step != 12 && item.step != 13" style="z-index: 10;">Préparation</p>
                                <p class="ml-0 mb-0 col" v-if="item.step === 21 || item.step === 22 || item.step === 23" style="z-index: 10;font-weight: bold;">Instrumentation</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 21 && item.step != 22 && item.step != 23" style="z-index: 10;">Instrumentation</p>
                                <p class="ml-0 mb-0 col" v-if="item.step === 31 || item.step === 32 || item.step === 33" style="z-index: 10;font-weight: bold;">Configuration</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 31 && item.step != 32 && item.step != 33" style="z-index: 10;">Configuration</p>
                                <p class="ml-0 mb-0 col" v-if="item.step === 41 || item.step === 42 || item.step === 43" style="z-index: 10;font-weight: bold;">Surveillance</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 41 && item.step != 42 && item.step != 43" style="z-index: 10;">Surveillance</p>
                            </div>                        
                        </div>
                    </div>

                    <v-row style="justify-content:end;margin-top:10px;margin-bottom:10px;padding-right: 100px;">
                        <v-chip v-for="tag in getCampainTags(item)" class="pl-3 pr-3 ma-2" link :key="tag" style="font-size:12px;">
                            {{ tag }}
                        </v-chip>
                    </v-row>    
                </template>
            </v-data-table>

            <!-- DATA TABLE without view details -->
            <v-data-table
                :headers="headers2"
                :items="campains_displayed"
                class="elevation-1 datatable-operation"
                style="border-radius: 5px; overflow: hidden;"
                :items-per-page="-1"
                :loading="loading_datatable"
                :search="search"
                :sort-by="['id']"
                :sort-desc="[true]"
                v-if="campains && campains.length > 0 && campains_available && !view_details && campains_displayed.length > 0"
            >
                <!-- TOP -->
                <template v-slot:top>
                    <v-toolbar flat v-if="campains_available">
                        <div> 
                            <v-tooltip v-if="view_details" bottom dark>
                                <template v-slot:activator="{ on }">
                                    <v-btn class="ma-0 mr-5 col-auto elevation-3" v-on="on" text icon @click="viewDetails()" dusk="logout-button" color="primary" style="max-width: 50px !important;min-width: 45px !important;border-radius: 25px;background-color: #001047;">
                                        <v-icon color="white">mdi-eye</v-icon>
                                    </v-btn>
                                </template>
                                <span>Afficher le détail des campagnes</span>
                            </v-tooltip>
                            <v-tooltip v-if="!view_details" bottom dark>
                                <template v-slot:activator="{ on }">
                                    <v-btn class="ma-0 mr-5 col-auto elevation-3" v-on="on" text icon @click="viewDetails()" dusk="logout-button" color="red" style="max-width: 50px !important;min-width: 45px !important;border-radius: 25px;background-color: #f15959;">
                                        <v-icon color="white">mdi-eye-off</v-icon>
                                    </v-btn>
                                </template>
                                <span>Masquer le détail des campagnes</span>
                            </v-tooltip>
                        </div>

                        <div class="col col-8">
                            <div class="elevation-0 pl-5 pt-2 pb-2" >
                                Liste des campagnes
                            </div>
                        </div>

                        <!-- SEARCH -->
                        <v-col style="display: flex; justify-content: end;">
                            <v-layout class="justify-end mt-7 max-width-300">
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    :label="$t('global.search')"
                                ></v-text-field>
                            </v-layout>
                        </v-col>

                        <v-snackbar
                            v-model="snackbar"
                            :color="snackbar_type">
                            {{ snackbar_text }}
                            <template v-slot:action="{ attrs }">
                                <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                            </template>
                        </v-snackbar>
                    </v-toolbar>
                </template>

                <!-- INFOS CAMPAGNES -->
                <template v-slot:item.name="{ item }">
                    <div style="height:100%;padding-left:20px;">
                        <div v-if="item && item !== 'undefined' && item !== null && typeof item === 'object'">
                            <v-col class="ma-0 pa-0" style="justify-content:space-between;">
                                <!-- CAMPAIN NAME --> 
                                <v-col class="mb-0 mt-2 mr-6 pb-1" v-on="on" link :to="'/campain/' + item.id" style="font-weight: bold;cursor:pointer; border-radius:25px;">                                   
                                    <div style="display:flex;flex-direction:row;">
                                        <!-- CAMPAIN STATE --> 
                                        <div>
                                            <div v-if="item.step < 13 && item.closed_at === null"                   style="width: 15px; background-color: rgb(239 120 14);        height:15px;border-radius:15px;margin-right:12px;position:relative;margin-left:5px;top:3px;     "></div>
                                            <div v-if="item.step > 20 && item.step < 23 && item.closed_at === null" style="width: 15px; background-color: rgb(14 76 239 / 51%);   height:15px;border-radius:15px;margin-right:12px;position:relative;margin-left:5px;top:3px;      "></div>
                                            <div v-if="item.step > 22 && item.step < 33 && item.closed_at === null" style="width: 15px; background-color: rgb(14 76 239 / 79%);   height:15px;border-radius:15px;margin-right:12px;position:relative;margin-left:5px;top:3px;      "></div>
                                            <div v-if="item.step > 32 && item.step < 43 && item.closed_at === null" style="width: 15px; background-color: rgb(14, 76, 239);     height:15px;border-radius:15px;margin-right:12px;position:relative;margin-left:5px;top:3px;      "></div>
                                            <div v-if="item.step === 43 && item.closed_at === null"                 style="width: 15px; background-color: rgb(14, 76, 239);     height:15px;border-radius:15px;margin-right:12px;position:relative;margin-left:5px;top:3px;      "></div>
                                            <div v-if="item.closed_at != null"                                      style="width: 15px; background-color: rgb(86, 86, 86);      height:15px;border-radius:15px;margin-right:12px;position:relative;margin-left:5px;top:3px;      "></div>
                                        </div>
                                    
                                        <v-chip v-if="item.label !== null" class="mb-1 mt-1" v-on="on" link :to="'/campain/' + item.id" style="font-weight: bold; background-color: #f3f3f3; border: 1px solid #e5e5e5;">{{item.name}}</v-chip>
                                    
                                    </div>
                                    <div v-for="presta in prestas" :key="presta">
                                        <div v-for="typex in types" :key="typex">
                                            <div v-if="presta.id === item.presta && typex.id === item.type" style="font-weight:normal;font-size: 12px;margin-left: 32px;">{{ presta.name }} ({{ typex.name.toLowerCase().trim() }})</div>
                                        </div>
                                    </div>
                                </v-col>
                            </v-col>
                            <!-- Actions -->
                            <v-row class="col pa-2 pl-0 ml-10 mt-0 mb-0 pt-0">
                                <div v-if="item.closed_at != null">
                                    <v-tooltip v-if="!item.trash && access_level <= 99 && item.step == 43 && item.state == -1" bottom dark>
                                        <template v-slot:activator="{ on }">
                                            <v-btn class="ma-0 col-0" text icon v-on="on" link :to="'/campain/' + item.id" dusk="logout-button">
                                                <v-icon color="red">mdi-lock-open</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Ouvrir la campagne</span>
                                    </v-tooltip>
                                </div>

                                <div v-else>
                                    <v-tooltip v-if="!item.trash && access_level <= 99 && item.step == 43 && item.state != -1" bottom dark>
                                        <template v-slot:activator="{ on }">
                                            <v-btn class="ma-0 col-0" text icon v-on="on" link :to="'/campain/' + item.id" dusk="logout-button">
                                                <v-icon color="green">mdi-lock</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Cloturer la campagne</span>
                                    </v-tooltip>
                                </div>
                                
                                <v-tooltip bottom dark>
                                    <template v-slot:activator="{ on }">
                                        <v-btn class="ma-0 col-0" text icon v-on="on" link :to="'/campain/' + item.id" dusk="logout-button">
                                            <v-icon color="primary">mdi-eye</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Afficher les détails de la campagne</span>
                                </v-tooltip>

                                <v-tooltip v-if="!item.trash && access_level <= 99 " bottom dark>
                                    <template v-slot:activator="{ on }">
                                        <v-btn class="ma-0 col-0" text icon v-on="on" @click="openDialogUpdateCampain(item)" dusk="logout-button">
                                            <v-icon color="primary">mdi-circle-edit-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('campain.edit_campain') }}</span>
                                </v-tooltip>

                                <v-tooltip v-if="!item.trash && access_level <= 99" bottom dark>
                                    <template v-slot:activator="{ on }">
                                        <v-btn class="ma-0 col-0 mt-2" text icon v-on="on" @click="setDataTrash(item,1)" dusk="logout-button">
                                            <v-icon color="red">mdi-delete-alert</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('campain.desactivate_campain') }}</span>
                                </v-tooltip>
                            </v-row>
                        </div>
                    </div>
                </template>

                <!-- TAGS -->
                <template v-slot:item.tags="{ item }">
                    <v-row style="margin-top:10px;margin-bottom:10px;">
                        <v-chip v-for="tag in getCampainTags(item)" class="pl-3 pr-3 ma-2" link :key="tag" style="font-size:12px;">
                            {{ tag }}
                        </v-chip>
                    </v-row>    
                </template>

                <!-- CAMPAIN END STATE -->
                <template v-slot:item.description="{ item }" style="display:flex;flex-direction:column;width:100%;">
                    <!-- IF OPEN --> 
                    <div v-if="item && item !== 'undefined' && item !== null && typeof item === 'object' && item.closed_at === null">
                        <!-- OPEN OTHERS -->
                        <div  v-if="item.presta !== 5" class="col pa-0 ma-0" style="height:110px;overflow:hidden;position:relative;top: 10px;">
                            <div class="row row col pa-10 pt-0 pb-5 ma-0 ml-0 mt-1 mb-5" style="
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                border-radius: 100px;
                            ">
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step <= 11 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 12 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 13 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step > 13 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                    <!-- ADMIN VIEW -->
                                    <v-icon v-if="item.step === 11 && access_level <= 100" @click="updateStep(item.id,12)" class="pointer" color="orange" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 12 && access_level <= 100" @click="updateStep(item.id,13)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 13 && access_level <= 100" @click="updateStep(item.id,11)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step > 13 && access_level <= 100" @click="updateStep(item.id,11)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                </p>
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step >= 13 && item.step <= 21 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 22 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 23 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 13 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step > 23 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                    <!-- ADMIN VIEW -->
                                    <v-icon v-if="item.step >= 13 && item.step <= 21 && access_level <= 100" @click="updateStep(item.id,22)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 22 && access_level <= 100" @click="updateStep(item.id,23)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 23 && access_level <= 100" @click="updateStep(item.id,21)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 13 && access_level <= 100" @click="updateStep(item.id,21)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step > 23 && access_level <= 100" @click="updateStep(item.id,21)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                </p>
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step >= 23 && item.step <= 31 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 32 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 33 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 23 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step > 33 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                    <!-- ADMIN VIEW -->
                                    <v-icon v-if="item.step >= 23 && item.step <= 31 && access_level <= 100" @click="updateStep(item.id,32)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 32 && access_level <= 100" @click="updateStep(item.id,33)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 33 && access_level <= 100" @click="updateStep(item.id,31)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 23 && access_level <= 100" @click="updateStep(item.id,31)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step > 33 && access_level <= 100" @click="updateStep(item.id,31)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                </p>
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step >= 33 && item.step <= 41 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 42 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 43 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 33 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <!-- ADMIN VIEW -->
                                    <v-icon v-if="item.step >= 33 && item.step <= 41 && access_level <= 100" @click="updateStep(item.id,42)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 42 && access_level <= 100" @click="updateStep(item.id,43)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 43 && access_level <= 100" @click="updateStep(item.id,41)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 33 && access_level <= 100" @click="updateStep(item.id,41)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                </p>
                            </div>
                            
                            <div class="col" style="
                                position: relative;
                                border: 1px solid #656565;
                                top: -58px;
                                width: 70%;
                                left: 15%;
                                border-radius: 15px;
                                height: 2px;
                                padding: 0px;
                            "></div>
                            
                            <div class="row row col pa-10 pt-0 pb-0 ma-0 ml-0" style="
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                position: relative;
                                top: -57px;
                            ">
                                <p class="ml-0 mb-0 col" v-if="item.step === 11 || item.step === 12 || item.step === 13" style="z-index: 10;font-weight: bold;">Préparation</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 11 && item.step != 12 && item.step != 13" style="z-index: 10;">Préparation</p>
                                <p class="ml-0 mb-0 col" v-if="item.step === 21 || item.step === 22 || item.step === 23" style="z-index: 10;font-weight: bold;">Intervention</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 21 && item.step != 22 && item.step != 23" style="z-index: 10;">Intervention</p>
                                <p class="ml-0 mb-0 col" v-if="item.step === 31 || item.step === 32 || item.step === 33" style="z-index: 10;font-weight: bold;">Analyses</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 31 && item.step != 32 && item.step != 33" style="z-index: 10;">Analyses</p>
                                <p class="ml-0 mb-0 col" v-if="item.step === 41 || item.step === 42 || item.step === 43" style="z-index: 10;font-weight: bold;">Rapports</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 41 && item.step != 42 && item.step != 43" style="z-index: 10;">Rapports</p>
                            </div>                         
                        </div>
                        
                        <!-- OPEN ONLINE -->
                        <div  v-if="item.presta === 5" class="col pa-0 ma-0" style="height:110px;overflow:hidden;position:relative;top: 10px;">
                            <div class="row row col pa-10 pt-0 pb-5 ma-0 ml-0 mt-1 mb-5" style="
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                border-radius: 100px;
                            ">
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step <= 11 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 12 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 13 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step > 13 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                    <!-- ADMIN VIEW -->
                                    <v-icon v-if="item.step === 11 && access_level <= 100" @click="updateStep(item.id,12)" class="pointer" color="orange" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 12 && access_level <= 100" @click="updateStep(item.id,13)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 13 && access_level <= 100" @click="updateStep(item.id,11)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step > 13 && access_level <= 100" @click="updateStep(item.id,11)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                </p>
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step >= 13 && item.step <= 21 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 22 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 23 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 13 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step > 23 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                    <!-- ADMIN VIEW -->
                                    <v-icon v-if="item.step >= 13 && item.step <= 21 && access_level <= 100" @click="updateStep(item.id,22)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 22 && access_level <= 100" @click="updateStep(item.id,23)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 23 && access_level <= 100" @click="updateStep(item.id,21)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 13 && access_level <= 100" @click="updateStep(item.id,21)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step > 23 && access_level <= 100" @click="updateStep(item.id,21)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                </p>
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step >= 23 && item.step <= 31 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 32 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 33 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 23 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step > 33 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                    <!-- ADMIN VIEW -->
                                    <v-icon v-if="item.step >= 23 && item.step <= 31 && access_level <= 100" @click="updateStep(item.id,32)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 32 && access_level <= 100" @click="updateStep(item.id,33)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 33 && access_level <= 100" @click="updateStep(item.id,31)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 23 && access_level <= 100" @click="updateStep(item.id,31)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step > 33 && access_level <= 100" @click="updateStep(item.id,31)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                </p>
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step >= 33 && item.step <= 41 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 42 && access_level > 100" color="flashGreen" style="font-size: 36px;">mdi-play-network</v-icon>
                                    <v-icon v-if="item.step === 43 && access_level > 100" color="red" style="font-size: 36px;">mdi-close-network-outline</v-icon>
                                    <v-icon v-if="item.step < 33 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <!-- ADMIN VIEW -->
                                    <v-icon v-if="item.step >= 33 && item.step <= 41 && access_level <= 100" @click="updateStep(item.id,42)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 42 && access_level <= 100" @click="updateStep(item.id,43)" class="pointer" color="flashGreen" style="font-size: 36px;">mdi-play-network</v-icon>
                                    <v-icon v-if="item.step === 43 && access_level <= 100" @click="updateStep(item.id,41)" class="pointer" color="red" style="font-size: 36px;">mdi-close-network-outline</v-icon>
                                    <v-icon v-if="item.step < 33 && access_level <= 100" @click="updateStep(item.id,41)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                </p>
                            </div>
                            <div v-if="item.step === 42" class="col" style="
                                position: relative;
                                border: 1px solid #00db37;
                                top: -58px;
                                width: 70%;
                                left: 15%;
                                border-radius: 15px;
                                height: 2px;
                                padding: 0px;
                            "></div>
                            <div v-if="item.step === 43" class="col" style="
                                position: relative;
                                border: 1px solid #d70000;
                                top: -58px;
                                width: 70%;
                                left: 15%;
                                border-radius: 15px;
                                height: 2px;
                                padding: 0px;
                            "></div>
                            <div v-if="item.step < 42" class="col" style="
                                position: relative;
                                border: 1px solid #656565;
                                top: -58px;
                                width: 70%;
                                left: 15%;
                                border-radius: 15px;
                                height: 2px;
                                padding: 0px;
                            "></div>
                            <div class="row row col pa-10 pt-0 pb-0 ma-0 ml-0" style="
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                position: relative;
                                top: -57px;
                            ">
                                <p class="ml-0 mb-0 col" v-if="item.step === 11 || item.step === 12 || item.step === 13" style="z-index: 10;font-weight: bold;">Préparation</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 11 && item.step != 12 && item.step != 13" style="z-index: 10;">Préparation</p>
                                <p class="ml-0 mb-0 col" v-if="item.step === 21 || item.step === 22 || item.step === 23" style="z-index: 10;font-weight: bold;">Instrumentation</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 21 && item.step != 22 && item.step != 23" style="z-index: 10;">Instrumentation</p>
                                <p class="ml-0 mb-0 col" v-if="item.step === 31 || item.step === 32 || item.step === 33" style="z-index: 10;font-weight: bold;">Configuration</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 31 && item.step != 32 && item.step != 33" style="z-index: 10;">Configuration</p>
                                <p class="ml-0 mb-0 col" v-if="item.step === 41 || item.step === 42 || item.step === 43" style="z-index: 10;font-weight: bold;">Surveillance</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 41 && item.step != 42 && item.step != 43" style="z-index: 10;">Surveillance</p>
                            </div>                         
                        </div>
                    </div>

                    <!-- IF CLOSED -->
                    <div v-if="item && item !== 'undefined' && item !== null && typeof item === 'object' && item.closed_at != null">
                        <!-- CLOSED OTHERS -->
                        <div  v-if="item.presta !== 5" class="col pa-0 ma-0" style="height:110px;overflow:hidden;position:relative;top: 10px;">
                            <div class="row row col pa-10 pt-0 pb-5 ma-0 ml-0 mt-1 mb-5" style="
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                border-radius: 100px;
                            ">
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step <= 11" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 12" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 13 " style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step > 13" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                </p>
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step === 21" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 22" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 23" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 21"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step > 23" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>                                            </p>
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step === 31" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 32" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 33" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 31"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step > 33" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                </p>
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step === 41" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 42" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 43" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 41"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                </p>
                            </div>
                            <div class="col" style="
                                position: relative;
                                border: 1px solid #656565;
                                top: -58px;
                                width: 70%;
                                left: 15%;
                                border-radius: 15px;
                                height: 2px;
                                padding: 0px;
                            "></div>
                            <div class="row row col pa-10 pt-0 pb-0 ma-0 ml-0" style="
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                position: relative;
                                top: -57px;
                            ">
                                <p class="ml-0 mb-0 col" v-if="item.step === 11 || item.step === 12 || item.step === 13" style="z-index: 10;font-weight: bold;">Préparation</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 11 && item.step != 12 && item.step != 13" style="z-index: 10;">Préparation</p>
                                <p class="ml-0 mb-0 col" v-if="item.step === 21 || item.step === 22 || item.step === 23" style="z-index: 10;font-weight: bold;">Intervention</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 21 && item.step != 22 && item.step != 23" style="z-index: 10;">Intervention</p>
                                <p class="ml-0 mb-0 col" v-if="item.step === 31 || item.step === 32 || item.step === 33" style="z-index: 10;font-weight: bold;">Analyses</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 31 && item.step != 32 && item.step != 33" style="z-index: 10;">Analyses</p>
                                <p class="ml-0 mb-0 col" v-if="item.step === 41 || item.step === 42 || item.step === 43" style="z-index: 10;font-weight: bold;">Rapports</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 41 && item.step != 42 && item.step != 43" style="z-index: 10;">Rapports</p>
                            </div>                       
                        </div>

                        <!-- CLOSED ONLINE -->
                        <div  v-if="item.presta === 5" class="col pa-0 ma-0" style="height:110px;overflow:hidden;position:relative;top: 10px;">
                            <div class="row row col pa-10 pt-0 pb-5 ma-0 ml-0 mt-1 mb-5" style="
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                border-radius: 100px;
                            ">
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step <= 11 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 12 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 13 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step > 13 && access_level > 100" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                    </p>
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step === 21 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 22 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 23 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 21 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step > 23 && access_level > 100" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                </p>
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step === 31 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 32 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 33 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 31 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step > 33 && access_level > 100" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                </p>
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step === 41 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 42 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-play-network</v-icon>
                                    <v-icon v-if="item.step === 43 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-close-network-outline</v-icon>
                                    <v-icon v-if="item.step < 41 && access_level > 100" style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                </p>
                            </div>
                            <div v-if="item.step === 42" class="col" style="
                                position: relative;
                                border: 1px solid #00db37;
                                top: -58px;
                                width: 70%;
                                left: 15%;
                                border-radius: 15px;
                                height: 2px;
                                padding: 0px;
                            "></div>
                            <div v-if="item.step === 43" class="col" style="
                                position: relative;
                                border: 1px solid #d70000;
                                top: -58px;
                                width: 70%;
                                left: 15%;
                                border-radius: 15px;
                                height: 2px;
                                padding: 0px;
                            "></div>
                            <div v-if="item.step < 42" class="col" style="
                                position: relative;
                                border: 1px solid #656565;
                                top: -58px;
                                width: 70%;
                                left: 15%;
                                border-radius: 15px;
                                height: 2px;
                                padding: 0px;
                            "></div>
                            <div class="row row col pa-10 pt-0 pb-0 ma-0 ml-0" style="
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                position: relative;
                                top: -57px;
                            ">
                                <p class="ml-0 mb-0 col" v-if="item.step === 11 || item.step === 12 || item.step === 13" style="z-index: 10;font-weight: bold;">Préparation</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 11 && item.step != 12 && item.step != 13" style="z-index: 10;">Préparation</p>
                                <p class="ml-0 mb-0 col" v-if="item.step === 21 || item.step === 22 || item.step === 23" style="z-index: 10;font-weight: bold;">Instrumentation</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 21 && item.step != 22 && item.step != 23" style="z-index: 10;">Instrumentation</p>
                                <p class="ml-0 mb-0 col" v-if="item.step === 31 || item.step === 32 || item.step === 33" style="z-index: 10;font-weight: bold;">Configuration</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 31 && item.step != 32 && item.step != 33" style="z-index: 10;">Configuration</p>
                                <p class="ml-0 mb-0 col" v-if="item.step === 41 || item.step === 42 || item.step === 43" style="z-index: 10;font-weight: bold;">Surveillance</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 41 && item.step != 42 && item.step != 43" style="z-index: 10;">Surveillance</p>
                            </div>                        
                        </div>
                    </div>
                </template>
            </v-data-table>

            <!-- DATA TABLE TRASH DATA -->
            <v-data-table
                :headers="headers"
                :items="trashed_campains"
                class="elevation-1 datatable-operation"
                style="border-radius: 5px; overflow: hidden;"
                :items-per-page="-1"
                :loading="loading_datatable"
                :search="search"
                :sort-by="['id']"
                :sort-desc="[true]"
                v-if="trashed_campains && trashed_campains.length > 0 && active_special_vue == 99"
            >
                <!-- TOP -->
                <template v-slot:top>
                    <v-toolbar flat v-if="trashed_campains">
                        <div class="col col-8">
                            <div class="elevation-0 pl-5 pt-2 pb-2" >
                                Liste des campagnes désactivées
                            </div>
                        </div>
                        
                        <!-- SEARCH -->
                        <v-col style="display: flex; justify-content: end;">
                            <v-layout class="justify-end mt-7 max-width-300">
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    :label="$t('global.search')"
                                ></v-text-field>
                            </v-layout>
                        </v-col>

                        <v-snackbar
                            v-model="snackbar"
                            :color="snackbar_type">
                            {{ snackbar_text }}
                            <template v-slot:action="{ attrs }">
                                <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                            </template>
                        </v-snackbar>
                    </v-toolbar>
                </template>

                <!-- INFOS CAMPAGNES -->
                <template v-slot:item.name="{ item }">
                    <div style="height:100%;padding-left:20px;">
                        <div v-if="item && item !== 'undefined' && item !== null && typeof item === 'object'">
                            <v-col class="ma-0 pa-0" style="justify-content:space-between;">
                                <!-- CAMPAIN NAME --> 
                                <v-col class="mb-2 mt-0 mr-1" v-on="on" link :to="'/campain/' + item.id" style="font-weight: bold;cursor:pointer; border-radius:25px;">                                   
                                    <div style="display:flex;flex-direction:row;">
                                        <!-- CAMPAIN STATE --> 
                                        <div class="pt-2">
                                            <div v-if="item.step < 13 && item.closed_at === null"                   style="width: 15px; background-color: rgb(239 120 14);        height:15px;border-radius:15px;margin-right:12px;position:relative;margin-left:5px;top:3px;     "></div>
                                            <div v-if="item.step > 20 && item.step < 23 && item.closed_at === null" style="width: 15px; background-color: rgb(14 76 239 / 51%);   height:15px;border-radius:15px;margin-right:12px;position:relative;margin-left:5px;top:3px;      "></div>
                                            <div v-if="item.step > 22 && item.step < 33 && item.closed_at === null" style="width: 15px; background-color: rgb(14 76 239 / 79%);   height:15px;border-radius:15px;margin-right:12px;position:relative;margin-left:5px;top:3px;      "></div>
                                            <div v-if="item.step > 32 && item.step < 43 && item.closed_at === null" style="width: 15px; background-color: rgb(14, 76, 239);     height:15px;border-radius:15px;margin-right:12px;position:relative;margin-left:5px;top:3px;      "></div>
                                            <div v-if="item.step === 43 && item.closed_at === null"                 style="width: 15px; background-color: rgb(14, 76, 239);     height:15px;border-radius:15px;margin-right:12px;position:relative;margin-left:5px;top:3px;      "></div>
                                            <div v-if="item.closed_at != null"                                      style="width: 15px; background-color: rgb(86, 86, 86);      height:15px;border-radius:15px;margin-right:12px;position:relative;margin-left:5px;top:3px;      "></div>
                                        </div>
                                    
                                        <v-chip v-if="item.label !== null" class="mb-1 mt-1" v-on="on" link :to="'/campain/' + item.id" style="font-weight: bold; background-color: #f3f3f3; border: 1px solid #e5e5e5;">{{item.name}}</v-chip>
                                    
                                    </div>
                                    <div v-for="presta in prestas" :key="presta">
                                        <div v-for="typex in types" :key="typex">
                                            <div v-if="presta.id === item.presta && typex.id === item.type" style="font-weight:normal;font-size: 12px;margin-left: 32px;">{{ presta.name }} ({{ typex.name.toLowerCase().trim() }})</div>
                                        </div>
                                    </div>
                                </v-col>
                            </v-col>
                            <!-- CAMPAIN DETAILS --> 
                            <v-row class="ml-6 mb-0">
                                <v-col>
                                    <div style="font-size: 12px;margin-left: 10px;">Référence : {{item.uid}}</div>
                                    <div style="font-size: 12px;margin-left: 10px;">Créée le : {{item.created_at}}</div>
                                    <div v-for="tviewer in team" :key="tviewer">
                                        <div v-if="tviewer.id === item.creator" style="font-size: 12px;margin-left: 10px;margin-bottom:5px;">Créé par : {{tviewer.first_name}} {{tviewer.last_name}}</div>
                                    </div>
                                </v-col>
                                <v-col>
                                    <div v-for="analyst in analysts" :key="analyst">
                                        <div v-if="analyst.id === item.manager" style="font-size: 12px;margin-left: 10px;font-weight: bold;">Chargé de campagne : {{analyst.first_name}} {{analyst.last_name}}</div>
                                    </div>
                                    <div style="font-size: 12px;margin-left: 10px;">N° de dossier : {{item.affaire_number}}</div>
                                    <div v-for="site in sites" :key="site">
                                        <div v-if="site.id === item.id_park" style="font-size: 12px;margin-left: 10px;">Parc machine associé : {{site.label}}</div>
                                    </div>
                                </v-col>
                            </v-row>

                            <div v-if="item.description != null" class="ml-12" style="font-weight: bold;font-size: 12px;">Informations complémentaires :</div> 
                            <p v-if="item.description != null" class="ml-12 mb-0" style="font-size: 12px; margin-bottom:15px;">{{ item.description }}</p>

                            <v-row class="col pa-2 pl-0 ml-10 mt-2 mb-0">
                                <div v-if="item.trash && access_level == 0">
                                    <v-tooltip bottom dark>
                                        <template v-slot:activator="{ on }">
                                            <v-btn class="ma-0 col-0" text icon v-on="on" @click="setDataUntrash(item, item.trash)" dusk="logout-button">
                                                <v-icon color="green">mdi-delete-restore</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Réactiver la campagne</span>
                                    </v-tooltip>
                                </div>
                            </v-row>
                        </div>
                    </div>
                </template>

                <!-- CAMPAIN END STATE -->
                <template v-slot:item.description="{ item }" style="display:flex;flex-direction:column;width:100%;">
                    <!-- IF OPEN --> 
                    <div v-if="item && item !== 'undefined' && item !== null && typeof item === 'object' && item.closed_at === null">
                        <!-- OPEN OTHERS -->
                        <div  v-if="item.presta !== 5" class="col pa-0 ma-0" style="height:110px;overflow:hidden;position:relative;top: 10px;">
                            <div class="row row col pa-10 pt-5 pb-5 ma-0 ml-0 mt-1 mb-5" style="
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                border-radius: 100px;
                            ">
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step <= 11 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 12 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 13 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step > 13 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                    <!-- ADMIN VIEW -->
                                    <v-icon v-if="item.step === 11 && access_level <= 100" @click="updateStep(item.id,12)" class="pointer" color="orange" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 12 && access_level <= 100" @click="updateStep(item.id,13)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 13 && access_level <= 100" @click="updateStep(item.id,11)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step > 13 && access_level <= 100" @click="updateStep(item.id,11)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                </p>
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step >= 13 && item.step <= 21 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 22 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 23 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 13 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step > 23 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                    <!-- ADMIN VIEW -->
                                    <v-icon v-if="item.step >= 13 && item.step <= 21 && access_level <= 100" @click="updateStep(item.id,22)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 22 && access_level <= 100" @click="updateStep(item.id,23)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 23 && access_level <= 100" @click="updateStep(item.id,21)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 13 && access_level <= 100" @click="updateStep(item.id,21)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step > 23 && access_level <= 100" @click="updateStep(item.id,21)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                </p>
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step >= 23 && item.step <= 31 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 32 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 33 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 23 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step > 33 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                    <!-- ADMIN VIEW -->
                                    <v-icon v-if="item.step >= 23 && item.step <= 31 && access_level <= 100" @click="updateStep(item.id,32)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 32 && access_level <= 100" @click="updateStep(item.id,33)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 33 && access_level <= 100" @click="updateStep(item.id,31)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 23 && access_level <= 100" @click="updateStep(item.id,31)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step > 33 && access_level <= 100" @click="updateStep(item.id,31)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                </p>
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step >= 33 && item.step <= 41 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 42 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 43 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 33 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <!-- ADMIN VIEW -->
                                    <v-icon v-if="item.step >= 33 && item.step <= 41 && access_level <= 100" @click="updateStep(item.id,42)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 42 && access_level <= 100" @click="updateStep(item.id,43)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 43 && access_level <= 100" @click="updateStep(item.id,41)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 33 && access_level <= 100" @click="updateStep(item.id,41)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                </p>
                            </div>
                            
                            <div class="col" style="
                                position: relative;
                                border: 1px solid #656565;
                                top: -58px;
                                width: 70%;
                                left: 15%;
                                border-radius: 15px;
                                height: 2px;
                                padding: 0px;
                            "></div>
                            
                            <div class="row row col pa-10 pt-0 pb-0 ma-0 ml-0" style="
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                position: relative;
                                top: -57px;
                            ">
                                <p class="ml-0 mb-0 col" v-if="item.step === 11 || item.step === 12 || item.step === 13" style="z-index: 10;font-weight: bold;">Préparation</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 11 && item.step != 12 && item.step != 13" style="z-index: 10;">Préparation</p>
                                <p class="ml-0 mb-0 col" v-if="item.step === 21 || item.step === 22 || item.step === 23" style="z-index: 10;font-weight: bold;">Intervention</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 21 && item.step != 22 && item.step != 23" style="z-index: 10;">Intervention</p>
                                <p class="ml-0 mb-0 col" v-if="item.step === 31 || item.step === 32 || item.step === 33" style="z-index: 10;font-weight: bold;">Analyses</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 31 && item.step != 32 && item.step != 33" style="z-index: 10;">Analyses</p>
                                <p class="ml-0 mb-0 col" v-if="item.step === 41 || item.step === 42 || item.step === 43" style="z-index: 10;font-weight: bold;">Rapports</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 41 && item.step != 42 && item.step != 43" style="z-index: 10;">Rapports</p>
                            </div>                         
                        </div>
                        
                        <!-- OPEN ONLINE -->
                        <div  v-if="item.presta === 5" class="col pa-0 ma-0" style="height:110px;overflow:hidden;position:relative;top: 10px;">
                            <div class="row row col pa-10 pt-5 pb-5 ma-0 ml-0 mt-1 mb-5" style="
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                border-radius: 100px;
                            ">
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step <= 11 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 12 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 13 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step > 13 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                    <!-- ADMIN VIEW -->
                                    <v-icon v-if="item.step === 11 && access_level <= 100" @click="updateStep(item.id,12)" class="pointer" color="orange" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 12 && access_level <= 100" @click="updateStep(item.id,13)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 13 && access_level <= 100" @click="updateStep(item.id,11)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step > 13 && access_level <= 100" @click="updateStep(item.id,11)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                </p>
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step >= 13 && item.step <= 21 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 22 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 23 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 13 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step > 23 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                    <!-- ADMIN VIEW -->
                                    <v-icon v-if="item.step >= 13 && item.step <= 21 && access_level <= 100" @click="updateStep(item.id,22)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 22 && access_level <= 100" @click="updateStep(item.id,23)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 23 && access_level <= 100" @click="updateStep(item.id,21)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 13 && access_level <= 100" @click="updateStep(item.id,21)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step > 23 && access_level <= 100" @click="updateStep(item.id,21)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                </p>
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step >= 23 && item.step <= 31 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 32 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 33 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 23 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step > 33 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                    <!-- ADMIN VIEW -->
                                    <v-icon v-if="item.step >= 23 && item.step <= 31 && access_level <= 100" @click="updateStep(item.id,32)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 32 && access_level <= 100" @click="updateStep(item.id,33)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 33 && access_level <= 100" @click="updateStep(item.id,31)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 23 && access_level <= 100" @click="updateStep(item.id,31)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step > 33 && access_level <= 100" @click="updateStep(item.id,31)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                </p>
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step >= 33 && item.step <= 41 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 42 && access_level > 100" color="flashGreen" style="font-size: 36px;">mdi-play-network</v-icon>
                                    <v-icon v-if="item.step === 43 && access_level > 100" color="red" style="font-size: 36px;">mdi-close-network-outline</v-icon>
                                    <v-icon v-if="item.step < 33 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <!-- ADMIN VIEW -->
                                    <v-icon v-if="item.step >= 33 && item.step <= 41 && access_level <= 100" @click="updateStep(item.id,42)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 42 && access_level <= 100" @click="updateStep(item.id,43)" class="pointer" color="flashGreen" style="font-size: 36px;">mdi-play-network</v-icon>
                                    <v-icon v-if="item.step === 43 && access_level <= 100" @click="updateStep(item.id,41)" class="pointer" color="red" style="font-size: 36px;">mdi-close-network-outline</v-icon>
                                    <v-icon v-if="item.step < 33 && access_level <= 100" @click="updateStep(item.id,41)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                </p>
                            </div>
                            <div v-if="item.step === 42" class="col" style="
                                position: relative;
                                border: 1px solid #00db37;
                                top: -58px;
                                width: 70%;
                                left: 15%;
                                border-radius: 15px;
                                height: 2px;
                                padding: 0px;
                            "></div>
                            <div v-if="item.step === 43" class="col" style="
                                position: relative;
                                border: 1px solid #d70000;
                                top: -58px;
                                width: 70%;
                                left: 15%;
                                border-radius: 15px;
                                height: 2px;
                                padding: 0px;
                            "></div>
                            <div v-if="item.step < 42" class="col" style="
                                position: relative;
                                border: 1px solid #656565;
                                top: -58px;
                                width: 70%;
                                left: 15%;
                                border-radius: 15px;
                                height: 2px;
                                padding: 0px;
                            "></div>
                            <div class="row row col pa-10 pt-0 pb-0 ma-0 ml-0" style="
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                position: relative;
                                top: -57px;
                            ">
                                <p class="ml-0 mb-0 col" v-if="item.step === 11 || item.step === 12 || item.step === 13" style="z-index: 10;font-weight: bold;">Préparation</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 11 && item.step != 12 && item.step != 13" style="z-index: 10;">Préparation</p>
                                <p class="ml-0 mb-0 col" v-if="item.step === 21 || item.step === 22 || item.step === 23" style="z-index: 10;font-weight: bold;">Instrumentation</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 21 && item.step != 22 && item.step != 23" style="z-index: 10;">Instrumentation</p>
                                <p class="ml-0 mb-0 col" v-if="item.step === 31 || item.step === 32 || item.step === 33" style="z-index: 10;font-weight: bold;">Configuration</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 31 && item.step != 32 && item.step != 33" style="z-index: 10;">Configuration</p>
                                <p class="ml-0 mb-0 col" v-if="item.step === 41 || item.step === 42 || item.step === 43" style="z-index: 10;font-weight: bold;">Surveillance</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 41 && item.step != 42 && item.step != 43" style="z-index: 10;">Surveillance</p>
                            </div>                         
                        </div>
                    </div>

                    <!-- IF CLOSED -->
                    <div v-if="item && item !== 'undefined' && item !== null && typeof item === 'object' && item.closed_at != null">
                        <!-- CLOSED OTHERS -->
                        <div  v-if="item.presta !== 5" class="col pa-0 ma-0" style="height:110px;overflow:hidden;position:relative;top: 10px;">
                            <div class="row row col pa-10 pt-5 pb-5 ma-0 ml-0 mt-1 mb-5" style="
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                border-radius: 100px;
                            ">
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step <= 11" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 12" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 13 " style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step > 13" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                </p>
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step === 21" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 22" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 23" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 21"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step > 23" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>                                            </p>
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step === 31" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 32" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 33" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 31"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step > 33" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                </p>
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step === 41" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 42" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 43" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 41"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                </p>
                            </div>
                            <div class="col" style="
                                position: relative;
                                border: 1px solid #656565;
                                top: -58px;
                                width: 70%;
                                left: 15%;
                                border-radius: 15px;
                                height: 2px;
                                padding: 0px;
                            "></div>
                            <div class="row row col pa-10 pt-0 pb-0 ma-0 ml-0" style="
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                position: relative;
                                top: -57px;
                            ">
                                <p class="ml-0 mb-0 col" v-if="item.step === 11 || item.step === 12 || item.step === 13" style="z-index: 10;font-weight: bold;">Préparation</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 11 && item.step != 12 && item.step != 13" style="z-index: 10;">Préparation</p>
                                <p class="ml-0 mb-0 col" v-if="item.step === 21 || item.step === 22 || item.step === 23" style="z-index: 10;font-weight: bold;">Intervention</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 21 && item.step != 22 && item.step != 23" style="z-index: 10;">Intervention</p>
                                <p class="ml-0 mb-0 col" v-if="item.step === 31 || item.step === 32 || item.step === 33" style="z-index: 10;font-weight: bold;">Analyses</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 31 && item.step != 32 && item.step != 33" style="z-index: 10;">Analyses</p>
                                <p class="ml-0 mb-0 col" v-if="item.step === 41 || item.step === 42 || item.step === 43" style="z-index: 10;font-weight: bold;">Rapports</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 41 && item.step != 42 && item.step != 43" style="z-index: 10;">Rapports</p>
                            </div>                       
                        </div>

                        <!-- CLOSED ONLINE -->
                        <div  v-if="item.presta === 5" class="col pa-0 ma-0" style="height:110px;overflow:hidden;position:relative;top: 10px;">
                            <div class="row row col pa-10 pt-5 pb-5 ma-0 ml-0 mt-1 mb-5" style="
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                border-radius: 100px;
                            ">
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step <= 11 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 12 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 13 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step > 13 && access_level > 100" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                    </p>
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step === 21 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 22 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 23 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 21 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step > 23 && access_level > 100" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                </p>
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step === 31 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 32 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                    <v-icon v-if="item.step === 33 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                    <v-icon v-if="item.step < 31 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step > 33 && access_level > 100" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                </p>
                                <p class="ml-0 mb-0 col" style="z-index: 10;">
                                    <!-- CUSTOMER VIEW -->
                                    <v-icon v-if="item.step === 41 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                    <v-icon v-if="item.step === 42 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-play-network</v-icon>
                                    <v-icon v-if="item.step === 43 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-close-network-outline</v-icon>
                                    <v-icon v-if="item.step < 41 && access_level > 100" style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                </p>
                            </div>
                            <div v-if="item.step === 42" class="col" style="
                                position: relative;
                                border: 1px solid #00db37;
                                top: -58px;
                                width: 70%;
                                left: 15%;
                                border-radius: 15px;
                                height: 2px;
                                padding: 0px;
                            "></div>
                            <div v-if="item.step === 43" class="col" style="
                                position: relative;
                                border: 1px solid #d70000;
                                top: -58px;
                                width: 70%;
                                left: 15%;
                                border-radius: 15px;
                                height: 2px;
                                padding: 0px;
                            "></div>
                            <div v-if="item.step < 42" class="col" style="
                                position: relative;
                                border: 1px solid #656565;
                                top: -58px;
                                width: 70%;
                                left: 15%;
                                border-radius: 15px;
                                height: 2px;
                                padding: 0px;
                            "></div>
                            <div class="row row col pa-10 pt-0 pb-0 ma-0 ml-0" style="
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                position: relative;
                                top: -57px;
                            ">
                                <p class="ml-0 mb-0 col" v-if="item.step === 11 || item.step === 12 || item.step === 13" style="z-index: 10;font-weight: bold;">Préparation</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 11 && item.step != 12 && item.step != 13" style="z-index: 10;">Préparation</p>
                                <p class="ml-0 mb-0 col" v-if="item.step === 21 || item.step === 22 || item.step === 23" style="z-index: 10;font-weight: bold;">Instrumentation</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 21 && item.step != 22 && item.step != 23" style="z-index: 10;">Instrumentation</p>
                                <p class="ml-0 mb-0 col" v-if="item.step === 31 || item.step === 32 || item.step === 33" style="z-index: 10;font-weight: bold;">Configuration</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 31 && item.step != 32 && item.step != 33" style="z-index: 10;">Configuration</p>
                                <p class="ml-0 mb-0 col" v-if="item.step === 41 || item.step === 42 || item.step === 43" style="z-index: 10;font-weight: bold;">Surveillance</p>
                                <p class="ml-0 mb-0 col" v-if="item.step != 41 && item.step != 42 && item.step != 43" style="z-index: 10;">Surveillance</p>
                            </div>                        
                        </div>
                    </div>

                    <v-row style="justify-content:end;margin-top:10px;margin-bottom:10px;padding-right: 100px;">
                        <v-chip v-for="tag in getCampainTags(item)" class="pl-3 pr-3 ma-2" link :key="tag" style="font-size:12px;">
                            {{ tag }}
                        </v-chip>
                    </v-row>    
                </template>
            </v-data-table>

            <!-- NO CAMPAINS -->
            <div v-if="campains_displayed.length == 0 && active_special_vue != 99">
                <div class="ma-0 pa-0"> 
                    <div style="height:150px;border-radius:0px 0px 5px 5px;background-color:white;" class="ma-0 pa-0">
                        <div class="col-0" style="color:black;font-weight:800;display:flex;justify-content:center; height:100%;min-width: 8px;border-radius:5px;" >
                            <v-col class="pa-0 pt-8 pb-4" style="display:flex;justify-content:center;align-items:center;">
                                <div style="text-align:center;opacity:0.5;">  
                                    <v-icon color="primary" style="height:36px;font-size:36px;">
                                        mdi-calendar-question
                                    </v-icon>
                                    <br>
                                    <div class="pa-3 pb-0">
                                        Une campagne prévue ?
                                    </div>
                                    <p class="pb-0" style="font-weight:400">
                                        Nous n'avons encore jamais réalisé de campagne pour vous sur ce type de prestation.
                                    </p>
                                </div>
                            </v-col>
                        </div>
                    </div>
                </div>
            </div>

            <!-- NO CAMPAINS TRASH DATA -->
            <div v-if="trashed_campains.length == 0 && active_special_vue == 99">
                <div class="ma-0 pa-0"> 
                    <div style="height:150px;border-radius:0px 0px 5px 5px;background-color:white;" class="ma-0 pa-0">
                        <div class="col-0" style="color:black;font-weight:800;display:flex;justify-content:center; height:100%;min-width: 8px;border-radius:5px;" >
                            <v-col class="pa-0 pt-8 pb-4" style="display:flex;justify-content:center;align-items:center;">
                                <div style="text-align:center;opacity:0.5;">  
                                    <v-icon color="green" style="height:36px;font-size:36px;">
                                        mdi-delete-outline
                                    </v-icon>
                                    <br>
                                    <p class="pb-0" style="font-weight:400">
                                        Pas de campagne désactivée.
                                    </p>
                                </div>
                            </v-col>
                        </div>
                    </div>
                </div>
            </div>

            <!-- DATA VOID  --> 
            <!-- ALL DATA VOID (specific categories)-->
            <div v-if="!campains_available && !deprecated_available && filter_mode != 9" class="rounded row notification_card mb-1 pa-10" style="color: grey;justify-content: center;">
                <div style="color:grey;text-align: center;">Aucune campagne présente dans cette catégorie.</div>
            </div>

            <!--FILTER MAX SIZE MESSAGE ITEM-->
            <template v-slot:item.message="{ item }">
                {{ item.message.substring(0, 100) + '...' }}
            </template>

            <!-- MODALS     --> 
            <!-- campain ADD / EDIT MODAL -->
            <v-dialog v-model="dialog_edit" max-width="60%">
                <v-card>
                    <validation-observer ref="observer" v-slot="{ invalid }">
                        <v-form ref="form">
                            <v-card-title>
                                <span class="headline">{{ dialogTitle }}</span>
                            </v-card-title>
                            <v-divider></v-divider>

                            <v-card-text>
                                <v-col>
                                    <v-row>
                                        <v-col class="col-6">
                                            <!-- CAMPAIN NAME -->
                                            <validation-provider :name="$t('campain.add_name')" rules="required"
                                                                                                        v-slot="{ errors }">
                                                <v-text-field solo
                                                                :label="$t('campain.add_name')"
                                                                v-model="edited_campain.name"
                                                                :prepend-icon="'mdi-label-outline'"
                                                                :error-messages="errors"
                                                />
                                            </validation-provider>

                                            <!-- CAMPAIN TYPE -->
                                            <validation-provider :name="$t('campain.add_type')" rules="required"
                                                                    v-slot="{ errors }">
                                                <v-select
                                                    :items="types"
                                                    v-model="edited_campain.type"
                                                    :label="$t('campain.add_type')"
                                                    :prepend-icon="'mdi-book'"
                                                    :error-messages="errors"
                                                    item-value="id"
                                                    item-text="name" solo
                                                >
                                                </v-select>
                                            </validation-provider>

                                            <!-- CAMPAIN MANAGER -->
                                            <validation-provider :name="$t('campain.add_manager')" rules="required"
                                                                    v-slot="{ errors }">
                                                <v-select
                                                    :items="analysts"
                                                    v-model="edited_campain.manager"
                                                    :label="$t('campain.add_manager')"
                                                    :prepend-icon="'mdi-account-hard-hat'"
                                                    :error-messages="errors"
                                                    item-value="id"
                                                    item-text="first_name" solo
                                                >
                                                </v-select>
                                            </validation-provider>

                                            <!-- Date début de la campagne -->
                                            <validation-provider :name="$t('campain.add_date')" rules="required" v-slot="{ errors }">
                                                <v-menu
                                                    v-model="menuStart"
                                                    :close-on-content-click="false"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            v-model="edited_campain.intervention_date_start"
                                                            label="Date de début de l'intervention"
                                                            prepend-icon="mdi-calendar"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            :error-messages="errors"
                                                            solo
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="edited_campain.intervention_date_start"
                                                    ></v-date-picker>
                                                    <!--@input="menu = false"-->
                                                </v-menu>
                                            </validation-provider>
                                        </v-col>

                                        <v-col class="col-6">
                                            <!-- CAMPAIN PRESTA -->
                                            <validation-provider :name="$t('campain.add_presta')" rules="required"
                                                                    v-slot="{ errors }">
                                                <v-select
                                                    :items="prestas"
                                                    v-model="edited_campain.presta"
                                                    :label="$t('campain.add_presta')"
                                                    :prepend-icon="'mdi-book'"
                                                    :error-messages="errors"
                                                    item-value="id"
                                                    item-text="name" solo
                                                >
                                                </v-select>
                                            </validation-provider> 

                                            <!-- CAMPAIN AFFAIRE NUMBER -->
                                            <validation-provider :name="$t('campain.add_affaire_number')" rules="required"
                                                                    v-slot="{ errors }">
                                                <v-text-field solo
                                                                :label="$t('campain.add_affaire_number')"
                                                                v-model="edited_campain.affaire_number"
                                                                :prepend-icon="'mdi-qrcode'"
                                                                :error-messages="errors"
                                                />
                                            </validation-provider>

                                            <!-- CAMPAIN PARK -->
                                            <validation-provider :name="$t('campain.add_park')" rules="required"
                                                                    v-slot="{ errors }">
                                                <v-select
                                                    :items="sites"
                                                    v-model="edited_campain.id_park"
                                                    :label="$t('campain.add_park')"
                                                    :prepend-icon="'mdi-factory'"
                                                    :error-messages="errors"
                                                    item-value="id"
                                                    item-text="label" solo
                                                >
                                                </v-select>
                                            </validation-provider>

                                            <!-- Date fin de la campagne -->
                                            <validation-provider :name="$t('campain.add_date')" rules="required" v-slot="{ errors }">
                                                <v-menu
                                                    v-model="menuEnd"
                                                    :close-on-content-click="false"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            v-model="edited_campain.intervention_date_end"
                                                            label="Date de fin de l'intervention"
                                                            prepend-icon="mdi-calendar"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            :error-messages="errors"
                                                            solo
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="edited_campain.intervention_date_end"
                                                    ></v-date-picker>
                                                    <!--@input="menu = false"-->
                                                </v-menu>
                                            </validation-provider>
                                        </v-col>
                                    </v-row>
                                    
                                    <!-- CAMPAIN MACHINES -->
                                    <validation-provider :name="$t('campain.add_machines')" rules="required"
                                                            v-slot="{ errors }">
                                        <v-select
                                            :items="machines"
                                            v-model="edited_campain.machine_list"
                                            :label="$t('campain.add_machines')"
                                            :prepend-icon="'mdi-car-turbocharger'"
                                            :error-messages="errors"
                                            item-value="id"
                                            item-text="label" solo chips clearable multiple
                                        >
                                        <template v-slot:prepend-item>
                                            <v-list-item
                                                subtitle="Prochainement disponible, la capacité à sélectionner toutes les machines"
                                                title="A venir :"
                                                disabled
                                            >
                                                <template v-slot:prepend>
                                                    <v-avatar color="primary" icon="mdi-food-apple"></v-avatar>
                                                </template>
                                            </v-list-item>
                                            <v-divider class="mt-2 mb-2"></v-divider>
                                            <!--
                                            <v-list-item
                                                title="Sélectionner toutes les machines"
                                                @click="toggle"
                                            >
                                                <template v-slot:prepend>
                                                <v-checkbox-btn
                                                    :indeterminate="likesSomeFruit && !likesAllFruit"
                                                    :model-value="likesAllFruit"
                                                ></v-checkbox-btn>
                                                </template>
                                            </v-list-item>
                                            -->
                                        </template>

                                        <template slot="selection" slot-scope="data">
                                            <!-- HTML that describe how select should render selected items -->
                                            <v-chip  v-on="on">{{data.item.label}}</v-chip>
                                        </template>
                                        <template slot="item" slot-scope="data">
                                            <!-- HTML that describe how select should render items when the select is open -->
                                            <v-col>
                                                <div><strong>{{ data.item.label }}</strong></div>
                                                <div style="font-size:12px;">{{ data.item.building.label }}</div>
                                            </v-col>
                                        </template>
                                        </v-select>
                                    </validation-provider>
                                    
                                    
                                    <!-- CAMPAIN DESCRIPTION -->
                                    <validation-provider :name="$t('campain.notes')" rules="required"
                                                            v-slot="{ errors }">
                                        <v-textarea rows="2" solo no-resize
                                                v-model="edited_campain.description"
                                                :label="$t('campain.notes')"
                                                :prepend-icon="'mdi-comment-outline'"
                                                :error-messages="errors"
                                        />
                                    </validation-provider>

                                    <!-- CAMPAIN TAGS -->
                                    <validation-provider :name="$t('campain.add_tags')" rules="required"
                                                            v-slot="{ errors }">
                                        <v-select
                                            :items="tags"
                                            v-model="edited_campain.tags"
                                            :label="$t('campain.add_tags')"
                                            :prepend-icon="'mdi-tag'"
                                            :error-messages="errors"
                                            item-value="name"
                                            item-text="name" solo chips clearable multiple
                                        >
                                        </v-select>
                                    </validation-provider>
                                </v-col>
                            </v-card-text>

                            <v-card-actions class="mb-3" style="position:absolute;top:10px;right:10px">
                                <v-btn @click="dialog_edit = false;" style="color:red;max-width: 30px;padding: 0 0 0 5px;min-width: 10px!important;height: 30px!important;">
                                    <v-icon left>mdi-close</v-icon>
                                </v-btn>
                            </v-card-actions>

                            <v-card-actions class="pb-5" style="display:flex;justify-content:center">
                                <v-btn type="submit" color="primary" :disabled="invalid"
                                        @click.prevent="prepareEditCampain()">
                                    <v-icon left>mdi-content-save</v-icon>
                                    {{ $t('global.validation') }}
                                </v-btn>
                            </v-card-actions>
                        </v-form>
                    </validation-observer>
                </v-card>
            </v-dialog>

            <!-- CAMPAIN DELETE MODAL -->
            <v-dialog v-model="dialog_delete" max-width="500px">
                <v-card>
                    <v-card-title>
                        <span class="headline">
                            {{ $t('campain.delete_campain') }}
                        </span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <br/>

                    <v-card-actions>
                        <v-spacer/>
                        <v-btn @click="dialog_delete = false">
                            <v-icon left>mdi-close</v-icon>
                            {{ $t('global.cancel') }}
                        </v-btn>
                        <v-btn color="error" @click="confirmCampainDelete()">
                            <v-icon left>mdi-delete</v-icon>
                            {{ $t('global.delete') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!--ACTION BUTTONS -->
            <template v-slot:item.action="{ item }">
                <v-icon class="pointer" @click="setDataTrash(item, 1)" v-if="!campains_trash">
                    mdi-eye-check
                </v-icon>
                <v-icon class="pointer" @click="setDataTrash(item, 0)" v-if="campains_trash">
                    mdi-eye-remove
                </v-icon>
                <v-icon class="pointer" @click="openDialogShow(item)">
                    mdi-arrow-expand-all
                </v-icon>
            </template>

            <!-- Modal next update -->
            <v-dialog v-model="dialog_next_update" max-width="500px">
                <v-card>
                    <v-card-title>
                        <span class="headline">
                            Fonctionnalité bientôt disponible
                        </span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <br/>
                    <p class="pl-4 pr-4 pb-4">
                        Cette fonctionnalité est en cours de développement et sera bientôt disponible.
                        Elle vous permettra entre autre de filter et de rechercher précisément via des mots clés votre cible.
                        Un rapport, une machine, une référence...
                        Le but, vous faire gagner du temps ! 
                    </p>

                    <!-- Bouton pour fermer -->
                    <v-card-actions class="mb-3" style="position:absolute;top:5px;right:2px">
                        <v-btn @click="closeNewUpdateDialog();" style="color:red;max-width: 30px;padding: 0 0 0 5px;min-width: 10px!important;height: 30px!important;">
                            <v-icon left>mdi-close</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-col>
    </v-col>
</template>

<script>
import {CAMPAIN,SUBASSEMBLIES,MACHINES,REPORT,USERS,SITES} from "../../../api";
import moment from "moment";

export default {
    name: "VisionCards",
    data() {
        return {
            campains_vibratoire_nb: 0,
            campains_online_nb: 0,
            campains_thermographie_nb: 0,
            campains_ultrasons_nb: 0,
            campains_equilibrage_nb: 0,
            campains_expertises_nb: 0,

            selectedLevel: null,
            campains_displayed: [],
            active_special_vue: 0,
            activeVue: 0,
            vues:[
                {id:0, label: "Vibratoires (Offline)"},           // 0 et null
                {id:5, label: "Vibratoires (Online)"},            // 5
                {id:1, label: "Thermographies"},                  // 1
                {id:2, label: "Ultrasons"},                       // 2
                {id:3, label: "Equilibrage"},                     // 3
                {id:4, label: "Expertises avancées"},             // 4
                
            ],
            analysts_datas:[
                {id:40,  name:"Eric Quenneville", tel:"+33 6 38 38 11 18", email:"eric.quenneville@techview.fr", logo:"https://techview.fr/wp-content/uploads/2024/10/ERIC-QUENNEVILLE-CAT-III-e1725528314226-removebg-preview-2.png"},
                {id:121, name:"Pascal Ardoin",    tel:"+33 6 74 35 31 13", email:"pascal.ardoin@techview.fr", logo:"https://techview.fr/wp-content/uploads/2024/09/PASCAL-ARDOIN-CAT-II-e1725542858336.png"},
                {id:91,  name:"Amandine Prieur",  tel:"+33 7 87 58 28 98", email:"amandine.prieur@techview.fr", logo:"https://techview.fr/wp-content/uploads/2024/12/572e2dac-42b9-46f8-a7d5-995f87877850.png"},
                {id:278, name:"Louis Blaise",  tel:"+33 6 77 33 08 68", email:"louis.blaise@techview.fr"},
                {id:216, name:"Guillaume Maury",  tel:"+33 6 07 46 48 40", email:"guillaume.maury@techview.fr"},
                {id:79,  name:"Sébastien Poudenx",  tel:"+33 6 68 42 45 15", email:"sebastien.poudenx@techview.fr"},
            ],
            prestas:[
                {id:0, name:"Mesures vibratoires"},
                {id:1, name:"Thermographie"},
                {id:2, name:"Mesures ultrasons"},
                {id:3, name:"Equilibrages"},
                {id:4, name:"Expertise avancée"},
                {id:5, name:"Surveillance Online"},
            ],
            headers: [
                {text: "Informations campagnes", value: 'name', width: '40%', sortable: false},
                {text: "Etat d'avancement", value: 'description', width:'40%'},
            ],
            headers2: [
                {text: "Informations campagnes", value: 'name', width: '30%', sortable: false},
                {text: "Tags", value: 'tags', width: '30%', sortable: false},
                {text: "Etat d'avancement", value: 'description', width:'40%'},
            ],
            infoToggle: false, // Machine menu package //
            // CAMPAINS ----------------
            raw_campains: [],
            campains: [],
            raw_trashed_campains: [],
            trashed_campains: [],
            view_details: false,
            //-
            campains_trash:false,
            campains_available:false,
            deprecated_length:5,
            deprecated_available:false,
            dialog_next_update: false,
            //-
            active_campains_count:0,
            inactive_campains_count:0,
            global_campains_count:0,
            //-
            menu: false,
            edited_campain: {
                date: null
            },

            menuStart: false,
            menuEnd: false,
            
            // GLOBAL ----------------
            loading_datatable:true,
            loading: true,
            access_level: this.$store.state.access_level,
            //-
            search: '',
            filter_mode:9,
            edited_index: null,
            invalid: false,
            //-
            dialog_edit: false,
            dialog_delete: false,
            delete_id: null,
            deleted_index: null,
            
            // ARRAYS ----------------
            selectedFilter: 9,
            data_filters: [
                {
                    level: 9,
                    level_name: "Tout voir",
                    level_color: "#d7d7d752",
                    count:this.global_notifications_count
                },
                {
                    level: 0,
                    level_name: "En préparation",
                    level_color: "#ef780e",
                    count:0
                },
                {
                    level: 1,
                    level_name: "En cours d'intervention",
                    level_color: "#0e4cef82",
                    count:0
                },
                {
                    level: 2,
                    level_name: "En cours d'analyses",
                    level_color: "#0e4cefc9",
                    count:0
                },
                {
                    level: 3,
                    level_name: "Rapports en cours d'édition",
                    level_color: "#0e4cef",
                    count:0
                },
                {
                    level: -1,
                    level_name: "Terminées",
                    level_color: "#565656",
                    count:0
                },                
            ],
            data_filters2: [
                {level: 0, level_name: "En préparation", level_color: "#ef780e", count:0},
                {level: 1, level_name: "En cours d'intervention", level_color: "#0e4cef82", count:0},
                {level: 2, level_name: "En cours d'analyses", level_color: "#0e4cefc9", count:0},
                {level: 3, level_name: "Rapports en cours d'édition", level_color: "#0e4cef", count:0},
                {level: -1, level_name: "Terminées", level_color: "#565656", count:0},                
            ],
            edited_campain: {
                id: null,
                name: '',
                presta:'',
                type: '',
                affaire_number:'',
                manager: '',
                id_park: null,
                machine_list:[],
                description: '',
                tags:[],
                intervention_date_end: null,
                intervention_date_start: null,
                                      
            },
            analysts: [],
            team: [],
            machines: [],
            machines_count:0,
            reports_count:0,
            reports_done_count:0,
            sites: [],
            types:[
                {id:0, name:"Ponctuel"},
                {id:1, name:"Annuel"},
                {id:2, name:"Semestriel"},
                {id:3, name:"Quadrmestriel"}, 
                {id:4, name:"Trimestriel"},
                {id:5, name:"Continu"},
            ],
            tags:[
                {id:0, name:"Caractérisation machine"},
                {id:1, name:"Création BDD"},
                {id:2, name:"Etude approfondie"},
                {id:3, name:"Machines outils"},
                {id:4, name:"Machines tournantes"},
                {id:5, name:"Surveillance à distance"},
                {id:6, name:"Trés basse vitesse"},
                {id:7, name:"Historique"},
            ],
            timeProps: {
                format: "24hr"
            },
            textFieldProps: {
                prependInnerIcon: "mdi-calendar",
                solo: true,
                rules: [v => !!v || this.$t('vee-validation.required_date_operation')]
            },
        }
    },
    computed: {
        dialogTitle() {
            return this.edited_index === null ? this.$t('campain.add') : this.$t('campain.edit');
        }
    },
    created() {
        moment.locale('fr');
        this.initialize();

        
    },
    methods: {
        // COLLECTING ALL NEEDED DATAS
        initialize() {
            this.loading_datatable = true;
            this.view_details = true;
            this.getData();
            this.getSideDatas();
            //this.getReports();

        },
        getData() {
            // GETTING CAMPAINS
            this.axios.get(CAMPAIN + '?trash=0')
            
            .then((success) => {
                this.campains_vibratoire_nb = 0;
                this.campains_online_nb = 0;
                this.campains_thermographie_nb = 0;
                this.campains_ultrasons_nb = 0;
                this.campains_equilibrage_nb = 0;
                this.campains_expertises_nb = 0;

                success.data.forEach(item => {
                    item.created_at = moment(item.created_at).format("DD/MM/YYYY");
                    item.machine_list = JSON.parse(item.machine_list);

                    if (item.presta == 0 || item.presta == null) {this.campains_vibratoire_nb = this.campains_vibratoire_nb +1};
                    if (item.presta == 5) {this.campains_online_nb = this.campains_online_nb +1};
                    if (item.presta == 1) {this.campains_thermographie_nb = this.campains_thermographie_nb +1};
                    if (item.presta == 2) {this.campains_ultrasons_nb = this.campains_ultrasons_nb +1};
                    if (item.presta == 3) {this.campains_equilibrage_nb = this.campains_equilibrage_nb +1};
                    if (item.presta == 4) {this.campains_expertises_nb = this.campains_expertises_nb +1};
                })
                this.filter_mode = 9;
                this.raw_campains = [];
                this.campains = [];

                if (this.checkDataAvailable(success.data)){
                    this.raw_campains = success.data;
                    this.campains = success.data;
                }
            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");

            }).finally(() => {
                this.getTrashData();
                this.loadVue(0);
            })
        },
        getDataVue() {
            // GETTING CAMPAINS
            this.axios.get(CAMPAIN + '?trash=0')
            
            .then((success) => {
                this.campains_vibratoire_nb = 0;
                this.campains_online_nb = 0;
                this.campains_thermographie_nb = 0;
                this.campains_ultrasons_nb = 0;
                this.campains_equilibrage_nb = 0;
                this.campains_expertises_nb = 0;

                success.data.forEach(item => {
                    item.created_at = moment(item.created_at).format("DD/MM/YYYY");
                    item.machine_list = JSON.parse(item.machine_list);

                    if (item.presta == 0 || item.presta == null) {this.campains_vibratoire_nb = this.campains_vibratoire_nb +1};
                    if (item.presta == 5) {this.campains_online_nb = this.campains_online_nb +1};
                    if (item.presta == 1) {this.campains_thermographie_nb = this.campains_thermographie_nb +1};
                    if (item.presta == 2) {this.campains_ultrasons_nb = this.campains_ultrasons_nb +1};
                    if (item.presta == 3) {this.campains_equilibrage_nb = this.campains_equilibrage_nb +1};
                    if (item.presta == 4) {this.campains_expertises_nb = this.campains_expertises_nb +1};
                })
                this.filter_mode = 9;
                this.raw_campains = [];
                this.campains = [];

                if (this.checkDataAvailable(success.data)){
                    this.raw_campains = success.data;
                    this.campains = success.data;
                }
            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");

            }).finally(() => {
                this.getTrashData();
                this.displayCampains(this.active_special_vue);
            })
        },
        getTrashData() {
            // GETTING DEACTIVATED campainS
            this.axios.get(CAMPAIN + '?trash=1')

            .then((success) => {
                success.data.forEach(item => {
                    item.created_at = moment(item.created_at).format("DD/MM/YYYY");
                    item.machine_list = JSON.parse(item.machine_list);
                })
                this.filter_mode = 9;
                this.raw_trashed_campains = [];
                this.trashed_campains = [];

                if (this.checkTrashDataAvailable(success.data)){
                    this.raw_trashed_campains = success.data;
                    this.trashed_campains = success.data.slice(0, this.deprecated_length);
                }

            }).catch(() => {
                this.showSnackbar('error', "Trashed data error.");
            
            }).finally(() => {
                this.getDataCount();
            });         
        },
        getSideDatas(){
            this.axios.get(REPORT + '?trash=0')
            
            .then((success) => {
                this.reports = [];
                this.reports = success.data;

            }).catch(() => {
                this.showSnackbar('error', "Side datas error.");
            }).finally(() => {
                this.getAnalysts();
                this.getTeam();
                this.getMachines();
                this.getSites();
            })
        },
        getAnalysts(){
            this.axios.get(USERS + '/analysts')
            
            .then((success) => {
                this.analysts = [];
                this.analysts = success.data;

            }).catch(() => {
                this.showSnackbar('error', "Analysts datas error.");
            })
        },
        getTeam(){
            this.axios.get(USERS + '/team')
            
            .then((success) => {
                this.team = [];
                this.team = success.data;

            }).catch(() => {
                this.showSnackbar('error', "Team datas error.");
            })
        },
        getMachines() {
            this.axios.get(MACHINES + '/resume')
            
            .then((success) => {
                this.machines = [];
                this.machines = success.data;
                this.machines_count = this.machines.length;

            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");
            })
        },
        getSites() {
            this.axios.get(SITES + '/resume')
            
            .then((success) => {
                this.sites = [];
                this.sites = success.data;

            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");
            })
        },
        getDataCount() {
            // Reset all counts
            this.data_filters2.forEach(data => {data.count = 0});
            this.active_campains_count =0;
            this.inactive_campains_count =0;
            this.global_campains_count =0;
            
            // Get all last counts
            this.campains.forEach(item => {  
                if(item.closed_at === null){
                    this.active_campains_count = this.active_campains_count +1;
                }
                
                this.data_filters2.forEach(filter => { 
                    if(item.state == filter.level){
                        filter.count = filter.count +1;
                    }
                });
            });

            this.inactive_campains_count = this.trashed_campains.length;
            this.global_campains_count = this.active_campains_count + this.inactive_campains_count;

            // FREEUP LOADING STEP
            this.loading_datatable = false
        },
        getCampainTags(campain) {
            if (campain.tags != "") {
                return JSON.parse(campain.tags);
            }
        },
        getReports() {
            this.axios.get(
                SUBASSEMBLIES
            ).then((success) => {
                this.subassemblies = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'))
            }).finally(() => {
                this.loading = false
            });
        },

        // DISPLAY DATA MANAGEMENT
        checkDataAvailable(datas){
            if(typeof datas[0] === 'undefined') {
                this.campains_available = false;
                return false;
            }else {
                this.campains_available = true;
                return true;
            }
        },
        checkTrashDataAvailable(datas){
            if(typeof datas[0] === 'undefined') {                    
                this.deprecated_available = false;
                return false;
            }else {
                this.deprecated_available = true;
                return true;
            }
        },
        setDataFiltered (filter) {
            this.loading_datatable = true;
            this.filter_mode = filter;
            this.deprecated_length = 5;
            this.dataTreatment();
            this.dataTrashTreatment();
            this.loading_datatable = false;
        },
        dataTreatment(){
            if(this.checkDataAvailable(this.raw_campains)) {                    
                this.campains = [];

                if(this.filter_mode === 9){
                    this.campains = this.raw_campains;

                }else{
                    this.raw_campains.forEach(campain => {
                        if (campain.state == this.filter_mode){
                            this.campains.push(campain);
                        }
                    })
                }
                
                // Then check result to si if there is somehting left in chosen datas
                this.checkDataAvailable(this.campains);
            }
        },
        dataTrashTreatment(){
            if(this.checkTrashDataAvailable(this.raw_trashed_campains)) {                    
                this.trashed_campains = [];

                if(this.filter_mode === 9){
                    this.trashed_campains = this.raw_trashed_campains;

                }else{
                    this.raw_trashed_campains.forEach(campain => {
                        if (campain.state == this.filter_mode){
                            this.trashed_campains.push(campain);
                        }
                    })
                }

                //Get deprecated list by max length
                this.trashed_campains.slice(0, this.deprecated_length);
                
                // Then check result to si if there is somehting left in chosen datas
                this.checkTrashDataAvailable(this.trashed_campains);
            }
        },
        viewMore() {
            this.deprecated_length = this.deprecated_length + 10;
            this.dataTrashTreatment();
        },
        viewDetails(){
            this.view_details = !this.view_details;
        },

        // POST REQUESTS PART
        prepareEditCampain() {
            if (this.edited_index !== null) {
                this.updateCampain();
            } else {
                this.addCampain();
            }
            this.closeDialog();
        },
        setDataTrash(data, state) {
            // Set notification seen
            if (state === 1) {
                this.axios.patch(CAMPAIN + '/trash/' + data.id)
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.getData();
                    this.dialog_show = false;
                })
            
            // Set notification unseen
            } if (state === 0) {
                this.axios.patch(CAMPAIN + '/untrash/' + data.id)
            
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.getData();
                    this.dialog_show = false;
                })
            }
        },
        setDataUntrash(data, trash) {
            // Set notification seen
            if (trash === 1) {
                this.axios.patch(CAMPAIN + '/untrash/' + data.id)
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.getData();
                    this.dialog_show = false;
                })
            }
        },
        updateCampain() {
            this.axios.patch(CAMPAIN + '/' + this.edited_campain.id,
                this.edited_campain
            ).then((success) => {
                Object.assign(this.campains[this.edited_index], success.data);
                this.edited_index = null;
                this.showSnackbar('success', this.$t('global.success_to_edit'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            }).finally(() => {
                this.getData();
                this.loading_datatable = true;
                this.closeDialog();
            })
        },
        updateStep(id, step_id){
            this.axios.patch(CAMPAIN + '/step/' + id + "/" + step_id
            ).then((success) => {
                this.getDataVue();
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            })
        },

        addCampain() {
            let formData = new FormData()
            formData.append('name', this.edited_campain.name);
            formData.append('presta', this.edited_campain.presta);
            formData.append('type', this.edited_campain.type);
            formData.append('affaire_number', this.edited_campain.affaire_number);
            formData.append('manager', this.edited_campain.manager);
            formData.append('id_park', this.edited_campain.id_park);
            formData.append('machine_list', [JSON.stringify(this.edited_campain.machine_list)]);
            formData.append('description', this.edited_campain.description);
            formData.append('tags', [JSON.stringify(this.edited_campain.tags)]);
            formData.append('intervention_date_start', this.edited_campain.intervention_date_start);
            formData.append('intervention_date_end', this.edited_campain.intervention_date_end);

            this.axios.post(CAMPAIN, formData)

            .then((success) => {
                this.campains.push(success.data);
                this.showSnackbar('success', this.$t('global.success_to_add'));

            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_add'));

            }).finally(() => {
                this.getData();
                this.loading_datatable = true;
                this.closeDialog();
            });
        },
        confirmCampainDelete() {
            this.axios.delete(
                CAMPAIN + '/delete/' + this.delete_id
            ).then(() => {
                this.campains.splice(this.deleted_index, 1);
                this.showSnackbar('success', this.$t('global.success_to_delete'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_delete'));
            }).finally(() => {
                this.loading_datatable = true;
                this.getData();
                this.dialog_delete = false;
            });
        },
        setClosed(id){
            this.axios.patch(CAMPAIN + '/close/' + id)
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.getData();
                    this.dialog_show = false;
                })
        },
        setOpened(id){
            this.axios.patch(CAMPAIN + '/open/' + id)
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.getData();
                    this.dialog_show = false;
                })
        },

        // MODALS
        openDialogAddCampain() {
            if (this.edited_index !== null) {
                this.$refs.form.reset();
                this.$refs.observer.reset();
                this.edited_index = null;
            }

            this.dialog_edit = true;
        },
        openDialogUpdateCampain(campain) {
            this.edited_index = this.campains.indexOf(campain);
            this.edited_campain = Object.assign({}, campain);
            this.dialog_edit = true;
        },
        openDialogDeleteCampain(campain) {
            this.deleted_index = this.campains.indexOf(campain);
            this.delete_id = campain.id;
            this.dialog_delete = true;
        },
        openDialogShow(){
            console.log("open dialog show requested");
        },
        closeDialog() {
            this.$refs.form.reset();
            this.$refs.observer.reset();
            this.dialog_edit = false;
        },
        dialogNextUpdate() {
            if (this.dialog_next_update){
                this.dialog_next_update = false;
            }else{
                this.dialog_next_update = true;
            }
        },
        closeNewUpdateDialog(){
            this.setCookieVolet();
            this.dialog_new_update = false;
        },
        infoToggler(){
            this.infoToggle = !this.infoToggle;
        },
        goToDetail(item) {
            if (item && item.id) {
            this.$router.push(`/campain/${item.id}`);
            }
        },


        // ONGLETS
        loadVue(id) {
            this.active_special_vue = id;
            this.displayCampains(id);
        },
        displayCampains(id) {
            this.loading_datatable = true;
            this.campains_displayed = [];

            this.campains.forEach(campain => {
                if (campain.presta === id) this.campains_displayed.push(campain);
            });
            this.loading_datatable = false;
        },
    },
}
</script>