<template>
    <div id="gateways-add-remote-installer-stock" class="pa-4">
        <v-row no-gutters>
            <v-col>
                <p class="title mb-0 color-primary text-uppercase">
                    {{ $t('gateways_remote_installer_stock.title') }}
                </p>
            </v-col>
        </v-row>
        <v-card width="100%" class="mt-4" elevation="0">
            <v-form ref="form"
                    v-model="valid">
                <v-card-text>
                    <v-autocomplete
                        v-model="selected_installer"
                        :rules="checkEmptyRule"
                        :items="installers"
                        :label="$t('gateways_remote_installer_stock.installer')"
                        :item-text="setInstallerName" item-value="id"
                        filled
                    >
                        <template v-slot:item="{item}">
                            {{item.first_name+ ' ' +item.last_name}}
                        </template>
                    </v-autocomplete>
                    <v-textarea
                        v-model="qr_code_input"
                        :label="$t('gateways_stock_reception.gateway_list')"
                        :messages="$t('gateways_stock_reception.gateway_list_description')"
                        :rules="checkEmptyRule"
                        auto-grow
                        filled
                        required
                    >
                    </v-textarea>
                    <template v-if="gateways_after_check">
                        <template v-for="(checked_gateway,index) in gateways_after_check">
                            <v-row class="mt-2 ml-4">
                                <v-chip v-if="checked_gateway.gateway"
                                        :color="checked_gateway.color"
                                        class="mb-2"
                                        :key="checked_gateway.gateway"
                                        small
                                        draggable
                                        dark
                                >
                                    <v-icon
                                        left
                                        class="mr-1"
                                        color="white"
                                        size="17"
                                    > {{ checked_gateway.icon }}
                                    </v-icon>
                                    <span class="font-10">
                                         {{ checked_gateway.gateway }}
                                    </span>
                                    <v-icon right
                                            class="ml-2"
                                            color="white"
                                            size="15"
                                            @click="refreshChipsAfterDelete(index,checked_gateway.gateway)">
                                        mdi-close-circle
                                    </v-icon>
                                </v-chip>
                            </v-row>
                        </template>
                    </template>
                </v-card-text>
                <v-card-actions class="pr-4 pb-4">
                    <v-spacer/>
                    <v-btn color="primary"
                           @click="validate()"
                           :disabled="!valid">
                        {{ $t('global.validation') }}
                    </v-btn>
                </v-card-actions>
            </v-form>
            <v-row class="ml-3 font-10">
                <v-icon left color="primary" size="15">mdi-alert-circle</v-icon>
                <span>{{ $t('physical_devices_stock_reception.physical_device_update_hint') }}</span>
                <v-icon left color="red" size="12" class="ml-2">
                    mdi-color-helper
                </v-icon>
                <span>
                    {{ $t('global.not_exist_notice') }}
                </span>
                <v-icon left color="green" size="12" class="ml-2">
                    mdi-color-helper
                </v-icon>
                <span>
                    {{ $t('global.exist_notice') }}
                </span>
            </v-row>
        </v-card>
        <global-snackbar/>
    </div>
</template>

<script>
import {GATEWAYS, USERS} from "../../../../api";
import GlobalSnackbar from "../../../../global/components/GlobalSnackbar";

export default {
    name: "AddRemoteInstallerStock",
    components: {GlobalSnackbar},
    data() {
        return {
            valid: true,
            installers: [],
            selected_installer: null,
            qr_code_input: '',
            gateways: [],
            gateways_after_check: [],
            checkEmptyRule: [
                v => !!v || this.$t('global.empty_field'),
            ],
            chips_rendering_timeout_id: null,
            error_collector:{
                not_found:0,
                success:0
            },
        }
    },
    watch: {
        qr_code_input(val) {
            clearTimeout(this.chips_rendering_timeout_id);
            this.chips_rendering_timeout_id=setTimeout(()=>{
                this.gateways = val.match(this.gateway_regex);
                if (val.length < 2) {
                    this.gateways = [];
                    this.gateways_after_check = [];
                }
                this.refreshChips();
                this.chipsRendering();
            },1500);
        }
    },
    created() {
        this.getAllInstallers();
    },
    methods: {
        getAllInstallers() {
            this.axios.get(
                USERS + '/installers'
            ).then((success) => {
                this.installers = success.data;
            }).catch((error) => {
                this.manageError(error.response.status,this.$t('global.error'));
            });
        },
        async validate() {
            this.$refs.form.validate();
            if (this.gateways_after_check.length === 0) {
                this.manageError(0,this.$t('global.invalid_gateway_number'));
                this.qr_code_input = '';
            } else {
                for (let i = 0; i < this.gateways_after_check.length; i++) {
                    if (this.gateways_after_check[i].color === 'green') {
                        await this.axios.patch(
                            GATEWAYS + '/' + this.gateways_after_check[i].gateway,
                            {
                                state: 2,
                                selected_installer: this.selected_installer
                            }
                        )
                    }
                }
                this.gatewayStateCheck();
                this.confirmationMessageDisplay();
                this.$refs.form.reset();
                this.qr_code_input = "";
                this.gateways = [];
                this.gateways_after_check = [];
                this.error_collector={
                        not_found:0,
                        success:0
                };
            }
        },
        gatewayStateCheck(){
            this.error_collector.not_found=0;
            this.error_collector.success=0;

            this.gateways_after_check.forEach((gateway)=>{
                gateway.color === 'red' ? this.error_collector.not_found++ : this.error_collector.success++;
            });
        },
        confirmationMessageDisplay() {
            let successMessage = this.$t('gateways_remote_installer_stock.gateway_success_affectation_filter');
            console.log(this.error_collector);
            if(this.error_collector.not_found === 0 && this.error_collector.success > 0){
                successMessage = this.$t('gateways_remote_installer_stock.gateway_success_affectation');
                this.showGlobalSnackbar('success', successMessage);
            }
            else if(this.error_collector.not_found > 0 && this.error_collector.success === 0){
                this.manageError(0, this.$t('gateways_remote_installer_stock.not_affected'));
            }
            else {
                this.showGlobalSnackbar('success', successMessage);
            }

        },
        chipsRendering() {
            if (this.gateways) {
                this.gateways.forEach((gateway,index) => {
                    this.axios.get(GATEWAYS + '/' + gateway)
                        .then((success) => {
                            let chipColor = '';
                            let chipIcon = '';

                            if (success.data.id && gateway.length === 16) {
                                chipColor = 'green';
                                chipIcon = 'mdi-checkbox-marked-circle-outline';
                            } else {
                                chipColor = 'red';
                                chipIcon = "mdi-alert-octagon";
                            }
                            if (this.gateways.length === 0) {
                                this.gateways_after_check.push({
                                    gateway: gateway,
                                    color: chipColor,
                                    icon: chipIcon
                                });
                            } else {
                                let item_index = 0;
                                item_index = this.gateways_after_check.findIndex(obj => obj.gateway === gateway)
                                if (item_index === -1) {
                                    this.gateways_after_check.push({
                                        gateway: gateway,
                                        color: chipColor,
                                        icon: chipIcon
                                    });
                                }
                            }
                        });
                });
            }
        },
        refreshChipsAfterDelete(index, gateway_eui) {
            if (this.gateways_after_check && this.qr_code_input.length > 0) {
                this.gateways_after_check.splice(index, 1);
                this.gateways.splice(index, 1);
                this.qr_code_input = this.qr_code_input.replace(gateway_eui, "");
            }
        },
        refreshChips() {
            if (!this.gateways) {
                this.gateways_after_check = [];
            }
            if (this.gateways_after_check) {
                for (let i = this.gateways_after_check.length - 1; i >= 0; i--) {
                    if (!this.gateways.find(gateway => {
                        gateway === this.gateways_after_check[i].gateway
                    })) {
                        this.gateways_after_check.splice(i, 1);
                    }
                }
            }
        },
        setInstallerName(item){
            return `${item.first_name}  ${item.last_name}`;
        }
    },
}
</script>
