<template>
    <div id="login-view" class="page-not-connected">
        <v-row v-if="maintenance_mode" align="center" justify="center" class="connexion-panel pl-15 pr-15 rounded " id="login-msg" style="text-align:center;background-color: rgb(255 192 117);color: black;max-width: 65%;position: fixed;height: 120px;top: 12px;font-size: 14px;}">
            <h4 class="mr-10">Migrations et mises à jour en cours...</h4>
            <p>Chers utilisateurs, notre plateforme est actuellement en cours de maintenance jusqu'au 04/07 14h.
                Durant cette periode la connexion à votre compte restera désactivée.</br>
                <strong>Cependant, sachez que nos services de collecte, de traitement de données et de diagnostic ne seront pas impactés et resteront actifs.</strong></br>
                Merci pour votre compréhension, l'équipe Techview.
            </p>
        </v-row>

        <!-- Left part for connexion -->
        <div id="login-panel" >
            <div id="login-left"> 
                <div id="login-logo-img" style="margin-bottom:10px!important;">
                    <img src="/images/techview_logo_white.png" alt="Logo Techview" style="margin-bottom:5px;">
                </div>

                <div id="login-header">
                    <h4 style="margin-bottom:30px;font-size:18px;">Experts Inside</h4>
                    <p style="font-size:14px;">Bienvenue sur notre portail de connexion.</br> Pour plus d'informations sur nos services en ligne rendez-vous sur notre <a href="https://techview.fr" style="color:white;font-weight:bold">site web.</a></p>
                    </br>
                    <p style="font-size:14px;">Vous souhaitez <a href="https://techview.fr/informations-techniques/" style="color:white;font-weight:bold">nous contacter</a> ? </br>Envoyez nous directement vos informations par e-mail à l'adresse contact@techview.fr</p>
                </div>
            </div>   

            <v-row align="center" justify="center" class="connexion-panel" id="login-véro">
                    <v-col>
                        <v-card>
                            <validation-observer v-slot="{ invalid }">
                                <v-form id="login-form">
                                    <v-card-text class="pt-4">
                                        <validation-provider :name="$t('login.email')" rules="required|email"
                                                            v-slot="{ errors }"
                                        >
                                            <v-text-field v-model="email" id="email" name="email"
                                                        :label="$t('login.email')"
                                                        :error-messages="errors"
                                                        class="pt-2" prepend-icon="mdi-account"
                                            />
                                        </validation-provider>

                                        <validation-provider :name="$t('login.password')" rules="required" v-slot="{ errors }">
                                            <v-text-field v-model="password"
                                                        id="password" name="password"
                                                        prepend-icon="mdi-lock"
                                                        :label="$t('login.password')"
                                                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                                        :type="show ? 'text' : 'password'"
                                                        @click:append="show = !show"
                                                        :error-messages="errors"
                                                        required autocomplete="on"
                                            />
                                        </validation-provider>

                                        <v-row>
                                            <v-col class="py-0">
                                                <v-checkbox v-model="remember_me" label="Se souvenir de moi"
                                                            hide-details required
                                                />
                                            </v-col>

                                            <v-col class="py-0" :class="is_mobile ? 'text-left' : 'text-right'">
                                                <v-btn class="btn-customization pt-6 pr-0 font-12" text plain
                                                    :class="is_mobile ? 'pl-0' : ''"
                                                    @click="$refs.modalResetPassword.dialog_forgotten_password = true"
                                                >
                                                    {{ $t('global.forgotten_password') }}
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>

                                    <v-card-actions class="justify-end">
                                        <v-btn @click.prevent="login()" :loading="login_button_loading" :disabled="invalid"
                                            type="submit" color="primary" dusk="login-button"
                                        >
                                            {{ $t('login.login') }}
                                        </v-btn>
                                    </v-card-actions>
                                </v-form>
                            </validation-observer>
                        </v-card>
                    </v-col>
            </v-row>
        </div>

        <!-- Footer de la page login -->
        <div id="login-footer">
            <h5><strong>Techview 2022-2024 © Copyright,</strong> tous droits réservés.
                <a href="https://techview.fr/declaration-de-confidentialite/"> Mentions légales </a> 
                &
                <a href=""> Politique de confidentialité </a>
            </h5>
        </div>

        <!-- modals -->
        <modal-reset-password ref="modalResetPassword"/>
        <global-snackbar/>
    </div>
</template>

<!-- scripts part -->
<script>
import GlobalSnackbar from "../components/GlobalSnackbar";
import ModalResetPassword from "../components/ModalResetPassword";

export default {
    name: 'LoginView',
    components: {GlobalSnackbar, ModalResetPassword},
    data: function () {
        return {
            login_button_loading: false,
            invalid: false,
            email: '',
            password: '',
            remember_me: true,
            show: false,
            maintenance_mode:false,
            notification: 0,
        }
    },
    methods: {
        login() {
            this.login_button_loading = true;
            this.$auth.login({
                data: {
                    email: this.email,
                    password: this.password
                },
                staySignedIn: this.remember_me
            }).then(() => {
                this.$store.dispatch('snackbar/hideSnackbar');
                this.$store.commit('setUserRoleId', this.$auth.user().role_id);
                this.$store.commit('setUserAccessLevel', this.$auth.user().access_level);

                if(this.$auth.user().notifications){
                    this.notification =  this.notification + this.$auth.user().notifications;
                    this.$store.commit('setNotification', this.notification);
                }
                
                this.$store.commit('setSwitchState', false);

                if (this.$auth.user().access_level > 99) {
                    this.$router.push({name: 'dashboard'});

                } else if (this.$auth.user().access_level === 0) {
                    this.$router.push({name: 'tnWjK3VljKmJ6H55x8'});

                } else {
                    this.$router.push({name: 'mco-panel'});
                }

            }).catch((error) => {
                this.manageError(error.response.status);
            }).finally(() => {
                this.login_button_loading = false;
            })
        },
    }
}
</script>

<!-- style part -->
<style scoped>
.btn-customization:hover {
    text-decoration: underline;
}
</style>
