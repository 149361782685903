<template>
    <div id="professional-detail">
        <v-row>
            <v-col class="ml-6 mr-6" style="background-color: #001047;">
                <v-subheader class="v-subheader col theme--light">
                    <!------------------- Info bulle ---------------------->
                    <v-tooltip bottom dark class="mr-2 ml-2">
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 mr-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                                <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:default>
                        <div style="min-width: 200px;position:relative;z-index: 10;">
                            <strong>Page [Gestion du client]</strong>
                            <p>Toutes les machines que nous sommes susceptibles de surveiller se trouvent ici.</br>
                                Toutes les caractéristiques techniques associées au suivi en ligne sont configurés sur cette page.</br>
                                Ceci est valable par exemple pour les sous-ensembles. </br>
                                Cliquer sur l'un d'entre eux pour le visualiser en détail.
                            </p>
                        </div>
                        </template>
                    </v-tooltip>
                    <p class="title mb-0 color-white" style="color: white!important;">
                        <div style="color:#FFFFFFb3;margin-right:4px;">Configuration > </div><div @click="infoToggler()" style="color:#FFFFFF;font-weight:bold;"> {{ $t('professional.title') }} : {{ label }}</div>
                    </p>
                </v-subheader>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: "ProfessionalDetail"
}
</script>