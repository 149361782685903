<template>
    <v-card elevation="2" outlined>
        <v-card-title class="justify-center">
            <!--         <v-card-title class="d-flex justify-space-between">
                       <v-icon>mdi-state-machine</v-icon>-->
            {{ $t('datatable_building_machines.title') }}
            <!--            <v-btn elevation="3" color="amber darken-3" @click="openDialogBindMachine()" small>
                            <v-icon color="white" small>mdi-link-variant</v-icon>
                        </v-btn>-->
        </v-card-title>
        <v-divider></v-divider>

        <v-data-table
            :headers="headers"
            :items="building_machines"
            sort-by="id"
            class="elevation-1 datatable-setting-min-height"
            :loading="loading"
            :items-per-page="5"
            :search="search">

            <template v-slot:top>
                <v-toolbar flat color="white" class="pt-3">
                    <v-row>
                        <v-col cols="8">
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                :label="$t('global.search')"
                            ></v-text-field>
                        </v-col>
                    </v-row>


                    <v-dialog v-model="dialog_bind_machine" max-width="500px">
                        <v-card>
                            <validation-observer ref="observer" v-slot="{ invalid }">
                                <v-form ref="form">
                                    <v-card-title class="justify-center">
                                <span class="headline">
                                    {{ $t('datatable_building_machines.associate_to_another_building') }}
                                </span>
                                    </v-card-title>
                                    <v-divider></v-divider>
                                    <br/>

                                    <v-card-text>
                                        <validation-provider :name="$t('global.label')" rules="required"
                                                             v-slot="{ errors }">
                                            <v-select
                                                :items="buildings"
                                                v-model="building_id_to_bind"
                                                :label="$t('navbar.buildings')"
                                                :prepend-inner-icon="'mdi-office-building-outline'"
                                                item-text="label"
                                                item-value="id" solo
                                                :error-messages="errors"
                                            ></v-select>
                                        </validation-provider>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-spacer/>
                                        <v-btn @click="closeDialog()">
                                            <v-icon left>mdi-close</v-icon>
                                            {{ $t('global.cancel') }}
                                        </v-btn>
                                        <v-btn color="primary" @click="bindMachine()"
                                               :disabled="invalid"
                                        >
                                            <v-icon left>mdi-link-variant-off</v-icon>
                                            {{ $t('global.dissociate') }}
                                        </v-btn>
                                    </v-card-actions>
                                </v-form>
                            </validation-observer>
                        </v-card>
                    </v-dialog>

                    <v-snackbar
                        v-model="snackbar"
                        :color="snackbar_type">
                        {{ snackbar_text }}
                        <template v-slot:action="{ attrs }">
                            <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                        </template>
                    </v-snackbar>
                </v-toolbar>
            </template>

            <template v-slot:item.label="{ item }">
                <router-link :to="{ path: '/machine/' + item.id}">
                    {{ item.label }}
                </router-link>
            </template>

            <template v-slot:item.building.path_plan="{ item }">
                <v-img aspect-ratio="1" v-if="item.building.path_plan" class="mb-2 mt-2" :src="item.building.path_plan"
                       height="200" contain>
                    <template v-slot:placeholder>
                        <v-row
                            class="fill-height ma-0 background-loader-image"
                            align="center" justify="center"
                        >
                            <v-progress-circular indeterminate color="black lighten-5"></v-progress-circular>
                        </v-row>
                    </template>
                </v-img>
            </template>

            <template v-slot:item.path_plan="{ item }">
                <v-img aspect-ratio="1" v-if="item.path_plan" class="mb-2 mt-2" :src="item.path_plan" height="200"
                       contain>
                    <template v-slot:placeholder>
                        <v-row
                            class="fill-height ma-0 background-loader-image"
                            align="center" justify="center"
                        >
                            <v-progress-circular indeterminate color="black lighten-5"></v-progress-circular>
                        </v-row>
                    </template>
                </v-img>
            </template>

            <template v-slot:item.subassemblies="{ item }">
                <span v-if="item.subassemblies.length === 0">{{ $t('machines.no_subassemblies') }}</span>
                <template v-else v-for="subassembly in item.subassemblies">
                    <v-chip :key="subassembly.id" class="mt-1 mb-1" :color="assetColor(subassembly)"
                            @click="$router.push({ name: 'subassembly', params: {id: subassembly.id} })"
                            text-color="white"
                    >
                        {{ subassembly.label }}
                    </v-chip>
                    <br/>
                </template>
            </template>

            <template v-slot:item.action="{ item }">
                <v-icon class="pointer" @click="openDialogBindMachine(item)">mdi-link-variant-off</v-icon>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
import {BUILDINGS, MACHINES} from "../../../api";

export default {
    name: "DatatableBuildingMachines",
    data() {
        return {
            building_id: null,
            buildings: [],
            building_machines: [],
            machines: [],
            headers: [
                {text: this.$t('datatable_building_machines.machine_name'), value: 'label'},
                {text: this.$t('datatable_building_machines.location'), value: 'building.path_plan'},
                {text: this.$t('global.visualization'), value: 'path_plan'},
                {text: this.$t('datatable_building_machines.subassemblies'), value: 'subassemblies'},
                {text: this.$t('global.actions'), value: 'action'}
            ],
            loading: true,
            search: '',
            invalid: false,

            dialog_bind_machine: false,
            loading_bind_machine: false,
            machine_id: null,
            building_id_to_bind: null
        }
    },
    created() {
        this.building_id = this.$route.params.id;
        this.getBuildingMachines();
        this.getBuildings();
    },
    methods: {
        getBuildingMachines() {
            this.loading = true;

            this.axios.get(
                BUILDINGS + "/" + this.building_id + "/machines"
            ).then((success) => {
                this.building_machines = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'))
            }).finally(() => {
                this.loading = false;
            });
        },

        getBuildings() {
            this.axios.get(
                BUILDINGS
            ).then((success) => {
                this.buildings = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            });
        },

        openDialogBindMachine(machine) {
            this.machine_id = machine.id;
            this.dialog_bind_machine = true;
        },

        bindMachine() {
            this.axios.put(
                MACHINES + '/' + this.machine_id + '/bind/building/' + this.building_id_to_bind,
            ).then(() => {
                this.getBuildingMachines();
                this.showSnackbar('success', this.$t('global.success_to_edit'));
                this.$refs.form.reset();
                this.$refs.observer.reset();
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            }).finally(() => {
                this.closeDialog();
            });
        },

        closeDialog() {
            this.building_id_to_bind = null;
            this.dialog_bind_machine = false;
        }
    }
}
</script>
