<template>
    <v-card elevation="2" outlined>
        <v-card-title class="justify-center">
            {{ $t('datatable_machine.title') }}
        </v-card-title>
        <v-divider></v-divider>

        <v-data-table
            :headers="headers"
            :items="site_machines"
            sort-by="id"
            class="elevation-1 datatable-setting-min-height"
            :loading="loading"
            :items-per-page="5"
            :search="search">

            <template v-slot:top>
                <v-toolbar flat color="white" class="pt-3">
                    <v-row>
                        <v-col cols="8">
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                :label="$t('global.search')"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-snackbar
                        v-model="snackbar"
                        :color="snackbar_type">
                        {{ snackbar_text }}
                        <template v-slot:action="{ attrs }">
                            <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                        </template>
                    </v-snackbar>
                </v-toolbar>
            </template>

            <template v-slot:item.label="{ item }">
                <router-link :to="{ path: '/machine/' + item.id}">
                    {{ item.label }}
                </router-link>
            </template>

            <template v-slot:item.path_plan="{ item }">
                <v-img aspect-ratio="1" v-if="item.path_plan" class="mb-2 mt-2" :src="item.path_plan" height="200"
                       contain>
                    <template v-slot:placeholder>
                        <v-row
                            class="fill-height ma-0 background-loader-image"
                            align="center" justify="center"
                        >
                            <v-progress-circular indeterminate color="black lighten-5"></v-progress-circular>
                        </v-row>
                    </template>
                </v-img>
            </template>

            <template v-slot:item.building="{ item }">
                <router-link :to="{ path: '/building/' + item.building.id}">
                    {{ item.building.label }}
                </router-link>
            </template>

            <template v-slot:item.building.path_plan="{ item }">
                <v-img aspect-ratio="1" v-if="item.building.path_plan" class="mb-2 mt-2" :src="item.building.path_plan"
                       height="200" contain>
                    <template v-slot:placeholder>
                        <v-row
                            class="fill-height ma-0 background-loader-image"
                            align="center" justify="center"
                        >
                            <v-progress-circular indeterminate color="black lighten-5"></v-progress-circular>
                        </v-row>
                    </template>
                </v-img>
            </template>

            <template v-slot:item.subassemblies="{ item }">
                <span v-if="item.subassemblies.length === 0">{{ $t('machines.no_subassemblies') }}</span>
                <v-chip-group v-else>
                    <v-chip v-for="subassembly in item.subassemblies" :key="subassembly.id"
                            :color="assetColor(subassembly)" text-color="white"
                            @click="$router.push({ name: 'subassembly', params: {id: subassembly.id} })"
                    >
                        {{ subassembly.label }}
                    </v-chip>
                </v-chip-group>
            </template>

            <template v-slot:item.action="{ item }">
                <v-icon class="pointer">mdi-link-variant-off</v-icon>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
import {SITES} from "../../../api";

export default {
    name: "DatatableSiteMachines",
    data() {
        return {
            site_id: null,
            site_machines: [],
            headers: [
                {text: this.$t('datatable_machine.building_name'), value: 'building'},
                {text: this.$t('datatable_machine.building_plan'), value: 'building.path_plan'},
                {text: this.$t('datatable_machine.machine_name'), value: 'label'},
                {text: this.$t('machines.machine_photo'), value: 'path_plan'},
                {text: this.$t('datatable_machine.subassemblies'), value: 'subassemblies'}
            ],
            loading: true,
            search: '',
        }
    },
    created() {
        this.site_id = this.$route.params.id;
        this.getSiteMachines();
    },
    methods: {
        getSiteMachines() {
            this.axios.get(
                SITES + "/" + this.site_id + "/machines"
            ).then((success) => {
                this.site_machines = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'))
            }).finally(() => {
                this.loading = false;
            });
        }
    }
}
</script>
