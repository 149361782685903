<template>
    <div id="three-dimensional-curves">
        <v-card elevation="0" :loading="three_d_chart_loading">
            <v-card-text>
                <v-row style="align-items: center;justify-content: space-between;">
                    <v-col class="col-4">
                        <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
                                transition="scale-transition" offset-y min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="dateRangeText"
                                              :label="$t('three_dimensional_curve.selected_period')"
                                              prepend-icon="mdi-calendar"
                                              readonly v-bind="attrs" v-on="on"
                                />
                            </template>
                            <v-date-picker v-model="range" range @change="periodChange"/>
                        </v-menu>

                    </v-col>

                    <v-row>
                        <v-col cols="5">
                            <v-menu
                                ref="starting_time_menu"
                                v-model="starting_time_menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                :return-value.sync="starting_time"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="starting_time"
                                        :label="$t('three_dimensional_curve.starting_time')"
                                        prepend-icon="mdi-clock-time-four-outline"
                                        :hint="starting_time ? getStartTimeUtcHint() : ''"
                                        :persistent-hint="true"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    />
                                </template>
                                <v-time-picker
                                    v-if="starting_time_menu"
                                    v-model="starting_time"
                                    :max="ending_time"
                                    full-width
                                    format="24hr"
                                    scrollable
                                    elevation="10"
                                    @change="timeChangeRequest()"
                                    @click:minute="$refs.starting_time_menu.save(starting_time)"
                                />
                            </v-menu>
                        </v-col>

                        <v-col cols="5">
                            <v-menu
                                ref="ending_time_menu"
                                v-model="ending_time_menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                :return-value.sync="ending_time"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="ending_time"
                                        :label="$t('three_dimensional_curve.ending_time')"
                                        prepend-icon="mdi-clock-time-four-outline"
                                        :hint="ending_time ? getEndTimeUtcHint() : ''"
                                        :persistent-hint="true"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    />
                                </template>
                                <v-time-picker
                                    v-if="ending_time_menu"
                                    v-model="ending_time"
                                    :min="starting_time"
                                    format="24hr"
                                    full-width
                                    scrollable
                                    elevation="10"
                                    @change="timeChangeRequest()"
                                    @click:minute="$refs.ending_time_menu.save(ending_time)"
                                />
                            </v-menu>
                        </v-col>
                    </v-row>

                    <div class="mr-3 ml-4">
                        <v-menu class="ml-1" offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn elevation="3" small color="orange" v-bind="attrs" v-on="on">
                                    <v-icon color="white" small> mdi-wrench</v-icon>
                                </v-btn>
                            </template>
                            <v-list class="mt-1">
                                <v-list-item link @click="displayUpdateCurve()">
                                    <v-list-item-title>{{ $t('three_dimensional_curve.graphical_settings')}}</v-list-item-title>
                                </v-list-item>
                                <v-list-item link @click="displayCurveAppearanceModal()">
                                    <v-list-item-title>{{ $t('three_dimensional_curve.appearance')}}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <v-btn class="ml-0" elevation="3" color="red darken-3" small @click="dialog_delete_three_dimensional_curve = true">
                            <v-icon color="white" small>mdi-trash-can-outline</v-icon>
                        </v-btn>
                        <v-btn class="ml-0"elevation="3" small color="primary" @click="threeDimensionalCurveCsvExport()" :loading="csv_export_loading">
                            <v-icon color="white" small>mdi-download</v-icon>
                        </v-btn>
                        <v-btn class="ml-0" elevation="3" small @click="toggleFullscreen()">
                            <v-icon color="black">mdi-fullscreen</v-icon>
                        </v-btn>
                    </div>
                </v-row>

                <div :id="'three_dimensional_curve_' + three_dimensional_curve.id"/>

                <div v-if="no_data_message" class="text-center mt-4">
                    <span class="font-weight-bold">No data available</span>
                </div>
            </v-card-text>

            <v-dialog v-model="dialog_delete_three_dimensional_curve" max-width="500px">
                <v-card>
                    <v-card-title>
                        <span class="headline">{{ $t('three_dimensional_curve.delete') }}</span>
                    </v-card-title>
                    <v-divider/>

                    <v-card-text class="text-center pb-0">
                        <p class="mb-5 mt-3">
                            {{ $t('three_dimensional_curve.delete_message') }}
                            <strong>{{ three_dimensional_curve.label }} ?</strong>
                        </p>
                    </v-card-text>

                    <v-card-actions class="mb-1">
                        <v-spacer/>
                        <v-btn @click="dialog_delete_three_dimensional_curve = false">
                            <v-icon left>mdi-close</v-icon>
                            {{ $t('global.close') }}
                        </v-btn>
                        <v-btn type="submit" color="error" @click="deleteThreeDimCurve()">
                            <v-icon left>mdi-content-save</v-icon>
                            {{ $t('global.delete') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>

        <three-dimensional-curve-appearance-modal v-if="three_dimensional_curve_appearance_modal_visible"
                                                  ref="appearance" @curveConfigurationUpdated="refreshChartPlotting"
                                                  @closedDialog="three_dimensional_curve_appearance_modal_visible=false"
        />

        <three-dimensional-curve-settings-modal v-if="three_dimensional_curve_setting_modal_visible" ref="settings"
                                                @curveConfigurationUpdated="refreshChartPlotting"
                                                @closedDialog="three_dimensional_curve_setting_modal_visible=false"
        />

        <global-snackbar/>
    </div>
</template>

<script>
import Plotly from "plotly.js-dist"
import moment from "moment";
import ThreeDimensionalCurveAppearanceModal from "./ThreeDimensionalCurveAppearanceModal";
import ThreeDimensionalCurveSettingsModal from "./ThreeDimensionalCurveSettingsModal";
import {
    DOWNLOAD_CSV_THREE_DIMENSION_CURVES,
    THREE_DIMENSION_CURVES
} from "../../../../api";
import {api as fullscreen} from "vue-fullscreen";
import GlobalSnackbar from "../../../../global/components/GlobalSnackbar";

export default {
    name: "ThreeDimensionalCurves",
    components: {
        GlobalSnackbar,
        ThreeDimensionalCurveSettingsModal,
        ThreeDimensionalCurveAppearanceModal},
    props: ['item'],
    data (){
        return {
            three_dimensional_curve: this.item,
            dialog_delete_three_dimensional_curve: false,
            three_d_chart_loading: true,
            three_dimensional_curve_setting_modal_visible:false,
            three_dimensional_curve_appearance_modal_visible:false,
            menu: false,
            starting_time_menu:false,
            ending_time_menu:false,
            starting_time:null,
            ending_time:null,
            x : [],
            y : [],
            z : [],
            chart_data: [],
            range: [
                moment().startOf('day').subtract(2, "days").local().format('YYYY-MM-DD'),
                moment().endOf('day').subtract(1, "days").local().format('YYYY-MM-DD'),
            ],
            selectedTimeDay:false,
            interval: null,
            chart_feasibility: true,
            first_metric_data:[],
            second_metric_data:[],
            third_metric_data:[],
            chart_line_width: 4,
            chart_color:[],
            chart_color_before_normalisation: [],
            number_of_point:200,
            time_out_id: null,
            csv_export_loading: false,
            tooltip_info:'',
            metric_details:[],
            no_data_message:false,
        }
    },
    watch: {
        chart_data() {
            if (this.chart_data.length === 4) {
                this.first_metric_data = this.chart_data[0].data;
                this.second_metric_data = this.chart_data[1].data;
                this.third_metric_data = this.chart_data[2].data;
                this.checkChartFeasibility();
            }
        },
    },
    computed: {
        dateRangeText() {
            const from = moment(this.range[0]).local().format('DD-MM-YYYY');
            const to = moment(this.range[1]).local().format('DD-MM-YYYY');
            this.range[0] === this.range[1] ? this.selectedTimeDay=true : this.selectedTimeDay=false;
            return `Du ${from} au ${to}`;
        },
    },
    methods: {
        periodChange() {
            this.starting_time = null;
            this.ending_time = null;
            this.refreshChartPlotting();
        },

        getStartTimeUtcHint() {
            return moment.parseZone((moment(this.range[0]).isAfter(this.range[1]) ? this.range[1] : this.range[0]) + moment(this.starting_time + ":00", "HH:mm:ss").format('HH:mm:ss'), 'YYYY-MM-DD T HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss') + " UTC ";
        },

        getEndTimeUtcHint() {
            return moment.parseZone((moment(this.range[0]).isAfter(this.range[1]) ? this.range[0] : this.range[1]) + moment(this.ending_time + ":00", "HH:mm:ss").format('HH:mm:ss'), 'YYYY-MM-DD T HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss') + " UTC ";
        },

        async loadChart() {
            let from = moment.utc(this.range[0]).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
            let to = moment.utc(this.range[1]).endOf('day').format('YYYY-MM-DDTHH:mm:ss');
            if (moment(this.range[0]).isAfter(this.range[1])) {
                [from, to] = [to, from];
            }
            let intervalDate = moment(from).valueOf()-moment(to).valueOf();
            if (intervalDate < 0) {
                intervalDate *= -1
            }

            let interval = this.calculInterval(intervalDate,this.number_of_point);
                let uri1 = `/api/${this.three_dimensional_curve.type_relatable}/${this.three_dimensional_curve.id_relatable}/metric/${this.three_dimensional_curve.metric_1_uuid}/timestamp?start=${moment(from).utc().format("YYYY-MM-DDTHH:mm:ss")}&end=${moment(to).utc().format("YYYY-MM-DDTHH:mm:ss")}&interval=${interval}`;
                let uri2 = `/api/${this.three_dimensional_curve.type_relatable}/${this.three_dimensional_curve.id_relatable}/metric/${this.three_dimensional_curve.metric_2_uuid}/timestamp?start=${moment(from).utc().format("YYYY-MM-DDTHH:mm:ss")}&end=${moment(to).utc().format("YYYY-MM-DDTHH:mm:ss")}&interval=${interval}`;
                let uri3 = `/api/${this.three_dimensional_curve.type_relatable}/${this.three_dimensional_curve.id_relatable}/metric/${this.three_dimensional_curve.metric_3_uuid}/timestamp?start=${moment(from).utc().format("YYYY-MM-DDTHH:mm:ss")}&end=${moment(to).utc().format("YYYY-MM-DDTHH:mm:ss")}&interval=${interval}`;
                let uri4 = `/api/${this.three_dimensional_curve.type_relatable}/${this.three_dimensional_curve.id_relatable}/metric/${this.three_dimensional_curve.configuration.metric_uuid}/timestamp?start=${moment(from).utc().format("YYYY-MM-DDTHH:mm:ss")}&end=${moment(to).utc().format("YYYY-MM-DDTHH:mm:ss")}&interval=${interval}`;

            if(this.starting_time && this.ending_time) {
                from = moment.parseZone(this.range[0] + moment(this.starting_time +":00","HH:mm:ss").format('HH:mm:ss'),'YYYY-MM-DD T HH:mm:ss');
                to = moment.parseZone(this.range[1] + moment(this.ending_time +":00","HH:mm:ss").format('HH:mm:ss'),'YYYY-MM-DD T HH:mm:ss');
                if (from.isAfter(to)) {
                    [from, to] = [to, from];
                }
                let intervalDate = moment(from).valueOf()-moment(to).valueOf();
                if (intervalDate < 0) {
                    intervalDate *= -1
                }
                let interval = this.calculInterval(intervalDate,this.number_of_point);

                 uri1 = `/api/${this.three_dimensional_curve.type_relatable}/${this.three_dimensional_curve.id_relatable}/metric/${this.three_dimensional_curve.metric_1_uuid}/timestamp?start=${from.utc().format('YYYY-MM-DDTHH:mm:ss')}&end=${to.utc().format('YYYY-MM-DDTHH:mm:ss')}&interval=${interval}`;
                 uri2 = `/api/${this.three_dimensional_curve.type_relatable}/${this.three_dimensional_curve.id_relatable}/metric/${this.three_dimensional_curve.metric_2_uuid}/timestamp?start=${from.utc().format('YYYY-MM-DDTHH:mm:ss')}&end=${to.utc().format('YYYY-MM-DDTHH:mm:ss')}&interval=${interval}`;
                 uri3 = `/api/${this.three_dimensional_curve.type_relatable}/${this.three_dimensional_curve.id_relatable}/metric/${this.three_dimensional_curve.metric_3_uuid}/timestamp?start=${from.utc().format('YYYY-MM-DDTHH:mm:ss')}&end=${to.utc().format('YYYY-MM-DDTHH:mm:ss')}&interval=${interval}`;
                 uri4 = `/api/${this.three_dimensional_curve.type_relatable}/${this.three_dimensional_curve.id_relatable}/metric/${this.three_dimensional_curve.configuration.metric_uuid}/timestamp?start=${from.utc().format('YYYY-MM-DDTHH:mm:ss')}&end=${to.utc().format('YYYY-MM-DDTHH:mm:ss')}&interval=${interval}`;

            }

            this.chart_data = [];
            await this.getMetricData(uri1, this);
            await this.getMetricData(uri2, this);
            await this.getMetricData(uri3, this);
            await this.getMetricData(uri4, this);

            if(this.chart_data.length === 0) {
                this.no_data_message= true;
                this.three_d_chart_loading= false;
            }
            else {
                this.no_data_message= false;
            }
        },

        async getMetricData(uri, that) {
            await axios.get(uri).then((success) => {
                const data = success.data.data_metric;
                const obj = {
                    data: data.data,
                };
                that.chart_data.push(obj);
            }).catch((error)=> {console.log(error)});
        },

        async checkChartFeasibility(){
            // Check if there is at least one null record
            if(this.first_metric_data.length === 0 || this.second_metric_data.length === 0 || this.third_metric_data.length === 0){
                //this.showGlobalSnackbar('error',this.$t('three_dimensional_curve.null_metric'));
                this.three_d_chart_loading= false;
                this.chartPlotting();
            }
            else
            {
                if(this.metric_details.length === 0) {
                    this.metric_details[0] = await this.getMetricDetails(this.three_dimensional_curve.metric_1_uuid);
                    this.metric_details[1] = await this.getMetricDetails(this.three_dimensional_curve.metric_2_uuid);
                    this.metric_details[2] = await this.getMetricDetails(this.three_dimensional_curve.metric_3_uuid);
                    this.metric_details[3] = await this.getMetricDetails(this.three_dimensional_curve.configuration.metric_uuid);
                }
                this.chartPlotting();
            }
        },

        checkChartPointsLevel(){
            // this function determine how we will treat our received data by assigning a complexity level
            // depending on the points number and their distribution over TIME
            this.recordCleaning(); // clean null records
            this.chartColorSetUp();
            this.chartAxisFilling(this.first_metric_data,this.x);
            this.chartAxisFilling(this.second_metric_data,this.y);
            this.chartAxisFilling(this.third_metric_data,this.z);
        },

        chartColorSetUp() {
            this.chart_color_before_normalisation = this.chart_data[3].data.map(x => parseFloat(x.value));
            this.chart_color_before_normalisation.length > 0 ? this.tooltip_info="C:"+this.metric_details[3][0].metric_name+" = %{line.color}<br>": this.tooltip_info="<br>";
            this.chart_color.forEach((x,index) => {
                if(isNaN(x)){
                    this.chart_color[index]= 0
                }
            });
            let min = Math.min.apply(Math, this.chart_color);
            let max = Math.max.apply(Math, this.chart_color);

            this.chart_color.forEach((x,index) => {
                this.chart_color[index]=((this.chart_color[index]-min)/(max-min));
                if(this.chart_color[index] <= this.three_dimensional_curve.configuration.min_color_percentage/100){
                    this.chart_color[index]= 0;

                }
                if(this.chart_color[index] >= this.three_dimensional_curve.configuration.max_color_percentage/100){
                    this.chart_color[index]= 1;
                }
            });
        },

        recordCleaning(){
            for (let i = this.first_metric_data.length -1; i>=0; i--) {
                if(this.first_metric_data[i].value === "" && this.second_metric_data.value === "" && this.third_metric_data.value === ""){
                    this.first_metric_data.splice(i,1);
                    this.second_metric_data.splice(i,1);
                    this.third_metric_data.splice(i,1);
                }
            }
        },

        chartAxisFilling(record,axis){
            record.forEach(sample => {
                sample.value === "" ? axis.push("0.0") : axis.push(sample.value);
            });
        },


        chartPlotting() {
            this.checkChartPointsLevel();
            Plotly.newPlot('three_dimensional_curve_' + this.three_dimensional_curve.id, [{
                type: 'scatter3d',
                mode: 'lines',
                x: this.x,
                y: this.y,
                z: this.z,
                hoverinfo: 'all',
                hovertemplate: "X: " + this.metric_details[0][0].metric_name + "= %{x}<br>" + "Y: " + this.metric_details[1][0].metric_name + "= %{y}<br>" + "Z: " + this.metric_details[2][0].metric_name + "= %{z}<br>" + this.tooltip_info + "<extra></extra>",
                opacity: 1,
                line: {
                    width: this.three_dimensional_curve.configuration.line_width,
                    color: this.chart_color_before_normalisation.length > 0 ? this.chart_color_before_normalisation : 'black',
                    colorscale: [
                        [0, this.three_dimensional_curve.configuration.min_color_code],
                        [1, this.three_dimensional_curve.configuration.max_color_code]
                    ]
                },
            }], {
                annotations: [{
                    text: "No data available",
                    xref: "paper",
                    yref: "paper",
                    showarrow: false,
                    font: {
                        size: 18
                    },
                    visible: this.x.length === 0 || this.y.length === 0 || this.z.length === 0,
                }
                ],
                margin: {
                    l: 20,
                    r: 20,
                    t: 35,
                    b: 40,
                },
            }, {
                displayModeBar: false,
                responsive: true,
            });
            this.three_d_chart_loading = false;
        },

        refreshChartPlotting() {
            this.three_dimensional_curve_setting_modal_visible = false;
            this.three_dimensional_curve_appearance_modal_visible = false;
            this.three_d_chart_loading = true;
            this.chart_data = [];
            this.first_metric_data = [];
            this.second_metric_data = [];
            this.third_metric_data = [];
            this.x = [];
            this.y = [];
            this.z = [];
            this.loadChart();
        },

        deleteThreeDimCurve(){
            this.axios.delete(
                THREE_DIMENSION_CURVES + '/' + this.three_dimensional_curve.id
            ).then(() => {
                this.$emit('deleteThreeDimensionalCurve',this.three_dimensional_curve.id);
            }).catch(() => {
                this.showGlobalSnackbar('error',this.$t('global.failure_to_delete'));
            }).finally(() => {
                this.dialog_delete_three_dimensional_curve = false;
            });
        },

        toggleFullscreen() {
            let wrapper = this.$el.querySelector('#three_dimensional_curve_' + this.three_dimensional_curve.id);
            wrapper.classList.add('enabled');

            fullscreen.toggle(wrapper, {
                teleport: this.teleport,
                callback: (isFullscreen) => {
                    this.fullscreen = isFullscreen;
                    if (isFullscreen === false) {
                        this.$el.querySelector('#three_dimensional_curve_' + this.three_dimensional_curve.id).classList.remove('enabled');
                    }
                },
            });
        },

        displayCurveAppearanceModal(){
            this.three_dimensional_curve_appearance_modal_visible= true;
            this.$nextTick(() => {
                this.$refs.appearance.three_dimensional_curve_appearance_open_dialog= true;
                this.$refs.appearance.configuration= this.three_dimensional_curve.configuration;
                this.$refs.appearance.curve_id= this.three_dimensional_curve.id;
            });
        },

        displayUpdateCurve(){
            this.three_dimensional_curve_setting_modal_visible = true;
            this.$nextTick(() => {
                this.$refs.settings.three_dimensional_curve_detail= this.three_dimensional_curve;
                this.$refs.settings.initializeChartUpdate();
            })
        },

        timeChangeRequest() {
            if(this.starting_time && this.ending_time ){
                this.refreshChartPlotting();
            }
        },

        threeDimensionalCurveCsvExport() {
            this.csv_export_loading=true;
            let globalData = [];
            this.chart_data.forEach(metric => {
                let metricData = {
                    data: null
                };
                metricData.data = this.fromUtcDateConversion(metric.data);
                globalData.push(metricData);
            });
            let metrics_unit = ['X', 'Y', 'Z', 'Color: '];
            this.axios({
                url: DOWNLOAD_CSV_THREE_DIMENSION_CURVES,
                method: 'POST',
                data: {
                    'data': globalData,
                    'metrics_unit': metrics_unit
                },
                responseType: 'blob',
            }).then((success) => {
                this.downloadCsvFile(success.data, "_3dcurve_data.csv");
            }).catch(() => {
                this.showGlobalSnackbar('error', this.$t('global.failure_to_download'));
            }).finally(() => {
                this.csv_export_loading = false;
            });
        },
    },
    mounted() {
        this.loadChart();
    },
}
</script>

<style scoped>
.error-notice{
    color: #212121;
    font-size: 10px;
    font-weight: 450;
    margin-top: 1px;
}

</style>
