<template>
    <div id="device-update-confirmation">
        <v-dialog v-model="confirmation_alert_dialog" width="400">
            <v-card>
                <v-card-title class="justify-center">
                    Confirmer la modification ?
                </v-card-title>

                <v-divider/>

                <v-card-text class="text-center mt-2">
                    <strong v-if="gadget_type" class="font-16">Vous allez modifier le mode de fonctionnement du capteur [{{ item_uid }}]</strong>
                    <p v-if="gadget_type" class="font-14">"{{ new_item_state_label }}"</p>
                    
                    <strong v-if="!gadget_type" class="font-16">Vous allez modifier le mode de fonctionnement de la passerelle {{ new_item_state_label }}</strong>
                    <p v-if="!gadget_type" class="font-14">"{{ new_item_state_label }}"</p>
                </v-card-text>

                <v-card-actions class="justify-center">
                    <v-btn plain @click="confirmation_alert_dialog=false">
                        {{ $t('global.cancel') }}
                    </v-btn>
                    <v-btn color="primary" @click="updateState()">
                        {{ $t('global.validation') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <global-snackbar/>
    </div>
</template>

<script>
import {GATEWAYS, PHYSICAL_DEVICES} from "../../../api";
import GlobalSnackbar from "../../../global/components/GlobalSnackbar";


export default {
    name: "DeviceUpdateConfirmation",
    components: {GlobalSnackbar},
    data() {
        return {
            alert_content_display: false,
            confirmation_alert_dialog: false,
            item_id: '',
            new_item_state: '',
            gadget_type: '',
            new_item_state_label: ''
        }
    },
    methods: {
        openConfirmationAlert(item_uid, new_state, type) {
            this.item_uid = item_uid;
            this.new_item_state = new_state;
            this.confirmation_alert_dialog = true;
            this.alert_content_display = true;
            this.gadget_type = type;
            this.new_item_state_label = this.checkGatewayAndPhysicalDevicesStates(new_state);
        },

        updateState() {
            this.alert_content_display = false;
            this.confirmation_alert_dialog = false;

            if(this.gadget_type){
                this.updateDeviceState();
            }else{
                this.updateGatewayState();
            }

            this.item_id = '';
            this.new_item_state = '';
            this.gadget_type = '';
            this.new_item_state_label = '';
        },

        async updateDeviceState() {
            await this.axios.patch(PHYSICAL_DEVICES + '/' + this.item_uid, {
                state: this.new_item_state,
            }).catch((error) => {
                this.showGlobalSnackbar(error.response.status,'Pensez a vérifier le cycle de vie des capteurs')
            }).then(()=>{
                this.$emit('Performed-action');
            })
        },

        async updateGatewayState() {
            await this.axios.patch(GATEWAYS + '/' + this.item_uid, {
                state: this.new_item_state,
            }).catch((error) => {
                this.manageError(error.response.status,this.$t('global.error'));
            }).then(()=>{
                this.$emit('Performed-action');
            });
        },
    }
}
</script>
