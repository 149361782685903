<template>
    <div id="modal-add-reminder">
        <v-dialog v-model="dialog_add_reminder" scrollable max-width="500px">
            <validation-observer ref="observer" v-slot="{ invalid }">
                <v-card>
                    <v-form ref="form">
                        <v-card-title>
                            <span class="headline">{{ $t('operation.plan_reminder') }}</span>
                        </v-card-title>
                        <v-divider></v-divider>

                        <v-card-text>
                            <validation-provider :name="$t('operation.date_sending_reminder')" rules="required"
                                                 v-slot="{ errors }">
                                <v-menu
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="dateReminder"
                                            :label="$t('operation.date_sending_reminder')"
                                            prepend-icon="mdi-calendar" readonly
                                            v-bind="attrs" v-on="on" solo
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        date-format="dd/MM/yyyy"
                                        v-model="edited_operation_timeline.date_reminder"
                                    ></v-date-picker>
                                </v-menu>
                            </validation-provider>
                        </v-card-text>

                        <v-snackbar
                            v-model="snackbar"
                            :color="snackbar_type">
                            {{ snackbar_text }}
                            <template v-slot:action="{ attrs }">
                                <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                            </template>
                        </v-snackbar>

                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer/>
                            <v-btn @click="dialog_add_reminder = false">
                                <v-icon left>mdi-close</v-icon>
                                {{ $t('global.close') }}
                            </v-btn>
                            <v-btn type="submit"
                                   color="primary"
                                   @click.prevent="addOperationReminder()"
                                   :disabled="invalid">
                                <v-icon left>mdi-content-save</v-icon>
                                {{ $t('global.validation') }}
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </validation-observer>
        </v-dialog>
    </div>
</template>

<script>
import {OPERATIONS} from "../../../../api";
import moment from "moment";

export default {
    name: "ModalAddReminder",
    data() {
        return {
            operation_id: null,
            dialog_add_reminder: false,
            invalid: false,
            menu: false,

            edited_operation_timeline: {
                date_reminder: null,
                type: null
            },
        }
    },
    computed: {
        dateReminder() {
            let data = "";
            if (this.edited_operation_timeline.date_reminder !== null) {
                data = moment(this.edited_operation_timeline.date_reminder).format('DD/MM/YYYY');
            }
            return data;
        }
    },
    methods: {
        openDialogAddReminder(operationId, dateReminder) {
            this.edited_operation_timeline.type = 5;
            this.edited_operation_timeline.date_reminder = dateReminder;
            this.operation_id = operationId;
            this.dialog_add_reminder = true;
        },

        addOperationReminder() {
            this.axios.post(
                OPERATIONS + '/' + this.operation_id + '/reminder', this.edited_operation_timeline
            ).then(() => {
                this.$emit('showSnackbarForModalAddReminder', {
                    type: 'success',
                    text: this.$t('operation.reminder_add')
                });
            }).catch(() => {
                this.$emit('showSnackbarForModalAddReminder', {
                    type: 'error',
                    text: this.$t('global.failure_to_add')
                });
            }).finally(() => {
                this.dialog_add_reminder = false;
                this.$refs.form.reset();
                this.$refs.observer.reset();
            });
        },
    }
}
</script>
