<template>
    <div id="user-vision">
        <v-row>
            <v-col style="background-color: white">
                <v-subheader>
                    <p class="title mb-0">
                        {{ $t('users.title') }}
                    </p>
                </v-subheader>
            </v-col>
        </v-row>

        <datatable-users class="mt-6"/>

    </div>
</template>

<script>
import DatatableUsers from "../../components/User/DatatableUsers";

export default {
    name: "UserVision",
    components: {DatatableUsers},
}
</script>
