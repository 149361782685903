<template>
    <div id="subassembly-specifications" style="height: 100%">
        <v-card class="rounded-lg" elevation="2" outlined :loading="loading" height="100%">
            <v-card-title class="justify-space-between">
                <v-icon>mdi-information</v-icon>
                {{ $t('subassembly_specifications.title') }}
                <v-btn elevation="3" color="amber darken-3" small
                       @click="$refs.modalSubassemblyDetail.openDialogDetailsSubassembly(false)">
                    <v-icon color="white" small>mdi-wrench</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider></v-divider>

            <v-row class="mt-2 ml-2 mr-2 mt-5">
                <v-text-field class="ml-2 mr-2" :prepend-inner-icon="'mdi-label-outline'"
                              :label="$t('global.name')"
                              v-model="subassembly.label"
                              outlined readonly background-color="#EEEEEE"
                />
                <v-text-field class="ml-2 mr-2 text-field-cursor-pointer"
                              :prepend-inner-icon="'mdi-office-building-outline'"
                              :label="$t('subassembly_specifications.building')"
                              v-model="subassembly.label_building"
                              @click="$router.push({ name: 'building', params: {id: subassembly.id_building} })"
                              outlined readonly background-color="#EEEEEE"
                />
                <v-text-field class="ml-2 mr-2 text-field-cursor-pointer" :prepend-inner-icon="'mdi-state-machine'"
                              :label="$t('subassembly_specifications.machine')"
                              v-model="subassembly.label_machine"
                              @click="$router.push({ name: 'machine', params: {id: subassembly.id_machine} })"
                              outlined readonly background-color="#EEEEEE"
                />
                <v-text-field class="ml-2 mr-2 text-field-cursor-pointer" :prepend-inner-icon="'mdi-sitemap'"
                              :label="$t('subassembly_specifications.site')"
                              v-model="subassembly.label_site"
                              @click="$router.push({ name: 'site', params: {id: subassembly.id_site} })"
                              outlined readonly background-color="#EEEEEE"
                />
            </v-row>

            <v-btn class="ml-4" outlined color="primary" small
                   @click="$refs.modalSubassemblyDetail.openDialogDetailsSubassembly(true)">
                {{ $t('subassembly_specifications.see_more') }}
            </v-btn>

            <v-textarea
                class="ml-4 mr-4 mt-4 mb-0" background-color="#EEEEEE"
                v-model="subassembly.note"
                rows="2" outlined readonly no-resize
                :label="$t('global.notes')"
                :prepend-inner-icon="'mdi-comment-outline'"
            />

            <v-img aspect-ratio="1" class="mb-4 ml-4 mr-4" :src="subassembly.path_image_machine" height="150" contain>
                <template v-slot:placeholder>
                    <v-row
                        class="fill-height ma-0 background-loader-image"
                        align="center" justify="center"
                    >
                        <v-progress-circular indeterminate color="black lighten-5" v-if="no_path_image === false"/>

                        <p class="ma-0" v-else>
                            {{ $t('global.no_image') }}
                        </p>
                    </v-row>
                </template>
            </v-img>

            <modal-subassembly-detail ref="modalSubassemblyDetail"
                                      v-on:updateSubassemblyLabel="updateSubassemblyLabel"
                                      v-on:showSnackbarForModalSubassemblyDetail="showSnackbarForModalSubassemblyDetail"
                                      v-bind:subassembly-data="subassembly"></modal-subassembly-detail>

            <v-snackbar
                v-model="snackbar"
                :color="snackbar_type">
                {{ snackbar_text }}
                <template v-slot:action="{ attrs }">
                    <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                </template>
            </v-snackbar>
        </v-card>
    </div>
</template>

<script>
import {SUBASSEMBLIES} from "../../../../api";
import ModalSubassemblyDetail from "./ModalSubassemblyDetail";

export default {
    name: "SubassemblySpecifications",
    components: {
        ModalSubassemblyDetail
    },
    data() {
        return {
            loading: true,
            subassembly_id: this.$route.params.id,
            subassembly: {
                label: '',
                label_building: '',
                label_machine: '',
                label_site: '',
                path_image_machine: '',
                id_machine: null,
                id_building: null,
                id_site: null
            },
            subassembly_configs: [],

            no_path_image: false,
        };
    },
    created() {
        this.getSubassembly();
    },
    methods: {
        getSubassembly() {
            this.loading = true;

            this.axios.get(
                SUBASSEMBLIES + "/" + this.subassembly_id
            ).then((success) => {
                this.subassembly_configs = JSON.parse(success.data.config);
                this.subassembly = success.data;
                this.$emit('updateSubassemblyLabel', {label: this.subassembly.label});
                this.loading = false
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            }).finally(() => {
                this.loading = false;
            });
        },

        showSnackbarForModalSubassemblyDetail(item) {
            this.showSnackbar(item.type, item.text);
            this.getSubassembly();
        },

        updateSubassemblyLabel(item) {
            this.label = item.label;
        }
    },
    mounted() {
        this.$nextTick(function () {
            setTimeout(() => {
                this.no_path_image = true;
            }, 5000);
        })
    }
}
</script>
