<template>
    <div id="machine-vision">
        <!-- IMPORT CONTENT -->
        <vision-cards class="mt-6" v-if="cards"/>
        <datatable-notifications class="mt-6" v-if="!cards"/>
    </div>
</template>

<script>
//import DatatableNotifications from "../../components/Campain/VisionDatatable";
import VisionCards from "../../components/Campain/VisionCards";

export default {
    name: "Vision",
    components: {VisionCards},
    data() {
        return {
            cards: true,
        }
    },
    created() {
        this.setCards();
    },
    methods: {
        switchView() {
            this.cards = !this.cards;
        },

        setCards() {
            this.cards = true;
        },

        setDatatable() {
            this.cards = false;
        },
    }
}


</script>
