<template>
    <v-col class="col col-12 mt-0 pt-0 pa-0" style="margin-top:0px!important;padding:0px;position:relative; top:-12px;">
        
        <!-- MACHINE MENU PACKAGE -->
        <div id="MenuPackage" style="padding:0px 12px;">
            <v-col class="pl-5 pr-5" style="background-color:#001047;color:#000000;border-radius: 0px 0px 5px 5px;">
                <!-- TOP TITLE -->
                <v-row class="pl-0 pt-5 mb-5" style="height: 52px; padding:5px;">
                    <!-- PAGE TITLE -->
                    <v-subheader class="v-subheader col theme--light">
                        <!-- COUNTER -->
                        <div v-for="campain in campains" :key="campain">
                            <v-col v-if="campain.presta === 5 && campain.step !== 43" color="white" class="col pa-0 mt-0">
                                <div v-for="status in states_ONLINE" :key="status">
                                    <v-col v-if="status.level === campain.state" class="col pt-0 pb-0 mr-0 rounded col ml-0" style="height: 35px;font-weight: bold;text-align: center;font-size: 16px;line-height: 35px;" :style="{backgroundColor: status.level_bg, color:status.level_color, border: status.level_border}">
                                        <v-icon style="font-size: 25px;position:relative;left:-6px;top:-2px;" :style="{color:status.level_color}">{{ status.level_icon }}</v-icon> {{ status.level_name }}
                                    </v-col>
                                </div>
                            </v-col>
                            <v-col v-if="campain.presta === 5 && campain.step === 43" color="white" class="col pa-0 mt-0">
                                <div v-for="status in states_ONLINE" :key="status">
                                    <v-col v-if="status.level === campain.state" class="col pt-0 pb-0 mr-0 rounded col ml-0" style="height: 35px;font-weight: bold;text-align: center;font-size: 16px;line-height: 35px;" :style="{backgroundColor: status.level_bg, color:status.level_color, border: status.level_border}">
                                        <v-icon style="font-size: 25px;position:relative;left:-6px;top:-2px;color:#089d2d" >mdi-pause-box</v-icon> Surveillance en pause.
                                    </v-col>
                                </div>
                            </v-col>
                            <v-col v-if="campain.presta !== 5 && campain.step !== 43" color="white" class="col pa-0 mt-0">
                                <div v-for="status in states_OFFLINE" :key="status">
                                    <v-col v-if="status.level === campain.state" class="col pt-0 pb-0 mr-0 rounded col ml-0" style="height: 35px;font-weight: bold;text-align: center;font-size: 16px;line-height: 35px;" :style="{backgroundColor: status.level_bg, color:status.level_color, border: status.level_border}">
                                        <v-icon style="font-size: 25px;position:relative;left:-6px;top:-2px;" :style="{color:status.level_color}">{{ status.level_icon }}</v-icon> {{ status.level_name }}
                                    </v-col>
                                </div>
                            </v-col>
                            <v-col v-if="campain.presta !== 5 && campain.step === 43" color="white" class="col pa-0 mt-0">
                                <div v-for="status in states_OFFLINE" :key="status">
                                    <v-col v-if="status.level === campain.state" class="col pt-0 pb-0 mr-0 rounded col ml-0" style="height: 35px;font-weight: bold;text-align: center;font-size: 16px;line-height: 35px;background-color: #089d2d1c;color:#089d2d;border:1px solid #089d2d">
                                        <v-icon style="font-size: 25px;position:relative;left:-6px;top:-2px;color:#089d2d" >mdi-check</v-icon> Campagne terminée.
                                    </v-col>
                                </div>
                            </v-col>
                        </div>
                        
                        <!------------------- Info bulle ---------------------->
                        <v-tooltip bottom dark class="mr-2 ml-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 mr-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                                    <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                                </v-btn>
                            </template>
                            <template v-slot:default>
                                <div style="min-width: 200px;position:relative;z-index: 10;">
                                    <strong>Page [Détails de la campagne]</strong>
                                    <p>Suivre l'avancement de notre intervention est aussi important que d'en suivre son contenu.</br>
                                        Vous pouvez depuis cette page apprhéender le contenu complet d'une de nos interventions. Cela vous permet notamment de visualiser tous les rapports qui vous seront remis ainsi que leurs état d'avancement.</br>
                                        En cas de besoin n'hésitez pas à contacter directement la personne en charge du dossier.
                                    </p>
                                </div>
                            </template>
                        </v-tooltip>

                        <p class="title mb-0 color-white" style="color: white!important;">
                            <div v-for="campain in campains" style="color:#FFFFFFb3;margin-right:4px;">Campagnes > {{ campain.name }} > </div><div @click="infoToggler()" style="color:#FFFFFF;font-weight:bold;"> {{ $t('campain.title_details') }}</div>
                        </p>
                    </v-subheader>

                    <!-- GLOBAL ACTIONS -->
                    <v-col class="row mt-1 col-auto pa-0 pr-5">
                        <v-tooltip bottom dark class="mr-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="getData()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                    <v-icon color="white">mdi-tag-multiple</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('global.1_5_2') }}</span>
                        </v-tooltip>
                        <v-tooltip bottom dark class="mr-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="getData()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                    <v-icon color="white">mdi-refresh</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('campain.refresh') }}</span>
                        </v-tooltip>
                        
                        <v-tooltip v-if="infoToggle == true" bottom dark class="mr-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="infoToggler()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                    <v-icon color="white">mdi-minus-box-multiple</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('machines.refresh') }}</span>
                        </v-tooltip>

                        <v-tooltip v-if="infoToggle == false" bottom dark class="mr-2">
                            <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" @click="infoToggler()" class="mr-2" dusk="logout-button" style="border: 1px solid #FFFFFF45;">
                                    <v-icon color="white">mdi-plus-box-multiple</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('machines.refresh') }}</span>
                        </v-tooltip>
                        
                    </v-col>
                </v-row>

                <!-- MACHINE INFOS (UNUSED) -->
                <v-col class="mb-4" v-if="infoToggle == true" style="min-height: 250px; background-color:#e8edff;border-radius:5px;">
                    <div>
                        <!-- DATA LIST -->
                        <template>
                            <!-- RESUME CAMPAIN-->
                            <ul id="notifications-cards" class="" style="padding-left: 0px!important;">
                                <!-- ACTIVE DATA (on non trashed data and non closed data)-->
                                <li class="row" v-for="campain in campains" :key="campain" >
                                    <div v-if="campain && campain !== 'undefined' && campain !== null && typeof campain === 'object' && campain.closed_at === null" class="rounded row notification_card mb-0 elevation-1 ml-0 mr-0 mt-0" style="background-color: #ffffff;">
                                        <div class="row pa-3">                                            
                                            <!--INFOS-->
                                            <div class="col-3 pl-10">
                                                <v-chip class="mb-5 mt-5" v-on="on" link :to="'/campain/' + campain.id" style="font-weight: bold; background-color: #f3f3f3; border: 1px solid #e5e5e5;">{{campain.name}}</v-chip>
                                                <div style="font-size: 12px;margin-left: 10px;">Ref - {{campain.uid}}</div>
                                                <div style="font-size: 12px;margin-left: 10px;">Début de la campagne {{campain.intervention_date}}</div>
                                                <div style="font-size: 12px;margin-left: 10px;">Créée le : {{campain.created_at}}</div>
                                                <div v-for="tviewer in team" :key="tviewer">
                                                    <div v-if="tviewer.id === campain.creator" style="font-size: 12px;margin-left: 10px;">Créé par : {{tviewer.first_name}} {{tviewer.last_name}}</div>
                                                </div>
                                                <div v-for="analyst in analysts" :key="analyst">
                                                    <div v-if="analyst.id === campain.manager" style="font-size: 12px;margin-left: 10px;margin-top:15px;font-weight: bold;">Chargé de campagne : {{analyst.first_name}} {{analyst.last_name}}</div>
                                                </div>
                                                <div style="font-size: 12px;margin-left: 10px;">N° de dossier : {{campain.affaire_number}}</div>
                                                <div v-for="site in sites" :key="site">
                                                    <div v-if="site.id === campain.id_park" style="font-size: 12px;margin-left: 10px;">Parc machine associé : {{site.label}}</div>
                                                </div>
                                                <v-chip v-if="campain.step === 43 && access_level <= 100" class="mb-5 mt-10 pointer pl-2 pr-5" @click="setClosed(id)" style="font-weight: bold; background-color: #089d2d1c; border: 1px solid #089d2d;color:#089d2d;">
                                                    <v-icon class="mr-2" style="font-size: 18px;color:#089d2d;">mdi-lock-open</v-icon>
                                                    Clôturer la campagne
                                                </v-chip>
                                            </div>

                                            <!-- UPDATES -->
                                            <div  v-if="campain.presta !== 5" class="col pl-5 pr-5 pb-0" style="border-left: 1px solid #d3d3d36e; font-size:12px;background-color: whitesmoke;">
                                                <v-col class="col col pa-0 pt-0 pb-1 ma-0 ml-0 mt-2" style="text-align: center;">
                                                    <div v-for="presta in prestas" :key="presta">
                                                        <div v-for="typex in types" :key="typex">
                                                            <v-chip v-if="presta.id === campain.presta && typex.id === campain.type" class="ml-0 mb-0">{{ presta.name }} ({{ typex.name.toLowerCase().trim() }})</v-chip>
                                                        </div>
                                                    </div>
                                                </v-col>
                                                <div class="row row col pa-10 pt-5 pb-5 ma-0 ml-0 mt-1 mb-5" style="
                                                    align-items: center;
                                                    justify-content: center;
                                                    text-align: center;
                                                    background-color: whitesmoke;
                                                    border-radius: 100px;
                                                ">
                                                    <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                        <!-- CUSTOMER VIEW -->
                                                        <v-icon v-if="campain.step <= 11 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 12 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 13 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                        <v-icon v-if="campain.step > 13 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                                        <!-- ADMIN VIEW -->
                                                        <v-icon v-if="campain.step === 11 && access_level <= 100" @click="updateStep(campain.id,12)" class="pointer" color="orange" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 12 && access_level <= 100" @click="updateStep(campain.id,13)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 13 && access_level <= 100" @click="updateStep(campain.id,11)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                        <v-icon v-if="campain.step > 13 && access_level <= 100" @click="updateStep(campain.id,11)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                                    </p>
                                                    <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                        <!-- CUSTOMER VIEW -->
                                                        <v-icon v-if="campain.step >= 13 && campain.step <= 21 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 22 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 23 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                        <v-icon v-if="campain.step < 13 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step > 23 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                                        <!-- ADMIN VIEW -->
                                                        <v-icon v-if="campain.step >= 13 && campain.step <= 21 && access_level <= 100" @click="updateStep(campain.id,22)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 22 && access_level <= 100" @click="updateStep(campain.id,23)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 23 && access_level <= 100" @click="updateStep(campain.id,21)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                        <v-icon v-if="campain.step < 13 && access_level <= 100" @click="updateStep(campain.id,21)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step > 23 && access_level <= 100" @click="updateStep(campain.id,21)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                                    </p>
                                                    <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                        <!-- CUSTOMER VIEW -->
                                                        <v-icon v-if="campain.step >= 23 && campain.step <= 31 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 32 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 33 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                        <v-icon v-if="campain.step < 23 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step > 33 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                                        <!-- ADMIN VIEW -->
                                                        <v-icon v-if="campain.step >= 23 && campain.step <= 31 && access_level <= 100" @click="updateStep(campain.id,32)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 32 && access_level <= 100" @click="updateStep(campain.id,33)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 33 && access_level <= 100" @click="updateStep(campain.id,31)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                        <v-icon v-if="campain.step < 23 && access_level <= 100" @click="updateStep(campain.id,31)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step > 33 && access_level <= 100" @click="updateStep(campain.id,31)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                                    </p>
                                                    <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                        <!-- CUSTOMER VIEW -->
                                                        <v-icon v-if="campain.step >= 33 && campain.step <= 41 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 42 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 43 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                        <v-icon v-if="campain.step < 33 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                        <!-- ADMIN VIEW -->
                                                        <v-icon v-if="campain.step >= 33 && campain.step <= 41 && access_level <= 100" @click="updateStep(campain.id,42)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 42 && access_level <= 100" @click="updateStep(campain.id,43)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 43 && access_level <= 100" @click="updateStep(campain.id,41)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                        <v-icon v-if="campain.step < 33 && access_level <= 100" @click="updateStep(campain.id,41)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                    </p>
                                                </div>
                                                <div class="col" style="
                                                    position: relative;
                                                    border: 1px solid #656565;
                                                    top: -58px;
                                                    width: 70%;
                                                    left: 15%;
                                                    border-radius: 15px;
                                                    height: 2px;
                                                    padding: 0px;
                                                "></div>
                                                <div class="row row col pa-10 pt-0 pb-0 ma-0 ml-0" style="
                                                    align-items: center;
                                                    justify-content: center;
                                                    text-align: center;
                                                    position: relative;
                                                    top: -57px;
                                                ">
                                                    <p class="ml-0 mb-0 col" v-if="campain.step === 11 || campain.step === 12 || campain.step === 13" style="z-index: 10;font-weight: bold;">Préparation</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step != 11 && campain.step != 12 && campain.step != 13" style="z-index: 10;">Préparation</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step === 21 || campain.step === 22 || campain.step === 23" style="z-index: 10;font-weight: bold;">Intervention</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step != 21 && campain.step != 22 && campain.step != 23" style="z-index: 10;">Intervention</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step === 31 || campain.step === 32 || campain.step === 33" style="z-index: 10;font-weight: bold;">Analyses</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step != 31 && campain.step != 32 && campain.step != 33" style="z-index: 10;">Analyses</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step === 41 || campain.step === 42 || campain.step === 43" style="z-index: 10;font-weight: bold;">Rapports</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step != 41 && campain.step != 42 && campain.step != 43" style="z-index: 10;">Rapports</p>
                                                </div>
                                                <v-row class="row pl-10 pr-10" style="position: relative; top:-35px;">
                                                    <v-col class="row col pa-0 pt-0 pb-1 ma-0 ml-10 mr-10" style="text-align: center;">
                                                        <div style="font-weight: bold;font-size: 13px;" class="mb-2 col col">
                                                            <div>Equipements concernés : </div> 
                                                            <v-chip class="ml-0 mb-0">{{ campain.machine_list.length }} / {{ machines_count }}</v-chip>
                                                        </div>
                                                        <div style="font-weight: bold;font-size: 13px;" class="mb-2 col col">
                                                            <div>Rapports disponibles: </div>
                                                            <v-chip class="ml-0 mb-0">{{ available_reports_count }} / {{ reports.length }}</v-chip>
                                                        </div> 
                                                    </v-col>
                                                </v-row>                          
                                            </div>

                                            <div  v-if="campain.presta === 5" class="col pl-5 pr-5 pb-0" style="border-left: 1px solid #d3d3d36e; font-size:12px;background-color: whitesmoke;">
                                                <v-col class="col col pa-0 pt-0 pb-1 ma-0 ml-0 mt-2" style="text-align: center;">
                                                    <div v-for="presta in prestas" :key="presta">
                                                        <div v-for="typex in types" :key="typex">
                                                            <v-chip v-if="presta.id === campain.presta && typex.id === campain.type" class="ml-0 mb-0">{{ presta.name }} ({{ typex.name.toLowerCase().trim() }})</v-chip>
                                                        </div>
                                                    </div>
                                                </v-col>
                                                <div class="row row col pa-10 pt-5 pb-5 ma-0 ml-0 mt-1 mb-5" style="
                                                    align-items: center;
                                                    justify-content: center;
                                                    text-align: center;
                                                    background-color: whitesmoke;
                                                    border-radius: 100px;
                                                ">
                                                    <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                        <!-- CUSTOMER VIEW -->
                                                        <v-icon v-if="campain.step <= 11 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 12 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 13 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                        <v-icon v-if="campain.step > 13 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                                        <!-- ADMIN VIEW -->
                                                        <v-icon v-if="campain.step === 11 && access_level <= 100" @click="updateStep(campain.id,12)" class="pointer" color="orange" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 12 && access_level <= 100" @click="updateStep(campain.id,13)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 13 && access_level <= 100" @click="updateStep(campain.id,11)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                        <v-icon v-if="campain.step > 13 && access_level <= 100" @click="updateStep(campain.id,11)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                                    </p>
                                                    <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                        <!-- CUSTOMER VIEW -->
                                                        <v-icon v-if="campain.step >= 13 && campain.step <= 21 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 22 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 23 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                        <v-icon v-if="campain.step < 13 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step > 23 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                                        <!-- ADMIN VIEW -->
                                                        <v-icon v-if="campain.step >= 13 && campain.step <= 21 && access_level <= 100" @click="updateStep(campain.id,22)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 22 && access_level <= 100" @click="updateStep(campain.id,23)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 23 && access_level <= 100" @click="updateStep(campain.id,21)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                        <v-icon v-if="campain.step < 13 && access_level <= 100" @click="updateStep(campain.id,21)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step > 23 && access_level <= 100" @click="updateStep(campain.id,21)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                                    </p>
                                                    <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                        <!-- CUSTOMER VIEW -->
                                                        <v-icon v-if="campain.step >= 23 && campain.step <= 31 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 32 && access_level > 100" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 33 && access_level > 100" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                        <v-icon v-if="campain.step < 23 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step > 33 && access_level > 100" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                                        <!-- ADMIN VIEW -->
                                                        <v-icon v-if="campain.step >= 23 && campain.step <= 31 && access_level <= 100" @click="updateStep(campain.id,32)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 32 && access_level <= 100" @click="updateStep(campain.id,33)" class="pointer" color="orange" style="font-size: 36px;">mdi-play-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 33 && access_level <= 100" @click="updateStep(campain.id,31)" class="pointer" color="accent" style="font-size: 36px;">mdi-check-network</v-icon>
                                                        <v-icon v-if="campain.step < 23 && access_level <= 100" @click="updateStep(campain.id,31)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step > 33 && access_level <= 100" @click="updateStep(campain.id,31)" class="pointer" color="accent" style="font-size: 28px;position: relative;top: 3px;">mdi-check-network</v-icon>
                                                    </p>
                                                    <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                        <!-- CUSTOMER VIEW -->
                                                        <v-icon v-if="campain.step >= 33 && campain.step <= 41 && access_level > 100" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 42 && access_level > 100" color="flashGreen" style="font-size: 36px;">mdi-play-network</v-icon>
                                                        <v-icon v-if="campain.step === 43 && access_level > 100" color="red" style="font-size: 36px;">mdi-close-network-outline</v-icon>
                                                        <v-icon v-if="campain.step < 33 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                        <!-- ADMIN VIEW -->
                                                        <v-icon v-if="campain.step >= 33 && campain.step <= 41 && access_level <= 100" @click="updateStep(campain.id,42)" class="pointer" color="black" style="font-size: 36px;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 42 && access_level <= 100" @click="updateStep(campain.id,43)" class="pointer" color="flashGreen" style="font-size: 36px;">mdi-play-network</v-icon>
                                                        <v-icon v-if="campain.step === 43 && access_level <= 100" @click="updateStep(campain.id,41)" class="pointer" color="red" style="font-size: 36px;">mdi-close-network-outline</v-icon>
                                                        <v-icon v-if="campain.step < 33 && access_level <= 100" @click="updateStep(campain.id,41)" class="pointer"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                    </p>
                                                </div>
                                                <div v-if="campain.step === 42" class="col" style="
                                                    position: relative;
                                                    border: 1px solid #00db37;
                                                    top: -58px;
                                                    width: 70%;
                                                    left: 15%;
                                                    border-radius: 15px;
                                                    height: 2px;
                                                    padding: 0px;
                                                "></div>
                                                <div v-if="campain.step === 43" class="col" style="
                                                    position: relative;
                                                    border: 1px solid #d70000;
                                                    top: -58px;
                                                    width: 70%;
                                                    left: 15%;
                                                    border-radius: 15px;
                                                    height: 2px;
                                                    padding: 0px;
                                                "></div>
                                                <div v-if="campain.step < 42" class="col" style="
                                                    position: relative;
                                                    border: 1px solid #656565;
                                                    top: -58px;
                                                    width: 70%;
                                                    left: 15%;
                                                    border-radius: 15px;
                                                    height: 2px;
                                                    padding: 0px;
                                                "></div>
                                                <div class="row row col pa-10 pt-0 pb-0 ma-0 ml-0" style="
                                                    align-items: center;
                                                    justify-content: center;
                                                    text-align: center;
                                                    position: relative;
                                                    top: -57px;
                                                ">
                                                    <p class="ml-0 mb-0 col" v-if="campain.step === 11 || campain.step === 12 || campain.step === 13" style="z-index: 10;font-weight: bold;">Préparation</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step != 11 && campain.step != 12 && campain.step != 13" style="z-index: 10;">Préparation</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step === 21 || campain.step === 22 || campain.step === 23" style="z-index: 10;font-weight: bold;">Instrumentation</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step != 21 && campain.step != 22 && campain.step != 23" style="z-index: 10;">Instrumentation</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step === 31 || campain.step === 32 || campain.step === 33" style="z-index: 10;font-weight: bold;">Configuration</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step != 31 && campain.step != 32 && campain.step != 33" style="z-index: 10;">Configuration</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step === 41 || campain.step === 42 || campain.step === 43" style="z-index: 10;font-weight: bold;">Surveillance</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step != 41 && campain.step != 42 && campain.step != 43" style="z-index: 10;">Surveillance</p>
                                                </div>
                                                <v-row class="row pl-10 pr-10" style="position: relative; top:-35px;">
                                                    <v-col class="row col pa-0 pt-0 pb-1 ma-0 ml-10 mr-10" style="text-align: center;">
                                                        <div style="font-weight: bold;font-size: 13px;" class="mb-2 col col">
                                                            <div>Equipements concernés : </div> 
                                                            <v-chip class="ml-0 mb-0">{{ campain.machine_list.length }} / {{ machines_count }}</v-chip>
                                                        </div>
                                                        <div style="font-weight: bold;font-size: 13px;" class="mb-2 col col">
                                                            <div>Rapports disponibles: </div>
                                                            <v-chip class="ml-0 mb-0">{{ campain.machine_list.length }} / {{ campain.machine_list.length }}</v-chip>
                                                        </div> 
                                                    </v-col>
                                                </v-row>                          
                                            </div>

                                            <!--MORE INFOS -->
                                            <div :to="{ path: '/campain/' + campain.id}" class="col-3 pl-5" style="border-left: 1px solid #d3d3d36e; font-size:12px;">
                                                <div>
                                                    <div style="font-weight: bold;font-size: 13px;">Informations complémentaires :</div> 
                                                    <v-row class="row col pa-0 pt-0 pb-1 ma-0 ml-0">
                                                        <p class="ml-0 mb-0">{{ campain.description }}</p>
                                                    </v-row> 
                                                    <v-row class="mt-5">
                                                        <v-chip v-for="tag in getCampainTags(campain)" class="pl-3 pr-3 ma-2" link :key="tag">
                                                            {{ tag }}
                                                        </v-chip>
                                                    </v-row>   
                                                </div>
                                            </div>

                                            <!-- ACTIONS -->
                                            <v-col v-if="access_level <= 99" class="col-1 pa-2" style="max-width: 80px;">
                                                <v-tooltip v-if="!campain.trash && access_level <= 99" bottom dark>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn class="ma-auto mr-5 ml-5 col-0" text icon v-on="on" @click="openDialogUpdateCampain(campain)" dusk="logout-button">
                                                            <v-icon color="primary">mdi-circle-edit-outline</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>{{ $t('campain.edit_campain') }}</span>
                                                </v-tooltip>
                                            </v-col>
                                        </div>
                                    </div>
                                </li>

                                <!-- CLOSED DATA (on non trashed data and closed data)-->
                                <li class="row" v-for="campain in campains" :key="campain">                          
                                    <div v-if="campain && campain !== 'undefined' && campain !== null && typeof campain === 'object' && campain.closed_at != null" class="rounded row notification_card mb-0 elevation-1 ml-0 mr-0 mt-0" style="border: solid 1px #80808021;background-color: #ffffff;">
                                        <div class="row pa-3" >
                                            <!--INFOS-->
                                            <div class="col-3 pl-10" style="background-color: whitesmoke;">
                                                <v-chip class="mb-5 mt-5" v-on="on" link :to="'/campain/' + campain.id" style="font-weight: bold; background-color: #f3f3f3; border: 1px solid #e5e5e5;">{{campain.name}}</v-chip>
                                                <div style="font-size: 12px;margin-left: 10px;">Ref - {{campain.uid}}</div>
                                                <div style="font-size: 12px;margin-left: 10px;">Créée le : {{campain.created_at}}</div>
                                                <div v-for="tviewer in team" :key="tviewer">
                                                    <div v-if="tviewer.id === campain.creator" style="font-size: 12px;margin-left: 10px;">Créé par : {{tviewer.first_name}} {{tviewer.last_name}}</div>
                                                </div>
                                                <div v-for="analyst in analysts" :key="analyst">
                                                    <div v-if="analyst.id === campain.manager" style="font-size: 12px;margin-left: 10px;margin-top:15px;font-weight: bold;">Chargé de campagne : {{analyst.first_name}} {{analyst.last_name}}</div>
                                                </div>
                                                <div style="font-size: 12px;margin-left: 10px;">N° de dossier : {{campain.affaire_number}}</div>
                                                <div v-for="site in sites" :key="site">
                                                    <div v-if="site.id === campain.id_park" style="font-size: 12px;margin-left: 10px;">Parc machine associé : {{site.label}}</div>
                                                </div>
                                                <v-chip v-if="campain.step === 43 && access_level <= 100" class="mb-5 mt-10 pointer pl-2 pr-5" @click="setOpened(id)" style="font-weight: bold; background-color: #d700001c; border: 1px solid #d70000;color:#d70000;">
                                                    <v-icon class="mr-2" style="font-size: 18px;color:#d70000;">mdi-lock</v-icon>
                                                    Ouvrir la campagne
                                                </v-chip>
                                            </div>

                                            <!-- UPDATES -->
                                            <div  v-if="campain.presta !== 5" class="col pl-5 pr-5 pb-0" style="border-left: 1px solid #d3d3d36e; font-size:12px;background-color: whitesmoke;">
                                                <v-col class="col col pa-0 pt-0 pb-1 ma-0 ml-0" style="text-align: center;">
                                                    <div v-for="presta in prestas" :key="presta">
                                                        <div v-for="typex in types" :key="typex">
                                                            <v-chip v-if="presta.id === campain.presta && typex.id === campain.type" class="ml-0 mb-0">{{ presta.name }} ({{ typex.name.toLowerCase().trim() }})</v-chip>
                                                        </div>
                                                    </div>
                                                </v-col>
                                                <div class="row row col pa-10 pt-5 pb-5 ma-0 ml-0 mt-1 mb-5" style="
                                                    align-items: center;
                                                    justify-content: center;
                                                    text-align: center;
                                                    background-color: whitesmoke;
                                                    border-radius: 100px;
                                                ">
                                                    <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                        <!-- CUSTOMER VIEW -->
                                                        <v-icon v-if="campain.step <= 11" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 12" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 13 " style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                                        <v-icon v-if="campain.step > 13" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                                    </p>
                                                    <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                        <!-- CUSTOMER VIEW -->
                                                        <v-icon v-if="campain.step === 21" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 22" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 23" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                                        <v-icon v-if="campain.step < 21"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step > 23" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>                                            </p>
                                                    <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                        <!-- CUSTOMER VIEW -->
                                                        <v-icon v-if="campain.step === 31" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 32" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 33" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                                        <v-icon v-if="campain.step < 31"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step > 33" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                                    </p>
                                                    <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                        <!-- CUSTOMER VIEW -->
                                                        <v-icon v-if="campain.step === 41" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 42" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 43" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                                        <v-icon v-if="campain.step < 41"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                    </p>
                                                </div>
                                                <div class="col" style="
                                                    position: relative;
                                                    border: 1px solid #656565;
                                                    top: -58px;
                                                    width: 70%;
                                                    left: 15%;
                                                    border-radius: 15px;
                                                    height: 2px;
                                                    padding: 0px;
                                                "></div>
                                                <div class="row row col pa-10 pt-0 pb-0 ma-0 ml-0" style="
                                                    align-items: center;
                                                    justify-content: center;
                                                    text-align: center;
                                                    position: relative;
                                                    top: -57px;
                                                ">
                                                    <p class="ml-0 mb-0 col" v-if="campain.step === 11 || campain.step === 12 || campain.step === 13" style="z-index: 10;font-weight: bold;">Préparation</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step != 11 && campain.step != 12 && campain.step != 13" style="z-index: 10;">Préparation</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step === 21 || campain.step === 22 || campain.step === 23" style="z-index: 10;font-weight: bold;">Intervention</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step != 21 && campain.step != 22 && campain.step != 23" style="z-index: 10;">Intervention</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step === 31 || campain.step === 32 || campain.step === 33" style="z-index: 10;font-weight: bold;">Analyses</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step != 31 && campain.step != 32 && campain.step != 33" style="z-index: 10;">Analyses</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step === 41 || campain.step === 42 || campain.step === 43" style="z-index: 10;font-weight: bold;">Rapports</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step != 41 && campain.step != 42 && campain.step != 43" style="z-index: 10;">Rapports</p>
                                                </div>
                                                <v-row class="row pl-10 pr-10" style="position: relative; top:-35px;">
                                                    <v-col class="row col pa-0 pt-0 pb-1 ma-0 ml-10 mr-10" style="text-align: center;">
                                                        <div style="font-weight: bold;font-size: 13px;" class="mb-2 col col">
                                                            <div>Equipements concernés : </div> 
                                                            <v-chip class="ml-0 mb-0">{{ campain.machine_list.length }} / {{ machines_count }}</v-chip>
                                                        </div>
                                                        <div style="font-weight: bold;font-size: 13px;" class="mb-2 col col">
                                                            <div>Rapports disponibles: </div>
                                                            <v-chip class="ml-0 mb-0">{{ campain.machine_list.length }} / {{ campain.machine_list.length }}</v-chip>
                                                        </div> 
                                                    </v-col>
                                                </v-row>                          
                                            </div>

                                            <div  v-if="campain.presta === 5" class="col pl-5 pr-5" style="border-left: 1px solid #d3d3d36e; font-size:12px;background-color: whitesmoke;">
                                                <v-col class="col col pa-0 pt-0 pb-1 ma-0 ml-0" style="text-align: center;">
                                                    <div v-for="presta in prestas" :key="presta">
                                                        <div v-for="typex in types" :key="typex">
                                                            <v-chip v-if="presta.id === campain.presta && typex.id === campain.type" class="ml-0 mb-0">{{ presta.name }} {{ typex.name }}</v-chip>
                                                        </div>
                                                    </div>
                                                </v-col>
                                                <div class="row row col pa-10 pt-5 pb-5 ma-0 ml-0 mt-1 mb-5" style="
                                                    align-items: center;
                                                    justify-content: center;
                                                    text-align: center;
                                                    background-color: whitesmoke;
                                                    border-radius: 100px;
                                                ">
                                                    <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                        <!-- CUSTOMER VIEW -->
                                                        <v-icon v-if="campain.step <= 11 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 12 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 13 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                                        <v-icon v-if="campain.step > 13 && access_level > 100" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                                        </p>
                                                    <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                        <!-- CUSTOMER VIEW -->
                                                        <v-icon v-if="campain.step === 21 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 22 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 23 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                                        <v-icon v-if="campain.step < 21 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step > 23 && access_level > 100" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                                    </p>
                                                    <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                        <!-- CUSTOMER VIEW -->
                                                        <v-icon v-if="campain.step === 31 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 32 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-play-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 33 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-check-network</v-icon>
                                                        <v-icon v-if="campain.step < 31 && access_level > 100"  style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step > 33 && access_level > 100" style="font-size: 28px;position: relative;top: 3px;color:#656565;">mdi-check-network</v-icon>
                                                    </p>
                                                    <p class="ml-0 mb-0 col" style="z-index: 10;">
                                                        <!-- CUSTOMER VIEW -->
                                                        <v-icon v-if="campain.step === 41 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-minus-network-outline</v-icon>
                                                        <v-icon v-if="campain.step === 42 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-play-network</v-icon>
                                                        <v-icon v-if="campain.step === 43 && access_level > 100" style="font-size: 36px;color:#656565;">mdi-close-network-outline</v-icon>
                                                        <v-icon v-if="campain.step < 41 && access_level > 100" style="font-size: 28px;position: relative;top: 3px;color:#65656563;">mdi-minus-network-outline</v-icon>
                                                    </p>
                                                </div>
                                                <div v-if="campain.step === 42" class="col" style="
                                                    position: relative;
                                                    border: 1px solid #00db37;
                                                    top: -58px;
                                                    width: 70%;
                                                    left: 15%;
                                                    border-radius: 15px;
                                                    height: 2px;
                                                    padding: 0px;
                                                "></div>
                                                <div v-if="campain.step === 43" class="col" style="
                                                    position: relative;
                                                    border: 1px solid #d70000;
                                                    top: -58px;
                                                    width: 70%;
                                                    left: 15%;
                                                    border-radius: 15px;
                                                    height: 2px;
                                                    padding: 0px;
                                                "></div>
                                                <div v-if="campain.step < 42" class="col" style="
                                                    position: relative;
                                                    border: 1px solid #656565;
                                                    top: -58px;
                                                    width: 70%;
                                                    left: 15%;
                                                    border-radius: 15px;
                                                    height: 2px;
                                                    padding: 0px;
                                                "></div>
                                                <div class="row row col pa-10 pt-0 pb-0 ma-0 ml-0" style="
                                                    align-items: center;
                                                    justify-content: center;
                                                    text-align: center;
                                                    position: relative;
                                                    top: -57px;
                                                ">
                                                    <p class="ml-0 mb-0 col" v-if="campain.step === 11 || campain.step === 12 || campain.step === 13" style="z-index: 10;font-weight: bold;">Préparation</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step != 11 && campain.step != 12 && campain.step != 13" style="z-index: 10;">Préparation</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step === 21 || campain.step === 22 || campain.step === 23" style="z-index: 10;font-weight: bold;">Instrumentation</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step != 21 && campain.step != 22 && campain.step != 23" style="z-index: 10;">Instrumentation</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step === 31 || campain.step === 32 || campain.step === 33" style="z-index: 10;font-weight: bold;">Configuration</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step != 31 && campain.step != 32 && campain.step != 33" style="z-index: 10;">Configuration</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step === 41 || campain.step === 42 || campain.step === 43" style="z-index: 10;font-weight: bold;">Surveillance</p>
                                                    <p class="ml-0 mb-0 col" v-if="campain.step != 41 && campain.step != 42 && campain.step != 43" style="z-index: 10;">Surveillance</p>
                                                </div>
                                                <v-row class="row pl-10 pr-10" style="position: relative; top:-35px;">
                                                    <v-col class="row col pa-0 pt-0 pb-1 ma-0 ml-10 mr-10" style="text-align: center;">
                                                        <div style="font-weight: bold;font-size: 13px;" class="mb-2 col col">
                                                            <div>Equipements concernés : </div> 
                                                            <v-chip class="ml-0 mb-0">{{ campain.machine_list.length }} / {{ machines_count }}</v-chip>
                                                        </div>
                                                        <div style="font-weight: bold;font-size: 13px;" class="mb-2 col col">
                                                            <div>Rapports disponibles: </div>
                                                            <v-chip class="ml-0 mb-0">{{ campain.machine_list.length }} / {{ campain.machine_list.length }}</v-chip>
                                                        </div> 
                                                    </v-col>
                                                </v-row>                          
                                            </div>

                                            <!--MORE INFOS -->
                                            <div :to="{ path: '/campain/' + campain.id}" class="col-3 pl-5" style="border-left: 1px solid #d3d3d36e; font-size:12px;background-color: whitesmoke;display: flex;flex-direction: column;justify-content: space-between;">
                                                <div>
                                                    <div style="font-weight: bold;font-size: 13px;">Informations complémentaires :</div> 
                                                    <v-row class="row col pa-0 pt-0 pb-1 ma-0 ml-0">
                                                        <p class="ml-0 mb-0">{{ campain.description }}</p>
                                                    </v-row> 
                                                    <v-row class="mt-5">
                                                        <v-chip v-for="tag in getCampainTags(campain)" class="pl-3 pr-3 ma-2" link :key="tag">
                                                            {{ tag }}
                                                        </v-chip>
                                                    </v-row> 
                                                </div>
                                                
                                                <!--
                                                <v-row v-if="campain.step === 43" class="mb-0" style="display: flex; align-items: flex-end">
                                                    <template>
                                                        <v-btn :disabled="isLoading" @click="printCampain(report_id)" class="ml-2">
                                                            <v-icon color="primary">{{ isLoading ? 'mdi-loading' : 'mdi-printer' }}</v-icon>
                                                            Imprimer la campagne
                                                        </v-btn>
                                                    </template>
                                                    <v-tooltip bottom dark class="mr-2 ml-2">
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 mr-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;margin-bottom:10px;">
                                                                <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <template v-slot:default>
                                                        <div style="min-width: 200px;position:relative;z-index: 10;">
                                                            <p>Pour que l'impression soit optimale pensez à appuyer sur le bouton ci dessous sur Chrome</p>
                                                            <img src="https://techview.fr/wp-content/uploads/2024/10/Capture-decran-2024-10-14-120731.png">
                                                            <p>Et ci dessous sur Firefox</p>
                                                            <img src="https://techview.fr/wp-content/uploads/2024/10/Capture-decran-2024-10-14-120130.png">
                                                        </div>
                                                        </template>
                                                    </v-tooltip>
                                                    <v-btn v-if="isLoaded" @click="printContent" class="ml-0 mr-3 v-size--default" dusk="logout-button" style="font-size:11px;background-color:#0009a5c9;max-width:50px;border-radius: 5px;">
                                                        <v-icon color="white" style="font-size:16px">mdi-printer</v-icon>
                                                    </v-btn>
                                                </v-row>                  
                                            -->           
                                            </div>

                                            <!-- ACTIONS -->
                                            <v-col class="col-1 pa-2" style="max-width: 80px;background-color: whitesmoke;">
                                                <v-tooltip v-if="!campain.trash && access_level <= 99 " bottom dark>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn class="ma-auto mr-5 ml-5 col-0" disabled text icon v-on="on" @click="openDialogUpdateCampain(campain)" dusk="logout-button">
                                                            <v-icon color="primary">mdi-circle-edit-outline</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>{{ $t('campain.edit_campain') }}</span>
                                                </v-tooltip>
                                            </v-col>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </template>
                    </div>
                </v-col>
                
                <!-- MACHINE LINKS -->
                <v-row v-if="infoToggle == false" style="border-top: 1px solid #ffffff1c;">
                    <v-row class="pb-2" style="margin:0px;">
                        <v-row class="ml-3 pt-4 pb-0 rounded" style="height:68px;">
                            <!-- BUTTON EXAMPLE 
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/events'}" dusk="logout-button">
                                        <v-icon color="white">mdi-alert</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.alerts') }}</span>
                            </v-tooltip>
                            -->

                            <div
                                v-for="filters in machine_filters_titles"
                                :key="filters.level"
                                class="mr-0"
                                style="height:40px; padding: 4px 0px;"
                            >
                                <div
                                class="ma-0 v-chip v-chip--clickable v-chip--link v-chip--no-color theme--light v-size--default"
                                style="
                                    color: white;
                                    font-weight: 400;
                                    display: flex;
                                    justify-content: left;
                                    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.29);
                                    background-color: #ffffff8c;
                                    font-size: 14px;
                                    height: 32px;
                                    margin-right: 12px !important;
                                "
                                @click="setDataFiltered(filters.level)"
                                >
                                <div
                                    v-if="filters.level_name !== 'Tout voir'"
                                    class="mr-2"
                                    style="color: white;min-width: 30px; min-height: 20px; border-radius:20px;font-weight: bold;text-align: center;"
                                    :style="{backgroundColor: filters.level_color}"
                                >{{filters.count}}</div>
                                {{filters.level_name}}
                                </div>
                            </div>
                        </v-row>     
                    </v-row>
                </v-row>

                <v-row v-if="infoToggle == true">
                    <v-row class="pb-5" style="margin:0px;">
                        <v-row class="ml-3 pt-4 pb-0 rounded" style="">
                            <!-- BUTTON EXAMPLE 
                            <v-tooltip bottom dark class="mr-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mr-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/events'}" dusk="logout-button">
                                        <v-icon color="white">mdi-alert</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('machine.alerts') }}</span>
                            </v-tooltip>
                            -->

                            <div
                                v-for="filters in machine_filters_titles"
                                :key="filters.level"
                                class="mr-0"
                                style="height:40px; padding: 4px 0px;"
                            >
                                <div
                                class="ma-0 v-chip v-chip--clickable v-chip--link v-chip--no-color theme--light v-size--default"
                                style="
                                    color: white;
                                    font-weight: 400;
                                    display: flex;
                                    justify-content: left;
                                    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.29);
                                    background-color: #ffffff8c;
                                    font-size: 14px;
                                    height: 32px;
                                    margin-right: 12px !important;
                                "
                                @click="setDataFiltered(filters.level)"
                                >
                                <div
                                    v-if="filters.level_name !== 'Tout voir'"
                                    class="mr-2"
                                    style="color: white;min-width: 30px; min-height: 20px; border-radius:20px;font-weight: bold;text-align: center;"
                                    :style="{backgroundColor: filters.level_color}"
                                >{{filters.count}}</div>
                                {{filters.level_name}}
                                </div>
                            </div>
                        </v-row>     
                    </v-row>
                </v-row>
            </v-col>
        </div>

        <!-- content --> 
        <v-col id="Content" color="white" class="ml-0 mr-4 pl-3 col mt-3">
            <!-- DATA TABLE -->
            <v-data-table
                :headers="headers"
                :items="reports"
                class="elevation-1 datatable-operation"
                :items-per-page="-1"
                :loading="loading_datatable"
                :search="search"
                style="border-radius: 5px; overflow: hidden;"
                no-data-text="Aucun rapport disponible pour cette campagne."
            >
                <!-- TOP -->
                <template v-slot:top>
                    <v-toolbar flat color="white" class="pt-3">
                        <v-col class="col-8">
                            <div class="elevation-0 pl-5 pt-2 pb-2" >
                                <h4>Liste des rapports de la campagne</h4>
                            </div>
                        </v-col>
                        <!-- SEARCH -->
                        <v-col style="display: flex; justify-content: end;">
                            <v-layout class="justify-end mt-7 max-width-300">
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    :label="$t('global.search')"
                                ></v-text-field>
                            </v-layout>
                        </v-col>

                        <v-snackbar
                            v-model="snackbar"
                            :color="snackbar_type">
                            {{ snackbar_text }}
                            <template v-slot:action="{ attrs }">
                                <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                            </template>
                        </v-snackbar>
                    </v-toolbar>
                </template>

                <!-- ETAT DE SANTE -->
                <template v-slot:item.alert_level="{ item }">
                    <div v-if="item.released_at != null || access_level <= 99">
                        <v-col v-if="(Number(item.alert_level) !== 0 && Number(item.alert_level) > 10 && Number(item.alert_level) < 20)" class="col justify-start h-100 pt-7 mt-3 mb-3" style="max-width: 60px;background-color: #d3ad00bf;height:90%;border-radius:5px;">
                            <v-row v-if="$store.state.access_level === 0" style="color:white;" class="row mb-3 justify-center"> #{{ item.id }} </v-row>
                            <div style="color:white; font-weight:800; font-size: 17px; text-align: center;">{{ item.indice }}%</div>
                            <v-icon color="white" large>
                                mdi-alert
                            </v-icon>
                        </v-col>

                        <v-col v-if="(Number(item.alert_level) !== 0 && Number(item.alert_level) > 20 && Number(item.alert_level) < 30)" class="col justify-start h-100 pt-7 mt-3 mb-3" style="max-width: 60px;background-color: #a90000bf;height:90%;border-radius:5px;">
                            <v-row v-if="$store.state.access_level === 0" style="color:white;" class="row mb-3 justify-center"> #{{ item.id }} </v-row>
                            <div style="color:white; font-weight:800; font-size: 17px; text-align: center;">{{ item.indice }}%</div>
                            <v-icon color="white" large>
                                mdi-alert
                            </v-icon>
                        </v-col>

                        <v-col v-if="(Number(item.alert_level) !== 0 && Number(item.alert_level) > 30)" class="col justify-start h-100 pt-7 mt-3 mb-3" style="max-width: 60px;background-color: #000000bf;height:90%;border-radius:5px;">
                            <v-row v-if="$store.state.access_level === 0" style="color:white;" class="row mb-3 justify-center"> #{{ item.id }} </v-row>
                            <div style="color:white; font-weight:800; font-size: 17px; text-align: center;">{{ item.indice }}%</div>
                            <v-icon color="white" large>
                                mdi-alert
                            </v-icon>
                        </v-col>


                        <v-col v-if="(Number(item.alert_level) === -1 || Number(item.alert_level) === 0)" class="col justify-start h-100 pt-7 mt-3 mb-3" style="max-width: 60px;background-color: #939393bf;height:90%;border-radius:5px;">
                            <v-row v-if="$store.state.access_level === 0" style="color:white;" class="row mb-3 justify-center"> #{{ item.id }} </v-row>
                            <div style="color:white; font-weight:800; font-size: 17px; text-align: center;">{{ item.indice }}%</div>
                            <v-icon color="white" large>
                                mdi-help-rhombus
                            </v-icon>
                        </v-col>
                        
                        <v-col v-if="(Number(item.alert_level) === 1 || Number(item.alert_level) === 2 || Number(item.alert_level) === 3)" class="col justify-start h-100 pt-7 mt-3 mb-3" style="max-width: 60px;background-color: #006600bf;height:90%;border-radius:5px;">
                            <v-row v-if="$store.state.access_level === 0" style="color:white;" class="row mb-3 justify-center"> #{{ item.id }} </v-row>
                            <div style="color:white; font-weight:800; font-size: 17px; text-align: center;">{{ item.indice }}%</div>
                            <v-icon color="white" large>
                                mdi-check-circle
                            </v-icon>
                        </v-col>
                    </div>

                    <div v-else>
                        <v-col class="col justify-start h-100" style="max-width: 60px;background-color: rgb(9 51 200 / 28%);height:90%;border-radius:5px;">
                            <v-icon style="color:rgb(0 16 71);" large>
                                mdi-file-document-edit-outline
                            </v-icon>
                        </v-col>
                    </div>
                    
                </template>

                <!-- INFOS -->
                <template v-slot:item.infos="{ item }">
                    <div style="display: flex; align-items: flex-start; height:100%; margin-bottom: 5px;">
                        <v-row v-if="access_level <= 100" style="width: 100%;" class="pt-2 pb-2">
                            <v-col class="changementDeTaille pt-3 pb-3" style="max-width: 95%;">
                                <div class="row" style="align-items: center;">
                                    <div v-for="machine in machines" :key="machine">
                                        <v-chip v-if="machine.id == item.id_machine" class="mb-5 mt-5" v-on="on" link :to="'/machine/' + item.id_machine + '/report'" style="font-weight: bold; background-color: #f3f3f3; border: 1px solid #e5e5e5; white-space: normal; max-width: 100%;">{{machine.label}}</v-chip>
                                    </div>
                                    <!------------------- Info bulle ---------------------->
                                    <div>
                                        <v-tooltip bottom dark class="mr-2" style="">
                                            <template v-slot:activator="{ on }">
                                                <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                                                    <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                                                </v-btn>
                                            </template>
                                            <template v-slot:default>
                                            <div style="min-width: 200px;">
                                                <p>Cliquer sur le titre de l'équipement pour visualiser tout ce qui le concerne en détail.</br>
                                                (Ex :  ses rapports, ses évènements ou encore ses caractéristiques.)
                                                </p>
                                            </div>
                                            </template>
                                        </v-tooltip>
                                    </div>

                                    
                                </div>
                                <div v-for="machine in machines" :key="machine">
                                    <div v-if="machine.id == item.id_machine" style="font-size: 12px;margin-left: 10px;">{{ $t('machines.ref') }}{{machine.uid}}</div>
                                    <div v-if="machine.id == item.id_machine" style="font-size: 12px;margin-left: 10px;">{{ $t('machines.building') }} {{machine.building.label}}</div>
                                </div>
                                <!-- ACTIONS -->
                                <v-row class="col pa-auto pt-2 pb-0">
                                    <v-tooltip v-if="campains[0].closed_at == null && access_level <= 99 && (item.step == 1 || item.step == 4)" bottom dark>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click.prevent="setReportState(item, 1)" class="ma-0 col-0" text icon v-on="on" link :to="'/campain/' + item.id" dusk="logout-button">
                                                <v-icon color="#017f00">mdi-book-clock-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Demander une validation</span>
                                    </v-tooltip>

                                    <v-tooltip v-if="campains[0].closed_at == null && access_level <= 99 && access_level >= 96 && item.step == 2" bottom dark>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click.prevent="setReportState(item, 2)" class="ma-0 col-0" text icon v-on="on" link :to="'/campain/' + item.id" dusk="logout-button">
                                                <v-icon color="#930000">mdi-book-cancel-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Relancer l'édition</span>
                                    </v-tooltip>

                                    <v-tooltip v-if="campains[0].closed_at == null && access_level <= 95 && item.step == 2" bottom dark>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click.prevent="setReportState(item, 4)" class="ma-0 col-0" text icon v-on="on" link :to="'/campain/' + item.id" dusk="logout-button">
                                                <v-icon color="#9f4300">mdi-book-cancel-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Refuser la validation</span>
                                    </v-tooltip>

                                    <v-tooltip v-if="campains[0].closed_at == null && access_level <= 95 && (item.step == 1 || item.step == 2 || item.step == 4)" bottom dark>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click.prevent="setReportState(item, 3)" class="ma-0 col-0" text icon v-on="on" link :to="'/campain/' + item.id" dusk="logout-button">
                                                <v-icon color="#017f00">mdi-book-check-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Valider le rapport</span>
                                    </v-tooltip>

                                    <v-tooltip v-if="campains[0].closed_at == null && access_level <= 95 && item.step == 3" bottom dark>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click.prevent="setReportState(item, 5)" class="ma-0 col-0" text icon v-on="on" link :to="'/campain/' + item.id" dusk="logout-button">
                                                <v-icon color="#930000">mdi-book-check-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Annuler la validation</span>
                                    </v-tooltip>

                                    <v-tooltip v-if="campains[0].closed_at == null && (access_level <= 99 && item.step == 3) || (access_level <= 95 && item.step == 6)" bottom dark>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click.prevent="setReportState(item, 6)" class="ma-0 col-0" text icon v-on="on" link :to="'/campain/' + item.id" dusk="logout-button">
                                                <v-icon color="#0e4cef">mdi-book-play-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Publier le rapport</span>
                                    </v-tooltip>

                                    <v-tooltip v-if="campains[0].closed_at == null && access_level <= 95 && item.step == 5" bottom dark>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click.prevent="setReportState(item, 7)" class="ma-0 col-0" text icon v-on="on" link :to="'/campain/' + item.id" dusk="logout-button">
                                                <v-icon color="#930000">mdi-book-check-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Retravailler le rapport</span>
                                    </v-tooltip>

                                    <v-tooltip v-if="access_level <= 99" bottom dark>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click.prevent="setReportState(item, 5)" class="ma-0 col-0" text icon v-on="on" link :to="'/reported/' + item.id" dusk="logout-button">
                                                <v-icon color="#014181">mdi-eye</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Consulter le rapport</span>
                                    </v-tooltip>

                                    <v-tooltip v-if="campains[0].closed_at == null && access_level <= 99" bottom dark>
                                        <template v-slot:activator="{ on }">
                                            <v-btn class="ma-0 col-0" text icon v-on="on" link :to="'/machine/redit/' + item.id" dusk="logout-button">
                                                <v-icon color="#014181">mdi-book-check-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Modifier le rapport</span>
                                    </v-tooltip>
                                </v-row>

                            </v-col>
                            <v-col style="display:flex;justify-content: center; max-width: 38px; flex-direction: column; margin-top: 25px; padding: 0;">
                                <div v-if="item.presta == 0">
                                    <div style="font-size: 16px; border: 1px solid grey; border-radius: 50%; padding: 7px 5px 5px 5px; color: grey;">VIB</div>
                                </div>
                                <div v-if="item.presta == 1">
                                    <div style="font-size: 16px; border: 1px solid grey; border-radius: 50%; padding: 7px 5px 5px 5px; color: grey;">TH</div>
                                </div>
                                <div v-if="item.presta == 2">
                                    <div style="font-size: 16px; border: 1px solid grey; border-radius: 50%; padding: 7px 5px 5px 5px; color: grey;">US</div>
                                </div>
                                <div v-if="item.presta == 3">
                                    <div style="font-size: 16px; border: 1px solid grey; border-radius: 50%; padding: 7px 5px 5px 5px; color: grey;">EQ</div>
                                </div>
                                <div v-if="item.presta == 4">
                                    <div style="font-size: 16px; border: 1px solid grey; border-radius: 50%; padding: 7px 5px 5px 5px; color: grey;">EXP</div>
                                </div>
                                <div v-if="item.presta == 5">
                                    <div style="font-size: 16px; border: 1px solid grey; border-radius: 50%; padding: 7px 5px 5px 5px; color: grey;">OL</div>
                                </div>

                                <v-icon v-if="item.type == 0 || item.type == 3" class="pointer mt-2"
                                        v-bind="attrs" v-on="on"
                                        style="font-size:35px;color: #c3c3c3;"
                                        @click="$router.push({ name: 'reported', params: {id: item.id} })"
                                >
                                    mdi-file-pdf
                                </v-icon>

                                <v-icon v-if="item.type == 1" class="pointer mt-2"
                                        v-bind="attrs" v-on="on"
                                        style="font-size:35px;color: #c3c3c3;"
                                        @click="$router.push({ name: 'reported', params: {id: item.id} })"
                                >
                                    mdi-file-document
                                </v-icon>
                            </v-col>
                        </v-row>

                        <v-row v-if="item.released_at == null && access_level > 99" style="width: 100%;" class="pt-2 pb-2">
                            <v-col class="changementDeTaille pa-3" style="max-width: 95%;">
                                <div class="row" style="align-items: center;">
                                    <div v-for="machine in machines" :key="machine">
                                        <v-chip v-if="machine.id == item.id_machine" class="mb-5 mt-5" v-on="on" link :to="'/machine/' + item.id_machine + '/report'" style="font-weight: bold; background-color: #f3f3f3; border: 1px solid #e5e5e5; white-space: normal; max-width: 100%;">{{machine.label}}</v-chip>
                                    </div>
                                    <!------------------- Info bulle ---------------------->
                                    <div>
                                        <v-tooltip bottom dark class="mr-2" style="">
                                            <template v-slot:activator="{ on }">
                                                <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                                                    <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                                                </v-btn>
                                            </template>
                                            <template v-slot:default>
                                            <div style="min-width: 200px;">
                                                <p>Cliquer sur le titre de l'équipement pour visualiser tout ce qui le concerne en détail.</br>
                                                (Ex :  ses rapports, ses évènements ou encore ses caractéristiques.)
                                                </p>
                                            </div>
                                            </template>
                                        </v-tooltip>
                                        
                                    </div>
                                </div>
                                <div v-for="machine in machines" :key="machine">
                                    <div v-if="machine.id == item.id_machine" style="font-size: 12px;margin-left: 10px;">{{ $t('machines.ref') }}{{machine.uid}}</div>
                                    <div v-if="machine.id == item.id_machine" style="font-size: 12px;margin-left: 10px;">{{ $t('machines.building') }} {{machine.building.label}}</div>
                                </div>
                                <div v-for="steps in data_filters" :key="steps">
                                    <v-chip v-if="item.step === steps.level && steps.level_name !== 'Tout voir'" class="ml-2 mb-2 mt-2" :style="{backgroundColor: steps.level_color}" style="font-size: 11px;color:white;">
                                        <v-icon class="mr-1" style="font-size: 12px;color:white" >{{ steps.level_icon }}</v-icon>
                                        {{steps.level_name}}
                                    </v-chip>
                                </div>

                                <!-- ACTIONS -->
                                <v-row class="col pa-auto">
                                    <v-card-actions v-if="item.step == 5" class="pa-1 col col">
                                        <v-tooltip bottom dark>
                                            <template v-slot:activator="{ on }">
                                                <v-btn class="ma-0 col-0" text icon v-on="on" link :to="'/reported/' + item.id" dusk="logout-button">
                                                    <v-icon color="#014181">mdi-eye</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Consulter le rapport</span>
                                        </v-tooltip>
                                    </v-card-actions>
                                </v-row>
                            </v-col>
                            <v-col style="display:flex;justify-content: center; max-width: 38px; flex-direction: column; margin-top: 25px; padding: 0;">
                                <div v-if="item.presta == 0">
                                    <div style="font-size: 16px; border: 1px solid grey; border-radius: 50%; padding: 7px 5px 5px 5px; color: grey;">VIB</div>
                                </div>
                                <div v-if="item.presta == 1">
                                    <div style="font-size: 16px; border: 1px solid grey; border-radius: 50%; padding: 7px 5px 5px 5px; color: grey;">TH</div>
                                </div>
                                <div v-if="item.presta == 2">
                                    <div style="font-size: 16px; border: 1px solid grey; border-radius: 50%; padding: 7px 5px 5px 5px; color: grey;">US</div>
                                </div>
                                <div v-if="item.presta == 3">
                                    <div style="font-size: 16px; border: 1px solid grey; border-radius: 50%; padding: 7px 5px 5px 5px; color: grey;">EQ</div>
                                </div>
                                <div v-if="item.presta == 4">
                                    <div style="font-size: 16px; border: 1px solid grey; border-radius: 50%; padding: 7px 5px 5px 5px; color: grey;">EXP</div>
                                </div>
                                <div v-if="item.presta == 5">
                                    <div style="font-size: 16px; border: 1px solid grey; border-radius: 50%; padding: 7px 5px 5px 5px; color: grey;">OL</div>
                                </div>

                                <v-icon v-if="item.type == 0 || item.type == 3" class="pointer mt-2"
                                        v-bind="attrs" v-on="on"
                                        style="font-size:35px;color: #c3c3c3;"
                                        @click="$router.push({ name: 'reported', params: {id: item.id} })"
                                >
                                    mdi-file-pdf
                                </v-icon>

                                <v-icon v-if="item.type == 1" class="pointer mt-2"
                                        v-bind="attrs" v-on="on"
                                        style="font-size:35px;color: #c3c3c3;"
                                        @click="$router.push({ name: 'reported', params: {id: item.id} })"
                                >
                                    mdi-file-document
                                </v-icon>
                            </v-col>
                        </v-row>

                        <v-row v-if="item.released_at != null && access_level > 99" style="width: 100%;" class="pt-2 pb-2">
                            <v-col class="changementDeTaille pa-3 pl-0" style="max-width: 95%;">
                                <div class="row" style="align-items: center; margin-left: 12px;">
                                    <div v-for="machine in machines" :key="machine">
                                        <v-chip v-if="machine.id == item.id_machine" class="mb-5 mt-5" v-on="on" link :to="'/machine/' + item.id_machine + '/report'" style="font-weight: bold; background-color: #f3f3f3; border: 1px solid #e5e5e5; white-space: normal; max-width: 100%;">{{machine.label}}</v-chip>
                                    </div>
                                    <!------------------- Info bulle ---------------------->
                                    <div>
                                        <v-tooltip bottom dark class="mr-2" style="">
                                            <template v-slot:activator="{ on }">
                                                <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                                                    <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                                                </v-btn>
                                            </template>
                                            <template v-slot:default>
                                            <div style="min-width: 200px;">
                                                <p>Cliquer sur le titre de l'équipement pour visualiser tout ce qui le concerne en détail.</br>
                                                (Ex :  ses rapports, ses évènements ou encore ses caractéristiques.)
                                                </p>
                                            </div>
                                            </template>
                                        </v-tooltip>
                                    </div>
                                </div>
                                <div v-for="machine in machines" :key="machine">
                                    <div v-if="machine.id == item.id_machine" style="font-size: 12px;margin-left: 10px;">{{ $t('machines.ref') }}{{machine.uid}}</div>
                                    <div v-if="machine.id == item.id_machine" style="font-size: 12px;margin-left: 10px;">{{ $t('machines.building') }} {{machine.building.label}}</div>
                                </div>
                                <div v-for="steps in data_filters" :key="steps">
                                    <v-chip v-if="item.step === steps.level && steps.level_name !== 'Tout voir'" class="ml-2 mb-2 mt-2" :style="{backgroundColor: steps.level_color}" style="font-size: 11px;color:white;">
                                        <v-icon class="mr-1" style="font-size: 12px;color:white" >{{ steps.level_icon }}</v-icon>
                                        {{steps.level_name}}
                                    </v-chip>
                                </div>

                                <!-- ACTIONS -->
                                <v-row class="col pa-auto">
                                    <v-card-actions v-if="item.step == 5" class="pa-1 col col">
                                        <v-tooltip bottom dark>
                                            <template v-slot:activator="{ on }">
                                                <v-btn @click.prevent="setReportState(item, 5)" class="ma-0 col-0" text icon v-on="on" link :to="'/reported/' + item.id" dusk="logout-button">
                                                    <v-icon color="#014181">mdi-eye</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Consulter le rapport</span>
                                        </v-tooltip>
                                    </v-card-actions>
                                </v-row>
                            </v-col>
                            <v-col style="display:flex;justify-content: center; max-width: 38px; flex-direction: column; margin-top: 25px; padding: 0;">
                                <div v-if="item.presta == 0">
                                    <div style="font-size: 16px; border: 1px solid grey; border-radius: 50%; padding: 7px 5px 5px 5px; color: grey;">VIB</div>
                                </div>
                                <div v-if="item.presta == 1">
                                    <div style="font-size: 16px; border: 1px solid grey; border-radius: 50%; padding: 7px 5px 5px 5px; color: grey;">TH</div>
                                </div>
                                <div v-if="item.presta == 2">
                                    <div style="font-size: 16px; border: 1px solid grey; border-radius: 50%; padding: 7px 5px 5px 5px; color: grey;">US</div>
                                </div>
                                <div v-if="item.presta == 3">
                                    <div style="font-size: 16px; border: 1px solid grey; border-radius: 50%; padding: 7px 5px 5px 5px; color: grey;">EQ</div>
                                </div>
                                <div v-if="item.presta == 4">
                                    <div style="font-size: 16px; border: 1px solid grey; border-radius: 50%; padding: 7px 5px 5px 5px; color: grey;">EXP</div>
                                </div>
                                <div v-if="item.presta == 5">
                                    <div style="font-size: 16px; border: 1px solid grey; border-radius: 50%; padding: 7px 5px 5px 5px; color: grey;">OL</div>
                                </div>

                                <v-icon v-if="item.type == 0 || item.type == 3" class="pointer mt-2"
                                        v-bind="attrs" v-on="on"
                                        style="font-size:35px;color: #c3c3c3;"
                                        @click="$router.push({ name: 'reported', params: {id: item.id} })"
                                >
                                    mdi-file-pdf
                                </v-icon>

                                <v-icon v-if="item.type == 1" class="pointer mt-2"
                                        v-bind="attrs" v-on="on"
                                        style="font-size:35px;color: #c3c3c3;"
                                        @click="$router.push({ name: 'reported', params: {id: item.id} })"
                                >
                                    mdi-file-document
                                </v-icon>
                            </v-col>
                        </v-row>
                    </div>
                </template>

                <!-- PRECO -->
                <template v-slot:item.preco="{ item }">
                    <div v-if="access_level <= 100">
                        <div v-if="item.step === 0" style="font-size:12px; padding: 10px;">
                            <div class="rounded row notification_card pa-1" style="color: grey;justify-content: center;">
                                <div style="color:grey;text-align: center;">Pas de recommandations associées. <br> Pour en ajouter veuillez éditer le rapport.</div>
                            </div>
                        </div>
                        <div v-if="item.step  > 0" class="pa-1 pl-6 pr-10" style="font-size:12px">
                            <div v-if="item.preco === null" class="changementPadding" style="font-size: 12px;margin-left: 10px; padding: 4px;">Aucune préconisation associée.</div>
                            <div v-else v-html="item.preco" class="changementPadding" style="font-size: 12px;margin-left: 10px; padding: 4px;"></div>
                        </div>

                    </div>

                    <div v-if="item.released_at == null && access_level > 99" class="changementDeTaille pa-3">
                        <div class="col" style="font-size: 12px;">
                            <div>
                                <li class="rounded row notification_card pa-1" style="color: grey;justify-content: center;">
                                    <div style="color: grey; text-align: center;">Ce rapport n'est pas encore terminé. <br> Vous recevrez une notification dés qu'il sera disponible.</div>
                                </li>
                            </div>
                        </div>
                    </div>

                    <div v-if="item.released_at != null && access_level > 99">
                        <div v-if="item.step === 0" style="font-size:12px">
                            <div class="rounded row notification_card pa-1" style="color: grey;justify-content: center;">
                                <div style="color:grey;text-align: center;">Pas de recommandations associées. <br> Pour en ajouter veuillez éditer le rapport.</div>
                            </div>
                        </div>
                        <div v-if="item.step  > 0" class="pa-1 pl-10 pr-10" style="font-size:12px">
                            <div v-if="item.preco === null" class="pa-1" style="font-size: 12px;margin-left: 10px;">Aucune préconisation associée.</div>
                            <div v-else v-html="item.preco" class="pa-1" style="font-size: 12px;margin-left: 10px;"></div>
                        </div>
                    </div>
                </template>

                <!-- TAGS -->
                <template v-slot:item.tags="{ item }">
                    <div v-if="access_level <= 100">
                        <div style="font-size:12px">
                            <div class="pa-1">
                                <v-chip class="pa-2 ma-1" v-if="item.step  > 0" v-for="tag in getReportTags(item)">{{ tag }}</v-chip>
                            </div>
                        </div>
                    </div>

                    <div v-if="item.released_at != null && access_level > 99">
                        <div style="font-size:12px">
                            <div class="pa-1">
                                <v-chip class="pa-2 ma-1" v-if="item.step > 0" v-for="tag in getReportTags(item)">{{ tag }}</v-chip>
                            </div>
                        </div>
                    </div>
                </template>
            </v-data-table> 

            <!-- MODAL AREA --> 
            <!-- campain ADD / EDIT MODAL -->
            <v-dialog v-model="dialog_edit" max-width="750px">
                <v-card>
                    <validation-observer ref="observer" v-slot="{ invalid }">
                        <v-form ref="form">
                            <v-card-title>
                                <span class="headline">{{ dialogTitle }}</span>
                            </v-card-title>
                            <v-divider></v-divider>

                            <v-card-text>
                                <v-col>
                                    <!-- CAMPAIN NAME -->
                                    <validation-provider :name="$t('campain.add_name')" rules="required"
                                                            v-slot="{ errors }">
                                        <v-text-field solo
                                                        :label="$t('campain.add_name')"
                                                        v-model="edited_campain.name"
                                                        :prepend-icon="'mdi-label-outline'"
                                                        :error-messages="errors"
                                        />
                                    </validation-provider>

                                    <!-- CAMPAIN PRESTA -->
                                    <validation-provider :name="$t('campain.add_presta')" rules="required"
                                                            v-slot="{ errors }">
                                        <v-select
                                            :items="prestas"
                                            v-model="edited_campain.presta"
                                            :label="$t('campain.add_presta')"
                                            :prepend-icon="'mdi-book'"
                                            :error-messages="errors"
                                            item-value="id"
                                            item-text="name" solo
                                        >
                                        </v-select>
                                    </validation-provider> 

                                    <!-- CAMPAIN TYPE -->
                                    <validation-provider :name="$t('campain.add_type')" rules="required"
                                                            v-slot="{ errors }">
                                        <v-select
                                            :items="types"
                                            v-model="edited_campain.type"
                                            :label="$t('campain.add_type')"
                                            :prepend-icon="'mdi-book'"
                                            :error-messages="errors"
                                            item-value="id"
                                            item-text="name" solo
                                        >
                                        </v-select>
                                    </validation-provider>

                                    <!-- CAMPAIN AFFAIRE NUMBER -->
                                    <validation-provider :name="$t('campain.add_affaire_number')" rules="required"
                                                            v-slot="{ errors }">
                                        <v-text-field solo
                                                        :label="$t('campain.add_affaire_number')"
                                                        v-model="edited_campain.affaire_number"
                                                        :prepend-icon="'mdi-qrcode'"
                                                        :error-messages="errors"
                                        />
                                    </validation-provider>

                                    <!-- CAMPAIN MANAGER -->
                                    <validation-provider :name="$t('campain.add_manager')" rules="required"
                                                            v-slot="{ errors }">
                                        <v-select
                                            :items="analysts"
                                            v-model="edited_campain.manager"
                                            :label="$t('campain.add_manager')"
                                            :prepend-icon="'mdi-account-hard-hat-outline'"
                                            :error-messages="errors"
                                            item-value="id"
                                            item-text="first_name" solo
                                        >
                                        </v-select>
                                    </validation-provider>

                                    <!-- CAMPAIN PARK -->
                                    <validation-provider :name="$t('campain.add_park')" rules="required"
                                                            v-slot="{ errors }">
                                        <v-select
                                            :items="sites"
                                            v-model="edited_campain.id_park"
                                            :label="$t('campain.add_park')"
                                            :prepend-icon="'mdi-factory'"
                                            :error-messages="errors"
                                            item-value="id"
                                            item-text="label" solo
                                        >
                                        </v-select>
                                    </validation-provider>

                                    <!-- CAMPAIN MACHINES -->
                                    <validation-provider :name="$t('campain.add_machines')" rules="required"
                                                            v-slot="{ errors }">
                                        <v-select
                                            :items="machines"
                                            v-model="edited_campain.machine_list"
                                            :label="$t('campain.add_machines')"
                                            :prepend-icon="'mdi-car-turbocharger'"
                                            :error-messages="errors"
                                            item-value="id"
                                            item-text="label" solo chips clearable multiple
                                        >
                                        </v-select>
                                    </validation-provider>
                                    
                                    <!-- CAMPAIN DESCRIPTION -->
                                    <v-textarea rows="2" solo no-resize
                                                v-model="edited_campain.description"
                                                :label="$t('global.notes')"
                                                :prepend-icon="'mdi-comment-outline'"
                                    />

                                    <!-- CAMPAIN TAGS -->
                                    <validation-provider :name="$t('campain.add_tags')" rules="required"
                                                            v-slot="{ errors }">
                                        <v-select
                                            :items="tags"
                                            v-model="edited_campain.tags"
                                            :label="$t('campain.add_tags')"
                                            :prepend-icon="'mdi-tag'"
                                            :error-messages="errors"
                                            item-value="name"
                                            item-text="name" solo chips clearable multiple
                                        >
                                        </v-select>
                                    </validation-provider>
                                </v-col>
                            </v-card-text>

                            <v-card-actions class="mb-3" style="position:absolute;top:10px;right:10px">
                                <v-btn @click="dialog_edit = false;" style="color:red;max-width: 30px;padding: 0 0 0 5px;min-width: 10px!important;height: 30px!important;">
                                    <v-icon left>mdi-close</v-icon>
                                </v-btn>
                            </v-card-actions>

                            <v-card-actions class="pb-5" style="display:flex;justify-content:center">
                                <v-btn type="submit" color="primary" :disabled="invalid"
                                        @click.prevent="prepareEditCampain()">
                                    <v-icon left>mdi-content-save</v-icon>
                                    {{ $t('global.validation') }}
                                </v-btn>
                            </v-card-actions>
                        </v-form>
                    </validation-observer>
                </v-card>
            </v-dialog>
            <!-- CAMPAIN DELETE MODAL -->
            <v-dialog v-model="dialog_delete" max-width="500px">
                <v-card>
                    <v-card-title>
                        <span class="headline">
                            {{ $t('campain.delete_campain') }}
                        </span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <br/>

                    <v-card-actions>
                        <v-spacer/>
                        <v-btn @click="dialog_delete = false">
                            <v-icon left>mdi-close</v-icon>
                            {{ $t('global.cancel') }}
                        </v-btn>
                        <v-btn color="error" @click="confirmCampainDelete()">
                            <v-icon left>mdi-delete</v-icon>
                            {{ $t('global.delete') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- SNACKBAR INFO DISPLAY -->
            <v-snackbar
                v-model="snackbar"
                :color="snackbar_type">
                {{ snackbar_text }}
                <template v-slot:action="{ attrs }">
                    <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                </template>
            </v-snackbar>
            <!--FILTER MAX SIZE MESSAGE ITEM-->
            <template v-slot:item.message="{ item }">
                {{ item.message.substring(0, 100) + '...' }}
            </template>
            <!--ACTION BUTTONS -->
            <template v-slot:item.action="{ item }">
                <v-icon class="pointer" @click="setDataTrash(item, 1)" v-if="!campains_trash">
                    mdi-eye-check
                </v-icon>
                <v-icon class="pointer" @click="setDataTrash(item, 0)" v-if="campains_trash">
                    mdi-eye-remove
                </v-icon>
                <v-icon class="pointer" @click="openDialogShow(item)">
                    mdi-arrow-expand-all
                </v-icon>
            </template>
            <!-- Modal next update -->
            <v-dialog v-model="dialog_next_update" max-width="500px">
                <v-card>
                    <v-card-title>
                        <span class="headline">
                            Fonctionnalité bientôt disponible
                        </span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <br/>
                    <p class="pl-4 pr-4">
                        Cette fonctionnalité est en cours de développement et sera bientôt disponible.
                        Elle vous permettra entre autre de filter et de rechercher précisément via des mots clés votre cible.
                        Un rapport, une machine, une référence...
                        Le but, vous faire gagner du temps ! 
                    </p>
                    <!-- Bouton pour fermer -->
                    <v-card-actions class="mb-3" style="position:absolute;top:20px;right:2px">
                        <v-btn @click="dialog_show = false;" style="color:red;max-width: 30px;padding: 0 0 0 5px;min-width: 10px!important;height: 30px!important;">
                            <v-icon left>mdi-close</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-col>

        <!-- PRINT AREA -->              
        <!-- ------------------------------------ -->
        <div ref="contentToPrint" class="pt-0 mb-3 mt-1 printable-content" id="PrintSection" style="position: fixed; left: 0; top: -20px; width: 100%; z-index: -100;">
            <!-- PRINT AREA (page de garde) -->
            <div id="presentation-page-print" style="height: 1020px; display: block;flex-direction: column; justify-content: space-between; position: relative; page-break-after: always;">
                <div>  
                    <div>
                        <div style="height:150px; background-image: url('https://techview.fr/wp-content/uploads/2024/09/OnLine2-report.png'); background-repeat: no-repeat; background-position: center; background-size: cover;">
                            <img style="width:100px; margin:25px;" src="https://techview.fr/wp-content/uploads/2023/08/40b88e9c74d694e5fe469dbaa779b52c-2.png">
                        </div>
                        <div style="background-color:#014181; color: white; text-align: center;">
                            <h2 style="padding: 5px; margin: 0;font-size: 24px;">EXPERTS INSIDE</h2>
                        </div>
                    </div>

                    <div v-for="campain in campains" :key="campain.id" style="margin-top: 50px; text-align: center;font-size: 24px;font-weight: 600;text-transform: uppercase;color: #23b5d3;">
                        <p v-if="reports.length > 0 && reports[0].intervention_date">{{ campain.name }}
                            <br>du {{ reports[0].intervention_date }}
                        </p>
                        <div>
                            <div v-if="reports.length > 0 && reports[0].id_professional && professional && reports[0].id_professional === professional.id" style="display: flex;flex-direction: column;align-items: center; color: black;">
                                <img style="max-height :150px;" :src="professional.logo">
                                <p>{{ professional.name }}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="margin-top: 50px; position: absolute; bottom: 0; width: 100%;">
                    <div>
                        <v-col class="col-12">
                            <div style="background-color: #014181;color: white;display: flex;flex-direction: column;padding: 20px; font-size: 14px; width: 60%;box-shadow: -15px -15px 0px 0px #23b5d3;margin-left: 15px;">
                                <div v-if="reports.length > 0" style="line-height: 1.2;">
                                    <p v-if="reports.length > 0 && reports[0].released_at != null" style="margin:0;">Date de mise à disposition du rapport {{ reports[0].released_at }}</p>
                                    <p v-if="reports.length > 0 && reports[0].interlocutor != null" style="margin:0;">Interlocuteurs : {{ reports[0].interlocutor }}</p>
                                    <p v-for="campain in campains" :key="campain.id" style="margin:0;">Affaire TECHVIEW : {{ campain.affaire_number}}</p>
                                </div>

                                <div v-for="analyst in analysts_datas" :key="analyst">
                                    <v-row v-if="reports.length > 0 && reports[0].intervenant === analyst.id" style="display: flex;align-items: flex-end;line-height: 1.2;">
                                        <v-col v-if="reports.length > 0" class="col">
                                            <p v-if="reports.length > 0 && reports[0].intervention_date != null" style="margin-top:20px;margin-bottom:5px;font-weight: 600;">Mesures sur site {{ reports[0].intervention_date }}</p>
                                            <p style="margin:0!important;font-style: italic;">{{ analyst.name }}</p>
                                            <p style="margin:0!important;"><v-icon color="white" size="14">mdi-phone-outline</v-icon> {{ analyst.tel }}</p>
                                            <p style="margin:0!important;"><v-icon color="white" size="14">mdi-email-outline</v-icon> <span style="text-decoration: underline;">{{ analyst.email }}</span></p>
                                        </v-col>
                                            <v-col class="col-4" v-if="analyst.logo != null">
                                            <img :src="analyst.logo" style="width: 100px;">
                                        </v-col>
                                    </v-row>
                                </div>

                                <div v-for="analyst in analysts_datas" :key="analyst">
                                    <v-row style="display: flex;align-items: flex-end;line-height: 1.2;" v-if="reports.length > 0 && reports[0].analyst && reports[0].analyst == analyst.id">
                                        <v-col class="col">
                                            <p style="margin-top:20px;margin-bottom:5px;font-weight: 600;">Analyse du rapport</p>
                                            <p style="margin:0!important;font-style: italic;">{{ analyst.name }}</p>
                                            <p style="margin:0!important;"><v-icon color="white" size="14">mdi-phone-outline</v-icon> {{ analyst.tel }}</p>
                                            <p style="margin:0!important;"><v-icon color="white" size="14">mdi-email-outline</v-icon> <span style="text-decoration: underline;">{{ analyst.email }}</span></p>
                                        </v-col>
                                        <v-col class="col-4" v-if="analyst.logo != null">
                                            <img :src="analyst.logo" style="width: 100px;">
                                        </v-col>
                                    </v-row>
                                </div>

                                <div v-for="analyst in analysts_datas" :key="analyst">
                                    <v-row style="display: flex;align-items: flex-end;line-height: 1.2;" v-if="reports.length > 0 && reports[0].validator === analyst.id">
                                        <v-col class="col">
                                            <p style="margin-top:20px;margin-bottom:5px;font-weight: 600;">Validateur du rapport</p>
                                            <p style="margin:0;font-style: italic;">{{ analyst.name }}</p>
                                            <p style="margin:0;"><v-icon color="white" size="14">mdi-phone-outline</v-icon> {{ analyst.tel }}</p>
                                            <p style="margin:0;"><v-icon color="white" size="14">mdi-email-outline</v-icon> <span style="text-decoration: underline;">{{ analyst.email }}</span></p>
                                        </v-col>
                                        <v-col class="col-4" v-if="analyst.logo != null">
                                            <img :src="analyst.logo" style="width: 100px;">
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                            <!-- Pied de page -->
                            <div style="margin-top: 25px; background-color: #014181; color: white; text-align: center; font-size: 10px; padding: 5px; display: flex; flex-direction: column; justify-content: flex-end;">
                                <p style="margin:0!important;">Techview - https://techview.fr - 05.56.95.51.92</p>
                                <p style="margin:0!important;">S.A.S au capital de 100 000 Euros - SIRET 793 684 960 00024 - Code A.P.E 8559a - TVA FR63 793 684 960</p>
                                <p style="margin:0!important;">11 rue Pierre et Marie Curie - 33290 Blanquefort</p>
                            </div>
                        </v-col>
                    </div>
                </div>
                
            </div>

            <!-- FILTERS PRINT (légende) -->
            <div class="row mb-5 mt-5 ml-0" style="display: flex; margin-bottom: 10px; margin-top: 10px;">
                <div v-for="filters in machine_filters_titles" :key="filters.level" class="mr-0">
                    <div v-if="filters.level_name !== 'Tout voir'" class="ma-auto theme--light v-size--default" style="color: black; font-weight: 400; display: flex; justify-content: left; font-size: 14px; padding: 10px 0; margin-right: 30px!important; border-radius: 20px;">
                        <div style="color: white;min-width: 30px; border-radius:20px;font-weight: bold;text-align: center; margin-right: 5px;" :style="{backgroundColor: filters.level_color}">
                            {{filters.count}}
                        </div>
                        {{filters.level_name}}
                    </div>
                </div>
            </div>

            <!-- REPORTS CARDS (sommaire) -->
            <div v-if="loading_reports == false" flat color="white" class="pt-0 mb-3 mt-1" style="page-break-after: always;">
                <template>
                    <!-- REPORTS DATA -->
                    <div v-if="reports && reports !== 'undefined' && reports !== null && typeof reports === 'object'" id="notifications-cards" style="border: 1px solid #e5e5e5; border-radius: 5px;">
                        <v-col :key="report">
                            <div style="color: #666666; font-size: 12px; text-align: center;  align-items: center; padding:10px 0; display: flex;">
                                <div style="width: 5%;">Etat de santé</div>
                                <div style="width: 40%;">Informations</div>
                                <div style="width: 55%;">Préconisations</div>
                            </div>

                            <div v-for="report in reports" style="border-top: 1px solid #e5e5e5; display: flex;">
                                <!-- ETAT DE SANTE -->
                                <div style="width: 5%; display: flex; align-items: center; justify-content: center;">
                                    <v-col v-if="(Number(report.alert_level) !== 0 && Number(report.alert_level) > 10 && Number(report.alert_level) < 20)" class="col h-100" style="width: 40px;background-color: #d3ad00bf;height:90%;border-radius:5px; display: flex; flex-direction: column; align-items: center; justify-content: center;">
                                        <div style="color:white; font-weight:800; font-size: 10px;">{{ report.indice }}%</div>
                                        <p style="color: white; font-size:24px;margin:0;">⚠</p>
                                        <v-icon color="white" small>
                                            mdi-alert
                                        </v-icon>
                                    </v-col>

                                    <v-col v-if="(Number(report.alert_level) !== 0 && Number(report.alert_level) > 20 && Number(report.alert_level) < 30)" class="col h-100" style="width: 40px;background-color: #a90000bf;height:90%;border-radius:5px; display: flex; flex-direction: column; align-items: center; justify-content: center;">
                                        <div style="color:white; font-weight:800; font-size: 10px;">{{ report.indice }}%</div>
                                        <p style="color: white; font-size:24px;margin:0;">⚠</p>
                                        <v-icon color="white" small>
                                            mdi-alert
                                        </v-icon>
                                    </v-col>

                                    <v-col v-if="(Number(report.alert_level) !== 0 && Number(report.alert_level) > 30)" class="col h-100" style="width: 40px;background-color: #000000bf;height:90%;border-radius:5px; display: flex; flex-direction: column; align-items: center; justify-content: center;">
                                        <div style="color:white; font-weight:800; font-size: 10px;">{{ report.indice }}%</div>
                                        <p style="color: white; font-size:24px;margin:0;">⚠</p>
                                        <v-icon color="white" small>
                                            mdi-alert
                                        </v-icon>
                                    </v-col>


                                    <v-col v-if="(Number(report.alert_level) === -1 || Number(report.alert_level) === 0)" class="col h-100" style="width: 40px;background-color: #939393bf;height:90%;border-radius:5px; display: flex; flex-direction: column; align-items: center; justify-content: center;">
                                        <div style="color:white; font-weight:800; font-size: 10px;">{{ report.indice }}%</div>
                                        <p style="color: white; font-size:24px;margin:0;">?</p>
                                        <v-icon color="white" small>
                                            mdi-help-rhombus
                                        </v-icon>
                                    </v-col>
                                    
                                    <v-col v-if="(Number(report.alert_level) === 1 || Number(report.alert_level) === 2 || Number(report.alert_level) === 3)" class="col h-100" style="width: 40px;background-color: #006600bf;height:90%;border-radius:5px; display: flex; flex-direction: column; align-items: center; justify-content: center;">
                                        <div style="color:white; font-weight:800; font-size: 10px;">{{ report.indice }}%</div>
                                        <p style="color: white; font-size:24px;margin:0;">✔</p>
                                        <v-icon color="white" small>
                                            mdi-check-circle
                                        </v-icon>
                                    </v-col>
                                </div>

                                <!-- INFOS -->
                                <div style="width: 40%; display: flex; align-items: center; justify-content: center;">
                                    <div v-if="report.released_at == null">
                                        <div class="changementDeTaille pa-3 pl-0" style="text-align: center;">
                                            <div class="row" style="align-items: center; justify-content: center; margin-left: 0!important;">
                                                <div v-for="machine in machines" :key="machine">
                                                    <div v-if="machine.id == report.id_machine" class="mb-5 mt-5" style="font-weight: bold; font-size: 12px;">{{machine.label}}</div>
                                                </div>
                                            </div>
                                            <div v-for="machine in machines" :key="machine">
                                                <div v-if="machine.id == report.id_machine" style="font-size: 12px;margin-left: 10px;">{{ $t('machines.ref') }}{{machine.uid}}</div>
                                                <div v-if="machine.id == report.id_machine" style="font-size: 12px;margin-left: 10px;">{{ $t('machines.building') }} {{machine.building.label}}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="report.released_at != null">
                                        <div class="changementDeTaille pa-3 pl-0" style="text-align: center;">
                                            <div class="row" style="align-items: center; justify-content: center; margin-left: 0!important;">
                                                <div v-for="machine in machines" :key="machine">
                                                    <div v-if="machine.id == report.id_machine" class="mb-5 mt-5"style="font-weight: bold; font-size: 12px;">{{machine.label}}</div>
                                                </div>
                                            </div>
                                            <div v-for="machine in machines" :key="machine">
                                                <div v-if="machine.id == report.id_machine" style="font-size: 12px;margin-left: 10px;">{{ $t('machines.ref') }}{{machine.uid}}</div>
                                                <div v-if="machine.id == report.id_machine" style="font-size: 12px;margin-left: 10px;">{{ $t('machines.building') }} {{machine.building.label}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- PRECO -->
                                <div style="width: 55%; border-left: 1px solid #e5e5e5; text-align: center;">
                                    <div>
                                        <div v-if="report.step  > 0" class="pa-1 pl-6 pr-10" style="font-size:12px">
                                            <div v-if="report.preco === null" class="changementPadding" style="font-size: 12px;margin-left: 10px; padding: 20px;">Aucune préconisation associée.</div>
                                            <div v-else v-html="report.preco" class="changementPadding" style="font-size: 12px;margin-left: 10px; padding: 20px;"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                    </div>
                </template>
            </div>

            <!-- PRINT REPORTS (détail de chaque rapports) -->
            <div v-for="report in reports" :key="report">
                <v-col class="pa-0 pt-1 col rounded" id="report-print-div">
                    <div flat style="height: 280mm; max-width: 100%; width: 100%; box-sizing: border-box; page-break-after: always;" class="pt-0 mb-3">
                        <!-- REPORT HEADER -->
                        <v-row style="background-color:white;margin:0; position: relative; z-index: 10;">
                            <div class="col pa-0 pt-1 pb-2" style="display: flex; flex-direction: column;">
                                <div style="display: flex;">
                                    <v-col class="col-2 pl-5">
                                        <img style="width:100px" src="https://techview.fr/wp-content/uploads/2023/08/40b88e9c74d694e5fe469dbaa779b52c-2.png">
                                    </v-col>
                                    <div v-for="campain in campains" :key="campain" style="display:flex; width: 100%; align-items: center; justify-content: center;">
                                        <v-col v-for="machine in machines" :key="machine" v-if="report.id_machine == machine.id" class="col" style="display: flex;align-items: center; color: black; justify-content: center; text-align: center;">
                                            <p style="font-size:16px">Rapport de l'équipement "<span style="font-weight: 800;">{{ machine.label}}</span>"
                                                <br>dans la campagne "<span style="font-weight: 800;">{{ campain.name}}</span>"
                                            </p>
                                        </v-col>
                                    </div>
                                </div>

                                <v-col style="margin-top: 0!important; padding: 0!important;">
                                    <div v-for="filters in machine_filters" :key="filters" style="max-height:50px;border-radius:5px;">
                                        <div  v-if="filters.level == report.alert_level && filters.level_name !== 'Tout voir'" class="col-0" style="font-weight:800;display:flex;justify-content:center;text-shadow:2px 2px 4px rgba(0, 0, 0, 0.29); height:100%;min-width: 8px;border-radius:5px;" >
                                            <v-row class="pa-0 ma-0" style="border-radius: 5px; overflow: hidden; width: 100%; display: flex;">
                                                <v-col class="col pa-0" style="width:35%;display:flex;flex-direction:column; justify-content: center;" :style="{backgroundColor: filters.level_color}">
                                                    <div style="align-items:center;width:100%; display: flex;">
                                                        <v-col style="margin-left: 12px;font-size:18px;font-weight:bold;text-align:right;">{{ report.indice }}%</v-col>
                                                        <v-col style="padding-left: 40px; font-size:10px;text-align:left;font-weight: 400;align-items:center;">
                                                            <div  v-if="report_position == 1 && report_position == report_nb">Rapport initial (seul rapport existant)</div>
                                                            <div  v-if="report_position == 1 && report_position != report_nb">Rapport initial (sur {{ report_nb }} existants)</div> 
                                                            <div  v-if="report_position > 1 && report_position == report_nb">Rapport n°{{ report_position }} (dernier en date)</div>
                                                            <div  v-if="report_position > 1 && report_position != report_nb">Rapport n°{{ report_position }} (sur {{ report_nb }} existants)</div>
                                                            <div  v-if="report.released_at">Publié le {{ report.released_at }}</div> 
                                                            <div  v-if="!report.released_at">Date de publication non définie</div> 
                                                        </v-col>
                                                    </div>
                                                </v-col>
                                                <v-col class="col pa-3" style="width:65%;background-color:#dddddd;height:50px;display:flex;flex-direction:column;justify-content: center;text-shadow:none;">
                                                    <div v-for="state in machine_filters" :key="state">
                                                        <div v-if="state.percent === report.indice" style="font-size:14px;color:black;padding-left: 20px;"> {{ state.annotation }}</div>
                                                        <div v-if="state.percent === report.indice && report_position == 1" style="font-size:10px;color:black;padding-left: 20px;font-weight:400;display:flex;"> <div style="color:#0e4cef;font-weight:bold;margin-right:5px;">* </div> Il s'agit du premier rapport enregistré sur cette application pour ce moyen.</div>
                                                        <div v-if="state.percent === report.indice && previous_indice < report.indice && report_position != 1" style="font-size:10px;color:black;padding-left: 20px;font-weight:400;display:flex;"> <div style="color:green;font-weight:bold;margin-right:5px;">↗ </div> Evolution positive depuis le {{previous_date}} (EDS {{previous_indice}}%)</div>
                                                        <div v-if="state.percent === report.indice && previous_indice == report.indice && report_position != 1" style="font-size:10px;color:black;padding-left: 20px;font-weight:400;display:flex;"> <div style="color:black;font-weight:bold;margin-right:5px;">= </div> Pas d'évolution depuis le {{previous_date}} (EDS {{previous_indice}}%)</div>
                                                        <div v-if="state.percent === report.indice && previous_indice > report.indice && report_position != 1" style="font-size:10px;color:black;padding-left: 20px;font-weight:400;display:flex;"> <div style="color:red;font-weight:bold;margin-right:5px;">↘ </div>Evolution négative depuis le {{previous_date}} (EDS {{previous_indice}}%)</div>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </div>
                                    <!-- Sous-ensemble -->
                                    <v-row v-for="machine in machines" :key="machine" v-if="machine.id === report.id_machine" style="display: flex; align-items: center; margin: 10px 0 0 0;">
                                        <div style="padding: 5px 10px; margin-bottom: 0px!important; border: 1px solid #e0e0e0; border-radius:25px; background-color: #e0e0e0;">{{ $t('report.subassembly_equipment') }} : 
                                            <template v-for="subassembly in machine.subassemblies" :key="subassembly">
                                                {{ subassembly.label }} |
                                            </template>
                                        </div>
                                    </v-row>
                                </v-col>                                 
                            </div> 
                        </v-row>

                        <div v-if="img_urls.length > 1">
                            <v-row >
                                <!-- REPORT CONTENT -->
                                <v-col class="col-7">
                                    <v-card>
                                        <validation-observer ref="observer" v-slot="{ invalid }">
                                            <v-card-text class="pb-0" style="padding:0!important">
                                                <v-col class="pa-0">
                                                    <v-col v-if="report.context || report.comment || report.preco" class="pa-0 pl-0 col" ref="contextHeightRef">
                                                        <!-- REPORT CONTEXT -->
                                                        <v-row class="pt-0 pl-3 pr-3">
                                                            <v-col class="pa-0 pl-0 col">
                                                                <template v-if="report.context">
                                                                    <p  class="d-inline-block" style="position: relative; top: 17px; background-color: white; z-index: 10; margin-left: 15px; padding: 0px 5px; font-weight: bold; width: 259px;font-size: 12px;">
                                                                        {{ $t('report.context') }}
                                                                    </p>
                                                                    <div style="border: 1px solid #bdbdbd; padding: 10px 20px; border-radius: 5px;margin: 0px 0px;font-size: 13px;line-height: 1.2;">
                                                                        <div v-html="report.context"></div>
                                                                    </div>
                                                                </template>
                                                            </v-col>

                                                        </v-row>

                                                        <!-- REPORT COMMENT -->
                                                        <template v-if="report.comment">
                                                            <v-col class="col" style="padding-left: 0; padding-right: 0;">
                                                                <p style="position: relative; top: 17px; background-color: white; z-index: 10; margin-left: 15px; padding: 0px 5px;font-weight: bold; display:inline-block;font-size: 12px;">
                                                                    {{ $t('report.comment') }}
                                                                </p>
                                                                <div style="border: 1px solid #bdbdbd; padding: 10px 20px; border-radius: 5px; margin: 0px 0px;font-size: 13px;line-height: 1.2;">
                                                                    <div v-html="report.comment"></div>
                                                                </div>
                                                            </v-col>
                                                        </template>
                                                    
                                                        <!-- REPORT PRECO -->
                                                        <template v-if="report.preco">
                                                            <p class="d-inline-block" style="position: relative; top: 17px; background-color: white; z-index: 10; margin-left: 15px; padding: 0px 5px; font-weight: bold; width: 212px;font-size: 12px;">
                                                                {{ $t('report.preco') }}
                                                            </p>
                                                            <div style="border: 1px solid #bdbdbd; padding: 10px 20px; border-radius: 5px; margin: 0px 0px;font-size: 13px;line-height: 1.2;">
                                                                <div v-html="report.preco"></div>
                                                            </div>
                                                        </template>
                                                    </v-col>                                        
                                                </v-col>
                                            </v-card-text>
                                        </validation-observer>
                                    </v-card>
                                </v-col>

                                <!-- REPORT IMAGES SECTION -->
                                <div class="pa-0 ma-0" v-if="report.images && report.images.length > 0">
                                    <v-col class="col" v-for="(image, index) in report.images" :key="image.path">
                                        <a :href="image.path" class="col col" style="max-height:250mm;">
                                            <img :src="image.path" alt="Image du rapport" style="object-fit: fill; width: 100%;">
                                        </a>
                                    </v-col>
                                </div>
                            </v-row>
                        </div>
                    </div>
                </v-col>
            </div>
        </div>
        <!-- ------------------------------------ -->  
        <!-- PRINT AREA -->
    </v-col>
</template>

<script>    
import {CAMPAIN,SUBASSEMBLIES,MACHINES,REPORT,USERS,SITES,DOCUMENTS, PROFESSIONALS} from "../../../api";
import moment from "moment";
//import PrintCampain from "../../components/Print/PrintCampain";

export default {
    name: "CampainDetail",
    components: {},
    data() {
        return {
            headers: [
                {text: "Etat de santé", value: 'alert_level', width: '92px'},
                {text: "Informations", value: 'infos', width:'30%', sortable: false},
                {text: "Préconisations", value: 'preco', width:'40%', sortable: false},
                {text: "Tags", value: 'tags', width:'30%', sortable: false},
            ],
            infoToggle: true, // Machine menu package //
            isLoading: false,
            pageLoaded: false,
            // CAMPAINS ----------------
            raw_campains: [],
            campains: [],
            raw_trashed_campains: [],
            trashed_campains: [],
            //-
            campains_trash:false,
            dialog_next_update: false,
            campains_available:false,
            deprecated_length:5,
            deprecated_available:false,
            //-
            active_campains_count:0,
            inactive_campains_count:0,
            global_campains_count:0,
            //-
            raw_reports:[],
            reports:[],
            available_reports_count:0,
            img_urls:[],
            //-
            professionals: [],
            professional: null,
            
            // GLOBAL ----------------
            loading_datatable:true,
            loading_reports:true,
            loading: true,
            access_level: this.$store.state.access_level,
            id: this.$route.params.id,
            machine_listed: "",
            //-
            search: '',
            filter_mode:9,
            edited_index: null,
            invalid: false,
            //-
            dialog_edit: false,
            dialog_delete: false,
            delete_id: null,
            deleted_index: null,

            isPrinting: false,
            
            // ARRAYS ----------------
            states_OFFLINE: [
                {
                    level: 3,
                    level_name: "Etablissement des rapports",
                    level_icon: "mdi-book-sync",
                    level_color: "#089d2d",
                    level_bg: "#ffffff",
                    level_border: "1px solid #089d2d",
                    count:0
                },
                {
                    level: 2,
                    level_name: "Analyses en cours",
                    level_icon: "mdi-cloud-sync",
                    level_color: "#014181",
                    level_bg: "#ffffff",
                    level_border: "1px solid #014181",
                    count:0
                },
                {
                    level: 1,
                    level_name: "Mesures en cours",
                    level_icon: "mdi-account-hard-hat",
                    level_color: "#014181",
                    level_bg: "#ffffff",
                    level_border: "1px solid #014181",
                    count:0
                },
                {
                    level: 0,
                    level_name: "En cours de préparation",
                    level_icon: "mdi-cog",
                    level_color: "#c97700",
                    level_bg: "#ffffff",
                    level_border: "1px solid #c97700",
                    count:0
                },              
            ],
            states_ONLINE: [
                {
                    level: 3,
                    level_name: "Surveillance active (Online)",
                    level_icon: "mdi-cloud-sync",
                    level_color: "#089d2d",
                    level_bg: "#089d2d1c",
                    level_border: "1px solid #089d2d",
                    count:0
                },
                {
                    level: 2,
                    level_name: "Configuration en cours",
                    level_icon: "mdi-cog",
                    level_color: "#014181",
                    level_bg: "#e0e8f0",
                    level_border: "1px solid #014181",
                    count:0
                },
                {
                    level: 1,
                    level_name: "Instrumentation en cours",
                    level_icon: "mdi-account-hard-hat",
                    level_color: "#014181",
                    level_bg: "#e0e8f0",
                    level_border: "1px solid #014181",
                    count:0
                },
                {
                    level: 0,
                    level_name: "En cours de préparation",
                    level_icon: "mdi-cog",
                    level_color: "#c97700",
                    level_bg: "#9d60081c",
                    level_border: "1px solid #c97700",
                    count:0
                },              
            ],
            data_filters: [
                {
                    level: 9,
                    level_name: "Tout voir",
                    level_color: "#d7d7d752",
                    count:this.global_notifications_count
                },
                {
                    level: 3,
                    level_name: "Rapports en cours de validation",
                    level_color: "#3373ba",
                    level_icon: "mdi-clipboard-check",
                    count:0
                },
                {
                    level: 2,
                    level_name: "Rapports en cours de validation",
                    level_color: "#0e4cef",
                    level_icon: "mdi-clipboard-pulse",
                    count:0
                },
                {
                    level: 1,
                    level_name: "Rapports en cours d'édition",
                    level_color: "#ef780e",
                    level_icon: "mdi-clipboard-edit",
                    count:0
                },
                {
                    level: 0,
                    level_name: "Rapports en attente",
                    level_color: "grey",
                    level_icon: "mdi-",
                    count:0
                },              
            ],

            machine_filters: [
                { level: 9, level_name: "Tout voir", level_color: "#d7d7d752", count:this.global_notifications_count},
                { level: -1, level_name: "Rapport en attente", level_color: "grey", count:0, annotation: "Etat à définir"},

                { level: 1, level_name: "Pour information : EDS 100% (vert)", level_color: "#4CAF50", count:0, percent: 100, annotation: "Etat neuf" },
                { level: 2, level_name: "Pour information : EDS 90% (vert)", level_color: "#4CAF50", count:0, percent: 90, annotation: "Comportement vibratoire correct" },
                { level: 3, level_name: "Pour information : EDS 80% (vert)", level_color: "#4CAF50", count:0, percent: 80, annotation: "Pour information" },

                { level: 11, level_name: "A suivre : EDS 70% (jaune)", level_color: "#FFCB05", count:0, percent: 70, annotation: "A suivre lors de la prochaine campagne" },
                { level: 12, level_name: "A suivre : EDS 60% (jaune)", level_color: "#FFCB05", count:0, percent: 60, annotation: "A suivre de manière rapprochée" },
                { level: 13, level_name: "A suivre : EDS 50% (jaune)", level_color: "#FFCB05", count:0, percent: 50, annotation: "Expertise complémentaire préconisée" },

                { level: 21, level_name: "Importance élevée : EDS 40% (rouge)", level_color: "#F44336", count:0, percent: 40, annotation: "Action à prévoir au prochaine arrêt technique" },
                { level: 22, level_name: "Importance élevée : EDS 30% (rouge)", level_color: "#F44336", count:0, percent: 30, annotation: "Action à prévoir au prochaine arrêt technique" },
                { level: 23, level_name: "Importance élevée : EDS 20% (rouge)", level_color: "#F44336", count:0, percent: 20, annotation: "Action à prévoir au prochaine arrêt technique" },
            
                { level: 31, level_name: "Urgence : EDS 10% (Noir)", level_color: "black", count:0, percent: 10, annotation: "Action à prévoir dans les plus brefs délais" },
                { level: 32, level_name:"Urgence : EDS 0% (Noir)", level_color: "black", count:0, percent: 0, annotation: "Arrêt de la machine recommandé" },               
            ],

            machine_filters_titles: [
                {
                    level: 9,
                    level_name: "Tout voir",
                    level_color: "#d7d7d752",
                    count:this.global_notifications_count
                },
                {
                    level: 3,
                    level_name: "Urgence",
                    level_color: "black",
                    count:0
                },
                {
                    level: 2,
                    level_name: "Importance élevée",
                    level_color: "#F44336",
                    count:0
                },
                {
                    level: 1,
                    level_name: "A suivre",
                    level_color: "#FFCB05",
                    count:0
                },
                {
                    level: 0,
                    level_name: "Pour information",
                    level_color: "#4CAF50",
                    count:0
                },
                {
                    level: -1,
                    level_name: "En attente",
                    level_color: "grey",
                    count:0
                },                
            ],
            edited_campain: {
                id: null,
                name: '',
                presta:'',
                type: '',
                affaire_number:'',
                manager: '',
                id_park: null,
                machine_list:[],
                description: '',
                tags:[],                          
            },
            analysts: [],
            team: [],
            machines: [],
            machines_count:0,
            reports_count:0,
            reports_done_count:0,
            sites: [],
            prestas:[
                {id:0, name:"Mesures vibratoires"},
                {id:1, name:"Thermographie"},
                {id:2, name:"Mesures ultrasons"},
                {id:3, name:"Equilibrages"},
                {id:4, name:"Expertise avancée"},
                {id:5, name:"Surveillance Online"},
            ],
            types:[
                {id:0, name:"Ponctuel"},
                {id:1, name:"Annuel"},
                {id:2, name:"Semestriel"},
                {id:3, name:"Quadrmestriel"}, 
                {id:4, name:"Trimestriel"},
                {id:5, name:"Continu"},
            ],
            tags:[
                {id:0, name:"Caractérisation machine"},
                {id:1, name:"Création BDD"},
                {id:2, name:"Etude approfondie"},
                {id:3, name:"Machines outils"},
                {id:4, name:"Machines tournantes"},
                {id:5, name:"Surveillance à distance"},
                {id:6, name:"Trés basse vitesse"},
                {id:7, name:"Historique"},
            ],
            timeProps: {
                format: "24hr"
            },
            analysts_datas:[
                {id:40,  name:"Eric Quenneville", tel:"+33 6 38 38 11 18", email:"eric.quenneville@techview.fr", logo:"https://techview.fr/wp-content/uploads/2024/10/ERIC-QUENNEVILLE-CAT-III-e1725528314226-removebg-preview-2.png"},
                {id:121, name:"Pascal Ardoin",    tel:"+33 6 74 35 31 13", email:"pascal.ardoin@techview.fr", logo:"https://techview.fr/wp-content/uploads/2024/09/PASCAL-ARDOIN-CAT-II-e1725542858336.png"},
                {id:91,  name:"Amandine Prieur",  tel:"+33 7 87 58 28 98", email:"amandine.prieur@techview.fr", logo:"https://techview.fr/wp-content/uploads/2024/12/572e2dac-42b9-46f8-a7d5-995f87877850.png"},
                {id:278,  name:"Louis Blaise",  tel:"+33 6 77 33 08 68", email:"louis.blaise@techview.fr"},
                {id:216,  name:"Guillaume Maury",  tel:"+33 6 07 46 48 40", email:"guillaume.maury@techview.fr"},
                {id:79,  name:"Sebastien Poudenx",  tel:"+33 6 68 42 45 15", email:"sebastien.poudenx@techview.fr"},
            ],
            textFieldProps: {
                prependInnerIcon: "mdi-calendar",
                solo: true,
                rules: [v => !!v || this.$t('vee-validation.required_date_operation')]
            },

            img_loading: false,
            img_result: [],
            img_uploaded_files: [],
            img_available: false,


            isLoaded: false,


            view_details: false,
        }
    },
    computed: {
        dialogTitle() {
            return this.edited_index === null ? this.$t('campain.add') : this.$t('campain.edit');
        },
        computed: {
            uploadAssetImageURI() {
                return `${MACHINES}/${this.machine_id}/${this.report_id}/image`;
            },
        },
    },
    created() {
        moment.locale('fr');
        this.initialize();
        this.loadData();
    },
    methods: {
        // COLLECTING ALL NEEDED DATAS
        initialize() {
            this.loading_datatable = true;
            this.loading_reports=true;
            this.getData();
            this.view_details = true;

        },
        getData() {
            // GETTING CAMPAINS
            this.axios.get(CAMPAIN + '/specific/' + this.id)
            
            .then((success) => {
                success.data.forEach(item => {
                    item.created_at = moment(item.created_at).format("DD/MM/YYYY");
                    item.machine_list = JSON.parse(item.machine_list);
                })
                this.filter_mode = 9;
                this.raw_campains = [];
                this.campains = [];

                if (this.checkDataAvailable(success.data)){
                    this.raw_campains = success.data;
                    this.campains = success.data;
                    this.machine_listed = this.campains[0].machine_list.join('-');
                }

            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");

            }).finally(() => {
                this.loading_datatable = false;
                this.getSideDatas();
            });
        },
        async getSideDatas() {
            try {
                const success = await this.axios.get(REPORT + '/campain/' + this.id);
                this.raw_reports = [];
                this.reports = [];
                this.raw_reports = success.data;

                for (const report of success.data) {
                    if (this.campains[0].machine_list.includes(report.id_machine)) {
                        // Attendre les données d'images pour chaque rapport
                        report.images = await this.img_getDocuments(report.id);
                        this.reports.push(report);
                    }
                }

                this.getDataCount();
            } catch (error) {
                this.showSnackbar('error', "Side datas error.");
            } finally {
                this.getAnalysts();
                this.getTeam();
                this.getMachines();
                this.getSites();
            }
        },

        getAnalysts(){
            this.axios.get(USERS + '/analysts')
            
            .then((success) => {
                this.analysts = [];
                this.analysts = success.data;

            }).catch(() => {
                this.showSnackbar('error', "Analysts datas error.");
            })
        },
        getTeam(){
            this.axios.get(USERS + '/team')
            
            .then((success) => {
                this.team = [];
                this.team = success.data;

            }).catch(() => {
                this.showSnackbar('error', "Team datas error.");
            })
        },
        getMachines() {
            this.axios.get(MACHINES + '/specific/' + this.machine_listed)
            
            .then((success) => {
                this.machines = [];
                this.machines = success.data;
                this.machines_count = this.machines.length;

            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");
            }).finally(() => {
                this.loading_reports = false;
            })
        },
        getSites() {
            this.axios.get(SITES + '/resume')
            
            .then((success) => {
                this.sites = [];
                this.sites = success.data;

            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");
            })
        },
        getProfessional() {
            this.axios.get(PROFESSIONALS + "/active")
            
            .then((success) => {
                this.professional = success.data;
            }).catch(() => {
                this.showSnackbar('error', "Untrashed me data error.");
            })
        },
        // DISPLAY DATA MANAGEMENT
        checkDataAvailable(datas){
            if(typeof datas[0] === 'undefined') {                    
                this.campains_available = false;
                return false;
            }else {
                this.campains_available = true;
                return true;
            }
        },
        setDataFiltered (filter) {
            this.loading_reports = true;
            this.filter_mode = filter;
            this.dataTreatment();
            this.loading_reports = false;
        },
        dataTreatment(){
            if(this.checkDataAvailable(this.raw_reports)) {                    
                this.reports = [];

                if(this.filter_mode === 9){
                    this.reports = this.raw_reports;

                }else{
                    this.raw_reports.forEach(report => {
                        if (report.alert_level == -1 && this.filter_mode == -1){
                            this.reports.push(report);
                        }
                        
                        if (report.alert_level >= 1 && report.alert_level <= 3 && this.filter_mode == 0){
                            this.reports.push(report);
                        }

                        if (report.alert_level >= 11 && report.alert_level <= 13 && this.filter_mode == 1){
                            this.reports.push(report);
                        }

                        if (report.alert_level >= 21 && report.alert_level <= 23 && this.filter_mode == 2){
                            this.reports.push(report);
                        }

                        if (report.alert_level >= 31 && report.alert_level <= 32 && this.filter_mode == 3){
                            this.reports.push(report);
                        }

                    })
                }
                
                // Then check result to si if there is somehting left in chosen datas
                this.checkDataAvailable(this.reports);
            }
        },


        img_launchUrl(url){
            this.report_url = url;
        },
        getCampainTags(campain) {
            if (campain.tags != "") {
                return JSON.parse(campain.tags);
            }
        },
        getReportTags(report) {
            if (report.tags != "") {
                return JSON.parse(report.tags);
            }
        },
        getDataCount() {
            // Reset all counts
            this.available_reports_count = 0;
            this.machine_filters_titles.forEach(data => {data.count = 0});
            
            // Get all last counts
            this.reports.forEach(item => {  
                if(item.step >= 4){
                    this.available_reports_count = this.available_reports_count +1;
                }
                this.machine_filters_titles.forEach(filter => { 
                    if (item.alert_level >= 1 && item.alert_level <= 3 && filter.level == 0){
                        filter.count = filter.count +1;
                    }

                    if (item.alert_level >= 11 && item.alert_level <= 13 && filter.level == 1){
                        filter.count = filter.count +1;
                    }

                    if (item.alert_level >= 21 && item.alert_level <= 23 && filter.level == 2){
                        filter.count = filter.count +1;
                    }

                    if (item.alert_level >= 31 && item.alert_level <= 32 && filter.level == 3){
                        filter.count = filter.count +1;
                    }

                    if (item.alert_level == -1 && filter.level == -1){
                        filter.count = filter.count +1;
                    }
                });
            });

            // FREEUP LOADING STEP
            this.loading_datatable = false
        },
        viewMore() {
            this.deprecated_length = this.deprecated_length + 10;
            this.dataTrashTreatment();
        },
        // EXISTING TREATMENTS
        // MODALS
        openDialogUpdateCampain(campain) {
            this.edited_index = this.campains.indexOf(campain);
            this.edited_campain = Object.assign({}, campain);
            this.dialog_edit = true;
        },
        openDialogDeleteCampain(campain) {
            this.deleted_index = this.campains.indexOf(campain);
            this.delete_id = campain.id;
            this.dialog_delete = true;
        },
        openDialogAddReport() {
            this.sub_edit_location = "add"
            this.modal_sub_title = "Ajouter un sous-ensemble";
            this.edited_subassembly = [];
            this.subassembly_id = "";
            // Open modal
            this.sub_dialog_edit = true;
        },
        openDialogEditReport(subassembly) {
            this.loading_bind_virtual_devices = true;
            this.modal_sub_title = "Modifier le sous-ensemble ";
            this.sub_edit_location = "edit"
            this.edited_subassembly = subassembly;
            this.subassembly_id = subassembly.id;
            this.virtual_devices_bind_to_subassembly = [];
            // Open modal
            this.sub_dialog_edit = true;

            this.axios.get(VIRTUAL_DEVICES + '/not_bind/subassembly/' + this.subassembly_id)
            .then((success) => {
                this.virtual_devices = success.data;
                this.dialog_bind_virtual_devices = true;

                this.virtual_devices.forEach(virtual_device => {
                    if (virtual_device.subassembly_id !== null) this.virtual_devices_bind_to_subassembly.push(virtual_device);
                });

            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'))

            }).finally(() => {
                this.loading_bind_virtual_devices = false
            });

        },
        openDialogDeleteReport(subassembly) {
            this.deleted_index = this.subassemblies.indexOf(subassembly);
            this.delete_id = subassembly.id;
            this.sub_dialog_delete = true;
        },
        openDialogShow(){
            console.log("open dialog show requested");
        },
        closeDialog() {
            this.$refs.form.reset();
            this.$refs.observer.reset();
            this.dialog_edit = false;
        },
        closeReportDialog() {
            this.sub_dialog_edit = false;
            this.sub_dialog_delete = false;
        },
        // POST REQUESTS PART
        prepareEditCampain() {
            if (this.edited_index !== null) {
                this.updateCampain();
            } else {
                this.addCampain();
            }
            this.closeDialog();
        },
        updateCampain() {
            this.axios.patch(CAMPAIN + '/' + this.edited_campain.id,
                this.edited_campain
            ).then((success) => {
                Object.assign(this.campains[this.edited_index], success.data);
                this.edited_index = null;
                this.showSnackbar('success', this.$t('global.success_to_edit'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            }).finally(() => {
                this.getData();
                this.loading_datatable = true;
                this.closeDialog();
            })
        },
        updateStep(id, step_id){
            this.axios.patch(CAMPAIN + '/step/' + id + "/" + step_id
            ).then((success) => {
                this.getData();

            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            })
        },
        confirmCampainDelete() {
            this.axios.delete(
                CAMPAIN + '/delete/' + this.delete_id
            ).then(() => {
                this.campains.splice(this.deleted_index, 1);
                this.showSnackbar('success', this.$t('global.success_to_delete'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_delete'));
            }).finally(() => {
                this.loading_datatable = true;
                this.getData();
                this.dialog_delete = false;
            });
        },
        setClosed(id){
            this.axios.patch(CAMPAIN + '/close/' + id)
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.getData();
                    this.dialog_show = false;
                })
        },
        setOpened(id){
            this.axios.patch(CAMPAIN + '/open/' + id)
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.getData();
                    this.dialog_show = false;
                })
        },
        setReportState(report, state) {
            //  transition ASK VALIDATION STEP [2]
            if (state === 1) {
                this.axios.patch( REPORT + '/ask_validate/' + report.id)
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.initialize();
                })
            }
            //  transition UNASK VALIDATION STEP [1]
            if (state === 2) {
                this.axios.patch( REPORT + '/unask_validate/' + report.id)
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.initialize();
                })
            }
            //  transition VALIDATION STEP [3]
            if (state === 3) {
                this.axios.patch( REPORT + '/certify/' + report.id )
            
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.initialize();
                })
            }
            //  transition VALIDATION STEP [4]
            if (state === 4) {
                this.axios.patch( REPORT + '/failed_certify/' + report.id )
            
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.initialize();
                })
            }
            //  transition UNVALIDATION STEP [4]
            if (state === 5) {
                this.axios.patch( REPORT + '/uncertify/' + report.id )
            
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.initialize();
                })
            }
            //  transition RELEASE STEP [5]
            if (state === 6) {
                this.axios.patch( REPORT + '/release/' + report.id )
            
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.initialize();
                })
            }
            //  transition UNRELEASE STEP [6]
            if (state === 7) {
                this.axios.patch( REPORT + '/unrelease/' + report.id )
            
                .then(() => {
                    this.showSnackbar('success', this.$t('global.success_to_edit'));
                
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.failure_to_update'));
                
                }).finally(() => {
                    this.loading_datatable = true;
                    this.initialize();
                })
            }
        },
        // TO MANAGE FOR REPORTS MANAGEMENT
        //COLLECT DATAS
        getReports() {
            this.axios.get(
                SUBASSEMBLIES
            ).then((success) => {
                this.subassemblies = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'))
            }).finally(() => {
                this.loading = false
            });
        },
        // DATA TREATMENT
        prepareEditReports() {
            if (this.sub_edit_location === 'edit') {
                //this.updateSubassembly();
            } else {
                this.addReport();
            }

            this.closeDialog();
        },
        // REQUESTS
        addReport() {
            this.edited_subassembly.config = this.subassembly_configuration_choose;
            let formData = new FormData()
            formData.append('label', this.edited_subassembly.label);
            formData.append('subassembly_type', this.edited_subassembly.subassembly_type);
            formData.append('config', JSON.stringify(this.edited_subassembly.config));
            formData.append('note', this.edited_subassembly.note);
            formData.append('campain_id', this.edited_subassembly.campain_id);
            formData.append('path_image', this.edited_subassembly.path_image);

            this.axios.post(
                SUBASSEMBLIES, formData, this.configurationFile
            ).then((success) => {
                this.subassemblies.push(success.data);
                this.showSnackbar('success', this.$t('global.success_to_add'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_add'));
            }).finally(() => {
                this.getReports();
                this.getData();
                this.resetFormEditReport();
                this.closeReportDialog();
            });
        },
        confirmReportDelete() {
            this.axios.delete(
                SUBASSEMBLIES + '/' + this.delete_id
            ).then(() => {
                this.subassemblies.splice(this.deleted_index, 1);
                this.showSnackbar('success', this.$t('global.success_to_delete'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_delete'));
            }).finally(() => {
                this.getReports();
                this.getData();
                this.closeReportDialog();
            });
        },
        resetFormEditReport() {
            this.$refs.formBindVirtualDevice.reset();
            this.$refs.observer.reset();
            this.subassembly_configuration = null;
            this.subassembly_configuration_choose = null;
        },
        // NOT USED TO BE IMPROVED
        onImageChanged() {
            if(this.edited_subassembly.path_image !== null) {
                let imageCheck = this.uploadFilesCheck(this.edited_subassembly.path_image,['image/png','image/jpg','image/jpeg'],2);
                if(!imageCheck.authorized) {
                    this.valid_image = false;
                    this.showSnackbar('error',imageCheck.output.toString());
                }
                else {
                    this.valid_image = true;
                }
            }
            if(this.edited_subassembly.path_image === null){
                this.valid_image = true;
            }
        },
        dialogNextUpdate() {
            if (this.dialog_next_update){
                this.dialog_next_update = false;
            }else{
                this.dialog_next_update = true;
            }
        },
        printCampain() {
            window.print();
        },
        loadData() {
            window.addEventListener("DOMContentLoaded", () => {

            })
            this.isLoading = true;

            setTimeout(() => {
                this.isLoading = false;
            }, 5000);
        },
        calculateHeight() {
            this.isLoading = true;

            // Logique de calcul de hauteur
            const headerHeight = document.getElementById('headerHeight');
            const contentHeight = document.getElementById('contentHeight');
            const imgHeight = document.getElementById('imgHeight');

            if (headerHeight && contentHeight && imgHeight) {
                const heightDiv1 = headerHeight.getBoundingClientRect().height;
                const heightDiv2 = contentHeight.getBoundingClientRect().height;

                const totalHeight = heightDiv1 + heightDiv2;

                if (totalHeight < 775) {
                    imgHeight.style.height = '500px';
                } else if (totalHeight > 950) {
                    imgHeight.style.pageBreakBefore = 'always';
                    imgHeight.style.height = '500px';
                } else {
                    imgHeight.style.height = `calc(1275px - ${totalHeight}px)`;
                }
            } else {
                console.error('Un ou plusieurs éléments non trouvés');
            }

            this.pageLoaded = true;
            this.isLoading = false;

            setTimeout(() => {
                this.printPDF();
            }, 100);
        },
        infoToggler(){
            this.infoToggle = !this.infoToggle;
        },
        goToReport(item) {
            if (item && item.id_machine) {
            this.$router.push(`/reported/${item.id}`);
            }
        },

        customFilter(value, search, item) {
            if (!search) return true;
            
            const searchLower = search.toLowerCase();

            return Object.values(item).some(val =>
                String(val).toLowerCase().includes(searchLower)
            );
        },

        
        img_getDocuments(report_id) {
            this.axios.get(DOCUMENTS + '/getFiles', {
                    params: {
                        entity_id: report_id,
                        type: 'report_images',
                        professional_id: this.$store.state.professional_id,
                    }
                }

            ).then((success) => {
                this.img_uploaded_files = [];
                this.img_urls = [];
                
                if (this.checkDataAvailable(success.data)){
                    success.data.forEach((path) => {
                        let fileName = path.replace(/^.*[\\\/]/, '');
                        this.img_uploaded_files.push({
                            file_name: fileName,
                            path: path
                        });
                    });

                    this.img_uploaded_files.reverse();

                    this.img_uploaded_files.forEach(img_url => {
                        this.img_urls.push(img_url.path);
                        this.img_available = true;
                    })

                }else{
                    this.img_available = false;
                }
            }).catch(() => {
                if(this.img_uploaded_files[0]){
                    this.showSnackbar('error', 'img_GetDocuments error');
                }

            }).finally(() => {
                return this.img_uploaded_files;
            });
        },
        DlBeforePrint() {
            this.img_loading = true;
            this.img_getDocuments();

            this.$nextTick(() => {
                if (this.img_available) {
                    this.printPDF();
                } else {
                    this.showSnackbar('error', 'Aucune image disponible pour imprimer.');
                }
            });
        },


        printContent() {
            const content = this.$refs.contentToPrint.innerHTML;

            const printWindow = window.open("", "_blank");
            printWindow.document.open();
            printWindow.document.write(`
                <html>
                <head>
                    <title>Impression</title>
                    <style>
                        body {
                            margin: 20px;
                        }
                    </style>
                </head>
                <body>
                    ${content}
                </body>
                </html>
            `);
            printWindow.document.close();

            const images = printWindow.document.images;
            const imageLoadPromises = Array.from(images).map((img) => {
                return new Promise((resolve) => {
                if (img.complete) {
                    resolve();
                } else {
                    img.onload = resolve;
                    img.onerror = resolve;
                }
                });
            });

            Promise.all(imageLoadPromises).then(() => {
                printWindow.print();

                //printWindow.onafterprint = () => printWindow.close();
                setTimeout(() => {
                    printWindow.close();
                }, 100);
               
            });
        },


        viewDetails(){
            this.view_details = !this.view_details;
        },
    },
    mounted() {
        this.getSideDatas();
        this.getProfessional();
        setTimeout(() => {
            this.isLoaded = true;
        }, 5000);
    },
    
}
</script>

<style>
    @page {
        size: A4;
        margin: 10mm;
    }

    @media print {
        html * {
            visibility: hidden;
        }

        #PrintSection, #PrintSection * {
            visibility: visible!important;
        }

        #Content, #MenuPackage {
            display: none;
        }

        #PrintSection {
            display: block!important;
        }
        html, body {
            width: 100%;
            height: auto;
            transform: scale(1);
            transform-origin: top left;
        }
    }
</style>