<template>
    <v-card class="rounded-lg" elevation="2" outlined min-height="550px" height="100%">
        <v-card-title class="d-flex justify-space-between">
            <v-icon>mdi-floor-plan</v-icon>
            {{ $t('subassembly_state.title') }}
            <modal-assets-edit
                ref="assetsEdit"
                @snackbarAssetsEdit="snackbarAssetsEdit"
                :assets-data="subassembly_data"
                :file-upload-endpoint="uploadAssetImageURI"
                :title="$t('subassembly_state.edit')"
                model="virtual_devices"
                @saveChange="saveState()"
            />
            <v-btn elevation="3" @click="$refs.assetsEdit.openAssetsEdit(false)" color="amber darken-3" small>
                <v-icon color="white" small>mdi-wrench</v-icon>
            </v-btn>
        </v-card-title>
        <v-divider/>

        <v-card-text>
            <layer-assets :assets-data="subassembly_data" :asset-type="'device'"/>
        </v-card-text>

        <v-divider/>

        <v-card-text>
            <v-container>
                <v-row>
                    <v-col v-for="(device ,index) in subassembly_data.assets" :key="index">
                        <v-row class="ml-1">
                            <v-icon v-if="device.alert_battery" color="red">
                                mdi-battery-low
                            </v-icon>
                            <v-icon v-if="device.alert_keep_alive" color="red">
                                mdi-wifi-off
                            </v-icon>
                        </v-row>

                        <v-row class="justify-center">
                            <v-chip v-if="access_level < 100" :color="assetColor(device)" text-color="white" justify="center" class="w-100 ma-2"
                                    @click="$router.push({ name: 'device', params: {id: device.id} })">
                                {{ device.label }} {{ device.last_value }}
                            </v-chip>

                            <v-chip v-if="access_level >= 100" :color="assetColor(device)" text-color="white" justify="center" class="w-100 ma-2">
                                {{ device.label }} {{ device.last_value }}
                            </v-chip>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-snackbar
            v-model="snackbar"
            :color="snackbar_type">
            {{ snackbar_text }}
            <template v-slot:action="{ attrs }">
                <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
            </template>
        </v-snackbar>
    </v-card>
</template>

<script>
import {SUBASSEMBLIES} from "../../../api";
import ModalAssetsEdit from "../ModalAssetsEdit";
import LayerAssets from "../LayerAssets";

export default {
    name: "SubassemblyState",
    components: {
        ModalAssetsEdit,
        LayerAssets,
    },
    data() {
        return {
            subassembly_data: {
                path_image: '',
                assets: [],
            },
            subassembly_id: this.$route.params.id,
            access_level: this.$store.state.access_level,
        };
    },
    created() {
        this.getSubassembly();
    },
    computed: {
        uploadAssetImageURI() {
            return `${SUBASSEMBLIES}/${this.subassembly_id}/image`;
        },
    },
    methods: {
        getSubassembly() {
            this.axios.get(
                SUBASSEMBLIES + "/" + this.subassembly_id + "/virtual_devices/details"
            ).then((success) => {
                this.subassembly_data = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            });
        },

        snackbarAssetsEdit(item) {
            this.showSnackbar(item.type, item.text);
            this.getSubassembly();
        },

        saveState() {
            this.axios.put(
                SUBASSEMBLIES + '/' + this.subassembly_id + '/virtual_devices/position', this.subassembly_data
            ).then(() => {
                this.showSnackbar('success', this.$t('global.success_to_edit'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            });
        },
    }
}
</script>
