<template>
    <div id="device-detail">
        <v-row>
            <v-col style="background-color: white">
                <v-subheader>
                    <p class="title mb-0">
                        {{ $t('devices.title') }}
                    </p>
                </v-subheader>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6">
                <virtual-device-specifications/>
            </v-col>
            <v-col cols="6">
                <virtual-device-state/>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6">
                <virtual-device-subassemblies v-on:reloadLocationWidget="reloadLocationWidget()"/>
            </v-col>
            <v-col cols="6">
                <virtual-device-location ref="virtualDeviceLocation"/>
            </v-col>
        </v-row>

        <global-snackbar/>
    </div>
</template>

<script>
import VirtualDeviceSpecifications from "../../components/VirtualDevice/VirtualDeviceSpecifications";
import VirtualDeviceState from "../../components/VirtualDevice/VirtualDeviceState";
import VirtualDeviceSubassemblies from "../../components/VirtualDevice/VirtualDeviceSubassemblies";
import VirtualDeviceLocation from "../../components/VirtualDevice/VirtualDeviceLocation";
import GlobalSnackbar from "../../../global/components/GlobalSnackbar";

export default {
    name: "DetailDevice",
    components: {
        VirtualDeviceSpecifications,
        VirtualDeviceState,
        VirtualDeviceSubassemblies,
        VirtualDeviceLocation,
        GlobalSnackbar
    },
    methods: {
        reloadLocationWidget() {
            this.$refs.virtualDeviceLocation.initialize();
        }
    }
}
</script>
