<template>
    <v-card class="pl-2 rounded-lg" elevation="2" outlined min-height="50px" height="100%" style="box-shadow: none!important;border:none!important;background-color: #e8edff!important;">
        <v-card-text class="pb-0" style="position: relative;top:-20px;">
            <v-row>
                
                <!------------------- Info bulle ---------------------->
                <v-tooltip bottom dark class="mr-2">
                    <template v-slot:activator="{ on }">
                        <v-btn text icon v-on="on" @click="getNotification()" class="ml-0 v-size--default" dusk="logout-button" style="border: 1px solid #00000099;width:18px;height:18px;">
                            <v-icon style="font-size:16px;color:#00000099;">mdi-information-variant</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:default>
                    <div style="min-width: 200px;position:relative;z-index: 10;">
                        <p>Cliquez sur la pastille du sous ensemble pour le voir en détail</p>
                    </div>
                    </template>
                </v-tooltip>

                <p class="pl-0 mb-0 ml-2">Sous-ensembles</p>
            </v-row>
            <v-container class="pl-0">
                <v-row>
                    <v-col class="col-auto mr-2 mt-3" v-for="(subassembly ,index) in machine_data.assets" :key="index">
                        <v-row class="justify-start">
                            <v-chip :color="assetColor(subassembly)" text-color="white" justify="start"
                                    class="mr-2 mt-2 mb-2"
                                    @click="$router.push({ name: 'subassembly', params: {id: subassembly.id} })">
                                {{ subassembly.label }}
                            </v-chip>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-snackbar
            v-model="snackbar"
            :color="snackbar_type">
            {{ snackbar_text }}
            <template v-slot:action="{ attrs }">
                <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
            </template>
        </v-snackbar>
    </v-card>
</template>

<script>
import {MACHINES} from "../../../api";
import ModalAssetsEdit from "../ModalAssetsEdit";
import LayerAssets from "../LayerAssets";

export default {
    name: "MachineState",
    components: {
        ModalAssetsEdit,
        LayerAssets,
    },
    data() {
        return {
            machine_data: {
                path_image: '',
                assets: []
            },
            machine_id: this.$route.params.id,
        };
    },
    created() {
        this.getMachine();
    },
    computed: {
        uploadAssetImageURI() {
            return `${MACHINES}/${this.machine_id}/image`;
        },
    },
    methods: {
        getMachine() {
            this.axios.get(
                MACHINES + "/" + this.machine_id + "/subassemblies/details"
            ).then((success) => {
                this.machine_data = success.data;
            }).catch(() => {
                this.showSnackbar('error', "Get machine error sub152");
            });
        },

        checkActivation(activationDate) {
            let now = new Date();
            let activation = new Date(activationDate);
            let expiredDate = activation.setFullYear(activation.getFullYear() + 1);
            return now > expiredDate;
        },

        checkLastSeen(lastSeenDate) {
            let now = new Date();
            let lastSeen = new Date(lastSeenDate);
            lastSeen.setDate(lastSeen.getDate() + 1);

            return now > lastSeen;
        },

        snackbarAssetsEdit(item) {
            this.showSnackbar(item.type, item.text);
            this.getMachine();
        },

        saveState() {
            this.axios.put(
                MACHINES + '/' + this.machine_id + '/subassemblies/position', this.machine_data
            ).then(() => {
                this.showSnackbar('success', this.$t('global.success_to_edit'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            });
        },
    }
}
</script>
