<template>
    <div id="virtual-device-vision">
        <v-row>
            <v-col class="ml-6 mr-6 pt-5 pl-1" style="background-color: #001047;border-radius: 0px 0px 5px 5px;">
                <v-subheader class="v-subheader col theme--light">
                    <!------------------- Info bulle ---------------------->
                    <v-tooltip bottom dark class="mr-2 ml-2">
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 mr-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                                <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:default>
                        <div style="min-width: 200px;position:relative;z-index: 10;">
                            <strong>Page [Gestion capteurs]</strong>
                            <p>Toutes les machines que nous sommes susceptibles de surveiller se trouvent ici.</br>
                                Toutes les caractéristiques techniques associées au suivi en ligne sont configurés sur cette page.</br>
                                Ceci est valable par exemple pour les sous-ensembles. </br>
                                Cliquer sur l'un d'entre eux pour le visualiser en détail.
                            </p>
                        </div>
                        </template>
                    </v-tooltip>
                    <p class="title mb-0 color-white" style="color: white!important;">
                        <div style="color:#FFFFFFb3;margin-right:4px;">Administration > </div><div @click="infoToggler()" style="color:#FFFFFF;font-weight:bold;"> {{ $t('virtual_devices.title') }}</div>
                    </p>
                </v-subheader>
            </v-col>
        </v-row>

        <v-row class="mt-6 mr-0 ml-0">
            <v-col>
                <v-card class="rounded-lg" elevation="2" outlined style="overflow: hidden;">
                    <v-data-table
                        :headers="headers"
                        :items="virtual_devices"
                        sort-by="id"
                        class="elevation-1 datatable-setting-min-height"
                        :loading="loading_virtual_devices"
                        :items-per-page="10"
                        :search="search">

                        <template v-slot:top>
                            <v-toolbar flat color="white" class="pt-3">
                                    <v-col class="col-8" style="display: flex;">
                                        <v-btn color="primary" class="white--text mr-3"
                                               @click="openDialogEditVirtualDevice()">
                                            {{ $t('virtual_devices.add_virtual_device') }}
                                        </v-btn>
                                        <v-btn color="primary" class="white--text mr-3"
                                               @click="openDialogImportVirtualDevice()">
                                            {{ $t('virtual_devices.import_virtual_device') }}
                                        </v-btn>
                                    </v-col>

                                    <v-col style="display: flex; justify-content: end;">
                                        <v-card-title class="layout justify-end mt-7 max-width-300">
                                            <v-text-field
                                                v-model="search"
                                                append-icon="mdi-magnify"
                                                :label="$t('global.search')"
                                            />
                                        </v-card-title>
                                    </v-col>

                                <v-dialog v-model="dialog_edit" max-width="500px">
                                    <v-card>
                                        <validation-observer ref="observer" v-slot="{ invalid }">
                                            <v-form ref="form">
                                                <v-card-title><span class="headline">{{ dialogTitle }}</span>
                                                </v-card-title>
                                                <v-divider/>

                                                <v-card-text>
                                                    <validation-provider :name="$t('global.label')" rules="required"
                                                                         v-slot="{ errors }">
                                                        <v-text-field
                                                            :label="$t('global.label')"
                                                            v-model="edited_virtual_device.label"
                                                            :prepend-icon="'mdi-label-outline'"
                                                            :error-messages="errors" solo
                                                        />

                                                    </validation-provider>

                                                    <validation-provider :name="$t('global.type')" rules="required"
                                                                         v-slot="{ errors }">
                                                        <v-text-field
                                                            :label="$t('global.type')"
                                                            v-model="edited_virtual_device.type"
                                                            :prepend-icon="'mdi-tape-measure'"
                                                            :error-messages="errors" solo
                                                        />

                                                    </validation-provider>

                                                    <validation-provider :name="$t('virtual_devices.hardware')"
                                                                         rules="required"
                                                                         v-slot="{ errors }"
                                                                         v-if="edited_index !== null">
                                                        <v-select
                                                            :items="physical_devices"
                                                            v-model="edited_virtual_device.hardware_uid"
                                                            :label="$t('virtual_devices.hardware')"
                                                            :prepend-icon="'mdi-console-line'"
                                                            :error-messages="errors" solo
                                                            item-text="uid"
                                                            item-value="uid"
                                                        >
                                                            <!--<template slot="selection" slot-scope="data">
                                                                {{ data.item.hardware_mac }} -
                                                                {{ data.item.hardware_type }}
                                                            </template>
                                                            <template slot="item" slot-scope="data">
                                                                {{ data.item.hardware_mac }} -
                                                                {{ data.item.hardware_type }}
                                                            </template>-->
                                                        </v-select>
                                                    </validation-provider>
                                                </v-card-text>
                                                <v-divider/>
                                                <v-card-actions>
                                                    <v-spacer/>
                                                    <v-btn @click="closeDialog()">
                                                        <v-icon left>mdi-close</v-icon>
                                                        {{ $t('global.close') }}
                                                    </v-btn>
                                                    <v-btn type="submit" color="primary" :disabled="invalid"
                                                           @click.prevent="prepareEditVirtualDevice()">
                                                        <v-progress-circular
                                                            v-if="loading_physical_device_association"
                                                            indeterminate color="white" size="20" width="2"
                                                        />
                                                        <v-icon v-else left>mdi-content-save</v-icon>
                                                        {{ $t('global.validation') }}
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-form>
                                        </validation-observer>
                                    </v-card>
                                </v-dialog>

                                <v-dialog v-model="dialog_import" max-width="500px">
                                    <v-card>
                                        <validation-observer ref="observerImport" v-slot="{ invalid }">
                                            <v-form ref="formImport">
                                                <v-card-title><span class="headline">{{
                                                        $t('virtual_devices.import_virtual_device')
                                                    }}</span>
                                                </v-card-title>
                                                <v-divider/>

                                                <v-card-text>
                                                    <validation-provider :name="$t('global.label')" rules="required"
                                                                         v-slot="{ errors }">
                                                        <v-text-field
                                                            :label="$t('global.label')"
                                                            v-model="edited_virtual_device.label"
                                                            :prepend-icon="'mdi-label-outline'"
                                                            :error-messages="errors" solo
                                                        />

                                                    </validation-provider>

                                                    <validation-provider :name="$t('global.type')" rules="required"
                                                                         v-slot="{ errors }">
                                                        <v-text-field
                                                            :label="$t('global.type')"
                                                            v-model="edited_virtual_device.type"
                                                            :prepend-icon="'mdi-tape-measure'"
                                                            :error-messages="errors" solo
                                                        />
                                                    </validation-provider>

                                                    <validation-provider :name="$t('virtual_devices.virtual_device')"
                                                                         rules="required"
                                                                         v-slot="{ errors }">
                                                        <v-select return-object
                                                                  :items="virtual_devices_to_import"
                                                                  v-model="selected_virtual_device"
                                                                  :label="$t('virtual_devices.virtual_device')"
                                                                  :prepend-icon="'mdi-smoke-detector'"
                                                                  :error-messages="errors" solo
                                                                  :loading="loading_import_virtual_device"
                                                                  item-text="device_name"
                                                        >
                                                        </v-select>
                                                    </validation-provider>
                                                </v-card-text>

                                                <v-divider/>

                                                <v-card-actions>
                                                    <v-spacer/>
                                                    <v-btn @click="closeDialogImport()">
                                                        <v-icon left>mdi-close</v-icon>
                                                        {{ $t('global.close') }}
                                                    </v-btn>
                                                    <v-btn type="submit" color="primary" :disabled="invalid"
                                                           @click.prevent="importVirtualDevice()">
                                                        <v-icon left>mdi-content-save</v-icon>
                                                        {{ $t('global.validation') }}
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-form>
                                        </validation-observer>
                                    </v-card>
                                </v-dialog>

                                <v-dialog v-model="dialog_delete" max-width="500px">
                                    <v-card>
                                        <v-card-title>
                                            <span class="headline">{{
                                                    $t('virtual_devices.delete_virtual_device')
                                                }}</span>
                                        </v-card-title>
                                        <v-divider/>
                                        <br/>

                                        <v-card-actions>
                                            <v-spacer/>
                                            <v-btn @click="dialog_delete = false">
                                                <v-icon left>mdi-close</v-icon>
                                                {{ $t('global.cancel') }}
                                            </v-btn>
                                            <v-btn color="error" @click="confirmDelete()">
                                                <v-icon left>mdi-delete</v-icon>
                                                {{ $t('global.delete') }}
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>

                                <v-snackbar
                                    v-model="snackbar"
                                    :color="snackbar_type">
                                    {{ snackbar_text }}
                                    <template v-slot:action="{ attrs }">
                                        <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                                    </template>
                                </v-snackbar>
                            </v-toolbar>
                        </template>

                        <template v-slot:item.label="{ item }">
                            <router-link :to="{ path: '/virtual_device/' + item.id}">
                                {{ item.label }}
                            </router-link>
                        </template>

                        <template v-slot:item.professional_id="{ item }">
                                {{ item.professional.name }}
                        </template>

                        <template v-slot:item.subassembly_id="{ item }">
                            <router-link v-if="item.subassembly_id"
                                         :to="{ path: '/subassembly/' + item.subassembly.id}">
                                {{ item.subassembly.label }}
                            </router-link>
                            <template v-else>
                                <span>{{ $t('virtual_devices.device_not_bind') }}</span>
                            </template>
                        </template>

                        <template v-slot:item.action="{ item }">
                            <v-icon class="pointer" @click="openDialogUpdateVirtualDevice(item)">mdi-pencil</v-icon>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import {VIRTUAL_DEVICES, PHYSICAL_DEVICES} from "../../../api";

export default {
    name: "VirtualDeviceVision",
    data() {
        return {
            virtual_devices: [],
            physical_devices: [],
            virtual_devices_to_import: [],
            headers: [
                {text: this.$t('global.id'), value: 'id'},
                {text: this.$t('global.uid'), value: 'uid'},
                {text: this.$t('virtual_devices.physical_device_reference'), value: 'physical_device.uid'},
                {text: this.$t('global.name'), value: 'label'},
                {text: this.$t('global.professional'), value: 'professional_id'},
                {text: this.$t('virtual_devices.last_time_see'), value: 'last_time_see'},
                {text: this.$t('global.subassembly'), value: 'subassembly_id'},
                {text: this.$t('global.actions'), value: 'action'}
            ],
            loading_virtual_devices: true,
            loading_physical_devices: true,
            search: '',
            invalid: false,

            dialog_edit: false,
            edited_index: null,
            edited_virtual_device: {
                id: null,
                uid: null,
                label: '',
                type: '',
                hardware_uid: '',
                hardware_mac: '',
            },
            selected_virtual_device: {},

            dialog_delete: false,
            delete_id: null,
            deleted_index: null,

            dialog_import: false,
            loading_import_virtual_device: false,
            loading_physical_device_association: false,
        }
    },
    computed: {
        dialogTitle() {
            return this.edited_index === null ? this.$t('virtual_devices.add_virtual_device') : this.$t('virtual_devices.edit_virtual_device');
        }
    },
    created() {
        this.getVirtualDevices();
        this.getPhysicalDevices();
    },
    methods: {
        getVirtualDevices() {
            this.axios.get(VIRTUAL_DEVICES).then((success) => {
                this.virtual_devices = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            }).finally(() => {
                this.loading_virtual_devices = false;
            });
        },

        getPhysicalDevices() {
            this.axios.get(
                PHYSICAL_DEVICES +'?state=ready'
            ).then((success) => {
                this.physical_devices = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            }).finally(() => {
                this.loading_physical_devices = false;
            });
        },

        openDialogEditVirtualDevice() {
            if (this.edited_index !== null) {
                this.$refs.form.reset();
                this.$refs.observer.reset();
                this.edited_index = null;
            }

            this.dialog_edit = true;
        },

        openDialogUpdateVirtualDevice(virtual_device) {
            this.edited_index = this.virtual_devices.indexOf(virtual_device);
            this.edited_virtual_device = Object.assign({}, virtual_device);
            this.dialog_edit = true;
        },

        prepareEditVirtualDevice() {
            if (this.edited_index !== null) {
                this.updateVirtualDevice();
            } else {
                this.addVirtualDevice();
            }
        },

        updateVirtualDevice() {
            this.loading_physical_device_association=true;
            this.axios.patch(VIRTUAL_DEVICES + '/' + this.edited_virtual_device.id,
                this.edited_virtual_device
            ).then((success) => {
                Object.assign(this.virtual_devices[this.edited_index], success.data);
                this.edited_index = null;
                this.getPhysicalDevices();
                this.showSnackbar('success', this.$t('global.success_to_edit'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            }).finally(() => {
                this.loading_physical_device_association=false;
                this.closeDialog();
            });
        },

        addVirtualDevice() {
            this.loading_physical_device_association= true;
            this.axios.post(VIRTUAL_DEVICES, this.edited_virtual_device
            ).then((success) => {
                this.virtual_devices.push(success.data);
                this.showSnackbar('success', this.$t('global.success_to_add'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_add'));
            }).finally(() => {
                this.closeDialog();
                this.loading_physical_device_association= false;
            });
        },

        closeDialog() {
            this.dialog_edit = false;
            this.$refs.form.reset();
            this.$refs.observer.reset();
        },

        openDialogImportVirtualDevice() {
            this.edited_virtual_device.label = '';
            this.edited_virtual_device.type = '';
            this.dialog_import = true;
            this.loading_import_virtual_device = true;
            this.getVirtualDevicesToImport();
        },

        getVirtualDevicesToImport() {
            this.axios.get(
                VIRTUAL_DEVICES + '/techview/to_import'
            ).then((success) => {
                this.virtual_devices_to_import = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            }).finally(() => {
                this.loading_import_virtual_device = false;
            });
        },

        importVirtualDevice() {
            this.edited_virtual_device.uid = this.selected_virtual_device.device_uuid;
            if (this.selected_virtual_device.hardware !== null) {
                this.edited_virtual_device.hardware_mac = this.selected_virtual_device.hardware.hardware_mac;
            } else {
                this.edited_virtual_device.hardware_mac = null;
            }

            this.axios.post(
                VIRTUAL_DEVICES + '/techview/import', this.edited_virtual_device
            ).then((success) => {
                this.virtual_devices.push(success.data);
                this.showSnackbar('success', this.$t('global.success_to_import'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_import'));
            }).finally(() => {
                this.closeDialogImport();
            });
        },

        closeDialogImport() {
            this.dialog_import = false;
            this.$refs.formImport.reset();
            this.$refs.observerImport.reset();
        }
    }
}
</script>
