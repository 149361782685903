<template>
    <div id="modal-subassembly-detail">
        <v-dialog v-model="dialog_detail" max-width="750px">
            <v-card>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-card-title>
                        <span class="headline">{{ $t('subassembly_specifications.all_characteristics') }}</span>
                    </v-card-title>
                    <v-divider></v-divider>

                    <v-row class="mt-2 ml-2 mr-2 mt-5">
                        <v-text-field class="ml-2 mr-2"
                                      :label="$t('subassembly_specifications.site')"
                                      v-model="subassembly.label_site"
                                      :prepend-inner-icon="'mdi-sitemap'"
                                      outlined readonly background-color="#EEEEEE"
                        />
                        <v-text-field class="ml-2 mr-2 test"
                                      :label="$t('subassembly_specifications.building')"
                                      v-model="subassembly.label_building"
                                      :prepend-inner-icon="'mdi-office-building-outline'"
                                      outlined readonly background-color="#EEEEEE"
                        />
                        <v-text-field class="ml-2 mr-2"
                                      :label="$t('subassembly_specifications.machine')"
                                      v-model="subassembly.label_machine"
                                      :prepend-inner-icon="'mdi-state-machine'"
                                      outlined readonly background-color="#EEEEEE"
                        />
                        <v-text-field class="ml-2 mr-2"
                                      :label="$t('global.name')"
                                      v-model="subassembly.label"
                                      :prepend-inner-icon="'mdi-label-outline'"
                                      outlined
                        />
                        <template v-for="config in subassembly_configs">
                            <v-text-field class="ml-2 mr-2"
                                          :key="config.index"
                                          v-if="config.type !== 'selector'"
                                          v-show="!config.hidden"
                                          :label="config.title" outlined
                                          v-model="config.value"
                                          :readonly="readonly"
                                          :type="config.type === 'integer' ? 'number': ''"
                                          :rules="config.required ? [v => !!v || $t('vee-validation.required_field')] : []"
                                          :prepend-inner-icon="config.icon"
                                          :background-color="readonly ? '#EEEEEE' : ''"
                            />

                            <v-select class="ml-2 mr-2"
                                      :key="config.index"
                                      v-if="config.type === 'selector'"
                                      v-show="!config.hidden"
                                      :label="config.title" outlined
                                      v-model="config.value"
                                      :readonly="readonly"
                                      :type="config.type === 'selector'"
                                      :rules="config.required ? [v => !!v || $t('vee-validation.required_field')] : []"
                                      :prepend-inner-icon="config.icon"
                                      :items="config.choice"
                                      item-value="id" item-text="text"
                                      :background-color="readonly ? '#EEEEEE' : ''"
                            />
                        </template>
                    </v-row>

                    <v-textarea
                        class="ml-4 mr-4 mt-4 mb-0" rows="2" outlined no-resize
                        v-model="subassembly_note"
                        :label="$t('global.notes')"
                        :prepend-inner-icon="'mdi-comment-outline'"
                        :readonly="readonly"
                        :background-color="readonly ? '#EEEEEE' : ''"
                    />

                    <v-divider/>

                    <v-card-actions>
                        <v-spacer/>
                        <v-btn @click="dialog_detail = false">
                            <v-icon left>mdi-close</v-icon>
                            {{ $t('global.close') }}
                        </v-btn>
                        <v-btn type="submit" color="primary"
                               @click.prevent="editSubassembly()"
                               v-if="!readonly"
                               :disabled="!valid"
                        >
                            <v-icon left>mdi-content-save</v-icon>
                            {{ $t('global.validation') }}
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>

        <v-snackbar
            v-model="snackbar"
            :color="snackbar_type">
            {{ snackbar_text }}
            <template v-slot:action="{ attrs }">
                <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import {SUBASSEMBLIES} from "../../../../api";

export default {
    name: "ModalSubassemblyDetail",
    props: ["subassemblyData"],
    data() {
        return {
            subassembly: [],
            subassembly_configs: [],
            subassembly_note: '',
            dialog_detail: false,
            readonly: true,
            valid: true
        };
    },
    methods: {
        openDialogDetailsSubassembly(readonly) {
            this.readonly = readonly;
            this.subassembly = this.subassemblyData;
            this.subassembly_note = this.subassemblyData.note;
            this.subassembly_configs = JSON.parse(this.subassemblyData.config);
            this.dialog_detail = true;
        },

        editSubassembly() {
            this.subassembly.note = this.subassembly_note;
            this.subassembly.config = this.subassembly_configs;
            this.axios.put(SUBASSEMBLIES + '/' + this.subassembly.id,
                this.subassembly
            ).then(() => {
                this.$emit('showSnackbarForModalSubassemblyDetail', {
                    type: 'success',
                    text: this.$t('global.success_to_add')
                });
                this.$emit('updateSubassemblyLabel', {label: this.subassembly.label});
                this.dialog_detail = false;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'))
            });
        }
    }
}
</script>
