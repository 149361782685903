<template>
    <div id="register-view" class="page-not-connected">
        <v-row align="center" justify="center" class="h-100">
            <v-col lg="4" md="6" sm="8" cols="10">
                <v-card class="elevation-12" align-self="center" :loading="loading">
                    <validation-observer v-slot="{ invalid }">
                        <v-form>
                            <v-card-title class="primary white--text">
                                {{ $t('register.login') }}
                            </v-card-title>

                            <template v-if="!loading">
                                <v-card-text class="pt-4" >
                                    <validation-provider rules="required" v-slot="{ errors }"
                                                         :name="$t('register.gender')" class="mb-2"
                                    >
                                        <v-select v-model="user.gender"
                                                  :items="genders"
                                                  item-value="value" item-text="label"
                                                  :label="$t('register.gender')"
                                                  prepend-icon="mdi-card-account-details-outline"
                                                  single-line class="mb-2"
                                        />
                                    </validation-provider>

                                    <validation-provider rules="required" v-slot="{ errors }"
                                                         :name="$t('register.first_name')" class="mb-2"
                                    >
                                        <v-text-field v-model="user.first_name"
                                                      :label="$t('register.first_name')"
                                                      :error-messages="errors"
                                                      prepend-icon="mdi-account" type="text"
                                        />
                                    </validation-provider>

                                    <validation-provider rules="required" v-slot="{ errors }"
                                                         :name="$t('register.last_name')" class="mb-2"
                                    >
                                        <v-text-field v-model="user.last_name"
                                                      :label="$t('register.last_name')"
                                                      :error-messages="errors"
                                                      prepend-icon="mdi-account" type="text"
                                        />
                                    </validation-provider>

                                    <validation-provider rules="required|min:10|max:10|numeric" v-slot="{ errors }"
                                                         :name="$t('register.phone')" class="mb-2"
                                    >
                                        <v-text-field v-model="user.phone"
                                                      :label="$t('register.phone')"
                                                      :error-messages="errors"
                                                      prepend-icon="mdi-phone" type="text"
                                        />
                                    </validation-provider>

                                    <validation-provider rules="required|email" v-slot="{ errors }"
                                                         :name="$t('register.email')" class="mb-2"
                                    >
                                        <v-text-field v-model="user.email"
                                                      :label="$t('register.email')"
                                                      :error-messages="errors"
                                                      prepend-icon="mdi-at" type="email" readonly
                                        />
                                    </validation-provider>

                                    <validation-provider rules="required|min:7" v-slot="{ errors }"
                                                         :name="$t('register.password')" class="mb-2" vid="password"
                                    >
                                        <v-text-field
                                            :label="$t('register.password')"
                                            v-model="user.password"
                                            prepend-icon="mdi-lock-check-outline"
                                            :type="show_password ? 'text' : 'password'"
                                            :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                                            :error-messages="errors"
                                            @click:append="show_password = !show_password"
                                        />
                                    </validation-provider>

                                    <validation-provider rules="required|confirmed:password" v-slot="{ errors }"
                                                         :name="$t('register.password')" class="mb-2"
                                    >
                                        <v-text-field
                                            :label="$t('register.password')"
                                            v-model="user.confirm_password"
                                            prepend-icon="mdi-lock-check-outline"
                                            :type="show_confirm_password ? 'text' : 'password'"
                                            :append-icon="show_confirm_password ? 'mdi-eye' : 'mdi-eye-off'"
                                            :error-messages="errors"
                                            @click:append="show_confirm_password = !show_confirm_password"
                                        />
                                    </validation-provider>
                                </v-card-text>

                                <v-card-actions class="justify-end">
                                    <v-btn @click.prevent="storeUserFromInvitationAndLogin()" :disabled="invalid"
                                           :loading="register_button_loading"
                                           type="submit" color="primary" dusk="login-button"
                                    >
                                        {{ $t('register.login') }}
                                    </v-btn>
                                </v-card-actions>
                            </template>

                            <v-progress-linear v-else indeterminate color="blue"/>
                        </v-form>
                    </validation-observer>
                </v-card>
            </v-col>
        </v-row>

        <global-snackbar/>
    </div>
</template>

<script>
import {USERS, INVITATION} from "../../api";
import GlobalSnackbar from "../components/GlobalSnackbar";

export default {
    name: 'RegisterView',
    components: {GlobalSnackbar},
    data: function () {
        return {
            invalid: false,
            register_button_loading: false,
            loading: true,
            show_password: false,
            show_confirm_password: false,

            user: {
                gender: '',
                first_name: '',
                last_name: '',
                phone: '',
                email: '',
                password: '',
                confirm_password: '',
                role_id: null,
                professional_id: '',
                invitation_id: null,
            },
            genders: [
                {
                    label: 'Mme',
                    value: 'Mme',
                },
                {
                    label: 'Mr',
                    value: 'Mr',
                },
            ]
        }
    },
    created() {
        this.getInvitation()
    },
    methods: {
        getInvitation() {
            this.axios.get(
                INVITATION + '/' + this.$route.query.token
            ).then((success) => {
                this.user.invitation_id = success.data.id
                this.user.email = success.data.email
                this.user.role_id = success.data.role
                this.user.professional_id = success.data.professional_id
            }).catch((error) => {
                let errorMessage = this.$t('register.error_get_invitation');
                if (error.response.status === 404) {
                    errorMessage = this.$t('register.invitation_not_found');
                    this.$router.push({name: 'login'});
                }
                this.manageError(error.response.status, errorMessage);
            }).finally(() => {
                this.loading = false;
            });
        },

        storeUserFromInvitationAndLogin() {
            this.register_button_loading = true;

            this.axios.post(
                USERS + '/from_invitation', this.user
            ).then(() => {
                this.login();
            }).catch((error) => {
                this.manageError(error.response.status, this.$t('register.error_during_inscription'));
            }).finally(() => {
                this.register_button_loading = false;
            });
        },

        login() {
            this.$auth.login({
                data: {
                    email: this.user.email,
                    password: this.user.password
                }
            }).then(() => {
                this.$store.commit('setUserRoleId', this.$auth.user().role_id);
                this.$store.commit('setUserAccessLevel', this.$auth.user().access_level);
                this.$store.commit('setSwitchState', false);

                if (this.$auth.user().access_level === 100 || this.$auth.user().access_level > 200) {
                    this.$router.push({name: 'dashboard'});
                } else {
                    this.$router.push({name: 'dashboard_admin'});
                }
            }).catch(() => {
                this.manageError(error.response.status, this.$t('register.error_during_login'));
            });
        }
    }
}
</script>
