var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"modal-add-reminder"}},[_c('v-dialog',{attrs:{"scrollable":"","max-width":"500px"},model:{value:(_vm.dialog_add_reminder),callback:function ($$v) {_vm.dialog_add_reminder=$$v},expression:"dialog_add_reminder"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',[_c('v-form',{ref:"form"},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('operation.plan_reminder')))])]),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-card-text',[_c('validation-provider',{attrs:{"name":_vm.$t('operation.date_sending_reminder'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-menu',{attrs:{"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('operation.date_sending_reminder'),"prepend-icon":"mdi-calendar","readonly":"","solo":""},model:{value:(_vm.dateReminder),callback:function ($$v) {_vm.dateReminder=$$v},expression:"dateReminder"}},'v-text-field',attrs,false),on))]}}],null,true)},[_vm._v(" "),_c('v-date-picker',{attrs:{"date-format":"dd/MM/yyyy"},model:{value:(_vm.edited_operation_timeline.date_reminder),callback:function ($$v) {_vm.$set(_vm.edited_operation_timeline, "date_reminder", $$v)},expression:"edited_operation_timeline.date_reminder"}})],1)]}}],null,true)})],1),_vm._v(" "),_c('v-snackbar',{attrs:{"color":_vm.snackbar_type},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-icon',{attrs:{"color":"white"},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("mdi-close")])]}}],null,true),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v("\n                        "+_vm._s(_vm.snackbar_text)+"\n                        ")]),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{on:{"click":function($event){_vm.dialog_add_reminder = false}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close")]),_vm._v("\n                            "+_vm._s(_vm.$t('global.close'))+"\n                        ")],1),_vm._v(" "),_c('v-btn',{attrs:{"type":"submit","color":"primary","disabled":invalid},on:{"click":function($event){$event.preventDefault();return _vm.addOperationReminder()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v("\n                            "+_vm._s(_vm.$t('global.validation'))+"\n                        ")],1)],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }