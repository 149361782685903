<template>
    <div>
        <!-- DATATABLE -->
        <v-row>
            <v-col class="pl-7 pr-7">
                <v-card>
                    <v-card-title>
                        <v-spacer/>
                    </v-card-title>

                    <v-data-table
                        :headers="headers"
                        :items="machines"
                        sort-by="id"
                        class="elevation-1"
                        :loading="loading"
                        :search="search">

                        <template v-slot:top>
                            <v-toolbar flat color="white">
                                <v-layout class="justify-start">
                                    <v-btn color="primary" class="white--text mr-3" @click="openDialogAddMachine()">
                                        {{ $t('machines.add_machine') }}
                                    </v-btn>
                                    <v-btn color="primary" class="mb-2 white--text" @click="dialog_edit = true">
                                        {{ $t('subassemblies.add_subassembly') }}
                                    </v-btn>
                                </v-layout>

                                <v-card-title class="mt-5">
                                    <v-text-field
                                        v-model="search"
                                        append-icon="mdi-magnify"
                                        :label="$t('global.search')"
                                    ></v-text-field>
                                </v-card-title>

                                <v-dialog v-model="dialog_edit" max-width="500px">
                                    <v-card>
                                        <validation-observer ref="observer" v-slot="{ invalid }">
                                            <v-form ref="form">
                                                <v-card-title>
                                                    <span class="headline">{{ dialogTitle }}</span>
                                                </v-card-title>
                                                <v-divider></v-divider>

                                                <v-card-text>
                                                    <validation-provider :name="$t('global.name')" rules="required"
                                                                         v-slot="{ errors }">
                                                        <v-text-field solo
                                                                      :label="$t('global.name')"
                                                                      v-model="edited_machine.label"
                                                                      :prepend-icon="'mdi-label-outline'"
                                                                      :error-messages="errors"
                                                        />
                                                    </validation-provider>

                                                    <validation-provider :name="$t('machines.building')"
                                                                         rules="required"
                                                                         v-slot="{ errors }">
                                                        <v-select
                                                            :items="buildings"
                                                            v-model="edited_machine.building_id"
                                                            :label="$t('machines.building')"
                                                            :prepend-icon="'mdi-office-building-outline'"
                                                            :error-messages="errors"
                                                            item-value="id"
                                                            item-text="label" solo
                                                        >
                                                        </v-select>
                                                    </validation-provider>

                                                    <v-file-input
                                                        v-if="edited_index === null"
                                                        v-model="edited_machine.path_plan"
                                                        :label="$t('machines.machine_photo')"
                                                        @change="onImageChanged"
                                                        solo
                                                    ></v-file-input>


                                                    <validation-provider :name="$t('machines.utilisation')"
                                                                         rules="required"
                                                                         v-slot="{ errors }">
                                                        <v-text-field solo
                                                                      :label="$t('machines.utilisation')"
                                                                      v-model="edited_machine.functionality"
                                                                      :prepend-icon="'mdi-function'"
                                                                      :error-messages="errors"
                                                        />
                                                    </validation-provider>

                                                    <v-textarea rows="2" solo no-resize
                                                                v-model="edited_machine.note"
                                                                :label="$t('global.notes')"
                                                                :prepend-icon="'mdi-comment-outline'"
                                                    />
                                                </v-card-text>

                                                <v-card-actions>
                                                    <v-spacer/>
                                                    <v-btn @click="closeDialog()">
                                                        <v-icon left>mdi-close</v-icon>
                                                        {{ $t('global.close') }}
                                                    </v-btn>
                                                    <v-btn type="submit" color="primary" :disabled="invalid"
                                                           @click.prevent="prepareEditMachine()">
                                                        <v-icon left>mdi-content-save</v-icon>
                                                        {{ $t('global.validation') }}
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-form>
                                        </validation-observer>
                                    </v-card>
                                </v-dialog>

                                <v-dialog v-model="dialog_delete" max-width="500px">
                                    <v-card>
                                        <v-card-title>
                                            <span class="headline">
                                                {{ $t('machines.delete_machine') }}
                                            </span>
                                        </v-card-title>
                                        <v-divider></v-divider>
                                        <br/>

                                        <v-card-actions>
                                            <v-spacer/>
                                            <v-btn @click="dialog_delete = false">
                                                <v-icon left>mdi-close</v-icon>
                                                {{ $t('global.cancel') }}
                                            </v-btn>
                                            <v-btn color="error" @click="confirmDelete()">
                                                <v-icon left>mdi-delete</v-icon>
                                                {{ $t('global.delete') }}
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-toolbar>
                        </template>

                        <template v-slot:item.label="{ item }">
                            <router-link :to="{ path: '/machine/' + item.id}">
                                {{ item.label }}
                            </router-link>
                        </template>

                        <template v-slot:item.building="{ item }">
                            <router-link :to="{ path: '/building/' + item.building.id}">
                                {{ item.building.label }}
                            </router-link>
                        </template>

                        <template v-slot:item.path_plan="{ item }">
                            <v-img aspect-ratio="1" v-if="item.path_plan" class="mb-2 mt-2" :src="item.path_plan"
                                   height="200" contain>
                                <template v-slot:placeholder>
                                    <v-row
                                        class="fill-height ma-0 background-loader-image"
                                        align="center" justify="center"
                                    >
                                        <v-progress-circular indeterminate
                                                             color="black lighten-5"></v-progress-circular>
                                    </v-row>
                                </template>
                            </v-img>
                        </template>

                        <template v-slot:item.subassemblies="{ item }">
                            <span v-if="item.subassemblies.length === 0">{{ $t('machines.no_subassemblies') }}</span>
                            <template v-else v-for="subassembly in item.subassemblies">
                                <v-chip class="mt-1 mb-1" :color="assetColor(subassembly)"
                                        @click="$router.push({ name: 'subassembly', params: {id: subassembly.id} })"
                                        text-color="white"
                                >
                                    {{ subassembly.label }}
                                </v-chip>
                                <br/>
                            </template>
                        </template>

                        <template v-slot:item.action="{ item }">
                            <v-icon class="pointer" @click="openDialogUpdateMachine(item)">mdi-pencil</v-icon>
                            <v-icon class="pointer" @click="openDialogDeleteMachine(item)"
                                    v-if="item.subassemblies.length === 0">
                                mdi-delete
                            </v-icon>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>

        <!--SNACKBAR-->
        <v-snackbar
            v-model="snackbar"
            :color="snackbar_type">
            {{ snackbar_text }}
            <template v-slot:action="{ attrs }">
                <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import {MACHINES, BUILDINGS, SUBASSEMBLIES} from "../../../api";

export default {
    name: "MachineVision",
    data() {
        return {
            machines: [],
            buildings: [],
            headers: [
                {text: this.$t('global.name'), value: 'label'},
                {text: this.$t('machines.building'), value: 'building'},
                {text: this.$t('machines.utilisation'), value: 'functionality'},
                {text: this.$t('machines.machine_photo'), value: 'path_plan'},
                {text: this.$t('machines.subassemblies'), value: 'subassemblies'},
                {text: this.$t('machines.total_virtual_devices'), value: 'virtual_devices_count'},
                {text: this.$t('global.actions'), value: 'action'}
            ],
            loading: true,
            search: '',

            dialog_edit: false,
            edited_index: null,
            edited_machine: {
                id: null,
                label: '',
                note: '',
                site: '',
                functionality: '',
                building_id: '',
                path_plan: null
            },
            invalid: false,

            dialog_delete: false,
            delete_id: null,
            deleted_index: null,
            valid_image: true,
        }
    },
    computed: {
        dialogTitle() {
            return this.edited_index === null ? this.$t('machines.add_machine') : this.$t('machines.edit_machine');
        }
    },
    created() {
        this.getMachines();
        this.getBuildings();
    },
    methods: {
        getMachines() {
            this.axios.get(
                MACHINES
            ).then((success) => {
                this.machines = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            }).finally(() => {
                this.loading = false;
            });
        },

        getBuildings() {
            this.axios.get(
                BUILDINGS
            ).then((success) => {
                this.buildings = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            });
        },

        openDialogAddMachine() {
            if (this.edited_index !== null) {
                this.$refs.form.reset();
                this.$refs.observer.reset();
                this.edited_index = null;
            }

            this.dialog_edit = true;
        },

        openDialogUpdateMachine(machine) {
            this.edited_index = this.machines.indexOf(machine);
            this.edited_machine = Object.assign({}, machine);
            this.dialog_edit = true;
        },

        prepareEditMachine() {
            if (this.edited_index !== null) {
                this.updateMachine();
            } else {
                this.addMachine();
            }
        },

        updateMachine() {
            this.axios.patch(MACHINES + '/' + this.edited_machine.id,
                this.edited_machine
            ).then((success) => {
                Object.assign(this.machines[this.edited_index], success.data);
                this.edited_index = null;
                this.showSnackbar('success', this.$t('global.success_to_edit'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_update'));
            }).finally(() => {
                this.closeDialog();
            })
        },

        addMachine() {
            let formData = new FormData()
            formData.append('label', this.edited_machine.label);
            formData.append('brand', this.edited_machine.brand);
            formData.append('model', this.edited_machine.model);
            formData.append('size', this.edited_machine.size);
            formData.append('functionality', this.edited_machine.functionality);
            formData.append('plan', this.edited_machine.path_plan);
            formData.append('building_id', this.edited_machine.building_id);

            this.axios.post(
                MACHINES, formData, this.configurationFile
            ).then((success) => {
                this.machines.push(success.data);
                this.showSnackbar('success', this.$t('global.success_to_add'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_add'));
            }).finally(() => {
                this.closeDialog();
            });
        },

        openDialogDeleteMachine(machine) {
            this.deleted_index = this.machines.indexOf(machine);
            this.delete_id = machine.id;
            this.dialog_delete = true;
        },

        confirmDelete() {
            this.axios.delete(
                MACHINES + '/' + this.delete_id
            ).then(() => {
                this.machines.splice(this.deleted_index, 1);
                this.showSnackbar('success', this.$t('global.success_to_delete'));
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.failure_to_delete'));
            }).finally(() => {
                this.dialog_delete = false;
            });
        },

        closeDialog() {
            this.dialog_edit = false;
            this.$refs.form.reset();
            this.$refs.observer.reset();
        },

        onImageChanged() {
            if(this.edited_machine.path_plan !== null) {
                let imageCheck = this.uploadFilesCheck(this.edited_machine.path_plan,['image/png','image/jpg','image/jpeg'],2);
                if(!imageCheck.authorized) {
                    this.valid_image = false;
                    this.showSnackbar('error',imageCheck.output.toString());
                }
                else {
                    this.valid_image = true;
                }
            }
            if(this.edited_machine.path_plan === null){
                this.valid_image = true;
            }
        },
    }
}
</script>
