<template>
    <v-row class="row mt-0">
        <!-- MACHINE MENU -->
        <v-col class="col-1 pt-5 pb-5 rounded" style="height: 110%;background-color: #014181;max-width: 70px;position: fixed;top: -20px;z-index: 1;">
            <!-- REPORTS -->
            <v-tooltip bottom dark class="mr-2">
                <template v-slot:activator="{ on }">
                    <v-btn class="ma-2 mt-10 mb-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/report'}" dusk="logout-button">
                        <v-icon color="white">mdi-chart-box</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('machine.reports') }}</span>
            </v-tooltip>
            <!-- ALERTS -->
            <v-tooltip bottom dark class="mr-2">
                <template v-slot:activator="{ on }">
                    <v-btn class="ma-2 mt-5 mb-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/events'}" dusk="logout-button">
                        <v-icon color="white">mdi-alert</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('machine.alerts') }}</span>
            </v-tooltip>
            <!-- SETTINGS -->
            <v-tooltip bottom dark class="mr-2">
                <template v-slot:activator="{ on }">
                    <v-btn class="ma-2 mt-5 mb-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/settings'}" dusk="logout-button">
                        <v-icon color="white">mdi-cog</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('machine.settings') }}</span>
            </v-tooltip bottom dark class="mr-2">
            <!-- DETAILS -->
            <v-tooltip v-if="access_level <= 100 || access_level == 200 || access_level === 300" bottom dark class="mr-2">
                <template v-slot:activator="{ on }">
                    <v-btn class="ma-2 mt-5 mb-5 col-0" text icon v-on="on" :to="{ path: '/machine/' + id + '/details'}" dusk="logout-button">
                        <v-icon color="white">mdi-chart-line</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('machine.courbes') }}</span>
            </v-tooltip>
        </v-col>          
        
        <!-- CONTENT -->
        <v-col class="col pa-0 pl-5 pr-5" style="margin-left: 70px;">
            <!-- TOP TITLE -->
            <v-row class="row mt-0">
                <!-- PAGE TITLE -->
                <v-subheader class="v-subheader col theme--light pl-5 ml-2">
                    <p class="title mb-0 color-primary text-uppercase">
                        {{ $t('machines.settings') }}
                    </p>
                    <!------------------- Info bulle ---------------------->
                    <v-tooltip bottom dark class="mr-2">
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" @click="getNotification()" class="ml-2 v-size--default" dusk="logout-button" style="border: 1px solid #cacaca;width:18px;height:18px;">
                                <v-icon color="darkgrey" style="font-size:16px">mdi-information-variant</v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:default>
                        <div style="min-width: 200px;position:relative;z-index: 10;">
                            <strong>Page [Caractéristiques]</strong>
                            <p>Nous sauvegardons ici toutes les informations associées à la cinématique mécanique du moyen.</br>
                                Des documentations aux références roulements en passant par le suivi de nos instruments de mesure tout est visible depuis cet onglet.
                            </p>
                        </div>
                        </template>
                    </v-tooltip>
                </v-subheader>

                <!-- FORMAT SELECTOR -->
                <v-row cols="2" class="v-subheader col-1 theme--light mr-3">
                    <!-- SPACER -->
                    <v-col class="col"></v-col>
                </v-row>
            </v-row>

            <!-- STATE DATAS -->
            <v-col class="row col-12 mb-0 pa-0">
                <!-- COUNTER -->
                <v-col color="white" class="col-auto pa-0 mt-5" v-if="machine">
                    <v-col class="col pt-0 pb-0 mr-10 rounded col ml-6" style="height: 35px;background-color: rgb(224 232 240);font-weight: bold;text-align: center;font-size: 16px;border: 1px solid #014181;line-height: 35px;color: #014181;">
                        {{ machine.label }}
                    </v-col>
                </v-col>

                <!-- GLOBAL ACTIONS -->
                <v-col class="row col ml-6 pt-8">
                    <v-tooltip bottom dark class="mr-2">
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" @click="getData()" class="mr-2" dusk="logout-button" style="border: 1px solid #01418145;">
                                <v-icon color="primary">mdi-tag-multiple</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('global.1_5_2') }}</span>
                    </v-tooltip>
                    <v-tooltip bottom dark class="mr-2">
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" @click="getData()" class="mr-2" dusk="logout-button" style="border: 1px solid #01418145;">
                                <v-icon color="primary">mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('machines.refresh') }}</span>
                    </v-tooltip>
                </v-col>

                <!-- SPACER -->
                <v-col class="row col"></v-col> 
            </v-col>

            <!-- MACHINE SETTINGS CONTENT -->
            <v-col>
                <div id="machine-detail">
                    <v-col class="pa-0">
                        <v-row>
                            <v-col id="fixed-col">
                                <machine-state/>
                            </v-col>
                            <v-col class="pl-0">
                                <machine-specifications v-on:updateLabelMachine="updateLabelMachine"/>
                            </v-col>
                        </v-row>
                        <v-col class="pr-0 pl-0">
                            <documents-upload v-bind:parentType="type" v-bind:parentId="id" v-bind:min-height="525"/>
                        </v-col>
                        <v-col class="pr-0 pl-0">
                            <datatable-machine-subassemblies/>
                        </v-col>
                    </v-col>

                    
                </div>   

                <!-- SNACKBAR -->
                <v-snackbar
                    v-model="snackbar"
                    :color="snackbar_type">
                    {{ snackbar_text }}
                    <template v-slot:action="{ attrs }">
                        <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
                    </template>
                </v-snackbar>
            </v-col>
        </v-col>
    </v-row>
</template>
<script>

import DocumentsUpload from "../../components/DocumentsUpload";
import MachineSpecifications from "../../components/Machine/MachineSpecifications";
import MachineState from "../../components/Machine/MachineState";
import DatatableMachineSubassemblies from "../../components/Machine/DatatableMachineSubassemblies";
import DatatableOperations from "../../components/DatatableOperations";
import ModalComparisonCurves from "../../components/Machine/ComparisonCurves/ModalComparisonCurves";
import ComparisonCurves from "../../components/Machine/ComparisonCurves/ComparisonCurves";
import ThreeDimensionalCurves from "../../components/Machine/ThreeDimensionalCurves/ThreeDimensionalCurves";

import {COMPARISON_CURVES, THREE_DIMENSION_CURVES, MACHINES} from "../../../api";

export default {
    name: "DetailMachine",
    components: {
        ThreeDimensionalCurves,
        DocumentsUpload,
        MachineSpecifications,
        MachineState,
        DatatableMachineSubassemblies,
        DatatableOperations,
        ModalComparisonCurves,
        ComparisonCurves
    },
    data() {
        return {
            id: this.$route.params.id,
            access_level: this.$store.state.access_level,
            type: "machine",
            machineTitle:"",
            label: '',
            comparison_curves: [],
            three_dimensional_curves: [],
            machine:[],
        }
    },
    created() {
        this.getMachineComparisonCurves();
        this.getMachineThreeDimensionalCurves();
        this.getMachine();
    },
    methods: {
        getMachine() {
            this.axios.get(MACHINES + '/specific/' + this.id)
            
            .then((success) => {
                this.machine = [];
                this.machine = success.data[0];

            }).catch(() => {
                this.showSnackbar('error', "Untrashed data error.");
            })
        },
        checkDataAvailable(datas){
            if(typeof datas[0] === 'undefined') {                    
                return false;
            }else {
                return true;
            }
        },
        getMachineComparisonCurves() {
            this.axios.get(
                COMPARISON_CURVES + "?id_entity=" + this.id + "&type=machine"
            ).then((success) => {
                this.comparison_curves = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            });
        },

        getMachineThreeDimensionalCurves() {
            this.axios.get(
                THREE_DIMENSION_CURVES + "?id_entity=" + this.id + '&type=machine'
            ).then((success) => {
                    this.three_dimensional_curves = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            });
        },

        deleteComparisonCurves(item) {
            let removed_item= this.comparison_curves.splice(this.comparison_curves.findIndex(
                comparison_curve => comparison_curve.id === item
            ), 1);
        },

        deleteThreeDimensionalCurve(item){
            this.three_dimensional_curves.splice(this.three_dimensional_curves.findIndex(
                three_dimensional_curve => three_dimensional_curve.id === item
            ),1);
            this.showSnackbar('success', this.$t('global.success_to_delete'));
        },

        updateLabelMachine(item) {
            this.label = item.label;
        }
    }
}
</script>
<style scoped lang="scss">
#fixed-col {
    min-width: 638px;
    max-width: 638px !important;
}
@media only screen and (max-width: 480px) {
    #fixed-col {
        min-width: 100%;
        max-width: 100% !important;
    }
}
</style>
