<template>
    <div id="modal-assets-edit">
        <v-dialog v-model="open" :max-width="`${layerWidth + 28}px`" persistent>
            <v-card>
                <v-card-title>
                    <span class="headline">{{ title }}</span>
                    <v-spacer/>
                    <div>
                        <v-btn
                            color="primary"
                            class="text-none"
                            depressed
                            :loading="isSelecting"
                            @click="onButtonClick"
                        >
                            <v-icon left>
                                mdi-cloud-upload
                            </v-icon>
                            {{ $t('global.upload_new_img') }}
                        </v-btn>
                        <input
                            ref="uploader"
                            class="d-none"
                            type="file"
                            accept="image/*"
                            @change="onFileChanged"
                        >
                    </div>
                </v-card-title>

                <v-divider class="mb-2"></v-divider>

                <v-card-text>
                    <div class="layer" ref="layer">

                        <v-img aspect-ratio="1" :src="assetsData.path_image" :height="layerHeight" :width="layerWidth"
                               contain>
                            <template v-slot:placeholder>
                                <v-row
                                    class="fill-height ma-0 background-loader-image"
                                    align="center" justify="center"
                                >
                                    <v-progress-circular
                                        indeterminate color="black lighten-5"
                                        v-if="no_path_image === false"
                                    />
                                    <p class="ma-0" v-else>
                                        {{ $t('global.no_image') }}
                                    </p>
                                </v-row>
                            </template>
                        </v-img>


                        <drag-zone v-for="(a, index) in assetsData.assets" :ref="`asset${a.id}`"
                                   :key="index"
                                   class="layer--device"
                                   :data-index="index"
                                   :parent='true'
                                   :resizable='false'
                                   :rotatable='false'
                                   :draggable='true'
                                   :active='true'
                                   :value="{ x: convertPosition(a.position_x, 'x', false), y: convertPosition(a.position_y, 'y', false), width:25, height: 25, rotation: 0 }"
                                   @drag-end="onDragEnd"
                                   @drag-start="onDragStart(index)"
                                   v-if="!a.is_hide"
                        >
                            <v-tooltip bottom :color="assetColor(a)">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        :color="assetColor(a)"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-brightness-1
                                    </v-icon>
                                </template>
                                <v-chip
                                    :color="assetColor(a)"
                                    text-color="white"
                                    justify="center"
                                >
                                    {{ a.label }}
                                </v-chip>
                            </v-tooltip>
                        </drag-zone>
                    </div>
                </v-card-text>

                <v-divider class="mb-2"></v-divider>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col v-for="(asset ,index) in assetsData.assets" :key="index">
                                <v-row class="ml-1 justify-center" v-if="model === 'virtual_devices'">
                                    <template v-if="asset.battery !== null">
                                        <v-icon v-if="asset.battery < 40" color="red">
                                            mdi-battery-low
                                        </v-icon>
                                    </template>
                                    <v-icon v-if="asset.alert_keep_alive" color="red">
                                        mdi-wifi-off
                                    </v-icon>
                                    <v-icon v-if="asset.alert_battery" color="red">
                                        mdi-battery-low
                                    </v-icon>
                                </v-row>

                                <v-row class="justify-center">
                                    <v-chip
                                        :color="assetColor(asset)"
                                        :id="model === 'virtual_devices' ? asset.id_device : asset.id"
                                        text-color="white"
                                        justify="center"
                                        class="ma-2"
                                        @click="toggleHide(index)"
                                    >
                                        {{ asset.label }}
                                        <v-icon right v-if="!asset.is_hide">
                                            mdi-eye
                                        </v-icon>
                                        <v-icon right v-else>
                                            mdi-eye-off
                                        </v-icon>
                                    </v-chip>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer/>
                    <v-btn @click="close()">
                        <v-icon left>mdi-close</v-icon>
                        {{ $t('global.close') }}
                    </v-btn>
                    <v-btn type="submit" color="primary"
                           @click.prevent="savePositionPoint()"
                    >
                        <v-icon left>mdi-content-save</v-icon>
                        {{ $t('global.validation') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar
            v-model="snackbar"
            :color="snackbar_type">
            {{ snackbar_text }}
            <template v-slot:action="{ attrs }">
                <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import {each, cloneDeep} from 'lodash';
import DragZone from 'yoyoo-ddr';
import 'yoyoo-ddr/dist/yoyoo-ddr.css'

export default {
    name: "ModalAssetsEdit",
    components: {
        DragZone,
    },
    props: {
        fileUploadEndpoint: {
            type: String,
            required: true,
            default: () => '',
        },
        title: {
            type: String,
            required: false,
            default: () => '',
        },
        model: {
            type: String,
            required: false,
            default: () => '',
        },
        assetsData: {
            type: Object,
            required: true,
            default: () => {
            },
        },
    },
    data() {
        return {
            open: false,
            edit: false,
            currentId: this.$route.params.id,
            isSelecting: false,
            initialData: [],
            currentDrag: null,

            no_path_image: false,
            layerWidth: 320,
            layerHeight: 320,
        };
    },
    methods: {
        openAssetsEdit(disabled) {
            this.disabled = disabled;
            this.open = true;
            this.initialData = cloneDeep(this.assetsData.assets);
        },

        checkActivation(activationDate) {
            let now = new Date();
            let activation = new Date(activationDate);
            let expiredDate = activation.setFullYear(activation.getFullYear() + 1);
            return now > expiredDate;
        },

        checkLastSeen(lastSeenDate) {
            let now = new Date();
            let lastSeen = new Date(lastSeenDate);
            lastSeen.setDate(lastSeen.getDate() + 1);

            return now > lastSeen;
        },

        onButtonClick() {
            this.isSelecting = true
            window.addEventListener('focus', () => {
                this.isSelecting = false
            }, {once: true})

            this.$refs.uploader.click()
        },

        onFileChanged(e) {
            this.isSelecting = true;
            let image;
            if (e.target.files.length > 1) {
                this.showSnackbar('error', this.$t('global.too_many_files'));
                this.isSelecting = false;
            } else {
                image = e.target.files.item(0);
                let imageCheck = this.uploadFilesCheck(image,['image/png','image/jpg','image/jpeg'],10);
                if(!imageCheck.authorized){
                    this.showSnackbar('error',imageCheck.output.toString());
                    this.isSelecting = false;
                }
                else {
                    this.uploadFile(image);
                }
            }
        },

        onDragStart(index) {
            this.currentDrag = index;
        },
        onDragEnd(event, transform) {
            const index = this.currentDrag;
            this.assetsData.assets[index].position_x = this.convertPosition(transform.x, 'x', true);
            this.assetsData.assets[index].position_y = this.convertPosition(transform.y, 'y', true);
        },

        toggleHide(index) {
            this.assetsData.assets[index].is_hide = !this.assetsData.assets[index].is_hide;
        },

        uploadFile(file) {
            let formData = new FormData();
            formData.append('file', file);
            formData.append('id', this.currentId);

            this.axios.post(
                this.fileUploadEndpoint, formData, this.configurationFile
            ).then((success) => {
                this.assetsData.path_image = '/' + success.data;
                this.showSnackbar('success', this.$t('global.success_to_add'));
            }).catch((error) => {
                this.showSnackbar('error', this.$t('global.failure_to_add'));
            }).finally(() => {
                this.isSelecting = false;
            });
        },

        convertPosition(value, type, reverse) {
            let ref, pos;
            if (type === 'x') {
                ref = this.layerWidth;
            } else if (type === 'y') {
                ref = this.layerHeight;
            } else {
                ref = type;
            }
            if (reverse) {
                pos = (value / ref) * 100;
            } else {
                pos = (value * ref) / 100;
            }
            return pos;
        },

        close() {
            const initialState = this.initialData;
            each(this.assetsData.assets, function (a, index) {
                a.position_x = initialState[index].position_x;
                a.position_y = initialState[index].position_y;
                a.is_hide = initialState[index].is_hide;
            });


            this.open = false;
        },

        savePositionPoint() {
            this.open = false;
            this.$emit('saveChange');
        }
    },
    mounted() {
        this.$nextTick(function () {
            setTimeout(() => {
                this.no_path_image = true;
            }, 7000);
        });
        if (window.screen.width > 1000) {
            this.layerWidth = 600;
            this.layerHeight = 400;
        } else {
            this.layerWidth = 320;
            this.layerHeight = 320;
        }
    }
}
</script>

<style scoped lang="scss">
.layer {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    display: flex;

    &--device {
        position: absolute;
        top: 50%;
        left: 50%;
    }
}

.yoyoo-ddr {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transition: all .2s ease-out;

    i {
        width: 20px;
        height: 20px;
        font-size: 20px;
        transition: all .2s ease-out;
        border-radius: 50%;
    }

    &.active {
        border: none !important;
    }

    &:hover {
        i {
            font-size: 24px;
            animation: bloom infinite 1.5s;
        }
    }
}


@keyframes bloom {
    50% {
        box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.3);
    }
}
</style>
